
<div class="wizard-item">
  <component
    :is="obj.node.tag"
    v-if="!isReload"
    :key="obj.key"
    :fb-node="obj.node"
    :section-u-i-enabled="false"
    :disable-add-button-elements="true"
    v-bind="obj.node.data.props"
  ></component>

  <div v-if="genAIStatus !== 'completed'" class="wizard-item-overlay">
    <div class="wizard-item-overlay-message">
      <span class="">Please wait...</span>
      <br />
      <span class="font-size-14"
        >while we generate content for this section</span
      >
    </div>
  </div>
</div>
