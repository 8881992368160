<template>
  <el-tooltip
    :content="tip"
    placement="top"
  >
    <anime
      :enter="enter"
      :leave="leave"
      :before-enter="beforeEnter"
    >
      <div v-if="OrgName === standard">
        <i
          v-if="!marked"
          class="material-icons"
          @click.prevent.stop="mark"
        >delete</i>
        <i
          v-else
          class="material-icons delete-forever"
          @click.prevent.stop="deleteForever"
        >delete_forever</i>
      </div>
      <div v-else>
        <div
          v-if="!marked"
          @click.prevent.stop="mark"
        >
          <cvt-icon
            :icon="iconConfig.propertiesSidebar.remove || 'trash'"
          />
        </div>
        <div
          v-else
          @click.prevent.stop="deleteForever"
        >
          <cvt-icon
            style="color: red;"
            :icon="iconConfig.propertiesSidebar.remove || 'trash'"
          />
        </div>
      </div>
    </anime>
  </el-tooltip>
</template>

<script>
  import { mapState } from 'vuex'
  import { FRESHWORKS, STANDARD } from '../../../../../storybook/components/constants'

  export default {
    props: {
      marked: Boolean
    },
    data () {
      return {
        enter: {
          opacity: 1,
          duration: 500,
        },
        leave: {
          opacity: 0,
          duration: 500,
          easing: 'easeInOutQuart'
        },
        beforeEnter (e) {
          e.style.opacity = 0
        },
        standard: ''
      }
    },
        computed: {
      tip () {
        return this.marked ? this.$t('element.property.cta.confirm') : this.$t('layout.manage.delete')
      },
      ...mapState('globalTheme', {
        iconConfig: ({ iconConfig }) => iconConfig,
        OrgName: ({ globalTheme }) => globalTheme.OrgName !== undefined ? globalTheme.OrgName : STANDARD
      }),
    },
    created () {
        this.standard = STANDARD
    },

    methods: {
      deleteForever () {
        this.$emit('deleteForever')
      },
      mark () {
        this.$emit('mark')
      }
    }
  }
</script>

<style lang="css">
  .delete-forever {
    color: red;
  }
</style>
