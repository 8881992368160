
<cvt-collapse
  :accordion="true"
  :theme="mode === 'LIGHT' ? 'light' : 'dark'"
  :prepend-chevron="prependChevron"
>
  <cvt-collapse-item title="Style" name="style" :initial-active="true">
    <h6 :class="textColorClass">
      {{ $t('element.property.button.size.label') }}
    </h6>
    <cvt-radio-group orientation="horizontal" :initial-value="size">
      <cvt-radio-button
        size="md"
        shape="pill"
        value="hr-1"
        @radioClick="updateDividerSize"
      >
        {{ $t('element.property.divider.style.thin') }}
      </cvt-radio-button>
      <cvt-radio-button
        size="md"
        shape="pill"
        value="hr-2"
        @radioClick="updateDividerSize"
      >
        {{ $t('element.property.divider.style.thick') }}
      </cvt-radio-button>
      <cvt-radio-button
        size="md"
        shape="pill"
        value="hr-3"
        @radioClick="updateDividerSize"
      >
        {{ $t('element.property.divider.style.thickest') }}
      </cvt-radio-button>
    </cvt-radio-group>

    <!-- <div class="divider mt-3"></div>

    <h6>Spacing</h6>
    <el-radio-group v-model="hr">
      <el-radio-button label="py-1">XS</el-radio-button>
      <el-radio-button label="py-2">SM</el-radio-button>
      <el-radio-button label="py-3">MD</el-radio-button>
      <el-radio-button label="py-4">LG</el-radio-button>
      <el-radio-button label="py-5">XL</el-radio-button>
    </el-radio-group> -->
  </cvt-collapse-item>

  <cvt-collapse-item
    :title="$t('elements.properties.color.label')"
    name="color"
  >
    <colors :mode="mode"></colors>
  </cvt-collapse-item>

  <cvt-collapse-item
    :title="$t('elements.properties.spacing.label')"
    name="spacing"
  >
    <spacing-ctrl></spacing-ctrl>
  </cvt-collapse-item>
</cvt-collapse>
