<template>
    <div>
      <div class="d-flex">
        <strong class="mr-2 mb-3">{{ label }}</strong>
        <cvt-switch
          size="xs"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="isEnabled"
          :mode="mode"
          @switchToggle="() => onToggleClicked(updateKey)"
        />
      </div>
      <div class="row" :class="{ 'pointer-events-none': !isEnabled }">
        <div class="col-12">
          <div class="froala-headline-editor">
            <!-- Any tag modification will change how froala data is saved. See saveWithHtml method -->
            <InlineEditor
              class="froala-editor"
              :config="config"
              :content="content"
              @change="(value) => selectAction(value)"/>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import InlineEditor from "./InlineEditor.vue"
import * as _ from "lodash";
import { mapActions } from "vuex";
import * as actions from "@/js/store/modules/actions";

export default {
  name: "TextOptions",
  components: { InlineEditor },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    config: {
      type: Object,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    updateKey: {
      type: String,
      required: true
    },
    isEnabled: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
    }),
    selectAction (value) {
      this.$emit('save', value)
    },
    onToggleClicked() {
      this.updateProp({ key: `template/${this.updateKey}`, value: !this.isEnabled });
    }
  }
};
</script>

<style scoped>

</style>