
<cvt-dialog
  size="lg"
  :show="showSubscriptionModal"
  :show-close="false"
  :height-auto="true"
  :show-header="false"
  :show-footer="false"
>
  <div class="row mb-3 mt-3">
    <div class="col-md-10 text-center mx-auto">
      <div class="my-3"></div>
      <h1 class="setup-title">{{ modalHeading }}</h1>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-md-10 text-center mx-auto">
      <h5>
        <div class="my-3"></div>
          {{ modalDescription }}
        <div class="my-3"></div>
      </h5>
    </div>
  </div>

  <div class="row d-flex justify-content-center mb-4">
    <div class="">
      <a v-show="false" ref="actionBtnUrlAnchor" :href="modalActionBtnUrl" target="_blank"></a>
      <cvt-button
        size="sm"
        color="LIGHT"
        :text="'Update Subscription'"
        @click="(event) => continueToPayment(event)"
      />
    </div>
  </div>
</cvt-dialog>
