<template>
  <div class="layers-menu">
    <div v-if="title" class="layer-menu__title">
      <h2>Layers</h2>
    </div>

    <div
      v-for="node in nodes"
        :key="node"
         class="layer"
         :class="isActiveNode(node)"
         @mouseover.stop.prevent="target(node)"
         @click.stop.prevent="select(node)">

      <h3 v-text="fmtNodeName(node.name)"></h3>
    </div>

  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from 'vuex'
  import * as getters from '../../../store/modules/getters'
  import * as actions from '../../../store/modules/actions'
  import * as mutations from '../../../store/modules/mutations'
  import { ELEMENT_NAME_MAP } from '../../../lib/helper-classes'

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Layers',
    props: {
      title: Boolean,
      mode: {
        type: String,
        default: 'LIGHT'
      },
      prependChevron: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters({
        nodes: getters.SELECTOR_GET_HIERARCHY,
        selected: getters.SELECTOR_SELECTED,
        targeted: getters.SELECTOR_TARGETED
      })
    },
    methods: {
      ...mapActions({
        deselect: actions.SELECTOR_DESELECT,
        select: actions.SELECTOR_SELECT,
        target: actions.SELECTOR_TARGET,
        historyApply: actions.HISTORY_APPLY
      }),
      isActiveNode (node) {
        return {
          'layer--active': node === this.selected
        }
      },
      fmtNodeName (name) {
        return ELEMENT_NAME_MAP[name] || name
      }
    }
  }
</script>

<style lang="scss">
  @import "@/scss/utils";

  .layers-menu {
    background: $white;
    padding: 10px;

    &__title {

    }

    .layer {

      cursor: pointer;
      padding-top: 5px;
      padding-bottom: 5px;
      color: $dark-gray;

      h3 {
        font-weight: bold;
        font-size: 18px;
        margin: 0;
      }

      &:hover {
        color: $blue;
        background: $light-gray;
      }

      &--active {
        color: $inf-green;
        background: $light-gray;
      }
    }
  }



</style>
