
<div>
  <h1>Table</h1>
  <h4 class="mt-4">Dark, Single Select</h4>
  <cvt-table
    mode="DARK"
    style="width: 100%;"
    :single-select="true"
    :value="selectedRow1"
    striped
    @change="onItemChange1"
  >
    <cvt-thead>
      <cvt-tr>
        <cvt-th>Date</cvt-th>
        <cvt-th>Name</cvt-th>
        <cvt-th>Address</cvt-th>
      </cvt-tr>
    </cvt-thead>
    <cvt-tbody>
      <cvt-tr
        v-for="(item, index) in tableData"
        :key="index"
        :name="index"
      >
        <cvt-td>{{ item.date }}</cvt-td>
        <cvt-td>{{ item.name }}</cvt-td>
        <cvt-td>{{ item.address }}</cvt-td>
      </cvt-tr>
    </cvt-tbody>
  </cvt-table>

  <h4 class="mt-4">Light, Multi Select</h4>
  <cvt-table
    mode="LIGHT"
    style="width: 100%;"
    :multi-select="true"
    :value="selectedRow2"
    striped
    @change="onItemChange2"
  >
    <cvt-thead>
      <cvt-tr>
        <cvt-th>Date</cvt-th>
        <cvt-th>Name</cvt-th>
        <cvt-th>Address</cvt-th>
      </cvt-tr>
    </cvt-thead>
    <cvt-tbody>
      <cvt-tr
        v-for="(item, index) in tableData"
        :key="index"
        :name="index"
      >
        <cvt-td>{{ item.date }}</cvt-td>
        <cvt-td>{{ item.name }}</cvt-td>
        <cvt-td>{{ item.address }}</cvt-td>
      </cvt-tr>
    </cvt-tbody>
  </cvt-table>
</div>
