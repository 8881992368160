import { styled } from "@egoist/vue-emotion"
import { mapGetters, mapState } from 'vuex'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { CvtIcon } from "../../molecules/icon"
import CvtButton from "../../molecules/Button"

let backgroundColor, Spacing, FontWeight, textFontSize, textColor, textFontWeight, FontSize

const getFooterContainer = () => styled('div')`
    background: #5D62AB;
    width: 100%;
    padding: 0px ${Spacing.SPACING_18}px;
    height: 70px;
    position: fixed;
    bottom: 0px;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
`

const getFooterWrapper = () => styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
`

const getLabelWrapper = () => styled('div')`
    margin-right: ${Spacing.SPACING_24}px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const getTrialIcon = () => styled(CvtIcon)`
    font-size: ${FontSize.FONT_SIZE_20}px;
    ${textColor({ themeing: "dark"}, 'WHITE')}
`

const getTrialLabel = () => styled('span')`
    ${textFontSize('md')}  
    ${textColor({ themeing: "dark"}, 'WHITE')}
    ${textFontWeight('BOLD')}
    padding-left: ${Spacing.SPACING_12}px;
`

const getBtnWrapper = () => styled('div')`
`

const getPricingBtn = () => styled(CvtButton)`
    padding: ${Spacing.SPACING_16}px ${Spacing.SPACING_12}px;
    border-radius: 7px !important;
    color: #5D62AB !important;
    font-size: ${FontSize.FONT_SIZE_14}px !important;
    font-weight: ${FontWeight.REGULAR} !important;
`

export const EnterpriseFooter = {
    name: 'EnterpriseFooter',
    data() {
        return {}
    },
    computed: {
        ...mapGetters('globalTheme', {
            textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
            color: getters.GLOBAL_STYLE_COLOR,
            backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
            textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
            textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
            textColor: getters.GLOBAL_STYLE_COLOR
            
        }),
        ...mapState('globalTheme', {
            FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
            FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
            FontSize: ({ globalTheme }) => globalTheme.FontSize,
            Spacing: ({ globalTheme }) => globalTheme.Spacing
        })
    },
    created(){
        backgroundColor = this.backgroundColor
        Spacing = this.Spacing
        FontSize = this.FontSize
        FontWeight = this.FontWeight
        textFontSize = this.textFontSize
        textColor = this.textColor
        textFontWeight = this.textFontWeight
    },
    props: {

    },
    render: function(h) {
        const FooterContainer = getFooterContainer()
        const FooterWrapper = getFooterWrapper()
        const LabelWrapper = getLabelWrapper()
        const TrialIcon = getTrialIcon()
        const TrialLabel = getTrialLabel()
        const BtnWrapper = getBtnWrapper()
        const PricingBtn = getPricingBtn()
        return(
            <FooterContainer>
                <FooterWrapper>
                    <LabelWrapper>
                        <TrialIcon
                            icon="info-circle"
                        />
                        <TrialLabel>{this.$t('enterprise.footer.pricing.text')}</TrialLabel>
                    </LabelWrapper>
                    <BtnWrapper>
                        <PricingBtn
                            mode="'LIGHT'"
                            color="light"
                            shape=""
                            text="See Pricing"
                        />
                    </BtnWrapper>
                </FooterWrapper>
            </FooterContainer>
        )
    }
}