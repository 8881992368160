
<div>
  <h1>Dropdown</h1>
  <div
    v-for="(color, index) in colors"
    :key="color"
  >
    <span>{{ index }}</span>
    <cvt-dropdown
      :select-disabled="false"
      :on-hover-trigger-mode="false"
      :bg-color="color"
    >
      <option
        v-for="item in data"
        :key="item.value"
        :icon="item.icon"
        :value="item.value"
        v-text="item.label"
      />
    </cvt-dropdown>
  </div>
</div>
