
<div class="field-editor">
  <div v-if="canShowListFolder" class="row my-2">
    <div class="col">
      <remote-data-selector
        v-model="folderId"
        endpoint="/v1/crm/folders"
        :placeholder="$t('element.property.form.select.folder_list')"
        :mode="mode"
      />
    </div>
  </div>
  <!-- Moved to CFormMenuOptions under a different tab -->

  <!--    <div v-if="canShowContactList" class="row my-2">-->
  <!--      <div class="col">-->
  <!--        <remote-data-selector-->
  <!--          v-model="listId"-->
  <!--          :placeholder="$t('element.property.form.select.contact_list')"-->
  <!--          :endpoint="contactListEndpoint"-->
  <!--          :mode="mode"-->
  <!--          :last-updated="contactListLastUpdated"-->
  <!--        />-->
  <!--      </div>-->
  <!--    </div>-->

  <!-- Moved to CFormMenuOptions under a different tab -->
  <!--    <div v-if="canAddCustomList" class="row my-2">-->
  <!--      <div class="col">-->
  <!--        <add-custom-list @created="refreshContactLists" />-->
  <!--      </div>-->
  <!--    </div>-->

  <div class="row my-2">
    <div class="col">
      <remote-form-fields
        :exclude="formFields"
        :is-busy="refreshForms"
        :placeholder="$t('element.property.form.add.field')"
        @selected="addQuestion"
      />
    </div>
  </div>

  <div v-if="config.CRM_FORM_FIELD_UI_ENABLED" class="row my-2">
    <div class="col">
      <add-custom-field @created="addQuestion" />
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="form-fields">
        <div class="row">
          <div class="col-md-12">
            <draggable-list
              :list="formFields"
              @change="updateFormFields"
              @item-clicked="selectField"
            >
              <template slot="list-item-template" slot-scope="props">
                <i
                  v-if="FormMenuHoverEffect"
                  id="drag-handle"
                  class="material-icons"
                  >drag_handle</i
                >
                <cvt-icon
                  v-else
                  id="drag-handle"
                  :icon="iconConfig.leftSidebar.layout.drag || 'copy'"
                />
                <span :class="{ 'blue-text': !FormMenuHoverEffect }">
                  {{ fmtFieldName(props.item) }}
                </span>

                <div
                  :class="addHoverEffect"
                  :style="!FormMenuHoverEffect ? 'margin-left: auto;' : ''"
                >
                  <delete-button
                    v-if="
                      !FormRequiredFieldsUnDeletable || !props.item.required
                    "
                    :marked="markedForDeletion(props.index)"
                    @mark="markFieldForDeletion(props.index)"
                    @deleteForever="confirmFieldDeletion"
                  />
                </div>
              </template>

              <template slot="list-item-settings" slot-scope="props">
                <field-editor
                  :visible="props.selected"
                  :field="selectedField"
                  :mode="mode"
                  @change="updateFieldById"
                />
              </template>
            </draggable-list>

            <cvt-button
              v-if="config.MANAGE_INTEGRATIONS_ENABLED"
              :outlined="false"
              :full-width="true"
              :text="$t('element.property.manage.integrations.text')"
              :color="'LIGHT'"
              @click="() => onManageIntegrations()"
            ></cvt-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="config.CRM_FORM_FIELD_VIRTUAL_UI_ENABLED" class="row my-2">
    <div class="col">
      <virtual-fields @selected="addQuestion" />
    </div>
  </div>
</div>
