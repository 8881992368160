export const DELTA_ADDED = 'delta:added'
export const DELTA_CHANGED = 'delta:changed'
export const DELTA_REMOVED = 'delta:removed'
export const DELTA_MOVED = 'delta:moved'
export const DELTA_SET_KEY = 'delta:set:key'
export const SELECTOR_TARGET = 'selector:target'
export const SELECTOR_IGNORE = 'selector:ignore'
export const SELECTOR_SELECT = 'selector:select'
export const SELECTOR_DESELECT = 'selector:deselect'
export const SELECTOR_BUILD_HIERARCHY = 'selector:build:hierarchy'
export const DROPZONE_DEACTIVATE = 'dropzone:deactivate'
export const DROPZONE_DISABLE = 'dropzone:disable'
export const DROPZONE_DROPPED = 'dropzone:dropped'
export const FORM_EDITOR_SHOW = 'form:editor:show'
export const FORM_EDITOR_HIDE = 'form:editor:hide'
export const FORM_EDITOR_UPDATE_CUSTOM_FIELDS =
  'form:editor:update:custom:fields'
export const QUICK_LAUNCH_BLOCK_SET_INSERT_HANDLER = 'quick:launch:block:show'
export const QUICK_LAUNCH_BLOCK_SET_PAGE_ENGINE =
  'quick:launch:block:set:page:engine'
export const SAVE_BLOCK_SHOW = 'save:block:show'
export const SAVE_BLOCK_HIDE = 'save:block:hide'
export const SAVE_BLOCK_RESET = 'save:block:reset'
export const SAVE_BLOCK_SET_DATA = 'save:block:set:data'
export const SAVE_BLOCK_SET_VNODE = 'save:block:set:vnode'
export const SAVE_BLOCK_UPDATE_DATA = 'save:block:update:data'
export const SWAP_BLOCK_SHOW_DIALOG = 'swap:block:set:show:dialog'
export const SWAP_BLOCK_HIDE_DIALOG = 'swap:block:set:hide:dialog'
export const SWAP_BLOCK_SET_CURRENT_BLOCK_DATA =
  'swap:block:set:current:block:data'
export const SET_REARRANGING_BLOCK = 'block:set:rearranging-block:data'
export const SWAP_BLOCK_SET_SAVED_BLOCK_DATA = 'swap:block:set:saved:block:data'
export const SITE_SET_DATA = 'site:set:data'
export const POPUP_SET_DATA = 'popup:set:data'
export const SITES_SET_DATA = 'sites:set:data'
export const SITES_APPEND_DATA = 'sites:append:data'
export const SITES_REPLACE_SITE = 'sites:replace:site'
export const SITE_LOCK = 'site:lock'
export const SITE_UNLOCK = 'site:unlock'
export const SDA_POPUP_LOCK = 'popup:lock'
export const SDA_POPUP_UNLOCK = 'popup:unlock'
export const SITE_TRIGGER_SETTINGS = 'site:trigger:settings'
export const SITE_TRIGGER_TAB = 'site:trigger:tab'
export const SDA_POPUP_TRIGGER_TAB = 'site:trigger:tab'
export const SITE_TOGGLE_READY_STATE = 'site:toggle:ready:state'
export const SITE_TOGGLE_PULSE_PREFERNCE = 'site:toggle:pulse:preference'
export const AUTH_SET_USER = 'auth:set:user'
export const AUTH_SET_FIREBASE_USER = 'auth:set:firebase:user'
export const IMAGE_SEARCH_UPDATE_RESULTS = 'image:search:update:results'
export const IMAGE_SEARCH_SET_INSERT_HANDLER = 'image:search:set:insert:handler'
export const THEME_UPDATE_DATA = 'theme:update:data'
export const THEME_SET_FONT = 'theme:set:font'
export const THEME_SET_BUTTON_DEFAULT_STYLES = 'theme:set:button:default:styles'
export const THEME_SET_FROALA_LINK_DEFAULT_STYLES =
  'theme:set:froala:link:default:styles'
export const SDA_POPUP_SETTINGS_UPDATE_DATA = 'sda:popup:settings:update:data'
export const PREVIEW_OPEN = 'preview:open'
export const PREVIEW_CLOSE = 'preview:close'
export const ICON_PICKER_SHOW = 'icon:picker:show'
export const HISTORY_DELTA_REF_PUSH = 'history:deltaref:push'
export const HISTORY_VIEW_MODE_PUSH = 'history:viewmode:push'
export const HISTORY_LISTEN = 'history:listen'
export const HISTORY_IGNORE = 'history:ignore'
export const OPEN_GRAPH_UPDATE = 'open:graph:update'
export const SUPER_UPDATE_APPS = 'super:update:apps'
export const SUPER_TOGGLE_SEARCHING = 'super:toggle:searching'
export const ELEMENT_LAUCHER_INIT = 'element:launcher:init'
export const ELEMENT_LAUCHER_TETHER = 'element:launcher:tether'
export const ELEMENT_LAUNCHER_TOGGLE = 'element:launcher:visisble'
export const RIGHT_CLICK_MENU_TOGGLE = 'right:click:menu:toggle'
export const RIGHT_CLICK_MENU_UPDATE_POSITION =
  'right:click:menu:update:position'
export const RIGHT_CLICK_MENU_UPDATE_TARGET = 'right:click:menu:update:target'
export const ASSET_MANAGER_TOGGLE = 'asset:manager:toggle'
export const ASSET_MANAGER_ACTIVATE_TAB = 'asset:manager:activate:tab'
export const ASSET_MANAGER_SET_SELECT_ACTION = 'asset:manager:set:select:action'
export const ASSET_MANAGER_RESET = 'asset:manager:reset'
export const MARKETPLACE_SET_TEMPLATES = 'marketplace:set:templates'
export const POPUP_MARKETPLACE_SET_TEMPLATES = 'popup:marketplace:set:templates'
export const MARKETPLACE_UPDATE_DOMAINS = 'marketplace:update:domains'
export const PAGE_PREVIEW_TOGGLE = 'page:preview:toggle'
export const STEPS_SET_STEP = 'steps:set:step'
export const STEPS_RESET = 'steps:reset'
export const STEPS_BLOCK = 'steps:block'
export const DRAFT_UPDATE_RANGE = 'draft:update:range'
export const DRAFT_SET_FROALA_INSTANCE = 'draft:set:froala:instance'
export const DRAFT_DECLARE_EDITING = 'draft:declare:editing'
export const DRAFT_UPDATE_RANGE_FORMAT = 'draft:update:range:format'
export const LAYOUT_UPDATE_PATH = 'layout:update:path'
export const START_OVER_SHOW_DIALOG_SET = 'start-over:show-dialog:set'
export const VIEW_MODE_SET = 'view:mode:set'
export const ADVANCED_MENU_SET_ACTIVE_TABS = 'ADVANCED_MENU_SET_ACTIVE_TABS'
export const INTERCOM_LOADED = 'INTERCOM_LOADED'
export const INTERCOM_SET_USER_DATA = 'INTERCOM_SET_USER_DATA'
export const MIXPANEL_LOADED = 'mixpanel:loaded'
export const PENDO_LOADED = 'pendo:loaded'
export const APPCUES_LOADED = 'appcues:loaded'
export const EVENT_TRACKING_LOADED = 'event:tracking:loaded'
export const CHAMELEON_LOADED = 'chameleon:loaded'
export const FROGED_LOADED = 'froged:loaded'
export const DOPPLER_LOADED = 'doppler:loaded'
export const CUSTOM_TENANT_SCRIPTS_LOADED = 'custom:tenant:scripts:loaded'
export const SHOW_EDITOR_NAME_DIALOG = 'show:editor:name:dialog'
export const HIDE_EDITOR_NAME_DIALOG = 'hide:editor:name:dialog'
export const SHOW_SITE_SETTINGS = 'show:site:settings'
export const HIDE_SITE_SETTINGS = 'hide:site:settings'
export const SITE_SET_SETTINGS_MESSAGE = 'site:set:settings:message'
export const SET_POPUP_IDX = 'popup:set:index'
export const SET_POPUP_EMPTY = 'popup:set:empty'
export const SET_POPUP_PATH = 'popup:set:path'
export const INIT_POPUP = 'popup:init'
export const UPDATE_TEMPLATE_PAGE = 'update:template:page'
export const UPDATE_SITE_PREVIEW_PAGE = 'update:site:preview:page'
export const UPDATE_SITE_PREVIEW_ROUTE_VISITED =
  'update:site:preview:route:visited'
export const UPDATE_DOMAINS = 'update:domains'
export const SET_SITE_ANALYTICS = 'set:site:analytics'
export const SET_PAGES_SESSION_COOKIE_DATA = 'set:pages:session:cookie:data'
export const PAGE_PREVIEW_INFO_TOGGLE = 'page:preview:info:toggle'
export const UPDATE_SUBSCRIPTION_MODAL_VISIBILITY =
  'update:subscription:modal:visibility'

export const SET_ALL_CATEGORIES = 'set:all:categories'
export const SET_MODAL = 'set:modal'
export const SET_PREFERENCES = 'set:preferences'

export const HEADER_DELTA_ADDED = 'header:delta:added'
export const HEADER_DELTA_CHANGED = 'header:delta:changed'
export const HEADER_DELTA_REMOVED = 'header:delta:removed'
export const HEADER_DELTA_MOVED = 'header:delta:moved'
export const HEADER_DELTA_SET_KEY = 'header:delta:set:key'

export const FOOTER_DELTA_ADDED = 'footer:delta:added'
export const FOOTER_DELTA_CHANGED = 'footer:delta:changed'
export const FOOTER_DELTA_REMOVED = 'footer:delta:removed'
export const FOOTER_DELTA_MOVED = 'footer:delta:moved'
export const FOOTER_DELTA_SET_KEY = 'footer:delta:set:key'

export const SET_DNS_RECORD = 'set:dns:record'
export const SET_CUSTOM_HOST_NAMES = 'set:custom:host:names'
export const SET_ENV_JWT_TOKEN = 'set:env:jwt:token'
export const SET_OIDC_CONFIG = 'set:oidc:config'

export const SELECT_INTEGRATION = 'select:integration'
export const SET_BLOCK_ADDED = 'set:block:added'
export const CHECKOUT_PAGE_OPTIONS = 'checkout:page:options'
export const CHECKOUT_THANK_YOU_PAGE_OPTIONS = 'checkout:thany-you:page:options'

export const SET_IT_TEAM = 'set:it:team'

export const SET_SIGNIN_USER_EMAIL = 'set:signin:user:email'
export const SET_CHAT_WIDGET_CONFIG = 'set:chat:widget:config'

export const SET_CONFIG = 'set:config'

export const TRIGGER_INTEGRATIONS_DIALOG = 'trigger:integrations:dialog'

export const BOOKMARK_BLOCK_SHOW = 'bookmark:block:show'
export const BOOKMARK_BLOCK_HIDE = 'bookmark:block:hide'
export const PAGES_BOOKMARK_BLOCK_SHOW = 'pages:bookmark:block:show'
export const PAGES_BOOKMARK_BLOCK_HIDE = 'pages:bookmark:block:hide'


export const MP_SET_MANAGER_MODE = 'mp:set:manager:mode'
export const MP_SET_BRANDS = 'mp:set:brands'
export const MP_RESET_STATE = 'mp:reset:state'
export const MP_UPDATE_THEME = 'mp:update:theme'
export const MP_SET_IDX = 'mp:set:idx'
export const MP_SET_BRAND_LOGO = 'mp:set:brand:logo'
export const MP_SET_FAVICON_URL = 'mp:set:favicon:url'
export const MP_SET_TYPOGRAPHY = 'mp:set:typography'
export const MP_SET_BRANDING_NAME = 'mp:set:branding:name'
export const MP_SET_BRANDING_DATA = 'mp:set:branding:data'

export const THEME_SET_GLOBAL_STYLES = 'theme:set:global:styles'
