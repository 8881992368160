<template>
  <div class="form-group">
    <el-container v-if="multiple" class="layout-container">
      <div class="mt-3">
        <cvt-label
        mode='label'
        :label="$t('setup.font.weight')"
          />
      </div>
      <el-select
      v-model="fontWeight"
      multiple
      class="pb-3"
      :label="$t('setup.font.weight')"
      @change="(value) => updateFontWeight(value)"
      >
    <el-option
    v-for="(weight,index) in  options" 
    :key="index"
      :label="weight.label"
      :value="weight.value"
      >
    </el-option>
  </el-select>
</el-container>
    <div v-if="!multiple">
      <cvt-select
      :value="fontWeight"
      mode="dropdown"
      :label="$t('setup.font.weight')"
      @input="(value) => updateFontWeight(value)"
    >
      <option v-for="weight in fontWeights" :key="weight.value" :value="weight.value" v-text="weight.label" />
    </cvt-select>
    </div>
  </div>
</template>

<script>
  import { FONT_WEIGHTS } from '../../lib/font'
  import CvtLabel from '../../../../../storybook/components/molecules/FieldLabel'

  export default {
    components:{
      CvtLabel
    },
    props: {
      value: {
        type: [String, Array],
        default: ''
      },
      options: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
      },
    },
    data () {
      return {
        fontWeights: this.options.length ? this.options : FONT_WEIGHTS,
        fontWeight: this.value,
      }
    },
    methods: {
      updateFontWeight (value) {
        this.fontWeight = value
        this.$emit('input', value)
      }
    },
  }
</script>

<style scoped>
.layout-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.label {
  margin-top: 50px;
}

</style>
