import loadPendo from '../../lib/loadPendo'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

function Pendo(...args) {
  if (!window.pendo) {
    return console.debug('Pendo is not currently installed', ...args)
  }
  return window.pendo
}

export default {
  state: {
    loaded: false,
  },
  getters: {},
  mutations: {
    [mutations.PENDO_LOADED](state, loaded) {
      state.loaded = loaded
    },
  },
  actions: {
    async [actions.PENDO_BOOT]({ state, rootGetters, commit }, user) {
      if (state.loaded) {
        return
      }

      const config = rootGetters[getters.AUTH_GET_USER_CONFIG]

      if (!config.PENDO_API_KEY) {
        return
      }

      await loadPendo(config.PENDO_API_KEY)

      const jwt = rootGetters[getters.PAGES_SESSION_DATA]

      if (jwt && jwt.PENDO_INITIALIZATION_CONFIG) {
        try {
          const parseConfig = JSON.parse(jwt.PENDO_INITIALIZATION_CONFIG) || {}

          Pendo().initialize(parseConfig)
        } catch (e) {
          console.error('error parsing session config', e, jwt)
        }
      }
    },
  },
}
