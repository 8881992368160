export const NEW_VISITORS: string = 'newVisitors'
export const RETURNING_VISITORS: string = 'returningVisitors'
export const DAY: string = 'day'
export const WEEK: string = 'week'
export const MONTH: string = 'month'

export const EQUALLY: string = 'equally'
export const LESS_THAN: string = 'lessThan'
export const MORE_THAN: string = 'moreThan'
export const BETWEEN: string = 'between'

export const SHOW_TO: string = 'showTo'
export const DONT_SHOW: string = 'dontShow'
export const REFERRAL_URL: string = 'referralUrl'
export const REFERRAL_QUERY: string = 'referralQuery'
export const DIRECT: string = 'direct'
export const ORGANIC: string = 'organic'
export const PAID: string = 'paid'
export const SOCIAL: string = 'social'
export const EMAIL: string = 'email'
export const ALL_REFERRALS: string = 'all'

export const STARTS_WITH: string = 'startsWith'
export const ENDS_WITH: string = 'endsWith'
export const CONTAINS: string = 'contains'
export const MATCH: string = 'match'

export const TIME_FRAME: string = 'timeFrame'
export const PAGE_SCROLL: string = 'pageScroll'
export const EXIT_INTENT: string = 'exitIntent'
export const MOUSE_POINTER: string = 'mousePointer'
export const EVENT: string = 'event'

export const ON_EXIT_SITE: string = 'onExitSite'
export const CAMPAIGN_BETWEEN_TIMEFRAME: string = 'campaignBetweenTimeframe'
export const ON_SCROLL_TO_TOP: string = 'onScrollToTop'
export const ON_BACK_BUTTON_CLICKED: string = 'onBackButtonClicked'

export const SECONDS: string = 'seconds'
export const MINUTES: string = 'minutes'
export const CLICK: string = 'click'

export const HOVER: string = 'hover'
export const OUT: string = 'out'
export const PIXEL: string = 'px'
export const PERCENTAGE: string = '%'

export const DESKTOP: string = 'desktop'
export const MOBILE: string = 'phone'

export const DATE_AND_TIME_BASED: string = 'dateAndTimeBased'
export const URGENCY_BASED: string = 'urgencyBased'


// Builder Form Integrations constants
export const AUTH_SUCCESS = 'authSuccess'
export const AUTHENTICATE = 'authenticate'
export const AUTH_INIT = 'authInit'
export const INTEGRATIONS = 'integrations'
export const INTEGRATION_COMPLETE = 'integrationComplete'
export const INTEGRATION_FINISHED = 'integrationFinished'
export const CREATE_NEW_INTEGRATION = 'createNewIntegration'
export const USE_EXISTING_SHEET = 'useExistingSheet'
export const EXIT_POST_MESSAGE = 'EXIT_POST_MESSAGE'


export const COUNTRIES_TIMEZONES_DATA: Array<{utcOffset: string, cityNameWithUtcOffset: string}> = [
    { utcOffset: '-12:00', cityNameWithUtcOffset: '(GMT -12:00) Eniwetok Kwajalein' },
    { utcOffset: '-11:00', cityNameWithUtcOffset: '(GMT -11:00) Midway Island, Samoa' },
    { utcOffset: '-10:00', cityNameWithUtcOffset: '(GMT -10:00) Hawaii' },
    { utcOffset: '-09:50', cityNameWithUtcOffset: '(GMT -9:30) Taiohae' },
    { utcOffset: '-09:00', cityNameWithUtcOffset: '(GMT -9:00) Alaska' },
    { utcOffset: '-08:00', cityNameWithUtcOffset: '(GMT -8:00) Pacific Time [US &amp; Canada]' },
    { utcOffset: '-07:00', cityNameWithUtcOffset: '(GMT -7:00) Mountain Time [US &amp; Canada]' },
    { utcOffset: '-06:00', cityNameWithUtcOffset: '(GMT -6:00) Central Time [US &amp; Canada], Mexico City' },
    { utcOffset: '-05:00', cityNameWithUtcOffset: '(GMT -5:00) Eastern Time [US &amp; Canada], Bogota, Lima' },
    { utcOffset: '-04:50', cityNameWithUtcOffset: '(GMT -4:30) Caracas' },
    { utcOffset: '-04:00', cityNameWithUtcOffset: '(GMT -4:00) Atlantic Time [Canada], Caracas, La Paz' },
    { utcOffset: '-03:50', cityNameWithUtcOffset: '(GMT -3:30) Newfoundland' },
    { utcOffset: '-03:00', cityNameWithUtcOffset: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
    { utcOffset: '-02:00', cityNameWithUtcOffset: '(GMT -2:00) Mid-Atlantic' },
    { utcOffset: '-01:00', cityNameWithUtcOffset: '(GMT -1:00) Azores, Cape Verde Islands' },
    { utcOffset: '+00:00', cityNameWithUtcOffset: '(GMT) Western Europe Time, London, Lisbon, Casablanca' },
    { utcOffset: '+01:00', cityNameWithUtcOffset: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris' },
    { utcOffset: '+02:00', cityNameWithUtcOffset: '(GMT +2:00) Kaliningrad, South Africa' },
    { utcOffset: '+03:00', cityNameWithUtcOffset: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg' },
    { utcOffset: '+03:50', cityNameWithUtcOffset: '(GMT +3:30) Tehran' },
    { utcOffset: '+04:00', cityNameWithUtcOffset: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
    { utcOffset: '+04:50', cityNameWithUtcOffset: '(GMT +4:30) Kabul' },
    { utcOffset: '+05:00', cityNameWithUtcOffset: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent' },
    { utcOffset: '+05:50', cityNameWithUtcOffset: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi' },
    { utcOffset: '+05:75', cityNameWithUtcOffset: '(GMT +5:45) Kathmandu, Pokhar' },
    { utcOffset: '+06:00', cityNameWithUtcOffset: '(GMT +6:00) Almaty, Dhaka, Colombo' },
    { utcOffset: '+06:50', cityNameWithUtcOffset: '(GMT +6:30) Yangon, Mandalay' },
    { utcOffset: '+07:00', cityNameWithUtcOffset: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
    { utcOffset: '+08:00', cityNameWithUtcOffset: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong' },
    { utcOffset: '+08:75', cityNameWithUtcOffset: '(GMT +8:45) Eucla' },
    { utcOffset: '+09:00', cityNameWithUtcOffset: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
    { utcOffset: '+09:50', cityNameWithUtcOffset: '(GMT +9:30) Adelaide, Darwin' },
    { utcOffset: '+10:00', cityNameWithUtcOffset: '(GMT +10:00) Eastern Australia, Guam, Vladivostok' },
    { utcOffset: '+10:50', cityNameWithUtcOffset: '(GMT +10:30) Lord Howe Island' },
    { utcOffset: '+11:00', cityNameWithUtcOffset: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia' },
    { utcOffset: '+11:50', cityNameWithUtcOffset: '(GMT +11:30) Norfolk Island' },
    { utcOffset: '+12:00', cityNameWithUtcOffset: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka' },
    { utcOffset: '+12:75', cityNameWithUtcOffset: '(GMT +12:45) Chatham Islands' },
    { utcOffset: '+13:00', cityNameWithUtcOffset: '(GMT +13:00) Apia, Nukualofa' },
    { utcOffset: '+14:00', cityNameWithUtcOffset: '(GMT +14:00) Line Islands, Tokelau' }
]

export const STANDARD = 'Standard'
export const BOOKMARKS = 'Bookmarks'
export const USER_OWNED = 'user-owned'

export const BRAND_MODE_LIST = 'brand-list'
export const BRAND_MODE_CREATE = 'brand-create'
