
  <div>
    <h1>Input</h1>
    <div class="w-100">
      <cvt-input
        v-for="(variant, index) in variants"
        :key="index"
        :mode="variant.mode"
        :type="variant.type"
        :input-type="variant.type"
        :label="variant.label"
        :disabled="variant.disabled"
        :required="variant.required"
        :input-size="variant.inputSize"
        :validation-status="variant.validationStatus"
        :suffix-icon="variant.suffixIcon"
        :prefix-icon="variant.prefixIcon"
        :minlength="variant.minLength"
        :maxlength="variant.maxLength"
        :clearable="variant.clearable"
        :floating-label="variant.floatingLabel"
      />

      <span>Grouped Input</span>
      <cvt-input mode="LIGHT" type="url">
        <template #prefix>http://</template>
      </cvt-input>

      <cvt-input mode="LIGHT" type="url">
        <template #suffix>.com</template>
      </cvt-input>

      <cvt-input
v-model="value"
        type="search"
        mode="LIGHT"
        :fetch-suggestions="fetchSuggestions"
        label="Autocomplete"
      ></cvt-input>

      <cvt-input
v-model="customValue"
        type="search"
        mode="LIGHT"
        :fetch-suggestions="fetchCustomSuggestions"
        label="Custom Template"
      >
        <template slot="item" slot-scope="prop">
          <div class="value">{{prop.value}}</div>
          <span class="link">{{prop.link}}</span>
        </template>
      </cvt-input>
    </div>
  </div>
