import * as _ from 'lodash'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { mapGetters, mapState } from "vuex";
import * as getters from "../../../../builder/src/js/store/modules/getters";
import CvtButton from "../../molecules/Button";
import { styled } from "@egoist/vue-emotion";
import CvtSelect from '../../molecules/Select'
import { ContentPlaceholder } from "../../molecules/ContentPlaceholder";

Vue.use(VueI18n)

let textFontWeight, textFontSize, Spacing, FontSize, backgroundColor, Colors

const getSelect = () => styled(CvtSelect)`
  width: 100%;
  margin-bottom: 23px;

  > div {
    border-radius: 4px;
  }

  div > div {
    border-radius: 0;
    border: 0;
  }
`
const getLoadingPlaceholder = () => styled(ContentPlaceholder) `
  height: 48px;
  width: 100%;
  left: 0;
  border: 2px;
`

export const LoadingComponent = ({props}) => {
  const LoadingPlaceholder = getLoadingPlaceholder()
  const {hideButton} = props

  return (
    <div class='my-2' style="display: flex;gap: 30px; align-items: center;">
      <div class='position-relative' style="flex:1; height: 48px">
        <LoadingPlaceholder/>
      </div>

      <div class='position-relative' style="flex:1; height: 48px">
        <LoadingPlaceholder/>
      </div>

      {hideButton !== true && (
          <CvtButton
          icon={'minus'}
          outlined={true}
          specialPadding="5px"
          color="danger"
          shape={'circle'}
          size={'10px'}
          disabled={true}
          onClick={() => {}}
        ></CvtButton>
        )
      }
    </div>
  )
}


export const FieldMapper = {
  name: 'FieldMapper',
  components: {
  },
  data() {
    return {

    }
  },
  props: {
    providerUnMappedFields: {type: Array, required: true},
    standardUnMappedFields: {type: Array, required: true},

    mappedFields: {type: Array, required: true},
    standardFieldLabels: {type: Object, required: true},
    providerFieldLabels: {type: Object, required: true},
    provider: {type: String, required: true},
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
    }),
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      globalTheme: ({ globalTheme }) => globalTheme,
    }),
  },
  created() {
    textFontSize = this.textFontSize
    textFontWeight = this.textFontWeight
    Spacing = this.Spacing
    FontSize = this.FontSize
    backgroundColor = this.backgroundColor
    Colors = this.Colors
  },
  mounted() {
  },
  methods: {

  },
  render: function (h) {
    const Select = getSelect()

    const Option = ({props}) => {
      return (
        <option id={props.index} value={props.option}>
          {props.title || props.option}
        </option>
      )
    }

    const getProviderOptions = (defaultValue) => {
      const options = [...this.providerUnMappedFields]

      if (!options.includes(defaultValue) && defaultValue !== "") {
        options.push(defaultValue)
      }
      return options.map(
        (option, index) => <Option option={option} index={index} title={this.providerFieldLabels[option]}/>
      )
    }

    const getStandardOptions = (defaultValue) => {
      const options = [...this.standardUnMappedFields]

      if (!options.includes(defaultValue) && defaultValue !== "") {
        options.push(defaultValue)
      }
      return options.map(
        (option, index) => <Option option={option} index={index} title={this.standardFieldLabels[option]}/>
      )
    }

    return (
      <div>
        {this.mappedFields.map((map, idx) => {
          return (
            <div className={'my-3'} style="display: flex;gap: 30px; align-items: center;">
              <div style="flex:1;">
                <div>
                  <Select placeholder="Standard Field" defaultValue={map.id} value={map.id}
                          onInput={val => this.$emit("updateStandardField", map, val)}>
                    {getStandardOptions(map.id)}
                  </Select>
                </div>
              </div>

              <div style="flex:1;">
                <div>
                  <Select placeholder={`${this.provider} Field`}
                          defaultValue={map.name}
                          value={map.name}
                          onInput={val => this.$emit("updateProviderField", map, val)}>
                    {getProviderOptions(map.name)}
                  </Select>
                </div>
              </div>

              <CvtButton
                icon={'minus'}
                outlined={true}
                disabled={map.name === "" || map.id === ""}
                specialPadding="5px"
                color="danger"
                shape={'circle'}
                size={'10px'}
                onClick={() => this.$emit("removeMapping", map)}
              ></CvtButton>
            </div>
          )
        })}
      </div>
    )
  },
}
