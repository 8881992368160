import Vue from '../../lib/utils/vue'
import { styled, VueEmotion } from '@egoist/vue-emotion'
import * as getters from '../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'


Vue.use(VueEmotion)


let Spacing, Radii, Border, Colors, textLineHeight, BorderStyle, textFontWeight, borderColor, backgroundColor, textFontSize, textColor, FontFamily;

const getSiteStatusLabel = () => styled('div')`
  display: flex;
  align-items:center;
  justify-content: center;

  font-family: ${FontFamily.DEFAULT};
  font-style: normal;
  padding: ${Spacing.SPACING_4}px;
  border-radius: ${Radii.RADIUS_PX_4}px;
  ${textFontWeight('REGULAR')}; 
  ${textFontSize('xs')};
  ${textLineHeight('xl')};
  
  ${(props) => {

    let styles = ``;

    styles += `width: ${props.width}px;`
    styles += `height: ${props.height}px;`

    if (props.status && props.status === 'published') 
    {
      styles += `${backgroundColor({ themeing: 'success' }, 100)}`;
      styles += `${textColor({ themeing: 'success' }, 300)};`
      styles += `border-width: ${Border.SIZE_1}px;`
      styles += `border-style: ${BorderStyle.SOLID};`
      styles += `${borderColor({ themeing: 'success' }, 300)};`
    }
    else if (props.usage === 'popup' && (props.status && props.status === 'unpublished'))
    {
      styles += `${backgroundColor({ themeing: 'warning' }, 100)}`;
      styles += `${textColor({ themeing: 'warning' }, 800)};`
      styles += `border-width: ${Border.SIZE_1}px;`
      styles += `border: 1px solid ${Colors.WARNING[300]};`
    }
    else 
    {
      styles += `${backgroundColor({ themeing: 'danger' }, 100)}`;
      styles += `${textColor({ themeing: 'danger' }, 500)};`
      styles += `border-width: ${Border.SIZE_1}px;`
      styles += `border-style: ${BorderStyle.SOLID};`
      styles += `${borderColor({ themeing: 'danger' }, 500)};`
    }

    return styles;
  }}
`

const getTextContainer = () => styled('div')``


export default {
  name: 'SiteStatusLabel',
  props: {
    status: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: 87
    },
    height: {
      type: Number,
      required: false,
      default: 26
    },
    usage: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() { return {} },

  methods: {},

  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    Colors = this.Colors
    BorderStyle = this.BorderStyle
    FontFamily = this.FontFamily

    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    textColor = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
  },

  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
    }),

    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      BorderStyle: ({ globalTheme }) => globalTheme.BorderStyle,
    })
  },

  render: function (h) {

    const SiteStatusLabel = getSiteStatusLabel();
    const TextContainer = getTextContainer();

    return (
      <SiteStatusLabel
        status={this.status}
        width={this.width}
        height={this.height}
        usage={this.usage}
      >
        <TextContainer>
          {
            this.status === 'published' 
              ? this.$t("element.revision.publish.label")
              : this.$t("site.status.unpublished.label")
          }
        </TextContainer>
      </SiteStatusLabel>
    )
  }
}
