
<div class="publish-container-wrap" :style="titleStyle">
  <div
    v-if="isConnectSite"
    class="connect-site-wrap"
    data-id-connect-element="connect-site"
  >
    <cvt-card class="connect-site-card">
      <h3 class="cs-heading" data-id-connect-text="connect-your-site">
        Connect your site
      </h3>

      <form class="cs-btn-input-wrap" @submit.prevent="connectAction">
        <div class="cs-input-wrap">
          <label for="cs-connection-url-input" class="cs-input-label"
            >Enter Website URL:</label
          >
          <input
            id="cs-connection-url-input"
            :class="`cs-connection-url-input ${
              isError ? 'cs-input-invalid' : ''
            }`"
            type="text"
            required
            :value="input"
            :placeholder="'https://'"
            @input="onChangeUrlInput"
          />
          <div class="cs-icon-note-wrap">
            <cvt-icon
              :class="`cs-input-icon ${isError && 'cs-invalid'}`"
              icon="cvt-icon-circle-info"
            />
            <small
              :class="isError ? 'cs-input-note cs-invalid' : 'cs-input-note'"
            >
              Please provide the root URL of your website where you wish to
              install the pop-up
            </small>
          </div>
        </div>

        <div class="cs-connect-btn-wrap">
          <cvt-button
            :class="`cs-connect-btn ${connectLoading && 'cs-loading'}`"
            :text="connectLoading ? 'Connecting...' : 'Connect'"
            :size="'lg'"
            :icon="!connectLoading ? 'fal fa-link' : ''"
            :color="popupConnectBtnConfig.color"
            :special-style="popupConnectBtnConfig.specialStyle"
            :outlined="true"
            :prepend="false"
            :disabled="loading"
            type="submit"
          />
        </div>
      </form>
    </cvt-card>
  </div>

  <div
    v-if="isInstallation"
    class="connect-script-wrap"
    data-id-connect-element="copy-code"
  >
    <cvt-card class="connect-script-card">
      <cvt-collapse :accordion="true" :prepend-chevron="false">
        <cvt-collapse-item
          title="Manual Install"
          name="Manual Install"
          :initial-active="true"
          header-class="cvt-collapse-item-header"
        >
          <div class="csr-step-one-wrap">
            <p class="csr-code-heading">
              Insert code into every page of your site, before
              <span class="csr-code-heading-span">&lt;body&gt;</span>
              tag
            </p>

            <div class="csr-code-text-wrap">
              <pre ref="scriptCodeElement" class="csr-code-text">{{
                script
              }}</pre>
            </div>

            <cvt-button
              class="code-copied-btn"
              :text="copiedScript ? 'Copied' : 'Copy code'"
              :size="'md'"
              :icon="copiedScript ? 'fas fa-check-double' : 'fas fa-copy'"
              :color="copySiteCodeBtnConfig.color"
              :special-style="copySiteCodeBtnConfig.specialStyle"
              :outlined="true"
              :prepend="false"
              @click="copyTextToClipboard"
            />
          </div>
        </cvt-collapse-item>
        <cvt-collapse-item
          v-if="showGoogleTagManager"
          title="Google Tag Manager"
          name="Google Tag Manager"
          header-class="cvt-collapse-item-header"
        >
          <div class="csr-step-two-wrap">
            <ol class="csr-ol-wrap">
              <li class="my-2">
                {{ $t('launch.sharing.debug.li.bullet_one') }}
              </li>
              <li class="my-2">
                {{ $t('launch.sharing.debug.li.bullet_two') }}
              </li>
              <li class="my-2">
                {{ $t('launch.sharing.debug.li.bullet_three') }}
              </li>
            </ol>
            <a class="csr-ol-link" href="#">Connect via Google Tag Manager</a>
          </div>
        </cvt-collapse-item>
      </cvt-collapse>
    </cvt-card>
    <cvt-button
      class="code-added-btn"
      :text="'I have added the code to my site'"
      :size="'md'"
      :icon="'cvt-icon-arrow-right-05'"
      :color="popupAddCodeToSiteBtnConfig.color"
      :special-style="popupAddCodeToSiteBtnConfig.specialStyle"
      :outlined="false"
      :prepend="false"
      @click="installationAction"
    />
  </div>

  <div v-if="isPublishing">
    <cvt-publish-card
      data-id-connect-element="publish-card"
      class="publish-card-wrap"
      :loading="loading"
      :show-thumbnail="showThumbnail"
      :published="popUpPublished"
      :disabled="setPublishButtonDisabled"
      :thumbnail="thumbnail"
      :color="popupPublishBtnConfig.color"
      :special-style="popupPublishBtnConfig.specialStyle"
      @publish="publishPopupHandle($event)"
      @republish="publishPopupHandle($event)"
    />

    <div class="publish-card-bottom-wrap">
      <cvt-button
        class="configure-root-btn"
        :text="'Configure root domain'"
        :size="'md'"
        :color="configurePopupBtnConfig.color"
        :special-style="configurePopupBtnConfig.specialStyle"
        :outlined="true"
        :prepend="false"
        @click="onConfigureRootDomain"
      />

      <cvt-button
        class="popup-code-btn"
        :text="'Get pop-up code'"
        :size="'md'"
        :color="configurePopupBtnConfig.color"
        :special-style="configurePopupBtnConfig.specialStyle"
        :outlined="true"
        :prepend="false"
        @click="onGetPopupCode"
      />
    </div>

    <div v-if="isAdmin" class="popup-launch-tabs-wrap">
      <cvt-tabs
        class="popup-launch-cvt-tabs mt-4"
        :keep-inactive-border="
          publishPageConfig.keepInactiveBorder
            ? publishPageConfig.keepInactiveBorder
            : false
        "
        :full-horizontal-border="
          publishPageConfig.fullHorizontalBorder
            ? publishPageConfig.fullHorizontalBorder
            : false
        "
        :smaller-tab-border="
          publishPageConfig.smallerTabBorder
            ? publishPageConfig.smallerTabBorder
            : false
        "
        :closeable="false"
        prefix-icon=""
        :in-active-border-color="
          publishPageConfig.inActiveBorderColor
            ? publishPageConfig.inActiveBorderColor
            : ''
        "
      >
        <cvt-tab title="Admin">
          <div class="row mt-4 d-flex justify-content-space-evenly">
            <div class="col-6 pb-4 d-flex justify-content-start">
              <categories></categories>
            </div>
            <div class="col-6 d-flex justify-content-start">
              <enable-marketplace
                :marketplace-enabled="popupSiteMarketPlaceEnabled"
                @switchToggle="
                  (payload) => popupSiteMarketplaceFlagChanged(payload)
                "
              />
            </div>
            <div class="col-12 px-0">
              <div class="col-6 pb-4 d-flex justify-content-start">
                <popup-thumbnail-url
                  :label="'Popup Thumbnail URL'"
                  :thumbnail-url="popupSiteAltThumbnailUrl"
                  @popupSiteThumbnailUrlChange="
                    (event) => popupSiteThumbnailUrlChange(event)
                  "
                />
              </div>
            </div>
            <div class="col-6 w-100 pb-4 d-flex justify-content-start">
              <cvt-button
                :mode="mode"
                :text="'Submit'"
                :color="''"
                :loading="false"
                :shadow="true"
                :disabled="false"
                @click="(event) => submitAdminTabSiteData(event)"
              />
            </div>
          </div>
        </cvt-tab>
      </cvt-tabs>
    </div>
  </div>
</div>
