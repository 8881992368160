<template>
  <div class="marketplace-wrapper">
    <page-preview
      :title="previewTitle"
      :url="previewUrl"
      :is-first="isFirstTemplate"
      :is-last="isLastTemplate"
      @prev="prevTemplate"
      @next="nextTemplate"
      @use-template="useTemplate(templateID)"
      @exit-clicked="goToCampaignBuilder"
    />
  </div>
</template>

<script>
import * as _ from 'lodash'
import * as getters from '../../store/modules/getters'
import * as actions from '../../store/modules/actions'
import { V1Sites, Marketplace } from '../../store/services/api'
import { mapGetters, mapActions, mapState } from 'vuex'
import PagePreview from '../PagePreview.vue'
import { cvtRouteNodes } from '@/js/utils/routes'

const sitesAPI = new V1Sites()
const marketplaceAPI = new Marketplace()

export default {
  components: {
    PagePreview
  },
  props: {
    templateID: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      locked: false,
      showOverlayOn: true,
      previewTemplate: null,
      enter: {
        duration: 800,
        opacity: 1,
        easing: 'easeInOutQuart'
      },
      leave: {
        duration: 800,
        opacity: 0,
        easing: 'easeInOutQuart'
      },
      beforeEnter (e) {
        e.style.opacity = 0
      }
    }
  },

  computed: {
    ...mapGetters({
      marketplaceTemplates: getters.MARKETPLACE_TEMPLATES,
      previewOpen: getters.PAGE_PREVIEW_OPEN,
      config: getters.AUTH_GET_USER_CONFIG
    }),
    ...mapState('route', {
      query: (state) => state.query
    }),
    previewUrl () {
      return _.get(this.previewTemplate, 'previewUrl')
    },
    previewTitle () {
      return _.get(this.previewTemplate, 'name')
    }
  },

  async created () {
    this.previewTemplate = await marketplaceAPI.templateByID(this.templateID).then(({ data }) => data)
  },

  mounted () {
    this.openPreviewWindow(true)
  },

  methods: {
    ...mapActions({
      createSiteFromTemplate: actions.MARKETPLACE_CREATE_SITE_FROM_TEMPLATE,
      openPreviewWindow: actions.PAGE_PREVIEW_TOGGLE,
      archiveSite: actions.SITE_ARCHIVE,
      goToCampaignBuilder: actions.SITE_CAMPAIGN_REDIRECT,
      loadMarketplaceTemplates: actions.MARKETPLACE_LOAD_TEMPLATES
    }),
    disableOverlay () {
      this.showOverlayOn = null
    },
    activateOverlay (templateId) {
      this.showOverlayOn = templateId
    },
    async useTemplate (templateId) {
      const { data } = await this.createSiteFromTemplate(templateId)

      this.$router.push({
        name: cvtRouteNodes.editorSetup,
        params: {
          siteId: data.id
        }
      })
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/utils";

  .marketplace-wrapper {
    //display: flex;
    //height: 100vh;
    //width:100vw;
    //overflow: hidden;
    display: grid;
    grid-template-columns: 280px 1fr;
  }

  .btn-preview-template {
    width: 223px;
    height: 46px;
    border-radius: 4px;
    box-shadow: 0 5px 6px 0 rgba(0,0,0,0.5);
    border: solid 1px rgba(0,0,0,0.1);
    background-color: #5d62ab;
    text-transform: uppercase;
  }

  .marketplace-sidebar {
    height: 100vh;
    position: fixed;

    display: flex;
    width: 250px;
    // padding: 4% 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 1;
  }

  .marketplace-sidebar__content {
    // margin-top:
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .marketplace-sidebar__title {
    margin-left: 15px;
  }

  .marketplace-categories {
    padding: 60px 0;
    width: 100%;
  }

  .marketplace-category {

    padding: 7px 25px;
    margin: 7px auto;
    transition: all 0.3s ease;

    &:hover, &--active {
      cursor: pointer;
      background: $white;
    }

    &:active {
      background: darken($light-gray, 20%);
    }

    &--active {
      color: $light-blue;
    }

  }

  .marketplace-template-groups {
    overflow: scroll;
    padding: 5%;
    flex: 1;
    position: relative;
    width: 1200px;
    max-width: 90%;
    display: block;
  }

  .marketplace-templates {
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    &--wrapper {
      position: relative;
      display: flex;
      margin: 0;
      flex: auto;
      // width: 100%;
    }
  }

  .marketplace-template {
    // width: 100%;
    margin-bottom: 60px;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    position: relative;
    max-height: 349px;
    min-height: 349px;
    height: 349px;
    width: 300px;
    border-radius: 4px;
    // background: $white;
    // box-shadow: 0 2px 4px rgba(0,0,0,.5);
    &--scratch {
      border: 2px dashed $other-gray;
      text-align: center;
      display: flex;
      align-items: center;
      top: 50px;
      height: 360px;
    }

    &__scratch_btn {
      border: 1px solid $inf-light-blue;
      color: $inf-light-blue;
      padding: 2rem;
      width: 75%;
      border-radius: 7px;
      text-transform: uppercase;
      background: transparent;
      transition: all 250ms ease;

      &:hover {
        background: $inf-light-blue;
        color: $white;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &__title {
      // padding: 10px;
      // font-size: 1.3rem;
      display: flex;
      &__text {
        width: auto;
      }
    }

    &__preview {
      position: relative;
      border-radius: 4px;
      // background-color: rgba(50, 49, 49, 0.73);
      box-shadow: 0 3px 7px rgba(0,0,0,.1);
      &:hover {
        transform: translateY(-2px);
        opacity: 1;
        background: rgba(0,0,0,.5);
        box-shadow: 0 22px 43px rgba(0, 0, 0, 0.2);
        cursor: pointer;
      }

      &__overlay {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background: hsla(0, 0%, 2%, 0.7);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .thumb-options {
    i {
      line-height: 36px;
    }
  }

  @media (min-width: 40em) {
    .marketplace-template {
      margin-right: 2%;
      margin-left: 2%;
      width: 46%;
    }
  }

  @media (min-width: 50em) {
    //FIXME
    //padding: 3% 0% 3% 15%;
  }

  @media (min-width: 60em) {
    .marketplace-template {
      width: 300px;
    }

    .marketplace-wrapper {
      //flex-direction: column;
      //height: 100%;
      //width: 100%;
      //overflow: hidden;
      display: grid;
      grid-template-columns: 280px 1fr;
    }

    // .marketplace-sidebar {
    //   width: 100%;
    // }

    .marketplace-template-groups {
      overflow-y: inherit;
      overflow-x: hidden;
      margin: 0 auto;
      width: 100%;
      padding: 3% 0% 3% 250px;
    }
    .c-template-controls.el-dropdown {
      z-index: 1;
    }
    .c-template-controls {
      .c-control-btn {
        border: solid 1px $off-gray;
        color: $off-gray;
        border-radius: 100px;
        padding: 5px 6px;
        line-height: .8;
        // margin-bottom: 10px;
      }
    }
  }

</style>
