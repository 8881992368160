
export default {
  components: {
  },
  props: {},
  data() {
    return {
      navigationItems : [
      {icon: 'heart', label: 'Branding', type:'route', route: 'branding'},
      {icon: 'user', label: 'Users', type:'route', route: 'users'},
      {icon: 'hexagon', label: 'Configurations', type:'route', route: 'configurations'},
      // {icon: 'cogs', label: 'Integrations', type:'route', route: 'integrations'},
      {icon: 'align-left', label: 'Documentation', type:'link', route: 'https://convrrt.stoplight.io/docs/documentation'},
      // {icon: 'border-all', label: 'Templates', type:'route', route: 'templates'},
      // {icon: 'pencil', label: 'Release Notes', type:'route', route: 'release-notes'},
      ],
    }
  },
  created() {},
  methods: {
    onChangeNavTab(item: { label: string; icon: string; route: string; type: string; }) {
      if(item.type === "route"){
        this.$router.push({name: `enterprise:dashboard:${item.route}`})
        return
      }
      window.open(item.route, '_blank')
    }
  },
}