<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    :id="vnodeId"
    ref="draftWrapperRef"
    :data-vnode-id="vnodeId"
    class="cvt-editor-text"
    :class="editorClasses"
    draggable="false"
    @mouseover="target"
    @click="enableEditor"
    @dblclick="resizeContent"
    @contextmenu="(event) => showContextMenuIfNotEditing(event)"
    @dragstart="dragstart"
    @dragend="dragend"
  >
    <drag-and-drop-zone :z-index="dropzoneZIndex" @dropped="addElementToSide" />

    <!-- main vdom node -->
    <animated-placeholder :loading="isLoading">
      <div
        class="draft-editor font-style-normalText"
        :class="stylesheet.classes.cvtListContainer"
      >
        <!-- Any tag modification will change how froala data is saved. See saveWithHtml method -->
        <div class="froala-editor" v-html="contentsHTML" />
      </div>
    </animated-placeholder>
    <template v-if="showActionButtonsOnClick && !isGridBuilder">
      <!--THis is only here because of freshwork in v1 gridbuilder,
      once they migrate to v2 or v3, it will become irrelevant-->
      <action-buttons
        v-if="selected"
        @duplicate="duplicateAndValidate"
        @remove="removeAndValidate"
      />
    </template>
  </div>
</template>

<script>
import FroalaEditor from 'froala-editor/js/froala_editor.min'
import * as _ from 'lodash'
import { mapGetters, mapMutations } from 'vuex'
import { rgbToHex } from '../../../lib/color-utils'
import { FONT_SIZES } from '../../../lib/font.js'
import * as getters from '../../../store/modules/getters'
import * as mutations from '../../../store/modules/mutations'
import FbVNode from '../../base/FbVNode.vue'
import AnimatedPlaceholder from '../../common/AnimatedPlaceholder.vue'

const paragraphFontStyles = [
  'font-style-title',
  'font-style-subtitle',
  'font-style-heading',
  'font-style-alternativeHeading',
  'font-style-lead',
  'font-style-normalText',
  'font-style-blockquote',
]

export default FbVNode.extend({
  components: {
    AnimatedPlaceholder
  },
  props: {
    contents: Object,
    contentsHTML: String,
    shadow: {
      type: String,
      default: 'shadow-none',
    },
    dc: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapGetters({
      range: getters.DRAFT_RANGE,
      rangeFormat: getters.DRAFT_RANGE_FORMAT,
      colors: getters.THEME_GET_COLORS,
      availableFonts: getters.THEME_AVAILABLE_FONTS,
      typography: getters.THEME_TYPOGRAPHY,
      theme: getters.THEME,
      advancedMenuOpen: getters.ADVANCED_MENU_OPEN,
      isStandalonePopupActive: getters.STANDALONE_POPUP_ACTIVE,
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    editorClasses() {
      return {
        ...this.classes,
        'w-100': this.isGridBuilder,
        [this.shadow]: true,
        [this.stylesheet.classes.shadowStyling]: true,
      }
    },
    getFroalaContainerId() {
      let froalaContainerId = '#froala-portal'

      if (this.isStandalonePopupActive) {
        froalaContainerId = '#froala-portal-sda-popup-builder'
      }

      return froalaContainerId
    },
    draftStyleClasses() {
      return {}
    },
    styles() {
      return {
        cvtListContainer: {
          '& .cvt-clist': {
            padding: '0 0 0 .8em',
            '& li': {
              paddingLeft: '.4rem',
              marginLeft: '1.25rem',
              '&::marker': {
                fontFamily: 'FontAwesome',
                display: 'inline-block',
              },
            },
            '&.check': {
              '& > li': {
                'list-style-type': '"\\f00c"',
              },
            },
            '&.square-check': {
              '& > li': {
                'list-style-type': '"\\f14a"',
              },
            },
            '&.circle-check': {
              '& > li': {
                'list-style-type': '"\\f058"',
              },
            },
            '&.calendar-check': {
              '& > li': {
                'list-style-type': '"\\f274"',
              },
            },
            '&.phone': {
              '& > li': {
                'list-style-type': '"\\f095"',
              },
            },
            '&.mobile': {
              '& > li': {
                'list-style-type': '"\\f10b"',
              },
            },
            '&.phone-square': {
              '& > li': {
                'list-style-type': '"\\f098"',
              },
            },
            '&.whatsapp': {
              '& > li': {
                'list-style-type': '"\\f232"',
              },
            },
            '&.heart': {
              '& > li': {
                'list-style-type': '"\\f004"',
              },
            },
            '&.star': {
              '& > li': {
                'list-style-type': '"\\f005"',
              },
            },
            '&.arrow-right': {
              '& > li': {
                'list-style-type': '"\\f061"',
              },
            },
            '&.chevron-right': {
              '& > li': {
                'list-style-type': '"\\f054"',
              },
            },
            '&.angle-right': {
              '& > li': {
                'list-style-type': '"\\f105"',
              },
            },
            '&.share': {
              '& > li': {
                'list-style-type': '"\\f064"',
              },
            },
            '&.long-arrow-right': {
              '& > li': {
                'list-style-type': '"\\f178"',
              },
            },
            '&.dot-circle': {
              '& > li': {
                'list-style-type': '"\\f192"',
              },
            },
            '&.hand-point-right': {
              '& > li': {
                'list-style-type': '"\\f0a4"',
              },
            },
            '&.lightbulb': {
              '& > li': {
                'list-style-type': '"\\f0eb"',
              },
            },
            '&.bolt': {
              '& > li': {
                'list-style-type': '"\\f0e7"',
              },
            },
            '&.times': {
              '& > li': {
                'list-style-type': '"\\f00d"',
              },
            },
            '&.times-circle': {
              '& > li': {
                'list-style-type': '"\\f057"',
              },
            },
            '&.book': {
              '& > li': {
                'list-style-type': '"\\f02d"',
              },
            },
            '&.quote-left': {
              '& > li': {
                'list-style-type': '"\\f10d"',
              },
            },
          },
        },
        shadowStyling: {
          '&.text-shadow-basic': {
            textShadow: '2px 3px 5px rgba(0,0,0,0.5)',
          },
          '&.text-shadow-hard': {
            textShadow: '5px 5px 0px rgba(0,0,0,0.2)',
          },
          '&.text-shadow-heavy': {
            textShadow:
              '0px 4px 3px rgba(0,0,0,0.4),0px 8px 13px rgba(0,0,0,0.1),0px 18px 23px rgba(0,0,0,0.1)',
          },
          '&.text-shadow-letterpress': {
            textShadow: '2px 2px 3px rgba(255,255,255,0.1)',
          },
        },
      }
    },
    getFroalaEditorId() {
      return this.config?.FROALA_EDITOR_ID
    },
  },
  data() {
    return {
      name: 'Draft',
      draftToolsVisible: false,
      isDirty: false,
      froala: null,
      editor: null,
      selectedSearchText: null,
      selectedSearchRange: null
    }
  },
  mounted() {
    this.init()
    this.$nextTick(() => {
      this.froala?.html?.set?.(this.contentsHTML)
    })
  },
  destroyed() {
    this.shutdown()
  },
  methods: {
    ...mapMutations({
      updateDraftRange: mutations.DRAFT_UPDATE_RANGE,
      setFroalaInstance: mutations.DRAFT_SET_FROALA_INSTANCE,
      updateDraftRangeFormat: mutations.DRAFT_UPDATE_RANGE_FORMAT,
      updateTypography: mutations.THEME_UPDATE_DATA,
    }),

    showContextMenuIfNotEditing(e) {
      // There is this strange issue which occurs when user is using old-builder and he right-clicks on text-element which
      // is being edited to open the context menu. Then sometimes accessing the hasFocus method of text-element(node) being edited throws error.
      // As per my initial oberservation, this is basically due to the plugin not behaving correctly. The optional-chaining is quick fix for it.
      if (!this.froala?.node?.hasFocus?.()) {
        e.stopPropagation()
      } else {
        this.showContextMenu(e)
      }
    },

    resizeContent() {
      if (this.isGridBuilder) {
        this.resize()
      }
    },

    getHexColors() {
      // Convert THEME_RGBA_COLOR to HEX format
      return Object.keys(this.colors).map((key) => {
        let r = this.colors[key]['color'][0]
        let g = this.colors[key]['color'][1]
        let b = this.colors[key]['color'][2]
        return rgbToHex(r, g, b)
      })
    },
    focusAndEditFroalaText() {
      // enables the froala-toolbar at top
      this.$refs.draftWrapperRef.click()

      // checks whether user has selecgted any text
      let isTextSelected = this.froala.selection?.get().toString() !== ''

      // if user has not selected any text in frola then we want to restore the cursor position
      if(!isTextSelected) {
        // sets the cursor position at the end of the text
        this.froala.selection.setAtEnd(this.froala.$el.get(0));
        this.froala.selection.restore();
      }

      // selects the text-properties in right-side-bar-panel
      this.selectSelfForHelp()
    },
    refreshFroalaButtons() {
      this.froala.button.bulkRefresh()
    },
    getFontFamily() {
      let fontFamily = {}
      this.availableFonts.forEach((font) => {
        fontFamily[`${font.familyName}, ${font.genericFamily}`] =
          font.familyName
      })
      return fontFamily
    },

    getFontFamilyName(font) {
      return font
        .split('-')
        .map((a) => a.charAt(0).toUpperCase() + a.slice(1))
        .join(' ')
    },

    getFontSizes() {
      return FONT_SIZES.map((fontSize) => {
        return fontSize.value
      })
    },

    init() {
      this.editor = this.$el.querySelector('.draft-editor')

      let hexColors = this.getHexColors()
      let fontFamily = this.getFontFamily()
      let fontSizes = this.getFontSizes()

      let styleFonts = {}
      let styleFontSize = {}

      Object.keys(this.typography).forEach((key) => {
        styleFonts[key] = this.typography[key]['font']
        styleFontSize[key] = this.typography[key]['size']
      })
      let moreMiscButtons = [
        'customParagraphStyle',
        'textColor',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'clearFormatting',
        'alignLeft',
        'alignCenter',
        'alignRight',
        'insertLink',
        'undo',
        'redo',
        'lineHeight',
        'fontSize',
        'fontWeight',
        'backgroundColor',
        'formatOLSimple',
        'customList',
      ]
      if (this.config.CHATGPT_ACTION_ENABLED) {
        moreMiscButtons.splice(1, 0, 'chatGPT')
      }

      let froalaConfig = {
        key: this.getFroalaEditorId,
        toolbarInline: true,
        placeholderText: this.$t(
          'editor.section.grid_item.edit.placeholder_text',
        ),
        toolbarContainer: this.getFroalaContainerId,
        charCounterCount: true,
        toolbarVisibleWithoutSelection: true,
        initOnClick: false,
        keepFormatOnDelete: true,
        pastePlain: true,
        pasteDeniedTags: ['div'],
        pasteDeniedAttrs: ['class', 'style'],
        paragraphMultipleStyles: false,
        toolbarSticky: true,
        toolbarButtons: {
          moreMisc: {
            buttons: moreMiscButtons,
            buttonsVisible: 20,
          },
        },
        colorsText: [...hexColors, 'REMOVE'],
        colorsBackground: [...hexColors, 'REMOVE'],
        fontFamilySelection: true,
        fontFamily: fontFamily,
        fontSize: fontSizes,
        lineHeights: {
          Default: '',
          Single: '1',
          1.15: '1.15',
          1.5: '1.5',
          Double: '2',
        },
        linkEditButtons: [
          'linkOpen',
          'linkEdit',
          'linkRemove',
          'textColor',
          'backgroundColor',
        ],
        linkAutoPrefix: 'https://',
        saveInterval: 2500,
        // Set the save param.
        saveParam: 'content',
        // Set the save URL.
        saveURL: '#',
        // HTTP request type.
        saveMethod: 'POST',
        // Additional save params.
        saveParams: {id: 'my_editor'},
        events: {
          'save.before': (html) => {
            this.saveWithHtml(html)
            return false
          },
          contentChanged: () => {
            if (this.isGridBuilder) {
              this.resize()
            }
            // this.autoSave()
          },
          'commands.after': function (cmd, param1, param2) {
            const isClearFormatting = cmd === 'clearFormatting'

            const isCustomTextColor = cmd === 'customTextColor'
            const isTextColor = cmd === 'textColor'
            const isBackgroundColor = cmd === 'backgroundColor'
            const isCustomBackgroundColor = cmd === 'customBackgroundColor'

            if (isClearFormatting) {
              let paragraphString = this.selection.element()
              for (let style of paragraphFontStyles) {
                if (style === 'font-style-normalText') {
                  if (!this?.node?.hasClass(paragraphString, style)) {
                    this.paragraphStyle.apply(style)
                  }
                } else {
                  if (this?.node?.hasClass(paragraphString, style)) {
                    // check if it contains it, then remove
                    this.paragraphStyle.apply(style)
                  }
                }
              }
            }

            if (isTextColor || isBackgroundColor) {
              // For some reason selected text is only retained here, so we commit it to state
              const selection = this.selection.get();
              if (selection.rangeCount > 0) {
                this.selectedSearchRange = selection.getRangeAt(0);
                this.selectedSearchText = selection.getRangeAt(0).toString();
              }
            }

            if (isCustomBackgroundColor) {
              // For some reason selected text is lost here

              if (this.selectedSearchText && this.selectedSearchRange) {
                const textElement = this.selection.element();
                const backgroundColor = window.getComputedStyle(textElement).backgroundColor

                // span with the selected color
                const span = document.createElement('span');
                span.style.backgroundColor = backgroundColor;
                span.textContent = this.selectedSearchText;

                // selected text with the span
                this.selectedSearchRange.deleteContents();
                this.selectedSearchRange.insertNode(span);

                // Clear the selection
                this.selection.get().removeAllRanges();
                this.selectedSearchText = null
                this.selectedSearchRange = null
              }

            }

            if (isCustomTextColor) {
              // For some reason selected text is lost here

              if (this.selectedSearchText && this.selectedSearchRange) {
                const textElement = this.selection.element();
                const textColor = window.getComputedStyle(textElement).color

                // span with the selected color
                const span = document.createElement('span');
                span.style.color = textColor;
                span.textContent = this.selectedSearchText;

                // selected text with the span
                this.selectedSearchRange.deleteContents();
                this.selectedSearchRange.insertNode(span);

                // Clear the selection
                this.selection.get().removeAllRanges();
                this.selectedSearchText = null
                this.selectedSearchRange = null
              }
            }
          },
        },
      }

      this.froala = new FroalaEditor(this.editor, froalaConfig)
    },
    shutdown() {
      if (this.editor) {
        this.froala.destroy()
      }
    },
    enableEditor(e) {
      if (this.isGridBuilder && !this.advancedMenuOpen) {
        return
      }

      if (!this.selected) {
        this.select(e)
        if (this.froala && this.froala.edit) {
          this.froala.edit.on()
        }
        if (this.froala && this.froala.events) {
          this.froala.events.focus()
          this.froala.events.enableBlur()
        }
      }
    },
    getHtml() {
      return this.froala.root.innerHTML
    },
    autoSave: _.debounce(
      async function () {
        this.froala.selection?.save()
        this.isDirty = true
        await this.save()
        this.isDirty = false
        this.froala.selection?.restore()
      },
      2000,
      { maxWait: 5000 },
    ),
    saveAction(htmlStr) {
      return Promise.all([this.updateProp('contentsHTML', htmlStr)])
    },
    saveWithHtml(html) {
      // prepares html to be saved to firebase
      let htmlStr = html
      if (htmlStr.search('<div class="froala-editor">') >= 0) {
        htmlStr = htmlStr.replace('<div class="froala-editor">', '') // replaces the first occurance
        htmlStr = htmlStr.replace(/<\/div>$/, '') // replaces the last </div> tag in the HTML string
      }

      // this makes sure no empty string is saved in firebase which makes froala element non-editable
      if (htmlStr === '') {
        if (this.contentsHTML !== '') {
          htmlStr = this.contentsHTML
        } else {
          htmlStr = '<br>'
        }
      }
      return this.saveAction(htmlStr)
    },
    save() {
      if (this.froala && this.froala.html) {
        let htmlStr = this.froala.html.get(true)

        // old code
        // htmlStr = htmlStr.split('<div class="froala-editor">').join('');
        // htmlStr = htmlStr.split('</div>').join('');

        // new code
        // if this line exists ('<div class="froala-editor">'), then and only then replace this and also its closing div.
        // previously, it was removing the closing div even if '<div class="froala-editor">' does not exist
        // that is why, it was causing the HTML nesting and text-compression issue
        if (htmlStr.search('<div class="froala-editor">') >= 0) {
          htmlStr = htmlStr.replace('<div class="froala-editor">', '') // replaces the first occurance
          htmlStr = htmlStr.replace(/<\/div>$/, '') // replaces the last </div> tag in the HTML string
        }

        // this makes sure no empty string is saved in firebase which makes froala element non-editable
        if (htmlStr === '') {
          if (this.contentsHTML !== '') {
            htmlStr = this.contentsHTML
          } else {
            htmlStr = '<br>'
          }
        }
        // htmlStr = htmlStr === '' ? '<br>' : htmlStr;
        return this.saveAction(htmlStr)
      } else {
        return Promise.all([])
      }
    },
  },
  watch: {
    selected(selectedNow, previouslySelected) {
      if (previouslySelected && !selectedNow && this.froala) {
        this.save()
        // this.froala.edit.off()
        // this.froala.disable()
        // this.froala.blur()
        // this.froala.events.disableBlur()
      }
    },
    contentsHTML: {
      handler(contentsHTML, oldContentsHTML) {
        if (
          _.isEqual(contentsHTML, oldContentsHTML) === false &&
          !this.isGridBuilder
        ) {
          // this.froala.html.set(contentsHTML)
        }
      },
      deep: true,
    },
    colors: function (val) {
      this.froala.destroy()
      this.init()
    },
  },
})
</script>

<style lang="scss">
@import '@/scss/utils';
.draft-editor {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-line;
}
.cvt-editor-text {
  position: relative;
}

.froala-editor {
  min-width: 50px;
  padding: 0;
  overflow-y: visible;
  p {
    margin-bottom: 0;
    min-height: 50px;
  }
}

.oldbuilder {
  &.desktop-mode {
    &.layout-style-1 {
      .froala-portal {
        .fr-toolbar {
          top: 70px !important;
        }
      }
    }

    &.layout-style-2 {
      .froala-portal {
        .fr-toolbar {
          top: 50px !important;
        }
      }
    }
  }

  &.mobile-mode {
    &.layout-style-1 {
      .froala-portal {
        .fr-toolbar {
          top: 70px !important;
        }
      }
    }

    &.layout-style-2 {
      .froala-portal {
        .fr-toolbar {
          top: 50px !important;
        }
      }
    }
  }
}

.gridbuilder {
  &.desktop-mode {
    // &.layout-style-1 {
    //   .froala-portal {
    //     .fr-toolbar {
    //       top: 70px !important;
    //     }
    //   }
    // }

    &.layout-style-2 {
      .froala-portal {
        .fr-toolbar {
          top: 50px !important;
        }
      }
    }
  }

  &.mobile-mode {
    // &.layout-style-1 {
    //   .froala-portal {
    //     .fr-toolbar {
    //       top: 70px !important;
    //     }
    //   }
    // }

    &.layout-style-2 {
      .froala-portal {
        .fr-toolbar {
          top: 70px !important;
        }
      }
    }
  }
}

.slide-engine-wrapper {
  .fr-toolbar {
    top: 175px;
  }
}

#froala-portal,
#froala-portal-sda-popup-builder {
  .fr-toolbar {
    display: block !important;
    position: absolute;
    border-radius: 10px !important;
    z-index: 21;

    bottom: unset !important;
    left: unset !important;
    right: unset !important;

    margin-left: auto !important;
    margin-right: auto !important;
  }

  .fr-toolbar.fr-top {
    border-bottom: 1px solid rgb(204, 204, 204) !important;
  }

  .fr-expanded {
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }

  .fr-toolbar .fr-command.fr-btn span {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  }

  .admin-description-wrapper {
    .fr-toolbar {
      position: unset !important;
      width: unset !important;
      border-bottom-right-radius: unset !important;
      border-bottom-left-radius: unset !important;
    }
  }

  .fr-toolbar
  .fr-dropdown-menu
  .fr-dropdown-wrapper
  .fr-dropdown-content
  ul.fr-dropdown-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
    column-gap: 8px;
    margin: 5px 10px !important;

    li {
      display: inline-block;
      padding: 0px 0px !important;
      border: 1px solid #f1f1f1;
      border-radius: 5px;
      min-width: 45px !important;
      display: flex;
      justify-content: center;

      a {
        font-family: 'Fontawesome';
        padding: 5px !important;
      }
    }
  }
}
</style>
