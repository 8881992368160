
<div identity="c-card-options-wrap">
  <cvt-collapse
    :accordion="true"
    :theme="mode === 'LIGHT' ? 'light' : 'dark'"
    :prepend-chevron="prependChevron"
  >

    <div v-if="showOption('desktop')" style="padding: 16px;">
      <template-options :mode="mode" />
    </div>

    <!-- Implement Icon options -->
    <cvt-collapse-item
      v-if="showOption('desktop')"
      title="Icon"
      name="Icon"
      :special-style="isSpecialStyle"
    >
      <icon-image-options  :mode="mode"/>
    </cvt-collapse-item>

    <!-- Headline Text -->
    <cvt-collapse-item :title="$t('elements.property.card.element.options.headline')" name="headline">
      <text-options
          :content="headLineHTML"
          :config="headlineConfig"
          :update-key="'enableHeadline'"
          :is-enabled="enableHeadline"
          :label="$t('elements.property.card.element.options.edit.headline')"
           @save="(val) => selectAction(val, 'headLineHTML')" />
    </cvt-collapse-item>

      <!-- Text -->
    <cvt-collapse-item :title="$t('editor.left_nav.element.text')" name="text">
      <text-options
        :content="textHTML"
        :config="textConfig"
        :update-key="'enableText'"
        :is-enabled="enableText"
        :label="$t('editor.section.grid_item.edit.btn_text')"
        @save="(val) => selectAction(val, 'textHTML')" />
    </cvt-collapse-item>

    <!-- Implement Style options -->
    <cvt-collapse-item :title="$t('elements.properties.local.style.label')" name="style">
      <style-option :mode="mode" />
    </cvt-collapse-item>

    <!-- Implement Background options -->
    <cvt-collapse-item :title="$t('elements.properties.background.color')" name="background">
      <background :mode="mode" />
    </cvt-collapse-item>

  </cvt-collapse>
</div>
