<template>
  <span v-show="visible" class="vnode__btn__group">
    <slot></slot>
  </span>
</template>
<script>
export default {
  props: {
    visible: Boolean
  },
  computed: {
  }
}
</script>

<style lang="scss">
  @import "@/scss/utils";

  .vnode__btn__group {
    position: absolute;
    top: -24px;
    right: -3px;
  }
</style>
