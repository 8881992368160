import {BackgroundShapeSVGObject} from "../BackgroundShapeSVGObject";

const CurveSVG =
    `
<svg data-name="Curves" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
    <path d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z" class="fill"></path>
</svg>`


export const CurveShape = new BackgroundShapeSVGObject({
    name: "Curves",
    canInvert: false,
    canFlip: true,
    svg: CurveSVG
})
