import * as getters from './getters';
import * as mutations from './mutations';

export default {
  state: {
    showModal: false
  },

  getters: {
    [getters.GET_MODAL] ({ showModal }) {
      return showModal;
    },
  },

  mutations: {
    [mutations.SET_MODAL](state, showModal) {
        state.showModal = showModal
      }
  }
}
