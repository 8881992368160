
<div class="remote-fields">
  <div v-if="loading" class="placeholder-container">
    <cvt-content-placeholder class="placeholder" />
  </div>
  <cvt-select
    v-else
    :mode="'dropdown'"
    :value="value"
    :label="placeholder"
    :background-mode="mode"
    @input="(value) => updateSelected(value)"
  >
    <option
      v-for="item in remoteData"
      :key="item['id']"
      :value="item['link']"
      v-text="item['title']"
    />
  </cvt-select>

</div>
