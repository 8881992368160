


export const truncateText = (text, maxLength) => {
  let text_ = text || ""
  return text_.length > maxLength ? `${text_.slice(0, maxLength)}...` : text_;
}



// export const nodes = [
//   {
//     label: 'Header Section',
//     content: {
//       type: 'content for Header Section',
//       description: "description for Header Section",
//       maxLength: "250"
//     },
//     children: [],
//   },
//   {
//     label: 'cta',
//     content: {
//       type: 'content for Cta',
//       description: "description for Cta",
//       maxLength: "180"
//     },
//     children: [
//       {
//         label: 'header',
//         content: {
//           type: 'content for header',
//           description: "description for header",
//           maxLength: "170"
//         },
//         children: [],
//       },
//     ],
//   },
//   {
//     label: 'Body Section',
//     children: [
//       {
//         label: 'title',
//         content: {
//           type: 'content for title',
//           description: "description for title",
//           maxLength: "200"
//         },
//         children: [],
//       },
//       {
//         label: 'body',
//         content: {
//           type: 'content for body',
//           description: "description for body",
//           maxLength: "200"
//         },
//         children: [],
//       },
//     ],
//   },
//   {
//     label: 'Body Section Two',
//     content: {
//       type: 'content for body section two',
//       description: "description for body section two",
//       maxLength: "190"
//     },
//     children: [],
//   },
//   {
//     label: 'Body Section Three',
//     children: [
//       {
//         label: 'title',
//         content: {
//           type: 'content for body section three',
//           description: "description for body section three",
//           maxLength: "120"
//         },
//         children: [],
//       },
//       {
//         label: 'body-text',
//         content: {
//           type: 'content for body-text',
//           description: "description for body-text",
//           maxLength: "120"
//         },
//         children: [],
//       },
//       {
//         label: 'body-text two',
//         content: {
//           type: 'content for body-text two',
//           description: "description for body-text two",
//           maxLength: "170"
//         },
//         children: [],
//       },
//       {
//         label: 'button',
//         children: [
//           {
//             label: 'button-text',
//             content: {
//               type: 'content for button-text',
//               description: "description for button-text",
//               maxLength: "150"
//             },
//             children: [],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     label: 'Footer',
//     content: {
//       type: 'content for Footer',
//       description: "description for footer",
//       maxLength: "150"
//     },
//     children: [],
//   },
// ]
