import Vue from '../../lib/utils/vue'
import VueI18n from 'vue-i18n'
import * as getters from '../../../builder/src/js/store/modules/getters'
import { styled, VueEmotion } from '@egoist/vue-emotion'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import CvtButton from '../molecules/Button'
import CvtTabs from '../molecules/tabs/Tabs'
import CvtTab from '../molecules/tabs/Tab'

Vue.use(VueEmotion)
Vue.use(VueI18n)

let FontFamily, Spacing, Radii, backgroundColor

const getFloatingMenuMain = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${Spacing.SPACING_48 - Spacing.SPACING_4}px;
  font-family: ${FontFamily.DEFAULT};
  border-radius: ${Radii.RADIUS_PX_8}px;
  padding: 0px ${Spacing.SPACING_4}px;
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')};
`

const getFloatingMenuSubInner = () => styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const getBackButtonWrap = () => styled('div')`
  display: flex;
  justify-content: start;
`

const getBackButton = () => styled(CvtButton)``
const getNextButtonWrap = () => styled('div')`
  display: flex;
  justify-content: end;
`

const getNextButton = () => styled(CvtButton)``

const getTabs = () => styled(CvtTabs)``

const getTab = () => styled(CvtTab)``

export const CvtFloatingBreadcrumb = {
  name: 'CvtFloatingBreadcrumb',
  data() {
    return {
      currentMenuRoute: this.activeMenu || this.menus[0]?.routeNames[0], // First menu will be selected when component is created/mounted
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    activeMenu: {
      type: String,
      default: undefined,
    },
    menus: {
      type: Array,
      default() {
        return []
      },
    },
    hideBackBtn: {
      type: Boolean,
      default: false,
    },
    hideNextBtn: {
      type: Boolean,
      default: false,
    },
    controlActiveTab: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    activeMenu(val) {
      this.currentMenuRoute = val
    },
  },
  computed: {
    ...mapGetters('globalTheme', {
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
    }),
    firstMenu() {
      return this.menus?.[0]
    },
    lastMenu() {
      return this.menus?.[this.menus?.length - 1]
    },
    backBtnDisabled() {
      return this.currentMenuRouteExist(
        this.firstMenu?.routeNames,
        this.currentMenuRoute,
      )
    },
    nextBtnDisabled() {
      return this.currentMenuRouteExist(
        this.lastMenu?.routeNames,
        this.currentMenuRoute,
      )
    },
  },
  methods: {
    toggleMenu(tabId) {
      if (this.controlActiveTab === false) {
        this.currentMenuRoute = tabId
      }
      let currentMenu = this.menus.find((menu) => {
        return this.currentMenuRouteExist(menu.routeNames, tabId)
      })
      this.$emit('menuChanged', currentMenu)
    },
    navigateMenu(nav) {
      let currIdx = -1
      // Get the index
      this.menus.forEach((menu, idx) => {
        let routeExist = this.currentMenuRouteExist(
          menu.routeNames,
          this.currentMenuRoute,
        )
        if (routeExist) {
          currIdx = idx
        }
      })
      // Set the new active item
      if (this.menus.length > 0) {
        let currentMenu = this.menus[currIdx + nav]
        if (this.controlActiveTab === false) {
          this.currentMenuRoute = currentMenu?.routeNames[0]
        }

        this.$emit('menuChanged', currentMenu)
      }
    },
    currentMenuRouteExist(routeNames, currentMenuRoute) {
      return routeNames.some((routeName) => routeName === currentMenuRoute)
    },
  },
  created() {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    backgroundColor = this.backgroundColor
  },
  render: function (h) {
    const FloatingMenuMain = getFloatingMenuMain()
    const FloatingMenuSubInner = getFloatingMenuSubInner()
    const BackButtonWrap = getBackButtonWrap()
    const BackButton = getBackButton()
    const NextButtonWrap = getNextButtonWrap()
    const NextButton = getNextButton()
    const Tabs = getTabs()
    const Tab = getTab()
    return (
      <FloatingMenuMain>
        <FloatingMenuSubInner>
          {!this.hideBackBtn && (
            <BackButtonWrap>
              <BackButton
                color={this.mode.toLowerCase()}
                size={'sm'}
                mode={this.mode}
                text={this.$t('elements.popups.menu.back')}
                icon={'chevron-left'}
                disabled={this.backBtnDisabled}
                disabledWithNoBg={this.backBtnDisabled}
                onClick={(event) => this.navigateMenu(-1)}
              ></BackButton>
            </BackButtonWrap>
          )}
          <Tabs
            isBreadCrumbs={true}
            prefixIcon={'cvt-icon-circle-check-mark'}
            dividedBy={'cvt-icon-arrow-right-07'}
            onToggle={(data) => this.toggleMenu(data)}
            specialMargin={'0px'}
            specialPadding={'2px 0px'}
            preIconSpecialMargin={'0px 5px 0px 0px'}
          >
            {this.menus &&
              this.menus.map((menu) => {
                return (
                  <Tab
                    key={menu.id}
                    id={menu.routeNames[0]}
                    title={menu.title}
                    disabled={menu.disabled}
                    stepCompleted={menu.completed}
                    active={this.currentMenuRouteExist(
                      menu.routeNames,
                      this.currentMenuRoute,
                    )}
                  />
                )
              })}
          </Tabs>
          {!this.hideNextBtn && (
            <NextButtonWrap>
              <NextButton
                color={this.mode.toLowerCase()}
                size={'sm'}
                mode={this.mode}
                text={this.$t('elements.popups.menu.next')}
                icon={'chevron-right'}
                prepend={false}
                disabled={this.nextBtnDisabled}
                disabledWithNoBg={this.nextBtnDisabled}
                onClick={(event) => this.navigateMenu(1)}
              ></NextButton>
            </NextButtonWrap>
          )}
        </FloatingMenuSubInner>
      </FloatingMenuMain>
    )
  },
}
