import Vue from 'vue'
import VueI18n from 'vue-i18n'
import * as getters from '../../../../../builder/src/js/store/modules/getters'
import * as constants from '../../../../../builder/src/js/common/constants/constants'
import { mapGetters, mapState } from 'vuex'
import CvtRadioGroup from '../../../molecules/radio-group/RadioGroup'
import { IntegrationReady } from '../ready'
import {
  getIntegrationTitle,
  getIntegrationUseSubtext,
  getIntegrationUseWrap,
  IntegrationDescription
} from "../description";
import { IntegrationLogoWrapper } from '../logo-wrapper'
import { IntegrationContainer } from '../container'
import { MailchimpSetup } from "./setup";
import IntegrationItemBase from "../IntegrationItemBase.vue";

Vue.use(VueI18n)

let textFontWeight, textFontSize, Spacing, FontSize, backgroundColor, Colors

export default IntegrationItemBase.extend({
  name: 'MailchimpIntegration',
  components: {
    CvtRadioGroup,
  },
  data() {
    return {
      showPipelineSelect: false,
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
    }),
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      globalTheme: ({ globalTheme }) => globalTheme,
    }),
  },
  created() {
    textFontSize = this.textFontSize
    textFontWeight = this.textFontWeight
    Spacing = this.Spacing
    FontSize = this.FontSize
    backgroundColor = this.backgroundColor
    Colors = this.Colors
  },
  mounted() {
    this.onScrollInToView()
  },
  methods: {
    onScrollInToView() {
      const container = document.getElementById('authWrap')
      container?.scrollIntoView()
    },
  },
  render: function (h) {
    const integrationTitle = this.selectedIntegration.title

    const IntegrationTitle = getIntegrationTitle()
    const IntegrationUseWrap = getIntegrationUseWrap()
    const IntegrationUseSubtext = getIntegrationUseSubtext()


    return (
      <IntegrationContainer>
        {this.showAuthScreen && (
          <IntegrationLogoWrapper>
            <IntegrationDescription provider="mailchimp" onStepChange={(step) => this.onChangeAuthStep(step, this.selected.id)}>
              <div style="padding: 45px 0px 45px 45px;">
                <IntegrationTitle>
                  <a href="https://www.hubspot.com/" target="_blank">Mailchimp</a> is a marketing automation platform designed to
                  help small businesses. Integrate Meclabs AI with Mailchimp to automatically
                  send new leads, customers, and subscribers to your mailing lists.
                </IntegrationTitle>
                <IntegrationUseWrap>
                  <p>Use this integration to</p>
                  <ul>
                    <li>
                      Add new subscribers to your email lists
                    </li>
                    <li>
                      Update existing subscriber data
                    </li>
                    <li>
                      Add contacts
                    </li>
                  </ul>
                </IntegrationUseWrap>
                <IntegrationUseSubtext>
                  Your Mailchimp contacts will automatically be updated with each form submission,
                  so you can spend less time manually transferring information and more time growing your business.
                </IntegrationUseSubtext>
                <IntegrationUseSubtext>
                  {/* Learn how to */}
                  Learn how to {' '}
                  <a href="https://integrations.meclabsai.com/mailchimp.html" target="_blank" style="text-decoration: underline;cursor: pointer;">
                    {' '}
                    integrate your form with {integrationTitle}.
                  </a>
                </IntegrationUseSubtext>
              </div>
            </IntegrationDescription>
          </IntegrationLogoWrapper>
        )}

        {this.integrationConfig.step === constants.AUTH_SUCCESS && this.isValidProvider && (
          <MailchimpSetup onCompleted={step => this.onChangeAuthStep(constants.INTEGRATION_COMPLETE)} onCancel={this.onCancel}/>
        )}
        {this.integrationConfig.step === constants.INTEGRATION_FINISHED && this.isValidProvider && (
          <MailchimpSetup onCancel={this.onCancel} onCompleted={step => this.onUpdate()}/>
        )}

        {this.integrationConfig.step === constants.INTEGRATION_COMPLETE && this.isValidProvider && (
          <IntegrationReady onFinished={this.onFinished}/>
        )}
      </IntegrationContainer>
    )
  },
})
