
<div class="alt-buttons">
  <cvt-button
    v-if="showDuplicate"
    color="light"
    size="xl"
    :outlined="true"
    :icon="iconConfig.propertiesSidebar.copy || 'copy'"
    @click="$emit('duplicate')"
  />
  <cvt-button
    v-if="showDelete"
    color="light"
    size="xl"
    :outlined="true"
    :icon="iconConfig.propertiesSidebar.remove || 'trash'"
    @click="$emit('remove')"
  />
</div>
