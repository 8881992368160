import * as getters from './getters'
import * as actions from './actions'

export default {
  state: {
    open: false,
    active: null,
    pageEngine: null,
    leftSidePage: false,
    sectionsOpen: false,
    showSideBarContext: {},
  },
  getters: {
    [getters.LEFT_SIDE_MENU_OPEN] ({ open }) {
      return open
    },
    [getters.LEFT_SIDE_MENU_ACTIVE_OPTION] ({ active }) {
      return active
    },
    [getters.LEFT_SIDE_PAGE_OPEN] ({ leftSidePage }) {
      return leftSidePage
    },
    [getters.LEFT_SIDE_SECTION_OPEN] ({ sectionsOpen }) {
      return sectionsOpen
    },
    [getters.LEFT_SIDE_BAR_CONTEXT] ({ showSideBarContext }) {
      return showSideBarContext;
    },
  },
  actions: {
    [actions.LEFT_SIDE_MENU_TOGGLE] ({ state, dispatch }, { open, activate,  context={}}) {
      if (open === undefined) {
        state.open = activate === state.active ? !state.open : true

        if (!state.open) {
          state.activate = null
        }
      }

      if (open !== undefined) {
        state.open = open
        if (open === false) {
          state.active = ''
        }
      }

      if (activate) {
        state.active = activate
        if (activate === 'pages') {
          state.leftSidePage = true
          state.showSideBarContext = context
        }
        if (activate === 'sections') {
          state.sectionsOpen = true
          state.showSideBarContext = context
        }
      }

      if (state.open) {
        dispatch(actions.ADVANCED_MENU_TOGGLE, false)
        dispatch(actions.SELECTOR_DESELECT)
      } else {
        dispatch(actions.LAYOUT_REFRESH, ['$children'])
        state.leftSidePage = false
        state.sectionsOpen = false
        state.showSideBarContext = {}
      }
    }
  }
}
