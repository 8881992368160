import { styled } from '@egoist/vue-emotion'
import { mapGetters, mapState, mapMutations} from 'vuex'
import CvtButton from './Button'
import * as getters from '@/js/store/modules/getters'
import * as mutations from '@/js/store/modules/mutations'
import { CvtIcon } from './icon'

let FontFamily, Colors, backgroundColor, Spacing;

const getModalContainer = () => styled('div')`
  position: fixed;
  z-index: 9997;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`

const getModalBackDrop = () => styled('div')`
  width: 100%;
  height: 100%;
  background-color: ${Colors.BASIC[1000]};
  opacity: 0.5;
`

const getModalInnerContainer = () => styled('div')`
  position: absolute;
  top: 0;
  z-index: 9998;
  top: 50%;
  left: 50%;
  width: ${(props) => props.modalWidth ? `${props.modalWidth}px` : 'unset'};
  margin-left: -${(props) => props.modalWidth ? `${props.modalWidth/2}px` : '25%'};
  transform: translate(0,-50%);
  font-family: ${FontFamily.DEFAULT} ;
  ${backgroundColor({ themeing: "dark"}, "WHITE")}
  border-radius: ${Spacing.SPACING_8}px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 50px 0px;
`

const getModalWrapper = () => styled('div')`
    width: 70%;
    margin: 0 auto;
    font-family: ${FontFamily.DEFAULT};
`

const getModalContent = () => styled('p')`
  font-weight: 300;
  font-size: 18px;
  color: ${Colors.BASIC[600]};
  padding-bottom: 30px;
  margin: 0;
`

const getModalButton = () => styled(CvtButton)`
  padding: 16px !important;
  background-color: ${(props) => props.btnColor  && props.btnColor} !important;
`

const getModalHeader = () => styled('h1')`
  font-weight: 600;
  font-size: 35px;
  color: ${Colors.PRIMARY[900]};
  padding-bottom: 30px;
  margin: 0;
`

const getModalIcon = () => styled(CvtIcon)`
  padding-bottom: 30px;
  ${(props) => props.iconColor && `color:${props.iconColor};`}
`


export const CvtModal = {
  name: 'CvtModal',
  data() {
    return {}
  },
  props: {
    modalWidth: {
      type: Number,
      default: 0
    },
    modalHeader: {
      type: String,
      default: ''
    },
    textContent: {
      type: String,
      default: ''
    },
    btnText:{
      type: String,
      default: ''
    },
    btnOnClick: Function,
    modalIcon: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: ''
    },
    btnColor: {
      type: String,
      default: ''
    }
  },
  methods: {
    ...mapMutations({
        toggleModal: mutations.SET_MODAL
    }),
    closeModal(){
      this.toggleModal(false)
    },
  },
  computed: {
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,

    }),
    ...mapGetters('globalTheme', {
        backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR
    })
  },
  created(){
    FontFamily = this.FontFamily
    Colors = this.Colors
    backgroundColor = this.backgroundColor
    Spacing = this.Spacing
  },

  render: function (h) {
    const ModalContainer = getModalContainer()
    const ModalBackDrop = getModalBackDrop()
    const ModalWrapper = getModalWrapper()
    const ModalInnerContainer = getModalInnerContainer()
    const ModalContent = getModalContent()
    const ModalHeader = getModalHeader()
    const ModalButton = getModalButton()
    const ModalIcon = getModalIcon()

    return (
      <ModalContainer>
        <ModalBackDrop
          onclick={this.closeModal}
        />
        <ModalInnerContainer
          modalWidth={this.modalWidth}
        >
          <ModalWrapper>
              <ModalIcon
                icon={this.modalIcon}
                iconColor={this.iconColor}
              ></ModalIcon>
              <ModalHeader>{this.modalHeader}</ModalHeader>
              <ModalContent>{this.textContent}</ModalContent>
              <ModalButton
                  shape='round'
                  color='dark'
                  mode={this.mode}
                  outlined={true}
                  text={this.btnText}
                  onClick={(e) => { this.btnOnClick()}}
                  fullWidth={true}
                  btnColor={this.btnColor}
              ></ModalButton>
          </ModalWrapper>
        </ModalInnerContainer>
      </ModalContainer>
    )
  },
}
