
<button
  :class="btnClasses"
  :disabled="site.locked"
  @click.prevent.stop="publishSite(site, $event)"
>
  {{ msg }} <i
    v-show="site.locked"
    class="fa fa-circle-o-notch fa-spin"
  />
</button>
