import Vue from '../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import * as getters from '../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import Button from './Button'

Vue.use(VueEmotion)


let Spacing, Radii, Border, Colors, textLineHeight, LineHeight, BorderStyle, textFontWeight, borderColor, backgroundColor, textFontSize, textColor, FontFamily;


const getMainContainer = () => styled('div')`
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  width: ${(3*Spacing.SPACING_96) + Spacing.SPACING_12}px;
  min-height: ${Spacing.SPACING_32 + Spacing.SPACING_4}px;

  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;

  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: ${Border.SIZE_1}px;
  border-left-width: 0px;
  border-style: ${BorderStyle.SOLID};
  ${borderColor({ themeing: 'dark' }, 100)};
`

const getCloseButton = () => styled(Button)`
  font-family: ${FontFamily.DEFAULT};
  text-align: center;
  font-style: normal;
`


const getBackButton = () => styled(Button)`
  font-family: ${FontFamily.DEFAULT};
  text-align: center;
  font-style: normal;
`

const getStepLabel = () => styled('div')`
  font-family: ${FontFamily.DEFAULT};
  text-align: center;
  font-style: normal;
  color: black;
  width: ${Spacing.SPACING_256 + Spacing.SPACING_8}px;
  padding: ${Spacing.SPACING_4}px;
  ${textFontWeight('REGULAR')}; 
  ${textFontSize('lg')};
  line-height: ${LineHeight.LINE_HEIGHT_24}px;
`


export default {
  name: 'RightSidebarNavigator',
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
      required: false
    },
    step: {
      type: Number,
      default: 1,
      required: true,
    },
    stepTitle: {
      type: String,
      required: true,
    },
  },
  data() { return {} },

  methods: {
    simpleNavBtnClicked(event, step){
      this.$emit('simpleNavBtnClicked', event, step);
    },
  },

  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    Colors = this.Colors
    BorderStyle = this.BorderStyle
    FontFamily = this.FontFamily

    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    textColor = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    LineHeight = this.LineHeight
  },

  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
    }),

    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      BorderStyle: ({ globalTheme }) => globalTheme.BorderStyle,
      LineHeight: ({ globalTheme }) => globalTheme.LineHeight,
    })
  },

  render: function (h) {

    const MainContainer = getMainContainer();
    const StepLabel = getStepLabel();

    const CloseButton = getCloseButton();
    const BackButton = getBackButton();

    return (
      <MainContainer>

        {this.step === 1 && (
          <CloseButton
            mode={this.mode.toLowerCase()}
            color={this.mode.toLowerCase()}
            shape={'pill'}
            size={'sm'}
            icon={'times'}
            icononly={true}
            onClick={(event) => this.simpleNavBtnClicked(event, 0) }
          ></CloseButton>
        )}

        {(this.step === 2 || this.step === 3) && (
          <BackButton
            mode={this.mode.toLowerCase()}
            color={this.mode.toLowerCase()}
            shape={'pill'}
            size={'sm'}
            icon={'arrow-left'}
            icononly={true}
            onClick={(event) => this.simpleNavBtnClicked(event, 1) }
          ></BackButton>
        )}
          
        <StepLabel>
          {this.stepTitle}
        </StepLabel>

      </MainContainer>
    )
  }
}
