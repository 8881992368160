<template>
  <cvt-dialog
    v-if="!AltPopupManager"
    size="lg"
    bg-color="#fff"
    :show="show"
    :show-close="false"
    :height-auto="true"
    :show-header="false"
    :show-footer="false"
  >
    <div class="row mb-4 mt-4">
      <div class="col-md-10 text-center mx-auto">
        <h1 class="setup-title" :style="titleStyle">
          Name your Popup Campaign
        </h1>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-10 text-center mx-auto">
        <h5 :style="titleStyle">
          Your site visitors will see this so choose a name that represent your
          product or company
        </h5>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-10 mx-auto">
        <cvt-input
          id="templateNameId"
          v-model="popupName"
          v-validate="validatePopupName"
          type="text"
          name="name"
          :label="'popup name'"
          :clearable="false"
          :validation-status="nameInvalid ? 'invalid' : 'valid'"
          :valid-message="'Popup name is valid'"
          :invalid-message="'Popup name is invalid'"
        />
        <div v-show="nameInvalid" class="invalid-feedback">
          Popup name is invalid
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="mx-auto">
        <cvt-button
          size="md"
          color="primary"
          :text="$t('setup.template.btn_text')"
          theme="light"
          :outlined="false"
          :full-width="true"
          :disabled="nameInvalid"
          @click="$emit('continue')"
        />
      </div>
    </div>
  </cvt-dialog>
  <cvt-dialog
    v-else
    bg-color="#ffffff"
    size="sm"
    :show="show"
    :show-close="false"
    :height-auto="true"
    :show-header="false"
    :show-footer="false"
  >
    <div class="row mb-2 mt-4">
      <div class="col-md-12">
        <h5 class="setup-title" :style="titleStyle">
          Name your Popup Campaign
        </h5>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-12">
        <cvt-input
          id="templateNameId"
          v-model="popupName"
          v-validate="validatePopupName"
          type="text"
          name="name"
          :label="'popup name'"
          :clearable="false"
          :validation-status="nameInvalid ? 'invalid' : 'valid'"
          :valid-message="'Popup name is valid'"
          :invalid-message="'Popup name is invalid'"
        />
        <div v-show="nameInvalid" class="invalid-feedback">
          Popup name is invalid
        </div>
      </div>
    </div>
    <div class="row">
      <div class="ml-auto mr-3">
        <cvt-button
          size="sm"
          color=""
          :text="$t('setup.template.btn_text')"
          theme="light"
          special-style="dark"
          :outlined="true"
          :full-width="true"
          :disabled="nameInvalid"
          @click="$emit('continue')"
        />
      </div>
    </div>
  </cvt-dialog>
</template>

<script>
import * as _ from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as actions from '../../../../store/modules/actions'
import * as getters from '../../../../store/modules/getters'
import * as mutations from '../../../../store/modules/mutations'
export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validatePopupName: {
        rules: {
          required: true,
          min: 3,
        },
      },
      mode: 'LIGHT',
      prependChevron: false,
    }
  },
  computed: {
    ...mapGetters({
      popup: getters.SDA_POPUP_SITE_DATA,
      step: getters.STEPS_CURRENT_STEP,
      fontStyles: getters.THEME_FONT_STYLES,
      brandLogo: getters.THEME_BRAND_LOGO,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      AltPopupManager: ({ globalTheme }) =>
        globalTheme.AltPopupManager !== undefined
          ? globalTheme.AltPopupManager
          : false,
    }),
    nameInvalid() {
      let invalid = this.errors.has('name')
      this.blockNextStep(invalid)
      return invalid
    },
    titleStyle() {
      return { 'font-family': this.FontFamily.DEFAULT }
    },
    popupName: {
      get() {
        return this.popup.popupName
      },
      set: _.debounce(
        function ({ target }) {
          this.updatePopup({
            ...this.popup,
            popupName: target.value,
          })
          return target.value
        },
        700,
        { trailing: true },
      ),
    },
  },
  mounted() {
    // setTimeout(() => {
    //   this.$el.querySelector('.form-control').focus()
    //   this.$validator.validate()
    // }, 0)
  },
  methods: {
    ...mapActions({
      updatePopup: actions.POPUP_UPDATE,
    }),
    ...mapMutations({
      blockNextStep: mutations.STEPS_BLOCK,
    }),
  },
}
</script>

<style lang="scss">
.step-one-setup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
}

.step-one-setup__input {
  width: 500px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.setup-title {
  font-weight: bold;
}

.modal__body {
  background-color: #fff;
}
</style>
