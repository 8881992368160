import Vue from "vue";
import VueI18n from "vue-i18n";
import { styled } from "@egoist/vue-emotion";
import * as getters from "../../../../../builder/src/js/store/modules/getters";
import * as constants from "../../../../../builder/src/js/common/constants/constants";
import { mapGetters, mapState } from "vuex";
import CvtButton from "../../../molecules/Button";
import CvtSelect from "../../../molecules/Select";
import CvtCustomCheckbox from "../../../molecules/CustomCheckbox";
import CvtRadioGroup from "../../../molecules/radio-group/RadioGroup";
import { IntegrationLogoWrapper } from "../logo-wrapper";
import { FieldMapper, LoadingComponent } from "../FieldMapper";

Vue.use(VueI18n)

let textFontWeight, textFontSize, Spacing, FontSize, backgroundColor, Colors, FontFamily

const getAuthenticationDescription = () => styled('div')`
  background: ${Colors.BASIC[100]};
  font-family: ${FontFamily.DEFAULT}
`

const getHorizontalLine = () => styled('div')`
  border-top: 1px solid ${Colors.BASIC[300]};
  margin-bottom: 15px;
`

const getAuthSuccessBtnWrap = () => styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`

const getSelect = () => styled(CvtSelect)`
  width: 100%;

  > div {
    border-radius: 4px;
  }

  div > div {
    border-radius: 0;
    border: 0;
  }
`

const getSelectPipelineText = () => styled('p')`
  margin-bottom: 6px;
`

const getMatchFieldsText = () => styled('p')`
  font-size: 16px;
`

const getAuthDescriptionHeader = () => styled('p')`
  font-weight: 700;
  font-size: 16px;
  color: ${Colors.BASIC[500]};
`

const getMapTitle = () => styled('h6')`
  font-weight: 700;
  font-size: 16px;
`

const getCustomCheckbox = () => styled(CvtCustomCheckbox)``


const MapComponent = ({props}) => {
  const {
    providerUnMappedFields,
    standardUnMappedFields,
    standardFieldLabels,
    updateStandardField,
    updateProviderField,
    removeMapping,
    showPipelineSelect,
    checkDealCallback,
    mappedFields,
    providerFieldLabels
  } = props

  const Select = getSelect()
  const SelectPipelineText = getSelectPipelineText()
  const CustomCheckbox = getCustomCheckbox()
  const HorizontalLine = getHorizontalLine()


  return (
    <div>
      <FieldMapper
        providerUnMappedFields={providerUnMappedFields}
        standardUnMappedFields={standardUnMappedFields}

        standardFieldLabels={standardFieldLabels}
        providerFieldLabels={providerFieldLabels}

        mappedFields={mappedFields}

        onUpdateProviderField={updateProviderField}
        onUpdateStandardField={updateStandardField}

        onRemoveMapping={removeMapping}
        provider={'Hubspot'}
      />

      {/* commenting out because it doesnt work for now... */}
      {/* <CustomCheckbox
        style="margin-bottom: 18px;"
        fontSize="14px"
        label="Update if contact already exists"
        checked={false}
      />

      <HorizontalLine></HorizontalLine>

      <CustomCheckbox
        style="margin-bottom: 15px;"
        fontSize="14px"
        label="Create a Deal"
        checked={false}
        onChange={(event) => checkDealCallback()}
      /> */}

      {showPipelineSelect && (
        <div>
          <SelectPipelineText>Select a pipeline</SelectPipelineText>
          <Select placeholder="Client Name">
            <option id="pipleline 1" value="pipleline 1">
              pipleline 1
            </option>
            <option id="pipleline 2" value="pipleline 2">
              pipleline 2
            </option>
          </Select>
        </div>
      )}
    </div>
  )
}


export const HubspotSetup = {
  name: 'HubspotSetup',
  components: {
    CvtRadioGroup
  },
  data() {
    return {
      hubspotFields: [1, 2, 3],
      showPipelineSelect: false,
      loading: true,
      isSaving: false,
      mappedFields: [],
      providerUnMappedFields: [], // keeps track of fields not mapped
      standardUnMappedFields: [], // keeps track of standards not mapped
      standardFieldLabels: {},
      providerFieldLabels: {},
      // Keeps tracking of fields for mapping
      fields: {providerFields: [], standardFields: []}
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapGetters({
      formFieldGroups: getters.FORM_EDITOR_FIELD_GROUPS,
      providerAPI: getters.GET_PROVIDER_API_SERVICE,
      site: getters.SITE_GET_DATA,
      selected: getters.SELECTOR_SELECTED,
    }),
    ...mapGetters('globalTheme', {
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
    }),
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      globalTheme: ({ globalTheme }) => globalTheme,
    }),
    canAddMapping() {
      const empty = this.mappedFields.filter(x => x.id==="" || x.name==="")
      if (empty.length >0) {
        return  false
      }
      if (this.standardUnMappedFields.length > 0) {
        this.addMapping()
        return true
      }
      return false
    },
    canSavingMapping() {
      const empty = this.mappedFields.filter(x => x.id === "" || x.name === "")
      return this.standardUnMappedFields.length === 0 && empty.length === 0;
    },
  },
  created() {
    textFontSize = this.textFontSize
    textFontWeight = this.textFontWeight
    Spacing = this.Spacing
    FontSize = this.FontSize
    backgroundColor = this.backgroundColor
    Colors = this.Colors
    FontFamily = this.FontFamily
  },
  mounted() {
    this.loading = true
    this.init().then().finally(() => {
      this.loading = false
    });
  },
  methods: {
    onCheckDeal() {
      this.showPipelineSelect = !this.showPipelineSelect
    },
    async onCompleted() {
      try {
        this.isSaving = true
        await this.providerAPI.updateMappedFields(
          this.mappedFields.filter(x => x.id !=="" || x.name !==""),
          this.site.id,
          this.selected.id
        )
        this.$emit('completed', constants.INTEGRATION_COMPLETE)
      }catch (e) {
        console.error(e)
      }
      finally {
        this.isSaving = false
      }
    },
    async init () {
      try {
        const fields = await this.providerAPI.getMappingFields()
        const value = await this.providerAPI.getMappedFields(this.site.id, this.selected.id)

        this.mappedFields = [...(value.fieldMappings || [])]

        this.standardUnMappedFields = this.selected.computedFields.map(x => x.id)
        this.standardFieldLabels = Object.assign({}, ...this.selected.computedFields.map(x => ({[x.id]: x.label})))

        this.providerUnMappedFields = fields.integrationFields.map(x => x.name)
        this.providerFieldLabels = Object.assign({}, ...fields.integrationFields.map(x => ({[x.name]: x.label})))

        this.fields.providerFields = [...this.providerUnMappedFields]
        this.fields.standardFields = [...this.standardUnMappedFields]

        if (this.mappedFields.length === 0) {
          this.addMapping()
        }
        else {
          this.updateCorrespondingFields()
        }
      }catch (e) {
        console.log(e)
      }

    },
    updateCorrespondingFields() {
      this.loading = true

      const mappedStandardFields = this.mappedFields.map(c=> c.id)
      const mappedHubspotFields = this.mappedFields.map(c=> c.name)

      this.standardUnMappedFields = this.fields.standardFields.filter(c => !mappedStandardFields.includes(c))
      this.providerUnMappedFields = this.fields.providerFields.filter(c => !mappedHubspotFields.includes(c))

      this.$nextTick(() => {
        this.loading = false
      })
    },
    addMapping() {
      this.mappedFields.push({id: "", name: ""})
    },
    removeMapping (map) {
      // remove from mapping list
      const idx = this.mappedFields.indexOf(map)
      this.mappedFields = this.mappedFields.filter((map, index) => index !== idx)
      this.updateCorrespondingFields()
    },
    UpdateStandardField (map, value) {
      // update convrrt field mapping and remove it from already mapped list
      map.id = value
      this.updateCorrespondingFields()
    },
    UpdateProviderField (map, value) {
      // update hubspot field mapping and remove it from already mapped list
      map.name = value
      this.updateCorrespondingFields()
    },
  },
  render(h) {
    const AuthenticationDescription = getAuthenticationDescription()
    const HorizontalLine = getHorizontalLine()
    const AuthSuccessBtnWrap = getAuthSuccessBtnWrap()

    const MatchFieldsText = getMatchFieldsText()
    const AuthDescriptionHeader = getAuthDescriptionHeader()

    const MapTitle = getMapTitle()

    return (
      <IntegrationLogoWrapper>
        <AuthenticationDescription>
          <div style="padding: 45px;">
            <AuthDescriptionHeader>
              Create a new contact or Update an existing one
            </AuthDescriptionHeader>
            <MatchFieldsText>Match your fields</MatchFieldsText>
            <HorizontalLine class={'mb-1'}></HorizontalLine>

            <div style="display: flex;gap: 30px; align-items: center;">
              <div style="flex:1;">
                <MapTitle class={'m-0 py-2'}>Convrrt Form Field</MapTitle>
              </div>

              <div style="flex:1;">
                <MapTitle class={'m-0 py-2'}>Desired HubSpot Field</MapTitle>
              </div>
              <CvtButton
                class={'d-none'}
                disabled={!this.canAddMapping}
                icon={'plus'}
                outlined={true}
                specialPadding="5px"
                color="light"
                shape={'circle'}
                size={'10px'}
                onClick={() => this.addMapping()}
              ></CvtButton>
            </div>
            <HorizontalLine class={'my-1'}></HorizontalLine>

            {this.loading ? <LoadingComponent/> : (
              <MapComponent
                providerUnMappedFields={this.providerUnMappedFields}
                standardUnMappedFields={this.standardUnMappedFields}
                standardFieldLabels={this.standardFieldLabels}
                providerFieldLabels={this.providerFieldLabels}
                showPipelineSelect={this.showPipelineSelect}
                removeMapping={this.removeMapping}
                checkDealCallback={this.onCheckDeal}
                updateProviderField={this.UpdateProviderField}
                updateStandardField={this.UpdateStandardField}
                mappedFields={this.mappedFields}
                />
            )}
          </div>
        </AuthenticationDescription>

        <AuthSuccessBtnWrap>
          <CvtButton
            text="Cancel"
            outlined={true}
            specialPadding="12px"
            color="light"
            onClick={() => this.$emit('cancel')}
          ></CvtButton>
          <CvtButton
            text="Save"
            outlined={true}
            specialPadding="12px"
            disabled={!this.canSavingMapping}
            color="primary"
            loading={this.isSaving}
            onClick={() =>
              this.onCompleted()
            }
          ></CvtButton>
        </AuthSuccessBtnWrap>
      </IntegrationLogoWrapper>
    )
  },
}
