import {CreateWidgetFromFirebaseNode} from "../components/v3/lib/vue-dom-obj";
import {ConvrrtFirebase} from "../convrrt-firebase";
import {VDomCommands} from "./VDomCommand";


export class PageSwapCommand extends VDomCommands {
    private readonly _firebaseRef: ConvrrtFirebase;
    private readonly _newNodeBlocks: any[];
    private readonly _oldNodeBlocks: any[];

    constructor(rootKey: string, newNodeBlocks: any[], oldNodeBlocks: any[]) {
        super('');
        this._firebaseRef = new ConvrrtFirebase(rootKey)
        this._newNodeBlocks = newNodeBlocks
        this._oldNodeBlocks = oldNodeBlocks
    }
    get$emitData() {
        return  {name: 'PageSwapCommand', forceBuild: true}
    }
    get hasChanges () {
        return true
    }

    get firebaseRef(): ConvrrtFirebase {
        return this._firebaseRef
    }

    async waitForDataPulling (database: ConvrrtFirebase): Promise<any> {
        return new Promise(resolve=> {
            database.ref.once('value', (snapshot: any) => {
                // allow DeltaRef Changes to take place
                // then push all changes a single change to history
                setTimeout(() => {
                    // @ts-ignore
                    resolve()
                }, 1200);
            })
        })
    }
    async pullChanges(deltaKey: string) {
        // deprecated
        const database = new ConvrrtFirebase(deltaKey)
        await database.ref.on('child_added', (snap: any, prevKey: number) => {
            this._newNodeBlocks.push(snap.val())
        })
        await this.waitForDataPulling(database)
    }

    async execute() {
        await this.firebaseRef?.set(this._newNodeBlocks);
    }

    async unExecute() {
        await this.firebaseRef?.set(this._oldNodeBlocks)
    }
}
