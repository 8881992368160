<script lang="ts">
export default {
  name: 'NewLabelInput',
  props: {
    value: {
      required: true,
      type: String,
    }
  },
  data() {
    return {
      regex: /^[A-Za-z][A-Za-z0-9]*$/,
    }
  },
  methods: {
    handleCvtInputChange(e) {
      if (this.regex.test(e.target.value) || e.target.value === "") {
        this.$emit('input', e.target.value)
      }
    },
    onKeyDown(event) {
      if (event.key === "Backspace" || event.ctrlKey || event.metaKey) {
        return;
      }
      const key = event.key;
      const value = `${this.value}${key}`

      if (!this.regex.test(value)) {
        event.preventDefault(); // Prevent the invalid key from being entered
      }
    }
  }
}
</script>

<template>
  <cvt-input
      :value="value"
      type="text"
      label="New Label"
      :clearable="true"
      @input="handleCvtInputChange"
      @keydown="onKeyDown"
  />
</template>

<style scoped lang="scss">

</style>