<template>
  <div>
    <h1>Tooltip</h1>
    <div class="w-100">
      <div class="mb-4">
        <span>Default Tooltip</span>
        <cvt-button
          id="tooltip-button"
          text="I have a tooltip"
          color="primary"
        />
        <cvt-tooltip
          ref="tooltip"
          target="tooltip-button"
        >
          Hello <strong>World!</strong>
        </cvt-tooltip>
      </div>
      <div
        v-for="(variant, index) in variants"
        :key="'tooltip-' + index"
        class="mb-4"
      >
        <span>{{ variant.description }}</span>
        <cvt-button
          :id="variant.target + index"
          text="I have a tooltip"
          color="primary"
        />
        <cvt-tooltip
          ref="tooltip"
          :show="variant.show"
          :delay="variant.delay"
          :no-fade="variant.noFade"
          :placement="variant.placement"
          :color='variant.color'
          :disabled="variant.disabled"
          :target="variant.target + index"
        >
          Hello <strong>World!</strong>
        </cvt-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PlayTooltip',
  data () {
    return {
      variants: [
        {
          description: 'Tooltip (Light, position on left)',
          show: false,
          delay: 400,
          noFade: false,
          placement: 'left',
          color: 'light',
          disabled: false,
          ref: 'tooltip',
          target: 'tooltip-button'
        },
        {
          description: 'Tooltip (Dark, Shown by default, position on right)',
          show: true,
          delay: 400,
          noFade: false,
          placement: 'right',
          color: 'dark',
          disabled: false,
          ref: 'tooltip',
          target: 'tooltip-button'
        },
        {
          description: 'Tooltip Disabled',
          show: false,
          delay: 400,
          noFade: false,
          placement: 'right',
          color: 'dark',
          disabled: true,
          ref: 'tooltip',
          target: 'tooltip-button'
        }
      ]
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
  },
}
</script>
<style lang="scss">
</style>
