import {RemoveCommand} from "../../../commands/RemoveCommand";
import {CreateWidgetFromFirebaseNode} from "../lib/vue-dom-obj";
import _ from "lodash";
import {BulkPropertyChangeCommand} from "../../../commands/BulkPropertyChangeCommand";
import {BlockElementOrderCommand} from "../../../commands/SectionRearrangeCommand";
import {AddCommand} from "../../../commands/AddCommand";
import {mapGetters, mapMutations, mapActions} from "vuex";
import * as getters from "../../../store/modules/getters";
import * as mutations from "../../../store/modules/mutations";
import * as actions from "../../../store/modules/actions";


export default {
    computed: {
        ...mapGetters({
            site: getters.SITE_GET_DATA,
            thankYouPage: getters.SITE_GET_THANKYOU,
        })
    },
    data() {
      return {
          updatingDataY: false
      }
    },
    methods: {
        ...mapMutations({
            disableCommandPush: mutations.HISTORY_IGNORE,
            enableCommandPush: mutations.HISTORY_LISTEN,
            ignore: mutations.SELECTOR_IGNORE,
        }),
        ...mapActions({
            deselect: actions.SELECTOR_DESELECT,
          }),
        executeCommand(command) {
            this.pushCommand(command).then(() => {
                this.onCommandActionExecuted()
            })
        },
        validateAndExecuteCommand(command) {
            this.validateAndPushCommand(command).then(() => {
                this.onCommandActionExecuted()
            })
        },
        onCommandActionExecuted() {
            // called after any command related to builder
            // in case any further action need to happen
        },
        setPageId (vNode) {
            const pageId = _.get(vNode, 'data.props.pageId', '')
            if (pageId === '') {
                console.log("this.site: ", this.site)
                if (this.site.pages.length > 2) {
                    const thankYou = _.find(this.site.pages, (page) => {
                        return page.name.includes('thank you')
                    })
                    if (thankYou) {
                        _.set(vNode, 'data.props.pageId', thankYou.id)
                    } else {
                        console.debug('Can\'t link automatically to the thank you page.')
                    }
                } else if (this.site.pages.length === 2) {
                    console.debug('Able to connect thank you page.')
                    _.set(vNode, 'data.props.pageId', this.thankYouPage.id)
                }
            }
        },
        forceRemoveNode (component) {
            this.getGrid().removeWidget(component.$el)
            this.fbNode.children = this.fbNode.children.filter(
                x => x.data.props.vnodeId !== component.vnodeId
            )
            // save to database
            this.firebaseRef.removeNode(+component.idx())
        },
        removeNode (component, execute=true) {
            this.ignore()
            this.deselect()
            const command = new RemoveCommand(this.vnodeId, component.vnodeId, component.fbNode)
            if (execute) {
                this.executeCommand(command)
                return
            }
            return command
        },
        duplicateNode (node) {
            this.ignore()
            this.deselect()
            let clonedNode = CreateWidgetFromFirebaseNode({
                data: Object.assign({}, node.data),
                tag: node.tag,
                desktop: Object.assign({}, node.data.props.desktop),
                phone: Object.assign({}, node.data.props.phone),
                children: node.children.map(x => _.clone(x))
            }, true)

            const alternatedViewMode = this.getAlternatedViewMode()
            clonedNode[alternatedViewMode] = clonedNode.data.props[alternatedViewMode] = this.fixOptions(
                clonedNode[alternatedViewMode], alternatedViewMode
            )
            clonedNode[this.viewMode] = clonedNode.data.props[this.viewMode] = this.fixOptions(
                clonedNode[this.viewMode], this.viewMode
            )
            this.executeCommand(new AddCommand(this.vnodeId, clonedNode))
        },
        makeWidget(element) {
            let _element = element
            if (_element.$el !== undefined) {
                _element = _element.$el
            }
            this.getGrid().makeWidget(_element);
            return element
        },
        change: _.debounce(function (e, items) {
            const bulkChangeCommand = new BulkPropertyChangeCommand(this.getVNodeID())
            this.changeEventHandler(e, items, bulkChangeCommand)
            this.validateAndExecuteCommand(bulkChangeCommand)
        }, 500),
        changeEventHandler(e, items, bulkChangeCommand) {
            if (items.length > 0) {
                items.forEach((changedItem) => {
                    const vNodeID = changedItem.el.dataset.vnodeId || 0
                    this.$children.forEach((child) => {
                        if (child.vnodeId === vNodeID) {
                            let command = child.createCommand(
                                this.viewMode,
                                {
                                    dataX: changedItem.x,
                                    dataY: changedItem.y,
                                    dataWidth: changedItem.w,
                                    dataHeight: changedItem.h,
                                }
                            )
                            bulkChangeCommand.addCommand(vNodeID, command)

                            if (
                                child.$children.length > 0 &&
                                child.$children[0].name === 'Icon'
                            ) {
                                const remValue = Math.floor(
                                    (child.$el.offsetWidth * 1) / 12.48
                                )
                                command = child.$children[0].createCommand('size', remValue)
                                bulkChangeCommand.addCommand(child.$children[0].getVNodeID(), command)
                            }
                        }
                    })
                })
            }
        },
        getNewRow(viewMode){
            return this.fbNode.children.reduce((row, n) => Math.max(row, n.data.props[viewMode].dataY + n.data.props[viewMode].dataHeight), 0);
        },
        fixOptions(options, viewMode){
            const row = this.getNewRow(viewMode)
            let y = row === 0 ? 0: row
            return {...options, dataY: y}
        },
        reloadContent() {

        },
        initGridBuilder({reload=false, skipEvent=false}) {

        },
        async arrangeNodeInYAscendingValue() {
            const bulkCommands = new BulkPropertyChangeCommand(this.getVNodeID())

            const getGridContainerVueElements = this.getGridContainerVueElements()
            const yNodes = []
            getGridContainerVueElements.forEach((value, i) => {
                if (value.name === 'GridItemWrapper') {
                    yNodes.push({element: value, y: Number(value.dataY), oldIdx: i})
                }
            })
            // Sort in ascending order
            yNodes.sort(function(a, b){return a.y - b.y})
            yNodes.forEach((value, j) => {
                bulkCommands.addCommand(value.element.getVNodeID(), new BlockElementOrderCommand(this.getVNodeID(), j, value.oldIdx))
            })
            await bulkCommands.execute()
        },
        getGridContainerVueElements() {
            const gridStackElement = this.getGrid()
            if (!gridStackElement) return []

            const gridStackVueElements = []
            for (const x of gridStackElement.el.children) {
                if(x.__vue__) {
                    gridStackVueElements.push(x.__vue__)
                }
            }
            return gridStackVueElements
        },
        mobileElementFloatFix(){
            return new Promise(resolve => {
                const bulkChangeCommand = new BulkPropertyChangeCommand(this.getVNodeID())

                const inlineHandler = (e, items) => {
                    this.changeEventHandler(e, items, bulkChangeCommand)
                }

                this.getGrid().on('change', inlineHandler)
                this.getGrid().float(false)
                setTimeout(() => {
                    bulkChangeCommand.execute().then(() => {
                        this.getGrid().off('change', inlineHandler)
                        this.getGrid().float(true)
                        resolve()
                    })
                }, 500)
            })
        },
        async _resizeChildrenHandler(){
            const getGridContainerVueElements = this.getGridContainerVueElements()
            for (const x of getGridContainerVueElements) {
                if (x.name === 'GridItemWrapper') {
                    const wrappedElement = x.getWrappedElement()
                    if (wrappedElement.name === 'SubGridContainer') {
                        await wrappedElement.resizeChildComponent()
                        wrappedElement.resizeSubGridHeight()
                        continue
                    }
                    if (wrappedElement.name === 'CButton') {
                        continue
                    }
                    wrappedElement.resize(true)
                }
            }
        },
        resizeChildComponent () {
            return new Promise(resolve => {
                this.unRegisterGridStackEvents()
                const bulkChangeCommand = new BulkPropertyChangeCommand(this.getVNodeID())

                const inlineHandler = (e, items) => {
                    console.log('resizeChildComponent Inline called')
                    this.changeEventHandler(e, items, bulkChangeCommand)
                }
                this.getGrid().on('change', inlineHandler)
                setTimeout(async () => {
                    // This timeout allows `this.unRegisterGridStackEvents()` call to unregister
                    // all its events handler and use the inline defined event handler.
                    await this._resizeChildrenHandler()
                    this.getGrid().off('change')
                    await bulkChangeCommand.execute()
                    await this.arrangeNodeInYAscendingValue()

                    setTimeout(async () => {
                        await this.mobileElementFloatFix()
                        setTimeout(() => {
                            this.registerGridStackEvents()
                            resolve()
                        }, 400)
                    }, 200)
                }, 200)
            })
        },
        handleDragFromAnotherSource(prevWidget, newWidget) {
            const previousItemWrapperComponent = prevWidget.el.__vue__
            const previousGridComponent = prevWidget.grid.rootComponent.__vue__
            const command = new BulkPropertyChangeCommand(this.vnodeId)

            if (previousItemWrapperComponent && previousGridComponent) {
                command.addCommand(previousGridComponent.vnodeId, previousGridComponent.removeNode(previousItemWrapperComponent, false))
                const fbNode = CreateWidgetFromFirebaseNode(previousItemWrapperComponent.fbNode, true)
                // Apply newWidget coordinates
                fbNode.data.props.desktop.dataHeight = newWidget.h
                fbNode.data.props.desktop.dataWidth = newWidget.w
                fbNode.data.props.desktop.dataX = newWidget.x
                fbNode.data.props.desktop.dataY = newWidget.y

                this.fixedChangesInCellSize(prevWidget, fbNode)
                command.addCommand(this.vnodeId, new AddCommand(this.vnodeId, fbNode))
            }
            this.executeCommand(command)
            this.getGrid().removeWidget(newWidget.el)
        }
    }
}