<template>
  <div>
    <h1>Progress</h1>
    <h4>Large</h4>
    <div
      v-for="(color, index) in colors"
      :key="index"
      class="mb-2"
    >
      {{ index }}
      <cvt-progress
        size="lg"
        :bg-color="color"
        mode="LIGHT"
        value-label=""
        label-pos="top"
        :max-value="100"
        :value-now="25"
      />
    </div>
    <h4 class="mb-2">
      Small
    </h4>
    <div
      v-for="(color, index) in colors"
      :key="smKey(index)"
      class="mb-2"
    >
      {{ index }}
      <cvt-progress
        size="sm"
        :bg-color="color"
        mode="LIGHT"
        value-label=""
        label-pos="top"
        :max-value="100"
        :value-now="65"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'PlayProgress',
  components: {
  },
  data () {
    return {
      colors: {
        Primary: 'primary',
        Secondary: 'secondary',
        Success: 'success',
        Danger: 'danger',
        Warning: 'warning',
        Info: 'info',
        Light: 'light',
        Dark: 'dark'
      },
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    smKey (index) {
      return `sm-${index}`
    }
  },
}
</script>
<style lang="scss">
</style>
