import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'

export default {
  state: {
    open: false,
    pagePreviewInfoOpen: false
  },
  getters: {
    [getters.PAGE_PREVIEW_OPEN] ({open}) {
      return open
    },
    [getters.PAGE_PREVIEW_INFO_OPEN] ({pagePreviewInfoOpen}) {
      return pagePreviewInfoOpen
    }
  },
  mutations: {
    [mutations.PAGE_PREVIEW_TOGGLE] (state, open) {
      state.open = open
    },
    [mutations.PAGE_PREVIEW_INFO_TOGGLE] (state, pagePreviewInfoOpen) {
      state.pagePreviewInfoOpen = pagePreviewInfoOpen
    }
  },
  actions: {
    [actions.PAGE_PREVIEW_TOGGLE] ({commit, dispatch}, open) {
      commit(mutations.PAGE_PREVIEW_TOGGLE, open)
    },
    [actions.PAGE_PREVIEW_INFO_TOGGLE] ({commit, dispatch}, pagePreviewInfoOpen) {
      commit(mutations.PAGE_PREVIEW_INFO_TOGGLE, pagePreviewInfoOpen)
    }
  }
}
