<template>
  <div
v-show="active && !isGridBuilder"
       class="dnd-zone"
       :class="classes"
       :style="style"
       @dragenter="validate"
       @dragleave="reset"
       @dragover="dragover"
       @drop.prevent="dropped"
  ></div>
</template>

<script>
  import * as $ from 'jquery'
  import * as _ from 'lodash'
  import { mapGetters, mapMutations } from 'vuex'
  import * as getters from '../../../store/modules/getters'
  import * as mutations from '../../../store/modules/mutations'

  export default {
    props: {
      zIndex:{ 
        type: Number,
        default: 0
      },
      cover: Boolean,
      disabledSides:{
        type: Array,
        default () {
          return []
        }
      }
    },
    data () {
      return {
        side: null
      }
    },

    computed: {
      ...mapGetters({
        active: getters.DROPZONE_ACTIVE,
        dataTransfer: getters.DROPZONE_DATA_TRANSFER,
        config: getters.AUTH_GET_USER_CONFIG,
        isGridBuilder: getters.IS_GRID_BUILDER,
      }),

      classes () {
        return {
          [`dnd-zone--${this.side}`]: true
        }
      },
      style () {
        return {
          'z-index': this.zIndex
        }
      }
    },
    methods: {

      ...mapMutations({
        deactivate: mutations.DROPZONE_DEACTIVATE
      }),

      offset () {
        let {
          left, top, right, bottom
        } = this.$el.getBoundingClientRect()
        return { top, right, left, bottom }
      },

      reset () {
        this.side = null
      },

      width () {
        return $(this.$el).innerWidth()
      },

      height () {
        return $(this.$el).innerHeight()
      },

      midPoints () {
        return {
          x: this.offset().left + (this.width() / 2),
          y: this.offset().top + (this.height() / 2)
        }
      },

      determineClosestSide ({clientX, clientY}) {
        let offset = this.offset()

        return _.chain([
          { side: 'left', dist: clientX - offset.left },
          { side: 'right', dist: offset.right - clientX },
          { side: 'top', dist: clientY - offset.top },
          { side: 'bottom', dist: offset.bottom -clientY }
        ]).sortBy('dist').head().get('side').value()
      },

      dragover (e) {
        e.preventDefault()
        if (this.cover) {
          this.side = 'all'
          return
        }

        let side = this.determineClosestSide(e)
        this.side = this.disabledSides.includes(side) ? null : side

        return false
      },

      validate (e) {
        e.preventDefault()
        return true
      },

      dropped (e) {
        this.$emit('dropped', {
          side: this.side,
          data: this.dataTransfer
        })
        this.reset()
        this.deactivate()
        return false
      }
    }
  }
</script>

<style lang="scss">
  @import "@/scss/utils";

  $dnd-border-size: 10px;
  $dnd-border-color: $inf-light-blue;

  .dnd-zone {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    position: absolute;

    opacity: 0.8;
    border: $dnd-border-size solid transparent;
    transition: all 300ms ease;

    // background-color: $brand-4;
    // border: $dnd-border-size solid $dnd-border-color;

    &--left {
      border-left: $dnd-border-size solid $dnd-border-color;
    }

    &--right {
      border-right: $dnd-border-size solid $dnd-border-color;
    }

    &--top {
      border-top: $dnd-border-size solid $dnd-border-color;
    }

    &--bottom {
      border-bottom: $dnd-border-size solid $dnd-border-color;
    }

    &--all {
      border: $dnd-border-size solid $dnd-border-color;
      background-color: rgba($brand-1, 0.9);
    }

  }
</style>
