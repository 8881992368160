<template>
    <div class="checkbox-opt-container">
        <div class="checkbox-opt-container__breadcrumb">
            <cvt-floating-breadcrumb
                :mode="'LIGHT'"
                :active-menu="currentMenuId"
                :menus="breadcrumbMenus"
                :hide-back-btn="true"
                :hide-next-btn="true"
                @menuChanged="(eventData) => onBreadCrumbSelectionChange(eventData)"
            />
        </div>

        <div class="checkbox-opt-container__content">
            <CheckoutPayment v-if="currentMenuId === 'checkout-page-design'" :page-config="checkoutPageConfig" v-bind="checkoutPageConfig.data.props"></CheckoutPayment>
            <CheckoutThankYou v-if="currentMenuId === 'thank-you-receipt-mock'" :page-config="checkoutThankYouPageConfig" v-bind="checkoutThankYouPageConfig.data.props" ></CheckoutThankYou>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import * as getters from "@/js/store/modules/getters";
import CheckoutPayment from "./CheckoutPayment/index.vue";
import CheckoutThankYou from "./CheckoutThankYou/index.vue";
import * as actions from "@/js/store/modules/actions";

export default {
    name: "CheckoutContainer",
    components: {
        CheckoutPayment,
        CheckoutThankYou
    },
    data() {
        return {
            currentMenuId: 'checkout-page-design',
            breadcrumbMenus: [
                {
                    id: 'checkout-page-design',
                    routeNames: ['checkout-page-design'],
                    title: 'Checkout Page Design',
                    completed: false,
                    disabled: false,
                    action: this.eventDataAction,
                },
                {
                    id: 'thank-you-receipt-mock',
                    routeNames: ['thank-you-receipt-mock'],
                    title: 'Thank you / Receipt Page Design',
                    completed: false,
                    disabled: false,
                    action: this.eventDataAction,
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            checkoutPageConfig: getters.CHECKOUT_PAGE_OPTIONS,
            checkoutThankYouPageConfig: getters.CHECKOUT_THANK_YOU_PAGE_OPTIONS,
        })
    },
    methods: {
        ...mapActions({
            deselect: actions.SELECTOR_DESELECT,
        }),
        onBreadCrumbSelectionChange(eventData) {
            if (eventData && eventData.action) {
                eventData.action()
                this.currentMenuId = eventData.id
                this.deselect()
            }
        },
        eventDataAction() {
            // Do nothing
        }
    }
}
</script>

<style lang="scss" scoped>
.checkbox-opt-container {
  background: #eff3f6;
  height: 100%;
  display: grid;
  grid-template-rows: 45px 1fr;
  gap: 1rem;

    &__breadcrumb {
      background: #fff;
      grid-row: 1;
      display: flex;
      justify-content: center;
    }
    &__content {
      grid-row: 2;
      background: #fff;
    }
}
</style>