

  <div
v-show="visible" class="vnode__overlay"
       @click="click" @mouseover="mouseover" @mouseout="mouseout">

    <slot></slot>

  </div>

