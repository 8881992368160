import Vue from "../../../lib/utils/vue";
import VueI18n from "vue-i18n";
import { mapGetters, mapState } from "vuex";
import { styled } from "@egoist/vue-emotion";
import * as getters from "../../../../builder/src/js/store/modules/getters";
import { CvtIcon } from "../../molecules/icon/icon";
import { CvtTooltip } from "../../molecules/tooltip/CvtTooltip";
import CvtButton from "../../molecules/Button";
import { ELEMENT_DROPPED_ON_GRID_CONTAINER, ELEMENT_DROPPED_ON_SUB_GRID_CONTAINER } from "@/js/plugins/events.js";

Vue.use(VueI18n)

let FontFamily, Spacing, backgroundColor, textColor, borderColor;

const GRID_CONTAINER_CLASS = 'grid-stack-item'
const SUBGRID_CONTAINER_CLASS = 'grid-stack-item-sub'

const allDragItems = [
  {
    icon: 'cvt-icon-four-squares-broken-line',
    name: 'container',
    label: 'help.container.message',
    type: 'Container',
    id: 'container_element',
  },
  {
    icon: 'far fa-pen',
    name: 'text',
    label: 'editor.left_nav.element.text',
    type: 'Text',
    id: 'text_element',
  },
  {
    icon: 'fal fa-hand-pointer',
    name: 'button',
    label: 'editor.left_nav.element.button',
    type: 'Button',
    id: 'button_element',
  },
  {
    icon: 'cvt-icon-picture-text',
    name: 'image',
    label: 'editor.left_nav.element.image',
    type: 'Image',
    id: 'image_element',
  },
  {
    icon: 'cvt-icon-video',
    name: 'video',
    label: 'editor.left_nav.element.video',
    type: 'Video',
    id: 'video_element',
  },
  {
    icon: 'cvt-icon-minus',
    name: 'divider',
    label: 'editor.left_nav.element.divider',
    type: 'Divider',
    id: 'divider_element',
  },
  {
    icon: 'cvt-icon-logo',
    name: 'logo',
    label: 'editor.left_nav.element.logo',
    type: 'Logo',
    id: 'logo_element',
  },
  {
    icon: 'cvt-icon-star',
    name: 'icon',
    label: 'editor.left_nav.element.icon',
    type: 'Icon',
    id: 'icon_element',
  },
  {
    icon: 'cvt-icon-layout-three-rows',
    name: 'form',
    label: 'editor.left_nav.element.form',
    type: 'Form',
    id: 'form_element',
  },
  {
    icon: 'cvt-icon-bullet-list',
    name: 'card_element',
    label: 'editor.section.action.bullet_list',
    type: 'Card Container',
    id: 'card_element',
  },
  {
    icon: 'cvt-icon-code',
    name: 'code',
    label: 'editor.left_nav.element.code',
    type: 'Code',
    id: 'code_element',
  },
  {
    icon: 'fal fa-stopwatch',
    name: 'countdown_timer',
    label: 'editor.section.action.count_down_timer',
    type: 'Countdown Timer',
    id: 'countdown_timer_element',
  },
  {
    icon: 'far fa-shopping-cart',
    name: 'checkout',
    label: 'editor.section.action.checkout',
    type: 'Checkout',
    id: 'checkout_element',
  },
  {
    icon: 'fal fa-calendar',
    name: 'calendar',
    label: 'Calendar',
    type: 'Calendar',
    id: 'calendar_element',
  },
  {
    icon: 'cvt-icon-bullet-list',
    name: 'bullet_list',
    label: 'editor.section.action.bullet_list',
    type: 'Bullet List',
    id: 'bullet_list_element',
  },
  {
    icon: 'fal fa-puzzle-piece',
    name: 'custom_widget',
    label: 'editor.section.action.custom_widget',
    type: 'Custom Widget',
    id: 'custom_widget_element',
  },
  {
    icon: 'cvt-icon-layout-three-rows',
    name: 'embed_form_element',
    label: 'editor.section.action.embed_form',
    type: 'Embed Form',
    id: 'embed_form_element',
  },
]

const getMainWrap = () => styled('div')`
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;

  position: absolute;
  font-family: ${FontFamily.DEFAULT};
  border-radius: ${Spacing.SPACING_32 - Spacing.SPACING_2}px;
`

const getDragItemsOuterWrap = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: ${Spacing.SPACING_12}px ${Spacing.SPACING_12}px;
  border-radius: ${Spacing.SPACING_8}px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.32);
  gap: 0px;
  z-index: 10;

  &.desktop {
    padding: ${Spacing.SPACING_12}px ${Spacing.SPACING_24 + 1}px;
    gap: 8px;
  }

  &.mobile {
    padding: ${Spacing.SPACING_12}px ${Spacing.SPACING_4 + Spacing.SPACING_2}px;
    gap: 0px;
  }
`

const getElementsBtn = () => styled('button')`
  // position: absolute; 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Spacing.SPACING_8 + 1}px ${Spacing.SPACING_18 + Spacing.SPACING_2}px ${Spacing.SPACING_8 + 1}px ${Spacing.SPACING_18 - Spacing.SPACING_2}px;

  border-width: 1px;
  border-style: solid;

  ${(props) => {
    let styles = ``;
    let backgroundColorObj = props.btnState.default.backgroundColor;
    let borderColorObj = props.btnState.default.borderColor;

    if (!backgroundColorObj.custom) {
      styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
    } else if (backgroundColorObj.custom) {
      styles += `background: ${backgroundColorObj.value};`;
    }

    if (!borderColorObj.custom) {
      styles += `${borderColor(borderColorObj.value, borderColorObj.weight)}`;
    } else if (borderColorObj.custom) {
      styles += `border-color: ${borderColorObj.value};`;
    }

    return styles;
  }}

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.32);
  border-radius: ${Spacing.SPACING_32 - Spacing.SPACING_2}px;
  width: ${Spacing.SPACING_128 + Spacing.SPACING_4}px;
  box-sizing: border-box;

  &:disabled {
    ${(props) => {
      let styles = ``;
      let backgroundColorObj = props.btnState.disabled.backgroundColor;

      if (!backgroundColorObj.custom) {
        styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
      } else if (backgroundColorObj.custom) {
        styles += `background: ${backgroundColorObj.value};`;
      }
      return styles;
    }}

    .sdi-element-btn-icon,
    .sdi-element-btn-text {
      ${(props) => {
        let styles = ``;
        let colorObj = props.btnState.disabled.color;

        if (!colorObj.custom) {
          styles += `${textColor(colorObj.value, colorObj.weight)}`;
        } else if (colorObj.custom) {
          styles += `color: ${colorObj.value};`;
        }

        return styles;
      }}
    }
  }

  &:hover:not(:disabled) {
    ${(props) => {
      let styles = ``;
      let backgroundColorObj = props.btnState.hover.backgroundColor;

      if (!backgroundColorObj.custom) {
        styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
      } else if (backgroundColorObj.custom) {
        styles += `background: ${backgroundColorObj.value};`;
      }
      return styles;
    }}

    .sdi-element-btn-icon,
    .sdi-element-btn-text {

      ${(props) => {
        let styles = ``;
        let colorObj = props.btnState.hover.color;

        if (!colorObj.custom) {
          styles += `color: ${colorObj.value};`;
        } else if (colorObj.custom) {
          styles += `color: ${colorObj.value};`;
        }
        return styles;
      }}
    }
  }
`

const getElementsBtnIcon = () => styled(CvtIcon)`
  ${(props) => {
    let styles = ``;
    let colorObj = props.iconState.default.color;

    if (!colorObj.custom) {
      styles += `${textColor(colorObj.value, colorObj.weight)}`;
    } else if (colorObj.custom) {
      styles += `color: ${colorObj.value};`;
    }

    return styles;
  }}

  font-size: ${Spacing.SPACING_24 - 4}px;
`

const getElementsBtnText = () => styled('span')`

  ${(props) => {
    let styles = ``;
    let colorObj = props.textState.default.color;

    if (!colorObj.custom) {
      styles += `${textColor(colorObj.value, colorObj.weight)}`;
    } else if (colorObj.custom) {
      styles += `color: ${colorObj.value};`;
    }

    return styles;
  }}

  padding-left: ${Spacing.SPACING_8 + Spacing.SPACING_2}px;
`


const getBlockPlusBtn = () => styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Spacing.SPACING_8}px;

  ${(props) => {
    let styles = ``;
    let backgroundColorObj = props.btnState.default.backgroundColor;

    if (!backgroundColorObj.custom) {
      styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
    } else if (backgroundColorObj.custom) {
      styles += `background: ${backgroundColorObj.value};`;
    }
    return styles;
  }}

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.32);
  border-radius: ${Spacing.SPACING_32 - Spacing.SPACING_2}px;
  border: none;
  box-sizing: border-box;

  &:hover:not(:disabled) {
    ${(props) => {
      let styles = ``;
      let backgroundColorObj = props.btnState.hover.backgroundColor;

      if (!backgroundColorObj.custom) {
        styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
      } else if (backgroundColorObj.custom) {
        styles += `background: ${backgroundColorObj.value};`;
      }
      return styles;
    }}

    .sdi-element-btn-icon,
    .sdi-sub-grid-element-btn-icon {
      ${(props) => {
        let styles = ``;
        let colorObj = props.btnState.hover.color;

        if (!colorObj.custom) {
          styles += `${textColor(colorObj.value, colorObj.weight)}`;
        } else if (colorObj.custom) {
          styles += `color: ${colorObj.value};`;
        }

        return styles;
      }}
    }
  }
`

const getBlockPlusBtnIcon = () => styled(CvtIcon)`
  ${(props) => {
    let styles = ``;
    let colorObj = props.iconState.default.color;

    if (!colorObj.custom) {
      styles += `${textColor(colorObj.value, colorObj.weight)}`;
    } else if (colorObj.custom) {
      styles += `color: ${colorObj.value};`;
    }

    return styles;
  }}

  font-size: ${Spacing.SPACING_16}px;
`

const getSubGridPlusBtn = () => styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Spacing.SPACING_8}px;
  width: ${Spacing.SPACING_32 + Spacing.SPACING_4 + Spacing.SPACING_2}px;
  height: ${Spacing.SPACING_32 + Spacing.SPACING_4 + Spacing.SPACING_2}px;

  ${(props) => {
    let styles = ``;
    let backgroundColorObj = props.btnState.default.backgroundColor;

    if (!backgroundColorObj.custom) {
      styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
    } else if (backgroundColorObj.custom) {
      styles += `background: ${backgroundColorObj.value};`;
    }
    return styles;
  }}

  border: 0px solid #E2E6ED;
  border-radius: 500%;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.04),0px 4px 8px rgba(0,0,0,0.32);
  box-sizing: border-box;

  &:hover:not(:disabled) {
    ${(props) => {
      let styles = ``;
      let backgroundColorObj = props.btnState.hover.backgroundColor;

      if (!backgroundColorObj.custom) {
        styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
      } else if (backgroundColorObj.custom) {
        styles += `background: ${backgroundColorObj.value};`;
      }
      return styles;
    }}

    .sdi-element-btn-icon,
    .sdi-sub-grid-element-btn-icon {
      ${(props) => {
        let styles = ``;
        let colorObj = props.btnState.hover.color;

        if (!colorObj.custom) {
          styles += `color: ${colorObj.value};`;
        } else if (colorObj.custom) {
          styles += `color: ${colorObj.value};`;
        }
        return styles;
      }}
    }
  }
`

const getSubGridPlusBtnIcon = () => styled(CvtIcon)`
  ${(props) => {
    let styles = ``;
    let colorObj = props.iconState.default.color;

    if (!colorObj.custom) {
      styles += `${textColor(colorObj.value, colorObj.weight)}`;
    } else if (colorObj.custom) {
      styles += `color: ${colorObj.value};`;
    }

    return styles;
  }}

  font-size: ${Spacing.SPACING_16}px;
`

const getDragItemsCloseBtn = () => styled(CvtButton)`
  z-index: 1000;
  background: transparent !important;
  color: #494c53 !important;

  width: ${Spacing.SPACING_24}px !important;
  height: ${Spacing.SPACING_24}px !important;

  margin: ${Spacing.SPACING_4}px !important;
  padding: ${Spacing.SPACING_4 + 3}px ${Spacing.SPACING_8 + 1}px ${Spacing.SPACING_8 + Spacing.SPACING_2}px ${Spacing.SPACING_12 - 1}px !important;

  display: flex;
  align-items: center !important;
  justify-content: center !important;

  &:hover {
    color: #000000 !important;
    background-color: #e2e6ed !important;
    border: 1px solid #000000 !important;
  }

  transform: rotate(135deg) !important;
  border: 1px solid #494c53 !important;
  border-radius: 500% !important;
  box-shadow: none !important;
`

const getDragItemWrapper = () => styled('div')``


const getDragItem = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Spacing.SPACING_8}px;

  &:hover {
    background-color: #e2e6ed;
    cursor: grab;
  }
`

const getDragItemIcon = () => styled('i')`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18.3px;
  line-height: 18.3px;
  display: flex;
  font-weight: 300;
  cursor: grab;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  border: 1px solid transparent;
  border-radius: ${Spacing.SPACING_8}px;
  color: #494c53;

  &:active,
  &:target {
    cursor: grabbing;
  }

  &-no_drag {
    cursor: pointer !important;
  }
`

const getTooltip = () => styled(CvtTooltip)``


export const CvtSectionDragItems = {
  name: 'CvtSectionDragItems',
  props: {
    vnodeId: {
      required: true,
    },
    usage: {
      type: String,
      required: true,
      default: 'block'
    },
    showPlusBtn: {
      type: Boolean,
      required: true,
      default: false
    },
    widgetClass: {
      type: String,
      required: true,
      default: GRID_CONTAINER_CLASS
    },
    excludeItem: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      allDragItems: allDragItems,
      open: false,
    }
  },
  computed: {
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      iconConfig: ({ iconConfig }) => iconConfig,
      dragElementsListBtnConfig: ({ globalButtonConfig }) => globalButtonConfig.locations.blockComponent.locations.dragElementsListBtn,
      dragElementsPlusBtnConfig: ({ globalButtonConfig }) => globalButtonConfig.locations.blockComponent.locations.dragElementsPlusBtn,
    }),
    ...mapGetters('globalTheme', {
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
    }),
    ...mapState('globalTheme', {
      elementDesktopSize: ({ elementSize }) => elementSize.gridStackDefaultSizeForDesktop,
      elementPhoneSize: ({ elementSize }) => elementSize.gridStackDefaultSizeForPhone,
      elementContainerDesktopSize: ({ elementSize }) => elementSize.gridContainerStackDefaultSizeForDesktop,
      builderElementsConfig: ({ globalTheme }) => globalTheme.builderElements,
    }),
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      viewMode: getters.VIEW_MODE,
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    newWidgetClasses() {
      return [
        { mobile: this.viewMode === 'phone', 'grid-stack-item-main': this.isUsageFromBlock },
        `newWidget ${this.widgetClass} ${this.widgetClass}-${this.vnodeId} ui-draggable icon-wrapper`,
      ]
    },
    dragItemsWrapClasses() {
      return {
        'drag-items-wrap': true,
        'opened': this.open,
        desktop: this.viewMode === 'desktop',
        mobile: this.viewMode === 'phone',
      }
    },
    elementsBtnClasses() {
      return {
        'sdi-element-btn': true,
      }
    },
    hideOnDesktop() {
      return (
        this.selected?.name === 'Block' && this.selected?.hideOnDesktop === true
      )
    },
    hideOnMobile() {
      return (
        this.selected?.name === 'Block' && this.selected?.hideOnMobile === true
      )
    },
    isDragItemsBtnDisabled() {
      return (
        (this.hideOnDesktop && this.viewMode === 'desktop') ||
        (this.hideOnMobile && this.viewMode === 'phone')
      )
    },
    isUsageFromBlock() {
      return this.usage === 'block'
    },
    isUsageFromSubGridContainer() {
      return this.usage === 'sub-grid-container'
    },
  },
  created() {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    backgroundColor = this.backgroundColor
    textColor = this.textColor
    borderColor = this.borderColor
    
    const elementDesktopSize = this.getDesktopElementSize()

    this.enabledDragItems = this.allDragItems
      .filter(dragItemElement => !this.excludeItem.includes(dragItemElement.name))
      .map((x) => {
        const desktop = elementDesktopSize[x.id]
        const phone = this.elementPhoneSize[x.id]
        return { ...x, desktop, phone }
      })
      .filter((dragItemElement) => {
        return this.builderElementsConfig.some((builderElement) => {
          return (builderElement.name === dragItemElement.name) && builderElement.enabledForGridBuilder
        })
      })
  },
  mounted() {
    this.$hub.$on(ELEMENT_DROPPED_ON_GRID_CONTAINER, (data) => {
      this.open = false;
    })
    this.$hub.$on(ELEMENT_DROPPED_ON_SUB_GRID_CONTAINER, (data) => {
      this.open = false;
    })
  },
  methods: {
    getDesktopElementSize() {
      if (this.widgetClass === GRID_CONTAINER_CLASS) {
        return this.elementDesktopSize
      }
      return this.elementContainerDesktopSize
    },
    openOrClose(value) {
      this.open = value
    },
    closeOnClickElseWhere (e) {
      const excludeFlags = ['drag-item__icon', 'ui-droppable', 'cvt-icon-plus', 'add-button-drag']
      const classList = [...e.target.classList].filter(x => excludeFlags.includes(x))
      if (classList.length === 0) {
        // this.open = false
      }
      if (classList.includes('cvt-icon-plus') || classList.includes('add-button-drag')) {
        if (e.target.parentElement !== this.$el) {
          this.open = false
        }
      }
    },
    dragItemHover(id, mouseLeave) {
      const configBgColor = this.config.CUSTOM_BUILDER_DRAG_ITEMS_STYLES?.customStyles.hoverBgColor;
      const dragItem = document.getElementById(id);
      const color = mouseLeave ? '#fff' : configBgColor || '#E2E6ED';
      dragItem.style.backgroundColor = color;
    },
  },

  render(h) {
    const MainWrap                = getMainWrap();
    const DragItemsOuterWrap      = getDragItemsOuterWrap();
    
    const ElementsBtn             = getElementsBtn();
    const ElementsBtnIcon         = getElementsBtnIcon();
    const ElementsBtnText         = getElementsBtnText();

    const BlockPlusBtn            = getBlockPlusBtn();
    const BlockPlusBtnIcon        = getBlockPlusBtnIcon();

    const SubGridPlusBtn          = getSubGridPlusBtn();
    const SubGridPlusBtnIcon      = getSubGridPlusBtnIcon();

    const DragItemsCloseBtn       = getDragItemsCloseBtn();
    const DragItemWrapper         = getDragItemWrapper();

    const DragItem                = getDragItem();
    const DragItemIcon            = getDragItemIcon();
    const Tooltip                 = getTooltip();

    const enabledDragItems = this.enabledDragItems.map((item) => {
      return (
        <DragItemWrapper
          id={item.id + '~' + this.vnodeId}
          key={'btn-' + item.name}
          class={this.newWidgetClasses}
          gs-w={item[this.viewMode] ? item[this.viewMode].w : ''}
          gs-h={item[this.viewMode] ? item[this.viewMode].h : ''}
          data-gs-width={item[this.viewMode] ? item[this.viewMode].w : ''}
          data-gs-height={item[this.viewMode] ? item[this.viewMode].h : ''}
          draggable="true"
        >
          <DragItem
            id={item.name + '~' + this.vnodeId}
            class={"grid-stack-item-content drag-item__icon ui-draggable-handle"}
            onMouseOver={() => this.dragItemHover(item.name + '~' + this.vnodeId, false) }
            onMouseLeave={() => this.dragItemHover(item.name + '~' + this.vnodeId, true) }
          >
            <DragItemIcon
              title={item.name}
              class={`icon ${item.icon} hover`}
              role="presentation"
            />
          </DragItem>
        </DragItemWrapper>
      )
    });

    const toolTips = this.enabledDragItems.map((item) => {
      return (
        <Tooltip
          key={'tooltip-' + item.name + this.vnodeId}
          target={item.id + '~' + this.vnodeId}
        >
          <strong>{this.$t(item.label)}</strong>
        </Tooltip>
      )
    });

    return (
      <MainWrap>

        {(this.isUsageFromBlock && this.showPlusBtn) && (
          <BlockPlusBtn
            class={this.elementsBtnClasses}
            v-show={!this.open}
            disabled={this.isDragItemsBtnDisabled}
            btnState={this.dragElementsPlusBtnConfig.state}
            onClick={() => this.openOrClose(true)}
          >
            <BlockPlusBtnIcon 
              class={'sdi-element-btn-icon'} 
              icon={this.iconConfig?.sectionUi?.addElementOld || 'cvt-icon-add-plus'}
              iconState={this.dragElementsPlusBtnConfig.btnIcon.state}
            />
          </BlockPlusBtn>
        )}
        
        {(this.isUsageFromBlock && !this.showPlusBtn) && (
          <ElementsBtn
            class={this.elementsBtnClasses}
            v-show={!this.open}
            disabled={this.isDragItemsBtnDisabled}
            btnState={this.dragElementsListBtnConfig.state}
            onClick={() => this.openOrClose(true)}
          >
            <ElementsBtnIcon 
              class={'sdi-element-btn-icon'} 
              icon={this.iconConfig?.sectionUi?.addElementNew || 'cvt-icon-add-plus'}
              iconState={this.dragElementsListBtnConfig.btnIcon.state}
            />
            <ElementsBtnText 
              class={'sdi-element-btn-text'}
              textState={this.dragElementsListBtnConfig.btnText.state}
            >
              {this.$t('editor.section.action.drag.text')}
            </ElementsBtnText>
          </ElementsBtn>
        )}

        {(this.isUsageFromSubGridContainer && this.showPlusBtn) && (
          <SubGridPlusBtn
            class={this.elementsBtnClasses}
            v-show={!this.open}
            disabled={this.isDragItemsBtnDisabled}
            btnState={this.dragElementsPlusBtnConfig.state}
            onClick={() => this.openOrClose(true)}
          >
            <SubGridPlusBtnIcon 
              class={'sdi-sub-grid-element-btn-icon'} 
              icon={this.iconConfig?.sectionUi?.subGridAddElement || 'cvt-icon-add-plus'}
              iconState={this.dragElementsPlusBtnConfig.btnIcon.state}
            />
          </SubGridPlusBtn>
        )}

        <DragItemsOuterWrap 
          class={this.dragItemsWrapClasses}
          v-show={this.open}
        >
          <DragItemsCloseBtn
            color={"dark"}
            icon={"cvt-icon-plus"}
            size={"lg"}
            onClick={() => this.openOrClose(false)}
          />
          { enabledDragItems } 
          { toolTips } 
        </DragItemsOuterWrap>
      </MainWrap>
    )
  }
}
