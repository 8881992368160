<div id="page-engine-wrapper" class="slide-engine-wrapper">
  <cvt-froala-wrapper :wrapper-id="'froala-portal-sda-popup-builder'" />
  <div
    ref="pageEngineWrapper"
    class="page-engine-wrapper-popup-container"
    @mouseover="mouseOverTarget"
    @click="blockTargetClick"
  >
  
  
    <div
      class="page-engine typography page-engin-margin-top grid-builder"
      :class="pageEngineClass"
      id="page-engine"
      :style="styles"
    >
      <cvt-sap-close-btn
        v-if="isStandalonePopupActive"
        :close-btn-position="sdaCloseButtonPosition()"
      />
  
  
      <template v-for="obj in clonedVDom">
        <component
          v-if="!isReload"
          :is="obj.node.tag"
          :fbNode="obj.node"
          :key="obj.key"
          v-bind="obj.node.data.props"
        />
      </template>
  
    </div>
  </div>
</div>
