
<div>
  <h1>Select</h1>
  <div class="w-100">
    <cvt-select
      v-for="(variant, index) in variants"
      :key="'select-' + index"
      :value="value"
      :label="variant.label"
      :help-text="variant.helpText"
      :multiple="variant.multiple"
      :mode="variant.mode"
      :select-disabled="variant.selectDisabled"
      :floating-label="variant.floatingLabel"
    >
      <option
        v-for="(item, i) in records"
        :key="i"
        :value="item.value"
        v-text="item.label"
      />
    </cvt-select>

    <cvt-select
      :value="value"
      label="Select with Custom Template"
      help-text="Choose your framework of choice"
      :multiple="false"
      mode="dropdown"
    >
      <optgroup label="Libraries">
        <option
          value="fuze-js"
          label="Fuse.js"
        >
          <span style="float:left;">Fuze.js</span>
          <span style="float:right;">fuse-js</span>
        </option>
        <option
          value="lit-element"
          label="litElement"
        >
          <span style="float:left;">litElement</span>
          <span style="float:right;">lit-element</span>
        </option>
        <option
          value="socket-io"
          label="Socket.io"
        >
          <span style="float:left;">Socket.io</span>
          <span style="float:right;">socket-io</span>
        </option>
      </optgroup>
    </cvt-select>

    <cvt-select
      :value="value"
      label="Select with Remote Search"
      help-text="Choose your framework of choice"
      mode="tags"
      :multiple="true"
      :fetch-suggestions="fetchSuggestions"
      :floating-label="false"
    />
  </div>
</div>
