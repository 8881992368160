import Vue from '../../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import CvtButton from '../../molecules/Button'
import { CvtIcon } from '../../molecules/icon/icon'
import { CvtTooltip } from '../../molecules/tooltip/CvtTooltip'
import CvtHeading from './heading'
import { LEFT_SIDEBAR_FRESHWORKS, LEFT_SIDEBAR_STANDARD } from '../../constants'

Vue.use(VueEmotion)

let Spacing, Radii, Border

let textFontWeight, borderColor, backgroundColor, textFontSize, textLineHeight

const getExpandedLayout = () => styled('div')`
  overflow: auto;
  ${(props) => {
    return props.type === LEFT_SIDEBAR_FRESHWORKS
      ? `padding: 15px 15px;`
      : `padding: ${Spacing.SPACING_24}px ${Spacing.SPACING_18 + 2}px;`
  }}
  border-left: ${Border.SIZE_1}px solid;

  ${(props) =>
    props.mode == 'DARK'
      ? borderColor({ themeing: 'dark' }, 500)
      : borderColor({ themeing: 'dark' }, 100)};

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${Radii.RADIUS_PX_8}px;
    ${(props) =>
      props.mode == 'DARK'
        ? backgroundColor({ themeing: 'dark' }, 600)
        : backgroundColor({ themeing: 'dark' }, 100)}
  }
`

const LayoutSectionWrapper = styled('div')`
  width: 300px;
`

const getAddLayoutSectionWrapper = () => styled('div')`
  display: flex;
  justify-content: center;
  padding: ${Spacing.SPACING_18}px;
`

const AddLayoutSectionBtn = styled(CvtButton)``

const ParentLayoutSection = styled('div')`
  display: flex;
  align-items: center;
  ${(props) => {
    return props.type === LEFT_SIDEBAR_FRESHWORKS
      ? `
      position: relative;
      top: -8px;
      `
      : ``
  }}
`

const LayoutSectionActions = styled('span')`
  display: flex;
  margin-left: auto;
`

const getLayoutSectionSetting = () => styled(CvtIcon)`
  margin-left: ${Spacing.SPACING_16}px;
  cursor: pointer;
`

const getLayoutSectionCopy = () => styled(CvtIcon)`
  margin-left: ${Spacing.SPACING_12}px;
  cursor: pointer;
`

const getLayoutSectionDelete = () => styled(CvtIcon)`
  margin-left: ${Spacing.SPACING_12}px;
  cursor: pointer;
`

const getBackButton = () => styled(CvtButton)`
  margin-right: ${Spacing.SPACING_8}px;
`

export const CvtLayouts = {
  name: 'CvtLayouts',
  components: {
    CvtTooltip
  },
  data() {
    return {
      currentLayout: [],
      dragIdx: null,
      refIdx: null
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
    layouts: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      default: 'layout-manager'
    },
    parent: {
      type: Object,
      default: () => {}
    },
    parentName: {
      type: String,
      default: 'Untitled'
    },
    hasParent: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: 'Add'
    },
    totalNodes: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: LEFT_SIDEBAR_STANDARD
    }
  },
  methods: {
    onDragOver(e) {
      e.preventDefault()
      const dataTransfer = e.dataTransfer
      const target = e.target
      const el = this.$refs.layoutContainer
      dataTransfer.dropEffect = 'move'

      if (
        target !== this.dragEl &&
        target.classList.contains('layout-section')
      ) {
        let pos = target.getBoundingClientRect()
        let next =
          (e.clientY - pos.top) / pos.height > 0.5 ||
          (e.clientX - pos.left) / pos.width > 0.5
        let refNode = next
          ? target.nextSibling == this.dragEl
            ? target
            : target.nextSibling
          : null
        if (el !== null) {
          this.refIdx = refNode ? refNode.dataset.id : this.totalNodes - 1
          el.insertBefore(this.dragEl, refNode)
        }
      }
    },
    onDragEnd(e) {
      e.preventDefault()
      const el = this.$refs.layoutContainer
      this.dragEl.classList.remove('ghost')
      if (el !== null) {
        el.removeEventListener('dragover', this.onDragOver, false)
        el.removeEventListener('dragend', this.onDragEnd, false)
      }

      if (this.dragIdx && this.refIdx) {
        this.$emit('move-node', this.dragIdx, this.refIdx)
      }
    },
    onDragStart(e) {
      
      const el = this.$refs.layoutContainer
      this.dragEl = e.target
      this.dragIdx = this.dragEl?.dataset?.id

      if (el !== null) {
        el.addEventListener('dragover', this.onDragOver, false)
        el.addEventListener('dragend', this.onDragEnd, false)
      }

      setTimeout(() => {
        this.dragEl.classList.add('ghost')
      }, 0)
    },
    addSection() {
      this.currentLayout.push({
        label: this.$t('site.input.default_value'),
        name: 'untitled'
      })
    },
    deleteSection(e, index) {
      e.stopPropagation()
      this.currentLayout.splice(index, 1)
    }
  },
  mounted() {
    const layoutContainer = this.$refs.layoutContainer

    this.onDragStart = this.onDragStart.bind(this)
    this.onDragOver = this.onDragOver.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)

    if (layoutContainer !== null) {
      layoutContainer.addEventListener('dragstart', this.onDragStart)
    }
  },
  updated() {
    const layoutContainer = this.$refs.layoutContainer

    if (layoutContainer !== null) {
      layoutContainer.removeEventListener('dragstart', this.onDragStart)
      layoutContainer.addEventListener('dragstart', this.onDragStart)
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      iconConfig: ({ iconConfig }) => iconConfig
    })
  },
  beforeMount() {
    this.currentLayout = this.layouts
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
  },
  render: function (h) {
    const ExpandedLayout = getExpandedLayout()
    const AddLayoutSectionWrapper = getAddLayoutSectionWrapper()
    const iconConfig = this.iconConfig?.leftSidebar?.layout
    const LayoutSectionCopy = getLayoutSectionCopy()
    const LayoutSectionDelete = getLayoutSectionDelete()
    const LayoutSectionSetting = getLayoutSectionSetting()
    const BackButton = getBackButton()
    const parentLayoutVar = (
      <ParentLayoutSection
        type={this.type}
        onMouseover={(e) => this.$emit('parent-layout-mouse-over', e)}
        onMouseenter={(e) => this.$emit('parent-layout-mouse-enter', e)}
      >
        <BackButton
          mode={this.mode}
          icon='chevron-left'
          actionIcon={true}
          color='dark'
          shape='pill'
          onClick={(e) => this.$emit('parent-layout-click', e)}
        ></BackButton>
        <span>{this.parentName}</span>
        <LayoutSectionActions>
          <LayoutSectionSetting
            id={this.parentName + '-settings'}
            icon={iconConfig['settings']}
            nativeOnClick={(e) => this.$emit('parent-layout-setting', e)}
          ></LayoutSectionSetting>
          <CvtTooltip
            placement='top'
            offset='5'
            fallbackPlacement='right'
            delay='100'
            color='dark'
            mode={this.mode}
            noFade={true}
            innerPadding={8}
            ref='tooltip'
            target={this.parentName + '-settings'}
          >
            Settings
          </CvtTooltip>
          <LayoutSectionCopy
            id={this.parentName + '-copy'}
            icon={iconConfig['duplicate']}
            nativeOnClick={(e) => this.$emit('parent-layout-copy', e)}
          ></LayoutSectionCopy>
          <CvtTooltip
            placement='top'
            offset='5'
            fallbackPlacement='right'
            delay='100'
            color='dark'
            mode={this.mode}
            noFade={true}
            innerPadding={8}
            ref='tooltip'
            target={this.parentName + '-copy'}
          >
            Copy
          </CvtTooltip>
          <LayoutSectionDelete
            id={this.parentName + '-delete'}
            icon={iconConfig['delete']}
            nativeOnClick={(e) => this.$emit('parent-layout-delete', e)}
          ></LayoutSectionDelete>
          <CvtTooltip
            placement='top'
            offset='5'
            fallbackPlacement='right'
            delay='100'
            color='dark'
            mode={this.mode}
            noFade={true}
            innerPadding={8}
            ref='tooltip'
            target={this.parentName + '-delete'}
          >
            Delete
          </CvtTooltip>
        </LayoutSectionActions>
      </ParentLayoutSection>
    )

    return (
      <div>
        {this.type === LEFT_SIDEBAR_FRESHWORKS && !this.hasParent && (
          <CvtHeading>LAYOUTS</CvtHeading>
        )}
        {this.type === LEFT_SIDEBAR_FRESHWORKS && this.hasParent && (
          <CvtHeading>{parentLayoutVar}</CvtHeading>
        )}
        <ExpandedLayout mode={this.mode} type={this.type}>
          {this.type !== LEFT_SIDEBAR_FRESHWORKS &&
            this.hasParent &&
            parentLayoutVar}
          <LayoutSectionWrapper ref='layoutContainer'>
            {this.$slots.default}
          </LayoutSectionWrapper>

            <AddLayoutSectionWrapper>
              <AddLayoutSectionBtn
                mode={this.mode}
                icon={iconConfig['plus'] || 'plus'}
                text={this.buttonText}
                onClick={(e) => this.$emit('add-node', e)}
              ></AddLayoutSectionBtn>
            </AddLayoutSectionWrapper>

        </ExpandedLayout>
      </div>
    )
  }
}
