<template>
  <div v-show="visible" class="mouse-follower text-uppercase p-2" :style="coords" v-text="targetName"></div>
</template>

<script>
  import { ELEMENT_NAME_MAP } from '../lib/helper-classes'
  import {mapGetters, mapMutations, mapActions} from 'vuex'
  import * as actions from '../store/modules/actions'
  import * as mutations from '../store/modules/mutations'
  import * as getters from '../store/modules/getters'

  export default {

    data () {
      return {
        coords: {
          top: -200,
          left: -200
        },

        offset: 10,
        holdingModifier: false,
        modifierKey: 'ctrlKey'
      }
    },
    computed: {
      ...mapGetters({
        target: getters.SELECTOR_TARGETED
      }),

      targetName () {
        return this.target ? ELEMENT_NAME_MAP[this.target.name] : 'NO TARGET'
      },

      visible () {
        return this.target && this.holdingModifier
      }
    },

    mounted () {
      // window.addEventListener('mousemove', this.follow, true)
      // window.addEventListener('keydown', this.watchShift, true)
      // window.addEventListener('keyup', this.watchShift, true)
    },

    beforeDestroy () {
      // window.removeEventListener('mousemove', this.follow, true)
      // window.removeEventListener('keydown', this.watchShift, true)
      // window.removeEventListener('keyup', this.watchShift, true)
    },


    methods: {
      calculateCoords ({clientX, clientY}) {
        let { scrollTop, scrollLeft } = document.body

        return {
          top: `${clientY + scrollTop + this.offset}px`,
          left: `${clientX + scrollLeft + this.offset}px`
        }
      },

      follow ({clientX, clientY}) {
        this.coords = this.calculateCoords({clientX, clientY})
      },

      watchShift (e) {
        this.holdingModifier = e[this.modifierKey]
      }
    }
  }
</script>

<style lang="scss">
  @import "@/scss/utils";

  .mouse-follower {
    min-height: 20px;
    position: fixed;
    background-color: $brand-1;
    color: $off-white;
    z-index: 900000;
  }
</style>
