import Vue from '../../lib/utils/vue'
import v from 'validator'
import { styled, VueEmotion } from '@egoist/vue-emotion'
import * as getters from '../../../builder/src/js/store/modules/getters'
import * as actions from '../../../builder/src/js/store/modules/actions'
import * as mutations from '../../../builder/src/js/store/modules/mutations'
import { mapGetters, mapState, mapActions } from 'vuex'
import CvtRightSidebarNavigator from '../../components/molecules/RightSidebarNavigator'
import { CvtSiteThumbnailAndActions } from '../organisms/right-sidebar/site-thumbnail-and-actions'
import {
  CvtUrlRadioButtonActions,
  PlaceholderUrlRadioButtonActions,
} from '../organisms/right-sidebar/url-radio-button-actions'
import { CvtCustomDomainAction } from '../organisms/publish/custom-domain-action'
import { LEFT_SIDEBAR_STANDARD, FRESHWORKS } from '../constants'
import { Notification } from 'element-ui'
import VueI18n from 'vue-i18n'
import { parseDomain, ParseResultType, ValidationErrorType } from 'parse-domain'
import { CvtAnalyticsSidebar } from '../organisms/right-sidebar/analytics-sidebar'

Vue.use(VueEmotion)
Vue.use(VueI18n)

let Spacing, backgroundColor;

const getMainContainer = () => styled('div')`
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  width: ${3 * Spacing.SPACING_96 + Spacing.SPACING_12}px;
`

const getContainer = () => styled('div')``

const getRightSidebarNavigator = () => styled(CvtRightSidebarNavigator)``

const getSiteThumbnailAndActions = () => styled(CvtSiteThumbnailAndActions)``

const getUrlRadioButtonActions = () => styled(CvtUrlRadioButtonActions)``

const getCustomDomainActions = () => styled(CvtCustomDomainAction)`
  width: ${3 * Spacing.SPACING_96 + Spacing.SPACING_12}px;
`

const getPlaceholderUrlRadioButtonActions = () =>
  styled(PlaceholderUrlRadioButtonActions)``

const getAnalyticsSideBar = () => styled(CvtAnalyticsSidebar)``

export const CvtRightSidebar = {
  name: 'CvtRightSidebar',
  components: {
    CvtSiteThumbnailAndActions,
    CvtUrlRadioButtonActions,
    CvtAnalyticsSidebar,
  },
  data() {
    return {
      navigationStep: 1,
      domain: '',
      showDomainInstruction: false,
      linkBtnText: this.$t(
        'custom_domain.ui.domain_instruction.verify_button.text.verify',
      ),
      linkBtnLoading: false,
      linkSuccessMessage: '',
      linkErrorMessage: '',
      publishedUrlConfigured: false,
      customUrlConfigured: false,
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
      required: false,
    },
    rightSidebarRadioGroupLoading: {
      type: Boolean,
      default: true,
      required: false,
    },
    stepTitle: {
      type: String,
      required: true,
    },
    imgSrc: {
      type: String,
      required: true,
    },
    imgAltText: {
      type: String,
      required: true,
    },
    imgWidth: {
      type: Number,
      required: false,
      default: 140,
    },
    imgHeight: {
      type: Number,
      required: false,
      default: 116,
    },
    siteLabelStatus: {
      type: String,
      required: true,
    },
    siteLabelWidth: {
      type: Number,
      required: false,
      default: 87,
    },
    siteLabelHeight: {
      type: Number,
      required: false,
      default: 26,
    },
    radioGroupTheme: {
      type: String,
      required: false,
    },
    radioGroupOrientation: {
      type: String,
      required: false,
    },
    radioGroupSize: {
      type: String,
      required: false,
    },
    radioGroupInitialValue: {
      type: String,
      default: true,
      required: false,
    },
    site: {
      type: Object,
      required: true,
      default: () => {},
    },
    siteUrl: {
      type: String,
      required: false,
    },
    isPublishedUrlSet: {
      type: Boolean,
      default: false,
    },
    isCustomUrlSet: {
      type: Boolean,
      default: false,
    },
    disablePublish: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
    }),
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      user: getters.AUTH_GET_USER,
      linkedDomains: getters.MARKETPLACE_SITE_DOMAINS,
      analytics: getters.SITE_ANALYTICS,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      BorderStyle: ({ globalTheme }) => globalTheme.BorderStyle,
      orgName: ({ globalTheme }) => globalTheme?.OrgName !== undefined ? globalTheme.OrgName : LEFT_SIDEBAR_STANDARD,
    }),
    verificationKey() {
      return `${this.user.orgID}-site-verification=${this.user.projectID}`
    },
    domainInfo() {
      return {
        verificationKey: this.verificationKey,
        domain: this.domain.trim().toLowerCase(),
        cname: this.config.CUSTOM_HOSTNAME_CONFIG.cdn_cname,
      }
    },
    connectBtnOutlined() {
      return this.orgName === FRESHWORKS
    },
    unlinkBtnTheme() {
      return this.orgName === FRESHWORKS ? 'light' : 'danger'
    },
    connectBtnColor() {
      return this.orgName === FRESHWORKS ? 'light' : 'success'
    },
    popUpBtnColor() {
      return this.orgName === FRESHWORKS ? 'light' : 'warning'
    },
    publishedUrlState: {
      get: function () {
        if (this.isPublishedUrlSet) {
          return true
        }
        if (this.publishedUrlConfigured) {
          return true
        }
        return false
      },
      set: function (newVal) {
        this.publishedUrlConfigured = newVal
        return newVal
      },
    },
    customUrlState: {
      get: function () {
        if (this.isCustomUrlSet) {
          return true
        }
        if (this.customUrlConfigured) {
          return true
        }
        return false
      },
      set: function (newVal) {
        this.customUrlConfigured = newVal
        return newVal
      },
    },
    templatesDefaultViewFormat: {
      get: function () {
        if (this.templatesViewFormat === null) {
          return this.config.MY_PAGES_DEFAULT_VIEW
            ? this.config.MY_PAGES_DEFAULT_VIEW
            : 'GRID'
        }
        return this.templatesViewFormat
      },
      set: function (newVal) {
        this.templatesViewFormat = newVal
      },
    },
  },
  created() {
    Spacing = this.Spacing
    backgroundColor = this.backgroundColor
    this.getSiteAnalytics(30);
  },
  methods: {
    ...mapActions({
      intercomTrack: actions.INTERCOM_TRACK,
      initAssetManager: actions.ASSET_MANAGER_INIT,
      mixpanelTrack: actions.MIXPANEL_TRACK,
      publish: actions.SITE_PUBLISH,
      // Deviation from launch, as templateId needs to be supplied as siteId
      loadDomains: actions.MARKETPLACE_LOAD_DOMAINS,
      connectDomain: actions.CUSTOM_DOMAIN_CONNECT,
      disconnectDomain: actions.CUSTOM_DOMAIN_DISCONNECT,
      siteLoadAnalytics: actions.SITE_LOAD_ANALYTICS,
    }),
    simpleNavBtnClicked(event, step) {
      this.navigationStep = step
      this.$emit('simpleNavBtnClicked', step)
      if (step === 0) {
        // reset step and emit close event
        this.navigationStep = 1
        this.$emit('rightSidebarClosed')
      }
    },
    copyTemplateBtnClicked(event) {
      this.$emit('copyTemplateBtnClicked', event)
    },
    editSiteBtnClicked(event) {
      this.$emit('editSiteBtnClicked', event)
    },
    archiveTemplateBtnClicked(event) {
      this.$emit('archiveTemplateBtnClicked', event)
    },
    connectCustomDomainsBtnClicked(event, step) {
      this.navigationStep = step
    },
    radioBtnClicked(event) {},
    editCustomUrlBtnClicked(event, step) {
      this.navigationStep = step
    },
    async publishSite(event) {
      try {
        this.$store.commit(mutations.SITE_SET_DATA, this.site)
        const { errors } = await this.publish(this.site, event)

        if (errors && errors.length) {
          console.error(errors)
        } else {
          this.publishedUrlState = true
          this.siteLabelStatus = 'published'
          this.published()
        }
      } catch (err) {
        console.error('Failed to publish the site', err)
      }
    },
    published() {
      this.intercomTrack({
        event: 'published-site',
        context: {
          id: this.site.id,
          url: this.site.siteUrl,
        },
      })
      this.mixpanelTrack({
        event: 'Site Published',
        category: 'templates',
        data: {
          page_id: this.site.id,
          page_url: this.site.siteUrl,
        },
      })
    },
    async validateDomainInput() {
      // Given input parts will be converted toUnicode if needed and converted to lowercase.
      const parseResult = parseDomain(this.domain.toLowerCase())
      const { subDomains, domain, topLevelDomains } = parseResult
      let showError = false
      // Error message.
      let customDomainErrorMessage
      // Given input is validated against RFC 1034.
      if (parseResult.type === ParseResultType.Listed) {
        // When parseResult.type === LISTED.
        // Then perform APEX check.
        if (!subDomains.length || subDomains[0] === 'www') {
          // When the domain is APEX.
          // Then display APEX domain warning.
          this.openApexDialog()
        } else if (v.isFQDN(this.domain) === false) {
          // When the hostname is not a FQDN.
          // Then perform FQDN check (Final redundancy check before Moving on to API).
          // Then set the showError flag to true.
          showError = true
          // Then set the message.
          customDomainErrorMessage =
            'custom_domain.error.domain_validation.listed.not_fqdn'
        } else {
          // When all checks have passed.
          // Then display DNS instructions.
          this.showDomainInstruction = true
        }
      } else if (parseResult.type === ParseResultType.NotListed) {
        // When the parseResult.type is NOT_LISTED.
        // Then set the showError flag to true.
        showError = true
        // When hostname is valid, but not listed in the downloaded public suffix list.
        // Then set the message.
        customDomainErrorMessage =
          'custom_domain.error.domain_validation.not_listed'
      } else if (parseResult.type === ParseResultType.Invalid) {
        // When the parseResult.type is INVALID.
        // Then set the showError flag to true.
        showError = true
        // When input does NOT validate against RFC 1034.
        // Returns an object that with an errors(key): array, containing multiple validation error objects.
        // The structure of the errors array item object will be as follows:
        //{
        // column: number
        // message: string // example: 'Label \"https://user@www\" contains invalid character \":\" at column 6.'
        // type: string // example: 'LABEL_INVALID_CHARACTER'
        //}
        // Here we will notify the end user of the errors one by one until none remain.
        if (parseResult.errors.length > 0) {
          // Then the parseResult object contains a key "errors".
          // When that errors key has a length greater than 0.
          // Then notify the user with the information in errors[0].
          // When given input exceeds 255 characters.
          if (
            parseResult.errors[0].type === ValidationErrorType.DomainMaxLength
          ) {
            // Then set the message.
            customDomainErrorMessage =
              'custom_domain.error.domain_validation.invalid.domain_maxlength'
          }
          // When a domain label < 1 character.
          if (
            parseResult.errors[0].type === ValidationErrorType.LabelMinLength
          ) {
            // Then set the message.
            customDomainErrorMessage =
              'custom_domain.error.domain_validation.invalid.labelminlength'
          }
          // When a domain label exceeds 63 characters.
          if (
            parseResult.errors[0].type === ValidationErrorType.LabelMaxLength
          ) {
            // Then set the message.
            customDomainErrorMessage =
              'custom_domain.error.domain_validation.invalid.labelmaxlength'
          }
          // When ValidationErrorType is an invalid character.
          if (
            parseResult.errors[0].type ===
            ValidationErrorType.LabelInvalidCharacter
          ) {
            // The try catch avoids a TypeError thrown by new URL
            try {
              // Then create a new URL to easily extract the protocol.
              const parseUrl = new URL(this.domain)
              // When the protocol exists
              if (parseUrl.protocol) {
                // Then set the message.
                customDomainErrorMessage =
                  'custom_domain.error.domain_validation.invalid.invalid_character.protocol'
              }
            } catch {
              // When a Non protocol related invalid character exists.
              // Then set the message.
              customDomainErrorMessage =
                'custom_domain.error.domain_validation.invalid.invalid_character.character'
            }
          }
        }
      } else if (parseResult.type === ParseResultType.Ip) {
        // When the parseResult.type is IP.
        // Then set the showError flag to true.
        showError = true
        // When input is an IP address and therefore invalid.
        // Then set the message.
        customDomainErrorMessage =
          'custom_domain.error.domain_validation.invalid.ip'
      } else if (parseResult.type === ParseResultType.Reserved) {
        // When the parseResult.type is RESERVED.
        // Then set the showError flag to true.
        showError = true
        // When the topLevelDomain is reserved.
        const reservedTLDS = [
          'localhost',
          'local',
          'example',
          'invalid',
          'test',
        ]
        if (
          reservedTLDS.indexOf(parseResult.hostname) !== -1 &&
          !parseResult.hostname
        ) {
          // Then set the message.
          customDomainErrorMessage = `${parseResult.hostname} custom_domain.error.domain_validation.reserved.tld`
        }
        // NOTE: Empty custom domain input results in an empty hostname, which types as RESERVED.
        // When the custom domain input / hostname is empty.
        if (!parseResult.hostname) {
          // Then set the message
          customDomainErrorMessage =
            'custom_domain.error.domain_validation.reserved.empty_field'
        }
        // When an unknown reserved type is entered.
        else {
          // Then set the message.
          customDomainErrorMessage =
            'custom_domain.error.domain_validation.reserved.unknown'
        }
      }
      // When the error flag is true.
      if (showError) {
        // Then show the error message
        Notification.error({
          message: this.$t(customDomainErrorMessage),
          position: 'bottom-right',
        })
        // Then reset the error message
        customDomainErrorMessage = ''
      }
    },
    async linkDomain() {
      // When the verification button is clicked

      // Then check for siteId and domain before request

      // Not a user notification
      if (!this.site.id) {
        console.debug('Issue validating prior to request - Missing siteId')
        return
      }

      // Then display a message to the user
      if (!this.domain) {
        Notification.error({
          message: this.$t(
            'custom_domain.error.domain_validation.reserved.empty_field',
          ),
          position: 'bottom-right',
        })
        return
      }

      // Then adjust the UI
      this.linkBtnLoading = true
      this.linkBtnText = this.$t(
        'custom_domain.ui.domain_instruction.verify_button.text.verifying',
      )

      // Then submit the domain for connection
      this.connectDomain({ siteId: this.site.id, domain: this.domain }).then(
        async (response) => {
          const check = response.data.check
          let message = response.data.message
          if (check && message === 'owner_match') {
            // Then set the flag for an existing domain
            this.unlinkExistingDomain = true
            // Prompt to unlink existing record
            this.openUnlinkDialog(this.site.id, this.domain)
          } else if (!check && message === 'owner_mismatch') {
            // When owner doesn't match
            // Then set the message to alert user:
            // the domain is already linked and they don't have permissions to unlink it.
            message =
              message === 'owner_mismatch'
                ? 'custom_domain.error.domain_already_linked_and_not_owner'
                : message
          } else if (!check && message === 'already_linked') {
            // Then update the domains list in state
            await this.loadDomains(this.site.id)
            // When the siteId(s) match, domain already linked
            // Then alert the user that the domain is already linked
            this.linkSuccessMessage = this.$t(
              'custom_domain.error.domain_already_linked',
            )
            setTimeout(() => {
              this.linkSuccessMessage = ''
            }, 5000)
          }
          if (check) {
            // Then update the domains list in state
            this.loadDomains(this.site.id)
            // When the preceeding code has finished without error
            // Then adjust the UI
            this.linkBtnLoading = false
            this.linkBtnText = this.$t(
              'custom_domain.ui.domain_instruction.verify_button.text.verify',
            )
            // Then reset the domain
            this.clearDomain()
            // Then display a success message to the end user
            this.linkSuccessMessage = this.$t(message)
            setTimeout(() => {
              this.linkSuccessMessage = ''
            }, 5000)
          } else if (!check && message) {
            // TODO:
            // The connect button in custom-domain-actions.jsx is not reactive and
            // its disable attribute is reliant on showDomainInstructions value of false.
            // This needs button needs its own disabling property to fix the issue.

            // Then adjust the UI
            this.linkBtnLoading = false
            this.linkBtnText = this.$t(
              'custom_domain.ui.domain_instruction.verify_button.text.try_again',
            )
            // Then reset the domain
            this.clearDomain()
            // Then show the error message
            Notification.error({
              message: this.$t(message),
              position: 'bottom-right',
            })
          }
        },
      )
    },
    openApexDialog() {
      this.$emit('apexDialog', true)
    },
    clearDomain() {
      this.showDomainInstruction = false
      this.domain = ''
    },
    updateDomain(domain) {
      this.domain = domain
    },
    goToAnalyticsMain(evt) {
      //Go to analytics page
      this.navigationStep = 3
    },
    async getSiteAnalytics(rangeWindow) {
      await this.siteLoadAnalytics({ 
        window: rangeWindow,
        siteId: this.site?.id,
      });
    },
    customDomainInputReset () {
      this.showDomainInstruction = false;
    }
  },
  render: function (h) {
    const MainContainer = getMainContainer()
    const StepOneContainer = getContainer()
    const StepTwoContainer = getContainer()
    const AnalyticsContainer = getContainer()

    const RightSidebarNavigator = getRightSidebarNavigator()
    const SiteThumbnailAndActions = getSiteThumbnailAndActions()
    const UrlRadioButtonActions = getUrlRadioButtonActions()
    const CustomDomainActions = getCustomDomainActions()
    const PlaceholderUrlRadioButtonActions =
      getPlaceholderUrlRadioButtonActions()

    const AnalyticsSideBar = getAnalyticsSideBar()

    return (
      <MainContainer>
        {this.navigationStep === 1 && (
          <StepOneContainer>
            <RightSidebarNavigator
              mode={this.mode}
              step={this.navigationStep}
              stepTitle={this.stepTitle}
              onSimpleNavBtnClicked={(event, step) =>
                this.simpleNavBtnClicked(event, step)
              }
            ></RightSidebarNavigator>

            <SiteThumbnailAndActions
              mode={this.mode}
              imgSrc={this.imgSrc}
              imgAltText={this.imgAltText}
              imgWidth={this.imgWidth}
              imgHeight={this.imgHeight}
              siteStatus={this.siteLabelStatus}
              siteWidth={this.siteLabelWidth}
              siteHeight={this.siteLabelHeight}
              onCopyTemplateBtnClicked={(event) =>
                this.copyTemplateBtnClicked(event)
              }
              onEditSiteBtnClicked={(event) => this.editSiteBtnClicked(event)}
              onArchiveTemplateBtnClicked={(event) =>
                this.archiveTemplateBtnClicked(event)
              }
              onAnalyticsBtnClicked={(event) => this.goToAnalyticsMain(event)}
            ></SiteThumbnailAndActions>

            <UrlRadioButtonActions
              mode={this.mode}
              radioGroupTheme={this.radioGroupTheme}
              radioGroupOrientation={this.radioGroupOrientation}
              radioGroupSize={this.radioGroupSize}
              radioGroupInitialValue={this.radioGroupInitialValue}
              siteStatus={this.siteLabelStatus}
              disablePublish={this.disablePublish}
              siteUrl={this.siteUrl}
              isPublishedUrlSet={this.publishedUrlState}
              isCustomUrlSet={this.customUrlState}
              linkedDomains={this.linkedDomains}
              onRadioBtnClicked={(event) => this.radioBtnClicked(event)}
              onEditCustomUrlBtnClicked={(event, step) =>
                this.editCustomUrlBtnClicked(event, step)
              }
              onConnectCustomDomainsBtnClicked={(event, step) =>
                this.connectCustomDomainsBtnClicked(event, step)
              }
              onSitePublishClicked={(event) => this.publishSite(event)}
            ></UrlRadioButtonActions>
          </StepOneContainer>
        )}

        {this.navigationStep === 2 && (
          <StepTwoContainer>
            <RightSidebarNavigator
              mode={this.mode}
              step={this.navigationStep}
              stepTitle={this.$t('custom_domain.ui.domain_instruction.title')}
              onSimpleNavBtnClicked={(event, step) =>
                this.simpleNavBtnClicked(event, step)
              }
            ></RightSidebarNavigator>

            <CustomDomainActions
              mode={this.mode}
              componentUsage={'right-sidebar'}
              linkedDomains={this.linkedDomains}
              domain={this.domain}
              domainInfo={this.domainInfo}
              linkBtnLoading={this.linkBtnLoading}
              linkBtnText={this.linkBtnText}
              site={this.site}
              show-domain-instruction={this.showDomainInstruction}
              theme={this.connectBtnColor}
              outlined={this.connectBtnOutlined}
              unlinkBtnTheme={this.unlinkBtnTheme}
              onValidateDomainInput={() => this.validateDomainInput()}
              onDomain-input={(domain) => this.updateDomain(domain)}
              onLink={() => this.linkDomain()}
              onRefresh={() => this.loadDomains(this.site.id)}
              onDns-unlink={(siteId, domain) => {
                this.$emit('dns-unlink', siteId, domain)
              }}
              onClear={() => this.customDomainInputReset()}
              // TODO: This triggered rerender in Vue, not if this will trigger within a .jsx file?
              // I believe a value will have to be emitted up through custom-domain-actions.jsx
              // to right-sidebar.jsx and emitted again to ChooseATemplate.vue where a rerender can trigger.
              key={this.linkBtnLoading}
            ></CustomDomainActions>
          </StepTwoContainer>
        )}

        {this.navigationStep === 3 && (
          <AnalyticsContainer>
            <RightSidebarNavigator
              mode={this.mode}
              step={this.navigationStep}
              stepTitle={this.$t('marketplace.templates.label.analytics')}
              onSimpleNavBtnClicked={(event, step) =>
                this.simpleNavBtnClicked(event, step)
              }
            />

            <AnalyticsSideBar
              site={this.site}
              metrics={this.analytics}
              onChangeRange={(window) => this.getSiteAnalytics(window)}
            />
          </AnalyticsContainer>
        )}
      </MainContainer>
    )
  },
}
