
  <div>
    <div class="feature-config-heading-wrapper">
      <h1 class="">Feature configuration</h1>
      <p>In this step you can activate and de-activate different features and manage general configuration for <br /> your builder</p>
    </div>

    <div class="feature-config-collapse-wrapper">
      <cvt-collapse
        :accordion='true'
        :prepend-chevron='false'
      >
        <cvt-collapse-item
          :special-style='true'
          title='Default video'
          name='2'
          special-font-family='Nunito'
        >
          <div class="col-6 py-2">
            <url-input
              v-model="builderDefaultVideo"
              label="Video URL"
              @urlIsValid="(status) => videoUrlIsValid(status)"
            />
            <div v-if="isVideoUrlValid" class="feature-config-input-hint-wrapper">
              <cvt-icon icon="info-circle"></cvt-icon>
              <p>The URL to a default video in the video element.</p>
            </div>
          </div>
        </cvt-collapse-item>
        <div class="feature-config-collapse-horizontal-border"></div>
        <cvt-collapse-item special-font-family='Nunito' :special-style='true' title='Maintenance mode' name='4'>
          <div class="d-flex align-items-start">
            <div class="col-6 py-2">
              <cvt-input
                v-model="maintenanceMessage"
                label="Maintenance Message"
                :clearable="false"
                :validation-status="featureConfig.MAINTENANCE_MESSAGE ? 'valid' : 'invalid'"
                :invalid-message="'Invalid Input'"
                :valid-message="'Valid Input'"
              ></cvt-input>
              <div v-if="featureConfig.MAINTENANCE_MESSAGE" class="feature-config-input-hint-wrapper">
                <cvt-icon icon="info-circle"></cvt-icon>
                <p>Enter maintenance message</p>
              </div>
            </div>
            <div class="pl-5">
              <p class="m-0 pt-3 pb-2"> Enable Maintenance mode</p>
              <cvt-switch
                size="sm"
                color="active"
                type="circular"
                description-type="default"
                :initial-state="featureConfig.MAINTENANCE_ENABLED"
                mode="LIGHT"
                @switchToggle="() => onSwitchToggle('MAINTENANCE_ENABLED')"
              />
            </div>
          </div>
        </cvt-collapse-item>
        <div class="feature-config-collapse-horizontal-border"></div>
        <cvt-collapse-item special-font-family='Nunito' :special-style='true' title='Enable sales funnels' name='5'>
          <div class="d-flex justify-content-between align-items-center">
            <p class="m-0">Enable sales funnels</p>
            <cvt-switch
              size="sm"
              color="active"
              type="circular"
              description-type="default"
              :initial-state="featureConfig.FUNNEL_ENABLED"
              mode="LIGHT"
              @switchToggle="() => onSwitchToggle('FUNNEL_ENABLED')"
            />
          </div>
        </cvt-collapse-item>
        <div class="feature-config-collapse-horizontal-border"></div>
        <cvt-collapse-item special-font-family='Nunito' :special-style='true' title='Enable pop-ups' name='6'>
          <div class="d-flex justify-content-between align-items-center">
            <p class="m-0">Enable pop-ups</p>
            <cvt-switch
              size="sm"
              color="active"
              type="circular"
              description-type="default"
              :initial-state="featureConfig.POPUP_ENABLED"
              mode="LIGHT"
              @switchToggle="() => onSwitchToggle('POPUP_ENABLED')"
            />
          </div>
        </cvt-collapse-item>
        <div class="feature-config-collapse-horizontal-border"></div>
        <cvt-collapse-item special-font-family='Nunito' :special-style='true' title='Help link configuration' name='7'>
          <div class="col-6 py-2">
            <url-input
              v-model="helpLink"
              label="Help Link"
              @urlIsValid="(status) => helpUrlIsValid(status)"
            />
            <div v-if="isHelpUrlValid" class="feature-config-input-hint-wrapper">
              <cvt-icon icon="info-circle"></cvt-icon>
              <p>The editor help button link.</p>
            </div>
          </div>
        </cvt-collapse-item>
        <div class="feature-config-collapse-horizontal-border"></div>
      </cvt-collapse>
  </div>
</div>


