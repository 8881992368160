<template>
  <div class="d-flex h-100" style="overflow: auto">
    <cvt-pages
      :pages="pages"
      :active-id="activePageId"
      :is-admin="isAdmin"
      :is-template="isTemplate"
      :is-site-preview="isSitePreview"
      @edit="(e) => changePage(e)"
    />
  </div>
</template>

<script>
import * as _ from 'lodash'
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import * as actions from '../../store/modules/actions'
import * as mutations from '../../store/modules/mutations'
import * as getters from '../../store/modules/getters'
import { cvtRouteNodes } from '@/js/utils/routes'

export default {
  props: {
    isTemplate: {
      type: Boolean,
      default: false
    },
    isSitePreview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showChangeLayoutDialog: false,
      layoutPageData: null,
      replaceLayoutFlag: false,
      activeTag: 'ALL'
    }
  },
  computed: {
    ...mapState({
      site: ({ site }) => _.get(site, 'site'),
      pages: ({ site }) =>
        _.chain(site)
          .get('site.pages')
          .orderBy(['isIndex', 'createdAt'], ['desc', 'asc'])
          .value(),
      indexPage: ({ site }) =>
        _.chain(site).get('site.pages').find({ isIndex: true }).value()
    }),
    ...mapState('globalTheme', {
      orgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined ? globalTheme.OrgName : 'standard',
    }),
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN,
      activePageId: getters.TEMPLATE_PAGE_ID
    })
  },
  methods: {
    ...mapMutations({
      updateTemplatePage: mutations.UPDATE_TEMPLATE_PAGE,
      updateSitePreviewPage: mutations.UPDATE_SITE_PREVIEW_PAGE,
    }),
    ...mapActions({
      toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE
    }),
    ...mapActions('loader', {
      startLoader: 'start',
      stopLoader: 'stop'
    }),
    changePage({ id }) {
      if(this.isTemplate) {

        this.updateTemplatePage(id)

      } else if(this.isSitePreview) {

        this.updateSitePreviewPage(id)
        const siteId = _.chain(this.$store).get('state.site.site.id').value()

        this.$router.push({
          name: cvtRouteNodes.sitePreview,
          params: {
            siteId: siteId,
            pageId: id,
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/utils";

.page-manager {
  width: 18rem;
}

.page-list__page {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  border-bottom: 1px solid rgba(175, 175, 173, 0.4);
  padding-left: 5px;

  &--active {
    background: lighten($inf-dark-blue, 5%);
  }
}

.page-list__page__ctrls {
  .material-icons {
    font-size: 18px;
    line-height: 1;
    cursor: pointer;
  }
}
</style>
