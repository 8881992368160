
  <div :id="vnodeId" ref="gridRootWrapper" :data-vnode-id="vnodeId" class="position-relative visual-box" @mouseenter="mouseOverAction" @mouseleave="mouseLeaveAction">
    <visual-box
        ref="vsBox" :grid-column="column"
        box-color="#e9e9e98c"
        :grid-column-height="cellHeight"
        :grid-v-node-id="vnodeId"/>
    <div
        :key="'grid-stack-' + vnodeId + viewMode"
        ref="gridElement"
        :class="gridClassName"
        @mousedown.stop="" 
        @mouseover.stop=""
    >

      <div v-if="parent().parent().name === 'CPopupBuilder' && isEmpty" class="d-flex justify-content-center">
        <div class="popup-default-box" >
          <!-- The enlarged Icon -->
          <span class="popup-default-icon cvt-icon-www-site-arrow-cursor"></span>
          <!-- Select Popup Style text under the Icon -->
          <span class="popup-default-text">Select Popup Style</span>
          <!-- Button to open up Pre Made Popup Options on click -->
          <cvt-button
class="popup-default-button" :icon="'arrow-right'" :prepend="false"
                      :text="'Select Pre-Made Popup'" @click="click($event)"></cvt-button>
        </div>
      </div>
      <div
v-if="!hasItemsInSlot && parent().parent().name !== 'CPopupBuilder'"
           class="empty-section-placeholder d-flex justify-content-center align-items-center">
        <!-- Internal dashed box -->
      </div>

      <!--      <div :class="isEmpty ? 'button-centre' : ''">-->
      <!--        <add-element-popover-->
      <!--            :is-empty="isEmpty"-->
      <!--            :btn-id="`add-element-${vnodeId}`"-->
      <!--            @add-item="(id) => addItem(id, `add-element-${vnodeId}`)"-->
      <!--        />-->
      <!--      </div>-->
      <slot />
    </div>
  </div>
