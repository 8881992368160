
<div class="auth-customer-container">
  <h1 class="auth-customer-heading">
    Authenticate your customers with Single sign on
  </h1>
  <p class="auth-customer-caption">
    These steps require access to your DNS provider. If you do not have access
    to the DNS manager, we offer to invite other team members to help you with
    the next steps.
  </p>

  <div class="auth-customer-btn-wrapper">
    <cvt-button
      :class="[
        { 'auth-customer-btn-active': oauthActive },
        { 'auth-customer-btn-disabled': isOauthBtnDisabled },
      ]"
      color="light"
      text="OIDC (OAUTH)"
      :btn-active="oauthActive"
      :disabled="isOauthBtnDisabled"
      @click="() => setActivetab('oauth')"
    >
    </cvt-button>

    <cvt-button
      :class="[
        { 'auth-customer-btn-active': jwtTabActive },
        { 'auth-customer-btn-disabled': isJwtBtnDisabled },
      ]"
      color="light"
      text="Generate JWT"
      :btn-active="jwtTabActive"
      :disabled="isJwtBtnDisabled"
      @click="() => setActivetab('jwt')"
    >
    </cvt-button>
  </div>

  <div v-if="jwtTabActive" class="jwt-wrapper">
    <div v-if="envJwtToken" class="jwt-input-wrapper">
      <div class="jwt-input-label">Your JWT Secret:</div>
      <div class="jwt-token-wrapper">
        <p class="jwt-input-tag">Token:</p>
        <div class="jwt-input-text-wrap">
          <input
            ref="jwtToken"
            class="jwt-input-text"
            :value="envJwtToken"
            readonly
          />
          <button @click="copyToClipboard">
            <i class="fa fa-copy"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="jwt-continue-btn-wrapper">
      <cvt-button
        text="Continue Feature configuration"
        @click="() => continueToFeatureConfig()"
      ></cvt-button>
    </div>
  </div>

  <div v-if="oauthActive">
    <form
      v-if="isOidcDiscoveryScreen"
      @submit.prevent="onAddNewConfiguration"
    >
      <div>
        <h1 class="oauth-heading">OIDC Discovery URL:</h1>
      </div>

      <div class="oauth-input-wrapper">
        <div class="oidc-input-wrapper">
          <cvt-input
            label="Enter your OIDC Discovery URL here"
            placeholder="https://<your-team-name>.yourdomain.com/"
            :required="true"
            :value="addOIDC.iodcUrl"
            @input="(e) => onOidcUrlChange(e)"
          >
          </cvt-input>

          <div class="oidc-input-hint-wrapper">
            <cvt-icon icon="info-circle"></cvt-icon>
            <p class="oidc-input-hint-message">
              When creating a client/app, your IdP may request an authorized
              redirect URL.
            </p>
          </div>
        </div>

        <cvt-select
          class="oidc-env-dropdown"
          label="Select your environment"
          :value="addOIDC.environment"
          @input="onEnvironmentChange"
        >
          <option
            v-for="(opt, index) in envOptions"
            :key="index"
            :value="opt"
          >
            {{ opt }}
          </option>
        </cvt-select>
      </div>

      <div class="add-oidc-config-btn-wrapper">
        <cvt-button text="Add OIDC Configuration" :loading="loading" submit>
        </cvt-button>
      </div>

      <div class="create-oidc-config-btn-wrapper">
        <p>Don’t have Discovery URL?</p>

        <cvt-button
          color="light"
          text="Create OIDC config"
          @click="onAddNewEnvironment"
        >
        </cvt-button>

        <span
          class="customer-auth-it-skip-btn"
          @click="() => setActivetab('jwt')"
        >
          Skip this step
        </span>
      </div>
    </form>

    <div v-if="isOidcEnvironmentScreen" class="oidc-env-screen">
      <p class="oidc-env-screen-heading">OIDC Environments</p>
      <div>
        <div class="oidc-env-card-wrapper">
          <cvt-oidc-environment-card
            v-for="(config, idx) in oidcConfigs"
            :id="config.id"
            :key="idx"
            width="210"
            :client-id="config.clientId"
            :environment="config.environment"
            :test-passed="config.testPassed"
            :test-is-loading="config.testIsLoading"
            @onStartConnection="startConnection(config, idx)"
            @onRemoveOidcItem="removeOidcItem(idx, oidcConfigs)"
          />

          <div class="add-new-env-icon" @click="onAddNewEnvironment">+</div>
        </div>
      </div>

      <div class="oidc-env-continue-btn-wrapper">
        <cvt-button
          text="NEXT: Feature Configuration"
          @click="continueToFeatureConfig"
        ></cvt-button>
      </div>

      <cvt-modal
        v-if="showModal"
        modal-header="OIDC verfication was successful!"
        text-content="Please proceed to the next step"
        btn-text="Close"
        btn-color="#36a635"
        :btn-on-click="closeModal"
        modal-icon="fas fa-check"
        icon-color="#36a635"
        @onCloseModal="closeModal"
      ></cvt-modal>
    </div>

    <form
      v-if="isOidcConfigurationScreen"
      class="new-oidc-config-container"
      @submit.prevent="onCreateNewConfiguration"
    >
      <h1 class="new-oidc-config-heading">Add new OIDC configuration</h1>

      <div class="new-oidc-config-form">
        <div
          v-for="(field, idx) in newOidcConfigFields"
          :key="idx"
          class="new-oidc-config-input-wrapper"
        >
          <cvt-input
            v-if="field.type === 'text'"
            :label="field.label"
            :value="field.value"
            required
            @input="(e) => onChangeNewOidcInput(e, idx)"
          >
          </cvt-input>
          <cvt-select
            v-if="field.type === 'select'"
            :label="field.label"
            :name="field.label"
            :value="field.value"
            @input="(value) => selectOnChange(value, idx)"
          >
            <option
              v-for="(option, id) in field.options"
              :key="id"
              :value="option"
            >
              {{ option }}
            </option>
          </cvt-select>
        </div>
      </div>

      <div class="new-oidc-config-form-btn-wrapper">
        <cvt-button
          color="light"
          text="Add OIDC Environment"
          :loading="loading"
          submit
        ></cvt-button>

        <span
          class="customer-auth-it-skip-btn"
          @click="() => setActivetab('jwt')"
        >
          Skip this step
        </span>
      </div>
    </form>
  </div>
</div>
