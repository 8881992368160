import { styled, VueEmotion } from '@egoist/vue-emotion'
import DOMPurify from 'dompurify'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import * as getters from '../../../builder/src/js/store/modules/getters'
import Vue from '../../lib/utils/vue'
import { LEFT_SIDEBAR_FRESHWORKS, LEFT_SIDEBAR_STANDARD } from '../constants'
import CvtButton from './Button'
import DropdownMenu from './dropdown/DropdownMenu'
import CvtFieldLabel from './FieldLabel'
import { CvtIcon } from './icon/icon'

let orgName,
  Spacing,
  Radii,
  elevation,
  Colors,
  backgroundColor,
  Border,
  textColor,
  borderColor,
  color,
  FontWeight,
  textFontWeight,
  textFontSize,
  textLineHeight

Vue.use(VueEmotion)

const getFormGroupDiv = () => styled('div')``

const getDropdownDiv = () => styled('div')`
  position: relative;
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  border-radius: ${Radii.RADIUS_PX_8}px;
`

const getInputGroupDiv = () => styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
  max-width: 100%;
  ${(props) =>
    props.showInputBorder &&
    `border: ${Border.SIZE_1}px solid ${Colors.BASIC[300]};`}
  border-radius: ${Radii.RADIUS_PX_8}px;
  ${(props) => {
    let styles = ''
    if (props.disabled) {
      if (props.typeOfInput === 'textarea') styles += `width: 100%;`
      return (styles += `cursor: not-allowed;
        ${backgroundColor({ themeing: 'dark' }, 200)}
        opacity: 1;`)
    }

    let defaultInputBorderConfig =
      props?.inputConfig?.borderColor?.default?.color
    if (defaultInputBorderConfig) {
      if (defaultInputBorderConfig.custom) {
        styles += `border-color: ${defaultInputBorderConfig.value};`
      } else {
        styles += borderColor(
          defaultInputBorderConfig.value,
          defaultInputBorderConfig.weight,
        )
      }
    }

    if (props.typeOfInput === 'textarea') styles += `width: 100%;`
    if (props.typeOfInput === 'text' && props.inputSize === 'sm') {
      styles += `height: ${Spacing.SPACING_32}px;`
    }

    if (props.validity === '') {
      if (props.inputFocused) {
        let inputBorderFocusedColorConfig =
          props?.inputConfig?.borderColor?.focused?.color
        let inputBorderFocusedShadowConfig =
          props?.inputConfig?.borderColor?.focused?.shadow
        let inputBorderFocusedSizeConfig =
          props?.inputConfig?.borderColor?.focused?.size

        styles += `${
          inputBorderFocusedColorConfig
            ? inputBorderFocusedColorConfig.custom
              ? `border-color: ${inputBorderFocusedColorConfig.value};`
              : borderColor(
                  inputBorderFocusedColorConfig.value,
                  inputBorderFocusedColorConfig.weight,
                )
            : borderColor({ themeing: 'primary' }, 500)
        }`

        styles += `${
          inputBorderFocusedShadowConfig
            ? inputBorderFocusedShadowConfig.weight
              ? elevation(props, inputBorderFocusedShadowConfig.weight)
              : ``
            : elevation(props, 'INSET')
        }`
        styles += `${
          inputBorderFocusedSizeConfig
            ? `border-width: ${Border[inputBorderFocusedSizeConfig]}`
            : `border-width: ${Border.SIZE_1}`
        }`
      } else {
        let inputBorderHoverColorConfig =
          props?.inputConfig?.borderColor?.hover?.color
        styles += `&:hover {
          ${
            inputBorderHoverColorConfig
              ? inputBorderHoverColorConfig.custom
                ? `border-color: ${inputBorderHoverColorConfig.value};`
                : borderColor(
                    inputBorderHoverColorConfig.value,
                    inputBorderHoverColorConfig.weight,
                  )
              : borderColor({ themeing: 'dark' }, 400)
          }
        }`
      }
    }

    if (props.validity === 'valid') {
      styles += `
        ${borderColor({ themeing: 'success' }, 500)}
      `
      if (props.inputFocused) {
        styles += `
          ${borderColor({ themeing: 'success' }, 500)}
          ${elevation(props, 'INSET')}
        `
      }
    }
    if (props.validity === 'invalid') {
      styles +=
        orgName === LEFT_SIDEBAR_FRESHWORKS
          ? `${borderColor({ themeing: 'danger' }, 800)}`
          : `${borderColor({ themeing: 'danger' }, 500)}`
      if (props.inputFocused) {
        styles += `
          ${elevation(props, 'INSET')}
        `
      }
    }
    return styles
  }}
`

const getInputGroupPrependDiv = () => styled('div')`
  margin-right: -${Spacing.SPACING_2 / 2}px;
  display: flex;
`

const getInputGroupAppendDiv = () => styled('div')`
  margin-left: -${Spacing.SPACING_2 / 2}px;
  display: flex;
`

const getInputGroupTextSpan = () => styled('span')`
  display: flex;
  align-items: center;
  position: relative;
  padding: ${Spacing.SPACING_8}px ${Spacing.SPACING_16}px;
  margin-bottom: 0;
  ${textFontSize('md')}
  font-weight: ${FontWeight.LIGHT};
  ${textLineHeight('md')}
  ${color({ themeing: 'dark' }, 300)}
  text-align: center;
  white-space: nowrap;
  ${(props) => {
    let inputIconColorConfig = props?.inputConfig?.iconColor
    if (inputIconColorConfig) {
      if (inputIconColorConfig.custom) {
        return `color: ${inputIconColorConfig.value};`
      } else {
        return textColor(
          inputIconColorConfig.value,
          inputIconColorConfig.weight,
        )
      }
    }
  }}
  ${(props) => {
    let styles = ``
    styles += props.usage === 'search' ? `cursor: pointer;` : ``
    styles += props.suffix ? `padding-right: 0px;` : ''
    return styles
  }}
`

const getFormControlInput = () => styled('input')`
  outline: 0;
  display: block;
  width: 100%;
  flex: 1;
  height: ${Spacing.SPACING_32}px;
  padding: ${Spacing.SPACING_12}px ${Spacing.SPACING_8}px;
  margin: ${Spacing.SPACING_4}px ${Spacing.SPACING_8}px;
  ${textFontSize('md')}
  ${textLineHeight('md')}
  font-weight: ${(props) =>
    props?.inputConfig
      ? FontWeight[props.inputConfig.fontWeight]
      : FontWeight.LIGHT};
  z-index: 0;
  ${color({ themeing: 'dark' }, 500)}
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  background-clip: padding-box;
  border: 0px solid transparent;
  border-radius: ${Radii.RADIUS_PERCENT_50 / 4}%;
  box-shadow: none;
  ${(props) => {
    if (props?.inputConfig?.color) {
      let inputDefaultColorConfig = props?.inputConfig?.color
      if (inputDefaultColorConfig?.custom) {
        return `color: ${inputDefaultColorConfig.value};`
      } else {
        return color(
          inputDefaultColorConfig.value,
          inputDefaultColorConfig.weight,
        )
      }
    } else {
      return color({ themeing: 'dark' }, 500)
    }
  }}
  ${(props) => {
    let styles = ''

    if (props.inputSize === 'sm') {
      styles += `
        height: ${Spacing.SPACING_24}px;
        padding: ${Spacing.SPACING_2}px ${Spacing.SPACING_12}px;
        ${textFontSize('sm')}
        ${textLineHeight('sm')}
      `
    }

    if (props.prefix) {
      styles += `padding-left: 0px; margin-left: 0px;`
    }

    if (props.disabled)
      return (styles += `cursor: not-allowed;
      ${backgroundColor({ themeing: 'dark' }, 200)}
      opacity: 1;`)

    return styles
  }}
    ${(props) => {
    let styles = ``
    let placeHolderColor = ``
    if (props.placeholder) {
      if (props?.inputConfig?.placeholder) {
        let inputPlaceholderColorConfig = props?.inputConfig?.placeholder?.color
        if (inputPlaceholderColorConfig?.custom) {
          placeHolderColor += `color: ${inputPlaceholderColorConfig.value};`
        } else {
          placeHolderColor += color(
            inputPlaceholderColorConfig.value,
            inputPlaceholderColorConfig.weight,
          )
        }

        styles += `
          ::placeholder {
          ${placeHolderColor}
          }
          :-ms-input-placeholder {
          ${placeHolderColor}
          }
          ::-ms-input-placeholder {
          ${placeHolderColor}
          `
      }
    }

    return styles
  }}

  transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
  border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  will-change: border-color, box-shadow;

  ~ .floating-label {
    position: absolute;
    pointer-events: none;
    transition: 0.2s ease all;
    ${(props) => {
      let styles = ``

      if (props.inputSize === 'sm') {
        styles += `
          left: ${Spacing.SPACING_8 + 2}px;
          top: ${Spacing.SPACING_8}px;
          ${textFontSize('sm')}
          ${textLineHeight('sm')}
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `
      } else {
        styles += `
          left: ${Spacing.SPACING_16}px;
          top: ${Spacing.SPACING_12}px;
          ${textFontSize('lg')}
          ${textLineHeight('lg')}
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `
      }
      if (props.prefix) {
        styles += `left: 45px;`
      }
      return styles
    }}
    ${textFontWeight('LIGHT')}
  }

  &:focus ~ .floating-label,
  &.valid ~ .floating-label,
  &.valid:focus ~ .floating-label {
    top: -8px;
    left: 16px;
    opacity: 1;
    padding: 0 10px;
    border-radius: ${Radii.RADIUS_PX_8}px;
    ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
    ${textFontSize('sm')}
    ${textLineHeight('sm')}
    ${textFontWeight('LIGHT')}
  }

  &.valid ~ .floating-label {
    ${borderColor({ themeing: 'dark' }, 300)}
  }
`

const getFormControlTextarea = () => styled('textarea')`
  outline: 0;
  height: auto;
  display: block;
  width: 100%;
  max-width: 100%;
  padding: ${Spacing.SPACING_12}px ${Spacing.SPACING_16}px;
  margin: ${Spacing.SPACING_8}px ${Spacing.SPACING_8}px;
  ${textFontSize('md')}
  ${textLineHeight('md')}
  ${color({ themeing: 'dark' }, 500)}
  font-weight: ${FontWeight.LIGHT};
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  background-clip: padding-box;
  border: 0px solid transparent;
  border-radius: ${Radii.RADIUS_PERCENT_50 / 4}%;
  box-shadow: none;

  ${(props) => {
    let styles = ''
    if (props.inputSize === 'sm') {
      styles += `
        padding: ${Spacing.SPACING_4}px ${Spacing.SPACING_12}px;
        ${textFontSize('sm')}
        ${textLineHeight('sm')}
      `
    }

    if (props.disabled)
      return (styles += `cursor: not-allowed;
      ${backgroundColor({ themeing: 'dark' }, 200)}
      opacity: 1;
      resize: none;
      `)

    return styles
  }}

  transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  will-change: border-color, box-shadow;
`

const getValidIcon = () => styled(CvtIcon)`
  ${(props) => {
    return props.isTextArea
      ? `margin-right: ${Spacing.SPACING_8}px; margin-top: ${Spacing.SPACING_8}px;`
      : ''
  }}
  ${color({ themeing: 'success' }, 500)}
`
const getInvalidIcon = () => styled(CvtIcon)`
  ${(props) => {
    return props.isTextArea
      ? `margin-right: ${Spacing.SPACING_8}px; margin-top: ${Spacing.SPACING_8}px;`
      : ''
  }}
  ${color({ themeing: 'danger' }, 500)}
`

const getClearBtnWrapSpan = () => styled('span')`
  ${(props) => {
    return props.validationStatus === ''
      ? ''
      : `margin: 0 ${Spacing.SPACING_12}px;`
  }}
`

const getLoadingSpan = () => styled('span')`
  ${(props) => {
    return props.validationStatus === ''
      ? ''
      : `margin: 0 ${Spacing.SPACING_12}px;`
  }}
`

const getClearButton = () => styled(CvtButton)``

const getSmallSpinner = () => styled('i')`
  width: ${Spacing.SPACING_16}px;
  height: ${Spacing.SPACING_16}px;
  display: inline-block;
  vertical-align: text-bottom;
  border: 0.2em solid;
  border-color: ${Colors.BASIC[500]};
  border-right-color: transparent;
  border-radius: ${Radii.RADIUS_PERCENT_50}%;
  animation: 1s spin linear infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

const getSmallSpan = () => styled('span')`
  margin-right: ${Spacing.SPACING_8}px;
`

const getChevronDown = () => styled(CvtIcon)``

const getValidationMsgContainer = () => styled('small')`
  margin-top: ${Spacing.SPACING_4 + Spacing.SPACING_2}px;
`

export default {
  name: 'CvtInput',
  components: {
    CvtIcon,
    CvtFieldLabel,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'LIGHT',
    },
    usage: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      default: undefined,
    },
    autocomplete: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    minlength: {
      type: Number,
      default: undefined,
    },
    maxlength: {
      type: Number,
      default: undefined,
    },
    min: {
      type: [Number, String],
      default: undefined,
    },
    max: {
      type: Number,
      default: undefined,
    },
    step: {
      type: Number,
      default: undefined,
    },
    pattern: {
      type: String,
      default: undefined,
    },
    validationStatus: {
      type: String,
      default: '',
      validator(value) {
        return ['valid', 'invalid', ''].indexOf(value) !== -1
      },
    },
    inputSize: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    // No label override
    noLabel: {
      type: Boolean,
      default: false,
    },
    suffixIcon: {
      type: String,
      default: '',
    },
    prefixIcon: {
      type: String,
      default: '',
    },
    fetchSuggestions: {
      type: Function,
    },
    validMessage: {
      type: String,
      defaults: 'The Input is Valid',
    },
    invalidMessage: {
      type: String,
      defaults: 'The Input is Invalid',
    },
    placeholder: {
      type: String,
      defaults: '',
    },
    actionButton: {
      type: Boolean,
      default: false,
    },
    showInputBorder: {
      type: Boolean,
      default: true,
    },
    inputConfig: {
      type: Object,
      default: () => {},
    },
    rows: {
      type: String,
      defaults: '',
    },
  },
  data() {
    return {
      open: false,
      searchOn: false,
      loading: false,
      suggestions: [],
      inputFocused: false,
      inputMouseenter: false,
      inputMouseleave: false,
      inputLength: 0,
      inputValue: this.value,
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      color: getters.GLOBAL_STYLE_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      elevation: getters.GLOBAL_STYLE_ELEVATION,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      Border: ({ globalTheme }) => globalTheme.Border,
      orgName: ({ globalTheme }) =>
        globalTheme?.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
      showInvalidIcon: ({ globalTheme }) =>
        globalTheme?.showInvalidIcon !== undefined
          ? globalTheme.showInvalidIcon
          : true,
      labelType: ({ globalTheme }) => globalTheme.labelType,
    }),
    isTextArea() {
      return this.type === 'textarea'
    },
    hasSuffix() {
      return this.$slots.suffix || this.suffixIcon.length > 0
    },
    hasPrefix() {
      return this.$slots.prefix || this.prefixIcon.length > 0
    },
    showSuggestions() {
      return (
        this.loading ||
        (this.open && this.suggestions.length > 0) ||
        (this.open && this.usage === 'search')
      )
    },
    showClearIcon() {
      if (this.clearable && !this.disabled) {
        if (this.inputLength > 0) {
          if (this.inputFocused) {
            return true
          }
          if (this.inputMouseenter) {
            return true
          }
          if (this.inputMouseleave) {
            return false
          }
        }
      }
      return false
    },
    formControlInputType() {
      return this.type === 'number' ||
        this.type === 'password' ||
        this.type === 'date' ||
        this.type === 'time'
        ? this.type
        : 'text'
    },
    showInputGroupTextSpan() {
      return (
        this.type !== 'number' && this.type !== 'date' && this.type !== 'time'
      )
    },
  },
  watch: {
    value(val, previous) {
      if (val !== previous) {
        this.value = val
        this.inputValue = val
      }
    },
  },
  created() {
    orgName = this.orgName
    Spacing = this.Spacing
    Radii = this.Radii
    elevation = this.elevation
    Colors = this.Colors
    backgroundColor = this.backgroundColor
    Border = this.Border
    borderColor = this.borderColor
    color = this.color
    textColor = this.textColor
    FontWeight = this.FontWeight
    textFontWeight = this.textFontWeight
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
  },
  mounted() {
    if (this.$refs.inputDropdown) {
      this.$refs.inputDropdown.addEventListener('click', this.stopPropagation)
      document.body.addEventListener('click', this.onClickOutside)
    }
  },
  beforeDestroy() {
    if (this.$refs.inputDropdown) {
      this.$refs.inputDropdown.removeEventListener(
        'click',
        this.stopPropagation,
      )
      document.body.removeEventListener('click', this.onClickOutside)
    }
  },
  methods: {
    onInputEvent(eventName, eventData, validationStatus) {
      if (eventName === 'focus') {
        this.inputFocused = true
        this.searchOn = true
        if (this.$refs.input && this.usage !== 'search') {
          if (this?.$refs?.input?.value?.length === 0) {
            this.removeOpen()
          } else {
            this.setOpen()
          }
        }
      }

      if (eventName === 'blur') {
        this.inputFocused = false
      }

      if (eventName === 'mouseenter') {
        this.inputMouseenter = true
        this.inputMouseleave = false
      }

      if (eventName === 'mouseleave') {
        this.inputMouseleave = true
        this.inputMouseenter = false
      }

      if (eventName === 'input') {
        // Should implement https://lodash.com/docs#debounce
        let sanitizedValue = DOMPurify.sanitize(this?.$refs?.input?.value)

        // replaceAll function is not supported in Chrome < 85, Firefox < 77
        // caused issues for multiple clients so changed it to more compatible function
        this.inputValue = sanitizedValue
          ?.replace(/&gt;/g, '>')
          ?.replace(/&lt;/g, '<')

        this.inputLength = sanitizedValue.length
        if (this?.$refs?.input?.value?.length === 0) {
          this.removeOpen()
        } else {
          this.setOpen()
        }
        this.querySuggestions()
      }
      this.$emit(eventName, eventData, validationStatus)
    },
    applySuggestion(item) {
      if (item.idx) {
        this.$emit('select', item.label, item.idx)
      } else {
        this.$emit('select', item.label)
      }
      this.suggestions = []
      this.searchOn = false
    },
    async querySuggestions() {
      if (this.fetchSuggestions) {
        this.loading = true
        await this.fetchSuggestions(
          this.inputValue.toString(),
          (suggestions) => {
            if (!Array.isArray(suggestions)) {
              console.error(
                '[Convrrt Element][Input] autocomplete suggestions must be an array',
              )
              return
            }
            this.suggestions = suggestions
            this.loading = false
          },
        )
      }
    },
    clearInput() {
      this.inputValue = ''
      this.inputLength = 0
      this.suggestions = []
      this.removeOpen()
      this.$emit('clear')
    },
    getMenuTopPos() {
      let inputGroup = this.$refs.inputGroup
      if (inputGroup) return inputGroup.getBoundingClientRect().height
    },
    getMenuBottomPos() {
      let inputGroup = this.$refs.inputGroup
      if (inputGroup) return inputGroup.getBoundingClientRect().height + 16
    },
    stopPropagation(event) {
      event.stopPropagation()
    },
    onClickOutside() {
      this.open = false
      this.searchOn = false
    },
    setOpen() {
      this.open = true
    },
    removeOpen() {
      this.open = false
    },
  },

  render: function (h) {
    const FormGroupDiv = getFormGroupDiv()
    const DropdownDiv = getDropdownDiv()
    const InputGroupDiv = getInputGroupDiv()
    const InputGroupPrependDiv = getInputGroupPrependDiv()
    const InputGroupTextSpan = getInputGroupTextSpan()
    const FormControlInput = getFormControlInput()
    const LoadingSpan = getLoadingSpan()
    const SmallSpinner = getSmallSpinner()
    const ClearBtnWrapSpan = getClearBtnWrapSpan()
    const ClearButton = getClearButton()
    const ValidIcon = getValidIcon()
    const InvalidIcon = getInvalidIcon()
    const InputGroupAppendDiv = getInputGroupAppendDiv()
    const ChevronDown = getChevronDown()
    const FormControlTextarea = getFormControlTextarea()
    const SmallSpan = getSmallSpan()
    const ValidationMsgContainer = getValidationMsgContainer()

    return (
      <FormGroupDiv identity="FormGroup">
        {this.labelType === 'standard' && (
          <CvtFieldLabel
            mode={this.mode}
            for={this.id}
            label={this.label}
            noLabel={this.noLabel}
          />
        )}
        <DropdownDiv identity="Dropdown" ref="inputDropdown">
          {!this.isTextArea ? (
            <InputGroupDiv
              identity="InputGroup"
              ref="inputGroup"
              inputFocused={this.inputFocused}
              validity={this.validationStatus}
              disabled={this.disabled}
              onmouseenter={(e) => this.onInputEvent('mouseenter', e)}
              onmouseleave={(e) => this.onInputEvent('mouseleave', e)}
              typeOfInput={this.type}
              inputSize={this.inputSize}
              inputConfig={this.inputConfig}
              showInputBorder={this.showInputBorder}
            >
              {this.hasPrefix && (
                <InputGroupPrependDiv identity="InputGroupPrepend">
                  <InputGroupTextSpan
                    identity="InputGroupPrependIcon"
                    isPrepend={true}
                  >
                    {this.$slots.prefix}
                    <cvt-icon icon={this.prefixIcon} hover></cvt-icon>
                  </InputGroupTextSpan>
                </InputGroupPrependDiv>
              )}
              <FormControlInput
                ref="input"
                type={this.formControlInputType}
                id={this.id}
                key={this.id}
                identity="FormControl"
                prefix={this.hasPrefix}
                suffix={this.hasSuffix}
                validity={this.validationStatus}
                inputSize={this.inputSize}
                name={this.name}
                placeholder={
                  this.labelType === 'floating' ? '' : this.placeholder
                }
                class={this.inputValue?.length > 0 ? 'valid' : ''}
                autocomplete={this.autocomplete}
                aria-describedby={this.label}
                required={this.required}
                minlength={this.minlength}
                maxlength={this.maxlength}
                min={this.min}
                max={this.max}
                step={this.step}
                pattern={this.pattern}
                value={this.inputValue}
                disabled={this.disabled}
                readonly={this.readonly}
                onInput={(e) => this.onInputEvent('input', e)}
                onFocus={(e) => this.onInputEvent('focus', e)}
                onBlur={(e) =>
                  this.onInputEvent('blur', e, this.validationStatus)
                }
                onChange={(e) => this.onInputEvent('change', e)}
                onKeydown={(e) => {
                  if (e.keyCode == 13) {
                    this.$emit('enter')
                  }
                  this.$emit('keydown', e)
                }}
                onClick={() => {
                  if (this.usage === 'search') {
                    this.querySuggestions()
                    this.open ? this.removeOpen() : this.setOpen()
                  }
                }}
                inputConfig={this.inputConfig}
              ></FormControlInput>
              {this.labelType === 'floating' && (
                <CvtFieldLabel
                  mode={this.mode}
                  label={this.label}
                  noLabel={this.noLabel}
                  inputLength={this.inputLength}
                />
              )}
              {this.showInputGroupTextSpan && (
                <InputGroupTextSpan suffix={this.hasSuffix}>
                  {this.loading && (
                    <LoadingSpan validationStatus={this.validationStatus}>
                      <SmallSpinner role="status"></SmallSpinner>
                    </LoadingSpan>
                  )}
                  {this.showClearIcon && !this.loading && (
                    <ClearBtnWrapSpan validationStatus={this.validationStatus}>
                      <ClearButton
                        shape="pill"
                        size="xs"
                        icon="times"
                        type="button"
                        disabled={this.disabled}
                        actionIcon={true}
                        colorOfActionIcon=""
                        modeOfActionIcon="medium"
                        onClick={this.clearInput}
                      ></ClearButton>
                    </ClearBtnWrapSpan>
                  )}
                  {this.validationStatus === 'valid' && (
                    <ValidIcon
                      icon="check"
                      prefixClass="check-icon"
                      hover
                    ></ValidIcon>
                  )}
                  {this.validationStatus === 'invalid' &&
                    this.showInvalidIcon && (
                      <InvalidIcon
                        icon="exclamation-circle"
                        prefixClass="exclamation-circle-icon"
                        hover
                      ></InvalidIcon>
                    )}
                </InputGroupTextSpan>
              )}
              {this.hasSuffix && (
                <InputGroupAppendDiv identity="InputGroupAppend">
                  <InputGroupTextSpan
                    identity="InputGroupAppendIcon"
                    isAppend={true}
                    usage={this.usage}
                    onClick={() => {
                      if (this.usage === 'search') {
                        this.querySuggestions()
                        this.open ? this.removeOpen() : this.setOpen()
                        this.searchOn = !this.searchOn
                      }
                    }}
                    inputConfig={this.inputConfig}
                  >
                    {this.$slots.suffix}
                    {!this.searchOn && this.usage === 'search' && (
                      <ChevronDown
                        icon="chevron-down"
                        prefixClass="chevron-down"
                        hover
                      ></ChevronDown>
                    )}
                    {((this.searchOn && this.usage === 'search') ||
                      this.usage !== 'search') && (
                      <cvt-icon icon={this.suffixIcon} hover></cvt-icon>
                    )}
                  </InputGroupTextSpan>
                </InputGroupAppendDiv>
              )}
            </InputGroupDiv>
          ) : (
            <InputGroupDiv
              identity="InputGroupTextarea"
              inputFocused={this.inputFocused}
              validity={this.validationStatus}
              disabled={this.disabled}
              ref="inputGroup"
              mode={this.mode}
              typeOfInput={this.type}
              showInputBorder={this.showInputBorder}
            >
              <FormControlTextarea
                id={this.id}
                ref="input"
                rows={this.rows}
                inputSize={this.inputSize}
                value={this.inputValue}
                required={this.required}
                disabled={this.disabled}
                placeholder={
                  this.labelType === 'floating' ? '' : this.placeholder
                }
                onInput={(e) => this.onInputEvent('input', e)}
                onFocus={(e) => this.onInputEvent('focus', e)}
                onBlur={(e) =>
                  this.onInputEvent('blur', e, this.validationStatus)
                }
                onChange={(e) => this.onInputEvent('change', e)}
                mode={this.mode}
              ></FormControlTextarea>
              {this.validationStatus === 'valid' && (
                <ValidIcon
                  icon="check"
                  prefixClass="check-icon"
                  isTextArea={this.isTextArea}
                  hover
                ></ValidIcon>
              )}
              {this.validationStatus === 'invalid' && this.showInvalidIcon && (
                <InvalidIcon
                  icon="exclamation-circle"
                  prefixClass="exclamation-circle-icon"
                  isTextArea={this.isTextArea}
                  hover
                ></InvalidIcon>
              )}
            </InputGroupDiv>
          )}
          {this.showSuggestions && this.suggestions.length > 0 && (
            <DropdownMenu
              ref="dropdownMenu"
              dropUp={false}
              loading={this.loading}
              filtered={this.suggestions}
              selected={[]}
              onoptionchange={(option) => this.applySuggestion(option)}
              top={this.getMenuTopPos()}
              bottom={this.getMenuBottomPos()}
              actionButton={this.actionButton}
              onCreateNewOrg={() => this.$emit('createNewOrg')}
            ></DropdownMenu>
          )}
        </DropdownDiv>
        <ValidationMsgContainer>
          {(this.validMessage || this.invalidMessage) &&
            this.validationStatus.length != 0 && (
              <SmallSpan>
                {this.validationStatus === 'valid' && (
                  <ValidIcon
                    icon="check"
                    prefixClass="check-icon"
                    hover
                  ></ValidIcon>
                )}
                {this.validationStatus === 'invalid' &&
                  this.showInvalidIcon && (
                    <InvalidIcon
                      icon="exclamation-circle"
                      prefixClass="exclamation-circle-icon"
                      hover
                    ></InvalidIcon>
                  )}
              </SmallSpan>
            )}
          {this.validationStatus === 'valid'
            ? this.validMessage
            : this.validationStatus === 'invalid'
            ? this.invalidMessage
            : ''}
        </ValidationMsgContainer>
      </FormGroupDiv>
    )
  },
}
