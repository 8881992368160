<template>
  <footer
      :id="anchorId"
      class="block"
      :class="{'block-disabled': blockDisabled, ...classes}"
      :data-vnode-id="vnodeId"
      @click="select"
      @mouseover="target"
      @contextmenu.stop.prevent="showContextMenu"
      @dblclick.stop.prevent="triggerOpenAssetManager"
  >
    <div
        v-show="showTextureBackground && isSectionUIEnabled || blockDisabled"
        class="dashed-texture"
    />

    <div v-if="rearrangingCurrentBlocks" class="working-on-block">
      <div class="working-on-block__container">
        <h3 class="loader-message">Please wait...</h3>
      </div>
    </div>

    <div
        v-show="showControlls && isSectionUIEnabled"
        class="block__target__indicator block__target__indicator--top"
    ></div>
    <div
        v-show="showControlls"
        class="block__target__indicator block__target__indicator--right"
    ></div>
    <div
        v-show="showControlls && isSectionUIEnabled"
        class="block__target__indicator block__target__indicator--bottom"
    ></div>
    <div
        v-show="showControlls"
        class="block__target__indicator block__target__indicator--left"
    ></div>

    <div :class="stylesheet.classes.bgColor"></div>
    <div :class="stylesheet.classes.bgImage"></div>
    <div :class="stylesheet.classes.overlayColor"></div>
    <!-- eslint-disable vue/no-v-html -->
    <div :class="stylesheet.classes.bgShape" v-html="bgSvg"></div>
    <!--eslint-enable-->
    <cvt-section-drag-items
        v-if="vnodeId && isGridBuilder && !isSectionUIEnabled"
        class="drag-items-block"
        :usage="'block'"
        :show-plus-btn="true"
        :vnode-id="vnodeId"
        :widget-class="'grid-stack-item'"
    />

    <div v-show="isSectionUIEnabled" class="section-controls-wrap">
      <section-controls
          v-show="showControlls"
          :class="sectionControlsClasses"
          :vnode-id="vnodeId"
          header-text="Footer"
          :hide-move-buttons="true"
          :allowed-controls="['delete', 'swap']"
          @moveSectionUp="moveSectionUp"
          @moveSectionDown="moveSectionDown"
          @copySection="copySection"
          @swapSection="swapSection"
          @deleteSection="deleteSection"
          @arrangeBlock="arrangeBlock"
      />
    </div>

    <div v-show="!isEmpty" :class="containerClasses">
      <template v-for="node in fbNode.children">
        <component
            :is="node.tag"
            :key="node.data.props.vnodeId"
            :fb-node="node"
            v-bind="node.data.props"
        ></component>
      </template>
    </div>

    <cvt-popover
        :ref="popoverRef"
        :mode="mode"
        placement="top"
        :target="`${anchorId}-add-core-element`"
        triggers="click"
    >
      <template #popper>
        <div class="d-flex">
          <cvt-button
              color="success"
              class="text-capitalize ml-1"
              :text="$t('add_section.cta')"
              @click.stop.prevent="openQuickLaunch"
          />
        </div>
      </template>
    </cvt-popover>
  </footer>
</template>

<script>
import Block from "../editor/nodes/Block.vue";
import {RemoveSubNodeCommand} from "@/js/commands/RemoveSectionCommand";
import {AddSubNodeCommand} from "@/js/commands/AddSectionCommand";
import {FooterBlock} from "@/js/lib/helper-classes";
export default Block.extend({
  name: "FooterBlockV3",
  props: {
    vDomPath: {
      type: String,
      required: true
    },
    blockDisabled: {
      type: Boolean,
      default: () => false
    },
    isFooterBlock: {
      type: Boolean,
      default: () => true
    },
  },
  methods: {
    deleteSection(event) {
      this.ignore()
      this.deselect()
      return this.pushCommand(
          new RemoveSubNodeCommand(
              this.vnodeId,
              this.vDomPath,
              Number(this.idx()),
              this.fbNode,
          ),
      )
    },
    copySection() {
      return this.pushCommand(
          new AddSubNodeCommand(
              this.vDomPath,
              new FooterBlock()
          )
      )
    }
  }
})
</script>

<style scoped>

</style>