
<div
  :id="vnodeId"
  class="cvt-editor-checkout"
  :class="componentLocalClasses"
  :data-vnode-id="vnodeId"
  :draggable="'true'"
  @click="select"
  @mouseover="target"
  @contextmenu.prevent.stop="showContextMenu"
  @dragstart="dragstart"
  @dragend="dragend"
>
  <div v-if="showThumbnail" class="pdt-img-thumb-wrap">
    <div class="pdt-img-thumb" :style="productImgThumbStyles"></div>
  </div>

  <div v-if="showTitle" class="pdt-title-wrap">
    <h1 class="pdt-title">{{ product.title || 'Product Title'}}</h1>
  </div>

  <div v-if="showTitle" class="pdt-short-divider my-3" />

  <div v-if="showBenefits" class="pdt-benefits-wrap">
    <div class="pdt-benefits">{{ product.benefits }}</div>
  </div>

  <div v-if="showCost" class="pdt-price-wrap">
    <h1 class="pdt-price">{{ product.price || 0 }} {{ product.currency || 'USD'}}</h1>
  </div>

  <div v-if="showSavings" class="pdt-discounted-price-wrap">
    <div class="pdt-discounted-price">
      <span class="disc-price-txt">Regular Price: </span>
      <span class="disc-price">{{ product.discountedPrice || 0.00 }} </span>
      <span class="disc-price-currency">{{ product.currency || 'USD' }}</span>
    </div>
  </div>

  <div class="pdt-price-buy-btn-wrap">
    <template v-for="node in fbNode.children">
      <template v-if="node.tag === 'c-button'">
        <c-button-v3 v-bind="node.data.props" :key="node.vnodeId" :fb-node="node"/>
      </template>
    </template>
  </div>
</div>
