
<GenAILayout>
  <div class="landingpage-goal-container">
    <div class="landingpage-goal-inner-container">
      <div class="landingpage-goal-wrapper">
        <h1 class="landingpage-goal-header">
          {{ $t('gen_ai.intent.description') }}
        </h1>

        <div
          v-for="(intentGoal, index) in intentGoals"
          :key="index"
          class="landingpage-goal-card-wrapper"
        >
          <cvt-goal-card
            :header-text="intentGoal.label"
            :icon="''"
            :is-active="intentGoal.value === selectedIntentGoal?.value"
            :value="intentGoal.value"
            @click="() => handleChange(intentGoal)"
          >
          </cvt-goal-card>
        </div>
      </div>
    </div>
    <hr />
    <div class="landingpage-goal-wrap">
      <div class="landingpage-goal-inner-btn-wrap">
        <cvt-button
          class="landingpage-goal-btn skip"
          :text="$t('editor.footer.back')"
          shape="round"
          color="light"
          :prepend="false"
          @click="handleBack"
        ></cvt-button>

        <cvt-button
          class="landingpage-goal-btn continue"
          :text="$t('custom_domain.ui.unlink_dialog.buttons.continue')"
          shape="round"
          color="primary"
          icon="arrow-right"
          :prepend="false"
          :loading="loading"
          :disabled="!selectedIntentGoal?.value"
          @click="handleLoadContent"
        ></cvt-button>
      </div>
    </div>
  </div>
</GenAILayout>
