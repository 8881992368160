<template>
  <span v-show="visible" class="vnode__move" :style="position" @click.stop.prevent="click">
    <el-tooltip :content="tip" placement="right">
      <i class="material-icons" v-text="icon"></i>
    </el-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    direction: {
      type: String,
      required: true
    }
  },
  computed: {
    icon () {
      return this.direction === 'up' ? 'arrow_upward' : 'arrow_downward'
    },
    position () {
      let position
      if (this.direction === 'up') {
        position = 'top'
      }

      if (this.direction === 'down') {
        position = 'bottom'
      }

      return {
        [position]: 0
      }
    },
    tip () {
      return `Move ${this.direction}`.toUpperCase()
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss">
  .vnode__move {
    left: -3px;
    width: 44px;
    text-align: center;
    position: absolute;
    z-index: 3;
    padding: .5em;
    font-size: 8pt;
    color: white;
    line-height: 8pt;
    text-transform: uppercase;
    transition: all 0.25s ease;
  }
</style>
