import FbVNode from '../../../../../components/base/FbVNode.vue'
import FirebaseNodeV3 from '../../../base/FirebaseNode'

export default FbVNode.extend({
  name: 'CountdownTimerV3',
  mixins: [FirebaseNodeV3],
  props: {
    type: {
      type: String,
      default: 'dateAndTimeBased',
    },
    themeStyle: {
      type: String,
      default: 'themeOne',
    },
    endDate: {
      type: String,
      default: '',
    },
    endTime: {
      type: String,
      default: '',
    },
    shortEndTime: {
      type: Number,
      default: 0,
    },
    timeZoneOffset: {
      type: String,
      default: '+00:00',
    },
    expireAction: {
      type: String,
      default: 'redirect_to_url',
    },
    expireActionMessage: {
      type: String,
      default: '',
    },
    expireActionPageId: {
      type: String,
      default: '',
    },
    expireActionRedirectionUrl: {
      type: String,
      default: '',
    },
    urgencyBasedTimeInMin: {
      type: Number,
      default: 0,
    },
    urgencyBasedTimeInSec: {
      type: Number,
      default: 0,
    },
    urgencyBasedTimeInHour: {
      type: Number,
      default: 0,
    },
    urgencyBasedTimeInDay: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      name: 'CountdownTimer',
    }
  },
  computed: {
    cdtWrapClasses() {
      return {
        cdtWrap: true,
        [this.themeStyle]: this.type === 'dateAndTimeBased',
        [this.type]: true,
      }
    },
    displayUrgencyBasedTimeInSec(){
      return this.urgencyBasedTimeInSec && !this.urgencyBasedTimeInDay
    }
  },
})
