
<cvt-dialog
  :show="isSwapBlockVisible"
  :size="'sm'"
  :height-auto="true"
  @close="abortBlockSwap"
>
  <template #title>
    <h5>{{ $t("editor.left_nav.block_manager.swap_block_dialog.template.h5") }}</h5>
  </template>

  <cvt-alert class="w-100" color="warning" icon="exclamation-triangle">
    <p>{{ $t("editor.left_nav.block_manager.swap_block_dialog.cvt_alert.p") }}</p>
    <span>{{ $t("editor.left_nav.block_manager.swap_block_dialog.cvt_alert.span") }}</span>
  </cvt-alert>

  <template #modalFooter>
    <div class="d-flex">
      <cvt-button
        class="mr-2"
        v-bind="cancelBtnProps"
        :text="$t(`editor.left_nav.swap_block_dialog.template.footer.cancel.btn`)"
        @click="abortBlockSwap"
      />
      <cvt-button
        v-bind="submitBtnProps"
        class="ml-2"
        :text="$t(`editor.left_nav.swap_block_dialog.template.footer.submit.btn`)"
        @click.stop="proceedBlockSwap"
      />
    </div>
  </template>
</cvt-dialog>
