<template>
  <section
    :id="anchorId"
    class="block"
    :class="classes"
    :data-vnode-id="vnodeId"
    @click="select"
    @mouseover="target"
    @contextmenu.stop.prevent="showContextMenu"
    @dblclick.stop.prevent="triggerOpenAssetManager"
  >
    <div
      v-show="showTextureBackground && isSectionUIEnabled"
      class="dashed-texture"
    />

    <div
      v-show="showControlls && isSectionUIEnabled"
      class="block__target__indicator block__target__indicator--top"
    ></div>
    <div
      v-show="showControlls"
      class="block__target__indicator block__target__indicator--right"
    ></div>
    <div
      v-show="showControlls && isSectionUIEnabled"
      class="block__target__indicator block__target__indicator--bottom"
    ></div>
    <div
      v-show="showControlls"
      class="block__target__indicator block__target__indicator--left"
    ></div>

    <div :class="stylesheet.classes.bgColor"></div>
    <div :class="stylesheet.classes.bgImage"></div>
    <div :class="stylesheet.classes.overlayColor"></div>

    <cvt-section-drag-items
      v-if="vnodeId && isGridBuilder && !isSectionUIEnabled"
      class="drag-items-block"
      :usage="'block'"
      :show-plus-btn="true"
      :vnode-id="vnodeId"
      :widget-class="'grid-stack-item'"
    />

    <div v-show="isSectionUIEnabled" class="section-controls-wrap">
      <section-controls
        v-show="showControlls"
        :class="sectionControlsClasses"
        :vnode-id="vnodeId"
        @moveSectionUp="moveSectionUp"
        @moveSectionDown="moveSectionDown"
        @copySection="copySection"
        @swapSection="swapSection"
        @deleteSection="deleteSection"
      />
    </div>

    <div v-show="!isEmpty" :class="containerClasses">
      <slot></slot>
    </div>

    <div v-show="isEmpty && !isGridBuilder">
      <h5 class="text-capitalize text-center">
        {{ $t('editor.section.empty.message') }}
      </h5>
      <p class="text-muted text-center">
        {{ $t('editor.section.row.add.message') }}
      </p>
    </div>

    <cvt-popover
      :ref="popoverRef"
      :mode="mode"
      placement="top"
      :target="`${anchorId}-add-core-element`"
      triggers="click"
    >
      <template #popper>
        <div class="d-flex">
          <cvt-button
            v-if="!isGridBuilder"
            class="text-capitalize"
            :text="$t('add_row.cta')"
            @click.prevent.stop="addRow"
          />
          <cvt-button
            color="success"
            class="text-capitalize ml-1"
            :text="$t('add_section.cta')"
            @click.stop.prevent="openQuickLaunch"
          />
        </div>
      </template>
    </cvt-popover>

    <div v-if="!isGridBuilder">
      <div
        v-if="addSectionsOnly"
        v-show="showAddBlockBtn"
        :id="`${anchorId}-add-core-element-sec`"
        class="add-structure-alt add-structure--bottom"
        @click.stop.prevent="openQuickLaunch"
      >
        {{ $t('add_section.cta') }}
      </div>
      <div
        v-else
        v-show="showAddBlockBtn"
        :id="`${anchorId}-add-core-element`"
        class="add-structure add-structure--bottom"
        @click.stop.prevent
      >
        {{ $t('editor.section.add.option.message') }}
      </div>
    </div>
  </section>
</template>

<script>
import color from 'color'
import uuid from 'uuid/v4'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { DuplicateSectionCommand } from '../../../commands/DuplicateSectionCommand'
import { RemoveSectionCommand } from '../../../commands/RemoveSectionCommand'
import { Row } from '../../../lib/helper-classes'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import * as mutations from '../../../store/modules/mutations'
import FbVNode from '../../base/FbVNode.vue'
import DragItems from './DragItems.vue'
import SectionControls from './SectionControls/SectionControls.vue'
import { PAGE_ENGINE_VDOM_UPDATE } from '@/js/plugins/events'

export default FbVNode.extend({
  components: {
    DragItems,
    SectionControls,
  },
  props: {
    isCover: Boolean,
    isFluid: Boolean,
    isPopupBlock: {
      type: Boolean,
      default: false,
    },
    bgShape: Object,
    sectionUIEnabled: {
      type: Boolean,
      default: true,
    },
    dc: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      name: 'Block',
      emptyStateClass: 'block--is-empty',
      addCoreElementVisible: false,
      mode: 'DARK',
    }
  },
  mounted() {
    if (!this.vnodeId) {
      this.updateProp('vnodeId', uuid())
    }
  },
  computed: {
    ...mapGetters({
      targetedBlock: getters.SELECTOR_GET_TARGET_BLOCK,
      viewMode: getters.VIEW_MODE,
      config: getters.AUTH_GET_USER_CONFIG,
      blockNodes: getters.LAYOUT_CHILDREN,
      currentProductVersionNumber: getters.CURRENT_PRODUCT_VERSION_NUMBER,
      pageEngine: getters.LAYOUT_PAGE_ENGINE,
    }),

    ...mapState('globalTheme', {
      addSectionsOnly: ({ globalTheme }) =>
        globalTheme.addSectionsOnly !== undefined
          ? globalTheme.addSectionsOnly
          : false,
    }),

    showAddBlockBtn() {
      return (
        this.selected ||
        this.targeted ||
        this.targetedBlock === this ||
        this.addCoreElementVisible ||
        this.isEmpty
      )
    },

    styles() {
      return this.getStyles()
    },

    popoverRef() {
      return `${uuid()}-popover`
    },

    isSectionUIEnabled() {
      if (!this.sectionUIEnabled) {
        return false
      }
      return this.config.SECTION_UI_ENABLED
    },
    sectionControlsClasses() {
      return {
        'section-control-d-wrap': this.isDesktopView,
        'section-control-m-wrap': this.isMobileView,
      }
    },

    localClasses() {
      return {
        'block--cover': this.isCover,
        'desktop-device': this.isDesktopView,
        'mobile-device': this.isMobileView,
        'overlay-texture': this.showTextureBackground,
      }
    },

    containerClasses() {
      return {
        [this.isFluid ? 'container-fluid' : 'container']: true,
        [this.stylesheet.classes.content]: true,
        ['alt-text-color']: this.isBackgroundColorDark,
      }
    },

    backgroundImage() {
      return this.background?.image
    },

    appliedBackgroudColor() {
      // Overlay background color
      if (this.background.overlay && this.overlayColorHSL) {
        return this.overlayColorHSL
      }
      // Background color
      return this.themeColorHSL
    },

    isBackgroundColorDark() {
      if (this.appliedBackgroudColor) {
        let appliedBgColor = color(this.appliedBackgroudColor)

        if (appliedBgColor.alpha() < 0.4 || appliedBgColor.lightness() >= 60) {
          return false
        }

        if (appliedBgColor.lightness() < 60) {
          return true
        }
      }

      return false
    },

    containerFontColorHex() {
      if (this.appliedBackgroudColor) {
        let appliedBgColor = color(this.appliedBackgroudColor)

        if (appliedBgColor.alpha() < 0.4 || appliedBgColor.lightness() >= 60) {
          return color('black').hex()
        }

        if (appliedBgColor.lightness() < 60) {
          return color('white').hex()
        }
      }

      return this.fontColorHEX
    },

    editorNodes() {
      return this.blockNodes.filter((node) => node.isEditorNode)
    },
    isDesktopView() {
      return this.viewMode === 'desktop'
    },
    isMobileView() {
      return this.viewMode === 'phone'
    },
    showTextureBackground() {
      return (
        (this.isDesktopView && this.hideOnDesktop) ||
        (this.isMobileView && this.hideOnMobile)
      )
    },
  },
  methods: {
    ...mapMutations({
      quickLaunchBlockSetInsertHandler:
        mutations.QUICK_LAUNCH_BLOCK_SET_INSERT_HANDLER,
    }),

    ...mapActions({
      toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
      refreshLayout: actions.LAYOUT_REFRESH,
      pushCommand: actions.HISTORY_COMMAND_PUSH,
      leftSideMenuToggle: actions.LEFT_SIDE_MENU_TOGGLE,
      swapBlockShowDialog: actions.SWAP_BLOCK_SHOW_DIALOG,
      swapBlockSetCurrentBlockData: actions.SWAP_BLOCK_SET_CURRENT_BLOCK_DATA,
    }),
    getStyles() {
      let backgroundImagePositionCalculated = this.getBackgroundImagePosition()
      let bgColorApplied = this.appliedBackgroudColor

      let bgColorZeeIndex = this.getBgColorZeeIndex()
      let bgImageZeeIndex = this.getBgImageZeeIndex()
      let overlayColorZeeIndex = this.getOverlayColorZeeIndex()

      return {
        bgImage: {
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: bgImageZeeIndex,
          backgroundImage:
            this.backgroundImage && `url(${this.backgroundImage})`,
          backgroundPosition: backgroundImagePositionCalculated,
          backgroundRepeat: this.background.repeat
            ? this.background.repeat
            : 'no-repeat',
          backgroundSize: this.background.size ? this.background.size : 'cover',
          backgroundAttachment: this.background.parallax ? 'fixed' : null,
          '@media only screen and (max-width: 767px)': {
            backgroundAttachment: 'scroll',
            // backgroundImage: 'USE MOBILE RESPONSIVE VERSION FOR BETTER PERFORMANCE'
          },
        },
        bgColor: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: bgColorZeeIndex,
          position: 'absolute',
          backgroundColor: bgColorApplied,
        },
        overlayColor: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: overlayColorZeeIndex,
          position: 'absolute',
          backgroundColor: bgColorApplied,
        },
        content: {
          color: this.containerFontColorHex,
          ...this.spacing.padding.toObject(),
          ...this.spacing.margin.toObject(),
          ...this.formColorTransform,
        },
      }
    },
    openQuickLaunch() {
      this.$refs[this.popoverRef].$refs.tooltip.doClose()
      this.addCoreElementVisible = false
      this.quickLaunchBlockSetInsertHandler(this.addElementBelow)
      this.toggleLeftSideMenu({ activate: 'sections' })
    },

    addRow() {
      this.$refs[this.popoverRef].$refs.tooltip.doClose()
      this.addCoreElementVisible = false
      this.refreshLayout()
      return this.push(new Row())
    },

    showAddRowDialog() {
      this.addRowDialogVisible = true
    },

    cancelAddRow() {
      this.numberOfColumns = 1
      this.addRowDialogVisible = false
    },

    updateBgSrc(assets) {
      if (!assets) return
      this.updateProp(
        'background/image',
        assets.block ? assets.block : assets.pop(),
      )
      if (this.themeColorId && this.themeColorAlpha === 1) {
        this.updateProp('themeColorAlpha', 0.8)
      }
    },

    triggerOpenAssetManager() {
      if (this.isGridBuilder) {
        return
      }
      this.openAssetManager(this.updateBgSrc)
    },

    canBeDeleted() {
      return this.siblings().length > 1
    },

    cantDeleteMsg() {
      return this.$t('editor.section.action.delete_last')
    },

    getBackgroundImagePosition() {
      if (
        this.isGridBuilder &&
        this.background.imagePosition &&
        this.background.imagePosition.calcPos &&
        this.background.imagePosition.calcPos.x != null &&
        this.background.imagePosition.calcPos.y != null
      ) {
        return `${this.background.imagePosition.calcPos.x}% ${this.background.imagePosition.calcPos.y}%`
      } else {
        return 'center center'
      }
    },
    getBgColorZeeIndex() {
      if (this.background.overlay && this.overlayColorHSL) {
        return -1
      }

      if (this.backgroundImage) {
        return 0
      }

      return 0
    },
    getBgImageZeeIndex() {
      if (this.background.overlay && this.overlayColorHSL) {
        return 0
      }

      if (this.backgroundImage) {
        return 0
      }

      return 0
    },
    getOverlayColorZeeIndex() {
      if (this.background.overlay && this.overlayColorHSL) {
        return 0
      }

      if (this.backgroundImage) {
        return -1
      }

      return -1
    },
    moveSectionUp(event) {
      let from = this.idx()
      let to = this.idx() - 1 // we want to move this block one-step up means, on lower index that is why we are substracting the 1 from the current index

      let node = this.editorNodes[from]

      if (node?.isFirst()) {
        this.$message.info(this.$t('editor.section.arrange.top_end'))
        return
      }

      // for v2 builder this function is being called from FbVNode.vue
      // for v3 builder this function is being called from FirebaseNode.js
      this.moveUp(to - from)
    },
    moveSectionDown(event) {
      let from = this.idx()
      let to = this.idx() + 1 // we want to move this block one-step down means, on higher index that is why we are adding the 1 to the current index

      let node = this.editorNodes[from]

      if (node?.isLast()) {
        this.$message.info(this.$t('editor.section.arrange.bottom_end'))
        return
      }
      // for v2 builder this function is being called from FbVNode.vue
      // for v3 builder this function is being called from FirebaseNode.js
      this.moveDown(to - from)
    },
    copySection(event) {
      if (this.currentProductVersionNumber >= 3.0) {
        return this.pushCommand(
          new DuplicateSectionCommand(Number(this.idx()), this.fbNode),
        )
        // TODO: needs to see what part does this below function plays in v3 builder, commenting it for now.
        // this.updateChildren();
      }
      return new Promise((res) => {
        this.duplicateAndValidate((_) => this.updateChildren())
        this.pageEngine.forceRefresh()
        res()
      })
    },
    swapSection(event) {
      event.preventDefault()
      event.stopPropagation()

      this.swapBlockSetCurrentBlockData(this)
      this.swapBlockShowDialog()
    },
    deleteSection(event) {
      if (this.currentProductVersionNumber >= 3.0 && this.canBeDeleted()) {
        return this.pushCommand(
          new RemoveSectionCommand(Number(this.idx()), this.fbNode),
        )
        // TODO: needs to see what part does this below function plays in v3 builder, commenting it for now.
        // this.updateChildren();
      }
      return new Promise((res) => {
        this.removeAndValidate((_) => this.updateChildren())
        this.pageEngine.forceRefresh()
        res()
      })
    },
  },
})
</script>

<style lang="scss">
@import './Block/Block.style.scss';
</style>
