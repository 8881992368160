
  <div
v-show="active && !isGridBuilder"
       class="dnd-zone"
       :class="classes"
       :style="style"
       @dragenter="validate"
       @dragleave="reset"
       @dragover="dragover"
       @drop.prevent="dropped"
  ></div>
