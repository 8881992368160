import * as _ from 'lodash'
import { Super } from '../services/api'

const superApi = new Super()

export default {
  namespaced: true,
  state: {
    currentOrgID: null,
    currentProjectID: null,
    organizations: [],
    projects: [],
    searching: false,
  },
  actions: {
    async onOrgChange({ state, dispatch }, orgID) {
      state.currentOrgID = orgID
      state.currentProjectID = ''
      return dispatch('searchProjects', state.currentProjectID)
    },
    async onProjectChange({ state, dispatch }, projectID) {
      state.currentProjectID = projectID
      return dispatch('sudo', {
        orgID: state.currentOrgID,
        projectID: state.currentProjectID,
      })
    },
    searchOrganizations: _.debounce(function ({ state }, query) {
      state.searching = true
      return superApi.searchOrganizations({ orgID: query }).then(({ data }) => {
        state.organizations = data
        state.searching = false
      })
    }, 500),
    searchProjects: _.debounce(function ({ state }, query) {
      if (!state.currentOrgID) {
        throw new Error(
          'Cannot search projects without first selecting an organization',
        )
      }

      state.searching = true
      return superApi
        .searchProjects({ orgID: state.currentOrgID, projectID: query })
        .then(({ data }) => {
          state.projects = data
          state.searching = false
        })
    }, 500),
    sudo(ctx, { orgID, projectID }) {
      return superApi.sudo({ orgID, projectID }).then((_) => {
        window.location.reload()
      })
    },
  },
}
