<template>
  <div class="container full-height">
    <div class="row full-height">
      <div
        class="col-lg-6 mx-auto loader-animation d-flex justify-content-center align-items-center"
      >
        <div class="loader-animation">
          <component
            :is="svg"
            v-if="svgPath === null || svgPath === 'undefined'"
          />
          <div v-else class="external-svg">
            <img :src="svgPath" class="img-style" />
          </div>
          <h2 class="loader-message" v-text="$t(msg)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InitializeAnimation from '../common/svgs/InitializeAnimation.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'DefaultLoader',
  components: {
    InitializeAnimation,
  },
  props: {
    svg: {
      type: String,
      default: 'InitializeAnimation',
    },
    svgPath: {
      type: String,
      default: null,
    },
    msg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
}
</script>
