<template>
  <div
    :id="vNodeId"
    ref="Container"
    data-identity="Container"
    :data-vnode-id="vNodeId"
    :class="classes"
    @click="onContainerClick"
    @mouseenter="mouseOverAction"
    @mouseleave="mouseLeaveAction"
    @contextmenu.prevent.stop="showContextMenu"
  >
    <div :class="containerBgImage" />
    <div :class="containerBgColor" />

    <slot/>
  </div>
</template>

<script>

export default {
  name: "ContainerAbstractTemplate",
  props: {
    vNodeId: {
      type: String,
      required: true
    },
    classes: {
      type: Object,
      required: true
    },
    onContainerClick: {
      type: Function,
      required: true
    },
    mouseOverAction: {
      type: Function,
      required: true
    },
    mouseLeaveAction: {
      type: Function,
      required: true
    },
    showContextMenu: {
      type: Function,
      required: true
    },
    containerBgImage: {
      type: Object,
      required: true
    },
    containerBgColor: {
      type: Object,
      required: true
    },
  },
};
</script>

<style scoped>

</style>