<template>
  <ContainerAbstractTemplate
    :classes="classes"
    :show-context-menu="showContextMenu"
    :mouse-leave-action="mouseLeaveAction"
    :mouse-over-action="mouseOverAction"
    :container-bg-color="ContainerBgColor"
    :container-bg-image="ContainerBgImage"
    :v-node-id="vnodeId"
    :style="dynamicWidth"
    :on-container-click="onClick"
  >
    <div :class="contentClasses">
      <CardTemplate
v-if="layout === 'left' || layout === 'center'"
                    :presentation="layout"
:layout-view="layoutView"
                    :template-key="fbTemplateKey"
                    :template="template"/>

      <NarrowCardTemplate
v-else-if="layout === 'narrow'"
                          :presentation="layout"
:layout-view="layoutView"
                          :template-key="fbTemplateKey"
                          :template="template"/>
      <div v-else>Unknown Presentation</div>
    </div>
  </ContainerAbstractTemplate>
</template>

<script>
import NarrowCardTemplate from "@/js/components/v3/editor/nodes/card/templates/NarrowCardTemplate.vue";
import CardTemplate from "@/js/components/v3/editor/nodes/card/templates/CardTemplate.vue";
import ContainerAbstractScript from '@/js/components/v3/editor/nodes/abstracts/ContainerAbstractScript'
import ContainerAbstractTemplate from "@/js/components/v3/editor/nodes/abstracts/ContainerAbstractTemplate.vue";
import FbVNode from "@/js/components/base/FbVNode.vue";
import { CButton as CButtonData } from "@/js/lib/helper-classes";

// Spacing - Only margin can be applied

export default FbVNode.extend({
  name: "CardControl",
  mixins: [ContainerAbstractScript],
  components: {
    ContainerAbstractTemplate,
    NarrowCardTemplate,
    CardTemplate
  },
  props: {
    template: Object,
    layout: {
      type: String,
      required: true,
    },
    layoutView: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    cardWidth: {
      type: Number,
      required: true,
    },
    itemStyles: {
      type: Object,
      required: true
    }
  },
  data() {
    const fbKey = `cardItems/${this.index}/`
    // Edit carefully: Path to CardItemTemplate computed with CardContainer
    const fbTemplateKey = `${this.parent().path()}/data/props/${fbKey}/template/`.replaceAll('//', '/')

    return {
      name: 'CardItem',
      fbTemplateKey,
      fbKey,
    }
  },
  computed: {
    extraClasses() {
      return {
        [this.stylesheet.classes.itemStyles]: true
      }
    },
    otherStyles () {
      return {
        '@global': {
          //  use "@media (max-width: 576px)": {} on api component
          '.container-phone': {
            flex: "0 0 100%",
            maxWidth: "100%"
          },
        },
        itemBgColor: {
          ...this.getBorderStyles()
        }
      }
    },
    set () {
      return this.template.icon.set
    },
    selector () {
      return this.template.icon.selector
    },
    size() {
      return this.template.icon.size
    },
    dynamicWidth () {
      return {
        width: `${this.cardWidth}rem`
      }
    }
  },
  methods: {
    updateProp: function (key, value) {
      if (['selector', 'set', 'size'].includes(key)) {
        key = `template/icon/${key}`
      }
      const keyFormat = `${this.fbKey}/${key}`.replaceAll("//", "/")
      return this.parent().updateProp(keyFormat, value)
    },
    async onClick(e) {
      await this.deselect()
      this.$nextTick(() => {
        this.edit(e)
      })
    },
    mouseOverAction (e) {
      e.stopPropagation()
      this.$el.classList.add('show-hover-effect')
    },
    mouseLeaveAction (e) {
      e.stopPropagation()
      // this.$parent.$parent.$el.classList.remove('hide-grid-lines')
      this.$el.classList.remove('show-hover-effect')
    },
    updateIconImgSrc(assets) {
      if (!assets) return
      this.updateProp('template/icon/src', assets.image ? assets.image : assets.pop())
    },
    getBorderStyles () {
      let defaultBorder = `${this.borderWidth || 0}px ${this.borderStyle} ${
        this.borderColor
      }`
      let defaultStyle = {};

      if (this.borderPosition && this.borderPosition.all === 'set') {
        const borderPosition = this.itemStyles.borderPosition
        if (borderPosition && Object.keys(borderPosition).length) {
          //Set the selections
          if (borderPosition.all) {
            defaultStyle['border'] = defaultBorder
          } else {
            defaultStyle['border'] = 'unset'
          }
          if (borderPosition.top) {
            defaultStyle['borderTop'] = defaultBorder
          }
          if (borderPosition.btm) {
            defaultStyle['borderBottom'] = defaultBorder
          }
          if (borderPosition.lft) {
            defaultStyle['borderLeft'] = defaultBorder
          }
          if (borderPosition.ryt) {
            defaultStyle['borderRight'] = defaultBorder
          }
        } else {
          defaultStyle['border'] = defaultBorder
        }
      }

      return defaultStyle
    },
  }
});
</script>

<style lang="scss" scoped>
.show-hover-effect {
  cursor: pointer;
  outline: #7c9dd5 solid 3px;
}
</style>