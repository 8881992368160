import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'
import loadIntercom from '../../lib/loadIntercom'
import { INTERCOM_SET_USER_DATA } from "./mutations";
import { INTERCOM_INIT_FROM_CONFIG } from "./actions";

function Intercom (...args) {
  if (!window.Intercom) {
    return console.debug('Intercom is not currently installed', ...args)
  }
  window.Intercom(...args)
}

export default {
  state: {
    visible: false,
    unread: 0,
    loaded: false,
    _userData: null
  },
  getters: {
    [getters.INTERCOM_VISIBLE] ({ visible }) {
      return visible
    },
    [getters.INTERCOM_UNREAD] ({ unread }) {
      return unread
    }
  },
  mutations: {
    [mutations.INTERCOM_LOADED] (state, loaded) {
      state.loaded = loaded
    },
    [mutations.INTERCOM_SET_USER_DATA] (state, user) {
      state._userData = user
    }
  },
  actions: {
    async [actions.INTERCOM_BOOT] ({ state, rootGetters, dispatch, commit }, user) {
      if (state.loaded) {
        return
      }

      commit(mutations.INTERCOM_LOADED, true)
      commit(mutations.INTERCOM_SET_USER_DATA, user)

      dispatch(actions.INTERCOM_INIT_FROM_CONFIG, 'HELP_BUTTON')
    },
    async [actions.INTERCOM_INIT_FROM_CONFIG] ({ state, rootGetters, commit }, key) {
      if (!state.user) {
        return
      }

      const config = rootGetters[getters.AUTH_GET_USER_CONFIG]
      const { type: mode, value: intercomAppID } = config[key] || {}

      if (mode !== 'intercom') {
        console.debug('Help button is not set to intercom mode')
        return
      }

      await loadIntercom(intercomAppID)

      // let method = state.loaded ? 'boot' : 'update'
      // boot once update user on route change
      Intercom('boot', {
        ...state.user,
        app_id: intercomAppID
      })

      // only init onUnreadCountOnce
      Intercom('onUnreadCountChangemethod', unreadCount => {
        state.unread = unreadCount
      })

      Intercom('onShow', _ => {
        state.visible = true
      })

      Intercom('onHide', _ => {
        state.visible = false
      })

      Intercom('hide')
    },
    async [actions.INTERCOM_TRACK] ({ state }, { event, context }) {
      Intercom('trackEvent', event, context)
    },
    async [actions.INTERCOM_SHOW] ({ state }) {
      Intercom('show')
    },
    async [actions.INTERCOM_HIDE] ({ state }) {
      Intercom('hide')
    },
    async [actions.INTERCOM_TOGGLE] ({ state, dispatch }) {
      return dispatch(state.visible ? actions.INTERCOM_HIDE : actions.INTERCOM_SHOW)
    }
  }
}
