<template>
  <div class="create-new-custom-field">
    <button
      class="text-capitalize btn btn-outline-secondary btn-block"
      @click="open"
    >
      {{ $t('element.property.form.option.create_list') }}
    </button>

    <cvt-dialog
      :show="dialogVisible"
      :height-auto="true"
      size="sm"
      @close="handleClose"
    >
      <template #title>
        <h5>{{ $t('element.property.form.field.custom.list.attr') }}</h5>
      </template>
      <div class="form-group">
        <label for="new-contact-attr-name">{{
          $t('element.properties.section.title')
        }}</label>
        <cvt-input
          type="text"
          :value="attributes.listName || ''"
          :label="$t('element.property.form.field.custom.list.name')"
          :placeholder="$t('element.property.form.field.custom.list.name')"
          @input="(e) => updateAttributeName(e)"
        />
        <small>{{ $t('element.property.form.field.custom.list.help') }}</small>
      </div>

      <template #modalFooter>
        <span class="dialog-footer d-flex">
          <cvt-button
            class="mr-1"
            color="light"
            :text="$t('element.property.cta.cancel')"
            @click="reset"
          />
          <cvt-button
            :text="$t('element.property.cta.confirm')"
            @click="confirm"
          />
        </span>
      </template>
    </cvt-dialog>
  </div>
</template>

<script>
import { V1CRM } from '@/js/store/services/api'
import { Notification } from 'element-ui'
import { mapGetters } from 'vuex'
import * as getters from '../../../store/modules/getters'
import { clientSpecificCustomParam } from '../../../utils/helpers'

const v1crm = new V1CRM()
function attrs() {
  return {
    listName: null,
  }
}

export default {
  data() {
    return {
      dialogVisible: false,
      attributes: attrs(),
    }
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),
  },
  methods: {
    close() {
      this.dialogVisible = false
    },
    open() {
      this.dialogVisible = true
    },
    reset() {
      this.attributes = attrs()
      this.close()
    },
    async confirm() {
      try {
        const optionalBodyParams = clientSpecificCustomParam(this.config) || {}
        const { data } = await v1crm.createCrmList({
          ...this.attributes,
          ...optionalBodyParams,
        })
        this.$emit('created', data)
        this.reset()
      } catch (error) {
        let errors = error.response.data.errors || []
        if (errors.length) {
          // extract the first error in the list
          errors = error.response.data.errors[0].message
        }
        Notification({
          title: 'Error',
          message:
            error?.response?.data?.message ||
            (errors.length && errors) ||
            'Something went wrong',
          type: 'error',
          duration: 15000,
          position: 'bottom-right',
        })
      }
    },
    handleClose() {
      this.reset()
    },
    updateAttributeName({ target }) {
      this.attributes.listName = target.value
    },
  },
}
</script>
