
  <div class="error-wrapper">
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1986 1352.92"
      style="enable-background:new 0 0 1986 1352.92;"
      xml:space="preserve"
    >
      <g id="Back">
        <g>
          <path
            class="st0"
            d="M703.32,959.32H181.65c-19.63,0-35.69-16.06-35.69-35.69V270.37c0-19.63,16.06-35.69,35.69-35.69h521.68
			c19.63,0,35.69,16.06,35.69,35.69v653.25C739.02,943.26,722.95,959.32,703.32,959.32z"
          />
          <path
            class="st1"
            d="M739.02,299.2H145.95v-28.83c0-19.63,16.06-35.69,35.69-35.69h521.68c19.63,0,35.69,16.06,35.69,35.69V299.2z
			"
          />
          <circle
            class="st0"
            cx="187.58"
            cy="266.27"
            r="14.76"
          />
          <circle
            class="st0"
            cx="237.88"
            cy="266.27"
            r="14.76"
          />
          <circle
            class="st0"
            cx="288.19"
            cy="266.27"
            r="14.76"
          />
          <path
            class="st0"
            d="M1833,1030.15h-452c-19.63,0-35.69-16.06-35.69-35.69V538.43c0-19.63,16.06-35.69,35.69-35.69h452
			c19.63,0,35.69,16.06,35.69,35.69v456.04C1868.69,1014.09,1852.63,1030.15,1833,1030.15z"
          />
          <path
            class="st1"
            d="M1868.69,561.41H1345.3v-22.99c0-19.63,16.06-35.69,35.69-35.69h452c19.63,0,35.69,16.06,35.69,35.69V561.41z
			"
          />
          <g>
            <path
              class="st0"
              d="M1351.88,861.92c-21.61,0-39.19-17.58-39.19-39.19V314.21c0-21.61,17.58-39.19,39.19-39.19H1757
				c21.61,0,39.19,17.58,39.19,39.19v508.52c0,21.61-17.58,39.19-39.19,39.19H1351.88z"
            />
            <path
              class="st2"
              d="M1757,278.52c19.63,0,35.69,16.06,35.69,35.69v508.52c0,19.63-16.06,35.69-35.69,35.69h-405.12
				c-19.63,0-35.69-16.06-35.69-35.69V314.21c0-19.63,16.06-35.69,35.69-35.69H1757 M1757,271.52h-405.12
				c-23.54,0-42.69,19.15-42.69,42.69v508.52c0,23.54,19.15,42.69,42.69,42.69H1757c23.54,0,42.69-19.15,42.69-42.69V314.21
				C1799.69,290.67,1780.54,271.52,1757,271.52L1757,271.52z"
            />
          </g>
          <path
            class="st1"
            d="M1792.69,343.04h-476.51v-28.83c0-19.63,16.06-35.69,35.69-35.69H1757c19.63,0,35.69,16.06,35.69,35.69
			V343.04z"
          />
          <g>
            <path
              class="st0"
              d="M1242.95,732.99c-21.61,0-39.19-17.58-39.19-39.19V185.27c0-21.61,17.58-39.19,39.19-39.19h405.12
				c21.61,0,39.19,17.58,39.19,39.19v508.52c0,21.61-17.58,39.19-39.19,39.19H1242.95z"
            />
            <path
              class="st2"
              d="M1648.08,149.58c19.63,0,35.69,16.06,35.69,35.69v508.52c0,19.63-16.06,35.69-35.69,35.69h-405.12
				c-19.63,0-35.69-16.06-35.69-35.69V185.27c0-19.63,16.06-35.69,35.69-35.69H1648.08 M1648.08,142.58h-405.12
				c-23.54,0-42.69,19.15-42.69,42.69v508.52c0,23.54,19.15,42.69,42.69,42.69h405.12c23.54,0,42.69-19.15,42.69-42.69V185.27
				C1690.77,161.73,1671.62,142.58,1648.08,142.58L1648.08,142.58z"
            />
          </g>
          <path
            class="st1"
            d="M1683.77,214.1h-476.51v-28.83c0-19.63,16.06-35.69,35.69-35.69h405.12c19.63,0,35.69,16.06,35.69,35.69
			V214.1z"
          />
          <circle
            class="st0"
            cx="1249.47"
            cy="183.89"
            r="14.76"
          />
          <circle
            class="st0"
            cx="1299.78"
            cy="183.89"
            r="14.76"
          />
          <circle
            class="st0"
            cx="1350.08"
            cy="183.89"
            r="14.76"
          />
          <line
            class="st3"
            x1="855.55"
            y1="470.7"
            x2="799.48"
            y2="526.77"
          />
          <line
            class="st3"
            x1="1258.78"
            y1="959.32"
            x2="1202.71"
            y2="1015.39"
          />
          <line
            class="st3"
            x1="674.15"
            y1="121.54"
            x2="618.08"
            y2="177.62"
          />
          <line
            class="st3"
            x1="1141.62"
            y1="238.87"
            x2="1141.62"
            y2="318.17"
          />
          <line
            class="st3"
            x1="748.93"
            y1="1057.16"
            x2="692.86"
            y2="1113.24"
          />
          <line
            class="st3"
            x1="257.16"
            y1="1057.16"
            x2="201.09"
            y2="1113.24"
          />
          <line
            class="st3"
            x1="1086.79"
            y1="186.07"
            x2="1142.86"
            y2="242.14"
          />
          <line
            class="st3"
            x1="891.85"
            y1="549.8"
            x2="947.92"
            y2="605.87"
          />
          <line
            class="st3"
            x1="1204.08"
            y1="1012.83"
            x2="1204.08"
            y2="1092.12"
          />
          <line
            class="st3"
            x1="209.85"
            y1="121.54"
            x2="265.92"
            y2="177.62"
          />
          <line
            class="st3"
            x1="567.16"
            y1="121.54"
            x2="623.23"
            y2="177.62"
          />
          <line
            class="st3"
            x1="786.89"
            y1="959.32"
            x2="842.96"
            y2="1015.39"
          />
          <line
            class="st3"
            x1="840.79"
            y1="1012.83"
            x2="920.09"
            y2="1012.83"
          />
          <line
            class="st3"
            x1="1633.14"
            y1="1089.06"
            x2="1689.21"
            y2="1145.14"
          />
          <line
            class="st3"
            x1="1755.91"
            y1="155.86"
            x2="1811.98"
            y2="211.93"
          />
        </g>
      </g>
      <g id="Man">
        <g>
          <g>
            <circle
              class="st4"
              cx="951.96"
              cy="260.46"
              r="87.12"
            />
            <path
              class="st5"
              d="M951.96,181.84c43.42,0,78.62,35.2,78.62,78.62c0,43.42-35.2,78.62-78.62,78.62s-78.62-35.2-78.62-78.62
				C873.34,217.04,908.54,181.84,951.96,181.84 M951.96,164.84c-52.72,0-95.62,42.89-95.62,95.62s42.89,95.62,95.62,95.62
				s95.62-42.89,95.62-95.62S1004.68,164.84,951.96,164.84L951.96,164.84z"
            />
          </g>
          <line
            class="st6"
            x1="911.8"
            y1="234.74"
            x2="951.96"
            y2="263.46"
          />
          <line
            class="st6"
            x1="985.34"
            y1="244.89"
            x2="951.96"
            y2="263.46"
          />
          <path
            class="st2"
            d="M1501.36,681.05H636.23c-19.63,0-35.69-16.06-35.69-35.69V456.44c0-19.63,16.06-35.69,35.69-35.69h865.13
			c19.63,0,35.69,16.06,35.69,35.69v188.92C1537.05,664.99,1520.99,681.05,1501.36,681.05z"
          />
          <g>
            <path
              class="st7"
              d="M1537.05,481.05H600.54v-24.62c0-19.63,16.06-35.69,35.69-35.69h865.13c19.63,0,35.69,16.06,35.69,35.69
				V481.05z"
            />
          </g>
          <rect
            x="659.23"
            y="543"
            class="st8"
            width="819.13"
            height="63.79"
          />
          <rect
            x="668.44"
            y="552.83"
            class="st5"
            width="42.43"
            height="44.13"
          />
          <rect
            x="719.05"
            y="552.83"
            class="st5"
            width="42.43"
            height="44.13"
          />
          <rect
            x="769.67"
            y="552.83"
            class="st5"
            width="42.43"
            height="44.13"
          />
          <rect
            x="820.29"
            y="552.83"
            class="st5"
            width="42.43"
            height="44.13"
          />
          <rect
            x="870.91"
            y="552.83"
            class="st5"
            width="42.43"
            height="44.13"
          />
          <rect
            x="921.52"
            y="552.83"
            class="st5"
            width="42.43"
            height="44.13"
          />
          <rect
            x="972.14"
            y="552.83"
            class="st5"
            width="42.43"
            height="44.13"
          />
          <rect
            x="1022.76"
            y="552.83"
            class="st5"
            width="42.43"
            height="44.13"
          />
          <rect
            x="1073.38"
            y="552.83"
            class="st5"
            width="42.43"
            height="44.13"
          />
          <rect
            x="1123.99"
            y="552.83"
            class="st5"
            width="24.53"
            height="44.13"
          />
          <circle
            class="st2"
            cx="641"
            cy="452.67"
            r="14.76"
          />
          <circle
            class="st2"
            cx="691.31"
            cy="452.67"
            r="14.76"
          />
          <circle
            class="st2"
            cx="741.61"
            cy="452.67"
            r="14.76"
          />
          <circle
            class="st2"
            cx="951.96"
            cy="262.46"
            r="9.03"
          />
          <path
            class="st9"
            d="M1536.52,482.05v166.41c0,18.92-15.34,34.25-34.25,34.25H676.83"
          />
        </g>
        <g>
          <ellipse
            class="st10"
            cx="802.78"
            cy="1149.32"
            rx="483.19"
            ry="8.59"
          />
          <polyline
            class="st11"
            points="361.96,683.13 361.96,906.11 351.96,1147.76 		"
          />
          <line
            class="st11"
            x1="437.48"
            y1="906.11"
            x2="447.48"
            y2="1147.76"
          />
          <line
            class="st11"
            x1="568.48"
            y1="906.11"
            x2="578.48"
            y2="1147.76"
          />
          <line
            class="st12"
            x1="361.96"
            y1="776.75"
            x2="399.2"
            y2="776.75"
          />
          <path
            class="st13"
            d="M417.83,803.35"
          />
          <path
            class="st4"
            d="M399.2,818.6c0,0-40.86-81.11-40.59-135.88c0.09-18.81,3.04-60.25,81.19-63.54s135.54,85.5,135.54,85.5
			L399.2,818.6L399.2,818.6z"
          />
          <g>
            <defs>
              <path
                id="SVGID_3_"
                d="M399.2,818.6c0,0-42.25-84.76-40.59-139.51c0.68-22.56,8.77-59.79,86.99-59.82
					c73.74-0.03,129.74,85.41,129.74,85.41L399.2,818.6L399.2,818.6z"
              />
            </defs>
            <clipPath id="SVGID_1_">
              <use
                xlink:href="#SVGID_3_"
                style="overflow:visible;"
              />
            </clipPath>
            <g class="st14">
              <g>
                <circle
                  class="st5"
                  cx="345.26"
                  cy="659.6"
                  r="24.46"
                />
                <circle
                  class="st5"
                  cx="430.66"
                  cy="654.92"
                  r="24.46"
                />
                <circle
                  class="st5"
                  cx="516.06"
                  cy="650.24"
                  r="24.46"
                />
                <circle
                  class="st5"
                  cx="392.25"
                  cy="726.82"
                  r="24.46"
                />
                <circle
                  class="st5"
                  cx="477.65"
                  cy="722.14"
                  r="24.46"
                />
                <circle
                  class="st5"
                  cx="441.8"
                  cy="794.67"
                  r="24.46"
                />
              </g>
            </g>
          </g>
          <g>
            <path
              class="st15"
              d="M435.91,651.71c-0.19-0.04-0.37-0.08-0.56-0.13c-14.24-3.38-23.04-17.66-19.66-31.9l22.44-94.55
				c3.38-14.24,17.66-23.02,31.9-19.66c14.24,3.38,23.04,17.66,19.66,31.9l-22.44,94.55C463.91,645.97,449.96,654.73,435.91,651.71z
				"
            />
          </g>
          <path
            class="st15"
            d="M454.27,604.74l4.83,1.86c23.9,9.21,50.3-5.57,54.94-30.76l5.39-29.29c3.6-19.52-7.34-38.81-25.95-45.74h0
			c-21.17-7.89-44.73,2.88-52.62,24.05l-10.22,27.43C422.81,573.29,433.35,596.68,454.27,604.74z"
          />
          <path
            class="st15"
            d="M439.23,568.19L439.23,568.19c7.91-2.41,12.41-10.86,10-18.78l-2.49-8.16c-2.41-7.91-10.86-12.41-18.78-10h0
			c-7.91,2.41-12.41,10.86-10,18.78l2.49,8.16C422.87,566.1,431.32,570.6,439.23,568.19z"
          />
          <line
            class="st9"
            x1="426.63"
            y1="543.04"
            x2="435.08"
            y2="558.42"
          />
          <polyline
            class="st9"
            points="507.68,546.72 502.46,570.03 494.17,568.22 		"
          />
          <path
            class="st8"
            d="M444.84,559.19l21.99-38.79l5.22-9.21c1.77,3.3,4.09,6.26,6.85,8.82c9.98,9.28,45.66,15.94,56.93-7.35
			c9.19-19-15.08-13.04-25.65-17.14c-16.6-6.44-25.86-19.62-52.58-13.07c-31.99,7.84-28.42,48.47-28.42,48.47
			S442.77,529.38,444.84,559.19z"
          />
          <polygon
            class="st8"
            points="473.43,609.02 458.35,605.88 470.7,621.06 		"
          />
          <circle
            class="st8"
            cx="489.86"
            cy="554.58"
            r="4.21"
          />
          <circle
            class="st8"
            cx="514.77"
            cy="558.68"
            r="4.21"
          />
          <path
            class="st9"
            d="M478.95,571.75c0,0,5.68,1.69,6.87,7.26"
          />
          <path
            class="st15"
            d="M530.55,663.84c0,0,35.64-28.95,65.5,7.51s-22.37,30.15-22.37,30.15l-26.3-6.78L530.55,663.84z"
          />
          <polygon
            class="st15"
            points="822.96,1147.76 692.99,1147.76 676.61,1147.55 689.15,1089.22 747.94,1094.48 		"
          />
          <polygon
            class="st15"
            points="939.7,824.39 861.17,902.92 851.12,912.66 815.78,862.17 855.17,830.52 		"
          />
          <path
            class="st8"
            d="M627.35,786.8l-154.7,117.93h198.27v199.63h113.73V849.75c0-34.77-29.97-62.95-66.93-62.95H627.35z"
          />
          <path
            class="st8"
            d="M631,816.51l-100.63,74.44c-36.64,27.11-94.6,13.97-116.48-27.08L399.2,818.6l176.76-117.56
			c31.2-16.69,67.44-13.26,101.83,10.4l195.55,91.91l-49.07,98L631,816.51z"
          />
          <path
            class="st16"
            d="M664.26,791.8L530.38,893.95c0,0-16.13,13.61-48.01,13.61"
          />
          <path
            class="st16"
            d="M786.6,853.06c0,0-3.06-64.02-67.37-61.26c-12.57,0.54-54.96,0-54.96,0"
          />
          <line
            class="st16"
            x1="786.6"
            y1="853.06"
            x2="786.6"
            y2="974.25"
          />
          <line
            class="st12"
            x1="568.48"
            y1="906.11"
            x2="364.42"
            y2="906.11"
          />
          <line
            class="st11"
            x1="492.36"
            y1="906.11"
            x2="482.96"
            y2="1147.76"
          />
          <polyline
            class="st17"
            points="426.96,703.02 444.27,712.34 552.58,676.43 		"
          />
          <polyline
            class="st18"
            points="455,674.76 409.29,730.12 375.16,700.89 		"
          />
          <path
            class="st15"
            d="M551.17,695.86c-0.88,0.41-1.79,0.75-2.72,1.01c-0.4,0.12-0.81,0.21-1.22,0.29l1.2-0.39L551.17,695.86z"
          />
          <path
            class="st19"
            d="M560.03,680.11c0,9.45-4.94,17.54-11.94,20.91l-2.74,0.93l-1.2,0.4l-16.42,5.56
			c2.64-1.53,4.93-3.76,6.71-6.49c2.5-3.81,3.98-8.59,3.98-13.76c0-9.85-5.37-18.24-12.87-21.32c-1.99-0.82-4.13-1.27-6.36-1.27
			c-1.26,0-2.5,0.14-3.7,0.42l17.3-5.92l1.81-0.62l3.57-1.22c0.86-0.14,1.74-0.22,2.63-0.22
			C551.42,657.52,560.03,667.63,560.03,680.11z"
          />
          <path
            class="st15"
            d="M669.29,704.87c-2.51,4.48,1.85,16.82,17.44,24.82s30.1,4.28,32.21,0c1.46-2.97-10.72-13.67-21.95-19.08
			C685.23,704.96,671.47,701,669.29,704.87z"
          />
          <path
            class="st20"
            d="M540.68,659.57l-24.91,9.48c-3.15,1.2-6.67-0.38-7.87-3.53l0,0c-1.2-3.15,0.38-6.67,3.53-7.87l24.91-9.48
			c3.15-1.2,6.67,0.38,7.87,3.53l0,0C545.4,654.85,543.82,658.37,540.68,659.57z"
          />
        </g>
      </g>
    </svg>
    <div class="bottom">
      <h2 class="title">
        {{ $t("editor.error.maintenance.header") }}
      </h2>
      <p
        class="subtitle"
        v-text="config.MAINTENANCE_MESSAGE"
      />
    </div>
  </div>
