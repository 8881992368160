import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

export default {
  state: {
    subscriptionModalVisible: false
  },
  getters: {
    [getters.SUBSCRIPTION_MODAL_VISIBLE] ({ subscriptionModalVisible }) {
      return subscriptionModalVisible
    },
  },
  mutations: {
    [mutations.UPDATE_SUBSCRIPTION_MODAL_VISIBILITY] (state, visibilityValue) {
      state.subscriptionModalVisible = visibilityValue
    },
  },
  actions: {
    [actions.SET_SUBSCRIPTION_MODAL_VISIBILITY] ({ state, commit }, visibilityValue) {
      commit(mutations.UPDATE_SUBSCRIPTION_MODAL_VISIBILITY, visibilityValue);
    },
  }
}
