
<el-select v-model="vnodeId" placeholder="choose a section">
  <el-option
    v-for="(section, idx) in sections"
    :key="section.vnodeId"
    :label="fmtName(section, idx + 1)"
    :value="section.vnodeId"
  >
  </el-option>
</el-select>
