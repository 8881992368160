import Vue from '../../lib/utils/vue'
import { styled, VueEmotion } from '@egoist/vue-emotion'
import * as getters from '../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import CvtButton from './Button'


Vue.use(VueEmotion)


let Spacing, Radii, Border, Colors, textLineHeight, BorderStyle, textFontWeight, borderColor, backgroundColor, textFontSize, textColor, FontFamily;

const getContainer = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  width: ${(3*Spacing.SPACING_96) - Spacing.SPACING_8}px;
`

const getSiteUrlLabel = () => styled('label')`
  font-family: ${FontFamily.DEFAULT};
  text-align: left;
  font-style: normal;
  padding: 0px 0px 0px ${Spacing.SPACING_2}px;
  border-radius: 0px;
  ${textFontWeight('REGULAR')};
  ${textFontSize('sm')};
  ${textLineHeight('xl')};
`

const getSiteUrlAndButtonContainer = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

const getSiteUrl = () => styled('label')`
  font-family: ${FontFamily.DEFAULT};
  text-align: left;
  font-style: normal;
  border-radius: 0px;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  width: ${Spacing.SPACING_96 + (3*Spacing.SPACING_18)}px;
  height: ${Spacing.SPACING_16}px;
  padding: 0px 0px 0px ${Spacing.SPACING_2}px;
  ${textFontWeight('REGULAR')};
  ${textFontSize('sm')};
  ${textLineHeight('xl')};
  ${textColor({ themeing: 'dark' }, 400)};
`

const getSiteUrlCopyButton = () => {
  return styled(CvtButton)`
    font-family: ${FontFamily.DEFAULT};
    margin-left: ${Spacing.SPACING_4 + Spacing.SPACING_2}px;
  `
}

export default {
  name: 'SiteUrl',
  props: {
    labelText: {
      type: String,
      required: true,
    },
    siteUrl: {
      type: String,
      required: true,
    },
    displayLabelText: {
      type: Boolean,
      default: true,
    },
    siteUrlUniqueId: {
      type: String,
      default: '',
      required: true
    }
  },
  data() { return {} },

  methods: {
    siteUrlCopyBtnClicked(value){
      let siteUrlElement = this.$refs[this.siteUrlUniqueId];
      let url = siteUrlElement.textContent.trim();

      this.$emit('siteUrlCopyBtnClicked', value, url);
      this.copyTextToClipboard(url, siteUrlElement);
    },

    async copyTextToClipboard(url, siteUrlElement) {
      // for old browsers support, where this WEB-API is not available
      // a fallback method will be used to copy the text
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(siteUrlElement);
        return;
      }

      // If it is new or compatible browser, following WEB-API will
      // be called to copy the text to clipboard
      await navigator.clipboard.writeText(url);
    },

    fallbackCopyTextToClipboard(siteUrlElement) {

      window.getSelection().removeAllRanges();  // remove any previous selections
      let range = document.createRange();       // create new Range object
      range.selectNode(siteUrlElement);         // set our Range to contain the Node we want to copy from
      window.getSelection().addRange(range);    // select
      document.execCommand("copy");             // copy to clipboard
      window.getSelection().removeAllRanges();  // remove current and any previous selections
    }
  },

  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    Colors = this.Colors
    BorderStyle = this.BorderStyle
    FontFamily = this.FontFamily

    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    textColor = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
  },

  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
    }),

    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      BorderStyle: ({ globalTheme }) => globalTheme.BorderStyle,
    })
  },

  render: function (h) {

    const Container = getContainer();
    const SiteUrlLabel = getSiteUrlLabel();
    const SiteUrlAndButtonContainer = getSiteUrlAndButtonContainer();
    const SiteUrl = getSiteUrl();
    const SiteUrlCopyButton = getSiteUrlCopyButton();

    return (

      <Container>
        {
          this.displayLabelText && (
            <SiteUrlLabel>
              {this.labelText}
            </SiteUrlLabel>
          )
        }

        <SiteUrlAndButtonContainer>

          <SiteUrl ref={this.siteUrlUniqueId}>
            {this.siteUrl}
          </SiteUrl>

          <SiteUrlCopyButton
            mode={'LIGHT'}
            shape={'pill'}
            size={'sm'}
            icon={'clone'}
            icononly={true}
            specialPadding={this.Spacing.SPACING_4 + 'px'}
            onClick={(value) => this.siteUrlCopyBtnClicked(value)}
          ></SiteUrlCopyButton>

        </SiteUrlAndButtonContainer>

      </Container>
    )
  }
}
