import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import Vue from '../../lib/utils/vue'
import * as getters from '../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'

let Spacing, Colors, textColor, textLineHeight, labelStyles

Vue.use(VueEmotion)

const getStandardLabel = () => styled('label')`
  padding-top: ${Spacing.SPACING_8}px;
  padding-bottom: ${Spacing.SPACING_8}px;
  margin-bottom: 0;
  ${(props) =>{
    let styles = ''
    if(!props.fieldlabelStyles){
      if(props.mode === 'DARK'){
        styles += textColor({ themeing: 'light' }, 100)
      }else{
        styles += textColor({ themeing: 'dark' }, 700)
      }
      styles += textLineHeight('md')
      styles += `font-size: ${labelStyles.labelFontSize};`
      styles = `color: ${Colors[labelStyles.color.style][labelStyles.color.weight]};`
    }else{
      styles += `line-height: ${props.fieldlabelStyles.lineHeight};`
      styles += `font-size: ${props.fieldlabelStyles.labelFontSize};`
      styles = `color: ${Colors[props.fieldlabelStyles.color.style][props.fieldlabelStyles.color.weight]};`
    }
    return styles;
  }}
 
  label {
    display: inline-block;
  }
`

const getFloatingLabel = () => styled('span')``

const Fragment = {
  // TODO: VUE3_UPDATE: Vue 2 work around for fragmenting in jsx, can be removed and it native to Vue 3
  functional: true,
  render: (h, ctx) => ctx.children,
}

export default {
  /**
   * This component is for labeling form fields
   *
   * Currently we have 2 cases for labels: standard & floating
   *
   * This component also contains a type override for unknown cases
   * It can be manually passed in as a prop
   */
  components: {},
  props: {
    // Theming
    mode: {
      type: String,
      default: 'LIGHT',
    },
    // Passed in as this.id
    for: {
      type: String,
      default: '',
    },
    // Label text to be displayed
    label: {
      type: String,
      default: '',
    },
    // No label override
    noLabel: {
      type: Boolean,
      default: false,
    },
    // Label typing will become global, this is a manual override for a value not in configuration
    typeOverride: {
      type: String,
      default: null,
    },
    // Usage: floating labels
    inputSize: {
      type: String,
      default: '',
    },
    // TODO: What is the purpose of this though?
    inputLength: {
      type: Number,
      default: 64,
    },
    fieldlabelStyles: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      // Type is a value that will come from configuration not a prop
      type: 'standard',
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      labelType: ({ globalTheme }) => globalTheme.labelType,
      labelStyles: ({ globalTheme }) => globalTheme.labelStyles,
    }),
    showLabel() {
      return this.label !== '' && this.noLabel === false
    },
  },
  created() {
    Spacing = this.Spacing
    Colors = this.Colors
    textColor = this.textColor
    textLineHeight = this.textLineHeight
    // Set tenant Configurable styles via GlOBAL_THEME
    labelStyles = this.labelStyles
    // Set type to typeOverride if passed as prop, or set configurable type via GlOBAL_THEME
    this.typeOverride !== null
      ? (this.type = this.typeOverride)
      : (this.type = this.labelType)
  },
  methods: {},
  render: function (h) {
    const StandardLabel = getStandardLabel()
    const FloatingLabel = getFloatingLabel()

    if(this.showLabel === false) {
      return;
    }else {
      if(this.type === 'floating'){
        return(
          <FloatingLabel
              class="floating-label"
              inputSize={this.inputSize}
              inputLength={this.inputLength}
            >
              {this.label}
          </FloatingLabel>
        )
      }
      else{
        return(
          <StandardLabel 
            mode={this.mode} 
            for={this.for}
            fieldlabelStyles={this.fieldlabelStyles}
          >
            {this.label}
          </StandardLabel>
        )
      }
    }
  },
}
