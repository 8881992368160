<template>
  <div id="page-engine" data-vnode-id="page-engine" :class="pageEngineClasses">
    <div class="wizard-container">
      <div class="wizard-steps">
        <div
          :class="[
            'wizard-step',
            {
              active: true,
              completed: false,
            },
          ]"
        >
          {{
            currentSection?.title
              ? currentSection.title
              : `Step ${currentSectionIndex + 1}`
          }}
        </div>
      </div>
      <div class="wizard-content-wrapper">
        <div class="wizard-content">
          <wizard-item
            v-if="currentSection"
            :obj="currentSection"
            :wizard-index="currentSectionIndex"
            :is-reload="isReload"
            @genAIStatusChanged="
              (status) =>
                currentSectionStatusUpdate(
                  currentSection.title,
                  'genAIStatus',
                  status,
                )
            "
          ></wizard-item>
        </div>
      </div>
      <div class="wizard-navigation">
        <button :disabled="!canPrevious" @click="previousSection">
          Previous
        </button>
        <div class="wizard-navigation__next-buttons">
          <button
            v-if="!isLastSection"
            :disabled="!(canNext && isCurrentSectionValid)"
            @click="nextSection"
          >
            Next
          </button>
          <button v-else :disabled="!isCurrentSectionValid" @click="onFinish">
            Finish, Let's go to builder!
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FirebaseNodeV3 from '../base/FirebaseNode'
import { cloneVDomFromDelta } from '../lib/vue-dom-obj'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
import * as getters from '@/js/store/modules/getters'
import * as actions from '@/js/store/modules/actions'
import * as mutations from '@/js/store/modules/mutations'

import Block from '../editor/nodes/Block.vue'
import FbVNode from '../../base/FbVNode.vue'
import { PAGE_ENGINE_VDOM_UPDATE } from '../../../plugins/events'
import { DESKTOP, MOBILE } from '../../../common/constants/constants'
import HeaderEngine from '@/js/components/v3/header/HeaderEngine.vue'
import FooterEngine from '@/js/components/v3/footer/FooterEngine.vue'
import WizardItem from './WizardItem.vue'
import { cvtRouteNodes } from '@/js/utils/routes'

export default FbVNode.extend({
  name: 'WizardPageEngineV3',
  mixins: [FirebaseNodeV3],
  components: {
    block: Block,
    'wizard-item': WizardItem,
    'header-engine': HeaderEngine,
    'footer-engine': FooterEngine,
  },
  async beforeRouteLeave(to, from, next) {
    try {
      const pageIsValid = await this.pageIsValid()

      if (pageIsValid === false) {
        return next(false)
      }

      this.toggleAdvancedMenu(false)
      this.toggleLeftSideMenu({ open: false })
      this.updateSiteThumbnail()
      this.setViewMode('desktop')
      this.deselect()
      this.ignore()
      next()
    } catch (err) {
      console.error('beforeRouteLeave error', err)
      next(false)
    }
  },

  props: {
    // usually passed from router
    page: {
      type: Object,
      required: true,
    },
    popupPath: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      name: 'WizardPageEngineV3',
      clonedVDom: [],
      isReload: false,
      clonedVDomNavigationStatus: {},
      currentSectionIndex: 0,
    }
  },
  computed: {
    ...mapGetters({
      vdom: getters.DELTA_VDOM,
      site: getters.SITE_GET_DATA,
      step: getters.STEPS_CURRENT_STEP,
      siteGetActivePage: getters.SITE_GET_ACTIVE_PAGE,
      viewMode: getters.VIEW_MODE,
      historyReady: getters.HISTORY_INITIALIZED,
      config: getters.AUTH_GET_USER_CONFIG,
      builderVersion: getters.CURRENT_PRODUCT_VERSION_NUMBER
    }),
    pageEngineClasses() {
      return {
        'page-engine': true,
        'wizard-page-engine': true,
        typography: true,
        'grid-builder': true,
        'view-mode-desktop': this.viewMode === DESKTOP,
        'view-mode-phone': this.viewMode === MOBILE,
      }
    },
    currentSection() {
      return this.clonedVDom[this.currentSectionIndex]
    },
    canNext() {
      return this.currentSectionIndex < this.clonedVDom.length - 1
    },
    canPrevious() {
      return this.currentSectionIndex > 0
    },
    isLastSection() {
      return this.currentSectionIndex === this.clonedVDom.length - 1
    },
    isCurrentSectionValid() {
      const sectionGenAIStatus =
        this.clonedVDomNavigationStatus[this.currentSection?.title]

      if (!sectionGenAIStatus) {
        return false
      }

      return sectionGenAIStatus?.genAIStatus === 'completed'
    },
  },
  watch: {
    viewMode: {
      handler(newVal) {
        setTimeout(() => {
          this.$nextTick(() => {
            this.$mount()
            this.clonedVDom = [...this.clonedVDom]
          })
        }, 200)
      },
    },
    vdom: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.forceBuildDOM()
        }
      },
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => setTimeout(vm.initVdom, 500))
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      const pageIsValid = await this.pageIsValid()

      if (pageIsValid === false) {
        return next(false)
      }
      this.startLoader('Switching Pages')
      setTimeout(this.initVdom, 500)
      next()
    } catch (err) {
      console.debug('beforeRouteUpdate error', err)
      next(false)
    }
  },

  methods: {
    ...mapMutations({
      ignore: mutations.SELECTOR_IGNORE,
      setQuickLaunchEngine: mutations.QUICK_LAUNCH_BLOCK_SET_PAGE_ENGINE,
      setViewMode: mutations.VIEW_MODE_SET,
    }),
    ...mapActions({
      deltaInit: actions.DELTA_INIT,
      initHeader: actions.HEADER_DELTA_INIT,
      initFooter: actions.FOOTER_DELTA_INIT,
      markStep: actions.STEPS_MARK,
      updateSiteThumbnail: actions.SITE_UPDATE_THUMBNAIL,
      toggleAdvancedMenu: actions.ADVANCED_MENU_TOGGLE,
      toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
      initLayout: actions.LAYOUT_INIT,
      undo: actions.HISTORY_COMMAND_UNDO,
      redo: actions.HISTORY_COMMAND_REDO,
      setBuilderVersion: actions.SET_BUILDER_VERSION,
    }),

    ...mapMutations('loader', {
      setLoaderMsg: 'setMessage',
    }),

    ...mapActions('loader', {
      startLoader: 'start',
    }),
    ...mapActions('pageManager', {
      initPageTracking: 'pageTrackingInit',
    }),
    buildDOM() {
      this.clonedVDom = cloneVDomFromDelta(this.vdom)
      // Ensure each section has a title property
      this.clonedVDom.forEach((section, index) => {
        if (!section.title) {
          section.title = `Step ${index + 1}`
        }
      })
    },
    forceBuildDOM() {
      this.isReload = true

      this.buildDOM()
      this.$nextTick(() => {
        this.isReload = false
        // setTimeout(() => this.enableCommandPush(), 500)
      })
    },
    async pageIsValid() {
      return true
    },
    initVdom() {
      this.deltaInit({
        key: this.path(),
      })

      if (this.step.state === undefined) {
        this.markStep('inprogress')
      }

      this.initLayout({
        pageEngine: this,
      })

      // this.setQuickLaunchEngine({
      //   pageEngine: this,
      // })
      this.initPageTracking(this.page.id)
      // init header and footer
      this.initFooter(this.page.id)
      this.initHeader(this.page.id)

      console.debug(`Builder Version: ${this.builderVersion}`)
    },
    $hubEventHandler(data) {
      if (data.forceBuild) {
        this.forceBuildDOM()
        return
      }
      this.buildDOM()
    },
    nextSection() {
      if (this.canNext) {
        this.currentSectionIndex++
      }
    },

    previousSection() {
      if (this.canPrevious) {
        this.currentSectionIndex--
      }
    },
    currentSectionStatusUpdate(title, status, value) {
      const previousStatus = this.clonedVDomNavigationStatus[title] || {}
      const newStatus = { [title]: { ...previousStatus, [status]: value } }
      this.clonedVDomNavigationStatus = _.merge(
        this.clonedVDomNavigationStatus,
        newStatus,
      )
      if (status === 'genAIStatus' && value === 'completed') {
        this.forceBuildDOM()
      }
    },
    onFinish() {
      this.$router.push({
        name: cvtRouteNodes.editorDesign,
        params: {
          siteId: this.site.id,
        },
      })
    },
  },
  mounted() {
    this.$hub.$on(PAGE_ENGINE_VDOM_UPDATE, this.$hubEventHandler)
    this.disableCommandPush()
  },
  beforeDestroy() {
    this.$hub.$off(PAGE_ENGINE_VDOM_UPDATE, this.$hubEventHandler)
  },
})
</script>

<style scoped lang="scss">
.page-engine.wizard-page-engine > header {
  display: none !important;
}
.page-engine.wizard-page-engine > footer {
  display: none !important;
}

.wizard-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  width: 100%;
}

.wizard-steps {
  text-align: center;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;

  &.active {
    background-color: #e0e0e0;
    font-weight: bold;
  }

  &.completed {
    background-color: #d0f0d0;
  }
}

.wizard-content-wrapper {
  flex: 1;
  overflow-y: auto;
}

.wizard-content {
  padding: 5px;
}

.wizard-navigation {
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  border-top: 1px solid #ddd;
  padding: 10px;

  button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;

    &:disabled {
      background-color: #ddd;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background-color: #45a049;
    }
  }
  &__next-buttons {
    display: flex;
    gap: 10px;
  }
}
</style>
