import Vue from '../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import { CvtIcon } from '../molecules/icon'
import { mapState } from '../../../builder/node_modules/vuex'
let color

const getIndustryCardWrapper = () => styled('div')`
  font-family: Nunito;
  display: flex;
  justify-content: start;
  align-items: center;
  border: 1px solid
    ${(props) => (props.isActive ? `${color.PRIMARY[500]}` : '#bcc2cb')};
  height: 90px;
  border-radius: 4px;
  cursor: pointer;
  min-width: 300px;
`
const getLogoWrapper = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
`
const getLogo = () => styled(CvtIcon)`
  color: ${(props) => (props.isActive ? `${color.PRIMARY[500]}` : '#494C53')};
  font-size: 40px;
`
const getTextContent = () => styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
`
const getHeadertext = () => styled('h1')`
  font-size: 20px;
  margin: 0px;
  font-weight: 400;
  color: ${(props) => props.isActive && `${color.PRIMARY[500]}`};
`
const getSubHeadding = () => styled('p')`
  font-size: 16px;
  margin: 0px;
  font-weight: 400;
  color: #8a8e97;
`
const getTextContentWrap = () => styled('div')``
const getActiveIconWrap = () => styled('div')`
  padding-right: 20px;
`
const getActiveIcon = () => styled(CvtIcon)`
  color: ${color.PRIMARY[500]};
`

export const CvtIndustryCard = {
  name: 'CvtIndustryCard',
  data() {
    return {}
  },
  computed: {
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
    }),
  },
  created() {
    color = this.Colors
  },
  props: {
    headerText: {
      type: String,
      default: '',
    },
    subText: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClickCard() {
      this.$emit('click', this.value)
    },
  },
  render: function (h) {
    const IndustryCardWrapper = getIndustryCardWrapper()
    const LogoWrapper = getLogoWrapper()
    const Logo = getLogo()
    const TextContent = getTextContent()
    const Headertext = getHeadertext()
    const SubHeadding = getSubHeadding()
    const TextContentWrap = getTextContentWrap()
    const ActiveIconWrap = getActiveIconWrap()
    const ActiveIcon = getActiveIcon()

    return (
      <IndustryCardWrapper onClick={this.onClickCard} isActive={this.isActive}>
        <LogoWrapper>
          <Logo isActive={this.isActive} icon={this.icon}></Logo>
        </LogoWrapper>
        <TextContent>
          <TextContentWrap>
            <Headertext isActive={this.isActive}>{this.headerText}</Headertext>
            <SubHeadding>{this.subText}</SubHeadding>
          </TextContentWrap>
          {this.isActive && (
            <ActiveIconWrap>
              <ActiveIcon icon="check-circle"></ActiveIcon>
            </ActiveIconWrap>
          )}
        </TextContent>
      </IndustryCardWrapper>
    )
  },
}
