import loadChameleon from '../../lib/loadChameleon'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

function ChameleonApp(...args) {
  if (!window.chmln) {
    return console.debug('Chameleon is not currently installed', ...args)
  }
  return window.chmln
}

export default {
  state: {
    loaded: false,
  },
  getters: {},
  mutations: {
    [mutations.CHAMELEON_LOADED](state, loaded) {
      state.loaded = loaded
    },
  },
  actions: {
    async [actions.CHAMELEON_BOOT]({ state, rootGetters, commit }, user) {
      if (state.loaded) {
        return
      }

      const config = rootGetters[getters.AUTH_GET_USER_CONFIG]
      if (!config.CHAMELEON_SCRIPT_KEY) {
        return
      }

      await loadChameleon({email: user.email, fullName: user.fullName, id: user.projectID, orgID: user.orgID}, config.CHAMELEON_SCRIPT_KEY)
      commit(mutations.CHAMELEON_LOADED, true)
      ChameleonApp()
    },
  },
}
