
<div class="sc-main-wrap-d-and-m">
  <div v-if="isDesktopView" class="sc-main-wrap-d">
    <div v-if="!disableUpAndDownButton" :class="scLeftContentClasses">
      <div v-if="!hideMoveButtons" class="sc-arrow-btns-d">
        <cvt-section-up-down-actions 
          :vnode-id="vnodeId" 
          @moveSectionUp="moveSectionUp"
          @moveSectionDown="moveSectionDown"
          @arrangeBlock="arrangeBlock"
        />
      </div>

      <div :class="scSectionActionClasses">
        <cvt-section-setting-actions
          :vnode-id="vnodeId"
          :is-grid-builder="isGridBuilder"
          :header-text="headerText"
          :allowed-controls="allowedControls"
          @copySection="copySection"
          @swapSection="swapSection"
          @deleteSection="deleteSection"
        />
      </div>
    </div>

    <div v-if="vnodeId && !isBuilderOnStaticMode" class="sc-mid-content-d">
      <cvt-section-drag-items
        v-if="isGridBuilder"
        class="drag-items-section-controls"
        :usage="'block'"
        :show-plus-btn="false"
        :vnode-id="vnodeId"
        :widget-class="'grid-stack-item'"
      />
    </div>

    <div v-if="!disableUpAndDownButton" class="sc-right-content-d">
      <cvt-section-visibility />
    </div>
  </div>

  <div v-if="isMobileView" class="sc-main-wrap-m">
    <div :class="scLeftContentClasses">
      <div class="sc-arrow-btns-m">
        <cvt-section-up-down-actions 
          :vnode-id="vnodeId"
          :is-grid-builder="isGridBuilder"
          :show-up-and-down-button="disableUpAndDownButton"
          @moveSectionUp="moveSectionUp"
          @moveSectionDown="moveSectionDown"
          @arrangeBlock="arrangeBlock"
        />
      </div>

      <div v-if="!disableUpAndDownButton"  :class="scSectionActionClasses">
        <cvt-section-setting-actions
          :vnode-id="vnodeId"
          :allowed-controls="allowedControls"
          @copySection="copySection"
          @swapSection="swapSection"
          @deleteSection="deleteSection"
        />
      </div>
    </div>

    <div v-if="vnodeId" class="sc-mid-content-m">
      <cvt-section-drag-items
        v-if="isGridBuilder"
        class="drag-items-section-controls"
        :usage="'block'"
        :show-plus-btn="false"
        :vnode-id="vnodeId"
        :widget-class="'grid-stack-item'"
      />
    </div>

    <div v-if="!disableUpAndDownButton" class="sc-right-content-m">
      <cvt-section-visibility />
    </div>
  </div>
</div>
