import Vue from '../../../lib/utils/vue'
import { styled, VueEmotion } from '@egoist/vue-emotion'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import { CvtSchemaCollapseItem } from './SchemaCollapseItem'
import VueI18n from 'vue-i18n'

Vue.use(VueEmotion)
Vue.use(VueI18n)

let Spacing, Radii, Border, AddPagesToRight

let borderColor, backgroundColor, textFontSize, textLineHeight, elevation, color

const getSchemaContainer = () => styled('div')``

export const CvtSchemaContainer = {
  name: 'CvtSchemaContainer',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('globalTheme', {}),
    ...mapState('globalTheme', {}),
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    nodes: {
      type: Array,
      default: [],
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onSelectNode(node) {
      this.$emit('selectNode', node)
    }
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    AddPagesToRight = this.AddPagesToRight
    borderColor = this.borderColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    elevation = this.elevation
    color = this.textColor
  },
  render: function (h) {
    const SchemaContainer = getSchemaContainer()

    return (
      <div>
        <SchemaContainer>
          {this.nodes.map((node, index) => (
            <CvtSchemaCollapseItem
              key={index}
              node={node}
              level={this.level || 0}
              onSelectNode={this.onSelectNode}
            />
          ))}
        </SchemaContainer>
      </div>
    )
  },
}
