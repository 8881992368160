import { styled, VueEmotion } from '@egoist/vue-emotion'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import * as getters from '../../../builder/src/js/store/modules/getters'
import Vue from '../../lib/utils/vue'
import { CvtIcon } from '../molecules/icon/icon'

Vue.use(VueEmotion)


let FontFamily, 
Spacing, 
Radii, 
Colors, 
Border, 
ColoredActive, 
textFontWeight, 
color, 
backgroundColor, 
textFontSize, 
elevation, 
textColor, 
IconSize


const getLeftNav = () => styled('aside')`
  display: flex;
  flex-direction: column; 
  width: 320px;
  font-family: ${FontFamily.DEFAULT};
  overflow-y: auto;
  background-color: #fff;
  border-right: 1px solid ${Colors.BASIC[100]};

  &.__fixed {
    position: fixed;
    height: 100%;
    z-index: 1;
    overflow-y: auto;
    padding-bottom: ${Spacing.SPACING_64}px;
  }

  @media (max-width: 768px) {
    width: 150px;
  }
`

const getNavItem = () => styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-stretch;
  ${color({ themeing: 'dark' }, 500)}
  ${textFontSize('lg')}
  ${textFontWeight('LIGHT')}
  
  height: 72px;
  
  &:hover, :focus, :active {
    ${color({ themeing: 'dark' }, 'WHITE')} !important;
    background-color: ${Colors.BASIC[100]};
    cursor: pointer;
  }
  &.isActive {
    ${color({ themeing: 'primary' }, 500)}
    background-color: ${Colors.BASIC[100]};
  }
`

const getNavItemInsideWrap = () => styled('div')`
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  ${(props) => {
    let styles = ``
      styles += props.isText
        ? `flex: 1; border-bottom: 1px solid ${Colors.BASIC[100]};`
        : `padding: 0 ${Spacing.SPACING_24}px;`

    return styles
  }}
  ${(props) => {
    if (props.isActive) {
      return color({ themeing: 'dark' }, 700)
    } else {
      return props.mode === 'DARK'
        ? color({ themeing: 'light' }, 100)
        : color({ themeing: 'dark' }, 700)
    }
  }}
`
const getNavIcon = () => styled(CvtIcon)`
  width: ${Spacing.SPACING_16}px;
  font-size: ${IconSize.ICON_SIZE_XL}px;
`

const getNavText = () => styled('span')`
  margin: 0 ${Spacing.SPACING_4}px;
`

import { mapActions } from 'vuex'

export const CvtPopupLeftNavBar = {
  name: 'CvtPopupLeftNavBar',
  data () {
    return {
      activeId: this.initialActiveId,
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
    usage: {
      type: String,
      default: 'landingPage'
    },
    type: {
      type: String,
      default: 'standard'
    },
    navigationItems: {
      type: Array,
      default: () => []
    },
    fixed: {
      type: Boolean,
      default: true
    },
    initialActiveId: {
      type: String,
      default: ''
    },
    counts: {
      type: Object,
      default: null
    },
    showCount: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters ('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      elevation: getters.GLOBAL_STYLE_ELEVATION,
    }),
    ...mapState ('globalTheme', {
      leftNavConfig: ({ leftNavConfig }) => leftNavConfig,
      iconConfig: ({ iconConfig }) => iconConfig,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      Border: ({ globalTheme }) => globalTheme.Border,
      IconSize: ({ globalTheme }) => globalTheme.IconSize,
      ColoredActive: ({ globalTheme }) =>
        globalTheme.LeftSideBarColoredActive !== undefined
          ? globalTheme.LeftSideBarColoredActive
          : false,
      ShowLeftNavShadow: ({ globalTheme }) =>
        globalTheme.ShowLeftNavShadow !== undefined
          ? globalTheme.ShowLeftNavShadow
          : false,
      leftNavItemsLinkColor: ({ leftNavConfig }) => leftNavConfig.marketplace.navItems.linkColor
    })
  },
  methods: {
    onNavItemClicked (item) { this.$emit('navItemClicked', item) },
    itemIcon (item) {
      const categories = this.iconConfig['marketplaceCategories']
      let id = item.id
      if (item.id === null) {
        id = 'all'
      }
      if (item.id === 'your-popups') {
        id = 'ebook'
      }
      if (item.id === 'archived-popups') {
        id = 'archived-templates'
      }
      if (item.id === 'promotion') {
        id = 'promote_new_product'
      }
      return item.icon ? item.icon : categories[id] 
    },
    countText (item) {
      if (this.counts === null) return ''
      else {
        const templateCount = this.counts[item.id === null ? 'all' : item.id]
        if (templateCount === undefined) return ''
        else return `${templateCount} templates`
      }
    },
    formatLabel(label){
      let formatedLabel = ""
      if(label){
        const arr = label.split(" ");
        for (var j = 0; j < arr.length; j++) {
          arr[j] = arr[j].charAt(0).toUpperCase() + arr[j].slice(1).toLowerCase();
          formatedLabel = arr.join(" ");
        }
      return formatedLabel;
      }
    }
  },
  created () {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    Colors = this.Colors
    Border = this.Border
    IconSize = this.IconSize
    textFontWeight = this.textFontWeight
    color = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    elevation = this.elevation
    textColor = this.textColor
    ColoredActive = this.ColoredActive
  },
  render: function (h) {
    const LeftNav = getLeftNav()
    const NavItem = getNavItem()
    const NavItemInsideWrap = getNavItemInsideWrap()
    const NavIcon = getNavIcon()
    const NavText = getNavText()
    let navigationItems = this.navigationItems

    // Convert counts to the json type
    const navItems = navigationItems.map((item) => {
      let activeFlag = item.id === this.activeId
      return (
        <NavItem
          mode={this.mode}
          type={this.type}
          class={{ isActive: activeFlag }}
          usage={this.usage}
          onClick={() => {
            this.activeId = item.id
            this.onNavItemClicked(item)
          }}
          showCount={this.showCount}
        >
          <NavItemInsideWrap
            mode={this.mode}
            isText={false}
            type={this.type}
            usage={this.usage}
            isActive={activeFlag}
            showCount={this.showCount}
          >
            <NavIcon
              icon={this.itemIcon(item)}
              type={this.type}
              showCount={this.showCount}
            ></NavIcon>
          </NavItemInsideWrap>
          <NavItemInsideWrap
            mode={this.mode}
            isText={true}
            type={this.type}
            usage={this.usage}
            isActive={activeFlag}
            showCount={this.showCount}
          >
            <NavText showCount={this.showCount}>{this.formatLabel(item.label)}</NavText>
          </NavItemInsideWrap>
        </NavItem>
      )
    })
    return (
      <LeftNav
        mode={this.mode}
        type={this.type}
        usage={this.usage}
        class={{ __fixed: this.fixed }}
      >
        {navItems}
      </LeftNav>
    )
  }
}
