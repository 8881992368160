<template >
  <div class="container">
    <div class="row">
      <div class="col">
        <h3>You've successfully connected your stripe account.</h3>
        <p>This window will close automatically</p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import * as getters from '@/js/store/modules/getters'

  export default {
    computed: {
      ...mapState('route', {
        query: ({query}) => query
      }),
      ...mapGetters({
        user: getters.AUTH_GET_USER
      })
    },
    mounted () {
      if (window.opener) {
        // send a message to the parent window
        // assuming this was opened by the same origin :D
        window.opener.postMessage(JSON.stringify({
          stripe: this.query
        }), window.location.origin)
      }
    }
  }
</script>

<style lang="scss">
</style>
