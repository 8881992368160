import Vue from '../../../lib/utils/vue'
import { styled, VueEmotion } from '@egoist/vue-emotion'
import { generateUuid } from '../../molecules/util/generateUuid'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import { CvtRadioGroup, CvtRadio } from '../../molecules/radio-group/index'
import CvtSiteUrl from '../../molecules/SiteUrl'
import CvtButton from '../../molecules/Button'
import { ContentPlaceholder } from '../../molecules/ContentPlaceholder'
import VueI18n from 'vue-i18n'

Vue.use(VueEmotion)
Vue.use(VueI18n)


let Spacing, Radii, Border, Colors, textLineHeight, BorderStyle, textFontWeight, borderColor, backgroundColor, textFontSize, textColor, FontFamily;


const getContainer = () => styled('div')`
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  width: ${(3*Spacing.SPACING_96) + Spacing.SPACING_12}px;
  padding: 
    ${Spacing.SPACING_18 + Spacing.SPACING_4}px
    ${Spacing.SPACING_32 + Spacing.SPACING_4}px
    ${Spacing.SPACING_24 - Spacing.SPACING_2}px
    ${Spacing.SPACING_32 + Spacing.SPACING_4}px;
`

const getWrapper = () => styled('div')``


const getRadioButtonsContainer = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
`


const getSiteUrlContainer = () => styled('div')`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  padding-left: ${Spacing.SPACING_48 + Spacing.SPACING_2}px;
  width: ${(2*Spacing.SPACING_96) - Spacing.SPACING_4}px;
`


const getPublishButtonOneContainer = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-left: ${Spacing.SPACING_32 + Spacing.SPACING_16 + Spacing.SPACING_2}px;
  width: ${Spacing.SPACING_128 + Spacing.SPACING_32 + Spacing.SPACING_16 + Spacing.SPACING_8}px;
`


const getPublishButtonTwoContainer = () => styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  width: ${Spacing.SPACING_128 + Spacing.SPACING_32 + Spacing.SPACING_16 + Spacing.SPACING_8}px;
`


const getEditSiteUrlContainer = () => styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  padding-top: ${Spacing.SPACING_8 + Spacing.SPACING_2}px;
`

const getCvtRadioGroup = () => styled(CvtRadioGroup)``

const getCvtRadio = () => styled(CvtRadio)``

const getCvtSiteUrl = () => styled(CvtSiteUrl)`
  padding-top: 5px;
  padding-bottom: 5px;

  & div > label {
    margin-bottom: 0px;
  } 
`

const getCvtEditCustomUrlButton = () => styled(CvtButton)``

const getCvtSitePublishButton = () => styled(CvtButton)``

const getCvtConnectCustomDomainButton = () => styled(CvtButton)`
  & > span {
    padding-right: ${Spacing.SPACING_12}px;
  }
`

const getConnectCustomDomainContainer = () => styled('div')`
  padding-left: ${Spacing.SPACING_4}px;
  padding-right: 0px;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
`

const getNoDomainsConnectedLabel = () => styled('label')`
  width: ${Spacing.SPACING_128 + Spacing.SPACING_32}px;
  height: ${Spacing.SPACING_16}px;
  ${textFontWeight('BOLD')}; 
  ${textFontSize('md')};
  ${textLineHeight('xl')};
  font-family: ${FontFamily.DEFAULT};
  padding-bottom: ${Spacing.SPACING_18 + Spacing.SPACING_2}px;
  padding-top: ${Spacing.SPACING_8 + Spacing.SPACING_2}px;
`


const getPlaceholderContainer = () => styled('div')`
  box-sizing: border-box;
  position: relative;
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  width: ${(3*Spacing.SPACING_96) + Spacing.SPACING_12}px;
  height: ${Spacing.SPACING_256}px;
  padding: 
    ${Spacing.SPACING_18 + Spacing.SPACING_4}px
    ${Spacing.SPACING_32 + Spacing.SPACING_4}px
    ${Spacing.SPACING_24 - Spacing.SPACING_2}px
    ${Spacing.SPACING_32 + Spacing.SPACING_4}px;
`


const getRadioPlaceholderOne = () => styled(ContentPlaceholder)`
  margin-top: ${Spacing.SPACING_4}px;
  width: ${(2*Spacing.SPACING_96) + Spacing.SPACING_12}px;
  height: ${(2*Spacing.SPACING_18) + Spacing.SPACING_2}px;
  margin-bottom: ${Spacing.SPACING_4}px;
`


const getRadioPlaceholderTwo = () => styled(ContentPlaceholder)`
  top: 65px;
  margin-top: ${Spacing.SPACING_4}px;
  width: ${(2*Spacing.SPACING_96) + Spacing.SPACING_12}px;
  height: ${(2*Spacing.SPACING_18) + Spacing.SPACING_2}px;
  margin-bottom: ${Spacing.SPACING_4}px;
`


const getSiteUrlTitlePlacholder = () => styled(ContentPlaceholder)`
  top: ${Spacing.SPACING_96 + Spacing.SPACING_2 - Spacing.SPACING_12}px;
  left: ${Spacing.SPACING_64 - Spacing.SPACING_4}px;
  margin-top: ${(2*Spacing.SPACING_24) + Spacing.SPACING_4}px;
  width: ${Spacing.SPACING_64 + Spacing.SPACING_16}px;
  height: ${Spacing.SPACING_16 + Spacing.SPACING_4}px;
  margin-bottom: ${Spacing.SPACING_4}px;
`


const getSiteUrlPlacholder = () => styled(ContentPlaceholder)`
  top: ${Spacing.SPACING_128 + Spacing.SPACING_8}px;
  left: ${Spacing.SPACING_64 - Spacing.SPACING_4}px;
  margin-top: ${Spacing.SPACING_24 + Spacing.SPACING_4}px;
  width: ${(2*Spacing.SPACING_96) - Spacing.SPACING_12}px;
  height: ${Spacing.SPACING_18 + Spacing.SPACING_12}px;
  margin-bottom: ${Spacing.SPACING_4}px;
`


const getEditSiteUrlButtonPlacholder = () => styled(ContentPlaceholder)`
  top: ${(2*Spacing.SPACING_96) - Spacing.SPACING_8}px;
  border-radius: ${Spacing.SPACING_8}px;
  left: ${Spacing.SPACING_64 - Spacing.SPACING_4}px;
  margin-top: ${Spacing.SPACING_24 + Spacing.SPACING_4}px;
  width: ${(2*Spacing.SPACING_96) - Spacing.SPACING_12}px;
  height: ${Spacing.SPACING_32 + Spacing.SPACING_8}px;
  margin-bottom: ${Spacing.SPACING_4}px;
`


export const CvtUrlRadioButtonActions = {
  name: 'CvtUrlRadioButtonActions',
  components: {},
  data () {
    return {
      radioInputValue: ''
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
      required: false
    },
    radioGroupTheme: {
      type: String,
      required: false,
    },
    radioGroupOrientation: {
      type: String,
      required: false,
    },
    radioGroupSize: {
      type: String,
      required: false,
    },
    siteStatus: {
      type: String,
      required: true,
    },
    siteUrl: {
      type: String,
      required: true,
    },
    isPublishedUrlSet:{
      type: Boolean,
      default: false,
    },
    isCustomUrlSet:{
      type: Boolean,
      default: false,
    },
    linkedDomains: {
      type: Array,
      default: () => []
    },
    disablePublish: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      BorderStyle: ({ globalTheme }) => globalTheme.BorderStyle,
    }),
    hasCustomDomain(){      
      if( this.linkedDomains.length ){
        return true;
      } else {
        return false;
      }
    },
    radioInitialValue(){
      if( this.linkedDomains.length ){
        return 'custom_domain';
      } else {
        return 'published_url';
      }
    }
  },
  watch: {
    radioInitialValue(val) {
      if( val.length ){
        this.radioBtnClicked(val);
      }
    }
  },
  created () {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    Colors = this.Colors
    BorderStyle = this.BorderStyle
    FontFamily = this.FontFamily

    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    textColor = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
  },
  methods: {
    radioBtnClicked (val) {
      this.radioInputValue = val;
      this.$emit('radioBtnClicked', val);
    },
    editCustomUrlBtnClicked(event, step){
      this.$emit('editCustomUrlBtnClicked', event, step);
    },
    connectCustomDomainsBtnClicked(event, step){
      this.$emit('connectCustomDomainsBtnClicked', event, step);
    },
    sitePublish(event){
      this.$emit('sitePublishClicked', event);
    }
  },
  render: function (h) {

    const Container = getContainer();
    const Wrapper = getWrapper();
    const RadioButtonsContainer = getRadioButtonsContainer();
    const SiteAndEditCustomUrlContainer = getSiteUrlContainer();
    const PublishButtonOneContainer = getPublishButtonOneContainer();
    const PublishButtonTwoContainer = getPublishButtonTwoContainer();
    const EditSiteUrlContainer = getEditSiteUrlContainer();
    const ConnectCustomDomainContainer = getConnectCustomDomainContainer();

    const RadioGroup = getCvtRadioGroup();
    const Radio = getCvtRadio();
    const EditCustomUrlButton = getCvtEditCustomUrlButton();
    const SitePublishButton = getCvtSitePublishButton();
    const ConnectCustomDomainButton = getCvtConnectCustomDomainButton();
    const SiteUrl = getCvtSiteUrl();
    const NoDomainsConnectedLabel = getNoDomainsConnectedLabel();

    const linkedDomainsComponents = [];

    let displayLabelText = true;

    if( this.radioInputValue == '' ){
      this.radioBtnClicked( this.radioInitialValue );
    }

    for (let key in this.linkedDomains) {
      let domainObject = this.linkedDomains[key];
      if (key != 0) {
        displayLabelText = false;
      }

      linkedDomainsComponents.push(
        <SiteUrl
          labelText= {this.$t('right_side_navbar.label.site_url.published')}
          siteUrl={domainObject.domain}
          displayLabelText={displayLabelText}
          siteUrlUniqueId={ generateUuid() }
        >
        </SiteUrl>
      )
    }

    return (
      <Container>
        {this.siteStatus === 'unpublished' && (
            <PublishButtonOneContainer>

            {!this.disablePublish && (
              <SitePublishButton
                text={'Publish'}
                icon={'cloud-upload'}
                size={'md'}
                mode={this.mode}
                color={''}
                specialPadding={`${Spacing.SPACING_12}px ${Spacing.SPACING_12 + Spacing.SPACING_2}px`}
                prepend={true}
                onClick={(event) => {this.sitePublish(event)}}
              >
              </SitePublishButton>
            )}
            </PublishButtonOneContainer>
        )}

        {this.siteStatus === 'published' && (
            <Wrapper>
                <RadioButtonsContainer>
                <RadioGroup 
                    theme={this.radioGroupTheme}
                    orientation={this.radioGroupOrientation}
                    size={this.radioGroupSize}
                    initialValue={this.radioInitialValue}
                    key={this.radioInitialValue}
                >
                    <Radio
                    border={false} 
                    otherValue={'published_url'}
                    onRadioClick={(val) => this.radioBtnClicked(val)}
                    >
                    {this.$t('right_side_navbar.radio_button.published_url.title')}
                    </Radio>

                    {this.radioInputValue === 'published_url' && (
                      <SiteAndEditCustomUrlContainer>
                        <SiteUrl
                            labelText= {this.$t('right_side_navbar.label.site_url.published')}
                            siteUrl={this.siteUrl}
                            displayLabelText={displayLabelText}
                            siteUrlUniqueId={ generateUuid() }
                        > </SiteUrl>
                      </SiteAndEditCustomUrlContainer>
                    )}

                    <Radio
                    border={false} 
                    otherValue={'custom_domain'}
                    onRadioClick={(val) => this.radioBtnClicked(val)}
                    >
                    {this.$t('custom_domain.ui.right_side_navbar.radio_action')}
                    </Radio>
                </RadioGroup>
                </RadioButtonsContainer>

                {this.radioInputValue === 'custom_domain' && this.hasCustomDomain &&  (
                    <SiteAndEditCustomUrlContainer>

                        { linkedDomainsComponents }

                        <EditSiteUrlContainer>
                            <EditCustomUrlButton
                            text={'Edit Custom URL'}
                            mode={'LIGHT'}
                            icon={'pencil'}
                            size={'xs'}
                            specialPadding={`${Spacing.SPACING_4 + Spacing.SPACING_2}px ${Spacing.SPACING_8 + Spacing.SPACING_2}px`}
                            actionIcon={false}
                            icononly={false}
                            prepend={true}
                            outlined={true}
                            onClick={(event) => this.editCustomUrlBtnClicked(event, 2) }
                            >
                            </EditCustomUrlButton>
                        </EditSiteUrlContainer>
                    </SiteAndEditCustomUrlContainer>
                )}

                <ConnectCustomDomainContainer>
                { this.radioInputValue == 'custom_domain' && !this.hasCustomDomain && (
                <Wrapper>
                    <NoDomainsConnectedLabel>
                    {this.$t('custom_domain.ui.right_side_navbar.no_domains_connected')}
                    </NoDomainsConnectedLabel>
                    <ConnectCustomDomainButton
                    text={this.$t('custom_domain.ui.right_side_navbar.connect_custom_domain')}
                    mode={'LIGHT'}
                    size={'md'}
                    fullWidth={true}
                    actionIcon={false}
                    icononly={false}
                    prepend={true}
                    specialPadding={`${Spacing.SPACING_8 + Spacing.SPACING_4}px ${Spacing.SPACING_8 + Spacing.SPACING_4 + Spacing.SPACING_2}px`}
                    onClick={(event) => this.connectCustomDomainsBtnClicked(event, 2) }
                    >
                    </ConnectCustomDomainButton>
                </Wrapper>
                )}
                </ConnectCustomDomainContainer>
            </Wrapper>
        )}
      </Container>
    )
  }
}


export const PlaceholderUrlRadioButtonActions = {
  name: 'PlaceholderUrlRadioButtonActions',
  components: {},
  data () {
    return { }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
      required: false
    },
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
    }),

    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      BorderStyle: ({ globalTheme }) => globalTheme.BorderStyle,
    })
  },
  created () {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    Colors = this.Colors
    BorderStyle = this.BorderStyle
    FontFamily = this.FontFamily

    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    textColor = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
  },
  methods: { },
  render: function (h) {

    const Container = getPlaceholderContainer();
    const RadioPlaceholderOne = getRadioPlaceholderOne();
    const RadioPlaceholderTwo = getRadioPlaceholderTwo();
    const SiteUrlTitlePlacholder = getSiteUrlTitlePlacholder();
    const SiteUrlPlacholder = getSiteUrlPlacholder();
    const EditSiteUrlButtonPlacholder = getEditSiteUrlButtonPlacholder();

    return (
      <Container>
        <RadioPlaceholderOne mode={this.mode}></RadioPlaceholderOne>
        <RadioPlaceholderTwo mode={this.mode}></RadioPlaceholderTwo>
        <SiteUrlTitlePlacholder mode={this.mode}></SiteUrlTitlePlacholder>
        <SiteUrlPlacholder mode={this.mode}></SiteUrlPlacholder>
        <EditSiteUrlButtonPlacholder mode={this.mode}></EditSiteUrlButtonPlacholder>
      </Container>
    )
  }
}