
<section
  :id="anchorId"
  class="block"
  :class="classes"
  :data-vnode-id="vnodeId"
  @click="select"
  @mouseover="target"
  @contextmenu.stop.prevent="showContextMenu"
  @dblclick.stop.prevent="triggerOpenAssetManager"
>
  <div
    v-show="showTextureBackground && isSectionUIEnabled"
    class="dashed-texture"
  />

  <div
    v-show="showControlls && isSectionUIEnabled"
    class="block__target__indicator block__target__indicator--top"
  ></div>
  <div
    v-show="showControlls"
    class="block__target__indicator block__target__indicator--right"
  ></div>
  <div
    v-show="showControlls && isSectionUIEnabled"
    class="block__target__indicator block__target__indicator--bottom"
  ></div>
  <div
    v-show="showControlls"
    class="block__target__indicator block__target__indicator--left"
  ></div>

  <div :class="stylesheet.classes.bgColor"></div>
  <div :class="stylesheet.classes.bgImage"></div>
  <div :class="stylesheet.classes.overlayColor"></div>

  <cvt-section-drag-items
    v-if="vnodeId && isGridBuilder && !isSectionUIEnabled"
    class="drag-items-block"
    :usage="'block'"
    :show-plus-btn="true"
    :vnode-id="vnodeId"
    :widget-class="'grid-stack-item'"
  />

  <div v-show="isSectionUIEnabled" class="section-controls-wrap">
    <section-controls
      v-show="showControlls"
      :class="sectionControlsClasses"
      :vnode-id="vnodeId"
      @moveSectionUp="moveSectionUp"
      @moveSectionDown="moveSectionDown"
      @copySection="copySection"
      @swapSection="swapSection"
      @deleteSection="deleteSection"
    />
  </div>

  <div v-show="!isEmpty" :class="containerClasses">
    <slot></slot>
  </div>

  <div v-show="isEmpty && !isGridBuilder">
    <h5 class="text-capitalize text-center">
      {{ $t('editor.section.empty.message') }}
    </h5>
    <p class="text-muted text-center">
      {{ $t('editor.section.row.add.message') }}
    </p>
  </div>

  <cvt-popover
    :ref="popoverRef"
    :mode="mode"
    placement="top"
    :target="`${anchorId}-add-core-element`"
    triggers="click"
  >
    <template #popper>
      <div class="d-flex">
        <cvt-button
          v-if="!isGridBuilder"
          class="text-capitalize"
          :text="$t('add_row.cta')"
          @click.prevent.stop="addRow"
        />
        <cvt-button
          color="success"
          class="text-capitalize ml-1"
          :text="$t('add_section.cta')"
          @click.stop.prevent="openQuickLaunch"
        />
      </div>
    </template>
  </cvt-popover>

  <div v-if="!isGridBuilder">
    <div
      v-if="addSectionsOnly"
      v-show="showAddBlockBtn"
      :id="`${anchorId}-add-core-element-sec`"
      class="add-structure-alt add-structure--bottom"
      @click.stop.prevent="openQuickLaunch"
    >
      {{ $t('add_section.cta') }}
    </div>
    <div
      v-else
      v-show="showAddBlockBtn"
      :id="`${anchorId}-add-core-element`"
      class="add-structure add-structure--bottom"
      @click.stop.prevent
    >
      {{ $t('editor.section.add.option.message') }}
    </div>
  </div>
</section>
