
<div identity="c-card-options-wrap">

  <cvt-collapse
    :accordion="true"
    :theme="mode === 'LIGHT' ? 'light' : 'dark'"
    :prepend-chevron="prependChevron"
  >
    <!-- Implement Manage Fields options -->
    <cvt-collapse-item
      name="build"
      :title="$t(`element.property.form.manage_fields`)"
      :initial-active="true"
      :special-style="isSpecialStyle"
    >
      <div class="row">
        <div class="col-12">
          <div v-if="loading" class="placeholder-container">
            <cvt-content-placeholder class="placeholder" />
          </div>
          <cvt-select
            v-else
            :value="embeddedScript.name"
            mode="dropdown"
            :background-mode="mode"
            :floating-label="false"
            label="Select Form"
            @input="(value) => embeddedScript = value"
          >
            <option
              v-for="field in fields"
              :key="field.id"
              :value="field.name"
              v-text="field.name"
            />
          </cvt-select>
        </div>
      </div>
      <div class="divider"></div>
      <div class="row align-items-baseline">
        <div class="col-12 pb-2">
          Style Class Name
        </div>
        <div class="col-12">
          <cvt-input
            v-model="className"
            type="text"
            :clearable="false"
          />
        </div>
      </div>

    </cvt-collapse-item>
  </cvt-collapse>
</div>
