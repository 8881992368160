import {PageEngineCommands} from "./PageEngineCommands";
import {VDomCommands} from "./VDomCommand";
import {VueGridStackComponent} from "@/src/builder/types";

export class SectionRearrangeCommand extends PageEngineCommands {
    private readonly _oldIdx: number;
    private readonly _steps: number;

    constructor(idx: number, steps: number) {
        super();
        this._oldIdx = idx
        this._steps = steps
    }

    get$emitData() {
        return  {name: 'SectionRearrangeCommand', forceBuild: false}
    }

    get hasChanges () {
        return true
    }

    async execute() {
        const currentIdx = this._oldIdx + this._steps
        await this.firebaseRef?.moveChild(this._oldIdx, this._steps).then(() => {
            this.$nextTick!(() => {
                let node = this.$children[currentIdx]
                node.$el.scrollIntoView({
                    behavior: 'smooth',
                })
            })
        })
    }

    async unExecute() {
        const currentIdx = this._oldIdx + this._steps
        const steps = -this._steps
        await this.firebaseRef?.moveChild(currentIdx, steps).then(() => {
            this.$nextTick!(() => {
                let node = this.$children[this._oldIdx]
                node.$el.scrollIntoView({
                    behavior: 'smooth',
                })
            })
        })
    }
}

export class BlockElementOrderCommand extends VDomCommands {
    private readonly _oldIdx: number;
    private readonly _newIdx: number;
    private readonly _steps: number;

    constructor(gridContainerVNodeId: string, newIdx: number, oldIdx: number) {
        super(gridContainerVNodeId);
        this._oldIdx = oldIdx
        this._newIdx = newIdx

        this._steps = newIdx
        if (oldIdx > newIdx) {
            this._steps = newIdx - oldIdx
        }
        else if (newIdx > oldIdx) {
            this._steps = oldIdx - newIdx
        }
    }

    getVueComponent(): VueGridStackComponent {
        super.getVueComponent();
        const vNodeIdGridComponent = (this._vNodeIdVueComponent_ as VueGridStackComponent)
        if (!vNodeIdGridComponent?.getGrid) {
            // Element must be a gridstack element [GridContainer or SubGridContainer]
            throw 'Invalid Element Found'
        }
        return vNodeIdGridComponent
    }

    get firebaseRef() {
        return this.getVueComponent().firebaseRef()
    }

    get$emitData() {
        return  {name: 'BlockElementOrderCommand', forceBuild: false}
    }

    get hasChanges () {
        return this._oldIdx !== this._newIdx
    }

    async execute() {
        await this.firebaseRef?.moveChild(this._oldIdx, this._steps)
    }

    async unExecute() {
        const currentIdx = this._oldIdx + this._steps
        const steps = -this._steps
        await this.firebaseRef?.moveChild(currentIdx, steps)
    }
}