
<div
  :id="vnodeId"
  class="cvt-editor-brand-logo"
  :class="classes"
  :style="mainWrapStyles"
  :data-vnode-id="vnodeId"
  :draggable="!isGridBuilder ? 'true' : 'false'"
  @click="select"
  @mouseover="target"
  @contextmenu.prevent.stop="showContextMenu"
  @_dblclick.stop.prevent="triggerOpenAssetManager"
  @dragstart="dragstart"
  @dragend="dragend"
>
  <drag-and-drop-zone :z-index="dropzoneZIndex" @dropped="addElementToSide" />

  <div v-if="!isGridBuilder" :class="imgWrapperClassesOB">
    <a v-if="href" :href="href">
      <img
        class="img-fluid-v2"
        :style="imgStylesOB"
        :src="altSrc"
        :alt="alt"
      />
    </a>
    <img
      v-else
      class="img-fluid-v2"
      :style="imgStylesOB"
      :src="altSrc"
      :alt="alt"
    />
  </div>

  <div v-else-if="isGridBuilder" :class="imgWrapperClassesGB" :style="imgWrapperStyles">
    <a
      v-if="href" 
      :href="href"
      class="img-fluid-v2"
      :style="imgStylesGB"
      :src="altSrc"
      :alt="alt"
    >
    </a>

    <div
      v-else
      class="img-fluid-v2"
      :style="imgStylesGB"
      :src="altSrc"
      :alt="alt"
    />
  </div>

  <template v-if="showActionButtonsOnClick && !isGridBuilder">
    <!--THis is only here because of freshwork in v1 gridbuilder,
    once they migrate to v2 or v3, it will become irrelevant-->
    <action-buttons
      v-if="selected"
      @duplicate="duplicateAndValidate"
      @remove="removeAndValidate"
    />
  </template>
</div>
