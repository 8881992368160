import { cvtRouteNodes } from "@/js/utils/routes"

export default {
  components: {},
  props: {},
  data() {
    return {}
  },
  created() {},
  methods: {
    authenticateCustomers(): void {
      this.$router.push({
        name: cvtRouteNodes.enterpriseIntegrateBuilderAuthCustomer
      });
    },
  },
}
