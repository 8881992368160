<template>
  <transition name="fade">
    <div v-show="visible" class="fullscreen-gallery">
      <div
        class="
          fullscreen-gallery__close
          d-flex
          justify-content-center
          align-items-center
        "
      >
        <i class="cvt-icon-delete" @click.prevent.stop="close"></i>
      </div>

      <div class="row text-center auto pt-5 bg-overlay">
        <div class="col">
          <h2>{{ $t('imagesearch.highres') }}</h2>
          <p>
            <i>{{ $t('imagesearch.cta.text') }}</i>
          </p>
        </div>
      </div>

      <div
        class="
          d-flex
          p-3
          justify-content-center
          align-items-center
          flex-wrap
          bg-overlay
        "
      >
        <div class="image-search-input">
          <el-input
            v-model="query"
            placeholder="Search the gallery"
            icon="search"
            size="large"
            autofocus
            :on-icon-click="runSearch"
            @keyup.enter.native="runSearch"
            @change="runSearch"
          >
          </el-input>
        </div>
      </div>

      <div
        class="
          d-flex
          p3
          justify-content-center
          align-items-center
          flex-wrap
          bg-overlay
        "
      >
        <h6
          v-for="(tag) in tags"
          :key="tag"
          class="badge-wrapper mr-2"
          @click.prevent.stop="activate(tag)"
        >
          <span
            class="badge badge-pill"
            :class="active(tag)"
            v-text="tag"
          ></span>
        </h6>
      </div>

      <transition-group
        name="fade"
        class="mx-5 mt-3 fullscreen-gallery__list"
        tag="div"
      >
        <image-search-item
          v-for="(image) in results"
          :key="image.id"
          :image="image"
          @selected="useImage"
        ></image-search-item>
      </transition-group>
    </div>
  </transition>
</template>

<script>
import * as _ from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import * as actions from '../store/modules/actions';
import * as getters from '../store/modules/getters';
import * as mutations from '../store/modules/mutations';
import ImageSearchItem from './ImageSearchItem.vue';
// import * as events from '../plugins/events'

const tags = [
  'business',
  'coaching',
  'consulting',
  'fitness',
  'marketing',
  'office',
  'services',
  'tech',
].map((t) => t.toUpperCase());

export default {
  components: {
    ImageSearchItem,
  },
  data() {
    return {
      tags,
      activeTag: tags[0],
      query: null,
    };
  },
  computed: {
    ...mapGetters({
      results: getters.IMAGE_SEARCH_RESULTS,
      visible: getters.IMAGE_SEARCH_VISIBLE,
    }),
  },
  mounted() {
    this.search({
      search: this.activeTag,
    });
  },
  methods: {
    ...mapMutations({
      close: mutations.IMAGE_SEARCH_CLOSE,
      useImage: mutations.IMAGE_SEARCH_SELECTED,
    }),
    ...mapActions({
      search: actions.IMAGE_SEARCH_QUERY,
    }),
    active(tag) {
      return {
        'badge--active': tag === this.activeTag,
      };
    },
    activate(tag) {
      this.activeTag = tag;
      this.search({
        search: this.activeTag,
      });
    },
    runSearch: _.debounce(
      function () {
        this.activeTag = null;

        if (!this.query) {
          this.activeTag = this.tags[0];
        }

        this.search({
          search: this.query || this.activeTag,
        });
      },
      500,
      {
        trailing: true,
      }
    ),
  }
};
</script>

<style lang="scss"></style>
