<template>
  <cvt-collapse :accordion="true" :theme="mode === 'LIGHT' ? 'light' : 'dark'" :prepend-chevron="prependChevron">
    <cvt-collapse-item
      title="Style"
      name="style"
      :initial-active="true"
    >
      <div class="row">
        <div class="col mb-3 d-flex align-items-center text-capitalize">
          {{ $t("element.property.label.resize") }}
        </div>
        <viewport />
        <div class="col-12">
          <resize
            :size-key="sizeKey"
            :min="0"
            :step="1"
            :max="12"
            hide-label
          />
        </div>
      </div>

      <div class="divider mt-2">
        <div class="nestedInnerItem">
            <cvt-select 
                :value="borderStyle"
                mode="dropdown"
                :background-mode="mode"
                :floating-label="false"
                :label='$t("elements.properties.border.style")'
                @input = "(value) => selectAction(value, 'borderStyle')"
            >
                <option v-for="style in borderStyles" :key="style" :value="style" v-text="style">
                </option>
            </cvt-select>
            <div style="margin: 5px;"></div>
            <cvt-input
              v-model="borderColor"
              style="width: max-content;"
              type="text"
              :clearable="false"
              :label='$t("elements.properties.border.color")'
            />
        </div>
      </div>

      <div class="divider mt-2" />

      <div class="row">
        <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
          {{ $t("elements.properties.border.width") }}
        </div>
        <div class="col-12">
          <cvt-slider
            id="slider-default"
            :tooltips-enabled="true"
            :step-marks-enabled="true"
            class="slider"
            :min="1"
            :max="10"
            :value="borderWidth"
            :step="1"
            @change="onChange"
          />
        </div>
      </div>
      <div class="divider mt-2" />

      <div class="row">
        <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
          {{ $t("elements.properties.border.radius") }}
        </div>
        <div class="col-12">
          <resize
            size-key="borderRadius"
            :min="0"
            :step="5"
            :max="100"
            :label="$t('elements.properties.border.radius')"
            hide-label
          />
        </div>
      </div>
    </cvt-collapse-item>

    <cvt-collapse-item
      :title="$t('elements.properties.background.image')"
      name="bgImage"
    >
      <element-image-manager
        image-key="background/image"
        :hook="selected.updateBgSrc"
      />
    </cvt-collapse-item>

    <cvt-collapse-item
      :title="$t('elements.properties.background.color')"
      name="color"
    >
      <colors alpha :mode="mode"/>
    </cvt-collapse-item>

    <cvt-collapse-item
      :title="$t('elements.properties.spacing.label')"
      name="spacing"
    >
      <spacing-ctrl />
    </cvt-collapse-item>

    <cvt-collapse-item
      :title="$t('elements.properties.visibility.label')"
      name="visibility"
    >
      <visibility-ctrl :mode="mode"/>
    </cvt-collapse-item>

    <cvt-collapse-item
      :title="$t('elements.properties.alignment')"
      name="alignment"
    >
      <flex-align :mode="mode"/>
    </cvt-collapse-item>
  </cvt-collapse>
</template>

<script>
import * as _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import Colors from '../theme/Colors.vue'
import ElementImageManager from './ElementImageManager.vue'
import FlexAlign from './FlexAlign.vue'
import Resize from './Resize.vue'
import SpacingCtrl from './SpacingCtrl.vue'
import Viewport from './Viewport.vue'
import VisibilityCtrl from './VisibilityCtrl.vue'

export default {
  components: {
    Resize,
    Viewport,
    SpacingCtrl,
    FlexAlign,
    Colors,
    VisibilityCtrl,
    ElementImageManager
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
    prependChevron: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeTabs: [
        'style'
        // 'bgImage',
        // 'color',
        // 'padding'
      ],
      borderStyles: [
        'none',
        'solid',
        // 'ridge',
        // 'inset',
        // 'outset',
        'dashed',
        'dotted',
        'double',
        'groove'
      ],
      borderWidth: 1
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      viewmode: getters.VIEW_MODE
    }),
    sizeKey () {
      return this.viewmode === 'desktop' ? 'size' : 'sizePhone'
    },
    borderStyle: {
      get () {
        return this.selected.borderStyle || 'none'
      },
      set: _.throttle(function (value) {
        return this.updateProp({ key: 'borderStyle', value })
      }, 300)
    },

    borderColor: {
      get () {
        return this.selected.borderColor
      },
      set: _.throttle(function ({ target }) {
        return this.updateProp({ key: 'borderColor', value: target.value })
      }, 300)
    }
  },
  created () {
    this.borderWidth = this.selected.borderWidth || 1
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP
    }),
    onChange: _.throttle(function (value) {
      this.borderWidth = value
      return this.updateProp({ key: 'borderWidth', value })
    }, 300),
    selectAction (value, computedProperty) {
      if(computedProperty === 'borderStyle')
        this.borderStyle = value
    }
  }
}
</script>

<style lang="scss">
.nestedInnerItem {
  display: flex;
}

.nestedInnerItem div[identity="FormGroup"] > label {
  line-height: inherit;
}
</style>
