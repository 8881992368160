import Vue from '../../lib/utils/vue'
import { styled, VueEmotion } from '@egoist/vue-emotion'


Vue.use(VueEmotion)


const getImageThumbnailContainer = () => styled('div')`
  ${(props) => `width: ${props.width}px;`}
  ${(props) => `height: ${props.height}px;`}
`

const getImageThumbnail = () => styled('img')`
  ${(props) => `width: ${props.width}px;`}
  ${(props) => `height: ${props.height}px;`}
`


export default {
  name: 'ImageThumbnail',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
      default: 'Image Not Found'
    },
    width: {
      type: Number,
      required: false,
      default: 140
    },
    height: {
      type: Number,
      required: false,
      default: 116
    },
  },
  
  data() { return {} },

  render: function (h) {

    const defaultPlaceholderImgUrl = `https://via.placeholder.com/${this.width}x${this.height}/ffffff/000000/?text=%20Image`; // placeholder image from web

    const ImageThumbnailContainer = getImageThumbnailContainer()
    const ImageThumbnail = getImageThumbnail()

    return (

      <ImageThumbnailContainer
        width={this.width}
        height={this.height}
      >

        <ImageThumbnail
          src={ (this.src && this.src.trim() !== '') ? this.src : defaultPlaceholderImgUrl}
          alt={this.alt}
          width={this.width}
          height={this.height}
        >
        </ImageThumbnail>


      </ImageThumbnailContainer>
    )
  }
}
