import deutsch from '../../assets/locales/de.json'
import english from '../../assets/locales/en.json'
import espanol from '../../assets/locales/es.json'
import french from '../../assets/locales/fr.json'
import italiano from '../../assets/locales/it.json'
import koreon from '../../assets/locales/ko.json'
import polish from '../../assets/locales/pl.json'
import brazillian from '../../assets/locales/pt-BR.json'
import portugues from '../../assets/locales/pt.json'
import chinese from '../../assets/locales/zh-Hans-CN.json'

export default {
  'en-US': english,
  'fr-FR': french,
  'es-ES': espanol,
  'it-IT': italiano,
  'pt-PT': portugues,
  'de-DE': deutsch,
  'pl-PL': polish,
  'nl-NL': deutsch,
  en: english,
  fr: french,
  es: espanol,
  it: italiano,
  pt: portugues,
  de: deutsch,
  'en-ko': koreon,
  ko: koreon,
  'en-nl': deutsch,
  'es-MX': espanol,
  'en-pl': polish,
  'pt-BR': brazillian,
  'zh-CN': chinese,
}
