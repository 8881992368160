<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as getters from '@/js/store/modules/getters'
import * as _ from 'lodash'
import * as actions from '@/js/store/modules/actions'
import QuillMentionInput from '@/js/components/common/QuillMentionInput.vue'

export default {
  name: 'GenAIOptions',
  components: {
    QuillMentionInput,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      user: getters.AUTH_GET_USER,
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN,
    }),
    genAI() {
      return this.selected.dc || {}
    },
    isSectionSelected() {
      return this.selected.name === 'Block'
    },
    description: {
      get() {
        return this.genAI.description || ''
      },
      set: _.debounce(function ({ target }) {
        return this.updateProp({
          key: 'dc',
          value: { ...this.genAI, description: target.value },
        })
      }, 1000),
    },
    maxLength: {
      get() {
        return this.genAI.maxLength || 0
      },
      set: _.debounce(function ({ target }) {
        return this.updateProp({
          key: 'dc',
          value: { ...this.genAI, maxLength: target.value },
        })
      }, 1000),
    },
    promptJSON: {
      get() {
        return this.genAI.promptJSON || ''
      },
    },
  },
  mounted() {
    console.log('genAI', this.genAI)
  },
  methods: {
    ...mapMutations('genAI', {
      openGenAIDialog: 'openGenAIDialog',
    }),
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
    }),
    saveText(formattedDelta, formattedText) {
      return this.updateProp({
        key: 'dc',
        value: {
          ...this.genAI,
          promptJSON: formattedDelta,
          prompt: formattedText,
        },
      })
    },
  },
}
</script>

<template>
  <div>
    <cvt-button
      :outlined="true"
      :full-width="true"
      shape="rounded"
      color="light"
      :mode="mode"
      :text="`${genAI.label || 'Assign A Variable'}`"
      @click="openGenAIDialog"
    />
    <div v-if="genAI.label" class="row no-gutters">
      <div class="col-md-12">
        <cvt-input
          v-model="description"
          type="text"
          label="Description"
          :clearable="false"
        />
      </div>

      <div v-if="!isSectionSelected" class="col-md-12 mt-3">
        <cvt-input
          v-model="maxLength"
          type="number"
          label="Max Length"
          :clearable="false"
        />
      </div>

      <div v-if="!isSectionSelected" class="col-md-12 mt-3">
        <div class="prompt-label">Prompt</div>
        <quill-mention-input
          :enable-mention="isAdmin"
          :value="promptJSON"
          @save-text="saveText"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.prompt-label {
  color: #8f9bb3;
}
</style>
