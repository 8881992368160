
<div :class="stylesheet.classes.presentation">
  <div :class="stylesheet.classes.presentationBody">
    <div v-if="template.enableIcon" class="card-icon">
      <div v-if="useIconAsImage">
        <div
          class="img-fluid"
          :style="imgStylesGB"
        />
      </div>
      <div v-else :class="iconWrapperClasses">
        <icon-template
          :set="iconProperties.set"
          :selector="iconProperties.selector"
          :class="iconClasses"
        />
      </div>
    </div>
    <div :class="stylesheet.classes.presentationContent">
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="template.enableHeadline" class="card-headline" v-html="titleHTML">

      </div>
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="template.enableText" class="card-text" v-html="descriptionHTML">
      </div>

      <div  v-if="template.enableButton">
        <c-button v-bind="buttonProps" :fb-node="buttonData"/>
      </div>
    </div>
  </div>
</div>
