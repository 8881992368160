export default {
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    thumbnailsEnabled: {
      type: Boolean,
      default: false
    },
    fallbackThumbnail: {
      type: [String, Blob],
      default: null
    },
    thumbnailProp: {
      type: String,
      default: 'thumbnail'
    },
    iconsEnabled: {
      type: Boolean,
      default: false
    },
    fallbackIcon: {
      type: String,
      default: 'el-icon-document'
    },
    iconProp: {
      type: String,
      default: 'icon'
    },
    removeEnabled: {
      type: Boolean,
      default: false
    },
    descriptionProp: {
      type: String,
      default: 'name'
    },
    titleProp: {
      type: String,
      default: 'name'
    },
    endIcon: {
      type: String,
      default: ''
    },
    showSeparator: {
      type: Boolean,
      default: false
    },
    isCard: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _thumbnailProp() {
      return this.thumbnailProp
    },
    _iconProp() {
      return this.iconProp
    },
    _descriptionProp() {
      return this.descriptionProp
    },
    _titleProp() {
      return this.titleProp
    }
  }
}
