import cssx from 'cssx'
export const typography = cssx('cssx-typography-styles')
export const themeColors = cssx('cssx-theme-colors')
export const froalaLinkDefaultTypography = cssx(
  'cssx-froala-link-default-typography',
)
export const mobileViewTypography = cssx('cssx-mobile-view-typography-styles')

cssx.minify(false)
typography.scope('.typography')
mobileViewTypography.scope('.page-engine-viewmode--sm')

// Disable blockquote left side base
typography.add({ '.fr-view blockquote': { 'border-left': 'none !important' } })
