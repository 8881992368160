import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'

export default {
  state: {
    open: false,
    target: null,
    position: {

    }
  },
  getters: {
    [getters.RIGHT_CLICK_MENU_OPEN] ({open}) {
      return open
    },
    [getters.RIGHT_CLICK_MENU_POSITION] ({position}) {
      return position
    },
    [getters.RIGHT_CLICK_MENU_TARGET] ({target}) {
      return target
    }
  },
  mutations: {
    [mutations.RIGHT_CLICK_MENU_TOGGLE] (state, open) {
      state.open = open
    },
    [mutations.RIGHT_CLICK_MENU_UPDATE_TARGET] (state, target) {
      state.target = target
    },
    [mutations.RIGHT_CLICK_MENU_UPDATE_POSITION] (state, {clientX, clientY}) {
      state.position = {
        top: `${clientY}px`,
        left: `${clientX}px`
      }
    }
  },
  actions: {
    [actions.RIGHT_CLICK_MENU_SHOW] ({commit}, {event, target}) {
      commit(mutations.RIGHT_CLICK_MENU_TOGGLE, true)
      commit(mutations.RIGHT_CLICK_MENU_UPDATE_TARGET, target)
      commit(mutations.RIGHT_CLICK_MENU_UPDATE_POSITION, event)
    },
    [actions.RIGHT_CLICK_MENU_RESET] ({commit}) {
      commit(mutations.RIGHT_CLICK_MENU_TOGGLE, false)
      commit(mutations.RIGHT_CLICK_MENU_UPDATE_TARGET, null)
      commit(mutations.RIGHT_CLICK_MENU_UPDATE_POSITION, {})
    }
  }
}
