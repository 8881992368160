import * as mutations from '../modules/mutations'
import * as actions from '../modules/actions'
import { database } from '@/js/store/services/firebase.service'
import { DELTA_MANUAL_CHANGES_PUSH } from '../modules/actions'

const db = database()

export class PageSwapControl {
  constructor(dispatch, commit) {
    this.dispatch = dispatch
    this.commit = commit
    this.deltaRef = null
    this._swap_process_completed = false
    this.disableGridHistoryCapture()
  }

  disableGridHistoryCapture() {
    this.commit(mutations.HISTORY_IGNORE)
  }

  unsubscribe() {
    if (this._swap_process_completed === false) {
      // if at this point process_complete is not complete, then something went wrong
      // so we need to restore disabled actions
      this._swap_process_completed = true
      this.commit(mutations.HISTORY_LISTEN)
    }
  }

  subscribe(deltaKey) {
    this.deltaRef = db.ref(deltaKey)
    this.deltaRef.once('value', (snapshot) => {
      // allow DeltaRef Changes to take place
      // then push all changes a single change to history
      setTimeout(() => {
        this.commit(mutations.HISTORY_LISTEN)
        this.dispatch(actions.DELTA_MANUAL_CHANGES_PUSH)
        this._swap_process_completed = true
      }, 1200)
    })
  }
}
