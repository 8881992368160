<template>
  <div class="alt-buttons-v3" :class="{'regenerate-btn-styles': showRegenerate}">
    <cvt-button
      v-if="showDuplicate"
      class="ab-duplicate-btn"
      color="light"
      size="md"
      :outlined="false"
      :icon="(iconConfig.propertiesSidebar.copy || 'copy')"
      @click="$emit('duplicate')"
    />

    <cvt-button
      v-if="showRegenerate"
      class="ab-regenerate-btn"
      color="light"
      size="md"
      :outlined="false"
      :icon="'refresh'"
      @click="() => {
        $emit('regenerate')
      }"
    />

    <cvt-button
      v-if="showDelete"
      class="ab-delete-btn"
      color="light"
      size="md"
      :outlined="false"
      :icon="(iconConfig.propertiesSidebar.remove || 'trash')"
      @click="$emit('remove')"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    showDuplicate: {
      type: Boolean,
      default: () => true
    },
    showDelete: {
      type: Boolean,
      default: () => true
    },
    showRegenerate: {
      type: Boolean,
      default: () => false
    },
    gsw: {
      type: Number,
      default: () => {}
    },
    gsh: {
      type: Number,
      default: () => {}
    },
    containerId:  {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('globalTheme', {
      iconConfig: ({ iconConfig }) => iconConfig,
    }),
  }
}
</script>

<style lang="scss">
.alt-buttons-v3 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 44px;
  background: #FFFFFF;

  right: 24px;
  bottom: -56px;
  z-index: 20;

  left: calc(50% - 44px);
  right: calc(50% - 44px);

  border: 1px solid #E2E6ED;
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);

  .ab-duplicate-btn,
  .ab-delete-btn,
  .ab-regenerate-btn {
    height: 100%;
    width: 100%;

    display: flex;
    align-content: center;
    align-items: center;

    i {
      font-size: 18px;
    }
  }
  
  .ab-duplicate-btn {
    border-radius: 8px 0px 0px 8px;
  }
  .ab-delete-btn {
    border-radius: 0px 8px 8px 0px;
  }
  .ab-regenerate-btn {
    border-radius: 0;
  }
}

.regenerate-btn-styles {
  width: 134px;
  left: calc(50% - 67px);
  right: calc(50% - 67px);
}
</style>
