<template>
  <div class="font-family-list" :style="styleHeight">
    <div
v-for="font in availableFonts"
         :key="font"
         class="font-family-list__item"
         :class="fontIsSelected(font)"
         :style="fontItemStyle(font)"
         @click.prevent.stop="selectFontFamily(font)"
         v-text="font.familyName"></div>
  </div>
</template>

<script>
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    maxHeight: {
      type: String,
      default: '450px'
    }
  },
  data () {
    return {
      selectedFontType: 'h1'
    }
  },
  computed: {
    ...mapGetters({
      availableFonts: getters.THEME_AVAILABLE_FONTS,
      typography: getters.THEME_TYPOGRAPHY
    }),
    selectedFont () {
      return this.typography[this.selectedFontType] || {font: {}}
    },
    styleHeight () {
      return {
        maxHeight: this.maxHeight
      }
    }
  },
  methods: {
    ...mapActions({
      setFont: actions.THEME_SET_FONT
    }),
    fontIsSelected (font) {
      return {
        'font-family-list__item--active': font.familyName === this.selectedFont.font.familyName
      }
    },
    fontItemStyle (font) {
      return {
        fontFamily: `"${font.familyName}", ${font.genericFamily}`
      }
    },
    selectFontFamily (font) {
      this.$emit('change', font)
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/utils";

  .font-family-list {
    overflow-y: scroll;
  }

  .font-family-list__item {
    font-size: 2rem;
    cursor: pointer;

    &:hover {
      background-color: $light-gray;
      color: $blue;
    }

    &:active, &--active {
      background-color: $dark-gray;
    }
  }
</style>
