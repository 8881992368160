import validator from 'validator'
import { Organization } from '../../../store/services/api'
import { mapGetters } from 'vuex'
import * as getters from '../../../store/modules/getters'
import OrgFooter from '../layouts/Footer/Footer.vue'
import OrgHeader from '../layouts/Header/Header.vue'
import { getFullUrlPath } from '@/js/utils/helpers'
import { CookieHelper } from '../../../store/services/cookieHelper'
import { routePaths, cvtRoutes, cvtRouteNodes } from '../../../utils/routes'

const organizationAPI = new Organization()

type RadioValue = 'agency' | 'enterprise' | 'smb'

interface IComputed {
  admin: {}
  signinUserEmail: ''
  config: {}
}

export default {
  components: {
    OrgHeader,
    OrgFooter,
  },
  props: {},
  data() {
    return {
      loading: false as boolean,
      currentRadioValue: 'agency' as RadioValue,
      form: {
        companyName: '',
        companyWebsite: '',
        industry: '',
        enabled: [],
      },
      formErrors: {
        isformValid: false,
        msg: {
          companyWebsite: '',
          companyName: '',
          industry: '',
        },
      },
    }
  },
  beforeMount() {
    var currentStage = this.config.CURRENT_STAGE

    if (currentStage === 'onboarded') {
      this.$router.push({
        path: routePaths.chooseATemplate,
      })
    } else if (currentStage === 'integrate_builder') {
      this.$router.push({
        name: cvtRouteNodes.enterpriseIntegrateBuilder,
      })
    } else if (currentStage === 'enterprise_dashboard') {
      this.$router.push({
        name: cvtRoutes.enterpriseDashboardRedirect.name,
      })
    }
  },
  computed: {
    ...mapGetters({
      admin: getters.AUTH_GET_USER,
      signinUserEmail: getters.SIGNIN_USER_EMAIL,
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    getCookie() {
      return CookieHelper('_pages_session=')
    },
  },
  async created() {
    window.scroll(0, 0)
    try {
      const user_email = this.getCookie?.['email']
      if (user_email) {
        const [email_username, domain] = user_email.split('@')

        const { data } = await organizationAPI.fetchBrand(domain)
        const url = data.domain
        const companyName = data.name

        this.form.companyName = companyName
        this.form.companyWebsite = this.formatUrl(url)

        await organizationAPI.fetchDemoTemplates()
      }
    } catch (err) {
      // no op
      this.$message.error('Could not fetch organization.')
    }
  },
  methods: {
    onSubmit: async function (event) {
      let isFormValid = this.validateForm()
      if (!isFormValid) return this.scrollToTop(10)
      this.loading = true
      try {
        const enabledInSandbox = this.form.enabled.reduce(
          (o, key) => ({ ...o, [key]: true }),
          {},
        )

        const payload = {
          name: this.form.companyName,
          onboardSurveyAnswers: {
            website: this.form.companyWebsite,
            industry: this.form.industry,
            category: this.currentRadioValue,
            ...enabledInSandbox,
          },
        }

        await organizationAPI.enterpriseOnboarding(payload)

        const fullPath = getFullUrlPath()
        const { email, orgID, projectID, userID, organization } = this.admin

        window.FM.associateVisitor(email)
        window.FM.trackCustomEvent('admin onboarding started', {
          email,
          userID,
          orgID,
          projectID,
          companyName: organization.name,
          companyWebsite: organization.onboardSurveyAnswers.website,
          industry: organization.onboardSurveyAnswers.industry,
          category: organization.onboardSurveyAnswers.category,
          checkoutEnabled: organization.onboardSurveyAnswers.checkoutEnabled,
          multipleAccEnabled:
            organization.onboardSurveyAnswers.multipleAccEnabled,
          popupsEnabled: organization.onboardSurveyAnswers.popupsEnabled,
          url: fullPath,
        })

        const urlObj = new URL(`${this.form.companyWebsite}`)
        const redirectUrl =
          this.currentRadioValue === 'smb'
            ? this.currentRadioValue
            : `brand-config/?site=${urlObj.hostname}`

        this.$router.push({ path: `/onboarding/${redirectUrl}` })

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$message.error(err.response.data.errors[0].message)
      }
    },
    scrollToTop(top: number) {
      window.scroll({ top, behavior: 'smooth' })
    },
    validateForm(): boolean {
      var formValidationStatus = true

      if (!this.form.companyWebsite) {
        this.formErrors.msg.companyWebsite = 'Website field is required'
        formValidationStatus = false
      } else if (!validator.isURL(this.form.companyWebsite)) {
        this.scrollToTop(10)
        this.formErrors.msg.companyWebsite = 'Website is not valid'
        formValidationStatus = false
      }

      if (!this.form.companyName) {
        this.formErrors.msg.companyName = 'Company name  is required'
        formValidationStatus = false
      }

      if (!this.form.industry) {
        this.formErrors.msg.industry = 'Industry  is required'
        formValidationStatus = false
      }

      this.formErrors.isformValid = formValidationStatus
      return formValidationStatus
    },
    onRadioClicked(value: RadioValue) {
      this.currentRadioValue = value
      this.form.enabled = []
    },
    formatUrl(url: string) {
      const format = /^https?:\/\//i
      return !format.test(url) ? `http://${url}` : url
    },
  },
}
