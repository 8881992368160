
<div
  :id="vnodeId"
  :data-vnode-id="vnodeId"
  class="cvt-editor-code-snippet w-100"
  :class="classes"
  :style="styles"
  draggable="true"
  @click.stop="select"
  @mouseover="target"
  @contextmenu.prevent.stop="showContextMenu"
  @dblclick.stop.prevent
  @dragstart="dragstart"
  @dragend="dragend"
>
  <drag-and-drop-zone :z-index="dropzoneZIndex" @dropped="addElementToSide"></drag-and-drop-zone>

  <h4 class="d-flex justify-content-center align-items-center">
    <i class="material-icons mr-2 md-48">code</i> Code Snippet
  </h4>

  <template v-if="showActionButtonsOnClick && !isGridBuilder">
    <!--THis is only here because of freshwork in v1 gridbuilder,
    once they migrate to v2 or v3, it will become irrelevant-->
    <action-buttons
      v-if="selected"
      @duplicate="duplicateAndValidate"
      @remove="removeAndValidate"
    />
  </template>
  </div>
