<template>
  <div class="slide-wrapper">
    <h6 v-if="!hideLabel">{{ $t("element.property.form.design.width") }}</h6>
    <cvt-slider
      id="slider-default"
      :tooltips-enabled="true"
      :step-marks-enabled="true"
      class="slider"
      :min="min"
      :max="max"
      :value="width"
      :step="step"
      @change="onChange"
    />
  </div>
</template>

<script>
import * as _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Resize',
  props: {
    step: {
      type: Number,
      default: 10
    },
    min: {
      type: Number,
      default: 10
    },
    max: {
      type: Number,
      default: 100
    },
    sizeKey: {
      type: String,
      default: 'width'
    },
    label: {
      type: String,
      default: 'Width'
    },
    hideLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED
    }),
    width: {
      get() {
        return _.get(this.selected, this.sizeKey.replaceAll('/', '.'), this.min)
      },
      set (value) {
        return value
      }
    }
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP
    }),
    clearSize () {
      return this.width = null
    },
    onChange: _.throttle(function (value) {
      this.width = value
      return this.updateProp({key: this.sizeKey, value})
    }, 300)
  }
}
</script>

<style lang="scss">
  .slide-wrapper {
    .slider {
      margin-top: 8px;
    }
  }
</style>
