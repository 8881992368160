<template>
  <div
    :id="vnodeId"
    ref="gridRootWrapper"
    :data-vnode-id="vnodeId"
    class="position-relative visual-box grid-stack-wrapper"
    @mouseenter="mouseOverAction"
    @mouseleave="mouseLeaveAction"
  >
    <!--    box-color="#e9e9e98c"-->
    <visual-box
      ref="vsBox"
      :grid-column="column"
      box-color="#333333"
      :grid-column-height="cellHeight"
      :grid-v-node-id="vnodeId"
    />
    <div
      :key="'grid-stack-' + vnodeId + viewMode"
      ref="gridElement"
      :class="gridClassName"
      @mousedown.stop="ContainerMouseDown"
      @mouseover.stop=""
    >
      <div
        v-if="
          fbNode.children.length === 0 &&
          parent().parent().name === 'CPopupBuilder'
        "
        class="d-flex justify-content-center"
      >
        <div class="popup-default-box">
          <!-- The enlarged Icon -->
          <span
            class="popup-default-icon cvt-icon-www-site-arrow-cursor"
          ></span>
          <!-- Select Popup Style text under the Icon -->
          <span class="popup-default-text">Select Popup Style</span>
          <!-- Button to open up Pre Made Popup Options on click -->
          <cvt-button
            class="popup-default-button"
            :color="selectPopupBtnConfig.color"
            :special-style="selectPopupBtnConfig.specialStyle"
            :icon="'arrow-right'"
            :prepend="false"
            :text="'Select Pre-Made Popup'"
            @click="click($event)"
          ></cvt-button>
        </div>
      </div>

      <div
        v-if="
          fbNode.children.length === 0 &&
          parent().parent().name !== 'CPopupBuilder'
        "
        class="empty-section-placeholder d-flex justify-content-center align-items-center"
      >
        <!-- Internal dashed box -->
      </div>

      <template v-for="node in fbNode.children">
        <grid-item-v3
          v-bind="node.data.props"
          :key="node.data.props.vnodeId"
          :fb-node="node"
          class="grid-stack-item-main"
          @removeElement="removeNode"
          @duplicateElement="duplicateNode"
          @forceRemoveElement="forceRemoveNode"
        ></grid-item-v3>
      </template>
    </div>
  </div>
</template>

<script>
import { get, set } from 'lodash'
import { mapState } from 'vuex'
import { AddCommand } from '../../../../commands/AddCommand'
import GridContainer from '../../../editor/nodes/GridContainer.vue'
import FirebaseNodeV3 from '../../base/FirebaseNode'
import GridBuilderMixin from '../../base/GridBuilderMixin'

export default GridContainer.extend({
  mixins: [FirebaseNodeV3, GridBuilderMixin],
  name: 'GridContainerV3',
  updated() {
    this.$nextTick(() => {
      this.$refs.vsBox.$forceUpdate()
    })
  },
  computed: {
    ...mapState('globalTheme', {
      selectPopupBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig?.locations.embeddedPopupBuilder.selectPopupBtn.props
          ? globalButtonConfig.locations.embeddedPopupBuilder.selectPopupBtn
              .props
          : {},
    }),
  },
  methods: {
    gridItemRemoved(e, items) {
      console.debug('Item Removed', e, items)
    },
    addGridItem(vNode, options) {
      if (['grid-box-container', 'sub-grid-container'].includes(vNode.tag)) {
        options.desktop.flexHorizonAlign = options.desktop.flexVerticalAlign =
          null
        options.phone.flexHorizonAlign = options.phone.flexVerticalAlign = null
      }
      if (['c-form'].includes(vNode.tag)) {
        this.setPageId(vNode)
        set(
          vNode,
          'data.props.fields',
          get(vNode, 'data.props.fields', []) || [],
        )
      }

      // We dont want to let the user resize the countdown timer
      if (vNode.tag === 'countdown-timer') {
        options.phone.noResize = true
      }

      // this function set some default values for flex-alignment using
      // config object that config object is set via configDefaults table
      // as well as organizationsConfig table, organizationConfig table entry
      // overrides the entry of configDefaults table
      this.setOrgSpecificDefaults(vNode, options)

      let widget = this.wrapInGridItemWrapper(vNode, options)
      this.pushCommand(new AddCommand(this.vnodeId, widget))
    },
    mouseOverAction(event) {
      this.$refs?.vsBox?.$el?.classList.add('show-grid')
    },
    mouseLeaveAction(event) {
      this.$refs?.vsBox?.$el?.classList.remove('show-grid')
    },
    ContainerMouseDown(event) {
      if (event.target === this.$refs.gridElement) {
        const sectionElement = this.getSectionElement()
        if (sectionElement) {
          this.advancedMenuToggle(true)
          this.cancelDebounceTarget()
          this.mutateTargeted(sectionElement)
          this.mutateSelected(sectionElement)
        }
        event.preventDefault()
        event.stopPropagation()
      }
    },
    getSectionElement() {
      const elements = document.querySelectorAll(
        `[data-vnode-id="${this.$parent.vnodeId}"]`,
      )
      if (elements.length > 0) {
        return elements[0].__vue__ || undefined
      }
    },
    initGridBuilder({ reload = false, skipEvent = false }) {
      this.initGridBuilderHandler({ reload, skipEvent })
    },
    fixedChangesInCellSize(prevWidget, fbNode) {
      if (prevWidget.el.classList.contains('sub')) {
        // Means that Item was dragged from sub-container into main grid
        fbNode.data.props.desktop.dataHeight =
          fbNode.data.props.desktop.dataHeight / 2
        fbNode.data.props.desktop.dataWidth =
          fbNode.data.props.desktop.dataWidth / 2
      }
    },
  },
})
</script>
