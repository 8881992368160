
<div class="marketplace-wrapper">
  <page-preview
    :title="previewTitle"
    :url="previewUrl"
    :is-first="isFirstTemplate"
    :is-last="isLastTemplate"
    @prev="prevTemplate"
    @next="nextTemplate"
    @use-template="useTemplate(templateID)"
    @exit-clicked="goToCampaignBuilder"
  />
</div>
