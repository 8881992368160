import Vue from '../../lib/utils/vue';
import { styled } from '@egoist/vue-emotion';
import { VueEmotion } from '@egoist/vue-emotion';
import CvtButton from '../molecules/Button';
import CvtInput from '../molecules/Input';
import CvtSwitch from '../molecules/Switch';
import CvtRadioGroup from '../molecules/radio-group/RadioGroup';
import CvtRadioButton from '../molecules/radio-group/RadioButton';
import { CvtIcon } from '../molecules/icon/icon';
import * as getters from '../../../builder/src/js/store/modules/getters';
import { mapGetters, mapState } from '../../../builder/node_modules/vuex';
import { LIONDESK, LEFT_SIDEBAR_STANDARD, LEFT_SIDEBAR_FRESHWORKS } from '../constants'
import VueI18n from 'vue-i18n';
Vue.use(VueEmotion);
Vue.use(VueI18n);


let textFontSize, textFontWeight, backgroundColor, color, textColor;
let FontFamily, Spacing, orgName;

const UTIL = {
  getSearchBarBgColor(props){
    if(props.mode == "DARK"){
      return backgroundColor({ themeing: "dark"}, 700)
    }else{
      if(props.searchBarBgConfig){
        const searchBarBgColorConfig = props.searchBarBgConfig?.default?.bgColor
        if(!searchBarBgColorConfig?.custom){
          return backgroundColor(searchBarBgColorConfig.value, searchBarBgColorConfig.weight)
        }else{
          return `background: ${searchBarBgColorConfig.value};`
        }
      }else{
        return backgroundColor({ themeing: "light"}, "WHITE")
      }
    }
  }

}
const getSearchBar = () => styled('div')`
  padding: 0  ${Spacing.SPACING_32}px;
  display: flex;
  justify-content: space-between;
  font-family: ${FontFamily.DEFAULT};
  ${(props) => UTIL.getSearchBarBgColor(props)}
`;


const getSortByName = () => styled('span')`
  ${textFontSize('sm')};
  margin: ${Spacing.SPACING_4}px;
  ${textFontWeight('LIGHT')}
  font-family: ${FontFamily.DEFAULT};
  ${(props) => {
    return props.mode === 'DARK'
      ? textColor({ themeing: 'light' }, 100)
      : textColor({ themeing: 'dark' }, 700);
  }}
`;

const getPublishedName = () => styled(getSortByName())`
  margin: ${Spacing.SPACING_12}px;
`;
const getInput = () => styled('div')`
  width: 70%;
`;

const getSortByDiv = () => styled('div')`
  display: flex;
  & > * {
    margin: ${Spacing.SPACING_4}px;
  }
`;
const getWideFlexDiv = () => styled('div')`
  display: flex;
  flex: 2;
  align-items: center;
  & > * {
    margin: ${Spacing.SPACING_4}px;
  }
`;
const getCvtSwitch = () => styled(CvtSwitch)`
  margin: ${Spacing.SPACING_4}px;
`;
const getCvtSearchBtnDiv = () => styled('div')`
  padding: ${Spacing.SPACING_2}px;
`;
const getRightDiv = () => styled('div')`
  margin:auto;
`

const getSortByLabelDiv = () => styled('div')`
  margin:auto;
  font-family: ${FontFamily.DEFAULT};
  ${textFontSize('sm')};
`

const getPublishedLabelDiv = () => styled('div')`
  margin:auto;
  font-family: ${FontFamily.DEFAULT};
  ${textFontSize('sm')};
  margin-left:  ${Spacing.SPACING_32}px;
`

const getToggleViewWrapper = () => styled('div')`
  padding: 0px 0px 0px 70px;
`;


const getRadioGroup = () => styled(CvtRadioGroup)``


const getRadioButton = () => styled(CvtRadioButton)``


const getIcon = () => styled(CvtIcon)``


export const CvtSearchFilter = {
  name: 'CvtSearchFilter',
  data () {
    return {
      inputQuery: '',
      isCreatedSelected: false,
      isLastUpdatedSelected: false,
      isPublished: false,
    };
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      color: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      orgName: ({ globalTheme }) => globalTheme?.OrgName !== undefined ? globalTheme.OrgName : LEFT_SIDEBAR_STANDARD
    }),
  },
  created () {
    (Spacing = this.Spacing),
      (textFontSize = this.textFontSize),
      (FontFamily = this.FontFamily),
      (backgroundColor = this.backgroundColor),
      (textFontWeight = this.textFontWeight),
      (color = this.color),
      (textColor = this.textColor),
      (orgName = this.orgName)
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    initialTemplatesViewFormatVal: {
      type: String,
      required: false,
      default: 'GRID',
    },
    displayTemplateViewFormatBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultSearch: {
      type: String,
      required: false,
      default: ''
    },
    searchBarBgConfig: {
      type: Object,
      required: () => {},
    }
  },
  methods: {
    radioBtnClicked(value) {
      this.$emit('templatesViewToggled', value); // GRID|LIST
    }
  },
  render: function (h) {
    const SearchBar = getSearchBar();
    const SectionName = getSortByName();
    const SortByWrapDiv = getSortByDiv();
    const WideFlexDiv = getWideFlexDiv();
    const InputBar = getInput();
    const PublishedName = getPublishedName();
    const PublishedSwitch = getCvtSwitch();
    const SearchBtnDiv = getCvtSearchBtnDiv();
    const RightDiv = getRightDiv();
    const SortByLabelDiv = getSortByLabelDiv();
    const PublishedLabelDiv = getPublishedLabelDiv();

    const ToggleViewWrapper = getToggleViewWrapper();
    const RadioGroup = getRadioGroup();
    const RadioButton = getRadioButton();
    const Icon = getIcon();

    return (
      <SearchBar
        mode={this.mode}
        searchBarBgConfig={this.searchBarBgConfig}
      >
        <WideFlexDiv>
          <InputBar>
            <CvtInput
              value={ this.defaultSearch }
              onClear={() => {
                this.inputQuery = '';
                this.$emit('searchDone', '');
              }}
              onInput={(e) => {
                this.inputQuery = e.target.value;
                if (!this.inputQuery) {
                  this.$emit('searchDone', '');
                }
              }}
              onEnter={() => this.$emit('searchDone', this.inputQuery)}
              size='small'
              label={this.$t('marketplace.template.search.label')}
              floatingLabel={true}
              prefixIcon="search"
              mode={this.mode}
              color='black'
            ></CvtInput>
          </InputBar>

          {this.displayTemplateViewFormatBtn && (

            <ToggleViewWrapper>
              <RadioGroup 
                theme={this.mode}
                orientation={'horizontal'}
                size={'md'}
                initialValue={this.initialTemplatesViewFormatVal}
              >
                <cvt-radio-button
                  onRadioClick={(value) => this.radioBtnClicked(value)}
                  size={'md'}
                  value={'LIST'}
                >
                  <Icon icon={'list'}></Icon>
                </cvt-radio-button>

                <cvt-radio-button
                  onRadioClick={(value) => this.radioBtnClicked(value)}
                  size={'md'}
                  value={'GRID'}
                >
                  <Icon icon={'image'}></Icon>
                </cvt-radio-button>

              </RadioGroup>
            </ToggleViewWrapper>

          )}


        </WideFlexDiv>
        {this.orgName === LIONDESK &&
          (
            <SortByLabelDiv><span>{this.$t('marketplace.template.search.sort.label')}</span></SortByLabelDiv>
          )}
        <RightDiv>
          {this.orgName !== LIONDESK &&
            (<SectionName mode={this.mode}>{this.$t('marketplace.template.search.sort.label')}</SectionName>)}

          <SortByWrapDiv>
            <CvtButton
              specialStyle = { this.orgName === LEFT_SIDEBAR_FRESHWORKS && this.isCreatedSelected ? 'dark' : '' }
              mode={this.mode}
              size='sm'
              text={this.$t('marketplace.template.search.date.created')}
              outlined={true}
              color={this.orgName === LIONDESK ? '' : this.isCreatedSelected ? 'default' : 'light'}
              onClick={() => {
                this.isCreatedSelected = !this.isCreatedSelected;
                if (this.isLastUpdatedSelected)
                  this.isLastUpdatedSelected = false;
                this.$emit('sortParameter', 'created', this.isCreatedSelected);
              }}
            ></CvtButton>
            <CvtButton
              specialStyle = { this.orgName === LEFT_SIDEBAR_FRESHWORKS && this.isLastUpdatedSelected ? 'dark' : '' }
              mode={this.mode}
              size='sm'
              text={this.$t('marketplace.template.search.date.updated')}
              outlined={true}
              color={this.orgName === LIONDESK ? '' : this.isLastUpdatedSelected ? 'default' : 'light'}
              onClick={() => {
                this.isLastUpdatedSelected = !this.isLastUpdatedSelected;
                if (this.isCreatedSelected) this.isCreatedSelected = false;
                this.$emit(
                  'sortParameter',
                  'lastUpdated',
                  this.isLastUpdatedSelected
                );
              }}
            ></CvtButton>
          </SortByWrapDiv>
        </RightDiv>
        {this.orgName === LIONDESK &&
          (
            <PublishedLabelDiv><span>{this.$t('element.revision.publish.label')}</span></PublishedLabelDiv>
          )}
        <RightDiv>
          {this.orgName !== LIONDESK &&
            (<PublishedName mode={this.mode}>{this.$t('element.revision.publish.label')}</PublishedName>)}
          <PublishedSwitch
            size='md'
            color='default'
            type='circular'
            descriptionType='text'
            activeText=''
            inactiveText=''
            disabled={false}
            initialState={false}
            leftSideLabel={true}
            showBigSwitch={false}
            mode={this.mode}
            showInside={true}
            onSwitchToggle={() => {
              this.isPublished = !this.isPublished;
              this.$emit('sortParameter', 'published', this.isPublished);
            }}
          ></PublishedSwitch>
        </RightDiv>
      </SearchBar>
    );
  },
};