import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'

import fns from '../services/fns'

export default {
  state: {
    results: [],
    visible: false,
    insert: null,
    searching: false
  },
  getters: {
    [getters.IMAGE_SEARCH_RESULTS] ({results}) {
      return results
    },
    [getters.IMAGE_SEARCH_VISIBLE] ({visible}) {
      return visible
    },
    [getters.IMAGE_SEARCH_SEARCHING] ({searching}) {
      return searching
    }
  },
  mutations: {
    [mutations.IMAGE_SEARCH_UPDATE_RESULTS] (state, results) {
      state.results = results || []
    },
    [mutations.IMAGE_SEARCH_SET_INSERT_HANDLER] (state, insert) {
      state.insert = insert
    }
  },
  actions: {
    [actions.IMAGE_SEARCH_QUERY] ({commit, state}, query) {
      state.searching = true
      commit(mutations.IMAGE_SEARCH_UPDATE_RESULTS, [])
      return fns.imageSearch(query).then((data) => {
        commit(mutations.IMAGE_SEARCH_UPDATE_RESULTS, data)
        state.searching = false
      }, _ => {
        state.searching = false
      })
    },
    [actions.IMAGE_SEARCH_SELECTED] ({state}, image) {
      state.insert(image)
      state.insert = null
    }
  }
}
