
<div>
  <h1>Collapse</h1>
  <h4>Normal Collapse</h4>
  <div class="d-flex flex-wrap mb-2">
    <div
      v-for="(theme, index) in collapseThemes"
      :key="index"
      class="m-2"
      style="width: 30%;"
    >
      <span>{{ index }}</span>
      <cvt-collapse
        :accordion="false"
        :theme="theme"
        :prepend-chevron="true"
        @change="onTabActiveChange"
      >
        <cvt-collapse-item
          title="CollapseItem"
          icon="user"
          name="1"
        >
          <div>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</div>
          <div>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</div>
        </cvt-collapse-item>
        <cvt-collapse-item
          title="With Minor Collapse"
          name="4"
        >
          <div>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</div>
          <cvt-collapse
            :accordion="false"
            :theme="theme"
            @change="onTabActiveChange"
          >
            <cvt-collapse-tiny-item
              title="Show More"
              name="4-1"
            >
              <div>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</div>
              <div>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</div>
            </cvt-collapse-tiny-item>
            <cvt-collapse-tiny-item
              title="Minor Collapse"
              name="4-2"
            >
              <div>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</div>
              <div>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</div>
              <cvt-collapse
                :accordion="false"
                :theme="theme"
                @change="onTabActiveChange"
              >
                <cvt-collapse-item
                  title="Collapse in Minor"
                  name="4-2-1"
                >
                  <div>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</div>
                  <div>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</div>
                </cvt-collapse-item>
                <cvt-collapse-item
                  title="Collapse in Minor 2"
                  name="4-2-2"
                >
                  <div>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</div>
                  <div>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</div>
                </cvt-collapse-item>
              </cvt-collapse>
            </cvt-collapse-tiny-item>
          </cvt-collapse>
          <div>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</div>
        </cvt-collapse-item>
        <cvt-collapse-item
          title="Features"
          name="2"
        >
          <div>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</div>
          <div>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</div>
        </cvt-collapse-item>
        <cvt-collapse-item
          title="Collapse with Component"
          name="3"
        >
          <div>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</div>
          <cvt-button
            color="primary"
            shape="rounded"
            size="md"
            icon="user"
            text="Click"
          />
          <div>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</div>
        </cvt-collapse-item>
      </cvt-collapse>
    </div>
  </div>

  <h4>Tiny Collapse</h4>
  <div class="d-flex flex-wrap mb-2">
    <div
      v-for="(theme, index) in collapseThemes"
      :key="tinyKey(index)"
      class="m-2"
      style="width: 30%;"
    >
      <span>{{ index }}</span>
      <cvt-collapse
        :accordion="true"
        :theme="theme"
        @change="onTabActiveChange"
      >
        <cvt-collapse-tiny-item
          title="Collapse Tiny Item"
          orientation="right"
          name="1"
        >
          <div>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</div>
          <div>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</div>
        </cvt-collapse-tiny-item>
        <cvt-collapse-tiny-item
          title="Features"
          orientation="left"
          name="2"
        >
          <div>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</div>
          <cvt-collapse
            :accordion="true"
            :theme="theme"
            @change="onTabActiveChange"
          >
            <cvt-collapse-item
              title="Collapse in Minor"
              icon="user"
              name="4-2-1"
            >
              <div>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</div>
              <div>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</div>
            </cvt-collapse-item>
            <cvt-collapse-item
              title="Collapse in Minor 2"
              name="4-2-2"
            >
              <div>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</div>
              <div>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</div>
            </cvt-collapse-item>
          </cvt-collapse>
          <div>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</div>
        </cvt-collapse-tiny-item>
        <cvt-collapse-tiny-item
          title="Collapse with Component"
          name="3"
        >
          <div>Consistent with real life: in line with the process and logic of real life, and comply with languages and habits that the users are used to;</div>
          <cvt-button
            color="primary"
            shape="rounded"
            size="md"
            icon="user"
            text="Click"
          />
          <div>Consistent within interface: all elements should be consistent, such as: design style, icons and texts, position of elements, etc.</div>
        </cvt-collapse-tiny-item>
      </cvt-collapse>
    </div>
  </div>
</div>
