
<el-dialog :visible.sync="visible" size="tiny" @close="handleClose">
  <span slot="title" class="save-block-title">
    {{
      $t('editor.left_nav.pages.page_actions_istemplate.popover.save_page')
    }}
  </span>
  <div class="row">
    <el-input
      v-model="pageName"
      class="col-12 mb-2"
      :placeholder="
        $t('element.property.save_page.input.page_name_placeholder')
      "
      autofocus
    />
    <el-input
      v-model="pageThumbnail"
      class="col-12 mb-2"
      :placeholder="
        $t('element.property.save_page.input.thumbnail_placeholder')
      "
    />

    <el-select
      v-model="pageTag"
      class="col-12"
      filterable
      allow-create
      :placeholder="
        $t('element.property.save_page.input.page_tags_placeholder')
      "
    >
      <el-option
        v-for="tag in pageTags"
        :key="tag"
        :label="tag"
        :value="tag"
      />
    </el-select>
  </div>

  <span slot="footer" class="dialog-footer">
    <div class="row">
      <cvt-switch
        class="col-1"
        size="sm"
        color="active"
        type="circular"
        description-type="default"
        :initial-state="false"
        @switchToggle="(e) => savePageGlobally(e)"
      />
      <span class="col-2">{{
        $t(
          'editor.left_nav.pages.page_actions_istemplate.popover.save_page_globally',
        )
      }}</span>
      <div class="col-9 mr-0">
        <el-button @click="handleClose">{{
          $t('element.property.cta.cancel')
        }}</el-button>
        <el-button type="primary" :loading="loading" @click="savePage">{{
          $t('element.property.cta.confirm')
        }}</el-button>
      </div>
    </div>
  </span>
</el-dialog>
