import { styled, VueEmotion } from '@egoist/vue-emotion'
import Vue from '../../../lib/utils/vue'
import { CvtIcon } from '../../molecules/icon';
import FontPicker from "../../../../builder/src/js/components/common/FontPicker.vue"


Vue.use(VueEmotion)

const getFontContainer = () => styled('div')`
   margin-bottom: 60px !important;

   p {
    font-size: 20px;
    margin-bottom: 10px;
   }
`

const getFontHeader = () => styled('p')``

const getFontGroupContainer = () => styled('div')`
   width: 100%;
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(250px, max-content));
   grid-gap: 15px;
   place-items: center;

   &::-webkit-scrollbar{
    display: none;
   }
`

const getFontBoxContainer = () => styled('div')`
   display: flex;
`

const getFontBox = () => styled('div')`
    width: 270px;
    height: 196px;
    border-radius: 4px;
    background-color: #F0F2F6;
    display: grid;
    place-content: center;
    cursor: pointer;
   ${(props) => props.isActive && `border: 3px solid #5D62AB;`}
`

const getFontActiveBox = () => styled('div')`
   display: flex;
   justify-content: center;
   align-items: center;
`


const getFontActiveLogo = () => styled('img')`
   height: 20px;
`


const getAddNewFont = () => styled('div')`
    width: 270px;
    height: 196px;
    border: 2px dashed #BCC2CB;
    border-radius: 4px;
    display: grid;
    place-content: center;
    cursor: pointer;
    text-align: center;

    p {
        color: #8A8E97;
        width: 80%;
        margin: 0 auto;
        padding-top: 15px;
    }
`

const getFontName = () => styled('p')``

const getAddNewFontIcon = () => styled(CvtIcon)`
   font-size: 25px;
   color: #5D62AB;
`

const getAddNewFontLabel = () => styled('p')``

export const CvtFontSelector = {
   name: 'CvtFontSelector',
   components: {
    FontPicker
   },
   data () {
   return {
       activeFont: null,
       selectedFont: '',
       showFont: false
   }
   },
   props: {
       label: {
           type: String,
           default: ''
       },
       brandFonts: {
           type: Array,
           default: () => []
       }
   },
   computed: {},
   methods: {
       onSelectFont(idx) {
           this.activeFont = idx
           this.$emit('setCurrentFont', idx)
       },
       showFontPicker() {
           this.showFont = !this.showFont
       },
       updateFontFamily(font) {
           this.selectedFont = font
       },
       onSelectNewFont(){
           this.$emit('selectNewFont', this.selectedFont)
           this.showFont = false
       }
   },
   render: function (h) {


       const FontContainer = getFontContainer()
       const FontHeader = getFontHeader()
       const FontName = getFontName()
       const FontGroupContainer = getFontGroupContainer()
       const FontBoxContainer = getFontBoxContainer()
       const FontActiveBox = getFontActiveBox()
       const FontActiveLogo = getFontActiveLogo()
       const FontBox = getFontBox()
       const AddNewFont = getAddNewFont()
       const AddNewFontIcon = getAddNewFontIcon()
       const AddNewFontLabel = getAddNewFontLabel()




       return (
           <FontContainer>
               <FontHeader>{ this.label }</FontHeader>
               <FontGroupContainer>
               { this.brandFonts.map((font, idx) => (
                   <FontBoxContainer
                       key={idx}
                       onClick={() => this.onSelectFont(idx)}
                   >
                       <FontBox
                           isActive={this.activeFont === idx}
                       >
                        <FontName>{ font.name }</FontName>
                           <FontActiveBox>
                           {
                               this.activeFont === idx && (
                                   <FontActiveLogo
                                       src="/img/enterprise/branding-check-light.svg"
                                       alt="check"
                                   />
                               )
                           }
                           </FontActiveBox>
                       </FontBox>
                   </FontBoxContainer>
               ))}
                   <AddNewFont
                       onclick={() => this.showFontPicker()}
                   >
                       <AddNewFontIcon
                           icon="fa fa-plus-circle"
                        />
                        <AddNewFontLabel>Add a font for your brand</AddNewFontLabel>
                   </AddNewFont>
                   <cvt-dialog
                       bg-color="#ffffff"
                       size="sm"
                       height-auto={true}
                       show-close={true}
                       show={this.showFont}
                       onclose={() => this.showFontPicker()}
                   >
                       <div class="pb-5">
                            <font-picker
                                placeholder="Select a font"
                                oninput={(value) => this.updateFontFamily(value)}
                            />
                       </div>
                       <cvt-button
                           color="success"
                           text="Done"
                           onClick={() => this.onSelectNewFont()}
                       />
                   </cvt-dialog>
               </FontGroupContainer>
           </FontContainer>
       )
   }
}
