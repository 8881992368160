<template>
  <div>
    <cvt-top-nav-bar
      style="position: fixed; width: 100%; z-index: 3"
      :mode="mode"
      variant="admin"
      :org-id="admin.orgID"
      :project-id="admin.projectID"
      route="marketplace"
      :fixed="true"
      :input-data="inputOrgData"
      :input-project-data="projects"
      @inputOrgName="onOrgChange"
      @createNewOrg="createNewOrg"
      @inputProjectID="onProjectChange"
      @change="searchProjects"
    >
    </cvt-top-nav-bar>

    <div>
      <div>
        <cvt-admin-left-nav-bar
          style="top: 64px"
          usage="adminDashboard"
          initial-active-id="templates"
          :mode="mode"
        >
        </cvt-admin-left-nav-bar>
      </div>
      <div style="position: absolute; left: 280px; top: 64px">
        <div class="search-filter-style">
          <cvt-search-filter-admin
            class="search-filter-bar"
            :mode="mode"
            @sortBymarketplace="onSortBymarketplace"
            @searchBySiteID="onsearchBySiteID"
            @goToMarketPlace="
              () => {
                $router.push('/')
              }
            "
          ></cvt-search-filter-admin>
        </div>
        <div
          class="cards-div"
          :style="filteredSites.length !== 0 ? 'padding: 20px;' : ''"
        >
          <cvt-marketplace-card
            v-for="(item, idx) in filteredSites"
            :id="item.id"
            :key="item.message"
            v-intersected-once="{
              onIntersected: !onlyMarketplace
                ? loadMoreSites
                : loadMoreTemplates,
              shouldObserve: filteredSites.length - 1 === idx,
            }"
            :item="item"
            :mode="mode"
            :thumbnail="item.thumbnail"
            :preview-button="previewButton"
            :copy-page-button="copyPageButton"
            thumbnail-alt="Lead Gen Thumbnail"
            usage="adminDashboard"
            @action="onAction"
          >
            <template #cardTitle>{{ item.name }}</template>
            <template #id>{{ item.id }}</template>
          </cvt-marketplace-card>
        </div>

        <div class="admin-dashboard-loading">
          <cvt-loading-message message="Loading" :display="isLoading">
          </cvt-loading-message>
        </div>
      </div>
    </div>
    <cvt-dialog
      :show="newOrgDialogFlag"
      :height-auto="true"
      :show-close="true"
      :show-footer="false"
      :bg-color="'white'"
      @close="handleClose"
    >
      <template v-if="showDialogHeader" #title>
        <h1>Create New Organization</h1>
      </template>
      <template #default>
        <cvt-new-org-modal
          :show="newOrgDialogFlag"
          :org-created="orgCreated"
          :shared-url="sharedURL"
          @api-call="apiCall"
          @close="handleClose"
        />
      </template>
    </cvt-dialog>
  </div>
</template>

<script>
import * as _ from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as getters from '../../store/modules/getters'
import * as actions from '../../store/modules/actions'
import * as mutations from '../../store/modules/mutations'
import { Organization } from '../../store/services/api'
import { cvtRouteNodes } from '@/js/utils/routes'

const organizationApi = new Organization()

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Dashboard',
  data() {
    return {
      filter: '',
      onlyMarketplace: false,
      apps: [],
      pagination: { limit: 5, offset: 0 },
      templatePagination: { limit: 5, offset: 0 },
      mode: 'LIGHT',
      inputOrgData: [],
      inputProjectData: [],
      newOrgDialogFlag: false,
      showDialogHeader: true,
      orgCreated: false,
      sharedURL: '',
      isLoading: false,
      searchById: '',
      // TODO: load these from the DASHBOARD_CONFIG in Spanner, that object will be similar to MARKETPLACE_CONFIG
      archiveButton: {
        text: 'marketplace.template.archive.cta.message',
      },
      copyPageButton: {
        text: 'marketplace.templates.label.copyTemplate',
      },
      getStartedButton: {
        text: 'marketplace.templates.label.getStarted',
        // eslint-disable-next-line vue/no-computed-properties-in-data
        specialStyle: this.buttonSpecialStyle,
      },
      previewButton: {
        text: 'marketplace.templates.label.preview',
        // eslint-disable-next-line vue/no-computed-properties-in-data
        outlined: this.buttonOutline,
      },
    }
  },
  computed: {
    ...mapGetters({
      sites: getters.SITES_GET_LIST,
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN,
      admin: getters.AUTH_GET_USER,
    }),
    ...mapState('super', [
      'searching',
      'projects',
      'organizations',
      'currentOrgID',
      'currentProjectID',
    ]),
    ...mapState('globalTheme', {
      //   marketplaceConfig: ({ marketplaceConfig }) => marketplaceConfig,
      //   adminDashboardConfig: ({ adminDashboardConfig }) => adminDashboardConfig,
    }),
    filteredSites() {
      let filtered_site = _.chain(this.sites)
        .filter((s) => {
          return this.filter
            ? _.includes(
                `${s.name.toLowerCase()} - ${s.id.toLowerCase()}`,
                this.filter.toLowerCase(),
              )
            : true
        })
        .value()
      return filtered_site
    },
    // TODO: Temp functions to catch orgs needing a customization
    buttonOutline() {
      if (this.organizations === 'infusionsoft') {
        return false
      } else return true
    },
    // eslint-disable-next-line vue/return-in-computed-property
    buttonSpecialStyle() {
      if (this.organizations === 'freshworks') {
        return 'dark'
      }
    },
  },
  watch: {
    organizations(orgs) {
      this.inputOrgData = orgs.map((org) => {
        return {
          value: org,
          label: org,
        }
      })
    },
  },
  created() {
    // Set necessary Spanner config objects if they are present
    //TODO: need to be able to load config pertaining to themable or customizable objects from spanner
    // this.config.MARKETPLACE_CONFIG ? this.setMarketplaceConfig(this.config.MARKETPLACE_CONFIG) : false
    this.searchOrganizations()
    this.loadMoreSites()
  },
  methods: {
    ...mapActions({
      loadSites: actions.SITES_FETCH_DATA,
      publish: actions.SITE_PUBLISH,
    }),
    ...mapActions('super', [
      'onProjectChange',
      'onOrgChange',
      'searchOrganizations',
      'searchProjects',
      'sudo',
    ]),
    ...mapMutations({
      setSitesData: mutations.SITES_SET_DATA,
    }),
    editLink(site) {
      return {
        name: cvtRouteNodes.editorSetup,
        params: {
          siteId: site.id,
        },
        query: {
          redirectUrl: '/admin/dashboard',
        },
      }
    },
    settingsLink(site) {
      return {
        name: cvtRouteNodes.editorLaunch,
        params: {
          siteId: site.id,
        },
        query: {
          redirectUrl: '/admin/dashboard',
        },
      }
    },
    btnDisabled(site) {
      return site.locked ? 'disabled' : ''
    },
    async loadMoreSites() {
      this.isLoading = true

      await this.loadSites({
        ...this.pagination,
        searchId: this.searchById,
      })

      this.isLoading = false
      this.pagination.offset += this.pagination.limit
    },
    async loadMoreTemplates() {
      this.isLoading = true

      await this.loadSites({
        ...this.templatePagination,
        market: true,
      })

      this.isLoading = false
      this.templatePagination.offset += this.templatePagination.limit
    },
    onAction(eventName, item) {
      if (eventName === 'edit') {
        this.$router.push(this.editLink(item))
      }
      if (eventName === 'settings') {
        this.$router.push(this.settingsLink(item))
      }
      if (eventName === 'publish') {
        this.publish(item, null)
      }
    },
    onsearchBySiteID(searchQuery) {
      this.searchById = searchQuery
      this.setSitesData([])
      this.pagination.offset = 0
      this.loadMoreSites()
    },
    onSortBymarketplace(isMarketPlace) {
      this.onlyMarketplace = !this.onlyMarketplace
      this.setSitesData([])
      this.pagination.offset = 0
      this.templatePagination.offset = 0
      this.onlyMarketplace ? this.loadMoreTemplates() : this.loadMoreSites()
    },
    createNewOrg() {
      this.newOrgDialogFlag = true
    },
    handleClose() {
      this.newOrgDialogFlag = false
      this.showDialogHeader = true
      this.orgCreated = false
    },
    async apiCall(data) {
      let response = await organizationApi.addOrganization(data)
      console.log(response)
      if (response.status === 'OK') {
        this.sharedURL = `https://staging.builder.convrrt.com/api/auth/login?orgID=${data.orgID}&projectID=welcome`
        this.orgCreated = true
        this.showDialogHeader = false
      } else {
        //TODO add error notification here!
        console.log('Try Again!')
      }
      console.log(data)
    },
  },
}
</script>
<style lang="scss">
@import '@/scss/utils';

.admin-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  color: $white;
  background-color: $brand-6;
}

.cards-div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 96px;
  background: #fff;
  overflow-y: scroll;
}

.search-filter-style {
  position: fixed;
  width: calc(100vw - 280px);
  z-index: 2;
}

.admin-dashboard-loading {
  width: calc(100vw - 280px);
}
</style>
