import api from '../services/api'

/**
 * @param  {VuexStore}
 * @return {VuexPlugin}
 *
 * This takes the API service and attaches request and response interceptors
 * Then commits to the vuex store the state the loader should be in
 * This should be paird with the vuex loader module which contains
 * the definitions for the mutation methods
 */
export default function interceptor (store) {
  function startLoaderOnReq (reqConfig) {
    // store.commit('startLoader')
    return reqConfig
  }

  function stopLoaderOnResp (resp) {
    // store.commit('stopLoader')
    return resp
  }

  function stopLoader (err) {
    // store.commit('stopLoader')
    return Promise.reject(err)
  }

  // api._client.interceptors.request.use(startLoaderOnReq, stopLoader)
  // api._client.interceptors.response.use(stopLoaderOnResp, stopLoader)
}
