<template >
	<div 
		class="container-fluid payment-page-root-wrap" 
		:class="{ 'selected-mark': selected, 'unselected-mark': !selected }"
		style="cursor: pointer" 
		@click="edit"
	>
		<div class="row">

			<div class="left-container">
				<div class="top-info-wrap mb-5" :class="stylesheet.classes.p">
					<div class="return-link-wrap mb-3">
						<span>
							<i class="far fa-arrow-left"></i>
						</span>
						<span class="ml-2">Return</span>
					</div>
					<div v-if="showLogo" class="pdt-img-wrap mb-3">
						<img :src="brandLogo || '/img/product-logo.svg'" alt="Product-Logo" />
					</div>
					<div class="breadcrumb-wrap mb-2">
						<span class="mr-2">Cart</span>
						<i class="far fa-arrow-right"></i>
						<span class="mx-2">
							<strong>Checkout</strong>
						</span>
						<i class="far fa-arrow-right"></i>
						<span class="ml-2">Confirmation</span>
					</div>
				</div>

				<div class="contact-info-wrap mb-5 pe-none">
					<h4 class="contact-info-heading mb-4" :class="stylesheet.classes.h4">Contact Information</h4>

					<div class="contact-info-input-wrap mb-3" :class="stylesheet.classes.p">
						<input class="contact-info-input" type="text" placeholder="Email address" />
					</div>

					<div class="keep-updated-wrap mb-5" :class="stylesheet.classes.p">
						<input class="keep-updated-checkbox" type="checkbox" name="keep-updated-checkbox" checked />
						<label class="keep-updated-label ml-2">Keep me up to date on news or offers</label>
					</div>
				</div>

				<div class="billing-info-wrap mb-5 pe-none">
					<h4 class="billing-info-heading mb-4" :class="stylesheet.classes.h4">Billing address</h4>

					<div class="billing-info-input-wrap mb-3" :class="stylesheet.classes.p">
						<input class="billing-info-input" type="text" placeholder="Full Name" />
					</div>

					<div class="street-address-input-wrap mb-3" :class="stylesheet.classes.p">
						<input class="street-address-input" type="text" placeholder="Street Address" />
					</div>

					<div class="house-no-input-wrap mb-3" :class="stylesheet.classes.p">
						<input class="house-no-input" type="text" placeholder="House number or P.O box (optional)" />
					</div>

					<div class="row" :class="stylesheet.classes.p">
						<div class="col-4">
							<div class="city-input-wrap mb-3">
								<input class="city-input" type="text" placeholder="City" />
							</div>
						</div>

						<div class="col-4">
							<div class="state-or-province-wrap mb-3">
								<select class="state-or-province-input" disabled>
									<option>State / Province</option>
								</select>
							</div>
						</div>

						<div class="col-4">
							<div class="zip-code-wrap mb-3">
								<input class="zip-code-input" type="text" placeholder="Zip/ Postal Code" />
							</div>
						</div>
					</div>

					<div class="country-wrap mb-3" :class="stylesheet.classes.p">
						<select class="country-input" disabled>
							<option>United States</option>
						</select>
					</div>

				</div>

				<div class="payment-info-wrap mb-4">
					<div class="payment-info-heading mb-4">
						<h4 :class="stylesheet.classes.h4">Payment</h4>
						<div class="row">
							<div class="col-6" :class="stylesheet.classes.p">
								All transactions are secure and encrypted.
							</div>
							<div class="col-6 payment-handler-logo-wrap d-flex justify-content-end">
								<img class="payment-handler-logo visa" src="/img/visa-logo.svg" />
								<img class="payment-handler-logo mastercard" src="/img/mastercard-logo.svg" />
								<img class="payment-handler-logo american-express" src="/img/american-express-logo.svg" />
							</div>
						</div>
					</div>
				</div>

				<div class="card-info-wrap pe-none mb-3" :class="stylesheet.classes.p">
					<div class="card-digits-input-wrap mb-3">
						<input class="card-digits-input" type="text" placeholder="1234 1234 1234 1234" />
					</div>
					<div class="row">
						<div class="col-6">
							<div class="card-mm-yy-wrap mb-3">
								<input class="card-mm-yy-input" type="text" placeholder="MM/YY" />
							</div>
						</div>
						<div class="col-6">
							<div class="card-cvc-wrap mb-3">
								<input class="card-cvc-input" type="text" placeholder="CVC" />
							</div>
						</div>
					</div>
				</div>

				<div class="payment-btn-wrap mb-5">
					<button type="button" class="btnn btn-block payment-now-btn" :class="stylesheet.classes.btn">PAY NOW</button>
				</div>
			</div>

			<div class="right-container">

				<div v-if="showLogo" class="pdt-thumb-outer-wrap mb-2">
					<div class="pdt-thumb-wrap">
						<img lass="pdt-thumb" src="/img/product--placeholder-v2.svg" alt="Product-Logo" />
					</div>
				</div>

				<div class="pdt-title-price-qty-wrap mb-4" :class="stylesheet.classes.p">
					<p class="pdt-title">
						<strong>Your Product Title</strong>
					</p>
					<p class="pdt-qty">QTY: 1</p>
					<h5 class="pdt-price">
						<strong>$9.99 USD</strong>
					</h5>
				</div>

				<div v-if="showDiscountCode" class="pdt-coupan-wrap d-flex justify-content-center mb-4 pe-none">
					<input type="text" class="pdt-coupan-input" placeholder="Coupon Code" />
					<button type="button" class="pdt-coupan-apply-btn" :class="stylesheet.classes.btn">APPLY</button>
				</div>

				<div class="pdt-subtotal-wrap d-flex justify-content-between align-items-center mb-2">
					<h5 class="pdt-subtotal-text" :class="stylesheet.classes.h5">Subtotal</h5>
					<h5 class="pdt-subtotal-amount" :class="stylesheet.classes.h5">
						<strong>$9.99 USD</strong>
					</h5>
				</div>

				<div class="pdt-tax-wrap d-flex justify-content-between align-items-center mb-3">
					<h5 class="pdt-tax-text" :class="stylesheet.classes.h5">Tax</h5>
					<h5 class="pdt-tax-amount" :class="stylesheet.classes.h5">
						<strong>$0 USD</strong>
					</h5>
				</div>

				<div class="pdt-desc-wrap d-flex justify-content-between mb-2">
					<p class="pdt-desc-text" :class="stylesheet.classes.p">
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis fugit quam perferendis facilis quod. Omnis
						fugit quam perferendis facilis quod.
					</p>
				</div>

				<div class="pdt-divider my-4"></div>

				<div class="pdt-total-wrap d-flex justify-content-between align-items-center">
					<h5 class="pdt-total-text" :class="stylesheet.classes.h5">Total</h5>
					<h5 class="pdt-total-amount" :class="stylesheet.classes.h5">
						<strong>$0 USD</strong>
					</h5>
				</div>
			</div>

		</div>
	</div>
</template>
  
<script>
import * as getters from '../../../../store/modules/getters'
import { mapGetters } from 'vuex'
import FbVNode from '@/js/components/base/FbVNode.vue'
import Mixins from '../PageMixins'


export default FbVNode.extend({
	name: 'CheckoutPayment',
	mixins: [Mixins],
	props: {
		showLogo: {
			type: Boolean,
			default: true,
		},
		showDiscountCode: {
			type: Boolean,
			default: true,
		},
		showGooglePay: {
			type: Boolean,
			default: false,
		},
		allowSubscription: {
			type: Boolean,
			default: true,
		},
		showUpOrDownSells: {
			type: Boolean,
			default: true,
		},
		redirectOption: {
			type: String,
			default: '',
		},
		pageId: {
			type: String,
			default: '',
		},
		redirectUrl: {
			type: String,
			default: '',
		},
		termsAndConditionsURL: {
			type: String,
			default: '',
		},
		privacyPolicyURL: {
			type: String,
			default: '',
		},
		refundURL: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			name: 'CheckoutPayment',
		}
	},
	computed: {
		...mapGetters({
			theme: getters.THEME,
			brandLogo: getters.THEME_BRAND_LOGO,
		}),
	}
})
</script>

<style lang="scss" scoped src="./index.scss">
