<template>
  <div>
    <div class="survey-menu my-3">
      <cvt-button 
        text="Configure Survey"
        @click="toggleSurvey"  
      ></cvt-button>
    </div>
  </div>
</template>

<script>

  import { mapGetters, mapActions, mapState } from 'vuex'
  import * as getters from '../../../store/modules/getters'
  import * as actions from '../../../store/modules/actions'
  export default {
    components: {
    },
    props: {
    },
    data () {
      return {
      }
    },
    computed: {
      ...mapGetters({
        selected: getters.SELECTOR_SELECTED,
        config: getters.AUTH_GET_USER_CONFIG,
        surveyOpen: getters.SURVEY_OPEN
      }),
      ...mapState('globalTheme', {
      }),
      isGridBuilder() {
        return this.config.THEME.gridBuilder || false
      }
    },
    created() {
    },
    methods: {
      ...mapActions({
        toggleSurvey: actions.SURVEY_TOGGLE
      }),
    }
  }
</script>

<style lang="scss">
  .survey-menu {
    display: flex;
    justify-content: center;
  }
</style>
