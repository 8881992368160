<template>
  <cvt-dialog
    :show="isSwapBlockVisible"
    :size="'sm'"
    :height-auto="true"
    @close="abortBlockSwap"
  >
    <template #title>
      <h5>{{ $t("editor.left_nav.block_manager.swap_block_dialog.template.h5") }}</h5>
    </template>

    <cvt-alert class="w-100" color="warning" icon="exclamation-triangle">
      <p>{{ $t("editor.left_nav.block_manager.swap_block_dialog.cvt_alert.p") }}</p>
      <span>{{ $t("editor.left_nav.block_manager.swap_block_dialog.cvt_alert.span") }}</span>
    </cvt-alert>

    <template #modalFooter>
      <div class="d-flex">
        <cvt-button
          class="mr-2"
          v-bind="cancelBtnProps"
          :text="$t(`editor.left_nav.swap_block_dialog.template.footer.cancel.btn`)"
          @click="abortBlockSwap"
        />
        <cvt-button
          v-bind="submitBtnProps"
          class="ml-2"
          :text="$t(`editor.left_nav.swap_block_dialog.template.footer.submit.btn`)"
          @click.stop="proceedBlockSwap"
        />
      </div>
    </template>
  </cvt-dialog>
</template>

<script>
import * as getters from '../../store/modules/getters';
import * as actions from '../../store/modules/actions';
import { mapGetters, mapActions, mapState } from 'vuex';
import { FRESHWORKS } from '../../../../../storybook/components/constants';

export default {
  data () {
    return { }
  },
  computed: {
    ...mapGetters({
      isSwapBlockVisible: getters.SWAP_BLOCK_VISIBLE,
    }),
    ...mapState('globalTheme', {
      orgName: ({ globalTheme }) => globalTheme.OrgName !== undefined ? globalTheme.OrgName : 'standard',
    }),
    isFreshworksOrg() {
      return this.orgName === FRESHWORKS;
    },
    cancelBtnProps(){
      if(this.isFreshworksOrg) {
        return { color: 'light'}
      } else {
        return { color: 'warning'}
      }
    },
    submitBtnProps(){
      if(this.isFreshworksOrg) {
        return {
          color: '',
          specialStyle: 'dark',
        }
      } else {
        return {
          color: 'light',
        }
      }
    },
  },
  methods: {
    ...mapActions({
      swapBlockHideDialog: actions.SWAP_BLOCK_HIDE_DIALOG,
      swapBlockSetCurrentBlockData: actions.SWAP_BLOCK_SET_CURRENT_BLOCK_DATA,
      leftSideMenuToggle: actions.LEFT_SIDE_MENU_TOGGLE,
    }),
    proceedBlockSwap(event) {
      this.swapBlockHideDialog();
      this.leftSideMenuToggle({ activate: 'sections' });
    },
    abortBlockSwap(event) {
      this.swapBlockHideDialog();
      this.swapBlockSetCurrentBlockData(null);
    },
  }
}
</script>

<style lang="scss">
</style>
