import * as _ from 'lodash'
import { relativePath } from '../services/firebase.service'
// import anime from 'animejs'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'
import { Notification } from 'element-ui'

const debouncedTarget = _.debounce(({ dispatch }, target) => {
  dispatch(actions.SELECTOR_TARGET, target)
}, 500)

const shakeNode = _.debounce(function (targets, delay = 0) {
  // anime({
  //   targets,
  //   delay,
  //   duration: 500,
  //   translateY: [0, 50, -50, 0],
  //   easing: 'easeInOutQuart'
  // }).finished.then(_ => {
  //   targets.style.transform = null
  //   return
  // })
}, 3000)

export default {
  state: {
    selected: null,
    targeted: null,
    targetRow: null,
    targetBlock: null,
    selectedRow: null,
    selectedBlock: null,
    hierarchy: [],
  },
  getters: {
    [getters.SELECTOR_SELECTED]({ selected }) {
      return selected
    },
    [getters.SELECTOR_GET_SELECTED_PROPS]({ selected }) {
      return selected ? selected.$props : {}
    },
    [getters.SELECTOR_GET_SELECTED_PROP]({ selected }, path) {
      return _.get(selected, path)
    },
    [getters.SELECTOR_TARGETED]({ targeted }) {
      return targeted
    },
    [getters.SELECTOR_SELECTED_VNODE_ID]({ selected }) {
      return selected && selected.vnodeId
    },
    [getters.SELECTOR_GET_HIERARCHY]({ hierarchy }) {
      return hierarchy
    },
    [getters.SELECTOR_GET_TARGET_ROW]({ targetRow }) {
      return targetRow
    },
    [getters.SELECTOR_GET_SELECTED_ROW]({ selectedRow }) {
      return selectedRow
    },
    [getters.SELECTOR_GET_TARGET_BLOCK]({ targetBlock }) {
      return targetBlock
    },
    [getters.SELECTOR_GET_SELECTED_BLOCK]({ selectedBlock }) {
      return selectedBlock
    },
  },
  mutations: {
    [mutations.SELECTOR_TARGET](state, target) {
      state.targeted = target
      state.targetRow = target.findParent((p) => p.name === 'Row')
      state.targetBlock = target.findParent((p) => p.name === 'Block')
    },
    [mutations.SELECTOR_IGNORE](state, target) {
      state.targeted = null
      state.targetRow = null
      state.targetBlock = null
    },
    [mutations.SELECTOR_SELECT](state, target) {
      state.selected = target
    },
    [mutations.SELECTOR_DESELECT](state, target) {
      state.selected = null
      state.selectedRow = null
      state.selectedBlock = null
      state.hierarchy = []
    },
    [mutations.SELECTOR_BUILD_HIERARCHY](state) {
      state.hierarchy = state.selected.parents()
    },
  },
  actions: {
    [actions.SELECTOR_DESELECT]({ commit }) {
      commit(mutations.SELECTOR_DESELECT)
      commit(mutations.ELEMENT_LAUNCHER_TOGGLE, false)
    },
    [actions.SELECTOR_SELECT]({ commit, dispatch, state }, target) {
      shakeNode.cancel()
      if (state.selected === target) {
        dispatch(actions.RIGHT_CLICK_MENU_RESET)
        return
      }

      dispatch(actions.SELECTOR_DESELECT)
      commit(mutations.SELECTOR_SELECT, target)

      commit('advancedPopoverMenu/attach', {
        element: target.$el,
        options: {
          placement: 'top',
          modifiers: {
            offset: {
              offset: '0, 10',
            },
          },
        },
      })

      commit(mutations.SELECTOR_BUILD_HIERARCHY)
      console.debug(
        'selected node',
        `https://console.firebase.google.com/u/0/project/convrrt-1081/database/data${relativePath(
          target.self(),
        )}`,
      )
      global.$elm = target
    },
    [actions.SELECTOR_TARGET]({ commit }, target) {
      commit(mutations.SELECTOR_TARGET, target)
      shakeNode(target.$el)
    },
    [actions.SELECTOR_DEBOUNCED_TARGET_CANCEL]() {
      debouncedTarget.cancel()
    },
    [actions.SELECTOR_DEBOUNCED_TARGET]: debouncedTarget,
    [actions.SELECTOR_UPDATE_SELECTED]({ state }, { path, value }) {
      return state.selected.self().child(path).set(value)
    },
    [actions.SELECTOR_REMOVE_SELECTED]({ state }) {
      if (state.selected.canBeDeleted()) {
        return state.selected.remove()
      }

      let message = 'This element could not be deleted'

      if (Object.prototype.hasOwnProperty.call(state.selected, 'cantDeleteMsg')) {
        message = state.selected.cantDeleteMsg()
      }

      Notification.warning({
        duration: 6000,
        message,
        position: 'bottom-right',
      })
    },
    [actions.SELECTOR_UPDATE_PROP]({ state }, { key, value }) {
      if (!key || key.length === 0) {
        throw new Error('Key is a required when updating firebase props')
      }
      return state?.selected?.updateProp(key, value)
    },
    [actions.SELECTOR_UPDATE_PARENT_PROP]({ state }, { key, value }) {
      if (!key || key.length === 0) {
        throw new Error('Key is a required when updating firebase props')
      }
      return state?.selected?.parent()?.updateProp(key, value)
    },
    [actions.SELECTOR_UPDATE_THEME_COLOR](
      { state, dispatch, getters: getter },
      { colorId, toBg },
    ) {
      if (!state?.selected?.applyColor) {
        return console.warn(
          state?.selected?.name,
          'does not have an applyColor fn',
        )
      }

      let exclude = {
        GridBoxContainer: true,
        Icon: true,
        CButton: true,
        Divider: true,
      }

      if (state.selected.name === 'Draft') {
        state.selected?.format?.('color', colorId)
      } else {
        if (
          _.get(getter[getters.AUTH_GET_USER_CONFIG], 'THEME.gridBuilder') &&
          !exclude[state.selected.name] &&
          state.selected.parent().name === 'GridItemWrapper'
        ) {
          state.selected.parent().applyColor(colorId)
        } else {
          if (toBg && state.selected.parent().name === 'GridItemWrapper') {
            state.selected.parent().applyColor(colorId)
          } else {
            state.selected.applyColor(colorId)
          }
        }
      }
    },
  },
}
