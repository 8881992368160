
<div
  class="
    cvt-editor-grid-item-wrapper
    grid-stack-draggable-item grid-stack-item
    ui-draggable
    item-hover-border
  "
  data-identity="GridItemWrapper"
  :data-gs-x="dataX"
  :data-gs-y="dataY"
  :data-gs-width="dataWidth"
  :data-gs-height="dataHeight"
  :data-vnode-id="vnodeId"
  :gs-x="dataX"
  :gs-y="dataY"
  :gs-w="dataWidth"
  :gs-h="dataHeight"
  :gs-locked="false"
  :data-gs-locked="false"
  :data-is-child-trageted="isChildTrageted"
  :data-is-child-selected="isChildSelected"
  :data-child-name="childComponent.name"
  @dblclick.stop=""
  @mouseenter="gridItemWrapperMouseEnter"
  @mouseover="targetChildElement"
>
  <div
    :class="contentWrapClasses"
    data-identity="GridItemWrapperContentWrapper"
    @dblclick="toggleOverlay(false)"
  >
    <div class="grid-item-actions">
      <div class="ui-draggable-handle move-icon">
        <cvt-icon icon="arrows-alt"/>
      </div>
    </div>

    <div
      v-if="isNonDraggableContent"
      class="editable-element-overlay"
    >
      <cvt-button
        class="editable-element-edit-button"
        color="light"
        size="md"
        :mode="'LIGHT'"
        :text="editBtnText"
        :icon="'pencil'"
        :outlined="true"
        @click="toggleOverlay(false)"
      />
    </div>

    <div
      ref="content"
      class="d-flex w-100 grid-stack-item-element-content"
      :class="contentClasses"
      data-identity="GridItemWrapperContent"
    >
      <slot />
    </div>

  </div>
  <action-buttons
    v-if="selectedChild"
    @duplicate="duplicateElement"
    @remove="removeElement"
  />
</div>
