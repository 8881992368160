<template>
  <div :class="stylesheet.classes.presentation">
    <div :class="stylesheet.classes.presentationBody">
      <div v-if="template.enableIcon" class="card-icon">
        <div v-if="useIconAsImage">
          <div
            class="img-fluid"
            :style="imgStylesGB"
          />
        </div>
        <div v-else :class="iconWrapperClasses">
          <icon-template
            :set="iconProperties.set"
            :selector="iconProperties.selector"
            :class="iconClasses"
          />
        </div>
      </div>
      <div :class="stylesheet.classes.presentationContent">
        <!-- eslint-disable vue/no-v-html -->
        <div v-if="template.enableHeadline" class="card-headline" v-html="titleHTML">

        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div v-if="template.enableText" class="card-text" v-html="descriptionHTML">
        </div>

        <div  v-if="template.enableButton">
          <c-button v-bind="buttonProps" :fb-node="buttonData"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconTemplate from "@/js/components/editor/nodes/icon/IconTemplate.vue";
import CardTemplate from "./CardTemplate.vue";
import CButton from "@/js/components/v3/editor/nodes/CButton.vue";

export default CardTemplate.extend({
  name: "NarrowCardTemplate",
  components: { IconTemplate, CButton },
  computed: {
    // iconWrapperStyle() {
    //   return {
    //     width: '100%',
    //     padding: `0 ${0.2*this.iconProperties.size}rem`
    //     // justifyContent: 'start', //this.iconProperties.alignment,
    //     // alignItems: 'self-start'
    //   }
    // },
    otherStyles () {
      return {
        presentation : {
          display: 'flex',
          minWidth: 0,
          height: '100%',
          wordWrap: 'break-word',
          '@global': {
            'h1, h2, h3, h4, h5, h6': {
              margin: 'unset !important'
            },
            p: {
              marginBottom: 0
            }
          },
        },
        presentationBody: {
          display: 'flex',
          flex: '1 1 auto',
          padding: this.layoutView === 'list'? "0.5rem": '1rem',
          gap: `${this.template.gap}px`,
        },
        presentationContent: {
          display: 'flex',
          gap: `${this.template.gap}px`,
          justifyContent: 'center',
          alignItems: 'self-start',
          flexDirection: 'column'
        }
      }
    },
  }
});
</script>

