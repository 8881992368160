
<div>
  <h1>Progress</h1>
  <h4>Large</h4>
  <div
    v-for="(color, index) in colors"
    :key="index"
    class="mb-2"
  >
    {{ index }}
    <cvt-progress
      size="lg"
      :bg-color="color"
      mode="LIGHT"
      value-label=""
      label-pos="top"
      :max-value="100"
      :value-now="25"
    />
  </div>
  <h4 class="mb-2">
    Small
  </h4>
  <div
    v-for="(color, index) in colors"
    :key="smKey(index)"
    class="mb-2"
  >
    {{ index }}
    <cvt-progress
      size="sm"
      :bg-color="color"
      mode="LIGHT"
      value-label=""
      label-pos="top"
      :max-value="100"
      :value-now="65"
    />
  </div>
</div>
