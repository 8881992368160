
<div class="integration-container">
  <div class="integration-heading-wrapper">
    <h1 class="integration-heading-label">Integrations</h1>
  </div>
  <div class="integration-body">
    <h1 class="integration-name">Webhook and form integration</h1>
    <p class="integration-caption">This is a one-time setup and should take no longer than 15 minutes of your time.</p>
    <div class="integration-btn-wrap">
      <cvt-button
        text='GET CSV OF ALL FORM DATA'
        color='light'
      >
      </cvt-button>
      <cvt-button
        text='WEBHOOK INTEGRATION'
        color='light'
      >
      </cvt-button>
    </div>
  </div>
</div>
