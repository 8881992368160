import { Notification } from 'element-ui'
import { PopUpMarketplace, V1PopupSite } from '../../services/api'
import * as qs from 'qs'
import * as actions from '../actions'
import * as getters from '../getters'
import * as mutations from '../mutations'

const v1PopupSites = new V1PopupSite()
const popupMarketplace = new PopUpMarketplace()

export default {
  state: {
    popupsTemplates: [],
  },
  getters: {
    [getters.POPUP_MARKETPLACE_TEMPLATES]({ popupsTemplates }) {
      return popupsTemplates
    },
  },
  mutations: {
    [mutations.POPUP_MARKETPLACE_SET_TEMPLATES](state, data) {
      // It might contain duplicate templates
      let allTemplates = state.popupsTemplates.concat(data)

      // Making [TemplateId, TempalteObject] format
      // Grouping is made on 'id' attribute of template, it can be any unique key
      let allTemplatesFormated = allTemplates.map((template) => [
        template['id'],
        template,
      ])

      let allTemplatesMapValues = new Map(allTemplatesFormated).values() // 'allTemplatesMapValues' contains, only unique entries
      state.popupsTemplates = [...allTemplatesMapValues]
    },
  },
  actions: {
    async [actions.POPUP_MARKETPLACE_LOAD_TEMPLATES]({ commit, dispatch, rootState }, query) {
      const { data } = await popupMarketplace.popupTemplates({
        ...query,
        categories: rootState.route.query.categories,
      })
      commit(mutations.POPUP_MARKETPLACE_SET_TEMPLATES, data)
      return data
    },
    async [actions.POPUP_MARKETPLACE_CREATE_SITE_FROM_TEMPLATE]({ dispatch, rootState }, popupTemplateId) {

      await dispatch('loader/start', 'loader.scratch.message')

      const { foreignData } = qs.parse(window.location.search.slice(1)) || {}
      const req = popupTemplateId ? popupMarketplace.clonePopupFromMarketplaceTemplate(popupTemplateId, foreignData) : v1PopupSites.create(foreignData)

      const response = await req.catch((err) => {

        console.error('failed to create site', err)

        dispatch('loader/stop')
        dispatch(actions.PAGE_PREVIEW_TOGGLE, false)

        const { response = {} } = err
        const { data } = response

        if (data && data.errors) {
          for (const e of data.errors) {
            Notification({
              title: e.code || 'woops',
              message: e.message,
              type: 'error',
              position: 'bottom-right',
            })
          }
        }

        throw err
      })

      return response
    },
  },
}
