import { styled, VueEmotion } from '@egoist/vue-emotion'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import * as getters from '../../../../builder/src/js/store/modules/getters';
import Vue from '../../../lib/utils/vue'
import CvtButton from "../../molecules/Button"
import CvtSelect from '../../molecules/Select'

Vue.use(VueEmotion)


let FontFamily,
Spacing,
Colors,
Border,
textFontWeight,
FontSize

const getNavHeader = () => styled('div')`
  font-family: ${FontFamily.DEFAULT};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const getNavLeftCaption = () => styled('div')`
  display: flex;
  align-items: center;
`

const getNavOrgName = () => styled('p')`
  margin: 0 ${Spacing.SPACING_18 + Spacing.SPACING_2}px;
  color: ${Colors.BASIC[500]};
  ${textFontWeight('REGULAR')}
  font-size: ${FontSize.FONT_SIZE_20}px;
  span {
    ${textFontWeight('BOLD')}
  }
`

const getMarketplaceWrapper = () => styled('div')``

const getMarketplaceBtn = () => styled(CvtButton)`
  background-color: #5D62AB !important;
`

export const CvtEntDashboardTopNav = {
  name: 'CvtEntDashboardTopNav',
  data () {
    return {
      activeId: this.initialActiveId,
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
    navLogoSrc: {
      type: String,
      default: ''
    },
    orgName: {
      type: String,
      default: 'Acme Corp'
    }
  },
  computed: {
    ...mapState ('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
    }),

    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
    })
  },
  methods: {
    goToMarketplace() {
      let route = this.$router.resolve({path: '/choose-a-template'});
      window.open(route.href, '_blank');
    }
  },
  created() {
    FontFamily = this.FontFamily
    Border = this.Border
    Spacing = this.Spacing
    Colors = this.Colors
    textFontWeight = this.textFontWeight
    FontSize = this.FontSize
  },
  render: function (h) {

    const NavHeader = getNavHeader()
    const NavLeftCaption = getNavLeftCaption()
    const NavOrgName = getNavOrgName()
    const MarketplaceWrapper = getMarketplaceWrapper()
    const MarketplaceBtn = getMarketplaceBtn()

    return (
      <NavHeader>
        <NavLeftCaption>
          <img src={this.navLogoSrc} style={{height: '64px'}} alt='navlogo' />
          <NavOrgName>Organization: <span>{this.orgName}</span></NavOrgName>
          <CvtSelect
            value={'Select override'}
            multiple={true}
            selectDisabled={false}
            floatingLabel={false}
            onInput={(e) => {}}
          >
            <option value={'Select override'}>
              Select override
            </option>
          </CvtSelect>
        </NavLeftCaption>
        <MarketplaceWrapper>
          <MarketplaceBtn
            mode="LIGHT"
            color=""
            icon='external-link'
            text='Go to Marketplace'
            prepend={false}
            onClick={this.goToMarketplace}
          >
          </MarketplaceBtn>
        </MarketplaceWrapper>
      </NavHeader>
    )
  }
}