
<cvt-image-gallery
  ref="cvtImageGallery"
  :loading="searching"
  :images="results"
  :categories="tags"
  :active-category="activeTag"
  @filter-gallery="(tag) => searchByTag(tag)"
  @insert-image="(img) => useImage(img)"
  @search-input="(value) => onSearchUpdate(value)"
  @search-change="(value) => onSearchUpdate(value)"
/>
