
<div class="form-group">
  <cvt-select
    :value="fontFamily"
    mode="dropdown"
    :label="$t('setup.font.family')"
    @input="(value) => updateFontFamily(value)"
  >
    <option v-if="placeholder.trim()" value="" disabled selected>
      {{placeholder}}
    </option>
    <option
      v-for="font in availableFonts"
      :key="font.kababName()"
      :value="font.kababName()"
    >
      <span :style="font.toStyle('18px')" v-text="font.familyName" />
    </option>
  </cvt-select>
</div>
