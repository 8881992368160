
<div identity="c-card-options-wrap">

  <cvt-collapse
    :accordion="true"
    :theme="mode === 'LIGHT' ? 'light' : 'dark'"
    :prepend-chevron="prependChevron"
  >
    <cvt-collapse-item
      v-if="showOption('desktop')"
      name="build"
      :title="$t('elements.property.configurations.label')"
      :initial-active="true"
      :special-style="isSpecialStyle"
    >
      <div class="row mb-3">
        <!-- View Controls -->
        <div class="col-12">
          <cvt-field-label :mode="mode" :label="$t('elements.property.view.label')" />
          <cvt-radio-group
            orientation="horizontal"
            :theme="mode"
            :initial-value="layoutView"
          >
            <el-tooltip
              :content="`${views[0]} view`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="views[0]"
                @radioClick="() => layoutView = views[0]"
              >
                <cvt-icon icon="cvt-icon-flex-direction-column" style="font-size: 18px;" />
              </cvt-radio-button>
            </el-tooltip>

            <el-tooltip
              :content="`${views[1]} view`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="views[1]"
                @radioClick="() => layoutView = views[1]"
              >
                <cvt-icon icon="cvt-icon-flex-direction-row" style="font-size: 18px;" />
              </cvt-radio-button>
            </el-tooltip>
          </cvt-radio-group>
          
        </div>
      </div>

      <div class="divider mt-2" />
      <div class="row mb-3">
        <!-- Container Layout Properties -->
        <div class="col-12">
          <cvt-field-label :mode="mode" :label="$t('elements.property.container.layout.label')" />
          <cvt-radio-group
            orientation="horizontal"
            :theme="mode"
            :initial-value="layout"
          >
            <el-tooltip
              :content="`${layouts[0]} layout`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="layouts[0]"
                @radioClick="() => layout = layouts[0]"
              >
                <cvt-icon icon="fa fa-align-left" />
              </cvt-radio-button>
            </el-tooltip>

            <el-tooltip
              :content="`${layouts[1]} layout`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="layouts[1]"
                @radioClick="() => layout = layouts[1]"
              >
                <cvt-icon icon="fa fa-align-center" />
              </cvt-radio-button>
            </el-tooltip>

            <el-tooltip
              :content="`${layouts[2]} layout`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="layouts[2]"
                @radioClick="() => layout = layouts[2]"
              >
                <cvt-icon icon="fa fa-list-ul" />
              </cvt-radio-button>
            </el-tooltip>
          </cvt-radio-group>
        </div>
      </div>
      <div v-if="isCardView" class="divider mt-2" />

      <div v-if="isCardView" class="row mb-3">
        <!-- Card Wrap Properties -->
        <div class="col-12">
          <cvt-field-label :mode="mode" :label="$t('elements.property.card.wrap.label')" />
          <cvt-radio-group
            orientation="horizontal"
            :theme="mode"
            :initial-value="layoutWrap"
          >
            <el-tooltip
              :content="`${layoutWrapOption[0]}`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="layoutWrapOption[0]"
                @radioClick="() => layoutWrap = layoutWrapOption[0]"
              >
                <cvt-icon icon="cvt-icon-column-flex-wrap-nowrap" style="font-size: 18px;" />
              </cvt-radio-button>
            </el-tooltip>

            <el-tooltip
              :content="`${layoutWrapOption[1]}`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="layoutWrapOption[1]"
                @radioClick="() => layoutWrap = layoutWrapOption[1]"
              >
                <cvt-icon icon="cvt-icon-row-flex-wrap-wrap" style="font-size: 18px;"  />
              </cvt-radio-button>
            </el-tooltip>
          </cvt-radio-group>
        </div>
      </div>
      <div v-if="isCardView" class="divider mt-2" />
      <div v-if="isCardView" class="row mb-3">
        <!-- Card Spacing Properties -->
        <div class="col-12">
          <cvt-field-label :mode="mode" :label="$t('elements.property.card.spacing.label')" />
          <cvt-radio-group
            orientation="horizontal"
            :theme="mode"
            :initial-value="layoutSpacing"
          >
            <el-tooltip
              :content="`${layoutSpacingOption[0]}`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="layoutSpacingOption[0]"
                @radioClick="() => layoutSpacing = layoutSpacingOption[0]"
              >
                <cvt-icon icon="cvt-icon-column-flex-wrap-nowrap" style="font-size: 18px;" />
              </cvt-radio-button>
            </el-tooltip>

            <el-tooltip
              :content="`${layoutSpacingOption[1]}`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="layoutSpacingOption[1]"
                @radioClick="() => layoutSpacing = layoutSpacingOption[1]"
              >
                <cvt-icon icon="cvt-icon-column-align-items-center" style="font-size: 18px;"  />
              </cvt-radio-button>
            </el-tooltip>

            <el-tooltip
              :content="`${layoutSpacingOption[2]}`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="layoutSpacingOption[2]"
                @radioClick="() => layoutSpacing = layoutSpacingOption[2]"
              >
                <cvt-icon icon="cvt-icon-column-align-items-flex-start" style="font-size: 18px;"  />
              </cvt-radio-button>
            </el-tooltip>

            <el-tooltip
              :content="`${layoutSpacingOption[3]}`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="layoutSpacingOption[3]"
                @radioClick="() => layoutSpacing = layoutSpacingOption[3]"
              >
                <cvt-icon icon="cvt-icon-column-align-items-flex-end" style="font-size: 18px;"  />
              </cvt-radio-button>
            </el-tooltip>

            <el-tooltip
              :content="`${layoutSpacingOption[4]}`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="layoutSpacingOption[4]"
                @radioClick="() => layoutSpacing = layoutSpacingOption[4]"
              >
                <cvt-icon icon="cvt-icon-column-align-content-flex-start" style="font-size: 18px;"  />
              </cvt-radio-button>
            </el-tooltip>
          </cvt-radio-group>

          <cvt-radio-group
            orientation="horizontal"
            :theme="mode"
            :initial-value="layoutSpacing"
          >
            <el-tooltip
              :content="`${layoutSpacingOption[5]}`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="layoutSpacingOption[5]"
                @radioClick="() => layoutSpacing = layoutSpacingOption[5]"
              >
                <cvt-icon icon="cvt-icon-column-align-content-flex-end" style="font-size: 18px;" />
              </cvt-radio-button>
            </el-tooltip>

            <el-tooltip
              :content="`${layoutSpacingOption[6]}`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="layoutSpacingOption[6]"
                @radioClick="() => layoutSpacing = layoutSpacingOption[6]"
              >
                <cvt-icon icon="cvt-icon-column-align-content-space-around" style="font-size: 18px;"  />
              </cvt-radio-button>
            </el-tooltip>

            <el-tooltip
              :content="`${layoutSpacingOption[7]}`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="layoutSpacingOption[7]"
                @radioClick="() => layoutSpacing = layoutSpacingOption[7]"
              >
                <cvt-icon icon="cvt-icon-column-align-content-space-between" style="font-size: 18px;"  />
              </cvt-radio-button>
            </el-tooltip>

            <el-tooltip
              :content="`${layoutSpacingOption[8]}`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="layoutSpacingOption[8]"
                @radioClick="() => layoutSpacing = layoutSpacingOption[8]"
              >
                <cvt-icon icon="cvt-icon-row-justify-content-space-evenly" style="font-size: 18px;"  />
              </cvt-radio-button>
            </el-tooltip>

            <el-tooltip
              :content="`${layoutSpacingOption[9]}`"
              placement="top"
            >
              <cvt-radio-button
                size="md"
                :value="layoutSpacingOption[9]"
                @radioClick="() => layoutSpacing = layoutSpacingOption[9]"
              >
                <cvt-icon icon="cvt-icon-column-align-items-stretch" style="font-size: 18px;"  />
              </cvt-radio-button>
            </el-tooltip>
          </cvt-radio-group>
        </div>
      </div>
      <div class="divider mt-2" />

      <div class="row">
        <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
          {{ $t('elements.property.gap.between.cards.label') }}
        </div>
        <div class="col-12">
          <cvt-slider
            id="slider-default"
            :tooltips-enabled="true"
            :step-marks-enabled="true"
            class="slider"
            :min="1"
            :max="100"
            :value="layoutGap"
            :step="1"
            @change="(value) => layoutGap = value"
          />
        </div>
      </div>
    </cvt-collapse-item>

    <cvt-collapse-item :title="$t('elements.properties.global.style.label')" name="style">
      <c-card-global-style-option :mode="mode" />
    </cvt-collapse-item>

    <cvt-collapse-item
      name="manage_list"
      :title="$t('elements.property.manage.cards.label')"
      :initial-active="false"
      :special-style="isSpecialStyle"
    >
      <div class="row">
        <div class="col">
          <div class="form-fields">
            <div class="row">
              <div class="col-md-12">
                <draggable-list
                  :list="cardItems"
                  @change="updateCardFields"
                  @item-clicked="selectField"
                >
                  <template slot="list-item-template" slot-scope="props">
                    <cvt-icon
                      id="drag-handle"
                      :icon="iconConfig.leftSidebar.layout.drag || 'copy'"
                    />
                    <span :class="{ 'blue-text': false }">
                      {{ fmtFieldName(props.item, props.index) }}
                    </span>

                    <div
                      class="draggable-actions"
                      style="margin-left: auto"
                    >
                      <delete-button
                        :marked="markedForDeletion(props.index)"
                        @mark="markFieldForDeletion(props.index)"
                        @deleteForever="confirmFieldDeletion"
                      />
                    </div>
                  </template>
                </draggable-list>

                <cvt-button
                  :outlined="false"
                  :full-width="true"
                  :text="$t('elements.property.add.card.label')"
                  :color="'LIGHT'"
                  @click="() => addCardField()"
                ></cvt-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </cvt-collapse-item>
  </cvt-collapse>
</div>
