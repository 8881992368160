import * as _ from 'lodash'

export default {
  name: 'PopupThumbnailUrl',
  props: {
    label: {
      type: String,
      default: ''
    },
    thumbnailUrl: {
      type: String,
      default: ''
    },
  },
  data () {
    return { }
  },
  methods: {
    popupSiteThumbnailUrlChange(event: any) {
      this.$emit('popupSiteThumbnailUrlChange', event)
    }
  }
}