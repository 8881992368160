import {PageEngineCommands} from "./PageEngineCommands";
import {CreateWidgetFromFirebaseNode} from "../components/v3/lib/vue-dom-obj";
import {FBVNodeComponent} from "../../../types";

/**
 * Handles Section Add Actions
 */
export class DuplicateSectionCommand extends PageEngineCommands {
    private _widget: object;
    private _idx?: number;
    private _sectionNode?: object;

    constructor(idx: number, sectionNode: object) {
        super();
        this._widget = CreateWidgetFromFirebaseNode(sectionNode, true)
        this._idx = idx
        this._sectionNode = sectionNode
    }

    get$emitData() {
        return  {name: 'DuplicateSectionCommand', forceBuild: false}
    }

    get hasChanges () {
        return true
    }

    async execute() {
        this.firebaseRef?.splice(this._idx, 0, this._widget).then(() => {
            this.$nextTick!(() => {
                let node = document.getElementById(this._sectionNode.data.props.vnodeId) as HTMLElement
                if(node){
                    node.scrollIntoView({ behavior: 'smooth'})
                } 
            })
        })
    }

    async unExecute() {
        this.firebaseRef?.splice(this._idx, 1)
    }
} 