<!-- Infusionsoft header -->
<template>
  <div>
    <cvt-top-nav-bar
      mode="LIGHT"
      variant="embedded"
      :class="'cvt-top-nav-bar'"
      :route="currentRouteName"
      :fixed="true"
      :template-name="siteName"
      :disable-exit="disableExit"
      :light-logo="logo"
      :dark-logo="logo"
      :revision-loading="saving || loading"
      :undo-disabled="disabled(editorCtrls[0].disabled)"
      :redo-disabled="disabled(editorCtrls[1].disabled)"
      :type="orgName"
      :disable-publish="disablePublish"
      @home="backStep"
      @backToCrm="(e) => goToCrmBuilder(e)"
      @exit-preview="goToCampaignBuilder"
      @back="backStep"
      @one-step-back="goOneStepBack"
      @switch-preview="switchPreview"
      @undo="execute(editorCtrls[0])"
      @redo="execute(editorCtrls[1])"
      @version-history="openRevisionManager"
      @version-history-save="openRevisionSaveManager"
      @help="help"
      @launch="(e) => launchStep(e)"
      @site-preview="goToSitePreview()"
      @use-template="
        {
        }
      "
      @done="
        {
        }
      "
      @top-settings="() => (siteSettings ? hideSettings() : showSettings())"
      @top-toggle="(panel) => open_menu(panel)"
      @exit="goToCampaignBuilder"
      @toggle-survey="toggleSurvey()"
      @toggle-popup="togglePopups()"
      @checkout-to-pages="checkoutToPages"
    />
  </div>
</template>

<script>
import color from 'color'
import cssx from 'cssx'
import { Notification } from 'element-ui'
import * as _ from 'lodash'
import v from 'validator'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as actions from '../../store/modules/actions'
import * as getters from '../../store/modules/getters'
import * as mutations from '../../store/modules/mutations'
import { cvtRouteNodes, cvtRoutes, routePaths } from '@/js/utils/routes'
import { safeLocalStorage } from '@/js/lib/localStorage'
const stylesheet = cssx('header-overrides')
const DESKTOP = {
  label: 'Desktop',
  icon: 'desktop_mac',
  action: 'enableDesktopMode',
  disabled: 'cantShowPreview',
}
const MOBILE = {
  label: 'Mobile',
  icon: 'phone_iphone',
  action: 'enablePhoneMode',
  disabled: 'cantShowPreview',
}
const previewCtrls = [DESKTOP, MOBILE]

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editorCtrls: [
        { label: 'undo', icon: 'undo', action: 'undo', disabled: 'cantUndo' },
        {
          label: 'redo',
          icon: 'redo',
          action: 'redo',
          iconRight: true,
          disabled: 'cantRedo',
        },
      ],
      selectedPreviewMode: DESKTOP,
      hasRendered: true, // variable is being used in test file.
      previewCtrls,
    }
  },
  computed: {
    ...mapGetters({
      site: getters.SITE_GET_DATA,
      step: getters.STEPS_CURRENT_STEP,
      readyState: getters.SITE_READY,
      user: getters.AUTH_GET_USER,
      config: getters.AUTH_GET_USER_CONFIG,
      checkout: getters.SITE_CHECKOUT,
      theme: getters.AUTH_GET_USER_BUILDER_THEME,
      canUndo: getters.HISTORY_CAN_UNDO,
      canRedo: getters.HISTORY_CAN_REDO,
      intercomVisible: getters.INTERCOM_VISIBLE,
      viewmode: getters.VIEW_MODE,
      siteSettings: getters.SITE_SETTINGS,
      pagesSettings: getters.LEFT_SIDE_MENU_OPEN,
    }),
    ...mapState('sitePreferences', {
      termsOfServiceURL: ({ preferences }) =>
        _.get(preferences, 'termsOfServiceURL', ''),
      cookiePolicyURL: ({ preferences }) =>
        _.get(preferences, 'cookiePolicyURL', ''),
      privacyPolicyURL: ({ preferences }) =>
        _.get(preferences, 'privacyPolicyURL', ''),
    }),
    ...mapState('loader', {
      loaderVisible: 'visible',
    }),
    ...mapState('route', {
      query: ({ query }) => query,
    }),
    ...mapState('globalTheme', {
      orgName: ({ globalTheme }) => globalTheme.OrgName,
    }),
    ...mapGetters('targetmode', {
      targetMode: 'layoutMode',
      targetModeText: 'message',
    }),
    ...mapState('revisions', ['saving', 'loading']),
    currentRouteName() {
      const {
        editorDesignPage,
        editor,
        editorLaunch,
        launch,
        sitePreview,
        chooseATemplatePreview,
        preview,
        marketplace,
        enterpriseIntegrateBuilder,
        aboutLandingPage,
        selectIndustry,
        landingPageGoal,
        genAIIntent,
        genAISelectTemplate,
      } = cvtRouteNodes

      if (this.$route.name.includes(enterpriseIntegrateBuilder)) {
        return routePaths.integrateBuilder
      } else {
        switch (this.$route.name) {
          case editorDesignPage:
            return editor
          case editorLaunch:
            return launch
          case sitePreview:
            return sitePreview
          case chooseATemplatePreview:
            return preview
          case cvtRoutes.checkoutPageDesign.name:
            return 'checkout'
          case aboutLandingPage:
            return aboutLandingPage
          case selectIndustry:
            return selectIndustry
          case landingPageGoal:
            return landingPageGoal
          case genAIIntent:
            return genAIIntent
          case genAISelectTemplate:
            return genAISelectTemplate
          default:
            return marketplace
        }
      }
    },

    headerTitle() {
      return (
        this.query.headerTitle ||
        this.theme.headerTitle ||
        this.theme.backToDashText
      )
    },
    navColor() {
      if (this.theme.color) {
        const colorPrefix = [3, 6].includes(this.theme.color.length) ? '#' : ''
        return color(`${colorPrefix}${this.theme.color}`)
      }
      return ''
    },
    logo() {
      return this.navColor && this.navColor.dark()
        ? this.theme.logoAlt
        : this.theme.logo
    },
    helpBtnMsg() {
      return this.intercomVisible ? 'Close Help' : 'Help'
    },
    helpBtnClass() {
      return {
        'btn-editor-ctrl': true,
        'btn-editor-ctrl--danger': this.intercomVisible,
      }
    },
    siteName() {
      return _.get(this, 'site.name') || this.title
    },
    ready: {
      get() {
        return this.readyState
      },
      set(value) {
        return this.toggleReady(value)
      },
    },
    pageEngineVisible() {
      const { editorDesignPage, editorThankyouPage } = cvtRouteNodes
      return (
        [editorDesignPage, editorThankyouPage].includes(this.$route.name) &&
        this.loaderVisible
      )
    },
    cantUndo() {
      return this.canUndo === false
    },
    cantRedo() {
      return this.canRedo === false
    },
    cantShowPreview() {
      return false
    },
    disableExit() {
      const has_site_redirect_url = safeLocalStorage.getItem('SITE_REDIRECT_URL') || false
      if (has_site_redirect_url !== false) {
        return false
      }
      return (
        this.step && this.step.id === 'launch' && this.step.state !== 'complete'
      )
    },
    togglePopups() {
      this.togglePopup()
      return this.deselectItem()
    },
    disablePublish() {
      return this.config.HIDE_PUBLISH_ACCESS
    },
  },
  watch: {
    viewmode: {
      immediate: true,
      handler(newVal) {
        this.selectedPreviewMode = newVal === 'desktop' ? DESKTOP : MOBILE
      },
    },
  },
  created() {
    if (this.hasRendered) {
      const linkElement = document.createElement('link')
      const head = document.querySelector('head')
      const title = document.querySelector('title')
      title.textContent = this.query.headerTitle || this.theme.pageTitle
      linkElement.rel = 'icon'
      linkElement.href = `${this.theme.favicon}`
      head.append(linkElement)
      if (!this.theme.color) {
        return
      }
      if (this.config.CUSTOM_BUILDER_STYLES) {
        linkElement.rel = 'stylesheet'
        linkElement.href = `${this.config.CUSTOM_BUILDER_STYLES}`
        head.append(linkElement)
      }
      stylesheet.add({
        '.main-nav': {
          'background-color': this.navColor.hex(),
        },
        '.main-nav .title-breadcrumbs .breadcrumb': {
          color:
            this.theme.mainNavBreadcrumbFontColor ||
            (this.navColor.dark() ? '#ffffff!important' : undefined),
        },
        'body > div > div > nav.main-nav > div > div > img': {
          height: this.theme.logoHeight,
        },
      })
    }
    // Disabled for now - until we decide to have a dynamic grid size defined by users
    // if (this.config.ELEMENT_SIZE) {
    //   this.setElementSize(this.config.ELEMENT_SIZE)
    // }
  },
  methods: {
    ...mapActions({
      toggleReady: actions.SITE_TOGGLE_READY_STATE,
      toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
      togglePagePreview: actions.PAGE_PREVIEW_TOGGLE,
      applyHistory: actions.HISTORY_APPLY,
      setViewMode: actions.CHANGE_VIEW_MODE,
      goToCampaignBuilder: actions.SITE_CAMPAIGN_REDIRECT,
      goToCrmBuilder: actions.SITE_CRM_REDIRECT,
      toggleIntercom: actions.INTERCOM_TOGGLE,
      back: actions.STEPS_BACK,
      next: actions.STEPS_NEXT,
      leftSideMenuToggle: actions.LEFT_SIDE_MENU_TOGGLE,
      toggleSurvey: actions.SURVEY_TOGGLE,
      togglePopup: actions.POPUP_BUILDER_TOGGLE,
      deselect: actions.SELECTOR_DESELECT,
    }),
    ...mapActions('globalTheme', {
      //   setElementSize: 'setElementSize',
    }),
    ...mapMutations({
      deselectItem: mutations.SELECTOR_DESELECT,
    }),
    exitAction () {
      this.goToCampaignBuilder()
    },
    launchStep(e) {
      if (e === 'save') {
        Notification.success({
          duration: 6000,
          message: `${this.$t('elements.property.revision.manger.success.saved.message')}`,
          position: 'bottom-right',
        })
      } else {
        if (!this.validateBeforeLaunch()) {
          return false
        }
        this.next({
          router: this.$router,
          route: this.$route,
        })
      }
    },
    goToSitePreview() {
      const { siteId, pageId } = this.$route.params

      this.$router.push({
        name: cvtRouteNodes.sitePreview,
        params: {
          siteId: siteId,
          pageId: pageId,
        },
      })
    },
    goOneStepBack() {
      this.$router.back()
    },
    backStep() {
      this.back({
        router: this.$router,
        route: this.$route,
      })
    },
    ...mapMutations({
      setSettingsActiveTab: mutations.SITE_TRIGGER_TAB,
      showSiteSettings: mutations.SHOW_SITE_SETTINGS,
      setSettingsMessage: mutations.SITE_SET_SETTINGS_MESSAGE,
      showSettings: mutations.SHOW_SITE_SETTINGS,
      hideSettings: mutations.HIDE_SITE_SETTINGS,
    }),
    ...mapMutations('targetmode', {
      setTargetMode: 'toggleLayoutMode',
    }),
    ...mapMutations('revisions', {
      openRevisionManager: 'open',
      openRevisionSaveManager: 'openSave',
    }),
    ...mapActions('loader', {
      startLoader: 'start',
      stopLoader: 'stop',
    }),
    help() {
      const { type: mode, value } = this.config.HELP_BUTTON
      switch (mode) {
        case 'intercom':
          return this.toggleIntercom()
        case 'external_link':
          return window.open(value, '_blank')
        default:
          alert('Help button config has not been set.')
      }
    },
    openPagePreview() {
      this.togglePagePreview(true)
    },
    enableDesktopMode() {
      // this.selectedPreviewMode = DESKTOP
      this.setViewMode('desktop')
    },
    enablePhoneMode() {
      // this.selectedPreviewMode = MOBILE
      this.setViewMode('phone')
    },
    execute(ctrl) {
      this[ctrl.action]()
    },
    openLayout() {
      this.toggleLeftSideMenu({
        activate: 'layout',
      })
    },
    addElements() {
      this.toggleLeftSideMenu({
        activate: 'element-launcher',
      })
    },
    undo() {
      this.applyHistory('undo')
    },
    redo() {
      this.applyHistory('redo')
    },
    disabled(prop) {
      return this[prop]
    },
    switchPreview(currentSelected) {
      this.startLoader(`Switching to ${currentSelected} view`).then(() => {
        setTimeout(() => {
          if (currentSelected === 'desktop') {
            this.enableDesktopMode()
          } else {
            this.enablePhoneMode()
          }
        }, 500)
        this.$nextTick(async () => {
          // disabled Loading Page
          setTimeout(() => {
            this.stopLoader()
          }, 4000)
        })
      })
    },
    validateBeforeLaunch() {
      let pass = true

      if (this.config.LEGAL_SYSTEM_ENABLED) {
        if (!this.isValidURL(this.privacyPolicyURL)) {
          pass = false
          this.setSettingsActiveTab('legal')
          this.setSettingsMessage(
            'Cannot publish until the privacy policy URL is set',
          )
        }

        if (pass && !this.isValidURL(this.cookiePolicyURL)) {
          pass = false
          this.setSettingsActiveTab('legal')
          this.setSettingsMessage(
            'Cannot publish until the cookie policy URL is set',
          )
        }

        if (pass && !this.isValidURL(this.termsOfServiceURL)) {
          pass = false
          this.setSettingsActiveTab('legal')
          this.setSettingsMessage(
            'Cannot publish until the terms of service URL is set',
          )
        }
      }

      if (
        pass &&
        this.config.ECOMMERCE &&
        this.checkout.successAction === 'link'
      ) {
        if (!this.isValidURL(this.checkout.successURL)) {
          pass = false
          this.setSettingsActiveTab('checkout')
          this.setSettingsMessage(
            'Cannot publish until checkout success URL is set',
          )
        }
      }

      if (!pass) {
        this.showSiteSettings()
      }

      return pass
    },
    isValidURL(url = '') {
      return v.isURL(url, {
        protocols: ['http', 'https'],
      })
    },
    open_menu(menu) {
      if (this.pagesSettings) {
        this.leftSideMenuToggle({
          open: false,
        })
      }
      this.leftSideMenuToggle({
        activate: menu,
      })
    },
    checkoutToPages() {
      this.deselect()
      const { siteId } = this.$route.params
      this.$router.push({
        name: cvtRoutes.checkoutPageDesign.name,
        params: {
          siteId: siteId,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

.autosave {
  color: $off-gray;
}

.main-nav {
  height: 48px;
  background-color: $top-nav-bg;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;

  img {
    height: 25px;
    cursor: pointer;
    margin: 0 11px 2px 0;
  }

  &__container {
    flex-grow: 1;
    // margin: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .menu {
    display: flex;
    align-items: center;

    .el-dropdown {
      margin-left: 0.5rem;

      button {
        .material-icons {
          font-size: 15px;
          position: relative;
          top: 3px;
        }
      }
    }
  }

  .left {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .right {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .title-breadcrumbs {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 680px;
    width: 100%;
    overflow: hidden;
    height: 100%;
    position: relative;

    .breadcrumb {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 100%;
      position: relative;
      right: 0;
      opacity: 0;
      font-size: 18px;
      color: #999;
    }

    .breadcrumb.previous {
      // color: #999;
      cursor: pointer;
      opacity: 1;
      max-width: 350px;
    }

    .breadcrumb.previous .breadcrumb-delimiter {
      opacity: 1;
    }

    .breadcrumb.current {
      font-weight: 600;
      // color: #fff;
      opacity: 1;
      max-width: 50%;
    }

    .breadcrumb-delimiter {
      height: 10px;
      width: 10px;
      margin: 0 5px;
      opacity: 0;
    }

    .breadcrumb div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: 100%;
    }
  }
}

$step-primary: $inf-light-blue;
.btn-editor-ctrl {
  display: flex;
  align-items: center;
  background: lighten($inf-dark-blue, 10%);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
  color: lighten($off-gray, 20%);
  padding: 0.25rem 1rem;
  margin-right: 0.5rem;

  &--exit {
    border: $step-primary;
    background: $step-primary;
    color: $white;

    &:hover {
      border: lighten($step-primary, 10%);
      background: lighten($step-primary, 10%);
    }

    &:active {
      border: darken($step-primary, 10%);
      background: darken($step-primary, 10%);
    }
  }

  &:last-child {
    margin: 0;
  }

  .material-icons {
    font-size: 20px;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &:hover {
    background: lighten($inf-dark-blue, 20%);
  }

  &--danger {
    background: $error;

    &:hover {
      background: $error;
    }
  }

  &--slate {
    background: lighten($slate, 10%);

    &:hover {
      background: lighten($slate, 15%);
    }
  }
}
</style>
