
  <cvt-dialog
    v-if="!AltPopupManager"
    :show="visible"
    :width="'50vw'"
    @close="close"
  >
    <template #title>
      <p class="upload__manager__text">File Manager</p>
    </template>

    <cvt-tabs
      v-if="config.COMPANY_IMAGES_TAB_ENABLED"
      :keep-inactive-border="false"
      :closeable="false"
      prefix-icon=""
    >
      <cvt-tab
        id="company-images"
        title="Company Images"
        name="company-images"
        :active="'company-images' == currentTab"
      >
        <crm-file-browser @use="confirmSelection" />
      </cvt-tab>

      <cvt-tab
        id="upload"
        title="Upload"
        name="upload"
        :active="'upload' == currentTab"
      >
        <alt-upload-dropzone
          ref="uploadDropzone"
          :image-only="true"
          :size-limit="5"
          @complete="confirmSelection"
          @gallery-btn-clicked="openGallery"
          @done="setShowDoneBtn"
        />
      </cvt-tab>
    </cvt-tabs>

    <alt-upload-dropzone
      v-if="!config.COMPANY_IMAGES_TAB_ENABLED"
      ref="uploadDropzone"
      :image-only="true"
      :size-limit="5"
      @complete="confirmSelection"
      @done="setShowDoneBtn"
      @gallery-btn-clicked="openGallery"
    />

<!--    <element-anchor v-if="isGridBuilder"></element-anchor>-->

    <template #modalFooter>
      <cvt-button :text="modalButtonText" @click="close" />
    </template>
  </cvt-dialog>

  <cvt-dialog
    v-else
    bg-color="#ffffff"
    :show="visible"
    size="sm"
    :height-auto="true"
    :show-close="false"
    @close="close"
  >
    <template #title>
      <h5>File upload</h5>
    </template>

    <alt-upload-dropzone
      ref="uploadDropzone"
      btn-label="Select from computer"
      :image-only="true"
      :size-limit="5"
      @complete="confirmSelection"
      @done="setShowDoneBtn"
      @gallery-btn-clicked="openGallery"
    />
    <template #modalFooter>
      <cvt-button :text="modalButtonText" color="light" @click="close" />
    </template>
  </cvt-dialog>
