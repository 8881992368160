import {BackgroundShapeSVGObject} from "../BackgroundShapeSVGObject";

const HorizontalRectangleSVG =
`
<svg data-name="Rectangle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
    <path d="M0,1200L1440,1200L1440,0L0,0Z" class="fill"></path>
</svg>`


export const HorizontalRectangleShape = new BackgroundShapeSVGObject({
    name: "Horizontal Bar",
    canInvert: false,
    canFlip: false,
    svg: HorizontalRectangleSVG,
    adjustWidth: false,
    adjustHeightInPercentage: true
})

const VerticalRectangleSVG =
`
<svg data-name="Rectangle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
    <path d="M0,128L1400,128L1400,0L0,0Z" class="fill"></path> 
</svg>`


export const VerticalRectangleShape = new BackgroundShapeSVGObject({
    name: "Vertical Bar",
    canInvert: true,
    canFlip: false,
    svg: VerticalRectangleSVG,
    adjustHeight: false,
    isFullHeight: true,
    showPositionToggle: false,
    adjustWidthInPercentage: true
})