
<div class="crm-tags">
  <div v-if="loading" class="placeholder-container">
    <cvt-content-placeholder class="placeholder" />
  </div>
  <cvt-select
    v-else
    ref="tagger"
    :value="localTags"
    value-key="id"
    mode="tags"
    :multiple="true"
    :remote-method="search"
    :label="placeholder"
    @visible-change="search('')"
    @input="(tags) => updateLocalTags(tags)"
  >
    <option
      v-for="tag in tagsList"
      :key="tag.id"
      :label="fmtTagName(tag.name, tag.id)"
      :value="tag.id"
    >
      <span style="float: left" v-html="fmtTagName(tag.name, tag.id)"></span>
    </option>
  </cvt-select>

  <!-- <button class="btn btn-sm btn-success mt-2 text-capitalize btn-block" @click.stop="addTag">Create Tag</button> -->
  <div class="button-margin">
    <cvt-button
      shape="rounded"
      color="success"
      :mode="mode"
      text="Create Tag"
      :full-width="true"
      @click.stop="openCustomTagDialog"
    />
  </div>

  <cvt-dialog
    ref="create-tag-dialog"
    :show="showDialog"
    size="sm"
    :height-auto="true"
    @close="showDialog = false"
  >
    <template #title>
      <h5>Create Tag</h5>
    </template>

    <cvt-input
      type="text"
      :value="customTagName"
      label="Enter a new tag name"
      @input="(e) => updateCustomTagName(e)"
    />

    <template #modalFooter>
      <cvt-button color="light" text="Cancel" @click="showDialog = false" />
      <cvt-button text="Create" @click="addTag" />
    </template>
  </cvt-dialog>
</div>
