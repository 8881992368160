<template>
  <section
    :id="anchorId"
    class="block"
    :class="classes"
    :data-vnode-id="vnodeId"
    :data-key-id="blockKey"
    @click="select"
    @mouseover="target"
    @contextmenu.stop.prevent="showContextMenu"
    @dblclick.stop.prevent="triggerOpenAssetManager"
  >
    <div
      v-show="showTextureBackground && isSectionUIEnabled"
      class="dashed-texture"
    />

    <div v-if="rearrangingCurrentBlocks" class="working-on-block">
      <div class="working-on-block__container">
        <h3 class="loader-message">Please wait...</h3>
      </div>
    </div>

    <div
      v-show="showControlls && isSectionUIEnabled"
      class="block__target__indicator block__target__indicator--top"
    ></div>
    <div
      v-show="showControlls"
      class="block__target__indicator block__target__indicator--right"
    ></div>
    <div
      v-show="showControlls && isSectionUIEnabled"
      class="block__target__indicator block__target__indicator--bottom"
    ></div>
    <div
      v-show="showControlls"
      class="block__target__indicator block__target__indicator--left"
    ></div>

    <div :class="stylesheet.classes.bgColor"></div>
    <div :class="stylesheet.classes.bgImage"></div>
    <div :class="stylesheet.classes.overlayColor"></div>
    <!-- eslint-disable vue/no-v-html -->
    <div :class="stylesheet.classes.bgShape" v-html="bgSvg"></div>
    <!--eslint-enable-->
    <cvt-section-drag-items
      v-if="vnodeId && isGridBuilder && !isSectionUIEnabled && !isBuilderOnStaticMode && showElementsBtn"
      class="drag-items-block"
      usage="block"
      :show-plus-btn="true"
      :vnode-id="vnodeId"
      :widget-class="'grid-stack-item'"
    />

    <div v-show="isSectionUIEnabled" class="section-controls-wrap">
      <section-controls
        v-show="showControlls"
        :class="sectionControlsClasses"
        :vnode-id="vnodeId"
        @moveSectionUp="moveSectionUp"
        @moveSectionDown="moveSectionDown"
        @copySection="copySection"
        @swapSection="swapSection"
        @deleteSection="deleteSection"
        @arrangeBlock="arrangeBlock"
      />
    </div>

    <div v-show="!isEmpty" :class="containerClasses">
      <template v-for="node in fbNode.children">
        <component
          :is="node.tag"
          :key="node.data.props.vnodeId"
          :fb-node="node"
          v-bind="node.data.props"
        ></component>
      </template>
    </div>

    <cvt-popover
      :ref="popoverRef"
      :mode="mode"
      placement="top"
      :target="`${anchorId}-add-core-element`"
      triggers="click"
    >
      <template #popper>
        <div class="d-flex">
          <cvt-button
            color="success"
            class="text-capitalize ml-1"
            :text="$t('add_section.cta')"
            @click.stop.prevent="openQuickLaunch"
          />
        </div>
      </template>
    </cvt-popover>
  </section>
</template>

<script>
import * as _ from 'lodash'
import FirebaseNodeV3 from '../../base/FirebaseNode'
import GridContainer from './GridContainer.vue'
import Block from '../../../editor/nodes/Block.vue'
import { mapActions, mapGetters } from 'vuex'
import * as getters from '../../../../store/modules/getters'
import * as actions from '../../../../store/modules/actions'
import { Notification } from 'element-ui'
import { hub } from '@/js/plugins/EventHub'
import { PAGE_ENGINE_VDOM_UPDATE } from '@/js/plugins/events'

export default Block.extend({
  mixins: [FirebaseNodeV3],
  components: {
    'grid-container': GridContainer,
  },
  props: {
    fbNode: {
      type: Object,
      default: () => {
        return { children: [] }
      },
    },
    disableAddButtonElements: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      rearrangingCurrentBlocks: false,
    }
  },
  computed: {
    ...mapGetters({
      reArrangingAnyBlock: getters.GET_REARRANGING_BLOCK,
      config: getters.AUTH_GET_USER_CONFIG,
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN,
      isStandalonePopUpActive: getters.STANDALONE_POPUP_ACTIVE,
    }),
    bgSvg() {
      return _.get(this, 'bgShape.shape.svg', '')
    },
    blockKey () {
      return `block_${this['.key']}`
    },
    getDefaultHeight() {
      return this.bgShape?.adjustHeightInPercentage ? 20 : 150
    },
    getDefaultWidth() {
      return this.bgShape?.adjustHeightInPercentage ? 20 : 100
    },
    isBuilderOnStaticMode () {
      return this.config.GRID_BUILDER_STATIC_MODE_ENABLED || false
    },
    showElementsBtn () {
      if (this.disableAddButtonElements === true) {
        return false
      }
      return !this.isPopupBlock || (this.isPopupBlock && this.isStandalonePopUpActive) || (this.isPopupBlock && !this.isStandalonePopUpActive && this.isAdmin)
    },
  },
  methods: {
    ...mapActions({
      setArrangingAnyBlock: actions.REARRANGING_BLOCK,
    }),
    async arrangeBlock() {
      if (this.reArrangingAnyBlock || this.rearrangingCurrentBlocks) {
        Notification.success({
          title: `Section`,
          duration: 10000,
          position: 'bottom-right',
          message: 'Builder is busy at the moment. Please wait...',
        })
        return
      }

      const getGridContainerVueElements = this.getSectionGridContainerElement()
      if (getGridContainerVueElements && !this.rearrangingCurrentBlocks) {
        this.rearrangingCurrentBlocks = true
        await this.setArrangingAnyBlock(this.rearrangingCurrentBlocks)

        await getGridContainerVueElements.__vue__?.resizeChildComponent()
        hub.$emit(PAGE_ENGINE_VDOM_UPDATE, {
          forceBuild: false,
          action: 'arrangeBlock',
          name: 'arrangeBlockCommand',
        })

        this.rearrangingCurrentBlocks = false
        await this.setArrangingAnyBlock(this.rearrangingCurrentBlocks)
      }
    },
    getSectionGridContainerElement() {
      return document.querySelector(
        `section[data-vnode-id="${this.vnodeId}"] > .container > div.grid-stack-wrapper`,
      )
    },
    getBgShapeConfig() {
      if (this.bgShape) {
        let viewModeConfig = this.bgShape[this.viewMode] || {
          height: this.getDefaultHeight,
          width: this.getDefaultWidth,
        }

        let svgInverted = this.bgShape.isInverted
          ? { transform: 'rotateY(180deg)' }
          : {}
        let svgFlipped = this.bgShape.isFlipped
          ? { transform: 'rotate(180deg)' }
          : {}

        let svgPosition =
          this.bgShape.position === 'top' ? { top: 0 } : { bottom: 0 }
        let svgWidth = {
          width: `calc(${viewModeConfig.width || 100}% + 1.3px)`,
        }
        let svgHeight = {
          height: this.bgShape.isFullHeight
            ? `100%`
            : `${viewModeConfig.height || this.getDefaultHeight}${
                this.bgShape.adjustHeightInPercentage ? '%' : 'px'
              }`,
        }
        let pathFile = { fill: this.bgShapeColorHSL || '#867474' }

        return {
          svgInverted,
          svgFlipped,
          svgPosition,
          svgHeight,
          svgWidth,
          pathFile,
        }
      }

      return {
        svgInverted: {},
        svgFlipped: {},
        svgPosition: {},
        svgHeight: {},
        svgWidth: {},
        pathFile: {},
      }
    },
    getBgShapeHeight() {
      return this.bgShape?.isFullHeight ||
        this.bgShape?.adjustHeightInPercentage
        ? '100%'
        : 'auto'
    },
    getStyles() {
      let backgroundImagePositionCalculated = this.getBackgroundImagePosition()
      let bgColorApplied = this.appliedBackgroudColor

      let bgColorZeeIndex = this.getBgColorZeeIndex()
      let bgImageZeeIndex = this.getBgImageZeeIndex()
      let overlayColorZeeIndex = this.getOverlayColorZeeIndex()
      let bgShapeConfig = this.getBgShapeConfig()
      let bgShapeHeight = this.getBgShapeHeight()

      return {
        bgImage: {
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: bgImageZeeIndex,
          backgroundImage:
            this.backgroundImage && `url(${this.backgroundImage})`,
          backgroundPosition: backgroundImagePositionCalculated,
          backgroundRepeat: this.background.repeat
            ? this.background.repeat
            : 'no-repeat',
          backgroundSize: this.background.size ? this.background.size : 'cover',
          backgroundAttachment: this.background.parallax ? 'fixed' : null,
          '@media only screen and (max-width: 767px)': {
            backgroundAttachment: 'scroll',
            // backgroundImage: 'USE MOBILE RESPONSIVE VERSION FOR BETTER PERFORMANCE'
          },
        },
        bgColor: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: bgColorZeeIndex,
          position: 'absolute',
          backgroundColor: bgColorApplied,
        },
        overlayColor: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: overlayColorZeeIndex,
          position: 'absolute',
          backgroundColor: bgColorApplied,
        },
        content: {
          color: this.containerFontColorHex,
          ...this.spacing.padding.toObject(),
          ...this.spacing.margin.toObject(),
          ...this.formColorTransform,
        },
        bgShape: {
          position: this.bgShape?.adjustHeightInPercentage
            ? 'unset'
            : 'absolute',
          width: '100%',
          overflow: 'hidden',
          height: bgShapeHeight,
          lineHeight: 0,
          left: 0,
          zIndex: 0,
          ...bgShapeConfig.svgInverted,
          ...bgShapeConfig.svgPosition,
          '@global': {
            svg: {
              position: this.bgShape?.adjustHeightInPercentage
                ? 'absolute'
                : 'relative',
              left: 0,
              display: 'block',
              ...bgShapeConfig.svgFlipped,
              ...bgShapeConfig.svgWidth,
              ...bgShapeConfig.svgHeight,
              ...bgShapeConfig.svgPosition,
              '@global': {
                path: {
                  ...bgShapeConfig.pathFile,
                },
              },
            },
          },
        },
      }
    },
  },
})
</script>
