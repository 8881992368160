<template>
  <div
    :id="vnodeId"
    class="cvt-editor-divider"
    :class="classes"
    :style="styles"
    draggable="true"
    @click="select"
    @mouseover="target"
    @contextmenu.prevent.stop="showContextMenu"
    @dblclick.stop.prevent
    @dragstart="dragstart"
    @dragend="dragend">

    <drag-and-drop-zone :z-index="dropzoneZIndex" @dropped="addElementToSide"></drag-and-drop-zone>

    <hr :class="hrClasses" :style="styleOverrides">
    <template v-if="showActionButtonsOnClick && !isGridBuilder">
      <!--THis is only here because of freshwork in v1 gridbuilder,
      once they migrate to v2 or v3, it will become irrelevant-->
      <action-buttons
          v-if="selected"
          @duplicate="duplicateAndValidate"
          @remove="removeAndValidate"
      />
    </template>
  </div>
</template>

<script>
import FbVNode from '../../base/FbVNode.vue'

export default FbVNode.extend({
  props: {
    size: String, // thickness of a horizontal bar
  },
  data () {
    return {
      name: 'Divider'
    }
  },
  computed: {
    hrClasses () {
      return {
        [this.size]: true
      }
    },
    styleOverrides () {
      return {
        borderColor: this.themeColorHSL,
        ...this.spacingStyle
      }
    }
  },
  methods: {
  }
})
</script>

<style lang="scss">
  .cvt-editor-divider {
    display: block;
    position: relative;
    width: 100%;

    // if div#page-engine has grid-builder class only then apply this drag-and-drop-handle style
    .grid-builder & {
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }

    hr {
      border: 0;

      &.hr-1 {
        border-top: 1px solid rgba(0,0,0,.1);
      }
      &.hr-2 {
        border-top: 2px solid rgba(0,0,0,.1);
      }
      &.hr-3 {
        border-top: 3px solid rgba(0,0,0,.1);
      }
    }
  }

</style>
