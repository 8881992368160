<template>
  <div
    :id="vnodeId"
    class="cvt-editor-form"
    :class="classes"
    :style="styles"
    draggable="true"
    @click="select"
    @mouseover="target"
    @contextmenu.prevent.stop="showContextMenu"
    @dblclick.stop
    @dragstart="dragstart"
    @dragend="dragend"
  >
    <img
      v-show="isEmpty"
      class="img-placeholder"
      src="/img/form-placeholder.svg"
    />

    <a
      v-show="!isEmpty && facebook"
      href="/api/auth/opt-in/facebook"
      class="btn btn-rounded btn-lg btn-block sso-facebook"
    >
      <i class="fa fa-lg fa-facebook-square mr-2" />Autofill with Facebook
    </a>
    <a
      v-show="!isEmpty && linkedin"
      href="/api/auth/opt-in/linkedin"
      class="btn btn-rounded btn-lg btn-block sso-linkedin"
    >
      <i class="fa fa-lg fa-linkedin-square mr-2" />Autofill with LinkedIn
    </a>

    <form
      v-show="!isEmpty"
      :id="id"
      class="form"
      :class="formClasses"
      @submit.prevent.stop
    >
      <p v-show="!fields.length">Click here to add Form Field</p>
      <form-control
        v-for="(f, idx) in fields"
        :key="f.id"
        :index="idx"
        :field="f"
        :data-global-tag-ids="tagData"
        :org-field-defaults-enabled="orgFieldDefaultsEnabled"
      />

      <div v-if="config.LEGAL_SYSTEM_ENABLED" class="row">
        <div class="col">
          <div class="form-check mb-2 mr-sm-2">
            <label class="form-check-label">
              <input
                id="policyAttestation"
                class="form-check-input mr-3"
                type="checkbox"
                name="meta[policyAttestation]"
                required="required"
              />
              <span
                >I attest that I have read and understand the policies listed
                and that my personal information will be collected and stored by
                this website.</span
              >
            </label>
          </div>
        </div>
      </div>

      <div v-if="config.LEGAL_SYSTEM_ENABLED" class="row mb-2 mr-sm-2">
        <div class="col text-center">
          <a href="#" target="_blank">Terms of Service</a>
        </div>
        <div class="col text-center">
          <a href="#" target="_blank">Privacy Policy</a>
        </div>
        <div class="col text-center">
          <a href="#" target="_blank">Cookie Policy</a>
        </div>
      </div>

      <template v-for="node in fbNode.children">
        <template v-if="node.tag === 'c-button'">
          <c-button-v3 v-bind="node.data.props" :key="node.vnodeId" :fb-node="node"/>
        </template>
      </template>
    </form>

    <drag-and-drop-zone :z-index="dropzoneZIndex" @dropped="addElementToSide" />
    <template v-if="showActionButtonsOnClick && !isGridBuilder">
      <!--THis is only here because of freshwork in v1 gridbuilder,
      once they migrate to v2 or v3, it will become irrelevant-->
      <action-buttons
        v-if="selected"
        @duplicate="duplicateAndValidate"
        @remove="removeAndValidate"
      />
    </template>
  </div>
</template>

<script>
import FirebaseNodeV3 from '../../base/FirebaseNode'
import CForm from '../../../editor/nodes/CForm.vue'
import CButtonV3 from '@/js/components/v3/editor/nodes/CButton.vue'


export default CForm.extend({
  name: 'CFormV3',
  components: {
    CButtonV3
  },
  mixins: [FirebaseNodeV3],
  computed: {
    isEmpty() {
      return !this.fields.length
    }
  },
  methods: {
    async pullOrgSpecificRequiredFields() {
      if (!this.isEmpty) return

      await this.fetchFormFieldGroups()
      const requiredFields = this.formFieldGroups
        .map((g) => g.fields)
        .flat()
        .filter((f) => f.required)
      this.updateProp('fields', requiredFields)
    }
  }
})
</script>
