import _ from 'lodash'
import { cvtRouteNodes } from '@/js/utils/routes'
import FeatureConfiguration from '../../FeatureConfiguration/FeatureConfiguration.vue'
import { mapGetters } from 'vuex'
import * as getters from '../../../../../../../builder/src/js/store/modules/getters'
import { Organization } from '../../../../../../../builder/src/js/store/services/api'

const organizationAPI = new Organization()

export default {
  components: {
    FeatureConfiguration,
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),
  },
  methods: {
    async onClickFeatureConfig() {
      const topNav_Config = this.config.TOP_NAV_CONFIG

      const topNavConfig = {
        key: 'TOP_NAV_CONFIG',
        table: 'organizationConfigs',
        value: {
          ...topNav_Config,
          editor: {
            ...topNav_Config.editor,
            showEntDashboardBtn: true,
          },
        },
      }
      await organizationAPI.upsertFeatureConfig(topNavConfig)
      this.$router.push({
        name: cvtRouteNodes.enterpriseDashboardBranding,
      })
    },
  },
}
