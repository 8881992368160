
<div>
  <div :class="'ent-dashboard-head-container'">
    <cvt-ent-dashboard-topnav
      :id="'ent-dashboard-head'"
      class="ent-dashboard-head-wrapper"
      :nav-logo-src="'/img/logo.png'"
      :org-name="'Convrrt'"
      @goToMarketPlace=" () => { $router.push('/') } "
    />
  </div>

  <div :id="'ent-dashboard-sidenav-wrap'" :class="'ent-dashboard-container'">
    <cvt-ent-dashboard-sidenav
      :id="'ent-dashboard-sidenav'"
      :navigation-items="navigationItems"
      :initial-active-item="navigationItems[0]"
      @onChangeNavTab="onChangeNavTab"
    />
    <router-view />
  </div>
</div>
