import { database } from '@/js/store/services/firebase.service'
import * as _ from 'lodash'
import * as getters from './getters'

const db = database()
function defaultSitePreferences(landingPage) {
  const genAI = {
    enabled: false,
    selectedIntents: [],
    selectedIntentGoals: [],
  }
  const basicPreference = {
    favicon: '',
    thumbnail: '',
    seoSettings: {
      allowRobots: {
        all: false,
        pages: {},
      },
    },
    integrations: {
      provider: "",
      step: "integrations",
    }
  }
  if (landingPage) {
    return {...basicPreference, genAI}
  }
  return basicPreference
}

export default {
  namespaced: true,
  state: {
    sitePreferencesRef: null,
    preferences: defaultSitePreferences(false),
  },
  getters: {
    [getters.GET_FB_SITE_PREFERENCES]({ preferences }) {
      return preferences
    }
  },
  mutations: {
    update(state, preferences) {
      state.preferences = preferences
    },
    save(state, { key, value }) {
      _.set(state.preferences, key, value)
    },
    saveIntegrationStep(state, {formId, params}) {
      _.set(state.preferences, `integrations.${formId}`, params)
      // _.set(state.preferences, 'integrations.step', step)
      // state.preferences = {...state.preferences, integrations: {...state.preferences.integrations, provider, step}}
    },
  },
  actions: {
    async init({ state, commit }, fbSitePath, landingPage) {
      if (state.sitePreferencesRef) {
        state.sitePreferencesRef.off('value')
      }

      const checkIntegrationMigration = async (data) => {
        if (data?.integrations?.formId) {
          const formId = data.integrations.formId
          for (const [key, value] of Object.entries(data.integrations)) {
            await state.sitePreferencesRef.child(`integrations/${key}`).remove()
            await state.sitePreferencesRef.child(`integrations/${formId}/${key}`).set(value)
          }
          commit('saveIntegrationStep', {formId, params: data.integrations})
        }
      }

      state.sitePreferencesRef = db.ref(fbSitePath).child('preferences')

      state.sitePreferencesRef.on('value', (snap) => {
        const preferences = snap.val()

        if (!preferences) {
          state.sitePreferencesRef
            .set(defaultSitePreferences(landingPage))
            .catch((err) => {
              console.error('failed to set site preferences', err)
            })
        }

        if (preferences !== null) {
          commit('update', preferences)
          checkIntegrationMigration(preferences)
        }
      })
    },
    async save({ state, commit }, { key, value }) {
      if (!key) {
        throw new Error('Missing key when updating site preferences')
      }

      commit('save', { key, value })
      await state.sitePreferencesRef.child(key).set(value)
    },
    async saveIntegrationStep({ state, commit }, {formId, params}) {
      if (!params.step || !params.provider) {
        throw new Error('Missing key when updating site preferences')
      }

      commit('saveIntegrationStep', {formId, params: Object.assign({}, state.preferences.integrations[formId] || {}, params)})

      for (const [key, value] of Object.entries(params)) {
        await state.sitePreferencesRef.child(`integrations/${formId}/${key}`).set(value)
      }
    },
    async clearIntegration({state, commit}, formId){
      commit('saveIntegrationStep', { provider: "", step: "integrations" })
      await state.sitePreferencesRef.child(`integrations/${formId}`).set(
        {
          provider: "",
          step: "integrations",
          formId: ""
        }
      )
    }
  },
}
