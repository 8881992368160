import * as _ from 'lodash'
import * as constants from '../../../builder/src/js/common/constants/constants'
import * as getters from '../../../builder/src/js/store/modules/getters'
import Vue from '../../lib/utils/vue'

import CvtRadio from '../molecules/radio-group/Radio'
import CvtRadioGroup from '../molecules/radio-group/RadioGroup'

import Button from '../molecules/Button'
import CvtInput from '../molecules/Input'
import CvtSelect from '../molecules/Select'

import { styled } from '@egoist/vue-emotion'
import VueI18n from 'vue-i18n'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'

Vue.use(VueI18n)

let Spacing,
  Border,
  Colors,
  textLineHeight,
  BorderStyle,
  borderColor,
  textColor,
  FontFamily,
  FontSize,
  FontWeight

const getElementWrapper = () => styled('div')`
  font-family: ${FontFamily.DEFAULT};
  ${textColor({ themeing: 'dark' }, 500)}

  ${(props) => {
    let style = ``

    if (props.height) {
      style += `height: ${props.height};`
    }

    if (props.width) {
      style += `width: ${props.width};`
    }

    return style
  }}
`

const getMainWrapper = () => styled('div')`
  width: 100%;
`

const getSectionWrapper = () => styled('div')`
  height: auto;
  min-height: ${Spacing.SPACING_96}px;
  margin-top: ${Spacing.SPACING_32}px;
  display: flex;
  justify-content: start;
  align-items: start;
`

const getSectionLeftHeading = () => styled('div')`
  font-size: ${FontSize.FONT_SIZE_14}px;
  font-weight: ${FontWeight.SEMI_BOLD};
  font-family: ${FontFamily.DEFAULT};
  ${textColor({ themeing: 'dark' }, 500)}
  ${textLineHeight('xl')}
  width: ${Spacing.SPACING_128}px;
`

const getSectionRightContentWrapper = () => styled('div')`
  width: 100%;
  padding-bottom: ${Spacing.SPACING_32}px;
  border-bottom-width: ${Border.SIZE_1}px;
  border-bottom-style: solid;
  border-bottom-color: ${Colors.BASIC[200]};
`

const getSectionRightContentHeading = () => styled('div')`
  font-size: ${FontSize.FONT_SIZE_14}px;
  font-weight: ${FontWeight.REGULAR};
  font-family: ${FontFamily.DEFAULT};
  ${textColor({ themeing: 'dark' }, 400)}
  ${textLineHeight('xl')}
  margin-bottom: ${Spacing.SPACING_16}px;
`

const getSpecificOptionContentWrapper = () => styled('div')`
  width: 100%;
  margin-top: ${Spacing.SPACING_32}px;
  padding: ${Spacing.SPACING_12 + Spacing.SPACING_8}px;
  border: ${Border.SIZE_1}px solid ${Colors.BASIC[200]};
`

const getSpecificOptionHeading = () => styled('div')`
  font-size: ${FontSize.FONT_SIZE_14}px;
  font-weight: ${FontWeight.REGULAR};
  font-family: ${FontFamily.DEFAULT};
  ${textColor({ themeing: 'dark' }, 400)}
  ${textLineHeight('xl')}
  margin-bottom: ${Spacing.SPACING_16}px;
`

const getSpecificOptionInnerWrapper = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
`

const getSpecificOptionSelectInnerWrapper = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  gap: 7px;
`

const getSpecificOptionPlusBtnInnerWrapper = () => styled('div')`
  width: 30px;
  height: 40px;
  display: flex;
  justify-content: end;
  align-items: center;
`

const getPlusButton = () => styled(Button)`
  font-family: ${FontFamily.DEFAULT};
  text-align: center;
  font-style: normal;
`

const getQuestionMarkButton = () => styled(Button)`
  font-family: ${FontFamily.DEFAULT};
  text-align: center;
  font-style: normal;
`

const getMinusButton = () => styled(Button)`
  font-family: ${FontFamily.DEFAULT};
  text-align: center;
  font-style: normal;
`

const getImportLink = () => styled(Button)`
  font-family: ${FontFamily.DEFAULT};
  text-align: center;
  font-style: normal;
  border-right-width: ${Border.SIZE_1}px;
  border-right-style: solid;
  border-right-color: ${Colors.BASIC[200]};
`

const getAddLink = () => styled(Button)`
  font-family: ${FontFamily.DEFAULT};
  text-align: center;
  font-style: normal;
`

const getTimeFrameWrapper = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const getTimeFrameInnerWrapperOne = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-bottom: ${Spacing.SPACING_16}px;
  border-bottom: ${Border.SIZE_1}px solid ${Colors.BASIC[200]};
`

const getTimeFrameInnerWrapperTwo = () => styled('div')`
  width: 100%;
  align-items: center;
  margin-top: ${Spacing.SPACING_16 + Spacing.SPACING_4}px;
`

const getPageScrollWrapper = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
`

const getExitIntentWrapper = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const getExitIntentInnerWrapperOne = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
`

const getExitIntentInnerWrapperTwo = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: ${Spacing.SPACING_16 + Spacing.SPACING_4}px;
  border-top: ${Border.SIZE_1}px solid ${Colors.BASIC[200]};
`

const getExitIntentInnerWrapperOneSubSection = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-bottom: ${Spacing.SPACING_16}px;
  padding-left: ${Spacing.SPACING_18 + Spacing.SPACING_2}px;
`

const getRadioGroup = () => styled(CvtRadioGroup)``

const getRadio = () => styled(CvtRadio)``

const getExitIntentInnerWrapperOneHeading = () => styled('div')`
  width: 100%;
  font-size: ${FontSize.FONT_SIZE_14}px;
  font-weight: ${FontWeight.REGULAR};
  font-family: ${FontFamily.DEFAULT};
  ${textColor({ themeing: 'dark' }, 400)}
  ${textLineHeight('xl')}
  padding-left: ${Spacing.SPACING_18 + Spacing.SPACING_2}px;
  margin-bottom: ${Spacing.SPACING_16}px;
`

const getExitIntentInnerWrapperTwoHeadingWrap = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 7px;
`

const getExitIntentInnerWrapperTwoHeading = () => styled('div')`
  font-size: ${FontSize.FONT_SIZE_14}px;
  font-weight: ${FontWeight.REGULAR};
  font-family: ${FontFamily.DEFAULT};
  ${textColor({ themeing: 'dark' }, 400)}
  ${textLineHeight('xl')}
  padding-left: ${Spacing.SPACING_18 + Spacing.SPACING_2}px;
  margin-bottom: ${Spacing.SPACING_16}px;
`

const getExitIntentInnerWrapperTwoSubHeading = () => styled('a')`
  font-size: ${FontSize.FONT_SIZE_14}px;
  font-weight: ${FontWeight.REGULAR};
  font-family: ${FontFamily.DEFAULT};
  ${textColor({ themeing: 'primary' }, 500)}
  ${textLineHeight('xl')}
  margin-bottom: ${Spacing.SPACING_16}px;
`

const getMousePointerWrapper = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const getMousePointerHeading = () => styled('div')`
  font-size: ${FontSize.FONT_SIZE_14}px;
  font-weight: ${FontWeight.REGULAR};
  font-family: ${FontFamily.DEFAULT};
  ${textColor({ themeing: 'dark' }, 400)}
  ${textLineHeight('xl')}
  margin-bottom: ${Spacing.SPACING_16}px;
  padding-left: ${Spacing.SPACING_18 + Spacing.SPACING_2}px;
`

const getMousePointerInnerWrapperOne = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: ${Spacing.SPACING_16}px;
  padding-left: ${Spacing.SPACING_18 + Spacing.SPACING_2}px;
  gap: 7px;
`

const getMousePointerInnerWrapperTwo = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
`

const getEventWrapper = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
`

const getEventHeading = () => styled('div')`
  font-size: ${FontSize.FONT_SIZE_14}px;
  font-weight: ${FontWeight.REGULAR};
  font-family: ${FontFamily.DEFAULT};
  ${textColor({ themeing: 'dark' }, 400)}
  ${textLineHeight('xl')}
  margin-bottom: ${Spacing.SPACING_16}px;
  padding-left: ${Spacing.SPACING_18 + Spacing.SPACING_2}px;
`

const getEventInnerWrapperOne = () => styled('div')`
  width: 410px;
  display: flex;
  justify-content: start;
  align-items: end;
  gap: 7px;
`

const getEventInnerWrapperOneSubSectionOne = () => styled('div')`
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  margin-top: ${Spacing.SPACING_16}px;
  padding-left: ${Spacing.SPACING_18 + Spacing.SPACING_2}px;
  gap: 7px;
`

const getEventInnerWrapperOneSubSectionTwo = () => styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  padding-bottom: ${Spacing.SPACING_4}px;
`

const getEventInnerWrapperTwo = () => styled('div')`
  width: 386px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: ${Spacing.SPACING_12}px;
`

const getEventInnerWrapperTwoSubSectionOne = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-bottom: ${Spacing.SPACING_4}px;
`

const getEventInnerWrapperThree = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: ${Spacing.SPACING_18 + Spacing.SPACING_2}px;
  gap: 7px;
`

const getPlacementTypeInnerWrapperOne = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  border-bottom: ${Border.SIZE_1}px solid ${Colors.BASIC[200]};
  padding-bottom: ${Spacing.SPACING_16}px;
`

const getPlacementTypeInnerWrapperTwo = () => styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${Spacing.SPACING_18 + Spacing.SPACING_2}px;
`

const getPlacementTypeAddImportWrapper = () => styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
`

const getFrequencyTypeWrapper = () => styled('div')`
  width: 100%;
  padding-bottom: ${Spacing.SPACING_32}px;
`

const getInput = () => styled('input')`
  height: ${(props) => props.height || '50px'};
  width: ${(props) => props.width || '50px'};
  border: ${Border.SIZE_1}px ${BorderStyle.SOLID};
  ${borderColor({ themeing: 'primary' }, 500)}
  padding: ${Spacing.SPACING_12}px ${Spacing.SPACING_4}px;
  ${textColor({ themeing: 'basic' }, 500)}
  border-radius: 4px;
  font-weight: 300;
  font-size: ${Spacing.SPACING_12}px;
  margin-left: ${Spacing.SPACING_8}px;
  margin-right: ${Spacing.SPACING_8}px;

  &:focus {
    outline: none;
  }

  &[type='checkbox'] {
    accent-color: ${Colors.PRIMARY[500]};
    width: ${Spacing.SPACING_16}px;
    margin-right: ${Spacing.SPACING_8}px;
    margin-left: 0;
  }
`

const getCvtSelect = () => styled(CvtSelect)`
  margin: 0 ${Spacing.SPACING_8 + Spacing.SPACING_2}px;
  div {
    ${borderColor({ themeing: 'primary' }, 500)}
    width: ${(props) => props.width || '100%'};
  }
  input {
    height: 22px;
    font-size: ${Spacing.SPACING_12}px;
    padding: 0;
  }
`

export const CvtSapRules = {
  name: 'CvtSapRules',
  components: {
    CvtRadioGroup,
    CvtSelect,
    CvtInput,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
      required: false,
    },
    showTriggerOptions: {
      type: Boolean,
      default: false,
    },
    showFrequencyOption: {
      type: Boolean,
      default: false,
    },
    showPlacementOptions: {
      type: Boolean,
      default: false,
    },
    showOpeningTimeTrigger: {
      type: Boolean,
      default: false,
    },
    showPageScrollTrigger: {
      type: Boolean,
      default: false,
    },
    showExitIntentTrigger: {
      type: Boolean,
      default: false,
    },
    showEventTrigger: {
      type: Boolean,
      default: false,
    },
    showCssSelectorTrigger: {
      type: Boolean,
      default: false,
    },
    trigger: {
      type: String,
      default: constants.TIME_FRAME,
      required: true,
    },
    timeFrame: {
      type: Object,
      default: () => {},
      required: true,
    },
    exitIntent: {
      type: Object,
      default: () => {},
      required: true,
    },
    mouse: {
      type: Object,
      default: () => {},
      required: true,
    },
    pageScroll: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      placementType: 'allPages',
      frequencyType: 'oncePerPageLoad',
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      BorderStyle: ({ globalTheme }) => globalTheme.BorderStyle,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
    }),
  },
  created() {
    Spacing = this.Spacing
    Border = this.Border
    Colors = this.Colors
    BorderStyle = this.BorderStyle
    FontFamily = this.FontFamily
    borderColor = this.borderColor
    textColor = this.textColor
    textLineHeight = this.textLineHeight
    FontSize = this.FontSize
    FontWeight = this.FontWeight
  },
  methods: {
    onTriggerChange(value) {
      this.$emit('triggerChanged', value)
    },
    onTimeFrameInputChange(value, timeFrameObject, fieldKey) {
      timeFrameObject[fieldKey] = value
      this.$emit('timeFramePropsChanged', timeFrameObject)
    },
    onExitIntentDesktopDeviceRadioBtnChange(
      value,
      exitIntentObject,
      fieldPath,
    ) {
      _.set(exitIntentObject, fieldPath, value)
      this.$emit('exitIntentPropsChanged', exitIntentObject)
    },
    onExitIntentMobileDeviceRadioBtnChange(value, exitIntentObject, fieldPath) {
      const payloadObject = {
        onScrollToTop: false,
        onBackButtonClicked: false,
      }

      if (value === constants.ON_SCROLL_TO_TOP) {
        payloadObject.onScrollToTop = true
      } else if (value === constants.ON_BACK_BUTTON_CLICKED) {
        payloadObject.onBackButtonClicked = true
      }

      _.set(exitIntentObject, fieldPath, payloadObject)
      this.$emit('exitIntentPropsChanged', exitIntentObject)
    },
    onExitIntentInputChange(value, exitIntentObject, fieldPath) {
      _.set(exitIntentObject, fieldPath, value)
      this.$emit('exitIntentPropsChanged', exitIntentObject)
    },
    onMouseTriggerInputChange(value, mouseTriggerObject, fieldPath) {
      _.set(mouseTriggerObject, fieldPath, value)
      this.$emit('mousePropsChanged', mouseTriggerObject)
    },
    onMouseTriggerRadioBtnChange(value, mouseTriggerObject, fieldPath) {
      _.set(mouseTriggerObject, fieldPath, value)
      this.$emit('mousePropsChanged', mouseTriggerObject)
    },
    onPageScrollInputChange(value, pageScrollObject, fieldPath) {
      _.set(pageScrollObject, fieldPath, value)
      this.$emit('pageScrollPropsChanged', pageScrollObject)
    },
    onPlacementTypeChange(value) {
      this.placementType = value
    },
    onFrequencyTypeChange(value) {
      this.frequencyType = value
    },
    onInputChange(val, currField, fieldTag) {
      return (currField[fieldTag] = val)
    },
  },
  render: function (h) {
    const ElementWrapper = getElementWrapper()
    const MainWrapper = getMainWrapper()

    const SectionWrapper = getSectionWrapper()
    const SectionLeftHeading = getSectionLeftHeading()
    const SectionRightContentWrapper = getSectionRightContentWrapper()
    const SectionRightContentHeading = getSectionRightContentHeading()

    const SpecificOptionContentWrapper = getSpecificOptionContentWrapper()
    const SpecificOptionHeading = getSpecificOptionHeading()

    const SpecificOptionInnerWrapper = getSpecificOptionInnerWrapper()
    const SpecificOptionSelectInnerWrapper =
      getSpecificOptionSelectInnerWrapper()
    const SpecificOptionPlusBtnInnerWrapper =
      getSpecificOptionPlusBtnInnerWrapper()

    const PlusButton = getPlusButton()
    const MinusButton = getMinusButton()
    const QuestionMarkButton = getQuestionMarkButton()
    const ImportLink = getImportLink()
    const AddLink = getAddLink()

    const RadioGroup = getRadioGroup()
    const Radio = getRadio()
    const Input = getInput()
    const CvtSelectInput = getCvtSelect()

    const TimeFrameWrapper = getTimeFrameWrapper()
    const TimeFrameInnerWrapperOne = getTimeFrameInnerWrapperOne()
    const TimeFrameInnerWrapperTwo = getTimeFrameInnerWrapperTwo()

    const PageScrollWrapper = getPageScrollWrapper()

    const ExitIntentWrapper = getExitIntentWrapper()
    const ExitIntentInnerWrapperOneHeading =
      getExitIntentInnerWrapperOneHeading()
    const ExitIntentInnerWrapperOne = getExitIntentInnerWrapperOne()
    const ExitIntentInnerWrapperOneSubSection =
      getExitIntentInnerWrapperOneSubSection()

    const ExitIntentInnerWrapperTwo = getExitIntentInnerWrapperTwo()
    const ExitIntentInnerWrapperTwoHeadingWrap =
      getExitIntentInnerWrapperTwoHeadingWrap()
    const ExitIntentInnerWrapperTwoHeading =
      getExitIntentInnerWrapperTwoHeading()
    const ExitIntentInnerWrapperTwoSubHeading =
      getExitIntentInnerWrapperTwoSubHeading()

    const MousePointerWrapper = getMousePointerWrapper()
    const MousePointerHeading = getMousePointerHeading()
    const MousePointerInnerWrapperOne = getMousePointerInnerWrapperOne()
    const MousePointerInnerWrapperTwo = getMousePointerInnerWrapperTwo()

    const EventWrapper = getEventWrapper()
    const EventHeading = getEventHeading()
    const EventInnerWrapperOne = getEventInnerWrapperOne()
    const EventInnerWrapperOneSubSectionOne =
      getEventInnerWrapperOneSubSectionOne()
    const EventInnerWrapperOneSubSectionTwo =
      getEventInnerWrapperOneSubSectionTwo()
    const EventInnerWrapperTwo = getEventInnerWrapperTwo()
    const EventInnerWrapperTwoSubSectionOne =
      getEventInnerWrapperTwoSubSectionOne()

    const EventInnerWrapperThree = getEventInnerWrapperThree()

    const PlacementTypeInnerWrapperOne = getPlacementTypeInnerWrapperOne()
    const PlacementTypeInnerWrapperTwo = getPlacementTypeInnerWrapperTwo()
    const PlacementTypeAddImportWrapper = getPlacementTypeAddImportWrapper()

    const FrequencyTypeWrapper = getFrequencyTypeWrapper()

    return (
      <MainWrapper>
        {this.showTriggerOptions && (
          <SectionWrapper>
            <SectionLeftHeading>Triggers</SectionLeftHeading>

            <SectionRightContentWrapper>
              <SectionRightContentHeading>
                Which visitor will trigger your campaign
              </SectionRightContentHeading>
              <ElementWrapper>
                <cvt-radio-group
                  theme={'LIGHT'}
                  orientation={'horizontal'}
                  initialValue={this.trigger}
                >
                  {this.showOpeningTimeTrigger && (
                    <cvt-radio-button
                      size={'lg'}
                      btnWidth={'80px'}
                      value={constants.TIME_FRAME}
                      supportText={'Time Frame'}
                      onRadioClick={(value) => this.onTriggerChange(value)}
                    >
                      <cvt-icon icon="clock"></cvt-icon>
                    </cvt-radio-button>
                  )}

                  {this.showPageScrollTrigger && (
                    <cvt-radio-button
                      size={'lg'}
                      btnWidth={'80px'}
                      value={constants.PAGE_SCROLL}
                      supportText={'Page Scroll'}
                      onRadioClick={(value) => this.onTriggerChange(value)}
                    >
                      <cvt-icon icon="arrow-down"></cvt-icon>
                    </cvt-radio-button>
                  )}

                  {this.showExitIntentTrigger && (
                    <cvt-radio-button
                      size={'lg'}
                      btnWidth={'80px'}
                      value={constants.EXIT_INTENT}
                      supportText={'Exit Intent'}
                      onRadioClick={(value) => this.onTriggerChange(value)}
                    >
                      <cvt-icon icon="external-link-alt"></cvt-icon>
                    </cvt-radio-button>
                  )}

                  {this.showCssSelectorTrigger && (
                    <cvt-radio-button
                      size={'lg'}
                      btnWidth={'80px'}
                      value={constants.MOUSE_POINTER}
                      supportText={'Mouse'}
                      onRadioClick={(value) => this.onTriggerChange(value)}
                    >
                      <cvt-icon icon="mouse-pointer"></cvt-icon>
                    </cvt-radio-button>
                  )}

                  {this.showEventTrigger && (
                    <div>
                      <cvt-radio-button
                        size={'lg'}
                        btnWidth={'80px'}
                        helpText="time frame"
                        value={constants.EVENT}
                        supportText={'Event'}
                        onRadioClick={(value) => this.onTriggerChange(value)}
                      >
                        <cvt-icon icon="code"></cvt-icon>
                      </cvt-radio-button>
                    </div>
                  )}
                </cvt-radio-group>
              </ElementWrapper>

              {this.trigger === constants.TIME_FRAME &&
                this.showOpeningTimeTrigger && (
                  <SpecificOptionContentWrapper>
                    <SpecificOptionHeading>
                      Select when to open your campaign
                    </SpecificOptionHeading>
                    <TimeFrameWrapper>
                      <TimeFrameInnerWrapperOne>
                        <span>Open campaign</span>
                        <Input
                          type={'number'}
                          maxlength={'2'}
                          height={'32px'}
                          width={'65px'}
                          value={this.timeFrame.openAfterNoOfSeconds}
                          onChange={(event) =>
                            this.onTimeFrameInputChange(
                              +event.target.value,
                              this.timeFrame,
                              'openAfterNoOfSeconds',
                            )
                          }
                        />
                        <span>second(s) after page loads</span>
                      </TimeFrameInnerWrapperOne>

                      <TimeFrameInnerWrapperTwo>
                        <ElementWrapper
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Input
                            type={'checkbox'}
                            checked={this.timeFrame.resetTimerOnPageLoad}
                            onChange={(event) => {
                              this.onTimeFrameInputChange(
                                event.target.checked,
                                this.timeFrame,
                                'resetTimerOnPageLoad',
                              )
                            }}
                          />
                          <span>Reset timer on each page load</span>
                        </ElementWrapper>

                        {/* {!this.timeFrame.resetTimerOnPageLoad && (
                          <ElementWrapper style={{display: 'flex', alignItems: 'center'}}>
                            <Input 
                              type={'checkbox'}
                              checked={this.timeFrame.delay}
                              onChange={(event) => { this.onTimeFrameInputChange(event.target.checked, this.timeFrame, 'delay' )}}
                            />
                            <span>Add delay before opening campaign</span>
                            {this.timeFrame.delay &&
                              <div style={{display: 'flex', alignItems: 'center'}}> 
                                <Input
                                  type="number"
                                  maxlength="2"
                                  height="40px"
                                  width="60px"
                                  value={this.timeFrame.delayBeforeOpening}
                                  onChange={(event) => this.onTimeFrameInputChange(+event.target.value, this.timeFrame, 'delayBeforeOpening')}
                                />
                                <span>second(s)</span>
                              </div>
                            }
                          </ElementWrapper>
                        )} */}
                      </TimeFrameInnerWrapperTwo>
                    </TimeFrameWrapper>
                  </SpecificOptionContentWrapper>
                )}

              {this.trigger === constants.PAGE_SCROLL &&
                this.showPageScrollTrigger && (
                  <SpecificOptionContentWrapper>
                    <SpecificOptionHeading>
                      Select when to open your campaign
                    </SpecificOptionHeading>

                    <PageScrollWrapper>
                      <ElementWrapper width={'auto'}>
                        Open campaign after visitor scrolls
                      </ElementWrapper>

                      <Input
                        type="number"
                        maxlength="5"
                        height="32px"
                        width="80px"
                        value={this.pageScroll.openAfterNoOfScrollPx}
                        onInput={(event) =>
                          this.onPageScrollInputChange(
                            event.target.value,
                            this.pageScroll,
                            'openAfterNoOfScrollPx',
                          )
                        }
                      />

                      <CvtSelectInput
                        width="80px"
                        multiple={false}
                        mode={'dropdown'}
                        backgroundMode={this.mode}
                        selectDisabled={false}
                        showCancelIcon={false}
                        floatingLabel={true}
                        v-model={this.pageScroll.measurement}
                        onInput={(value) =>
                          this.onPageScrollInputChange(
                            value,
                            this.pageScroll,
                            'measurement',
                          )
                        }
                      >
                        <option value={constants.PIXEL}>px</option>
                        <option value={constants.PERCENTAGE}>%</option>
                      </CvtSelectInput>
                      <ElementWrapper width={'auto'}>
                        Of the entire page
                      </ElementWrapper>
                    </PageScrollWrapper>
                  </SpecificOptionContentWrapper>
                )}

              {this.trigger === constants.EXIT_INTENT &&
                this.showExitIntentTrigger && (
                  <SpecificOptionContentWrapper>
                    <ExitIntentWrapper>
                      <ExitIntentInnerWrapperOne>
                        <ExitIntentInnerWrapperOneHeading>
                          Select when to open your campaign on desktop devices
                        </ExitIntentInnerWrapperOneHeading>

                        <ElementWrapper width={'auto'}>
                          <RadioGroup
                            theme={''}
                            orientation={'vertical'}
                            size={'md'}
                            initialValue={this.exitIntent.action}
                          >
                            <Radio
                              border={false}
                              otherValue={constants.ON_EXIT_SITE}
                              onRadioClick={(value) => {
                                this.onExitIntentDesktopDeviceRadioBtnChange(
                                  value,
                                  this.exitIntent,
                                  'action',
                                )
                              }}
                            >
                              Open campaign immediately when a visitor tries to
                              leave your site
                            </Radio>
                            <Radio
                              border={false}
                              otherValue={constants.CAMPAIGN_BETWEEN_TIMEFRAME}
                              onRadioClick={(value) => {
                                this.onExitIntentDesktopDeviceRadioBtnChange(
                                  value,
                                  this.exitIntent,
                                  'action',
                                )
                              }}
                            >
                              Enable the campaign to open when a visitor tries
                              to leave, but within a time frame
                            </Radio>
                          </RadioGroup>
                        </ElementWrapper>

                        {this.exitIntent.action ===
                          constants.CAMPAIGN_BETWEEN_TIMEFRAME && (
                          <ElementWrapper
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              paddingLeft: '10px',
                              marginBottom: '15px',
                            }}
                          >
                            <CvtSelectInput
                              width={'120px'}
                              multiple={false}
                              mode={'dropdown'}
                              backgroundMode={this.mode}
                              selectDisabled={false}
                              showCancelIcon={false}
                              floatingLabel={true}
                              v-model={
                                this.exitIntent.campaignBetweenTimeframe.desktop
                                  .duration
                              }
                              onInput={(value) =>
                                this.onExitIntentInputChange(
                                  value,
                                  this.exitIntent,
                                  'campaignBetweenTimeframe.desktop.duration',
                                )
                              }
                            >
                              <option value={constants.LESS_THAN}>
                                Less than
                              </option>
                              <option value={constants.MORE_THAN}>
                                More than
                              </option>
                              <option value={constants.BETWEEN}>Between</option>
                            </CvtSelectInput>
                            <Input
                              type="number"
                              maxlength="2"
                              height="32px"
                              width="45px"
                              value={
                                this.exitIntent.campaignBetweenTimeframe.desktop
                                  .durationStartFrame
                              }
                              onChange={(event) =>
                                this.onExitIntentInputChange(
                                  +event.target.value,
                                  this.exitIntent,
                                  'campaignBetweenTimeframe.desktop.durationStartFrame',
                                )
                              }
                            />
                            {this.exitIntent.campaignBetweenTimeframe.desktop
                              .duration === constants.BETWEEN && (
                              <ElementWrapper
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <span>and</span>
                                <Input
                                  type="number"
                                  maxlength="2"
                                  height="32px"
                                  width="45px"
                                  value={
                                    this.exitIntent.campaignBetweenTimeframe
                                      .desktop.durationEndFrame
                                  }
                                  onChange={(event) =>
                                    this.onExitIntentInputChange(
                                      +event.target.value,
                                      this.exitIntent,
                                      'campaignBetweenTimeframe.desktop.durationEndFrame',
                                    )
                                  }
                                />
                                <CvtSelectInput
                                  width="110px"
                                  label={''}
                                  helpText={''}
                                  multiple={false}
                                  mode={'dropdown'}
                                  backgroundMode={this.mode}
                                  selectDisabled={false}
                                  showCancelIcon={false}
                                  floatingLabel={true}
                                  v-model={
                                    this.exitIntent.campaignBetweenTimeframe
                                      .desktop.durationTimeUnit
                                  }
                                  onInput={(value) =>
                                    this.onExitIntentInputChange(
                                      value,
                                      this.exitIntent,
                                      'campaignBetweenTimeframe.desktop.durationTimeUnit',
                                    )
                                  }
                                >
                                  <option value={constants.SECONDS}>
                                    Seconds
                                  </option>
                                  <option value={constants.MINUTES}>
                                    Minutes
                                  </option>
                                </CvtSelectInput>
                              </ElementWrapper>
                            )}

                            {this.exitIntent.campaignBetweenTimeframe.desktop
                              .duration === constants.BETWEEN ? (
                              <span>After page entry</span>
                            ) : (
                              <span>Seconds after page entry</span>
                            )}
                          </ElementWrapper>
                        )}

                        <ExitIntentInnerWrapperOneSubSection>
                          <Input
                            type={'checkbox'}
                            checked={
                              this.exitIntent.onExitSite.desktop.delayEnabled
                            }
                            onChange={(event) =>
                              this.onExitIntentInputChange(
                                event.target.checked,
                                this.exitIntent,
                                'onExitSite.desktop.delayEnabled',
                              )
                            }
                          />
                          <span>When the user exits, wait</span>
                          <Input
                            type={'number'}
                            maxlength={'2'}
                            height={'40px'}
                            width={'60px'}
                            value={
                              this.exitIntent.onExitSite.desktop.delayInSeconds
                            }
                            onChange={(event) =>
                              this.onExitIntentInputChange(
                                +event.target.value,
                                this.exitIntent,
                                'onExitSite.desktop.delayInSeconds',
                              )
                            }
                          />

                          <ElementWrapper width={'auto'}>
                            seconds before displaying the campaign
                          </ElementWrapper>
                        </ExitIntentInnerWrapperOneSubSection>
                      </ExitIntentInnerWrapperOne>

                      {this.exitIntent.action === constants.ON_EXIT_SITE && (
                        <ExitIntentInnerWrapperTwo>
                          <ExitIntentInnerWrapperTwoHeadingWrap>
                            <ExitIntentInnerWrapperTwoHeading>
                              Select when to open your campaign on mobile
                              devices
                            </ExitIntentInnerWrapperTwoHeading>
                            <ExitIntentInnerWrapperTwoSubHeading>
                              Learn More
                            </ExitIntentInnerWrapperTwoSubHeading>
                          </ExitIntentInnerWrapperTwoHeadingWrap>

                          <ElementWrapper width={'100%'}>
                            <RadioGroup
                              theme={''}
                              orientation={'vertical'}
                              size={'md'}
                              initialValue={
                                this.exitIntent.onExitSite.mobile.onScrollToTop
                                  ? constants.ON_SCROLL_TO_TOP
                                  : constants.ON_BACK_BUTTON_CLICKED
                              }
                            >
                              <Radio
                                border={false}
                                otherValue={constants.ON_SCROLL_TO_TOP}
                                onRadioClick={(value) => {
                                  this.onExitIntentMobileDeviceRadioBtnChange(
                                    value,
                                    this.exitIntent,
                                    'onExitSite.mobile',
                                  )
                                }}
                              >
                                Scroll up the page
                              </Radio>
                              <Radio
                                border={false}
                                otherValue={constants.ON_BACK_BUTTON_CLICKED}
                                onRadioClick={(value) => {
                                  this.onExitIntentMobileDeviceRadioBtnChange(
                                    value,
                                    this.exitIntent,
                                    'onExitSite.mobile',
                                  )
                                }}
                              >
                                Clicking back button until the previous visited
                                site
                              </Radio>
                            </RadioGroup>
                          </ElementWrapper>
                        </ExitIntentInnerWrapperTwo>
                      )}
                    </ExitIntentWrapper>
                  </SpecificOptionContentWrapper>
                )}

              {this.trigger === constants.MOUSE_POINTER &&
                this.showCssSelectorTrigger && (
                  <SpecificOptionContentWrapper>
                    <MousePointerHeading>
                      Copy the CSS selector of the HTML element: e.g{' '}
                      {`<div class="selector"></div>`}
                    </MousePointerHeading>

                    <MousePointerWrapper>
                      <MousePointerInnerWrapperOne>
                        <ElementWrapper width={'auto'}>
                          CSS Selector
                        </ElementWrapper>

                        <ElementWrapper width={'300px'}>
                          <Input
                            type="text"
                            height="45px"
                            width="300px"
                            value={this.mouse.cssSelector}
                            onInput={(event) =>
                              this.onMouseTriggerInputChange(
                                event.target.value,
                                this.mouse,
                                'cssSelector',
                              )
                            }
                          />
                        </ElementWrapper>
                      </MousePointerInnerWrapperOne>

                      <MousePointerInnerWrapperTwo>
                        <ElementWrapper width={'auto'}>
                          <RadioGroup
                            theme={''}
                            orientation={'vertical'}
                            size={'md'}
                            initialValue={this.mouse.action}
                          >
                            <Radio
                              border={false}
                              otherValue={constants.CLICK}
                              onRadioClick={(value) => {
                                this.onMouseTriggerRadioBtnChange(
                                  value,
                                  this.mouse,
                                  'action',
                                )
                              }}
                            >
                              Click or touch
                            </Radio>
                            <Radio
                              border={false}
                              otherValue={constants.HOVER}
                              onRadioClick={(value) => {
                                this.onMouseTriggerRadioBtnChange(
                                  value,
                                  this.mouse,
                                  'action',
                                )
                              }}
                            >
                              Hover (Desktop only)
                            </Radio>
                            <Radio
                              border={false}
                              otherValue={constants.OUT}
                              onRadioClick={(value) => {
                                this.onMouseTriggerRadioBtnChange(
                                  value,
                                  this.mouse,
                                  'action',
                                )
                              }}
                            >
                              Out (Desktop only)
                            </Radio>
                          </RadioGroup>
                        </ElementWrapper>
                      </MousePointerInnerWrapperTwo>
                    </MousePointerWrapper>
                  </SpecificOptionContentWrapper>
                )}

              {this.trigger === constants.EVENT && this.showEventTrigger && (
                <SpecificOptionContentWrapper>
                  <EventHeading>
                    Immediately open campaign on event trigger
                  </EventHeading>

                  <EventWrapper>
                    <EventInnerWrapperOne>
                      <EventInnerWrapperOneSubSectionOne>
                        <ElementWrapper width={'auto'}>
                          Convrrt Events
                        </ElementWrapper>

                        <ElementWrapper width={'300px'}>
                          <CvtInput mode={this.mode} type={'text'}></CvtInput>
                        </ElementWrapper>
                      </EventInnerWrapperOneSubSectionOne>

                      <EventInnerWrapperOneSubSectionTwo>
                        <MinusButton
                          mode={this.mode.toLowerCase()}
                          color={this.mode.toLowerCase()}
                          shape={'pill'}
                          size={'sm'}
                          icon={'minus'}
                          icononly={true}
                          onClick={(event) => {}}
                        ></MinusButton>
                      </EventInnerWrapperOneSubSectionTwo>
                    </EventInnerWrapperOne>

                    <EventInnerWrapperTwo>
                      <EventInnerWrapperTwoSubSectionOne>
                        <PlusButton
                          mode={this.mode.toLowerCase()}
                          color={this.mode.toLowerCase()}
                          shape={'pill'}
                          size={'sm'}
                          icon={'plus'}
                          icononly={true}
                          onClick={(event) => {}}
                        ></PlusButton>
                      </EventInnerWrapperTwoSubSectionOne>
                    </EventInnerWrapperTwo>

                    <EventInnerWrapperThree>
                      <ElementWrapper width={'auto'}>
                        <QuestionMarkButton
                          mode={this.mode.toLowerCase()}
                          color={this.mode.toLowerCase()}
                          shape={'pill'}
                          size={'md'}
                          icon={'question-circle'}
                          icononly={true}
                          onClick={(event) => {}}
                        ></QuestionMarkButton>
                      </ElementWrapper>

                      <ElementWrapper width={'auto'}>
                        Add Google Analytics Events
                      </ElementWrapper>
                    </EventInnerWrapperThree>
                  </EventWrapper>
                </SpecificOptionContentWrapper>
              )}
            </SectionRightContentWrapper>
          </SectionWrapper>
        )}

        {this.showPlacementOptions && (
          <SectionWrapper>
            <SectionLeftHeading>Placement</SectionLeftHeading>

            <SectionRightContentWrapper>
              <SectionRightContentHeading>
                Select where your campaign will be shown around the site
              </SectionRightContentHeading>

              <ElementWrapper>
                <cvt-radio-group
                  theme={'LIGHT'}
                  orientation={'horizontal'}
                  initialValue={this.placementType}
                >
                  <cvt-radio-button
                    size={'md'}
                    value={'allPages'}
                    onRadioClick={(value) => this.onPlacementTypeChange(value)}
                  >
                    All pages
                  </cvt-radio-button>
                  <cvt-radio-button
                    size={'md'}
                    value={'specificPages'}
                    onRadioClick={(value) => this.onPlacementTypeChange(value)}
                  >
                    Specific pages
                  </cvt-radio-button>
                </cvt-radio-group>
              </ElementWrapper>

              {this.placementType === 'specificPages' && (
                <SpecificOptionContentWrapper>
                  <PlacementTypeInnerWrapperOne>
                    <SpecificOptionHeading>
                      Select where to show your campaign
                    </SpecificOptionHeading>

                    <ElementWrapper width={'auto'}>
                      <RadioGroup
                        theme={''}
                        orientation={'horizontal'}
                        size={'md'}
                        initialValue={'allWebsites'}
                      >
                        <Radio
                          border={false}
                          otherValue={'allWebsites'}
                          onRadioClick={(val) => {}}
                        >
                          All Websites
                        </Radio>
                        <Radio
                          border={false}
                          otherValue={'homePage'}
                          onRadioClick={(val) => {}}
                        >
                          Home Page
                        </Radio>
                        <Radio
                          border={false}
                          otherValue={'firstLandingPage'}
                          onRadioClick={(val) => {}}
                        >
                          First Landing Page
                        </Radio>
                        <Radio
                          border={false}
                          otherValue={'userReachingPageNumber'}
                          onRadioClick={(val) => {}}
                        >
                          User Reaching Page Number
                        </Radio>
                      </RadioGroup>
                    </ElementWrapper>
                  </PlacementTypeInnerWrapperOne>

                  <PlacementTypeInnerWrapperTwo>
                    <SpecificOptionHeading>
                      Select where to show your campaign
                    </SpecificOptionHeading>

                    <PlacementTypeAddImportWrapper>
                      <ImportLink
                        text={'Import'}
                        icon={''}
                        size={'md'}
                        mode={this.mode}
                        color={''}
                        prepend={true}
                        onClick={(event) => {}}
                      ></ImportLink>

                      <AddLink
                        text={'Add'}
                        icon={''}
                        size={'md'}
                        mode={this.mode}
                        color={''}
                        prepend={true}
                        onClick={(event) => {}}
                      ></AddLink>

                      <PlusButton
                        mode={this.mode.toLowerCase()}
                        color={this.mode.toLowerCase()}
                        shape={'pill'}
                        size={'sm'}
                        icon={'plus'}
                        icononly={true}
                        onClick={(event) => {}}
                      ></PlusButton>
                    </PlacementTypeAddImportWrapper>
                  </PlacementTypeInnerWrapperTwo>
                </SpecificOptionContentWrapper>
              )}
            </SectionRightContentWrapper>
          </SectionWrapper>
        )}

        {this.showFrequencyOption && (
          <SectionWrapper>
            <SectionLeftHeading>Frequency</SectionLeftHeading>

            <FrequencyTypeWrapper>
              <SectionRightContentHeading>
                Select the number of times a visitor will see your campaign
              </SectionRightContentHeading>

              <cvt-radio-group
                theme={'LIGHT'}
                orientation={'horizontal'}
                initialValue={'oncePerPageLoad'}
              >
                <cvt-radio-button
                  size={'md'}
                  value={'oncePerPageLoad'}
                  onRadioClick={(value) => this.onFrequencyTypeChange(value)}
                >
                  Once per page load
                </cvt-radio-button>
                <cvt-radio-button
                  size={'md'}
                  value={'advanced'}
                  onRadioClick={(value) => this.onFrequencyTypeChange(value)}
                >
                  Advanced
                </cvt-radio-button>
              </cvt-radio-group>

              {this.frequencyType === 'advanced' && (
                <SpecificOptionContentWrapper>
                  <SpecificOptionHeading>
                    Show advance rules for your campaign
                  </SpecificOptionHeading>

                  <SpecificOptionInnerWrapper>
                    <SpecificOptionSelectInnerWrapper>
                      <ElementWrapper width={'200px'}>
                        <cvt-select
                          value={'showAgainAfter'}
                          label={''}
                          helpText={''}
                          multiple={false}
                          mode={'dropdown'}
                          backgroundMode={this.mode}
                          selectDisabled={false}
                          floatingLabel={true}
                          onInput={() => {}}
                        >
                          <option value={'showAgainAfter'}>
                            Show again after
                          </option>
                          <option value={'loremIpsum'}>Lorem Ipsum</option>
                          <option value={'dollarSit'}>Dollar Sit</option>
                        </cvt-select>
                      </ElementWrapper>

                      <ElementWrapper width={'100px'}>
                        <cvt-select
                          value={'5'}
                          label={''}
                          helpText={''}
                          multiple={false}
                          mode={'dropdown'}
                          backgroundMode={this.mode}
                          selectDisabled={false}
                          floatingLabel={true}
                          onInput={() => {}}
                        >
                          <option value={'5'}>5</option>
                          <option value={'4'}>4</option>
                          <option value={'3'}>3</option>
                        </cvt-select>
                      </ElementWrapper>

                      <ElementWrapper width={'auto'}>Day(s)</ElementWrapper>
                    </SpecificOptionSelectInnerWrapper>

                    <SpecificOptionPlusBtnInnerWrapper>
                      <PlusButton
                        mode={this.mode.toLowerCase()}
                        color={this.mode.toLowerCase()}
                        shape={'pill'}
                        size={'sm'}
                        icon={'plus'}
                        icononly={true}
                        onClick={(event) => {}}
                      ></PlusButton>
                    </SpecificOptionPlusBtnInnerWrapper>
                  </SpecificOptionInnerWrapper>
                </SpecificOptionContentWrapper>
              )}
            </FrequencyTypeWrapper>
          </SectionWrapper>
        )}
      </MainWrapper>
    )
  },
}
