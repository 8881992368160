import { styled, VueEmotion } from '@egoist/vue-emotion'
import VueI18n from 'vue-i18n'
import truncate from 'lodash/truncate'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import Vue from '../../../lib/utils/vue'
import { LEFT_SIDEBAR_FRESHWORKS, LEFT_SIDEBAR_STANDARD } from "../../constants";
import CvtButton from '../../molecules/Button'
import { ContentPlaceholder } from '../../molecules/ContentPlaceholder'
import { LazyImage } from '../../molecules/LazyImage'
import CvtSelect from '../../molecules/Select'
import CvtSwitch from '../../molecules/Switch';
import { CvtIcon } from '../../molecules/icon/icon'
import { CvtTooltip } from '../../molecules/tooltip/CvtTooltip'
import CvtHeading from './heading'
import { BOOKMARKS, STANDARD } from "../../../../builder/src/js/common/constants/constants";

Vue.use(VueEmotion)
Vue.use(VueI18n)

let Spacing, Radii, Border, AddSectionsToRight, borderColor, backgroundColor, textFontSize, textLineHeight, textFontWeight, elevation, color

// TODO: VUE3_UPDATE: Vue 2 work around for fragmenting in jsx, can be removed and it native to Vue 3
const Fragment = {
  functional: true,
  render: (h, ctx) => ctx.children,
}

const getMainWrapper = () => styled('div')`
  height: 100%
`

const getExpandedSections = () => styled('div')`
  width: 340px;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-gap: ${Spacing.SPACING_24}px;
  padding: ${Spacing.SPACING_24}px ${Spacing.SPACING_8}px;
  border-left: ${Border.SIZE_1}px solid;

  ${(props) =>
    props.type === LEFT_SIDEBAR_FRESHWORKS
      ? `
      position: fixed;
      top: 100px;
      right: 64px;
      background-color: #FFF;
      height: 100vh;
  `
      : ``}

  ${(props) =>
    props.mode == 'DARK'
      ? borderColor({ themeing: 'dark' }, 500)
      : borderColor({ themeing: 'dark' }, 100)};
`

const getAddBlankSectionWrapper = () => styled('div')`
  padding: 0 ${Spacing.SPACING_12}px;
  padding-bottom: ${Spacing.SPACING_24}px;
`

const AddBlankSectionBtn = styled(CvtButton)`
  ${(props) => (props.type !== LEFT_SIDEBAR_STANDARD ? `margin: auto;` : ``)}
`

const getSectionFilterWrapper = () => styled('div')`
  padding: 0 ${Spacing.SPACING_12}px;
  ${(props) =>
    props.side === true
      ? `
    border-top: 1px solid #CFD7DF;
    box-shadow: 0px 8px 4px rgb(7 126 214 / 6%);
    padding-top: 24px;
  `
      : ``}
`

const getSectionFilter = () => styled(CvtSelect)`
  ${color({ themeing: 'dark' }, 500)}
`

const getSectionsWrapper = () => styled('div')`
  overflow: auto;
  ${(props) => props.noPadding ? '' : `padding: 0 ${Spacing.SPACING_12}px;`}
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${Radii.RADIUS_PX_8}px;
    ${(props) =>
      props.mode == 'DARK'
        ? backgroundColor({ themeing: 'dark' }, 600)
        : backgroundColor({ themeing: 'dark' }, 100)}
  }

  ${(props) =>
    props.type === LEFT_SIDEBAR_FRESHWORKS
      ? `height: calc(100vh - 335px);`
      : ``}
`

const getSection = () => styled('div')`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  width: 300px;
  margin-bottom: ${Spacing.SPACING_24}px;

  &:last-child {
    margin-bottom: 0;
  }
`

const getSectionName = () => styled('span')`
  margin-bottom: ${Spacing.SPACING_4}px;
  ${textFontSize('sm')}
  ${textLineHeight('xl')}
`

const getSectionThumbnailWrapper = () => styled('div')`
  position: relative;
  overflow: hidden;
  border-radius: ${Radii.RADIUS_PX_8}px;
  ${(props) => elevation(props, 100)}

  &:hover {
    ${(props) => elevation(props, 400)}
    .overlay {
      display: flex;
    }
  }
`

const getSectionOverlay = () => styled('div')`
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  z-index: 1;
  ${backgroundColor({ themeing: 'dark' }, 100)}
  ${(props) =>
    props.mode == 'LIGHT'
      ? 'background: rgba(240, 242, 246, 0.5);'
      : 'background: rgb(73, 76, 83, 0.5);'}
  border-radius: ${Radii.RADIUS_PX_8}px;
`

const AddSectionBtn = styled(CvtButton)``

const SectionThumbnail = styled(LazyImage)``

const getPlaceholderWrapper = () => styled('div')`
  position: relative;
  width: 300px;
  padding-top: calc((100% * 190 / 300) + 16px);
  margin-bottom: ${Spacing.SPACING_16}px;
`

const getBookmarkPlaceholderWrapper = () => styled('div')`
position: relative;
width: 300px;
padding-top: calc((100% * 50 / 300) + 10px);
margin-bottom: ${Spacing.SPACING_4}px;
`

const getSectionHeading = () => styled('div')`
  display: flex;
  top: 50px;
  width: 311px;
`

const getCloseIcon = () => styled('div')`
  margin-left: auto;
`

const getPlaceholder = () => styled(ContentPlaceholder)`
  border-radius: ${Radii.RADIUS_PX_8}px;

  &.header-left {
    top: 0;
    left: 0;
    width: 70%;
    height: 16px;
    margin-bottom: ${Spacing.SPACING_8}px;
  }

  &.thumb-card {
    left: 0;
    width: 100%;
    height: calc(100% - 24px);
    top: 24px;
  }

  &.row-card {
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
  }
`

const getBlankText = () => styled('span')`
  display: flex;
  width: 300px;
  justify-content: center;
`

const getSectionSwitchWrap = () => styled('div')`
  display: flex;
  justify-content: center;
`

const getCvtSwitch = () => styled(CvtSwitch)`
  margin: ${Spacing.SPACING_4}px;
`;

const getBookmark = () => styled('div')`
  display: flex;
  align-items: center;
  border-bottom: ${Border.SIZE_1}px solid;
  margin-bottom: ${Spacing.SPACING_24}px;
  padding: 0 ${Spacing.SPACING_12}px;
  ${(props) =>
    props.mode == 'DARK'
      ? borderColor({ themeing: 'dark' }, 600)
      : borderColor({ themeing: 'dark' }, 100)}

  &:last-child {
    margin-bottom: 0;
  }
`

const getBookmarkName = () => styled('span')`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  padding: ${Spacing.SPACING_2}px;
  margin-right: 10px;
  margin-left: 10px;
  ${textFontSize('xl')}
  ${textLineHeight('lg')}
  ${textFontWeight('REGULAR')}

  &:focus {
    outline: none;
  }
`

const DeleteBookmarkIcon = styled(CvtIcon)`
  cursor: pointer;
  margin-left: 7px;
`

const AddBookmarkIcon = styled(CvtIcon)`
  cursor: pointer;
  margin-left: 7px;
`

const EditBookmarkIcon = styled(CvtIcon)`
cursor: pointer;
`


export const CvtSections = {
  name: 'CvtSections',
  components: {
    CvtTooltip,
  },
  data() {
    return {
      switchDirection: STANDARD,
      selectedSection: null
    }
  },
  computed: {
    ...mapGetters({
      isSwapBlockInProcess: getters.SWAP_BLOCK_IN_PROCESS,
    }),
    ...mapGetters('globalTheme', {
      textColor: getters.GLOBAL_STYLE_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      elevation: getters.GLOBAL_STYLE_ELEVATION,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      AddSectionsToRight: ({ globalTheme }) =>
        globalTheme.AddSectionsToRight !== undefined
          ? globalTheme.AddSectionsToRight
          : false,
      SectionGalleryLabel: ({ globalTheme }) =>
        globalTheme.SectionGalleryLabel !== undefined
          ? globalTheme.SectionGalleryLabel
          : 'editor.section.filter.dropdown.placeholder',
      iconConfig: ({ iconConfig }) => iconConfig
    }),
    currentSections() {
      return this.sections
    },
    enableBookmark() {
      // @TODO: feature flag bookmarking feature here
      return true
    }
  },
  methods: {
    onSwitchToggle(data) {
      this.switchDirection = data
      this.$emit("user-view-preference", data)
    },
    truncate(name) {
      return truncate(name, {
        length: 20,
        omission: '...',
      })
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
    loading: {
      type: Boolean,
      default: true
    },
    sections: {
      type: Array,
      default: () => []
    },
    sectionTags: {
      type: Array,
      default: () => ['ALL']
    },
    activeTag: {
      type: String,
      default: 'ALL'
    },
    type: {
      type: String,
      default: LEFT_SIDEBAR_STANDARD
    },
    category: {
      type: String,
      default: 'SECTIONS'
    },
    selectDisabled: {
      type: Boolean,
      default: false
    },
    showAddBlankSection: {
      type: Boolean,
      default: true
    },
    showAddSection: {
      type: Boolean,
      default: true
    },
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    AddSectionsToRight = this.AddSectionsToRight
    borderColor = this.borderColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    elevation = this.elevation
    color = this.textColor
    textFontWeight = this.textFontWeight
  },
  render: function (h) {
    const MainWrapper = getMainWrapper()
    const ExpandedSections = getExpandedSections()
    const AddBlankSectionWrapper = getAddBlankSectionWrapper()
    const SectionFilterWrapper = getSectionFilterWrapper()
    const SectionFilter = getSectionFilter()
    const SectionsWrapper = getSectionsWrapper()
    const Section = getSection()
    const SectionName = getSectionName()
    const SectionThumbnailWrapper = getSectionThumbnailWrapper()
    const SectionOverlay = getSectionOverlay()
    const PlaceholderWrapper = getPlaceholderWrapper()
    const BookmarkPlaceholderWrapper = getBookmarkPlaceholderWrapper()
    const Placeholder = getPlaceholder()
    const SectionHeading = getSectionHeading()
    const closeIcon = getCloseIcon()
    const BlankText = getBlankText()
    const SectionSwitch = getCvtSwitch();
    const SectionSwitchWrap = getSectionSwitchWrap()
    const Bookmark = getBookmark()
    const BookmarkName = getBookmarkName()

    const iconConfig = this.iconConfig?.leftSidebar?.layout
    const blankSectionIcon = this.AddSectionsToRight
      ? this.iconConfig?.leftSidebar?.pages?.plus
      : ''

    const sections = []
    const uniqueKeys = new Set();
    const placeholders = Array(3).fill(
      <PlaceholderWrapper>
        <Placeholder class='header-left' mode={this.mode}></Placeholder>
        <Placeholder class='thumb-card' mode={this.mode}></Placeholder>
      </PlaceholderWrapper>
    )

    const bookmarkPlaceholders = Array(4).fill(
      <BookmarkPlaceholderWrapper>
        <Placeholder class='row-card' mode={this.mode}></Placeholder>
      </BookmarkPlaceholderWrapper>
    )

    this.currentSections.forEach((section) => {
      if (!uniqueKeys.has(section['.key'])) {
        uniqueKeys.add(section['.key']);

        if (this.switchDirection === BOOKMARKS) {
          const isEditable = this.selectedSection && this.selectedSection['.key'] === section['.key']
          return sections.push(
            <Bookmark id={section.name} name={section.name} key={section['.key']}>
              <CvtIcon icon="cvt-icon-layers"></CvtIcon>
              
              <BookmarkName 
                title={section.name}
                contentEditable={isEditable}
                suppressContentEditableWarning={true}
                onBlur={({ target }) => {
                  this.$emit('edit-bookmark-name', this.selectedSection, target.innerText)
                  this.selectedSection = null
                }}
                style={{ border: isEditable ? '0.5px solid gray' : 'none' }}
              >
                {isEditable ? section.name : this.truncate(section.name)}
              </BookmarkName>

              <span>
                <EditBookmarkIcon 
                  id={section.name + '-bookmark-edit'}
                  icon="pen"
                  nativeOnClick={(e) => this.selectedSection = section}
                ></EditBookmarkIcon>
                <CvtTooltip
                  placement="top"
                  offset="5"
                  fallbackPlacement="right"
                  delay="100"
                  color="dark"
                  mode={this.mode}
                  noFade={true}
                  innerPadding={8}
                  ref="tooltip"
                  target={section.name + '-bookmark-edit'}
                >
                  Edit
                </CvtTooltip>
              </span>

              <span>
                <AddBookmarkIcon 
                  id={section.name + '-bookmark-add'}
                  icon="cvt-icon-plus"
                  nativeOnClick={() => this.$emit('insert-block', section)}
                ></AddBookmarkIcon>
                <CvtTooltip
                  placement="top"
                  offset="5"
                  fallbackPlacement="right"
                  delay="100"
                  color="dark"
                  mode={this.mode}
                  noFade={true}
                  innerPadding={8}
                  ref="tooltip"
                  target={section.name + '-bookmark-add'}
                >
                  Add to page
                </CvtTooltip>
              </span>

              <span>
                <DeleteBookmarkIcon 
                  id={section.name + '-bookmark-delete'}
                  icon="cvt-icon-trashcan"
                  nativeOnClick={(e) => this.$emit("delete-bookmark-dialog", section)}
                ></DeleteBookmarkIcon>
                <CvtTooltip
                  placement="top"
                  offset="5"
                  fallbackPlacement="right"
                  delay="100"
                  color="dark"
                  mode={this.mode}
                  noFade={true}
                  innerPadding={8}
                  ref="tooltip"
                  target={section.name + '-bookmark-delete'}
                >
                  Delete
                </CvtTooltip>
              </span>
            </Bookmark>
          )
        }

        sections.push(
          <Section id={section.name} name={section.name} key={section['.key']}>
            <SectionName>{section.name}</SectionName>
            <SectionThumbnailWrapper mode={this.mode}>
              <SectionOverlay mode={this.mode} class={'overlay'}>
                {this.showAddSection && (
                    <AddSectionBtn
                      color={this.type === LEFT_SIDEBAR_FRESHWORKS ? 'light' : ''}
                      mode={this.mode}
                      shadow={true}
                      text={this.category === 'SECTIONS' ? this.$t('editor.section.action.add_section_btn_text') : 'Add this Popup'}
                      onClick={() => this.$emit('insert-block', section)}
                    ></AddSectionBtn>
                )}
  
                {this.isSwapBlockInProcess && (
                  <AddSectionBtn
                    color={this.type === LEFT_SIDEBAR_FRESHWORKS ? 'light' : ''}
                    mode={this.mode}
                    shadow={true}
                    text={'Swap Section'}
                    onClick={() => this.$emit('swap-block', section)}
                  ></AddSectionBtn>
                )}
              </SectionOverlay>
              <SectionThumbnail
                mode={this.mode}
                dataSrc={section.thumbnail}
              ></SectionThumbnail>
            </SectionThumbnailWrapper>
          </Section>
        )
      }
    })

    const blankText = <BlankText>{this.$t('editor.left_nav.sections.sections_wrapper.blank_text')}</BlankText>

    return (
      <MainWrapper data-identity="cvt-sections">
        {this.type === LEFT_SIDEBAR_FRESHWORKS && (
          <CvtHeading>
            <SectionHeading>
              <div>
                {this.category === 'SECTIONS' ? 'SECTION' : 'POPUP'} {this.$t('editor.left_nav.sections.heading')}
              </div>
              <closeIcon onClick={() => this.$emit('close-section')}>
                <i class="fas fa-times"></i>
              </closeIcon>
            </SectionHeading>
          </CvtHeading>
        )}
        <ExpandedSections mode={this.mode} type={this.type}>
          {this.category === 'SECTIONS' && (
            <div>
              {this.showAddBlankSection && (
                <AddBlankSectionWrapper>
                  <AddBlankSectionBtn
                    mode={this.mode}
                    color={this.type === LEFT_SIDEBAR_FRESHWORKS ? `light` : ``}
                    size={this.type === LEFT_SIDEBAR_FRESHWORKS ? `sm` : ``}
                    fullWidth={this.type === LEFT_SIDEBAR_STANDARD ? true : false}
                    shadow={true}
                    text={this.$t('editor.section.blank_cta')}
                    onClick={() => this.$emit('insert-blank')}
                    type={this.type}
                    outlined={this.AddSectionsToRight}
                    icon={blankSectionIcon}
                  ></AddBlankSectionBtn>
                </AddBlankSectionWrapper>
              )}
              {this.enableBookmark && (
                <SectionSwitchWrap>
                  <SectionSwitch
                    size="md"
                    color="grey"
                    type="rounded"
                    descriptionType="text"
                    inactiveText={STANDARD}
                    activeText={BOOKMARKS}
                    initialState={this.switchDirection === BOOKMARKS}
                    onSwitchToggle={data => this.onSwitchToggle(data)}
                  >
                  </SectionSwitch>
                </SectionSwitchWrap>
              )}
            </div>
          )}

          {this.switchDirection === STANDARD && (
            <Fragment>
              {this.category === 'SECTIONS' && (
                <div>
                  <SectionFilterWrapper side={this.AddSectionsToRight}>
                    {this.type === LEFT_SIDEBAR_FRESHWORKS && (
                      <h5>{this.$t('editor.header.marketplace.title')}</h5>
                    )}
                    {this.sectionTags.length > 0 && this.showAddBlankSection && (
                      <SectionFilter
                        label={this.$t(this.SectionGalleryLabel)}
                        floatingLabel={
                          this.type === LEFT_SIDEBAR_STANDARD ? true : false
                        }
                        mode="dropdown"
                        backgroundMode={this.mode}
                        value={this.activeTag}
                        defaultValue={this.activeTag}
                        showCancelIcon={false}
                        onInput={(value) => {
                          this.$emit('filter-section', value)
                        }}
                        selectDisabled={this.selectDisabled}
                      >
                        <option value={'ALL'}>ALL</option>
                        {this.sectionTags.map((option) => {
                          return <option value={option}>{option}</option>
                        })}
                      </SectionFilter>
                    )}
                  </SectionFilterWrapper>
                </div>
              )}
              <SectionsWrapper mode={this.mode} type={this.type}>
                {this.loading && placeholders}{' '}
                {!this.loading && (sections.length > 0 ? sections : blankText)}
              </SectionsWrapper>
            </Fragment>
          )}

          {this.switchDirection === BOOKMARKS && (
            <Fragment>
              <SectionsWrapper noPadding mode={this.mode} type={this.type}>
                {this.loading && bookmarkPlaceholders}{' '}
                {!this.loading && (sections.length > 0 ? sections : blankText)}
              </SectionsWrapper>
            </Fragment>
          )}
        </ExpandedSections>
      </MainWrapper>
    )
  }
}
