import Vue from '../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import * as getters from '../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import CvtSiteStatusLabel from '../molecules/SiteStatusLabel'
import CvtButton from '../molecules/Button'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

let Spacing, FontSize, Radii, Border, Colors, textLineHeight, BorderStyle, textFontWeight, borderColor, backgroundColor, textFontSize, textColor, LineHeight;

const getContainer = () => styled('div')`
  height: ${Spacing.SPACING_64 + Spacing.SPACING_8}px;
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: ${Border.SIZE_1}px;
  border-style: ${BorderStyle.SOLID};
  ${borderColor({ themeing: 'dark' }, 100)};
`

const getInnerContainer = () => styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const getLabelContainer = () => styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  padding-left: 35px;
`

const getButtonsContainer = () => styled('div')`
  display: flex;
  align-items: center;
  width: 60%;
  display: flex;
  justify-content: end;
  padding-right: 42px;
`

const getTemplateTitle = () => styled('div')`
  align-self: center;
`

const getSiteStatusLabel = () => styled(CvtSiteStatusLabel)``

const getCopyTemplateButton = () => styled(CvtButton)`
  height: 44px;
  display: flex;
  align-items: center !important;
  border-radius: ${Spacing.SPACING_4}px !important;
`

const getEditTemplateButton = () => styled(CvtButton)`
  height: 44px;
  display: flex;
  align-items: center !important;
  border-radius: ${Spacing.SPACING_4}px !important;
  margin-left: ${Spacing.SPACING_8 + Spacing.SPACING_2}px;
`

const getArchiveButton = () => styled(CvtButton)`
  display: flex;
  align-items: center !important;
  border-radius: ${Spacing.SPACING_4}px !important;
  margin-left: ${Spacing.SPACING_8 + Spacing.SPACING_2}px;
  height: 44px;
`

const getActionsButton = () => styled(CvtButton)`
  height: 44px;
  display: flex;
  align-items: center !important;
  text-align: center;
  font-style: normal;
  margin-left: ${Spacing.SPACING_8 + Spacing.SPACING_2}px;
  border-radius: ${Spacing.SPACING_4}px !important;
  padding: 10px 15px !important;
`

export const CvtPopupListItem = {
  name: 'CvtPopupListItem',
  data () {
    return {}
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
      required: false
    },
    templateTitle: {
      type: String,
      required: true,
    },
    siteStatus: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
    }),

    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      BorderStyle: ({ globalTheme }) => globalTheme.BorderStyle,
      LineHeight: ({ globalTheme }) => globalTheme.LineHeight,
      FontSize: ({globalTheme}) => globalTheme.FontSize,
      ypCopyPopupBtnConfig: ({ globalButtonConfig }) => globalButtonConfig?.locations.sdaPopupYourPopups.locations.copyPopup.props,
      ypEditPopupBtnConfig: ({ globalButtonConfig }) => globalButtonConfig?.locations.sdaPopupYourPopups.locations.editPopup.props,
      ypArchivePopupBtnConfig: ({ globalButtonConfig }) => globalButtonConfig?.locations.sdaPopupYourPopups.locations.archivePopup.props,
      ypMoreActionsPopupBtnConfig: ({ globalButtonConfig }) => globalButtonConfig?.locations.sdaPopupYourPopups.locations.moreActionPopup.props,
    })
  },
  created () {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    Colors = this.Colors
    BorderStyle = this.BorderStyle
    LineHeight = this.LineHeight
    FontSize = this.FontSize

    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    textColor = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
  },
  methods: { 
    copyTemplateBtnClicked(event){
      this.$emit('copyTemplateBtnClicked', event);
    },
    
    editSiteBtnClicked(event){
      this.$emit('editSiteBtnClicked', event);
    },
    
    archiveTemplateBtnClicked(event){
      this.$emit('archiveTemplateBtnClicked', event);
    },

    moreActionsBtnClicked(event){
      this.$emit('moreActionsBtnClicked', event);
    },
  },
  render: function (h) {

    const Container = getContainer();
    const InnerContainer = getInnerContainer();
    const TemplateTitle = getTemplateTitle();
    const LabelContainer = getLabelContainer();
    const ButtonContainer = getButtonsContainer();
    const SiteStatusLabel = getSiteStatusLabel();
    const CopyTemplateButton = getCopyTemplateButton();
    const EditTemplateButton = getEditTemplateButton();
    const ArchiveButton = getArchiveButton();
    const ActionsButton = getActionsButton();

    return (
      <Container>

        <InnerContainer>
        
          <LabelContainer>

            <TemplateTitle>
              {this.templateTitle}
            </TemplateTitle>

            <SiteStatusLabel
              status={this.siteStatus}
              width={77}
              height={44}
              usage="popup"
            ></SiteStatusLabel>

          </LabelContainer>

          <ButtonContainer> 
            <CopyTemplateButton
              mode={this.mode}
              text={this.$t('marketplace.templates.label.copy_popup')}
              icon={'clone'}
              size={'xs'}
              specialPadding={`9px 9px`}
              prepend={true}
              color={this.ypCopyPopupBtnConfig.color}
              specialStyle={this.ypCopyPopupBtnConfig.specialStyle}
              outlined={this.ypCopyPopupBtnConfig.outlined}
              onClick={(event)=> this.copyTemplateBtnClicked(event)}
            ></CopyTemplateButton> 

            <EditTemplateButton 
              text={this.$t('marketplace.templates.label.edit_popup')}
              mode={this.mode.toLowerCase()}
              icon={'pencil'}
              size={'xs'}
              specialPadding={`9px ${Spacing.SPACING_16}px`}
              actionIcon={false}
              icononly={false}
              prepend={true}
              color={this.ypEditPopupBtnConfig.color}
              specialStyle={this.ypEditPopupBtnConfig.specialStyle}
              outlined={this.ypEditPopupBtnConfig.outlined}
              onClick={(event)=> this.editSiteBtnClicked(event)}
            ></EditTemplateButton>

            <ArchiveButton
              text={this.$t('marketplace.templates.label.archive_popup')}
              mode={this.mode.toLowerCase()}
              icon={'archive'}
              size={'xs'}
              specialPadding={`9px ${Spacing.SPACING_16}px`}
              actionIcon={false}
              icononly={false}
              prepend={true}
              color={this.ypArchivePopupBtnConfig.color}
              specialStyle={this.ypArchivePopupBtnConfig.specialStyle}
              outlined={this.ypArchivePopupBtnConfig.outlined}
              onClick={(event)=> this.archiveTemplateBtnClicked(event)}
            ></ArchiveButton>

            <ActionsButton
              mode={this.mode.toLowerCase()}
              shape={'pill'}
              size={'md'}
              icon={'ellipsis-h'}
              icononly={true}
              color={this.ypMoreActionsPopupBtnConfig.color}
              specialStyle={this.ypMoreActionsPopupBtnConfig.specialStyle}
              outlined={this.ypMoreActionsPopupBtnConfig.outlined}
              onClick={(event) => this.moreActionsBtnClicked(event)}
            ></ActionsButton>

          </ButtonContainer>
        </InnerContainer>

      </Container>
    )
  }
}