import * as _ from 'lodash'
import * as getters from '../getters';
import * as mutations from '../mutations';
import * as actions from '../actions';
import { database } from '../../services/firebase.service'
import { SDA_POPUP_SETTINGS } from '../../../common/types/vuex'
import { DEFAULT_SDA_POPUP_SETTINGS } from '../../../common/defaults/popup/settings'

const db = database()

export default {
  state: {
    settings: {},
    settingsRef: null,
  },
  getters: {
    [getters.SDA_POPUP_SETTINGS]({ settings }: { settings: SDA_POPUP_SETTINGS }) {
      return settings
    },
  },
  mutations: {
    [mutations.SDA_POPUP_SETTINGS_UPDATE_DATA](state, settings: SDA_POPUP_SETTINGS) {
      state.settings = settings
    },
  },
  actions: {
    [actions.SDA_POPUP_SETTINGS_INIT]({ state, commit }, popupSitefirebasePath: string) {
      if (state.settingsRef) {
        state.settingsRef.off('value')
      }

      state.settingsRef = db.ref(popupSitefirebasePath).child('settings')

      state.settingsRef.on('value', (snap: any) => {
        let settings = snap.val()
        
        if (settings) {
          commit(mutations.SDA_POPUP_SETTINGS_UPDATE_DATA, settings)
        } else {
          // if setting node is not found in firebase, then we are setting-up the default-values there.
          db.ref(popupSitefirebasePath).child('settings').set(DEFAULT_SDA_POPUP_SETTINGS)
        }
      })
    },
    [actions.SDA_POPUP_SETTINGS_UPDATE_PROP]({ state }, { key, value }) {
      return state.settingsRef.child(`${key}`).set(value)
    },
  },
}
