
<div class="wrapper-class">
  <cvt-layouts
    :parent="parent"
    :parent-name="fmtName(parent)"
    :has-parent="hasParent"
    :button-text="parentIsColumn ? `Add Row`: $t(snakeCase(addBtnTxt) + '.cta')"
    :total-nodes="editorNodes.length"
    :type="$attrs.type"
    @parent-layout-setting.stop="select(parent)"
    @parent-layout-copy.stop="duplicateParent(parent)"
    @parent-layout-delete.stop="removeParent(parent)"
    @parent-layout-mouse-over.stop
    @parent-layout-mouse-enter.stop="highlight(parent)"
    @parent-layout-click.stop="goBack(parent)"
    @add-node.stop="parentIsColumn ? addRow() : addObject()"
    @move-node="(from, to) => moveNode(from, to)"
  >
    <cvt-layout-section
      v-for="(node, idx) in editorNodes"
      :key="`${fmtName(node, idx)}-id`"
      :data-id="idx"
      :name="`${fmtName(node, idx).toLowerCase().replace(' ', '-')}-id`"
      :label="fmtName(node, idx)"
      :type="$attrs.type"
      @layout-mouse-enter="highlight(node)"
      @layout-click="enterNode(node)"
      @layout-setting.stop="targetAndSelect(node)"
      @layout-copy.stop="duplicate(node)"
      @layout-delete.stop="remove(node)"
    />
  </cvt-layouts>
</div>
