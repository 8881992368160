
<cvt-collapse
  :accordion="true"
  :theme="mode === 'LIGHT' ? 'light' : 'dark'"
  :prepend-chevron="prependChevron"
>
  <cvt-collapse-item
    title="HTML"
    name="html"
    :initial-active="true"
    :special-style="isSpecialStyle"
  >
    <cvt-alert
      color="primary"
      icon="info-circle"
    >
      {{ $t("element.property.code.helper.label") }}
    </cvt-alert>
    <ace
      ref="ace"
      lang="html"
      :code-snippet-html="codeSnippetHtml"
      @change="updateCodeSnippet"
    />
    <strong><i /></strong>
  </cvt-collapse-item>
</cvt-collapse>
