/**
 * This is a map of constants to event names
 * All events for the hub should be defined here and the key's should be used
 * for easy regactoring and maintainability
 */
export const EVENT_KEY_EXAMPLE = 'event:key:example:noop'
export const SELECTOR_SELECTION_CHANGED = 'selector:selection:changed'
export const SELECTOR_DESELECTED = 'selector:deselected'
export const IMAGE_SEARCH_SELECTED = 'image:search:selected'
export const ELEMENT_DROPPED_ON_GRID_CONTAINER = 'element:dropped:on:grid:container'
export const ELEMENT_DROPPED_ON_SUB_GRID_CONTAINER = 'element:dropped:on:sub:grid:container'
export const PAGE_ENGINE_VDOM_UPDATE = 'page:engine:vdom:update'
export const PAGE_ENGINE_RELOAD = 'page:engine:reload'
