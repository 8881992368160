import Vue from 'vue'
import { VueEmotion } from '@egoist/vue-emotion'
import { CvtTooltip } from './tooltip/CvtTooltip'

Vue.use(VueEmotion)

export default {
  name: 'CvtPopover',
  model: {
    prop: 'show'
  },
  props: {
    arrow: {
      type: Boolean,
      default: true
    },
    show: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    arrowClass: {
      type: [Object, Array, String],
      default: undefined
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    target: {
      // String ID of element, or element/component reference
      // Or function that returns one of the above
      type: [String, HTMLElement, Function, Object],
      // default: undefined,
      required: true
    },
    offset: {
      type: Number,
      default: 8
    },
    mode: {
      type: String,
      default: 'LIGHT'
    },
    color: {
      type: String,
      default: 'light'
    },
    triggers: {
      type: String,
      default: 'click'
    },
    innerPadding: {
      type: Number,
      default: 16
    },
    borderRadius: {
      type: Number,
      default: 8
    }
  },
  data () {
    return {}
  },
  methods: {
    setStore (nodes) {
      nodes.map(node => {
        if (node.componentOptions && node.componentOptions.Ctor) {
          node.componentOptions.Ctor.prototype.$store = this.$store
        }
        if (node.children) {
          return this.setStore(node.children)
        }
        return node
      })

      return nodes
    }
  },
  render: function (h) {
    return (
      <CvtTooltip
        ref='tooltip'
        boundary-padding='8'
        offset={this.offset}
        target={this.target}
        color={this.color}
        mode={this.mode}
        placement={this.placement}
        triggers={this.triggers}
        innerPadding={this.innerPadding}
        borderRadius={this.borderRadius}
      >
        {this.setStore(this.$slots.popper || this.$vnode.data.popper)}
      </CvtTooltip>
    )
  }
}
