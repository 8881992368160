
<div>
  <h1>Upload</h1>
  <h4 class="mt-3">
    FileList
  </h4>
  <div
    v-for="(listThumnailOrIcon, index) in listThumbnailIcon"
    :key="index"
  >
    <span>{{ index }}</span>
    <cvt-file-list
      :files="files"
      :remove-enabled="true"
      :thumbnails-enabled="listThumnailOrIcon === 'thumbnail'"
      :icons-enabled="listThumnailOrIcon === 'icon'"
      thumbnail-prop="thumbnail"
      description-prop="name"
      fallback-icon="file"
      :fallback-thumbnail="'http://longwoodinteriors.com/wp-content/uploads/2014/04/placeholder3.png'"
      :is-card="false"
      :show-separator="false"
      @remove="onRemove"
    >
      <template #noItemAvailable>
        <span>{{ customMessage }}</span>
      </template>
    </cvt-file-list>
  </div>

  <h4 class="mt-3">
    Upload
  </h4>
  <span>Default</span>
  <cvt-upload
    class="mt-3"
    :remove-enabled="true"
    :icons-enabled="true"
    :thumbnails-enabled="false"
    fallback-icon="file"
    :manual-upload="false"
    :plain="false"
    :disabled="false"
    :limit="10000000"
    :accept="'*'"
    :multiple="false"
    :action="'http://localhost:3000/file_upload/'"
    :webkitdirectory="false"
  />
  <span>Default</span>
  <cvt-upload
    class="mt-3"
    :remove-enabled="true"
    :icons-enabled="true"
    :thumbnails-enabled="false"
    fallback-icon="file"
    :manual-upload="true"
    :plain="true"
    :disabled="false"
    :limit="10000000"
    :accept="'*'"
    :multiple="false"
    :action="'http://localhost:3000/file_upload/'"
    :webkitdirectory="false"
  />
</div>
