import {BackgroundShapeSVGObject} from "../BackgroundShapeSVGObject";

const arrowSvg = `
<svg class="svg" data-name="Arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
    <path d="M649.97 0L550.03 0 599.91 54.12 649.97 0z" class="fill"></path>
</svg>`

export const ArrowShape = new BackgroundShapeSVGObject({
    name: "Arrow",
    canInvert: false,
    canFlip: true,
    svg: arrowSvg
})