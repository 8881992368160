
<div data-identity="AddImageUrl">
  <h4 class="admin-label">
    {{ label }}
  </h4>
  <cvt-input
    type="text"
    name="imageUrl"
    placeholder="https://"
    :clearable="false"
    :value="imageUrl"
    @input="(event) => { $emit('siteImageUrlChange', event) }"
  />
</div>
