import { Organization } from '../../services/api'
import * as getters from '../getters'
import * as mutations from '../mutations'
import * as actions from '../actions'

const organization = new Organization()

export default {
  state: {
    users: {},
  },
  getters: {
    [getters.IT_TEAM]({ users }) {
      return users
    },
  },
  mutations: {
    [mutations.SET_IT_TEAM](state, users) {
      state.users = users
    },
  },
  actions: {
    [actions.FETCH_IT_TEAM]({ commit }) {
      return organization.fetchITMember().then((res) => {
        commit(mutations.SET_IT_TEAM, res.data)
      })
    },
    [actions.CREATE_IT_MEMBER]({ dispatch }, data) {
      return organization.inviteITMember(data)
    },
    [actions.DELETE_IT_MEMBER]({ dispatch, commit }, id) {
      return organization.deleteITMember(id).then(() => {
        dispatch(actions.FETCH_IT_TEAM)
      })
    },
    [actions.RESEND_IT_INVITE]({ dispatch, commit }, id) {
      return organization.resendITInvite(id)
    },
  },
}
