
  <div class="form-group">
    <el-container v-if="multiple" class="layout-container">
      <div class="mt-3">
        <cvt-label
        mode='label'
        :label="$t('setup.font.weight')"
          />
      </div>
      <el-select
      v-model="fontWeight"
      multiple
      class="pb-3"
      :label="$t('setup.font.weight')"
      @change="(value) => updateFontWeight(value)"
      >
    <el-option
    v-for="(weight,index) in  options" 
    :key="index"
      :label="weight.label"
      :value="weight.value"
      >
    </el-option>
  </el-select>
</el-container>
    <div v-if="!multiple">
      <cvt-select
      :value="fontWeight"
      mode="dropdown"
      :label="$t('setup.font.weight')"
      @input="(value) => updateFontWeight(value)"
    >
      <option v-for="weight in fontWeights" :key="weight.value" :value="weight.value" v-text="weight.label" />
    </cvt-select>
    </div>
  </div>
