import Vue from '../../lib/utils/vue'
import { VueEmotion, styled } from '@egoist/vue-emotion'
import * as getters from '../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'

Vue.use(VueEmotion)
let Spacing
let TextFontSize, TextFontWeight

  const getLoadingContainer = () => styled('div')`
    ${TextFontSize('xl')};
    ${TextFontWeight('regular')};
    text-align: center;
    padding: ${Spacing.SPACING_2+Spacing.SPACING_8}px;

    ${(props) => {
      if( !props.display ){
        return 'display: none;'
      }
    }}

    &:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
      animation: ellipsis steps(4,end) 900ms infinite;
      content: "...";
      width: 0px;
    }
    
    @keyframes ellipsis {
      to {
        width: ${Spacing.SPACING_2+Spacing.SPACING_18}px;    
      }
    }
    
    @-webkit-keyframes ellipsis {
      to {
        width: ${Spacing.SPACING_2+Spacing.SPACING_18}pxpx;    
      }
    }
  `

export default {
  name: 'CvtLoadingMessage',

  data() {
    return { }
  },

  props: {
    message: {
      type: String,
      default: 'Loading'
    },
    display: {
      type: Boolean,
      default: true,
    }
  },

  created () {
    TextFontSize = this.TextFontSize
    TextFontWeight = this.TextFontWeight

    Spacing = this.Spacing
  },

  computed: {
    ...mapGetters('globalTheme', {
      TextFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      TextFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT
    }),

    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
    }),

  },

  methods: {
  },

  render: function (h) {
    const LoadingContainer = getLoadingContainer()

    return (
      <LoadingContainer
        display={this.display}>
        {this.message}
      </LoadingContainer>
    )
  }
}
