
<el-dialog
  :visible.sync="visible"
  size="tiny"
  @open="open"
  @close="close"
>
  <span
    slot="title"
    class="save-block-title"
  >
    {{ $t('editor.save_block') }}
  </span>

  <div class="row">
    <el-input
      v-model="block.name"
      class="col-12 mb-2"
      placeholder="Name the block"
      autofocus
    />
    <el-input
      v-model="block.thumbnail"
      class="col-12 mb-2"
      placeholder="Url to thumbnail"
    />

    <el-select
      v-show="block"
      v-model="block.tags"
      class="col-12"
      multiple
      filterable
      allow-create
      placeholder="Apply block tags"
    >
      <el-option
        v-for="tag in tags"
        :key="tag"
        :label="tag"
        :value="tag"
      />
    </el-select>
  </div>

  <span
    slot="footer"
    class="dialog-footer"
  >
    <div class="row">
      <cvt-switch
              class="col-1"
              size="sm"
              color="active"
              type="circular"
              description-type="default"
              :initial-state="false"
              @switchToggle="(e) => saveBlockGlobally(e)"
      />
      <span class="col-2">{{
          $t(
              'editor.left_nav.pages.page_actions_istemplate.popover.save_page_globally',
          )
          }}</span>
      <div class="col-9 mr-0">
        <el-button @click="cancel">{{ $t('element.property.cta.cancel') }}</el-button>
        <el-button
            type="primary"
            :disabled="isSaving"
            @click="updateAndConfirm">{{ $t('element.property.cta.confirm') }}</el-button>
      </div>
    </div>
  </span>
</el-dialog>
