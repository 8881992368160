<template>
  <div
    :id="vnodeId"
    :data-vnode-id="vnodeId"
    class="cvt-editor-image"
    :class="classes"
    draggable="true"
    @click="select"
    @mouseover="target"
    @contextmenu.prevent.stop="showContextMenu"
  >
    <div :class="imgWrapperClasses">
      <a v-if="href" :href="href" target="_blank">
        <img
          class="img-fluid"
          :class="imgClasses"
          :style="imgStyles"
          :src="altSrc"
          :alt="alt"
        />
      </a>
      <img
        v-else
        class="img-fluid"
        :class="imgClasses"
        :style="imgStyles"
        :src="altSrc"
        :alt="alt"
      />
    </div>
    <template v-if="showActionButtonsOnClick && !isGridBuilder">
      <!--THis is only here because of freshwork in v1 gridbuilder,
      once they migrate to v2 or v3, it will become irrelevant-->
      <action-buttons
        v-if="selected"
        @duplicate="duplicateAndValidate"
        @remove="removeAndValidate"
      />
    </template>
  </div>
</template>

<script>
import FbVNode from '../../base/FbVNode.vue'

const IMG_ALIGNMENT = {
  'mx-auto': 'text-center',
  'ml-auto': 'text-right',
  'mr-auto': 'text-left',
}

export default FbVNode.extend({
  props: {
    src: String,
    width: Number,
    shadow: String, // shadow or shadown--deep
    radius: String, // rounded or circle
    alt: String,
    href: String,
  },
  data() {
    return {
      name: 'CSurvey',
    }
  },
  computed: {
    imgWrapperClasses() {
      return {
        [this.stylesheet.classes.spacing]: true,
        [IMG_ALIGNMENT[this.bootstrapAlignment]]: true,
      }
    },
    imgClasses() {
      return {
        [this.shadow]: this.shadow.length,
        [this.radius]: this.radius.length,
      }
    },
    imgStyles() {
      return {
        width: `${this.width}%`,
      }
    },
    altSrc() {
      return this.src || '/img/img-placeholder.svg'
    },
    styles() {
      return {
        spacing: {
          ...this.spacingStyle,
        },
      }
    },
  },
  methods: {
    updateSrc(assets) {
      if (!assets) return
      this.updateProp('src', assets.image ? assets.image : assets.pop())
    },
  },
})
</script>

<style lang="scss">
@import '@/scss/utils';
.cvt-editor-image {
  position: relative;
}

.img-fluid {
  position: relative;

  @media (max-width: 768px) {
    width: inherit !important;
  }
}
</style>
