<template>
  <GenAILayout>
    <div class="landingpage-goal-container">
      <div class="landingpage-goal-inner-container">
        <div class="landingpage-goal-wrapper">
          <h1 class="landingpage-goal-header">
            {{ $t('gen_ai.landingpage_goal.header.text') }}
          </h1>

          <div
            v-for="(intent, index) in intents"
            :key="index"
            class="landingpage-goal-card-wrapper"
          >
            <cvt-goal-card
              :header-text="intent.text"
              :icon="intent.icon"
              :is-active="selectedIntent === intent.intent"
              :value="intent.intent"
              @click="(value) => onClickGoalCard(value)"
            >
            </cvt-goal-card>
          </div>
        </div>
      </div>
      <hr />
      <div class="landingpage-goal-wrap">
        <div class="landingpage-goal-inner-btn-wrap">
          <cvt-button
            class="landingpage-goal-btn skip"
            :text="$t('editor.footer.back')"
            shape="round"
            color="light"
            :prepend="false"
            @click="handleBack"
          ></cvt-button>

          <cvt-button
            class="landingpage-goal-btn continue"
            :text="$t('custom_domain.ui.unlink_dialog.buttons.continue')"
            shape="round"
            color="primary"
            icon="arrow-right"
            :disabled="disabled"
            :prepend="false"
            @click="handleOnClick"
          ></cvt-button>
        </div>
      </div>
    </div>
  </GenAILayout>
</template>
<script>
import { cvtRouteNodes } from '../../utils/routes'
import { getSessionItemGenAIOnboarding, setSessionGenAiOnboarding } from "./utils";
import GenAILayout from "./baseLayout.vue"

export default {
  components: {
    GenAILayout
  },
  props: {},
  data() {
    return {
      disabled: true,
      selectedIntent: '',
      intents: [
        {
          id: 0,
          icon: 'folder',
          text: this.$t('gen_ai.landingpage_goal.option.collect_more_leads'),
          intent: 'pre-sale',
          isActive: true,
        },
        {
          id: 1,
          icon: 'shopping-cart',
          text: this.$t('gen_ai.landingpage_goal.option.follow_up_with_leads'),
          intent: 'during-sale',
          isActive: false,
        },
        {
          id: 2,
          icon: 'users',
          text: this.$t(
            'gen_ai.landingpage_goal.option.get_referrals_and_business',
          ),
          intent: 'post-sale',
          isActive: false,
        },
      ],
      sectionError: {
        isSectionValid: false,
        msg: '',
      },
    }
  },
  mounted() {
    this.selectedIntent = getSessionItemGenAIOnboarding('intent') || ''
    this.validateSection(this.selectedIntent)
  },
  methods: {
    handleOnClick() {
      if (this.sectionError.isSectionValid) {
        setSessionGenAiOnboarding('intent', this.selectedIntent)

        this.$router.push({
          name: cvtRouteNodes.genAIIntent,
        })
      }
    },
    handleBack() {
      this.$router.push({
        name: cvtRouteNodes.selectIndustry,
      })
    },
    onClickGoalCard(goal) {
      this.validateSection(goal)
      this.selectedIntent = goal
    },
    validateSection(selectedGoal) {
      if (!selectedGoal) {
        this.disabled = true
        this.sectionError.msg = 'Select a goal'
        this.sectionError.isSectionValid = false
      } else {
        this.disabled = false
        this.sectionError.isSectionValid = true
      }
    },
  },
}
</script>
<style lang="scss">
@import './styles/LandingPageGoal.style.scss';
</style>
