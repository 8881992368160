<template>
  <div class="galley__item" @mouseenter="showOverlay" @mouseleave="hideOverlay">
    <div v-show="overlay" class="galley__item__overlay">
      <div class="galley__item__overlay__content d-flex flex-column justify-content-center align-items-center">
        <a v-show="button" href="#" class="btn btn-primary" @click.prevent.stop="use">{{ $t("imagesearch.item.use_this_image") }}</a>
        <i v-show="loader" class="fa fa-spinner fa-spin"></i>
        <p v-show="loader"><i>{{ $t("imagesearch.loading") }}</i></p>
        <p v-show="error" class="text-danger"><i v-text="error"></i></p>
      </div>
    </div>
    <img v-show="!overlay" class="img-fluid" :src="image.gallery" alt="">
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      overlayVisible: false,
      loading: true,
      error: null
    }
  },
  computed: {
    overlay () {
      return this.loading || this.overlayVisible && !this.error
    },
    loader () {
      return this.loading && !this.error
    },
    button () {
      return !this.loading && !this.error
    }
  },
  mounted () {
    let img = this.$el.querySelector('img')

    img.onload = this.onload.bind(this)
    img.onerror = this.onerror.bind(this)
  },
  methods: {
    showOverlay () {
      this.overlayVisible = true
    },
    hideOverlay () {
      this.overlayVisible = false
    },
    use () {
      this.$emit('selected', this.image)
    },
    onload () {
      this.loading = false
    },
    onerror () {
      this.error = this.$t('imagesearch.failed.message')
      // console.log('error', arguments)
    }
  }
}
</script>

<style lang="scss">

</style>