import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

export default {
  state: {
    visible: false,
  },
  getters: {
    [getters.ICON_PICKER_VISIBLE]({ visible }) {
      return visible
    },
  },
  mutations: {
    [mutations.ICON_PICKER_SHOW](state, visible) {
      state.visible = visible ? true : false
    },
  },
  actions: {
    [actions.ICON_PICKER_SELECT]({ commit, dispatch }, icon) {
      commit(mutations.ICON_PICKER_SHOW, false)
      dispatch(actions.SELECTOR_UPDATE_PROP, { key: 'set', value: icon.set })
      dispatch(actions.SELECTOR_UPDATE_PROP, {
        key: 'selector',
        value: icon.class,
      })
    },
  },
}
