
<div class="crm-file-browser">
  <div class="row mb-3">
    <div class="col-12 ml-auto">
      <el-input
        v-model="fileName"
        type="text"
        placeholder="Search your Company Files"
        :on-icon-click="search"
        icon="search"
        autofocus
        @keyup.enter.native="search"
      ></el-input>
    </div>
  </div>
  <div class="row">
    <div v-for="file in crmFiles" :key="file" class="col-3">
      <div class="crm-file-browser__image">
        <img
          :src="file.download_url"
          :alt="file.file_name"
          class="img-fluid"
          @click="use(file.download_url)"
        />
      </div>
    </div>
  </div>
</div>
