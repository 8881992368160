import { mapGetters, mapState, mapActions } from 'vuex'
import * as actions from '../../../../store/modules/actions'
import * as getters from '../../../../store/modules/getters'
import { Organization } from '../../../../store/services/api'
import * as _ from 'lodash'

const organizationAPI = new Organization()

export default {
  data() {
    return {
      tabs: ['Default Theme', 'Top Nav'],
      activeTabIdx: 0,
      colors: '',
      fontSize: '',
      textFontWeight: '',
      border: '',
      radii: '',
      disableBtn: false,
      brandColors: {
        TOP_NAV_CONFIG: [],
        THEME: [],
      },
      brandLogos: {
        THEME: [],
      },
      brandFonts: {
        THEME: [],
      },
      configs: {
        TOP_NAV_CONFIG: {
          theme: {
            hexBackgroundColor: {
              key: 'hexBackgroundColor',
              label: 'Hex Background Color',
              value: '',
              type: 'color',
            },
            backgroundColor: {
              key: 'backgroundColor',
              label: 'Background Color',
              value: '',
              type: 'radio',
              options: ['light', 'dark'],
            },
            textColor: {
              key: 'textColor',
              label: 'Text Color',
              value: '',
              type: 'radio',
              options: ['light', 'dark'],
            },
          },
          marketplace: {
            logo: {
              key: 'logo',
              label: 'Show Logo',
              value: false,
              type: 'switch',
            },
            title: {
              key: 'title',
              label: 'Show Title',
              value: false,
              type: 'switch',
            },
            help: {
              key: 'help',
              label: 'Show Help',
              value: false,
              type: 'switch',
            },
            exit: {
              key: 'exit',
              label: 'Show Exit',
              value: false,
              type: 'switch',
            },
            exitText: {
              key: 'exitText',
              label: 'Exit Text',
              value: '',
              type: 'text',
            },
            launch: {
              key: 'launch',
              label: 'Show Launch',
              value: false,
              type: 'switch',
            },
            versionHistory: {
              key: 'versionHistory',
              label: 'Show Version History',
              value: false,
              type: 'switch',
            },
            useThisTemplate: {
              key: 'useThisTemplate',
              label: 'Show Use This Template',
              value: false,
              type: 'switch',
            },
            showViewModeRadioBtns: {
              key: 'showViewModeRadioBtns',
              label: 'Show View Mode Radio Buttons',
              value: false,
              type: 'switch',
            },
            showLaunchBtn: {
              key: 'showLaunchBtn',
              label: 'Show Launch Button',
              value: false,
              type: 'switch',
            },
            showSitePreviewBtn: {
              key: 'showSitePreviewBtn',
              label: 'Show Site Preview Button',
              value: false,
              type: 'switch',
            },
            showScheduleDemoBtn: {
              key: 'showScheduleDemoBtn',
              label: 'Show Schedule Demo Button',
              value: false,
              type: 'switch',
            },
          },
          preview: {
            logo: {
              key: 'logo',
              label: 'Show Logo',
              value: false,
              type: 'switch',
            },
            title: {
              key: 'title',
              label: 'Show Title',
              value: false,
              type: 'switch',
            },
            help: {
              key: 'help',
              label: 'Show Help',
              value: false,
              type: 'switch',
            },
            exit: {
              key: 'exit',
              label: 'Show Exit',
              value: false,
              type: 'switch',
            },
            launch: {
              key: 'launch',
              label: 'Show Launch',
              value: false,
              type: 'switch',
            },
            versionHistory: {
              key: 'versionHistory',
              label: 'Show Version History',
              value: false,
              type: 'switch',
            },
            useThisTemplate: {
              key: 'useThisTemplate',
              label: 'Show Use This Template',
              value: false,
              type: 'switch',
            },
            useThisTemplateIcon: {
              key: 'useThisTemplateIcon',
              label: 'Show Use This Template Icon',
              value: false,
              type: 'switch',
            },
            useThisTemplateText: {
              key: 'useThisTemplateText',
              label: 'Use This Template Text',
              value: '',
              type: 'text',
            },
            copyTemplateText: {
              key: 'copyTemplateText',
              label: 'Copy Template Text',
              value: '',
              type: 'text',
            },
            restoreTemplateText: {
              key: 'restoreTemplateText',
              label: 'Restore Template Text',
              value: '',
              type: 'text',
            },
            showViewModeRadioBtns: {
              key: 'showViewModeRadioBtns',
              label: 'Show View Mode Radio Buttons',
              value: false,
              type: 'switch',
            },
            showLaunchBtn: {
              key: 'showLaunchBtn',
              label: 'Show Launch Button',
              value: false,
              type: 'switch',
            },
            showSitePreviewBtn: {
              key: 'showSitePreviewBtn',
              label: 'Show Site Preview Button',
              value: false,
              type: 'switch',
            },
          },
          editor: {
            logo: {
              key: 'logo',
              label: 'Show Logo',
              value: false,
              type: 'switch',
            },
            title: {
              key: 'title',
              label: 'Show Title',
              value: false,
              type: 'switch',
            },
            help: {
              key: 'help',
              label: 'Show Help',
              value: false,
              type: 'switch',
            },
            exit: {
              key: 'exit',
              label: 'Show Exit',
              value: false,
              type: 'switch',
            },
            launch: {
              key: 'launch',
              label: 'Show Launch',
              value: false,
              type: 'switch',
            },
            launchIcon: {
              key: 'launchIcon',
              label: 'Show Launch Icon',
              value: false,
              type: 'switch',
            },
            launchText: {
              key: 'launchText',
              label: 'Launch Text',
              value: '',
              type: 'text',
            },
            versionHistory: {
              key: 'versionHistory',
              label: 'Show Version History',
              value: false,
              type: 'switch',
            },
            useThisTemplate: {
              key: 'useThisTemplate',
              label: 'Show Use This Template',
              value: false,
              type: 'switch',
            },
            previewMode: {
              key: 'previewMode',
              label: 'Show Preview Mode',
              value: false,
              type: 'switch',
            },
            noUndoRedoLabel: {
              key: 'noUndoRedoLabel',
              label: 'No Undo Redo Label',
              value: false,
              type: 'switch',
            },
            savePublish: {
              key: 'savePublish',
              label: 'Save/Publish',
              value: '',
              type: 'text',
            },
            previewText: {
              key: 'previewText',
              label: 'Preview Text',
              value: '',
              type: 'text',
            },
            showViewModeRadioBtns: {
              key: 'showViewModeRadioBtns',
              label: 'Show View Mode Radio Buttons',
              value: false,
              type: 'switch',
            },
            showLaunchBtn: {
              key: 'showLaunchBtn',
              label: 'Show Launch Button',
              value: false,
              type: 'switch',
            },
            showSitePreviewBtn: {
              key: 'showSitePreviewBtn',
              label: 'Show Site Preview Button',
              value: false,
              type: 'switch',
            },
            showScheduleDemoBtn: {
              key: 'showScheduleDemoBtn',
              label: 'Show Schedule Demo Button',
              value: false,
              type: 'switch',
            },
          },
          launch: {
            logo: {
              key: 'logo',
              label: 'Show Logo',
              value: false,
              type: 'switch',
            },
            title: {
              key: 'title',
              label: 'Show Title',
              value: false,
              type: 'switch',
            },
            help: {
              key: 'help',
              label: 'Show Help',
              value: false,
              type: 'switch',
            },
            exit: {
              key: 'exit',
              label: 'Show Exit',
              value: false,
              type: 'switch',
            },
            editText: {
              key: 'editText',
              label: 'Edit Text',
              value: '',
              type: 'text',
            },
            exitIcon: {
              key: 'exitIcon',
              label: 'Show Exit Icon',
              value: false,
              type: 'switch',
            },
            launch: {
              key: 'launch',
              label: 'Show Launch',
              value: false,
              type: 'switch',
            },
            exitOutlined: {
              key: 'exitOutlined',
              label: 'Show Exit Outlined',
              value: false,
              type: 'switch',
            },
            versionHistory: {
              key: 'versionHistory',
              label: 'Show Version History',
              value: false,
              type: 'switch',
            },
            useThisTemplate: {
              key: 'useThisTemplate',
              label: 'Show Use This Template',
              value: false,
              type: 'switch',
            },
            backBtn: {
              key: 'backBtn',
              label: 'Show Back Button',
              value: false,
              type: 'switch',
            },
            showViewModeRadioBtns: {
              key: 'showViewModeRadioBtns',
              label: 'Show View Mode Radio Buttons',
              value: false,
              type: 'switch',
            },
            showLaunchBtn: {
              key: 'showLaunchBtn',
              label: 'Show Launch Button',
              value: false,
              type: 'switch',
            },
            showSitePreviewBtn: {
              key: 'showSitePreviewBtn',
              label: 'Show Site Preview Button',
              value: false,
              type: 'switch',
            },
            showScheduleDemoBtn: {
              key: 'showScheduleDemoBtn',
              label: 'Show Schedule Demo Button',
              value: false,
              type: 'switch',
            },
          },
          'site-preview': {
            logo: {
              key: 'logo',
              label: 'Show Logo',
              value: false,
              type: 'switch',
            },
            title: {
              key: 'title',
              label: 'Show Title',
              value: false,
              type: 'switch',
            },
            help: {
              key: 'help',
              label: 'Show Help',
              value: false,
              type: 'switch',
            },
            exit: {
              key: 'exit',
              label: 'Show Exit',
              value: false,
              type: 'switch',
            },
            launch: {
              key: 'launch',
              label: 'Show Launch',
              value: false,
              type: 'switch',
            },
            versionHistory: {
              key: 'versionHistory',
              label: 'Show Version History',
              value: false,
              type: 'switch',
            },
            useThisTemplate: {
              key: 'useThisTemplate',
              label: 'Show Use This Template',
              value: false,
              type: 'switch',
            },
            useThisTemplateIcon: {
              key: 'useThisTemplateIcon',
              label: 'Show Use This Template Icon',
              value: false,
              type: 'switch',
            },
            backBtn: {
              key: 'backBtn',
              label: 'Show Back Button',
              value: false,
              type: 'switch',
            },
            viewModeCenter: {
              key: 'viewModeCenter',
              label: 'Show View Mode Center',
              value: false,
              type: 'switch',
            },
            launchText: {
              key: 'launch',
              label: 'Launch Text',
              value: '',
              type: 'text',
            },
            launchBtnStyle: {
              key: 'launchBtnStyle',
              label: 'Launch Button Style',
              value: '',
              type: 'select',
              options: ['primary', 'secondary', 'success', 'danger'],
            },
            useThisTemplateText: {
              key: 'useThisTemplateText',
              label: 'Use This Template Text',
              value: '',
              type: 'text',
            },
            showViewModeRadioBtns: {
              key: 'showViewModeRadioBtns',
              label: 'Show View Mode Radio Buttons',
              value: false,
              type: 'switch',
            },
            showLaunchBtn: {
              key: 'showLaunchBtn',
              label: 'Show Launch Button',
              value: false,
              type: 'switch',
            },
            showSitePreviewBtn: {
              key: 'showSitePreviewBtn',
              label: 'Show Site Preview Button',
              value: false,
              type: 'switch',
            },
          },
        },
        THEME: {
          logo: {
            key: 'logo',
            label: 'Primary Logo',
            value: '',
            type: 'img',
          },
          color: {
            key: 'color',
            label: 'Primary Color',
            value: '',
            type: 'color',
          },
          font: {
            key: 'font',
            label: 'Primary Font',
            value: '',
            type: 'font',
          },
        },
      },
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      Border: ({ globalTheme }) => globalTheme.Border,
      Radii: ({ globalTheme }) => globalTheme.Radii,
    }),
    ...mapGetters({
      defaultConfig: getters.USER_CONFIG,
    }),
    ...mapGetters('globalTheme', {
      TextFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
    }),
  },
  async created() {
    await this.fetchConfig()

    const topNavConfigKeys = Object.keys(this.defaultConfig?.TOP_NAV_CONFIG)

    this.configs.TOP_NAV_CONFIG = this.filterTopNavConfigByKeys(
      topNavConfigKeys,
      this.configs.TOP_NAV_CONFIG,
      this.defaultConfig.TOP_NAV_CONFIG,
    )

    const payload = {
      configKey: 'CURRENT_STAGE',
      value: 'enterprise_dashboard',
    }
    await this.updateConfig(payload)
  },
  methods: {
    ...mapActions({
      fetchConfig: actions.FETCH_CONFIG,
      upsertFeatureConfig: actions.UPSERT_CONFIG,
    }),
    onTabClicked(idx) {
      this.activeTabIdx = idx
    },

    onSelectNewColor(key: String, selectedColor: String) {
      const newColorValue = {
        hex: selectedColor,
        type: 'accent',
      }
      const newBrand = [...this.brandColors[key], newColorValue]
      this.brandColors[key] = newBrand
    },

    onSetCurrentColor: _.debounce(async function (
      configKey,
      index,
      key,
      colorIndex,
    ) {
      const value = this.brandColors[configKey][colorIndex].hex
      this.configs[configKey][index][key].value = value

      const payload = {
        configKey: configKey,
        value: {
          ...this.defaultConfig[configKey],
          [index]: {
            ...this.defaultConfig[configKey][index],
            [key]: value,
          },
        },
      }

      await this.updateConfig(payload)
    },
    500),

    onSetThemeCurrentColor: _.debounce(async function (
      configKey,
      index,
      colorIndex,
    ) {
      const value = this.brandColors[configKey][colorIndex].hex
      this.configs[configKey][index].value = value

      const payload = {
        configKey: configKey,
        value: {
          ...this.defaultConfig[configKey],
          [index]: value,
        },
      }

      await this.updateConfig(payload)
    },
    500),

    onSetThemeCurrentFont: _.debounce(async function (
      configKey,
      index,
      fontIndex,
    ) {
      const value = this.brandFonts[configKey][fontIndex].name
      this.configs[configKey][index].value = value

      const payload = {
        configKey: configKey,
        value: {
          ...this.defaultConfig[configKey],
          [index]: value,
        },
      }

      await this.updateConfig(payload)
    },
    500),

    onSetThemeCurrentLogo: _.debounce(async function (configKey, index, logo) {
      const value = logo
      this.configs[configKey][index].value = value

      const payload = {
        configKey: configKey,
        value: {
          ...this.defaultConfig[configKey],
          [index]: value,
        },
      }

      await this.updateConfig(payload)
    }, 500),

    onSelectNewFont(key: String, selectedFont: String) {
      if (!selectedFont) return
      const updatedFontsArr = [...this.brandFonts[key], { name: selectedFont }]
      this.brandFonts[key] = updatedFontsArr
    },

    onSetCurrentFont: _.debounce(async function (
      configKey,
      index,
      key,
      fontIndex,
    ) {
      const value = this.brandFonts[configKey][fontIndex].name
      this.configs[configKey][index][key].value = value

      const payload = {
        configKey: configKey,
        value: {
          ...this.defaultConfig[configKey],
          [index]: {
            ...this.defaultConfig[configKey][index],
            [key]: value,
          },
        },
      }

      await this.updateConfig(payload)
    },
    500),

    onSetCurrentLogo: _.debounce(async function (configKey, index, key, logo) {
      const value = logo
      this.configs[configKey][index][key].value = value

      const payload = {
        configKey: configKey,
        value: {
          ...this.defaultConfig[configKey],
          [index]: {
            ...this.defaultConfig[configKey][index],
            [key]: value,
          },
        },
      }

      await this.updateConfig(payload)
    }, 500),

    onCompleteUpload(key: String, image: [string]) {
      const selectedLogo = image[0]
      if (!selectedLogo?.trim()) return
      const newLogoValue = {
        type: 'icon',
        theme: null,
        formats: [
          {
            src: selectedLogo,
          },
        ],
      }
      const updatedLogoArr = [...this.brandLogos[key], { logo: newLogoValue }]
      this.brandLogos[key] = updatedLogoArr
    },

    capitalizeFirstLetter(str: String) {
      let formatedStr = ''
      if (str) {
        formatedStr = str.charAt(0).toUpperCase() + str.slice(1)
      }
      return formatedStr
    },
    async updateConfig(config: {
      configKey: String
      value: [String, Object, Boolean]
    }) {
      try {
        this.disableBtn = false
        const payload = {
          key: config.configKey,
          table: 'organizationConfigs',
          value: config.value,
        }
        this.upsertFeatureConfig(payload)
      } catch (err) {
        this.disableBtn = true
        console.debug(err)
      }
    },
    onSwitchToggle: _.debounce(async function (configKey, index, key) {
      const value = this.configs[configKey][index][key].value
      this.configs[configKey][index][key].value = !value

      const payload = {
        configKey: configKey,
        value: {
          ...this.defaultConfig[configKey],
          [index]: {
            ...this.defaultConfig[configKey][index],
            [key]: !value,
          },
        },
      }

      await this.updateConfig(payload)
    }, 500),

    updateInputValue: _.debounce(async function (configKey, index, key, value) {
      this.configs[configKey][index][key].value = value

      const payload = {
        configKey: configKey,
        value: {
          ...this.defaultConfig[configKey],
          [index]: {
            ...this.defaultConfig[configKey][index],
            [key]: value,
          },
        },
      }
      await this.updateConfig(payload)
    }, 500),

    setRadioBtnValue: _.debounce(async function (configKey, index, key, value) {
      this.configs[configKey][index][key].value = value

      const payload = {
        configKey: configKey,
        value: {
          ...this.defaultConfig[configKey],
          [index]: {
            ...this.defaultConfig[configKey][index],
            [key]: value,
          },
        },
      }

      await this.updateConfig(payload)
    }, 500),

    filterTopNavConfigByKeys(
      keys: String[],
      defaultTopNavConfig: object,
      updatedTopNavConfig: object,
    ) {
      let filteredConfig = {}
      let canBefiltered = keys.length && Object.keys(defaultTopNavConfig).length

      if (canBefiltered) {
        Object.keys(defaultTopNavConfig).filter((key) => {
          if (keys.includes(key)) {
            filteredConfig[key] = {}
            Object.keys(defaultTopNavConfig[key]).filter((configKey) => {
              if (Object.keys(updatedTopNavConfig[key]).includes(configKey)) {
                filteredConfig[key][configKey] = {
                  ...defaultTopNavConfig[key][configKey],
                  value: updatedTopNavConfig[key][configKey],
                }
              }
            })
          }
        })
        return filteredConfig
      }
      return defaultTopNavConfig
    },
  },
}
