
<div class="slide-wrapper">
  <h6 v-if="!hideLabel">{{ $t("element.property.form.design.width") }}</h6>
  <cvt-slider
    id="slider-default"
    :tooltips-enabled="true"
    :step-marks-enabled="true"
    class="slider"
    :min="min"
    :max="max"
    :value="width"
    :step="step"
    @change="onChange"
  />
</div>
