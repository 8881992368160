<script>
import FirebaseNodeV3 from '../../base/FirebaseNode'
import CVideo from "../../../editor/nodes/CVideo.vue";

export default CVideo.extend({
  name: "CVideoV3",
  mixins: [FirebaseNodeV3],
  created () {
    if (this.fbNode.data.props.src === undefined) {
      this.fbNode.data.props.src = ''
    }
  }
})
</script>
