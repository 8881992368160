
<el-dialog
  :title="title"
  :visible.sync="visible"
  width="510px"
  :before-close="handleCloseDialog"
  custom-class="products-dialog-container"
>
  <div class="products-dialog">
    <div
      v-if="showProducts"
      class="row"
    >
      <div class="col-7">
        <el-input
          v-model="query"
          placeholder="Search Products"
          @input="debounceProductSearch"
        />
      </div>
      <div class="col">
        <button
          class="btn btn-block btn btn-outline-primary"
          @click="createNewProduct"
        >
          {{ $t("elements.property.product.create") }}
        </button>
      </div>
    </div>
    <div
      v-if="showProducts"
      v-loading="loadingProducts"
      class="stipe-products"
    >
      <div
        v-for="productDisplay in products"
        :key="productDisplay.id"
        class="row stripe-product py-3"
      >
        <div class="col d-flex align-items-center">
          <div class="stripe-product-description">
            <h6 class="text-primary m-0">
              {{ productDisplay.name | truncate(100) }}
              <span class="text-success"><small>{{ productDisplay.skus[0].price | currency }}</small></span>
            </h6>
          </div>
        </div>
        <div class="col-3 ml-auto d-flex align-items-center justify-content-end product-actions">
          <el-tooltip
            :content="$t('element.property.product.cta.delete')"
            placement="top"
          >
            <i
              class="fa fa-trash fa-lg"
              @click="confirmAndRemoveProduct(productDisplay)"
            />
          </el-tooltip>
        </div>
      </div>
    </div>

    <div
      v-if="showCreate"
      class="strip-create-product"
    >
      <!-- <div class="row mb-3">
        <div class="col">
          <p class="text-muted">Add up to 5 product images</p>
          <upload-plus :before-upload="beforeProductImageUpload" @change="updateProductImages"></upload-plus>
        </div>
      </div> -->

      <div class="row mb-3">
        <div class="col">
          <label for="">Product Name</label>
          <input
            v-model="product.name"
            type="text"
            class="form-control"
            placeholder="Give this product a name"
          >
        </div>
      </div>

      <div class="row mb-3">
        <div class="col">
          <label for="">Description</label>
          <textarea
            v-model="product.description"
            cols="30"
            rows="5"
            class="form-control"
            placeholder="Write a fancy product description"
          />
        </div>
      </div>

      <div class="row no-gutters mb-3">
        <div class="col">
          <label for="">Price</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input
              v-model="product.price"
              type="text"
              class="form-control"
              aria-label="Amount (to the nearest dollar)"
            >
          </div>
        </div>
      </div>

      <div
        v-if="false"
        class="row mb-3"
      >
        <div class="col">
          <el-checkbox v-model="recurring">
            This is a recurring payment
          </el-checkbox>
        </div>
      </div>

      <div
        v-if="recurring"
        class="row mb-3"
      >
        <div class="col-12 mb-3">
          <label for="">Billing Interval</label>
          <div class="input-group">
            <el-select v-model="plan.interval">
              <el-option
                v-for="interval in intervals"
                :key="interval.value"
                :label="interval.label"
                :value="interval.value"
              />
            </el-select>
          </div>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col">
          <upload-drop :before-upload="beforeProductUpload" @change="updateProductDownloadUrls"></upload-drop>
        </div>
      </div> -->
      <div class="row">
        <div class="col">
          <button
            class="btn btn-primary"
            @click="saveProduct"
          >
            Save
          </button>
          <button
            class="btn btn-outline-dark"
            @click="cancelCreateProduct"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <span slot="footer" class="dialog-footer d-flex justify-content-center">

  </span> -->
</el-dialog>
