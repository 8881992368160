import Vue from 'vue'
import * as events from './events'

export const hub = new Vue()

hub.events = events

/**
 * @param  {Vue}
 * @return {VuePlugin}
 *
 * This pluin exposes a communication hub for non-parent child
 * communication. Alowing other components to listen and dispatch
 * events to eachother
 *
 */

function install (Vue) {
  Vue.prototype.$hub = hub
  Vue.prototype.$hubEvents = events
}

export default { install }
