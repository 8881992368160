
<div class="element-image-manager">
  <div
    class="element-image-manager__preview checkered"
    @mouseover.stop.prevent="showOverlay"
    @mouseleave.stop.prevent="hideOverlay"
    @click.stop.prevent="openAssetManager('imageThumbnail')"
  >
    <small v-if="!src && !overlayVisible"
      ><i>{{ $t('imagesearch.results.empty.message') }}</i></small
    >

    <div
      v-if="!isGridBuilder"
      v-show="overlayVisible"
      class="element-image-manager__preview__overlay"
    >
      <small
        ><i>{{ assetMgrBtnText }} Image</i></small
      >
    </div>

    <div
      v-else-if="isGridBuilder"
      v-show="!isBackgroundImageAdded && overlayVisible"
      class="element-image-manager__preview__overlay"
    >
      <small
        ><i>{{ assetMgrBtnText }} Image</i></small
      >
    </div>

    <img
      v-show="isBackgroundImageAdded"
      :src="src"
      class="img-fluid"
      alt=""
    />

    <div
      v-if="canRepositionImage"
      :style="'move'"
      class="color-spectrum__barrier"
      @mousedown="captureClickAndMouseWheelEvent"
    >
      <drag-marker
        v-if="isBackgroundImageAdded"
        ref="backgroundImagePositioner"
        classes="color-spectrum__marker"
        containment
        :style="backgroundImgMarkerInitialPosition"
        @dragmove="positionMove"
      >
      </drag-marker>
    </div>
  </div>

  <div class="my-4">
    <cvt-input
      v-model="caption"
      type="text"
      :label="$t('element.property.image.caption')"
      :clearable="false"
        />
  </div>

  <div v-if="OrgName !== orgNameFreshWork" class="divider mt-3" />
  <div v-else class="mt-3" />
  <div v-if="OrgName !== orgNameFreshWork" class="row">
    <div
      v-if="OrgName !== orgNameFreshWork"
      class="col align-items-center text-capitalize"
    >
      {{ $t('element.property.image.url') }}
    </div>
    <div v-if="OrgName !== orgNameFreshWork" class="col-12">
      <url-input
        v-model="src"
        :placeholder="$t('element.property.image.hyperlink.alt')"
        autofocus
      />
    </div>
  </div>

  <div class="row">
    <div class="col d-flex justify-content-center">
      <cvt-button
        v-bind="removeBtnProps"
        shape="rounded"
        @click.stop.prevent="clearImage"
      />
    </div>
    <div class="col d-flex justify-content-center">
      <cvt-button
        v-bind="replaceBtnProps"
        shape="rounded"
        @click.stop.prevent="openAssetManager('replaceButton')"
      />
    </div>
  </div>
  <div v-if="OrgName !== orgNameFreshWork" class="divider mt-3" />
  <div v-else class="mt-3" />
</div>
