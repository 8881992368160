import { styled, VueEmotion } from '@egoist/vue-emotion'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import * as getters from '../../../builder/src/js/store/modules/getters'
import Vue from '../../lib/utils/vue'
import { FRESHWORKS, LEFT_SIDEBAR_FRESHWORKS, LEFT_SIDEBAR_STANDARD, LIONDESK } from '../constants'
import { CvtIcon } from './icon/icon'
Vue.use(VueEmotion)

let FontFamily, Spacing, Radii, FontWeight, Colors, Border

let textFontWeight,
  borderColor,
  textColor,
  backgroundColor,
  textFontSize,
  textLineHeight,
  elevation,
  showGradient,
  GradientColors,
  changeBorderColor,
  showBoxShadow,
  orgName,
  buttonConfig

const UTIL = {
  btnShape (shape) {
    switch (shape) {
      case 'pill':
        return `border-radius: ${Radii.RADIUS_PERCENT_50}px;`
      case 'squared':
        return `border-radius: 0px;`
      case 'circle':
        return `border-radius: ${Radii.RADIUS_PERCENT_50}%;`
      default:
        return `border-radius: ${Radii.RADIUS_PX_8}px;`
    }
  },
  actionBtnSize (size) {
    switch (size) {
      case 'xs':
        return `padding: ${Spacing.SPACING_4}px;`
      case 'sm':
        return `padding: ${orgName === LEFT_SIDEBAR_FRESHWORKS ? `8px 9px;` : Spacing.SPACING_8}px;`
      case 'lg':
        return `padding: ${Spacing.SPACING_16}px;`
      case 'xl':
        return `padding: ${Spacing.SPACING_18}px;`
      default:
        return `padding: ${Spacing.SPACING_12}px;`
    }
  },
  btnSize (size) {
    switch (size) {
      case 'xs':
        return `padding: ${Spacing.SPACING_4}px ${Spacing.SPACING_16}px;`
      case 'sm':
        // return `padding: ${Spacing.SPACING_8}px ${Spacing.SPACING_16}px;`
        return `padding: ${orgName === LEFT_SIDEBAR_FRESHWORKS ? Spacing.SPACING_4 + Spacing.SPACING_2 : Spacing.SPACING_8}px ${orgName === LEFT_SIDEBAR_FRESHWORKS ? Spacing.SPACING_12 : Spacing.SPACING_16}px;`
      case 'lg':
        return `padding: ${Spacing.SPACING_16}px ${Spacing.SPACING_16}px;`
      case 'xl':
        return `padding: ${Spacing.SPACING_18}px ${Spacing.SPACING_16}px;`
      default:
        return `padding: ${Spacing.SPACING_12}px ${Spacing.SPACING_16}px;`
    }
  },
  btnTextSpacing (size) {
    switch (size) {
      case 'xs':
        return Spacing.SPACING_4
      case 'sm':
        return Spacing.SPACING_8
      case 'lg':
        return Spacing.SPACING_16
      case 'xl':
        return Spacing.SPACING_18
      default:
        return Spacing.SPACING_12
    }
  },
  btnFont (size, props) { 
    let btnFontWeight = ''
    let btnFontWeightConfig = props?.btnState?.default?.fontWeight;
    let textFontWeightConfig = buttonConfig.textFontWeight ? buttonConfig.textFontWeight : size;

    if(btnFontWeightConfig){
      btnFontWeight = btnFontWeightConfig
    }else{
      btnFontWeight = textFontWeightConfig
    }

    return textFontSize(size) + textFontWeight(btnFontWeight) + textLineHeight(size)
  }
}

const getButton = () => {
  return styled('button')`
    display: flex;
    position: relative;
    align-items: baseline;
    justify-content: ${(props) => props?.btnState?.default?.btnSpacing ? "space-between": "center"};
    align-items: center;
    vertical-align: middle;
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
    font-family: ${FontFamily.DEFAULT};
    font-weight: ${FontWeight.LIGHT};
    text-align: center;
    color: ${(props) => (props.btnTextColor ? `${props.btnTextColor} !important` : '')};
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    outline: none !important;
    border: ${Border.SIZE_1}px solid transparent;
    ${(props) => {
      let styles = ``
      styles +=
        (props.icononly && props.shape == 'pill'
          ? UTIL.btnShape('circle')
          : UTIL.btnShape(props.shape)) +
        (props.actionIcon || props.icononly
          ? UTIL.actionBtnSize(props.size)
          : UTIL.btnSize(props.size)) +
        UTIL.btnFont(
          orgName === LEFT_SIDEBAR_FRESHWORKS ? 'SEMI_BOLD' : props.size, props
        )

      if (
        !props.disabled &&
        props.orgnization !== 'infusionsoft' &&
        props.setButtonShadow
      )
        styles += props.shadow ? elevation(props, 400) : elevation(props, 200)

      let focusBoxColor = textColor(props, 300)
      if (
        props.themeing == 'light' ||
        props.themeing == 'ternary' ||
        props.themeing === 'link'
      ) {
        let btnTextOnChangeConfig = props?.btnState?.onChange?.color;
        let btnTextColorConfig = props?.btnState?.default?.color;
        let btnBorderColorConfig = props?.btnState?.default?.borderColor;
        let btnHoverBorderColorConfig = props?.btnState?.hover?.borderColor;
        let btnHoverBgColorConfig = props?.btnState?.hover?.backgroundColor;

        focusBoxColor = textColor({ themeing: 'primary' }, 300)
        focusBoxColor = focusBoxColor.replace('color: ', '')

        styles += ` ${
         props.themeing !== 'link' && props.specialBtnTextColor !== ''
            ? `color: ${props.specialBtnTextColor};`
            : btnTextColorConfig
              ? !btnTextColorConfig.custom
                ? !props.btnTextChange 
                  ? textColor( btnTextColorConfig.value , btnTextColorConfig.weight)
                  : textColor( btnTextOnChangeConfig.value , btnTextOnChangeConfig.weight)
                : !props.btnTextChange 
                  ? `color: ${btnTextColorConfig.value};`  
                  : `color: ${btnTextOnChangeConfig.value};`
              : textColor({ themeing: 'primary' }, 500)
        }        
        ${
          props.outlined
            ? changeBorderColor
              ? `border: 1px solid #CFD7DF;`
              : btnBorderColorConfig
                ? !btnBorderColorConfig.custom ? borderColor(btnBorderColorConfig.value, btnBorderColorConfig.weight) : `border-color: ${btnBorderColorConfig.value};`
                : borderColor({ themeing: 'primary' }, 500)
            : ''
        }

          // TODO: Need to be refactored in the future using a spanner config
          ${
            props.orgnization === 'infusionsoft' ||
            props.themeing == 'ternary' ||
            props.themeing == 'link'
              ? 'background-color: transparent;'
              : backgroundColor(
                  {
                    themeing: 'dark',
                    showGradient: props.showGradient,
                    removeGradient: !props.showGradient,
                  },
                  'WHITE',
                )
          }

          &:hover {
            ${props.outlined 
            ? btnHoverBorderColorConfig
              ? !btnHoverBorderColorConfig.custom
                ? borderColor(btnHoverBorderColorConfig.value, btnHoverBorderColorConfig.weight) 
                : `border-color: ${btnHoverBorderColorConfig.value};`
              : borderColor({ themeing: 'primary' }, 500) 
            : ''}
            ${
              props.showGradient && Colors.SMOKE
                ? !props.onmousedown
                  ? backgroundColor(
                      {
                        themeing: 'smoke',
                        showGradient: props.showGradient,
                        removeGradient: true,
                      },
                      25,
                    ) + borderColor({ themeing: 'smoke' }, 100)
                  : ``
                : btnHoverBgColorConfig
                ? !btnHoverBgColorConfig.custom
                  ? backgroundColor(btnHoverBgColorConfig.value , btnHoverBgColorConfig.weight) 
                  : `background-color: ${btnHoverBgColorConfig.value};`
                : backgroundColor({ themeing: 'dark' }, 200)
            }
          }  
          ${props.themeing === 'link' ? `padding: 0px;` : ``}                
          `
      } else {
        if (props.themeing == 'dark') {
          focusBoxColor = textColor(props, 400)
        }
        let backgroundColorObj = props.btnState?.default?.backgroundColor;
        let hoverColorObj = props.btnState?.hover?.backgroundColor;
        
        focusBoxColor = focusBoxColor.replace('color: ', '')
        styles += `${textColor({ themeing: 'dark' }, 100)}

        ${props.showGradient
            ? backgroundColor(
              {
                themeing: 'dark',
                showGradient: props.showGradient,
                removeGradient: !props.showGradient
              },
              'WHITE'
            )
            : backgroundColorObj 
            ? !backgroundColorObj.custom 
              ? styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`
              : styles += `background: ${backgroundColorObj.value};`
            : backgroundColor(props, 500)
        }
          &:hover {
            border-color: transparent;
            // TODO: Need to be refactored in the future using a spanner config
            ${props.orgnization === 'infusionsoft'
            ? backgroundColor(props, 700)
            : props.showGradient
              ? !props.onmousedown
                ? backgroundColor({ themeing: 'light', showGradient: props.showGradient, removeGradient: true }, 900)
                + borderColor({ themeing: 'primary' }, 900)
                : ``
              : hoverColorObj 
              ? !hoverColorObj.custom 
                ? styles += `${backgroundColor(hoverColorObj.value, hoverColorObj.weight)}`
                : styles += `background: ${hoverColorObj.value};`
              : backgroundColor(props, 400)
            }
          }
          `
      }
      if (props.specialStyle === 'dark') {
        styles += `${
          props.showGradient === true
            ? `background: linear-gradient(${
                GradientColors[props.specialStyle.toUpperCase()]['500']
              });`
            : backgroundColor({ themeing: 'dark' }, 'WHITE')
        }`
        styles += `&:hover {

              ${
                !props.onmousedown && props.showGradient === true
                  ? `background: linear-gradient(180deg, ${props.specialBtnTextColor} 0%, ${props.specialBtnTextColor} 100%);`
                  : ``
              }
            }
            `
      }
      if (props.orgName === LIONDESK) {
        styles += `text-transform: uppercase;`
      }
      if (props.removeBackground) {
        styles += `background-color: transparent;`
        styles += `&:hover {background-color: transparent;border-color: transparent;}`
      }
      return styles
    }}

    ${(props) =>
      props.specialPadding !== '' ? `padding: ${props.specialPadding};` : ``}
    ${(props) => {
      return props.orgName === LEFT_SIDEBAR_FRESHWORKS
        ? `line-height: 18px; !important`
        : ``
    }}

    & > span {
      ${(props) => {
        return props.icon
          ? props.prepend
            ? `margin-left: ${UTIL.btnTextSpacing(props.size)}px;`
            : `margin-right: ${UTIL.btnTextSpacing(props.size)}px;`
          : ''
      }}
    }

    &:disabled {
      cursor: not-allowed;
      ${(props) => {
        let diasbleBtnBgColorConfig = props.btnState?.disabled?.backgroundColor;
        return props.showGradient
          ? backgroundColor(
              { ...props, themeing: 'smoke', removeGradient: true },
              50,
            ) + `color: ${Colors.SMOKE[300]};`
          : props.disabledWithNoBg
          ? `background-color: transparent;`
          : diasbleBtnBgColorConfig
            ? !diasbleBtnBgColorConfig.custom
              ? backgroundColor(diasbleBtnBgColorConfig.value, diasbleBtnBgColorConfig.weight)
              : `background-color: ${diasbleBtnBgColorConfig.value};`
            :`background-color: ${Colors.BASIC[200]};`
      }}
      ${(props) => {
        let diasbleBtnTextColorConfig = props.btnState?.disabled?.color;
        return props.showGradient 
        ? ``
        : diasbleBtnTextColorConfig
          ? !diasbleBtnTextColorConfig.custom
            ? textColor(diasbleBtnTextColorConfig.value, diasbleBtnTextColorConfig.weight)
            : `color: ${diasbleBtnTextColorConfig.value};`
          : `color: ${Colors.BASIC[300]};`
      }}
    }

    &.actionIcon {
      ${(props) => {
        let styles = ``
        if (props.size === 'xs')
          styles += `& > i{ width: 8.75px; height: 10px;}`
        else if (props.size === 'sm')
          styles += `& > i{ width: 10.5px; height: 12px;}`
        else if (props.size === '')
          styles += `& > i{ width: 12.25px; height: 14px;}`
        else if (props.size === 'lg')
          styles += `& > i{ width: 14px; height: 16px;}`
        else if (props.size === 'xl')
          styles += `& > i{ width: 15.75px; height: 18px;}`

        styles += `background-color: transparent;
      box-shadow: none;
      ${
        props.modeOfActionIcon === 'light'
          ? textColor({ themeing: 'dark' }, 100)
          : props.modeOfActionIcon === 'dark'
          ? props.colorOfActionIcon === ''
            ? textColor({ themeing: props.modeOfActionIcon }, 500)
            : textColor({ themeing: props.colorOfActionIcon }, 900)
          : textColor({ themeing: props.colorOfActionIcon }, 500)
      }
      ${
        props.overrideColor
          ? 'color:' + props.overrideColor + ' !important;'
          : ''
      }
      &:hover {
        ${
          props.modeOfActionIcon === 'light'
            ? props.colorOfActionIcon === ''
              ? backgroundColor({ themeing: 'dark' }, 900)
              : backgroundColor({ themeing: props.colorOfActionIcon }, 200)
            : props.colorOfActionIcon === ''
            ? backgroundColor({ themeing: 'dark' }, 200)
            : backgroundColor({ themeing: props.colorOfActionIcon }, 200)
        }
        ${
          props.overrideHoverBgColor
            ? 'background-color:' + props.overrideHoverBgColor + ' !important;'
            : ''
        }
      }
      :disabled {
        cursor: not-allowed;
        background-color: transparent;
        border-color: transparent;
        color: ${Colors.BASIC[300]};
      }
      `
        if (props.removeBackground) {
          styles += `background-color: transparent;background: transparent;`
          styles += `&:hover {background-color: transparent;border-color: transparent;background: transparent;}`
        }
        return styles
      }}
    }

    &.focusViaTab {
      ${(props) => {
        let focusBoxColor = textColor(props, 300)
        let bg = backgroundColor({ ...props, themeing: 'dark' }, 500)

        if (props.showGradient) {
          bg = ``
          focusBoxColor = Colors.INFO[800]
        }
        if (props.themeing == 'light') {
          focusBoxColor = textColor({ themeing: 'primary' }, 300)
          if (props.showGradient) {
            focusBoxColor = Colors.INFO[800]
          }
          focusBoxColor = focusBoxColor.replace('color: ', '')
          return `border-color: transparent; 
                ${backgroundColor({ themeing: 'dark' }, 'WHITE')}         
                box-shadow: 0 0 0 3pt ${focusBoxColor}`
        } else {
          if (props.themeing == 'dark') {
            focusBoxColor = textColor(props, 400)
          }
          focusBoxColor = focusBoxColor.replace('color: ', '')

          return `border-color: transparent;
                ${bg}
                box-shadow: 0 0 0 3pt ${focusBoxColor}`
        }
      }}
    }

    &.focusViaClick {
      ${(props) => {
        let focusBoxColor = textColor(props, 300)
        if (props.themeing == 'light') {
          focusBoxColor = textColor({ themeing: 'primary' }, 300)
          focusBoxColor = focusBoxColor.replace('color: ', '')
          let style = `${
            !props.showGradient && props.outlined
              ? borderColor({ themeing: 'primary' }, 500)
              : ``
          } 
              box-shadow: none; 
              ${
                props.showGradient
                  ? backgroundColor({ ...props, removeGradient: true }, 800)
                  : backgroundColor({ themeing: 'dark' }, 300)
              }`
          if (props.specialStyle === 'dark') {
            style +=
              props.showGradient === true
                ? `background: linear-gradient(${
                    GradientColors[props.specialStyle.toUpperCase()]['500']
                  });`
                : ''
            return style
          } else {
            if (props.themeing == 'dark') {
              focusBoxColor = textColor(props, 400)
            }
            focusBoxColor = focusBoxColor.replace('color: ', '')

            let btnFocusBorderColorConfig = props?.btnState?.focus?.borderColor;
            return `
              ${
                props.outlined && !props.showGradient
                  ? btnFocusBorderColorConfig
                    ? !btnFocusBorderColorConfig.custom
                      ? borderColor(btnFocusBorderColorConfig.value, btnFocusBorderColorConfig.weight) 
                      : `border-color: ${btnFocusBorderColorConfig.value};`
                    : `border-color: transparent;`
                  : ``
              } 
                ${
                  props.showGradient
                    ? backgroundColor(
                        { ...props, themeing: 'smoke', removeGradient: true },
                        25,
                      ) +
                      `box-shadow: inset 0px 0px 4px rgba(39, 49, 58, 0.26); !important`
                    : backgroundColor(props, 600)
                }
                box-shadow: none;`
          }
        }

        if (props.specialStyle === 'dark') {
          return props.showGradient
            ? `box-shadow: inset 0px 0px 4px rgba(39, 49, 58, 0.26) !important;`
            : ``
        }
      }}
    
  `
}
const getSmallSpinner = () => {
  return styled('i')`
    position: absolute;
    top: calc(50% - ${Spacing.SPACING_12}px);
    left: calc(50% - ${Spacing.SPACING_12}px);
    width: ${Spacing.SPACING_16}px;
    height: ${Spacing.SPACING_16}px;
    border-width: 0.2em;
    display: inline-block;
    vertical-align: text-bottom;
    border: 0.25em solid;
    border-color: ${Colors.BASIC[500]};
    border-right-color: transparent;
    border-radius: ${Radii.RADIUS_PERCENT_50}%;
    animation: 1s spin linear infinite;
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  `
}

const getIconButton = () => {
  return styled('CvtIcon')`

    ${(props) => {
      let margin = ''
      if (props.orgName === LEFT_SIDEBAR_FRESHWORKS && props.icon && props.text) {
        if (!props.prepend)
          margin = 'margin-left: 10px;'
        if (props.prepend)
          margin = 'margin-right: 10px;'
      }
      return UTIL.btnFont(props.size) +
        margin
    }}

    ${(props) => {
      let styles = ``
      let iconColorConfig = props?.btnState?.default?.iconColor;
      if (iconColorConfig && !props.disabled) {
        if(!iconColorConfig.custom){
          styles += textColor(iconColorConfig.value, iconColorConfig.weight)
        }else{
          styles += `color: ${iconColorConfig.value};`
        }
      }
      return styles;
    }}

    ${(props) =>
      props.rotateIcon && `transform: rotate(${props.rotateIcon}deg);`
    }
  `
}

export default {
  name: 'CvtButton',
  components: {
    CvtIcon,
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      elevation: getters.GLOBAL_STYLE_ELEVATION,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      GradientColors: ({ globalTheme }) =>
        globalTheme?.GradientColors !== undefined
          ? globalTheme?.GradientColors
          : '',
      Border: ({ globalTheme }) => globalTheme.Border,
      // TODO: Need to be refactored in the future using a spanner config
      orgnization: ({ globalTheme }) => globalTheme.orgnization || 'convrrt',
      showGradient: ({ globalTheme }) =>
        globalTheme?.useButtonGredient !== undefined
          ? globalTheme.useButtonGredient
          : false,
      changeBorderColor: ({ globalTheme }) =>
        globalTheme.changeBorderColor !== undefined
          ? globalTheme.changeBorderColor
          : false,
      showBoxShadow: ({ globalTheme }) =>
        globalTheme.showBoxShadow !== undefined
          ? globalTheme.showBoxShadow
          : true,
      //TODO: orgName and organization are redundant.
      orgName: ({ globalTheme }) =>
        globalTheme?.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
      specialBtnTextColor: ({ globalTheme }) =>
        globalTheme?.specialBtnTextColor !== undefined
          ? globalTheme.specialBtnTextColor
          : '',
      pageManagerButtonsLoadingIcon: ({ globalTheme }) =>
        globalTheme?.pageManagerButtonsLoadingIcon !== undefined
          ? globalTheme.pageManagerButtonsLoadingIcon
          : true,
      buttonConfig: ({ globalTheme }) =>
        globalTheme.buttonConfig ? globalTheme.buttonConfig : '',
    }),
    setButtonShadow(){
      return this.showBoxShadow || this.shadow
    }
  },
  props: {
    color: {
      type: String,
      default: '',
    },
    colorOfActionIcon: {
      type: String,
      default: '',
    },
    modeOfActionIcon: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'LIGHT',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    shape: {
      type: String,
      default: '',
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
    prepend: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: [String, Object],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledWithNoBg: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    actionIcon: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    specialStyle: {
      type: String,
      default: '',
    },
    specialPadding: {
      type: String,
      default: '',
    },
    removeBackground: {
      type: Boolean,
      default: false,
    },
    customFontWeight: {
      type: String,
      default: 'inherit',
    },
    btnTextColor: {
      type: String,
      default: '',
    },
    overrideColor: {
      type: String,
      default: '',
    },
    overrideHoverBgColor: {
      type: String,
      default: '',
    },
    btnState:{
      type: Object,
      default: () => {}
    },
    btnTextChange: {
      type: Boolean,
      default: false,
    },
    rotateIcon: {
      type: String,
      default: ''
    },
    changeBtnBorderColor: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      focusViaClick: false,
      focusViaTab: false
    }
  },
  beforeDestroy() {
    this.$emit('clickOut', '')
  },
  methods: {
    click(e) {
      this.$emit('click', e)
    },
    setFocusViaClick() {
      this.focusViaClick = true
    },
    setFocusViaTab() {
      this.focusViaTab = true
    },
    removeFocus() {
      if (this.focusViaClick) this.focusViaClick = false
      if (this.focusViaTab) this.focusViaTab = false
    },
  },
  mounted() {
    window.addEventListener('mouseup', this.removeFocus)
  },
  created() {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    FontWeight = this.FontWeight
    Colors = this.Colors
    Border = this.Border
    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    textColor = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    elevation = this.elevation
    showGradient = this.showGradient
    changeBorderColor = this.changeBtnBorderColor ? !this.changeBtnBorderColor : this.changeBorderColor
    showBoxShadow = this.showBoxShadow
    GradientColors = this.GradientColors
    orgName = this.orgName
    buttonConfig = this.buttonConfig
  },
  render: function (h) {
    const Button = getButton()
    const SmallSpinner = getSmallSpinner()
    const IconButton = getIconButton()

    let btnColor = this.color
    if (!this.color && this.orgName === FRESHWORKS && !this.specialStyle) {
      btnColor = 'light'
    }

    let btnSize = this.orgName === FRESHWORKS ? 'sm' : this.size

    return (
      <Button
        class={{
          focusViaClick: this.focusViaClick && !this.focusViaTab,
          focusViaTab: this.focusViaTab && !this.focusViaClick,
          actionIcon: this.actionIcon,
        }}
        onkeyup={(e) => {
          if (e.keyCode == 9) this.setFocusViaTab()
        }}
        type={this.type}
        onmousedown={this.setFocusViaClick}
        onmouseup={this.removeFocus}
        onBlur={this.removeFocus}
        onClick={(e) => {
          this.setFocusViaClick()
          setTimeout(this.removeFocus, 200)
          this.click(e)
        }}
        themeing={btnColor}
        colorOfActionIcon={this.colorOfActionIcon}
        modeOfActionIcon={this.modeOfActionIcon}
        actionIcon={this.actionIcon}
        mode={this.mode}
        shape={this.shape}
        size={btnSize}
        // TODO: Need to be refactored in the future using a spanner config
        shadow={this.orgnization === 'infusionsoft' ? false : this.shadow}
        fullWidth={this.fullWidth}
        outlined={this.outlined}
        disabled={this.disabled || this.loading}
        disabledWithNoBg={this.disabledWithNoBg}
        prepend={this.prepend}
        icon={this.icon}
        icononly={!Boolean(this.text)}
        // TODO: Need to be refactored in the future using a spanner config
        orgnization={this.orgnization}
        specialStyle={this.specialStyle}
        showGradient={this.showGradient}
        // Why is there orgName & organization ??
        orgName={this.orgName}
        specialPadding={this.specialPadding}
        specialBtnTextColor={this.specialBtnTextColor}
        removeBackground={this.removeBackground}
        btnTextColor={this.btnTextColor}
        btnState={this.btnState}
        btnTextChange={this.btnTextChange}
        overrideColor={this.overrideColor}
        overrideHoverBgColor={this.overrideHoverBgColor}
        setButtonShadow={this.setButtonShadow}
      >
        {this.loading && this.pageManagerButtonsLoadingIcon && (
          <SmallSpinner></SmallSpinner>
        )}
        {!this.prepend && this.text && (
          <span style={{ fontWeight: this.customFontWeight }}>{this.text}</span>
        )}
        <IconButton
          icon={this.icon}
          size={this.size}
          orgName={this.orgName}
          prepend={this.prepend}
          text={this.text}
          btnState={this.btnState}
          disabled={this.disabled}
          rotateIcon={this.rotateIcon}
          hover
        ></IconButton>
        {this.prepend && this.text && (
          <span style={{ fontWeight: this.customFontWeight }}>{this.text}</span>
        )}
      </Button>
    )
  },
}
