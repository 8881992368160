import { styled, VueEmotion } from '@egoist/vue-emotion'
import VueI18n from 'vue-i18n'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import Vue from '../../../lib/utils/vue'
import { LEFT_SIDEBAR_FRESHWORKS, LEFT_SIDEBAR_STANDARD } from '../../constants'
import CvtButton from '../../molecules/Button'

Vue.use(VueEmotion)
Vue.use(VueI18n)

let Spacing,
  Radii,
  Border,
  AddPagesToRight,
  borderColor,
  backgroundColor,
  textFontSize,
  textLineHeight,
  elevation,
  color

const getExpandedPages = () => styled('div')`
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-gap: ${Spacing.SPACING_24}px;
  padding: ${Spacing.SPACING_24}px ${Spacing.SPACING_8}px;
  border-left: ${Border.SIZE_1}px solid;

  ${(props) =>
    props.type === LEFT_SIDEBAR_FRESHWORKS
      ? `
      position: fixed;
      top: 100px;
      right: 64px;
      background-color: #FFF;
      height: 100vh;
  `
      : ``}

  ${(props) =>
    props.mode == 'DARK'
      ? borderColor({ themeing: 'dark' }, 500)
      : borderColor({ themeing: 'dark' }, 100)};
`

const getPage = () => styled('div')`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  width: 350px;
  margin-bottom: ${Spacing.SPACING_24}px;

  &:last-child {
    margin-bottom: 0;
  }
`

const getPagesWrapper = () => styled('div')`
  overflow-y: auto;
  padding: 0 ${Spacing.SPACING_12}px;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${Radii.RADIUS_PX_8}px;
    ${(props) =>
      props.mode == 'DARK'
        ? backgroundColor({ themeing: 'dark' }, 600)
        : backgroundColor({ themeing: 'dark' }, 100)}
  }

  ${(props) =>
    props.type === LEFT_SIDEBAR_FRESHWORKS
      ? `height: calc(100vh - 230px);`
      : ``}
`

const getTitleWrapper = () => styled('div')`
  display: flex;
`
const getManagePageTitle = () => styled('div')`
  margin-top: auto;
  margin-bottom: auto;
`
const CvtPageSettings = {
  name: 'CvtPageSettings',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('globalTheme', {
      textColor: getters.GLOBAL_STYLE_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      elevation: getters.GLOBAL_STYLE_ELEVATION,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
    }),
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    type: {
      type: String,
      default: LEFT_SIDEBAR_STANDARD,
    },
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    borderColor = this.borderColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    elevation = this.elevation
    color = this.textColor
  },
  render: function (h) {
    const ExpandedPages = getExpandedPages()
    const Page = getPage()
    const PagesWrapper = getPagesWrapper()
    const TitleWrapper = getTitleWrapper()
    const ManagePageTitle = getManagePageTitle()

    return (
      <div>
        <ExpandedPages mode={this.mode} type={this.type}>
          <TitleWrapper>
            <CvtButton
              mode={this.mode}
              size={`md`}
              onClick={() => this.$emit('back')}
              icon={'fas fa-arrow-left'}
              actionIcon={true}
              removeBackground={true}
            ></CvtButton>
            <ManagePageTitle>
              {this.$t('editor.left_nav.manage_pages_title')}
            </ManagePageTitle>
          </TitleWrapper>

          <PagesWrapper mode={this.mode} type={this.type}>
            <Page>{this.$slots.default}</Page>
          </PagesWrapper>
        </ExpandedPages>
      </div>
    )
  },
}

export default CvtPageSettings
