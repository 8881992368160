<template>
  <div identity="c-card-options-wrap">

    <cvt-collapse
      :accordion="true"
      :theme="mode === 'LIGHT' ? 'light' : 'dark'"
      :prepend-chevron="prependChevron"
    >
      <!-- Implement Manage Fields options -->
      <cvt-collapse-item
        name="build"
        :title="$t(`element.property.form.manage_fields`)"
        :initial-active="true"
        :special-style="isSpecialStyle"
      >
        <div class="row">
          <div class="col-12">
            <div v-if="loading" class="placeholder-container">
              <cvt-content-placeholder class="placeholder" />
            </div>
            <cvt-select
              v-else
              :value="embeddedScript.name"
              mode="dropdown"
              :background-mode="mode"
              :floating-label="false"
              label="Select Form"
              @input="(value) => embeddedScript = value"
            >
              <option
                v-for="field in fields"
                :key="field.id"
                :value="field.name"
                v-text="field.name"
              />
            </cvt-select>
          </div>
        </div>
        <div class="divider"></div>
        <div class="row align-items-baseline">
          <div class="col-12 pb-2">
            Style Class Name
          </div>
          <div class="col-12">
            <cvt-input
              v-model="className"
              type="text"
              :clearable="false"
            />
          </div>
        </div>

      </cvt-collapse-item>
    </cvt-collapse>
  </div>
</template>

<script>
import * as _ from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import { LEFT_SIDEBAR_FRESHWORKS } from "../../../../../../storybook/components/constants";

export default {
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    prependChevron: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      isGridBuilder: getters.IS_GRID_BUILDER,
      formFieldGroups: getters.FORM_EDITOR_FIELD_GROUPS,
    }),
    ...mapState('globalTheme', {
      DividerWithBorder: ({ globalTheme }) =>
        globalTheme.DividerWithBorder !== undefined
          ? globalTheme.DividerWithBorder
          : true,
      RadioButtonShape: ({ globalTheme }) =>
        globalTheme.RadioButtonShape !== undefined
          ? globalTheme.RadioButtonShape
          : '',
    }),
    fields () {
      const fieldFilter = this.formFieldGroups.filter(x => x.label === "Embed Fields")
      if (fieldFilter.length > 0) {
        return fieldFilter[0].fields
      }
      return []
    },
    textColorClass() {
      return { 'text-white': this.mode === 'DARK' }
    },
    isSpecialStyle() {
      return this.OrgName === LEFT_SIDEBAR_FRESHWORKS
    },
    embeddedScript: {
      get() {
        return _.get(this, 'selected.embeddedScript', {})
      },
      set(value) {
        const filterList = this.fields.filter(x => x.name === value)
        if (filterList.length > 0) {
          return this.updateProp({ key: 'embeddedScript', value: filterList[0] })
        }
      },
    },
    className: {
      get() {
        return _.get(this, 'selected.className', "cvt-embedded-form")
      },
      set: _.throttle(function ({ target }) {
        return this.updateProp({ key: 'className', value: target.value })
      }, 300),
    },
  },
  mounted() {
    this.loading = true
    this.loadFields().finally(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
      loadFields: actions.FORM_EDITOR_LOAD_FIELDS,
    }),
  },
}
</script>

<style lang="scss"></style>
