
<svg id="el_y8HCNe8ID" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 939 746" style="enable-background:new 0 0 939.51 746.05;" xml:space="preserve">
  <g id="el_9_RQzQFXfW">
    <path id="el_BCcDyBLv9k" d="M926.01,744.55H13.5c-6.6,0-12-5.4-12-12V13.5c0-6.6,5.4-12,12-12h912.51c6.6,0,12,5.4,12,12v719.05,C938.01,739.15,932.61,744.55,926.01,744.55z"/>
    <g id="el_xwpdovcFpQ">
      <path id="el_8kN-DhKprI" d="M938.01,61.81H1.5V12.92C1.5,6.64,6.64,1.5,12.92,1.5h913.66c6.28,0,11.42,5.14,11.42,11.42L938.01,61.81,L938.01,61.81z"/>
    </g>
    <circle id="el_RM2o2ak984" cx="41.96" cy="33.43" r="14.76"/>
    <circle id="el_qSdVpTrbXG" cx="92.27" cy="33.43" r="14.76"/>
    <circle id="el_X26_P6-SeD" cx="142.57" cy="33.43" r="14.76"/>
  </g>
  <g id="el_tgcpy-6OKC_an_Z6ZlrtHwR" data-animator-group="true" data-animator-type="0"><g id="el_tgcpy-6OKC">
    <line id="el_3BHRb-c5TZ" x1="721.9" y1="527.47" x2="922.72" y2="527.47"/>
    <line id="el_kpQZhff43W" x1="721.9" y1="547.74" x2="922.72" y2="547.74"/>
    <rect id="el_5wqM1jmXV7" x="265.56" y="513.95" width="429.82" height="193.43"/>
  </g></g>
  <g id="el_cplgDyBHgR_an_KvOsz4RA-" data-animator-group="true" data-animator-type="0"><g id="el_cplgDyBHgR">
    <line id="el_8iHUsfQ3kZ" x1="265.56" y1="400.34" x2="922.72" y2="400.34"/>
    <line id="el_-6otIZ4HCs" x1="265.56" y1="425.69" x2="466.38" y2="425.69"/>
    <line id="el_-KlecWJh_e" x1="265.56" y1="445.96" x2="466.38" y2="445.96"/>
    <line id="el_2zeoZN6grnP" x1="265.56" y1="465.39" x2="466.38" y2="465.39"/>
    <line id="el_TkmJTRL5hNF" x1="494.55" y1="425.69" x2="695.37" y2="425.69"/>
    <line id="el_4_9CMEZICdf" x1="494.55" y1="445.96" x2="695.37" y2="445.96"/>
    <line id="el_fMwqYkwC6oc" x1="494.55" y1="465.39" x2="695.37" y2="465.39"/>
    <line id="el_BiQpV6mrY7u" x1="721.9" y1="425.69" x2="922.72" y2="425.69"/>
    <line id="el_MU0ihTKSJNx" x1="721.9" y1="445.96" x2="922.72" y2="445.96"/>
    <line id="el_FRp2Yd_Y9gt" x1="721.9" y1="465.39" x2="922.72" y2="465.39"/>
  </g></g>
  <g id="el_QA9ncuME6M6_an_G5LwyT6Nw" data-animator-group="true" data-animator-type="0"><g id="el_QA9ncuME6M6">
    <path id="el_qD5QA07HkX2" d="M914.08,369.51h-639.9c-4.77,0-8.63-3.86-8.63-8.63V87.58c0-4.77,3.86-8.63,8.63-8.63h639.9,c4.77,0,8.63,3.86,8.63,8.63v273.31C922.72,365.65,918.85,369.51,914.08,369.51z"/>
    <path id="el_QsmvgsCj25T" d="M365.18,369.17l107.97-144.88c0,0,16.85-20.99,29.88,0l47.63,57.68L637.18,165c0,0,30.45-38.76,58.83,0,l128.73,204.17H365.18z"/>
    <circle id="el_Cv1aQuOnjy2" cx="408.68" cy="151.22" r="42.71"/>
  </g></g>
  <g id="el_rt4gBZHqI96">
    <path id="el_kYKXVE5FScS" d="M88.17,744.55H15.92c-7.97,0-14.42-6.46-14.42-14.42V61.81h86.67V744.55z"/>
    <g id="el_JhOozElrmUA_an_4taMqPjUY" data-animator-group="true" data-animator-type="0"><path id="el_JhOozElrmUA" d="M62.66,138.07H27.53c-6.6,0-12-5.4-12-12V90.94c0-6.6,5.4-12,12-12h35.13c6.6,0,12,5.4,12,12v35.13,C74.66,132.67,69.26,138.07,62.66,138.07z"/></g>
    <g id="el_4_LkjOHjV_0_an_JWRji5mhn" data-animator-group="true" data-animator-type="0"><path id="el_4_LkjOHjV_0" d="M62.66,222.26H27.53c-6.6,0-12-5.4-12-12v-35.13c0-6.6,5.4-12,12-12h35.13c6.6,0,12,5.4,12,12v35.13,C74.66,216.86,69.26,222.26,62.66,222.26z"/></g>
    <g id="el_WTYjc2QnCZz_an_pEYEPD3Bo" data-animator-group="true" data-animator-type="0"><path id="el_WTYjc2QnCZz" d="M62.66,306.44H27.53c-6.6,0-12-5.4-12-12v-35.13c0-6.6,5.4-12,12-12h35.13c6.6,0,12,5.4,12,12v35.13,C74.66,301.04,69.26,306.44,62.66,306.44z"/></g>
    <g id="el_h94ldoQIzzo_an_SyGYMDrga" data-animator-group="true" data-animator-type="0"><path id="el_h94ldoQIzzo" d="M62.66,390.63H27.53c-6.6,0-12-5.4-12-12V343.5c0-6.6,5.4-12,12-12h35.13c6.6,0,12,5.4,12,12v35.13,C74.66,385.23,69.26,390.63,62.66,390.63z"/></g>
  </g>
  <g id="el_dBdyYdNq3pc">
    <g id="el_E_ae5SBZt-__an_6wEA4-RvY" data-animator-group="true" data-animator-type="0"><path id="el_E_ae5SBZt-_" d="M149.66,138.07h-35.13c-6.6,0-12-5.4-12-12V90.94c0-6.6,5.4-12,12-12h35.13c6.6,0,12,5.4,12,12v35.13,C161.66,132.67,156.26,138.07,149.66,138.07z"/></g>
    <g id="el_B8nqAKv3nDp_an_I9_KUfmvJ" data-animator-group="true" data-animator-type="0"><path id="el_B8nqAKv3nDp" d="M222.3,138.07h-35.13c-6.6,0-12-5.4-12-12V90.94c0-6.6,5.4-12,12-12h35.13c6.6,0,12,5.4,12,12v35.13,C234.3,132.67,228.9,138.07,222.3,138.07z"/></g>
    <g id="el_iTWaF2V7Hwo_an_tGOaN6o_S" data-animator-group="true" data-animator-type="0"><path id="el_iTWaF2V7Hwo" d="M149.66,211.56h-35.13c-6.6,0-12-5.4-12-12v-35.13c0-6.6,5.4-12,12-12h35.13c6.6,0,12,5.4,12,12v35.13,C161.66,206.16,156.26,211.56,149.66,211.56z"/></g>
    <g id="el_r4NOrq5WpVb_an_614VQyRQ1" data-animator-group="true" data-animator-type="0"><path id="el_r4NOrq5WpVb" d="M222.3,211.56h-35.13c-6.6,0-12-5.4-12-12v-35.13c0-6.6,5.4-12,12-12h35.13c6.6,0,12,5.4,12,12v35.13,C234.3,206.16,228.9,211.56,222.3,211.56z"/></g>
    <g id="el_lC7ZszIn8wH_an_65dHD96I6" data-animator-group="true" data-animator-type="0"><path id="el_lC7ZszIn8wH" d="M149.66,285.05h-35.13c-6.6,0-12-5.4-12-12v-35.13c0-6.6,5.4-12,12-12h35.13c6.6,0,12,5.4,12,12v35.13,C161.66,279.65,156.26,285.05,149.66,285.05z"/></g>
    <g id="el_aRA4YgVLoA5_an_KaefaQYQh" data-animator-group="true" data-animator-type="0"><path id="el_aRA4YgVLoA5" d="M222.3,285.05h-35.13c-6.6,0-12-5.4-12-12v-35.13c0-6.6,5.4-12,12-12h35.13c6.6,0,12,5.4,12,12v35.13,C234.3,279.65,228.9,285.05,222.3,285.05z"/></g>
    <g id="el_PcQdATDKHki_an_g9dQXDGSp" data-animator-group="true" data-animator-type="0"><rect id="el_PcQdATDKHki" x="88.17" y="61.81" width="161.39" height="682.74"/></g>
  </g>
</svg>
