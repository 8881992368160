import Vue from '../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import { mapState } from '../../../builder/node_modules/vuex'

Vue.use(VueEmotion)

let Colors

const getAnimatedPlaceholder = () => styled('span')`
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholder-shimmer;
  animation-timing-function: linear;
  ${(props) =>
    props.mode == 'DARK'
      ? `background: linear-gradient(
    to right,
    ${Colors.BASIC[800]} 8%,
    ${Colors.BASIC[700]} 18%,
    ${Colors.BASIC[800]} 33%
  );`
      : `background: linear-gradient(
    to right,
    ${Colors.BASIC[200]} 8%,
    ${Colors.BASIC[100]} 18%,
    ${Colors.BASIC[200]} 33%
  );`}
  background-size: 800px 104px;
  position: absolute;
  @keyframes placeholder-shimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
`

export const ContentPlaceholder = {
  name: 'ContentPlaceholder',
  data () {
    return {}
  },
  computed: {
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors
    })
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    }
  },
  created () {
    Colors = this.Colors
  },
  render: function (h) {
    const AnimatedPlaceholder = getAnimatedPlaceholder()

    return (
      <AnimatedPlaceholder mode={this.mode}>
        {this.$slots.default}
      </AnimatedPlaceholder>
    )
  }
}
