
<div>
  <!-- Delete Page Dialog -->
  <cvt-dialog
    :show="deleteVisible"
    :height-auto="true"
    size="sm"
    @close="abortPageRemoval"
  >
    <template #title>
      <h5>{{ $t("pagemanager.cvt_dialog.template.h5") }}</h5>
    </template>
    <cvt-alert
      class="w-100"
      color="warning"
      icon="exclamation-triangle"
    >
      <p>{{ $t("pagemanager.cvt_dialog.cvt_alert.p") }}</p>
    </cvt-alert>
    <template #modalFooter>
      <div class="d-flex">
        <cvt-button
          :loading="removing"
          class="mr-1"
          color="light"
          :text="$t(`pagemanager.cvt_dialog.submit.btn`)"
          @click="proceedPageRemoval"
        />
        <cvt-button
          :loading="removing"
          color="warning"
          :special-style="saveBtnStyle"
          :text="$t(`pagemanager.cvt_dialog.cancel.btn`)"
          @click="abortPageRemoval"
        />
      </div>
    </template>
  </cvt-dialog>

</div>
