import { mapGetters } from 'vuex'
import * as getters from '../../../store/modules/getters'
import { DESKTOP, MOBILE } from '../../../common/constants/constants'
import LayoutBreadcrumb from '../../editor/LayoutBreadcrumb.vue'
import { layoutStyles, contentStyle } from '../../../../../typings/components/ConvrrtEditorLayout'

export default {
  name: 'ConvrrtEditorLayout',
  components: {
    LayoutBreadcrumb,
  },
  props: {
    showLeftSideBar: {
      type: Boolean,
      required: true,
      default: true,
    },
    showEditorControls: {
      type: Boolean,
      required: true,
      default: true,
    },
    isGridBuilder: {
      type: Boolean,
      required: true,
      default: true,
    },
    viewMode: {
      type: String,
      required: true,
      default: DESKTOP,
    },
  },
  computed: {
    ...mapGetters({
      advanceSideBarOpen: getters.ADVANCED_MENU_OPEN,
      config: getters.AUTH_GET_USER_CONFIG
    }),
    isCheckoutMockPage():boolean {
      return this.$route.name === 'editor:checkout'
    },
    isDesktopView(): boolean {
      return this.viewMode === DESKTOP
    },
    isMobileView(): boolean {
      return this.viewMode === MOBILE
    },
    isInFreeTrialMode(): boolean {
      return this.config.TRIAL_MODE
    },
    layoutStyles(): layoutStyles{
      return {
        'gridbuilder': this.isGridBuilder,
        'oldbuilder': !this.isGridBuilder,
        'desktop-mode': this.isDesktopView,
        'mobile-mode': this.isMobileView,
        'layout-style-2': this.showEditorControls && !this.showLeftSideBar,
        'layout-style-1': (this.showEditorControls && this.showLeftSideBar) || (!this.showEditorControls && !this.showLeftSideBar),
        'has-sidebar': this.isGridBuilder || this.advanceSideBarOpen,
        'layout-style-checkout-mock': this.isCheckoutMockPage,
        'trial-mode': this.isInFreeTrialMode
      }
    },
    contentStyle(): contentStyle {
      return {
        'gridbuilder': this.isGridBuilder,
        'oldbuilder': !this.isGridBuilder,
        'layout-style-1-with-controls': this.showEditorControls && this.showLeftSideBar,
        'layout-style-2-with-controls ml-3': this.showEditorControls && !this.showLeftSideBar,
        'layout-plain': !this.showEditorControls && !this.isCheckoutMockPage,
        'layout-style-checkout-mock-editor': this.isCheckoutMockPage,
      }
    },
  },
}
