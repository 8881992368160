
<div>
  <org-header></org-header>

  <div class="row m-0 p-0 collect-more-content-wrap">
    <div
      class="col-sm-12 col-md-12 col-lg-6 m-0 p-0 collect-more-content-one"
    >
      <div class="sub-content-one">
        <h3 class="main-heading">Create your Convrrt account</h3>

        <div class="customize-builder-wrap">
          <img
            class="customize-builder-img"
            src="/img/organization/customize-builder.png"
            alt="Customize Builder Image"
          />
        </div>

        <div class="customize-builder-ul-wrap">
          <ul class="customize-builder-ul">
            <li>Try our platform risk-free</li>
            <li>Tailor to your brand in seconds</li>
            <li>Populate pre-built templates</li>
          </ul>
        </div>
      </div>

      <div class="sub-content-two">
        <h4 class="trusted-by-heading">
          Trusted by 60,000+ users create landing pages
        </h4>

        <div class="col-md-8 saas-leader-imgs-wrap">
          <img
            src="/img/organization/client-logos/sendinblue-logo.png"
            alt="Sendinblue Logo"
          />
          <img
            src="/img/organization/client-logos/keap-logo.png"
            alt="Keap Logo"
          />
          <img
            src="/img/organization/client-logos/sharpspring-logo.png"
            alt="Sharpspring Logo"
          />
          <img
            src="/img/organization/client-logos/integrate-logo.png"
            alt="Integrate Logo"
          />
          <img
            src="/img/organization/client-logos/instamojo-logo.png"
            alt="Instamojo Logo"
          />
        </div>
      </div>
    </div>

    <div
      class="col-sm-12 col-md-12 col-lg-6 m-0 p-0 collect-more-content-two"
    >
      <form class="fields-sub-content" @submit.prevent="onSubmit">
        <h3 class="sub-heading">Tell us more about yourself</h3>

        <div class="field-container">
          <p class="field-heading">Company Name</p>
          <input
            id="companyName"
            v-model="form.companyName"
            class="field-control"
            type="text"
            @blur="validateForm"
          />
          <p v-if="formErrors.msg.companyName" class="field-error">
            {{ formErrors.msg.companyName }}
          </p>
        </div>

        <div class="field-container">
          <p class="field-heading">Company website URL</p>
          <input
            id="companyWebsite"
            v-model="form.companyWebsite"
            class="field-control"
            type="url"
            @blur="validateForm"
          />
          <p v-if="formErrors.msg.companyWebsite" class="field-error">
            {{ formErrors.msg.companyWebsite }}
          </p>
        </div>

        <div class="field-container">
          <p class="field-heading">Industry</p>
          <select
            id="industry"
            v-model="form.industry"
            class="field-control"
            @blur="validateForm"
          >
            <option value="">Choose an option</option>
            <option value="Marketing Automation">Marketing Automation</option>
            <option value="Event Management">Event Management</option>
          </select>
          <p v-if="formErrors.msg.industry" class="field-error">
            {{ formErrors.msg.industry }}
          </p>
        </div>

        <div class="field-container">
          <p class="field-heading">How do you define your organization?</p>
          <cvt-radio-group
            class="radio-group"
            orientation="vertical"
            :disabled="loading"
            :initial-value="currentRadioValue"
            theme="dark"
          >
            <cvt-radio
              class="radio-item-container"
              size="md"
              value="enterprise"
              :border="false"
              @radioClick="() => onRadioClicked('enterprise')"
            >
              SAAS company (enterprise)
            </cvt-radio>
            <cvt-radio
              class="radio-item-container"
              size="md"
              value="agency"
              :border="false"
              @radioClick="() => onRadioClicked('agency')"
            >
              Digital Agency (handles multiple accounts)
            </cvt-radio>
            <cvt-radio
              class="radio-item-container"
              size="md"
              value="smb"
              :border="false"
              @radioClick="() => onRadioClicked('smb')"
            >
              SMB (manages one account)
            </cvt-radio>
          </cvt-radio-group>
        </div>

        <div
          v-if="currentRadioValue === 'enterprise'"
          class="field-container-cb"
        >
          <input
            v-model="form.enabled"
            class="field-control-cb"
            type="checkbox"
            value="popupsEnabled"
          />
          <p class="field-heading-cb">
            I’d like to incorporate popups in my sandbox
          </p>
        </div>

        <div v-if="currentRadioValue === 'agency'" class="field-container-cb">
          <input
            v-model="form.enabled"
            class="field-control-cb"
            type="checkbox"
            value="multipleAccEnabled"
          />
          <p class="field-heading-cb">I intend to manage multiple accounts</p>
        </div>

        <div class="field-container-cb">
          <input
            v-model="form.enabled"
            class="field-control-cb"
            type="checkbox"
            value="funnelsEnabled"
          />
          <p class="field-heading-cb">
            I’d like to activate sales funnels in my sandbox
          </p>
        </div>

        <div
          v-if="currentRadioValue === 'enterprise'"
          class="field-container-cb"
        >
          <input
            v-model="form.enabled"
            class="field-control-cb"
            type="checkbox"
            value="checkoutEnabled"
          />
          <p class="field-heading-cb">
            I’d like to have checkout/e-commerce options in my sandbox
          </p>
        </div>

        <div class="create-acc-btn-wrap">
          <button :disabled="loading" class="create-acc-btn">
            CREATE MY ACCOUNT
          </button>
        </div>
      </form>
    </div>

    <org-footer></org-footer>
  </div>
</div>
