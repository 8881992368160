import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'
import { ThemeManagerV1 } from '../services/theme-management/v1'
import { ThemeManagerV2 } from '../services/theme-management/v2'
import { ButtonFormat, FroalaFormat, FroalaLinkFormat } from 'src/js/lib/font'
import { Commit } from 'vuex'
import { type ThemeState, type ThemeData } from 'types'
import {
  ButtonTypography,
  FroalaLinkTypography,
  Typography,
} from '../services/theme-management/types'
import { Color } from 'color'

function cleanNewState(newState?: { [key: string]: any }) {
  const state: { [key: string]: any } = {}
  if (!newState) {
    return state
  }
  Object.entries(newState).forEach(([key, value]) => {
    if (key !== 'themeManager') {
      state[key] = value
    }
  })
  return state
}

export default {
  state: {
    colors: {},
    blockColors: {},
    typography: {},
    buttonTypography: {},
    froalaLinkTypography: {},
    availableFonts: [],
    defaultColor: {},
    brandLogo: null,
    themeManager: null,
  },
  getters: {
    [getters.THEME](state: ThemeState) {
      return {
        colors: state.colors,
        blockColors: state.blockColors,
        buttonTypography: state.buttonTypography,
        typography: state.typography,
      }
    },
    [getters.THEME_TYPOGRAPHY](state: ThemeState) {
      return state.typography
    },
    [getters.THEME_GET_COLORS](state: ThemeState) {
      return state.colors
    },
    [getters.THEME_GET_BLOCK_COLORS](state: ThemeState) {
      return state.blockColors
    },
    [getters.THEME_DEFAULT_COLOR](state: ThemeState) {
      return state.defaultColor
    },
    [getters.THEME_AVAILABLE_FONTS](state: ThemeState) {
      return state.availableFonts
    },
    [getters.THEME_FONT_STYLES](state: ThemeState) {
      return [
        { style: 'title', label: 'Title' },
        { style: 'subtitle', label: 'Subtitle' },
        { style: 'heading', label: 'Heading' },
        { style: 'alternativeHeading', label: 'Alternative Heading' },
        { style: 'lead', label: 'Lead' },
        { style: 'normalText', label: 'Normal Text' },
        { style: 'blockquote', label: 'Blockquote' },
      ].map((s) => {
        return {
          ...s,
          format: state.typography[s.style as keyof Typography],
        }
      })
    },
    [getters.THEME_BUTTON_DEFAULT_STYLES](state: ThemeState) {
      return [{ item: 'button', label: 'Button' }].map((s) => {
        return {
          ...s,
          format: state.buttonTypography[s.item as keyof ButtonTypography],
        }
      })
    },
    [getters.THEME_FROALA_LINK_DEFAULT_STYLES](state: ThemeState) {
      return [
        { item: 'link', label: 'Link' },
        { item: 'linkHover', label: 'Hover Link' },
        { item: 'linkVisited', label: 'Visited Link' },
      ].map((s) => {
        return {
          ...s,
          format:
            state.froalaLinkTypography[s.item as keyof FroalaLinkTypography],
        }
      })
    },
    [getters.THEME_BRAND_LOGO](state: ThemeState) {
      return state.brandLogo
    },
    [getters.THEME_CAN_ADD_NEW_COLORS](state: ThemeState) {
      if (!state.themeManager) {
        return false
      }
      return state.themeManager.canAddNewColors()
    },
    [getters.THEME_CAN_EDIT_COLORS](state: ThemeState) {
      if (!state.themeManager) {
        return false
      }
      return state.themeManager.canEditColors()
    },
    [getters.THEME_MANAGER_VERSION](state: ThemeState) {
      if (!state.themeManager) {
        return 1
      }
      return state.themeManager.version
    },
    [getters.THEME_MANAGER](state: ThemeState) {
      return state.themeManager
    },
  },
  mutations: {
    [mutations.THEME_UPDATE_DATA](state: ThemeState, theme: ThemeData) {
      const newState = state.themeManager?.updateThemeData(state, theme)
      Object.assign(state, newState)
    },
    [mutations.THEME_SET_FONT](
      state: ThemeState,
      { style, format }: { style: string; format: FroalaFormat },
    ) {
      const newState = state.themeManager?.setFont(state, {
        style,
        format,
      })
      Object.assign(state, cleanNewState(newState))
    },
    [mutations.THEME_SET_BUTTON_DEFAULT_STYLES](
      state: ThemeState,
      { item, format }: { item: string; format: ButtonFormat },
    ) {
      const newState = state.themeManager?.setButtonDefaultStyles(state, {
        item,
        format,
      })
      Object.assign(state, cleanNewState(newState))
    },

    [mutations.THEME_SET_FROALA_LINK_DEFAULT_STYLES](
      state: ThemeState,
      { item, format }: { item: string; format: FroalaLinkFormat },
    ) {
      const newState = state.themeManager?.setFroalaLinkDefaultStyles(state, {
        item,
        format,
      })
      Object.assign(state, cleanNewState(newState))
    },
  },
  actions: {
    async [actions.THEME_INIT](
      { state, rootGetters }: { state: ThemeState; rootGetters: any },
      fbSitePath: string,
    ) {
      const currentProductVersionNumber = rootGetters[getters.CURRENT_PRODUCT_VERSION_NUMBER]
      if (state.themeManager) {
        state.themeManager.destroy()
      } else {
        const themeManagerClass = currentProductVersionNumber >= 4.0 ? ThemeManagerV2 : ThemeManagerV1
        // @ts-expect-error
        state.themeManager = new themeManagerClass(fbSitePath)
      }

      const newState = await state.themeManager?.initThemeLoad()
      Object.assign(state, cleanNewState(newState))

      state.themeManager?.themeRef.on('value', (snap: any) => {
        let theme = snap.val()
        state.themeManager
          ?.themeSetup(state, theme)
          .then((newState: ThemeState) => {
            Object.assign(state, cleanNewState(newState))
          })
      })
    },
    async [actions.THEME_UPDATE_COLOR](
      { state }: { state: ThemeState },
      { color, key }: { color: Color; key: string },
    ) {
      if (!state.themeManager) {
        return key
      }
      const [newKey, newState] = await state.themeManager.updateColor(state, {
        color,
        key,
      })
      Object.assign(state, cleanNewState(newState))
      return newKey
    },
    async [actions.THEME_ADD_NEW_COLOR](
      { state }: { state: ThemeState },
      color: Color,
    ) {
      if (!state.themeManager) {
        return color
      }
      const [key, newState] = await state.themeManager.addNewColor(state, color)
      Object.assign(state, cleanNewState(newState))
      return key
    },
    async [actions.THEME_ADD_COLORS_FROM_BLOCK](
      { state }: { state: ThemeState },
      colors: { [key: string]: Color },
    ) {
      if (!state.themeManager) {
        return colors
      }
      const [newState] = await state.themeManager.addColorsFromBlock(
        state,
        colors,
      )
      Object.assign(state, cleanNewState(newState))
      return colors
    },
    async [actions.THEME_SET_FONT](
      { state, commit }: { state: ThemeState; commit: Commit },
      { style, format }: { style: string; format: FroalaFormat },
    ) {
      commit(mutations.THEME_SET_FONT, { style, format })
      return state.themeManager?.addFont(state, state.typography);
    },
    async [actions.THEME_SET_BRAND_LOGO](
      { state }: { state: ThemeState },
      logo: string,
    ) {
      state.brandLogo = logo
      return state.themeManager?.addBrandLogo(state, logo);
    },
    async [actions.THEME_REMOVE_COLOR](
      { state }: { state: ThemeState },
      color: string,
    ) {
      return state.themeManager?.removeColor(state, color);
    },
    async [actions.THEME_SET_BUTTON_DEFAULT_STYLES](
      { state, commit }: { state: ThemeState; commit: Commit },
      { item, format }: { item: string; format: ButtonFormat },
    ) {
      commit(mutations.THEME_SET_BUTTON_DEFAULT_STYLES, { item, format })
      return state.themeManager?.addButtonDefaultStyles(
        state,
        state.buttonTypography,
      );
    },
    async [actions.THEME_SET_FROALA_LINK_DEFAULT_STYLES](
      { state, commit }: { state: ThemeState; commit: Commit },
      { item, format }: { item: string; format: FroalaLinkFormat },
    ) {
      commit(mutations.THEME_SET_FROALA_LINK_DEFAULT_STYLES, {
        item,
        format,
      })
      return state.themeManager?.addFroalaLinkDefaultStyles(
        state,
        state.froalaLinkTypography,
      );
    },
  },
}
