import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'
import { database } from '@/js/store/services/firebase.service'

let db = database()

export default {
  state: {
    openGraphRef: null,
    settings: {
      title: '',
      description: '',
      image: '',
      url: '',
    },
  },
  getters: {
    [getters.OPEN_GRAPH_SETTINGS]({ settings }) {
      return settings
    },
  },
  mutations: {
    [mutations.OPEN_GRAPH_UPDATE](state, settings) {
      state.settings = settings
    },
  },
  actions: {
    [actions.OPEN_GRAPH_INIT]({ state, commit }, fbSitePath) {
      if (state.openGraphRef) {
        state.openGraphRef.off('value')
      }

      state.openGraphRef = db.ref(fbSitePath).child('opengraph')

      state.openGraphRef.on('value', (snap) => {
        let graph = snap.val()
        if (graph) {
          commit(mutations.OPEN_GRAPH_UPDATE, graph)
        }
      })
    },
    [actions.OPEN_GRAPH_SAVE]({ state, commit }, settings) {
      return state.openGraphRef.set(settings).then((_) => {
        commit(mutations.OPEN_GRAPH_UPDATE, settings)
      })
    },
  },
}
