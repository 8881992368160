
import FbVNode from '../../../../../components/base/FbVNode.vue'
import FirebaseNodeV3 from '../../../base/FirebaseNode' // If any property & function exist in both FbVNode & FirebaseNode, then properties & functions present in FirebaseNode would take precendence.
import CButtonV3 from '../CButton.vue'
import { componentLocalClasses, productImgThumbStyles } from '../../../../../common/types/components-typings/Checkout' // import of type definitions 


export default FbVNode.extend({
  name: 'CheckoutV3',
  components: {
    CButtonV3,
  },
  mixins: [FirebaseNodeV3],  
  props: {
    showThumbnail: {
      type: Boolean,
      required: false
    },
    showTitle: {
      type: Boolean,
      required: false
    },
    showBenefits: {
      type: Boolean,
      required: false
    },
    showSavings: {
      type: Boolean,
      required: false
    },
    showCost: {
      type: Boolean,
      required: false
    },
    product: {
      type: Object,
      required: false,
      default: () => {},
    }
  },
  data () {
    return {
      name: 'Checkout'
    }
  },
  computed: {
    componentLocalClasses(): componentLocalClasses {
      return {
        ...this.classes,
      }
    },
    productImgThumbStyles(): productImgThumbStyles {
      return {
        backgroundImage: `url(${this.productThumbnailUrl})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }
    },
    productThumbnailUrl(): string {
      return this.product.thumbnailUrl || '/img/product--placeholder.svg'
    },
  }
})