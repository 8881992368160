<template>
	<cvt-collapse
		:accordion="true"
		:theme="mode === 'LIGHT' ? 'light' : 'dark'"
		:prepend-chevron="prependChevron"
	>
		<cvt-collapse-item
			name="Properties"
			:title="$t('elements.properties.checkout.mock.payment.page.display_options')"
			:initial-active="true"
		>
			<div class="row mt-2">
				<div class="col">
					<h6 :class="textColorClass">
						{{ $t('elements.properties.checkout.mock.thankyou.page.display_options.thankyou') }}
					</h6>
				</div>
				<div class="col-3">
					<cvt-switch
						size="sm"
						color="active"
						type="circular"
						description-type="default"
						:initial-state="showThankYouMessage"
						:mode="mode"
						@switchToggle="(status) => onSwitchToggle('showThankYouMessage', status)"
					></cvt-switch>
				</div>
			</div>

      <div class="divider" />

			<div class="row mt-2">
				<div class="col">
					<h6 :class="textColorClass">
						{{ $t('elements.properties.checkout.mock.thankyou.page.display_options.contact_info') }}
					</h6>
				</div>
				<div class="col-3">
					<cvt-switch
						size="sm"
						color="active"
						type="circular"
						description-type="default"
						:initial-state="showContactEmail"
						:mode="mode"
						@switchToggle="(status) => onSwitchToggle('showContactEmail', status)"
					></cvt-switch>
				</div>
			</div>

      <div class="divider" />

			<div class="row mt-2">
				<div class="col">
					<h6 :class="textColorClass">
						{{ $t('elements.properties.checkout.mock.thankyou.page.display_options.billing_info') }}
					</h6>
				</div>
				<div class="col-3">
					<cvt-switch
						size="sm"
						color="active"
						type="circular"
						description-type="default"
						:initial-state="showBillingInfo"
						:mode="mode"
						@switchToggle="(status) => onSwitchToggle('showBillingInfo', status)"
					></cvt-switch>
				</div>
			</div>

      <div class="divider" />

			<div class="row mt-2">
				<div class="col">
					<h6 :class="textColorClass">
						{{ $t('elements.properties.checkout.mock.thankyou.page.display_options.order_summary') }}
					</h6>
				</div>
				<div class="col-3">
					<cvt-switch
						size="sm"
						color="active"
						type="circular"
						description-type="default"
						:initial-state="showOrderSummary"
						:mode="mode"
						@switchToggle="(status) => onSwitchToggle('showOrderSummary', status)"
					></cvt-switch>
				</div>
			</div>

      <div class="divider" />

			<div class="row mt-2">
				<div class="col">
					<h6 :class="textColorClass">
						{{ $t('elements.properties.checkout.mock.thankyou.page.display_options.order_items') }}
					</h6>
				</div>
				<div class="col-3">
					<cvt-switch
						size="sm"
						color="active"
						type="circular"
						description-type="default"
						:initial-state="showItemsInOrder"
						:mode="mode"
						@switchToggle="(status) => onSwitchToggle('showItemsInOrder', status)"
					></cvt-switch>
				</div>
			</div>

			<template v-if="showThankYouMessage">
				<div class='row mt-3'>
					<div class='col-md-12 ml-auto'>
						<cvt-input
							type="text"
							:label="$t('elements.properties.checkout.mock.thankyou.page.thankyou_message_heading')"
							:validation-status="isThankYouMessage1Valid"
							:value="thankYouMessage1"
							@input="(e) => updateProperty('thankYouMessage1', e.target.value)"
						/>
					</div>
				</div>
				<div class='row'>
					<div class='col-md-12 ml-auto'>
						<cvt-input
							type="text"
							:label="$t('elements.properties.checkout.mock.thankyou.page.thankyou_message_subheading')"
							:validation-status="isThankYouMessage2Valid"
							:value="thankYouMessage2"
							@input="(e) => updateProperty('thankYouMessage2', e.target.value)"
						/>
					</div>
				</div>
				<div class='row'>
					<div class='col-md-12 ml-auto'>
						<cvt-input
							type="textarea"
							:label="$t('elements.properties.checkout.mock.thankyou.page.thankyou_message_long_text')"
							:validation-status="isThankYouMessageSubtitleValid"
							:value="thankYouMessageSubtitle"
							@input="(e) => updateProperty('thankYouMessageSubtitle', e.target.value)"
						/>
					</div>
				</div>
			</template>

		</cvt-collapse-item>
	</cvt-collapse>
</template>

<script>
import * as _ from 'lodash'
import * as getters from "@/js/store/modules/getters";
import * as actions from "@/js/store/modules/actions";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CheckoutThankYou",
  props: {
    mode: {
			type: String,
			default: 'LIGHT',
    },
    prependChevron: {
			type: Boolean,
			default: false,
    },
  },
	data() {
		return { }
	},
  computed: {
		...mapGetters({
			selected: getters.SELECTOR_SELECTED
		}),
		textColorClass() {
			return { 'text-white': this.mode == 'DARK' }
		},
		showThankYouMessage: {
			get () {
				return this.selected.showThankYouMessage
			},
			set (value) {
				return this.updateProp({ key: 'showThankYouMessage', value })
			}
		},
		thankYouMessage1: {
			get () {
				return this.selected.thankYouMessage1 || ''
			},
			set (value) {
				return this.updateProp({ key: 'thankYouMessage1', value })
			}
		},
		thankYouMessage2: {
			get () {
				return this.selected.thankYouMessage2 || ''
			},
			set (value) {
				return this.updateProp({ key: 'thankYouMessage2', value })
			}
		},
		thankYouMessageSubtitle: {
			get () {
				return this.selected.thankYouMessageSubtitle || ''
			},
			set (value) {
				return this.updateProp({ key: 'thankYouMessageSubtitle', value })
			}
		},
		showContactEmail: {
			get () {
				return this.selected.showContactEmail
			},
			set (value) {
				return this.updateProp({ key: 'showContactEmail', value })
			}
		},
		showBillingInfo: {
			get () {
				return this.selected.showBillingInfo
			},
			set (value) {
				return this.updateProp({ key: 'showBillingInfo', value })
			}
		},
		showOrderSummary: {
			get () {
				return this.selected.showOrderSummary
			},
			set (value) {
				return this.updateProp({ key: 'showOrderSummary', value })
			}
		},
		showItemsInOrder: {
			get () {
				return this.selected.showItemsInOrder
			},
			set (value) {
				return this.updateProp({ key: 'showItemsInOrder', value })
			}
		},
		isThankYouMessage1Valid() {
			return (this.selected.thankYouMessage1 && this.selected.thankYouMessage1.length > 0 ) ? 'valid' : 'invalid'
		},
		isThankYouMessage2Valid() {
			return (this.selected.thankYouMessage2 && this.selected.thankYouMessage2.length > 0 ) ? 'valid' : 'invalid'
		},
		isThankYouMessageSubtitleValid() {
			return (this.selected.thankYouMessageSubtitle && this.selected.thankYouMessageSubtitle.length > 0 ) ? 'valid' : 'invalid'
		},
  },
  methods: {
		...mapActions({
			updateProp: actions.SELECTOR_UPDATE_PROP
		}),
		onSwitchToggle (propertyName, status) {
      this[propertyName] = (status === 'Active')
		},
		updateProperty (propertyName, value) {
      this[propertyName] = value
		}
  }
}
</script>

<style lang="scss" scoped>
	.divider {
		width: 100%;
		border-top: 1px solid #eee;
		margin-top: 0.75rem !important;
		margin-bottom: 0.75rem !important;
	}
</style>