import axios from 'axios'
import APIClient from "./ApiClient";

const client = axios.create({
  timeout: 60000,
  baseURL: 'https://cdn.convrrt.com',
  withCredentials: false
})

class CDNClient extends APIClient {
  _client = client

  countries () {
    return this.get('/assets/ISO-3166/data/countries/all.json').then(({data}) => data)
  }

  subdivisionByCountryCode (isoCode) {
    return this.get(`/assets/ISO-3166/data/subdivisions/by_country/${isoCode}.json`).then(({data}) => data)
  }
}

export default new CDNClient()