import { styled, VueEmotion } from '@egoist/vue-emotion'
import Vue from '../../../lib/utils/vue'
import { CvtIcon } from '../../molecules/icon';
import ColorPicker from "../../../../builder/src/js/components/spectrum/ColorPicker.vue"


Vue.use(VueEmotion)


const getContainerColor = () => styled('div')`
   margin-bottom: 40px !important;
`


const getColorHeader = () => styled('p')`
   font-size: 20px;
   margin-bottom: 10px;
`


const getColorGroupContainer = () => styled('div')`
   width: 100%;
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(50px, max-content));
   grid-gap: 10px;
   place-items: center;

   &::-webkit-scrollbar{
    display: none;
   }
`


const getColorBoxContainer = () => styled('div')`
   display: flex;
`


const getColorBox = () => styled('div')`
   height: 50px;
   width: 50px;
   border: 1px solid #f7f7f7;
   border-radius: 3px;
   display: grid;
   place-content: center;
   cursor: pointer;
   ${(props) => props.bgColor && `background-color:${props.bgColor};`}
   ${(props) => props.isActive && `border: 3px solid #5D62AB;`}
`


const getColorActiveBox = () => styled('div')`
   display: flex;
   justify-content: center;
   align-items: center;
`


const getColorActiveLogo = () => styled('img')`
   height: 20px;
`


const getAddPrimaryColor = () => styled('div')`
   height: 50px;
   width: 50px;
   border: 1px dashed #BCC2CB;
   border-radius: 4px;
   display: grid;
   place-content: center;
   cursor: pointer;
`


const getAddPrimaryColorIcon = () => styled(CvtIcon)`
   font-size: 25px;
   color: #5D62AB;
`


export const CvtColorSelector = {
   name: 'CvtColorSelector',
   components: {
   ColorPicker
   },
   data () {
   return {
       activeColor: null,
       selectedColor: '',
       showColor: false
   }
   },
   props: {
       label: {
           type: String,
           default: ''
       },
       brandColors: {
           type: Array,
           default: () => []
       }
   },
   computed: {},
   methods: {
       onSelectColor(idx) {
           this.activeColor = idx
           this.$emit('setCurrentColor', idx)
       },
       showColorPicker() {
           this.showColor = !this.showColor
       },
       updateCurrentColor(color) {
           this.selectedColor = color
       },
       selectNewColor(){
           this.$emit('selectNewColor', this.selectedColor)
           this.showColor = false
       }
   },
   render: function (h) {


       const ContainerColor = getContainerColor()
       const ColorHeader = getColorHeader()
       const ColorGroupContainer = getColorGroupContainer()
       const ColorBoxContainer = getColorBoxContainer()
       const ColorActiveBox = getColorActiveBox()
       const ColorActiveLogo = getColorActiveLogo()
       const ColorBox = getColorBox()
       const AddPrimaryColor = getAddPrimaryColor()
       const AddPrimaryColorIcon = getAddPrimaryColorIcon()




       return (
           <ContainerColor>
               <ColorHeader>{ this.label }</ColorHeader>
               <ColorGroupContainer>
               { this.brandColors.map((color, idx) => (
                   <ColorBoxContainer
                       key={idx}
                       onClick={() => this.onSelectColor(idx)}
                   >
                       <ColorBox
                           bgColor={color.hex}
                           isActive={this.activeColor === idx}
                       >
                           <ColorActiveBox>
                           {
                               this.activeColor === idx && (
                                   <ColorActiveLogo
                                       src="/img/enterprise/branding-check-light.svg"
                                       alt="check"
                                   />
                               )
                           }
                           </ColorActiveBox>
                       </ColorBox>
                   </ColorBoxContainer>
               ))}
                   <AddPrimaryColor
                       onclick={() => this.showColorPicker()}
                   >
                       <AddPrimaryColorIcon
                           icon="fa fa-plus-circle"
                   />
                   </AddPrimaryColor>
                   <cvt-dialog
                       bg-color="#ffffff"
                       size="sm"
                       height-auto={true}
                       show-close={true}
                       show={this.showColor}
                       onclose={() => this.showColorPicker()}
                   >
                       <div class="row no-gutters">
                           <div class="col-auto">
                               <color-picker
                                   ref="picker"
                                   oncolor-changed={this.updateCurrentColor}
                               />
                           </div>
                       </div>
                       <cvt-button
                           color="success"
                           text="Done"
                           onClick={() => this.selectNewColor()}
                       />
                   </cvt-dialog>
               </ColorGroupContainer>
           </ContainerColor>
       )
   }
}
