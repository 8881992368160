import Vue from '../../../lib/utils/vue'
import { styled, VueEmotion } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from 'vuex'

import CvtDropdown from '../../../components/molecules/dropdown/Dropdown'
import CvtCustomAnalytics from '../../../components/molecules/CustomAnalytics'

Vue.use(VueEmotion)

let Spacing;

const getDropdown  = () => styled(CvtDropdown)``

const getAnalyticsMainContainer = () => styled('div')`
  padding: ${Spacing.SPACING_18+Spacing.SPACING_2}px;
`

const getAnalyticsDropdownContainer = () => styled('div')`
  margin-bottom: ${Spacing.SPACING_32+Spacing.SPACING_8}px;
`

const getAnalyticsContainer = () => styled('div')`
  margin-bottom: ${Spacing.SPACING_48+Spacing.SPACING_12}px;;z
`

const getCustomAnalytics = () => styled(CvtCustomAnalytics)``


export const CvtAnalyticsSidebar = {
  name: 'CvtAnalyticsSidebar',
  props: {
    site: {
      type: Object,
      required: true,
      default: () => { }
    },
    metrics: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  components: {
    CvtCustomAnalytics
  },
  data() {
    return {
      selectedVal: 30
    }
  },
  methods:{
    changeRange(v){
      this.selectedVal = v;
      this.$emit('changeRange', this.selectedVal)
    }
  },
  created () {
    Spacing = this.Spacing
  },
  computed: {
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
    }),
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG
    }),
    dateRangeOptions() {

      let range = [
        { value: 1, label: '' },
        { value: 3, label: '' },
        { value: 5, label: '' },
        { value: 7, label: '' },
        { value: 15, label: '' },
        { value: 30, label: '' }
      ];

      let today = new Date();
      let date2 = today.toLocaleString('en-us', { month: 'short' }) + ' ' + today.getDate();

      for (let idx = 0; idx < range.length; idx++) 
      {
        let val = range[idx].value - 1;
        let td = new Date();

        td.setDate(td.getDate() - val );

        let date1 = td.toLocaleString('en-us', { month: 'short' }) + ' ' + td.getDate();
        
        range[idx].label = `Last ${range[idx].value} days ( ${date1} - ${date2} )`;
      }

      return range;
    },

    selectedLabel() {

      let defaultLabel = 'Select a Range';

      if(this.selectedVal) {
        return this.dateRangeOptions.filter(rn => rn.value == this.selectedVal )[0].label || defaultLabel;
      }

      return defaultLabel;
    },

    analyticsEnabed(){
      return this.config.SIDEBAR_ANALYTICS_ENABLED === true || this.config.SIDEBAR_ANALYTICS_ENABLED == 'true';
    }

  },

  render: function (h) {

    const Dropdown = getDropdown()
    const AnalyticsMainContainer = getAnalyticsMainContainer()
    const AnalyticsContainer = getAnalyticsContainer()
    const CustomAnalytics = getCustomAnalytics()
    const AnalyticsDropdownContainer = getAnalyticsDropdownContainer()

    if( !this.analyticsEnabed ){
      return (
        <AnalyticsMainContainer>
          You are not Authorized to view this
        </AnalyticsMainContainer>
      )
    }

    const RangeOptions = this.dateRangeOptions.map((item, index) => {
      return ( <option value={item.value} icon={''}>{item.label}</option> );
    });

    let queryResults = this.metrics.length ? this.metrics[0] : {
      unique_visitors: 0,
      bounce_rate: 0,
      leads_collected: 0,
      conversion_rate: 0
    }

    console.debug('Analytics Query Results: ', queryResults);

    let data = [
      { pvalue: queryResults.unique_visitors, label: this.$t('elements.properties.analytics.visitors'), postfix: '' },
      { pvalue: queryResults.bounce_rate, label: this.$t('elements.properties.analytics.bounce.rate'), postfix: '%' },
      { pvalue: queryResults.leads_collected, label: this.$t('elements.properties.analytics.leads.generated'), postfix: ''  },
      { pvalue: queryResults.conversion_rate, label: this.$t('elements.properties.analytics.conversion.rate'), postfix: '%'  },
    ];

    return (
      <AnalyticsMainContainer>
        <AnalyticsDropdownContainer>

          <Dropdown
            size={'xs'}
            bgColor='basic'
            fullWidth={true}
            onHoverTriggerMode={true}
            clickAction={true}
            title={this.selectedLabel}
            ondropDownClickAction={(e) => this.changeRange(e)}         
          >
            {RangeOptions}
          </Dropdown>

        </AnalyticsDropdownContainer>

        {data.map(d => (
          <AnalyticsContainer>
            <CustomAnalytics
              pvalue={d.pvalue}
              label={d.label}
              postfix={d.postfix}
            />
          </AnalyticsContainer>
        ))}
      </AnalyticsMainContainer>
    )

  }

}
