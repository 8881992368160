<template>
  <cvt-dialog
    v-if="!AltPopupManager"
    size="lg"
    bg-color="#fff"
    :show="show"
    :show-close="false"
    :height-auto="true"
    :show-header="false"
    :show-footer="false"
  >
    <div class="row mb-4 mt-4">
      <div class="col-md-10 text-center mx-auto">
        <h1 class="setup-title" :style="titleStyle">
          {{ titleMessage }}
        </h1>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-10 text-center mx-auto">
        <h5 class="setup-title-desc" :style="titleStyle"> {{ titleDescription }}</h5>
      </div>
    </div>

    <div v-if="canEditTemplateName" class="row mb-2">
      <div class="col-md-10 mx-auto">
        <cvt-input
          id="templateNameId"
          :value="siteName"
          type="text"
          :maxlength="150"
          name="name"
          :label="$t('settings.page.label')"
          :clearable="false"
          :validation-status="nameInvalid ? 'invalid' : 'valid'"
          :valid-message="$t('settings.page.validation')"
          :invalid-message="$t(invalidFieldMsg)"
          @input="(e) => onChangeSiteName(e)"
        />
        <div v-show="nameInvalid" class="invalid-feedback">
          {{ $t('setup.template.name') }}
        </div>
      </div>
    </div>

    <div  v-if="canUseBrandPreference">
    <div class="row mb-4">
      <div class="col-md-10 mx-auto">

        <cvt-select
        id="templatePreference"
      mode="dropdown"
      :label="$t('settings.page.preferences')"
      :value="preference"
      :dropdown-menu-styles="{position: 'relative'}"
      @input="(value) => choosePreference(value)"
    >
    <option disabled value="">{{ $t('settings.page.preference_select') }}</option>
      <option v-for="pr in preferences" :key="pr" :value="pr" v-text="pr" />
    </cvt-select>
      </div>
    </div>
    </div>

    <div class="row mb-4">
      <div  v-if="canEditTemplateName" class="mx-auto">
        <cvt-button
          size="md"
          color="primary"
          :text="$t('setup.template.btn_text')"
          theme="light"
          :outlined="false"
          :full-width="true"
          :disabled="nameInvalid"
          :loading="loading"
          @click="() => continueHandle(false)"
        />
      </div>

      <div  v-if="brandPreferenceEnabled && !preferences.length" class="mx-auto">
        <cvt-button
          size="md"
          color="primary"
          :text="$t('settings.page.create_new_preference')"
          theme="light"
          :outlined="false"
          :full-width="true"
          @click="createNewPreference"
        />
      </div>
    </div>
  </cvt-dialog>
  <cvt-dialog
    v-else
    bg-color="#ffffff"
    size="sm"
    :show="show"
    :show-close="false"
    :height-auto="true"
    :show-header="false"
    :show-footer="false"
  >
    <div class="row mb-2 mt-4">
      <div class="col-md-12">
        <h5 class="setup-title" :style="titleStyle">
          {{ $t('setup.message.title') }}
        </h5>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-12">
        <cvt-input
          id="templateNameId"
          :value="siteName"
          type="text"
          :maxlength="150"
          name="name"
          :label="$t('settings.page.label')"
          :clearable="false"
          :validation-status="nameInvalid ? 'invalid' : 'valid'"
          :valid-message="$t('settings.page.validation')"
          :invalid-message="$t(invalidFieldMsg)"
          @input="(e) => onChangeSiteName(e)"
        />
        <div v-show="nameInvalid" class="invalid-feedback">
          {{ $t('setup.template.name') }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="ml-auto mr-3">
        <cvt-button
          size="sm"
          color=""
          :text="$t('setup.template.btn_text')"
          theme="light"
          special-style="dark"
          :outlined="true"
          :disabled="nameInvalid"
          :loading="loading"
          :full-width="true"
          @click="() => continueHandle(false)"
        />
      </div>
    </div>
  </cvt-dialog>
</template>

<script>
import * as _ from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { START_OVER_KEY } from "../../../lib/helper-classes"
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import * as mutations from '../../../store/modules/mutations'

export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mode: 'LIGHT',
      prependChevron: false,
      preference: "",
      siteName: "",
      loading: false,
      invalidFieldMsg: ""
    }
  },
  computed: {
    ...mapGetters({
      site: getters.SITE_GET_DATA,
      step: getters.STEPS_CURRENT_STEP,
      fontStyles: getters.THEME_FONT_STYLES,
      brandLogo: getters.THEME_BRAND_LOGO,
      preferences:getters.GET_PREFERENCES
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      AltPopupManager: ({ globalTheme }) =>
        globalTheme.AltPopupManager !== undefined
          ? globalTheme.AltPopupManager
          : false,
      brandPreferenceEnabled: ({ globalTheme }) => Boolean(globalTheme.brandPreferenceEnabled),
    }),
    nameInvalid() {
      return this.isSiteNameValid()
    },
    titleStyle() {
      return { 'font-family': this.FontFamily.DEFAULT }
    },
    titleMessage() {
      return  this.$t(this.canEditTemplateName ? 'setup.message.title' : 'setup.preference.title' )
    },
    titleDescription() {
      return  this.$t(this.canEditTemplateName ? 'setup.message.description' : 'setup.preference.description' )
    },
    canEditTemplateName() {
      return this.preferences.length || !this.brandPreferenceEnabled
    },
    canUseBrandPreference() {
      return this.preferences.length && this.brandPreferenceEnabled
    }
  },
  async created() {
    this.siteName = this.site.name

    if(this.brandPreferenceEnabled) {
    this.fetchPreferences()
    }
  },
  methods: {
    ...mapActions({
      updateSite: actions.SITE_UPDATE,
      fetchPreferences: actions.FETCH_PREFERENCES,
      updatePreference: actions.UPDATE_PREFERENCE,
    }),
    ...mapMutations({
      blockNextStep: mutations.STEPS_BLOCK,
      showSettings: mutations.SHOW_SITE_SETTINGS,
    }),
    ...mapActions('revisions', {
      revisionSaveSnapshot: 'saveSnapshot'
    }),
    continueHandle(displaySettings) {
      let siteNameIsValid = !this.nameInvalid
      if(siteNameIsValid){
        this.loading = true

        this.updateSite({
            ...this.site,
            name: this.siteName,
        })
        .then(()=> {
          this.loading = false

          this.revisionSaveSnapshot({
            key: 'manually.saved',
            description: START_OVER_KEY
          })

          this.$emit('continue')

          if(this.brandPreferenceEnabled && (!this.AltPopupManager && this.preference)) {
            this.updateThePreference()
          }

          if(displaySettings){
            this.showSettings()
          }
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
      }
    },
    choosePreference (value) {
      this.preference = value
    },
    updateThePreference() {
      this.updatePreference({name: this.preference, siteId:this.site.id})
    },
    createNewPreference () {
      this.continueHandle(true)
    },
    onChangeSiteName ({ target }) {
      this.siteName = target.value
    },
    isSiteNameValid(){
      if(!this.siteName){
        this.invalidFieldMsg = "element.property.form.field.required_message"
        return true
      }
      else if(this.siteName.length < 3){
        this.invalidFieldMsg = "element.property.form.field.required_3_min_char"
        return true
      }
      return false
    }
  },
}
</script>

<style lang="scss">
.step-one-setup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
}

.step-one-setup__input {
  width: 500px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.setup-title {
  font-weight: bold;
}

.modal__body {
  background-color: #fff;
}
</style>
