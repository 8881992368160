import * as actions from '../actions'
import * as getters from '../getters'
import * as mutations from '../mutations'
import { Organization } from '../../services/api'

const organizationAPI = new Organization()

export default {
  state: {
    config: {},
  },
  getters: {
    [getters.USER_CONFIG]({ config }) {
      return config
    },
  },
  mutations: {
    [mutations.SET_CONFIG](state, config) {
      state.config = config
    },
  },
  actions: {
    async [actions.FETCH_CONFIG]({ commit }) {
      return await organizationAPI
        .fetchConfig()
        .then((res) => commit(mutations.SET_CONFIG, res.data))
    },
    async [actions.UPSERT_CONFIG]({ dispatch }, payload) {
      return await organizationAPI.upsertFeatureConfig(payload).then(() => {
        dispatch(actions.FETCH_CONFIG)
      })
    },
  },
}
