export class MixPanel {
  constructor() {
    if (this.instance) return this

    // mixpanel.init('fb5e569fe1290d306bb4090655c9777b')
    // this.instance = mixpanel
  }

  async trackEvent(event, category, metadata = {}) {
    // await this.instance.track(
    //   event,
    //   Object.assign({ category: category }, metadata),
    // )
  }

  async initUser(userId) {
    // await this.instance.identify(userId)
  }

  async setPeopleOnce(user) {
    // await this.instance.people.set_once({
    //   $email: user.email,
    //   USER_ID: user.userId,
    //   PROJECT_ID: user.projectID,
    //   ORG_ID: user.orgId,
    //   $firstname: user.firstName,
    //   $lastname: user.lastName,
    // })
  }
}
