<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="crm-tags">
    <div v-if="loading" class="placeholder-container">
      <cvt-content-placeholder class="placeholder" />
    </div>
    <cvt-select
      v-else
      ref="tagger"
      :value="localTags"
      value-key="id"
      mode="tags"
      :multiple="true"
      :remote-method="search"
      :label="placeholder"
      @visible-change="search('')"
      @input="(tags) => updateLocalTags(tags)"
    >
      <option
        v-for="tag in tagsList"
        :key="tag.id"
        :label="fmtTagName(tag.name, tag.id)"
        :value="tag.id"
      >
        <span style="float: left" v-html="fmtTagName(tag.name, tag.id)"></span>
      </option>
    </cvt-select>

    <!-- <button class="btn btn-sm btn-success mt-2 text-capitalize btn-block" @click.stop="addTag">Create Tag</button> -->
    <div class="button-margin">
      <cvt-button
        shape="rounded"
        color="success"
        :mode="mode"
        text="Create Tag"
        :full-width="true"
        @click.stop="openCustomTagDialog"
      />
    </div>

    <cvt-dialog
      ref="create-tag-dialog"
      :show="showDialog"
      size="sm"
      :height-auto="true"
      @close="showDialog = false"
    >
      <template #title>
        <h5>Create Tag</h5>
      </template>

      <cvt-input
        type="text"
        :value="customTagName"
        label="Enter a new tag name"
        @input="(e) => updateCustomTagName(e)"
      />

      <template #modalFooter>
        <cvt-button color="light" text="Cancel" @click="showDialog = false" />
        <cvt-button text="Create" @click="addTag" />
      </template>
    </cvt-dialog>
  </div>
</template>

<script>
  import * as _ from 'lodash'
import moment from 'moment'
import { V1CRM } from '../../../store/services/api'

  const crm = new V1CRM()

  export default {
    props: {
      tags: {
        type: Array,
        required: true
      },
      placeholder: {
        type: String,
        default: "Choose tags for this form"
      }
    },
    data () {
      return {
        remoteTags: [],
        mode: 'LIGHT',
        customTagName: '',
        showDialog: false,
        loading: false,
        previousArray: []
      }
    },
    computed: {
      localTags: {
        get () {
          return  this.tags.reduce((prev, currentTag) => {
            if(currentTag.name !== null && currentTag.name !== undefined) {
              prev.push(currentTag.id);
            }
            return prev
          }, [])
          // return this.previousArray
        },
        set (tags) {
          
          // When a user types in input field and that string is not found in suggestions
          // It sends input-event instead of array of tags, so below Array-check is to avoid it  
          if(Array.isArray(tags))
          {
            this.$emit('update-tags', this.remoteTags.filter(
              (remoteTag) => { 
                return tags.indexOf(remoteTag.id) > -1;
              }
            ));
          }
          this.$refs.tagger.query = ''
          return tags
        }
      },
      tagsList () {
        return [
          ...this.remoteTags.filter(t => _.find(this.localTags, t.id)),
          ...this.remoteTags.filter(t => !_.find(this.localTags, {id: t.id}))
        ] || []
      }
    },
    watch: {
      tags: {
        handler (newVal, oldVal) {
          if (newVal) {
            this.loading = true
            this.$nextTick(() => {
              // force localTag to re-render
              this.loading = false
            })
          }
        },
      },
    },
    created () {

      this.loading = true

      crm.getTags().then(({data}) => {
        
        this.remoteTags = data

        // Remove all those tags which does not have name attribute 
        if(this.remoteTags?.length > 0) {
          this.remoteTags = this.remoteTags.filter(tag => {
            return tag.name !== null && tag.name !== undefined
          });
        }

        this.loading = false
      })
    },
    mounted () {
      document.body.appendChild(this.$refs['create-tag-dialog'].$el)
    },
    beforeDestroy () {
      document.body.removeChild(this.$refs['create-tag-dialog'].$el)
    },
    methods: {
      search: _.throttle(function (query) {
        crm.getTags(query).then(({data}) => {
          this.remoteTags = data
        })
      }, 1000),
      fmtTagName (name) {
        name = name?.toLowerCase().trim()
        if (name?.length >= 50) {
          return `${name?.slice(0, 50)}...`
        }
        if(name) {
          return `${name}`
        }
      },
      updateLocalTags (tags) {
        this.localTags = tags
      },
      updateCustomTagName ({target}) {
        this.customTagName = target.value
      },
      openCustomTagDialog () {
        this.customTagName = ''
        this.showDialog = true
      },
      addTag () {
        crm.createTag({
          name: this.customTagName,
          description: `Created from landing page builder (${moment().format('MM-DD-YYYY')})`
        }).then(({data}) => {
          this.remoteTags.push(data)
          this.$emit('update-tags', [
            data,
            ...this.tags
          ])
        }).then(() => {
          this.$message({
            type: 'success',
            message: 'New tag created'
          })
          this.showDialog = false
        }).catch((err) => {
          this.showDialog = false
          console.error(err)
          // this.$message({
          //   type: 'info',
          //   message: 'Input canceled'
          // });
        })
      }
    }
  }
</script>

<style lang="scss">
  .crm-tags {
    .el-select {
      width: 100%;
    }
  }

  .button-margin{
    margin-top: 10px;
  }

  .placeholder-container {
    position: relative;
    height: 48px;
  }

  .placeholder {
    height: 48px;
    width: 100%;
    left: 0;
    border-radius: 8px;
  }
</style>
