<template>
  <section class="card-body">
    <slot></slot>
  </section>
</template>

<script>
  export default {
    name: 'CvtCardBody',
  }
</script>

<style scoped>
.card-body {
  border-top: 1px solid #ddd;
  width: 100%;
  height: 100%;
  padding: 16px;
  margin: 0;
  display: flex;
  background-color: white;
}
</style>
