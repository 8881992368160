export default {
    name: 'EnableDemoTemplate',
    props: {
      demoTemplateEnabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        mode: 'LIGHT',
      }
    },
    methods: {
        demoTemplateChange (status) {
        if(status === 'Active') {
          this.$emit('switchToggle', { value: true })
        } 
        else if(status === 'Inactive'){
          this.$emit('switchToggle', { value: false })
        }
      }
    }
  }