<template>
  <div class="galley__item" @mouseenter="showOverlay" @mouseleave="hideOverlay">
    <div v-show="overlayVisible" class="galley__item__overlay">
      <div class="galley__item__overlay__content d-flex flex-column justify-content-center align-items-center">
        <a v-show="isAdmin" href="" class="btn btn-danger mb-1" @click.prevent.stop="remove"><i class="cvt-icon-trash"></i> (Admin Only)</a>
        <div class="add-block-btn" @click.prevent.stop="use">
          <i class="cvt-add"></i>
        </div>
        <p class="block-title" v-text="block.name"></p>
      </div>
    </div>
    <img class="img-fluid" :src="block.thumbnail" alt="">
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as getters from '../../../store/modules/getters'

export default {
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      overlayVisible: false
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN
    })
  },
  methods: {
    showOverlay () {
      this.overlayVisible = true
    },
    hideOverlay () {
      this.overlayVisible = false
    },
    remove () {
      this.$emit('remove', this.block)
    },
    use () {
      this.$emit('selected', this.block)
    }
  }
}
</script>

<style lang="scss">
  // @import "@/scss/utils";
</style>
