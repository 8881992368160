import Vue from '../../../lib/utils/vue'
import { id } from '../../../lib/utils/common'
import { styled } from '@egoist/vue-emotion'
import { CvtIcon } from '../icon/icon'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import { VueEmotion } from '@egoist/vue-emotion'
import Util from './util'

Vue.use(VueEmotion)

let Spacing, FontFamily, Border
let textFontWeight,
  textLineHeight,
  backgroundColor,
  textFontSize,
  color,
  borderColor
let collapseItemHeaderColor,
  collapseItemContentColor,
  collapseItemHeaderBoxShadow,
  collapseItemTitleFontWeight

const getCollapseItem = () => {
  return styled('div')`
    width:100%;
  `
}
const getCollapseItemHeader = () => {
  return styled('div')`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: ${FontFamily.DEFAULT};
    cursor: pointer;
    outline: none;
    &.is-active {
      ${(props) => {
        if (props.specialStyle)
          return `background-color: ${collapseItemHeaderColor};box-shadow: ${collapseItemHeaderBoxShadow};`
        switch (props.themeing) {
          case 'light':
            return backgroundColor({ themeing: 'dark' }, 200)
          case '':
            return ''
          default:
            return backgroundColor(props, 600)
        }
      }}
    }
    &:hover {
      ${(props) => {
        switch (props.themeing) {
          case 'light':
            return backgroundColor({ themeing: 'dark' }, 200)
          case '':
            return ''
          default:
            return backgroundColor(props, 600)
        }
      }}
    }
    ${(props) => {
      let padding = `padding: ${Spacing.SPACING_16}px;`
      let fontSize = textFontSize('lg')
      let fontWeight = `font-weight:${collapseItemTitleFontWeight} !important;`
      let textColor = Util.textHeaderColor(props)
      let bgColor = props.specialStyle
        ? `background-color: ${collapseItemHeaderColor};`
        : props.themeing == ''
        ? 'background-color: transparent;'
        : backgroundColor(props, 500)

      let lineHeight = textLineHeight('lg')
      return padding + fontSize + fontWeight + lineHeight + bgColor + textColor
    }}

    ${props => props.specialFontFamily && `span {
      font-family: ${props.specialFontFamily} !important;
    }`}
  `
}
const getCollapseItemWrap = () => {
  return styled('div')`
    &.smooth-enter-active,
    &.smooth-leave-active {
      transition: height 0.5s;
      overflow: hidden;
    }
    &.smooth-enter,
    &.smooth-leave-to {
      height: 0;
    }
  `
}
const getCollapseItemContent = () => {
  return styled('div')`
    transition: 150ms ease-out;
    border: ${Border.SIZE_1}px solid;

    ${(props) => {
      let padding = `padding: ${Spacing.SPACING_16}px;`
      let fontSize = textFontSize('lg')
      let textColor = Util.textContentColor(props)
      let lineHeight = textLineHeight('lg')
      let bgColor = props.specialStyle
        ? `background-color: ${collapseItemContentColor};`
        : props.themeing == 'dark'
        ? backgroundColor(props, 800)
        : 'background-color: transparent;'
      let contentBorderColor =
        props.themeing == ''
          ? 'border-color: transparent;'
          : borderColor(props, 500)
      return (
        padding +
        fontSize +
        lineHeight +
        textColor +
        contentBorderColor +
        bgColor
      )
    }}
  `
}
const getCollapseItemIcon = () => {
  return styled(CvtIcon)`
    margin-right: auto;
    margin-left: ${Spacing.SPACING_8}px;
  `
}
const getCollapseItemArrow = () => {
  return styled(CvtIcon)`
    ${(props) => (props.prependChevron ? `width: 20px;` : ``)}
  `
}
export default {
  name: 'CvtCollapseItem',
  data() {
    return {
      id: id(),
    }
  },
  inject: ['collapse'],
  props: {
    title: {
      type: String,
      default: 'Collapse',
    },
    name: {
      type: [String, Number],
    },
    icon: {
      type: String,
      default: '',
    },
    initialActive: {
      type: Boolean,
      default: false,
    },
    specialStyle: {
      type: Boolean,
      default: false,
    },
    specialFontFamily: {
      type: String,
      default: '',
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      color: getters.GLOBAL_STYLE_COLOR,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Border: ({ globalTheme }) => globalTheme.Border,
      iconConfig: ({ iconConfig }) => iconConfig,
      collapseItemHeaderColor: ({ globalTheme }) =>
        globalTheme.collapseItemHeaderColor !== undefined
          ? globalTheme.collapseItemHeaderColor
          : ``,
      collapseItemContentColor: ({ globalTheme }) =>
        globalTheme.collapseItemContentColor !== undefined
          ? globalTheme.collapseItemContentColor
          : ``,
      collapseItemHeaderBoxShadow: ({ globalTheme }) =>
        globalTheme.collapseItemHeaderBoxShadow !== undefined
          ? globalTheme.collapseItemHeaderBoxShadow
          : ``,
      collapseItemTitleFontWeight: ({ globalTheme }) =>
        globalTheme.collapseItem &&
        globalTheme.collapseItem.title &&
        globalTheme.collapseItem.title.fontWeight?globalTheme.collapseItem.title.fontWeight:'regular',
    }),
    isActive() {
      return this.collapse.activeItems.indexOf(this.name) > -1
    },
    theme() {
      return this.collapse.$props.theme
    },
    prependChevron() {
      return this.collapse.$props.prependChevron
    },
  },
  mounted() {
    if (this.initialActive) this.handleHeaderClick()
  },
  methods: {
    handleHeaderClick() {
      this.dispatch()
    },
    handleEnterClick() {
      this.dispatch()
    },
    dispatch() {
      const parent = this.$parent || this.$root
      parent.$emit.apply(parent, ['item-click', this])
    },
    beforeEnter: function (el) {
      el.style.height = '0'
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    afterEnter: function (el) {
      el.style.height = 'auto'
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leave: function (el) {
      /*
       * Add Height update operation to an event loop, so beforeLeave and leave does not conflict with each other.
       * Just to note that this will not delay the animation.
       * The animation will work as expected.
       */
      setTimeout(() => {
        el.style.height = '0'
      }, 0)
    },
  },
  created() {
    Spacing = this.Spacing
    FontFamily = this.FontFamily
    Border = this.Border

    textFontWeight = this.textFontWeight
    textLineHeight = this.textLineHeight
    borderColor = this.borderColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    color = this.color

    collapseItemContentColor = this.collapseItemContentColor
    collapseItemHeaderColor = this.collapseItemHeaderColor
    collapseItemHeaderBoxShadow = this.collapseItemHeaderBoxShadow
    collapseItemTitleFontWeight = this.collapseItemTitleFontWeight
  },
  render: function (h) {
    Util.self = this
    const CollapseItem = getCollapseItem()
    const CollapseItemHeader = getCollapseItemHeader()
    const CollapseItemWrap = getCollapseItemWrap()
    const CollapseItemContent = getCollapseItemContent()
    const CollapseItemIcon = getCollapseItemIcon()
    const CollapseItemArrow = getCollapseItemArrow()
    const collapseIcon =
      this.iconConfig?.propertiesSidebar?.collapse || 'chevron-down'
    const expandIcon =
      this.iconConfig?.propertiesSidebar?.expand || 'chevron-right'

    return (
      <CollapseItem
        class={{ 'is-active': this.isActive, 'cvt-collapse-item': true }}
      >
        <div
          role="tab"
          aria-expanded={this.isActive}
          class={`cvt-collapse-tab-${this.id}`}
        >
          <CollapseItemHeader
            onClick={() => this.handleHeaderClick()}
            role="button"
            id={`cvt-collapse-head-${this.id}`}
            onKeyup={(e) => this.handleEnterClick(e)}
            class={{ 'is-active': this.isActive }}
            themeing={this.theme}
            specialStyle={this.specialStyle}
            specialFontFamily={this.specialFontFamily}
          >
            {this.prependChevron && (
              <CollapseItemArrow
                prependChevron={this.prependChevron}
                icon={this.isActive ? collapseIcon : expandIcon}
              ></CollapseItemArrow>
            )}
            <span>{this.title}</span>
            <CollapseItemIcon icon={this.icon}></CollapseItemIcon>
            {!this.prependChevron && (
              <CollapseItemArrow
                prependChevron={this.prependChevron}
                icon={this.isActive ? collapseIcon : expandIcon}
              ></CollapseItemArrow>
            )}
          </CollapseItemHeader>
        </div>

        <transition
          name="smooth"
          onBeforeEnter={(el) => this.beforeEnter(el)}
          onEnter={(el) => this.enter(el)}
          onAfterEnter={(el) => this.afterEnter(el)}
          onBeforeLeave={(el) => this.beforeLeave(el)}
          onLeave={(el) => this.leave(el)}
        >
          {this.isActive && (
            <CollapseItemWrap id={`cvt-collapse-content-${this.id}`}>
              <CollapseItemContent
                specialStyle={this.specialStyle}
                themeing={this.theme}
              >
                {this.$slots.default}
              </CollapseItemContent>
            </CollapseItemWrap>
          )}
        </transition>
      </CollapseItem>
    )
  },
}
