import { VNode, VNodeData } from "@/js/lib/helper-classes";

const defaultEmbeddedScript = {
  id: 'default',
  name: 'default',
  embed: ''
}


export class CEmbeddedForm extends VNode {
  tag = 'c-embedded-form'
  data = new VNodeData({
    props: {
      embeddedScript: {...defaultEmbeddedScript},
      className: "cvt-embedded-form"
    },
  })
  children = []
}