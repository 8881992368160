import * as getters from "./getters";
import * as mutations from "./mutations";

export default {
    state: {
        show: false,
    },
    getters: {
        [getters.START_OVER_DIALOG_SHOW]({ show }) {
            return show
        },
    },
    mutations: {
        [mutations.START_OVER_SHOW_DIALOG_SET](state, value) {
            state.show = value
        },
    },
    actions: {
    },
}