
<div>
  <div class="d-flex mb-5">
    <cvt-input
      class="marketplace-branding-search flex-grow-1"
      type="search"
      :mode="mode"
      :value="searchTerm"
      :no-label="true"
      suffix-icon="search"
      :show-input-border="false"
      :fetch-suggestions="fetchSuggestions"
      @input="({ target: { value } }) => {
        searchTerm = value
      }"
      @select="onSelect"
    ></cvt-input>

    <div
      class="d-flex justify-content-end flex-grow-0"
      style="flex-basis: 25%"
    >
      <cvt-button
        class="rounded-lg"
        color="dark"
        :text="$t('marketplace.branding.list.create.new.branding')"
        @click="onCreateNewBranding"
      />
    </div>
  </div>

  <div v-if="searchSelected" class="mb-5">
    <div class="mb-3 font-weight-bold" style="font-size: 14px; font-family: 'Nunito';font-style: italic;">{{ $t('marketplace.branding.list.search.result') }}</div>
    <div
      class="d-flex w-100 px-4 py-3 align-items-center justify-content-between brand-block"
    >
      <div class="brand-block-name">{{ selectedSearchItem.brandingName }}</div>
      <div class="d-flex">
        <cvt-button
          class="rounded-lg my-2"
          style="color: #494c53; border: 1px solid #494c53"
          color="light"
          icon="cvt-icon-pencil"
          :prepend="true"
          :outlined="true"
          :text="'Edit'"
          @click="() => editBranding(selectedSearchItem)"
        />

        <cvt-button
          class="rounded-lg ml-3 my-2"
          style="color: #494c53; border: 1px solid #494c53"
          color="light"
          icon="trash"
          :prepend="true"
          :outlined="true"
          :text="'Delete'"
          @click="() => toggleDeleteDialog(selectedSearchItem)"
        />
      </div>
    </div>
  </div>


  <div v-if="searchSelected" class="mb-2 font-weight-bold" style="font-size: 14px; font-family: 'Nunito';font-style: italic;"> 
    {{ $t('marketplace.branding.list.other.branding.themes') }}
  </div>
  <div
    v-for="brand in brandingData"
    :key="brand.idx"
    class="d-flex w-100 px-4 py-3 align-items-center justify-content-between brand-block"
  >
    <div class="brand-block-name">{{ brand.brandingName }}</div>
    <div class="d-flex">
      <cvt-button
        class="rounded-lg my-2"
        style="color: #494c53; border: 1px solid #494c53"
        color="light"
        icon="cvt-icon-pencil"
        :prepend="true"
        :outlined="true"
        :text="'Edit'"
        @click="() => editBranding(brand)"
      />

      <cvt-button
        class="rounded-lg ml-3 my-2"
        style="color: #494c53; border: 1px solid #494c53"
        color="light"
        icon="trash"
        :prepend="true"
        :outlined="true"
        :text="'Delete'"
        @click="() => toggleDeleteDialog(brand)"
      />
    </div>
  </div>
  <div
    v-if="!brandingData.length"
    class="d-flex justify-content-center"
  >
    <p style="font-size: 13px; opacity: 70%">
      {{ $t('marketplace.branding.list.no.data') }}
      <span
        style="text-decoration: underline; cursor: pointer"
        @click="onCreateNewBranding"
      >
        {{ $t('marketplace.branding.list.create.new.branding') }}
      </span>
    </p>
  </div>

  <cvt-dialog
    :show="deleteDialogVisible"
    :height-auto="true"
    :border-radius="'8px'"
    size="sm"
    bg-color="#fff"
    :show-close="false"
  >
    <template #title>
      <h5 class="del-color-warn-title" style="font-family: 'Nunito'; font-weight: 700;">{{ $t('marketplace.branding.list.delete.title') }}</h5>
    </template>

    <template #default>
      <h5 class="del-color-warn-subtitle" style="font-family: 'Nunito';font-size: 14px;">
        {{ $t('marketplace.branding.list.delete.subtitle') }}
      </h5>
      <span style="font-family: 'Nunito'">{{ selectedBrandForDeletion.brandingName }}</span>
    </template>

    <template #modalFooter>
      <div class="d-flex">
        <cvt-button
          class="mr-2"
          color="light"
          :text="$t('marketplace.dialog.archive.cta.cancel')"
          @click.stop="toggleDeleteDialog()"
        />
        <cvt-button
          color="dark"
          :text="$t('marketplace.dialog.archive.cta.submit')"
          class="mr-2"
          @click.stop="deleteBranding"
        />
      </div>
    </template>
  </cvt-dialog>
</div>
