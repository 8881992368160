<template>
  <div
    :id="vnodeId"
    :data-vnode-id="vnodeId"
    class="cvt-editor-video w-100"
    :class="classes"
    :style="styles"
    draggable="true"
    @click="select"
    @mouseover="target"
    @contextmenu.prevent.stop="showContextMenu"
    @dblclick.stop.prevent
    @dragstart="dragstart"
    @dragend="dragend"
  >
    <drag-and-drop-zone
      :z-index="dropzoneZIndex"
      @dropped="addElementToSide"
    />

    <div
      class="embed-responsive embed-responsive-16by9"
      :class="videoWrapperClasses"
    >
      <div class="cvt-editor-video__overlay" />
      <iframe
        class="embed-responsive-item"
        :src="src"
        allowfullscreen
      />
    </div>
    <template v-if="showActionButtonsOnClick && !isGridBuilder">
      <!--THis is only here because of freshwork in v1 gridbuilder,
      once they migrate to v2 or v3, it will become irrelevant-->
      <action-buttons
          v-if="selected"
          @duplicate="duplicateAndValidate"
          @remove="removeAndValidate"
      />
    </template>
  </div>
</template>

<script>
import FbVNode from '../../base/FbVNode.vue'
import { mapGetters } from 'vuex'
import * as getters from '@/js/store/modules/getters'

export default FbVNode.extend({
  props: {
    src: String,
    crmRemoteVideoUrl: {
      type: String,
      required: false
    },
  },
  data () {
    return {
      name: 'CVideo'
    }
  },
  mounted () {
    if (!this.src) {
      this.updateProp('src', this.config.BUILDER_DEFAULT_VIDEO || '')
    }
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG
    }),
    videoWrapperClasses () {
      return {
        [this.stylesheet.classes.spacing]: true
      }
    },
    styles () {
      return {
        spacing: {
          ...this.spacingStyle
        }
      }
    }
  }
})
</script>

<style lang="scss">
  .cvt-editor-video {
    display: block;
    position: relative;

    // if div#page-engine has grid-builder class only then apply this drag-and-drop-handle style
    .grid-builder & {
      cursor: grab;
  
      &:active {
        cursor: grabbing;
      }
    }
  }

  .cvt-editor-video__overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
</style>
