/**
 * Google Font Selection
 * =====================
 */

const fontSizes = [
  '100', '200', '300', '400','500','600', '700','800', '900'
]

export default [
  {
    familyName: "Abril Fatface",
    genericFamily: "cursive",
    source: "google",
    tags: ["400"],
  },
  {
    familyName: "Advent Pro",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["100", "200", "300", "400", "500", "600", "700"],
  },
  {
    familyName: "Amiri",
    genericFamily: "serif",
    source: "google",
    tags: ["400", "700"],
  },
  {
    familyName: "Arimo",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["400", "500", "600", "700"],
  },
  {
    familyName: "Source Sans 3",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["200", "300", "400", "500", "600", "700", "800", "900"],
  },
  {
    familyName: "Vast Shadow",
    genericFamily: "serif",
    source: "google",
    tags: ["400"],
  },
  {
    familyName: "DM Sans",
    genericFamily: "san-serif",
    source: "google",
    tags: [...fontSizes, "1000"],
  },
  {
    familyName: "Bitter",
    genericFamily: "serif",
    source: "google",
    tags: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  },
  {
    familyName: "Cardo",
    genericFamily: "serif",
    source: "google",
    tags: ["400", "700"],
  },
  {
    familyName: "Catamaran",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  },
  {
    familyName: "Dosis",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["200", "300", "400", "500", "600", "700", "800"],
  },
  {
    familyName: "Ek Mukta",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["200", "300", "400", "500", "600", "700", "800"],
  },
  {
    familyName: "Fira Sans",
    genericFamily: "sans-serif",
    tags: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
    source: "google",
  },
  {
    familyName: "Fredoka One",
    genericFamily: "cursive",
    source: "google",
    tags: ["400"],
  },
  {
    familyName: "Inknut Antiqua",
    genericFamily: "serif",
    source: "google",
    tags: ["300", "400", "500", "600", "700", "800", "900"],
  },
  {
    familyName: "Lato",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["100", "300", "400", "700", "900"],
  },
  {
    familyName: "Libre Baskerville",
    genericFamily: "serif",
    source: "google",
    tags: ["400", "700"],
  },
  {
    familyName: "Lora",
    genericFamily: "serif",
    source: "google",
    tags: ["400", "500", "600", "700"],
  },
  {
    familyName: "Martel",
    genericFamily: "serif",
    source: "google",
    tags: ["200", "300", "400", "600", "700", "800", "900"],
  },
  {
    familyName: "Merriweather",
    genericFamily: "serif",
    source: "google",
    tags: ["300", "400", "700", "900"],
  },
  {
    familyName: "Montserrat",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  },
  {
    familyName: "Nunito",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["200", "300", "400", "500", "600", "700", "800", "900", "1000"],
  },
  {
    familyName: "Open Sans",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["300", "400", "500", "600", "700", "800"],
  },
  {
    familyName: "Oswald",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["200", "300", "400", "500", "600", "700"],
  },
  {
    familyName: "Palanquin Dark",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["100", "200", "300", "400", "500", "600", "700"],
  },
  {
    familyName: "Patua One",
    genericFamily: "cursive",
    source: "google",
    tags: ["400"],
  },
  {
    familyName: "Playfair Display",
    genericFamily: "serif",
    source: "google",
    tags: ["400", "500", "600", "700", "800", "900"],
  },
  {
    familyName: "Poppins",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  },
  {
    familyName: "PT Serif",
    genericFamily: "serif",
    source: "google",
    tags: ["400", "700"],
  },
  {
    familyName: "Questrial",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["400"],
  },
  {
    familyName: "Raleway",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  },
  {
    familyName: "Roboto",
    genericFamily: "sans-serif",
    tags: ["100", "300", "400", "500", "700", "900"],
    source: "google",
  },
  {
    familyName: "Roboto Condensed",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["300", "400", "700"],
  },
  {
    familyName: "Rubik",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["300", "400", "500", "600", "700", "800", "900"],
  },
  {
    familyName: "Sarpanch",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["400", "500", "600", "700", "800", "900"],
  },
  {
    familyName: "Titillium Web",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["200", "300", "400", "600", "700", "900"],
  },
  {
    familyName: "Varela Round",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["400"],
  },
  {
    familyName: "Work Sans",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  },
  {
    familyName: "Cantarell",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["400", "700"],
  },
  {
    familyName: "Crimson Text",
    genericFamily: "serif",
    source: "google",
    tags: ["400", "600", "700"],
  },
  {
    familyName: "Domine",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["400", "500", "600", "700"],
  },
  {
    familyName: "Muli",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  },
  {
    familyName: "Neuton",
    genericFamily: "serif",
    source: "google",
    tags: ["200", "300", "400", "700", "800"],
  },
  {
    familyName: "Fanwood Text",
    genericFamily: "serif",
    source: "google",
    tags: ["400"],
  },
  {
    familyName: "Archivo Narrow",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["400", "500", "600", "700"],
  },
  {
    familyName: "Enriqueta",
    genericFamily: "serif",
    source: "google",
    tags: ["400", "500", "600", "700"],
  },
  {
    familyName: "Helvetica",
    genericFamily: "sans-serif",
    source: "default",
    tags: fontSizes,
  },
  {
    familyName: "Times New Roman",
    genericFamily: "serif",
    source: "default",
    tags: fontSizes,
  },
  {
    familyName: "Calibri",
    genericFamily: "sans-serif",
    source: "default",
    tags: fontSizes,
  },
  {
    familyName: "Georgia",
    genericFamily: "serif",
    source: "default",
    tags: fontSizes,
  },
  {
    familyName: "Impact",
    genericFamily: "sans-serif",
    source: "default",
    tags: fontSizes,
  },
  {
    familyName: "Verdana",
    genericFamily: "sans-serif",
    source: "default",
    tags: fontSizes,
  },
  {
    familyName: "Inter",
    genericFamily: "sans-serif",
    source: "default",
    tags: fontSizes,
  },
  {
    familyName: "League Spartan",
    genericFamily: "sans-serif",
    source: "google",
    tags: fontSizes,
  },
  {
    familyName: "Manrope",
    genericFamily: "sans-serif",
    source: "google",
    tags: fontSizes.filter(x => !['100', '900'].includes(x)),
  },
  {
    familyName: "Sen",
    genericFamily: "san-serif",
    source: "google",
    tags: ["400", "500", "600", "700", "800"],
  },
  {
    familyName: "Plus Jakarta Sans",
    genericFamily: "sans-serif",
    source: "google",
    tags: fontSizes.filter(x => !['100', '900'].includes(x)),
  },
  {
    familyName: "Fjalla One",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["400"],
  },
  {
    familyName: "Roboto Slab",
    genericFamily: "sans-serif",
    source: "google",
    tags: fontSizes,
  },
  {
    familyName: "Oxygen",
    genericFamily: "sans-serif",
    source: "google",
    tags: ["300", "400", "700"],
  }
];

