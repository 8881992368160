<template>
  <div class="ace__container">
    <div class="editor"></div>
  </div>
</template>

<script>
  import ace from 'ace-builds'
  import 'ace-builds/src-noconflict/mode-html'
  import 'ace-builds/src-noconflict/theme-monokai'

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Ace',
    props: {
      theme: {
        type: String,
        default: 'monokai',
      },
      lang: {
        type: String,
        required: true
      },
      containerClass: {
        type: String,
        default: ''
      },
      codeSnippetHtml: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        editor: null
      }
    },
    watch: {
      codeSnippetHtml(newValue){
        // it will update the data in firebase, but it will only update the code-snippet
        // when we move our focus out from this element. 
        // If we dont implement it the logic this way, then, when a user types in 
        // it will save the data in firebase and upon saving, the component gets new props
        // and upon receiving new pros, the element sets the cursor position at end or at start
        // which kind of gives the user a bad experience in editing the code-snippet element
        if(!this.editor.isFocused()) {

          this.$nextTick(() => {
            this.editor.setValue(newValue, 1)
          })
        }
      }
    },
    mounted () {
      this.editor = ace.edit(this.$el.querySelector('.editor'))
      this.$emit('init', this.editor)

      this.editor.$blockScrolling = Infinity
      this.editor.getSession().setMode('ace/mode/'+this.lang)
      this.editor.setTheme('ace/theme/'+this.theme)
      // this.editor.resize()

      this.editor.on('change', () => {
        this.$emit('change', this.editor.getValue())
      })

      this.$nextTick(() => {
        this.editor.setValue(this.codeSnippetHtml, 1)
      })
    },
    methods: {
      // this method being is used from outside of this component
      // with $refs we use this setValue method to change the content
      setValue (value = '') {
        return this.editor.setValue(value, 1)
      }
    }
  }
</script>

<style lang="scss">
  .ace__container {
    width: 100%;
    height: 300px;
    position: relative;

    .editor {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 300px;
    }
  }
</style>
