
<div class="country-subdivision-selector">
  <div class="form-group">
    <label>Default Country:</label><br>
    <el-select
      v-model="country"
      placeholder="Choose default country"
      @change="onCountryChange">

      <el-option
        v-for="c in countries"
        :key="c.alpha3"
        :label="c.name"
        :value="c.alpha3">
      </el-option>
    </el-select>
  </div>
  <div class="form-group">
    <label>Default State / Province:</label><br>
    <el-select
      v-model="subdivision"
      placeholder="Choose default state / province"
      @change="onSubdivisionChange">

      <el-option
        v-for="sub in subdivisions"
        :key="sub.alpha3"
        :label="sub.name"
        :value="sub.alpha3">
      </el-option>
    </el-select>
  </div>
</div>
