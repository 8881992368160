
<div>
  <h1>Nav</h1>
  <div class="mt-3 mb-3">
    <h4>Basic</h4>
    <cvt-nav>
      <cvt-nav-item active>Active</cvt-nav-item>
      <cvt-nav-item>Link</cvt-nav-item>
      <cvt-nav-item>Another Link</cvt-nav-item>
      <cvt-nav-item disabled>Disabled</cvt-nav-item>
    </cvt-nav>
  </div>
  <div class="mt-3 mb-3">
    <h4>Pills</h4>
    <cvt-nav pills>
      <cvt-nav-item active>Active</cvt-nav-item>
      <cvt-nav-item>Link</cvt-nav-item>
      <cvt-nav-item>Another Link</cvt-nav-item>
      <cvt-nav-item disabled>Disabled</cvt-nav-item>
    </cvt-nav>
  </div>
  <div class="mt-3 mb-3">
    <h4>Tabs</h4>
    <cvt-card title="Card Title">
      <cvt-card-header header-tag="nav">
        <cvt-nav card-header tabs>
          <cvt-nav-item>Active</cvt-nav-item>
          <cvt-nav-item active >Second</cvt-nav-item>
          <cvt-nav-item>Third</cvt-nav-item>
          <cvt-nav-item>Inactive</cvt-nav-item>
          <cvt-nav-item disabled>Disabled</cvt-nav-item>
        </cvt-nav>
      </cvt-card-header>
      <cvt-card-body class="text-center">
        Sample text body
      </cvt-card-body>
    </cvt-card>
  </div>
  <div class="mt-3 mb-3">
    <h4>Using Vue Router</h4>
    <cvt-card title="Card Title" no-body>
      <cvt-card-header header-tag="nav">
        <cvt-nav card-header tabs>
          <!-- <cvt-nav-item>'s with child routes. Note the trailing slash on the first <cvt-nav-item> -->
          <cvt-nav-item to="/some/route/" exact exact-active-class="active">Active</cvt-nav-item>
          <cvt-nav-item to="/some/route/foo" exact exact-active-class="active">Foo</cvt-nav-item>
          <cvt-nav-item to="/some/route/bar" exact exact-active-class="active">Bar</cvt-nav-item>
        </cvt-nav>
      </cvt-card-header>
  
      <cvt-card-body>
        <!-- Child route gets rendered in <router-view> or <nuxt-child> -->
        <router-view></router-view>
        <!-- Or if using Nuxt.js
        <nuxt-child></nuxt-child>
        -->
      </cvt-card-body>
    </cvt-card>
  </div>
</div>
