<template>
  <cvt-collapse
    :accordion="true"
    :theme="mode === 'LIGHT' ? 'light' : 'dark'"
    :prepend-chevron="prependChevron"
  >
    <cvt-collapse-item
      title="URL"
      name="url"
      :initial-active="true"
      :special-style="isSpecialStyle"
    >
      <cvt-alert color="primary" icon="info-circle">
        {{ $t('element.property.video.message') }}
      </cvt-alert>

      <cvt-input
        v-model="src"
        class="mt-2"
        :placeholder="$t('element.property.video.input.placeholder')"
        :label="
          !VideoMenuInputFloatingLabel
            ? $t('element.property.video.input.placeholder')
            : ''
        "
        :floating-label="VideoMenuInputFloatingLabel"
        autofocus
      >
      </cvt-input>

      <template v-if="sideBarVideoListOptions">
        <div class="divider mt-2" />

        <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
          {{ $t('element.property.video.crm.video_list') }}
        </div>

        <video-list
          :value="crmRemoteVideoUrl"
          :mode="mode"
          endpoint="/v1/crm/media/lists"
          placeholder="Select Video"
          @videoSelectionUpdated="onVideoSelectionUpdated"
        />
      </template>
    </cvt-collapse-item>

    <cvt-collapse-item
      :title="$t('elements.properties.spacing.label')"
      name="spacing"
      :special-style="isSpecialStyle"
    >
      <spacing-ctrl></spacing-ctrl>
    </cvt-collapse-item>
    <cvt-collapse-item
      v-if="isGridBuilder"
      title="Style"
      name="style"
      :special-style="isSpecialStyle"
    >

      <!-- <div class="row">
        <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
          {{ $t('elements.properties.background.color') }}
        </div>
        <div class="col-12">
          <colors alpha :mode="mode" />
        </div>
      </div>

      <div class="divider mt-2" /> -->

      <div class="nestedInnerItem">
        <cvt-select
          :value="borderStyle"
          mode="dropdown"
          :background-mode="mode"
          :floating-label="false"
          :label="$t('elements.properties.border.style')"
          @input="(value) => updateBorderStyle(value)"
        >
          <option
            v-for="style in borderStyles"
            :key="style"
            :value="style"
            v-text="style"
          />
        </cvt-select>
        <div style="margin: 5px" />
        <cvt-input
          v-model="borderColor"
          style="width: max-content"
          type="text"
          :clearable="false"
          :label="$t('elements.properties.border.color')"
        />
      </div>

      <div class="divider mt-2" />

      <div class="row">
        <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
          {{ $t('elements.properties.border.width') }}
        </div>
        <div class="col-12">
          <cvt-slider
            id="slider-default"
            :tooltips-enabled="true"
            :step-marks-enabled="true"
            class="slider"
            :min="1"
            :max="10"
            :value="borderWidth"
            :step="1"
            @change="updateBorderWidth"
          />
        </div>
      </div>
      <div class="divider mt-2" />

      <div class="row">
        <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
          {{ $t('elements.properties.border.radius') }}
        </div>
        <div class="col-12">
          <cvt-slider
            id="slider-default"
            :tooltips-enabled="true"
            :step-marks-enabled="true"
            class="slider"
            :min="0"
            :max="100"
            :value="borderRadius"
            :step="5"
            @change="updateBorderRadius"
          />
        </div>
      </div>
    </cvt-collapse-item>
  </cvt-collapse>
</template>

<script>
import * as video from 'js-video-url-parser'
import * as _ from 'lodash'
import v from 'validator'
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../../storybook/components/constants'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
// import Colors from '../theme/Colors.vue'
import FlexAlign from './FlexAlign.vue'
import Resize from './Resize.vue'
import SpacingCtrl from './SpacingCtrl.vue'
import VideoList from './VideoList.vue'

export default {
  components: {
    SpacingCtrl,
    // Colors,
    VideoList,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    prependChevron: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 'url',
      borderStyles: [
        'none',
        'solid',
        // 'ridge',
        // 'inset',
        // 'outset',
        'dashed',
        'dotted',
        'double',
        'groove',
      ],
      borderWidth: 0,
      borderRadius: 0,
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      config: getters.AUTH_GET_USER_CONFIG,
      isGridBuilder: getters.IS_GRID_BUILDER,
      user: getters.AUTH_GET_USER,
    }),
    ...mapState('globalTheme', {
      VideoMenuInputFloatingLabel: ({ globalTheme }) =>
        globalTheme.VideoMenuInputFloatingLabel !== undefined
          ? globalTheme.VideoMenuInputFloatingLabel
          : true,
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
    }),
    src: {
      get() {
        return this.selected.src
      },
      /**
       * Added debounce to get the code block some time before it refresh the video, without hammering with multiple requests at a time.
       */
      set: _.debounce(
        function ({ target }) {
          if (
            !target.value ||
            !v.isURL(target.value, { allow_protocol_relative_urls: true })
          ) {
            this.$message({
              type: 'warning',
              message: 'You must provide a video src.',
            })
            return this.updateProp({
              key: 'src',
              value: this.config.BUILDER_DEFAULT_VIDEO || '',
            })
          }

          return this.updateProp({
            key: 'src',
            value: this.fmtVideoSrc(target.value),
          })
        },
        700,
        { leading: false, trailing: true },
      ),
    },
    isSpecialStyle() {
      return this.OrgName === LEFT_SIDEBAR_FRESHWORKS
    },
    borderStyle: {
      get() {
        return this.selected.parent().name === 'GridItemWrapper'
          ? this.selected.parent().borderStyle || 'none'
          : 'none'
      },
      set: _.throttle(function (value) {
        return this.selected.parent().updateProp('borderStyle', value)
      }, 300),
    },
    borderColor: {
      get() {
        return this.selected.parent().name === 'GridItemWrapper'
          ? this.selected.parent().borderColor
          : null
      },
      set: _.throttle(function ({ target }) {
        if (this.selected.parent().name === 'GridItemWrapper') {
          return this.selected.parent().updateProp('borderColor', target.value)
        }

        return
      }, 300),
    },
    crmRemoteVideoUrl: {
      get() {
        return this.selected.crmRemoteVideoUrl
      },
      set(value) {
        this.selected.updateProp('crmRemoteVideoUrl', value)
      },
    },
    sideBarVideoListOptions() {
      return this.config.SIDEBAR_VIDEO_LIST_OPTIONS === true
    },
  },
  created() {
    if (this.selected.parent().name === 'GridItemWrapper') {
      this.borderWidth = this.selected.parent().borderWidth || 0
      this.borderRadius = this.selected.parent().borderRadius || 0
    }
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
    }),
    fmtVideoSrc(src) {
      const exampleUrl = 'https://convrrt.com'
      const newUrl = new URL(src, exampleUrl)
      let query = `${newUrl.origin}${newUrl.pathname}`

      if (src.indexOf('wistia') >= 0) {
        let id = newUrl.href.split('/').pop()
        return `https://fast.wistia.net/embed/iframe/${id}`
      }

      /**
       * video.parse(src) might give undefined value if url is not valid.
       * so setting default response to handle without typeerror.
       */
      let fmtVideoUrl =
        video.create({
          videoInfo: video.parse(src) || {
            id: '',
            mediaType: '',
            provider: '',
          },
          format: 'embed',
        }) ||
        src ||
        ''

      return fmtVideoUrl ? fmtVideoUrl : query 
    },
    updateBorderStyle(value) {
      if (this.selected.parent().name === 'GridItemWrapper') {
        this.borderStyle = value
      }
    },
    updateBorderWidth: _.throttle(function (value) {
      if (this.selected.parent().name !== 'GridItemWrapper') return

      this.borderWidth = value
      return this.selected.parent().updateProp('borderWidth', value)
    }, 300),
    updateBorderRadius: _.throttle(function (value) {
      if (this.selected.parent().name !== 'GridItemWrapper') return

      this.borderRadius = value
      return this.selected.parent().updateProp('borderRadius', value)
    }, 300),

    onVideoSelectionUpdated(value) {
      // data is being set in the required-format
      this.src = { target: { value: value } }

      this.crmRemoteVideoUrl = value
    },
  },
}
</script>

<style lang="scss"></style>
