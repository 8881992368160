import * as _ from 'lodash'
import Vue from '../../../../lib/utils/vue'
import VueI18n from 'vue-i18n'
import { styled } from '@egoist/vue-emotion'
import * as getters from '../../../../../builder/src/js/store/modules/getters'
import * as constants from '../../../../../builder/src/js/common/constants/constants'
import { mapGetters, mapState, mapActions } from 'vuex'
import CvtRadioGroup from '../../../molecules/radio-group/RadioGroup'
import { IntegrationReady } from '../ready'
import {
  getIntegrationTitle,
  getIntegrationUseSubtext,
  getIntegrationUseWrap,
  IntegrationDescription
} from "../description";
import { IntegrationLogoWrapper } from '../logo-wrapper'
import { IntegrationContainer } from '../container'
import * as actions from "../../../../../builder/src/js/store/modules/actions"
import { GoogleSheetSetup } from "./setup";
import IntegrationItemBase from "../IntegrationItemBase.vue";

Vue.use(VueI18n)

let textFontWeight, textFontSize, Spacing, FontSize, backgroundColor, Colors


export default IntegrationItemBase.extend({
  name: 'GoogleSheetIntegration',
  components: {
    CvtRadioGroup,
  },
  data() {
    return {
      initialRadioValue: '',
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      selectedIntegration: getters.SELECTED_INTEGRATION,
      formFieldGroups: getters.FORM_EDITOR_FIELD_GROUPS
    }),
    ...mapGetters('globalTheme', {
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
    }),
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      globalTheme: ({ globalTheme }) => globalTheme,
    }),
  },
  created() {
    textFontSize = this.textFontSize
    textFontWeight = this.textFontWeight
    Spacing = this.Spacing
    FontSize = this.FontSize
    backgroundColor = this.backgroundColor
    Colors = this.Colors
  },
  mounted() {
    this.onScrollInToView()
  },
  methods: {
    onScrollInToView() {
      const container = document.getElementById('authWrap')
      container?.scrollIntoView()
    },
  },
  render: function (h) {
    const IntegrationTitle = getIntegrationTitle()
    const IntegrationUseWrap = getIntegrationUseWrap()
    const IntegrationUseSubtext = getIntegrationUseSubtext()


    const integrationTitle = this.selectedIntegration.title

    return (
      <IntegrationContainer>
        {this.showAuthScreen && (
          <IntegrationLogoWrapper>
            <IntegrationDescription provider="googleSheet" onStepChange={(step) => this.onChangeAuthStep(step, this.selected.id)}>
              <div style="padding: 45px 0px 45px 45px;">
                <IntegrationTitle>
                  <a href="https://www.google.com/sheets/about/" target="_blank">Google Sheets</a> is a powerful data management tool that helps you organize important data and
                  collaborate with others. Integrate MeclabsAI with Google Sheets to automatically
                  sync form submissions to your spreadsheets and update your data in real time.
                </IntegrationTitle>
                <IntegrationUseWrap>
                  <p>Use this integration to</p>
                  <ul>
                    <li>
                      Add form data to your spreadsheets
                      {this.$t('builder.integrations.description.list.one')}
                    </li>
                    <li>
                      Automate your workflow
                      {this.$t('builder.integrations.description.list.two')}
                    </li>
                    <li>
                      Cut out manual data entry
                      {this.$t('builder.integrations.description.list.three')}
                    </li>
                  </ul>
                </IntegrationUseWrap>
                <IntegrationUseSubtext>
                  New form data will be sent to your spreadsheets instantly so you
                  and your team can view and analyze it right away. Just create your form,
                  customize to your liking, and integrate to start collecting responses in Google Sheets today,
                </IntegrationUseSubtext>
                <IntegrationUseSubtext>
                  {/* Learn how to */}
                  Learn how to{' '}
                  <a href="https://integrations.meclabsai.com/google-sheets.html" target="_blank" style="text-decoration: underline;cursor: pointer;">
                    {' '}
                    integrate your form with {integrationTitle}.
                  </a>
                </IntegrationUseSubtext>
              </div>
            </IntegrationDescription>
          </IntegrationLogoWrapper>
        )}

        {this.integrationConfig.step === constants.AUTH_SUCCESS && this.isValidProvider && (
          <GoogleSheetSetup onCompleted={step => this.onCompleted()} onCancel={this.onCancel}/>
        )}
        {this.integrationConfig.step === constants.INTEGRATION_FINISHED && this.isValidProvider && (
          <GoogleSheetSetup onCancel={this.onCancel} onCompleted={step => this.onUpdate()}/>
        )}

        {this.integrationConfig.step === constants.INTEGRATION_COMPLETE && this.isValidProvider && (
          <IntegrationReady  onFinished={
            () => this.onFinished()
          }/>
        )}
      </IntegrationContainer>
    )
  },
})
