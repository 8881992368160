
	<cvt-collapse
		:accordion="true"
		:theme="mode === 'LIGHT' ? 'light' : 'dark'"
		:prepend-chevron="prependChevron"
	>
		<cvt-collapse-item
			name="Properties"
			:title="$t('elements.properties.checkout.mock.payment.page.display_options')"
			:initial-active="true"
		>
			<div class="row mt-2">
				<div class="col">
					<h6 :class="textColorClass">
						{{ $t('elements.properties.checkout.mock.payment.page.display_options.show_logo') }}
					</h6>
				</div>
				<div class="col-3">
					<cvt-switch
						size="sm"
						color="active"
						type="circular"
						description-type="default"
						:initial-state="showLogo"
						:mode="mode"
						@switchToggle="(status) => onSwitchToggle('showLogo', status)"
					></cvt-switch>
				</div>
			</div>

      <div class="divider" />

			<div class="row mt-2">
				<div class="col">
					<h6 :class="textColorClass">
						{{ $t('elements.properties.checkout.mock.payment.page.display_options.show_discount_code') }}
					</h6>
				</div>
				<div class="col-3">
					<cvt-switch
						size="sm"
						color="active"
						type="circular"
						description-type="default"
						:initial-state="showDiscountCode"
						:mode="mode"
						@switchToggle="(status) => onSwitchToggle('showDiscountCode', status)"
					></cvt-switch>
				</div>
			</div>

      <div class="divider" />

			<div class="row mt-2">
				<div class="col">
					<h6 :class="textColorClass">
						{{ $t('elements.properties.checkout.mock.payment.page.display_options.show_google_pay') }}
					</h6>
				</div>
				<div class="col-3">
					<cvt-switch
						size="sm"
						color="active"
						type="circular"
						description-type="default"
						:initial-state="showGooglePay"
						:mode="mode"
						@switchToggle="(status) => onSwitchToggle('showGooglePay', status)"
					></cvt-switch>
				</div>
			</div>

      <div class="divider" />

			<div class="row mt-2">
				<div class="col">
					<h6 :class="textColorClass">
						{{ $t('elements.properties.checkout.mock.payment.page.display_options.show_allow_subscription') }}
					</h6>
				</div>
				<div class="col-3">
					<cvt-switch
						size="sm"
						color="active"
						type="circular"
						description-type="default"
						:initial-state="allowSubscription"
						:mode="mode"
						@switchToggle="(status) => onSwitchToggle('allowSubscription', status)"
					></cvt-switch>
				</div>
			</div>

      <div class="divider" />

			<div class="row mt-2">
				<div class="col">
					<h6 :class="textColorClass">
						{{ $t('elements.properties.checkout.mock.payment.page.display_options.show_upsell_downsell') }}
					</h6>
				</div>
				<div class="col-3">
					<cvt-switch
						size="sm"
						color="active"
						type="circular"
						description-type="default"
						:initial-state="showUpOrDownSells"
						:mode="mode"
						@switchToggle="(status) => onSwitchToggle('showUpOrDownSells', status)"
					></cvt-switch>
				</div>
			</div>

      <div class="divider mt-5" />

			<div class='row mt-3'>
				<div class='col-md-12 ml-auto'>
					<cvt-select
						mode='dropdown'
						:value='redirectOption'
						:label='$t(`elements.properties.checkout.mock.payment.page.redirect_option`)'
						:background-mode='mode'
						@input="(value) => updateProperty('redirectOption', value)"
					>
						<option
							v-for='option in redirectOptions'
							:key='option.value'
							:value='option.value'
							v-text='option.label'
						/>
					</cvt-select>
				</div>
			</div>

			<div v-if="redirectOption === 'page'" class='row mt-3'>
				<div class='col-md-12 ml-auto'>
					<page-list
						:selected-page='pageId'
						:mode='mode'
						@change="(value) => updateProperty('pageId', value)"
					/>
				</div>
			</div>

			<div v-if="redirectOption === 'url'" class='row mt-3'>
				<div class='col-md-12 ml-auto'>
					<h6 :class='textColorClass'>
						{{ $t('element.property.form.option.external_url') }}
					</h6>
				</div>
				<div class='col-md-12 ml-auto'>
					<url-input
						v-model='redirectUrl'
						type='text'
						placeholder='https://external.site'
					/>
				</div>
			</div>

			<div class='row mt-3'>
				<div class='col-md-12 ml-auto'>
					<h6 :class='textColorClass'>
						{{ $t('elements.properties.checkout.mock.payment.page.terms_conditions_url') }}
					</h6>
				</div>
				<div class='col-md-12 ml-auto'>
					<url-input
						v-model='termsAndConditionsURL'
						type='text'
						placeholder='https://external.site/terms'
					/>
				</div>
			</div>

			<div class='row mt-3'>
				<div class='col-md-12 ml-auto'>
					<h6 :class='textColorClass'>
						{{ $t('elements.properties.checkout.mock.payment.page.privacy_policy_url') }}
					</h6>
				</div>
				<div class='col-md-12 ml-auto'>
					<url-input
						v-model='privacyPolicyURL'
						type='text'
						placeholder='https://external.site/policy-url'
					/>
				</div>
			</div>

			<div class='row mt-3'>
				<div class='col-md-12 ml-auto'>
					<h6 :class='textColorClass'>
						{{ $t('elements.properties.checkout.mock.payment.page.refund_url') }}
					</h6>
				</div>
				<div class='col-md-12 ml-auto'>
					<url-input
						v-model='refundURL'
						type='text'
						placeholder='https://external.site/terms'
					/>
				</div>
			</div>

		</cvt-collapse-item>
	</cvt-collapse>
