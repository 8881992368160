
  <div>
    <h1>Tab</h1>
    <div>
      <h4>Basic</h4>
      <div
        v-for="(tabPlacement, index) in tabPositions"
        :key="index"
        class="mb-5"
      >
        <span>
          {{ index }}
        </span>
        <cvt-tabs
          :keep-inactive-border="true"
          :pills="true"
          :closeable="true"
          :end="tabPlacement === 'end' || tabPlacement === 'right'"
          :vertical="tabPlacement === 'left' || tabPlacement === 'right'"
          mode="LIGHT"
          prefix-icon="star"
          @activate-tab="onTabClick"
          @close-tab="onTabClose"
          @click="onTabClick"
        >
          <cvt-tab title="Tab 1">Tab contents 1</cvt-tab>
          <cvt-tab title="Tab 2" active>Tab contents 2</cvt-tab>
          <cvt-tab title="Tab 3">Tab contents 3</cvt-tab>
        </cvt-tabs>
      </div>
      <div class="mb-5">
        <span>Dark</span>
        <cvt-tabs
          :keep-inactive-border="true"
          :pills="true"
          :closeable="true"
          :end="false"
          :vertical="false"
          mode="DARK"
          prefix-icon="star"
          @activate-tab="onTabClick"
          @close-tab="onTabClose"
          @click="onTabClick"
        >
          <cvt-tab title="Tab 1" :closeable="true">Tab contents 1</cvt-tab>
          <cvt-tab title="Tab 2" active :closeable="true">Tab contents 2</cvt-tab>
          <cvt-tab title="Tab 3" :closeable="true">Tab contents 3</cvt-tab>
        </cvt-tabs>
      </div>
      <h4>Custom</h4>
      <div class="mb-5">
        <cvt-tabs :mode="mode">
          <cvt-tab active>
            <template #title>
              <cvt-icon icon="spin" class="fa-spinner"></cvt-icon><span style="margin-right: 12px;"></span> I'm <i>custom</i> <strong>title</strong>
            </template>
            <p class="p-3">Tab contents 1</p>
          </cvt-tab>
      
          <cvt-tab>
            <template #title>
              <cvt-icon icon="clock" small></cvt-icon><span style="margin-right: 12px;"></span>  Time Tab
            </template>
            <p class="p-3">Tab contents 2</p>
          </cvt-tab>

          <cvt-tab :disabled="true">
            <template #title>
              <cvt-icon icon="ban" small></cvt-icon><span style="margin-right: 12px;"></span>  Disabled Tab
            </template>
          </cvt-tab>
        </cvt-tabs>
      </div>
      <h4>Lazy</h4>
      <div class="mb-5">
        <cvt-tabs :mode="mode" content-class="mt-3">
          <!-- This tabs content will always be mounted -->
          <cvt-tab title="Regular tab">
            <p class="p-3">Tab contents 1</p>
          </cvt-tab>

          <!-- This tabs content will not be mounted until the tab is shown -->
          <!-- and will be un-mounted when hidden -->
          <cvt-tab title="Lazy tab" lazy>
            <p class="p-3">Tab contents 2</p>
          </cvt-tab>
        </cvt-tabs>
      </div>
      <h4>Advanced</h4>
      <div class="mb-5">
        <cvt-tabs
:closeable="closeable" 
          :mode="mode"
          @activate-tab="onTabClick"
          @close-tab="onTabClose"
        >
          <!-- Render Tabs, supply a unique \`key\` to each tab -->
          <cvt-tab v-for="i in tabs" :key="'dyn-tab-' + i" :title="'Tab ' + i">
            Tab contents {{ i }}          
          </cvt-tab>        

          <!-- New Tab Button (Using tabs-end slot) -->
          <template #tabs-end>
            <cvt-button 
              shape="pill"
              size="lg"
              icon="plus"
              :disabled="false"
              :action-icon="true"
              color-of-action-icon=""
              :mode-of-action-icon="mode == 'DARK' ? 'light' : 'medium'"
              @click.prevent="newTab"
            >
            </cvt-button>  
          </template>

          <!-- Render this if no tabs -->
          <template #empty>
            <div class="text-center text-muted">
              There are no open tabs<br>
              Open a new tab using the <b>+</b> button above.
            </div>
          </template>
        </cvt-tabs>
      </div>
      <h4>iOS Pills</h4>
      <div class="mb-5">
        <cvt-tabs 
          :pills="true"
          :closeable="closeable"
          :ios-pills="true"
          :mode="mode"
          prefix-icon=""
          @click="onTabClick"
          @activate-tab="onTabClick"
          @close-tab="onTabClose"
        >
          <cvt-tab title="Tab 1" active>Tab contents 1</cvt-tab>
          <cvt-tab title="Tab 2">Tab contents 2</cvt-tab>
          <cvt-tab title="Tab 3">Tab contents 3</cvt-tab>
          <cvt-tab title="Tab titles is longer">Tab contents 4, the contents are longer</cvt-tab>
          <cvt-tab title="Tab even this is longer">Tab contents 5, even these are to test</cvt-tab>
          <cvt-tab title="short 6">short 6</cvt-tab>
          <cvt-tab title="7">Tab 7</cvt-tab>
        </cvt-tabs>
      </div>
      <h4>Raised</h4>
      <div class="mb-5">
        <cvt-tabs 
          :pills="false"
          :closeable="closeable"
          :header-raised="true"
          :mode="mode"
          @click="onTabClick"
          @activate-tab="onTabClick"
          @close-tab="onTabClose"
        >
          <cvt-tab title="Tab 1" active>Tab contents 1</cvt-tab>
          <cvt-tab title="Tab 2">Tab contents 2</cvt-tab>
          <cvt-tab title="Tab 3">Tab contents 3</cvt-tab>
        </cvt-tabs>
      </div>
      <h4>ButtonGroup</h4>
      <div class="mb-5">
        <cvt-tabs
          :pills="true"
          :closeable="closeable"
          :button-group="true"
          :mode="mode"
          @click="onTabClick"
          @activate-tab="onTabClick"
          @close-tab="onTabClose"
        >
          <cvt-tab title="Tab 1" active>Tab contents 1</cvt-tab>
          <cvt-tab title="Tab 2">Tab contents 2</cvt-tab>
          <cvt-tab title="Tab 3">Tab contents 3</cvt-tab>
          <cvt-tab title="Tab 4">Tab contents 4</cvt-tab>
          <cvt-tab title="Tab 5">Tab contents 5</cvt-tab>
        </cvt-tabs>
      </div>
    </div>
  </div>
