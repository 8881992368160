
<div class="colors">
  <div class="d-flex flex-wrap">
    <div
      :id="`tooltip-${uniqueId}`"
      class="theme-color"
      :style="colorToStyle(defaultTextColor)"
    ></div>
  </div>

  <cvt-tooltip
    ref="tooltip"
    :show="showTooltip"
    :delay="20"
    :no-fade="true"
    :placement="'left'"
    :color="'light'"
    :mode="'LIGHT'"
    :boundary-padding="0"
    :inner-padding="0"
    :triggers="'click'"
    :target="`tooltip-${uniqueId}`"
  >
    <div class="tooltip-wrapper">
      <div
        v-for="(color, key) in colors"
        :key="key"
        class="theme-color"
        :style="colorToStyle(color)"
        @click.stop="applyColor(color, key)"
      ></div>
    </div>
  </cvt-tooltip>
</div>
