import Vue from '../../../lib/utils/vue'
import KeyCodes from '../../../lib/utils/key-codes'
import { stop } from '../../../lib/utils/event'
import { CvtLink } from '../link/index'
import { CvtIcon } from '../icon'
import CvtButton from '../Button'

import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'

Vue.use(VueEmotion)

let FontSize, FontFamily, Spacing, Radii, Colors, Border
let textFontWeight, borderColor, color, backgroundColor

const UTIL = {
  getDefaultTabTextColor(props){
    if(props.mode == 'DARK'){
      return color({ themeing: 'dark' }, 'WHITE')
    }else{
      if(props.tabsConfig){
        const tabTextColorConfig = props.tabsConfig?.tab?.default?.textColor
        if(!tabTextColorConfig.custom){
          return color(tabTextColorConfig.value, tabTextColorConfig.weight)
        }else{
          return `color: ${tabTextColorConfig.value};`
        }
      }else{
        return color({ themeing: 'dark' }, 500)
      }
    }
  },
  getDefaultTabBgColor(props){
    if(props.tabsConfig){
      const tabBgColorConfig = props.tabsConfig?.tab?.default?.bgColor
        if(!tabBgColorConfig?.custom){
          return backgroundColor(tabBgColorConfig.value, tabBgColorConfig.weight)
        }else{
          return `background: ${tabBgColorConfig.value};`
        }
    }else{
      return `background: transparent;`
    }
  },
  getTabTextColorOnHover(props){
    if(props.mode == 'LIGHT'){
      return  color({ themeing: 'primary' }, 500)
    }
    else if(!props.iosPills){
      if(props.tabsConfig){
        const tabTextColorOnHoverConfig = props.tabsConfig?.tab?.hover?.textColor
        if(!tabTextColorOnHoverConfig?.custom){
          return color(tabTextColorOnHoverConfig.value, tabTextColorOnHoverConfig.weight)
        }else{
          return `color: ${tabTextColorOnHoverConfig.value};`
        }
      }else{
        return color({ themeing: 'dark' }, 500)
      }
    }else{
      return ``
    }
    
  },
  getTabBorderColorOnHover(props){
    if(props.mode == 'DARK'){
      return borderColor({ themeing: 'primary' }, 500)
    }else{
      if(props.tabsConfig){
        const tabBorderColorOnHoverConfig = props.tabsConfig?.tab?.hover?.borderColor
        if(!tabBorderColorOnHoverConfig?.custom){
          borderColor(tabBorderColorOnHoverConfig.value, tabBorderColorOnHoverConfig.weight)
        }else{
          return `border-color: ${tabBorderColorOnHoverConfig.value};`
        }
      }else{
        return borderColor({ themeing: 'primary' }, 500)
      }
    }
  },
  getTabActiveBgColor(props){
    if(props.tabsConfig){
      const tabBgColorActiveConfig = props.tabsConfig?.tab?.active?.bgColor
      if(!tabBgColorActiveConfig?.custom){
        return backgroundColor(tabBgColorActiveConfig.value, tabBgColorActiveConfig.weight)
      }else{
        return `background: ${tabBgColorActiveConfig.value};`
      }
    }else{
      backgroundColor({ themeing: 'primary' }, 500)
    }
    
  },
  getTabActiveTextColor(props){
    if(props.tabsConfig){
      const tabTextColorActiveConfig = props.tabsConfig?.tab?.active?.textColor
      if(!tabTextColorActiveConfig?.custom){
        return color(tabTextColorActiveConfig.value, tabTextColorActiveConfig.weight)
      }else{
        return `color ${tabTextColorActiveConfig.value};`
      }
    }else{
      color({ themeing: 'dark' }, 100)
    }
    
  },
  getTabBorderRadius(props){
    if(props.tabsConfig){
      const tabBorderRadiusConfig = props.tabsConfig?.tab?.default?.border?.radius
      if(tabBorderRadiusConfig){
        return `border-radius: ${tabBorderRadiusConfig}px !important;`
      }
    }
  },
  getTabSpacing(props){
    if(props.tabsConfig){
      const tabSpacingConfig = props.tabsConfig?.tab?.default?.spacing
      if(tabSpacingConfig.isSpacing){
        return (
          `margin:
          ${tabSpacingConfig.top}px  
          ${tabSpacingConfig.right}px 
          ${tabSpacingConfig.bottom}px 
          ${tabSpacingConfig.left}px;`
        )
      }
    }
  },
  getTabDefaultBorder(props){
    if(props.tabsConfig){
      const tabBorderConfig = props.tabsConfig?.tab?.default?.border
      const tabBorderColorConfig = props.tabsConfig?.tab?.default?.border?.color
      if(Object.keys(tabBorderConfig).length !== 0){
        if(!tabBorderColorConfig?.custom){
          return `border: ${tabBorderConfig.size}px solid ${Colors[tabBorderColorConfig.value.toUpperCase()][tabBorderColorConfig.weight]} !important;`
        }else{
          return `border: ${tabBorderConfig.size}px solid ${tabBorderColorConfig.value} !important;`
        }
      }
    }
  }
}

const getListItem = () => styled('li')`
  ${(props) => {
    let styles = ``
    if (props.headerRaised) {
      styles += `
        &:nth-child(n){
          & a {
            border-width: ${Spacing.SPACING_2}px;
          }
        }
        &:first-child {
          & a {
            border-bottom-left-radius: ${Radii.RADIUS_PX_8}px;
          }
        }
        &:nth-last-child(2) {
          & a {
            border-bottom-right-radius: ${Radii.RADIUS_PX_8}px;
          }
        }
      `
    } else if (
      props.iosPills &&
      !props.nextTabIsActive &&
      !props.isLocalActive
    ) {
      let bordercolor =
        props.mode == 'LIGHT' ? Colors.BASIC[200] : Colors.BASIC[600]

      styles += `
        position: relative;
        &::after {
          content: '';
          border: ${Border.SIZE_1}px solid ${bordercolor};
          height: 80%;
          position: absolute;
          right: 0;
          top: 10%;
        }
      `
    } else if (props.buttonGroup) {
      styles += `
        &:nth-child(n) {
          border-width: 1px 0 1px 1px;
          border-style: solid;
          border-color: ${Colors.PRIMARY[500]};
        }
        &:first-child {
          border-top-left-radius: ${Radii.RADIUS_PX_8}px;
          border-bottom-left-radius: ${Radii.RADIUS_PX_8}px;
          border-width: 1px 0 1px 1px;
          & > a {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
        }
        &:nth-last-child(2), :nth-last-child(2) > a {
          border-top-right-radius: ${Radii.RADIUS_PX_8}px;
          border-bottom-right-radius: ${Radii.RADIUS_PX_8}px;
          border-width: 1px 1px 1px 1px;
          & > a {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      `
    }
    return styles
  }}
`
const getClearButton = () => styled(CvtButton)`
  display: none;
`

const getClearBtnWrapSpan = () => styled('span')`
  display: none;
`

const getIndentationSpan = () => styled('span')`
  ${(props) => {
    let styles = ``
    if (props.titlePrefixIndent) {
      styles += props.hasPrefixIcon ? `width:3.5px;` : `width:9.5px;`
    } else if (props.titleSuffixIndent) {
      styles += props.hasPrefixIcon ? `width:15.25px;` : `width:9.25px;`
    }
    return styles
  }}
`

const getCustomStarIcon = () => styled(CvtIcon)`
  ${(props) => {
    let styles = ``
    if ( !props.isBreadCrumbs ) {
      styles += `padding: ${Spacing.SPACING_4}px;`;
    } else {
      //For breadcrums pre and post icons
      if( props.preIconSpecialMargin !== '') {
        styles += `margin: ${props.preIconSpecialMargin};`;
      } else {
        if( props.pre ) {
          styles += `margin-right: ${Spacing.SPACING_8+Spacing.SPACING_2}px;`;
        } else {
          styles += `margin-left: ${Spacing.SPACING_8+Spacing.SPACING_2}px;`;
        }
      }
    }
    return styles;
  }}
`

const getStarIconWrapSpan = () => styled('span')`
  border: ${Border.SIZE_1}px solid transparent;
  border-radius: ${Radii.RADIUS_PERCENT_50}%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const getTitleWrapSpan = () => styled('span')`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5px 5px;
`

const getCustomCvtLink = () => styled(CvtLink)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${Spacing.SPACING_8}px ${Spacing.SPACING_8}px;
  ${(props) => UTIL.getDefaultTabTextColor(props)}
  ${(props) => UTIL.getDefaultTabBgColor(props)}
  ${(props) => UTIL.getTabBorderRadius(props)}
  ${(props) => UTIL.getTabSpacing(props)}
  ${(props) => UTIL.getTabDefaultBorder(props)}
  
  &:hover,
  :focus {
    text-decoration: none;
    ${(props) => UTIL.getTabBorderColorOnHover(props)}
    ${(props) => UTIL.getTabTextColorOnHover(props)}
  }

  ${(props) => {
    let styles = ''
    if (props.iosPills) {
      styles += `padding: ${Spacing.SPACING_2}px ${Spacing.SPACING_8}px;`
    }
    if (props.inactive && !props.buttonGroup) {
      
      let borderSize = Spacing.SPACING_4;

      if (props.smallerTabBorder) {
        borderSize = 1;
      }

      let borderColor = props.keepInactiveBorder
        ? props.mode == 'LIGHT'
          ? Colors.BASIC[200]
          : Colors.BASIC[600]
        : 'transparent'
      if (props.headerRaised) {
        borderSize = Spacing.SPACING_4
        borderColor = 'transparent'
      }

      if (props.inActiveBorderColor !== '') {
        borderColor = props.inActiveBorderColor;
      }

      if (props.top) {
        styles += `border-bottom: ${borderSize}px solid ${borderColor};`
      }

      if (props.bottom) {
        styles += `border-top: ${borderSize}px solid ${borderColor};`
      }

      if (props.left) {
        styles += `border-right: ${borderSize}px solid ${borderColor};`
      }

      if (props.right) {
        styles += `border-left: ${borderSize}px solid ${borderColor};`
      }
    }

    if (props.isActive && !props.buttonGroup) {
      styles += `${(props) =>
        props.mode == 'LIGHT' ? color({ themeing: 'primary' }, 500) : ''}
                border-bottom: ${Spacing.SPACING_4}px solid ${
        Colors.PRIMARY[500]
      };    
                border-top-left-radius: ${Radii.RADIUS_PX_8}px;
                border-top-right-radius: ${Radii.RADIUS_PX_8}px;
                ${textFontWeight(0)}`
    }

    if (props.tabActive && !props.buttonGroup) {
      styles += `border: 0;`

      if (props.top)
        styles += `border-bottom: ${Spacing.SPACING_4}px solid ${Colors.PRIMARY[500]};`
      if (props.bottom)
        styles += `border-top-left-radius: 0; border-top-right-radius: 0; border-top: ${Spacing.SPACING_4}px solid ${Colors.PRIMARY[500]};`
      if (props.left)
        styles += `border-bottom-right-radius: 0; border-top-right-radius: 0; border-right: ${Spacing.SPACING_4}px solid ${Colors.PRIMARY[500]};`
      if (props.right)
        styles += `border-bottom-left-radius: 0; border-top-left-radius: 0; border-left: ${Spacing.SPACING_4}px solid ${Colors.PRIMARY[500]};`

      if (props.smallerTabBorder) {
        styles += `border-bottom: 1px solid ${Colors.PRIMARY[500]};`
      }
    }

    if (props.pills) {
      styles += `border: 0;`

      if (props.iosPills) {
        styles += `     
            ${textFontWeight('light')}
            font-family: ${FontFamily.DEFAULT};
            :hover {
              ${color({ themeing: 'dark' }, 300)}
              .star-icon {
                ${color({ themeing: 'dark' }, 300)}
              }
            }
          `
      } else if (props.buttonGroup) {
        styles += `   
          ${color({ themeing: 'primary' }, 500)}   
          ${textFontWeight('regular')}
          font-family: ${FontFamily.DEFAULT};
          .star-icon {
            ${color({ themeing: 'primary' }, 500)}
          }
          :hover {
            ${color({ themeing: 'primary' }, 500)}
          }
        `
      }

      if (props.isActive) {
        if (!props.iosPills && !props.buttonGroup) {
          styles += `
              ${UTIL.getTabActiveTextColor(props)}      
              ${UTIL.getTabActiveBgColor(props)}    
              border-radius: ${Radii.RADIUS_PX_8}px;  
              .star-icon {
                ${color({ themeing: 'dark' }, 100)}
              }
              :hover, :focus {
                ${UTIL.getTabActiveTextColor(props)}
              }
                `
        } else if (props.iosPills) {
          styles += `   
            ${color({ themeing: 'dark' }, 500)}   
            ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
            ${textFontWeight('light')}
            font-family: ${FontFamily.DEFAULT};
            border-radius: ${Radii.RADIUS_PX_8}px;  
            .star-icon {
              ${color({ themeing: 'dark' }, 500)}
            }
            :hover {
              ${color({ themeing: 'dark' }, 500)}
              .star-icon {
                ${color({ themeing: 'dark' }, 500)}
              }
            }
          `
        } else if (props.buttonGroup) {
          styles += `   
            ${color({ themeing: 'dark' }, 100)}   
            ${backgroundColor({ themeing: 'primary' }, 500)}    
            ${textFontWeight('regular')}
            font-family: ${FontFamily.DEFAULT};
            .star-icon {
              ${color({ themeing: 'dark' }, 100)}
            }
            :hover {
              ${color({ themeing: 'dark' }, 100)}
            }
          `
        }
        if (props.closeable) {
          styles += `
                  &:hover{
                    #clearButton {
                      display: inline-flex;
                    }
                    #indentationSpan1, #indentationSpan2 {
                      display: none;
                    }
                  }`
        }
      }
    }

    if (props.closeable) {
      styles += `
          &:hover{
            #clearButton {
              display: inline-flex;
              
            }
            #indentationSpan1, #indentationSpan2 {
              display: none;
            }
          }
      `
    }

    let disabledStyles = ``
    if (props.disabled) {
      disabledStyles += `${color({ themeing: 'dark' }, 400)}  
          pointer-events: none;
          cursor: default;`
    }

    styles += disabledStyles
    return styles
  }}

  &:focus {
    outline: none;
  }

  transition: all 400ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
`

const getBreadcrumbsItem = () => styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${Spacing.SPACING_8}px;
`

const getBreadcrumbsTitleWrap  = () => styled('span')`
`

const getBreadcrumbsButtonWrap = () => styled('div')`

  ${(props) => {
    if(props.specialPadding !== '') {
      return `padding: ${props.specialPadding};`
    } else {
      return `padding: ${Spacing.SPACING_8+Spacing.SPACING_2}px ${Spacing.SPACING_12+Spacing.SPACING_8}px;`
    }
  }}

  ${(props) => {
    if(props.specialMargin !== '') {
      return `margin: ${props.specialMargin};`
    } else {
      return `margin: 0 ${Spacing.SPACING_8+Spacing.SPACING_2}px;`
    }
  }}
  
  font-family: ${FontFamily.DEFAULT};
  ${textFontWeight('BOLD')};
  font-size: ${FontSize.FONT_SIZE_14}px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => {

    let styles = '';
    if (props.pills) {
      let defaultTheme = 'dark';
      if( props.active ){
        defaultTheme = 'primary';
      }

      styles += `
        border-radius: ${Spacing.SPACING_32 - Spacing.SPACING_2}px;
        ${backgroundColor({ themeing: defaultTheme }, 100)};
      `;
    }

    //For states i.e. active, disabled, hover and default
    if( props.active ){
      styles += `
        ${color({ themeing: 'primary' }, 500)};
        .star-icon {
          ${color({ themeing: 'primary' }, 500)}
        }
      `
    } else if( props.disabled && !props.stepCompleted ){
      styles += `
        ${color({ themeing: 'dark' }, 300)};
        .star-icon {
          ${color({ themeing: 'dark' }, 300)}
        }
      `
    } else if( props.disabled && props.stepCompleted){
      styles += `
        ${color({ themeing: 'success' }, 500)};
        .star-icon {
          ${color({ themeing: 'success' }, 500)}
        }
      `
    } else {

      //default
      styles += `
        ${color({ themeing: 'dark' }, 500)};
        cursor: pointer;
        &:hover {
          ${color({ themeing: 'primary' }, 700)};
          .star-icon {
            ${color({ themeing: 'primary' }, 700)};
          }
        }

        .star-icon {
          ${color({ themeing: 'dark' }, 500)};
        }
      `
    }

    return styles;
  }}
`

const getBreadcrumbsDivisionWrap = () => styled('span')`
  font-size: ${FontSize.FONT_SIZE_14}px;
  margin-left: ${Spacing.SPACING_8}px;
  ${color({ themeing: 'dark' }, 500)};
  ${textFontWeight('BOLD')};

  ${(props) => {
    if( props.isLastTab ){
      return 'display:none;';
    }
  }}
`

const getBreadcrumbsDivisionIcon = () => styled(CvtIcon)`
  font-size: ${FontSize.FONT_SIZE_14}px;
  margin-left: ${Spacing.SPACING_8}px;
  ${color({ themeing: 'dark' }, 500)};
  ${(props) => {
    if( props.isLastTab ){
      return 'display:none;';
    }
  }}
`

// @vue/component
export default {
  name: 'CvtTabButtonWrapper',
  inject: {
    bvTabs: {
      default() /* istanbul ignore next */ {
        return {}
      }
    }
  },
  props: {
    // Reference to the child <cvt-tab> instance
    tab: { default: null },
    tabs: {
      type: Array,
      default() /* istanbul ignore next */ {
        return []
      }
    },
    id: { type: String, default: null },
    controls: { type: String, default: null },
    tabIndex: { type: Number, default: null },
    posInSet: { type: Number, default: null },
    setSize: { type: Number, default: null },
    noKeyNav: { type: Boolean, default: false },
    pills: { default: false },
    top: { default: false },
    bottom: { default: false },
    left: { default: false },
    right: { default: false },
    prefixIcon: { default: 'star' },
    headerRaised: { type: Boolean, default: false },
    iosPills: { type: Boolean, default: false },
    buttonGroup: { type: Boolean, default: false },
    nextTabIsActive: { type: Boolean, default: false },
    keepInactiveBorder: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'LIGHT'
    },
    //Used for breadcrumbs
    isBreadCrumbs: {
      type: Boolean,
      default: false
    },  
    postfixIcon: {
      type: String,
      default: ''
    },
    dividedBy: {
      type: String,
      default: 'slash'
    },
    isLastTab: {
      type: Boolean,
      default: false
    },
    specialMargin: {
      type: String,
      default: ''
    },
    specialPadding: {
      type: String,
      default: ''
    },
    preIconSpecialMargin: {
      type: String,
      default: ''
    },
    smallerTabBorder: {
      type: Boolean,
      default: false
    },
    inActiveBorderColor: {
      type: String,
      default: ''
    },
    tabsConfig: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR
    }),
    ...mapState('globalTheme', {
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      FontFamily: ({globalTheme}) => globalTheme.FontFamily,
      Spacing: ({globalTheme}) => globalTheme.Spacing,
      Radii: ({globalTheme}) => globalTheme.Radii,
      Colors: ({globalTheme}) => globalTheme.Colors,
      Border: ({globalTheme}) => globalTheme.Border
    }),
    hasPrefixIcon() {
      if (this.prefixIcon.length > 0) {
        return true
      }
      return false
    },
    hasPostfixIcon() {
      if (this.postfixIcon.length > 0) {
        return true
      }
      return false
    },
    isActivePill() {
      if (this.tab.localActive && !this.tab.disabled && this.pills) return true
      return false
    },
    isActiveTab() {
      if (this.tab.localActive && !this.tab.disabled && !this.pills) return true
      return false
    }
  },
  methods: {
    focus() {
      if (this.$refs && this.$refs.link && this.$refs.link.focus) {
        this.$refs.link.focus()
      }
    },
    handleEvt(evt) {
      if (this.tab.disabled) {
        /* istanbul ignore next */
        return
      }
      const type = evt.type
      const key = evt.keyCode
      const shift = evt.shiftKey
      if (type === 'click') {
        stop(evt)
        this.$emit('click', evt)
      } else if (type === 'keydown' && key === KeyCodes.SPACE) {
        // For ARIA tabs the SPACE key will also trigger a click/select
        // Even with keyboard navigation disabled, SPACE should "click" the button
        // See: https://github.com/bootstrap-vue/bootstrap-vue/issues/4323
        stop(evt)
        this.$emit('click', evt)
      } else if (type === 'keydown' && !this.noKeyNav) {
        // For keyboard navigation
        if (
          key === KeyCodes.UP ||
          key === KeyCodes.LEFT ||
          key === KeyCodes.HOME
        ) {
          stop(evt)
          if (shift || key === KeyCodes.HOME) {
            this.$emit('first', evt)
          } else {
            this.$emit('prev', evt)
          }
        } else if (
          key === KeyCodes.DOWN ||
          key === KeyCodes.RIGHT ||
          key === KeyCodes.END
        ) {
          stop(evt)
          if (shift || key === KeyCodes.END) {
            this.$emit('last', evt)
          } else {
            this.$emit('next', evt)
          }
        }
      }
    },
    onClose(evt) {
      stop(evt)
      const { tab, posInSet } = this
      this.bvTabs.closedTab(tab, posInSet)
    }
  },
  created() {
    FontSize = this.FontSize
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    Colors = this.Colors
    Border = this.Border
    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    color = this.textColor
    backgroundColor = this.backgroundColor
  },
  render(h) {
    const ListItem = getListItem()
    const ClearButton = getClearButton()
    const ClearBtnWrapSpan = getClearBtnWrapSpan()
    const IndentationSpan = getIndentationSpan()
    const CustomStarIcon = getCustomStarIcon()
    const StarIconWrapSpan = getStarIconWrapSpan()
    const TitleWrapSpan = getTitleWrapSpan()
    const CustomCvtLink = getCustomCvtLink()
    const BreadcrumbsItem = getBreadcrumbsItem()
    const BreadcrumbsTitleWrap = getBreadcrumbsTitleWrap()
    const BreadcrumbsButtonWrap = getBreadcrumbsButtonWrap()
    const BreadcrumbsDivisionWrap = getBreadcrumbsDivisionWrap()
    const BreadcrumbsDivisionIcon = getBreadcrumbsDivisionIcon()

    const closeIcon = (
      <ClearBtnWrapSpan id='clearButton'>
        <ClearButton
          shape='pill'
          size='xs'
          icon='times'
          disabled={false}
          actionIcon={true}
          colorOfActionIcon={
            this.isActivePill && !this.iosPills ? 'primary' : ''
          }
          modeOfActionIcon={
            this.isActiveTab || (this.buttonGroup && !this.tab.localActive)
              ? 'medium'
              : this.isActivePill && !this.iosPills
              ? 'light'
              : 'dark'
          }
          onClick={this.onClose}
        ></ClearButton>
      </ClearBtnWrapSpan>
    )

    let starIcon = (
      <StarIconWrapSpan>
        <CustomStarIcon
          icon={this.prefixIcon}
          prefixClass='star-icon'
          isBreadCrumbs={this.isBreadCrumbs}
          pre={true}
          preIconSpecialMargin={this.preIconSpecialMargin}
        ></CustomStarIcon>
      </StarIconWrapSpan>
    )

    let postIcon = (
      <StarIconWrapSpan>
        <CustomStarIcon
          icon={this.postfixIcon}
          prefixClass='star-icon'
          isBreadCrumbs = {this.isBreadCrumbs}
          pre={false}
        ></CustomStarIcon>
      </StarIconWrapSpan>
    )

    /* HANDLING the logic for Showing only 1 icon at a Time
      When custom icon is given in title slot prefix and postfix icons won't be displayed.
    */
    if (this.tab.normalizeSlot('title')) {
      let slotTitle = this.tab.normalizeSlot('title')

      for (let i in slotTitle) {
        if (
          slotTitle[i].tag &&
          slotTitle[i].tag.toLowerCase().includes('icon')
        ) {
          starIcon = '';
          postIcon = '';
        }
      }
    }

    return (
      <ListItem
        headerRaised={this.headerRaised}
        iosPills={this.iosPills}
        buttonGroup={this.buttonGroup}
        nextTabIsActive={this.nextTabIsActive}
        isLocalActive={this.tab.localActive}
        role='presentation'
        mode={this.mode}
      >

        {this.isBreadCrumbs == false && (
          <CustomCvtLink
            ref='link'
            {...this.tab.titleLinkAttributes}
            role={'tab'}
            id={this.id}
            tabindex={this.tabIndex}
            aria-selected={
              this.tab.localActive && !this.tab.disabled ? 'true' : 'false'
            }
            aria-setsize={this.setSize}
            aria-posinset={this.posInSet}
            aria-controls={this.controls}
            isActive={this.tab.localActive && !this.tab.disabled}
            disabled={this.tab.disabled}
            closeable={this.tab.isCloseable}
            pills={this.pills}
            inactive={!(this.tab.localActive && !this.tab.disabled)}
            tabActive={this.tab.localActive && !this.tab.disabled && !this.pills}
            top={this.top}
            left={this.left}
            right={this.right}
            bottom={this.bottom}
            onClick={this.handleEvt}
            onKeydown={this.handleEvt}
            headerRaised={this.headerRaised}
            iosPills={this.iosPills}
            buttonGroup={this.buttonGroup}
            keepInactiveBorder={this.keepInactiveBorder}
            mode={this.mode}
            smallerTabBorder={this.smallerTabBorder}
            inActiveBorderColor={this.inActiveBorderColor}
            tabsConfig={this.tabsConfig}
          >
            {[
              this.hasPrefixIcon && starIcon,
              <IndentationSpan
                id='indentationSpan1'
                titlePrefixIndent={true}
                hasPrefixIcon={this.hasPrefixIcon}
              ></IndentationSpan>,
              <TitleWrapSpan>
                {this.tab.normalizeSlot('title') || this.tab.title}
              </TitleWrapSpan>,
              <IndentationSpan
                id='indentationSpan2'
                titleSuffixIndent={true}
                hasPrefixIcon={this.hasPrefixIcon}
              ></IndentationSpan>,
              this.tab.isCloseable && closeIcon
            ]}
          </CustomCvtLink>
        )}

        {this.isBreadCrumbs == true && (
          <BreadcrumbsItem>
            
              <BreadcrumbsButtonWrap
                pills={ this.pills }
                active={this.tab.active}
                disabled={this.tab.disabled}
                onClick={this.handleEvt}
                specialMargin={this.specialMargin}
                specialPadding={this.specialPadding}
                stepCompleted={this.tab.stepCompleted}
              >
                {[
                  this.hasPrefixIcon && starIcon,
                  <BreadcrumbsTitleWrap>
                    { this.tab.normalizeSlot('title') || this.tab.title }
                  </BreadcrumbsTitleWrap>,
                  this.hasPostfixIcon && postIcon
                ]}
              </BreadcrumbsButtonWrap>

              {this.dividedBy == 'slash' && (              
                <BreadcrumbsDivisionWrap
                  isLastTab={this.isLastTab}
                > /
                </BreadcrumbsDivisionWrap>
              )}

              {this.dividedBy != 'slash' && (              
                <BreadcrumbsDivisionIcon
                  icon={ this.dividedBy }
                  isLastTab={this.isLastTab}
                >
                </BreadcrumbsDivisionIcon>
              )}

          </BreadcrumbsItem>
        )}
      </ListItem>
    )
  }
}
