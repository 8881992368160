
  <div class="uploader">
    <el-progress
v-show="filestack.upload.progress"
                 :percentage="filestack.upload.progress"
                 :status="filestack.upload.status"></el-progress>

    <el-upload
action="interceptedByHttpReqHandler"
               list-type="picture"
               :file-list="fileList"
               :http-request="upload"
               :on-remove="remove"
               :show-file-list="showFileList"
               :drag="showDragArea">

      <span>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
        <div slot="tip" class="el-upload__tip">jpg/png files with a size less than 10Mb</div>
      </span>
    </el-upload>
  </div>
