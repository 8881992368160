
<div>
  <h1>ItemList</h1>
  <div
    v-for="(listThumnailOrIcon, index) in listThumbnailIcon"
    :key="index"
  >
    <span>{{ index }}</span>
    <cvt-list
      :items="items"
      :remove-enabled="listThumnailOrIcon == 'thumbnail'"
      :thumbnails-enabled="listThumnailOrIcon == 'thumbnail'"
      :icons-enabled="listThumnailOrIcon == 'icon'"
      thumbnail-prop="thumbnail"
      title-prop="title"
      description-prop="description"
      fallback-icon="check"
      :fallback-thumbnail="'http://longwoodinteriors.com/wp-content/uploads/2014/04/placeholder3.png'"
      end-icon="check"
      :is-card="listThumnailOrIcon == 'thumbnail'"
      :show-separator="listThumnailOrIcon == 'thumbnail'"
      @remove="onRemove"
    >
      <template #noItemAvailable>
        <span>{{ customMessage }}</span>
      </template>
    </cvt-list>
  </div>
</div>
