<template>
  <div 
    v-if="!isGridBuilder" 
    class="l-breadcrumb" 
    @click.prevent.stop
  >
    <a
      v-for="node in nodeList"
      :key="node.vnodeId"
      @mouseover="target(node, $event)"
      @click="select(node, $event)"
      v-text="fmtName(node)"
    >
    </a>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import * as getters from '../../store/modules/getters'
import * as mutations from '../../store/modules/mutations'
import * as actions from '../../store/modules/actions'

import { ELEMENT_NAME_MAP } from '../../lib/helper-classes'

function synthMouseEvent(node) {
  return {
    preventDefault() {},
    stopPropagation() {},
    shiftKey: node.layoutElm,
  }
}

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      pageEngine: getters.LAYOUT_PAGE_ENGINE,
      children: getters.LAYOUT_CHILDREN,
      parent: getters.LAYOUT_PARENT,
      path: getters.LAYOUT_PATH,
      config: getters.AUTH_GET_USER_CONFIG,
      currentProductVersionNumber: getters.CURRENT_PRODUCT_VERSION_NUMBER,
      isGridBuilder: getters.IS_GRID_BUILDER,
    }),
    parents() {
      if(this.selected && Object.keys(this.selected).length){
        return this.selected.parents()
      }
      return []
    },
    nodeList() {
      return [...this.parents]
    },
  },
  methods: {
    ...mapMutations({
      updatePath: mutations.LAYOUT_UPDATE_PATH,
    }),
    ...mapActions({
      toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
      refreshLayout: actions.LAYOUT_REFRESH,
    }),
    fmtName(node) {
      return node.anchorName ? node.anchorName : ELEMENT_NAME_MAP[node.name]
    },
    select(node, event) {
      event.stopPropagation()
      event.preventDefault()
      node.select(synthMouseEvent(node))
    },
    target(node, event) {
      event.stopPropagation()
      event.preventDefault()
      node.target(synthMouseEvent(node))
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

.l-breadcrumb {
  margin: 5px 0px;
  min-height: 20px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  z-index: 1;
  background: transparent;
  font-size: 12px;
  transition: all 0.3s cubic-bezier(0.73, 0, 0.23, 0.99);

  a {
    display: block;
    float: left;
    cursor: pointer;
    background: $dark-gray;
    padding-right: 10px;
    height: 20px;
    margin-right: 21px;
    position: relative;
    text-decoration: none;
    color: $dark;

    &:last-of-type {
      margin-right: 0px;
    }

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      left: -20px;
      border: 10px solid transparent;
      border-color: $dark-gray;
      border-left-color: transparent;
    }

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      right: -20px;
      border: 10px solid transparent;
      border-left-color: $dark-gray;
    }

    &:first-of-type {
      padding-left: 15px;

      &:before {
        display: none;
      }
    }

    &:hover {
      background: $dark;
      color: $white !important;
      text-decoration: none;

      &:before {
        border-color: $dark;
        border-left-color: transparent;
      }

      &:after {
        border-left-color: $dark;
      }
    }
    &:last-child {
      background: $slate;
      color: #fff;
      text-decoration: none;

      &:before {
        border-color: $slate;
        border-left-color: transparent;
      }

      &:after {
        border-left-color: $slate;
      }
    }
  }
}
</style>
