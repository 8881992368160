
<div class="d-flex h-100" style="overflow: auto">
  <cvt-pages
    :pages="pages"
    :active-id="activePageId"
    :is-admin="isAdmin"
    :is-template="isTemplate"
    :is-site-preview="isSitePreview"
    @edit="(e) => changePage(e)"
  />
</div>
