
  <div>
    <cvt-collapse
      :accordion="true"
      :theme="mode === 'LIGHT' ? 'light' : 'dark'"
      :prepend-chevron="prependChevron"
    >
      <cvt-collapse-item title="Style" name="style" :initial-active="true">
        <div class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t('elements.properties.background.color') }}
          </div>
          <div class="col-12">
            <colors alpha :mode="mode" />
          </div>
        </div>
        <div class="divider mt-2" />
        <div class="nestedInnerItem">
          <cvt-select
            :value="borderStyle"
            mode="dropdown"
            :background-mode="mode"
            :floating-label="false"
            :label="$t('elements.properties.border.style')"
            @input="(value) => selectAction(value, 'borderStyle')"
          >
            <option
              v-for="style in borderStyles"
              :key="style"
              :value="style"
              v-text="style"
            />
          </cvt-select>
          <div style="margin: 5px" />
          <cvt-input
            v-model="borderColor"
            style="width: max-content"
            type="text"
            :clearable="false"
            :label="$t('elements.properties.border.color')"
          />
        </div>

        <div class="divider mt-2" />

        <div class="row">
          <div class="col-md-12 d-flex align-items-center text-capitalize">
            {{ $t('elements.properties.border.position') }}
          </div>
        </div>

        <div class="row">
          <div class="col">
            <cvt-radio-group
              :key="borderPositionKey"
              orientation="horizontal"
              :initial-value="positionDefVal"
            >
              <cvt-radio-button
                :value="pv.all"
                :disabled="disableAllBorder"
                type="multiselect"
                @radioClick="($event) => updatePosition($event, 'all')"
              >
                <cvt-icon icon="border-all"></cvt-icon>
              </cvt-radio-button>
              <cvt-radio-button
                :value="pv.top"
                type="multiselect"
                @radioClick="($event) => updatePosition($event, 'top')"
              >
                <cvt-icon icon="border-top"></cvt-icon>
              </cvt-radio-button>
              <cvt-radio-button
                :value="pv.btm"
                type="multiselect"
                @radioClick="($event) => updatePosition($event, 'btm')"
              >
                <cvt-icon icon="border-bottom"></cvt-icon>
              </cvt-radio-button>
              <cvt-radio-button
                :value="pv.lft"
                type="multiselect"
                @radioClick="($event) => updatePosition($event, 'lft')"
              >
                <cvt-icon icon="border-left"></cvt-icon>
              </cvt-radio-button>
              <cvt-radio-button
                :value="pv.ryt"
                type="multiselect"
                @radioClick="($event) => updatePosition($event, 'ryt')"
              >
                <cvt-icon icon="border-right"></cvt-icon>
              </cvt-radio-button>
            </cvt-radio-group>
          </div>
        </div>

        <div class="divider mt-2" />

        <div class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t('elements.properties.border.width') }}
          </div>
          <div class="col-12">
            <cvt-slider
              id="slider-default"
              :tooltips-enabled="true"
              :step-marks-enabled="true"
              class="slider"
              :min="1"
              :max="10"
              :value="borderWidth"
              :step="1"
              @change="onChange"
            />
          </div>
        </div>
        <div class="divider mt-2" />

        <div class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t('elements.properties.border.radius') }}
          </div>
          <div class="col-12">
            <resize
              size-key="borderRadius"
              :min="0"
              :step="5"
              :max="100"
              :label="$t('elements.properties.border.radius')"
              hide-label
            />
          </div>
        </div>
        <div class="divider mt-2" />

        <div class="row">
          <div class="col">
            <h6>{{ $t('elements.properties.shadow.label') }}</h6>
          </div>
          <div class="col-12">
            <cvt-radio-group orientation="horizontal" :initial-value="shadow">
              <cvt-radio-button
                size="md"
                value="shadow-none"
                :shape="RadioButtonShape"
                @radioClick="updateImgShadow"
              >
                {{ $t('elements.properties.option.none') }}
              </cvt-radio-button>
              <cvt-radio-button
                size="md"
                value="shadow"
                :shape="RadioButtonShape"
                @radioClick="updateImgShadow"
              >
                {{ $t('elements.properties.shadow.normal') }}
              </cvt-radio-button>
              <cvt-radio-button
                size="md"
                value="shadow-lg"
                :shape="RadioButtonShape"
                @radioClick="updateImgShadow"
              >
                {{ $t('elements.properties.shadow.deep') }}
              </cvt-radio-button>
            </cvt-radio-group>
          </div>
        </div>
      </cvt-collapse-item>

      <!-- Implement background image option -->
      <cvt-collapse-item
        :title="$t('elements.properties.background.image')"
        name="bgImage"
      >
        <element-image-manager
          image-key="background/image"
          :hook="selected.updateBgSrc"
          :background-img-position="backgroundPosition"
          :can-change-postion="true"
          @backgroundImgPositionChanged="onBgImgPositionChanged"
        />

        <background-size-repeat> </background-size-repeat>

        <div class="divider mt-2" />
        <background-color-overlay :mode="mode"> </background-color-overlay>
      </cvt-collapse-item>

      <cvt-collapse-item
        :title="$t('elements.properties.spacing.label')"
        name="spacing"
      >
        <spacing-ctrl />
      </cvt-collapse-item>

<!--      <cvt-collapse-item-->
<!--        :title="$t('elements.properties.visibility.label')"-->
<!--        name="visibility"-->
<!--      >-->
<!--        <visibility-ctrl :mode="mode" />-->
<!--      </cvt-collapse-item>-->
    </cvt-collapse>
  </div>
