
<div class="">
  <div class="row mb-1">
    <div class="col">
      <strong
        >{{ $t('launch.tracking.head') }} <code>&lt;head&gt;</code></strong
      >
      <ace
        ref="trackHead"
        lang="html"
        :code-snippet-html="tracking.head"
        @change="trackHead"
      />
    </div>
  </div>
  <div class="row mb-1">
    <div class="col">
      <strong>{{ $t('launch.tracking.opening_body') }}</strong>
      <ace
        ref="trackAfterOpeningBody"
        lang="html"
        :code-snippet-html="tracking.afterOpeningBody"
        @change="trackAfterOpeningBody"
      />
    </div>
  </div>
  <div class="row mb-1">
    <div class="col">
      <strong>{{ $t('launch.tracking.closing_body') }}</strong>
      <ace
        ref="trackBeforeClosingBody"
        lang="html"
        :code-snippet-html="tracking.beforeClosingBody"
        @change="trackBeforeClosingBody"
      />
    </div>
  </div>
</div>
