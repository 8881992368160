const GEN_AI_ONBOARDING_KEY = 'genAIOnboarding'
const GEN_AI_TEMPLATE = 'genAIOnboardingTemplate'

export const setSessionGenAiOnboarding = (key, value) => {
  let newGenAIOnboarding = {}
  let genAIOnboarding = sessionStorage.getItem(GEN_AI_ONBOARDING_KEY)
  genAIOnboarding = genAIOnboarding ? JSON.parse(genAIOnboarding) : null

  if (genAIOnboarding) {
    newGenAIOnboarding = {
      ...genAIOnboarding,
      [key]: value,
    }
  } else {
    newGenAIOnboarding = {
      [key]: value,
    }
  }
  sessionStorage.setItem(
    GEN_AI_ONBOARDING_KEY,
    JSON.stringify(newGenAIOnboarding),
  )
}


export const getSessionGenAIOnboarding = () => {
  let genAIOnboarding = sessionStorage.getItem(GEN_AI_ONBOARDING_KEY)
  genAIOnboarding = genAIOnboarding ? JSON.parse(genAIOnboarding) : null

  return genAIOnboarding
}

export const getSessionItemGenAIOnboarding = (key) => {
  let genAIOnboarding = sessionStorage.getItem(GEN_AI_ONBOARDING_KEY)
  genAIOnboarding = genAIOnboarding ? JSON.parse(genAIOnboarding) : {}

  return genAIOnboarding[key]
}


export const setGenAITemplate = (value) => {
  sessionStorage.setItem(GEN_AI_TEMPLATE, value)
}

export const getGenAITemplate = () => {
  return sessionStorage.getItem(GEN_AI_TEMPLATE)
}


export const GenAIIntent = [
  {
    label: 'Pre-sale',
    value: 'pre-sale',
    goals: [
      { label: 'Get More Subscription Purchases', value: 'get-more-subscription-purchases' },
      { label: 'Get More Quote Starts', value: 'get-more-quote-starts' },
      { label: 'Get More Trial Signups', value: 'get-more-trial-signups' },
      { label: 'Launch My Business', value: 'launch-my-business' },
      { label: 'Run Events or Webinars', value: 'run-events-or-webinars' },
    ]
  },
  {
    label: 'During-sale',
    value: 'during-sale' ,
    goals: [
      { label: 'Sell Your E-book', value: 'sell-your-ebook' },
      { label: 'Promote a New Product', value: 'promote-a-new-product' },
      { label: 'Offer Upsell', value: 'offer-upsell' },
      { label: 'Drive App. Downloads', value: 'drive-app-downloads' },
    ]
  },
  {
    label: 'Post-sale',
    value: 'post-sale',
    goals: [
      { label: 'Engage Existing Customers', value: 'engage-existing-customers' },
      { label: 'Gather Feedback', value: 'gather-feedback' },
      { label: 'Encourage Referrals', value: 'encourage-referrals' },
      { label: 'Offer Support', value: 'offer-support' },
    ]
  },
]

export const genAIIntentList = GenAIIntent.map(intent => intent.value);

export const genAIIntentGoalList = GenAIIntent.reduce((goals, intent) => {
  intent.goals.forEach(goal => {
    goals.push(goal.value);
  });
  return goals;
}, []);