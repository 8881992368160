<template>
  <div
    :id="vnodeId"
    ref="subGridWrapper"
    data-identity="SubGridContainer"
    :data-vnode-id="vnodeId"
    :class="classes"
    @click="onClick"
    @mouseenter="mouseOverAction"
    @mouseleave="mouseLeaveAction"
  >
    <div :class="subContainerBgImage" />
    <div :class="subContainerBgColor" />

    <div class="section-drag-items-wrap">
      <cvt-section-drag-items
        v-if="vnodeId"
        class="drag-items-sub-container"
        :exclude-item="['container', 'card_element']"
        usage="sub-grid-container"
        :show-plus-btn="true"
        :vnode-id="vnodeId"
        :widget-class="'grid-stack-item grid-stack-item-sub'"
      />
    </div>
    <div  v-show="fbNode.children.length === 0 && !backgroundImgExist" :class="stylesheet.classes.bgEmpty" />
    <div class="w-100" :class="containerClasses">
      <div
          :id="`${vnodeId}-sub-grid`"
          ref="gridRootWrapper" class="position-relative sub-grid-visual-box">
        <visual-box
            ref="vsBox"
            box-color="#333333"
            :grid-column="column"
            :grid-column-height="cellHeight"
            :grid-v-node-id="`${vnodeId}-sub-grid`"/>
        <div
            ref="nestedGridStackElement"
            :style="fontColorBG"
            class="grid-stack"
            @mousedown="subContainerMouseDown"
        >
          <template v-for="node in fbNode.children">
            <grid-item-v3
                :key="node.data.props.vnodeId"
                :fb-node="node"
                v-bind="node.data.props"
                class="grid-stack-item-sub"
                @removeElement="removeNode"
                @duplicateElement="duplicateNode"
                @forceRemoveElement="forceRemoveNode"
            >
            </grid-item-v3>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AddCommand } from '../../../../commands/AddCommand'
import SubGridContainer from '../../../editor/nodes/SubgridContainer/script.ts'
import FirebaseNodeV3 from '../../base/FirebaseNode'
import GridBuilderMixin from '../../base/GridBuilderMixin'
import {BulkPropertyChangeCommand} from "@/js/commands/BulkPropertyChangeCommand";

export default SubGridContainer.extend({
  name: 'SubGridContainerV3',
  mixins: [FirebaseNodeV3, GridBuilderMixin],

  computed: {
    backgroundImgExist() {
      return (
        this.fbNode?.data?.props?.background?.image != null &&
        this.fbNode?.data?.props?.background?.image !== ''
      )
    },
    containerClasses() {
      return {
        [this.stylesheet.classes.content]: true,
        ['alt-text-color']: this.isBackgroundColorDark,
      }
    }
  },
  methods: {
    getGrid() {
      return this.$refs.nestedGridStackElement?.gridstack
    },
    addGridItem(vNode, options) {
      if (['grid-box-container', 'sub-grid-container'].includes(vNode.tag)) {
        return
      }
      if (['c-form'].includes(vNode.tag)) {
        this.setPageId(vNode)
      }
      let widget = this.wrapInGridItemWrapper(vNode, options)
      this.executeCommand(new AddCommand(this.vnodeId, widget))
    },
    onCommandActionExecuted() {
      setTimeout(()=> {
        this.resizeSubGridHeight()
      }, 300)
    },
    reloadContent() {
      const children = [...this.$refs.nestedGridStackElement.children]
      children.forEach((child) => {
        this.makeWidget(child)
      })
    },
    registerObserver() {

    },
    subContainerMouseDown(event) {
      if(event.target === this.$refs.nestedGridStackElement) {
        this.advancedMenuToggle(true)
        this.target(event)
        this.mutateSelected(this)

        event.preventDefault()
        event.stopPropagation()
      }
    },
    mouseOverAction (event) {
      this.$parent.$parent.$el.classList.add('hide-grid-lines')
      this.$refs.vsBox.$el.classList.add('show-grid')
    },
    mouseLeaveAction (event) {
      this.$parent.$parent.$el.classList.remove('hide-grid-lines')
      this.$refs.vsBox.$el.classList.remove('show-grid')
    },
    resizeSubGridHeight () {
      // Fits subgrid container height to content height
      this.$nextTick(() => {
        const gridItemWrapper = this.parent()
        const rootGridBuilder = this.parent().parent()

        const ofHeight = this.$refs.nestedGridStackElement.offsetHeight + (this.getGrid().opts.cellHeight * 2)
        const height = Math.round(
            (ofHeight + (rootGridBuilder.getGrid().opts.verticalMargin || 0)) / ((rootGridBuilder.getGrid().getCellHeight() + (rootGridBuilder.getGrid().opts.verticalMargin || 0)) || 1)
        )
        if (!rootGridBuilder.itemResizing && !this.itemResizing) {
          // create a command and execute it outside command manager stack
          // because we don't want to track it
          const command = this.createCommand(
              this.viewMode,
              Object.assign(gridItemWrapper.$props[this.viewMode], {
                dataX: gridItemWrapper.dataX,
                dataY: gridItemWrapper.dataY,
                dataWidth: gridItemWrapper.dataWidth,
                dataHeight: height,
              }),
              gridItemWrapper.getVNodeID()
          )
          command.execute()
        }
      })
    },
    resizeChildComponent () {
      return new Promise(resolve => {
        const getGridContainerVueElements = this.getGridContainerVueElements()
        this.unRegisterGridStackEvents()
        const bulkChangeCommand = new BulkPropertyChangeCommand(this.getVNodeID())

        const inlineHandler = (e, items) => {
          this.changeEventHandler(e, items, bulkChangeCommand)
        }
        this.getGrid().on('change', inlineHandler)
        for (const x of getGridContainerVueElements) {
          if (x.name === 'GridItemWrapper') {
            const wrappedElement = x.getWrappedElement()
            if (wrappedElement.name === 'CButton') {
              continue
            }
            wrappedElement.resize(true)
          }
        }
        this.getGrid().off('change')

        bulkChangeCommand.execute().then(() => {
          this.arrangeNodeInYAscendingValue().then(() => {
            setTimeout(() => {
              this.mobileElementFloatFix().then(() => {
                setTimeout(() => {
                  this.registerGridStackEvents()
                  resolve()
                }, 400)
              })
            }, 200)
          })
        })
      })
    },
    initGridBuilder({reload=false, skipEvent=false}) {
      this.initGridBuilderHandler({reload, skipEvent})
    },
    fixedChangesInCellSize(prevWidget, fbNode) {
      if(!prevWidget.el.classList.contains('block-grid')) {
          // Means that Item was dragged from sub-container into main grid
          fbNode.data.props.desktop.dataHeight = fbNode.data.props.desktop.dataHeight * 2
          fbNode.data.props.desktop.dataWidth = fbNode.data.props.desktop.dataWidth * 2
      }
    },
  },
})
</script>
