<template>
  <div :id="vnodeId" ref="gridRootWrapper" :data-vnode-id="vnodeId" class="position-relative visual-box" @mouseenter="mouseOverAction" @mouseleave="mouseLeaveAction">
    <visual-box
        ref="vsBox" :grid-column="column"
        box-color="#e9e9e98c"
        :grid-column-height="cellHeight"
        :grid-v-node-id="vnodeId"/>
    <div
        :key="'grid-stack-' + vnodeId + viewMode"
        ref="gridElement"
        :class="gridClassName"
        @mousedown.stop="" 
        @mouseover.stop=""
    >

      <div v-if="parent().parent().name === 'CPopupBuilder' && isEmpty" class="d-flex justify-content-center">
        <div class="popup-default-box" >
          <!-- The enlarged Icon -->
          <span class="popup-default-icon cvt-icon-www-site-arrow-cursor"></span>
          <!-- Select Popup Style text under the Icon -->
          <span class="popup-default-text">Select Popup Style</span>
          <!-- Button to open up Pre Made Popup Options on click -->
          <cvt-button
class="popup-default-button" :icon="'arrow-right'" :prepend="false"
                      :text="'Select Pre-Made Popup'" @click="click($event)"></cvt-button>
        </div>
      </div>
      <div
v-if="!hasItemsInSlot && parent().parent().name !== 'CPopupBuilder'"
           class="empty-section-placeholder d-flex justify-content-center align-items-center">
        <!-- Internal dashed box -->
      </div>

      <!--      <div :class="isEmpty ? 'button-centre' : ''">-->
      <!--        <add-element-popover-->
      <!--            :is-empty="isEmpty"-->
      <!--            :btn-id="`add-element-${vnodeId}`"-->
      <!--            @add-item="(id) => addItem(id, `add-element-${vnodeId}`)"-->
      <!--        />-->
      <!--      </div>-->
      <slot />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {
  GridItemWrapper,
  CImage,
  CButton,
  Draft,
  Divider,
  CForm,
  Icon,
  CVideo,
  BrandLogo,
  CodeSnippet,
  SubGridContainer,
  CSurvey,
  CountdownTimer,
  Checkout,
} from '../../../lib/helper-classes'
import FbVNode from '../../base/FbVNode.vue'
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import AddElementPopover from './AddElementPopover.vue'
import * as _ from 'lodash'
import {GridStack} from "gridstack";
import { ELEMENT_DROPPED_ON_GRID_CONTAINER } from '@/js/plugins/events.js'
import BuilderVisualBox from "./BuilderBox/VisualBox.vue"
import { CCard } from "@/js/lib/custom-component-descriptors/ccard";
import { CEmbeddedForm } from "@/js/lib/custom-component-descriptors/cembedded-form";

export default FbVNode.extend({
  props: {},
  name: 'GridContainer',
  components: {
    AddElementPopover,
    'visual-box': BuilderVisualBox
  },
  data () {
    return {
      hasItemsInSlot: false,
      cellHeight: 24,
      column: 24,
      options: {
        alwaysShowResizeHandle:
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            ),
        resizable: {
          handles: 'e, se, s, sw, w, n, ne, nw',
        },
        removeTimeout: 100,
        removable: '#trash',
        float: true,
        dragIn: '.newWidget',
        dragInOptions: {
          revert: 'invalid',
          scroll: false,
          appendTo: 'body',
          helper: 'clone',
          handle: '.grid-stack-item-content',
        },
        styleInHead: true,
        placeholderClass: 'cvt-grid-stack-placeholder',
        disableOneColumnMode: true,
        staticGrid: false,
        margin: 0
      },
      className: 'grid-stack',
      itemResizing: true,
    }
  },
  computed: {
    ...mapGetters({
      advancedMenuOpen: getters.ADVANCED_MENU_OPEN,
      viewMode: getters.VIEW_MODE,
      isEmpty: getters.POPUP_EMPTY,
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    ...mapState('globalTheme', {
      elementDesktopSize: ({ elementSize }) => elementSize.gridStackDefaultSizeForDesktop,
      elementPhoneSize: ({ elementSize }) => elementSize.gridStackDefaultSizeForPhone,
      Colors: ({ globalTheme }) => globalTheme.Colors,
    }),
    gridClassName () {
      return this.className + ' ' + `grid-stack-${this.vnodeId}`
    },
  },
  watch: {
    viewMode (newVal) {
      this.onViewModeChanged()
    },
  },
  beforeUpdate () {
    try {
      this.unRegisterGridStackEvents()
    } catch (e) {
      console.debug(e)
    }
  },
  updated () {
    setTimeout(() => {
      this.initGridBuilderHandler({reload: false, skipEvent: false})
    }, 400)
    // GridStack.setupDragIn('.newWidget', { revert: 'invalid', scroll: false, appendTo: 'body', helper: 'clone', handle: '.grid-stack-item-content' })
  },
  mounted () {
    this.$nextTick(() => {
      this.onViewModeChanged()
      this.initGridBuilderHandler({reload: true, skipEvent: false})
      // GridStack.setupDragIn('.grid-stack-item-main', { revert: 'invalid', scroll: false, appendTo: 'parent', helper: 'clone', handle: '.grid-stack-item-content' })
    })

  },
  methods: {
    ...mapActions({
      toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
    }),
    initGridBuilderHandler({reload=false, skipEvent=false}) {
      const blockKey = this.$parent.blockKey
      const gridOptions = Object.assign({}, this.options, {
        acceptWidgets: `.grid-stack-item-main`,
        dragInOptions: {
          revert: 'invalid',
          scroll: false,
          appendTo: `[data-key-id='${blockKey}']`,
          helper: 'clone',
          handle: '.grid-stack-item-content'
        },
        cellHeight: this.cellHeight,
        column: this.column,
        staticGrid: this.config.GRID_BUILDER_STATIC_MODE_ENABLED || false
      })

      this.grid = GridStack.init(gridOptions, `.grid-stack-${this.vnodeId}`)
      this.grid.rootComponent = this.$refs.gridRootWrapper
      // // eslint-disable-next-line no-undef
      // $(this.$el).droppable({
      //   disabled: true,
      // })
      this.$refs.gridElement?.style?.setProperty(
          '--grid-cell-height',
          gridOptions.cellHeight + 'px'
      )

      this.$refs.gridElement?.style?.setProperty(
          '--grid-item-bg-color',
          this.Colors.SUCCESS[100]
      )
      this.$refs.gridElement?.style?.setProperty(
          '--grid-item-border-color',
          this.Colors.SUCCESS[500]
      )
      this.itemResizing = false

      if (reload) {
        const observer = new MutationObserver(this.onElChange)
        observer.observe(this.$el, {
          childList: true,
          subtree: true
        })
        this.observer = observer
      }
      if (!skipEvent) {
        this.registerGridStackEvents()
      }
    },
    getGrid () {
      return this.grid
    },
    getDefaultSize (elementId, viewMode = 'desktop') {
      const elementSize = viewMode === 'desktop' ? this.elementDesktopSize : this.elementPhoneSize
      return elementSize[elementId]
    },
    onViewModeChanged () {
      // this.getGrid().column(12);
      // const width = this.getGrid().cellWidth();
      // this.getGrid().cellHeight(width);
      if (this.viewMode === 'phone') {
        this.cellHeight = 8
        this.column = 8
      }else {
        this.cellHeight = 24
        this.column = 24
      }
      if (this.getGrid()) {
        this.getGrid()._updateStyles()
      }
      // this.$mount()
    },
    wrapInGridItemWrapper (vnode, options) {
      let gridItem = new GridItemWrapper(options)
      gridItem.children.push(vnode)
      return gridItem
    },
    dragStart (event, el) {
      this.$refs.gridElement.classList.add('show-grid')
      this.getGrid().engine.setIsDragging(true)
    },
    dragStop (event, el) {
      this.$refs.gridElement.classList.remove('show-grid')
      this.getGrid().engine.setIsDragging(false)

      this.postDragStopActions(event, el)
    },
    gridItemRemoved (e, items) {
      console.debug('Item Removed', e, items)
    },
    change: _.debounce(function (e, items) {
      if (items.length > 0) {
        items.forEach((changedItem) => {
          const vNodeID = changedItem.el.dataset.vnodeId || 0
          this.$children.forEach((child) => {
            if (child.vnodeId === vNodeID) {
              child.updateProp(
                  this.viewMode,
                  Object.assign(child.$props[this.viewMode], {
                    dataX: changedItem.x,
                    dataY: changedItem.y,
                    dataWidth: changedItem.w,
                    dataHeight: changedItem.h,
                  })
              )

              if (
                  child.$children.length > 0 &&
                  child.$children[0].name === 'Icon'
              ) {
                const remValue = Math.floor(
                    (child.$el.offsetWidth * 1) / 12.48
                )
                child.$children[0].updateProp('size', remValue)
              }

              setTimeout(() => {
                child.triggerResizeChild()
              }, 0)
            }
          })
        })
      }
    }, 500),
    added (e, items) {
      console.debug('added', e, items);
    },
    fixOptions(options, viewMode){
      return options
    },
    dropped (e, prevWidget, newWidget) {
      // if drop is coming from dragItem, then ~ is a separator key for getting the element_id
      if (prevWidget && newWidget) {
          this.handleDragFromAnotherSource(prevWidget, newWidget)
          return
      }
      const newElementId = newWidget.el.id.toString().split('~')[0]
      const defaultElementSize = this.getDefaultSize(`${newElementId}`)
      const defaultMobileElementSize = this.getDefaultSize(`${newElementId}`, 'phone')

      const desktopOptions = {
        dataX: newWidget.x,
        dataY: newWidget.y,
        dataWidth: defaultElementSize.w || 1,
        dataHeight: defaultElementSize.h || 1,
        flexHorizonAlign: 'justify-content-center',
        flexVerticalAlign: 'align-items-center',
      }
      let mobileOptions = Object.assign({}, desktopOptions)
      mobileOptions.dataHeight = defaultMobileElementSize.h || 1
      mobileOptions.dataWidth = defaultMobileElementSize.w || 1

      const opts = {
        phone: mobileOptions,
        desktop: desktopOptions
      }
      // this is fix item positioning on view mode that is not activate
      const alternatedViewMode = this.getAlternatedViewMode()
      opts[alternatedViewMode] = this.fixOptions(opts[alternatedViewMode], alternatedViewMode)
      opts[alternatedViewMode].dataX = 0  // set X co-ordinate to 0 for view mode that is not activate

      const vNode = this.getElementFromId(newElementId)
      this.addGridItem(vNode, opts)

      // Remove cloned element from grid
      this.getGrid().removeWidget(newWidget.el)

      // $hub is event-bus
      this.$hub.$emit(ELEMENT_DROPPED_ON_GRID_CONTAINER, { data: newWidget })
      this.getGrid().engine.setIsDragging(false)
    },
    addItem (id, btnId) {
      // ELEMENT_SIZE - from Spanner config
      // For Width and Height
      document.getElementById(btnId).click()
      const defaultElementSize = this.getDefaultSize(`${id}`)
      const defaultMobileElementSize = this.getDefaultSize(`${id}`, 'phone')

      const desktopOptions = {
        dataX: 0,
        dataY: Math.ceil(this.getGridHeight() / this.getGridCellHeight()),
        dataWidth: defaultElementSize.w || 1,
        dataHeight: defaultElementSize.h || 1,
        flexHorizonAlign: 'justify-content-center',
        flexVerticalAlign: 'align-items-center',
      }

      let mobileOptions = Object.assign({}, desktopOptions)
      mobileOptions.dataHeight = defaultMobileElementSize.h || 1
      mobileOptions.dataWidth = defaultMobileElementSize.w || 1

      const opts = {
        phone: mobileOptions,
        desktop: desktopOptions,
      }

      const vNode = this.getElementFromId(id)
      this.addGridItem(vNode, opts)
    },
    addGridItem (vNode, options) {
      if (['grid-box-container', 'sub-grid-container', 'card_element'].includes(vNode.tag)) {
        options.desktop.flexHorizonAlign = options.desktop.flexVerticalAlign = null;
        options.phone.flexHorizonAlign = options.phone.flexVerticalAlign = null;
      }
      // this function set some default values for flex-alignment using
      // config object that config object is set via configDefaults table
      // as well as organizationsConfig table, organizationConfig table entry
      // overrides the entry of configDefaults table
      this.setOrgSpecificDefaults(vNode, options);

      let widget = this.wrapInGridItemWrapper(vNode, options)
      this.push(widget).then((a) => {
        // getGrid().makeWidget - converts an HTML to a widget
        const gridItemWrapper = this.$children[this.$children.length - 1]
        this.getGrid().makeWidget(gridItemWrapper.$el)
        gridItemWrapper.$el.scrollIntoView({
          behavior: 'smooth',
        })
        this.$nextTick(()=> {
          if (gridItemWrapper.resize) {
            gridItemWrapper.resize()
          }
        })
      })
    },
    getGridHeight () {
      return this.getGrid().el.getBoundingClientRect().height
    },
    getGridCellHeight () {
      return this.getGrid().getCellHeight()
    },
    resizeStart (e, el) {
      this.getGrid().engine.setIsDragging(false)
      this.itemResizing = true
      this.$refs.gridElement.classList.add('show-grid')
    },
    resizeStop (e, el) {
      this.itemResizing = false
      this.$refs.gridElement.classList.remove('show-grid')
    },
    onElChange (records) {
      this.hasItemsInSlot = !!this.$slots.default
    },
    getElementFromId (id) {
      let element = null

      switch (id) {
        case 'text_element':
          element = new Draft()
          break
        case 'button_element':
          element = new CButton()
          break
        case 'image_element':
          element = new CImage()
          break
        case 'divider_element':
          element = new Divider()
          break
        case 'form_element':
          element = new CForm()
          break
        case 'card_element':
          element = new CCard()
          break
        case 'icon_element':
          element = new Icon()
          break
        case 'video_element':
          element = new CVideo()
          break
        case 'logo_element':
          element = new BrandLogo()
          break
        case 'code_element':
          element = new CodeSnippet()
          break
        case 'container_element':
          element = new SubGridContainer()
          break
        case 'countdown_timer_element':
          element = new CountdownTimer()
          break
        case 'checkout_element':
          element = new Checkout()
          break
        case 'embed_form_element':
          element = new CEmbeddedForm()
          break
        case 'survey_element':
          element = new CSurvey()
          // eslint-disable-next-line no-fallthrough
        default:
          break
      }

      return element
    },
    // Still WIP, Method for fixing cell height issue
    recalculateHeight () {
      setTimeout(() => {
        // console.log('recalc ', this);
        this.getGrid().cellHeight('auto')
        this.getGrid().cellHeightThrottle?.(300)
        // this.grid.column(12)
        // const width = this.grid.cellWidth()
        // this.grid.cellHeight(width)
        // this.grid._updateStyles()
        // this.$refs.gridElement.style.setProperty('--grid-cell-height', width + 'px')
        // this.grid.update(this.$el, this.options)
      }, 2500)
    },
    click (event) {
      event.stopPropagation()
      this.toggleLeftSideMenu({
        open: true,
        activate: 'popups'
      })
    },
    registerGridStackEvents(){
      if (this.getGrid()) {
        this.getGrid().on('dragstop', this.dragStop)
        this.getGrid().on('dragstart', this.dragStart)

        this.getGrid().on('change', this.change)
        this.getGrid().on('added', this.added)

        this.getGrid().on('dropped', this.dropped)
        this.getGrid().on('resizestart', this.resizeStart)

        this.getGrid().on('removed', this.gridItemRemoved)
        this.getGrid().on('resizestop', this.resizeStop)
      }
    },
    unRegisterGridStackEvents(){
      if (this.getGrid()) {
        this.getGrid().off('dragstop')
        this.getGrid().off('dragstart')

        this.getGrid().off('change')
        this.getGrid().off('added')

        this.getGrid().off('dropped')
        this.getGrid().off('resizestart')

        this.getGrid().off('removed')
        this.getGrid().off('resizestop')
      }
    },
    setOrgSpecificDefaults(vNode, options) {
      try {

        const defaults = this.config.FLEX_ALIGNMENT_DEFAULTS;
        
        if(defaults?.image && vNode.tag === 'c-image')
        {
          options.desktop.flexHorizonAlign = defaults?.image?.desktop?.flexHorizonAlign || 'justify-content-start';
          options.desktop.flexVerticalAlign = defaults?.image?.desktop?.flexVerticalAlign || 'align-items-start';
  
          options.phone.flexHorizonAlign = defaults?.image?.phone?.flexHorizonAlign || 'justify-content-start';
          options.phone.flexVerticalAlign = defaults?.image?.phone?.flexVerticalAlign || 'align-items-start';
        }
        else if(defaults?.logo && vNode.tag === 'brand-logo')
        {
          options.desktop.flexHorizonAlign = defaults?.logo?.desktop?.flexHorizonAlign || 'justify-content-center';
          options.desktop.flexVerticalAlign = defaults?.logo?.desktop?.flexVerticalAlign || 'align-items-center';
  
          options.phone.flexHorizonAlign = defaults?.logo?.phone?.flexHorizonAlign || 'justify-content-center';
          options.phone.flexVerticalAlign = defaults?.logo?.phone?.flexVerticalAlign || 'align-items-center';
        }
        else if(defaults?.icon && vNode.tag === 'icon')
        {
          options.desktop.flexHorizonAlign = defaults?.icon?.desktop?.flexHorizonAlign || 'justify-content-center';
          options.desktop.flexVerticalAlign = defaults?.icon?.desktop?.flexVerticalAlign || 'align-items-center';
  
          options.phone.flexHorizonAlign = defaults?.icon?.phone?.flexHorizonAlign || 'justify-content-center';
          options.phone.flexVerticalAlign = defaults?.icon?.phone?.flexVerticalAlign || 'align-items-center';
        }
        else if(defaults?.form && vNode.tag === 'c-form')
        {
          options.desktop.flexHorizonAlign = defaults?.form?.desktop?.flexHorizonAlign || 'justify-content-center';
          options.desktop.flexVerticalAlign = defaults?.form?.desktop?.flexVerticalAlign || 'align-items-center';
  
          options.phone.flexHorizonAlign = defaults?.form?.phone?.flexHorizonAlign || 'justify-content-center';
          options.phone.flexVerticalAlign = defaults?.form?.phone?.flexVerticalAlign || 'align-items-center';
        }
        else if(defaults?.text && vNode.tag === 'draft') 
        {
          options.desktop.flexHorizonAlign = defaults?.text?.desktop?.flexHorizonAlign || 'justify-content-start';
          options.desktop.flexVerticalAlign = defaults?.text?.desktop?.flexVerticalAlign || 'align-items-center';

          options.phone.flexHorizonAlign = defaults?.text?.phone?.flexHorizonAlign || 'justify-content-start';
          options.phone.flexVerticalAlign = defaults?.text?.phone?.flexVerticalAlign || 'align-items-center';
        }
      } catch(e) {
        console.debug('Error In Setting Defaults To Elements', e);
      }
    },
    mouseOverAction(event) {
      this.$refs.vsBox.$el.classList.add('show-grid')
    },
    mouseLeaveAction(event) {
      this.$refs.vsBox.$el.classList.remove('show-grid')
    },
    postDragStopActions(event, el) {

        const gridItemWrapperVueInstance = el.__vue__
        const wrapperElementVueInstance = gridItemWrapperVueInstance && gridItemWrapperVueInstance.getWrappedElement()

        if (gridItemWrapperVueInstance && wrapperElementVueInstance) {

            let elementName = wrapperElementVueInstance.name

            if (elementName === 'Draft') {
                wrapperElementVueInstance.focusAndEditFroalaText()
                wrapperElementVueInstance.refreshFroalaButtons()
            } else {
                // Define other elemets related actions here after defining if-else condition
            }
        } else {
            throw new Error('Something went wrong in postDragStopActions method')
        }
    },
    handleDragFromAnotherSource(prevWidget, newWidget) {
       console.debug('Dragging from Another source out DragItem is Not implemented for V2 Builder')
    }
  },
  created () {},
  beforeUnmount () {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  beforeDestroy() {
    this.grid?.destroy()
  }
})
</script>

<style lang='scss'>
.grid-stack {
  > .grid-stack-item {
    $gridstack-columns: 24;
    min-width: (100% / $gridstack-columns);

    @for $i from 1 through $gridstack-columns {
      &[gs-w='#{$i}'] {
        width: (100% / $gridstack-columns) * $i;
      }
      &[gs-x='#{$i}'] {
        left: (100% / $gridstack-columns) * $i;
      }
      &[gs-min-w='#{$i}'] {
        min-width: (100% / $gridstack-columns) * $i;
      }
      &[gs-max-w='#{$i}'] {
        max-width: (100% / $gridstack-columns) * $i;
      }
    }
  }
  &.grid-stack-1>.grid-stack-item {
    min-width: 100%;
    &[gs-w='1'] { width: 100%; }
    &[gs-x='1'] { left: 100%; }
    &[gs-min-w='1'] { min-width: 100%; }
    &[gs-max-w='1'] { max-width: 100%; }
  }
}
.page-engine-viewmode--sm .grid-stack {
    > .grid-stack-item {
      $gridstack-columns: 8;
      min-width: (100% / $gridstack-columns);

      @for $i from 1 through $gridstack-columns {
        &[gs-w='#{$i}'] {
          width: (100% / $gridstack-columns) * $i;
        }
        &[gs-x='#{$i}'] {
          left: (100% / $gridstack-columns) * $i;
        }
        &[gs-min-w='#{$i}'] {
          min-width: (100% / $gridstack-columns) * $i;
        }
        &[gs-max-w='#{$i}'] {
          max-width: (100% / $gridstack-columns) * $i;
        }
      }
    }
    &.grid-stack-1>.grid-stack-item {
      min-width: 100%;
      &[gs-w='1'] { width: 100%; }
      &[gs-x='1'] { left: 100%; }
      &[gs-min-w='1'] { min-width: 100%; }
      &[gs-max-w='1'] { max-width: 100%; }
    }
    &.show-grid:hover,
    &.ui-droppable-hover {
      background-size: 100px var(--grid-cell-height), 12.47% 100px, 20px 20px, 20px 20px;
    }
}
.grid-stack {
  min-height: 100px;
  // width: 100%;
  margin: 0;
  &.show-grid:hover,
  &.ui-droppable-hover {
    // background-size: 40px 40px;
    // background-image:linear-gradient(to right, grey 1px, transparent 1px), linear-gradient(to bottom, grey 1px, transparent 1px);
     // background-image: linear-gradient(grey 1px, transparent 1px), linear-gradient(90deg, grey 1px, transparent 1px);
     // background-size: 100px var(--grid-cell-height), 4.162% 100px, 20px 20px, 20px 20px;
    //background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoBAMAAAB+0KVeAAAAHlBMVEUAAABkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGSH0mEbAAAACnRSTlMAzDPDPPPYnGMw2CgMzQAAAChJREFUKM9jgAPOAgZMwGIwKkhXQSUY0BCCMxkEYUAsEM4cjI4fwYIAf2QMNbUsZjcAAAAASUVORK5CYII=');
    //background-size: 60px 38px;
  }
  &.show-grid:hover {
    .item-hover-border {
      border: 2px solid var(--grid-item-border-color);
    }
  }
  & > div > .add-element-popover .add-button {
    position: absolute;
    z-index: 10;
    top: -2em;
    left: 50%;
    border-radius: 24px;
    padding: 16px;
    background: white;
    outline: none;
    font-size: 18px;
    font-weight: bold;
    opacity: 1;
    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04),
    0px 8px 16px rgba(96, 97, 112, 0.16);
    transition: all 0.25s ease;
  }
}

.cvt-grid-stack-placeholder {
  background-color: var(--grid-item-bg-color);
  border: 1px solid var(--grid-item-border-color);
}

.popup-default-box {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  // position: absolute;
  position: relative;
  width: 402px;
  height: 288px;
  /* 2) Basic: Stroke/300 */
  border: 1px dashed #BCC2CB;
  box-sizing: border-box;
  margin: 85px;
}

.popup-default-icon{
  display: flex;
  -webkit-box-pack: center;
  height: 64px;
  width: 64px;
  position: absolute;
  left: 170px;
  top: 81px;
  justify-content: center;
  font-size: 64px;
  /* 1) Basic: Fill/500 */
  background-repeat:no-repeat;
  background-size: 100%;
}

.popup-default-text{
  /* Select Popup Style */

  position: absolute;
  width: 238px;
  height: 40px;
  left: 83px;
  top: 167px;

  /* H3 28 px */
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 40px;

  /* identical to box height, or 143% */

  /* 1) Basic: Fill/500 */
  color: #494C53;
}

.popup-default-button{
  /* Button: MD/Filled/Round */

  /* Auto Layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px !important;

  position: absolute !important;
  width: 225px;
  height: 40px;
  top: 220px;
  left: 90px;
  font-size: 14px !important;
  /* 3) Color: Fill/1) Primary/500 */

  /* Shadow/light/200 */
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 8px;
}
.empty-section-placeholder {
  border: 2px dashed #494c53;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
