import { styled } from '@egoist/vue-emotion'
import CvtButton from '../../molecules/Button'
import { mapGetters, mapState} from 'vuex'
import * as getters from '../../../../builder/src/js/store/modules/getters'

let backgroundColor, FontWeight, FontFamily, textFontWeight, textColor, Colors, Spacing, textFontSize, FontSize

const getTopNav = () => styled('nav')`
  display: flex;
  position: fixed;
  justify-content: space-between;
  z-index: 75;
  width: 100%;
  height: ${Spacing.SPACING_64}px;
  border-bottom: 1px solid ${Colors.BASIC[100]};
  ${backgroundColor({ themeing: "dark"}, "WHITE")}
`

const getLeftHeaderWrapper = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

const getRightHeaderWrapper = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

const getLogo = () => styled('img')`
  width: ${Spacing.SPACING_96}px;
  height: 100%;
  height: 100%;
  cursor: pointer;
`

const getLogoWrapper = () => styled('div')``

const getNavText = () => styled('p')`
  font-family: ${FontFamily.DEFAULT};
  font-style: normal;
  ${textFontWeight('regular')}
  font-size: ${FontSize.FONT_SIZE_20}px;
  line-height: 32px;
  text-align: center;
  ${textColor({themeing: "dark"}, 500)}
  margin: 0;
`
const getNavTextWrapper = () => styled('div')`
  padding-left: ${Spacing.SPACING_32}px;
`

const getBtnContainer = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`
const getHelpBtnWrapper = () => styled('div')`
  margin-right: ${Spacing.SPACING_16}px;
`

const getDemoBtnWrapper = () => styled('div')`
  margin-right:${Spacing.SPACING_16}px;
`

const getHelpButton = () => styled(CvtButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px !important;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  border: none;
  color: #0040db !important;
  ${backgroundColor({ themeing: "dark"}, "WHITE")}
`
const getDemoButton = () => styled(CvtButton)`
  border-radius: 8px !important;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  border: none;
  margin-right: ${Spacing.SPACING_32}px;
  color: #0040db !important;
  ${backgroundColor({ themeing: "dark"}, "WHITE")}
`

export const EnterpriseTopNavBar = {
  name: 'EnterpriseTopNavBar',
  data() {
    return {
      logoPath: ""
    }
  },
  props: {
    navText: {
      type: String,
      default: '',
    }
  },
  created(){
    backgroundColor = this.backgroundColor
    Spacing = this.Spacing
    FontWeight = this.FontWeight
    textFontSize = this.textFontSize
    FontSize = this.FontSize
    FontFamily = this.FontFamily
    textColor = this.textColor
    Colors = this.Colors
    textFontWeight = this.textFontWeight
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      color: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR
    }),
    ...mapState('globalTheme', {
        FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
        FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
        FontSize: ({ globalTheme }) => globalTheme.FontSize,
        Spacing: ({ globalTheme }) => globalTheme.Spacing,
        Colors: ({ globalTheme }) => globalTheme.Colors,
    }),
    getTopNavLogo(){
      return this.config.THEME.logo ? this.config.THEME.logo : ""
    }
  },
  render: function (h) {
    const TopNavBar = getTopNav()
    const LeftHeaderWrapper = getLeftHeaderWrapper()
    const RightHeaderWrapper = getRightHeaderWrapper()
    const Logo = getLogo()
    const LogoWrapper = getLogoWrapper()
    const NavText = getNavText()
    const NavTextWrapper = getNavTextWrapper()
    const HelpBtnWrapper = getHelpBtnWrapper()
    const DemoBtnWrapper = getDemoBtnWrapper()
    const BtnContainer = getBtnContainer()
    const HelpButton = getHelpButton()
    const DemoButton = getDemoButton()

    return (
      <TopNavBar>
        <LeftHeaderWrapper>
          <LogoWrapper>
            <Logo src={this.getTopNavLogo}></Logo>
          </LogoWrapper>
          <NavTextWrapper>
            <NavText>{this.navText}</NavText>
          </NavTextWrapper>
        </LeftHeaderWrapper>
        <RightHeaderWrapper>
          <BtnContainer>
            <HelpBtnWrapper>
              <HelpButton
                mode="'LIGHT'"
                color="light"
                icon="question-circle"
                shape=""
              ></HelpButton>
            </HelpBtnWrapper>
            <DemoBtnWrapper>
              <DemoButton
                mode="'LIGHT'"
                color="light"
                icon="phone"
                text={this.$t('enterprise.header.top_nav.demo_btn_text')}
                prepend={false}
                rotateIcon="90"
                shape=""
              ></DemoButton>
            </DemoBtnWrapper>
          </BtnContainer>
        </RightHeaderWrapper>
      </TopNavBar>
    )
  },
}
