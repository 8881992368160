<template>
  <anime :enter="enter" :leave="leave" :before-enter="beforeEnter">
    <div v-if="open" class="page-preview-dialog">
      <div
        :class="[
          { 'dot-grid': true },
          { 'page-preview__window': templatePreviewAlert },
        ]"
      />

      <cvt-top-nav-bar
        mode="LIGHT"
        variant="embedded"
        route="preview"
        :fixed="true"
        :template-name="title"
        :type="orgName"
        @home="cancelPreview"
        @exit-preview="cancelPreview"
        @switch-preview="switchPreview"
        @use-template="clickedUseTemplate"
      />

      <left-side-bar v-if="showLeftSideBar" :is-template="isTemplate" />

      <div
        :class="{
          'page-preview-dialog__window': true,
          'preview-info-active': isPagePreviewInfoOpen,
        }"
      >
        <div
          v-if="!isGridBuilder"
          v-show="loading"
          class="page-preview-dialog__window__loader"
        >
          <h1>{{ $t(`editor.message.redirect_msg.${showRedirectMsg}`) }}</h1>
          <loader-square />
        </div>

        <cvt-alert-with-button
          v-if="showWhyUseTemplateAlert"
          :alert-btn-text="
            $t('marketplace.templates.preview.why_use_template_alert_btn_text')
          "
          :alert-message="
            $t('marketplace.templates.preview.why_use_template_alert_message')
          "
          @exitPreviewMode="showPreviewDetails"
        />
        <cvt-alert-with-button
          v-if="showUseTemplateAlert"
          :alert-btn-text="
            $t('marketplace.templates.preview.use_template_alert_btn_text')
          "
          :alert-message="
            $t('marketplace.templates.preview.use_template_alert_message')
          "
          @exitPreviewMode="clickedUseTemplate"
        />

        <embed
          v-if="showEmbededTag"
          :src="previewUrl"
          :class="[mode, { 'preview-info-active': isPagePreviewInfoOpen }]"
          width="95%"
          height="95%"
          type="text/html"
        />

        <img
          v-if="isPagePreviewInfoOpen"
          class="desktop preview-info-active"
          :src="templateImageUrl"
        />

        <iframe
          v-if="showIframeTag"
          v-show="!loading"
          :class="mode"
          :src="previewUrl"
          frameborder="0"
          @load="iframeLoaded"
        />
      </div>

      <cvt-preview-details
        v-if="isPagePreviewInfoOpen"
        :video-path="templateVideoUrlFmt"
        :description="templateDescription"
      />
    </div>
  </anime>
</template>

<script>
import * as _ from 'lodash'
import * as video from 'js-video-url-parser'
import { mapActions, mapGetters, mapState } from 'vuex'
import * as actions from '../store/modules/actions'
import * as getters from '../store/modules/getters'
import LoaderSquare from './common/LoaderSquare.vue'
import LeftSideBar from './editor/left-side-menu/LeftSideBar.vue'

export default {
  components: {
    LoaderSquare,
    LeftSideBar,
  },
  props: {
    title: {
      type: String,
      default() {
        return ''
      },
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    showUseTemplate: {
      type: Boolean,
      default: true,
    },
    showDirectional: {
      type: Boolean,
      default: true,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    previewCta: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      mode: 'desktop',
      loading: true,
      loaderMsg: this.$t('editor.message.redirect_msg.false'),
      showRedirectMsg: false,
      enter: {
        opacity: [0, 1],
        translateY: ['-100vh', 0],
        duration: 500,
        easing: 'easeInOutQuad',
      },
      leave: {
        opacity: [1, 0],
        translateY: [0, '-100vh'],
        duration: 500,
        easing: 'easeInOutQuad',
      },
      beforeEnter(e) {
        e.style.opacity = 0
      },
    }
  },
  computed: {
    ...mapGetters({
      open: getters.PAGE_PREVIEW_OPEN,
      config: getters.AUTH_GET_USER_CONFIG,
      pageId: getters.TEMPLATE_PAGE_ID,
      template: getters.SITE_GET_DATA,
      isPagePreviewInfoOpen: getters.PAGE_PREVIEW_INFO_OPEN,
      currentProductVersionNumber: getters.CURRENT_PRODUCT_VERSION_NUMBER,
      isGridBuilder: getters.IS_GRID_BUILDER,
    }),
    ...mapState('globalTheme', {
      orgName: ({ globalTheme }) => globalTheme.OrgName,
      templatePreviewAlert: ({ globalTheme }) =>
        globalTheme.templatePreviewAlert,
    }),
    previewUrl() {
      return `${this.url}?previewMode=true&mode=${this.mode}`
    },
    url() {
      if (this.template !== null) {
        return `/api/v1/marketplace/templates/${this.template?.id}/${this.pageId}/preview`
      }
      return ''
    },
    showWhyUseTemplateAlert() {
      return !this.isPagePreviewInfoOpen && this.templatePreviewAlert
    },
    showUseTemplateAlert() {
      return this.isPagePreviewInfoOpen && this.templatePreviewAlert
    },
    showLeftSideBar() {
      return this.isGridBuilder && !this.isPagePreviewInfoOpen
    },
    showEmbededTag() {
      return this.isGridBuilder && this.url != '' && !this.isPagePreviewInfoOpen
    },
    showIframeTag() {
      return !this.isGridBuilder && this.url != ''
    },
    templateImageUrl() {
      return this.template?.imageUrl?.trim() || ''
    },
    templateVideoUrl() {
      return this.template?.videoUrl?.trim() || ''
    },
    templateDescription() {
      return this.template?.description?.trim() || ''
    },
    templateVideoUrlFmt() {
      let src = this.templateVideoUrl

      if (src) {
        const newUrl = new URL(src)
        let query = `${newUrl.origin}${newUrl.pathname}`

        if (src.indexOf('wistia') >= 0) {
          let id = newUrl.href.split('/').pop()
          return `https://fast.wistia.net/embed/iframe/${id}`
        }

        let fmtVideoUrl =
          video.create({
            videoInfo: video.parse(src) || {
              id: '',
              mediaType: '',
              provider: '',
            },
            format: 'embed',
          }) ||
          src ||
          ''

        return query ? `${fmtVideoUrl}?${query.search}` : fmtVideoUrl
      }

      return src
    },
  },
  watch: {
    open() {
      this.reload()
    },
  },
  methods: {
    ...mapActions({
      toggle: actions.PAGE_PREVIEW_TOGGLE,
      showPagePreviewInfo: actions.PAGE_PREVIEW_INFO_TOGGLE,
    }),
    modeIsActive(mode) {
      return {
        'preview-btn--active': this.mode === mode,
      }
    },
    activateMode(mode) {
      this.mode = mode
    },
    resetLoader(msg = 'Loading Preview', redirecting = false) {
      this.showRedirectMsg = redirecting
      this.loading = true
      this.loaderMsg = msg
    },
    reload() {
      this.resetLoader()
      if (_.isFunction(_.get(this, '$el.querySelector'))) {
        const iframe = this.$el.querySelector('iframe')
        if (iframe) {
          // eslint-disable-next-line no-self-assign
          iframe.src = iframe.src
          console.debug('preview src', iframe.src)
        }
      }
    },
    clickedPrev() {
      this.$emit('prev')
      this.reload()
    },
    clickedNext() {
      this.$emit('next')
      this.reload()
    },
    clickedUseTemplate() {
      this.$emit('use-template')
      this.resetLoader('Redirecting to editor', true)
    },
    iframeLoaded() {
      this.loading = false
    },
    cancelPreview() {
      this.$emit('exit-clicked')
      this.toggle(false)
    },
    switchPreview(currentSelected) {
      if (currentSelected === 'desktop') {
        this.activateMode('desktop')
        this.reload()
      } else {
        this.activateMode('phone')
        this.reload()
      }
    },
    showPreviewDetails() {
      this.showPagePreviewInfo(true)
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';
.page-preview__window {
  background: #f0f2f6;
}
.page-preview-dialog {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 10001;
  background: $brand-8;
}
.page-preview-dialog__header {
  background: $white;
  padding: 0 25px;
  display: flex;
  position: relative;
  height: 48px;
  align-items: center;
  background: $inf-dark-blue;
  color: $white;
  &__back {
    position: relative;
    z-index: 10;
  }
  &__title {
    margin-left: 2em;
  }
  &__controls {
    position: relative;
    z-index: 10;
    display: flex;
    margin-left: auto;
  }
  &__control {
    margin-right: 0.5rem;
  }
}
.device-mode {
  margin: auto;
  margin-top: 0.25em;
}
.page-preview-dialog__window {
  height: calc(100vh - 85px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: 85px;
  margin-left: 82px;
  width: calc(100% - 82px);
  overflow-y: scroll;
  padding-bottom: 20px;

  &.preview-info-active {
    width: calc(100% - 664px) !important;
  }
  iframe {
    height: 100%;
    transition: all 0.5s ease;
    margin: 0 auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: 10;
  }
  .desktop {
    width: calc(95vw - 82px);
    &.preview-info-active {
      width: calc(95vw - 582px);
    }
  }
  .phone {
    width: 428px;
  }
}
.page-preview-dialog__window__loader {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
}
.use-template {
  padding: 0.25rem 1rem !important;
}
</style>
