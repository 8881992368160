import Vue from "vue";
import VueI18n from "vue-i18n";
import * as _ from 'lodash'
import { styled } from "@egoist/vue-emotion";
import * as getters from "../../../../../builder/src/js/store/modules/getters";
import { mapGetters, mapState } from "vuex";
import CvtButton from "../../../molecules/Button";
import CvtInput from "../../../molecules/Input";
import { IntegrationLogoWrapper } from "../logo-wrapper";
import v from 'validator'

Vue.use(VueI18n)

let textFontWeight, textFontSize, Spacing, FontSize, FontFamily, backgroundColor, Colors

const getAuthenticationDescription = () => styled('div')`
  background: ${Colors.BASIC[100]};
  font-family: ${FontFamily.DEFAULT};
`

const getAuthDescriptionHeader = () => styled('p')`
  font-weight: 700;
  font-size: 14px;
  color: ${Colors.BASIC[500]};
`

const getAuthSuccessBtnWrap = () => styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`

const getTextArea = () => styled('textarea')`
  > div {
    border-radius: 0;
  }
  > div > div {
    border-radius: 0;
    border: 0;
  }
  font-size: 16px;
`

const getButton = () => styled(CvtButton)`
  background-color: ${Colors.PRIMARY[500]};
`

export const ZapierSetup = {
  name: 'ZapierSetup',
  data() {
    return {
      webhookUrl: '',
      webhookTested: false,
      loading: false,
      testData: null,
      isSaving: false
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapGetters({
      providerAPI: getters.GET_PROVIDER_API_SERVICE,
      site: getters.SITE_GET_DATA,
      selected: getters.SELECTOR_SELECTED,
    }),
    ...mapGetters('globalTheme', {
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
    }),
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      globalTheme: ({ globalTheme }) => globalTheme,
    }),
    canSavingMapping() {
      const empty = this.mappedFields.filter(x => x.id !=="" || x.name !=="")
      return empty.length > 1;
    },
    canTestWebhook () {
      return true
    },
    validationStatus: {
      get () {
        return this.isValid(this.webhookUrl) ? 'valid' : 'invalid'
      },
      set (value) {
        return value
      }
    }
  },
  created() {
    textFontSize = this.textFontSize
    textFontWeight = this.textFontWeight
    Spacing = this.Spacing
    FontSize = this.FontSize
    backgroundColor = this.backgroundColor
    Colors = this.Colors
    FontFamily = this.FontFamily
  },
  mounted () {
    this.loading = true
    this.providerAPI.getHookUrl(this.site.id, this.selected.id).then(res => {
      this.webhookUrl = res?.hookURL || ""
      this.loading = false
      this.$validator.validate()
    }).catch(e => {
      this.loading = false
      console.error(e)
    })
  },
  methods: {
    async onCompleted() {
      try {
        this.loading = true
        await this.providerAPI.saveHookUrl(this.site.id, this.selected.id, this.webhookUrl)
        this.$emit('completed')
        this.webhookTested = false
      }catch (e) {
        console.error(e)
      }finally {
        this.loading = false
      }
    },
    async testWebhookUrl() {
      if (this.canTestWebhook) {
        try {
          this.loading = true
          const res = await this.providerAPI.triggerMockEvent(this.webhookUrl, this.selected.id)
          this.webhookTested = true
          this.testData = JSON.stringify(res.data, undefined, 4)
        }catch (e) {
          this.testData = JSON.stringify(e.toJSON(), undefined, 4)
          console.error(e)
        }finally {
          this.loading = false
        }
      }
    },
    isValid (uri) {
      if (!uri) return false

      return v.isURL(uri, {
        protocols: this.allowedProtocols,
      }) && uri.startsWith('https://hooks.zapier.com');
    },
    onInput ({ target }) {
      this.validationStatus = this.isValid(target.value) ? 'valid' : 'invalid'
      this.webhookUrl = this.fmt(target.value)
    },
    onChange ({ target }) {
      this.$emit('change', this.fmt(target.value))
    },
    onBlur ({ target }) {
      this.$emit('blur', this.fmt(target.value), this.validationStatus)
    },
    isHTTPLink (url = '') {
      return url.startsWith('http://') || url.startsWith('https://') || url.startsWith('//')
    },
    fmt (url = '') {
      if (_.isString(url) === false) {
        throw new Error(`URL is not a string ${url}`)
      }

      // if the url contains any protocol then don't format it
      // or isn't a valid url
      url = url.trim()

      // don't validate
      if (!url) {
        return url
      }
      // already included protocol or it's still not valid.
      // don't modify it.
      if (this.isHTTPLink(url) || !v.isURL(url)) {
        return url
      }

      // add basic protocol which inherits browsers proto
      return `http://${url}`
    }
  },
  render(h) {
    const AuthenticationDescription = getAuthenticationDescription()
    const AuthDescriptionHeader = getAuthDescriptionHeader()
    const AuthSuccessBtnWrap = getAuthSuccessBtnWrap()
    const TextArea = getTextArea()

    const Button = getButton()

    return (
      <IntegrationLogoWrapper>
        <AuthenticationDescription>
          <div style="padding: 45px;">
            <AuthDescriptionHeader>
              1.Catch Hook in Webhooks by Zapier
            </AuthDescriptionHeader>
            <div className="form-group">
              <CvtInput
                type="text"
                value={this.webhookUrl}
                label="Zapier Webhook URL"
                placeholder="Webhook URL"
                clearable={false}
                validationStatus={this.validationStatus}
                validMessage={this.$t('editor.right_nav.url_input.valid_message')}
                invalidMessage={`Zapier webhook url that start with https://hooks.zapier.com`}
                onInput={(e) => this.onInput(e)}
                onChange={(e) => this.onChange(e)}
                onBlur={(e) => this.onBlur(e)}
              />
              <CvtButton
                text="Trigger a hook test"
                style={"margin-top: 0.8rem"}
                outlined={true}
                disabled={this.validationStatus === 'invalid'}
                specialPadding="12px"
                color="primary"
                loading={this.loading}
                onClick={() =>
                  this.testWebhookUrl()
                }
              ></CvtButton>

              {this.testData && (
                <div class="form-group mt-3">
                  <TextArea id="FormControlTextarea"
                            class="form-control border-0"
                            rows={10} readonly={true}
                            value={this.testData}/>
                </div>
              )}
            </div>
          </div>
        </AuthenticationDescription>

        <AuthSuccessBtnWrap>
          <CvtButton
            text="Cancel"
            outlined={true}
            specialPadding="12px"
            color="light"
            loading={this.loading}
            onClick={() => this.$emit('cancel')}
          ></CvtButton>
          <Button
            text="Save"
            outlined={true}
            specialPadding="12px"
            disabled={!this.webhookTested}
            color="primary"
            loading={this.loading}
            onClick={() =>
              this.onCompleted()
            }
          ></Button>
        </AuthSuccessBtnWrap>
      </IntegrationLogoWrapper>
    )
  },
}
