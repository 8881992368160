<template>
  <el-dialog :visible.sync="visible" @close="close">
    <span slot="title" class="iconlib-title"> Icon Library </span>

    <el-alert
      :title="$t('icon.search.help.message')"
      type="info"
      show-icon
      :closable="false"
    >
    </el-alert>

    <div v-loading="loading" class="container">
      <div class="row mt-2">
        <div class="col-md-8">
          <input
            v-model="filter"
            type="text"
            class="form-control"
            placeholder="search icons"
            @input="debouncedSearch"
          />
        </div>
        <div class="col-md-4">
          <select
            v-model="selectedSet"
            class="form-control"
            @change="debouncedSearch"
          >
            <option
              v-for="set in sets"
              :key="set.value"
              :value="set.value"
              v-text="set.label"
            />
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="icon-list">
            <div
              v-for="icon in icons"
              :key="icon.class"
              class="icon-list-item"
              @click="select(icon)"
            >
              <icon-template :set="icon.set" :selector="icon.class" />
              <small class="mt-2" v-text="icon.class" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import * as axios from 'axios'
import * as _ from 'lodash'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as actions from '../../store/modules/actions'
import * as getters from '../../store/modules/getters'
import * as mutations from '../../store/modules/mutations'
import IconTemplate from './nodes/icon/IconTemplate.vue'

const API = axios.create({
  baseURL: '/api/v1',
})

export default {
  components: {
    IconTemplate,
  },
  data() {
    return {
      icons: [],
      filter: '',
      offset: 0,
      limit: 100,
      loading: false,
      selectedSet: '',
      sets: [
        { value: '', label: '-- filter by set --' },
        { value: 'font-awesome-icon', label: 'Font Awesome' },
        { value: 'themify-icon', label: 'Themify' },
        { value: 'material-icon', label: 'Material Design' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      open: getters.ICON_PICKER_VISIBLE,
    }),

    visible: {
      get() {
        return this.open
      },
      set(value) {
        this.show(value)
      },
    },
  },
  mounted() {
    this.search()
  },
  methods: {
    ...mapMutations({
      show: mutations.ICON_PICKER_SHOW,
    }),
    ...mapActions({
      select: actions.ICON_PICKER_SELECT,
    }),
    close() {
      this.show(false)
    },
    async search() {
      this.loading = true
      try {
        this.icons = await API.get('/assets/search/icons', {
          params: {
            query: this.filter,
            limit: this.limit,
            offet: this.offset,
            set: this.selectedSet,
          },
        }).then(({ data }) => data)
      } finally {
        this.loading = false
      }
    },
    debouncedSearch: _.debounce(function () {
      this.search()
    }, 500),
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

.iconlib-title {
  font-size: 1.5em;
  font-weight: bold;
}

.icon-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;

  .icon-list-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #757575;
    width: 15%;
    height: 70px;
    font-size: 1.9rem;
    transition: all 0.3s ease;

    i.material-icons {
      font-size: 2rem;
    }

    small {
      font-size: 0.6rem;
    }

    &:hover {
      cursor: pointer;
      background-color: #ddd;
    }
  }
}
</style>
