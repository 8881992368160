<template>
  <div :class="{ 'pointer-none': !enableMention }">
    <div id="editor"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as getters from '@/js/store/modules/getters'
import Quill from 'quill'
import { Mention, MentionBlot } from 'quill-mention'

Quill.register({ 'blots/mention': MentionBlot, 'modules/mention': Mention })

export default {
  props: {
    enableMention: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      quillEditor: null,
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setInitialContent()
      },
    },
  },
  mounted() {
    this.loadQuillScript()
  },
  methods: {
    loadQuillScript() {
      // Load Quill script dynamically
      const script = document.createElement('script')
      script.src = 'https://cdn.jsdelivr.net/npm/quill@2.0.2/dist/quill.js'
      script.async = true
      script.onload = () => {
        this.initializeEditor()
        this.setInitialContent()
      }
      document.head.appendChild(script)
    },
    initializeEditor() {
      // Initialize Quill editor
      this.quillEditor = new Quill('#editor', {
        theme: 'snow',
        modules: {
          toolbar: false,
          mention: {
            mentionDenotationChars: ['#'],
            fixMentionsToQuill: true,
            positioningStrategy: 'normal',
            defaultMenuOrientation: 'bottom',
            source: (searchTerm, renderList) => {
              const data = [
                { id: 1, value: 'description' },
                { id: 2, value: 'max-length' },
                { id: 3, value: 'business-category' },
                { id: 3, value: 'business-description' },
                { id: 4, value: 'goal' },
                { id: 5, value: 'intent' },
                { id: 6, value: 'deeper-intent' },
              ]
              const matches = data.filter((item) =>
                item.value.toLowerCase().includes(searchTerm.toLowerCase()),
              )
              renderList(matches, searchTerm)
            },
          },
        },
      })
      this.quillEditor.on('text-change', (delta, oldDelta, source) => {
        if (source == 'user') {
          this.saveQuillText()
        }
      })
    },
    setInitialContent() {
      if (this.value && this.quillEditor) {
        const content = JSON.parse(this.value)
        return this.quillEditor.setContents(content)
      }
      return this.quillEditor.setContents([])
    },
    saveQuillText() {
      const delta = this.quillEditor.getContents(
        0,
        this.quillEditor.getLength(),
      )
      const formattedText = this.deltaToString(delta.ops)
      const formattedDelta = JSON.stringify(delta.ops)
      return this.$emit('save-text', formattedDelta, formattedText)
    },
    deltaToString(delta) {
      if (!Array.isArray(delta)) {
        console.error('Invalid delta format')
        return ''
      }

      return delta.reduce((result, op) => {
        if (typeof op.insert === 'string') {
          return result + op.insert
        } else if (typeof op.insert === 'object' && op.insert.mention) {
          const { denotationChar, value } = op.insert.mention
          return result + `${denotationChar}${value}`
        }
        return result
      }, '')
    },
  },
}
</script>

<style lang="scss">
/* Include Quill stylesheet */
@import url('https://cdn.jsdelivr.net/npm/quill@2.0.2/dist/quill.snow.css');

.pointer-none {
  background: #f7f9fc;
  pointer-events: none;
}

.ql-container {
  border: 1px solid #e4e9f2;
  border-radius: 4px;
  height: 110px;
  font-family: 'Nunito';
  font-size: 15px;
  font-weight: 400;
  color: #8f9bb3;

  .mention {
    background-color: #d3e1eb;
    border-radius: 6px;
    padding: 0.5px 4px;
    user-select: all;
  }
}

.ql-mention-list {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 4px;
  max-height: 250px;
  overflow-y: auto;
  font-family: 'Nunito';
}

.ql-mention-list-item {
  cursor: pointer;
  padding: 8px;
}

.ql-mention-list-item:hover {
  background-color: #f7f9fc;
}
</style>
