
<div>
  <main-nav title="Integrate Builder"/>
  <div class="row ib-main-content-wrap">
    <div class="col-sm-12 col-md-12 col-lg-4 ib-tutorial-content-wrap">
      <h3 class="ib-tut-heading">Integrage the builder in your app</h3>

      <p class="ib-tut-para-one">
        If you are satisfied with playing around with the builder and are
        ready to move to the next step to integrate Convrrt landing page
        builder into your app, please follow this step-by-step guide.
      </p>

      <p class="ib-tut-para-two">
        At any point while setting up the builder for your app if you run into
        any issues, please follow the links below for help:
      </p>

      <div class="ib-tut-step-wrap">
        <div class="icon-wrap">
          <img class="" src="/img/enterprise/play.svg" alt="Play Icon" />
        </div>
        <div class="heading-and-desc-wrap">
          <h4 class="ib-tut-step-heading">
            Watch our setup-by-step video guide
          </h4>
          <p class="ib-tut-step-desc">
            <a href="#">Click here </a>to watch our step-by-step guide to
            setup your integration
          </p>
        </div>
      </div>

      <div class="ib-tut-step-wrap">
        <div class="icon-wrap">
          <img
            class=""
            src="/img/enterprise/docs.svg"
            alt="Documentation Icon"
          />
        </div>
        <div class="heading-and-desc-wrap">
          <h4 class="ib-tut-step-heading">Read our documentation</h4>
          <p class="ib-tut-step-desc">
            Our integration documenation should help you answer any question -
            <a href="#">Documentation link</a>
          </p>
        </div>
      </div>

      <div class="ib-tut-step-wrap">
        <div class="icon-wrap">
          <img class="" src="/img/enterprise/chat.svg" alt="Chat Icon" />
        </div>
        <div class="heading-and-desc-wrap">
          <h4 class="ib-tut-step-heading">Chat with us</h4>
          <p class="ib-tut-step-desc">
            <a href="#">Click here to start a chat</a>
          </p>
        </div>
      </div>

      <div class="ib-tut-step-wrap">
        <div class="icon-wrap">
          <img
            class=""
            src="/img/enterprise/schedule.svg"
            alt="Calender Icon"
          />
        </div>
        <div class="heading-and-desc-wrap">
          <h4 class="ib-tut-step-heading">Schedule a 1:1 demo</h4>
          <p class="ib-tut-step-desc">
            <a href="#">Click here </a>to find time on your calendar to
            schedule a 1:1 session to explore the integration posiblities.
            This is definitely helpful when creating custom-widgets for
            integration
          </p>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-8 ib-setup-content-wrap">
      <div class="breadcrumb-wrap">
        <cvt-floating-breadcrumb 
          :mode="'LIGHT'" 
          :menus="breadcrumbMenus"
          :hide-back-btn="false"
          :hide-next-btn="false"
          :active-menu="currentRoute"
          @menuChanged="(eventData) => onBreadCrumbSelectionChange(eventData)"
        />
      </div>

      <div class="dynamic-content-wrap">
        <router-view />
      </div>
    </div>
  </div>
</div>
