import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'

import Vue from '../../../lib/utils/vue'
Vue.use(VueEmotion)

export default {
  name: 'CvtTfoot',
  components: {},
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  render: function (h) {
    return <tfoot>{this.$slots.default}</tfoot>
  }
}
