import * as _ from 'lodash'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'
import { ViewModeCommand } from '../../commands/ViewModeCommand'

const DESKTOP = 'desktop'
const TABLET = 'tablet'
const PHONE = 'phone'

const MODES = [DESKTOP, TABLET, PHONE]

const MODE_CLASSES = {
  [DESKTOP]: '',
  [TABLET]: 'page-engine-viewmode--md',
  [PHONE]: 'page-engine-viewmode--sm',
}

export default {
  state: {
    mode: DESKTOP,
    className: MODE_CLASSES[DESKTOP],
  },
  getters: {
    [getters.VIEW_MODE]({ mode }) {
      return mode
    },
    [getters.VIEW_MODE_CLASS]({ className }) {
      return className
    },
  },
  mutations: {
    [mutations.VIEW_MODE_SET](state, mode) {
      if (!_.includes(MODES, mode)) {
        throw new Error(`cannot set mode: ${mode} must be one of ${MODES}`)
      }

      state.mode = mode
      state.className = MODE_CLASSES[mode]
    },
  },
  actions: {
    async [actions.CHANGE_VIEW_MODE](cxt, action) {
      if (!_.includes(MODES, action)) {
        throw new Error(`cannot set mode: ${action} must be one of ${MODES}`)
      }

      if (cxt.state.mode === action) return

      const historyViewMode = Object.assign(
        {},
        { previousViewMode: cxt.state.mode, currentViewMode: action },
      )
      cxt.commit(mutations.VIEW_MODE_SET, action)
      // push view-mode history change
      if (cxt.rootGetters[getters.CURRENT_PRODUCT_VERSION_NUMBER] >= 3.0) {
        cxt.dispatch(
          actions.HISTORY_COMMAND_PUSH,
          new ViewModeCommand(
            cxt.dispatch,
            historyViewMode.currentViewMode,
            historyViewMode.previousViewMode,
          ),
        )
        return
      }
      cxt.commit(mutations.HISTORY_VIEW_MODE_PUSH, historyViewMode)
    },
    async [actions.COMMAND_CHANGE_VIEW_MODE](cxt, action) {
      cxt.commit(mutations.VIEW_MODE_SET, action)
    },
  },
}
