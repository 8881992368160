
<div>
  <div class="d-flex">
    <strong class="mr-2 mb-3">{{ label }}</strong>
    <cvt-switch
      size="xs"
      color="active"
      type="circular"
      description-type="default"
      :initial-state="isEnabled"
      :mode="mode"
      @switchToggle="() => onToggleClicked(updateKey)"
    />
  </div>
  <div class="row" :class="{ 'pointer-events-none': !isEnabled }">
    <div class="col-12">
      <div class="froala-headline-editor">
        <!-- Any tag modification will change how froala data is saved. See saveWithHtml method -->
        <InlineEditor
          class="froala-editor"
          :config="config"
          :content="content"
          @change="(value) => selectAction(value)"/>
      </div>
    </div>
  </div>
</div>
