import * as _ from 'lodash'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { styled } from '@egoist/vue-emotion'
import * as getters from '../../../../../builder/src/js/store/modules/getters'
import * as actions from '../../../../../builder/src/js/store/modules/actions'
import * as constants from '../../../../../builder/src/js/common/constants/constants'
import { mapGetters, mapState, mapActions } from 'vuex'
import CvtButton from '../../../molecules/Button'
import CvtInput from '../../../molecules/Input'
import CvtSelect from '../../../molecules/Select'
import CvtRadioGroup from '../../../molecules/radio-group/RadioGroup'
import CvtCustomCheckbox from '../../../molecules/CustomCheckbox'
import { IntegrationLogoWrapper } from '../logo-wrapper'
import { FieldMapper, LoadingComponent } from "../FieldMapper";

Vue.use(VueI18n)

let textFontWeight, textFontSize, Spacing, FontSize, backgroundColor, Colors, FontFamily

const getAuthenticationDescription = () => styled('div')`
  background: ${Colors.BASIC[100]};
  font-family: ${FontFamily.DEFAULT}
`

const getHorizontalLine = () => styled('div')`
  border-top: 1px solid ${Colors.BASIC[300]};
  margin-bottom: 15px;
`

const getAuthSuccessBtnWrap = () => styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`


const getSelect = () => styled(CvtSelect)`
  width: 100%;
  margin-bottom: 23px;

  > div {
    border-radius: 4px;
  }

  div > div {
    border-radius: 0;
    border: 0;
  }
`

const getFieldsCaption = () => styled('p')`
  font-size: 16px;
  font-weight: 400;
`

const getAuthDescriptionHeader = () => styled('p')`
  font-weight: 700;
  font-size: 16px;
  color: ${Colors.BASIC[500]};
`

const getCustomCheckbox = () => styled(CvtCustomCheckbox)``

const getCheckboxWrap = () => styled('div')`
  background: white;
  padding: 4px 7px;
  border-radius: 4px;
`

const getCheckboxContainer = () => styled('div')`
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
`
const getMapTitle = () => styled('h6')`
  font-weight: 700;
  font-size: 14px;
`


export const MailchimpSetup = {
  name: 'MailchimpSetup',
  components: {
    CvtRadioGroup,
  },
  data() {
    return {
      mailchimpFields: [1, 2, 3],
      showPipelineSelect: false,
      loadingStatus: {value: true, action: "list"},
      setupModel: {
        listId: null,
        tags: [],
        tagType: "static-tagging",
        data: [],
        // provider: "mailchimp"
      },
      userContactList: [],
      taggingTypes: [
        {name: "Static Tagging", value: "static-tagging"},
        {name: "Dynamic Tagging", value: "dynamic-tagging"},
      ],
      tagsById: [],
      // for mapping

      providerUnMappedFields: [], // keeps track of fields not mapped
      standardUnMappedFields: [], // keeps track of standards not mapped

      standardFieldLabels: {},
      providerFieldLabels: {},
      // Keeps tracking of fields for mapping
      fields: {providerFields: [], standardFields: []}
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapGetters({
      providerAPI: getters.GET_PROVIDER_API_SERVICE,
      site: getters.SITE_GET_DATA,
      selected: getters.SELECTOR_SELECTED,
    }),
    ...mapGetters('globalTheme', {
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
    }),
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      globalTheme: ({ globalTheme }) => globalTheme,
    }),
    ...mapState('sitePreferences', {
      integrationConfig: ({ preferences }) => _.get(preferences, 'integrations', {}),
    }),
    showAuthScreen () {
      if (this.integrationConfig.step === constants.AUTH_INIT) {
        return true
      }
      else if (!this.isValidProvider) {
        return true
      }

      return false
    },
    isValidProvider () {
      return this.integrationConfig.provider === this.selectedIntegration.key
    },
    canSavingMapping() {
      const empty = this.setupModel.data.filter(x => x.id === "" || x.name === "")
      return this.standardUnMappedFields.length === 0 && empty.length === 0;
    },
    canAddMapping() {
      const empty = this.setupModel.data.filter(x => x.id==="" || x.name==="")
      if (empty.length > 0) {
        return  false
      }
      if (this.standardUnMappedFields.length > 0) {
        this.addMapping()
        return true
      }
      return false
    },
    isLoadingList() {
      return this.loadingStatus.value && this.loadingStatus.action === "list"
    },
    isLoadingFieldsTags() {
      return this.loadingStatus.value && this.loadingStatus.action === "tags-fields"
    },
    isSaving () {
      return this.loadingStatus.value && this.loadingStatus.action === "saving"
    },
    isRefreshing () {
      return this.loadingStatus.value && this.loadingStatus.action === "refresh"
    }
  },
  created() {
    textFontSize = this.textFontSize
    textFontWeight = this.textFontWeight
    Spacing = this.Spacing
    FontSize = this.FontSize
    backgroundColor = this.backgroundColor
    Colors = this.Colors
    FontFamily = this.FontFamily
  },
  mounted() {
    this.onScrollInToView()

    this.loadingStatus = {value: true, action: "list"}
    this.init().finally(() => {
      this.loadingStatus = {value: false, action: "tags-fields"}
    })
  },
  methods: {
    ...mapActions('sitePreferences', {
      saveIntegrationStep: 'saveIntegrationStep',
    }),
    ...mapActions({
      setSelectedIntegration: actions.SELECT_INTEGRATION,
    }),
    _default() {
      return {
        listId: null,
        tags: [],
        tagType: "static-tagging",
        data: [],
        // provider: "mailchimp"
      }
    },
    onScrollInToView() {
      const container = document.getElementById('authWrap')
      container?.scrollIntoView()
    },
    async init () {
      this.setupModel = await this.providerAPI.getMappedSetup(this.site.id, this.selected.id)

      if (this.setupModel.listId === undefined) {
        this.setupModel = this._default()
      }else {
        this.setupModel.data = this.setupModel.fieldMappings
        delete this.setupModel.fieldMappings

        if (this.setupModel.tags === undefined) {
          this.setupModel.tags = []
        }
      }

      this.userContactList = await this.providerAPI.getUserList()

      if (this.setupModel.listId) {
        await this.onListChange(this.setupModel.listId)
      }
    },
    async onCompleted() {
      try {
        this.loadingStatus = {value: true, action: "saving"}

        await this.providerAPI.saveMappedSetup(this.site.id, this.selected.id, this.setupModel)
        this.$emit('completed')
      }catch (e) {
        console.error(e)
      }finally {
        this.loadingStatus = {value: false, action: "tags-fields"}
      }
    },
    async onListChange(listId) {
      try {
        this.loadingStatus = {value: true, action: "tags-fields"}

        this.tagsById = await this.providerAPI.getUserListTag(listId)

        this.fields = await this.providerAPI.getFieldMappings(listId, this.selected.id)

        this.setupModel.listId = listId

        this.standardUnMappedFields = this.selected.computedFields.map(x => x.id)
        this.standardFieldLabels = Object.assign(
          {}, ...this.selected.computedFields.map(x => ({[x.id]: x.label}))
        )

        this.providerUnMappedFields = this.fields.integrationFields.map(x => x.name)
        this.providerFieldLabels = Object.assign(
          {}, ...this.fields.integrationFields.map(x => ({[x.name]: x.label}))
        )

        this.fields.providerFields = [...this.providerUnMappedFields]
        this.fields.standardFields = [...this.standardUnMappedFields]

        if (this.setupModel.data.length === 0) {
          this.addMapping()
        }
        else {
          this.updateCorrespondingFields()
        }
      }catch (e) {
        console.error(e)
      }finally {
        this.loadingStatus = {value: false, action: "tags-fields"}
      }
    },
    onTagSelectedChanged(e, id) {
      if (e.target.value === 'on') {
        this.setupModel.tags.push(id)
      }else {
        this.setupModel.tags = this.setupModel.tags.filter(x=> x !== id)
      }
    },
    updateCorrespondingFields() {
      const mappedStandardFields = this.setupModel.data.map(c=> c.id)
      const mappedHubspotFields = this.setupModel.data.map(c=> c.name)

      this.standardUnMappedFields = this.fields.standardFields.filter(c => !mappedStandardFields.includes(c))
      this.providerUnMappedFields = this.fields.providerFields.filter(c => !mappedHubspotFields.includes(c))
    },
    addMapping() {
      this.setupModel.data.push({id: "", name: ""})
    },
    removeMapping (map) {
      // remove from mapping list
      this.loadingStatus = {value: true, action: "refresh"}

      const idx = this.setupModel.data.indexOf(map)
      this.setupModel.data = this.setupModel.data.filter((map, index) => index !== idx)
      this.updateCorrespondingFields()

      this.$nextTick(() => {
        this.loadingStatus = {value: false, action: "refresh"}
      })
    },
    UpdateStandardField (map, value) {
      // update convrrt field mapping and remove it from already mapped list
      this.loadingStatus = {value: true, action: "refresh"}

      map.id = value
      this.updateCorrespondingFields()

      this.$nextTick(() => {
        this.loadingStatus = {value: false, action: "refresh"}
      })
    },
    UpdateProviderField (map, value) {
      // update hubspot field mapping and remove it from already mapped list
      this.loadingStatus = {value: true, action: "refresh"}

      map.name = value
      this.updateCorrespondingFields()

      this.$nextTick(() => {
        this.loadingStatus = {value: false, action: "refresh"}
      })
    },
  },
  render: function (h) {
    const AuthenticationDescription = getAuthenticationDescription()
    const HorizontalLine = getHorizontalLine()
    const AuthSuccessBtnWrap = getAuthSuccessBtnWrap()
    const Select = getSelect()
    const AuthDescriptionHeader = getAuthDescriptionHeader()

    const CustomCheckbox = getCustomCheckbox()
    const CheckboxWrap = getCheckboxWrap()
    const CheckboxContainer = getCheckboxContainer()
    // const integrationTitle = this.selectedIntegration.title
    const MapTitle = getMapTitle()
    const FieldsCaption = getFieldsCaption()

    const getListOptions = this.userContactList.map(
      (option, idx) => {
        return (
          <option id={idx} value={option.id}>
            {option.name}
          </option>
        )
      },
    )

    const getTaggingTypes = this.taggingTypes.map(
      (option, index) => {
        return (
          <option id={option.value} value={option.value}>
            {option.name}
          </option>
        )
      },
    )

    return (
      <IntegrationLogoWrapper>
        <AuthenticationDescription>
          {this.isLoadingList ? (
            <LoadingComponent hideButton={true}/>
          ) : (
            <div style="padding: 45px;">
              <div>
                <AuthDescriptionHeader>Choose a list</AuthDescriptionHeader>
                <p>Select the list you want to add information to</p>
                <Select placeholder="Please select"
                        defaultValue={this.setupModel.listId}
                        value={this.setupModel.listId}
                        onInput={(val) => this.onListChange(val)}>
                  {getListOptions}
                </Select>
              </div>

              {this.isLoadingFieldsTags ? (<LoadingComponent hideButton={true}/>) : (
                this.setupModel.listId && (
                <div>
                  <HorizontalLine></HorizontalLine>
                  <p>Tags</p>
                  <Select placeholder="Tagging Type"
                          defaultValue={this.setupModel.tagType}
                          value={this.setupModel.tagType}
                          onInput={(val) => this.setupModel.tagType = val}>
                    {getTaggingTypes}
                  </Select>

                  {this.setupModel.tagType === "dynamic-tagging" && <p style="font-size: 14px;">There are no form fields to pull the tags from.</p>}

                  {this.setupModel.tagType === "static-tagging" && (
                    <div>
                      <p style="font-size: 14px;">
                        You can select tags from the list below. Selected tags will
                        applied to all new contacts created through this form.
                      </p>
                      <CheckboxContainer style="">
                        {this.tagsById.map(x => {
                          return (
                            <CheckboxWrap>
                              <CustomCheckbox
                                fontSize="14px"
                                label={x.name}
                                checked={this.setupModel.tags.includes(x.id)}
                                onChange={e => this.onTagSelectedChanged(e, x.id)}
                              />
                            </CheckboxWrap>
                          )
                        })}
                      </CheckboxContainer>
                    </div>
                  )}
                  <HorizontalLine class={'mb-3'}></HorizontalLine>

                  <FieldsCaption>Match your fields</FieldsCaption>

                  <div style="display: flex;gap: 30px; align-items: center; margin-top:0.4rem">
                    <div style="flex:1;">
                      <MapTitle class={'m-0 py-2'}>Convrrt Form Field</MapTitle>
                    </div>

                    <div style="flex:1;">
                      <MapTitle class={'m-0 py-2'}>Desired Mailchimp Field</MapTitle>
                    </div>
                    <CvtButton
                      class={'d-none'}
                      disabled={!this.canAddMapping}
                      icon={'plus'}
                      outlined={true}
                      specialPadding="5px"
                      color="light"
                      shape={'circle'}
                      size={'10px'}
                      onClick={() => this.addMapping()}
                    ></CvtButton>
                  </div>
                  <HorizontalLine class={'my-1'}></HorizontalLine>

                  {!this.isRefreshing && (<FieldMapper
                    providerUnMappedFields={this.providerUnMappedFields}
                    standardUnMappedFields={this.standardUnMappedFields}

                    standardFieldLabels={this.standardFieldLabels}
                    providerFieldLabels={this.providerFieldLabels}

                    mappedFields={this.setupModel.data}

                    onUpdateProviderField={this.UpdateProviderField}
                    onUpdateStandardField={this.UpdateStandardField}

                    onRemoveMapping={this.removeMapping}
                    provider={'Mailchimp'}
                  />)}
                </div>
              ))}
            </div>
          )}
        </AuthenticationDescription>

        <AuthSuccessBtnWrap>
          <CvtButton
            text="Cancel"
            outlined={true}
            specialPadding="12px"
            color="light"
            loading={this.loadingStatus.value}
            onClick={() => this.$emit('cancel')}
          ></CvtButton>
          {this.setupModel.listId && (
          <CvtButton
            text="Save"
            outlined={true}
            specialPadding="12px"
            disabled={!this.canSavingMapping}
            color="primary"
            loading={this.loadingStatus.value}
            onClick={() =>
              this.onCompleted()
            }
          ></CvtButton>)}
        </AuthSuccessBtnWrap>
      </IntegrationLogoWrapper>
    )
  },
}
