import Vue from '../../../lib/utils/vue'
import { mergeData } from 'vue-functional-data-merge'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'

let FontFamily, Spacing, Radii, Border
let borderColor, color, backgroundColor, textFontSize

Vue.use(VueEmotion)

const getCustomUnorderedList = () => styled('ul')`
  ${(props) => {
    let ifNavCss = `
      display: inline-flex;
      flex-wrap: wrap;
      padding-left: 0;
      list-style: none;  
      ${textFontSize('md')}
      font-family: ${FontFamily.DEFAULT};
    `
    
    let ifNavPillsCss = `
      &:hover, :focus{           
        ${backgroundColor({ themeing: 'dark' }, 100)}  
      }
      li {
        a.active{
          ${color({ themeing: 'dark' }, 'WHITE')}        
          ${backgroundColor({ themeing: 'primary' }, 500)}    
          border-radius: ${Radii.RADIUS_PX_8}px;          
        }        
      }      
    `

    let ifNavTabsTabActiveCss = `
      li {
        a.active{
          ${color({ themeing: 'dark' }, 400)}  
          ${backgroundColor({ themeing: 'dark' }, 'WHITE')}        
          border: ${Border.SIZE_1}px solid transparent;
          ${borderColor({ themeing: 'dark' }, 200)}  
          border-top-left-radius: ${Radii.RADIUS_PX_8}px;          
          border-top-right-radius: ${Radii.RADIUS_PX_8}px;          
        }
      }
    `

    let ifCardHeaderTabsCss = `
      margin-right: -${Spacing.SPACING_16}px;
      margin-bottom: -${Spacing.SPACING_12}px; 
      margin-left: -${Spacing.SPACING_16}px;    
      li{        
        a {
          border: ${Border.SIZE_1}px solid transparent;
          border-top-left-radius: ${Radii.RADIUS_PX_8}px;
          border-top-right-radius: ${Radii.RADIUS_PX_8}px;        
          &:hover, :focus{         
            ${borderColor({ themeing: 'dark' }, 200)}
          }       
        } 
      }
      border-bottom: 0;     
    `

    let ifCardHeaderPillsCss = `
      &:hover, :focus{           
        ${backgroundColor({ themeing: 'dark' }, 200)}
      }
      margin-right: -${Spacing.SPACING_16}px;
      margin-bottom: -${Spacing.SPACING_16}px;
      margin-left: -${Spacing.SPACING_16}px;
      li {
        a.active{
          ${color({ themeing: 'dark' }, 'WHITE')}
          ${backgroundColor({ themeing: 'primary' }, 500)} 
          border-radius: ${Radii.RADIUS_PX_8}px;          
        }        
      }      
    `

    let finalCss = ``
    if (props.isNav) {
      finalCss += ifNavCss
    }

    if (props.isNavPills && !props.isCardHeaderPills) {
      finalCss += ifNavPillsCss
    }

    if (props.isNavTabs) {
      finalCss += ifNavTabsTabActiveCss
    }

    if (props.isCardHeaderTabs) {
      finalCss += ifCardHeaderTabsCss
    }

    if (props.isCardHeaderPills) {
      finalCss += ifCardHeaderPillsCss
    }

    return finalCss
  }}
`

export const props = {
  tag: {
    type: String,
    default: 'ul'
  },
  fill: {
    type: Boolean,
    default: false
  },
  justified: {
    type: Boolean,
    default: false
  },
  align: {
    type: String,
    default: null
  },
  tabs: {
    type: Boolean,
    default: false
  },
  pills: {
    type: Boolean,
    default: false
  },
  vertical: {
    type: Boolean,
    default: false
  },
  small: {
    type: Boolean,
    default: false
  },
  cardHeader: {
    type: Boolean,
    default: false
  },
  styling: {
    type: String,
    default: ''
  }
}

const computeJustifyContent = (value) => {
  // Normalize value
  value = value === 'left' ? 'start' : value === 'right' ? 'end' : value
  return `justify-content-${value}`
}

// @vue/component
export const CvtNav = {
  name: 'CvtNav',
  props: {
    tag: {
      type: String,
      default: 'ul'
    },
    fill: {
      type: Boolean,
      default: false
    },
    justified: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: null
    },
    tabs: {
      type: Boolean,
      default: false
    },
    pills: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    cardHeader: {
      type: Boolean,
      default: false
    },
    styling: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border
    })
  },
  created () {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    borderColor = this.borderColor
    color = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
  },
  render: function (h) {
    const customUnorderedList = getCustomUnorderedList()

    let mergedData = mergeData({}, {
      class: {
        'flex-column': this.vertical,
        [computeJustifyContent(this.align)]: !this.vertical && this.align,
        ['text-' + this.align]: this.vertical && this.align,
        small: this.small
      },
      style: this.styling
    })

    let cssConditions = {
      isNav: true,
      isNavTabs: this.tabs,
      isNavPills: this.pills && !this.tabs,
      isCardHeaderTabs: !this.vertical && this.cardHeader && this.tabs,
      isCardHeaderPills:
        !this.vertical && this.cardHeader && this.pills && !this.tabs
    }

    return (
      <customUnorderedList attrs={cssConditions} {...mergedData}>
        {this.$slots.default}
      </customUnorderedList>
    )
  }
}
