import { styled, VueEmotion } from '@egoist/vue-emotion'
import { mapActions, mapGetters, mapState } from 'vuex'
import * as getters from '../../../builder/src/js/store/modules/getters'
import { V1Sites } from '../../../builder/src/js/store/services/api'
import Vue from '../../lib/utils/vue'
import CvtButton from "../molecules/Button"
import { CvtIcon } from '../molecules/icon/icon'

let sitesAPI = new V1Sites()
Vue.use(VueEmotion)


let FontFamily,
Spacing,
Radii,
Colors,
Border,
ColoredActive,
ShowLeftNavShadow,
textFontWeight,
color,
backgroundColor,
textFontSize,
elevation,
textColor,
linkColor,
IconSize


const getLeftNav = () => styled('aside')`
  display: flex;
  flex-direction: column;
  width: 280px;
  font-family: ${FontFamily.DEFAULT};
  overflow-y: auto;
  ${() =>
    ShowLeftNavShadow ? `box-shadow: -4px 0px 19px 5px rgba(0,0,0,0.3);` : ``}
  ${(props) => {
    let styles = ``
    if (props.type === 'alternate' && props.usage === 'landingPage') {
      styles += backgroundColor({ themeing: 'dark' }, 'WHITE')
    } else {
      let weight = 'WHITE'
      weight = props.mode === 'DARK' ? 700 : 'WHITE'
      styles += `
      ${backgroundColor(
        { themeing: props.mode === 'DARK' ? 'dark' : 'light' },
        weight
      )};
        padding: 0 ${Spacing.SPACING_24}px;
      `
    }
    return styles
  }}

  &.__fixed {
    position: fixed;
    height: calc(100vh - 50px);
    z-index: 1;
    overflow-y: auto;
    padding-bottom: ${Spacing.SPACING_64}px;
    ${(props) => props.showV1BuilderVersionSwitch && `padding-bottom: ${Spacing.SPACING_192}px;`}
  }
`

const getNavHeader = () => styled('div')`
  margin: ${Spacing.SPACING_16}px 0 ${Spacing.SPACING_4}px;
`

const getSelectLabel = () => styled('label')`
  margin: ${Spacing.SPACING_2}px 0;
  ${color({ themeing: 'dark' }, 400)}
  ${textFontSize('sm')}
  ${textFontWeight('LIGHT')}
`

const getNavItem = () => styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-stretch;
  ${color({ themeing: 'dark' }, 500)}
  ${textFontSize('lg')}
  ${textFontWeight('LIGHT')}
  ${(props) =>{
    if(props.alignTabToBottom){
      let styles = ''
      styles += `position: absolute; bottom: 10px;`
      return styles;
    }
  }
  }

  ${(props) => {
    let styles = ``
    if (props.type === 'alternate' && props.usage === 'landingPage') {
      styles += `
        height: ${props.showCount === true ? '72px;' : '55px;'}
        &:hover, :focus, :active {
          ${backgroundColor({ themeing: 'dark' }, 100)}
          cursor: pointer;
        }
        &.isActive {
          // TODO: color value should be refactored
          ${ColoredActive === true
          ? `${backgroundColor({ themeing: 'primary' }, 100)}
              border-right: 4px solid #044a75;
              `
          : backgroundColor({ themeing: 'dark' }, 100)
        }
        }
      `
    } else {
      styles += `
        height: 40px;
        margin: ${Spacing.SPACING_16}px 0;
        border-radius: ${Radii.RADIUS_PX_8}px;
        &:hover, :focus, :active {
          ${color({ themeing: 'dark' }, 'WHITE')} !important
          ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
          ${elevation({ mode: 'LIGHT' }, 200)}
          cursor: pointer;
        }
        &.isActive {
          ${color({ themeing: 'primary' }, 500)}
          ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
          ${elevation({ mode: 'LIGHT' }, 200)}
        }
      `
    }
    return styles
  }}

`

const getNavItemInsideWrap = () => styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  display: flex;
  flex-direction: column;
  /* display: block; */
  align-items: start;
  justify-content: center;

  ${(props) => {
    let styles = ``
    if (props.type === 'alternate' && props.usage === 'landingPage' && props.showCount)
      styles += props.isText
        ? `border-bottom: ${Border.SIZE_1}px solid ${Colors.BASIC[200]}; flex: 1;`
        : `border-bottom: ${Border.SIZE_1}px solid transparent; padding: 0 ${Spacing.SPACING_24}px;`
    else
      styles += props.isText
        ? `flex: 1;`
        : `padding: 0 ${Spacing.SPACING_16}px;`

    if (props.type === 'alternate')
      styles += `padding-top: ${Spacing.SPACING_16}px;
                 padding-bottom: ${Spacing.SPACING_16}px;
                 justify-content: end;`
    return styles
  }}
  ${(props) => {
    if (props.isActive) {
      return color({ themeing: 'dark' }, 700)
    } else {
      return props.mode === 'DARK'
        ? color({ themeing: 'light' }, 100)
        : color({ themeing: 'dark' }, 700)
    }
  }}
`
const getNavIcon = () => styled(CvtIcon)`
  width: ${Spacing.SPACING_18}px;
  font-size: ${IconSize.ICON_SIZE_XL}px;
  ${(props) => {
    if (props.type === 'alternate' && props.showCount)
      return `padding-top: 4px;`
  }}
  ${props => !props.showCount ? `color: rgba(0, 0,0, 0.54);` : ``}
`

const getNavText = () => styled('span')`
  margin: 0 ${Spacing.SPACING_4}px;
  ${props => !props.showCount && !linkColor
    ? color({ themeing: 'primary' }, 500)
    : linkColor
        ? color({ themeing: linkColor }, 500)
        : ``
  }
`

const getNavCountText = () => styled('span')`
  ${textFontSize('md')}
  margin: 0 ${Spacing.SPACING_4}px;
`

const getVersionSwitchText = () => styled('p')`
  ${textColor({ themeing: "dark" }, 700)}
  ${textFontWeight('REGULAR')}
  ${textFontSize("sm")}
  margin: 0px;
`

const getVersionSwitchBtn = () => styled(CvtButton)``

const getVersionLabel = () => styled('p')`
  ${textColor({ themeing: "dark" }, 400)}
  ${textFontWeight('REGULAR')}
  ${textFontSize("xs")}
  margin: 0px;
  text-align: right;
`

const getVersionSwitchContainer = () => styled('div')`
  height: 170px;
  display: flex;
  align-items: center;
  background: white;
  width: inherit;
  position: fixed;
  bottom: 0px;
  left: 0;
`
const getVersionSwitchWrapper = () => styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${Spacing.SPACING_16}px;
  padding: ${Spacing.SPACING_16}px ${Spacing.SPACING_18}px;
  overflow-x: auto;
`


export const CvtLeftNavBar = {
  name: 'CvtLeftNavBar',
  data () {
    return {
      activeId: this.initialActiveId,
    }
  },
  components: {
    CvtButton
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
    usage: {
      type: String,
      default: 'landingPage'
    },
    type: {
      type: String,
      default: 'standard'
    },
    navigationItems: {
      type: Array,
      default: () => []
    },
    fixed: {
      type: Boolean,
      default: true
    },
    initialActiveId: {
      type: String,
      default: ''
    },
    counts: {
      type: Object,
      default: null
    },
    showCount: {
      type: Boolean,
      default: true
    },
    alignTabToBottom:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    ...mapGetters ('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      elevation: getters.GLOBAL_STYLE_ELEVATION,
      textColor: getters.GLOBAL_STYLE_COLOR
    }),
    ...mapState ('globalTheme', {
      leftNavConfig: ({ leftNavConfig }) => leftNavConfig,
      iconConfig: ({ iconConfig }) => iconConfig,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      Border: ({ globalTheme }) => globalTheme.Border,
      IconSize: ({ globalTheme }) => globalTheme.IconSize,
      ColoredActive: ({ globalTheme }) =>
        globalTheme.LeftSideBarColoredActive !== undefined
          ? globalTheme.LeftSideBarColoredActive
          : false,
      ShowLeftNavShadow: ({ globalTheme }) =>
        globalTheme.ShowLeftNavShadow !== undefined
          ? globalTheme.ShowLeftNavShadow
          : false,
      leftNavItemsLinkColor: ({ leftNavConfig }) => leftNavConfig.marketplace.navItems.linkColor,
      certifiedTemplatesMarketplaceUI: ({ globalTheme }) => globalTheme.certifiedTemplatesMarketplaceUI,
      marketplaceLeftNavBarBottomBtnConfig : ({ globalButtonConfig }) => globalButtonConfig.locations.marketplaceLeftNavBarBottom.locations.default.state
    }),
    isGridBuilder() {
      if(!this.config) return;
      return this.config.GRID_BUILDER_ENABLED
    },
    enableV1BuilderVersionSwitch(){
      if(!this.config) return;
      return this.config.ENABLE_V1_BUILDER_VERSION_SWITCH
    },
    showV1BuilderVersionSwitch(){
      return this.isGridBuilder && this.enableV1BuilderVersionSwitch && this.$route.name === "choose:a:template"
    }
  },
  methods: {
    ...mapActions('loader', {
      startLoader: 'start',
      stopLoader: 'stop'
    }),
    onNavItemClicked (item) { this.$emit('navItemClicked', item) },
    itemIcon (item) {
      const categories = this.iconConfig['marketplaceCategories']
      let id = item.id
      if (item.id === null) {
        id = 'all'
      }
      return item.icon ? item.icon : categories[id]
    },
    countText (item) {
      if (this.counts === null) return ''
      else {
        const templateCount = this.counts[item.id === null ? 'all' : item.id]
        if (templateCount === undefined) return ''
        else return `${templateCount} templates`
      }
    },
    alignNavItemToBottom (item) {
      if(item.id === 'archived-templates'){
        return this.alignTabToBottom
      }
      return false
    },
    filterNavItems(activeFlag, item) {
      const NavItem = getNavItem()
      const NavItemInsideWrap = getNavItemInsideWrap()
      const NavIcon = getNavIcon()
      const NavText = getNavText()
      const NavCountText = getNavCountText()

      if(this.certifiedTemplatesMarketplaceUI){
        if(item.id == null || item.id == "your-templates" || item.id == "archived-templates"){
          return (
            <NavItem
              mode={this.mode}
              type={this.type}
              class={{ isActive: activeFlag }}
              usage={this.usage}
              onClick={() => {
                this.activeId = item.id
                this.onNavItemClicked(item)
              }}
              showCount={this.showCount}
              alignTabToBottom={this.alignNavItemToBottom(item)}
            >
              <NavItemInsideWrap
                mode={this.mode}
                isText={false}
                type={this.type}
                usage={this.usage}
                isActive={activeFlag}
                showCount={this.showCount}
              >
                <NavIcon
                  icon={this.itemIcon(item)}
                  type={this.type}
                  showCount={this.showCount}
                ></NavIcon>
              </NavItemInsideWrap>
              <NavItemInsideWrap
                mode={this.mode}
                isText={true}
                type={this.type}
                usage={this.usage}
                isActive={activeFlag}
                showCount={this.showCount}
              >
                <NavText showCount={this.showCount}>{this.formatLabel(item.label)}</NavText>
                {this.type === 'alternate' && this.showCount && (
                  <NavCountText showCount={this.showCount}>{this.counts && this.countText(item)}</NavCountText>
                )}
              </NavItemInsideWrap>
            </NavItem>
          )
        }
      }else{
        return (
          <NavItem
            mode={this.mode}
            type={this.type}
            class={{ isActive: activeFlag }}
            usage={this.usage}
            onClick={() => {
                this.activeId = item.id
                this.onNavItemClicked(item)
            }}
            showCount={this.showCount}
            alignTabToBottom={this.alignNavItemToBottom(item)}
          >
            <NavItemInsideWrap
              mode={this.mode}
              isText={false}
              type={this.type}
              usage={this.usage}
              isActive={activeFlag}
              showCount={this.showCount}
            >
              <NavIcon
                icon={this.itemIcon(item)}
                type={this.type}
                showCount={this.showCount}
              ></NavIcon>
            </NavItemInsideWrap>
            <NavItemInsideWrap
              mode={this.mode}
              isText={true}
              type={this.type}
              usage={this.usage}
              isActive={activeFlag}
              showCount={this.showCount}
            >
              <NavText showCount={this.showCount}>{this.formatLabel(item.label)}</NavText>
              {this.type === 'alternate' && this.showCount && (
                <NavCountText showCount={this.showCount}>{this.counts && this.countText(item)}</NavCountText>
              )}
            </NavItemInsideWrap>
          </NavItem>
        )
      }
    },
    async switchToBuilderVersion(){
      this.startLoader()
      await sitesAPI.switchBuilderVersion({
        enableV2: false
      })
      this.$router.go();
    },
    formatLabel(label){
      let formatedLabel = ""
      if(label){
        const arr = label.split(" ");
        for (var j = 0; j < arr.length; j++) {
          arr[j] = arr[j].charAt(0).toUpperCase() + arr[j].slice(1).toLowerCase();
          formatedLabel = arr.join(" ");
        }
      return formatedLabel;
      }
    }
  },
  created () {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    Colors = this.Colors
    Border = this.Border
    IconSize = this.IconSize
    textFontWeight = this.textFontWeight
    color = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    elevation = this.elevation
    textColor = this.textColor
    ColoredActive = this.ColoredActive
    ShowLeftNavShadow = this.ShowLeftNavShadow
    linkColor = this.leftNavItemsLinkColor
  },
  render: function (h) {
    let navigationItems = this.navigationItems

    const LeftNav = getLeftNav()
    const VersionSwitchContainer = getVersionSwitchContainer()
    const VersionSwitchWrapper = getVersionSwitchWrapper()
    const VersionSwitchText = getVersionSwitchText()
    const VersionSwitchBtn = getVersionSwitchBtn()
    const VersionLabel = getVersionLabel()

    // Convert counts to the json type
    const navItems = navigationItems.map((item) => {
      let activeFlag = item.id === this.activeId
      return this.filterNavItems(activeFlag, item)
    })
    return (
      <LeftNav
        mode={this.mode}
        type={this.type}
        usage={this.usage}
        class={{ __fixed: this.fixed }}
        showV1BuilderVersionSwitch={this.showV1BuilderVersionSwitch}
      >
        {navItems}
        {this.showV1BuilderVersionSwitch &&
        <VersionSwitchContainer>
          <VersionSwitchWrapper>
            <VersionSwitchText>
            { this.$t('marketplace.left_nav.builder_version_switch.message') }
            </VersionSwitchText>
            <VersionSwitchBtn
              color="light"
              text={this.$t('marketplace.left_nav.builder_version_switch.btn_text')}
              btnState={this.marketplaceLeftNavBarBottomBtnConfig}
              outlined={true}
              changeBtnBorderColor={true}
              onClick={this.switchToBuilderVersion}
            >
            </VersionSwitchBtn>
            <VersionLabel>{ this.$t('marketplace.left_nav.builder_current_version.label') }</VersionLabel>
          </VersionSwitchWrapper>
        </VersionSwitchContainer>
        }
      </LeftNav>
    )
  }
}
