
<div>
  <div class="d-flex">
    <strong class="mr-2">{{$t('elements.property.edit.background.color.label')}}</strong>
    <cvt-switch
      size="xs"
      color="active"
      type="circular"
      description-type="default"
      :initial-state="enableBackground"
      :mode="mode"
      @switchToggle="onToggleClicked"
    />
  </div>
  <div class="row" :class="{ 'pointer-events-none': !enableBackground }">
    <div class="col-12">
      <colors alpha :mode="mode"/>
    </div>
  </div>
</div>
