
<div>
  <h1>Switch</h1>
  <div
    v-for="(shape, id) in shapes"
    :key="id"
  >
    <h4>{{ id }}</h4>
    <div class="d-flex">
      <div
        v-for="(color, index) in colors"
        :key="index"
        class="m-2"
      >
        <span>{{ index }}</span>
        <cvt-switch
          :color="color"
          description-type="default"
          size="md"
          :type="shape"
          :initial-state="true"
        />
      </div>
    </div>
  </div>

  <div
    v-for="(desc_type, id) in descriptionTypes"
    :key="id"
  >
    <h4>{{ id }}</h4>
    <div class="d-flex">
      <div
        v-for="(color, index) in colors"
        :key="index"
        class="m-2"
      >
        <span>{{ index }}</span>
        <cvt-switch
          :color="color"
          :description-type="desc_type"
          size="md"
          type="rounded"
          :initial-state="true"
        />
      </div>
    </div>
  </div>
</div>
