<template>
  <span>
    <material-icon v-if="set === 'material-icon'" :selector="selector" />
    <font-awesome-icon
      v-if="set === 'font-awesome-icon'"
      :selector="selector"
    />
    <themify-icon v-if="set === 'themify-icon'" :selector="selector" />
  </span>
</template>
<script lang="ts">
import FontAwesomeIcon from './IconFontAwesome.vue'
import MaterialIcon from './IconMaterial.vue'
import ThemifyIcon from './IconThemify.vue'

export default {
  name: 'IconTemplate',
  components: {
    MaterialIcon,
    FontAwesomeIcon,
    ThemifyIcon,
  },
  props: {
    selector: {
      required: true,
      type: String,
    },
    set: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return ['font-awesome-icon', 'material-icon', 'themify-icon'].includes(
          value,
        )
      },
    },
  },
}
</script>
