import DragHelper from './helpers/dragHelper'
import { CvtTooltip } from '../tooltip/CvtTooltip'
import { LIONDESK, LEFT_SIDEBAR_STANDARD, LEFT_SIDEBAR_FRESHWORKS, FRESHWORKS } from '../../constants'
import {
  defaultValue,
  nextCustomValue,
  prevCustomValue,
  round,
  roundToCustom,
  valuePercent
} from './helpers/utils'
import idMixin from '../../../lib/mixins/id'
import { styled, VueEmotion } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import Vue from '../../../lib/utils/vue'

let Spacing, Radii, Border
let backgroundColor, borderColor, textFontSize, textFontWeight, color, elevation
let railHeight, knobSize, stopSize
Vue.use(VueEmotion)

const getSlider = () => styled('span')`
  display: flex;
  padding: 0 ${Spacing.SPACING_8}px;
  width: 100%;

  &.disabled {
    opacity: 0.7;
  }

  &.disabled .slider__knob {
    cursor: not-allowed;
  }
`

const getSliderContainer = () => styled('span')`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`

const getSliderInner = () => styled('span')`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
`

const getSliderRail = () => styled('span')`
  position: absolute;
  top: 50%;
  left: 0;
  height: ${railHeight};
  border-radius: ${Radii.RADIUS_PX_4}px;
  transform: translateY(-50%);
  width: 100%;
  transition: all 0.1s;
  ${(props) =>
    props.themeing == 'light'
      ? backgroundColor({ themeing: 'dark' }, 500)
      : backgroundColor(props, 100)}
`

const getSliderFill = () => styled('span')`
  position: absolute;
  top: 50%;
  left: 0;
  height: ${railHeight};
  border-radius: ${Radii.RADIUS_PX_4}px;
  transform: translateY(-50%);
  transition: all 0.1s;
  ${(props) => backgroundColor(props, 500)}
`

// box-sizing: border-box;
const getSliderKnob = () => styled('span')`
  position: absolute;
  top: 50%;
  left: 0;  
  height: ${knobSize};
  width: ${knobSize};
  border: ${Border.SIZE_1}px solid;
  border-radius: ${Radii.RADIUS_PERCENT_50}%;
  transform: translate(-30%, -50%);
  cursor: pointer;
  z-index: 1;
  transition: all 0.1s;
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  ${(props) => borderColor(props, 500)}
  ${(props) => elevation(props, 300)}
`

const getSliderStop = () => styled('span')`
  width: ${stopSize};
  height: ${stopSize};
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: ${Radii.RADIUS_PERCENT_50}%;
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}

  span {
    position: relative;
    top: 8px;
    left: -1px;
    ${textFontSize('md')}
    ${textFontWeight()}
    ${(props) => color(props, 500)}
  }
`

const getSliderHiddenInput = () => styled('input')`
  display: none;
`

export default {
  name: 'CvtSlider',
  components: {
    DragHelper,
    CvtTooltip
  },
  props: {
    id: {
      type: String,
      default: null
    },
    name: String,
    value: {
      type: [Number, Array],
      validator (value) {
        return (
          (Array.isArray(value) && value.length === 2) ||
          typeof value === 'number'
        )
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: [String, Number],
      default: 0
    },
    max: {
      type: [String, Number],
      default: 100
    },
    step: {
      type: [String, Number],
      default: 1
    },
    percent: {
      type: Boolean,
      default: false
    },
    marks: {
      type: Object,
      default: null
    },
    inputSize: {
      type: Number,
      default: 180
    },
    debounce: {
      type: Number,
      default: 300
    },
    tooltipsEnabled: {
      type: Boolean,
      default: false
    },
    customStepsEnabled: {
      type: Boolean,
      default: false
    },
    stepMarksEnabled: {
      type: Boolean,
      default: false
    },
    markLabelsEnabled: {
      type: Boolean,
      default: false
    },
    rangeEnabled: {
      type: Boolean,
      default: false
    },
    inputEnabled: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'LIGHT'
    },
    color: {
      type: String,
      default: ''
    }
  },
  mixins: [idMixin],
  data () {
    return {
      showTooltip: false,
      showRangeTooltip: false,
      actualValue: null,
      rangeValue: null,
      dragStartValue: null,
      isRangeDragged: false,
      minStep: 10,
      tooltipDisabled: false,
      offsetWidth: null
    }
  },
  created () {
    this.parseValue()
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    color = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    elevation = this.elevation
    railHeight = `${Spacing.SPACING_4}px`
    knobSize = `${Spacing.SPACING_18}px`
    stopSize = `${Spacing.SPACING_4}px`
  },
  mounted () {
    const { offsetWidth } = this.$refs.inner
    this.offsetWidth = offsetWidth
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      elevation: getters.GLOBAL_STYLE_ELEVATION
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      OrgName: ({ globalTheme }) => globalTheme?.OrgName !== undefined ? globalTheme.OrgName : LEFT_SIDEBAR_STANDARD,
    }),
    _min () {
      return this.customStepsEnabled ? this._customSteps[0] : Number(this.min)
    },
    _max () {
      return this.customStepsEnabled
        ? this._customSteps[this._customSteps.length - 1]
        : Number(this.max)
    },
    _step () {
      return Number(this.step)
    },
    _customSteps () {
      return (
        this.marks &&
        Object.keys(this.marks)
          .map((v) => parseInt(v))
          .sort((p, n) => (p < n ? -1 : p > n ? 1 : 0))
      )
    },

    _stops () {
      if (this.OrgName === FRESHWORKS) return
      if (!this.marks && !this.stepMarksEnabled) return
      const stops = []
      if (this.marks) {
        for (const key in this.marks) {
          let label = key
          let style = {}
          if (typeof this.marks[key] === 'object') {
            label = this.marks[key].label || key
            style = this.marks[key].style
          }
          stops.push({
            value: valuePercent(key, this._min, this._max),
            label,
            style
          })
        }
      } else if (this.stepMarksEnabled && this._step) {
        let stopsNum = parseInt((this._max - this._min) / this.step)
        let increment = this.min
        while (stopsNum >= 0) {
          stops.push({
            value: valuePercent(increment, this._min, this._max),
            label: increment
          })
          increment += this._step
          stopsNum--
        }
      }
      return stops.length > 0 && stops
    },
    _knobId () {
      return this.getUID()
    },
    _rangeKnobId () {
      return this.getUID()
    },
    valuePercent () {
      return valuePercent(this.actualValue, this._min, this._max)
    },
    valuePercentRange () {
      return valuePercent(this.rangeValue, this._min, this._max)
    },
    rangeFillPercent () {
      return Math.abs(this.valuePercentRange - this.valuePercent)
    },
    sliderDisabled () {
      return this.disabled
    },
    showInput () {
      return this.inputEnabled && !this.rangeEnabled
    }
  },
  watch: {
    rangeEnabled () {
      this.parseValue()
    },
    value (newValue) {
      this.parseValue()
    },
    min () {
      this.actualValue = this.round(this.actualValue)
    },
    max () {
      this.actualValue = this.round(this.actualValue)
    }
  },
  methods: {
    dragStart (event, offset /*{ left: number, top: number }*/) {
      // detect knob been dragged to assign current position
      // to verify on drag end
      const targetId = (event.target && event.target.id) || null
      if (targetId === this.$refs.knob.id) {
        // tooltip triggers hide event automatically
        this.showTooltip = this.tooltipsEnabled
        this.dragStartValue = this.actualValue
        this.isRangeDragged = false
        return
      } else if (this.$refs.rangeKnob && targetId === this.$refs.rangeKnob.id) {
        // tooltip triggers hide event automatically
        this.showRangeTooltip = this.tooltipsEnabled
        this.dragStartValue = this.rangeValue
        this.isRangeDragged = true
        return
      }
      // If the click is out of knob, move it to mouse position
      // this.drag(event, offset)
    },
    drag (event, offset /*{ left: number, top: number }*/) {
      const { offsetWidth } = this.$refs.inner
      // detect which knob is been dragged
      let targetValue = this.isRangeDragged ? this.rangeValue : this.actualValue
      targetValue = this.round(this.valueFromBounds(offset.left, offsetWidth))
      this.setDropValue(targetValue, this.isRangeDragged)
    },
    dragEnd (event, offset /*{ left: number, top: number }*/) {
      const { offsetWidth } = this.$refs.inner
      const evalPosFn = this.customStepsEnabled
        ? this.roundToCustom
        : this.round
      // detect which knob is been dragged
      let targetValue = this.isRangeDragged ? this.rangeValue : this.actualValue
      targetValue = evalPosFn(this.valueFromBounds(offset.left, offsetWidth))
      if (this.dragStartValue !== this.actualValue) {
        this.setDropValue(targetValue, this.isRangeDragged, true)
      }
    },
    setDropValue (value, isRangeKnob, isDragEnd = false) {
      if (this.rangeEnabled) {
        if (isRangeKnob) {
          let minRange = this.actualValue + this.step
          if (this.customStepsEnabled && isDragEnd) {
            minRange = nextCustomValue(this.actualValue, this._customSteps)
            value <= minRange
              ? (this.rangeValue = minRange)
              : (this.rangeValue = value)
          } else {
            value < minRange
              ? (this.rangeValue = minRange)
              : (this.rangeValue = value)
          }
        } else {
          let maxRange = this.rangeValue - this.step
          if (this.customStepsEnabled && isDragEnd) {
            maxRange = prevCustomValue(this.rangeValue, this._customSteps)
            value >= maxRange
              ? (this.actualValue = maxRange)
              : (this.actualValue = value)
          } else {
            value > maxRange
              ? (this.actualValue = maxRange)
              : (this.actualValue = value)
          }
        }
        isDragEnd
          ? this.emitChange([this.actualValue, this.rangeValue])
          : this.emitInput([this.actualValue, this.rangeValue])
      } else {
        this.actualValue = value
        isDragEnd
          ? this.emitChange(this.actualValue)
          : this.emitInput(this.actualValue)
      }
    },
    emitInput (value) {
      this.$emit('input', value)
    },
    emitChange (value) {
      this.$emit('change', value)
    },
    valueFromBounds (point, width) {
      return (point / width) * (this._max - this._min) + this._min
    },
    roundToCustom (value) {
      return roundToCustom(value, this._min, this._max, this._customSteps)
    },
    round (value) {
      return round(value, this._min, this._max, this._step)
    },
    parseValue () {
      const { _min: min, _max: max } = this
      if (this.rangeEnabled) {
        if (!Array.isArray(this.value)) {
          console.error(
            `Invalid Value provided for Range slider [${this.value}]. Initializing with default values`
          )
          this.actualValue = min
          this.rangeValue = max
        } else {
          const value = this.value
          let [actualValue, rangeValue] = value
          actualValue = defaultValue(
            actualValue,
            min
          )(this.customStepsEnabled ? this.roundToCustom : this.round)
          rangeValue = defaultValue(
            rangeValue,
            max
          )(this.customStepsEnabled ? this.roundToCustom : this.round)

          if (actualValue > rangeValue) {
            console.error(
              `Invalid Value provided for Range slider. Alternating Start and End Value`
            )
            this.actualValue = rangeValue
            this.rangeValue = actualValue
          } else if (actualValue === rangeValue) {
            console.error(
              `Invalid Value provided for Range slider. Values can't be equal, setting end value to max`
            )
            this.actualValue = actualValue
            this.rangeValue = max
          } else {
            this.actualValue = actualValue
            this.rangeValue = rangeValue
          }
        }
      } else {
        if (Array.isArray(this.value))
          throw new Error('Invalid Value provided for Single Value Slider')
        this.actualValue = defaultValue(
          this.value,
          min
        )(this.customStepsEnabled ? this.roundToCustom : this.round)
      }
    }
  },
  render: function (h) {
    const Slider = getSlider()
    const SliderContainer = getSliderContainer()
    const SliderInner = getSliderInner()
    const SliderHiddenInput = getSliderHiddenInput()
    const SliderRail = getSliderRail()
    const SliderFill = getSliderFill()
    const SliderKnob = getSliderKnob()
    const SliderStop = getSliderStop()

    return (
      <Slider class={{ disabled: this.disabled }} id={this.id}>
        <SliderContainer>
          <drag-helper
            disabled={this.disabled}
            onDragstart={(e, off) => this.dragStart(e, off)}
            onDrag={this.drag}
            onDragend={this.dragEnd}
          >
            <SliderInner ref='inner'>
              <SliderHiddenInput
                type='text'
                name={this.name}
                value={this.actualValue}
                disabled={this.disabled}
              ></SliderHiddenInput>

              <SliderRail themeing={this.color}></SliderRail>

              {this.rangeEnabled ? (
                <div>
                  <SliderFill
                    style={{
                      left: this.valuePercent + '%',
                      width: this.rangeFillPercent + '%'
                    }}
                    themeing={this.color}
                  ></SliderFill>
                  <SliderKnob
                    mode={this.mode}
                    class='slider__knob'
                    id={this._knobId}
                    ref='knob'
                    themeing={this.color}
                    style={{ left: this.valuePercent + '%' }}
                  >
                    {this.$slots.default}
                  </SliderKnob>
                  <SliderKnob
                    mode={this.mode}
                    class='slider__knob'
                    id={this._rangeKnobId}
                    ref='rangeKnob'
                    themeing={this.color}
                    style={{ left: this.valuePercentRange + '%' }}
                  >
                    {this.$slots.default}
                  </SliderKnob>

                  {this.tooltipsEnabled && (
                    <cvt-tooltip
                      placement='top'
                      offset='5'
                      boundary-padding='5'
                      show={this.showTooltip}
                      fallbackPlacement='right'
                      ref='tooltip'
                      target={this._knobId}
                      delay='600'
                      color='dark'
                      mode={this.mode}
                    >
                      {typeof this.actualValue == 'number' && this.actualValue % 1 != 0 ? this.actualValue.toFixed(1) : this.actualValue}
                    </cvt-tooltip>
                  )}

                  {this.tooltipsEnabled && (
                    <cvt-tooltip
                      placement='top'
                      offset='5'
                      boundary-padding='5'
                      show={this.showRangeTooltip}
                      fallbackPlacement='right'
                      ref='tooltip'
                      target={this._rangeKnobId}
                      delay='600'
                      color='dark'
                      mode={this.mode}
                    >
                      {typeof this.rangeValue == 'number' && this.rangeValue % 1 != 0 ? this.rangeValue.toFixed(1) : this.rangeValue}
                    </cvt-tooltip>
                  )}
                </div>
              ) : (
                  <div>
                    <SliderFill
                      style={{ width: this.valuePercent + '%' }}
                      themeing={this.color}
                    ></SliderFill>

                    <SliderKnob
                      mode={this.mode}
                      class='slider__knob'
                      id={this._knobId}
                      ref='knob'
                      themeing={this.color}
                      style={{ left: this.valuePercent + '%' }}
                    >
                      {this.$slots.knob}
                    </SliderKnob>

                    {this.tooltipsEnabled && (
                      <cvt-tooltip
                        placement='top'
                        offset='5'
                        boundary-padding='5'
                        show={this.showTooltip}
                        fallbackPlacement='right'
                        ref='tooltip'
                        target={this._knobId}
                        delay='600'
                        color='dark'
                        mode={this.mode}
                      >
                        {typeof this.actualValue == 'number' && this.actualValue % 1 != 0 ? this.actualValue.toFixed(1) : this.actualValue}
                      </cvt-tooltip>
                    )}
                  </div>
                )}

              {this._stops &&
                this._stops.map((stop) => {
                  return (
                    <SliderStop
                      themeing={this.color}
                      style={{ left: stop.value + '%' }}
                    >
                      {this.markLabelsEnabled && (
                        <span domPropsInnerHTML={stop.label}></span>
                      )}
                    </SliderStop>
                  )
                })}
            </SliderInner>
          </drag-helper>
        </SliderContainer>
      </Slider>
    )
  }
}
