import * as getters from '../getters'
import * as mutations from '../mutations'
import * as actions from '../actions'
import { integrationsList } from '../d';
import { getIntegrationAPI } from "@/js/store/modules/builderIntegration/integrations";

// interface Integration {
//   image: string;
//   complete: boolean;
//   title: string;
//   description: string;
// }
//
// interface State {
//   integrationsData: Integration[];
//   currentScreen: string;
//   currentAuthStep: string;
//   selectedItem: Integration | null;
// }

const integrationsData = [...integrationsList];

export default {
  state: {
    integrationsData,
    selectedItem: null,
    currentIntegrationAPI: null
  },
  getters: {
    [getters.GET_BUILDER_INTEGRATIONS_DATA]({ integrationsData }) {
      return integrationsData
    },
    [getters.SELECTED_INTEGRATION]({ selectedItem }) {
      return selectedItem
    },
    [getters.GET_PROVIDER_API_SERVICE]({ currentIntegrationAPI }) {
      return currentIntegrationAPI
    },
  },
  mutations: {
    [mutations.SELECT_INTEGRATION](state, selectedItem) {
      state.selectedItem = selectedItem
      state.currentIntegrationAPI = selectedItem ? getIntegrationAPI(selectedItem.key) : null
    },
  },
  actions: {
    [actions.UPDATE_BUILDER_INTEGRATIONS_DATA]({ commit, state }, data) {
      return state.integrationsData = data;
    },
    [actions.SELECT_INTEGRATION]({ commit, state }, selectedItem) {
      commit(mutations.SELECT_INTEGRATION, selectedItem)
    },
  },
}