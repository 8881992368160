import { DefaultIntegrationAPI } from "./base";
import { spreadsheetDetails, spreadsheetsData } from "./mock";


interface FieldMapping {
  id: string;
}

interface CreateSheetRequest {
  title: string;
  columnNames: string[]; // columnNames are the fields the user selects, like email, name
  workSheetName: string;
  siteID: string
  formId: string
}

interface UpdateWorksheetRequest {
  spreadsheetId: string;
  columnNames: string[];
  workSheetName: string;
  siteID: string
  formId: string
}

interface TriggerMockEventRequest {
  workSheetName: string;
  spreadsheetId: string;
  data: string[][];
}

interface SaveIntegrationMappingRequest {
  spreadsheetId: string;
  workSheetName: string;
  fieldMappings: FieldMapping[];
}

export class GoogleIntegrationAPI extends DefaultIntegrationAPI{
  async getMappedSetup (siteId: string, formId: string): Promise<{name: string, field: string}[]> {
    const { data } = await this.api.get(`/v1/sites/${siteId}/${formId}/getIntegrationMapping`)
    return data
  }

  async createNewSheet(data: CreateSheetRequest): Promise<any> {
    const res = await this.api.post(
      '/v1/integrations/googleSheet/create', data
    );
    return res.data;
  }

  async updateWorksheet(data: UpdateWorksheetRequest): Promise<any> {
    const res = await this.api.put(
      '/v1/integrations/googleSheet/use-existing-worksheet', data
    );
    return res.data;
  }

  // async triggerMockEvent(data: TriggerMockEventRequest): Promise<any> {
  //   const res = await this.api.post('/v1/integrations/googleSheet/trigger-mock-event', data);
  //   return res.data;
  // }

  async getSpreadsheetById(id: string): Promise<any> {
    const res = await this.api.get(`/v1/integrations/googleSheet/list/${id}`);
    return res.data;
  }

  async getAllSpreadsheets(): Promise<any> {
    const res = await this.api.get('/v1/integrations/googleSheet/list');
    return res.data;
  }

  async saveIntegrationMapping(siteId: string, formId: string, data: SaveIntegrationMappingRequest): Promise<any> {
    const res = await this.api.post(`/api/v1/sites/${siteId}/${formId}/saveIntegrationMapping`, data);
    return res.data;
  }
}