import { DefaultIntegrationAPI } from "./base";

interface MappingFields{
  integrationFields: {name: string, label: string}[]
  standardFields: {id: string, label: string}[]
}

export class HubSpotAPI extends DefaultIntegrationAPI{
  async getMappingFields (): Promise<MappingFields> {
    const { data } = await this.api.get(`/v1/integrations/hubspot/form/fields`)
    return data
  }

  async getMappedFields (siteId: string, formId: string): Promise<{name: string, field: string}[]> {
    const { data } = await this.api.get(`/v1/sites/${siteId}/${formId}/getIntegrationMapping`)
    return data
  }

  async updateMappedFields (data: {name: string, field: string}[], siteId: string, formId: string): Promise<MappingFields> {
    const res = await this.api.post(
      `/v1/sites/${siteId}/${formId}/saveIntegrationMapping?provider=hubspot`, {data}
    )
    return res.data
  }
}