
  <div class="font-family-list" :style="styleHeight">
    <div
v-for="font in availableFonts"
         :key="font"
         class="font-family-list__item"
         :class="fontIsSelected(font)"
         :style="fontItemStyle(font)"
         @click.prevent.stop="selectFontFamily(font)"
         v-text="font.familyName"></div>
  </div>
