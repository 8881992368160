<template>
  <div>
    <div v-if="visible" class="loader-wrapper">
      <component
        :is="currentLoader"
        :msg="msg"
        :svg="svg"
        :svg-path="svgPath"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as loaders from './loaders/index.vue'

const loaderList = {
  editor: loaders.EditorLoader,
  '': loaders.DefaultLoader,
}

export default {
  name: 'Loader',
  data() {
    return {
      svgPath: 'undefined',
    }
  },
  computed: {
    ...mapState('loader', ['visible', 'msg', 'svg', 'route']),
    currentLoader() {
      return loaderList[this.route] || loaderList['']
    },
  },
  created() {
    this.svgPath = sessionStorage.getItem('SVG_ICON')
  },
  updated() {
    this.svgPath = sessionStorage.getItem('SVG_ICON')
  },
}
</script>

<style lang="scss">
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  background: #fefefe;
  color: #373737;
  // font-family: "Nunito", Arial, sans-serif;

  .full-height {
    height: 100%;
  }

  .loader-animation {
    width: 100%;
  }

  .loader-message {
    // font-family: "Rokkitt", sans-serif;
    letter-spacing: 0.05em;
    font-size: 30px;
    line-height: 1.2;
    text-align: center;
    margin: 0 auto 0.25em;
  }
  .external-svg {
    width: 100%;
    min-height: 15em;
    text-align: center;
  }
  .img-style {
    height: 32px;
    width: 32px;
  }
}
</style>
