import * as _ from 'lodash'
import * as constants from '../../../builder/src/js/common/constants/constants'
import * as getters from '../../../builder/src/js/store/modules/getters'
import Vue from '../../lib/utils/vue'

import Button from '../molecules/Button'
import CvtRadioButton from '../molecules/radio-group/RadioButton'
import CvtRadioGroup from '../molecules/radio-group/RadioGroup'
import CvtSelect from '../molecules/Select'

import { styled } from '@egoist/vue-emotion'
import VueI18n from 'vue-i18n'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'

Vue.use(VueI18n)

let Spacing, Border, Colors, textLineHeight, BorderStyle, borderColor, backgroundColor, textColor, FontFamily, FontSize, FontWeight

const getElementWrapper = () => styled('div')`
  ${(props) => {
    let style = ``

    if (props.height) {
      style += `height: ${props.height};`
    }

    if (props.width) {
      style += `width: ${props.width};`
    }

    return style
  }}
  font-family: ${FontFamily.DEFAULT};
`

const getMainWrapper = () => styled('div')`
  width: 100%;
`
const getFlexDiv = () => styled('div')`
  display: flex;
`

const getHeadingWrapper = () => styled('div')`
  width: 100%;
  border-bottom-width: ${Border.SIZE_1}px;
  border-bottom-style: solid;
  border-bottom-color: ${Colors.BASIC[200]};
`

const getHeading = () => styled('h1')`
  font-family: ${FontFamily.DEFAULT};
  font-weight: ${FontWeight.SEMI_BOLD};
  font-size: ${FontSize.FONT_SIZE_34 + FontSize.FONT_SIZE_10}px;
  margin-bottom: ${Spacing.SPACING_8}px;
`

const getSubHeading = () => styled('h3')`
  font-family: ${FontFamily.DEFAULT};
  font-size: ${FontSize.FONT_SIZE_14}px;
  margin-bottom: ${Spacing.SPACING_32 - Spacing.SPACING_2}px;
`

const getSectionWrapper = () => styled('div')`
  margin-top: ${Spacing.SPACING_32}px;
  display: flex;
  flex-direction: column;
`

const getSectionLeftHeading = () => styled('div')`
  font-size: ${FontSize.FONT_SIZE_14}px;
  font-weight: ${FontWeight.SEMI_BOLD};
  font-family: ${FontFamily.DEFAULT};
  ${textColor({ themeing: 'dark' }, 500)}
  ${textLineHeight('xl')}
  width: ${Spacing.SPACING_96 + Spacing.SPACING_48}px;
  margin-bottom: 25px;
`

const getSectionRightContentWrapper = () => styled('div')`
  width: 100%;
  padding-bottom: ${Spacing.SPACING_32}px;
  border-bottom-width: ${Border.SIZE_1}px;
  border-bottom-style: solid;
  border-bottom-color: ${Colors.BASIC[200]};

  &:last-child {
    border-bottom-width: 0px;
    padding-bottom: 0px;
  }
`

const getSectionRightContentHeading = () => styled('div')`
  font-size: ${FontSize.FONT_SIZE_14}px;
  font-weight: ${FontWeight.REGULAR};
  font-family: ${FontFamily.DEFAULT};
  ${textColor({ themeing: 'dark' }, 400)}
  ${textLineHeight('xl')}
  margin-bottom: ${Spacing.SPACING_16}px;
`

const getSpecificOptionContentWrapper = () => styled('div')`
  width: 100%;
  margin-top: ${Spacing.SPACING_32}px;
  padding: ${Spacing.SPACING_24}px ${Spacing.SPACING_12}px 0px
    ${Spacing.SPACING_12}px;
  border: ${Border.SIZE_1}px solid ${Colors.BASIC[200]};
`

const getSpecificOptionHeading = () => styled('div')`
  font-size: ${FontSize.FONT_SIZE_14}px;
  font-weight: ${FontWeight.REGULAR};
  font-family: ${FontFamily.DEFAULT};
  ${textColor({ themeing: 'dark' }, 400)}
  ${textLineHeight('xl')}
  margin-bottom: ${Spacing.SPACING_24}px;
  margin-left: 10px;
`

const getSpecificOptionInnerWrapper = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: center;
`

const getSpecificOptionSelectInnerWrapper = () => styled('div')`
  width: 95%;
  display: flex;
  align-items: center;
`

const getSpecificOptionPlusBtnInnerWrapper = () => styled('div')`
  margin-left: 10px;
`

const getIconButton = () => styled(Button)`
  font-family: ${FontFamily.DEFAULT};
  text-align: center;
  font-style: normal;
`

const getAddBtn = () => styled('div')`
  ${backgroundColor({ themeing: 'primary' }, 500)}
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding-bottom: 2px;
`

const getMinusSign = () => styled('span')`
  width: 9px;
  height: 1.5px;
  background: #fff;
`

const getSubtractBtn = () => styled('div')`
  ${backgroundColor({ themeing: 'basic' }, 300)}
  cursor: pointer;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  margin-bottom: 8px;
`

const getUrlConditionsWrapper = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
`

const getUrlConditionsHeading = () => styled('div')`
  font-size: ${FontSize.FONT_SIZE_14}px;
  font-weight: ${FontWeight.REGULAR};
  font-family: ${FontFamily.DEFAULT};
  ${textColor({ themeing: 'dark' }, 400)}
  ${textLineHeight('xl')}
  margin-bottom: ${Spacing.SPACING_18 + Spacing.SPACING_4}px;
`

const getUrlConditionsInnerContentWrapper = () => styled('div')`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 7px;
  margin-bottom: ${Spacing.SPACING_18 + Spacing.SPACING_4}px;
`

const getInput = () => styled('input')`
  height: 34px;
  width: ${(props) => props.width || '50px'};
  border: ${Border.SIZE_1}px ${BorderStyle.SOLID};
  ${borderColor({ themeing: 'primary' }, 500)}
  padding: 12px 10px;
  ${textColor({ themeing: 'basic' }, 500)}
  border-radius: 4px;
  font-weight: 300;
  font-size: 12px;
  margin: 0 12px;

  &:focus {
    outline: none;
  }
`

const getFilterLabel = () => styled('span')`
  display: flex;
  align-items: center;
`

const getCvtSelect = () => styled(CvtSelect)`
  margin: 0 10px;
  div {
    ${borderColor({ themeing: 'primary' }, 500)}
    width: ${(props) => props.width || '100%'};
  }
  input {
    height: 22px;
    margin-right: 0px;
    padding-right: 0px;
    font-size: 12px;
  }
`

export const CvtSapAudience = {
  name: 'CvtSapAudience',
  components: {
    CvtRadioGroup,
    CvtRadioButton,
    CvtSelect,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
      required: false,
    },
    showVisitorTypes: {
      type: Boolean,
      default: false,
    },
    showTrafficSources: {
      type: Boolean,
      default: false,
    },
    showEventConditions: {
      type: Boolean,
      default: false,
    },
    showUrlConditions: {
      type: Boolean,
      default: false,
    },
    visitorTypes: {
      type: String,
      default: 'all',
      required: true,
    },
    specificVisitorTypes: {
      type: Array,
      default: () => [],
      required: true,
    },
    trafficSources: {
      type: String,
      default: 'all',
      required: true,
    },
    specificTrafficSources: {
      type: Array,
      default: () => [],
      required: true,
    },
    eventConditions: {
      type: String,
      default: 'off',
      required: true,
    },
    urlConditions: {
      type: String,
      default: 'disabled',
      required: true,
    },
  },
  data() {
    return { }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      BorderStyle: ({ globalTheme }) => globalTheme.BorderStyle,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
    }),
    specificVisitorTypesArray: {
      get() {
        return this.specificVisitorTypes
      },
      set(value) {
        this.$emit('specificVisitorAdded', value)
      }
    },
    specificTrafficSourcesArray: {
      get() {
        return this.specificTrafficSources
      },
      set(value) {
        this.$emit('specificTrafficSourcesAdded', value)
      }
    },
  },
  created() {
    Spacing = this.Spacing
    Border = this.Border
    Colors = this.Colors
    BorderStyle = this.BorderStyle
    FontFamily = this.FontFamily
    borderColor = this.borderColor
    textColor = this.textColor
    backgroundColor = this.backgroundColor
    textLineHeight = this.textLineHeight
    FontSize = this.FontSize
    FontWeight = this.FontWeight
  },
  methods: {
    onVisitorTypesChange(value) {
      this.$emit('visitorTypesChanged', value)
    },
    onTrafficSourcesChange(value) {
      this.$emit('trafficSourcesChanged', value)
    },
    addNewVisitorField(fields) {
      fields.push({ visitorType: 'newVisitors' })

      // it would re-renders the list
      this.specificVisitorTypesArray = fields
    },
    removeVisitorField(index, fields) {
      fields.splice(index, 1)

      // it would re-renders the list
      this.specificVisitorTypesArray = fields
    },
    addNewTrafficField(fields) {
      fields.push({ trafficSource: 'dontShow' })

      // it would re-renders the list
      this.specificTrafficSourcesArray = fields
    },
    removeTrafficField(index, fields) {
      fields.splice(index, 1)

      // it would re-renders the list
      this.specificTrafficSourcesArray = fields
    },
    onVisitorTypeInputChange(value, idx, fieldName, fieldArr) {
      const item = fieldArr.findIndex((_, index) => idx === index)

      if (item >= 0) {

        if (fieldName === 'visitorType') {
          if(value === constants.RETURNING_VISITORS) {
            fieldArr[item]['visited'] = constants.EQUALLY
            fieldArr[item]['numberOfTimes'] = 1
            fieldArr[item]['lastNumberOfTimes'] = 1
            fieldArr[item]['betweenNumberOfTimes'] = null
            fieldArr[item]['duration'] = constants.DAY
          } 
          else if (value === constants.NEW_VISITORS) {
            fieldArr[item]['visited'] = null
            fieldArr[item]['numberOfTimes'] = null
            fieldArr[item]['lastNumberOfTimes'] = null
            fieldArr[item]['betweenNumberOfTimes'] = null
            fieldArr[item]['duration'] = null
          }
        }

        if (fieldName === 'visited') {
          if (value === constants.EQUALLY || value === constants.LESS_THAN || value === constants.MORE_THAN) {
            fieldArr[item]['betweenNumberOfTimes'] = null
          }

          if (value === 'between') {
            fieldArr[item]['betweenNumberOfTimes'] = 1
          }
        }

        if (fieldName === 'duration') {
          if (value === constants.MONTH) {
            fieldArr[item]['lastNumberOfTimes'] = null
          } else {
            fieldArr[item]['lastNumberOfTimes'] = 1
          }
        }

        fieldArr[item][fieldName] = value

        // it would re-renders the list
        this.specificVisitorTypesArray = _.cloneDeep(this.specificVisitorTypesArray)
      }
    },
    onTrafficSourceInputChange(value, idx, fieldName, fieldArr) {
      const item = fieldArr.findIndex((_, index) => idx === index)

      if (item >= 0) {

        if (fieldName === 'trafficSource') {
          if(value === constants.SHOW_TO) {
            fieldArr[item]['target'] = constants.ALL_REFERRALS
            fieldArr[item]['condition'] = null
            fieldArr[item]['url'] = null
          } 
          else if (value === constants.DONT_SHOW) {
            fieldArr[item]['target'] = null
            fieldArr[item]['condition'] = null
            fieldArr[item]['url'] = null
          }
        }

        if (fieldName === 'target') {
          if(value === constants.REFERRAL_URL) {
            fieldArr[item]['condition'] = constants.STARTS_WITH
            fieldArr[item]['url'] = ''
          } else {
            fieldArr[item]['condition'] = null
            fieldArr[item]['url'] = null
          }
        }

        fieldArr[item][fieldName] = value

        // it would re-renders the list
        this.specificTrafficSourcesArray = _.cloneDeep(this.specificTrafficSourcesArray)
      }
    },
    onEventConditionsChange(value) {
      this.$emit('eventConditionsChanged', value)
    },
    onUrlConditionsChange(value) {
      this.$emit('urlConditionsChanged', value)
    },
  },
  render: function (h) {
    const ElementWrapper = getElementWrapper()
    const MainWrapper = getMainWrapper()
    const HeadingWrapper = getHeadingWrapper()
    const FlexDiv = getFlexDiv()
    const Heading = getHeading()
    const SubHeading = getSubHeading()

    const SectionWrapper = getSectionWrapper()
    const SectionLeftHeading = getSectionLeftHeading()
    const SectionRightContentWrapper = getSectionRightContentWrapper()
    const SectionRightContentHeading = getSectionRightContentHeading()

    const SpecificOptionContentWrapper = getSpecificOptionContentWrapper()
    const SpecificOptionHeading = getSpecificOptionHeading()

    const SpecificOptionInnerWrapper = getSpecificOptionInnerWrapper()
    const SpecificOptionSelectInnerWrapper = getSpecificOptionSelectInnerWrapper()
    const SpecificOptionPlusBtnInnerWrapper = getSpecificOptionPlusBtnInnerWrapper()

    const PlusButton = getIconButton()
    const AddBtn = getAddBtn()
    const SubtractBtn = getSubtractBtn()
    const MinusSign = getMinusSign()
    const Input = getInput()
    const CvtSelectInput = getCvtSelect()
    const FilterLabel = getFilterLabel()

    const UrlConditionsWrapper = getUrlConditionsWrapper()
    const UrlConditionsHeading = getUrlConditionsHeading()
    const UrlConditionsInnerContentWrapper = getUrlConditionsInnerContentWrapper()

    const fieldData = {
      specificVisitors: this.specificVisitorTypesArray,
      specificTraffic: this.specificTrafficSourcesArray,
    }

    const renderAddBtn = (currIdx, tag, action) => {
      return (
        (currIdx === fieldData[tag].length - 1) ? (<AddBtn onClick={() => action(fieldData[tag], tag)}>+</AddBtn>) : null
      )
    }

    const renderSubtractBtn = (currIdx, tag, action) => {
      return (
        (fieldData[tag].length > 1) ? (<SubtractBtn onClick={() => action(currIdx, fieldData[tag])}><MinusSign></MinusSign></SubtractBtn>) : null
      )
    }

    return (
      <MainWrapper>
        <HeadingWrapper>
          <Heading>Select Your Audience</Heading>
          <SubHeading>
            You can select specific types of audiences as: traffic source, geo,
            browsers, new users etc.
          </SubHeading>
        </HeadingWrapper>

        {this.showVisitorTypes && (
          <SectionWrapper>
            <SectionLeftHeading>Visitor Types</SectionLeftHeading>

            <SectionRightContentWrapper>
              <SectionRightContentHeading>
                Which visitor types will see your campaign
              </SectionRightContentHeading>

              <ElementWrapper>
                <cvt-radio-group
                  theme={'LIGHT'}
                  orientation={'horizontal'}
                  initialValue={this.visitorTypes}
                >
                  <cvt-radio-button
                    size={'md'}
                    value={'all'}
                    onRadioClick={(value) => this.onVisitorTypesChange(value)}
                  >
                    All visitor types
                  </cvt-radio-button>
                  <cvt-radio-button
                    size={'md'}
                    value={'specific'}
                    onRadioClick={(value) => this.onVisitorTypesChange(value)}
                  >
                    Specific visitor types
                  </cvt-radio-button>
                </cvt-radio-group>
              </ElementWrapper>

              {this.visitorTypes === 'specific' && (
                <SpecificOptionContentWrapper>
                  
                  <SpecificOptionHeading>
                    Select specific visitors types to target
                  </SpecificOptionHeading>

                  {this.specificVisitorTypesArray.map((visitor, idx) => (
                    <SpecificOptionInnerWrapper>

                      <SpecificOptionSelectInnerWrapper>

                        <CvtSelectInput
                          multiple={false}
                          mode={'dropdown'}
                          selectDisabled={false}
                          floatingLabel={true}
                          showCancelIcon={false}
                          width={"150px"}
                          v-model={visitor.visitorType}
                          onInput={(value) => this.onVisitorTypeInputChange(value, idx, 'visitorType', this.specificVisitorTypesArray)}
                        >
                          <option value={constants.NEW_VISITORS}>New Visitors</option>
                          <option value={constants.RETURNING_VISITORS}>Returning visitors</option>
                        </CvtSelectInput>

                        {(visitor.visitorType === constants.RETURNING_VISITORS) && (
                          <FlexDiv>

                            <FilterLabel>visited</FilterLabel>
                            <CvtSelectInput
                              multiple={false}
                              mode={'dropdown'}
                              selectDisabled={false}
                              floatingLabel={true}
                              showCancelIcon={false}
                              width={"130px"}
                              v-model={visitor.visited}
                              onInput={(value) => this.onVisitorTypeInputChange(value, idx, 'visited', this.specificVisitorTypesArray)}
                            >
                              <option value={constants.EQUALLY}>Equally</option>
                              <option value={constants.LESS_THAN}>Less than</option>
                              <option value={constants.MORE_THAN}>More than</option>
                              <option value={constants.BETWEEN}>Between</option>
                            </CvtSelectInput>

                            <Input
                              type="number"
                              value={visitor.numberOfTimes}
                              onInput={(e) => this.onVisitorTypeInputChange(+e.target.value, idx, 'numberOfTimes', this.specificVisitorTypesArray)}
                            />

                            {visitor.visited === constants.BETWEEN && (
                              <FlexDiv>
                                <FilterLabel>to</FilterLabel>
                                <Input
                                  type="number"
                                  value={visitor.betweenNumberOfTimes}
                                  onInput={(e) => this.onVisitorTypeInputChange(+e.target.value, idx, 'betweenNumberOfTimes', this.specificVisitorTypesArray)}
                                />
                              </FlexDiv>
                            )}

                            <FilterLabel>last</FilterLabel>
                            {visitor.visited !== constants.MORE_THAN &&
                              visitor.duration !== constants.MONTH && (
                                <Input
                                  type="number"
                                  value={visitor.lastNumberOfTimes}
                                  onInput={(e) =>this.onVisitorTypeInputChange(+e.target.value, idx, 'lastNumberOfTimes', this.specificVisitorTypesArray)}
                                />
                            )}

                            {/* Duration */}
                            <CvtSelectInput
                              v-model={visitor.duration}
                              multiple={false}
                              mode={'dropdown'}
                              selectDisabled={false}
                              floatingLabel={true}
                              showCancelIcon={false}
                              width={"100px"}
                              onInput={(value) =>this.onVisitorTypeInputChange(value, idx, 'duration', this.specificVisitorTypesArray)}
                            >
                              <option value={constants.DAY}>Day</option>
                              <option value={constants.WEEK}>Week</option>
                              <option value={constants.MONTH}>Month</option>
                            </CvtSelectInput>
                          </FlexDiv>
                        )}

                      </SpecificOptionSelectInnerWrapper>

                      <SpecificOptionPlusBtnInnerWrapper>
                        { renderSubtractBtn(idx, 'specificVisitors', this.removeVisitorField) }
                        { renderAddBtn(idx, 'specificVisitors', this.addNewVisitorField) }
                      </SpecificOptionPlusBtnInnerWrapper>
                    </SpecificOptionInnerWrapper>
                  ))}

                </SpecificOptionContentWrapper>
              )}
            </SectionRightContentWrapper>
          </SectionWrapper>
        )}

        {this.showTrafficSources && (
          <SectionWrapper>
            <SectionLeftHeading>Traffic Sources</SectionLeftHeading>

            <SectionRightContentWrapper>
              <SectionRightContentHeading>
                Which kind of incoming traffic sources will see your campaign
              </SectionRightContentHeading>

              <ElementWrapper>
                <cvt-radio-group
                  theme={'LIGHT'}
                  orientation={'horizontal'}
                  initialValue={this.trafficSources}
                >
                  <cvt-radio-button
                    size={'md'}
                    value={'all'}
                    onRadioClick={(value) => this.onTrafficSourcesChange(value)}
                  >
                    All traffic sources
                  </cvt-radio-button>
                  <cvt-radio-button
                    size={'md'}
                    value={'specific'}
                    onRadioClick={(value) => this.onTrafficSourcesChange(value)}
                  >
                    Specific traffic sources
                  </cvt-radio-button>
                </cvt-radio-group>
              </ElementWrapper>

              {this.trafficSources === 'specific' && (
                <SpecificOptionContentWrapper>
                  <SpecificOptionHeading>
                    Select specific domain URLs to target
                  </SpecificOptionHeading>

                  {this.specificTrafficSourcesArray.map((trafficSources, idx) => (
                    <SpecificOptionInnerWrapper>
                      <SpecificOptionSelectInnerWrapper>
                        <CvtSelectInput
                          multiple={false}
                          mode={'dropdown'}
                          selectDisabled={false}
                          floatingLabel={true}
                          showCancelIcon={false}
                          width={"145px"}
                          v-model={trafficSources.trafficSource}
                          onInput={(value) => this.onTrafficSourceInputChange(value, idx, 'trafficSource', this.specificTrafficSourcesArray)}
                        >
                          <option value={constants.SHOW_TO}>Show to</option>
                          <option value={constants.DONT_SHOW}>Don't show</option>
                        </CvtSelectInput>

                        {trafficSources.trafficSource !== constants.DONT_SHOW && (
                          <FlexDiv>
                            <CvtSelectInput
                              multiple={false}
                              mode={'dropdown'}
                              selectDisabled={false}
                              floatingLabel={true}
                              showCancelIcon={false}
                              width={"145px"}
                              v-model={trafficSources.target}
                              onInput={(value) => this.onTrafficSourceInputChange(value, idx, 'target', this.specificTrafficSourcesArray)}
                            >
                              <option value={constants.REFERRAL_URL}>Referral URL</option>
                              <option value={constants.REFERRAL_QUERY}>Referral query</option>
                              <option value={constants.DIRECT}>Direct</option>
                              <option value={constants.ORGANIC}>Organic</option>
                              <option value={constants.PAID}>Paid</option>
                              <option value={constants.SOCIAL}>Social</option>
                              <option value={constants.EMAIL}>Email</option>
                              <option value={constants.ALL_REFERRALS}>All referrals</option>
                            </CvtSelectInput>

                            {trafficSources.target === constants.REFERRAL_URL && (
                              <FlexDiv>
                                <CvtSelectInput
                                  multiple={false}
                                  mode={'dropdown'}
                                  selectDisabled={false}
                                  floatingLabel={true}
                                  showCancelIcon={false}
                                  width={"145px"}
                                  v-model={trafficSources.condition}
                                  onInput={(value) => this.onTrafficSourceInputChange(value, idx, 'condition', this.specificTrafficSourcesArray)}
                                >
                                  <option value={constants.STARTS_WITH}>Starts with</option>
                                  <option value={constants.ENDS_WITH}>Ends with</option>
                                  <option value={constants.CONTAINS}>Contains</option>
                                  <option value={constants.MATCH}>Match</option>
                                </CvtSelectInput>

                                <Input
                                  value={trafficSources.url}
                                  type="text"
                                  placeholder="https://"
                                  width={'270px'}
                                  onInput={(e) => this.onTrafficSourceInputChange(e.target.value, idx, 'url', this.specificTrafficSourcesArray)}
                                />
                              </FlexDiv>
                            )}
                          </FlexDiv>
                        )}
                      </SpecificOptionSelectInnerWrapper>

                      <SpecificOptionPlusBtnInnerWrapper>
                        { renderSubtractBtn(idx, 'specificTraffic', this.removeTrafficField) }
                        { renderAddBtn(idx, 'specificTraffic', this.addNewTrafficField) }
                      </SpecificOptionPlusBtnInnerWrapper>
                    </SpecificOptionInnerWrapper>
                  ))}
                </SpecificOptionContentWrapper>
              )}
            </SectionRightContentWrapper>
          </SectionWrapper>
        )}

        {this.showEventConditions && (
          <SectionWrapper>
            <SectionLeftHeading>Event Conditions</SectionLeftHeading>

            <SectionRightContentWrapper>
              <SectionRightContentHeading>
                Select event conditions user must qualify to see your campaign
              </SectionRightContentHeading>
              <cvt-radio-group
                theme={'LIGHT'}
                orientation={'horizontal'}
                initialValue={this.eventConditions}
              >
                <cvt-radio-button
                  size={'md'}
                  value={'off'}
                  onRadioClick={(value) => this.onEventConditionsChange(value)}
                >
                  Off
                </cvt-radio-button>
                <cvt-radio-button
                  size={'md'}
                  value={'on'}
                  onRadioClick={(value) => this.onEventConditionsChange(value)}
                >
                  On
                </cvt-radio-button>
              </cvt-radio-group>
            </SectionRightContentWrapper>
          </SectionWrapper>
        )}

        {this.showUrlConditions && (
          <SectionWrapper>
            <SectionLeftHeading>URL Conditions</SectionLeftHeading>

            <SectionRightContentWrapper>
              <SectionRightContentHeading>Select URL condition that users must pass to see your campaign</SectionRightContentHeading>
              <cvt-radio-group
                theme={'LIGHT'}
                orientation={'horizontal'}
                initialValue={this.urlConditions}
              >
                <cvt-radio-button
                  size={'md'}
                  value={'disabled'}
                  onRadioClick={(value) => this.onUrlConditionsChange(value)}
                >
                  Disable
                </cvt-radio-button>
                <cvt-radio-button
                  size={'md'}
                  value={'enabled'}
                  onRadioClick={(value) => this.onUrlConditionsChange(value)}
                >
                  Enable
                </cvt-radio-button>
              </cvt-radio-group>

              {this.urlConditions === 'enabled' && (
                <SpecificOptionContentWrapper>
                  <UrlConditionsWrapper>

                    <UrlConditionsHeading>Show to users who</UrlConditionsHeading>
                    <UrlConditionsInnerContentWrapper>
                      <ElementWrapper width={'auto'}>
                        <PlusButton
                          mode={this.mode.toLowerCase()}
                          color={this.mode.toLowerCase()}
                          shape={'pill'}
                          size={'sm'}
                          icon={'plus'}
                          icononly={true}
                          onClick={(event) => {}}
                        ></PlusButton>
                      </ElementWrapper>
                      <ElementWrapper width={'auto'}>Add show URL condition</ElementWrapper>
                    </UrlConditionsInnerContentWrapper>

                  </UrlConditionsWrapper>

                  <UrlConditionsWrapper>

                    <UrlConditionsHeading>Exclude from users who</UrlConditionsHeading>
                    <UrlConditionsInnerContentWrapper>
                      <ElementWrapper width={'auto'}>
                        <PlusButton
                          mode={this.mode.toLowerCase()}
                          color={this.mode.toLowerCase()}
                          shape={'pill'}
                          size={'sm'}
                          icon={'plus'}
                          icononly={true}
                          onClick={(event) => {}}
                        ></PlusButton>
                      </ElementWrapper>
                      <ElementWrapper width={'auto'}>Add exclude URL condition</ElementWrapper>
                    </UrlConditionsInnerContentWrapper>

                  </UrlConditionsWrapper>
                </SpecificOptionContentWrapper>
              )}
            </SectionRightContentWrapper>
          </SectionWrapper>
        )}

      </MainWrapper>
    )
  },
}