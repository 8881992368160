
<div class="spacing-controls">
  <div class="row">
    <div
      v-if="OrgName === 'freshworks'"
      class="col-auto mx-auto"
    >
      <cvt-radio-group
        class="radio-group"
        orientation="horizontal"
        :initial-value="spacingMode"
      >
        <cvt-radio-button
          horizontal
          size="md"
          :value="$t('elements.properties.spacing.vertical')"
          color="primary"
          @radioClick="onSwitchToggle"
        >
          Vertical spacing
        </cvt-radio-button>
        <cvt-radio-button
          size="md"
          :value="$t('elements.properties.spacing.horizontal')"
          color="primary"
          @radioClick="onSwitchToggle"
        >
          Horizontal spacing
        </cvt-radio-button>
      </cvt-radio-group>
    </div>
    <div
      v-else
      class="col-auto mx-auto"
    >
      <cvt-switch
        size="md"
        color="grey"
        type="rounded"
        description-type="text"
        :inactive-text="$t('elements.properties.spacing.vertical')"
        :active-text="$t('elements.properties.spacing.horizontal')"
        :initial-state="direction == 'vertical'? false: true"
        :mode="mode"
        @switchToggle="onSwitchToggle"
      />
    </div>
  </div>
  <div class="row mt-3">
    <div class="col mx-auto">
      <spacing
        attr="margin"
        :direction="direction"
        hide-title
        show-remove
      />
      <div :class="DividerWithBorder ? 'divider' : 'divider-spacing'" />
      <spacing
        attr="padding"
        :direction="direction"
        hide-title
        show-remove
      />
    </div>
  </div>
</div>
