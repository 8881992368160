import ItemBaseMixin from './mixins/item-base'

import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import { CvtIcon } from '../icon/icon'
import CvtButton from '../Button'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import Vue from '../../../lib/utils/vue'
Vue.use(VueEmotion)

let FontFamily, Spacing, Radii, Border, Colors
let textFontWeight, color, backgroundColor, textFontSize, textLineHeight, elevation

const getCvtListItem = () => styled('li')`
  line-height: 1.8;
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  border-radius: ${Radii.RADIUS_PX_4}px;
  ${() => {
    let fontFamily = `font-family: ${FontFamily.DEFAULT};`
    let fontColor = `${color({ themeing: 'dark' }, 500)}`
    return fontFamily + fontColor + textFontSize() + textFontWeight()
  }}

  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:active {
    ${backgroundColor({ themeing: 'dark' }, 100)}
  }
  &:focus {
    outline: none;
  }

  &.__card {
    margin-bottom: ${Spacing.SPACING_8}px;
    ${elevation({ mode: 'LIGHT' }, 200)}

    &:hover {
      ${elevation({ mode: 'LIGHT' }, 400)}
    }
  }
`

const getCvtListItemWrapper = () => styled('div')`
  padding: ${Spacing.SPACING_16}px;
  ${(props) => {
    if (props.showSeparator) {
      return `border-bottom: ${Border.SIZE_1}px solid; border-color: ${Colors.BASIC[200]};`
    }
  }}
`

const getCvtItemTop = () => styled('div')``

const getCvtItemWrapper = () => styled('a')`
  display: flex;
  outline: none;
  ${color({ themeing: 'dark' }, 500)}
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`

const getCvtItemStart = () => styled('div')`
  margin-right: ${Spacing.SPACING_16}px;
`

const getCvtItemBody = () => styled('div')`
  flex: 1;
  margin: auto 0 auto;
  display: inline;
  white-space: normal;
`

const getCvtItemBodyTitle = () => styled('p')`
  ${textFontSize('lg')}
  ${textLineHeight('lg')}
  ${textFontWeight('regular')}
  ${color({ themeing: 'dark' }, 500)}
  margin: 0;
`

const getCvtItemBodyDesc = () => styled('p')`
  ${textFontSize('sm')}
  ${color({ themeing: 'dark' }, 300)}
  ${textFontWeight('regular')}
  margin: 0;
`

const getCvtItemEnd = () => styled('div')`
  display: inherit;
  margin-left: ${Spacing.SPACING_16}px;
  * {
    margin: auto;
  }
`

const getCvtItemThumbnail = () => styled('img')`
  vertical-align: middle;
  border-style: none;
  border-radius: ${Radii.RADIUS_PX_4}px;
  width: 64px;
  height: 64px;
  object-fit: cover;
`

const getCvtItemRemove = () => styled('div')`
  margin: auto;
`

const getCvtItemBottom = () => styled('div')``

const getClearButton = () => styled(CvtButton)``

export default {
  name: 'CvtItem',
  mixins: [ItemBaseMixin],
  props: {
    item: {
      type: [Object, File],
      default: null
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      elevation: getters.GLOBAL_STYLE_ELEVATION
    }),
    ...mapState('globalTheme', {
      FontFamily: ({globalTheme}) => globalTheme.FontFamily,
      Spacing: ({globalTheme}) => globalTheme.Spacing,
      Radii: ({globalTheme}) => globalTheme.Radii,
      Colors: ({globalTheme}) => globalTheme.Colors,
      Border: ({globalTheme}) => globalTheme.Border
    }),
    _closeIconClass() {
      return ['far', 'fa-times-circle']
    },
    _closeIconLabel() {
      return 'press delete to remove'
    }
  },
  methods: {
    onRemove(item) {
      this.$emit('remove', item)
    }
  },
  created() {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    Colors = this.Colors
    Border = this.Border

    textFontWeight = this.textFontWeight
    color = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    elevation = this.elevation
  },
  render: function (h) {
    const CvtListItem = getCvtListItem()
    const CvtListItemWrapper = getCvtListItemWrapper()
    const CvtItemTop = getCvtItemTop()
    const CvtItemWrapper = getCvtItemWrapper()
    const CvtItemStart = getCvtItemStart()
    const CvtItemBody = getCvtItemBody()
    const CvtItemBodyTitle = getCvtItemBodyTitle()
    const CvtItemBodyDesc = getCvtItemBodyDesc()
    const CvtItemEnd = getCvtItemEnd()
    const CvtItemThumbnail = getCvtItemThumbnail()
    const CvtItemRemove = getCvtItemRemove()
    const CvtItemBottom = getCvtItemBottom()
    const ClearButton = getClearButton()
    return this.item ? (
      <CvtListItem
        tabindex='0'
        class={('select-text', 'antialiased', { __card: this.isCard })}
      >
        <CvtListItemWrapper showSeparator={this.showSeparator}>
          <CvtItemTop>
            <slot name='item-top'></slot>
            {this.$slots.itemTop}
          </CvtItemTop>
          <CvtItemWrapper>
            {this.iconsEnabled ? (
              <CvtItemStart>
                <CvtIcon
                  icon={this.item[this._iconProp] || this.fallbackIcon}
                ></CvtIcon>
              </CvtItemStart>
            ) : (
              this.thumbnailsEnabled && (
                <CvtItemStart>
                  <CvtItemThumbnail
                    src={
                      this.item[this._thumbnailProp] || this.fallbackThumbnail
                    }
                  />
                </CvtItemStart>
              )
            )}

            <CvtItemBody>
              <CvtItemBodyTitle>{this.item[this._titleProp]}</CvtItemBodyTitle>
              <CvtItemBodyDesc>
                {this.item[this._descriptionProp]}
              </CvtItemBodyDesc>
            </CvtItemBody>

            {this.endIcon && (
              <CvtItemEnd>
                <CvtIcon icon={this.endIcon}></CvtIcon>
              </CvtItemEnd>
            )}

            {this.removeEnabled && (
              <CvtItemEnd>
                <CvtItemRemove
                  role='button'
                  onClick={() => this.onRemove(this.item)}
                  onmouseleave={() => {
                    let allClearButtons = document.getElementsByName(
                      'clearButtonName'
                    )
                    if (allClearButtons) {
                      allClearButtons.forEach((allClearButton) => {
                        allClearButton.blur()
                      })
                    }
                  }}
                >
                  <ClearButton
                    name='clearButtonName'
                    shape='pill'
                    size='sm'
                    icon='times'
                    text=''
                    actionIcon={true}
                    colorOfActionIcon={''}
                    modeOfActionIcon={'dark'}
                    color='light'
                  ></ClearButton>
                </CvtItemRemove>
              </CvtItemEnd>
            )}
          </CvtItemWrapper>

          <CvtItemBottom>{this.$slots.itemBottom}</CvtItemBottom>
        </CvtListItemWrapper>
      </CvtListItem>
    ) : (
      <div></div>
    )
  }
}
