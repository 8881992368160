import {
    CreateWidgetFromFirebaseNode
} from "../components/v3/lib/vue-dom-obj";
import {PageEngineCommands} from "./PageEngineCommands";
import {VDomCommands} from "./VDomCommand";
import {ConvrrtFirebase} from "../convrrt-firebase";

/**
 * Handles Section Removal Actions
 */
export class RemoveSectionCommand extends PageEngineCommands {
    private _sectionNode: any;
    private _widget: any
    private readonly _idx: number;

    constructor(idx: number, sectionNode: any) {
        super();
        this._sectionNode = sectionNode
        this._widget = CreateWidgetFromFirebaseNode(sectionNode)
        this._idx = idx
    }
    get$emitData() {
        return  {name: 'RemoveSectionCommand', forceBuild: false}
    }
    get hasChanges () {
        return true
    }

    async execute() {
        await this.firebaseRef?.splice(this._idx, 1);
    }

    async unExecute() {
        await this.firebaseRef?.splice(this._idx, 0, this._widget).then(() => {
            this.$nextTick!(() => {
                let node = document.getElementById(this._sectionNode.data.props.vnodeId) as HTMLElement
                if(node){
                    node.scrollIntoView({ behavior: 'smooth'})
                }
            });
        })
    }
}

export class RemoveSubNodeCommand extends VDomCommands{
    private _sectionNode: any;
    private readonly _firebaseRef: ConvrrtFirebase;
    private _widget: any
    private readonly _idx: number;

    constructor(vNodeId: string, rootKey: string, idx: number, sectionNode: any) {
        super(vNodeId);
        this._firebaseRef = new ConvrrtFirebase(rootKey)
        this._sectionNode = sectionNode
        this._widget = CreateWidgetFromFirebaseNode(sectionNode)
        this._idx = idx
    }
    get$emitData() {
        return  {name: 'RemoveSectionCommand', forceBuild: false}
    }
    get hasChanges () {
        return true
    }

    get firebaseRef(): ConvrrtFirebase {
        return this._firebaseRef
    }
    async execute() {
        await this.firebaseRef?.splice(this._idx, 1);
    }

    async unExecute() {
        await this.firebaseRef?.splice(this._idx, 0, this._widget).then(() => {
            this.getVueComponent()?.$nextTick!(() => {
                let node = document.getElementById(this._sectionNode.data.props.vnodeId) as HTMLElement
                node.scrollIntoView({ behavior: 'smooth'})
            });
        })
    }
}