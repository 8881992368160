
<div identity="BackgroundShape">
  <div v-if="showOption('desktop')" class="row mb-3">
    <div class="col-12">
      <cvt-select
          mode="dropdown"
          :value="objectShape"
          label="Shapes"
          :theme="mode"
          :background-mode="mode"
          @input="onShapeChanged"
      >
        <option
            v-for="optionAction in shapes"
            :key="optionAction.name"
            :value="optionAction.name"
            v-text="optionAction.name"
        ></option>
      </cvt-select>
    </div>
  </div>

  <div v-if="showOption('desktop')" class="row">
    <div class="col">
      <h6>{{$t('elements.properties.bgshape.color')}}</h6>
    </div>
    <div class="col-12">
      <colors
          alpha
          :mode="mode"
          :custom-options="customOptions"
          @apply-color="applyBackgroundColor"
          @apply-transparency="applyTransparency"
      />
    </div>
  </div>

  <div v-if="showShapePositionToggle" class="row  mt-3">
    <div
        v-if="OrgName === 'freshworks'"
        class="col-auto mx-auto"
    >
      <cvt-radio-group
          class="radio-group"
          orientation="horizontal"
          :initial-value="bgPosition"
      >
        <cvt-radio-button
            horizontal
            size="md"
            value='top'
            color="primary"
            @radioClick="toggleShapePosition"
        >
          {{$t('elements.properties.bgshape.top')}}
        </cvt-radio-button>
        <cvt-radio-button
            size="md"
            value='bottom'
            color="primary"
            @radioClick="toggleShapePosition"
        >
          {{$t('elements.properties.bgshape.bottom')}}
        </cvt-radio-button>
      </cvt-radio-group>
    </div>
    <div
        v-else
        class="col-auto mx-auto"
    >
      <cvt-switch
          size="md"
          color="grey"
          type="rounded"
          description-type="text"
          :inactive-text="$t('elements.properties.bgshape.top')"
          :active-text="$t('elements.properties.bgshape.bottom')"
          :initial-state="bgPosition === 'top'? false: true"
          :mode="mode"
          @switchToggle="toggleShapePosition"
      />
    </div>
  </div>

  <div v-if="showWidth" class="row  mt-3">
    <div class="col-auto d-flex align-items-center">
      <h6 :class="textColorClass" class="m-0">
        {{$t('elements.properties.bgshape.width')}}
      </h6>
    </div>
    <div class="col d-flex align-items-center">
      <cvt-slider
          id="slider-default"
          :tooltips-enabled="true"
          :step-marks-enabled="false"
          class="slider"
          :min="getMinWidth"
          :max="getMaxWidth"
          :value="width"
          :step="1"
          @change="onWidthChange"
      />
    </div>
  </div>

  <div v-if="showHeight" class="row  mt-3">
    <div class="col-auto d-flex align-items-center">
      <h6 :class="textColorClass" class="m-0">
        {{$t('elements.properties.bgshape.height')}}
      </h6>
    </div>
    <div class="col d-flex align-items-center">
      <cvt-slider
          id="slider-default"
          :tooltips-enabled="true"
          :step-marks-enabled="false"
          class="slider"
          :min="0"
          :max="getMaxHeight"
          :value="height"
          :step="1"
          @change="onHeightChange"
      />
    </div>
  </div>
  <div v-if="canFlip && showOption('desktop')">
    <div class="divider mt-2"/>
    <div class="row">
      <div class="col">
        <strong>{{$t('elements.properties.bgshape.flip')}}</strong>
      </div>
      <div class="col-3">
        <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="isFlipped"
            @switchToggle="() => toggleFlip()"
        />
      </div>
    </div>
  </div>
  <div v-if="canInvert && showOption('desktop')">
    <div class="divider mt-2"/>
    <div class="row">
      <div class="col">
        <strong>{{$t('elements.properties.bgshape.invert')}}</strong>
      </div>
      <div class="col-3">
        <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="isInverted"
            @switchToggle="() => toggleInvert()"
        />
      </div>
    </div>
  </div>
  
</div>
