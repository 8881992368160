import * as Sentry from '@sentry/browser'
import axios from 'axios'
import firebase from 'firebase/app'
import * as _ from 'lodash'
import { USER_CONFIG } from '../../common/types/vuex'
import { Auth } from '../services/api'
import { CookieHelper } from '../services/cookieHelper'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

const auth = new Auth()

window.$signOut = firebase.auth().signOut

export default {
  state: {
    user: null,
    firebaseUser: null,
    sessionConfig: null,
  },
  getters: {
    [getters.AUTH_GET_USER]({ user }) {
      return user
    },
    [getters.AUTH_GET_USER_CONFIG]({ user }): USER_CONFIG {
      const { config = {} } = user || {}
      return config
    },
    [getters.AUTH_GET_USER_BUILDER_THEME]({ user }) {
      const { config = {} } = user || {}
      return _.chain(config.THEME)
        .cloneDeep()
        .omitBy(_.isNull)
        .defaultsDeep({
          color: '',
          logo: '',
          favicon: '',
          logoHeight: '25px',
          pageTitle: 'Site Builder',
          backToDashText: 'Campaigns',
        })
        .value()
    },
    [getters.AUTH_GET_FIREBASE_USER]({ firebaseUser }) {
      return firebaseUser
    },
    [getters.AUTH_GET_USER_IS_ADMIN]({ user }) {
      return user && user.isAdmin
    },
    [getters.AUTH_GET_USER_IS_ADMIN]({ user }) {
      return user && user.isAdmin
    },
    [getters.PAGES_SESSION_DATA]({ sessionConfig }) {
      return sessionConfig
    },
  },
  mutations: {
    [mutations.AUTH_SET_USER](state, data) {
      state.user = data
    },
    [mutations.AUTH_SET_FIREBASE_USER](state, user) {
      state.firebaseUser = user
    },
    [mutations.SET_PAGES_SESSION_COOKIE_DATA](state, data) {
      state.sessionConfig = data
    },
  },
  actions: {
    [actions.AUTH_LOAD_USER]({ commit, dispatch }, currentRoute) {
      return auth.currentUser().then(({ data }) => {
        const getCookie = CookieHelper('_pages_session=')
        commit(mutations.AUTH_SET_USER, data)
        commit('i18n/initLocale')
        commit(mutations.SET_PAGES_SESSION_COOKIE_DATA, getCookie)
        dispatch(actions.INTERCOM_BOOT, {
          email: data.email,
          orgID: data.orgID,
          projectID: data.projectID,
          appId: data.projectID,
          user_hash: data.intercomHash,
        })

        dispatch(actions.MIXPANEL_BOOT, data)
        dispatch(actions.PENDO_BOOT, data)
        dispatch(actions.APPCUES_BOOT, data)
        dispatch(actions.CHAMELEON_BOOT, data)
        dispatch(actions.EVENT_TRACKING_BOOT, currentRoute)
        dispatch(actions.CUSTOM_TENANT_SCRIPTS_BOOT, data)
        dispatch(actions.FROGED_BOOT, data)
        dispatch(actions.DOPPLER_BOOT)

        Sentry.setUser({
          id: data.userID,
          email: data.email,
          orgID: data.orgID,
          projectID: data.projectID,
          firstName: data.firstName,
          fullName: data.fullName,
        })

        Sentry.setTags({
          orgID: data.orgID,
          projectID: data.projectID,
        })

        if (window.FS) {
          window.FS.identify(data.userID, {
            email: data.email,
          })
        }
      })
    },
    [actions.AUTH_FIREBASE_LOGIN]({ state }) {
      return firebase
        .auth()
        .signInWithCustomToken(state.user.firebaseAuthToken)
        .then((_) => {
          return Promise.resolve()
        })
    },
    [actions.AUTH_FIREBASE_WATCH_AUTH]({ commit }) {
      firebase.auth().onAuthStateChanged(function (user) {
        commit(mutations.AUTH_SET_FIREBASE_USER, user)
      })
    },
    [actions.AUTH_HEARTBEAT]({ dispatch }) {
      console.debug('initializing heartbeat')

      let rootHost
      const originComponents = window.location.origin.split('.')

      if (originComponents.length > 3) {
        rootHost = originComponents.slice(1).join('.')
      } else {
        rootHost = originComponents.join('.')
      }

      // disable in development
      if (
        rootHost.indexOf('localhost') >= 0 ||
        rootHost.indexOf('builder.convrrt.local') >= 0
      ) {
        return
      }

      // run keepAlive every minute
      setInterval(() => {
        axios.get(`${rootHost}/app/session/keepAlive`).catch(() => {
          console.warn('heartbeat failed, session will timeout')
        })
      }, 60000)

      // load new auth token and authenticate into firebase
      // every 30 minutes
      setInterval(async () => {
        console.debug('syncing session to avoid logout')
        await dispatch(actions.AUTH_LOAD_USER)
        await dispatch(actions.AUTH_FIREBASE_LOGIN)
      }, 1800000)
    },
  },
}
