<template>
  <cvt-image-gallery
    ref="cvtImageGallery"
    :loading="searching"
    :images="results"
    :categories="tags"
    :active-category="activeTag"
    @filter-gallery="(tag) => searchByTag(tag)"
    @insert-image="(img) => useImage(img)"
    @search-input="(value) => onSearchUpdate(value)"
    @search-change="(value) => onSearchUpdate(value)"
  />
</template>

<script>
  import * as _ from 'lodash'
  import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
  import * as getters from '../../../store/modules/getters'
  import * as mutations from '../../../store/modules/mutations'
  import * as actions from '../../../store/modules/actions'
  import ImageLoader from './ImageLoader.vue'

  const tags = [
    'all',
    'business',
    'coaching',
    'consulting',
    'fitness',
    'marketing',
    'office',
    'services',
    'tech'
  ].map(t => t.toUpperCase())

  export default {


    data () {
      return {
        tags,
        activeTag: 'all'.toUpperCase(),
        query: null,
        loading: true
      }
    },

    computed: {
      ...mapGetters({
        results: getters.IMAGE_SEARCH_RESULTS,
        searching: getters.IMAGE_SEARCH_SEARCHING
      }),
    },
    created () {
      this.search({
        search: this.alterTag(this.activeTag)
      })
    },
    methods: {
      ...mapActions({
        useImage: actions.IMAGE_SEARCH_SELECTED,
        search: actions.IMAGE_SEARCH_QUERY
      }),
      alterTag(tag) {
        if (tag.toLowerCase() === 'all') {
          return 'small business'
        }
        return tag
      },
      searchByTag (tag) {
        this.activeTag = tag
        this.query = ""

        this.search({
          search: this.alterTag(tag)
        })
      },
      runSearch: _.debounce(function () {
        if (this.$refs.cvtImageGallery) {
          this.$refs.cvtImageGallery.clearFilter()
        }

        this.search({
          search: this.query
        })
      }, 100, {
        trailing: true
      }),
      onSearchUpdate: _.debounce(function (value) {
        let working_value = value

        if (_.isEmpty(value)) {
          // Restore search to activeTag
          working_value = this.alterTag(this.activeTag)
        }

        this.query = working_value
        this.runSearch()
      }, 800, {
        trailing: true
      })
    },



  }
</script>

<style lang="scss">
  @import "@/scss/utils";
  .image-gallery {
    width: 20rem;

    .el-select {
      width: 100%;
    }
  }

  .image-gallery__results {
    overflow-x: hidden;
    width: 100%;
  }

  .image-gallery__result__scroll {
    overflow-y: scroll;
    height: 75vh;
    width: 100%;
  }

  .image-gallery__result {
    img {
      // height: 191px;
    }
  }
</style>
