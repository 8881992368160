import { styled, VueEmotion } from '@egoist/vue-emotion'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import Vue from '../../../lib/utils/vue'
import { CvtTab, CvtTabs } from '../../molecules/tabs'

Vue.use(VueEmotion)

let FontFamily,
  Spacing,
  settingsTabLeftPadding,
  siteSettingsTopPosition,
  settingsTabRoundedEdge,
  settingsTabTopPadding

let backgroundColor, textFontSize, textLineHeight

const getSettingsContainer = () => styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: ${FontFamily.DEFAULT};
`

const SettingsTabContainer = styled('div')`
  display: flex;
  width: 100%;
  height: calc(100% - 80px);
  flex-direction: column;
  align-items: flex-start;
`

const getSettingsTitle = () => styled('h1')`
  margin: 0;
  width: ${(props) => props.leftSideWidth};
  text-align: left;
  padding: ${Spacing.SPACING_32}px 0 ${Spacing.SPACING_32}px
    ${Spacing.SPACING_32}px;
  font-size: ${Spacing.SPACING_24 - Spacing.SPACING_2}px;
  ${textLineHeight('xl')}
  ${backgroundColor({ themeing: 'dark' }, 100)}
  ${(props) => {
    if (props.usage === 'PopupSiteSettings') {
      return `border-top-left-radius: ${Spacing.SPACING_8}px`
    }
  }}
`

const SettingsAlertsContainer = styled('div')`
  width: 80%;
`

const getSettingOptions = () => styled(CvtTabs)`
  width: 100%;
  height: 100%;
  & > div:first-child {
    width: ${(props) => props.leftSideWidth};
    padding-left: ${settingsTabLeftPadding}px;
    padding-top: ${settingsTabTopPadding ? '20px' : 'unset'};
    border-radius: ${settingsTabRoundedEdge ? '4px' : 'unset'};
    ${backgroundColor({ themeing: 'dark' }, 100)}
    ul {
      width: 100%;
      a {
        padding: ${Spacing.SPACING_16}px;
        span:nth-child(2) {
          justify-content: left;
        }
      }
      a:not([isactive='true']) {
        ${backgroundColor({ themeing: 'dark' }, 100)}
      }
    }
  }
  & > div:nth-child(2) {
    width: 75%;
    margin-left: 0;
    overflow: auto;
    padding: 0 ${Spacing.SPACING_32}px ${Spacing.SPACING_64}px;
  }
`

const Tab = styled(CvtTab)``

export const CvtSettings = {
  name: 'CvtSettings',
  data() {
    return {
      titleStyle: {
        fontFamily: 'Nunito',
        fontSize: '60px',
      },
      subtitleStyle: {
        fontFamily: 'Nunito',
        fontSize: '30px',
      },
      headingStyle: {
        fontFamily: 'Nunito',
        fontSize: '20px',
      },
      bodyStyle: {
        fontFamily: 'Nunito',
        fontSize: '16px',
      },
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      settingsTabLeftPadding: ({ globalTheme }) =>
        globalTheme.settingsTabLeftPadding,
      siteSettingsTopPosition: ({ globalTheme }) =>
        Boolean(globalTheme.siteSettingsTopPosition),
      settingsTabTopPadding: ({ globalTheme }) =>
        globalTheme.settingsTabTopPadding,
      settingsTabRoundedEdge: ({ globalTheme }) =>
        globalTheme.settingsTabRoundedEdge,
    }),
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    activeTab: {
      type: String,
      default: 'general',
    },
    usage: {
      type: String,
      default: '',
    },
    leftSideWidth: {
      type: String,
      default: '25%',
    },
  },
  created() {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    settingsTabLeftPadding = this.settingsTabLeftPadding
    siteSettingsTopPosition = this.siteSettingsTopPosition
    settingsTabRoundedEdge = this.settingsTabRoundedEdge
    settingsTabTopPadding = this.settingsTabTopPadding
  },
  render: function (h) {
    const SettingsContainer = getSettingsContainer()
    const SettingsTitle = getSettingsTitle()
    const Tabs = getSettingOptions()

    return (
      <SettingsContainer>
        <SettingsTabContainer>
          {(this.$slots.alert || true) && (
            <SettingsAlertsContainer>
              {this.$slots.alert}
            </SettingsAlertsContainer>
          )}

          {!siteSettingsTopPosition && (
            <SettingsTitle
              usage={this.usage}
              leftSideWidth={this.leftSideWidth}
            >
              Settings
            </SettingsTitle>
          )}

          <Tabs
            keepInactiveBorder={false}
            mode={this.mode}
            closeable={false}
            vertical={true}
            prefixIcon={''}
            ontoggle={(id) => this.$emit('toggle', id)}
            usage={this.usage}
            leftSideWidth={this.leftSideWidth}
          >
            {this.$slots.general && (
              <Tab
                title={this.$t('settings.general.label')}
                id="general"
                active={this.activeTab == 'general'}
              >
                {this.$slots.general}
              </Tab>
            )}
            {this.$slots.companyLogo && (
              <Tab
                title={this.$t('setup.logo.text')}
                id="company-logo"
                active={this.activeTab == 'company-logo'}
              >
                {this.$slots.companyLogo}
              </Tab>
            )}
            {this.$slots.favicon && (
              <Tab
                title={this.$t('settings.favicon.label')}
                id="favicon"
                active={this.activeTab == 'favicon'}
              >
                {this.$slots.favicon}
              </Tab>
            )}
            {this.$slots.tracking && (
              <Tab
                title={this.$t('launch.tracking.tab_title')}
                id="tracking"
                active={this.activeTab == 'tracking'}
              >
                {this.$slots.tracking}
              </Tab>
            )}
            {this.$slots.tenantChatWidget && (
              <Tab
                title={this.$t('launch.chat.message')}
                id="tenantChatWidget"
                active={this.activeTab == 'tenantChatWidget'}
              >
                {this.$slots.tenantChatWidget}
              </Tab>
            )}
            {this.$slots.seo && (
              <Tab
                title={this.$t('launch.sharing.message')}
                id="seo"
                active={this.activeTab == 'seo'}
              >
                {this.$slots.seo}
              </Tab>
            )}
            {this.$slots.checkoutPage && (
              <Tab
                title={this.$t('launch.ecommerce.checkout_settings')}
                id="checkout"
                active={this.activeTab == 'checkout'}
              >
                {this.$slots.checkoutPage}
              </Tab>
            )}
            {this.$slots.legal && (
              <Tab
                title="Legal/Privacy"
                id="legal"
                active={this.activeTab == 'legal'}
              >
                {this.$slots.legal}
              </Tab>
            )}
            {this.$slots.audience && (
              <Tab
                title={this.$t('settings.audience.label')}
                id="audience"
                active={this.activeTab == 'audience'}
              >
                {this.$slots.audience}
              </Tab>
            )}
            {this.$slots.rules && (
              <Tab
                title={this.$t('settings.rules.label')}
                id="rules"
                active={this.activeTab == 'rules'}
              >
                {this.$slots.rules}
              </Tab>
            )}
            {this.$slots.integrations && (
              <Tab
                title={this.$t('settings.integrations.label')}
                id="integrations"
                active={this.activeTab == 'integrations'}
              >
                {this.$slots.integrations}
              </Tab>
            )}
          </Tabs>
        </SettingsTabContainer>
      </SettingsContainer>
    )
  },
}
