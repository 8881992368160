<template>
  <transition-group :tag="tag" :class="className" :mode="mode" :css="false" @enter="onEnter" @leave="onLeave">
    <slot></slot>
  </transition-group>
</template>

<script>
  import anime from './anime'
  export default {
    mixins: [anime],
    props: {
      className: {
        type: String,
        default() { return ''}
      },
      tag: {
        type: String,
        default() {
          return ''
        }
      }
    }
  }
</script>
