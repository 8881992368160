<template>
  <cvt-elements
    :elements="elements"
    :type="$attrs.type"
    @element-drag-start="(e, elm) => dragstart(e, elm)"
    @element-drag-end="dragend"
    @element-mouse-down="(elm) => clicked(elm)"
  />
</template>

<script>
  import * as _ from 'lodash'
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
  import * as getters from '@/js/store/modules/getters'
  import * as mutations from '@/js/store/modules/mutations'
  import * as actions from '@/js/store/modules/actions'

  import {
    Draft,
    Icon,
    CImage,
    CButton,
    Divider,
    CVideo,
    CForm,
    BrandLogo,
    CodeSnippet,
    GridBoxContainer
  } from '@/js/lib/helper-classes'

const allOldBuilderElements = [
  { icon: 'cvt-icon-text', name: 'text', label: 'Text', type: 'Text', node: Draft, draggable: true },
  { icon: 'cvt-icon-image', name: 'image', label: 'Image', type: 'Image', node: CImage, draggable: true },
  { icon: 'cvt-icon-button', name: 'button', label: 'Button', type: 'Button', node: CButton, draggable: true },
  { icon: 'cvt-icon-divider', name: 'divider', label: 'Divider', type: 'Divider', node: Divider, draggable: true },
  { icon: 'cvt-icon-form', name: 'form', label: 'Form', type: 'Form', node: CForm, draggable: true },
  { icon: 'cvt-icon-icons', name: 'icon', label: 'Icon', type: 'Icon', node: Icon, draggable: true },
  { icon: 'cvt-icon-video', name: 'video', label: 'Video', type: 'Video', node: CVideo, draggable: true },
  { icon: 'cvt-icon-logo', name: 'logo', label: 'Logo', type: 'Logo', node: BrandLogo, draggable: true },
  { icon: 'cvt-icon-code', name: 'code', label: 'Code', type: 'Code', node: CodeSnippet, draggable: true },
  { icon: 'cvt-icon-container', name: 'container', label: 'container', type: 'Container', node: GridBoxContainer, draggable: true }
]

  export default {
    data () {
      return {
        limit: 3,
        expanded: false,

        enter: {
          translateX: [-250, 0],
          opacity: 1,
          duration: 200,
          easing: 'easeInOutQuart'
        },

        leave: {
          translateX: -250,
          duration: 200,
          opacity: 0,
          easing: 'easeInOutQuart'
        }
      }
    },
    computed: {
      ...mapState('globalTheme', {
        builderElementsConfig: ({ globalTheme }) => globalTheme.builderElements,
      }),
      ...mapGetters({
        visible: getters.ELEMENT_LAUNCHER_VISISBLE,
        selected: getters.SELECTOR_SELECTED,
        targeted: getters.SELECTOR_TARGETED,
        insertionPoint: getters.ELEMENT_LAUNCHER_TARGET,
        config: getters.AUTH_GET_USER_CONFIG,
        isGridBuilder: getters.IS_GRID_BUILDER
      }),
      elements () {
        let elements = allOldBuilderElements.filter((oldBuilderElement) => {
          
          let isBuilderElementEnabled = this.builderElementsConfig.some((builderElement) => {
            return (builderElement.name === oldBuilderElement.name && builderElement.enabledForOldBuilder)
          })

          return isBuilderElementEnabled
        });

        return elements
      },
      expandBtnMsg () {
        return this.expanded ? 'Go back' : 'More'
      },
      expandBtnIcon () {
        return this.expanded ? 'keyboard_arrow_left' : 'more_vert'
      },
      classes () {
        return {
          // 'element-launcher--expanded': this.expanded
        }
      },
      visibleElements () {
        return _.filter(this.elements, (e) => {
          return this.expanded ? _.indexOf(this.elements, e) > this.limit - 1
            : _.indexOf(this.elements, e) <= this.limit - 1
        })
      },

    },

    methods: {
      ...mapMutations({
        disableDropzones: mutations.DROPZONE_DISABLE
      }),

      ...mapActions({
        activateDropzones: actions.DROPZONE_ACTIVATE,
        intercomTrack: actions.INTERCOM_TRACK
      }),

      expand () {
        this.expanded = !this.expanded
      },

      beforeEnter (e) {
        e.style.opacity = 0
      },

      dragstart (e, elm) {
        e.dataTransfer.setData('convrrt/element', null)
        console.log('drag started', elm)
        this.activateDropzones(elm)
        return true
      },

      dragend () {
        this.disableDropzones()
      },

      clicked (elm) {
        if (elm.type === 'Cart') {
          this.intercomTrack({
            event: 'clicked-cart-button'
          })
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "@/scss/utils";

  .element-launcher {
    width: auto;
    background: $slate;
    transition: all 250ms ease;
    color: $white;

    display: flex;
    flex-wrap: wrap;
    flex: 0;
    width: 11rem;
    height: 100%;
    justify-content: space-between;

    > small {
      font-weight: bold;
    }
  }

  .element-launcher__item {
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: lighten($slate, 10%);
    transition: all 250ms ease;
    padding: 1.5rem;

    color: $white;
    cursor: move;

    &:last-child {
      margin-right: 0;
    }

    i {
      font-size: 38px;
    }

    &:hover {
      background: lighten($slate, 20%);
      transform: rotate(15deg);
    }

  }
</style>
