<template>
  <span class="cssload-loader"><span class="cssload-loader-inner"></span></span>
</template>

<script>
  export default {
  }
</script>

<style lang="scss">
  .cssload-loader {
    display: block;
    margin:0 auto;
    width: 29px;
    height: 29px;
    position: relative;
    border: 4px solid rgb(48,199,109);
    animation: cssload-loader 2.3s infinite ease;
  }

  .cssload-loader-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: rgb(48,199,109);
    animation: cssload-loader-inner 2.3s infinite ease-in;
  }

  @keyframes cssload-loader {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(180deg);
    }

    50% {
      transform: rotate(180deg);
    }

    75% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes cssload-loader-inner {
    0% {
      height: 0%;
    }

    25% {
      height: 0%;
    }

    50% {
      height: 100%;
    }

    75% {
      height: 100%;
    }

    100% {
      height: 0%;
    }
  }
</style>
