<template>
  <div class="placeholder-wrapper" :class="{ 'is-loading': loading }">
    <div v-if="loading" class="animated-placeholder"></div>
    <div class="content" :class="{ hidden: loading }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnimatedPlaceholder',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.placeholder-wrapper {
  position: relative;
  /* width: 100%; */
  /* height: 100%; */
}

.animated-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholder-shimmer;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.2) 8%,
    rgba(0, 0, 0, 0.7) 28%,
    rgba(0, 0, 0, 0.2) 43%
  );
  background-size: 800px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;
  cursor: not-allowed;
  border-radius: 4px;
}

.content {
  position: relative;
  z-index: 0;
}

.content.hidden {
  visibility: hidden;
}

@keyframes placeholder-shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
</style>
