import Vue from '../../../../lib/utils/vue'
import VueI18n from 'vue-i18n'
import * as getters from '../../../../../builder/src/js/store/modules/getters'
import * as constants from '../../../../../builder/src/js/common/constants/constants'
import { mapGetters, mapState } from 'vuex'
import CvtRadioGroup from '../../../molecules/radio-group/RadioGroup'
import { IntegrationReady } from '../ready'
import {
  getIntegrationTitle,
  getIntegrationUseSubtext,
  getIntegrationUseWrap,
  IntegrationDescription
} from "../description";
import { IntegrationLogoWrapper } from '../logo-wrapper'
import { IntegrationContainer } from '../container'
import { ZapierSetup } from "./setup";
import IntegrationItemBase from "../IntegrationItemBase.vue";

Vue.use(VueI18n)

let textFontWeight, textFontSize, Spacing, FontSize, backgroundColor, Colors

export default IntegrationItemBase.extend({
  name: 'ZapierIntegration',
  components: {
    CvtRadioGroup,
  },
  data() {
    return {
      initialRadioValue: '',
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
    }),
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      globalTheme: ({ globalTheme }) => globalTheme,
    }),
  },
  created() {
    textFontSize = this.textFontSize
    textFontWeight = this.textFontWeight
    Spacing = this.Spacing
    FontSize = this.FontSize
    backgroundColor = this.backgroundColor
    Colors = this.Colors
  },
  mounted() {
    this.onScrollInToView()
  },
  methods: {
    onScrollInToView() {
      const container = document.getElementById('authWrap')
      container?.scrollIntoView()
    },
  },
  render: function (h) {
    const integrationTitle = this.selectedIntegration.title

    const IntegrationTitle = getIntegrationTitle()
    const IntegrationUseWrap = getIntegrationUseWrap()
    const IntegrationUseSubtext = getIntegrationUseSubtext()

    return (
      <IntegrationContainer>
        {this.showAuthScreen && (
          <IntegrationLogoWrapper>
            <IntegrationDescription skipAuth={true}
                                    authenticationText="continue"
                                    provider="zapier"
                                    onStepChange={(step) => this.onChangeAuthStep(step, this.selected.id)}>
              <div style="padding: 45px 0px 45px 45px;">
                <IntegrationTitle>
                  <a href="https://zapier.com/" target="_blank">Zapier</a> is a powerful automation tool that lets you automatically zap information between web apps.
                  With MeclabsAI Zapier integration, you can instantly sync form responses to CRM systems,
                  cloud storage platforms, project management boards, and more — streamlining your workflow
                  and eliminating manual data entry.
                </IntegrationTitle>
                <IntegrationUseWrap>
                  <p>Use this integration to</p>
                  <ul>
                    <li>
                      Create a Zap and choose a Zapier Webhook node as the starting node
                    </li>
                    <li>
                      On Zapier Webhook settings, under <strong>Apps & Event</strong>, select the catch hook
                    </li>
                    <li>
                      On Zapier Webhook settings, under <strong>Test</strong>, copy your webhook URL
                    </li>
                  </ul>
                </IntegrationUseWrap>
                <IntegrationUseSubtext>
                  Choose from 3000+ platforms to sync form submissions to, including popular software like
                  Microsoft Excel, Gmail, Discord, Google Contacts, Klaviyo, and many more. Try it for free today!
                </IntegrationUseSubtext>
                <IntegrationUseSubtext>
                  {/* Learn how to */}
                  Learn how to{' '}
                  <a href="https://integrations.meclabsai.com/zapier.html" target="_blank" style="text-decoration: underline;cursor: pointer;">
                    {' '}
                    integrate your form with {integrationTitle}.
                  </a>
                </IntegrationUseSubtext>
              </div>
            </IntegrationDescription>
          </IntegrationLogoWrapper>
        )}

        {this.integrationConfig.step === constants.AUTH_SUCCESS && this.isValidProvider && (
          <ZapierSetup onCompleted={step => this.onCompleted()} onCancel={this.onCancel}/>
        )}
        {this.integrationConfig.step === constants.INTEGRATION_FINISHED && this.isValidProvider && (
          <ZapierSetup onCancel={this.onCancel} onCompleted={step => this.onUpdate()}/>
        )}

        {this.integrationConfig.step === constants.INTEGRATION_COMPLETE && this.isValidProvider && (
          <IntegrationReady onFinished={
            () => this.onFinished()
          }/>
        )}
      </IntegrationContainer>
    )
  },
})
