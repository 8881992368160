
<div
  :id="vnodeId"
  :data-vnode-id="`${vnodeId}`" :class="containerClasses"
  @click="select"
  @mouseover="target">
  <card-item-control
    v-for="(item, idx) in cardItems"
    :key="item.id"
    :class="stylesheet.classes.cardItem"
    :layout="layout"
    :layout-view="layoutView"
    :index="idx"
    v-bind="item"
    :border-radius="itemBorderRadius(item.borderRadius)"
    :border-style="itemBorderStyle(item.borderStyle)"
    :border-color="itemBorderColor(item.borderColor)"
    :border-width="itemBorderWidth(item.borderWidth)"
    :shadow="itemShadow(item.shadow)"
    :item-styles="itemStyles"
  />
</div>
