import Vue from '../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import CvtButton from '../molecules/Button'
import { CvtTabs, CvtTab } from '../molecules/tabs'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import VueI18n from 'vue-i18n'
Vue.use(VueEmotion)
Vue.use(VueI18n)

let Spacing
const getWrapperDiv = () => styled('div')`
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 600px;
  position: absolute;
  right: 0;
  box-shadow: 0px 4px 12px rgba(18, 52, 77, 0.35);
  background-color: #ffffff;
  z-index: 2;
`

const getHeader = () => styled('h4')`
  margin: ${Spacing.SPACING_16}px;
`

const getMainContent = () => styled('div')`
  overflow: auto;
  height: 100%;
`

const getTabs = () => styled(CvtTabs)`
  margin: 0px ${Spacing.SPACING_16}px;
`

const getFooter = () => styled('div')`
  background-color: #f5f7f9;
`

const getWrapper = () => styled('div')`
  display: flex;
  flex-direction: row-reverse;
  margin: ${Spacing.SPACING_16}px;

  & > button {
    margin-left: ${Spacing.SPACING_12}px;
  }
`

export const RightSideImgModal = {
  name: 'RightSideImgModal',
  data() {
    return {}
  },
  computed: {
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing
    })
  },
  created() {
    Spacing = this.Spacing
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {},
  render: function (h) {
    const WrapperDiv = getWrapperDiv()
    const Header = getHeader()
    const MainContent = getMainContent()
    const Footer = getFooter()
    const ButtonWrapper = getWrapper()
    const StyledCvtTabs = getTabs()

    return (
      this.show && (
        <WrapperDiv>
          <Header>Add image</Header>
          <MainContent>
            <StyledCvtTabs prefixIcon=''>
              <CvtTab title='Upload Image'>{this.$slots.upload_zone}</CvtTab>
              <CvtTab title='Add Image URL'>Add Image URL</CvtTab>
              <CvtTab
                title='Select from library'
                onClick={() => this.$emit('set-gallery')}
              >
                {this.$slots.image_gallery}
              </CvtTab>
            </StyledCvtTabs>
          </MainContent>
          <Footer>
            <ButtonWrapper>
              <CvtButton
                text='Save'
                outlined={true}
                specialStyle='dark'
              ></CvtButton>
              <CvtButton
                text='Cancel'
                color='light'
                outlined={true}
                onClick={() => this.$emit('close')}
              ></CvtButton>
            </ButtonWrapper>
          </Footer>
        </WrapperDiv>
      )
    )
  }
}
