import { styled, VueEmotion } from '@egoist/vue-emotion'
import VueI18n from 'vue-i18n'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import Vue from '../../../lib/utils/vue'
import CvtButton from '../../molecules/Button'
import { ContentPlaceholder } from '../../molecules/ContentPlaceholder'
import { CvtIcon } from '../../molecules/icon/icon'
import { LazyImage } from '../../molecules/LazyImage'

Vue.use(VueEmotion)
Vue.use(VueI18n)

let FontFamily, Spacing, Radii, FontWeight, Colors, Border

let textFontWeight,
  borderColor,
  textColor,
  backgroundColor,
  textFontSize,
  textLineHeight,
  elevation

const getPublishCardContainer = () => {
  return styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
  `
}

const getPublishBtnContainer = () => styled('div')`
  margin-bottom: ${Spacing.SPACING_24}px;
`

const PublishBtn = styled(CvtButton)``

const getThumbnailContainer = () => {
  return styled('div')`
    width: 326px;
    overflow: hidden;
    border-radius: ${Radii.RADIUS_PX_8}px;
    ${(props) => elevation(props, 400)}
  `
}

const getPublishDisabledMessage = () => styled('div')`
  margin-bottom: ${Spacing.SPACING_24}px;
  border: 1px solid #79ddf4;
  border-radius: ${Radii.RADIUS_PX_8}px;
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  padding: 14px 16px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #0c4481;
`

const getPublishDisabledMessageIcon = () => {
  return styled('CvtIcon')`
    margin-right: 5px;
  `
}

const getThumbnail = () => {
  return styled(LazyImage)`
    ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  `
}

const getPlaceholderContainer = () => {
  return styled('div')`
    position: relative;
    &::before {
      content: '';
      display: block;
      padding-top: calc(100% * 391 / 326);
    }
  `
}

const getThumbnailPlaceholder = () => {
  return styled(ContentPlaceholder)`
    top: 0;
    width: 100%;
    height: 100%;
  `
}

export const CvtPublishCard = {
  name: 'CvtPublishCard',
  data() {
    return {
      loadingImage: false,
    }
  },
  components: {
    CvtIcon,
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      elevation: getters.GLOBAL_STYLE_ELEVATION,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      Border: ({ globalTheme }) => globalTheme.Border,
    }),
    loader() {
      if (this.loading) return true
      if (this.loadingImage) return true
      else return false
    },
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    published: {
      type: Boolean,
      default: false,
    },
    thumbnail: {
      type: String,
      default: '',
    },
    specialStyle: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    btnColor: {
      type: String,
      default: '',
    },
    showThumbnail: {
      // placeholder and actual thumbnail (both)
      type: Boolean,
      default: true,
    },
    disabledCustomMessage: {
      type: Object,
      default: {
        message: '',
        action: '',
      },
    },
    customDisabledMessageEnabled: {
      type: Object,
    },
  },
  methods: {
    getPublishBtnColor() {
      if (this.published) {
        if (!this.specialStyle) {
          return 'light'
        }
      } else {
        return this.btnColor
      }
    },
  },
  watch: {
    // Initially thumbnail value is empty when component loads, so 'loadingImage' is set to true
    // because 'LazyImage component' throws an error and that event set this 'loadingImage' to true.
    // If we pass a non-empty value from parent component 'loadingImage' remains true and
    // loading placholder is shown to the user, to counter this behvior a watcher is set on
    // 'thumbnail' variable so whenever this value changes, re-set 'loadingImage' to false & set 'loading' to true
    thumbnail(oldThumbnail, newThumbnail) {
      if (oldThumbnail !== newThumbnail) {
        this.loadingImage = false
      }
    },
  },
  created() {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    FontWeight = this.FontWeight
    Colors = this.Colors
    Border = this.Border
    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    textColor = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    elevation = this.elevation
    this.loadingImage = false
  },
  render: function (h) {
    const PublishCardContainer = getPublishCardContainer()
    const PublishBtnContainer = getPublishBtnContainer()
    const ThumbnailContainer = getThumbnailContainer()
    const Thumbnail = getThumbnail()
    const PlaceholderContainer = getPlaceholderContainer()
    const ThumbnailPlaceholder = getThumbnailPlaceholder()
    const publishDisabledMessage = getPublishDisabledMessage()
    const PublishDisabledMessageIcon = getPublishDisabledMessageIcon()

    return (
      <PublishCardContainer>
        <PublishBtnContainer>
          <PublishBtn
            mode={this.mode}
            text={
              this.loading
                ? 'Publishing...'
                : this.published
                ? this.$t('launch.republish.cta')
                : this.$t('launch.publish.cta')
            }
            color={this.getPublishBtnColor()}
            outlined={this.published}
            loading={this.loading}
            shadow={true}
            disabled={this.disabled}
            onClick={() => (!this.disabled ? this.$emit('publish') : '')}
            specialStyle={this.specialStyle}
          ></PublishBtn>
        </PublishBtnContainer>
        {this.disabled && !this.customDisabledMessageEnabled && (
          <publishDisabledMessage>
            <PublishDisabledMessageIcon icon="exclamation-circle"></PublishDisabledMessageIcon>
            {this.$t(
              'launch.publish.launch.user_not_activated_publish_permission_message.cta',
            )}
          </publishDisabledMessage>
        )}
        {this.disabled && this.customDisabledMessageEnabled && (
          <publishDisabledMessage>
            <PublishDisabledMessageIcon icon="exclamation-circle"></PublishDisabledMessageIcon>
            {this.$t(this.disabledCustomMessage.message)}{' '}
            <a href={this.customDisabledMessageEnabled.link}>
              {this.$t(this.disabledCustomMessage.action)}
            </a>
          </publishDisabledMessage>
        )}
        <ThumbnailContainer mode={this.mode}>
          {this.loader && (
            <PlaceholderContainer>
              <ThumbnailPlaceholder mode={this.mode}></ThumbnailPlaceholder>
            </PlaceholderContainer>
          )}
          {!this.loader && this.showThumbnail && (
            <Thumbnail
              aspectRatio={391 / 326}
              dataSrc={this.thumbnail}
              imageLoadError={true}
              onImgError={(event) => {
                this.loadingImage = true
              }}
            ></Thumbnail>
          )}
        </ThumbnailContainer>
      </PublishCardContainer>
    )
  },
}
