import {mapActions, mapMutations} from "vuex";
import { PropertyChangeCommand, SizePropertyChangeCommand } from "../../../commands/PropertyChangeCommand";
import { SectionRearrangeCommand } from "../../../commands/SectionRearrangeCommand";
import { ConvrrtFirebase } from "../../../convrrt-firebase";
import moveElementInArray from "../../../lib/moveElementInArray";
import * as actions from '../../../store/modules/actions';
import * as mutations from "../../../store/modules/mutations";

export default {
  props: {
    fbNode: {
      type: Object,
      default: () => {}
    },
    background: {
      type: Object,
      default () { return {
        coloroverlay: "",
        coloralpha: 1,
        overlay: false
      } }
    },
    padding: {
      type: Object,
      default () { return {} }
    },
    margin: {
      type: Object,
      default () { return {} }
    },
  },
  computed: {
    isEmpty () {
      return this.fbNode && this.fbNode.children
          ? this.fbNode.children.length === 0 : true
    },
  },
  methods: {
    ...mapActions({
      pushCommand: actions.HISTORY_COMMAND_PUSH,
    }),
    ...mapMutations({
      disableCommandPush: mutations.HISTORY_IGNORE,
      enableCommandPush: mutations.HISTORY_LISTEN
    }),
    getVNodeID () {
      return this.vnodeId
    },
    firebaseRef () {
      return new ConvrrtFirebase(this.path())
    },
    path () {
      if (this['.path'] === undefined && Object.prototype.hasOwnProperty.call(this.fbNode.data.props, '.path')) {
        return this.fbNode.data.props['.path']
      }
      return this['.path']
    },
    idx() {
      return this.firebaseRef().key
    },
    self () {
      return this.firebaseRef().ref
    },
    moveChild (idx, direction) {
      return this.transaction((children) => {
        return moveElementInArray(children, idx, direction)
      })
    },

    moveUp (steps = -1) {
      let command = new SectionRearrangeCommand(this.idx(), steps)
      this.validateAndPushCommand(command)
    },

    moveDown (steps = 1) {
      let command = new SectionRearrangeCommand(this.idx(), steps)
      this.validateAndPushCommand(command)
    },
    getDataPropsKey(key){
      return `data/props/${key}`
    },
    createCommand (key, value, vNodeID = null) {
      let command = null
      if (['desktop', 'phone'].includes(key)){
        command = new SizePropertyChangeCommand(vNodeID || this.getVNodeID(), this.getDataPropsKey(key), value)
      }
      else {
        command = new PropertyChangeCommand(vNodeID || this.getVNodeID(), this.getDataPropsKey(key), value)
      }
      return command
    },
    updateProp: function (key, value) {
      let command = this.createCommand(key, value)
      return this.validateAndPushCommand(command)
    },
    validateAndPushCommand(command) {
      if (command.hasChanges) {
        return this.pushCommand(command)
      }
      return new Promise(resolve => {resolve()})
    },
    deleteNode(idx) {
      this.firebaseRef().removeNode(idx)
    },
    resize (fitContent=false) {
      if (this.isGridBuilder && !['GridItemWrapper', 'GridItemWrapperV3'].includes(this.parent().name)) {
        return
      }

      const gridItem = this.parent()
      const gridContainer = this.parent().parent()

      this.$nextTick(() => {
        if (gridContainer.getGrid && typeof gridContainer.getGrid === 'function' && gridContainer.getGrid()?.opts) {
          let ofHeight = Math.max(this.$el.offsetHeight, ...Array(...this.$el.children).map(a => a.offsetHeight))
          if (fitContent) {
            ofHeight += + (gridContainer.getGrid().opts.cellHeight)
          }
          const height = Math.round(
              (ofHeight + (gridContainer.getGrid().opts.verticalMargin || 0)) / ((gridContainer.getGrid().getCellHeight() + (gridContainer.getGrid().opts.verticalMargin || 0)) || 1)
          )

          if (!gridContainer.itemResizing) {
            gridContainer.getGrid().update(
                gridItem.$el, {w: gridItem.dataWidth, h: fitContent? height: Math.max(height, gridItem.dataHeight)}
            )
          }
        }
      })
    },
  }
}