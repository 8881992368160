
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from "vuex";
import * as _ from "lodash";
import * as constants from "../../../../builder/src/js/common/constants/constants";
import * as getters from "../../../../builder/src/js/store/modules/getters";
import * as actions from "../../../../builder/src/js/store/modules/actions";


export default Vue.extend({
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      selectedIntegration: getters.SELECTED_INTEGRATION,
      selected: getters.SELECTOR_SELECTED,
    }),
    ...mapState('sitePreferences', {
      integrationConfigs: ({ preferences }) => _.get(preferences, 'integrations', {}),
    }),
    integrationConfig () {
      return this.integrationConfigs[this.selected.id] || {
        provider: "",
        step: constants.AUTH_INIT
      }
    },
    showAuthScreen () {
      if (this.integrationConfig.step === constants.AUTH_INIT) {
        return true
      }
      else if (!this.isValidProvider) {
        return true
      }

      return false
    },
    isValidProvider () {
      return this.integrationConfig.provider === this.selectedIntegration.key
    }
  },
  methods: {
    ...mapActions('sitePreferences', {
      saveIntegrationStep: 'saveIntegrationStep',
    }),
    ...mapActions({
      setSelectedIntegration: actions.SELECT_INTEGRATION,
    }),
    onCancel() {
      this.setSelectedIntegration(null).then()
    },
    onCompleted() {
      this.onChangeAuthStep(constants.INTEGRATION_COMPLETE, this.selected.id)
    },
    onChangeAuthStep(step, formId) {
      const params = {
        provider: this.selectedIntegration.key,
        step: step,
        formId: formId
      }
      this.saveIntegrationStep(
        {
          formId,
          params
        }
      ).then()
    },
    onFinished(step = constants.INTEGRATION_FINISHED) {
      this.onChangeAuthStep(step, this.selected.id)
      this.onCancel()
    },
    onUpdate(step = constants.INTEGRATION_FINISHED) {
      this.onFinished(step)
    }
  }
})
</script>
