<script>
import FirebaseNodeV3 from '../../base/FirebaseNode'
import CButton from "../../../editor/nodes/CButton.vue";
import uuid from "uuid/v4";

export default CButton.extend({
  name: "CButtonV3",
  mixins: [FirebaseNodeV3],

  mounted() {
    if (this.form && !this.vnodeId && !this.formVNodeId) {
      // we need to create a temporary vnodeID for v3 to track the form submit button property changes.
      this.formVNodeId = uuid()
    }
  },
  methods: {
    getVNodeID () {
      return this.vnodeId || this.formVNodeId
    },
    updateGenerativeContent(content)  {
      this.updateProp('cta', content)
    }
  }
})
</script>
