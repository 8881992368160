import { styled, VueEmotion } from '@egoist/vue-emotion'
import { mapGetters, mapState, mapActions } from 'vuex'
import * as getters from '../../../builder/src/js/store/modules/getters'
import Vue from '../../lib/utils/vue'

Vue.use(VueEmotion)

let FontFamily,
  Spacing,
  Radii,
  Colors,
  Border,
  ColoredActive,
  ShowLeftNavShadow,
  textFontWeight,
  color,
  backgroundColor,
  textFontSize,
  elevation,
  textColor,
  linkColor,
  IconSize

const getLeftNav = () => styled('aside')`
  display: flex;
  flex-direction: column;
  width: 280px;
  font-family: ${FontFamily.DEFAULT};
  overflow-y: auto;
  ${() =>
    ShowLeftNavShadow ? `box-shadow: -4px 0px 19px 5px rgba(0,0,0,0.3);` : ``}
  ${(props) => {
    let styles = ``
    if (props.type === 'alternate' && props.usage === 'landingPage') {
      styles += backgroundColor({ themeing: 'dark' }, 'WHITE')
    } else {
      let weight = 'WHITE'
      weight = props.mode === 'DARK' ? 700 : 'WHITE'
      styles += `
      ${backgroundColor(
        { themeing: props.mode === 'DARK' ? 'dark' : 'light' },
        weight,
      )};
        padding: 0 ${Spacing.SPACING_24}px;
      `
    }
    return styles
  }}

  &.__fixed {
    position: fixed;
    height: calc(100vh - 50px);
    z-index: 1;
    overflow-y: auto;
    padding-bottom: ${Spacing.SPACING_64}px;
    padding-bottom: ${Spacing.SPACING_192}px;
  }
`

export const CvtLeftSidebar = {
  name: 'CvtLeftSidebar',
  data() {
    return {}
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    usage: {
      type: String,
      default: 'landingPage',
    },
    type: {
      type: String,
      default: 'standard',
    },
    fixed: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      elevation: getters.GLOBAL_STYLE_ELEVATION,
      textColor: getters.GLOBAL_STYLE_COLOR,
    }),
    ...mapState('globalTheme', {
      leftNavConfig: ({ leftNavConfig }) => leftNavConfig,
      iconConfig: ({ iconConfig }) => iconConfig,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      Border: ({ globalTheme }) => globalTheme.Border,
      IconSize: ({ globalTheme }) => globalTheme.IconSize,
      ColoredActive: ({ globalTheme }) =>
        globalTheme.LeftSideBarColoredActive !== undefined
          ? globalTheme.LeftSideBarColoredActive
          : false,
      ShowLeftNavShadow: ({ globalTheme }) =>
        globalTheme.ShowLeftNavShadow !== undefined
          ? globalTheme.ShowLeftNavShadow
          : false,
      leftNavItemsLinkColor: ({ leftNavConfig }) =>
        leftNavConfig.marketplace.navItems.linkColor,
      certifiedTemplatesMarketplaceUI: ({ globalTheme }) =>
        globalTheme.certifiedTemplatesMarketplaceUI,
      marketplaceLeftNavBarBottomBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig.locations.marketplaceLeftNavBarBottom.locations
          .default.state,
    }),
    isGridBuilder() {
      if (!this.config) return
      return this.config.GRID_BUILDER_ENABLED
    },
  },
  methods: {
    ...mapActions('loader', {
      startLoader: 'start',
      stopLoader: 'stop',
    }),
  },
  created() {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    Colors = this.Colors
    Border = this.Border
    IconSize = this.IconSize
    textFontWeight = this.textFontWeight
    color = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    elevation = this.elevation
    textColor = this.textColor
    ColoredActive = this.ColoredActive
    ShowLeftNavShadow = this.ShowLeftNavShadow
    linkColor = this.leftNavItemsLinkColor
  },
  render: function (h) {
    const LeftNav = getLeftNav()

    return (
      <LeftNav
        mode={this.mode}
        type={this.type}
        usage={this.usage}
        class={{ __fixed: this.fixed }}
      >
        {this.$slots.default}
      </LeftNav>
    )
  },
}
