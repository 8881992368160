import Vue from 'vue'
import * as _ from 'lodash'
import * as actions from '@/js/store/modules/actions'
import * as getters from '@/js/store/modules/getters'
import { mapActions, mapGetters } from 'vuex'
import { getThemeMode } from '@/js/lib/helperFunctions'
import SpacingCtrl from '../SpacingCtrl.vue'


export default Vue.extend({
  components: {
    SpacingCtrl,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    prependChevron: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
    }),
    themeMode() {
      return getThemeMode(this.mode)
    },
    showThumbnail: {
      get () {
        return _.get(this, 'selected.showThumbnail', true)
      },
      set (value) {
        return this.updateProp({ key: 'showThumbnail', value })
      },
    },
    showTitle: {
      get () {
        return _.get(this, 'selected.showTitle', true)
      },
      set (value) {
        return this.updateProp({ key: 'showTitle', value })
      },
    },
    showBenefits: {
      get () {
        return _.get(this, 'selected.showBenefits', true)
      },
      set (value) {
        return this.updateProp({ key: 'showBenefits', value })
      },
    },
    showCost: {
      get () {
        return _.get(this, 'selected.showCost', true)
      },
      set (value) {
        return this.updateProp({ key: 'showCost', value })
      },
    },
    showSavings: {
      get () {
        return _.get(this, 'selected.showSavings', true)
      },
      set (value) {
        return this.updateProp({ key: 'showSavings', value })
      },
    },
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
    }),
    updateCheckoutProp(propName: string, state: string ) {
      this[propName] = state != "Inactive"

      // This setTimeout is necessary as DOM has not been updated yet and if we resize it at the same moment, it would give no effect.
      // So we are waiting for DOM to update and then we are applying resize to it 
      setTimeout(() => {
        this.selected.resize(true)
      }, 1000)
    },
  },
})