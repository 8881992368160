
<div data-identity="AddVideoUrl">
  <h4 class="admin-label">
    {{ $t('launch.tab.control.heading.add_video_url') }}
  </h4>
  <cvt-input
    type="text"
    name="videoUrl"
    placeholder="https://"
    :clearable="false"
    :value="videoUrl"
    @input="(event) => { $emit('siteVideoUrlChange', event) }"
  />
</div>
