<!-- <template>
  <div :class="{'drag-item': true, 'show': open}">
    <cvt-button
      v-if="!open"
      color="dark"
      icon="cvt-icon-plus"
      class="add-button-drag"
      size="xl"
      @click="() => openOrClose(true)"
    />
    <div :class="{'d-flex h-100': true, 'drag-item__collapse': !open}">
      <div :class="{'drag-item__container': true, 'open': open}">
        <cvt-button
          color="dark"
          icon="cvt-icon-plus"
          class="add-button-drag rotate-plus-to-close"
          size="xl"
          @click="() => openOrClose(false)"
        />
        <div
          v-for="item of dragItems"
          :id="item.id + '~' + vnodeId"
          :key="'btn-' + item.name"
          :class="newWidgetClasses"
          :gs-w="item[viewMode].w"
          :gs-h="item[viewMode].h"
          :data-gs-width="item[viewMode].w"
          :data-gs-height="item[viewMode].h"
          draggable="true"
        >
          <div
            :id="item.name + '~' + vnodeId"
            class="grid-stack-item-content drag-item__icon"
          >
            <i
              :title="item.name"
              role="presentation"
              :class="`icon ${item.icon} hover`"
              size="md"
              orgname="standard"
              prepend="true"
              text=""
            />
          </div>
        </div>
        <cvt-tooltip
          v-for="item of dragItems"
          :key="'tooltip-' + item.name + vnodeId"
          :target="item.id + '~'+vnodeId"
          size="xs"
        >
          <strong>{{ item.label }}</strong>
        </cvt-tooltip>
      </div>
    </div>
  </div>
</template> -->


<script lang="jsx">
import { mapState } from 'vuex'
import Vue from 'vue'
import { ELEMENT_DROPPED_ON_SUB_GRID_CONTAINER } from '@/js/plugins/events.js'

const GRID_CONTAINER_CLASS = 'grid-stack-item'

export default Vue.extend({
  name: 'DragItem',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    vnodeId: {
      required: true
    },
    widgetClass: {
      type: String,
      default () {
        return GRID_CONTAINER_CLASS
      }
    },
    viewMode: {
      type: String,
      required: true
    },
    excludeItem: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    let dragItems = [
      {icon: 'cvt-icon-four-squares-broken-line', name: 'container', label: 'container', type: 'Container', id: 'container_element'},
      {icon: 'far fa-pen', name: 'text', label: 'Text', type: 'Text', id: 'text_element'},
      {icon: 'fal fa-hand-pointer', name: 'button', label: 'Button', type: 'Button', id: 'button_element'},
      {icon: 'cvt-icon-picture-text', name: 'image', label: 'Image', type: 'Image', id: 'image_element'},
      {icon: 'cvt-icon-video', name: 'video', label: 'Video', type: 'Video', id: 'video_element'},
      {icon: 'cvt-icon-minus', name: 'divider', label: 'Divider', type: 'Divider', id: 'divider_element'},
      {icon: 'cvt-icon-logo', name: 'logo', label: 'Logo', type: 'Logo', id: 'logo_element'},
      {icon: 'cvt-icon-star', name: 'icon', label: 'Icon', type: 'Icon', id: 'icon_element'},
      {icon: 'cvt-icon-layout-three-rows', name: 'form', label: 'Form', type: 'Form', id: 'form_element'},
      {icon: 'cvt-icon-code', name: 'code', label: 'Code', type: 'Code', id: 'code_element'},
      {icon: 'fal fa-calendar', name: 'calendar', label: 'Calendar', type: 'Calendar', id: 'calendar_element'},
      {icon: 'fal fa-stopwatch', name: 'countdown_timer', label: 'Countdown Timer', type: 'Countdown Timer', id: 'countdown_timer_element'},
      {icon: 'cvt-icon-bullet-list', name: 'bullet_list', label: 'Bullet List', type: 'Bullet List', id: 'bullet_list_element'},
      {icon: 'fal fa-puzzle-piece', name: 'custom_widget', label: 'Custom Widget', type: 'Custom Widget', id: 'custom_widget_element'}
    ]
    return {
      dragItems: dragItems,
      open: false,
    }
  },
  computed: {
    newWidgetClasses () {
      return [
        {'mobile': this.viewMode === 'phone'},
        `newWidget ${this.widgetClass}-${this.vnodeId} ui-draggable icon-wrapper`
      ]
    },
    ...mapState('globalTheme', {
      elementDesktopSize: ({ elementSize }) => elementSize.gridStackDefaultSizeForDesktop,
      elementContainerDesktopSize: ({ elementSize }) => elementSize.gridContainerStackDefaultSizeForDesktop,
      elementPhoneSize: ({ elementSize }) => elementSize.gridStackDefaultSizeForPhone,
      builderElementsConfig: ({ globalTheme }) => globalTheme.builderElements,
    }),
    resolveItemViewMode (item) {
      return item[this.viewMode]
    }
  },
  created () {
    const elementDesktopSize = this.getDesktopElementSize()

    this.dragItems = this.dragItems.filter(y => !this.excludeItem.includes(y.name))
    .map(x => {
      const desktop = elementDesktopSize[x.id]
      const phone = this.elementPhoneSize[x.id]
      return {...x, desktop, phone}
    })
    .filter((dragItemElement) => {
      let isBuilderElementEnabled = this.builderElementsConfig.some((builderElement) => {
        return (builderElement.name === dragItemElement.name) && builderElement.enabledForGridBuilder
      })

      return isBuilderElementEnabled
    })
  },
  mounted () {
    this.$nextTick(() => {
      let rootElement = document.querySelector('body')
      rootElement.addEventListener('click', this.closeOnClickElseWhere, true)
    })

    this.$hub.$on(ELEMENT_DROPPED_ON_SUB_GRID_CONTAINER, (data) => {
      this.open = false;
    })
  },
  destroyed () {
    let rootElement = document.querySelector('body')
    rootElement.removeEventListener('click', this.closeOnClickElseWhere, true)
  },
  methods: {
    getDesktopElementSize () {
      if (this.widgetClass === GRID_CONTAINER_CLASS) {
        return this.elementDesktopSize
      }
      return this.elementContainerDesktopSize
    },
    openOrClose (value) {
      this.open = value
    },
    closeOnClickElseWhere (e) {
      const excludeFlags = ['drag-item__icon', 'ui-droppable', 'cvt-icon-plus', 'add-button-drag']
      const classList = [...e.target.classList].filter(x => excludeFlags.includes(x))
      if (classList.length === 0) {
        // this.open = false
      }
      if (classList.includes('cvt-icon-plus') || classList.includes('add-button-drag')) {
        if (e.target.parentElement !== this.$el) {
          this.open = false
        }
      }
    }
  },

  render(h) {
    return (
      <div class={{'drag-item': true, 'show': this.open}}>

        {!this.open && (
          <cvt-button
            color="dark"
            icon="cvt-icon-plus"
            class="add-button-drag"
            size="xl"
            onClick={() => this.openOrClose(true)}
          />
        )}

        <div class={{'d-flex h-100': true, 'drag-item__collapse': !this.open}}>
          <div class={{'drag-item__container': true, 'open': this.open}}>
            <cvt-button
              color="dark"
              icon="cvt-icon-plus"
              class="add-button-drag rotate-plus-to-close"
              size="xl"
              onClick={() => this.openOrClose(false)}
            />
            
            {this.dragItems.map((item) => {
              return (
                <div
                  id={item.id + '~' + this.vnodeId}
                  key={'btn-' + item.name}
                  class={this.newWidgetClasses}
                  gs-w={item[this.viewMode] ? item[this.viewMode].w : ''}
                  gs-h={item[this.viewMode] ? item[this.viewMode].h : ''}
                  data-gs-width={item[this.viewMode] ? item[this.viewMode].w : ''}
                  data-gs-height={item[this.viewMode] ? item[this.viewMode].h : ''}
                  draggable={true}
                >
                  <div
                    id={item.name + '~' + this.vnodeId}
                    class="grid-stack-item-content drag-item__icon"
                  >
                    <i
                      title={item.name}
                      role={"presentation"}
                      class={`icon ${item.icon} hover`}
                      size="md"
                      orgname="standard"
                      prepend="true"
                      text=""
                    />
                  </div>
                </div>
              )}
            )}

          </div>
        </div>
      </div>
    )
  }
})
</script>

<style scoped lang="scss">
@import "@/scss/utils";
.page-engine-wrapper-cpopup {
  .drag-item{
    top:0;
  }
}
.drag-item, .add-item{
  position: absolute;
  width: 100%;
  top:-35px;
  margin:auto;
  display: flex;
  justify-content: center;
  //left: 0;
  height: 0;
  &.show {
    height: auto;
    z-index: 99999999999999;
    top: 5px;
    position: sticky;
  }
  &__collapse{
    visibility: hidden;
  }
  &__container{
    display: flex;
    max-width: 80vw;
    overflow: hidden;
    //max-height: 50vh;
    text-align: center;
    background-color: transparent;
    color: #494C53;
    padding: 12px 0px;
    border-radius: 10px;
    width: 52px;
    transition: all 250ms cubic-bezier(0.27,0.01,0.38,1.06);
    &.open {
      z-index: 1000;
      background-color: $white;
      width: auto;
      box-shadow: 0 3px 5px -1px rgba(0,0,0,.20), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
      &.mobile{
        width: 100%;
      }
    }
    &.mobile{
      width: 14%;
    }
  }
  .icon-wrapper{
    margin-right: 5px;
    margin-left: 5px;

    &:nth-child(1){
      margin-left: 0;
    }
    &:nth-child(2){
      margin-left: 12px;
    }
    &:nth-last-child(1){
      margin-right: 0.8rem;
    }
    &.mobile{
      margin-left: .5rem;
    }
  }
  .add-button-drag{
    z-index: 2;

    .overlay-texture & {
      z-index: 1;
    }

    padding: 6px;
    border-radius: 30px;
    background: $bright-blue;
    box-shadow: 0 0 7px rgba(0,0,0,.32),0 2px 8px rgba(0,0,0,.04);
    width: 32px;
    height: 32px;
    margin-top: 2px;
    display: flex;
    align-items: center;
    &:hover {
      background: $darker;
    }
    &.rotate-plus-to-close {
      margin-left: 1rem;
      transform: rotate(135deg);
      transition: transform 0.4s;
      background: $white;
      color: $dark;
      border: 1px solid $dark;
      box-shadow: none;
    }
  }
  &__icon{
    display: flex;
    position: relative;
    align-items: baseline;
    justify-content: center;
    width: auto;
    font-weight: 300;
    text-align: center;
    vertical-align: middle;
    cursor: grab;
    user-select: none;
    transition: all 250ms cubic-bezier(0.27,0.01,0.38,1.06);
    outline: none !important;
    border: 1px solid transparent;
    padding: 8px;
    font-size: 18px;
    line-height: 12px;
    background-color: transparent;
    box-shadow: none;
    color: #494c53;
    &:hover{
      background-color: #E2E6ED;
      cursor: grab;
    }
    &:active, &:target{
      cursor: grabbing;
    }
    &-no_drag{
      cursor: pointer !important;
    }
  }
}
</style>
