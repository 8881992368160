<template>
  <div
    v-if="isAdmin"
    class="container mt-5 pt-5"
  >
    <div class="playground-banner">
      <cvt-alert
        color="danger"
        :close-icon="true"
      >
        <h4>You're currently logged in as orgID: <strong>{{ admin.orgID }}</strong> projectID: <strong>{{ admin.projectID }}</strong></h4>
      </cvt-alert>
    </div>
    <div class="item-list">
      <play-alert />
      <play-button />
      <play-dropdown />
      <play-pagination />
      <play-progress />
      <play-collapse />
      <play-dialog />
      <play-input />
      <play-item-list />
      <play-nav />
      <play-popover />
      <play-radio-group />
      <play-select />
      <play-slider />
      <play-switch />
      <play-table />
      <play-tab />
      <play-tooltip />
      <play-upload />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import * as getters from '../../store/modules/getters'
import PlayAlert from './PlayAlert.vue'
import PlayButton from './PlayButton.vue'
import PlaySwitch from './PlaySwitch.vue'
import PlayDropdown from './PlayDropdown.vue'
import PlayPagination from './PlayPagination.vue'
import PlayProgress from './PlayProgress.vue'
import PlayCollapse from './PlayCollapse.vue'
import PlayDialog from './PlayDialog.vue'
import PlayRadioGroup from './PlayRadioGroup.vue'
import PlayTable from './PlayTable.vue'
import PlaySelect from './PlaySelect.vue'
import PlayNav from './PlayNav.vue'
import PlayInput from './PlayInput.vue'
import PlayTab from './PlayTab.vue'
import PlayItemList from './PlayItemList.vue'
import PlayUpload from './PlayUpload.vue'
import PlayTooltip from './PlayTooltip.vue'
import PlaySlider from './PlaySlider.vue'
import PlayPopover from './PlayPopover.vue'

export default {
  name: 'PlayGround',
  components: {
    PlayAlert,
    PlayButton,
    PlaySwitch,
    PlayDropdown,
    PlayPagination,
    PlayProgress,
    PlayCollapse,
    PlayDialog,
    PlayRadioGroup,
    PlayTable,
    PlaySelect,
    PlayNav,
    PlayInput,
    PlayTab,
    PlayItemList,
    PlayUpload,
    PlayTooltip,
    PlaySlider,
    PlayPopover
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN,
      admin: getters.AUTH_GET_USER
    }),
  },
  created () {
    this.hideLoader()
    if (this.admin.config.GLOBAL_THEME) {
      this.setTheme(this.admin.config.GLOBAL_THEME)
    }
  },
  methods: {
    ...mapActions('loader', {
      hideLoader: 'stop'
    }),
    ...mapActions('globalTheme', {
      setTheme: 'setTheme'
    }),
  },
}
</script>
<style lang="scss">
@import "@/scss/utils";
.playground-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
}
</style>
