<template>
  <div>
    <h1>Table</h1>
    <h4 class="mt-4">Dark, Single Select</h4>
    <cvt-table
      mode="DARK"
      style="width: 100%;"
      :single-select="true"
      :value="selectedRow1"
      striped
      @change="onItemChange1"
    >
      <cvt-thead>
        <cvt-tr>
          <cvt-th>Date</cvt-th>
          <cvt-th>Name</cvt-th>
          <cvt-th>Address</cvt-th>
        </cvt-tr>
      </cvt-thead>
      <cvt-tbody>
        <cvt-tr
          v-for="(item, index) in tableData"
          :key="index"
          :name="index"
        >
          <cvt-td>{{ item.date }}</cvt-td>
          <cvt-td>{{ item.name }}</cvt-td>
          <cvt-td>{{ item.address }}</cvt-td>
        </cvt-tr>
      </cvt-tbody>
    </cvt-table>

    <h4 class="mt-4">Light, Multi Select</h4>
    <cvt-table
      mode="LIGHT"
      style="width: 100%;"
      :multi-select="true"
      :value="selectedRow2"
      striped
      @change="onItemChange2"
    >
      <cvt-thead>
        <cvt-tr>
          <cvt-th>Date</cvt-th>
          <cvt-th>Name</cvt-th>
          <cvt-th>Address</cvt-th>
        </cvt-tr>
      </cvt-thead>
      <cvt-tbody>
        <cvt-tr
          v-for="(item, index) in tableData"
          :key="index"
          :name="index"
        >
          <cvt-td>{{ item.date }}</cvt-td>
          <cvt-td>{{ item.name }}</cvt-td>
          <cvt-td>{{ item.address }}</cvt-td>
        </cvt-tr>
      </cvt-tbody>
    </cvt-table>
  </div>
</template>
<script>
export default {
  name: 'PlayAlert',
  components: {
  },
  data () {
    return {
      selectModes: {
        Default: '',
        SingleSelect: 'single',
        MultiSelect: 'multi'
      },
      modes: {
        Dark: 'DARK',
        Light: 'LIGHT'
      },
      selectedRow1: [],
      selectedRow2: [],
      tableData: [
        {
          date: '2016-05-03',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        },
        {
          date: '2016-05-02',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        },
        {
          date: '2016-05-04',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        },
        {
          date: '2016-05-01',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        },
        {
          date: '2016-05-08',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        },
        {
          date: '2016-05-06',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        },
        {
          date: '2016-05-07',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles'
        }
      ],
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    onItemChange1 (values) {
      this.selectedRow1 = [...values]
    },
    onItemChange2 (values) {
      this.selectedRow2 = [...values]
    }
  },
}
</script>
<style lang="scss">
</style>
