import * as _ from 'lodash'

export default function vueDomo(h, v, node) {
  if (_.isString(node)) return v(node)

  let vNode = _.cloneDeep(node)

  _.set(vNode, 'data.props[".key"]', node['.key'])
  _.set(vNode, 'data.props[".path"]', node['.path'])

  vNode.children = vNode.children || []
  if (!Array.isArray(vNode.children))  {
      vNode.children = Object.values(vNode.children)
  }

  return h(
    vNode.tag,
    vNode.data,
    vNode.children.map((child, idx) => {
      if (_.isString(child)) return v(child)
      if (!child) return
      child['.key'] = `${idx}`
      child['.path'] = `${node['.path']}/children/${idx}`
      return vueDomo(h, v, child)
    }),
  )
}
