
<div>
  <h1>Alert</h1>
  <cvt-alert
    v-for="(color, key) in colors"
    :key="key"
    :color="color"
    :close-icon="true"
  >
    {{ key }}
  </cvt-alert>
</div>
