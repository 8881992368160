// @ts-nocheck
import { storage } from '@/js/store/services/firebase.service'
import path from 'path-browserify'
import uuid from 'uuid/v4'
import { mapGetters, mapMutations, mapState } from 'vuex'
import {
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../../storybook/components/constants'
import UploadUiIcon from '../../../../../static/img/ui-upload-icon.svg'
import UiUploadSpinner from '../../../../../static/img/ui-upload-spinner.svg'
import * as getters from '../../../store/modules/getters'

class Upload {
  constructor({ file, ref }) {
    this.file = file
    this.ref = ref
    this.progress = 0
    this.status = ''
    this.task = null
  }

  put() {
    return this.ref.put(this.file)
  }

  start() {
    this.task = this.put()

    let unsubscribe = this.task.on(storage.TaskEvent.STATE_CHANGED, (snap) => {
      let percent = (snap.bytesTransferred / snap.totalBytes) * 100
      if (percent > 0) {
        this.progress = parseInt(percent)
      }
    })

    this.task.then((snap) => {
      this.progress = 100
      this.status = 'success'
      unsubscribe()
    })

    this.task.catch(() => {
      this.progress = 100
      this.status = 'exception'
    })

    return this
  }

  pause() {
    this.task.pause()
    return this
  }

  resume() {
    this.task.resume()
    return this
  }

  cancel() {
    this.task.cancel()
    return this
  }
}

class UploadQueue {
  constructor(uploads = []) {
    this.stack = uploads
  }

  push(upload) {
    this.stack.push(upload)
  }

  start() {
    this.stack = this.stack.map((f) => f.start())
    return Promise.all(this.stack.map((f) => f.task))
  }

  pause() {
    this.stack = this.stack.map((f) => f.pause())
  }

  cancel() {
    this.stack = this.stack.map((f) => f.cancel())
  }

  drain() {
    this.cancel()
  }
}

export default {
  props: {
    label: {
      type: String,
      default: 'Drag files here',
    },
    btnLabel: {
      type: String,
      default: 'Select a file from your computer',
    },
    multi: {
      type: Boolean,
      default: false,
    },
    bucket: {
      type: String,
      default: 'cdn.convrrt.com',
    },
    private: {
      type: Boolean,
      default: false,
    },
    hideGalleryButton: {
      type: Boolean,
    },
    imageOnly: {
      type: Boolean,
      default: false,
    },
    // If the value is more than zero, limit will be applied in mb.
    sizeLimit: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      queue: new UploadQueue(),
      dragging: false,
      alertMessage: '',
      progressColor: 'dark',
      viewOnlyImage: '',
      showUploadProgressFlow: false,
      uploadIconSvg: UploadUiIcon,
      uploadSpinnerSvg: UiUploadSpinner,
    }
  },
  computed: {
    ...mapGetters({
      user: getters.AUTH_GET_USER,
    }),
    ...mapState('globalTheme', {
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
      Colors: ({ globalTheme }) =>
        globalTheme.Colors !== undefined
          ? globalTheme.Colors
          : LEFT_SIDEBAR_STANDARD,
      AltPopupManager: ({ globalTheme }) =>
        globalTheme.AltPopupManager !== undefined
          ? globalTheme.AltPopupManager
          : false,
    }),
    prefix() {
      return this.private ? 'users' : 'apps'
    },
    userKey() {
      return this.private
        ? this.user.id
        : [this.user.orgID, this.user.projectID].join('/')
    },
    storageRef() {
      return storage()
        .refFromURL(`gs://${this.bucket}`)
        .child(`/${this.prefix}/${this.userKey}`)
    },
    dndMsg() {
      return this.dragging ? 'Drop file(s) here' : this.label
    },
    dragEnabledClass() {
      return {
        'upload-dropzone--active': this.dragging,
      }
    },
    uploadInProgress() {
      return this.queue.stack.some((u) => u.status === '')
    },
    uploadProgressColor() {
      return this.uploadError ? 'danger' : 'dark'
    },
    stopSpinnerClass() {
      return this.uploadError ? 'upload-queue__stop__spinner' : ''
    },
    uploadError() {
      return this.queue.stack.some((u) => u.status === 'exception')
    },
    uploadSuccess() {
      return this.queue.stack.some((u) => u.status === 'success')
    },
    isSpecialStyle() {
      return this.OrgName === LEFT_SIDEBAR_FRESHWORKS ? 'dark' : ''
    },
    acceptUploadTypes() {
      let allowedType = ''

      if (this.imageOnly) {
        allowedType = 'image/x-png,image/gif,image/jpeg,image/svg+xml'
      }

      return allowedType
    },
  },
  methods: {
    ...mapMutations({}),
    openImageSearch() {
      this.$emit('gallery-btn-clicked')
    },
    openFileDialog() {
      this.$el.querySelector('.el-upload__input').click()
    },
    closePreview() {
      this.showUploadProgressFlow = false
    },
    reset() {
      if (this.queue) {
        this.queue.drain()
      }

      this.queue = new UploadQueue()
    },
    dragenter(e) {
      this.dragging = true
      console.debug('dragenter', e)
      return true
    },
    dragover(e) {
      console.debug('dragover', e)
      return false
    },
    dragleave(e) {
      this.dragging = false
      console.debug('dragleave', e)
    },
    dropped(e) {
      this.dragging = false
      this.filesSelected({ target: { files: e.dataTransfer.files } })
    },
    genUUIDNAME(f) {
      return `${uuid()}${path.extname(f.name)}`
    },

    //Validate file type and size
    validateFiles(files) {
      const allowedImages = ['jpeg', 'jpg', 'png', 'gif', 'svg+xml']
      let result = { success: true, message: '' }

      for (let i = 0; i < files.length; i++) {
        let file = files.item(i)

        //size validation
        if (this.sizeLimit > 0) {
          let sizeInMB = (file.size / (1024 * 1024)).toFixed(1)

          if (sizeInMB > this.sizeLimit) {
            let validationMsg = `You are trying to upload a file of size ${sizeInMB}mb. `
            validationMsg += `File size more than ${this.sizeLimit}mb is not allowed.`

            result = { success: false, message: validationMsg }
            break
          }
        }
        //Type validation
        if (this.imageOnly) {
          let fType = file.type.split('/')
          console.debug(fType)
          if (!allowedImages.includes(fType[1])) {
            let validationMsg = `File type ${file.type} is not allowed. Please try with a different image file.`

            result = { success: false, message: validationMsg }
            break
          }
        }
      }

      return result
    },

    filesSelected(e) {
      this.reset()
      this.alertMessage = ''
      this.showUploadProgressFlow = true

      let files = e.target.files
      let valid = this.validateFiles(files)

      if (!valid.success) {
        this.alertMessage = valid.message

        let modalBody = document.getElementById('modal-body')
        modalBody.scrollTop = 0
        return false
      }

      if (this.imageOnly) {
        this.viewOnlyImage = URL.createObjectURL(files[0])
      }

      for (let i = 0; i < files.length; i++) {
        let file = files.item(i)

        this.queue.push(
          new Upload({
            file,
            ref: this.storageRef.child(this.genUUIDNAME(file)),
          }),
        )
      }

      this.queue.start().then((results) => {
        this.reset()
        this.$emit('done')
        this.$emit(
          'complete',
          results.map((t) => `https://${t.ref.bucket}/${t.ref.fullPath}`),
        )
      })
    },
  },
}
