import Vue from '../../../lib/utils/vue'
import VueI18n from 'vue-i18n'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { styled } from '@egoist/vue-emotion'
import { mapGetters, mapState } from '../../../../../node_modules/.pnpm/vuex@3.0.1/node_modules/vuex'
import { CvtIcon } from '../../molecules/icon/icon'
import { CvtTooltip } from '../../molecules/tooltip/CvtTooltip'

Vue.use(VueI18n)

let Spacing, backgroundColor, color, borderColor;
  
// TODO: VUE3_UPDATE: Vue 2 work around for fragmenting in jsx, can be removed and it native to Vue 3
const Fragment = {
  functional: true,
  render: (h, ctx) => ctx.children,
}

const getMainWrap = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.isDesktopView ? `flex-direction: row;` : `flex-direction: column;`}
  gap: 8px;
`

const getBtn = () => styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;

  ${(props) => {
    let styles = ``;
    let backgroundColorObj = props.btnState.default.backgroundColor;
    let borderColorObj = props.btnState.default.borderColor;

    if (!backgroundColorObj.custom) {
      styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
    } else if (backgroundColorObj.custom) {
      styles += `background: ${backgroundColorObj.value};`;
    }

    if (!borderColorObj.custom) {
      styles += `${borderColor(borderColorObj.value, borderColorObj.weight)}`;
    } else if (borderColorObj.custom) {
      styles += `border-color: ${borderColorObj.value};`;
    }

    return styles;
  }}

  padding: ${Spacing.SPACING_8 + 2}px;
  border-radius: ${Spacing.SPACING_24 + Spacing.SPACING_4 + Spacing.SPACING_2}px;

  &:hover {
    ${(props) => {
      let styles = ``;
      let backgroundColorObj = props.btnState.hover.backgroundColor;

      if (!backgroundColorObj.custom) {
        styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
      } else if (backgroundColorObj.custom) {
        styles += `background: ${backgroundColorObj.value};`;
      }
      return styles;
    }}
  }
`

const getArrowBtnIcon = () => styled(CvtIcon)`
  ${(props) => {
    let styles = ``;
    let colorObj = props.iconState.default.color;

    if (!colorObj.custom) {
      styles += `${color(colorObj.value, colorObj.weight)}`;
    } else if (colorObj.custom) {
      styles += `color: ${colorObj.value};`;
    }

    return styles;
  }}

  font-size: ${Spacing.SPACING_18}px;
  font-weight: bold;
`

const getTooltip = () => styled(CvtTooltip)``


export const CvtSectionUpDownActions = {
  name: 'CvtSectionUpDownActions',
  props: {
    vnodeId: {
      type: String,
      required: true,
      default: ''
    },
    isGridBuilder: {
      type: Boolean,
      required: false,
      default: false
    },
    showUpAndDownButton: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return { }
  },
  computed: {
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      iconConfig: ({ iconConfig }) => iconConfig,
      upDownArrowBtnsConfig: ({ globalButtonConfig }) => globalButtonConfig.locations.blockComponent.locations.upDownArrowBtns,
      autoArrangeBtnConfig: ({ globalButtonConfig }) => globalButtonConfig.locations.blockComponent.locations.autoArrangeBlockBtn,
    }),
    ...mapGetters('globalTheme', {
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
    }),
    ...mapGetters({
      viewMode: getters.VIEW_MODE,
    }),
    isDesktopView() {
      return this.viewMode === 'desktop';
    },
    isMobileView() {
      return this.viewMode === 'phone';
    }
  },
  created() {
    Spacing = this.Spacing
    backgroundColor = this.backgroundColor
    color = this.textColor
    borderColor = this.borderColor
  },

  render(h) {
    const MainWrap = getMainWrap();
    const UpArrowBtn = getBtn();
    const DownArrowBtn = getBtn();
    const AutoArrangeBlockBtn = getBtn();
    const ArrowBtnIcon = getArrowBtnIcon();
    const Tooltip = getTooltip();

    return (
      <MainWrap
        isDesktopView={this.isDesktopView}
      >
        {!this.showUpAndDownButton && (
            <Fragment>
              <UpArrowBtn
                  id={`${this.vnodeId}-move-section-up-btn-d`}
                  class="sc-move-up-button-d"
                  btnState={this.upDownArrowBtnsConfig.state}
                  onClick={(event) => this.$emit('moveSectionUp', event)}
              >
                <ArrowBtnIcon 
                  class={'sva-element-btn-icon'} 
                  icon={this.iconConfig?.sectionUi?.up || 'cvt-icon-arrow-up-07'}
                  iconState={this.upDownArrowBtnsConfig.btnIcon.state}
                />
              </UpArrowBtn>
              <Tooltip target={`${this.vnodeId}-move-section-up-btn-d`}>{this.$t('editor.section.action.move_up.text')}</Tooltip>
            </Fragment>
        )}
        {!this.showUpAndDownButton && (
            <Fragment>
              <DownArrowBtn
                  id={`${this.vnodeId}-move-section-down-btn-m`}
                  class="sc-move-up-button-m"
                  btnState={this.upDownArrowBtnsConfig.state}
                  onClick={(event) => this.$emit('moveSectionDown', event)}
              >
                <ArrowBtnIcon 
                  class={'sva-element-btn-icon'} 
                  icon={this.iconConfig?.sectionUi?.down || 'cvt-icon-arrow-down-07'}
                  iconState={this.upDownArrowBtnsConfig.btnIcon.state}
                />
              </DownArrowBtn>
              <Tooltip target={`${this.vnodeId}-move-section-down-btn-m`}>{this.$t('editor.section.action.move_down.text')}</Tooltip>
            </Fragment>
        )}

        {(this.isGridBuilder && this.isMobileView) &&
          <Fragment>
            <AutoArrangeBlockBtn
              id={`${this.vnodeId}-auto-arrange-block-btn-m`}
              class="sc-arrange-block-button-m"
              btnState={this.autoArrangeBtnConfig.state}
              onClick={(event) => this.$emit('arrangeBlock', event)}
            >
              <ArrowBtnIcon 
                class={'sva-element-btn-icon'} 
                icon={this.iconConfig?.sectionUi?.autoArrange || 'cvt-icon-magic-wand-02'}
                iconState={this.autoArrangeBtnConfig.btnIcon.state}
              />
            </AutoArrangeBlockBtn>
            <Tooltip target={`${this.vnodeId}-auto-arrange-block-btn-m`}>{this.$t('editor.section.action.auto_arrange_block.text')}</Tooltip>
          </Fragment>
        }
      </MainWrap>
    );
  }
}


