import { CvtItem } from '../list/index'
import CvtProgress from '../Progress'
import { STATUS } from './helpers/shared-properties'
import { isFile } from '../../../lib/utils/inspect'
import { VueEmotion } from '@egoist/vue-emotion'
import Vue from '../../../lib/utils/vue'

Vue.use(VueEmotion)

const NAME = 'CvtFileItem'

export default {
  name: NAME,
  extends: CvtItem,
  components: {
    CvtProgress,
  },
  inject: {
    cvtList: {
      default() /* istanbul ignore next */ {
        return {}
      }
    }
  },
  data: () => ({
    thumbnail: null
  }),
  model: {
    prop: 'file',
    event: 'change'
  },
  props: {
    file: {
      type: [Object, File],
      default: null
    },
    timeout: {
      type: Number,
      default: 15000
    },
    status: {
      type: String,
      default: STATUS.PENDING
    },
    progress: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.thumbnail = isFile(this.file)
      ? URL.createObjectURL(this.file)
      : this.file[this.thumbnailProp] || this.fallbackThumbnail
  },
  beforeUpdate() {
    this.thumbnail = isFile(this.file)
      ? URL.createObjectURL(this.file)
      : this.file[this.thumbnailProp] || this.fallbackThumbnail
  },
  computed: {
    showProgress() {
      return (
        this.status === STATUS.PENDING || this.status === STATUS.IN_PROGRESS
      )
    },
    _iconsEnabled() {
      return this.iconsEnabled && !this.thumbnailsEnabled
    }
  },
  methods: {
    isSuccess(status) {
      return status === STATUS.SUCCESS
    },
    isPending(status) {
      return status === STATUS.PENDING || status === STATUS.IN_PROGRESS
    },
    onRemove(item) {
      this.$emit('remove', item.id, item.status)
    }
  },
  render: function (h) {
    return (
      this.file && <CvtItem
          item={this.file}
          removeEnabled={this.removeEnabled}
          thumbnailsEnabled={this.thumbnailsEnabled}
          fallbackThumbnail={this.fallbackThumbnail}
          iconsEnabled={this._iconsEnabled}
          fallbackIcon={this.fallbackIcon}
          iconProp={this.iconProp}
          thumbnailProp={this.thumbnail}
          descriptionProp={this.descriptionProp}
          titleProp={this.titleProp}
          endIcon={this.isSuccess(this.status) ? 'check-circle' : this.isPending(this.status) ? 'circle-notch fa-spin' : 'exclamation-triangle'}
          showSeparator={this.showSeparator}
          isCard={this.isCard}
          onRemove={(item) => this.onRemove(item)}
        >
          {this.showProgress && (
                <div slot='itemBottom' class='b-progress-bottom'>
                  <cvt-progress
                    class='b-file-progress'
                    showOutsetProgress={true}
                    height='3px'
                    bgColor='info'
                    timeout={this.file.size}
                    valueNow={this.progress}
                  ></cvt-progress>
                </div>
              )}
      </CvtItem>
    )
  }
}
