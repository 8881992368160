
<div class="mx-4 mt-4">
  <template v-if="brandManagerViewMode === BRAND_MODE_LIST">
    <branding-list />
  </template>

  <template v-if="brandManagerViewMode === BRAND_MODE_CREATE">
    <create-brand />
  </template>
</div>
