import { CreateWidgetFromFirebaseNode } from '../components/v3/lib/vue-dom-obj'
import {PageEngineCommands} from "./PageEngineCommands";
import {ConvrrtFirebase} from "../convrrt-firebase";


/**
 * Handles Section Swap Action
 */

export class SwapSectionCommand extends PageEngineCommands {
  private _oldBlockNode: any;
  private _newBlockNode: any;
  protected readonly _indexToBeReplacedAt: number;
  private readonly _oldWidget: any;
  protected readonly _newWidget: any;

  constructor(oldBlockNode: any, newBlockNode: any) {
    super()

    this._oldBlockNode = oldBlockNode;
    this._newBlockNode = newBlockNode;

    this._indexToBeReplacedAt = Number(oldBlockNode['.key']);

    this._oldWidget = CreateWidgetFromFirebaseNode(oldBlockNode, false)
    this._newWidget = CreateWidgetFromFirebaseNode(newBlockNode, true)
  }
  get$emitData() {
    return  {name: 'SwapSectionCommand', forceBuild: false}
  }
  get hasChanges() {
    return true
  }

  async execute() {
    await this.firebaseRef?.splice(this._indexToBeReplacedAt, 1, this._newWidget);
    this.$nextTick!(() => {
      const _addedGridStackNodeElement = this.$children[this._indexToBeReplacedAt]
      _addedGridStackNodeElement.$el.scrollIntoView({ behavior: 'smooth'})
    });
  }

  async unExecute() {
    await this.firebaseRef?.splice(this._indexToBeReplacedAt, 1, this._oldWidget);
  }
}


export class SwapHeaderFooterSectionCommand extends SwapSectionCommand {
  private readonly _firebaseRef: ConvrrtFirebase;
  constructor(rootKey: string, oldBlockNode: any, newBlockNode: any) {
    super(oldBlockNode, newBlockNode)
    this._firebaseRef = new ConvrrtFirebase(rootKey)
  }

  get firebaseRef () {
    return this._firebaseRef
  }

  async execute() {
    await this.firebaseRef?.splice(this._indexToBeReplacedAt, 1, this._newWidget);
  }
}
