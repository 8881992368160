import Vue from '../../../lib/utils/vue'
import VueI18n from 'vue-i18n'
import { mapGetters, mapActions, mapState } from 'vuex'
import { styled } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import * as actions from '../../../../builder/src/js/store/modules/actions'
import { CvtIcon } from '../../molecules/icon/icon'

Vue.use(VueI18n)

let FontFamily, Spacing, Radii, FontWeight, Colors, Border, backgroundColor, borderColor, textColor;

const getMainWrap = () => styled('div')`
  padding-bottom: ${Spacing.SPACING_12 - Spacing.SPACING_2}px;

  ${(props) => {
    let styles = `position: relative;`

    if(props.isDesktopView) {
      styles += `left: -${Spacing.SPACING_48 - Spacing.SPACING_2}px;`;
    } else {
      styles += `left: 0px;`;
    }
    return styles;
  }}

  &:hover {
    .sva-visibility-main-wrap{
      display: flex;
    }
  }
`

const getVisibilityBtn = () => styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  padding: ${Spacing.SPACING_8 + Spacing.SPACING_2}px ${Spacing.SPACING_16}px ${Spacing.SPACING_8 + Spacing.SPACING_2}px ${Spacing.SPACING_12 + Spacing.SPACING_2}px;
  border-radius: ${Spacing.SPACING_24 + Spacing.SPACING_4 + Spacing.SPACING_2}px;

  border-width: 1px;
  border-style: solid;

  ${(props) => {
    let styles = ``;
    let backgroundColorObj = props.btnState.default.backgroundColor;
    let borderColorObj = props.btnState.default.borderColor;

    if (!backgroundColorObj.custom) {
      styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
    } else if (backgroundColorObj.custom) {
      styles += `background: ${backgroundColorObj.value};`;
    }

    if (!borderColorObj.custom) {
      styles += `${borderColor(borderColorObj.value, borderColorObj.weight)}`;
    } else if (borderColorObj.custom) {
      styles += `border-color: ${borderColorObj.value};`;
    }

    return styles;
  }}

  &:hover {
    ${(props) => {
      let styles = ``;
      let backgroundColorObj = props.btnState.hover.backgroundColor;

      if (!backgroundColorObj.custom) {
        styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
      } else if (backgroundColorObj.custom) {
        styles += `background: ${backgroundColorObj.value};`;
      }
      return styles;
    }}
  }
`

const getVisibilityBtnIcon = () => styled(CvtIcon)`
  ${(props) => {
    let styles = ``;
    let colorObj = props.iconState.default.color;

    if (!colorObj.custom) {
      styles += `${textColor(colorObj.value, colorObj.weight)}`;
    } else if (colorObj.custom) {
      styles += `color: ${colorObj.value};`;
    }

    return styles;
  }}

  font-size: ${Spacing.SPACING_18}px;
`

const getVisibilityBtnText = () => styled('span')`
  font-family: ${FontFamily.DEFAULT};
  font-weight: ${FontWeight.BOLD};
  font-style: normal;
  font-size: ${Spacing.SPACING_12}px;
  height: ${Spacing.SPACING_12}px;
  line-height: ${Spacing.SPACING_12}px;
  padding: 0 0px 0 ${Spacing.SPACING_4 + Spacing.SPACING_2}px;

  ${(props) => {
    let styles = ``;
    let colorObj = props.textState.default.color;

    if (!colorObj.custom) {
      styles += `${textColor(colorObj.value, colorObj.weight)}`;
    } else if (colorObj.custom) {
      styles += `color: ${colorObj.value};`;
    }

    return styles;
  }}
`

const getVisibilityContentWrap = () => styled('div')``

const getVisibilityContentInnerWrap = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  height: ${Spacing.SPACING_48 + Spacing.SPACING_12}px;
  padding: ${Spacing.SPACING_4 + 3}px ${Spacing.SPACING_12 + 3}px ${Spacing.SPACING_4 + 3}px ${Spacing.SPACING_12 + 1}px;
  border-radius: ${Spacing.SPACING_12 + 3}px;
  gap: 7px;
  margin-top: ${Spacing.SPACING_8 + Spacing.SPACING_2}px;

  border-width: 1px;
  border-style: solid;

  ${(props) => {
    let styles = ``;
    let backgroundColorObj = props.btnState.default.backgroundColor;
    let borderColorObj = props.btnState.default.borderColor;

    if (!backgroundColorObj.custom) {
      styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
    } else if (backgroundColorObj.custom) {
      styles += `background: ${backgroundColorObj.value};`;
    }

    if (!borderColorObj.custom) {
      styles += `${borderColor(borderColorObj.value, borderColorObj.weight)}`;
    } else if (borderColorObj.custom) {
      styles += `border-color: ${borderColorObj.value};`;
    }

    return styles;
  }}

  &:hover {
    ${(props) => {
      let styles = ``;
      let backgroundColorObj = props.btnState.hover.backgroundColor;

      if (!backgroundColorObj.custom) {
        styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
      } else if (backgroundColorObj.custom) {
        styles += `background: ${backgroundColorObj.value};`;
      }
      return styles;
    }}
  }
`

const getCheckboxLabel = () => styled('label')`
  position: relative;
  padding: 0px 0px 0px ${Spacing.SPACING_24 + 1}px;
  margin-bottom: 0px;
  cursor: pointer;

  font-family: ${FontFamily.DEFAULT};
  font-weight: ${FontWeight.BOLD};
  font-style: normal;
  font-size: ${Spacing.SPACING_12}px;

  width: ${Spacing.SPACING_128 + Spacing.SPACING_12}px;
  display: flex;
  justify-content: start;
  align-items: center;

  ${(props) => {
    let styles = ``;
    let colorObj = props.cbLabelState.default.color;
    
    if (!colorObj.custom) {
      styles += `${textColor(colorObj.value, colorObj.weight)}`;
    } else if (colorObj.custom) {
      styles += `color: ${colorObj.value};`;
    }

    return styles;
  }}

  &:hover {
    .sva-checkmark {
      ${(props) => {
        let styles = `
          border-radius: 50%; 
          border-width: 2px; 
          border-style: solid;
        `;

        let backgroundColorObj = props.cbState.hover.backgroundColor;
        let borderColorObj = props.cbState.hover.borderColor;

        if (!backgroundColorObj.custom) {
          styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
        } else if (backgroundColorObj.custom) {
          styles += `background: ${backgroundColorObj.value};`;
        }
       
        if (!borderColorObj.custom) {
          styles += `${borderColor(borderColorObj.value, borderColorObj.weight)}`;
        } else if (borderColorObj.custom) {
          styles += `border-color: ${borderColorObj.value};`;
        }

        return styles;
      }}
    }
  }
`

const getCheckboxInput = () => styled('input')`
  position: absolute;
  opacity: 0;
  display: none;
  visibility: hidden;
  height: 0;
  width: 0;
  overflow: hidden;
  cursor: pointer;

  &:checked ~ span.sva-checkmark {
    border-width: 1.5px; 
    border-style: solid;
  }

  &:checked ~ .sva-checkmark:after {
    display: block;
  }
`

const getCheckMark = () => styled('span')`
  position: absolute;
  top: 0;
  left: 0px;
  height: ${Spacing.SPACING_12 + 4}px;
  width: ${Spacing.SPACING_12 + 4}px;
  border-radius: 50%;
  border: ${Spacing.SPACING_2 - 0.5}px solid white;

  ${(props) => {
    let styles = ``;
    let backgroundColorObj = props.cbState.unchecked.backgroundColor;

    if (!backgroundColorObj.custom) {
      styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
    } else if (backgroundColorObj.custom) {
      styles += `background: ${backgroundColorObj.value};`;
    } 
    return styles;
  }}

  &::after {
    content: '';
    position: absolute;
    display: none;
    top: 1.5px;
    left: 1.5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    ${(props) => {
      let styles = ``;
      let backgroundColorObj = props.cbState.checked.backgroundColor;

      if (!backgroundColorObj.custom) {
        styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
      } else if (backgroundColorObj.custom) {
        styles += `background: ${backgroundColorObj.value};`;
      }
      return styles;
    }}
  }
`

export const CvtSectionVisibility = {
  name: 'CvtSectionVisibility',
  props: { },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      Border: ({ globalTheme }) => globalTheme.Border,
      iconConfig: ({ iconConfig }) => iconConfig,
      sectionVisibilityCboxConfig: ({ globalCheckBoxConfig }) => globalCheckBoxConfig.locations.blockComponent.locations.sectionVisibilityBtn,
      sectionVisibilityBtnConfig: ({ globalButtonConfig }) => globalButtonConfig.locations.blockComponent.locations.sectionVisibilityBtn,
    }),
    ...mapGetters('globalTheme', {
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
    }),
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      viewMode: getters.VIEW_MODE,
    }),
    hideOnDesktop: {
      get () {
        return this.selected?.hideOnDesktop;
      },
      set (value) {
        return this.updateProp({ key: 'hideOnDesktop', value });
      }
    },
    hideOnMobile: {
      get () {
        return this.selected?.hideOnMobile;
      },
      set (value) {
        return this.updateProp({ key: 'hideOnMobile', value });
      }
    },
    isDesktopView() {
      return this.viewMode === 'desktop';
    },
    isMobileView() {
      return this.viewMode === 'phone';
    },
    mainWrapperClasses() {
      return {
        'sva-main-wrapper': true,
        'desktop': this.isDesktopView,
        'mobile': this.isMobileView,
      }
    }
  },
  watch: {
    selected(newValue, oldValue) {
      // so this code block makes sure that when ever user moves his cursor to other section, 
      // we dont want to keep the visibilty dropdown opened, hence the following logic.
      this.isOpen = false;
    }
  },
  created() {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    FontWeight = this.FontWeight
    Colors = this.Colors
    Border = this.Border
    backgroundColor = this.backgroundColor
    borderColor = this.borderColor
    textColor = this.textColor
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP
    }),
    sectionVisibilityChanged (event, device) {
      let checked = event.target.checked;

      if (device === 'desktop') 
      {
        if(checked) 
        {
          if(!this.hideOnMobile) 
          {
            this.hideOnDesktop = true;
          }
          else 
          {
            this.$notify({
              title: this.$t('editor.error.visibility_ctlr.error_title'),
              message:   this.$t('editor.error.visibility_ctlr.error_message'),
              type: 'error'
            });

            event.preventDefault();
          }
        } 
        else if(!checked) 
        {
          this.hideOnDesktop = false;
        }
      } 
      else if (device === 'mobile') 
      {
        if(checked) 
        {
          if(!this.hideOnDesktop)
          {
            this.hideOnMobile = true;
          }
          else 
          {
            this.$notify({
              title: this.$t('editor.error.visibility_ctlr.error_title'),
              message:  this.$t('editor.error.visibility_ctlr.error_message'),
              type: 'error'
            });

            event.preventDefault();
          }
        } 
        else if(!checked) 
        {
          this.hideOnMobile = false;
        }
      }  
    },
    openOrClose (value) {
      this.isOpen = value;
    },
  },

  render(h) {
    const MainWrap = getMainWrap();
    const VisibilityBtn = getVisibilityBtn();
    const VisibilityBtnIcon = getVisibilityBtnIcon();
    const VisibilityBtnText = getVisibilityBtnText();

    const VisibilityContentWrap = getVisibilityContentWrap();
    const VisibilityContentInnerWrap = getVisibilityContentInnerWrap();
    const CheckboxLabel = getCheckboxLabel();
    const CheckboxInput = getCheckboxInput();
    const CheckMark = getCheckMark();

    return (
      <MainWrap
        isDesktopView={this.isDesktopView}
      >
        <VisibilityBtn
          btnState={this.sectionVisibilityBtnConfig.state}
          onClick={() => this.openOrClose(!this.isOpen)}
        >
          <VisibilityBtnIcon 
            class={'sva-element-btn-icon'} 
            icon={this.iconConfig?.sectionUi?.sectionVisibility || 'cvt-icon-eye'}
            iconState={this.sectionVisibilityBtnConfig.state}
          />
          <VisibilityBtnText 
            class={'sva-element-btn-text'}
            textState={this.sectionVisibilityBtnConfig.state}
          >
            {this.$t('editor.section.action.visibility.text')}
          </VisibilityBtnText>
        </VisibilityBtn>

        <VisibilityContentWrap 
          v-show={this.isOpen} 
          class={'sva-visibility-main-wrap'}
        >
          <VisibilityContentInnerWrap 
            id={'sva-visibility-cb-wrap'} 
            btnState={this.sectionVisibilityBtnConfig.state}
          >
            <CheckboxLabel 
              class={"element-enable-propagation"} 
              cbState={this.sectionVisibilityCboxConfig.state}
              cbLabelState={this.sectionVisibilityCboxConfig.checkboxLabel.state}
            >
              {this.$t('editor.section.action.hide_on_desktop.text')}
              <CheckboxInput
                class={"element-enable-propagation"} 
                type={"checkbox"}
                name={"section_visibility_desktop"}
                value={"desktop"}
                checked={this.hideOnDesktop}
                cbState={this.sectionVisibilityCboxConfig.state}
                onClick={(event) => this.sectionVisibilityChanged(event, 'desktop')}
              ></CheckboxInput>
              <CheckMark 
                class="element-enable-propagation sva-checkmark"
                cbState={this.sectionVisibilityCboxConfig.state}
              ></CheckMark>
            </CheckboxLabel>

            <CheckboxLabel 
              class={"element-enable-propagation"} 
              cbState={this.sectionVisibilityCboxConfig.state}
              cbLabelState={this.sectionVisibilityCboxConfig.checkboxLabel.state}
            >
              {this.$t('editor.section.action.hide_on_mobile.text')}
              <CheckboxInput
                class={"element-enable-propagation"} 
                type={"checkbox"}
                name={"section_visibility_mobile"}
                value={"mobile"}
                checked={this.hideOnMobile}
                cbState={this.sectionVisibilityCboxConfig.state}
                onClick={(event) => this.sectionVisibilityChanged(event, 'mobile')}
              ></CheckboxInput>
              <CheckMark 
                class="element-enable-propagation sva-checkmark"
                cbState={this.sectionVisibilityCboxConfig.state}
              ></CheckMark>
            </CheckboxLabel>

          </VisibilityContentInnerWrap>

        </VisibilityContentWrap>

      </MainWrap>
    );
  }
}