export default function loadPendo(API_KEY: string) {
  return new Promise((resolve, reject) => {
    var v, w, x, y, z
    var o = (window['pendo'] = window['pendo'] || {})
    o._q = o._q || []
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push'](
              [m].concat([].slice.call(arguments, 0)),
            )
          }
      })(v[w])
    y = document.createElement('script')
    y.async = true
    y.src = 'https://cdn.pendo.io/agent/static/' + API_KEY + '/pendo.js'
    y.onload = () => resolve(window['pendo'])
    y.onerror = (err) => reject(err)
    z = document.getElementsByTagName('script')[0]
    z.parentNode.insertBefore(y, z)
  })
}
