<template>

  <div
v-show="visible" class="vnode__overlay"
       @click="click" @mouseover="mouseover" @mouseout="mouseout">

    <slot></slot>

  </div>

</template>
<script>
export default {
  props: {
    visible: Boolean
  },
  methods: {
    click () {
      this.$emit('click')
    },
    mouseover () {
      this.$emit('mouseover')
    },
    mouseout () {
      this.$emit('mouseout')
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/utils";

  .vnode__overlay {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: $blue;
    opacity: 0.2;
    z-index: 1;
  }
</style>
