import Popper from 'popper.js'

export default {
  namespaced: true,

  state: {
    visible: true,
    menuElement: null,
    popperInstance: null
  },

  getters: {

  },

  mutations: {
    init (state, menuElement) {
      state.menuElement = menuElement
    },
    show (state) {
      state.visible = true
    },
    hide (state) {
      state.visible = false
    },
    attach (state, { element, options }) {
      state.popperInstance = new Popper(element, state.menuElement, options)
    },
    update (state) {
      if (state.popperInstance) {
        state.popperInstance.scheduleUpdate()
      }
    }
  },

  actions: {
  }
}