<template>
  <div class="uploader">
    <el-progress
v-show="filestack.upload.progress"
                 :percentage="filestack.upload.progress"
                 :status="filestack.upload.status"></el-progress>

    <el-upload
action="interceptedByHttpReqHandler"
               list-type="picture"
               :file-list="fileList"
               :http-request="upload"
               :on-remove="remove"
               :show-file-list="showFileList"
               :drag="showDragArea">

      <span>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
        <div slot="tip" class="el-upload__tip">jpg/png files with a size less than 10Mb</div>
      </span>
    </el-upload>
  </div>
</template>

<script>
import filestack from '../mixins/filestack'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Uploader',
  mixins: [filestack],

  props: {
    url: {
      type: String,
      default() {
        return ''
      }
    }
  },

  data () {
    return {
      showDragArea: true,
      showFileList: true
    }
  },

  computed: {
    image () {
      return this.url ? {
        name: 'Current banner image',
        url: this.url
      } : null
    },
    fileList () {
      return this.image ? [this.image] : []
    }
  },
  methods: {
    remove () {
      this.$emit('change', '')
    },
    upload (opts) {
      return this.$filestackUpload(opts.file).then(({url}) => {
        this.$emit('change', url)
        this.$filestackReset()
      })
    }
  }
}
</script>