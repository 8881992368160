
<div :key="`spacing-${viewMode}-${direction}-${selected.vNodeID}`" class="spacing-sliders">
  <div class="row">
    <div class="col text-left">
      <div class="d-flex">
        <i  v-if="OrgName !== orgNameFreshWork" class="material-icons mr-1" v-text="icon"></i>
        <p><strong class="text-capitalize mr-1" v-text="title"></strong></p>
      </div>
      <p v-text="subtext"></p>
    </div>
  </div>

  <div v-if="OrgName === orgNameFreshWork">
    <div class="row">
      <div class="col">
      <cvt-radio-group orientation='horizontal' :initial-value="space" :remove-active="removeActive">
        <cvt-radio-button size="md" shape="pill" :value='fmtKey(1)' @radioClick="updateSpace">
          XS
        </cvt-radio-button>
        <cvt-radio-button size="md" shape="pill" :value='fmtKey(2)' @radioClick="updateSpace">
          SM
        </cvt-radio-button>
        <cvt-radio-button size="md" shape="pill" :value='fmtKey(3)' @radioClick="updateSpace">
          MD
        </cvt-radio-button>
        <cvt-radio-button size="md" shape="pill" :value='fmtKey(4)' @radioClick="updateSpace">
          LG
        </cvt-radio-button>
        <cvt-radio-button size="md" shape="pill" :value='fmtKey(5)' @radioClick="updateSpace">
          XL
        </cvt-radio-button>
      </cvt-radio-group>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <cvt-button
          :outlined="true"
          :text="btnText"
          shape="pill"
          color= "light"
          :mode= "mode"
          size="md" 
          @click.stop="remove"
        />
      </div>
    </div>
  </div>
  
  <div v-else>
    <div class="row">
      <div class="col">
      <cvt-radio-group orientation='horizontal' :initial-value="space" :remove-active="removeActive">
        <cvt-radio-button size="md" :value='fmtKey(1)' @radioClick="updateSpace">
          XS
        </cvt-radio-button>
        <cvt-radio-button size="md" :value='fmtKey(2)' @radioClick="updateSpace">
          SM
        </cvt-radio-button>
        <cvt-radio-button size="md" :value='fmtKey(3)' @radioClick="updateSpace">
          MD
        </cvt-radio-button>
        <cvt-radio-button size="md" :value='fmtKey(4)' @radioClick="updateSpace">
          LG
        </cvt-radio-button>
        <cvt-radio-button size="md" :value='fmtKey(5)' @radioClick="updateSpace">
          XL
        </cvt-radio-button>
      </cvt-radio-group>
      </div>
    </div>

    <div v-if="showRemove" class="row">
      <div class="col">
        <cvt-button
        :outlined="true"
        :text="$t(`elements.properties.spacing.${attr.toLowerCase()}.remove`)"
        :full-width="true"
        shape="rounded"
        color= "light"
        :mode= "mode"
        @click.stop="remove"
      />
      </div>
    </div>
  </div>

</div>
