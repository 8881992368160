import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import Vue from '../../../lib/utils/vue'
Vue.use(VueEmotion)

let Colors
let backgroundColor

const getCvtTr = () => styled('tr')`
  ${(props) =>
    props.mode == 'LIGHT'
      ? backgroundColor({ themeing: 'dark' }, 'WHITE')
      : backgroundColor({ themeing: 'dark' }, 700)}
  &:hover {
    ${(props) =>
      props.mode == 'LIGHT'
        ? backgroundColor({ themeing: 'dark' }, 100)
        : backgroundColor({ themeing: 'dark' }, 800)}
  }

  &.is-active {
    background-color: ${Colors.PRIMARY[300]} !important;
  }
`
export default {
  name: 'CvtTr',
  components: {},
  props: {
    name: {
      type: [String, Number]
    }
  },
  data () {
    return {}
  },
  inject: ['table'],
  computed: {
    ...mapGetters('globalTheme', {
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR
    }),
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors
    }),
    isActive () {
      return this.table.activeItems.indexOf(this.name) > -1
    },
    mode () {
      return this.table.$props.mode
    }
  },
  watch: {},
  created () {
    Colors = this.Colors
    backgroundColor = this.backgroundColor
  },
  methods: {
    onClick () {
      this.table.$emit.apply(this.table, ['item-click', this])
    }
  },
  render: function (h) {
    const CvtTr = getCvtTr()
    return (
      <CvtTr
        mode={this.mode}
        onClick={this.onClick}
        class={{ 'is-active': this.isActive }}
      >
        {this.$slots.default}
      </CvtTr>
    )
  }
}
