import '../../scss/convrrt.scss'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import Vue from '../../lib/utils/vue'
import * as getters from "../../../builder/src/js/store/modules/getters"
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'

Vue.use(VueEmotion)

let backgroundColor, color, elevation
let FontSize, Spacing, Radii

const getVtcProgress = () => {
  return styled('div')`
  position: relative;
`
}

const getProgressWrapper = () => {
  return styled('div')`
  display: flex;
  overflow: hidden;
  font-size: ${FontSize.FONT_SIZE_11}px;
  ${(props) => {
    let wrapperShadow = elevation(props, 100)
    let wrapperHeight =
      props.size == 'lg'
        ? `height: ${Spacing.SPACING_24}px;`
        : `height: ${Spacing.SPACING_8}px;`
    let wrapperBkgColor =
      props.themeing == 'light'
        ? backgroundColor({ themeing: 'dark' }, 500)
        : backgroundColor(props, 100)
    let wrapperBorderRadius =
      props.size == 'lg'
        ? `border-radius: ${Radii.RADIUS_PX_8 + Radii.RADIUS_PX_4}px;`
        : props.size == 'sm'
        ? `border-radius: ${Radii.RADIUS_PX_4}px;`
        : `border-radius: ${Radii.RADIUS_PX_8}px;`

    return wrapperShadow + wrapperHeight + wrapperBkgColor + wrapperBorderRadius
  }}
`
}

const getProgressBar = () => {
  return styled('div')`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  ${color({ themeing: 'dark' }, 'WHITE')}
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
  ${(props) => {
    let barBorderRadius =
      props.size == 'lg'
        ? `border-radius: ${Radii.RADIUS_PX_8 + Radii.RADIUS_PX_4}px;`
        : props.size == 'sm'
        ? `border-radius: ${Radii.RADIUS_PX_4}px;`
        : `border-radius: ${Radii.RADIUS_PX_8}px;`

    let barBkgColor = backgroundColor(props, 500)

    return barBorderRadius + barBkgColor
  }}
`
}

const getInternalProgressLabel = () => {
  return styled('span')`
  position: absolute;
  right: ${Spacing.SPACING_12}px;
  ${(props) => {
    return props.themeing == 'light'
      ? color({ themeing: 'dark' }, 500)
      : color(props, 100)
  }}
`
}

const getExternalProgressLabel = () => {
  return styled('span')`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;
  right: ${Spacing.SPACING_2}px;
  font-size: ${FontSize.FONT_SIZE_11}px;
  ${color({ themeing: 'dark' }, 500)}
  ${(props) => (props.labelPos == 'top' ? 'top: -1.2rem;' : 'bottom: -1.2rem;')}
`
}

export default {
  name: 'CvtProgress',
  components: {},
  model: {
    prop: 'valueNow',
    event: 'change'
  },
  props: {
    bgColor: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'LIGHT'
    },
    valueNow: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: 'lg'
    },
    valueLabel: {
      type: String,
      default: ''
    },
    labelPos: {
      type: String,
      default: 'top'
    },
    maxValue: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('globalTheme', {
      color: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      elevation: getters.GLOBAL_STYLE_ELEVATION
    }),
    ...mapState('globalTheme', {
      Spacing: ({globalTheme}) => globalTheme.Spacing,
      Radii: ({globalTheme}) => globalTheme.Radii,
      FontSize: ({globalTheme}) => globalTheme.FontSize
    }),
    progressStyles() {
      return `width: ${this.percentValue}%`
    },
    labeledValue() {
      if (this.valueLabel) {
        return `${Math.max(this.valueNow, 0) || 0} ${this.valueLabel}`
      }
    },
    percentValue() {
      return Math.abs(
        Math.floor(
          (Math.min(Math.max(this.valueNow, 0), this.maxValue) /
            this.maxValue) *
            100
        )
      )
    }
  },
  created() {
    FontSize = this.FontSize
    Spacing = this.Spacing
    Radii = this.Radii
    color = this.color
    backgroundColor = this.backgroundColor
    elevation = this.elevation
  },
  methods: {},
  render: function (h) {

    const VtcProgress = getVtcProgress()
    const ExternalProgressLabel = getExternalProgressLabel()
    const ProgressWrapper = getProgressWrapper()
    const ProgressBar = getProgressBar()
    const InternalProgressLabel = getInternalProgressLabel()

    return (
      <VtcProgress>
        {this.size === 'sm' && (
          <ExternalProgressLabel labelPos={this.labelPos}>
            <span>{this.labeledValue}</span>
            <span>{this.percentValue}%</span>
          </ExternalProgressLabel>
        )}
        <ProgressWrapper
          themeing={this.bgColor}
          size={this.size}
          mode={this.mode}
        >
          <ProgressBar
            themeing={this.bgColor}
            role='progressbar'
            style={this.progressStyles}
            aria-valuenow={this.valueNow}
            aria-valuemin='0'
            aria-valuemax={this.maxValue}
            size={this.size}
          >
            <InternalProgressLabel themeing={this.bgColor}>
              {this.size === 'lg' ? this.percentValue + '%' : ''}
            </InternalProgressLabel>
          </ProgressBar>
        </ProgressWrapper>
      </VtcProgress>
    )
  }
}
