import color from 'color'

export default function stringify ({r, g, b, a}) {
  if (!r || !g || !b) return null
  // let r, g, b, a
  // ({r, g, b, a} = this.contrast.rgba)
  return `rgba(${r},${g},${b},${a > -1 ? a : 1})`
}

export function rgbaToColor (opts) {
  let { rgba } = opts || {}
  let { r, g, b, a } = rgba || {}
  const result = color({r, g, b})
  result.alpha(a || 1)
  return result
}

function componentToHex (c) {
  let hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

export function rgbToHex (r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export const DEFAULT_COLOR = {
  hex: '#1B659C'
}
