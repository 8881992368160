
<div :class="subNavOuterClasses">
  <div :class="subNavInnerClasses">

    <div class="sub-nav-head">
      <cvt-floating-breadcrumb
        :mode="'LIGHT'"
        :active-menu="currentMenuId"
        :menus="breadcrumbMenus"
        :hide-back-btn="false"
        :hide-next-btn="false"
        :control-active-tab="true"
        @menuChanged="(eventData) => onBreadCrumbSelectionChange(eventData)"
      />
    </div>

    <div :class="subNavContentClasses">
      <slot></slot>
    </div>

  </div>
</div>
