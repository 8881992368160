import { cvtRouteNodes } from "../../../utils/routes"

export default {
  name: "CustomHostNameUrlSetup",
  components: {},
  props: {
    envUrls: {
        type: Array,
        default: []
    },
    loading: {
        type: Boolean,
        default: false
    },
  },
  data() {
    return {}
  },
  methods: {
    continueToConfigDns() {
        this.$emit('continueToConfigDns')
    },
    onSkipHostNameUrlSetup() {
      this.$emit('skipHostNameUrlSetup')
    }
  }
}
