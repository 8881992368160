<template>
  <div>
    <h1>Radio Group</h1>
    <h3>Horizontal</h3>
    <div v-for="(color, key) in radioButtonStyle" :key="color">
      <span>{{ key }}</span>
      <cvt-radio-group :theme="color" :initial-value="'2'">
        <cvt-radio value="1" :border="false">
          Option A
        </cvt-radio>
        <cvt-radio value="2" :border="false">
          Option B
        </cvt-radio>
        <cvt-radio value="3" :border="false">
          Option C
        </cvt-radio>
      </cvt-radio-group>
    </div>
    <h3>Vertical</h3>
    <cvt-radio-group :initial-value="'2'" orientation="vertical">
      <cvt-radio value="1" :border="false">
        Option A
      </cvt-radio>
      <cvt-radio value="2" :border="false">
        Option B
      </cvt-radio>
      <cvt-radio value="3" :border="false">
        Option C
      </cvt-radio>
    </cvt-radio-group>
    <h3>With Border</h3>
    <cvt-radio-group :initial-value="'2'">
      <cvt-radio value="1" :border="true">
        Option A
      </cvt-radio>
      <cvt-radio value="2" :border="true">
        Option B
      </cvt-radio>
      <cvt-radio value="3" :border="true">
        Option C
      </cvt-radio>
    </cvt-radio-group>
    <h3>Disabled</h3>
    <cvt-radio-group :initial-value="'2'" disabled>
      <cvt-radio value="1" :border="true">
        Option A
      </cvt-radio>
      <cvt-radio value="2" :border="true">
        Option B
      </cvt-radio>
      <cvt-radio value="3" :border="true">
        Option C
      </cvt-radio>
    </cvt-radio-group>

    <h3>Sizes</h3>
    <div v-for="(size, key) in radioButtonSize" :key="size">
      <span>{{ key }}</span>
      <cvt-radio-group :size="size" theme="success" :initial-value="'2'">
        <cvt-radio value="1" :border="false">
          Option A
        </cvt-radio>
        <cvt-radio value="2" :border="false">
          Option B
        </cvt-radio>
        <cvt-radio value="3" :border="false">
          Option C
        </cvt-radio>
      </cvt-radio-group>
    </div>

    <h3>Horizontal Button Radio Group</h3>
    <div v-for="(color, key) in radioButtonStyle" :key="color">
      <span>{{ key }}</span>
      <cvt-radio-group :theme="color" :initial-value="'2'">
        <cvt-radio-button value="1">
          Option A
        </cvt-radio-button>
        <cvt-radio-button value="2">
          Option B
        </cvt-radio-button>
        <cvt-radio-button value="3">
          Option C
        </cvt-radio-button>
      </cvt-radio-group>
    </div>

    <h3>Vertical Button Radio Group</h3>
    <cvt-radio-group theme="danger" :initial-value="'2'" orientation="vertical">
      <cvt-radio-button value="1">
        Option AAAA
      </cvt-radio-button>
      <cvt-radio-button value="2">
        Option B
      </cvt-radio-button>
      <cvt-radio-button value="3">
        Option C
      </cvt-radio-button>
    </cvt-radio-group>

    <h3>Sizes - Button Radio Group</h3>
    <div v-for="(size, key) in radioButtonSize" :key="size">
      <span>{{ key }}</span>
      <cvt-radio-group theme="info" :initial-value="'2'">
        <cvt-radio-button value="1" :size="size">
          Option A
        </cvt-radio-button>
        <cvt-radio-button value="2" :size="size">
          Option B
        </cvt-radio-button>
        <cvt-radio-button value="3" :size="size">
          Option C
        </cvt-radio-button>
      </cvt-radio-group>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PlayRadioGroup',
  components: {},
  data() {
    return {
      radioButtonStyle: {
        Primary: 'primary',
        Success: 'success',
        Danger: 'danger',
        Warning: 'warning',
        Info: 'info',
        Light: 'light',
        Dark: 'dark'
      },
      radioButtonSize: {
        'Extra Small': 'xs',
        Small: 'sm',
        Medium: '',
        Large: 'lg',
        'Extra Large': 'xl'
      }
    }
  },
  computed: {},
  created() {},
  methods: {}
}
</script>
<style lang="scss"></style>
