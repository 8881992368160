import Vue from '../../../lib/utils/vue'
import VueI18n from 'vue-i18n'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { styled, VueEmotion } from '@egoist/vue-emotion'
import { mapGetters, mapState } from '../../../../../node_modules/.pnpm/vuex@3.0.1/node_modules/vuex'
import { CvtIcon } from '../../molecules/icon/icon'
import { CvtTooltip } from '../../molecules/tooltip/CvtTooltip'

Vue.use(VueEmotion)
Vue.use(VueI18n)

let FontFamily, Spacing, Radii, FontWeight, Colors, Border, backgroundColor, borderColor, color;

const getMainForDesktopWrapper = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${FontFamily.DEFAULT};
  border-radius: ${Spacing.SPACING_18 + Spacing.SPACING_2}px;
  height: ${Spacing.SPACING_32 + Spacing.SPACING_8}px;
  padding: 0px ${Spacing.SPACING_12 + Spacing.SPACING_2}px;
  flex-direction: row;

  border-width: 1px;
  border-style: solid;

  ${(props) => {
    let styles = ``;
    let backgroundColorObj = props.btnState.default.backgroundColor;
    let borderColorObj = props.btnState.default.borderColor;

    if (!backgroundColorObj.custom) {
      styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
    } else if (backgroundColorObj.custom) {
      styles += `background: ${backgroundColorObj.value};`;
    }

    if (!borderColorObj.custom) {
      styles += `${borderColor(borderColorObj.value, borderColorObj.weight)}`;
    } else if (borderColorObj.custom) {
      styles += `border-color: ${borderColorObj.value};`;
    }

    return styles;
  }}

  &:hover {
    ${(props) => {
      let styles = ``;
      let backgroundColorObj = props.btnState.hover.backgroundColor;
  
      if (!backgroundColorObj.custom) {
        styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
      } else if (backgroundColorObj.custom) {
        styles += `background: ${backgroundColorObj.value};`;
      }
      return styles;
    }}

    .sc-settings-d-wrap {
      ${(props) => {
        let styles = ``;
        let backgroundColorObj = props.btnState.hover.backgroundColor;
    
        if (!backgroundColorObj.custom) {
          styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
        } else if (backgroundColorObj.custom) {
          styles += `background: ${backgroundColorObj.value};`;
        }
        return styles;
      }}
    }

    .sc-action-btns-d-wrap,
    .sc-sa-copy-section-btn,
    .sc-sa-swap-section-btn,
    .sc-sa-delete-section-btn {
      ${(props) => {
        let styles = ``;
        let backgroundColorObj = props.btnState.hover.backgroundColor;
    
        if (!backgroundColorObj.custom) {
          styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
        } else if (backgroundColorObj.custom) {
          styles += `background: ${backgroundColorObj.value};`;
        }
        return styles;
      }}
    }

    .sc-action-btns-d-wrap {
      width: ${Spacing.SPACING_64 + Spacing.SPACING_12 + Spacing.SPACING_4}px;
      height: auto;
      position: unset;
      overflow: unset;
      opacity: 1;
    }

    .sc-seprator-d {
      width: 1px;
    }
  }
`

const getMainForMobileWrapper = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${FontFamily.DEFAULT};
  border-radius: ${Spacing.SPACING_18 + Spacing.SPACING_2}px;
  padding: 0px 10.5px 0px 10.5px;
  flex-direction: column;

  border-width: 1px;
  border-style: solid;
  
  ${(props) => {
    let styles = ``;
    let backgroundColorObj = props.btnState.default.backgroundColor;
    let borderColorObj = props.btnState.default.borderColor;

    if (!backgroundColorObj.custom) {
      styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
    } else if (backgroundColorObj.custom) {
      styles += `background: ${backgroundColorObj.value};`;
    }

    if (!borderColorObj.custom) {
      styles += `${borderColor(borderColorObj.value, borderColorObj.weight)}`;
    } else if (borderColorObj.custom) {
      styles += `border-color: ${borderColorObj.value};`;
    }

    return styles;
  }}

  &:hover {
    padding: 0px 10.5px 5px 10.5px;
    ${(props) => {
      let styles = ``;
      let backgroundColorObj = props.btnState.hover.backgroundColor;
  
      if (!backgroundColorObj.custom) {
        styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
      } else if (backgroundColorObj.custom) {
        styles += `background: ${backgroundColorObj.value};`;
      }
      return styles;
    }}

    .sc-settings-m-wrap {
      ${(props) => {
        let styles = ``;
        let backgroundColorObj = props.btnState.hover.backgroundColor;
    
        if (!backgroundColorObj.custom) {
          styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
        } else if (backgroundColorObj.custom) {
          styles += `background: ${backgroundColorObj.value};`;
        }
        return styles;
      }}
    }

    .sc-action-btns-m-wrap,
    .sc-sa-copy-section-btn,
    .sc-sa-swap-section-btn,
    .sc-sa-delete-section-btn {
      ${(props) => {
        let styles = ``;
        let backgroundColorObj = props.btnState.hover.backgroundColor;
    
        if (!backgroundColorObj.custom) {
          styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
        } else if (backgroundColorObj.custom) {
          styles += `background: ${backgroundColorObj.value};`;
        }
        return styles;
      }}
    }

    .sc-action-btns-m-wrap {
      width: auto;
      height: 90px;
      position: unset;
      overflow: unset;
      opacity: 1;
    }

    .sc-seprator-m {
      height: 2px;
    }
  }
`

const getSettingsBtnForDesktopWrap = () => styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border: 0;
  ${(props) => {
    let styles = ``;
    let backgroundColorObj = props.btnState.default.backgroundColor;

    if (!backgroundColorObj.custom) {
      styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
    } else if (backgroundColorObj.custom) {
      styles += `background: ${backgroundColorObj.value};`;
    }
    return styles;
  }}
`

const getSettingsBtnForMobileWrap = () => styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 10px 0px;
  border-radius: 500%;
  ${(props) => {
    let styles = ``;
    let backgroundColorObj = props.btnState.default.backgroundColor;

    if (!backgroundColorObj.custom) {
      styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
    } else if (backgroundColorObj.custom) {
      styles += `background: ${backgroundColorObj.value};`;
    }
    return styles;
  }}
`

const getSeparatorForDesktop = () => styled('div')`
  width: 0px;
  height: ${Spacing.SPACING_16 + Spacing.SPACING_2}px;;
  background: #ffffff80;
  transition: width 10ms ease;
`

const getSeparatorForMobile = () => styled('div')`
  width: ${Spacing.SPACING_16 + Spacing.SPACING_2}px;
  height: 0;
  background: #ffffff80;
  transition: height 10ms ease;
`

const getActionBtnsForDesktopWrap = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: ${Spacing.SPACING_8 + Spacing.SPACING_2}px;
  gap: ${Spacing.SPACING_8 + Spacing.SPACING_2}px;
  height: 0px;
  width: 0px;
  position: absolute;
  overflow: hidden;
  opacity: 0;
  transition: width 400ms ease, opacity 1000ms ease;
  flex-direction: row;
  padding-left: ${Spacing.SPACING_8 + Spacing.SPACING_2}px;

  ${(props) => {
    let styles = ``;
    let backgroundColorObj = props.btnState.default.backgroundColor;

    if (!backgroundColorObj.custom) {
      styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
    } else if (backgroundColorObj.custom) {
      styles += `background: ${backgroundColorObj.value};`;
    }
    return styles;
  }}
`

const getActionBtnsForMobileWrap = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: ${Spacing.SPACING_8 + Spacing.SPACING_2}px;
  gap: ${Spacing.SPACING_8 + Spacing.SPACING_2}px;
  height: 0px;
  width: 0px;
  position: absolute;
  overflow: hidden;
  opacity: 0;
  transition: height 400ms ease, opacity 1000ms ease;
  flex-direction: column;
  padding-left: 0px;

  ${(props) => {
    let styles = ``;
    let backgroundColorObj = props.btnState.default.backgroundColor;

    if (!backgroundColorObj.custom) {
      styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
    } else if (backgroundColorObj.custom) {
      styles += `background: ${backgroundColorObj.value};`;
    }
    return styles;
  }}
`

const getSettingsBtnText = () => styled('span')`
  font-family: ${FontFamily.DEFAULT};
  font-style: normal;
  font-weight: ${FontWeight.BOLD};
  font-size: ${Spacing.SPACING_12}px;
  line-height: ${Spacing.SPACING_12}px;

  ${(props) => {
    let styles = ``;
    let colorObj = props.btnState.default.color;

    if (!colorObj.custom) {
      styles += `${color(colorObj.value, colorObj.weight)}`;
    } else if (colorObj.custom) {
      styles += `color: ${colorObj.value};`;
    }

    return styles;
  }}

  padding: 0px ${Spacing.SPACING_4 + Spacing.SPACING_2}px;
`

const getIcon = () => styled(CvtIcon)`
  ${(props) => {
    let styles = ``;
    let colorObj = props.iconState.default.color;

    if (!colorObj.custom) {
      styles += `${color(colorObj.value, colorObj.weight)}`;
    } else if (colorObj.custom) {
      styles += `color: ${colorObj.value};`;
    }

    return styles;
  }}

  font-size: ${Spacing.SPACING_18}px;
`

const getBtn = () => styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 0px;

  ${(props) => {
    let styles = ``;
    let backgroundColorObj = props.btnState.default.backgroundColor;
    if (!backgroundColorObj.custom) {
      styles += `${backgroundColor(backgroundColorObj.value, backgroundColorObj.weight)}`;
    } else if (backgroundColorObj.custom) {
      styles += `background: ${backgroundColorObj.value};`;
    }
    return styles;
  }}

  &:hover i {
    border-color: transparent;
    color: #c3c9d6;
  }
`

const getTooltip = () => styled(CvtTooltip)``

export const CvtSectionSettingActions = {
  name: 'CvtSectionSettingActions',
  data() {
    return {}
  },
  props: {
    vnodeId: {
      type: String,
      required: true,
      default: ''
    },
    headerText: {
      type: String,
      default: null,
    },
    allowedControls : {
      type: Array,
      default: () => ['clone', 'delete', 'swap'],
    }
  },
  computed: {
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      Border: ({ globalTheme }) => globalTheme.Border,
      iconConfig: ({ iconConfig }) => iconConfig,
      sectionSettingsBtnConfig: ({ globalButtonConfig }) => globalButtonConfig.locations.blockComponent.locations.sectionSettingsBtn,
    }),
    ...mapGetters('globalTheme', {
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
    }),
    ...mapGetters({
      viewMode: getters.VIEW_MODE,
    }),
    isDesktopView() {
      return this.viewMode === 'desktop';
    },
    isMobileView() {
      return this.viewMode === 'phone';
    },
    getText() {
      return this.headerText? this.headerText : this.$t('editor.section.action.settings.text')
    }
  },
  methods: {
    copy(event) { 
      this.$emit('copySection', event);
    },
    swap(event) {
      this.$emit('swapSection', event);
    },
    delete(event) { 
      this.$emit('deleteSection', event);
    },
    isAllowed(action) {
      return this.allowedControls.includes(action)
    }
  },
  created() {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    FontWeight = this.FontWeight
    Colors = this.Colors
    Border = this.Border
    backgroundColor = this.backgroundColor
    borderColor = this.borderColor
    color = this.textColor
  },
  render: function (h) {

    const MainForDesktopWrapper = getMainForDesktopWrapper()
    const MainForMobileWrapper = getMainForMobileWrapper()

    const SettingsBtnForDesktopWrap = getSettingsBtnForDesktopWrap()
    const SettingsBtnForMobileWrap = getSettingsBtnForMobileWrap()


    const SettingsBtnText = getSettingsBtnText()
    const SettingsIcon = getIcon()

    const SeparatorForDesktop = getSeparatorForDesktop()
    const SeparatorForMobile = getSeparatorForMobile()

    const ActionBtnsForDesktopWrap = getActionBtnsForDesktopWrap()
    const ActionBtnsForMobileWrap = getActionBtnsForMobileWrap()


    const CopyBtn = getBtn()
    const CopyIcon = getIcon()

    const SwapBtn = getBtn()
    const SwapIcon = getIcon()

    const DeleteBtn = getBtn()
    const DeleteIcon = getIcon()

    const Tooltip = getTooltip()

    const WrapperElement = this.isDesktopView ? MainForDesktopWrapper : MainForMobileWrapper;

    const CopyBtnElement = (
      <CopyBtn 
        id={`${this.vnodeId}-copy-section-btn`} 
        class={'sc-sa-copy-section-btn'} 
        onClick={(event)=> {this.copy(event)}} 
        btnState={this.sectionSettingsBtnConfig.state}
      >
        <CopyIcon 
          icon={this.iconConfig?.sectionUi?.copyBlock || 'cvt-icon-clone'}
          iconState={this.sectionSettingsBtnConfig.btnIcon.state}
        ></CopyIcon>
        <Tooltip 
          target={`${this.vnodeId}-copy-section-btn`} 
          placement={this.isMobileView ? 'left' : 'top'}
        >
          {this.$t('editor.section.action.copy_section')}
        </Tooltip>
      </CopyBtn>
    );

    const SwapBtnElement = (
      <SwapBtn 
        id={`${this.vnodeId}-swap-section-btn`} 
        class={'sc-sa-swap-section-btn'} 
        onClick={(event)=> {this.swap(event)}} 
        btnState={this.sectionSettingsBtnConfig.state}
      >
        <SwapIcon 
          icon={this.iconConfig?.sectionUi?.swapBlock || 'far fa-sync-alt'}
          iconState={this.sectionSettingsBtnConfig.btnIcon.state}
        ></SwapIcon>
        <Tooltip 
          target={`${this.vnodeId}-swap-section-btn`} 
          placement={this.isMobileView ? 'left' : 'top'}
        >
          {this.$t('editor.section.action.swap_section')}
        </Tooltip>
      </SwapBtn>
    );

    const DeleteBtnElement = (
      <DeleteBtn 
        id={`${this.vnodeId}-delete-section-btn`} 
        class={'sc-sa-delete-section-btn'} 
        onClick={(event)=> {this.delete(event)}} 
        btnState={this.sectionSettingsBtnConfig.state}
      >
        <DeleteIcon 
          icon={this.iconConfig?.sectionUi?.deleteBlock || 'cvt-icon-trashcan'}
          iconState={this.sectionSettingsBtnConfig.btnIcon.state}
        ></DeleteIcon>
        <Tooltip 
          target={`${this.vnodeId}-delete-section-btn`} 
          placement={this.isMobileView ? 'left' : 'top'}
        >
          {this.$t('editor.section.action.delete_section')}
        </Tooltip>
      </DeleteBtn>
    );
    
    return (
      <WrapperElement btnState={this.sectionSettingsBtnConfig.state}>

        {this.isDesktopView && (
          <SettingsBtnForDesktopWrap 
            class={'sc-settings-d-wrap'} 
            btnState={this.sectionSettingsBtnConfig.state}
          >
            <SettingsIcon 
              icon={this.iconConfig?.sectionUi?.settings || 'gear'}
              iconState={this.sectionSettingsBtnConfig.btnIcon.state}
            ></SettingsIcon>
            <SettingsBtnText
              btnState={this.sectionSettingsBtnConfig.state}
            >
              {this.getText}
            </SettingsBtnText>
          </SettingsBtnForDesktopWrap>
        )}

        {this.isMobileView && (
          <SettingsBtnForMobileWrap 
            class={'sc-settings-m-wrap'}
            btnState={this.sectionSettingsBtnConfig.state}
          >
            <SettingsIcon 
              icon={this.iconConfig?.sectionUi?.settings || 'gear'}
              iconState={this.sectionSettingsBtnConfig.btnIcon.state}
            ></SettingsIcon>
          </SettingsBtnForMobileWrap>
        )}

        {this.isDesktopView && <SeparatorForDesktop class={'sc-seprator-d'} />}
        {this.isMobileView && <SeparatorForMobile class={'sc-seprator-m'} />}

        {this.isDesktopView && (
          <ActionBtnsForDesktopWrap 
            class={'sc-action-btns-d-wrap'} 
            btnState={this.sectionSettingsBtnConfig.state}
          >
            { this.isAllowed('clone') && CopyBtnElement }
            { this.isAllowed('swap') && SwapBtnElement }
            { this.isAllowed('delete') && DeleteBtnElement }
          </ActionBtnsForDesktopWrap> 
        )}

        {this.isMobileView && (
          <ActionBtnsForMobileWrap 
            class={'sc-action-btns-m-wrap'} 
            btnState={this.sectionSettingsBtnConfig.state}
          >
            { this.isAllowed('clone') && CopyBtnElement }
            { this.isAllowed('swap') && SwapBtnElement }
            { this.isAllowed('delete') && DeleteBtnElement }
          </ActionBtnsForMobileWrap>
        )}
          
      </WrapperElement>
    )
  }
}