
<div class="configuration-container">
  <div class="configuration-heading-wrapper">
    <h1>Configurations</h1>
    <div class="configuration-btn-wrapper">
      <!-- <cvt-button
        text='Save changes'
        color='light'
        hover
      >
      </cvt-button> -->
    </div>
  </div>
  <div class="configuration-main-wrapper">
    <cvt-ent-dashboard-tabs
      :active-tab='activeTabIdx'
      :tabs-array='tabs'
      nav-tab-width='500px'
      nav-tab-body-width='100%'
      @tabClicked='(idx) => onTabClicked(idx)'
    >
    <div v-if='activeTabIdx === 0' class='config-custom-first-tab'>
      <custom-host-name-url-setup
      v-if="!verifyHostName"
        :env-urls="envUrls"
        :loading="loading"
        @continueToConfigDns="continueToConfigDns()"
        @skipHostNameUrlSetup="skipHostNameUrlSetup()"
      />
      <custom-host-names
        v-else-if="verifyHostName"
        :custom-host-names="customHostNames"
        :loading="loading"
        @onVerifyCustomHostName="verifyCustomHostnames()"
      ></custom-host-names>
    </div>
    <div v-else-if='activeTabIdx === 1' class="config-custom-first-tab">
      <div>
          <customer-authentication
            :show-config-btn="false"
            @onContinueToFeatureConfig="continueToFeatureConfig"
          >
          </customer-authentication>
      </div>
    </div>
    <div v-else-if='activeTabIdx === 2' class="config-custom-first-tab">
      <div>
        <feature-configuration />
      </div>
    </div>
    </cvt-ent-dashboard-tabs>
  </div>
</div>
