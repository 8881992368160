<template>
  <div class="d-flex h-100" style="overflow: auto">
    <!-- Drag and Drop List of Pages -->
    <cvt-pages
      v-show="isPagesList"
      :pages="pages"
      :active-id="activeId"
      :allow-delete="allowDelete"
      :allow-duplicate="allowDuplicate"
      :allow-swap="allowSwap"
      :allow-create="allowCreate"
      :allow-bookmark="true"
      :is-admin="isAdmin"
      :is-template="false"
      @edit="(e) => edit(e)"
      @settings="(e) => openSettings(e)"
      @copy="(e) => duplicate(e)"
      @delete="(e, page) => showDeleteDialog(e)"
      @createNew="(e) => createNew(site.id)"
      @save="(e) => save(e)"
      @layout="(page) => showReplacePageDialog(page)"
      @bookmark="(e) => showPageBookmarkDialog(e)"
    />

    <!-- Delete Page Dialog -->
    <cvt-dialog
      :show="showRemovePageDialog"
      :height-auto="true"
      size="sm"
      @close="showRemovePageDialog = false"
    >
      <template #title>
        <h5>
          {{ $t('editor.left_nav.page_manager.remove_dialog.template.h5') }}
        </h5>
      </template>
      <cvt-alert class="w-100" color="warning" icon="exclamation-triangle">
        <p>
          {{ $t('editor.left_nav.page_manager.remove_dialog.cvt_alert.p') }}
        </p>
      </cvt-alert>
      <template #modalFooter>
        <div class="d-flex">
          <cvt-button
            :loading="removing"
            class="mr-1"
            color="light"
            :text="$t(`pagemanager.cvt_dialog.submit.btn`)"
            @click="proceedPageRemoval"
          />
          <cvt-button
            :loading="removing"
            color="warning"
            :text="$t(`pagemanager.cvt_dialog.cancel.btn`)"
            @click="abortPageRemoval"
          />
        </div>
      </template>
    </cvt-dialog>

    <!-- Swap Page Layout Dialog -->
    <cvt-dialog
      :show="replacePageDialogVisible"
      :height-auto="true"
      size="sm"
      @close="replacePageDialogVisible = false"
    >
      <template #title>
        <h5>
          {{ $t('editor.left_nav.page_manager.layout_dialog.template.h5') }}
        </h5>
      </template>

      <cvt-alert class="w-100" color="warning" icon="exclamation-triangle">
        <p>
          {{ $t('editor.left_nav.page_manager.layout_dialog.cvt_alert.p') }}
        </p>
        <span>{{
          $t('editor.left_nav.page_manager.layout_dialog.cvt_alert.span')
        }}</span>
      </cvt-alert>

      <template #modalFooter>
        <div class="d-flex">
          <cvt-button
            v-bind="cancelBtnProps"
            :text="
              $t(
                `editor.left_nav.page_manager.layout_dialog.template.footer.cancel.btn`,
              )
            "
            @click="abortReplacePage"
          />
          <cvt-button
            v-bind="submitBtnProps"
            class="mr-1"
            :text="
              $t(
                `editor.left_nav.page_manager.layout_dialog.template.footer.submit.btn`,
              )
            "
            @click.stop="confirmReplacePage"
          />
        </div>
      </template>
    </cvt-dialog>

    <!-- Firebase Pages List -->
    <cvt-save-pages
      v-show="isSavePage"
      :pages="filteredSavedPages"
      :loading="loading"
      :inserting-page="insertingOrReplacingPage"
      :page-tags="tags"
      @insert-page="(e) => insertOrReplacePage(e)"
      @insert-blank-page="(e) => insertBlankPage(e)"
      @back="back"
      @filter-section="(value) => (activeTag = value)"
      @user-view-preference="(data) => (userViewPreference = data)"
      @delete-bookmark-dialog="(page) => showDeleteBookmarkDialog(page)"
      @edit-page-name="(page, replaceText) => editPageName(page, replaceText)"
    />

    <!-- Delete Page Bookmark Dialog -->
    <cvt-dialog
      :show="deleteBookmarkVisible"
      :height-auto="true"
      size="sm"
      @close="deleteBookmarkVisible = false"
    >
      <template slot="title">
        <h5>Delete Page Bookmark</h5>
      </template>

      <cvt-alert class="w-100" color="warning">
        <p>
          You are deleting:
          {{ selectedBookmarkPage && selectedBookmarkPage.pageName }}. <br />
          Are you sure you want to continue?
        </p>
      </cvt-alert>

      <template slot="modalFooter">
        <div class="d-flex">
          <cvt-button
            class="mr-1"
            :loading="deletingBookmark"
            color="light"
            :text="$t('marketplace.dialog.archive.cta.cancel')"
            @click="deleteBookmarkVisible = false"
          />
          <cvt-button
            color="warning"
            :loading="deletingBookmark"
            :text="$t('marketplace.dialog.archive.cta.submit')"
            @click="onDeleteBookmark"
          />
        </div>
      </template>
    </cvt-dialog>

    <!-- Page Settings Dialog -->
    <cvt-page-settings
      v-show="isPageSettings"
      :pages="filteredSavedPages"
      :loading="loading"
      :page-tags="tags"
      @insert-page="(e) => insertOrReplacePage(e)"
      @insert-blank-page="(e) => insertBlankPage(e)"
      @back="back"
    >
      <page-settings :handle-close="back" />
    </cvt-page-settings>
  </div>
</template>

<script>
import { database } from '@/js/store/services/firebase.service'
import * as _ from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { FRESHWORKS } from '../../../../../../storybook/components/constants'
import { PageSwapCommand } from '../../../commands/PageSwapCommand'
import * as actions from '../../../store/modules/actions'
import * as mutations from '../../../store/modules/mutations'
import * as getters from '../../../store/modules/getters'
import { PageSwapControl } from '../../../store/services/pageSwapControl'
import { Notification } from 'element-ui'
import { cvtRouteNodes } from '@/js/utils/routes'
import PageSettings from './PageSettings.vue'
import { STANDARD, USER_OWNED } from '@/js/common/constants/constants'

let db = database()

const viewOptions = {
  page: 'page',
  save: 'save',
  settings: 'settings',
}
export default {
  components: {
    'page-settings': PageSettings,
  },
  data() {
    return {
      activePage: null,
      showRemovePageDialog: false,
      pageArgs: null,
      removing: false,
      savedPages: [],
      loading: true,
      tags: [],
      showSavePagesList: true,
      replacePageDialogVisible: false,
      savedPageData: null,
      proceedReplacePage: false,
      activeTag: 'ALL',
      currentView: viewOptions.page,
      userViewPreference: STANDARD,
      deletingBookmark: false,
      selectedBookmarkPage: null,
      deleteBookmarkVisible: false,
      insertingOrReplacingPage: false,
    }
  },
  computed: {
    ...mapState({
      site: ({ site }) => _.get(site, 'site'),
      pages: ({ site }) =>
        _.chain(site)
          .get('site.pages')
          .orderBy(['isIndex', 'createdAt'], ['desc', 'asc'])
          .value(),
      indexPage: ({ site }) =>
        _.chain(site).get('site.pages').find({ isIndex: true }).value(),
    }),
    ...mapState('globalTheme', {
      orgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined ? globalTheme.OrgName : 'standard',
      deletePageWithoutConfirm: ({ globalTheme }) =>
        globalTheme.deletePageWithoutConfirm !== undefined
          ? globalTheme.deletePageWithoutConfirm
          : false,
    }),
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN,
      deltaKey: getters.DELTA_KEY,
      pageEngine: getters.LAYOUT_PAGE_ENGINE,
      user: getters.AUTH_GET_USER,
      isGridBuilder: getters.IS_GRID_BUILDER,
      currentProductVersionNumber: getters.CURRENT_PRODUCT_VERSION_NUMBER,
    }),
    pageManagerEnabled() {
      return this.config.PAGE_MANAGER_CREATE_PAGE || this.config.ECOMMERCE
    },
    allowDelete() {
      return this.pageManagerEnabled
    },
    allowDuplicate() {
      return this.pageManagerEnabled
    },
    allowSwap() {
      return this.config.FUNNEL_ENABLED
    },
    allowCreate() {
      return this.pageManagerEnabled
    },
    activeId() {
      return this.$route.params.pageId
    },
    filteredSavedPages() {
      if (this.userViewPreference === STANDARD) {
        if (this.activeTag === 'ALL') {
          return this.savedPages
        }
        return _.filter(this.savedPages, (b) => {
          return b.pageTag.toUpperCase() === this.activeTag.toUpperCase()
        })
      } else {
        return _.filter(this.savedPages, (b) => {
          return b.pageTag.toUpperCase() === USER_OWNED.toUpperCase()
        })
      }
    },
    isFreshworksOrg() {
      return this.orgName === FRESHWORKS
    },
    cancelBtnProps() {
      if (this.isFreshworksOrg) {
        return { color: 'light' }
      } else {
        return { color: 'warning' }
      }
    },
    submitBtnProps() {
      if (this.isFreshworksOrg) {
        return {
          color: '',
          specialStyle: 'dark',
        }
      } else {
        return {
          color: 'light',
        }
      }
    },
    isPagesList() {
      return this.currentView === viewOptions.page
    },
    isSavePage() {
      return this.currentView === viewOptions.save
    },
    isPageSettings() {
      return this.currentView === viewOptions.settings
    },
  },
  created() {
    const { MARKETPLACE_PATH } = this.config
    const { orgID, projectID } = this.user

    const paths = [
      {
        path: `${MARKETPLACE_PATH}/${_.get(this.site, 'orgID')}`,
        child: 'pages',
      },
      {
        path: `${MARKETPLACE_PATH}/${_.get(this.site, 'orgID')}/${_.get(
          this.site,
          'orgID',
        )}`,
        child: `pages`,
      },
      { path: MARKETPLACE_PATH, child: `globalPages` }, // add global pages to list
      //USER BLOCKS
      { path: `${MARKETPLACE_PATH}/${orgID}/${projectID}`, child: `pages` },
    ]
    paths.forEach(async (item, pathIndex) => {
      await db
        .ref(item.path)
        .child(item.child)
        .once('value', (snap) => {
          const path = `${item.path}/${item.child}`
          this.savePageLoadAction(snap, path)
        })
    })
  },

  methods: {
    ...mapActions({
      toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
      pushCommand: actions.HISTORY_COMMAND_PUSH,
    }),
    ...mapMutations({
      addPage: mutations.SET_BLOCK_ADDED,
      togglePageBookmarkDialog: mutations.PAGES_BOOKMARK_BLOCK_SHOW,
    }),
    ...mapMutations('pageManager', {
      settings: 'open',
      showDeleteDialog: 'openDeleteDialog',
      save: 'openSave',
    }),
    ...mapActions('pageManager', {
      duplicate: 'duplicate',
      remove: 'remove',
      insertSavePages: 'insertSavePages',
      replacePageLayout: 'replacePageLayout',
      createBlankPage: 'createNew',
      toggleSwitchingPage: 'setSwitchingPages',
    }),
    ...mapActions('revisions', {
      revisionSaveSnapshot: 'saveSnapshot',
    }),
    ...mapActions('loader', {
      startLoader: 'start',
      stopLoader: 'stop',
    }),
    savePageLoadAction(snap, path) {
      let pages = snap.val()
      let tags = []
      for (let key in pages) {
        let page = pages[key]
        if (page['pageName'] !== undefined) {
          if (
            (page['isGridBuilder'] !== undefined &&
              page['isGridBuilder'] === true &&
              this.isGridBuilder) ||
            (page['isGridBuilder'] !== undefined &&
              page['isGridBuilder'] === false &&
              !this.isGridBuilder)
          ) {
            this.savedPages.push({
              key: key,
              fbPath: `${path}/${key}`,
              ...page,
            })
          }
        }

        if (page.pageTag && page.pageTag !== USER_OWNED) {
          tags[page.pageTag.toUpperCase()] = true
        }
      }
      tags = Object.keys(tags)
      tags.unshift('ALL')
      this.tags = _.sortedUniq(tags)
      this.loading = false
    },
    createNew(id) {
      if (this.isGridBuilder && this.config.FUNNEL_ENABLED) {
        this.currentView = viewOptions.save
      } else {
        this.insertBlankPage()
      }
    },
    confirmAndRemove(...args) {
      this.showRemovePageDialog = true
      this.pageArgs = args

      // TODO: for Freshworks delete dialog
      // Was not showing so this is added.
      if (this.deletePageWithoutConfirm) {
        this.proceedPageRemoval()
      }
    },

    async proceedPageRemoval() {
      this.edit(this.indexPage)
      this.removing = true
      await this.remove(...this.pageArgs)
      this.$message({
        type: 'success',
        message: this.$t('pagemanager.cvt_dialog.delete.success.message'),
      })
      this.showRemovePageDialog = false
      this.removing = false
      this.pageArgs = null
    },

    abortPageRemoval() {
      this.showRemovePageDialog = false
      this.pageArgs = null
      this.removing = false
    },

    edit({ id }) {
      this.toggleSwitchingPage(true)

      if (this.$router.history.current.query['redirectUrl']) {
        // remove the redirect url so the routing can happen
        delete this.$router.history.current.query['redirectUrl']
      }

      this.$router
        .push({
          page: cvtRouteNodes.editorDesignPage,
          params: {
            ...this.$route.params,
            pageId: id,
          },
        })
        .then(() => {
          window.location.reload()
          this.toggleSwitchingPage(false)
        })
    },
    async insertOrReplacePage(page) {
      this.insertingOrReplacingPage = true
      this.addPage(true)
      if (this.proceedReplacePage) {
        const pageProps = {
          page: page, // Firebase-Saved-Page
          siteId: this.site.id,
          pageToBeReplace: this.savedPageData, // Site's Own-Page
        }
        if (this.currentProductVersionNumber >= 3.0) {
          await this.pageSwapV3(pageProps)
        } else {
          await this.pageSwapV1AndV2(pageProps)
        }
        this.insertingOrReplacingPage = false
      } else {
        const pageProps = {
          page: page,
          siteId: this.site.id,
        }
        try {
          await this.insertSavePages({ pageProps })
        } finally {
          this.currentView = viewOptions.page
        }
        this.insertingOrReplacingPage = false
      }
    },
    async pageSwapV1AndV2(pageProps) {
      const pageSwapControl = new PageSwapControl(
        this.$store.dispatch,
        this.$store.commit,
      )
      try {
        await this.replacePageLayout({ pageProps })
        pageSwapControl.subscribe(this.deltaKey)
        Notification.success({
          title: 'Success',
          message: 'Page successfully swapped',
          position: 'bottom-right',
        })
      } catch (e) {
        console.error(e)
        Notification.error({
          title: 'Woops',
          message: 'Failed to swap page. Please try again.',
          position: 'bottom-right',
        })
      } finally {
        this.currentView = viewOptions.page
        pageSwapControl.unsubscribe()
      }
    },
    async pageSwapV3(pageProps) {
      let pageToBeReplaceVdom = await db
        .ref(pageProps.pageToBeReplace.firebaseRef)
        .child('vdom')
        .once('value')

      const pageSwapCommand = new PageSwapCommand(
        `${pageProps.pageToBeReplace.firebaseRef}/vdom`,
        pageProps.page?.vdom || [],
        pageToBeReplaceVdom.val() || [],
      )
      setTimeout(async () => {
        try {
          await this.startLoader('Apply Selected Page...')
          await this.replacePageLayout({ pageProps })
          await this.pushCommand(pageSwapCommand)
          await this.revisionSaveSnapshot({
            key: 'manually.saved',
            description: `Page-Swapped:${new Date()
              .toISOString()
              .slice(0, 16)
              .replace('T', '@')}`,
          })
          this.currentView = viewOptions.page
          Notification.success({
            title: 'Success',
            message: 'Page successfully swapped',
            position: 'bottom-right',
          })
          await this.startLoader('Refreshing Builder...')
          this.$store
            .dispatch(actions.SITE_FETCH_DATA, this.$route.params.siteId)
            .then((_) => {
              this.stopLoader('Builder is Ready')
            })
        } catch (e) {
          console.error(e)
          Notification.error({
            title: 'Woops',
            message: 'Failed to swap page. Please try again.',
            position: 'bottom-right',
          })
        } finally {
          this.currentView = viewOptions.page
          this.toggleLeftSideMenu({ open: false })
        }
      }, 500)
    },
    showReplacePageDialog(page) {
      this.savedPageData = page
      this.replacePageDialogVisible = true
    },
    showPageBookmarkDialog(e) {
      this.togglePageBookmarkDialog(e.firebaseRef)
    },
    async onDeleteBookmark() {
      this.deletingBookmark = true
      await db.ref(this.selectedBookmarkPage.fbPath).remove()
      this.deletingBookmark = false
      this.$message({
        type: 'success',
        message: 'page deleted successfully',
      })
      this.deleteBookmarkVisible = false
      this.toggleLeftSideMenu({ open: false })
    },
    showDeleteBookmarkDialog(page) {
      this.selectedBookmarkPage = page
      this.deleteBookmarkVisible = true
    },
    async editPageName(page, replaceText) {
      await db.ref(page.fbPath).update({
        pageName: replaceText,
      })
      page.pageName = replaceText
    },
    abortReplacePage() {
      this.savedPageData = null
      this.replacePageDialogVisible = false
      this.proceedReplacePage = false
    },
    confirmReplacePage() {
      this.replacePageDialogVisible = false
      this.currentView = viewOptions.save
      this.proceedReplacePage = true
    },
    async insertBlankPage() {
      this.loading = true
      await this.createBlankPage(this.site.id)
      this.$message({
        type: 'success',
        message: this.$t('pagemanager.page.created.success'),
      })
      this.loading = false
      this.currentView = viewOptions.page
    },
    async openSettings(e) {
      this.settings(e)
      this.currentView = viewOptions.settings
    },
    back() {
      this.currentView = viewOptions.page
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

.page-manager {
  width: 18rem;
}

.page-list__page {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  border-bottom: 1px solid rgba(175, 175, 173, 0.4);
  padding-left: 5px;

  &--active {
    background: lighten($inf-dark-blue, 5%);
  }
}

.page-list__page__ctrls {
  .material-icons {
    font-size: 18px;
    line-height: 1;
    cursor: pointer;
  }
}
</style>
