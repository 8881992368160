<template>
  <anime :enter="enter" :leave="leave" :before-enter="beforeEnter">
    <slot></slot>
  </anime>
</template>

<script>
  export default {
    props: {
      duration: {
        type: Number,
        default: 1000
      }
    },
    data () {
      return {
        enter: {
          opacity: [0, 1],
          duration: this.duration,
          easing: 'easeInOutQuart'
        },
        leave: {
          opacity: [1, 0],
          duration: this.duration,
          easing: 'easeInOutQuart'
        },
        beforeEnter (e) {
          e.style.opacity = 0
        }
      }
    }
  }
</script>
