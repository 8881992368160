import { styled } from '@egoist/vue-emotion'
import VueI18n from 'vue-i18n'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import * as getters from '../../../builder/src/js/store/modules/getters'
import Vue from '../../lib/utils/vue'
import { LEFT_SIDEBAR_STANDARD } from '../constants'
import CvtButton from '../molecules/Button'
import { ContentPlaceholder } from '../molecules/ContentPlaceholder'
import { CvtIcon } from '../molecules/icon/icon'
import { LazyImage } from '../molecules/LazyImage'
import CvtPopover from '../molecules/Popover'

Vue.use(VueI18n)

let FontFamily,
  Spacing,
  Radii,
  textFontWeight,
  color,
  backgroundColor,
  textFontSize,
  elevation,
  borderColor

const getCardWrapper = () => styled('div')`
  margin: ${Spacing.SPACING_16}px;
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${FontFamily.DEFAULT};
  ${(props) => {
    let styles = `
      ${
        props.mode === 'DARK'
          ? color({ themeing: 'dark' }, 'WHITE')
          : color({ themeing: 'dark' }, 500)
      }
    `
    return styles
  }}
`

const getMoreActionsWrap = () => styled('div')`
  align-self: flex-end;
  padding: ${Spacing.SPACING_8}px 0;
`

const getMoreActionsWrapTabs = () => styled('div')`
  display: flex;
  gap: 8px;
  padding: ${Spacing.SPACING_8}px 0;
`

const getButton = () => styled(CvtButton)`
  ${(props) => {
    return props.chooseTemplateTenantStyles?.previewBtnStyles || ''
  }}
`

const getCardTitle = () => styled('span')`
  margin: ${Spacing.SPACING_8}px;
  ${textFontWeight('BOLD')}
  ${textFontSize('md')}
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
`

const getCardTitleTabs = () => styled('span')`
  margin: ${Spacing.SPACING_8}px;
  ${textFontWeight('BOLD')}
  ${textFontSize('md')}
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  ${(props) => {
    return props.chooseTemplateTenantStyles?.cardTitleStyles || ''
  }}
`

const getCardTitleWrapper = () => styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const getCardTitleWrapperTabs = () => styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`

const getThumbNailWrapper = () => styled('div')`
  width: 300px;
  height: 360px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: ${Radii.RADIUS_PX_4}px;
  overflow: hidden;
  ${(props) => {
    return props.chooseTemplateTenantStyles?.thumbnailWrapper || ''
  }}
`

const getThumbnail = () => styled(LazyImage)`
  & img {
    opacity: 0.8;
    ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
    ${(props) => {
      return props.usage === 'startFromScratch' ? `display: none;` : ``
    }}
  }
`

const getContentWrapper = () => styled('div')`
  position: relative;
  background-color: transparent;

  ${(props) => {
    let styles = ``

    if (props.tabsUi) {
      styles += `
        border-width: 1px; 
        border-style: solid;
        ${borderColor({ themeing: 'dark' }, 200)}
        border-radius: ${Radii.RADIUS_PX_4}px;
      `
    } else {
      styles += `border-radius: ${Radii.RADIUS_PX_8 + Radii.RADIUS_PX_4}px;`
    }

    styles += `
      ${elevation({ mode: props.mode }, 100)}
      &:hover {
        ${elevation({ mode: props.mode }, 400)}
        ${props.usage === 'startFromScratch' ? `img { display: flex; }` : ``}
      }
    `
    return styles
  }}
`

const getCardContent = () => styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  width: 300px;
  height: 360px;
  border-radius: ${Radii.RADIUS_PX_4}px;
  display: flex;
  flex-direction: column;
  ${(props) => {
    let styles = `
      ${
        props.usage === 'startFromScratch'
          ? `opacity: 1; ${
              // TODO: Need to be refactored in the future using a spanner config
              props.orgnization === 'infusionsoft'
                ? `background: linear-gradient(rgba(255,255,255,.7),rgba(255,255,255,.7)),url(https://storage.googleapis.com/cdn.convrrt.com/assets/img/landing-page.svg)no-repeat center!important;`
                : props.mode === 'LIGHT'
                ? `background: transparent;`
                : backgroundColor({ themeing: 'dark' }, 800)
            }`
          : `opacity: 0;`
      }
      &:hover {
        ${
          props.usage === 'startFromScratch' && props.hover
            ? `${
                props.chooseTemplateTenantStyles?.cardContentHoverStyles ||
                `background: linear-gradient(rgba(255,255,255,.7),rgba(255,255,255,.7)),url(https://storage.googleapis.com/cdn.convrrt.com/assets/img/landing-page.svg)no-repeat center!important;`
              }`
            : ``
        }
        opacity: 1;
        ${
          props.mode === 'LIGHT'
            ? `background: rgba(255, 255, 255, 0.7);`
            : `background: rgba(0, 0, 0, 0.7);`
        }
      }
      ${
        props.usage === 'startFromScratch' &&
        props.alwaysShowGetStartedThumbnail
          ? `${
              props.chooseTemplateTenantStyles?.cardContentHoverStyles ||
              `background: linear-gradient(rgba(255,255,255,.7),rgba(255,255,255,.7)),url(https://storage.googleapis.com/cdn.convrrt.com/assets/img/landing-page.svg)no-repeat center!important;`
            }`
          : ``
      }
    `

    if (props.tabsUi) {
      styles += `border-width: 1px`
      styles += `border-style: solid`
      styles += `${borderColor({ themeing: 'dark' }, 200)};`
    }

    return styles
  }}
`

const getGetStartedWrap = () => styled('div')`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: ${Spacing.SPACING_8}px;
`

const getGetStartedButton = () => styled(CvtButton)``

const getPreviewWrap = () => styled('div')`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: ${Spacing.SPACING_8}px;
`

const getTemplatePreviewWrap = () => styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: ${Spacing.SPACING_8}px;
`

const getEditBtnWrap = () => styled('div')`
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: flex-end;
`
const getSettingsBtnWrap = () => styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`
const getPublishBtnWrap = () => styled('div')`
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: flex-start;
`
const getPreviewButton = () => styled(CvtButton)`
  padding: ${Spacing.SPACING_12}px 30px !important;
  ${(props) => {
    return props.chooseTemplateTenantStyles?.previewBtnStyles || ''
  }}
`

const getCopyTemplateWrap = () => styled('div')`
  flex: 1;
  display: flex;
  flex-direction: ${(props) =>
    props.showBothTemplateActions ? 'column' : 'flex-start'};
  ${(props) =>
    props.showBothTemplateActions
      ? `
  align-items: center;
  row-gap: 20px;
  `
      : ''}
  justify-content: center;
  padding: ${Spacing.SPACING_8}px;
`

const getCopyTemplateButton = () => styled(CvtButton)`
  ${(props) => {
    return props.showBothTemplateActions
      ? ``
      : `
      margin-top: auto;
      margin-bottom: auto;
      `
  }}
`

const getStartFromScratchWrap = () => styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => {
    return !props.hover
      ? `
    background: url(https://storage.googleapis.com/cdn.convrrt.com/assets/img/landing-page.svg)no-repeat;
    `
      : ``
  }}
`

const getStartFromScratchButton = () => styled(CvtButton)`
  ${(props) => {
    return !props.hover
      ? `
    position: absolute !important;
    bottom: 25px !important;
    `
      : ``
  }}
`

const getPopperContent = () => styled('div')`
  display: flex;
  align-items: center;
  ${textFontWeight('REGULAR')}
  ${textFontSize('sm')}
  height: ${Spacing.SPACING_32}px;
  cursor: pointer;
  ${(props) => {
    let styles = `
      &:hover {
        ${
          props.mode === 'DARK'
            ? `${backgroundColor({ themeing: 'dark' }, 600)}`
            : `${backgroundColor({ themeing: 'primary' }, 100)} 
                ${color({ themeing: 'primary' }, 500)}`
        }
      }
    `
    return styles
  }}
`

const getPopperIcon = () => styled(CvtIcon)`
  width: 10.5px;
  margin-left: ${Spacing.SPACING_12}px;
`

const getPopperText = () => styled('span')`
  margin: 0 ${Spacing.SPACING_12}px;
  flex: 1;
  display: flex;
  justify-content: flex-start;
`

const getPlaceholderContainer = () => styled('div')`
  position: relative;
  width: 300px;
  height: 360px;
  margin: ${Spacing.SPACING_16}px ${Spacing.SPACING_16}px
    ${Spacing.SPACING_48}px ${Spacing.SPACING_16}px;
`

const getPlaceholder = () => styled(ContentPlaceholder)`
  border-radius: ${Radii.RADIUS_PX_8}px;

  &.thumb-card {
    top: 0;
    width: 100%;
    height: 100%;
  }

  &.title {
    bottom: -24px;
    left: 25%;
    width: 50%;
    height: 16px;
  }
`

export const CvtMarketplaceCard = {
  name: 'CvtMarketplaceCard',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      elevation: getters.GLOBAL_STYLE_ELEVATION,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
    }),
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      iconConfig: ({ iconConfig }) => iconConfig,
      // TODO: Need to be refactored in the future using a spanner config
      orgnization: ({ globalTheme }) => globalTheme.orgnization || 'convrrt',
      orgName: ({ globalTheme }) =>
        globalTheme?.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
      certifiedTemplatesMarketplaceUI: ({ globalTheme }) =>
        globalTheme.certifiedTemplatesMarketplaceUI,
    }),
    chooseTemplateTenantStyles() {
      if (!this.config) return
      return this.config.MARKETPLACE_CHOOSE_A_TEMPLATE_CONFIG?.customStyles
    },
    alwaysShowGetStartedThumbnail() {
      return Boolean(
        this?.config?.MARKETPLACE_CHOOSE_A_TEMPLATE_CONFIG
          ?.alwaysShowGetStartedThumbnail,
      )
    },
    showBothTemplateActions() {
      return Boolean(
        this?.config?.MARKETPLACE_CONFIG?.marketplaceCard
          ?.showBothTemplateActions,
      )
    },
    secondActionButton() {
      if (this.swapTemplateButtonAction) {
        return this?.config?.MARKETPLACE_CONFIG?.marketplaceCard.copyPageButton
      }
      return this?.config?.MARKETPLACE_CONFIG?.marketplaceCard.editPageButton
    },
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    thumbnail: {
      type: String,
      default: '',
    },
    thumbnailAlt: {
      type: String,
      default: '',
    },
    usage: {
      type: String,
      default: '',
    },
    moreActions: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: 'mpCard',
    },
    item: {
      type: Object,
      default: null,
    },
    hover: {
      type: Boolean,
      default: true,
    },
    isTemplateActionsRightSidebarEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    archiveButton: {
      type: Object,
      default: null,
    },
    copyPageButton: {
      type: Object,
      default: null,
    },
    getStartedButton: {
      type: Object,
      default: null,
    },
    previewButton: {
      type: Object,
      default: () => {},
    },
    swapTemplateButtonAction: {
      type: Boolean,
      default: false,
    },
    tabsUi: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.$refs.popover) {
      this.$refs.popover.$el.addEventListener('click', this.stopPropagation)
      document.body.addEventListener('click', this.onClickOutsidePopover)
    }
  },
  beforeDestroy() {
    if (this.$refs.popover) {
      this.$refs.popover.$el.removeEventListener('click', this.stopPropagation)
      document.body.removeEventListener('click', this.onClickOutsidePopover)
    }
  },
  methods: {
    click(eventName) {
      this.$emit(eventName, eventName)
    },
    adminAction(eventName) {
      this.$emit('action', eventName, this.item)
    },
    moreActionClicked(moreActionKey) {
      this.onClickOutsidePopover()
      this.$emit('moreActionClicked', moreActionKey)
    },
    onClickOutsidePopover() {
      if (this.$refs.popover && this.$refs.popover.$refs.tooltip)
        this.$refs.popover.$refs.tooltip.doClose()
    },
    stopPropagation(event) {
      event.stopPropagation()
    },
    templateActionsBtnClicked(event) {
      this.$emit('templateActionsBtnClicked', event)
    },

    onClickTemplateAction() {
      if (this.swapTemplateButtonAction) {
        return this.click('editPage')
      }
      this.click('copyTemplate')
    },
    onClickSecondTemplateAction() {
      if (this.swapTemplateButtonAction) {
        this.click('copyTemplate')
      }
      return this.click('editPage')
    },
  },
  created() {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    textFontWeight = this.textFontWeight
    color = this.textColor
    borderColor = this.borderColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    elevation = this.elevation
  },
  render: function (h) {
    // TODO:
    /**
     * In order to internationalize and/or customize text for the popout,
     * that is shown by the 3 dots, the iconConfig file would need a helper
     * that could reference the text for a given icon within it the config
     * and then apply an internationalization or specialized customization.
     **/

    const cardIcons = this.iconConfig['marketplaceCards']
    const CardWrapper = getCardWrapper()
    const MoreActionsWrap = getMoreActionsWrap()
    const MoreActionsWrapTabs = getMoreActionsWrapTabs()
    const MoreActionsButton = getButton()
    const SettingsButton = getButton()
    const InfoButton = getButton()
    const CardTitle = getCardTitle()
    const CardTitleTabs = getCardTitleTabs()
    const CardTitleWrapper = getCardTitleWrapper()
    const CardTitleWrapperTabs = getCardTitleWrapperTabs()
    const ThumbNailWrapper = getThumbNailWrapper()
    const Thumbnail = getThumbnail()
    const ContentWrapper = getContentWrapper()
    const CardContent = getCardContent()
    const GetStartedWrap = getGetStartedWrap()
    const GetStartedButton = getGetStartedButton()
    const PreviewWrap = getPreviewWrap()
    const TemplatePreviewWrap = getTemplatePreviewWrap()
    const PreviewButton = getPreviewButton()
    const CopyTemplateWrap = getCopyTemplateWrap()
    const CopyTemplateButton = getCopyTemplateButton()
    const StartFromScratchWrap = getStartFromScratchWrap(CopyTemplateWrap)
    const StartFromScratchButton = getStartFromScratchButton()
    const PopperContent = getPopperContent()
    const PopperIcon = getPopperIcon()
    const PopperText = getPopperText()
    const EditBtnWrap = getEditBtnWrap()
    const SettingsBtnWrap = getSettingsBtnWrap()
    const PublishBtnWrap = getPublishBtnWrap()

    const moreActionsElement = this.moreActions.map((action) => {
      return (
        <PopperContent
          mode={this.mode}
          onClick={() => this.moreActionClicked(action.key)}
        >
          <PopperIcon icon={cardIcons[action.key]}></PopperIcon>
          <PopperText>{action.text}</PopperText>
        </PopperContent>
      )
    })

    return (
      <CardWrapper mode={this.mode}>
        <ContentWrapper
          mode={this.mode}
          usage={this.usage}
          tabsUi={this.tabsUi}
        >
          <ThumbNailWrapper
            chooseTemplateTenantStyles={this.chooseTemplateTenantStyles}
          >
            {this.hover && (
              <Thumbnail
                usage={this.usage}
                mode={this.mode}
                aspectRatio={360 / 330}
                dataSrc={this.thumbnail}
              ></Thumbnail>
            )}
          </ThumbNailWrapper>
          <CardContent
            mode={this.mode}
            usage={this.usage}
            tabsUi={this.tabsUi}
            orgnization={this.orgnization}
            hover={this.hover}
            chooseTemplateTenantStyles={this.chooseTemplateTenantStyles}
            alwaysShowGetStartedThumbnail={this.alwaysShowGetStartedThumbnail}
          >
            {this.usage === '' && (
              <GetStartedWrap>
                {!this.certifiedTemplatesMarketplaceUI && (
                  <GetStartedButton
                    color=""
                    specialStyle={this.getStartedButton?.specialStyle}
                    shape={this.getStartedButton?.shape || 'rounded'}
                    shadow={true}
                    mode={this.mode}
                    size="md"
                    icon={cardIcons['startButton']}
                    prepend={false}
                    text={this.$t(this.getStartedButton?.text)}
                    onClick={() => this.click('getStarted')}
                  ></GetStartedButton>
                )}
              </GetStartedWrap>
            )}
            {this.usage === '' && (
              <PreviewWrap>
                <PreviewButton
                  color="light"
                  shape={this.previewButton?.shape || 'rounded'}
                  shadow={true}
                  mode={this.mode}
                  size="md"
                  icon={cardIcons['preview']}
                  outlined={this.previewButton?.outlined}
                  text={this.$t(this.previewButton?.text)}
                  onClick={() => this.click('preview')}
                  chooseTemplateTenantStyles={this.chooseTemplateTenantStyles}
                ></PreviewButton>
              </PreviewWrap>
            )}
            {this.usage === 'popupMarketplace' && (
              <TemplatePreviewWrap>
                <GetStartedButton
                  color=""
                  specialStyle={this.getStartedButton?.specialStyle}
                  shape={this.getStartedButton?.shape || 'rounded'}
                  shadow={true}
                  mode={this.mode}
                  size="md"
                  icon={cardIcons['startButton']}
                  prepend={false}
                  text={this.$t(this.getStartedButton?.text)}
                  onClick={() => this.click('getStarted')}
                ></GetStartedButton>
              </TemplatePreviewWrap>
            )}
            {this.usage === 'adminDashboard' && (
              <EditBtnWrap>
                <PreviewButton
                  shape={this.previewButton?.shape || 'rounded'}
                  shadow={true}
                  mode={this.mode}
                  size="md"
                  prepend={false}
                  text="Edit"
                  onClick={() => this.adminAction('edit')}
                ></PreviewButton>
              </EditBtnWrap>
            )}
            {this.usage === 'adminDashboard' && (
              <SettingsBtnWrap>
                <PreviewButton
                  color="light"
                  shape={this.previewButton?.shape || 'rounded'}
                  shadow={true}
                  mode={this.mode}
                  size="md"
                  outlined={this.previewButton?.outlined}
                  text="Settings"
                  onClick={() => this.adminAction('settings')}
                ></PreviewButton>
              </SettingsBtnWrap>
            )}
            {this.usage === 'adminDashboard' && (
              <PublishBtnWrap>
                <PreviewButton
                  color="light"
                  shape={this.previewButton?.shape || 'rounded'}
                  shadow={true}
                  mode={this.mode}
                  size="md"
                  outlined={this.previewButton?.outlined}
                  text="Publish"
                  onClick={() => this.adminAction('publish')}
                ></PreviewButton>
              </PublishBtnWrap>
            )}
            {this.usage === 'startFromScratch' && (
              <StartFromScratchWrap hover={this.hover}>
                <StartFromScratchButton
                  color=""
                  specialStyle={this.getStartedButton?.specialStyle}
                  shape={this.getStartedButton?.shape || 'rounded'}
                  shadow={true}
                  mode={this.mode}
                  size="md"
                  icon={cardIcons['startButton']}
                  prepend={false}
                  text={this.$t(this.getStartedButton?.text)}
                  onClick={() => this.click('startFromScratch')}
                  hover={this.hover}
                ></StartFromScratchButton>
              </StartFromScratchWrap>
            )}
            {this.usage === 'myTemplate' && (
              <CopyTemplateWrap
                showBothTemplateActions={this.showBothTemplateActions}
              >
                <CopyTemplateButton
                  color=""
                  specialStyle={this.getStartedButton?.specialStyle}
                  shape={this.copyPageButton?.shape || 'rounded'}
                  shadow={true}
                  mode={this.mode}
                  size="md"
                  icon={cardIcons['copy']}
                  prepend={false}
                  text={this.$t(this?.copyPageButton?.text)}
                  onClick={this.onClickTemplateAction}
                  showBothTemplateActions={this.showBothTemplateActions}
                ></CopyTemplateButton>
                {this.showBothTemplateActions && (
                  <CopyTemplateButton
                    color=""
                    specialStyle={this.getStartedButton?.specialStyle}
                    shape={this.secondActionButton?.shape || 'rounded'}
                    shadow={true}
                    mode={this.mode}
                    size="md"
                    icon={cardIcons['copy']}
                    prepend={false}
                    text={this.$t(this?.secondActionButton?.text)}
                    onClick={this.onClickSecondTemplateAction}
                    showBothTemplateActions={this.showBothTemplateActions}
                  ></CopyTemplateButton>
                )}
              </CopyTemplateWrap>
            )}
            {this.usage === 'archivedTemplate' && (
              <CopyTemplateWrap>
                <CopyTemplateButton
                  color=""
                  shape={this.archiveButton?.shape || 'rounded'}
                  shadow={true}
                  mode={this.mode}
                  size="md"
                  icon={cardIcons['copy']}
                  prepend={false}
                  text={this.$t(this?.archiveButton?.text)}
                  onClick={() => this.click('restoreTemplate')}
                ></CopyTemplateButton>
              </CopyTemplateWrap>
            )}
          </CardContent>
        </ContentWrapper>

        {!this.tabsUi && (
          <CardTitleWrapper>
            {this.$slots.cardTitle && (
              <CardTitle>{this.$slots.cardTitle}</CardTitle>
            )}

            {this.text && <CardTitle>{this.text}</CardTitle>}

            {this.usage === 'myTemplate' && (
              <MoreActionsWrap>
                <MoreActionsButton
                  id={this.id + '-more-actions'}
                  ref="moreActionsToggler"
                  color={this.mode.toLowerCase()}
                  mode={this.mode}
                  shape="pill"
                  size="sm"
                  icon={cardIcons['more']}
                  shadow={true}
                  onClick={(event) => this.templateActionsBtnClicked(event)}
                  chooseTemplateTenantStyles={this.chooseTemplateTenantStyles}
                ></MoreActionsButton>

                {!this.isTemplateActionsRightSidebarEnabled && (
                  <CvtPopover
                    ref="popover"
                    placement="bottom"
                    target={this.id + '-more-actions'}
                    triggers="click"
                    color={this.mode.toLowerCase()}
                    mode={this.mode}
                    innerPadding={0}
                    borderRadius={Radii.RADIUS_PX_8}
                  >
                    <div slot="popper">{moreActionsElement}</div>
                  </CvtPopover>
                )}
              </MoreActionsWrap>
            )}
          </CardTitleWrapper>
        )}

        {this.tabsUi && (
          <CardTitleWrapperTabs>
            <CardTitleTabs
              chooseTemplateTenantStyles={this.chooseTemplateTenantStyles}
            >
              {this.$slots.cardTitle}
            </CardTitleTabs>
            {this.usage === 'myTemplate' && (
              <MoreActionsWrapTabs>
                <InfoButton
                  id={this.id + '-more-actions-info'}
                  ref="moreActionsTogglerInfo"
                  color={this.mode.toLowerCase()}
                  mode={this.mode}
                  size="sm"
                  icon={cardIcons['info']}
                  shadow={true}
                  outlined={true}
                  specialPadding={'10px 12px 10px 12px'}
                  onClick={(event) => this.templateActionsBtnClicked(event)}
                  chooseTemplateTenantStyles={this.chooseTemplateTenantStyles}
                ></InfoButton>

                {!this.isTemplateActionsRightSidebarEnabled && (
                  <CvtPopover
                    ref="popover"
                    placement="bottom"
                    target={this.id + '-more-actions-settings'}
                    triggers="click"
                    color={this.mode.toLowerCase()}
                    mode={this.mode}
                    innerPadding={0}
                    borderRadius={Radii.RADIUS_PX_8}
                  >
                    <div slot="popper">{moreActionsElement}</div>
                  </CvtPopover>
                )}
              </MoreActionsWrapTabs>
            )}
          </CardTitleWrapperTabs>
        )}

        {this.$slots.dateCreated && (
          <CardTitle>{this.$slots.dateCreated}</CardTitle>
        )}
        {this.$slots.lastUpdated && (
          <CardTitle>{this.$slots.lastUpdated}</CardTitle>
        )}
        {this.$slots.published && (
          <CardTitle>{this.$slots.published}</CardTitle>
        )}
        {this.$slots.id && <CardTitle>{this.$slots.id}</CardTitle>}
      </CardWrapper>
    )
  },
}

export const PlaceholderCard = {
  name: 'PlaceholderCard',
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      elevation: getters.GLOBAL_STYLE_ELEVATION,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
    }),
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  created() {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    textFontWeight = this.textFontWeight
    color = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    elevation = this.elevation
  },
  render: function (h) {
    const PlaceholderContainer = getPlaceholderContainer()
    const Placeholder = getPlaceholder()
    return (
      <PlaceholderContainer>
        <Placeholder mode={this.mode} class="thumb-card"></Placeholder>
        <Placeholder mode={this.mode} class="title"></Placeholder>
      </PlaceholderContainer>
    )
  },
}
