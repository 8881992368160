
<cvt-top-nav-bar
  mode="LIGHT"
  variant="embedded"
  :route="currentRouteName"
  :fixed="true"
  template-name="Generative Wizard Editor"
  :disable-exit="!disableExitButton"
  :light-logo="logo"
  :dark-logo="logo"
  :revision-loading="false"
  :undo-disabled="disabled(editorCtrls[0].disabled)"
  :redo-disabled="disabled(editorCtrls[1].disabled)"
  :type="orgName"
  @back="backStep"
  @exit="exitAction"
  @help="help"
  @exit-preview="exitAction"
  @switch-preview="() => {}"
  @undo="execute(editorCtrls[0])"
  @redo="execute(editorCtrls[1])"
  @top-settings="() => {}"
  @version-history="() => {}"
  @version-history-save="() => {}"
/>
