import { styled, VueEmotion } from '@egoist/vue-emotion'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex';
import * as getters from '../../../../builder/src/js/store/modules/getters';
import Vue from '../../../lib/utils/vue'

import { CvtIcon } from '../../molecules/icon/icon'

Vue.use(VueEmotion)


let FontFamily, 
Spacing, 
textFontWeight,
textFontSize,
Radii, 
Colors, 
FontSize

const getSideNavContainerWrap = () => styled('div')`
  width: 280px;
  height: 100%;
  overflow-y: hidden;
  background-color: ${Colors.BASIC[200]};
`

const getSideNavContainer = () => styled('div')`
  height: 100%;
  background-color: ${Colors.BASIC[200]};
  padding: ${Spacing.SPACING_24 + Spacing.SPACING_2}px ${Spacing.SPACING_24 + Spacing.SPACING_2}px 0 ${Spacing.SPACING_24 + Spacing.SPACING_2}px;
`

const getSideNavItemWrapper = () => styled('div')`
  padding: ${Spacing.SPACING_8}px;
  margin-bottom: 15px;
  display: flex;
  cursor: pointer;
  border-radius: ${Radii.RADIUS_PX_8}px;
  ${props => props.active && `
  background-color: ${Colors.BASIC['WHITE']}; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);`}
`

const getNavIcon = () => styled(CvtIcon)`
  font-size: ${FontSize.FONT_SIZE_20}px;
  margin: 0 ${Spacing.SPACING_18 + Spacing.SPACING_2}px 0 ${Spacing.SPACING_8 + Spacing.SPACING_2}px;
  color: #495057;
  ${props => props.active && `color: #5D62AB`}
`

const getSideNavItemLabel = () => styled('span')`
  ${textFontSize('lg')};
  color: #495057;
  ${textFontWeight('SEMI_BOLD')};
  ${props => props.active && `color: #5D62AB`}
`

export const CvtEntDashboardSideNav = {
  name: 'CvtEntDashboardSideNav',
  data () {
    return {
      activeItem: this.initialActiveItem,
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
    navigationItems: {
      type: Array,
      default: () => []
    },
    initialActiveItem: {
      type: Object,
      default: {
        icon: '', 
        label: '', 
        route: ''
      }
    },
  },
  computed: {
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      iconConfig: ({ iconConfig }) => iconConfig,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
    }),

    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
    }),
  },
  methods: {
    onChangeNavTab (item) {
      this.activeItem = item
      this.$emit('onChangeNavTab', item)
    },

    onRouteChange (name) {
      const relPathName = name.split(':').slice(-1)[0];
      this.navigationItems.find((item) => {
        if (item.route === relPathName) return this.onChangeNavTab(item);
      })
    },
  },
  watch: {
    '$route' (to, from) {
      this.onRouteChange(to.name)
    }
  },
  created () {
    this.onRouteChange(this.$route.name)

    FontFamily = this.FontFamily;
    Spacing = this.Spacing;
    Radii = this.Radii;
    Colors = this.Colors;
    textFontWeight = this.textFontWeight;
    textFontSize = this.textFontSize;
    FontSize = this.FontSize;
  },
  render: function (h) {
    const SideNavContainer  = getSideNavContainer()
    const SideNavItemWrapper = getSideNavItemWrapper()
    const NavIcon = getNavIcon()
    const SideNavItemLabel = getSideNavItemLabel()
    const SideNavContainerWrap = getSideNavContainerWrap()

    const navItems = this.navigationItems.map((item) => {
      const activeFlag = item.label === this.activeItem.label

      return (
        <SideNavItemWrapper onClick={() => this.onChangeNavTab(item)} active={activeFlag}>
            <NavIcon
              icon={item.icon}
              active={activeFlag}
            ></NavIcon>
          <SideNavItemLabel active={activeFlag}>{item.label}</SideNavItemLabel>
        </SideNavItemWrapper>
      )
    })

    return (
        <SideNavContainerWrap>
        <SideNavContainer>
          {navItems}
        </SideNavContainer>
        </SideNavContainerWrap>
    )
  }
}