
<div class="invite-it-wrap">
  <div class="heading-n-desc-wrap">
    <div class="main-heading-wrap">
      <h3 class="main-heading">Invite IT to setup the DNS</h3>
    </div>

    <div class="main-desc-wrap">
      <p class="main-desc">
        These steps require access to your DNS provider. If you do not have
        access to the DNS manager, we offer to invite other team members to
        help you with the next steps.
      </p>
    </div>
  </div>

  <form class="label-n-input-wrap" @submit.prevent="sendInvite">
    <p class="small-desc">Invite an engineer</p>

    <div
      v-for="(input, idx) in emailAddresses"
      :key="`emailInput-${idx}`"
      class="url-input-wrap mb-3 d-flex align-items-start"
    >
      <input
        v-model="input.email"
        class="url-input"
        required
        type="email"
        value=""
        placeholder="Joe@company.com"
      />

      <div class="plus-btn-wrap">
        <span
          v-show="idx === emailAddresses.length - 1"
          :class="'ib-plus-btn mr-3'"
          @click="addNewInput(emailAddresses)"
        >
          <i :class="'fa fa-plus-circle ib-plus-icon'"></i>
        </span>
        <span
          v-show="emailAddresses.length > 1 && idx !== 0"
          @click="removeInput(idx, emailAddresses)"
        >
          <i :class="'fa fa-remove ib-remove-icon'"></i>
        </span>
      </div>
    </div>

    <div class="action-btns-wrap">
      <cvt-button
        v-if="!disableCancelBtn"
        :color="'light'"
        :style="'color: #5D62AB'"
        :mode="'LIGHT'"
        :text="'Cancel'"
        :special-padding="'20px 49px'"
        @click="cancelBtn"
      >
      </cvt-button>

      <cvt-button
        :class="'action-btn-invite'"
        :mode="'LIGHT'"
        :text="'Send Invite'"
        :disabled="loading"
        submit
        :special-padding="'20px 171px'"
      >
      </cvt-button>
      
      <span class="invite-it-skip-btn" @click="onSkipItInvite">
        Skip this step
      </span>
    </div>
  </form>
</div>
