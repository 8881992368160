import { CvtSlider } from './index'
import { styled, VueEmotion } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from 'vuex'
import Vue from '../../../lib/utils/vue'

let Spacing, Radii, Border
let backgroundColor, borderColor, textFontSize, textFontWeight, color, elevation
let railWidth, knobSize, stopSize

Vue.use(VueEmotion)

const getSlider = () => styled('span')`
  display: flex;

  &.disabled {
    opacity: 0.7;
  }

  &.disabled .slider__knob {
    cursor: not-allowed;
  }
`

const getSliderInner = () => styled('span')`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
`

const getSliderRail = () => styled('span')`
  width: ${railWidth};
  margin: 0 ${Spacing.SPACING_16}px;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  border-radius: ${Radii.RADIUS_PX_4}px;
  transition: all 0.1s;
  ${(props) =>
    props.themeing == 'light'
      ? backgroundColor({ themeing: 'dark' }, 500)
      : backgroundColor(props, 100)}
`

const getSliderFill = () => styled('span')`
  width: ${railWidth};
  margin: 0 ${Spacing.SPACING_16}px;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  transition: all 0.1s;
  border-radius: ${Radii.RADIUS_PX_4}px;
  ${(props) => backgroundColor(props, 500)}
`

const getSliderKnob = () => styled('span')`
  position: absolute;
  top: auto;
  left: 9px;
  transform: translateY(30%);
  border-radius: ${Radii.RADIUS_PERCENT_50}%;
  height: ${knobSize};
  width: ${knobSize};
  cursor: pointer;
  z-index: 1;
  border: ${Border.SIZE_1}px solid;
  transition: all 0.1s;
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  ${(props) => borderColor(props, 500)}
  ${(props) => elevation(props, 300)}
`

const getSliderStop = () => styled('span')`
  width: ${stopSize};
  height: ${stopSize};
  position: absolute;
  bottom: 0;
  margin: auto;
  left: 16px;
  border-radius: ${Radii.RADIUS_PERCENT_50}%;
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}

  span {
    position: relative;
    left: -40px;
    top: -9px;
    ${textFontSize('md')}
    ${textFontWeight()}
    ${(props) => color(props, 500)}
  }
`

const getSliderHiddenInput = () => styled('span')`
  display: none;
`

export default {
  name: 'CvtVerticalSlider',
  extends: CvtSlider,
  props: {
    height: {
      type: Number,
      default: 300,
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      elevation: getters.GLOBAL_STYLE_ELEVATION,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
    }),
  },
  methods: {
    dragStart(event, offset /*{ left: number, top: number }*/) {
      // detect knob been dragged to assign current position
      // to verify on drag end
      const targetId = (event.target && event.target.id) || null
      if (targetId === this.$refs.knob.id) {
        // tooltip triggers hide event automatically
        this.showTooltip = this.tooltipsEnabled
        this.dragStartValue = this.actualValue
        this.isRangeDragged = false
        return
      } else if (this.$refs.rangeKnob && targetId === this.$refs.rangeKnob.id) {
        // tooltip triggers hide event automatically
        this.showRangeTooltip = this.tooltipsEnabled
        this.dragStartValue = this.rangeValue
        this.isRangeDragged = true
        return
      }
      // If the click is out of knob, move it to mouse position
      // this.drag(event, offset)
    },
    drag(event, offset /*{ left: number, top: number }*/) {
      const { offsetHeight } = this.$refs.inner
      // detect which knob is been dragged
      let targetValue = this.isRangeDragged ? this.rangeValue : this.actualValue
      targetValue = this.round(
        this.valueFromBounds(offsetHeight - offset.top, offsetHeight),
      )
      this.setDropValue(targetValue, this.isRangeDragged)
    },
    dragEnd(event, offset /*{ left: number, top: number }*/) {
      const { offsetHeight } = this.$refs.inner
      const evalPosFn = this.customStepsEnabled
        ? this.roundToCustom
        : this.round
      // detect which knob is been dragged
      let targetValue = this.isRangeDragged ? this.rangeValue : this.actualValue
      targetValue = evalPosFn(
        this.valueFromBounds(offsetHeight - offset.top, offsetHeight),
      )
      if (this.dragStartValue !== this.actualValue) {
        this.setDropValue(targetValue, this.isRangeDragged, true)
      }
    },
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    color = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    elevation = this.elevation
    railWidth = `${Spacing.SPACING_4}px`
    knobSize = `${Spacing.SPACING_18}px`
    stopSize = `${Spacing.SPACING_4}px`
  },
  render: function (h) {
    const Slider = getSlider()
    const SliderInner = getSliderInner()
    const SliderHiddenInput = getSliderHiddenInput()
    const SliderRail = getSliderRail()
    const SliderFill = getSliderFill()
    const SliderKnob = getSliderKnob()
    const SliderStop = getSliderStop()

    return (
      <Slider
        class={{ disabled: this.disabled }}
        id={this.id}
        style={{ height: this.height + 'px' }}
      >
        <drag-helper
          disabled={this.disabled}
          onDragstart={(e, off) => this.dragStart(e, off)}
          onDrag={this.drag}
          onDragend={this.dragEnd}
        >
          <SliderInner ref="inner">
            <SliderHiddenInput
              type="text"
              name={this.name}
              value={this.actualValue}
              disabled={this.disabled}
            ></SliderHiddenInput>
            <SliderRail themeing={this.color}></SliderRail>

            {this.rangeEnabled ? (
              <div>
                <SliderFill
                  style={{
                    bottom: this.valuePercent + '%',
                    height: this.rangeFillPercent + '%',
                  }}
                  themeing={this.color}
                ></SliderFill>
                <SliderKnob
                  mode={this.mode}
                  class="slider__knob"
                  id={this._knobId}
                  ref="knob"
                  themeing={this.color}
                  style={{ bottom: this.valuePercent + '%' }}
                >
                  {this.$slots.default}
                </SliderKnob>

                <SliderKnob
                  mode={this.mode}
                  class="slider__knob"
                  id={this._rangeKnobId}
                  ref="rangeKnob"
                  themeing={this.color}
                  style={{ bottom: this.valuePercentRange + '%' }}
                >
                  {this.$slots.default}
                </SliderKnob>

                {this.tooltipsEnabled && (
                  <cvt-tooltip
                    placement="right"
                    offset="5"
                    boundary-padding="5"
                    show={this.showTooltip}
                    fallbackPlacement="right"
                    ref="tooltip"
                    target={this._knobId}
                    delay="600"
                    color="dark"
                    mode={this.mode}
                  >
                    {this.actualValue}
                  </cvt-tooltip>
                )}

                {this.tooltipsEnabled && (
                  <cvt-tooltip
                    placement="right"
                    offset="5"
                    boundary-padding="5"
                    show={this.showRangeTooltip}
                    fallbackPlacement="right"
                    ref="tooltip"
                    target={this._rangeKnobId}
                    delay="600"
                    color="dark"
                    mode={this.mode}
                  >
                    {this.rangeValue}
                  </cvt-tooltip>
                )}
              </div>
            ) : (
              <div>
                <SliderFill
                  style={{ height: this.valuePercent + '%' }}
                  themeing={this.color}
                ></SliderFill>

                <SliderKnob
                  mode={this.mode}
                  class="slider__knob"
                  id={this._knobId}
                  ref="knob"
                  themeing={this.color}
                  style={{ bottom: this.valuePercent + '%' }}
                >
                  {this.$slots.knob}
                </SliderKnob>

                {this.tooltipsEnabled && (
                  <cvt-tooltip
                    placement="right"
                    offset="5"
                    boundary-padding="5"
                    show={this.showTooltip}
                    fallbackPlacement="right"
                    ref="tooltip"
                    target={this._knobId}
                    delay="600"
                    color="dark"
                    mode={this.mode}
                  >
                    {this.actualValue}
                  </cvt-tooltip>
                )}
              </div>
            )}

            {this._stops &&
              this._stops.map((stop) => {
                return (
                  <SliderStop
                    themeing={this.color}
                    style={{ bottom: stop.value + '%' }}
                  >
                    {this.markLabelsEnabled && (
                      <span domPropsInnerHTML={stop.label}></span>
                    )}
                  </SliderStop>
                )
              })}
          </SliderInner>
        </drag-helper>
      </Slider>
    )
  },
}
