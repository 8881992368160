<template>
  <el-dialog
    :visible="visible"
    size="tiny"
    custom-class="gen-ai-dialog-container"
    @close="handleClose"
    @open="handleOpen"
  >
  <span
      slot="title"
  >
<!--      {{ $t('editor.save_block') }}-->
    Generative AI Content
  </span>
  <form
class="d-flex mb-2"
        @submit.prevent="addNewLabel"
  >

    <div class="w-100 mr-2 mb-1">
      <NewLabelInput
          v-model="newLabel"
      />
    </div>
    <div class="m-2 align-self-end w-25">
      <cvt-button
        :outlined="true"
        :full-width="true"
        shape="rounded"
        color = "light"
        mode="light"
        :text="`Add Label`"
      />
    </div>
  </form>
  <div class="fieldItemContainer">
    <div class="d-flex fieldItem" style="cursor: default">
      <div class="col-2 p-0 m-0 fieldItem-title">Title</div>
      <div class="fieldItem-description font-weight-bold">Description</div>
    </div>
    <hr class="m-1">
    <div ref="tagSection" class="tag-section">
      <template v-for="(item, idx) in DynamicFields">
        <div
:key="idx" :class="{'d-flex fieldItem': true, 'fieldItem-selected': selectedValue.label === item.label}"
             @click="() => handleSelection(item)" >
          <div class="col-2 p-0 m-0 fieldItem-title">{{item.label}}</div>
          <div class="fieldItem-description">{{item.description || ''}}</div>
        </div>
      </template>
    </div>
  </div>
  </el-dialog>
</template>
<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import * as getters from "@/js/store/modules/getters";
import {DynamicFields} from '../../genAIFields'
import * as actions from "@/js/store/modules/actions";
import NewLabelInput from './NewLabelInput.vue'

const COMPONENT_VALUE_PATH = {
  draft: '/data/props/contentsHTML',
  'c-button': '/data/props/cta',
}



export default {
  components: {NewLabelInput},
  data() {
    return {
      DynamicFields: [...DynamicFields],
      selectedValue: {},
      newLabel: ""
    }
  },
  computed: {
    ...mapState('genAI', {
      visible: 'genAIDialogVisible',
    }),
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      config: getters.AUTH_GET_USER_CONFIG,
      pageKey: getters.DELTA_KEY
    }),
    genAI() {
      return this.selected.dc || {}
    }
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
    }),
    ...mapMutations('genAI', {
      closeGenAIDialog: 'closeGenAIDialog'
    }),
    handleSelection (item) {
      this.selectedValue = {...item}
      const data = {key: 'dc', value: {...item}}

      if (COMPONENT_VALUE_PATH[this.selected.nodeTag]) {
        data.value.path = `/vdom${this.selected.path().toString().split(this.pageKey)[1]}${COMPONENT_VALUE_PATH[this.selected.nodeTag]}`
      }
      this.updateProp(data)
    },
    handleOpen () {
      if (this.genAI) {
        this.selectedValue = this.genAI
      }
    },
    handleClose () {
      this.closeGenAIDialog()
      this.selectedValue = {}
    },
    addNewLabel () {
      if (!this.newLabel.trim()) return
      this.DynamicFields.push({label: this.newLabel})
      this.newLabel = ""
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    scrollToBottom() {
      const container = this.$refs.tagSection;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
  },
}
</script>


<style scoped>
.gen-ai-dialog-container {
  .el-dialog__body {
    padding-top: 0;
  }
}
.fieldItemContainer {
  background: #F3F3FA;

  .fieldItem {
    cursor: pointer;
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    padding: 0.5rem;
    gap: 15px;

    &-title {
      font-weight: 700;
    }
    &-description {
      font-weight: 400;
    }

  }
  .fieldItem-selected {
    color:  #0040DB;
  }
}

.tag-section {
  height: 200px;
  overflow-y: scroll;
}

</style>