import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import Vue from '../../../lib/utils/vue'
Vue.use(VueEmotion)

let Spacing, Border
let color, borderColor

const getCvtTh = () => styled('th')`
  border-bottom: ${Border.SIZE_1}px solid;
  padding: ${Spacing.SPACING_12}px ${Spacing.SPACING_8}px;
  min-width: 0;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
  ${(props) =>
    props.mode == 'LIGHT'
      ? color({ themeing: 'dark' }, 400) +
        borderColor({ themeing: 'dark' }, 200)
      : color({ themeing: 'dark' }, 'WHITE') +
        borderColor({ themeing: 'dark' }, 600)}
`
export default {
  name: 'CvtTh',
  components: {},
  props: {},
  inject: ['table'],
  data () {
    return {}
  },
  computed: {
    ...mapGetters('globalTheme', {
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Border: ({ globalTheme }) => globalTheme.Border
    }),
    mode () {
      return this.table.$props.mode
    }
  },
  watch: {},
  created () {
    Spacing = this.Spacing
    Border = this.Border
    borderColor = this.borderColor
    color = this.textColor
  },
  methods: {},
  render: function (h) {
    const CvtTh = getCvtTh()
    return <CvtTh mode={this.mode}>{this.$slots.default}</CvtTh>
  }
}
