<template>
  <div identity="c-card-options-wrap">
    <cvt-collapse
      :accordion="true"
      :theme="mode === 'LIGHT' ? 'light' : 'dark'"
      :prepend-chevron="prependChevron"
    >

      <div v-if="showOption('desktop')" style="padding: 16px;">
        <template-options :mode="mode" />
      </div>

      <!-- Implement Icon options -->
      <cvt-collapse-item
        v-if="showOption('desktop')"
        title="Icon"
        name="Icon"
        :special-style="isSpecialStyle"
      >
        <icon-image-options  :mode="mode"/>
      </cvt-collapse-item>

      <!-- Headline Text -->
      <cvt-collapse-item :title="$t('elements.property.card.element.options.headline')" name="headline">
        <text-options
            :content="headLineHTML"
            :config="headlineConfig"
            :update-key="'enableHeadline'"
            :is-enabled="enableHeadline"
            :label="$t('elements.property.card.element.options.edit.headline')"
             @save="(val) => selectAction(val, 'headLineHTML')" />
      </cvt-collapse-item>

        <!-- Text -->
      <cvt-collapse-item :title="$t('editor.left_nav.element.text')" name="text">
        <text-options
          :content="textHTML"
          :config="textConfig"
          :update-key="'enableText'"
          :is-enabled="enableText"
          :label="$t('editor.section.grid_item.edit.btn_text')"
          @save="(val) => selectAction(val, 'textHTML')" />
      </cvt-collapse-item>

      <!-- Implement Style options -->
      <cvt-collapse-item :title="$t('elements.properties.local.style.label')" name="style">
        <style-option :mode="mode" />
      </cvt-collapse-item>

      <!-- Implement Background options -->
      <cvt-collapse-item :title="$t('elements.properties.background.color')" name="background">
        <background :mode="mode" />
      </cvt-collapse-item>

    </cvt-collapse>
  </div>
</template>

<script>
import * as _ from 'lodash'
import { mapGetters, mapState } from "vuex";
import * as getters from '@/js/store/modules/getters'
import { rgbToHex } from "@/js/lib/color-utils";
import { FONT_SIZES } from "@/js/lib/font"

import TextOptions from "./TextOptions.vue";
import IconImageOptions from "./IconImageOptions.vue";
import StyleOption from "./StyleOption.vue";
import Background from "./Background.vue";
import TemplateOptions from "./TemplateOptions.vue";
import { LEFT_SIDEBAR_FRESHWORKS } from "../../../../../../../storybook/components/constants";

export default {
  components: {
    TextOptions,
    IconImageOptions,
    StyleOption,
    Background,
    TemplateOptions
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    prependChevron: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      config: getters.AUTH_GET_USER_CONFIG,
      viewMode: getters.VIEW_MODE,
      availableFonts: getters.THEME_AVAILABLE_FONTS,
      colors: getters.THEME_GET_COLORS,
      typography: getters.THEME_TYPOGRAPHY,
    }),
    ...mapState('globalTheme', {
      OrgName: ({ globalTheme }) => globalTheme.OrgName !== undefined ? globalTheme.OrgName : 'standard'
    }),
    isSpecialStyle() {
      return this.OrgName === LEFT_SIDEBAR_FRESHWORKS
    },
    enableBackground: {
      get() {
        return _.get(this, 'selected.template.enableBackground', true)
      },
    },
    enableHeadline: {
      get() {
        return _.get(this, 'selected.template.enableHeadline', true)
      },
    },
    enableIcon: {
      get() {
        return _.get(this, 'selected.template.enableIcon', true)
      },
    },
    enableText: {
      get() {
        return _.get(this, 'selected.template.enableText', true)
      },
    },
    headLineHTML: {
      get() {
        return _.get(this, 'selected.template.headline', "Edit Me")
      },
      set (value) {
        this.selected.updateProp('template/headline', value )
      }
    },
    textHTML: {
      get () {
        return _.get(this, 'selected.template.text', "Edit Me")
      },
      set (value) {
        this.selected.updateProp('template/text', value)
      }
    },
    headlineConfig () {
      return this.getFroalaConfig([
        'customParagraphStyleOne',
        'textColor',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'clearFormatting',
        'undo',
        'redo',
        'fontWeight',
        'backgroundColor'
      ])
    },
    textConfig () {
      return this.getFroalaConfig([
        'customParagraphStyleTwo',
        'textColor',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'clearFormatting',
        'undo',
        'redo',
        'fontWeight',
        'backgroundColor',
        'formatOLSimple',
        'customList',
      ])
    }
  },
  methods: {
    showOption(...views) {
      return views.includes(this.viewMode)
    },
    getHexColors() {
      // Convert THEME_RGBA_COLOR to HEX format
      return Object.keys(this.colors).map((key) => {
        let r = this.colors[key]['color'][0]
        let g = this.colors[key]['color'][1]
        let b = this.colors[key]['color'][2]
        return rgbToHex(r, g, b)
      })
    },
    getFontFamily() {
      let fontFamily = {}
      this.availableFonts.forEach((font) => {
        fontFamily[`${font.familyName}, ${font.genericFamily}`] =
          font.familyName
      })
      return fontFamily
    },
    getFontSizes() {
      return FONT_SIZES.map((fontSize) => {
        return fontSize.value
      })
    },

    selectAction (value, computedProperty) {
      this[computedProperty] = value
    },
    getFroalaConfig (moreMiscButtons) {
      let hexColors = this.getHexColors()
      let fontFamily = this.getFontFamily()
      let fontSizes = this.getFontSizes()

      let styleFonts = {}
      let styleFontSize = {}

      Object.keys(this.typography).forEach((key) => {
        styleFonts[key] = this.typography[key]['font']
        styleFontSize[key] = this.typography[key]['size']
      })

      return {
        key: this.config?.FROALA_EDITOR_ID,
        toolbarBottom: true,
        toolbarStickyOffset: false,
        colorsText: hexColors,
        colorsBackground: hexColors,
        fontFamilySelection: true,
        fontFamily: fontFamily,
        fontSize: fontSizes,
        initOnClick: false,
        keepFormatOnDelete: true,
        pastePlain: true,
        pasteDeniedTags: ['div'],
        pasteDeniedAttrs: ['class', 'style'],
        paragraphMultipleStyles: false,
        placeholderText: this.$t(
          'editor.section.grid_item.edit.placeholder_text',
        ),
        paragraphFormatSelection: false,
        charCounterMax: false,
        charCounterCount: false,
        toolbarButtons: {
          moreMisc: {
            buttons: moreMiscButtons,
            buttonsVisible: 20,
          },
        },
      }
    }
  },
}
</script>

<style lang="scss">
  .pointer-events-none {
    pointer-events: none;
    opacity: 0.5;
  }
</style>
