<template>
  <div class="container-fluid full-height pt-3">
    <div class="row d-flex justify-content-between align-items-center px-3">
      <div class="col-md-4 row align-items-center">
        <div class="rounded item mr-3" style="width: 65px" />
        <div class="rounded item" style="width: 65px" />
      </div>
      <div class="col-md-4 d-flex align-items-center justify-content-center">
        <div class="rounded item py-2" style="width: 80px" />
      </div>
      <div class="col-md-4 d-flex align-items-end justify-content-end">
        <div class="rounded mr-2 item" style="width: 10px" />
        <div class="rounded mr-2 item" style="width: 10px" />
        <div class="rounded mr-2 item" style="width: 40px" />
        <div class="rounded item" style="width: 40px" />
      </div>
    </div>
    <div class="row mt-3 border-top justify-content-between align-items-start">
      <div class="col-1 mt-3">
        <div class="side-action">
          <div class="item" />
          <div class="item" />
        </div>
        <div class="side-action">
          <div class="item" />
          <div class="item" />
        </div>
        <div class="side-action">
          <div class="item" />
          <div class="item" />
        </div>
      </div>
      <div class="col-10 main-builder-skeleton">
        <div class="item position-relative">
          <content-placeholder class="anim" />
        </div>
        <div class="item position-relative">
          <content-placeholder class="anim" />
        </div>
        <div class="sections">
          <div>
            <div class="item position-relative">
              <content-placeholder class="anim" />
            </div>
            <div class="item position-relative">
              <content-placeholder class="anim" />
            </div>
            <div class="item position-relative">
              <content-placeholder class="anim" />
            </div>
            <div class="item position-relative">
              <content-placeholder class="anim" />
            </div>
          </div>

          <div>
            <div class="item position-relative">
              <content-placeholder class="anim" />
            </div>
            <div class="item position-relative">
              <content-placeholder class="anim" />
            </div>
            <div class="item position-relative">
              <content-placeholder class="anim" />
            </div>
            <div class="item position-relative">
              <content-placeholder class="anim" />
            </div>
          </div>

          <div>
            <div class="item position-relative">
              <content-placeholder class="anim" />
            </div>
            <div class="item position-relative">
              <content-placeholder class="anim" />
            </div>
            <div class="item position-relative">
              <content-placeholder class="anim" />
            </div>
            <div class="item position-relative">
              <content-placeholder class="anim" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-1 right-menu-options mt-1">
        <div class="item" />
        <div class="right-action">
          <div class="item" />
          <div class="item" />
        </div>
        <div class="right-action">
          <div class="item" />
          <div class="item" />
        </div>
        <div class="right-action">
          <div class="item" />
          <div class="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentPlaceholder } from '../../../../../storybook/components/molecules/ContentPlaceholder'
export default {
  name: 'EditorLoader',
  components: {
    'content-placeholder': ContentPlaceholder,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.rounded {
  border-radius: 16px;
}
.item {
  background: #e2e6ee;
  height: 10px;
}

.side-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 27px;
  & :nth-child(1) {
    border-radius: 50%;
    margin-bottom: 5px;
    width: 20px;
    height: 20px;
  }
  & :nth-child(2) {
    border-radius: 8px;
    width: 45px;
  }
}

.main-builder-skeleton {
  border-radius: 1px;
  border: 10px solid #e2e6ee;
  padding: 30px 30px !important;
  height: 90vh;
  & > div {
    margin-bottom: 25px;
    border-radius: 4px;
  }
  & > :nth-child(1) {
    width: 115px;
    height: 35px;
  }
  & > :nth-child(2) {
    width: 100%;
    height: 250px;
  }
}
.sections {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 40px;
  margin-top: 20px;
  & > div {
    width: 100% !important;
    height: unset !important;
    & > div {
      margin-bottom: 10px;
      width: 100%;
    }
    & > :nth-child(1) {
      width: 100%;
      height: 220px;
    }
    & > :nth-child(2) {
      width: 100%;
      height: 20px;
    }
    & > :nth-child(3) {
      width: 100%;
      height: 20px;
    }
    & > :nth-child(4) {
      width: 70%;
      height: 20px;
    }
  }
}

.right-menu-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  & > div:nth-child(1) {
    margin-top: 10px;
    width: 30px;
    height: 10px;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  & > .right-action {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    & > div:nth-child(1) {
      width: 30px;
      height: 10px;
      border-radius: 6px;
      margin-bottom: 15px;
    }
    & :nth-child(2) {
      border-radius: 50%;
      width: 100px;
      height: 100px;
    }
  }
  & > .right-action:nth-child(2) {
    & > div:nth-child(1) {
      width: 70px;
    }
  }
}
.anim {
  width: 100% !important;
  height: 100% !important;
  border-radius: 4px;
}
</style>
