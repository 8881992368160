import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'

import Vue from '../../lib/utils/vue'
import { CvtIcon } from './icon/icon'
import * as getters from '../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import { FRESHWORKS, LEFT_SIDEBAR_STANDARD } from '../constants'

Vue.use(VueEmotion)
  
let Spacing, FontFamily, Colors, Radii, textFontWeight, backgroundColor, textFontSize, color, elevation, orgName, radioSwitchConfig, switchColorConfig

const STATIC = {
  width: {
    xs: 24,
    sm: 32,
    md: 40,
    lg: 56,
    xl: 72
  },
  border: {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5
  },
  switchCoreWidth: {
    xs: 24,
    sm: 44,
    md: 60,
    lg: 88,
    xl: 100
  },
  switchCoreHeight: {
    xs: 16,
    sm: 24,
    md: 32,
    lg: 48,
    xl: 56
  },
  switchEnablerDimension: {
    xs: 14,
    sm: 22,
    md: 30,
    lg: 44,
    xl: 52
  },
  bigSwitchCoreHeight: {
    xs: 6,
    sm: 8,
    md: 10,
    lg: 12,
    xl: 16
  },
  bigSwitchTopPos: {
    xs: -4,
    sm: -8,
    md: -10,
    lg: -16,
    xl: -20
  },
  factor: {
    md: 7,
    lg: 8.2,
    xl: 9.2
  }
}

const UTIL = {
  getSwitchCoreWidth(props) {
    return STATIC.switchCoreWidth[props.size]
  },
  getSwitchCoreHeight(props) {
    return STATIC.switchCoreHeight[props.size]
  },
  getBigSwitchHeight(props) {
    return STATIC.switchEnablerDimension[props.size]
  },
  getBigSwitchCoreHeight(props) {
    return STATIC.bigSwitchCoreHeight[props.size]
  },
  getBigSwitchTopPos(props) {
    return STATIC.bigSwitchTopPos[props.size]
  },
  getSwitchEnablerDimension(props) {
    return STATIC.switchEnablerDimension[props.size]
  },
  getHeight(props) {
    return (
      (STATIC.width[props.size] - STATIC.border[props.size] * 3) / 2 +
      STATIC.border[props.size] * 2
    )
  },
  getBorder(props) {
    return STATIC.border[props.size]
  }
}

const getSwitchInput = () => styled('input')`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  margin: 0;
`

const getSwitchCore = () => styled('span')`
  margin: ${Spacing.SPACING_2};
  display: inline-block;
  position: relative;
  outline: none;

  ${(props) => {
    let styles = ``
    if(switchColorConfig.inActive &&  props.type === 'circular'){
      let { weight , style} = switchColorConfig.inActive.circular.bgColor;
      styles += `${backgroundColor({ themeing: style }, weight)}`;
    }else{
      styles += `${backgroundColor({ themeing: "dark" }, 200)}`;
    }
      
    if (props.type === 'circular')
      styles += `border-radius: ${UTIL.getHeight(props)}px;`
    else styles += `border-radius: ${Radii.RADIUS_PX_4}px;`
    styles += `width: ${UTIL.getSwitchCoreWidth(props)}px;`
    if (props.descriptionType === 'text' && props.showInside) {
      if (props.leftLabelWidth && props.rightLabelWidth) {
        let totalWidth = props.leftLabelWidth + props.rightLabelWidth
        if (props.size == 'xl')
          styles += `width: ${
            totalWidth + Spacing.SPACING_24 + Spacing.SPACING_4
          }px;`
        else styles += `width: ${totalWidth + Spacing.SPACING_24}px;`
      } else {
        styles += `width: ${
          STATIC.factor['md'] *
          (props.activeTextLength + props.inactiveTextLength + 4)
        }px;`
      }
    }
    styles += `height: ${UTIL.getSwitchCoreHeight(props)}px;`
    styles += `border: ${UTIL.getBorder(props)}px solid ${Colors.BASIC[200]};`

    if(switchColorConfig.inActive && props.type === 'circular'){
      if(switchColorConfig.inActive.circular.border.color){
        let { style, weight } = switchColorConfig.inActive.circular.border.color;
        styles += `border-color: ${Colors[style][weight]};`
      }else{
        styles += `border-color: transparent;`
      }
      
    }

    if(props.state && props.type === 'circular'){
      styles += `border-color: transparent;`;
      if(switchColorConfig.active){
        let { style, weight } = switchColorConfig.active.circular.bgColor;
        styles += `${backgroundColor({ themeing: style }, weight)}`;
      }else{
        styles += `${backgroundColor({ themeing: "primary" }, 400)}`;
      }
    }
    return styles;
  }}
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s;
  vertical-align: middle;
  ${(props) => {
    let styles = ``
    if (radioSwitchConfig && props.showInside) {
      radioSwitchConfig.removeElevation ? styles += `border: 0;` : ``
      props.disabled ? styles += `
      background-color: ${radioSwitchConfig.inactiveBg};` : ''
    }
    return styles;
  }}

  &:hover{
    ${(props) =>{
      if(switchColorConfig.hover){
        if(props.type === 'circular' && !props.disabled){
          let { style, weight } = switchColorConfig.hover.color;
          return `outline: 4px solid ${Colors[style][weight]};`
        }
      }
    }
  }}
  ${(props) => props.disabled && `opacity: 0.3;`}
  
  &:after {
    content: '';
    position: absolute;
    top: 0px;
    height: 100%;
    transition: all 0.3s;
    ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
    ${(props) => {
      let styles = ``

      if (props.type === 'circular')
        styles += `border-radius: ${Radii.RADIUS_PERCENT_50}%;`
      else styles += `border-radius: ${Radii.RADIUS_PX_4}px;`

      if (props.state) styles += `right: 0px;`
      else styles += `left: 0px;`

      styles += `width: ${
        UTIL.getSwitchEnablerDimension(props) - STATIC.border[props.size]
      }px;`
      if (props.state) {
        if (props.descriptionType === 'text' && props.showInside) {
          if (props.rightLabelWidth) {
            let rightWidth = props.rightLabelWidth
            styles += `width: ${rightWidth + Spacing.SPACING_8}px;`
          } else
            styles += `width: ${
              STATIC.factor['md'] * (props.activeTextLength + 1.4)
            }px;`
        }
      } else {
        if (props.descriptionType === 'text' && props.showInside) {
          if (props.leftLabelWidth) {
            let leftWidth = props.leftLabelWidth
            styles += `width: ${leftWidth + Spacing.SPACING_8}px;`
          } else
            styles += `width: ${
              STATIC.factor['md'] * (props.inactiveTextLength + 1.4)
            }px;`
        }
      }
      styles += elevation(props, 100)
      return styles
    }}
    ${(props) => {
      let styles = ``
      if (radioSwitchConfig && props.showInside) radioSwitchConfig.removeElevation ? styles += `display: none;` : ''
      return styles;
    }}
  }
  &.big-switch {
    border: none;
    height: ${(props) => UTIL.getBigSwitchCoreHeight(props)}px;
    &:after {
      ${(props) => (props.state ? backgroundColor(props, 500) : '')}
      ${(props) => elevation(props, 300)}
      height: ${(props) => UTIL.getBigSwitchHeight(props)}px;
      width: ${(props) => UTIL.getBigSwitchHeight(props)}px;
      top: ${(props) => UTIL.getBigSwitchTopPos(props)}px;
    }
  }
`

const getSwitchLabel = () => styled('span')`
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  user-select: none;
  ${(props) => {
    let styles = ``

    styles += `display: ${props.showInside ? 'flex' : 'inline-block'};`
    if (props.descriptionType === 'text') {
      styles += textFontSize('sm')
    } else if (props.descriptionType === 'icon') {
      if (props.size === 'xl') styles += `font-size: 32px;`
      else if (props.size === 'lg') styles += `font-size: 24px;`
      else styles += textFontSize('lg')
    }
    return styles
  }}
  
  ${textFontWeight('REGULAR')}  
  font-family: ${FontFamily.DEFAULT};
  cursor: pointer;
  vertical-align: middle;
  color: ${Colors.PRIMARY[400]};
`

const getSwitchLabelLeft = (SwitchLabel) => styled(SwitchLabel)`
  ${(props) => {
    let styles = ``
    if (!props.showInside) styles += `margin-right: ${Spacing.SPACING_8}px;`
    if (!props.showInside || (props.showInside && !props.state)) {
      if (!props.showInside && props.mode === 'DARK') {
        styles += color({ themeing: 'dark' }, 200)
      } else {
        styles += color({ themeing: 'dark' }, 500)
      }
    } else {
      if (props.state && props.themeing === 'grey') {
        styles += color({ themeing: 'dark' }, 500)
      } else {
        styles += color({ themeing: 'dark' }, 200)
      }
    }
    if (props.showInside)
      styles += `position: absolute; left: 0px; width: 50%; z-index: 1; height: 100%;`

    return styles
  }}

  ${(props) => {
    let styles = ``
    if (radioSwitchConfig && props.showInside) {
      styles += `
      background-color: ${radioSwitchConfig.inactiveBg}; 
      border: 0.5px solid ${radioSwitchConfig.border};
      border-radius: ${radioSwitchConfig.radius};
      &:hover {
        background-color: ${radioSwitchConfig.hoverBg}
      }`
      props.active ? styles +=
      `background-color: ${radioSwitchConfig.activeBg}; 
      color: ${radioSwitchConfig.activeTextColor};
      ` :
      ``
      props.disabled ? styles += `background-color: ${radioSwitchConfig.disabledBg}; color: ${radioSwitchConfig.border};` : ''
    }
    return styles;
  }}

  & > span {
    position: absolute;
    left: ${Spacing.SPACING_4}px;
  }
`

const getSwitchLabelRight = (SwitchLabel) => styled(SwitchLabel)`
  ${(props) => {
    let styles = ``
    if (!props.showInside) styles += `margin-left: ${Spacing.SPACING_8}px;`
    if (
      !props.showInside ||
      (props.showInside && props.state) ||
      (props.showInside && !props.state && props.descriptionType === 'text')
    ) {
      if (!props.showInside && props.mode === 'DARK') {
        styles += color({ themeing: 'dark' }, 200)
      } else {
        styles += color({ themeing: 'dark' }, 500)
      }
    } else {
      styles += color({ themeing: 'dark' }, 300)
    }
    if (props.showInside)
      styles += `position: absolute; right: 0px; width: 50%; z-index: 1; height: 100%;`
    return styles
  }}

  ${(props) => {
    let styles = ``
    if (radioSwitchConfig && props.showInside) {
      styles += `
      background-color: ${radioSwitchConfig.inactiveBg};
      border: 0.5px solid ${radioSwitchConfig.border};
      border-radius: ${radioSwitchConfig.radius};
      &:hover {
        background-color: ${radioSwitchConfig.hoverBg}
      }`
      props.active ? styles +=
      `background-color: ${radioSwitchConfig.activeBg}; 
      color: ${radioSwitchConfig.activeTextColor};
      border: 1px solid ${radioSwitchConfig.border};` : 
      ``
      props.disabled ? styles += `background-color: ${radioSwitchConfig.disabledBg}; color: ${radioSwitchConfig.border};` : ''
    }
    return styles;
  }}

  & > span {
    position: absolute;
    right: ${Spacing.SPACING_4}px;
  }
`

const getInsideLabelsWrapDiv = () => styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
`

const getSwitchIcon = () => styled(CvtIcon)`
  ${(props) => `font-size: ${UTIL.getHeight(props) / 2} `}
`

const getInnerTextSpan = () => styled('span')`
  white-space: nowrap;
  padding: 0 ${Spacing.SPACING_8}px;
  user-select: none;
`

const getSwitchWrapper = () => styled('div')``

export default {
  name: 'CvtSwitch',
  components: {},
  props: {
    size: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'LIGHT'
    },
    color: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'rounded'
    },
    position: {
      type: String,
      default: 'outer'
    },
    showDescription: {
      type: Boolean,
      default: false
    },
    descriptionType: {
      type: String,
      default: 'text'
    },
    activeText: {
      type: String,
      default: 'Active'
    },
    inactiveText: {
      type: String,
      default: 'Inactive'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    initialState: {
      type: Boolean,
      default: false
    },
    leftSideLabel: {
      type: Boolean,
      default: false
    },
    showBigSwitch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      state: this.initialState,
      rightLabelWidth: 0,
      leftLabelWidth: 0
    }
  },
  watch: {
    initialState() {
      this.state = this.initialState
    }
  },
  mounted() {
    this.updateLabelWidth()
  },
  updated() {
    this.updateLabelWidth()
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textColor: getters.GLOBAL_STYLE_COLOR,
      elevation: getters.GLOBAL_STYLE_ELEVATION
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      OrgName: ({ globalTheme }) => globalTheme.OrgName ? globalTheme.OrgName : LEFT_SIDEBAR_STANDARD,
      radioSwitchConfig: ({ globalTheme }) =>
        globalTheme.radioSwitchConfig !== undefined
          ? globalTheme.radioSwitchConfig
          : false,
      switchColorConfig: ({ globalTheme }) => globalTheme.switchColorConfig ? globalTheme.switchColorConfig : "",
    }),
    showOutside() {
      if (this.leftSideLabel) {
        return true
      }

      if (
        this.size === 'md' ||
        this.size === 'lg' ||
        this.size === 'xl' ||
        this.descriptionType === 'default'
      ) {
        if (!(this.type === 'circular' && this.descriptionType === 'text')) {
          return false
        }
      }
      return true
    },
    showInside() {
      if (this.leftSideLabel) {
        return false
      }

      if (
        this.size === 'xs' ||
        this.size === 'sm' ||
        this.descriptionType === 'default' ||
        (this.type === 'circular' && this.descriptionType === 'text')
      ) {
        return false
      }
      return true
    },
    activeItem () {
      return this.state ? this.activeText : this.inactiveText 
    }
  },
  methods: {
    onClick(e) {
      if (!this.disabled) this.state = !this.state
      let currentSelected = this.state ? this.activeText : this.inactiveText
      this.$emit('switchToggle', currentSelected, e)
    },
    updateLabelWidth() {
      this.$nextTick(function () {
        if (this.$refs.innerTextRight) {
          this.rightLabelWidth = this.$refs.innerTextRight.getBoundingClientRect().width
        }

        if (this.$refs.innerTextLeft) {
          this.leftLabelWidth = this.$refs.innerTextLeft.getBoundingClientRect().width
        }
      })
    }
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    FontFamily = this.FontFamily
    Colors = this.Colors

    textFontWeight = this.textFontWeight
    color = this.textColor
    elevation = this.elevation
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    radioSwitchConfig = this.radioSwitchConfig
    switchColorConfig = this.switchColorConfig
  },
  render: function (h) {
    const SwitchWrapper = getSwitchWrapper()
    const SwitchInput = getSwitchInput()
    const SwitchCore = getSwitchCore()
    const SwitchLabel = getSwitchLabel()
    const SwitchLabelLeft = getSwitchLabelLeft(SwitchLabel)
    const SwitchLabelRight = getSwitchLabelRight(SwitchLabel)
    const InsideLabelsWrapDiv = getInsideLabelsWrapDiv()
    const SwitchIcon = getSwitchIcon()
    const InnerTextSpan = getInnerTextSpan()

    return (
      <div class='vtc-switch'>
        <fieldset disabled={this.disabled}>
          <SwitchWrapper onClick={this.onClick}>
            <SwitchInput
              type='checkbox'
              true-value='true'
              disabled={this.disabled}
            ></SwitchInput>
            {this.showOutside && this.leftSideLabel && (
              <SwitchLabelLeft
                state={this.state}
                active={this.activeItem === this.inactiveText}
                disabled={this.disabled}
                showInside={this.showInside}
                size={this.size}
                activeTextLength={this.activeText.length}
                inactiveTextLength={this.inactiveText.length}
                descriptionType={this.descriptionType}
                mode={this.mode}
              >
                {this.descriptionType === 'text' ? (
                  this.state ? (
                    this.activeText
                  ) : (
                    this.inactiveText
                  )
                ) : this.descriptionType === 'icon' ? (
                  this.state ? (
                    <SwitchIcon icon='check-circle' />
                  ) : (
                    <SwitchIcon icon='times-circle' />
                  )
                ) : (
                  ''
                )}
              </SwitchLabelLeft>
            )}
            {this.showOutside && !this.leftSideLabel && (
              <SwitchLabelLeft
                state={this.state}
                active={this.activeItem === this.inactiveText}
                disabled={this.disabled}
                showInside={this.showInside}
                size={this.size}
                activeTextLength={this.activeText.length}
                inactiveTextLength={this.inactiveText.length}
                descriptionType={this.descriptionType}
                mode={this.mode}
              >
                {this.descriptionType === 'text' ? (
                  this.inactiveText
                ) : (
                  <SwitchIcon icon='times-circle' />
                )}
              </SwitchLabelLeft>
            )}
            <SwitchCore
              state={this.state}
              type={this.type}
              descriptionType={this.descriptionType}
              showInside={this.showInside}
              identity='switchcore'
              size={this.size}
              mode={this.mode}
              themeing={this.color}
              activeTextLength={this.activeText.length}
              inactiveTextLength={this.inactiveText.length}
              class={{ 'big-switch': this.showBigSwitch }}
              rightLabelWidth={this.rightLabelWidth}
              leftLabelWidth={this.leftLabelWidth}
              orgName={this.OrgName}
              disabled={this.disabled}
            >
              {this.showInside && (
                <InsideLabelsWrapDiv>
                  <SwitchLabelLeft
                    state={this.state}
                    active={this.activeItem == this.inactiveText}
                    disabled={this.disabled}
                    showInside={this.showInside}
                    descriptionType={this.descriptionType}
                    type={this.type}
                    size={this.size}
                    activeTextLength={this.activeText.length}
                    inactiveTextLength={this.inactiveText.length}
                    mode={this.mode}
                    themeing={this.color}
                  >
                    {this.descriptionType === 'text' ? (
                      <InnerTextSpan ref='innerTextLeft' id='innerTextLeft'>
                        {this.inactiveText}
                      </InnerTextSpan>
                    ) : (
                      <SwitchIcon icon='times-circle' />
                    )}
                  </SwitchLabelLeft>

                  <SwitchLabelRight
                    state={this.state}
                    active={this.activeItem === this.activeText}
                    disabled={this.disabled}
                    showInside={this.showInside}
                    descriptionType={this.descriptionType}
                    type={this.type}
                    size={this.size}
                    activeTextLength={this.activeText.length}
                    inactiveTextLength={this.inactiveText.length}
                    mode={this.mode}
                  >
                    {this.descriptionType === 'text' ? (
                      <InnerTextSpan ref='innerTextRight' id='innerTextRight'>
                        {this.activeText}
                      </InnerTextSpan>
                    ) : (
                      <SwitchIcon icon='check-circle' />
                    )}
                  </SwitchLabelRight>
                </InsideLabelsWrapDiv>
              )}
            </SwitchCore>
            {this.showOutside && !this.leftSideLabel && (
              <SwitchLabelRight
                state={this.state}
                active={this.activeItem === this.activeText}
                disabled={this.disabled}
                showInside={this.showInside}
                descriptionType={this.descriptionType}
                size={this.size}
                activeTextLength={this.activeText.length}
                inactiveTextLength={this.inactiveText.length}
                mode={this.mode}
              >
                {this.descriptionType === 'text' ? (
                  this.activeText
                ) : (
                  <SwitchIcon icon='check-circle' />
                )}
              </SwitchLabelRight>
            )}
          </SwitchWrapper>
        </fieldset>
      </div>
    )
  }
}
