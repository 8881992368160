import Vue from '../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import { CvtIcon } from './icon/icon'
import CvtButton from './Button'
import * as getters from '../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'

Vue.use(VueEmotion)

let Spacing, Radii, Border, Colors, AlertSeparator, AlertFontSize, AlertConfig
let textFontWeight, borderColor, backgroundColor, textFontSize, textColor

const UTIL = {
  shape(shape) {
    switch (shape) {
      case 'pill':
        return `border-radius: ${Radii.RADIUS_PERCENT_50}px !important;`
      case 'circle':
        return `border-radius: ${Radii.RADIUS_PERCENT_50}% !important;`
      default:
        return `border-radius: 0px;`
    }
  },
  defaultBackgroundColor(props){
    switch(props.alertTheme){
      case "light":
        return backgroundColor({ themeing: 'dark' }, 'WHITE')
      case "dark":
        return backgroundColor({ themeing: 'dark' }, 700)
      default:
        return backgroundColor(props, 100)
    }
  },
  defaultTextColor(props){
    switch(props.alertTheme){
      case "light":
        return textColor({ themeing: "light" }, 700)
      case "dark":
        return textColor({ themeing: "dark" }, 100)
      default:
        return textColor(props, 700)
    }
  },
  customBackgroundColor(props){
    let { style, weight } = AlertConfig.lightTheme.bgColor;
    let { style: darkStyle, weight: darkWeight } = AlertConfig.darkTheme.bgColor;
    let { style: defaultStyle, weight: defaultWeight } = AlertConfig.default.bgColor;
    
    switch(props.alertTheme){
      case "light":
        return backgroundColor({ themeing: style }, weight)
      case "dark":
        return backgroundColor({ themeing: darkStyle }, darkWeight)
      default:
        return backgroundColor({ themeing: defaultStyle }, defaultWeight)
    }
  },
  customTextColor(props){
    let { style, weight } = AlertConfig.lightTheme.textColor;
    let { style: darkStyle, weight: darkWeight } = AlertConfig.darkTheme.textColor;
    let { style: defaultStyle, weight: defaultWeight } = AlertConfig.default.textColor;
    
    switch(props.alertTheme){
      case "light":
        return textColor({ themeing: style }, weight)
      case "dark":
        return textColor({ themeing: darkStyle }, darkWeight)
      default:
        return textColor({ themeing: defaultStyle }, defaultWeight)
    }
  },
  alertBorderColor(props){
    let { style, weight } = AlertConfig.lightTheme.borderColor;
    let { style: darkStyle, weight: darkWeight } = AlertConfig.darkTheme.borderColor;
    let { style: defaultStyle, weight: defaultWeight } = AlertConfig.default.borderColor;
    
    switch(props.alertTheme){
      case "light":
        return borderColor({ themeing: style }, weight)
      case "dark":
        return borderColor({ themeing: darkStyle }, darkWeight)
      default:
        return borderColor({ themeing: defaultStyle }, defaultWeight)
    }
  },
  leftBorderColor(props){
    if(AlertConfig?.borderLeft){
      let { style, weight } = AlertConfig.lightTheme.borderColor;
      let { style: darkStyle, weight: darkWeight } = AlertConfig.darkTheme.borderColor;
      let { style: defaultStyle, weight: defaultWeight } = AlertConfig.default.borderColor;
      
      switch(props.alertTheme){
        case "light":
          return Colors[style.toUpperCase()][weight]
        case "dark":
          return Colors[darkStyle.toUpperCase()][darkWeight]
        default:
          return Colors[defaultStyle.toUpperCase()][defaultWeight]
      }
    }else{
      return "transparent";
    }
    
  },
  defaultLeftBorderColor(props){
      return Colors[props.themeing.toUpperCase()][300]
  }
}
const getLeftBorderColor = (props) =>{
  return AlertConfig ? UTIL.leftBorderColor(props) : UTIL.defaultLeftBorderColor(props)
}
const getShape = () =>{
  if(AlertConfig?.icon){
    let { shape } = AlertConfig.icon
    return UTIL.shape(shape);
  }
}
const getIconColor = (props) =>{
  if(AlertConfig?.icon?.iconColor){
    let { style, weight } = AlertConfig.icon.iconColor;
    return textColor({ themeing: style }, weight);
  }else{
    return textColor(props, 400)
  }
}
const getIconBgColor = () =>{
  if(AlertConfig?.icon?.bgColor){
    let { style, weight } = AlertConfig.icon.bgColor;
    return backgroundColor({ themeing: style }, weight);
  }else{
    return `background-color: transparent`;
  }
}
const getIconSize = () =>{
  let size = AlertConfig?.icon?.size ? AlertConfig.icon.size : 'xl';
  return textFontSize(size);
}

const getBackgroundColor = (props) =>{
  return (AlertConfig ? UTIL.customBackgroundColor(props) : UTIL.defaultBackgroundColor(props))
}

const getBorderColor = (props) =>{
  return (AlertConfig ? UTIL.alertBorderColor(props) : borderColor(props, 300))
}
const getTextColor = (props) =>{
  return AlertConfig ? UTIL.customTextColor(props) : UTIL.defaultTextColor(props)
}

const getAlertBox = () => {
  return styled('div')`
    display: flex;
    flex-direction: row;
    position: relative;
    height: auto;
    min-height: 54px;
    align-items: flex-start;
    padding: ${Spacing.SPACING_16}px;
    
    border: ${Border.SIZE_1}px solid transparent;
    border-radius: ${Radii.RADIUS_PX_8}px;
    ${(props) =>
      getBackgroundColor(props) +
      getBorderColor(props)
    }
    ${(props) => props.verticalAlignContent ? 'align-items: center;' : ''}
    ${(props) => props.customMargin !== '' ? `margin: ${props.customMargin};` : `margin-bottom: ${Spacing.SPACING_32}px;`}
  `
}
const getWarningIcon = () => {
  return styled(CvtIcon)`
  display: flex;
  justify-content:center;
  align-items:center;
  width: 20px;
  height: 20px;
    ${(props) => {
      let styles = getIconSize()
      styles += getIconColor(props)
      styles += getIconBgColor()
      styles += getShape()
      return styles;
    }}
  `
}
const getCloseButtonWrapper = () => {
  return styled('div')`
    color: gray;
    cursor: pointer;
  `
}
const getAlertMsgWrapper = () => {
  return styled('div')`
    padding: 0 ${Spacing.SPACING_18}px;
    flex: 1;
    align-self: center;

    ${(props) =>
      AlertSeparator
        ? `
        border-left: 1px solid ${getLeftBorderColor(props)};
        margin-left: ${Spacing.SPACING_8}px;
      `
        : ``}
  `
}
const getAlertMsg = () => {
  return styled('p')`
    ${textFontWeight('REGULAR')}
    margin: 0;
    ${(props) =>
      textFontSize(AlertFontSize) +
      getTextColor(props) 
    }  
  `
}
const getActionAnchor = () => {
  return styled('a')`
    ${textFontSize('xs')}
    cursor: pointer;
    padding: 5px 0 0;
    ${(props) => {
      let styles = ``
      styles += textColor(props, 500)
      styles += `
      &:hover {
        ${textColor(props, 500)}
      }
    `
      return styles
    }}
  `
}
export default {
  name: 'CvtAlert',
  components: {
    CvtIcon
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      AlertSeparator: ({ globalTheme }) =>
        globalTheme.AlertSeparator !== undefined
          ? globalTheme.AlertSeparator
          : false,
      AlertFontSize: ({ globalTheme }) =>
        globalTheme.AlertFontSize !== undefined
          ? globalTheme.AlertFontSize
          : 'md',
      alertConfigState: ({ globalTheme }) => globalTheme.alertConfig ?  globalTheme.alertConfig : ""
    })
  },
  props: {
    alertTheme: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    closeIcon: {
      type: Boolean,
      default: false
    },
    closeAction: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: 'This is description!'
    },
    icon: {
      type: String,
      default: 'check'
    },
    verticalAlignContent: {
      type: Boolean,
      default: false
    },
    customMargin: {
      type: String,
      default: ''
    },
    alertConfig: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      show: true
    }
  },
  methods: {
    close() {
      this.show = false
    }
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    Colors = this.Colors
    AlertSeparator = this.AlertSeparator
    AlertFontSize = this.AlertFontSize
    AlertConfig = this.alertConfig ? this.alertConfig : this.alertConfigState

    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    textColor = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
  },
  render: function (h) {
    const AlertBox = getAlertBox()
    const WarningIcon = getWarningIcon()
    const CloseButtonWrapper = getCloseButtonWrapper()
    const AlertMsgWrapper = getAlertMsgWrapper()
    const AlertMsg = getAlertMsg()
    const ActionAnchor = getActionAnchor()
    return (
      <div>
        {this.show && (
          <AlertBox
            themeing={this.color}
            alertTheme={this.alertTheme}
            role='alert'
            verticalAlignContent={this.verticalAlignContent}
            customMargin={this.customMargin}
          >
            <WarningIcon
              themeing={this.color}
              icon={this.icon}
              hover
            ></WarningIcon>
            <AlertMsgWrapper themeing={this.color}>
              <AlertMsg themeing={this.color} alertTheme={this.alertTheme}>
                {this.$slots.default}
              </AlertMsg>
            </AlertMsgWrapper>

            {this.closeIcon && (
              <CloseButtonWrapper onClick={this.close}>
                <CvtButton
                  shape='pill'
                  size='xs'
                  icon='times'
                  disabled={false}
                  actionIcon={true}
                  colorOfActionIcon={
                    this.alertTheme === 'dark' ? '' : this.color
                  }
                  modeOfActionIcon={
                    this.alertTheme === 'dark' ? 'light' : 'dark'
                  }
                ></CvtButton>
              </CloseButtonWrapper>
            )}
            {this.closeAction && (
              <ActionAnchor themeing={this.color} onClick={this.close}>
                {this.$slots.actionText}
              </ActionAnchor>
            )}
          </AlertBox>
        )}
      </div>
    )
  }
}
