
<div class="container-fluid full-height pt-3">
  <div class="row d-flex justify-content-between align-items-center px-3">
    <div class="col-md-4 row align-items-center">
      <div class="rounded item mr-3" style="width: 65px" />
      <div class="rounded item" style="width: 65px" />
    </div>
    <div class="col-md-4 d-flex align-items-center justify-content-center">
      <div class="rounded item py-2" style="width: 80px" />
    </div>
    <div class="col-md-4 d-flex align-items-end justify-content-end">
      <div class="rounded mr-2 item" style="width: 10px" />
      <div class="rounded mr-2 item" style="width: 10px" />
      <div class="rounded mr-2 item" style="width: 40px" />
      <div class="rounded item" style="width: 40px" />
    </div>
  </div>
  <div class="row mt-3 border-top justify-content-between align-items-start">
    <div class="col-1 mt-3">
      <div class="side-action">
        <div class="item" />
        <div class="item" />
      </div>
      <div class="side-action">
        <div class="item" />
        <div class="item" />
      </div>
      <div class="side-action">
        <div class="item" />
        <div class="item" />
      </div>
    </div>
    <div class="col-10 main-builder-skeleton">
      <div class="item position-relative">
        <content-placeholder class="anim" />
      </div>
      <div class="item position-relative">
        <content-placeholder class="anim" />
      </div>
      <div class="sections">
        <div>
          <div class="item position-relative">
            <content-placeholder class="anim" />
          </div>
          <div class="item position-relative">
            <content-placeholder class="anim" />
          </div>
          <div class="item position-relative">
            <content-placeholder class="anim" />
          </div>
          <div class="item position-relative">
            <content-placeholder class="anim" />
          </div>
        </div>

        <div>
          <div class="item position-relative">
            <content-placeholder class="anim" />
          </div>
          <div class="item position-relative">
            <content-placeholder class="anim" />
          </div>
          <div class="item position-relative">
            <content-placeholder class="anim" />
          </div>
          <div class="item position-relative">
            <content-placeholder class="anim" />
          </div>
        </div>

        <div>
          <div class="item position-relative">
            <content-placeholder class="anim" />
          </div>
          <div class="item position-relative">
            <content-placeholder class="anim" />
          </div>
          <div class="item position-relative">
            <content-placeholder class="anim" />
          </div>
          <div class="item position-relative">
            <content-placeholder class="anim" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-1 right-menu-options mt-1">
      <div class="item" />
      <div class="right-action">
        <div class="item" />
        <div class="item" />
      </div>
      <div class="right-action">
        <div class="item" />
        <div class="item" />
      </div>
      <div class="right-action">
        <div class="item" />
        <div class="item" />
      </div>
    </div>
  </div>
</div>
