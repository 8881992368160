import loadDoppler from '../../lib/loadDoppler'
import loadZendesk from '../../lib/loadZendesk'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

function ensure_no_forward_slash(url?: string) {
  if (url && url.endsWith('/')) {
    return url.replace(/.$/, '')
  }
  return url
}
const chatSetting = {
  webWidget: {
    zIndex: 8888,
    offset: {
      horizontal: '25px',
      vertical: '55px',
      mobile: {
        horizontal: '-7px',
        vertical: '56px',
      },
    },
    launcher: {
      chatLabel: {
        '*': 'Chat',
      },
    },
    chat: {
      title: {
        '*': 'Customer Support Chat',
        'en-US': 'Customer Support Chat',
        es: 'Atención al Cliente',
      },
      offlineForm: {
        greeting: {
          '*': 'How can we help you? Leave us a message and we’ll contact you soon.',
          'en-US':
            'How can we help you? Leave us a message and we’ll contact you soon.',
          es: '¿En qué podemos ayudarte? Déjanos un mensaje y nos contactaremos pronto.',
        },
      },
      prechatForm: {
        greeting: {
          '*': 'Hi! We’re here to help you. Tell us about your questions or request.',
          'en-US':
            'Hi! We’re here to help you. Tell us about your questions or request.',
          es: '¡Hola! Estamos aquí para ayudarte. Cuéntanos sobre tu consulta o solicitud.',
        },
      },
    },
  },
}

function loadInstallation(config: any) {
  const script = document.createElement('script')

  const DOPPLER_LEGACY_BASE_URL = ensure_no_forward_slash(
    config.DOPPLER_LEGACY_BASE_URL,
  )
  const DOPPLER_AUTH_URL = ensure_no_forward_slash(config.DOPPLER_AUTH_URL)

  const callLoadLoad = (lang: string) => {
    loadZendesk(config.CUSTOM_ZENDESK_KEY, lang, chatSetting)
  }

  const assetManifestConfig = (): {
    manifestURL: string
  } => {
    const host = window.location.host
    if (host === 'production.builder.convrrt.com') {
      return {
        manifestURL:
          'https://cdn.fromdoppler.com/doppler-style-guide/asset-manifest-V1.json',
      }
    }
    return {
      manifestURL:
        'https://cdn.fromdoppler.com/doppler-style-guide/asset-manifest-INT.json',
    }
  }

  script.type = 'text/javascript'

  script.textContent = `
   window["style-guide-configuration"] = {
     initFonts: true,
   };
   assetServices.load({
     manifestURL: "${assetManifestConfig().manifestURL}",
   });

   window["doppler-session-mfe-configuration"] = {
     dopplerLegacyBaseUrl: "${DOPPLER_LEGACY_BASE_URL}",
     useDummies: false,
   };
   assetServices.load({
     manifestURL:
       "https://cdn.fromdoppler.com/doppler-session-mfe/asset-manifest-v1.json",
   });

   window["doppler-menu-mfe-configuration"] = {
     dopplerMenuElementId: "doppler-menu-mfe",
     dopplerLegacyBaseUrl: "${DOPPLER_LEGACY_BASE_URL}",
     onStatusUpdate: (status) => {
       if (status === "authenticated") {
         // Only show the page content after Menu is ready
         document
           .getElementsByClassName("dp-hidden-page")[0]
           ?.classList.add("dp-show-page");
       } else if (status === "non-authenticated") {
         // When the status change to non-authenticated, redirect to Login page
         if (window.location.pathname !== '/admin/dashboard') {
            window.top.location = "${DOPPLER_AUTH_URL}/login?redirect=${
    window.location.href
  }"
         }
       }
     },
     beamerId: "wCWRrvwa50706",
   };
   assetServices.load({
     manifestURL:
       "https://cdn.fromdoppler.com/doppler-menu-mfe/asset-manifest-v1.json",
   });

   window.addEventListener("doppler-session-state-update", () => {
      if (window.dopplerSessionState && window.dopplerSessionState.rawDopplerUserData?.user?.lang) {
        localStorage.setItem("languageOverride", window.dopplerSessionState.rawDopplerUserData.user.lang)
        "${callLoadLoad('en')}"
        if (window.location.pathname === '/admin/dashboard') {
          window.displayDopplerNavBar(false)
        }
      }

     if (window.dopplerSessionState?.status === "non-authenticated") {
      if (window.location.pathname !== '/admin/dashboard') {
        window.top.location = "${DOPPLER_AUTH_URL}/login?redirect=${
    window.location.href
  }"
      }
     }
   });

   window.addEventListener("DOMContentLoaded", () => {
     console.log(window.dopplerSessionState);
   });
 `

  // Define your HTML content (already provided)
  const htmlToAppend = `
  <div class="dp-library">
      <div id="doppler-menu-mfe"></div>
    </div>
  `

  // Check if the element with the desired ID already exists
  const existingElement = document.getElementById('doppler-menu-mfe')

  if (!existingElement) {
    const container = document.createElement('div')
    container.innerHTML = htmlToAppend
    document.body.prepend(container)
  }

  // Append the script element to the document head
  document.head.appendChild(script)
}

export default {
  state: {
    loaded: false,
  },
  getters: {},
  mutations: {
    [mutations.DOPPLER_LOADED](state, loaded) {
      state.loaded = loaded
    },
  },
  actions: {
    async [actions.DOPPLER_BOOT]({ state, rootGetters, commit }) {
      if (state.loaded) {
        return
      }

      const config = rootGetters[getters.AUTH_GET_USER_CONFIG]

      if (!config.DOPPLER_NAV_MENU_ENABLED) {
        return
      }

      await loadDoppler()
      loadInstallation(config)
      commit(mutations.DOPPLER_LOADED, true)
    },
  },
}
