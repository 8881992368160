
<cvt-dialog
  v-if="!AltPopupManager"
  size="lg"
  bg-color="#fff"
  :show="show"
  :show-close="false"
  :height-auto="true"
  :show-header="false"
  :show-footer="false"
>
  <div class="row mb-4 mt-4">
    <div class="col-md-10 text-center mx-auto">
      <h1 class="setup-title" :style="titleStyle">
        {{ titleMessage }}
      </h1>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-md-10 text-center mx-auto">
      <h5 class="setup-title-desc" :style="titleStyle"> {{ titleDescription }}</h5>
    </div>
  </div>

  <div v-if="canEditTemplateName" class="row mb-2">
    <div class="col-md-10 mx-auto">
      <cvt-input
        id="templateNameId"
        :value="siteName"
        type="text"
        :maxlength="150"
        name="name"
        :label="$t('settings.page.label')"
        :clearable="false"
        :validation-status="nameInvalid ? 'invalid' : 'valid'"
        :valid-message="$t('settings.page.validation')"
        :invalid-message="$t(invalidFieldMsg)"
        @input="(e) => onChangeSiteName(e)"
      />
      <div v-show="nameInvalid" class="invalid-feedback">
        {{ $t('setup.template.name') }}
      </div>
    </div>
  </div>

  <div  v-if="canUseBrandPreference">
  <div class="row mb-4">
    <div class="col-md-10 mx-auto">

      <cvt-select
      id="templatePreference"
    mode="dropdown"
    :label="$t('settings.page.preferences')"
    :value="preference"
    :dropdown-menu-styles="{position: 'relative'}"
    @input="(value) => choosePreference(value)"
  >
  <option disabled value="">{{ $t('settings.page.preference_select') }}</option>
    <option v-for="pr in preferences" :key="pr" :value="pr" v-text="pr" />
  </cvt-select>
    </div>
  </div>
  </div>

  <div class="row mb-4">
    <div  v-if="canEditTemplateName" class="mx-auto">
      <cvt-button
        size="md"
        color="primary"
        :text="$t('setup.template.btn_text')"
        theme="light"
        :outlined="false"
        :full-width="true"
        :disabled="nameInvalid"
        :loading="loading"
        @click="() => continueHandle(false)"
      />
    </div>

    <div  v-if="brandPreferenceEnabled && !preferences.length" class="mx-auto">
      <cvt-button
        size="md"
        color="primary"
        :text="$t('settings.page.create_new_preference')"
        theme="light"
        :outlined="false"
        :full-width="true"
        @click="createNewPreference"
      />
    </div>
  </div>
</cvt-dialog>
<cvt-dialog
  v-else
  bg-color="#ffffff"
  size="sm"
  :show="show"
  :show-close="false"
  :height-auto="true"
  :show-header="false"
  :show-footer="false"
>
  <div class="row mb-2 mt-4">
    <div class="col-md-12">
      <h5 class="setup-title" :style="titleStyle">
        {{ $t('setup.message.title') }}
      </h5>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-md-12">
      <cvt-input
        id="templateNameId"
        :value="siteName"
        type="text"
        :maxlength="150"
        name="name"
        :label="$t('settings.page.label')"
        :clearable="false"
        :validation-status="nameInvalid ? 'invalid' : 'valid'"
        :valid-message="$t('settings.page.validation')"
        :invalid-message="$t(invalidFieldMsg)"
        @input="(e) => onChangeSiteName(e)"
      />
      <div v-show="nameInvalid" class="invalid-feedback">
        {{ $t('setup.template.name') }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="ml-auto mr-3">
      <cvt-button
        size="sm"
        color=""
        :text="$t('setup.template.btn_text')"
        theme="light"
        special-style="dark"
        :outlined="true"
        :disabled="nameInvalid"
        :loading="loading"
        :full-width="true"
        @click="() => continueHandle(false)"
      />
    </div>
  </div>
</cvt-dialog>
