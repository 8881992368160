import BFormFileMixin from './mixins/form-file'
import UploadInputMixin from './mixins/upload-inputs'
import BFileList from './FileList'
import idMixin from '../../../lib/mixins/id'
import normalizeSlotMixin from '../../../lib/mixins/normalize-slot'
import ItemBaseMixing from '../list/mixins/item-base'
import { getComponentConfig } from '../../../lib/utils/config'
import CvtButton from '../Button'
import { CvtIcon } from '../icon/icon'
import { isFile } from '../../../lib/utils/inspect'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import Vue from '../../../lib/utils/vue'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'

let Spacing, Radii, Border, BorderStyle
let borderColor, color, textFontSize

Vue.use(VueEmotion)

const getCvtUpload = () => styled('div')`
  padding: ${Spacing.SPACING_16}px;
  border-radius: ${Radii.RADIUS_PX_8}px;
  border: ${Border.SIZE_1}px ${BorderStyle.SOLID};
  ${borderColor({ themeing: 'dark' }, 200)}
  position: relative;

  &.disabled {
    opacity: 0.5;
    user-select: none;

    * {
      pointer-events: none;
    }
  }
`

const getCvtUploadDropper = () => styled('div')`
  height: 30vh;
  border: ${Border.SIZE_1}px ${BorderStyle.DASHED};
  ${borderColor({ themeing: 'dark' }, 300)}
  border-radius: ${Radii.RADIUS_PX_4}px;
  position: relative;
  ${color({ themeing: 'dark' }, 300)}
  ${textFontSize('md')}

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:hover,
  &:focus {
    border: ${Border.SIZE_1}px ${BorderStyle.DASHED};
    ${borderColor({ themeing: 'primary' }, 500)}
  }
`

const getCvtDropperText = () => styled('div')`
  position: relative;
  z-index: 1;

  ${color({ themeing: 'dark' }, 500)}
  a {
    ${color({ themeing: 'info' }, 500)}
    cursor: pointer;

    &:hover {
      ${color({ themeing: 'info' }, 500)}
    }
  }
`

const getCvtUploadInputDropper = () => styled('input')`
  position: absolute;
  height: 30vh;
  margin: 0;
  opacity: 0;
  background: transparent;
  z-index: 9999;

  ${(props) => {
    let top = `top: ${Spacing.SPACING_16}px;`
    let left = `left: ${Spacing.SPACING_16}px;`
    let width = `width: calc(100% - ${Spacing.SPACING_32}px);`
    let display = `display: ${props.manualPlainUpload ? 'none' : ''};`
    let border = `border: ${Border.SIZE_1}px ${BorderStyle.DASHED};`
    let brColor = borderColor({ themeing: 'dark' }, 300)

    return top + left + width + display + border + brColor
  }}

  &:hover,
  &:focus {
    border: ${Border.SIZE_1}px ${BorderStyle.DASHED};
    ${borderColor({ themeing: 'primary' }, 500)}
  }
`

const getCvtUploadTip = () => styled('div')`
  ${color({ themeing: 'dark' }, 500)}
  ${textFontSize('sm')}
  margin: ${Spacing.SPACING_8}px 0;
`

const getCvtManualUplaod = () => styled('div')`
  display: flex;
  div:first-child {
    margin-right: ${Spacing.SPACING_16}px;
  }
`

const NAME = 'CvtUpload'

export default {
  name: NAME,
  components: {
    CvtButton,
    BFileList
  },
  provide() {
    return {
      bUpload: this
    }
  },
  mixins: [
    idMixin,
    normalizeSlotMixin,
    BFormFileMixin,
    ItemBaseMixing,
    UploadInputMixin
  ],
  props: {
    dropPlaceholder: {
      type: String,
      default: () => getComponentConfig(NAME, 'dropPlaceholder')
    },
    manualUpload: Boolean,
    fileNameFormatter: {
      type: Function,
      default: (file) => {
        console.log(file)
      }
    },
    action: {
      type: String,
      default: 'http://localhost:8080/fileupload'
    },
    uploadThreads: {
      type: Number,
      default: 1
    },
    uploadHandler: {
      type: Function,
      default: null
    },
    beforeUpload: {
      type: Function,
      default: () => true
    },
    beforeRemove: {
      type: Function,
      default: () => true
    },
    onRemove: {
      type: Function,
      default: null
    },
    onSuccess: {
      type: Function,
      default: () => {}
    },
    onError: {
      type: Function,
      default: () => {}
    },
    onExceed: {
      type: Function,
      default: () => {}
    },
    onProgress: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      stackedFiles: []
    }
  },
  watch: {
    plain() {
      this.removeListeners()
      this.addListeners()
    },
    manualUpload() {
      this.removeListeners()
      this.addListeners()
    },
    noDrop() {
      this.removeListeners()
      this.addListeners()
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      color: getters.GLOBAL_STYLE_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE
    }),
    ...mapState('globalTheme', {
      Spacing: ({globalTheme}) => globalTheme.Spacing,
      Radii: ({globalTheme}) => globalTheme.Radii,
      Border: ({globalTheme}) => globalTheme.Border,
      BorderStyle: ({globalTheme}) => globalTheme.BorderStyle
    }),
    _id() {
      return this.safeId()
    }
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    BorderStyle = this.BorderStyle
    borderColor = this.borderColor
    color = this.color
    textFontSize = this.textFontSize
  },
  mounted() {
    this.addListeners()
  },
  beforeDestroy() {
    this.removeListeners()
  },
  methods: {
    onSelectionClick() {
      this.$refs.input.click()
    },
    onUploadClick(e) {
      this.$emit('upload')
    },
    detectFileChanges(files) {
      if (Array.isArray(files) && files.length > 0) {
        this.stackedFiles = files
      } else if (isFile(files)) {
        this.stackedFiles = [files]
      }
      this.$refs.input.value = ''
    },
    addListeners() {
      // listen for new added files
      // send new files to FileList component for upload handling and archived
      this.$on('input', this.detectFileChanges)
      this.$refs.input.addEventListener('change', this.onFileChange)
      this.$refs.input.addEventListener('focusin', this.focusHandler)
      this.$refs.input.addEventListener('focusout', this.focusHandler)
      this.$refs.input.addEventListener('reset', this.onReset)

      if (!this.plain && !this.noDrop) {
        this.$refs.input.addEventListener('dragover', this.onDragover)
        this.$refs.input.addEventListener('dragleave', this.onDragleave)
        this.$refs.input.addEventListener('drop', this.onDrop)
      }
    },
    removeListeners() {
      this.$off('input')
      this.$refs.input.removeEventListener('change', this.onFileChange)
      this.$refs.input.removeEventListener('focusin', this.focusHandler)
      this.$refs.input.removeEventListener('focusout', this.focusHandler)
      this.$refs.input.removeEventListener('reset', this.onReset)

      if (!this.plain && !this.noDrop) {
        this.$refs.input.removeEventListener('dragover', this.onDragover)
        this.$refs.input.removeEventListener('dragleave', this.onDragleave)
        this.$refs.input.removeEventListener('drop', this.onDrop)
      }
    }
  },
  render: function (h) {
    const CvtUpload = getCvtUpload()
    const CvtManualUplaod = getCvtManualUplaod()
    const CvtUploadTip = getCvtUploadTip()
    const CvtUploadDropper = getCvtUploadDropper()
    const CvtDropperText = getCvtDropperText()
    const CvtUploadInputDropper = getCvtUploadInputDropper()

    return (
      <CvtUpload class={this.disabled}>
        {this.manualUpload ? (
          <div>
            <CvtManualUplaod>
              <CvtButton
                color='light'
                onClick={this.onSelectionClick}
                text='Select file'
              ></CvtButton>
              <CvtButton
                color='primary'
                onClick={this.onUploadClick}
                text='Click to upload'
              ></CvtButton>
            </CvtManualUplaod>
            <CvtUploadTip>
              {this.accept} files with a size less than{' '}
              {(this.limit / 1024 / 1024).toFixed(2)}MB
            </CvtUploadTip>
          </div>
        ) : this.plain ? (
          <div>
            <CvtButton
              color='primary'
              onClick={this.onSelectionClick}
              text='Click to upload'
            ></CvtButton>
            <CvtUploadTip>
              {this.accept} files with a size less than{' '}
              {(this.limit / 1024 / 1024).toFixed(2)}MB
            </CvtUploadTip>
          </div>
        ) : (
          <CvtUploadDropper ref='dropper'>
            <CvtIcon icon='upload'></CvtIcon>
            <CvtDropperText>
              Drop file here or&nbsp;
              <a onClick={this.onSelectionClick}>click to upload</a>
            </CvtDropperText>
          </CvtUploadDropper>
        )}
        <cvt-file-list
          files={this.stackedFiles}
          removeEnabled={this.removeEnabled}
          iconsEnabled={this.iconsEnabled}
          thumbnailsEnabled={this.thumbnailsEnabled}
          fallbackThumbnail={this.fallbackThumbnail}
          fallbackIcon={this.fallbackIcon}
          upload-handler={this.uploadHandler}
          descriptionProp={'name'}
          autoUpload={!this.manualUpload}
          upload-threads={this.uploadThreads}
        ></cvt-file-list>

        <CvtUploadInputDropper
          style={{ display: this.plain || this.manualUpload ? 'none' : '' }}
          ref='input'
          type='file'
          id={this._id}
          name={this.name}
          disabled={this.disabled}
          capture={this.capture || null}
          accept={this.accept || null}
          multiple={this.multiple}
          action={this.action}
          webkitdirectory={this.directory}
          uploadThreads={this.uploadThreads}
          manualPlainUpload={this.plain || this.manualUpload}
        ></CvtUploadInputDropper>
      </CvtUpload>
    )
  }
}
