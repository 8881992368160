import Vue from '../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import CvtButton from '../molecules/Button'
import CvtInput from '../molecules/Input'
import CvtSwitch from '../molecules/Switch'
import * as getters from '../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import VueI18n from 'vue-i18n'
Vue.use(VueEmotion)
Vue.use(VueI18n)

let textFontSize, textFontWeight, backgroundColor, color, textColor
let FontFamily, Spacing

const getSearchBar = () => styled('div')`
  padding: 0px ${Spacing.SPACING_32}px;
  display: flex;
  height: ${Spacing.SPACING_96}px;
  justify-content: space-between;
  font-family: ${FontFamily.DEFAULT};
  ${(props) => {
    let weight = 'WHITE'
    weight = props.mode === 'DARK' ? 700 : 'WHITE'
    let background = backgroundColor(
      { themeing: props.mode === 'DARK' ? 'dark' : 'light' },
      weight,
    )
    return background
  }}
`
const getMarketPlaceName = () => styled('label')`
  ${textFontSize('sm')};
  margin: 0 ${Spacing.SPACING_12}px;
  ${textFontWeight('LIGHT')}
  font-family: ${FontFamily.DEFAULT};
  ${(props) => {
    return props.mode === 'DARK'
      ? textColor({ themeing: 'light' }, 100)
      : textColor({ themeing: 'dark' }, 700)
  }}
`
const getFilterByName = () => styled(getMarketPlaceName())`
  margin: 0px;
`
const getSiteIdInput = () => styled('div')`
  width: 30%;
  & > * {
    margin-top: ${Spacing.SPACING_4}px;
  }
`
const getWideFlexDiv = () => styled('div')`
  display: flex;
  flex: 2;
  & > * {
    margin: ${Spacing.SPACING_4}px;
  }
`
const getCvtSwitch = () => styled(CvtSwitch)`
  margin: ${Spacing.SPACING_4}px;
`
const getCvtSearchBtnDiv = () => styled('div')`
  margin-top: ${Spacing.SPACING_32}px !important;
  padding: ${Spacing.SPACING_2}px;
`
const getRightDiv = () => styled('div')`
  padding-top: ${Spacing.SPACING_8}px;
`

export const CvtSearchFilterAdmin = {
  name: 'CvtSearchFilterAdmin',
  data() {
    return {
      inputProductID: '',
      inputSiteID: '',
      isMarketPlace: false,
      projectIdCB: function () {},
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      color: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      marketplaceConfig: ({ marketplaceConfig }) => marketplaceConfig,
    }),
  },
  created() {
    ;(Spacing = this.Spacing),
      (textFontSize = this.textFontSize),
      (FontFamily = this.FontFamily),
      (backgroundColor = this.backgroundColor),
      (textFontWeight = this.textFontWeight),
      (color = this.color),
      (textColor = this.textColor)
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    inputData: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    inputData(suggestions) {
      this.projectIdCB(
        suggestions.map((suggestion) => {
          return {
            value: suggestion,
            label: suggestion,
          }
        }),
      )
    },
  },
  methods: {
    fetchSuggestions(term, cb) {
      this.projectIdCB = cb
    },
  },
  render: function (h) {
    const SearchBar = getSearchBar()
    const WideFlexDiv = getWideFlexDiv()
    const MarketPlaceName = getMarketPlaceName()
    const SiteIdInput = getSiteIdInput()
    const PublishedSwitch = getCvtSwitch()
    const SearchBtnDiv = getCvtSearchBtnDiv()
    const RightDiv = getRightDiv()
    const FilterByName = getFilterByName()
    return (
      <SearchBar mode={this.mode}>
        <WideFlexDiv>
          <SiteIdInput>
            <CvtInput
              label=" "
              size="small"
              mode={this.mode}
              color="black"
              placeholder="Site ID"
              value={this.inputSiteID}
              onClear={() => {
                this.inputSiteID = ''
                this.$emit('searchBySiteID', '')
              }}
              onInput={(e) => {
                this.inputSiteID = e.target.value
                this.$emit('searchBySiteID', this.inputSiteID)
              }}
            ></CvtInput>
          </SiteIdInput>

          <RightDiv>
            <MarketPlaceName mode={this.mode}>MarketPlace</MarketPlaceName>
            <PublishedSwitch
              size="md"
              color="default"
              type="circular"
              descriptionType="text"
              activeText=""
              inactiveText=""
              disabled={false}
              initialState={false}
              leftSideLabel={true}
              showBigSwitch={false}
              mode={this.mode}
              showInside={true}
              onSwitchToggle={() => {
                this.isMarketPlace = !this.isMarketPlace
                this.$emit('sortBymarketplace', this.isMarketPlace)
              }}
            ></PublishedSwitch>
          </RightDiv>
        </WideFlexDiv>

        <SearchBtnDiv>
          <CvtButton
            mode={this.mode}
            onClick={() => this.$emit('goToMarketPlace')}
            icon="external-link"
            shape={
              this?.marketplaceConfig?.gotoMarketplaceButton?.shape || 'rounded'
            }
            text="Go to Marketplace"
            prepend={false}
          ></CvtButton>
        </SearchBtnDiv>
      </SearchBar>
    )
  },
}
