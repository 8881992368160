import * as getters from './getters'
import * as actions from './actions'
import { database } from '@/js/store/services/firebase.service'

let db = database()

export default {
  state: {
    anchors: {},
    anchorsRef: null,
  },
  getters: {
    [getters.ANCHORS]({ anchors }) {
      return anchors
    },
  },
  actions: {
    [actions.ANCHORS_UPDATE_BY_ID]({ state }, { id, value }) {
      return state.anchorsRef.child(id).set(value)
    },
    [actions.ANCHORS_INIT]({ state }, fbKey) {

      if (state.anchorsRef) {
        state.anchorsRef.off('value')
      }

      state.anchorsRef = db.ref(fbKey).child('anchors')

      state.anchorsRef.on('value', (snap) => {
        state.anchors = snap.val() || {}
      })

      return Promise.resolve()
    },
    [actions.ADD_NEW_ANCHORS]({ state }, anchors) {
      const newAnchors = {}

      for (const anchorId in anchors) {
        newAnchors[anchorId] = anchors[anchorId]
      }

      const allAnchors = { ...state.anchors, ...newAnchors }
      state.anchorsRef.set(allAnchors)
    },
  },
}
