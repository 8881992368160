import { database } from '@/js/store/services/firebase.service';
import { BulkPropertyChangeCommand } from "../../../commands/BulkPropertyChangeCommand";
import { PopupInsertBlockCommand } from "../../../commands/PopupInsertBlockCommand";
import { PopupPropertyChangeCommand } from "../../../commands/PopupPropertyChangeCommand";
import * as actions from '../actions';
import * as getters from '../getters';

const db = database()

export default {
	state: {
		popupFirebasePath: null,
		popupFirebaseData: {},
		rootPath: null, // rootpath is path to blocks, neccessary for computing block children firebase paths
	},
	getters: {
		[getters.SDA_POPUP_FIREBASE_PATH]({ popupFirebasePath }) {
			return popupFirebasePath
		},
		[getters.SDA_POPUP_FIREBASE_BLOCK]({ popupFirebaseData }) {
			return popupFirebaseData.children || []
		},
		[getters.SDA_POPUP_ROOTPATH]({ rootPath }) {
			return rootPath
		},
		[getters.SDA_POPUP_FIREBASE_DATA]({ popupFirebaseData }) {
			return popupFirebaseData
		},
	},
	mutations: {
		setPopupFirebasePathProperty(state, payload) {
			const data = { ...state.popupFirebaseData.data }
			const props = { ...data.props, [payload.key]: payload.value }
			state.popupFirebaseData = { ...state.popupFirebaseData, data: { ...data, props: { ...props } } }
		}
	},
	actions: {
		async [actions.SDA_POPUP_FIREBASE_INIT]({ state, dispatch }, popupFirebasePath) {
			state.popupFirebasePath = popupFirebasePath
			await dispatch(actions.SDA_POPUP_PROPS_REFRESH)
			state.rootPath = `${state.popupFirebasePath}/children`
		},
		async [actions.SDA_POPUP_PROPS_REFRESH]({ state }) {
			state.popupFirebaseData = await new Promise((resolve, reject) => {
				const ref = db.ref(state.popupFirebasePath)
				ref.on(
					'value',
					(snapshot) => {
						resolve(snapshot.val())
					},
					(error) => {
						reject(error)
					},
				)
			})
		},
		[actions.SDA_POPUP_FIREBASE_GET_PROPERTY](
			{ state, commit, dispatch, rootState, rootGetters },
			propertyName,
		) {
			return new Promise((resolve, reject) => {
				if (state.popupFirebaseData[propertyName]) {
					return resolve(state.popupFirebaseData[propertyName])
				}
				const ref = db.ref(`/${state.popupFirebasePath}/data/props/${propertyName}`)

				ref.on(
					'value',
					(snapshot) => {
						const value = snapshot.val()
						state.popupFirebaseData[propertyName] = value
						resolve(value)
					},
					(error) => {
						reject(error)
					},
				)
			})
		},
		async [actions.SDA_POPUP_FIREBASE_UPDATE_PROPERTIES](
			{ state, commit, dispatch, rootState, rootGetters },
			payloads,
		) {
			const bulkUpdateCommand = new BulkPropertyChangeCommand("")

			for (const [key, value] of Object.entries(payloads)) {
				const command = new PopupPropertyChangeCommand(key, value)
				bulkUpdateCommand.addCommand(key, command)
			}
			if (bulkUpdateCommand.hasChanges) {
				await dispatch(actions.HISTORY_COMMAND_PUSH, bulkUpdateCommand)
				await dispatch(actions.SDA_POPUP_PROPS_REFRESH)
			}
		},
		async [actions.SDA_POPUP_BUILDER_INSERT_BLOCK]({ state, commit, dispatch, rootState, rootGetters }, { block, idx }) {
			const command = new PopupInsertBlockCommand(block, state.rootPath)
			dispatch(actions.HISTORY_COMMAND_PUSH, command)
		},
	},
}
