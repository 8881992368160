
<div class="container full-height">
  <div class="row full-height">
    <div
      class="col-lg-6 mx-auto loader-animation d-flex justify-content-center align-items-center"
    >
      <div class="loader-animation">
        <component
          :is="svg"
          v-if="svgPath === null || svgPath === 'undefined'"
        />
        <div v-else class="external-svg">
          <img :src="svgPath" class="img-style" />
        </div>
        <h2 class="loader-message" v-text="$t(msg)" />
      </div>
    </div>
  </div>
</div>
