<template>
  <div class="social-share-buttons d-flex">
    <div v-for="(data, provider) in providers" :key="data" class="social-share-button mr-1" :class="providerClass(provider)" @click="openShare(provider)">
      <i :class="data.icon"></i>
      <small v-text="provider"></small>
    </div>
  </div>
</template>

<script>
  import qs from 'querystring'

  const PROVIDER_URL_MAP = {
    google: {
      url: 'https://plus.google.com/share',
      param: 'url',
      icon: 'fa fa-google-plus-official'
    },
    facebook: {
      url: 'https://www.facebook.com/sharer/sharer.php',
      param: 'u',
      icon: 'fa fa-facebook-official'
    },
    twitter: {
      url: 'https://twitter.com/home',
      param: 'status',
      icon: 'fa fa-twitter-square'
    },
    linkedIn: {
      url: 'https://www.linkedin.com/shareArticle',
      param: 'url',
      icon: 'fa fa-linkedin-square'
    },
    pinterest: {
      url: 'https://pinterest.com/pin/create/button/',
      param: 'url',
      icon: 'fa fa-pinterest-square'
    },
    email: {
      url: 'mailto:',
      param: 'body',
      icon: 'fa fa-envelope-o'
    }
  }

  export default {
    props: {
      url: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        providers: PROVIDER_URL_MAP
      }
    },
    methods: {
      providerClass (provider) {
        return `social-share-button--${provider}`
      },
      fmtUrl (provider) {
        let providerOpts = PROVIDER_URL_MAP[provider]
        return `${providerOpts.url}?${qs.encode({
          [providerOpts.param]: this.url
        })}`
      },
      openShare (provider) {
        window.open(this.fmtUrl(provider), '', `
          menubar=no,
          toolbar=no,
          resizable=yes,
          scrollbars=yes,
          height=600,
          width=600
        `)
      }
    }
  }
</script>

<style lang="scss">
  @import "@/scss/utils";

  .social-share-buttons {

  }

  .social-share-button {
    width: 75px;
    height: 75px;
    cursor: pointer;
    background-color: $off-gray;
    transition: all 100ms ease;
    transform: scale(1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 3px;

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }

    .fa {
      font-size: 25px;
    }

    // The new twitter X icon is only available from v6 of font awesome... this is a hack
    .fa.fa-twitter-square::before{
      content:"𝕏";
    }

    &--google { background-color: #db4437; }
    &--facebook { background-color: #4267b2; }
    &--twitter { background-color: #794bc4; }
    &--linkedIn { background-color: #283e4a; }
    &--pinterest { background-color: #bd081c; }
    &--email { background-color: #eee; color: black; }
  }


</style>
