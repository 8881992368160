import * as getters from "@/js/store/modules/getters"
import { database } from "@/js/store/services/firebase.service"
import { Notification } from 'element-ui'
import * as _ from 'lodash'
import { nanoid } from 'nanoid'
import { V1Sites } from '../services/api'
import * as actions from './actions'
const db = database()
const sites = new V1Sites()

export default {
  namespaced: true,

  state: {
    page: {
      name: ''
    },
    saving: false,
    settingsVisible: false,
    deleteVisible: false,
    saveVisible: false,
    pageObject: null,
    switchingPages: false,
    trackingRef: false,
    tracking: {}
  },
  getters: {
    isSwitchingPages({switchingPages}) {
      return switchingPages
    },
    tracking({tracking}) {
      return tracking
    }
  },
  mutations: {
    update(state, page) {
      state.page = {
        ...state.page,
        ...page
      }
    },
    open(state, page) {
      state.settingsVisible = true
      state.page = page
    },
    openSave(state, page) {
      state.saveVisible = true
      state.pageObject = page
    },
    close(state) {
      state.settingsVisible = false
      state.pageObject = null
    },
    closeSave(state) {
      state.saveVisible = false
      state.pageObject = null
    },
    openDeleteDialog(state, page) {
      state.deleteVisible = true
      this.state
      state.page = page
    },
    closeDeleteDialog(state) {
      state.deleteVisible = false
    }
  },
  actions: {
    async save({ state, dispatch }) {
      state.saving = true
      try {
        await sites.updatePage(state.page)
        await dispatch(actions.SITE_FETCH_DATA, state.page.siteId, { root: true })
        Notification.success({
          title: 'Success',
          // TODO: add I18N for .js files and use location: pagemanager.success.page_updated
          message: 'Page was updated successfully',
          position: 'bottom-right'
        })
      } catch (e) {
        console.error(e)
        Notification.error({
          title: 'Woops',
          // TODO: add I18N for .js files and use location: pagemanager.error.page_updated
          message: 'Failed to save page. Please try again.',
          position: 'bottom-right'
        })
      } finally {
        state.saving = false
      }
    },
    setSwitchingPages({ state, dispatch }, value) {
      state.switchingPages = value
    },

    async remove({ state, dispatch }, { siteId, id }) {
      await sites.deletePage({ siteId, id })
      await dispatch(actions.SITE_FETCH_DATA, siteId, { root: true })
    },

    async duplicate({ state, rootState, dispatch }, { siteId, id }) {
      state.saving = true
      try {
        await sites.clonePage({ siteId, id })
        await dispatch(actions.SITE_FETCH_DATA, siteId, { root: true })
        Notification.success({
          title: 'Success',
          message: 'Page successfully duplicated',
          position: 'bottom-right'
        })
      } catch (e) {
        console.error(e)
        Notification.error({
          title: 'Woops',
          message: 'Failed to duplicate page. Please try again.',
          position: 'bottom-right'
        })
      } finally {
        state.saving = false
      }
    },
    async replacePageLayout({ state, rootState, dispatch }, { pageProps }) {
      // This code block copies color and anchors from Saved-Page which is being merged in.
      const paletteColors = _.get(pageProps, 'page.meta.colors', {})
      const anchors       = _.get(pageProps, 'page.meta.anchors', {})

      await dispatch(actions.THEME_ADD_NEW_COLORS, paletteColors, { root: true })
      await dispatch(actions.ADD_NEW_ANCHORS, anchors, { root: true })

      // deprecated
      // const siteId = pageProps.siteId
      // const id = pageProps.pageToBeReplace.id
      // await sites.replacePage({ siteId, id, pageProps })
    },
    async insertSavePages({ state, rootState, dispatch }, { pageProps }) {
      const siteId = pageProps.siteId
      await sites.insertPage({ siteId, pageProps })
      await dispatch(actions.SITE_FETCH_DATA, siteId, { root: true })
    },

    async savePageToFirebase({ state, rootState, dispatch }, { pageProps }) {
      const siteId = state.pageObject.siteId
      const id = state.pageObject.id
      await sites.savePage({ siteId, id, pageProps })
      await dispatch(actions.SITE_FETCH_DATA, siteId, { root: true })
    },
    async createNew({ state, dispatch }, siteId) {
      const pagename = `Untitled (${nanoid(3)})`
      await sites.createPage({
        siteId,
        name: pagename,
        pageName: pagename,
        isIndex: false
      })
      await dispatch(actions.SITE_FETCH_DATA, siteId, { root: true })
    },
    async pageTrackingInit({state, dispatch, commit, rootGetters}, pageId){
      if (state.trackingRef) {
        state.trackingRef.off('value')
      }
      const page = rootGetters[getters.SITE_GET_ACTIVE_PAGE](pageId)

      const trackingRefKey = `${page.firebaseRef}/tracking`
      state.trackingRef = db.ref(trackingRefKey)
      state.trackingRef.on('value', (snap) => {
        state.tracking = snap.val() || {
          head: '',
          afterOpeningBody: '',
          beforeClosingBody: '',
        }
      })
    },
    async saveTracking({ state }, tracking) {
      return state.trackingRef.set(tracking).then(() => {
        state.tracking = tracking
        return Promise.resolve()
      })
    },
  }
}
