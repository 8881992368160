<template>
  <div>
    <h1>Dialog</h1>
    <div class="d-flex mb-2">
      <div
        v-for="(color, index) in colorOptions"
        :key="color"
        class="m-2"
      >
        <span>{{ index }}</span>
        <cvt-dialog
          :color="color"
          mode="LIGHT"
          size=""
        >
          <template #triggerButton>
            <div>
              {{buttonText}}
              </div>
          </template>

          <template #title>
            <div>
              {{title}}
              </div>
          </template>
          <div>
            {{content}}
          </div>

          <template #modalFooterButton>
            <div>
              {{modalFooterButtonText}}
              </div>
          </template>
        </cvt-dialog>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PlayAlert',
  components: {
  },
  data () {
    return {
      colorOptions: {
        Light: 'light',
        Dark: 'dark'
      },
      modeOptions: {
        Light: 'LIGHT',
        Dark: 'DARK'
      },
      content: '<p>This is the <b>body</b> of the modal.</p>',
      title: 'Title of Dialog',
      buttonText: 'Launch demo modal',
      modalFooterButtonText: 'Submit'
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
  },
}
</script>
<style lang="scss">
</style>
