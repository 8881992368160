import { styled, VueEmotion } from '@egoist/vue-emotion'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import * as getters from '../../../../builder/src/js/store/modules/getters';
import Vue from '../../../lib/utils/vue'

Vue.use(VueEmotion)


let FontFamily,
Colors,
Border,
textFontWeight,
FontSize

const getBrandingTabsContainer = () => styled('div')`
  font-family: ${FontFamily.DEFAULT};
  background-color: ${Colors.BASIC['WHITE']};
`

const getBrandingTabWrap = () => styled('div')`
  ${props => `width: ${props.navTabWidth};`}
  height: 40px;
  display: flex;
  ${props => props.usage === 'marketplace' ?
  '' :
  'box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);'}
`

const getBrandingTabSpan = () => styled('span')`
  ${textFontWeight('REGULAR')}
  ${props =>props.usage === 'marketplace' ?
    `font-size: ${FontSize.FONT_SIZE_14}px;` :
    ``
  }
  padding: 9px 15px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  ${props => props.usage === 'marketplace' ?
  `border-bottom: ${Border.SIZE_2}px solid ${Colors.BASIC[300]}; color: ${Colors.BASIC[300]};` :
  `color: ${Colors.BASIC[500]};`
  }
  ${props => props.active && (
    props.usage === 'marketplace' ?
    `border-bottom: ${Border.SIZE_2}px solid ${Colors.PRIMARY[500]}; color: ${Colors.PRIMARY[500]};` :
    `border-bottom: ${Border.SIZE_2}px solid #5D62AB; color: #5D62AB;`
  )}
`

const getBrandingTabBody = () => styled('div')`
  width: ${props => props.navTabBodyWidth};
`

export const CvtEntDashboardTabs = {
  name: 'CvtEntDashboardTabs',
  data () {
    return {
      activeTabIdx: 0,
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
    activeTab: {
      type: Number,
      default: 0
    },
    tabsArray: {
      type: Array,
      default: []
    },
    navTabWidth: {
      type: String,
      default: '410px'
    },
    navTabBodyWidth: {
      type: String,
      default: '410px'
    },
    usage: {
      type: String,
      default: ''
    }
  },
  watch: {
    activeTab(value){
      this.activeTabIdx = value
    }
  },
  computed: {
    ...mapState ('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize
    }),

    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
    }),
  },
  methods: {
    onTabClicked (idx) {
      this.activeTabIdx = idx;
      this.$emit('tabClicked', idx)
    }
  },
  created () {
    FontFamily = this.FontFamily
    textFontWeight = this.textFontWeight
    Colors = this.Colors
    Border = this.Border
    FontSize = this.FontSize
  },
  render: function (h) {
    const BrandingTabsContainer = getBrandingTabsContainer()
    const BrandingTabWrap = getBrandingTabWrap()
    const BrandingTabSpan = getBrandingTabSpan()
    const BrandingTabBody = getBrandingTabBody()

    return (
      <BrandingTabsContainer>
        <BrandingTabWrap usage={this.usage} navTabWidth={this.navTabWidth}>
          {this.tabsArray.map((tab, idx) => (
            <BrandingTabSpan
                usage={this.usage}
                onClick={() => this.onTabClicked(idx)}
                active={idx === this.activeTabIdx}
            >
              {tab}
            </BrandingTabSpan>
          ))}
        </BrandingTabWrap>

        <BrandingTabBody navTabBodyWidth={this.navTabBodyWidth}>
            {this.$slots.default}
        </BrandingTabBody>
      </BrandingTabsContainer>
    )
  }
}