import { cvtRouteNodes } from '@/js/utils/routes'
import { Organization } from '../../../../../js/store/services/api'

const organizationAPI = new Organization()

export default {
  components: {},
  props: {},
  data() {
    return {
      cvtRouteNodes,
    }
  },
  created() {
    const payload = {
      key: 'CURRENT_STAGE',
      value: 'integrate_builder',
    }
    this.updateConfig(payload)
  },
  methods: {
    async updateConfig(config: { key: String; value: String }) {
      try {
        const payload = {
          key: config.key,
          table: 'organizationConfigs',
          value: config.value,
        }
        await organizationAPI.upsertFeatureConfig(payload)
      } catch (err) {
        console.log(err)
      }
    },
    navigateToNamedRoute(namedRoute: String): void {
      this.$router.push({
        name: namedRoute,
      })
    },
  },
}
