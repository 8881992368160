
<div class="">
  <p>{{ $t("elements.property.product.select.header") }}</p>
  <div class="mb-2">
    <cvt-button
      :outlined="true"
      :full-width="true"
      shape="rounded"
      color = "light"
      :mode="mode"
      :text="$t(`elements.property.product.manage`)"
      @click="openProductsDialog"
    />
  </div>
  <div class="">
    <ecomm-products
      :products="products"
      :placeholder="$t('elements.property.product.select.message')"
      @product-selected="updateProducts"
    />
  </div>
  <div class="divider" />
  <div class="products">
    <div
      v-for="product in products"
      :key="product.id"
      class="row py-1 stripe-product"
    >
      <div class="col">
        <p
          class="font-weight-bold m-0"
          v-text="product.name"
        />
        <small
          class="text-success m-0"
        >{{ product.sku.price | currency }}</small>
      </div>
      <div class="col-3 product-actions align-self-center text-center">
        <el-tooltip
          :content="$t('element.property.product.cta.remove')"
          placement="top"
        >
          <i
            class="fa fa-trash fa-lg"
            @click="removeProduct(product)"
          />
        </el-tooltip>
      </div>
    </div>
  </div>
</div>
