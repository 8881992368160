<template>
  <div>
    <h1>Popover</h1>
    <div class="w-100">
      <div class="mb-4">
        <span>Popover Triggers on click</span>
        <cvt-button
          id="popover-button"
          color="primary"
          text="I have a Popover"
        />
        <cvt-popover
          placement="right"
          target="popover-button"
          triggers="click"
          color="light"
          mode="LIGHT"
        >
          <template #popper>
            Tooltip Sample Text
          </template>
        </cvt-popover>
      </div>

      <div class="mb-4">
        <span>Popover Triggers on hover</span>
        <cvt-button
          id="popover-button-0"
          color="primary"
          text="I have a Popover"
        />
        <cvt-popover
          placement="right"
          target="popover-button-0"
          triggers="hover"
          color="light"
          mode="LIGHT"
        >
          <template #popper>
            Tooltip Sample Text
          </template>
        </cvt-popover>
      </div>

      <div class="mb-4">
        <span>Popover With Actions</span>
        <cvt-button
          id="popover-button-1"
          color="primary"
          text="I have a Popover"
        />

        <cvt-popover
          ref="popover"
          placement="bottom"
          target="popover-button-1"
          triggers="click"
          color="light"
          mode="LIGHT"
        >
          <template #popper>
            <span>Are you sure you want to delete this?</span>
            <div class="actions">
              <cvt-button
                color=""
                size="sm"
                shape="rounded"
                :text="$t('element.property.cta.cancel')"
                @click="onAction"
              />
              <cvt-button
                color="danger"
                size="sm"
                shape="rounded"
                text="Delete"
                @click="onAction"
              />
            </div>
          </template>
        </cvt-popover>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayPopover',
  components: {
  },
  data () {
    return {}
  },
  computed: {
  },
  created () {
  },
  methods: {
    onAction (e) {
      this.$refs.popover.$refs.tooltip.doClose()
    }
  },
}
</script>
<style lang="scss">
</style>
