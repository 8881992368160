import { CvtList, ItemBaseMixin } from '../list/index'
import UploadInputMixing from './mixins/upload-inputs'
import xhrUpload from './helpers/ajax-uploader'
import CvtFileItem from './FileItem'
import idMixin from '../../../lib/mixins/id'
import BvEvent from '../../../lib/utils/bv-event.class'
import { STATUS } from './helpers/shared-properties'
import { isArray } from '../../../lib/utils/array'

import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'

import Vue from '../../../lib/utils/vue'
Vue.use(VueEmotion)

const CvtFileList = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
  .b-progress-bottom {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
`

export default {
  components: {
    CvtList,
    CvtFileItem
  },
  extends: CvtList,
  inject: {
    bUpload: {
      default() /* istanbul ignore next */ {
        return null
      }
    }
  },
  provide() {
    return {
      cvtList: this
    }
  },
  mixins: [idMixin, ItemBaseMixin, UploadInputMixing],
  data() {
    return {
      _status: STATUS,
      fileName: 'file',
      filesLocal: [],
      reqs: {}
    }
  },
  props: {
    files: {
      type: Array,
      default: () => []
    },
    action: {
      type: String,
      default: 'http://localhost:8080/fileupload'
    },
    uploadThreads: {
      type: Number,
      default: 1
    }
  },
  beforeMount() {
    // initialize here to make sure
    // data replacement on storybook or by extension
    // don't affect the component behaviour
    this._status = STATUS
  },
  mounted() {
    this.initialize(this.files)
    if (!this.autoUpload && this.bUpload) {
      this.bUpload.$on('upload', () =>
        this.uploadThreadsHandler(this.uploadThreads)
      )
    }
  },
  watch: {
    files(newFiles, oldFiles) {
      this.initialize(newFiles)
    },

    autoUpload(newVal) {
      // autoUpload Enabled, stop listening for manual upload event
      if (newVal) {
        this.bUpload.$off('upload')
      } else {
        this.bUpload.$on('upload', () =>
          this.uploadThreadsHandler(this.uploadThreads)
        )
      }
    }
  },
  computed: {
    _limit() {
      return this.bUpload.limit || this.limit
    }
  },
  methods: {
    genFileId(file) {
      const fileStr = file.name + file.lastModified + file.size + file.type
      return this.getHashFromString(fileStr)
    },
    initialize(files) {
      const _files = [...files]
      _files.forEach((f) => {
        const id = this.genFileId(f)
        this.filesLocal.unshift(
          Object.assign(f, { id, status: STATUS.PENDING })
        )
      })

      if (this.autoUpload) {
        this.$nextTick(() => {
          this.uploadThreadsHandler()
        })
      }
    },
    async uploadThreadsHandler() {
      if (
        this._limit &&
        this.filesLocal.length + this.files.length > this._limit
      ) {
        this.onExceed && this.onExceed(this.files, this.filesLocal)
        return
      }
      const pending = this.filesLocal.find((f) => f.status === STATUS.PENDING)
      if (pending) {
        pending.status = STATUS.IN_PROGRESS
        this.upload(pending)
      }
    },

    upload(rawFile) {
      if (!this.beforeUpload) {
        return this.post(rawFile)
      }
      const before = this.beforeUpload(rawFile)
      if (before !== false) {
        return this.post(rawFile)
      } else {
        return this.onRemove(null, rawFile)
      }
    },
    onSuccess(res, file) {
      //timing out to show progress bar
      setTimeout(() => {
        file.status = STATUS.SUCCESS
        this.bUpload.onSuccess(res, file)
        this.$forceUpdate()
      }, 400)

      this.uploadThreadsHandler()
    },

    onError(res, file) {
      console.log(res)
      //timing out to show progress bar
      setTimeout(() => {
        file.status = STATUS.FAIL
        this.bUpload.onError(res, file)
        this.$forceUpdate()
      }, 400)

      this.uploadThreadsHandler()
    },

    abort(file) {
      const { reqs } = this
      if (file) {
        let uid = file
        if (file.uid) uid = file.uid
        if (reqs[uid]) {
          reqs[uid].abort()
        }
      } else {
        Object.keys(reqs).forEach((uid) => {
          if (reqs[uid]) reqs[uid].abort()
          delete reqs[uid]
        })
      }
    },
    post(rawFile) {
      const { uid } = rawFile
      const options = {
        headers: this.bUpload.headers || {},
        withCredentials: this.bUpload.withCredentials || false,
        file: rawFile,
        data: this.bUpload.data || {},
        filename: this.bUpload.name || this.fileName,
        action: this.bUpload.action,
        onProgress: (e) => {
          return this.onProgress(e, rawFile)
        },
        onSuccess: (res) => {
          this.onSuccess(res, rawFile)
          delete this.reqs[uid]
        },
        onError: (err) => {
          this.onError(err, rawFile)
          delete this.reqs[uid]
        }
      }
      const functor = this.bUpload.uploadHandler || xhrUpload
      const req = functor(options)
      this.reqs[uid] = req
      return req
    },
    onProgress(e, file) {
      this.$nextTick(() => {
        file.progress = Math.floor(e.percent)
        this.bUpload.onProgress(e, file)
        this.$forceUpdate()
      })
    },

    async onRemove(id) {
      // by call bvEvt.preventDefault()
      const tabEvt = new BvEvent('onRemove', {
        cancelable: true,
        vueTarget: this
      })

      this.$emit(tabEvt.type, tabEvt)

      if (!tabEvt.defaultPrevented) {
        this.filesLocal = this.filesLocal.filter((f) => f.id !== id)
      }
    }
  },
  render: function (h) {
    return (
      <CvtFileList>
        {this.filesLocal.map((file) => {
          return (
            <cvt-file-item
              ref='fileItem'
              file={file}
              id={file.id}
              status={file.status}
              removeEnabled={this.removeEnabled}
              thumbnailsEnabled={this.thumbnailsEnabled}
              fallbackThumbnail={this.fallbackThumbnail}
              iconsEnabled={this.iconsEnabled}
              fallbackIcon={this.fallbackIcon}
              iconProp={this.iconProp}
              thumbnailProp={this.thumbnailProp}
              descriptionProp={this.descriptionProp}
              progress={file.progress}
              onRemove={this.onRemove}
              showSeparator={this.showSeparator}
              isCard={this.isCard}
            ></cvt-file-item>
          )
        })}
      </CvtFileList>
    )
  }
}
