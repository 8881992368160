import Vue from '../../lib/utils/vue'
import { VueEmotion, styled } from '@egoist/vue-emotion'
import * as getters from '../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import { CvtIcon } from './icon/icon'

Vue.use(VueEmotion)

let Spacing, TextFontSize, TextLineHeight, TextColor, FontSize;

  const getAnalyticsContainer = () => styled('div')`
    margin: ${Spacing.SPACING_16}px 0px;
    ${TextLineHeight( 'xl' )};
  `

  const getValueContainer = () => styled('div')`
    text-align: center;
    font-size: ${FontSize.FONT_SIZE_40 + FontSize.FONT_SIZE_24}px;
  `

  const getLabelContainer = () => styled('div')`
    text-align: center;
    margin-top: ${Spacing.SPACING_16}px;
    ${TextFontSize( 'md' )};
  `
  const getKpiContainer = () => styled('div')`
    text-align: center;
    margin-top: ${Spacing.SPACING_16}px;
    ${TextFontSize( 'md' )};
  `
  const getEmptyMsgContainer = () => styled('div')`
    text-align: center;
    margin-top: ${Spacing.SPACING_12}px;
    ${TextFontSize( 'md' )};
    ${TextColor({ themeing:'dark' }, 300)};
  `
  const getKpiValue = () => styled('span')`
    margin-right: ${Spacing.SPACING_2}px;

    ${(props) => {
      let styles = ''
      if(props.positiveKpi){      
        styles += `${TextColor({ themeing: 'success' }, 500)}`
      } else {
        styles += `${TextColor({ themeing: 'danger' }, 500)}`
      }
  
      return styles
    }}
  `

  const getIcon = () => styled(CvtIcon)`
    ${TextFontSize('lg')};
    margin-right: ${Spacing.SPACING_4}px;

    ${(props) => {
      let styles = ''
      if(props.positiveKpi){      
        styles += `${TextColor({ themeing: 'success' }, 500)}`
      } else {
        styles += `${TextColor({ themeing: 'danger' }, 500)}`
      }
  
      return styles
    }}
  `

export default {
  name: 'CvtCustomAnalytics',

  data() {
    return { }
  },

  props: {
    pvalue: {
      required: true,
      type: [String, Number]
    },
    label: {
      required: true,
      type: [String, Number]
    },
    kpi:{
      default: 0,
      type: Number
    },
    kpisup:{
      default: "",
      type: [String, Number]
    },
    postfix:{
      default: "",
      type: [String, Number]
    },
  },

  created () {
    TextFontSize = this.TextFontSize
    TextLineHeight = this.TextLineHeight
    TextColor = this.TextColor

    Spacing = this.Spacing
    FontSize = this.FontSize
  },

  computed: {
    ...mapGetters('globalTheme', {
      TextFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      TextLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      TextColor: getters.GLOBAL_STYLE_COLOR,
    }),

    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
    }),

    positiveKpi(){
      return this.kpi >= 0;
    },

    kpiIcon(){
      if( this.kpi >= 0 )
        return 'caret-up'
      else
        return 'caret-down'
    }

  },

  render: function (h) {
    const AnalyticsContainer = getAnalyticsContainer()
    const ValueContainer = getValueContainer()
    const LabelContainer = getLabelContainer()
    const KpiContainer = getKpiContainer()
    const EmptyMsgContainer = getEmptyMsgContainer()
    const Icon = getIcon()
    const KpiValue = getKpiValue()

    return (
      <AnalyticsContainer>

        { this.pvalue ? 
          <ValueContainer>
            { this.pvalue }{ this.postfix }
          </ValueContainer>
          : null
        }

        { !this.pvalue ? 
          <ValueContainer>
            -
          </ValueContainer>
          : null
        }

        <br />

        <LabelContainer>
          { this.label }
        </LabelContainer>

        { !this.pvalue ? 
          <EmptyMsgContainer>
            {this.$t('elements.properties.analytics.no.data.recorded')}
          </EmptyMsgContainer>
          : null
        }

        <KpiContainer>
          {/*<Icon*/}
          {/*  icon={this.kpiIcon}*/}
          {/*  positiveKpi={this.positiveKpi}*/}
          {/*  >*/}
          {/*</Icon>*/}
          {/*<KpiValue positiveKpi={this.positiveKpi}>*/}
          {/*  { Math.abs(this.kpi)}%*/}
          {/*</KpiValue>*/}
          {/*({ this.kpisup })*/}          
        </KpiContainer>

      </AnalyticsContainer>
    )
  }
}
