import { styled } from '@egoist/vue-emotion'
import CvtInput from "../molecules/Input"
import { CvtRadio, CvtRadioGroup } from '../molecules/radio-group'
import CvtButton from '../molecules/Button'


const getNewUserContainer = () => styled('div')`
    background-color: #ffffff;
    position: absolute;
    top: 0;
    z-index: 9998;
    top: 50%;
    left: 50%;
    transform: translate(0,-50%);
    max-height: 800px;
    width: 500px;
    margin-left: -250px;
    display: flex;
    justify-content: center;
    padding: 40px 0px;
    border-radius: 5px;
    overflow-y: scroll ;
`

const getNewUserWrapper = () => styled('form')`
    width: 80%;
`

const getNewUserHeaderWrapper = () => styled('div')`
    text-align: center;

        &h1{
            font-weight: 600;
            font-size: 40px;
        }
        &p{
            font-weight: 400;
            font-size: 16px;
            color: #494C53;
        }
`

const getNewUserInputField = () => styled(CvtInput)`

`
const getNewUserInputFieldWapper = () => styled('div')``

const getNewUserRoleWrapper = () => styled('div')`
    padding-top: 30px;

    &p{
        margin: 0px;
    }
`

const getRoleHeader = () => styled('p')``

const getRoleWrapper = () => styled(CvtRadioGroup)``

const getRoleBtn = () => styled(CvtRadio)`
    &label{
        padding: 0px !important;
    }
`

const getNewUserBtnWrapper = () => styled('div')`
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 30px 0px;
`

const getCancelBtn = () => styled(CvtButton)`
    background-color: #ffffff !important;
    border: 1px solid #5D62AB !important;
    color: #5D62AB !important;
`

const getInviteBtn = () => styled(CvtButton)`
    margin-left: 20px;
    background-color: #5D62AB !important;
`

const getNewUserHeader = () => styled('h1')``

const getNewUserHeaderContent = () => styled('p')``

export const CvtInviteNewUserModal = {
  name: 'CvtInviteNewUserModal',
  data() {
    return {
        newUserData: {
            inputData: [
                {
                    key: "name",
                    label: "Name",
                    value: "",
                    placeholder: "Team Member Name"
                },
                {
                    key: "email",
                    label: "Email",
                    value: "",
                    placeholder: "email@example.com"
                }
            ],
            roles: [
                {
                    label: "Admin",
                    key: 'admin',
                    value: 'admin'
                },
                {
                    label: "Developer",
                    key: 'developer',
                    value: 'developer'
                },
                {
                    label: "Designer",
                    key: 'designer',
                    value: 'designer'
                },
                {
                    label: "Viewer",
                    key: 'viewer',
                    value: 'viewer'
                }
            ]
        },
        newTeamPayload: {
            name: "",
            email: "",
            role: "admin"
        },
        currentRadioValue: "admin"
    }
  },
  props: {
    loading:{
        type: Boolean,
        default: false,
    }
  },
  computed: {

  },
  created() {

  },
  methods: {
    updateInput(e, key){
        const { value } = e.target
        this.newUserData.inputData = this.newUserData.inputData.map((inpuData) =>{
            return(inpuData.key === key ? { ...inpuData, value: value } : { ...inpuData })
        })

        this.newTeamPayload = {
            ...this.newTeamPayload,
            [key]: value
        }
    },
    updateRole(value){
        this.currentRadioValue = value;
        this.newTeamPayload = {
            ...this.newTeamPayload,
            role: value
        }
    },
    submit(e){
        e.preventDefault()
        this.$emit("onCreateNewTeam", this.newTeamPayload)
    }
  },
  render: function (h) {
    const NewUserContainer = getNewUserContainer()
    const NewUserWrapper = getNewUserWrapper()
    const NewUserHeaderWrapper = getNewUserHeaderWrapper()
    const NewUserInputFieldWapper = getNewUserInputFieldWapper()
    const NewUserInputField = getNewUserInputField()
    const NewUserRoleWrapper = getNewUserRoleWrapper()
    const RoleHeader = getRoleHeader()
    const RoleWrapper = getRoleWrapper()
    const RoleBtn = getRoleBtn()
    const NewUserBtnWrapper = getNewUserBtnWrapper()
    const CancelBtn = getCancelBtn()
    const InviteBtn = getInviteBtn()
    const NewUserHeader= getNewUserHeader()
    const NewUserHeaderContent = getNewUserHeaderContent()

    return (
        <NewUserContainer>
            <NewUserWrapper
                onSubmit={(e) => this.submit(e)}
            >
                <NewUserHeaderWrapper>
                    <NewUserHeader>Invite A Team Member</NewUserHeader>
                    <NewUserHeaderContent>
                        Add a new team member to your dashboard and assign a role. New team members will receive an invite link via the provided email.
                    </NewUserHeaderContent>
                </NewUserHeaderWrapper>
                <NewUserInputFieldWapper>
                    {
                       this.newUserData.inputData.map((input, idx) => {
                        return(
                            <NewUserInputField
                                key={idx}
                                label={input.label}
                                placeholder={input.placeholder}
                                required={true}
                                value={input.value}
                                oninput={(e) => this.updateInput(e, input.key)}
                            />
                        )
                       })
                    }
                </NewUserInputFieldWapper>
                <NewUserRoleWrapper>
                   <RoleHeader>Role</RoleHeader>
                   <cvt-radio-group
                    theme='dark'
                    orientation='vertical'
                    size='md'
                    initialValue={this.currentRadioValue}
                   >
                    {
                        this.newUserData.roles.map((role, id) => {
                            return(
                                <cvt-radio
                                    key={id}
                                    size='md'
                                    value={role.value}
                                    border={false}
                                    onRadioClick={() => this.updateRole(role.value)}
                                >
                                    {role.label}
                                </cvt-radio>
                            )}
                        )
                    }
                    </cvt-radio-group>
                </NewUserRoleWrapper>
                <NewUserBtnWrapper>
                    <CancelBtn
                        text="Cancel"
                        color=""
                        mode="'primary'"
                        onClick={() => { this.$emit('onTogggleModal', false)}}
                    />
                    <InviteBtn
                        text="Send Invite"
                        color=""
                        mode="'primary'"
                        loading={this.loading}
                        submit
                    />
                </NewUserBtnWrapper>
            </NewUserWrapper>
        </NewUserContainer>
    )
  }
}
