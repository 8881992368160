<template>
  <transition name="fade">
    <div v-show="visible" class="fullscreen-gallery">

        <div class="fullscreen-gallery__close d-flex justify-content-center align-items-center">
          <i class="cvt-icon-delete" @click.prevent.stop="close"></i>
        </div>

        <div class="row text-center auto pt-5 bg-overlay">
          <div class="col">
            <h2>Select a block type</h2>
            <p><i>Click a block to add it to the page</i></p>
          </div>
        </div>
        <div class="d-flex p-3 justify-content-center align-items-center flex-wrap bg-overlay">
          <h6 v-for="(tag) in tags" :key="tag" class="badge-wrapper mr-2" @click.prevent.stop="activate(tag)">
            <span class="badge badge-pill" :class="active(tag)" v-text="tag"></span>
          </h6>
        </div>

        <transition-group name="fade" class="mx-5 mt-3 fullscreen-gallery__list" tag="div">
          <block-item v-for="(block) in filteredBlocks" :key="block['.key']" :block="block" @selected="useBlock" @remove="removeBlock"></block-item>
        </transition-group>

    </div>
  </transition>
</template>

<script>
import * as _ from 'lodash'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import * as mutations from '../../../store/modules/mutations'
import BlockItem from './QuickLaunchBlockItem.vue'
import { database } from '@/js/store/services/firebase.service'
let db = database()
let marketplace = db.ref('/marketplace')
let blocks = marketplace.child('blocks')

export default {
  components: {
    BlockItem
  },
  props: {
  },
  firebase () {
    return {
      blocks: blocks
    }
  },
  data () {
    return {
      activeTag: 'ALL'
    }
  },
  computed: {
    ...mapGetters({
      visible: getters.QUICK_LAUNCH_BLOCK_VISIBLE
    }),
    tags () {
      let tags = {}
      this.blocks.forEach(block => {
        block.tags.forEach(tag => {
          tags[tag.toUpperCase()] = true
        })
      })

      tags.ALL = true

      return Object.keys(tags).sort((a, b) => a > b)
    },
    filteredBlocks () {
      if (this.activeTag === 'ALL') {
        return this.blocks
      }

      return _.filter(this.blocks, block => {
        return _.includes(block.tags.map(t => t.toUpperCase()), this.activeTag)
      })
    }
  },
  methods: {
    ...mapMutations({
      close: mutations.QUICK_LAUNCH_BLOCK_HIDE
    }),
    ...mapActions({
      insert: actions.QUICK_LAUNCH_BLOCK_INSERT
    }),
    active (tag) {
      return {
        'badge--active': tag === this.activeTag
      }
    },
    activate (tag) {
      this.activeTag = tag
    },
    useBlock (block) {
      this.insert(block.vnode)
    },
    removeBlock (block) {
      if (prompt('Type DELETE to remove this block') !== 'DELETE') return
      blocks.child(block['.key']).remove()
    }
  }

}
</script>

<style lang="scss">
  // @import "@/scss/utils";

</style>
