<template>
  <div class="adm-footer-wrap">
      <div class="row adm-footer-inner-wrap">
        <div class="col-sm-4 col-md-2">
          <div class="footer-ul-wrap">
            <div class="footer-ul-heading">Solutions</div>

            <ul class="footer-ul">
              <li>
                <a href="#">Convrrt For SaaS</a>
              </li>
              <li>
                <a href="#">Convrrt For Product Managers</a>
              </li>
              <li>
                <a href="#">Convrrt For CTOs</a>
              </li>
              <li>
                <a href="#">The Platform</a>
              </li>
              <li>
                <a href="#">CRM Solution</a>
              </li>
              <li>
                <a href="#">SEO Page Builder</a>
              </li>
              <li>
                <a href="#">API Solutions</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-sm-4 col-md-2">
          <div class="footer-ul-wrap">
            <div class="footer-ul-heading">Resources</div>

            <ul class="footer-ul">
              <li>
                <a href="#">Guides</a>
              </li>
              <li>
                <a href="#">Webinars</a>
              </li>
              <li>
                <a href="#">Build Vs. Buy</a>
              </li>
              <li>
                <a href="#">ROI Calculator</a>
              </li>
              <li>
                <a href="#">Convrrt Vs. BeeFree</a>
              </li>
              <li>
                <a href="#">Convrrt Vs. Unlayer</a>
              </li>
              <li>
                <a href="#">Convrrt Vs. Simvoly</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-sm-4 col-md-2">
          <div class="footer-ul-wrap">
            <div class="footer-ul-heading">Case Studies</div>

            <ul class="footer-ul">
              <li>
                <a href="#">Keap</a>
              </li>
              <li>
                <a href="#">Sharpspring</a>
              </li>
              <li>
                <a href="#">SendInBlue</a>
              </li>
              <li>
                <a href="#">Freshworks</a>
              </li>
              <li>
                <a href="#">LionDesk</a>
              </li>
              <li>
                <a href="#">CRMGrow</a>
              </li>
              <li>
                <a href="#">Sellsy</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-sm-4 col-md-2">
          <div class="footer-ul-wrap">
            <div class="footer-ul-heading">Company</div>

            <ul class="footer-ul">
              <li>
                <a href="#">About Us</a>
              </li>
              <li>
                <a href="#">Blog</a>
              </li>
              <li>
                <a href="#">Pricing</a>
              </li>
              <li>
                <a href="#">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-sm-4 col-md-2">
          <div class="footer-ul-wrap">
            <div class="footer-icons-ul-heading">
              <a href="https://www.convrrt.com">
                <img
                  class="adm-logo-2"
                  src="/img/organization/convrrt-logo-2.svg"
                  alt="Convrrt Logo"
                />
              </a>
            </div>

            <ul class="footer-icons-ul">
              <li>
                <a href="#">
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>


<script>
export default {
  props: {},
  data() {
    return {}
  },
}
</script>


<style lang="scss">
  @import "@/scss/utils";

  // The new twitter X icon is only available from v6 of font awesome... this is a hack
  .fa-twitter::before{
    content:"𝕏";
  }
</style>