
<div class="verify-custom-hostnames-wrap">
  <div class="vch-heading-n-desc-wrap">
    
    <div class="vch-icon-wrap">
      <cvt-icon :icon="'cvt-icon-check-mark'"></cvt-icon>
    </div>

    <div class="vch-main-heading-wrap">
      <h3 class="vch-main-heading">DNS verfication was successful!</h3>
    </div>

    <div class="vch-main-desc-wrap">
      <p class="vch-main-desc">Please proceed to the next step</p>
    </div>

  </div>

  <div class="vch-content-wrap">
    <div class="authenticate-cust-btn-wrap">
      <cvt-button
        class="authenticate-cust-btn"
        :color="''"
        :mode="'LIGHT'"
        :text="'Authenticate customers'"
        :special-padding="'20px 135px'"
        @click="authenticateCustomers"
      >
      </cvt-button>
    </div>
  </div>
</div>
