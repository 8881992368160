import { mapGetters } from "vuex";
import * as getters from "@/js/store/modules/getters";

export default {
  props: {
    shadow: {
      type: String,
      default: 'shadow-none',
    },
    borderRadius: Number,
    borderWidth: Number,
    borderColor: String,
    borderStyle: String,
    borderPosition: Object
  },
  computed: {
    ...mapGetters({
      targetsRow: getters.SELECTOR_GET_TARGET_ROW,
      dropzonesActive: getters.DROPZONE_ACTIVE,
      viewMode: getters.VIEW_MODE,
    }),
    otherStyles () {
      return {}
    },
    extraClasses () {
      return {}
    },
    styles() {
      let { image } = this.background
      let backgroundImagePositionCalculated = this.getBackgroundImagePosition()
      let bgColor = this.calcBgColor()

      return {
        containerBorderRadius: {
          borderRadius: `${this.borderRadius === 0 ? null : this.borderRadius}px`,
        },
        bgImage: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          backgroundImage: image && `url(${image})`,
          backgroundPosition: backgroundImagePositionCalculated,
          backgroundRepeat: this.background.repeat
            ? this.background.repeat
            : 'no-repeat',
          backgroundSize: this.background.size ? this.background.size : '',
          borderRadius: `${this.borderRadius || 0}px`,
        },
        bgColor: { ...bgColor },
        content: {
          width: '100%',
          height: '100%',
          position: 'relative',
          zIndex: this.dropzonesActive ? this.dropzoneZIndex : 1,
          color: this.fontColorHEX,
          ...this.spacing.padding.toObject(),
          ...this.spacing.margin.toObject(),
          ...this.formColorTransform,
        },
        bgEmpty: {
          backgroundImage: `url(/img/box-container-placeholder-.svg)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
        },
        ...this.otherStyles
      }
    },
    contentClasses() {
      return {
        [this.stylesheet.classes.content]: true,
        ...this.flexAlignmentClasses,
      }
    },
    localClasses() {
      return {
        'd-flex': true,
        'position-relative': true,
        [`container-${this.viewMode}`]: true,
        [this.bootstrapAlignment]: true,
        [`${this.stylesheet.classes.root}`]: true,
        [this.shadow]: true,
        [`${this.stylesheet.classes.containerBorderRadius}`]: true,
        ...this.extraClasses
      }
    },
    //font color based on the backgorund color selected
    fontColorBG() {
      let colr = ''
      if (this.fontColorHEX) {
        colr = 'color: ' + this.fontColorHEX + ';'
      }
      return colr
    },

    //Whether the background image or color to take the priority
    ContainerBgColor() {
      return {
        [this.stylesheet.classes.bgColor]: true,
        [this.stylesheet.classes?.itemBgColor]: true
      }
    },

    ContainerBgImage() {
      return {
        [this.stylesheet.classes.bgImage]: true,
        [this.stylesheet.classes?.itemBgImage]: true
      }
    },

    backgroundImgExist() {
      return this.background?.image != null && this.background?.image !== ''
    },
  },
  methods: {
    onClick(e) {
      if (e.target && e.target.parentElement === this.$refs.subGridWrapper) {
        this.edit(e)
      }
    },
    mouseOverAction (e) {
      e.stopPropagation()
    },
    mouseLeaveAction (e) {
      e.stopPropagation()
    },
    updateBgSrc(assets) {
      if (!assets) return
      this.updateProp(
        'background/image',
        assets.block ? assets.block : assets.pop(),
      )
    },
    getBackgroundImagePosition() {
      if (
        this.background.imagePosition &&
        this.background.imagePosition.calcPos &&
        this.background.imagePosition.calcPos.x != null &&
        this.background.imagePosition.calcPos.y != null
      ) {
        return `${this.background.imagePosition.calcPos.x}% ${this.background.imagePosition.calcPos.y}%`
      } else {
        return 'center center'
      }
    },

    calcBgColor() {
      let defaultBorder = `${this.borderWidth || 0}px ${this.borderStyle} ${
        this.borderColor
      }`
      let bgColorApplied = this.appliedBackgroudColor()

      let defaultStyle = {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        backgroundColor: bgColorApplied,
        borderRadius: `${this.borderRadius || 0}px`,
      }

      if (this.borderPosition && Object.keys(this.borderPosition).length) {
        //Set the selections
        if (this.borderPosition.all) {
          defaultStyle['border'] = defaultBorder
        }
        if (this.borderPosition.top) {
          defaultStyle['borderTop'] = defaultBorder
        }
        if (this.borderPosition.btm) {
          defaultStyle['borderBottom'] = defaultBorder
        }
        if (this.borderPosition.lft) {
          defaultStyle['borderLeft'] = defaultBorder
        }
        if (this.borderPosition.ryt) {
          defaultStyle['borderRight'] = defaultBorder
        }
      } else {
        defaultStyle['border'] = defaultBorder
      }

      return defaultStyle
    },

    appliedBackgroudColor() {
      //Overlay background color
      if (this.background.overlay && this.overlayColorHSL) {
        return this.overlayColorHSL
      }

      //Background color
      return this.themeColorHSL
    },
  }
}