import _ from "lodash";
import * as actions from "../store/modules/actions";
import {PageEngineCommands} from "./PageEngineCommands";
import {PopupProps, StandalonePageEngineComponent} from "../../../types";


export class PopupPropertyChangeCommand extends PageEngineCommands {
    private readonly _firebaseKey: string;
    private readonly _newValue: object;
    private readonly _lodashKey: string;
    private readonly _oldValue: any;

    constructor(key: string, newValue: any, vNodeId="page-engine-wrapper") {
        super(vNodeId);
        this._firebaseKey = `data/props/${key}`
        this._newValue =  newValue
        this._lodashKey = this._firebaseKey.split("/").join(".")
        this._oldValue = _.clone(_.get(this.cPopupData, this._lodashKey, ""))
    }

    get$emitData() {
        return  {name: 'PopupPropertyChangeCommand', forceBuild: false}
    }
    get hasChanges () {
        return _.isEqual(this._newValue, this._oldValue) === false;
    }

    get cPopupData (): { data: { props: PopupProps } } | undefined {
        return this.getVueComponent()?.cPopupData
    }

    getVueComponent(): StandalonePageEngineComponent {
        return super.getVueComponent() as StandalonePageEngineComponent
    }

    async execute() {
        await this.firebaseRef?.setChild(this._firebaseKey, this._newValue)
    }

    async unExecute() {
        await this.firebaseRef?.setChild(this._firebaseKey, this._oldValue)
        await this.getVueComponent().$store.dispatch(actions.SELECTOR_DESELECT)
    }
}
