import Vue from '../../lib/utils/vue'
import VueI18n from 'vue-i18n'
import { styled } from '@egoist/vue-emotion'
import CvtAlert from './Alert'
import CvtButton from './Button'
import { mapState, mapGetters,  } from 'vuex'
import * as getters from '../../../builder/src/js/store/modules/getters'
import Button from './Button'

Vue.use(VueI18n)

let FontFamily,Spacing, Radii,FontWeight, Colors, Border,textFontWeight, borderColor,textColor, backgroundColor

const getMainWrapper = () => styled('div')`
  ${(props) => props.alertWidth && `width: ${props.alertWidth}px`}
`

const getAlert = () => styled(CvtAlert)`
  margin-bottom: ${Spacing.SPACING_16}px;
`

const getAlertContentWrap = () => styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`

const getAlertMessage = () => styled('span')`
  padding-right: ${Spacing.SPACING_12}px;
`

const getButtonWapper = () => styled('div')`
  ${(props) => props.btnWidth && `width: ${props.btnWidth}px`}
`

export const CvtAlertWithButton= {
  name: 'CvtAlertWithButton',
  props: {
    alertBtnText: {
      type: String,
      default: ""
    },
    alertMessage: {
      type: String,
      default: ""
    },
    infoAlertConfig: {
      type: Object,
      default: () => {}
    },
    alertWidth: {
      type: String,
      default: ""
    },
    btnWidth: {
      type: String,
      default: ""
    },
    btnColor: {
      type: String,
      default: "info"
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
    }),
    ...mapGetters({
      site: getters.SITE_GET_DATA,
      isGridBuilder: getters.IS_GRID_BUILDER,
      viewMode: getters.VIEW_MODE,
      activeSitePreviewPageId: getters.SITE_PREVIEW_PAGE_ID,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      Border: ({ globalTheme }) => globalTheme.Border,
    }),
    isBtnWidthFull(){
      if(this.btnWidth){
        return true
      }
      return false
    }
  },
  created() {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    FontWeight = this.FontWeight
    Colors = this.Colors
    Border = this.Border
    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    textColor = this.textColor
    backgroundColor = this.backgroundColor
  },
  render(h) {

    const MainWrapper         = getMainWrapper()
    const Alert               = getAlert()
    const AlertContentWrap    = getAlertContentWrap()
    const AlertMessage        = getAlertMessage()
    const ButtonWapper        = getButtonWapper()

    return (
      <MainWrapper
        alertWidth={this.alertWidth}
      >
        <Alert
          icon={'info-circle'}
          color={'info'}
          vertical-align-content={true}
          custom-margin={'0px 0px 16px 0px'}
          alertConfig={this.infoAlertConfig}
        >
          <AlertContentWrap>
            <AlertMessage>{this.alertMessage}</AlertMessage>
            <ButtonWapper
              btnWidth={this.btnWidth}
            >
              <CvtButton
                identity="TopNavBackButton1"
                color={this.btnColor}
                mode={'LIGHT'}
                text={this.alertBtnText}
                size="sm"
                onClick={(e) => {
                  this.$emit("exitPreviewMode")
                }}
                fullWidth={this.isBtnWidthFull}
              ></CvtButton>
            </ButtonWapper>
          </AlertContentWrap>
        </Alert>
      </MainWrapper>
    )
  }
}