<template>
  <cvt-dialog :show="visible" :hide-borders="true" @close="close">
    <div class="integrations-wrapper">
      <IntegrationsTemplate :input-full-width="true" />
    </div>
  </cvt-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as actions from '../../store/modules/actions'
import * as getters from '../../store/modules/getters'
import * as mutations from '../../store/modules/mutations'

import { IntegrationsTemplate } from '../../../../../storybook/components/templates/integrations'

export default {
  components: {
    IntegrationsTemplate,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      visible: getters.INTEGRATIONS_DIALOG_ENABLED,
    }),
  },
  methods: {
    ...mapMutations({
      triggerIntegrationsDialog: mutations.TRIGGER_INTEGRATIONS_DIALOG,
    }),
    ...mapActions({}),
    close() {
      return this.triggerIntegrationsDialog(false)
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';
.integrations-wrapper {
  margin: 0 50px;
}
</style>
