
<div class="draggable-list" @dragover="scroll">
  <slot name="title"></slot>

  <div v-for="(item, index) in list" :key="item.id" class="">
    <draggable
      :index="index"
      :payload="item"
      :active="isActive(item)"
      @dragstart="dragstart"
      @dragend="dragend"
      @dropped="dropped"
      @click="itemClicked">

      <slot
      name="list-item-template"
      :item="item"
      :index="index"
      :selected="isActive(item)"></slot>

    </draggable>

    <slot name="list-item-settings" :selected="isActive(item)"></slot>
  </div>

</div>
