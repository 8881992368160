<template>
  <div class="draggable-list" @dragover="scroll">
    <slot name="title"></slot>

    <div v-for="(item, index) in list" :key="item.id" class="">
      <draggable
        :index="index"
        :payload="item"
        :active="isActive(item)"
        @dragstart="dragstart"
        @dragend="dragend"
        @dropped="dropped"
        @click="itemClicked">

        <slot
        name="list-item-template"
        :item="item"
        :index="index"
        :selected="isActive(item)"></slot>

      </draggable>

      <slot name="list-item-settings" :selected="isActive(item)"></slot>
    </div>

  </div>
</template>
<script>
import Draggable from './Draggable.vue'

export default {
  components: {
    Draggable
  },
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      payload: null,
      payloadIdx: null,
      selected: null
    }
  },
  methods: {
    isActive (item) {
      return this.selected === item.id
    },
    itemClicked (data) {
      if (this.selected === data.payload.id) {
        this.selected = null
      } else {
        this.selected = data.payload.id
      }
      this.$emit('item-clicked', data)
    },
    scroll ({clientY}) {
      let {top, bottom} = this.$el.getBoundingClientRect()
      let data = {clientY, top, bottom}

      if (clientY <= top + 30) {
        this.$el.scrollTop -= 10
      }

      if (clientY >= bottom - 40) {
        this.$el.scrollTop += 10
      }
    },
    dragstart ({index, payload}) {
      this.payloadIdx = index
      this.payload = payload
    },
    dragend () {
      this.payloadIdx = null
      this.payload = null
    },
    dropped ({index, offset}) {
      let dropPos = index
      let clone = this.list.slice()

      if (this.payloadIdx !== clone.length - 1) {
        dropPos = index + offset
      }

      clone.splice(dropPos, 0, this.payload)

      if (index < this.payloadIdx) {
        this.payloadIdx += 1
      }

      clone.splice(this.payloadIdx, 1)

      this.$emit('change', clone)
    }
  }
}
</script>

<style lang="scss">
  .draggable-list {
    // height: 600px;
    // overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }
</style>
