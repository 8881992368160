
<div>
  <div class="row">
    <div class="col">
      <strong>Button</strong>
    </div>
    <div class="col-3">
      <cvt-switch
        size="xs"
        color="active"
        type="circular"
        description-type="default"
        :initial-state="enableButton"
        :mode="mode"
        @switchToggle="() => onSwitchToggle('enableButton')"
      />
    </div>
  </div>
  <div :class="DividerWithBorder ? 'divider' : 'divider-no-border'" />
  <div class="row">
    <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
      Inline Space
    </div>
    <div class="col-12">
      <cvt-slider
        id="slider-default"
        class="slider"
        :tooltips-enabled="true"
        :step-marks-enabled="true"
        :min="0"
        :max="50"
        :value="gap"
        :step="1"
        @change="(cval) => gap = cval"
      />
    </div>
  </div>

  <div v-if="isCardView" :class="DividerWithBorder ? 'divider' : 'divider-no-border'" />
  <div v-if="isCardView" class="row align-items-baseline">
    <div class="col-6">
      <strong>Card Width</strong>
    </div>
    <div class="col">
      <cvt-input
        v-model="cardWidth"
        type="number"
        :clearable="false"
      />
    </div>
  </div>

</div>
