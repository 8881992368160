<template>
  <i class="material-icons" v-text="selector"></i>
</template>
<script lang="ts">
export default {
  name: 'MaterialIcon',
  props: {
    selector: {
      required: true,
      type: String,
    },
  },
}
</script>
