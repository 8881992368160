<template>
  <div>
    <flex-align 
      v-if="isGridBuilder && selected.parent().name === 'GridItemWrapper' && !hideFlexAlignment"
      :mode="mode"
      :disable-alignment="hideFlexAlignment"
      :disable-constrain="hideFlexAlignment"
    ></flex-align>

    <cvt-collapse
      :accordion="true"
      :theme="mode === 'LIGHT' ? 'light' : 'dark'"
      :prepend-chevron="prependChevron"
    >
      <cvt-collapse-item
          v-if="config.IS_GEN_AI_ENABLED"
          title="Generative AI"
          name="GenerativeContent"
          :special-style="isSpecialStyle"
      >
        <GenAIOptions :mode="mode" />
      </cvt-collapse-item>
      <cvt-collapse-item
        :title="$t('elements.properties.style.label')"
        name="style"
        :initial-active="true"
        :special-style="isSpecialStyle"
      >
      <div class="row">
        <div class="col-auto">
          <h6>
            {{ $t('elements.properties.shadow.normal') }}
          </h6>
        </div>
        <div class="col-12">
          <cvt-radio-group orientation="horizontal" :initial-value="shadow" class="row">
            <cvt-radio-button
              size="md"
              value=""
              class="col-4 mb-2"
              :shape="RadioButtonShape"
              @radioClick="updateShadow"
            >
              {{ $t('elements.properties.option.none') }}
            </cvt-radio-button>
            <cvt-radio-button
              size="md"
              value="text-shadow-basic"
              class="col-4 mb-2"
              :shape="RadioButtonShape"
              @radioClick="updateShadow"
            >
              {{ $t('element.property.button.shadow.basic') }}
            </cvt-radio-button>
            <cvt-radio-button
              size="md"
              value="text-shadow-hard"
              class="col-4 mb-2"
              :shape="RadioButtonShape"
              @radioClick="updateShadow"
            >
              {{ $t('element.property.button.shadow.hard') }}
            </cvt-radio-button>
            <cvt-radio-button
              size="md"
              value="text-shadow-heavy"
              class="col-4 mb-2"
              :shape="RadioButtonShape"
              @radioClick="updateShadow"
            >
              {{ $t('element.property.button.shadow.heavy') }}
            </cvt-radio-button>
            <cvt-radio-button
              size="md"
              value="text-shadow-letterpress"
              class="col-4 mb-2"
              :shape="RadioButtonShape"
              @radioClick="updateShadow"
            >
              {{ $t('element.property.button.shadow.letterpress') }}
            </cvt-radio-button>
          </cvt-radio-group>
        </div>
      </div>
      </cvt-collapse-item>
    </cvt-collapse>

    <cvt-collapse
      :accordion="true"
      :theme="mode === 'LIGHT' ? 'light' : 'dark'"
      :prepend-chevron="prependChevron"
    >
      <cvt-collapse-item
        :title="$t('elements.properties.spacing.label')"
        name="spacing"
        :initial-active="false"
        :special-style="isSpecialStyle"
      >
        <spacing-ctrl />
      </cvt-collapse-item>
    </cvt-collapse>
  </div>
</template> 

<script>
  import SpacingCtrl from './SpacingCtrl.vue'
  import FlexAlign from './FlexAlign.vue'
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
  import * as getters from '../../../store/modules/getters'
  import * as actions from '../../../store/modules/actions'
  import { LEFT_SIDEBAR_FRESHWORKS, LEFT_SIDEBAR_STANDARD } from '../../../../../../storybook/components/constants'
  import GenAIOptions from "../../genAi/GenAIOptions.vue";
  export default {
    components: {
      GenAIOptions,
      SpacingCtrl,
      FlexAlign
    },
    props: {
      mode: {
        type: String,
        default: 'LIGHT'
      },
      prependChevron: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        activeTab: 'spacing'
      }
    },
    computed: {
      ...mapGetters({
        selected: getters.SELECTOR_SELECTED,
        config: getters.AUTH_GET_USER_CONFIG,
        isGridBuilder: getters.IS_GRID_BUILDER,
        user: getters.AUTH_GET_USER
      }),
      ...mapState('globalTheme', {
        OrgName: ({ globalTheme }) => globalTheme.OrgName !== undefined ? globalTheme.OrgName : LEFT_SIDEBAR_STANDARD,
        RadioButtonShape: ({ globalTheme }) =>  globalTheme.RadioButtonShape !== undefined ? globalTheme.RadioButtonShape : '',
      }),
      isSpecialStyle () {
        return this.OrgName === LEFT_SIDEBAR_FRESHWORKS
      },
      hideFlexAlignment() {
        return this.config.HIDE_FLEX_ALIGNMENT_OPTIONS?.text === true;
      },
      shadow: {
        get() {
          return this.selected.shadow
        },
        set(value) {

          return this.updateProp({
            key: 'shadow',
            value,
          })
        },
      },
    },
    watch: { },
    methods: {
      ...mapActions({
        updateProp: actions.SELECTOR_UPDATE_PROP,
      }),
    updateShadow(value) {
      this.shadow = value
    },
     }
  }
</script>

<style lang="scss">
</style>
