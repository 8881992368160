<template>
  <div class="remote-fields">
    <div v-if="loading" class="placeholder-container">
      <cvt-content-placeholder class="placeholder" />
    </div>
    <cvt-select
      v-else
      :mode="'dropdown'"
      :value="value"
      :label="placeholder"
      :background-mode="mode"
      @input="(value) => updateSelected(value)"
    >
      <option
        v-for="item in remoteData"
        :key="item['id']"
        :value="item['link']"
        v-text="item['title']"
      />
    </cvt-select>

  </div>
</template>

<script>

import * as axios from 'axios';

const client = axios.create({
  baseURL: '/api',
  withCredentials: true
})

export default {
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      loading: false,
      remoteData: []
    }
  },
  async mounted () {
    await this.loadRemoteData()
  },
  methods: {
    async loadRemoteData () {
      try {
        this.loading = true
        const getData = await client.get(this.endpoint);
        this.remoteData = getData.data && getData.data[0] && getData.data[0].fields;
        this.loading = false;
      } catch(error) {
        console.error(error);
      }finally {
        this.loading = false;
      }
    },
    updateSelected (data) {
      this.$emit('videoSelectionUpdated', data)
    },
  }
}
</script>

<style lang="scss">
  @import "@/scss/utils";

  .remote-fields {
    .placeholder-container {
      position: relative;
      height: 48px;
    }

    .placeholder {
      height: 48px;
      width: 100%;
      left: 0;
      border-radius: 8px;
    }

    .el-select {
      width: 100%;
      .el-input__inner {
        border: 3px solid $inf-green;
        &::placeholder {
          text-transform: uppercase;
          color: $inf-green;
        }
      }
    }
    .el-select-group__title {
      font-size: 1rem;
      font-weight: bold;
      background: $dark;
      color: $white;
    }

    .el-select-dropdown__item {

      .row {
        height: 100%;
      }

      .material-icons {
        line-height: 1;
      }

      small {
        line-height: 1;
        font-size: 1rem;
      }
    }
  }
</style>
