import * as _ from 'lodash'
import Vue from '../../../../lib/utils/vue'
import VueI18n from 'vue-i18n'
import { styled } from '@egoist/vue-emotion'
import * as getters from '../../../../../builder/src/js/store/modules/getters'
import * as constants from '../../../../../builder/src/js/common/constants/constants'
import { mapGetters, mapState, mapActions } from 'vuex'
import CvtButton from '../../../molecules/Button'
import CvtInput from '../../../molecules/Input'
import CvtSelect from '../../../molecules/Select'
import CvtCustomCheckbox from '../../../molecules/CustomCheckbox'
import CvtRadioGroup from '../../../molecules/radio-group/RadioGroup'
import CvtRadio from '../../../molecules/radio-group/Radio'
import { IntegrationLogoWrapper } from '../logo-wrapper'
import * as actions from '../../../../../builder/src/js/store/modules/actions'
import { LoadingComponent } from "../FieldMapper";

Vue.use(VueI18n)

let textFontWeight, textFontSize, Spacing, FontSize, backgroundColor, Colors, FontFamily

const getRadioGroup = () => styled(CvtRadioGroup)``

const getRadio = () => styled(CvtRadio)`
  font-size: 16px;
  font-weight: 400;
  label > span:after {
    top: 2px !important;
    left: 2px !important;
  }
`

const getCheckboxInputLabel = () => styled('p')`
  margin-bottom: 20px;
  font-size: 16px;
`

const getSheetSelectDropdown = () => styled(CvtSelect)`
  width: 100%;

  > div {
    border-radius: 0;
  }

  div > div {
    border-radius: 0;
    border: 0;
  }
`

const getSheetSelectButtonWrap = () => styled('div')`
  margin-left: 10px;
  button {
    padding: 13px;
  }
`

const getAuthenticationDescription = () => styled('div')`
  background: ${Colors.BASIC[100]};
  font-family: ${FontFamily.DEFAULT};
`

const getHorizontalLine = () => styled('div')`
  border-top: 1px solid ${Colors.BASIC[300]};
  margin-bottom: 15px;
`

const getSheetSelectWrap = () => styled('div')`
  display: flex;
  align-items: end;
  justify-content: space-between;
`

const getSheetNameContainer = () => styled('div')`
  margin-top: 20px;
  margin-bottom: 24px;
`

const getSheetName = () => styled('p')`
  margin-bottom: 8px;
`

const getAuthSuccessBtnWrap = () => styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`

const getAuthDescriptionHeader = () => styled('p')`
  font-weight: 700;
  font-size: 16px;
  color: ${Colors.BASIC[500]};
`

const getSelectFieldsContainer = () => styled('div')`
  margin: 23px 0;
`

const getCustomCheckbox = () => styled(CvtCustomCheckbox)``

export const GoogleSheetSetup = {
  name: 'GoogleSheetSetup',
  components: {
    CvtRadioGroup,
  },
  data() {
    return {
      loading: false,
      loadingSpreadSheets: true,
      setupModel: {
        columnNames: [],
        workSheetName: "",
        spreadsheetId: ""
      },
      savedData: { //
        workSheetName: "",
      },
      actionType: '',
      spreadsheetTitle: "",
      existingSpreadSheets: [],
      spreadSheetData: null
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapGetters({
      providerAPI: getters.GET_PROVIDER_API_SERVICE,
      site: getters.SITE_GET_DATA,
      selected: getters.SELECTOR_SELECTED,
    }),
    ...mapGetters('globalTheme', {
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
    }),
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      globalTheme: ({ globalTheme }) => globalTheme,
    }),
    ...mapState('sitePreferences', {
      integrationConfig: ({ preferences }) =>
        _.get(preferences, 'integrations', {}),
    }),
    isUnselected() {
      return this.actionType === ''
    },
    isCreateNew() {
      return this.actionType === constants.CREATE_NEW_INTEGRATION
    },
    isUseExisting() {
      return this.actionType === constants.USE_EXISTING_SHEET
    },
    getConvrrtFormFields() {
      return this.selected.computedFields
    },
    hasSpreadSheetData() {
      if (this.isCreateNew) {
        return true
      }
      return this.hasSpreadSheetId && this.spreadSheetData !== null
    },
    hasSpreadSheetId () {
      return this.setupModel.spreadsheetId !== ""
    },
    validationStatus: {
      get () {
        if (!this.hasSpreadSheetData || _.isEmpty(this.setupModel.workSheetName)) {
          return 'invalid'
        }

        if (this.savedData?.workSheetName === this.setupModel.workSheetName && this.savedData?.spreadsheetId === this.setupModel.spreadsheetId) {
          return 'valid'
        }

        return this.spreadSheetData?.worksheets.includes(this.setupModel.workSheetName) ? 'invalid' : 'valid'
      },
      set (value) {
        return value
      }
    }
  },
  created() {
    textFontSize = this.textFontSize
    textFontWeight = this.textFontWeight
    Spacing = this.Spacing
    FontSize = this.FontSize
    backgroundColor = this.backgroundColor
    Colors = this.Colors
    FontFamily = this.FontFamily
  },
  mounted() {
    this.loading = true
    this.init().finally(() => {
      this.$validator.validate()
      this.loading = false
    })
  },
  methods: {
    ...mapActions('sitePreferences', {
      saveIntegrationStep: 'saveIntegrationStep',
    }),
    ...mapActions({
      setSelectedIntegration: actions.SELECT_INTEGRATION,
    }),
    async init() {
      const data = await this.providerAPI.getMappedSetup(this.site.id, this.selected)
      if (data.columnNames) {
        this.actionType = constants.USE_EXISTING_SHEET
        this.savedData = {
          workSheetName: data.workSheetName,
          spreadsheetId: data.spreadsheetId,
        }

        this.setupModel = {
          columnNames: data.columnNames,
          workSheetName: data.workSheetName,
          spreadsheetId: data.spreadsheetId,
        }
        await this.loadExistingSheets()
        await this.loadSpreadSheetDetails()
      }else {
        this.actionType = ""
      }
    },
    async onCompleted() {
      if ( this.isCreateNew) {
        return await this.completedCreateNew()
      }
      try {
        this.loading = true
        await this.providerAPI.updateWorksheet({
          spreadsheetId: this.setupModel.spreadsheetId,
          columnNames: this.setupModel.columnNames,
          workSheetName: this.setupModel.workSheetName,
          siteID: this.site.id,
          formId: this.selected.id
        })
        this.$emit('completed', constants.INTEGRATION_COMPLETE)
      }
      catch (e){
        console.error(e)
      }finally {
        this.loading = false
      }
    },
    async completedCreateNew() {
      this.loading = true
      try {
        const { spreadsheetId } = await this.providerAPI.createNewSheet({
          title: this.spreadsheetTitle,
          columnNames: this.setupModel.columnNames,
          workSheetName: this.setupModel.workSheetName,
          siteID: this.site.id,
          formId: this.selected.id
        })
        this.$emit('completed', constants.INTEGRATION_COMPLETE)
      }
      catch (e){
        console.error(e)
      }finally {
        this.loading = false
      }
    },
    async loadExistingSheets() {
      try {
        this.loading = true
        this.loadingSpreadSheets = true
        this.spreadSheetData = null

        const spreadsheets = await this.providerAPI.getAllSpreadsheets()
        console.log("spreadsheets: ", spreadsheets)

        this.existingSpreadSheets = [...spreadsheets]
      } catch (e) {
        console.log(e)
      }finally {
        this.loading = false
        this.loadingSpreadSheets = false
      }
    },
    async loadSpreadSheetDetails() {
      try {
        this.loading = true
        this.spreadSheetData = await this.providerAPI.getSpreadsheetById(this.setupModel.spreadsheetId)
        console.log("spreadSheetData: ", this.spreadSheetData)
      } catch (e) {
        console.log(e)
      }finally {
        this.loading = false
      }
    },
    onFieldSelect(e, id) {
      if (e.target.value === 'on') {
        this.setupModel.columnNames.push(id)
      }else {
        this.setupModel.columnNames = this.setupModel.columnNames.filter(x=> x !== id)
      }
    },
    onRadioValueChanged(value) {
      this.actionType = value
      this.setupModel.spreadsheetId = ""

      if (value === constants.USE_EXISTING_SHEET) {
        this.loadExistingSheets().then()
      }
    },
    onSpreadSheetChanged(value) {
      const isNew = this.setupModel.spreadsheetId !== value
      this.setupModel.spreadsheetId = value
      if (isNew) {
        this.loadSpreadSheetDetails().then()
      }
    }
  },
  render: function (h) {
    const AuthenticationDescription = getAuthenticationDescription()
    const HorizontalLine = getHorizontalLine()
    const SheetSelectDropdown = getSheetSelectDropdown()
    const RadioGroup = getRadioGroup()
    const Radio = getRadio()
    const SheetSelectWrap = getSheetSelectWrap()
    const SheetSelectButtonWrap = getSheetSelectButtonWrap()
    const SheetNameContainer = getSheetNameContainer()
    const SheetName = getSheetName()
    const CheckboxInputLabel = getCheckboxInputLabel()
    const AuthSuccessBtnWrap = getAuthSuccessBtnWrap()
    const AuthDescriptionHeader = getAuthDescriptionHeader()
    const SelectFieldsContainer = getSelectFieldsContainer()

    const CustomCheckbox = getCustomCheckbox()


    const existingSpreadSheetOptions = this.existingSpreadSheets.map((option, idx) => {
      return (
        <option id={idx} value={option.id}>
          {option.name}
        </option>
      )
    })

    return (
      <IntegrationLogoWrapper>
        <AuthenticationDescription>
          <div style="padding: 45px;">
            <div>
              <AuthDescriptionHeader>Choose an option</AuthDescriptionHeader>
              <RadioGroup
                theme={''}
                orientation={'vertical'}
                size={'md'}
                disabled={this.loading}
                initialValue={this.actionType}
              >
                <Radio
                  border={false}
                  otherValue={constants.CREATE_NEW_INTEGRATION}
                  onRadioClick={(value) => this.onRadioValueChanged(constants.CREATE_NEW_INTEGRATION)}
                >
                  Create a new spreadsheet
                </Radio>
                <Radio
                  border={false}
                  otherValue={constants.USE_EXISTING_SHEET}
                  onRadioClick={(value) => this.onRadioValueChanged(constants.USE_EXISTING_SHEET)}
                >
                  Use an existing spreadsheet
                </Radio>
              </RadioGroup>
            </div>

            {!this.isUnselected && (
              <div>
                <HorizontalLine></HorizontalLine>
                <div>
                  {this.isCreateNew && (
                    <SheetNameContainer>
                      <SheetName>Spreadsheet Name</SheetName>
                      <CvtInput
                        label=""
                        type="text"
                        mode={this.mode}
                        color="black"
                        clearable={false}
                        placeholder="Give your Spreadsheet a name"
                        value={this.spreadsheetTitle}
                        onInput={(e) => {
                          // this.validationStatus = this.isValid(target.value) ? 'valid' : 'invalid'
                          this.spreadsheetTitle = e.target.value
                        }}
                      ></CvtInput>
                    </SheetNameContainer>
                  )}
                  {this.isUseExisting && (
                    <SheetNameContainer>
                      <SheetName>Spreadsheet Name</SheetName>
                      {
                        this.loadingSpreadSheets ? (
                          <LoadingComponent hideButton={true}/>
                        ) : (
                          <SheetSelectWrap>
                            <SheetSelectDropdown
                              placeholder="Select an existing spreadsheet"
                              defaultValue={this.setupModel.spreadsheetId}
                              value={this.setupModel.spreadsheetId}
                              onInput={(val) => this.onSpreadSheetChanged(val)}>

                              {existingSpreadSheetOptions}

                              {!this.existingSpreadSheets.length && (
                                <option value={''} disabled>
                                  no spreadsheets available
                                </option>
                              )}
                            </SheetSelectDropdown>
                            <SheetSelectButtonWrap>
                              <CvtButton
                                text="Select"
                                loading={this.loading}
                                outlined={true}
                                disabled={!this.hasSpreadSheetId}
                                specialPadding="12px"
                                color="success"
                                onClick={() => this.loadSpreadSheetDetails()}
                              ></CvtButton>
                            </SheetSelectButtonWrap>
                          </SheetSelectWrap>

                        )
                      }
                    </SheetNameContainer>
                  )}
                </div>

                {this.hasSpreadSheetData && (
                  <div>
                    <SheetNameContainer>
                      <SheetName>Worksheet Name</SheetName>
                      <CvtInput
                        label=""
                        size="small"
                        mode={this.mode}
                        color="black"
                        placeholder="Give your worksheet a name"
                        validationStatus={this.validationStatus}
                        invalidMessage={`Work sheet name already or invalid`}

                        value={this.setupModel.workSheetName}

                        onClear={() => {
                          this.setupModel.workSheetName = ''
                        }}

                        onInput={(e) => {
                          this.setupModel.workSheetName = e.target.value
                        }}
                      ></CvtInput>
                    </SheetNameContainer>

                    <HorizontalLine></HorizontalLine>

                    <SelectFieldsContainer>
                      <CheckboxInputLabel>
                        Select form fields to send to Google Sheets
                      </CheckboxInputLabel>

                      <div style="display:flex; flex-direction: column;gap: 15px;">
                        {this.getConvrrtFormFields.map((field) => (
                          <CustomCheckbox
                            label={field.label}
                            checked={this.setupModel.columnNames.includes(field.id)}
                            onChange={e => this.onFieldSelect(e, field.id)}
                          />
                        ))}
                      </div>
                    </SelectFieldsContainer>

                    <HorizontalLine></HorizontalLine>

                    <CheckboxInputLabel>
                      Send additional data to Google Sheets
                    </CheckboxInputLabel>

                    <CustomCheckbox
                      label="Submission Date"
                      checked={this.setupModel.columnNames.includes("Submission Date")}
                      onChange={e => this.onFieldSelect(e, "Submission Date")}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </AuthenticationDescription>

        <AuthSuccessBtnWrap>
          <CvtButton
            text="Cancel"
            outlined={true}
            loading={this.loading}
            specialPadding="12px"
            color="light"
            onClick={() => this.$emit('cancel')}
          ></CvtButton>
          <CvtButton
            text="Save"
            outlined={true}
            loading={this.loading}
            specialPadding="12px"
            disabled={!this.setupModel.columnNames.length > 0 || this.validationStatus === 'invalid'}
            color="primary"
            onClick={() => this.onCompleted()}
          ></CvtButton>
        </AuthSuccessBtnWrap>
      </IntegrationLogoWrapper>
    )
  },
}
