
<div>
  <h5 class="mt-2 mb-4">{{ $t('launch.chat.header') }}</h5>

  <div v-if="getChatWidgetConfiguration" class="row mb-5 ml-2">
    <h6 :class="textColorClass">{{ $t('launch.chat.toggle') }}</h6>
    <cvt-switch
      size="sm"
      color="active"
      type="circular"
      description-type="default"
      :initial-state="chatToggleState"
      mode="LIGHT"
      @switchToggle="onSwitchToggle"
    />
    <div class="d-block w-100 text-black-50">
      <small class="css-2tg5nq">
        <a href="https://conversations-app.brevo.com" target="_blank"><cvt-icon icon="exclamation-circle" prefix-class="exclamation-circle-icon"/></a>
        {{ $t('launch.chat.hint') }}
      </small>
    </div>
  </div>
  <p v-if="!getChatWidgetConfiguration">
    {{ $t('launch.chat.disabled_message') }}
  </p>
</div>
