
export class Command {
    async execute (): Promise<void> {
        throw 'Function requires implementation'
    }

    async unExecute (): Promise<void>  {
        throw 'Function requires implementation'
    }

    get hasChanges (): boolean {
        throw 'Function requires implementation'
    }
}
