<template>
  <div class="add-element-popover">
    <cvt-button
      :id="btnId"
      color="light"
      icon="cvt-icon-plus"
      :class="btnClasses"
      size="xl"
      @click="() => rotatePlusClose = !rotatePlusClose"
    />
  <cvt-popover placement="top" :target="btnId" triggers="click blur" color="light" mode="DARK" :border-radius="10" :inner-padding="10">
      <template #popper>
        <div class="d-flex">
          <cvt-button
            v-for="item of filteredElements"
            :id="btnId+'-'+item.name"
            :key="'btn-' + item.name"
            size="md"
            mode-of-action-icon="dark"
            color-of-action-icon="dark"
            :action-icon="true"
            :icon="elementIconConfig[item.name] || item.icon"
            color="light"
            @click="() => $emit('add-item', item.id)"
            @clickOut="clikedOutSide(item.name)"
          />
          <cvt-tooltip 
            v-for="item of filteredElements"
            :key="'tooltip-' + item.name"
            :target="btnId+'-'+item.name"
            size="xs"
          >
            <strong>{{ item.label }}</strong>
          </cvt-tooltip>
        </div>
      </template>
    </cvt-popover>

  </div>
</template>

<script>
import FbVNode from '../../base/FbVNode.vue'
import { mapState, mapGetters } from 'vuex'
import * as getters from '../../../store/modules/getters'

export default FbVNode.extend({
  name: 'AddElementPopover',
  data () {
    return {
      rotatePlusClose: false,
    }
  },
  props: {
    btnId: String,
    notFilter: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    clikedOutSide (name) {
      if(name === 'container')
      {
        this.rotatePlusClose= false
      }
    }
  },
  computed: {
    btnClasses () {
      return {
        'add-button': true,
        'rotate-plus-to-close': this.rotatePlusClose
      }
    },
    ...mapState('globalTheme', {
      iconConfig: ({ iconConfig }) => iconConfig,
    }),
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG
    }),
    elementIconConfig () {
      return this.iconConfig?.leftSidebar?.elements
    },
    elements () {
      let popoverElements = [
        {icon: 'cvt-icon-text', name: 'text', label: 'Text', type: 'Text', id: 'text_element'},
        {icon: 'cvt-icon-picture-text', name: 'image', label: 'Image', type: 'Image', id: 'image_element'},
        {icon: 'cvt-icon-button', name: 'button', label: 'Button', type: 'Button', id: 'button_element'},
        {icon: 'cvt-icon-minus', name: 'divider', label: 'Divider', type: 'Divider', id: 'divider_element'},
        {icon: 'cvt-icon-form', name: 'form', label: 'Form', type: 'Form', id: 'form_element'},
        {icon: 'cvt-icon-star', name: 'icon', label: 'Icon', type: 'Icon', id: 'icon_element'},
        {icon: 'cvt-icon-video', name: 'video', label: 'Video', type: 'Video', id: 'video_element'},
        {icon: 'cvt-icon-logo', name: 'logo', label: 'Logo', type: 'Logo', id: 'logo_element'},
        {icon: 'cvt-icon-four-squares-broken-line', name: 'container', label: 'container', type: 'Container', id: 'container_element'}        
      ]

      if (this.config?.THEME?.SurveyEnabled) {
        popoverElements.push(
          {icon: 'cvt-icon-star', name: 'survey', label: 'survey', type: 'survey', id: 'survey_element'}
        )
      }

      return popoverElements
    },
    filteredElements () {
      return this.elements.filter((elm) => this.notFilter.indexOf(elm.name) === -1)
    }
  }
})
</script>

<style lang="scss">
.add-element-popover {
  .rotate-plus-to-close {
    transform: rotateY(0deg) rotate(45deg);
    transition: transform 0.4s;
  }
}
</style>
