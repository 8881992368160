
<div class="checkbox-opt-container">
    <div class="checkbox-opt-container__breadcrumb">
        <cvt-floating-breadcrumb
            :mode="'LIGHT'"
            :active-menu="currentMenuId"
            :menus="breadcrumbMenus"
            :hide-back-btn="true"
            :hide-next-btn="true"
            @menuChanged="(eventData) => onBreadCrumbSelectionChange(eventData)"
        />
    </div>

    <div class="checkbox-opt-container__content">
        <CheckoutPayment v-if="currentMenuId === 'checkout-page-design'" :page-config="checkoutPageConfig" v-bind="checkoutPageConfig.data.props"></CheckoutPayment>
        <CheckoutThankYou v-if="currentMenuId === 'thank-you-receipt-mock'" :page-config="checkoutThankYouPageConfig" v-bind="checkoutThankYouPageConfig.data.props" ></CheckoutThankYou>
    </div>
</div>
