
<cvt-dialog
  :title="title"
  :show="visibleSave"
  :height-auto="true"
  :width="'512px'"
  @close="closeSave"
>
  <template #title>
    <div>Save Snapshot</div>
  </template>
  <div
    class="revision-manager-dialog"
  >
    <cvt-input
      ref="snapInput"
      type="textarea"
      required
      :value="snapshotDesc"
      :label="$t('element.revision.snapshot.description.label')"
      :placeholder="$t('element.revision.snapshot.description.label')"
      @input="setSnapshotDesc"
    />
    

    <div
      v-loading="saving"
      class="revision-table-container"
    >

    </div>
  </div>

  <template #modalFooter>
    <div style="display: flex;">
    <cvt-button
      :outlined="true"
      text='Cancel'
      color='light'
      @click="closeSave"
    ></cvt-button>
    <cvt-button
      style="margin-left: 12px;"
      text='Save'
      :disabled="saveButtonDisabled"
      :outlined="true"
      special-style='dark'
      @click="saveSnapshotAndClearForm"
    ></cvt-button>
    </div>
  </template>
</cvt-dialog>
