<script lang="jsx">
import { cloneVDomFromDelta } from '../lib/vue-dom-obj'
import { FooterBlock, HeaderBlock } from '@/js/lib/helper-classes'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as getters from '@/js/store/modules/getters'
import FooterBlockUI from './FooterBlock.vue'
import { PAGE_ENGINE_VDOM_UPDATE } from '@/js/plugins/events'
import * as actions from '@/js/store/modules/actions'
import * as mutations from '@/js/store/modules/mutations'
import { AddSubNodeCommand } from '@/js/commands/AddSectionCommand'

export default {
  name: 'FooterEngineV3',
  components: {
    FooterBlockUI,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: 'FooterEngine',
      clonedVDom: [],
      isReload: false,
      isLoading: false,
      setInsertCallbackSet: false,
    }
  },
  computed: {
    ...mapGetters({
      vdom: getters.FOOTER_DELTA_VDOM,
      site: getters.SITE_GET_DATA,
      viewMode: getters.VIEW_MODE,
      domReady: getters.FOOTER_DELTA_VDOM_READY,
      rootKey: getters.FOOTER_DELTA_KEY,
      footerDisabled: getters.FOOTER_DISABLED,
      sectionOpen: getters.LEFT_SIDE_SECTION_OPEN,
      config: getters.AUTH_GET_USER_CONFIG,
      isGridBuilder: getters.IS_GRID_BUILDER,
    }),
  },
  watch: {
    viewMode: {
      handler(newVal) {
        setTimeout(() => {
          this.$nextTick(() => {
            this.$mount()
            this.clonedVDom = [...this.clonedVDom]
          })
        }, 200)
      },
    },
    domReady: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.buildDOM()
          this.isLoading = false
        }
      },
    },
    sectionOpen: {
      handler(newVal, oldVal) {
        this.isLoading = newVal
        if (!newVal) {
          this.setInsertCallback(null)
          this.setInsertCallbackSet = false
          this.forceBuildDOM()
        }
      },
    },
  },
  mounted() {
    this.$hub.$on(PAGE_ENGINE_VDOM_UPDATE, this.$hubEventHandler)
    this.initFooter(this.page.id) // init header vdom fetching
  },
  methods: {
    ...mapMutations({
      setInsertCallback: mutations.QUICK_LAUNCH_BLOCK_SET_INSERT_HANDLER,
    }),
    ...mapActions({
      leftSideMenuToggle: actions.LEFT_SIDE_MENU_TOGGLE,
      pushCommand: actions.HISTORY_COMMAND_PUSH,
      initFooter: actions.FOOTER_DELTA_INIT,
    }),
    buildDOM() {
      this.clonedVDom = cloneVDomFromDelta(this.vdom)
      this.isLoading = false
    },
    forceBuildDOM() {
      this.isReload = true
      this.buildDOM()
      this.$nextTick(() => {
        this.isReload = false
      })
    },
    $hubEventHandler(data) {
      if (data.forceBuild) {
        this.forceBuildDOM()
        return
      }
      this.buildDOM()
    },
    async createNewFooter() {
      if (this.isLoading) return

      this.isLoading = true
      await this.pushCommand(
        new AddSubNodeCommand(this.rootKey, new FooterBlock(), (node) => {
          if (!node) {
            return true
          }
          return Array.isArray(node) && node.length === 0
        }),
      )
    },
    loadPreMadeFooter(event) {
      if (this.isLoading) return

      event.preventDefault()
      event.stopPropagation()

      this.setInsertCallback(this.preMadeInsertCallback)
      this.setInsertCallbackSet = true
      this.leftSideMenuToggle({
        activate: 'sections',
        context: { isFooterBlock: true },
      })
    },
    async preMadeInsertCallback(vnodes) {
      vnodes.tag = 'footer-block'
      vnodes.data.props.isFooterBlock = true
      this.isLoading = true
      await this.pushCommand(new AddSubNodeCommand(this.rootKey, vnodes))
      this.isLoading = false
    },
  },
  render() {
    if (
      !(this.config.GRID_BUILDER_HEADER_FOOTER_ENABLED && this.isGridBuilder)
    ) {
      // Return Null if feature is not enabled
      return null
    }
    // We only expect cloneVDOM to have one Node available
    if (this.clonedVDom.length === 0 || this.isReload) {
      return (
        <footer class="d-flex justify-content-center empty-header-footer">
          <div class="d-flex my-4">
            <cvt-button
              color="primary"
              size={'sm'}
              class="mr-2"
              text={this.$t('footer.engine.start_blank_footer_text')}
              disabled={this.isLoading}
              onClick={this.createNewFooter}
            ></cvt-button>
            <cvt-button
              outlined={true}
              color={'light'}
              size={'sm'}
              class="ml-2"
              text={this.$t('footer.engine.select_premade_footer_text')}
              disabled={this.isLoading}
              onClick={this.loadPreMadeFooter}
            ></cvt-button>
          </div>
        </footer>
      )
    }

    const getProps = (x) => {
      return {
        ...x.node.data.props,
        vDomPath: this.rootKey,
        fbNode: x.node,
        blockDisabled: this.footerDisabled,
      }
    }

    return (
      <div>
        {this.clonedVDom.map((x) => (
          <FooterBlockUI key={x.key} {...{ props: getProps(x) }} />
        ))}
      </div>
    )
  },
}
</script>
