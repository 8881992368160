<template>
  <div>
    <div class="nestedInnerItem">
      <cvt-select
        :value="borderStyle"
        mode="dropdown"
        :background-mode="mode"
        :floating-label="false"
        :label="$t('elements.properties.border.style')"
        @input="(value) => updateBorderProps('borderStyle', value)"
      >
        <option
          v-for="style in borderStyles"
          :key="style"
          :value="style"
          v-text="style"
        />
      </cvt-select>
      <div style="margin: 5px" />
      <cvt-input
        v-model="borderColor"
        style="width: max-content"
        type="text"
        :clearable="false"
        :label="$t('elements.properties.border.color')"
      />
    </div>

    <div class="divider mt-2" />

    <div class="row">
      <div class="col-md-12 d-flex align-items-center text-capitalize">
        {{ $t('elements.properties.border.position') }}
      </div>
    </div>

    <div class="row">
      <div class="col">
        <cvt-radio-group
          :key="borderPositionKey"
          orientation="horizontal"
          :initial-value="positionDefVal"
        >
          <cvt-radio-button
            :value="pv.all"
            :disabled="disableAllBorder"
            type="multiselect"
            @radioClick="($event) => updatePosition($event, 'all')"
          >
            <cvt-icon icon="border-all"></cvt-icon>
          </cvt-radio-button>
          <cvt-radio-button
            :value="pv.top"
            type="multiselect"
            @radioClick="($event) => updatePosition($event, 'top')"
          >
            <cvt-icon icon="border-top"></cvt-icon>
          </cvt-radio-button>
          <cvt-radio-button
            :value="pv.btm"
            type="multiselect"
            @radioClick="($event) => updatePosition($event, 'btm')"
          >
            <cvt-icon icon="border-bottom"></cvt-icon>
          </cvt-radio-button>
          <cvt-radio-button
            :value="pv.lft"
            type="multiselect"
            @radioClick="($event) => updatePosition($event, 'lft')"
          >
            <cvt-icon icon="border-left"></cvt-icon>
          </cvt-radio-button>
          <cvt-radio-button
            :value="pv.ryt"
            type="multiselect"
            @radioClick="($event) => updatePosition($event, 'ryt')"
          >
            <cvt-icon icon="border-right"></cvt-icon>
          </cvt-radio-button>
        </cvt-radio-group>
      </div>
    </div>

    <div class="divider mt-2" />

    <div class="row">
      <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
        {{ $t('elements.properties.border.width') }}
      </div>
      <div class="col-12">
        <cvt-slider
          id="slider-default"
          :tooltips-enabled="true"
          :step-marks-enabled="true"
          class="slider"
          :min="1"
          :max="10"
          :value="borderWidth"
          :step="1"
          @change="(value) => updateBorderProps('borderWidth', value)"
        />
      </div>
    </div>
    <div class="divider mt-2" />

    <div class="row">
      <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
        {{ $t('elements.properties.border.radius') }}
      </div>
      <div class="col-12">
        <cvt-slider
          id="slider-default"
          class="slider"
          :tooltips-enabled="true"
          :step-marks-enabled="true"
          :min="0"
          :max="100"
          :value="borderRadius"
          :step="5"
          @change="(value) => updateBorderProps('borderRadius', value)"
        />
      </div>
    </div>
    <div class="divider mt-2" />

    <div class="row">
      <div class="col">
        <h6>{{ $t('elements.properties.shadow.label') }}</h6>
      </div>
      <div class="col-12">
        <cvt-radio-group orientation="horizontal" :initial-value="shadow">
          <cvt-radio-button
            size="md"
            value="shadow-none"
            :shape="RadioButtonShape"
            @radioClick="(value) => shadow = value"
          >
            {{ $t('elements.properties.option.none') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="shadow"
            :shape="RadioButtonShape"
            @radioClick="(value) => shadow = value"
          >
            {{ $t('elements.properties.shadow.normal') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="shadow-lg"
            :shape="RadioButtonShape"
            @radioClick="(value) => shadow = value"
          >
            {{ $t('elements.properties.shadow.deep') }}
          </cvt-radio-button>
        </cvt-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import { mapGetters, mapState } from "vuex";
import * as getters from "@/js/store/modules/getters";

export default {
  name: "StyleOption",
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  data () {
    return {
      borderStyles: ['none', 'solid', 'dashed', 'dotted', 'double', 'groove'],
      positionDefVal: 'set',
      pv: {
        all: 'set',
        top: '',
        btm: '',
        lft: '',
        ryt: '',
      },
      borderPositionKey: 1,
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED
    }),
    ...mapState('globalTheme', {
      RadioButtonShape: ({ globalTheme }) =>
        globalTheme.RadioButtonShape !== undefined
          ? globalTheme.RadioButtonShape
          : '',
    }),
    borderStyle: {
      get() {
        return _.get(this, 'selected.borderStyle', "")
      },
    },
    borderWidth: {
      get() {
        return _.get(this, 'selected.borderWidth', 0)
      },
    },
    borderRadius: {
      get() {
        return _.get(this, 'selected.borderRadius', 0)
      },
    },
    borderColor: {
      get() {
        return _.get(this, 'selected.borderColor', "")
      },
      set: _.throttle(function ({ target }) {
        this.selected.updateProp('borderColor', target.value)
      }, 300),
    },
    shadow: {
      get() {
        return _.get(this, 'selected.shadow', "")
      },
      set(value) {
        this.selected.updateProp('shadow', value)
      }
    },
    borderPosition: {
      get() {
        return _.get(this, 'selected.borderPosition', {})
      },
      set(value) {
        return this.selected.updateProp('borderPosition', value)
      },
    },
    backgroundPosition: {
      get() {
        return _.get(this, 'selected.background.imagePosition', {})
      },
      set(value) {
        return this.selected.updateProp('background/imagePosition', value )
      },
    },
    disableAllBorder() {
      return this.pv.top !== '' ||
        this.pv.btm !== '' ||
        this.pv.lft !== '' ||
        this.pv.ryt !== '';
    },
  },
  created() {
    if (this.borderPosition && Object.keys(this.borderPosition).length) {
      this.pv = this.borderPosition
    }
    this.setDefaultAllBorder()
  },
  methods: {
    setDefaultAllBorder () {
      if (
        this.pv.top !== '' ||
        this.pv.btm !== '' ||
        this.pv.lft !== '' ||
        this.pv.ryt !== ''
      ) {
        this.pv.all = ''
      } else {
        this.pv.all = this.positionDefVal
      }

      this.borderPosition = this.pv
    },
    updatePosition (key, from) {
      if (key === '') {
        this.pv[from] = this.positionDefVal
      } else {
        this.pv[from] = ''
      }

      this.setDefaultAllBorder()
      this.borderPositionKey++;
    },
    updateBorderProps: _.throttle(function (key, value) {
      return this.selected.updateProp(key, value)
    }, 300),
  }
};
</script>

<style scoped>

</style>