
<div>
  <div v-if="visible" class="loader-wrapper">
    <component
      :is="currentLoader"
      :msg="msg"
      :svg="svg"
      :svg-path="svgPath"
    />
  </div>
</div>
