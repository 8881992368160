import msgpack from 'msgpack-lite'
import * as mutations from '../../store/modules/mutations'
import * as actions from '../../store/modules/actions'
import {ViewModeCommand} from "../../commands/ViewModeCommand";


class HistoryTracker {
    async undo (dispatch) {

    }

    async redo (dispatch) {

    }

    async apply(commit, dispatch, action) {
        commit(mutations.HISTORY_IGNORE)
        await this[action](dispatch)

        commit(mutations.HISTORY_LISTEN)
        await dispatch(actions.LAYOUT_REFRESH)
    }
}


class DeltaRefChangeTracker extends HistoryTracker {
    constructor(currentVersion, previousVersion) {
        super()
        this._previousVersion = msgpack.encode(previousVersion)
        this._currentVersion = msgpack.encode(currentVersion)
    }

    get currentVersion() {
        return msgpack.decode(this._currentVersion)
    }
    get previousVersion() {
        return msgpack.decode(this._previousVersion)
    }

    async undo (dispatch) {
        await dispatch(
            actions.DELTA_SET_CURRENT_REV,
            this.previousVersion
        )
    }

    async redo (dispatch) {
        await dispatch(
            actions.DELTA_SET_CURRENT_REV,
            this.currentVersion
        )
    }
}


class ViewModeChangeTracker extends HistoryTracker {
    constructor(currentVersion, previousVersion, currentDeltaChange) {
        super()
        this.previewViewMode = previousVersion
        this.currentViewMode = currentVersion
        this._currentDeltaChange = msgpack.encode(currentDeltaChange)
    }

    get currentVersion() {
        return msgpack.decode(this._currentDeltaChange)
    }

    get previousVersion() {
        return undefined
    }

    async undo (dispatch) {
        await this.handleAction(dispatch, this.previewViewMode)
    }

    async redo (dispatch) {
        await this.handleAction(dispatch, this.currentViewMode)
    }

    async handleAction (dispatch, viewMode, message=null) {
        const _message = message || `Switching to ${viewMode} view`
        await dispatch('loader/start', _message)
        await dispatch(
            actions.CHANGE_VIEW_MODE,
            viewMode
        )
        setTimeout(async () => {
            dispatch('loader/stop')
        }, 4000)
    }
}


class PopupActiveTrack extends ViewModeChangeTracker {
    constructor(currentVersion, previousVersion, currentDeltaChange, message=null) {
        super(currentVersion, previousVersion, currentDeltaChange);
        this.message = message || 'Switching to builder'
    }

    async redo (dispatch) {
        await this.undo(dispatch)
    }

    async undo (dispatch) {
        await this.handleAction(dispatch, this.previewViewMode, this.message)
    }
}


export class BuilderUndoRedoManger {
    constructor(dispatch, commit) {
        this.currentSnapshot = null
        this._undoStack = []
        this._redoStack = []
        this.dispatch = dispatch
        this.commit = commit
        this._listen = false
        this._initialized = false
    }

    get listen () {
        return this._listen
    }

    get initialized () {
        return this._initialized
    }

    get canUndo () {
        return this._undoStack.length - 1 > 0
    }

    get canRedo () {
        return this._redoStack.length > 0
    }

    set listen (val) {
        this._listen = val
    }

    async undoHandler(levels = 1) {
        const historyTracker = this._undoStack.pop()
        await historyTracker.apply(this.commit, this.dispatch, 'undo')
        this._redoStack.push(historyTracker)
    }

    async apply(action)  {
        if (this.listen === false) return

        this.commit(mutations.HISTORY_IGNORE)

        if (action === 'undo' && this.canUndo) {
            await this.undoHandler()
        }

        if (action === 'redo' && this.canRedo) {
            await this.redoHandler()
        }
    }

    async redoHandler(levels = 1) {
        const historyTracker = this._redoStack.pop()
        await historyTracker.apply(this.commit, this.dispatch, 'redo')
        this._undoStack.push(historyTracker)
    }

    historyDeltaRefPush(currentVersion){
        if (this.listen === false) return
        // get last item
        const lastHistoryTracker = this._undoStack[this._undoStack.length - 1]

        this._undoStack.push(
            new DeltaRefChangeTracker(currentVersion, lastHistoryTracker.currentVersion)
        )
        this._redoStack = []
    }

    viewModeChanged(currentViewMode, previousViewMode) {
        if (this.listen === false) return
        // get last item
        const lastHistoryTracker = this._undoStack[this._undoStack.length - 1]

        this._undoStack.push(
            new ViewModeChangeTracker(currentViewMode, previousViewMode, lastHistoryTracker.currentVersion)
        )
        this._redoStack = []
    }

    async historyPopupBuilderActive(popUpBuilder, currentViewMode) {
        if (popUpBuilder) {
            // if popup builder is active, we need to save the state of the builder as an undoredo item
            // which would be restored when the popup is closed
            const lastHistoryTracker = this._undoStack[this._undoStack.length - 1]
            this._undoStack.push(
                new PopupActiveTrack(currentViewMode, currentViewMode, lastHistoryTracker.currentVersion)
            )
            // stop history from further undo_redo capture
            this.commit(mutations.HISTORY_IGNORE)
            return
        }
        // restore last state when the popBuilder is closed and enable history undo_redo capture
        const lastHistoryTracker = this._undoStack.pop()
        await lastHistoryTracker.apply(this.commit, this.dispatch, 'undo')
    }

    historyInitialRev (currentVersion, commit, dispatch){
        this.commit = commit
        this.dispatch = dispatch
        this._undoStack = []
        this._redoStack = []
        this._undoStack.push(new DeltaRefChangeTracker(currentVersion, {}))
        this._initialized = true
    }
}


export class BuilderUndoRedoMangerV3 {
    constructor(dispatch, commit) {
        this._undoCommands = []
        this._redoCommands = []
        this.dispatch = dispatch
        this.commit = commit
        this._listen = false
        this._initialized = false
    }

    get initialized () {
        return this._initialized
    }

    clear () {
        this._redoCommands = []
        this._undoCommands = []
    }

    get listen () {
        return this._listen
    }

    set listen (val) {
        this._listen = val
    }

    get canUndo () {
        return this._undoCommands.length > 0;
    }

    get canRedo () {
        return this._redoCommands.length > 0;
    }

    async redoHandler(levels = 1){
        for (let i = 1; i <= levels; i++) {
            if (this._redoCommands.length !== 0) {
                const command = this._redoCommands.pop();
                await command.execute();
                command.updateVDom('execute')
                this._undoCommands.push(command);
            }
        }
    }

    async undoHandler(levels = 1) {
        for (let i = 1; i <= levels; i++) {
            if (this._undoCommands.length !== 0) {
                const command = this._undoCommands.pop();
                await command.unExecute();
                command.updateVDom('unExecute')
                this._redoCommands.push(command);
            }
        }
    }

    async apply(action)  {
        if (action === 'undo' && this.canUndo) {
            await this.undoHandler()
        }

        else if (action === 'redo' && this.canRedo) {
            await this.redoHandler()
        }
    }

    async pushCommand (command) {
        await command.execute()
        if (this.listen) {
            this._undoCommands.push(command);
            this._redoCommands = []
        }
        command.updateVDom('execute')
    }

    historyDeltaRefPush(currentVersion) {

    }

    historyInitialRev (currentVersion, commit, dispatch){
        this.clear()
        this._initialized = true
    }

    async historyPopupBuilderActive(popUpBuilder, currentViewMode) {
        if (popUpBuilder) {
            // if popup builder is active, we need to save the state of the builder as an undoredo item
            // which would be restored when the popup is closed
            this._undoCommands.push(
                new ViewModeCommand(this.dispatch, currentViewMode, currentViewMode, 'Switching to builder')
            )
            // stop history from further undo_redo capture
            this.listen = false
            return
        }
        // restore last state when the popBuilder is closed and enable history undo_redo capture
        const command = this._undoCommands.pop()
        await command.unExecute()
        this.listen = true
    }
}
