export default function loadIntercom (APP_ID) {
  return new Promise((resolve, reject) => {
    var w = window
    var ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
      resolve(w.Intercom)
    } else {
      var d = document
      var i = function () { i.c(arguments) }
      i.q = []
      i.c = function (args) { i.q.push(args) }
      w.Intercom = i
      var s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://widget.intercom.io/widget/' + APP_ID
      s.onload = () => resolve(w.Intercom)
      s.onerror = (err) => reject(err)
      var x = d.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    }
  })
}
