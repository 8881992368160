import _ from 'lodash'
import Vue from '../../lib/utils/vue'
import VueI18n from 'vue-i18n'
import { styled } from '@egoist/vue-emotion'
import * as getters from '../../../builder/src/js/store/modules/getters'
import * as actions from '../../../builder/src/js/store/modules/actions'
import * as mutations from '../../../builder/src/js/store/modules/mutations'
import { PopUpMarketplace, V1PopupSite } from '../../../builder/src/js/store/services/api'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { CvtPopupLeftNavBar } from '../organisms/popup-left-nav'
import { CvtPopupSearchFilter } from '../organisms/popup-search'
import { CvtPopupListItem } from '../organisms/popup-list-item'
import { CvtMarketplaceCard } from '../organisms/marketplace-card'
import CvtLoadingMessage from '../molecules/LoadingMessage'
import { cvtRouteNodes } from "@/js/utils/routes"

const popupSiteAPI = new V1PopupSite()
const popupMarketplace = new PopUpMarketplace()

Vue.use(VueI18n)


const getContentWrap = () => styled('div')`
  display: flex;
  justify-content: space-between;
  height: 100vh;
`

const getPopupLeftNavBarWrap = () => styled('div')`
  position: fixed;
  top: 64px;
`

const getMarketplaceTemplateGroupAlt = () => styled('div')`
  background-color: #fff;
  overflow-y: inherit;
  overflow-x: hidden;
  margin-top: 30px;
  width: 100%;
  padding: 3% 0% 3% 320px;

  @media (max-width: 768px) {
    padding: 3% 0% 3% 150px;
  }
`

const getMarketplaceCard = () => styled(CvtMarketplaceCard)`
  width: 320px !important;
  height: 240px !important;

  > div > div {
    border: 1px solid #f0f2f6;
    width: 320px;
    height: 240px;
    background-color: #fff;
    border-radius: 7px;

    button {
      border-radius: 0px !important;
    }
  }
`

const getMarketplaceTemplate = () => styled('div')`
  margin-top: 70px;
`

const getMarketplaceTemplates = () => styled('div')`
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`

const getSearchFilter = () => styled(CvtPopupSearchFilter)`
  position: fixed;
  width: calc(100vw - 320px);
  z-index: 2;
  top: 64px;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    width: calc(100vw - 150px);
  }
`

const removeDuplicateTemplates = (templateArr) => {
  const uniqueIds = [];
  const unique = templateArr.filter(element => {
  const isDuplicate = uniqueIds.includes(element.id);
    if (!isDuplicate) {
      uniqueIds.push(element.id);
      return true;
    }
    return false;
  });
  return unique;
}

export const CvtPopupChooseTemplate = {
  name: 'CvtPopupChooseTemplate',
  data() {
    return {
      locked: false,
      yourPopups: {
        id: 'your-popups',
        label: this.$t('marketplace.left_side_bar.popups.your_popups'),
        index: 23,
      },
      archivedPopups: {
        id: 'archived-popups',
        label: this.$t('marketplace.left_side_bar.popups.archive_popups'),
        index: 24,
      },
      beforeEnter(e) {
        e.style.opacity = 0
      },
      yourPopupsList: [],
      yourPopupsListAll: [],
      archivedPopupsList: [],
      yourPopupsSitesListAll: [],
      marketplacePagination: { limit: 10, offset: 0 },
      archivedPopupsPagination: { limit: 10, offset: 0 },
      personalPopupsPagination: { limit: 10, offset: 0 },
      loading: true,
      showArchiveDialog: false,
      archivePopup: null,
      archiving: false,
      templatesCount: {},
      searchQuery: '',
      isCreatedSelected: false,
      isLastUpdatedSelected: false,
      isPublished: false,
      filterToEcommerce: false,
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      marketplacePopups: getters.POPUP_MARKETPLACE_TEMPLATES,
      allCategories: getters.GET_ALL_CATEGORIES,
    }),
    ...mapState('route', {
      query: (state) => state.query,
    }),
    ...mapState('globalTheme', {
      marketplaceConfig: ({ marketplaceConfig }) => marketplaceConfig,
      iconConfig: ({ iconConfig }) => iconConfig,
      leftNavType: ({ iconConfig }) => iconConfig?.marketplaceCategories?.theme || 'standard',
      leftNavItemsAllPopupsText: ({ leftNavConfig }) => leftNavConfig.marketplace.navItems.allTemplatesText.text,
      StartFromScratchHover: ({ globalTheme }) => globalTheme.StartFromScratchHover ?? true,
      ShowCount: ({ globalTheme }) => globalTheme.ShowCount ?? true,
      activeCategory: ({ activeCategory }) => activeCategory,
    }),
    getDynamicCategories(){
      return this.allCategories.length ? this.allCategories : []
    },
    allTemplateActive() {
      return this.activeCategory === null
    },
    yourPopupsActive() {
      return this.activeCategory === this.yourPopups.id
    },
    archivedPopupsActive() {
      return this.activeCategory === this.archivedPopups.id
    },
    cardType() {
      return this.yourPopupsActive ? 'myTemplate' : (this.archivedPopupsActive ? 'archivedTemplate' : 'popupMarketplace')
    },
    filteredPopups() {
      if (this.yourPopupsActive) {
        const yourPopups = removeDuplicateTemplates(this.yourPopupsList)
        return yourPopups;
      }
      if (this.archivedPopupsActive) {
        const archivedPopups = removeDuplicateTemplates(this.archivedPopupsList)
        return archivedPopups;
      }
      return _.chain(this.marketplacePopups)
        .filter((template) => {
          let templateCategoryIds = template.categories.map((category) => category.id)
          return this.activeCategory ? _.includes(templateCategoryIds , this.activeCategory) : true
        })
        .value()
    },
    filteredCategories() {
      return this.categories.filter((category) =>  {
          return (
            (category.id == null) ||
            (this.templatesCount[category.id] && this.templatesCount[category.id] > 0)
          )
        }
      )
    },
    categories() {
      let categories = []

      if (this.config.ECOMMERCE && this.filterToEcommerce) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.activeCategory = 'e-commerce'

        categories = [
          {
            id: 'e-commerce',
            label: 'E-Commerce',
            index: 22
          },
          {
            id: 'your-templates',
            label: this.leftNavItemsYourPagesTemplatesText,
            index: 23,
          },
          this.archivedTemplates,
        ]

      } else {

        categories = [
          {
            id: null,
            label: this.$t(this.leftNavItemsAllPopupsText),
            index: 0
          },
          ...this.getDynamicCategories,
          {
            ...this.yourPopups,
            index: this.getDynamicCategories.length + 1
          },
          {
            ...this.archivedPopups,
            index: this.getDynamicCategories.length + 2
          },
        ]
      }

      return categories
    },
    isGridBuilder() {
      return this.config.GRID_BUILDER_ENABLED
    },
    isTemplateActionsRightSidebarEnabled() {
      return this.config.TEMPLATE_ACTIONS_RIGHT_SIDEBAR
    },
    isTabsUiEnabled() {
      return this.config.CHOOSE_A_TEMPLATE_TABS_UI
    },
    isStartFromScratchCardEnabled(){
      return this.config.START_FROM_SCRATCH_ENABLED
    }
  },

  async created() {
    Promise.all([
      popupMarketplace.countPopupTemplate({}),
      this.loadMoreMarketplacePopups(),
      this.loadMorePersonalPopups(),
      this.loadMoreArchivedPopups(),
    ])
    .then((values) => {
      const [ templateCountsData ] = values
      this.templatesCount = templateCountsData.data
    })
    .catch((error) => {
      console.log('Something went wrong !', error);
    })

    const { categories = '' } = this.query
    this.filterToEcommerce = categories.includes('e-commerce')
    this.fetchAllCategories()
    // Set configurations for tenants if they exist
    this.setTheme(this.config.GLOBAL_THEME)
    this.setTopNavConfig(this.config.TOP_NAV_CONFIG)
    this.setLeftNavConfig(this.config.LEFT_NAV_CONFIG)
    this.setMarketplaceConfig(this.config.MARKETPLACE_CONFIG)
    this.setIconConfig(this.config.ICON_CONFIG)
    this.setGlobalButtonConfig(this.config.GLOBAL_BUTTON_CONFIG)
    this.setGlobalCheckBoxConfig(this.config.GLOBAL_CHECKBOX_CONFIG)
    this.setSdaPopupSettingsConfig(this.config.SA_POPUP_SETTINGS_CONFIG)
    // Set configurations for tenants End
  },
  methods: {
    ...mapActions({
      createPopupSiteFromTemplate: actions.POPUP_MARKETPLACE_CREATE_SITE_FROM_TEMPLATE, // also used for GetStarted Button (From-Scratch)
      sdaPopupSiteClone: actions.SDA_POPUP_SITE_CLONE,
      sdaPopupSiteArchive: actions.SDA_POPUP_SITE_ARCHIVE,
      sdaPopupSiteRestore: actions.SDA_POPUP_SITE_RESTORE,
      loadMarketplacePopups: actions.POPUP_MARKETPLACE_LOAD_TEMPLATES,
      mixpanelTrack: actions.MIXPANEL_TRACK,
      fetchAllCategories: actions.FETCH_ALL_CATEGORIES
    }),
    ...mapMutations({
      hideEditorNameDialog: mutations.HIDE_EDITOR_NAME_DIALOG,
      showEditorNameDialog: mutations.SHOW_EDITOR_NAME_DIALOG,
    }),
    ...mapActions('globalTheme', {
      setTheme: 'setTheme',
      setTopNavConfig: 'setTopNavConfig',
      setLeftNavConfig: 'setLeftNavConfig',
      setMarketplaceConfig: 'setMarketplaceConfig',
      setIconConfig: 'setIconConfig',
      setActiveCategory: 'setActiveCategory',
      setGlobalButtonConfig: 'setGlobalButtonConfig',
      setGlobalCheckBoxConfig: 'setGlobalCheckBoxConfig',
      setSdaPopupSettingsConfig: 'setSdaPopupSettingsConfig',
    }),
    async copyPopupBtnClicked(popup) {
      const popupSite = await this.sdaPopupSiteClone(popup.id)

      this.$router.push({
        name: cvtRouteNodes.popupEditorDesign,
        params: {
          popupId: popupSite.id,
        },
      })
    },
    editSiteBtnClicked(popup) {
      this.editPopupSite(popup)
    },
    archivePopupBtnClicked(popup) {
      this.openArchivePrompt(popup)
    },
    loadMorePopups() {
      if (this.yourPopupsActive) {
        return this.loadMorePersonalPopups
      }
      if (this.archivedTemplatesActive) {
        return this.loadMoreArchivedPopups
      }
      return this.loadMoreMarketplacePopups
    },
    onSearchDone(searchQuery) {
      this.searchQuery = searchQuery

      this.resetAndLoadYourPages()
    },
    onSortParameter(sortMethod, isSorted) {
      if (sortMethod === 'created') {
        this.isCreatedSelected = isSorted
        if (this.isCreatedSelected) {
          this.isLastUpdatedSelected = false
        }
      } else if (sortMethod === 'lastUpdated') {
        this.isLastUpdatedSelected = isSorted
        if (this.isLastUpdatedSelected) {
          this.isCreatedSelected = false
        }
      } else if (sortMethod === 'published') {
        this.isPublished = isSorted
      }

      this.resetAndLoadYourPages()
    },

    resetAndLoadYourPages() {
      this.personalPopupsPagination.offset = 0
      this.yourPopupsListAll = []
      this.yourPopupsList = []

      this.loadMorePersonalPopups()
    },
    moreActionsBtnClicked(event, popupSite) {
      //TODO: Implement more actions
      console.log('moreActionsBtnClicked', event);
    },
    async startFromScratch() {
      console.debug('clicked start from scratch')
      if (this.locked) {
        console.debug('start from scratch is locked')
        console.error('You already clicked this once... something went wrong. If you are having issues refresh your browser and try again.')
        return
      }
      this.locked = true
      try {
        const { data } = await this.createPopupSiteFromTemplate()
        this.showEditorNameDialog()
        this.$router.push({
          name: cvtRouteNodes.popupEditorDesign,
          params: {
            popupId: data.id,
          },
        })
      } catch (err) {
        this.hideEditorNameDialog()
        this.locked = false
        throw err
      }
    },
    selectCategory(cat) {
      this.setActiveCategory(cat.id)
    },
    async useTemplate(popupTemplateId) {
      console.debug('using template', popupTemplateId)
      const { data } = await this.createPopupSiteFromTemplate(popupTemplateId)
      console.debug('cloned template', data)
      this.showEditorNameDialog()

      const { id: popupId } = data

      this.$router.push({
        name: cvtRouteNodes.popupEditorDesign,
        params: {
          popupId: popupId,
        },
      })

      if (this.yourPopupsActive) {
        this.mixpanelTrack({
          event: 'Copy Popup Template',
          category: 'templates',
          data: { template_id: popupId },
        })
      } else if (this.archivedPopupsActive) {
        this.mixpanelTrack({
          event: 'Restore Archived Popup Template',
          category: 'templates',
          data: { template_id: popupId },
        })
      } else {
        this.mixpanelTrack({
          event: 'Use This Popup Template',
          category: 'templates',
          data: { template_id: popupId },
        })
      }
    },
    editPopupSite(popup) {
      this.mixpanelTrack({
        event: 'Edit Popup Site',
        category: 'sites',
        data: { popupSiteId: popup.id },
      });

      this.$router.push({
        name: cvtRouteNodes.popupEditorDesign,
        params: {
          popupId: popup.id,
        },
      })
    },
    async openArchivePrompt(popup) {
      this.showArchiveDialog = true
      this.archivePopup = popup
    },
    async onArchiveTemplate() {

      const { id, popupName } = this.archivePopup
      this.archiving = true

      await this.sdaPopupSiteArchive(id)

      this.yourPopupsList = this.yourPopupsList.filter((popupSite) => popupSite.id !== id)
      this.yourPopupsListAll = this.yourPopupsListAll.filter((popupSite) => popupSite.id !== id)
      this.archivedPopupsList.unshift(this.archivePopup)

      this.$message({ type: 'info', message: `Archived: ${popupName}` })

      this.archivePopup = null
      this.archiving = false
      this.showArchiveDialog = false
    },
    abortTemplateArchive() {
      this.archivePopup = null
      this.showArchiveDialog = false
    },
    async restoreArchivedPopup(popup) {
      const { id, popupName } = popup
      await this.sdaPopupSiteRestore(id);

      this.archivedTemplatesList = this.archivedTemplatesList.filter((archivedPopup) => archivedPopup.id !== id)
      this.yourTemplatesList.unshift(template);

      this.$message({ type: 'info', message: `Restored: ${popupName}` });
    },
    // This name is a bit confusing since this function
    // is the first template loading function to run...
    // it retrieves based on the pagination value so it
    // works regardless of initial or subsequent runs.
    async loadMoreMarketplacePopups() {
      try {
        this.loading = true
        await this.loadMarketplacePopups({...this.marketplacePagination})
        this.marketplacePagination.offset += this.marketplacePagination.limit
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },
    async loadMorePersonalPopups() {
      let submitData = {
        ...this.personalPopupsPagination,
        dateCreated: this.isCreatedSelected ? 1 : 0,
        dateUpdated: this.isLastUpdatedSelected ? 1 : 0,
        isPublished: this.isPublished ? 1 : 0,
      }

      if(this.searchQuery) {
        if(this.searchQuery.trim().length >= 3) {
          submitData['search'] = this.searchQuery.trim()
        } else {
          this.$message({ type: 'warning', message: `Search characters should not be less than 3` });
          return;
        }
      }

      try {
        this.loading = true

        const { data } = await popupSiteAPI.all(submitData) // Search Popups API
        this.yourPopupsListAll = this.yourPopupsListAll.concat(data)

        this.personalPopupsPagination.offset += this.personalPopupsPagination.limit
        this.yourPopupsList = this.yourPopupsListAll.slice(0, this.personalPopupsPagination.offset + this.personalPopupsPagination.limit)

        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },
    async loadMoreArchivedPopups() {
      this.loading = true
      try {
        const { data } = await popupSiteAPI.all({
          ...this.archivedPopupsPagination,
          archived: true,
        })
        this.archivedPopupsList = this.archivedPopupsList.concat(data)
        this.archivedPopupsPagination.offset +=
          this.archivedPopupsPagination.limit
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },
  },
  render: function (h) {
    const ContentWrap = getContentWrap()
    const PopupLeftNavBarWrap = getPopupLeftNavBarWrap()
    const MarketplaceTemplateGroupAlt = getMarketplaceTemplateGroupAlt()
    const MarketplaceTemplate = getMarketplaceTemplate()
    const MarketplaceTemplates = getMarketplaceTemplates()
    const SearchFilter = getSearchFilter()
    const MarketPlaceCard = getMarketplaceCard()

    return (
      <div>
        <PopupLeftNavBarWrap>
          <CvtPopupLeftNavBar
            usage={'landingPage'}
            type={this.leftNavType}
            navigationItems={this.filteredCategories}
            initialActiveId={this.categories[0].id}
            mode={this.mode}
            onNavItemClicked={this.selectCategory}
            count={this.templatesCount}
            ShowCount={this.ShowCount}
          />
        </PopupLeftNavBarWrap>

        <ContentWrap>

          <MarketplaceTemplateGroupAlt>
            {this.yourPopupsActive && (
              <SearchFilter
                mode={this.mode}
                displayTemplateViewFormatBtn={this.isTemplateActionsRightSidebarEnabled}
                defaultSearch={this.searchQuery}
                searchDone={this.onSearchDone}
                onSortParameter={this.onSortParameter}
              />
            )}

            {!this.yourPopupsActive && (
              <MarketplaceTemplates>
                {(!this.archivedPopupsActive && this.isStartFromScratchCardEnabled) &&
                  <MarketPlaceCard
                    mode={this.mode}
                    hover={this.StartFromScratchHover}
                    usage={"startFromScratch"}
                    id={"mpCard-getStarted"}
                    getStartedButton={this.marketplaceConfig.marketplaceCard.getStartedButton}
                    onStartFromScratch={this.startFromScratch}
                  />
                }
                {this.filteredPopups.map((popup, idx) => (
                  <MarketPlaceCard
                    key={idx}
                    id={`mpCard-t-${idx}`}
                    mode={this.mode}
                    usage={this.cardType}
                    item={popup}
                    text={popup.popupName}
                    hover={this.StartFromScratchHover}
                    thumbnail={popup.thumbnail}
                    thumbnailAlt={popup.name}
                    getStartedButton={this.marketplaceConfig.marketplaceCard.getStartedButton}
                    archiveButton={this.marketplaceConfig.marketplaceCard.archiveButton}
                    previewButton={{}}
                    onGetStarted={() => this.useTemplate(popup.id)}
                    onRestoreTemplate={() => this.restoreArchivedPopup(popup)}
                    vIntersectedOnce={{
                      onIntersected: this.loadMorePopups(),
                      shouldObserve: this.filteredPopups.length - 1 === idx,
                    }}
                  />
                ))}
              </MarketplaceTemplates>
            )}

            {this.yourPopupsActive && (
              <MarketplaceTemplate>
                {this.filteredPopups.map((popup, idx) => (
                  <CvtPopupListItem
                    key={popup.id}
                    id={`mpCard-yp-${idx}`}
                    mode={this.mode}
                    templateTitle={popup.popupName}
                    siteStatus={popup.state}
                    onCopyTemplateBtnClicked={() =>this.copyPopupBtnClicked(popup)}
                    onEditSiteBtnClicked={() => this.editSiteBtnClicked(popup)}
                    onArchiveTemplateBtnClicked={() =>this.archivePopupBtnClicked(popup)}
                    onMoreActionsBtnClicked={(data) => this.moreActionsBtnClicked(data, popup)}
                    vIntersectedOnce={{
                      onIntersected: this.loadMorePopups(),
                      shouldObserve: this.filteredPopups.length - 1 === idx,
                    }}
                  >
                  </CvtPopupListItem>
                ))}
              </MarketplaceTemplate>
            )}

            <CvtLoadingMessage message={"Loading"} display={this.loading} />

          </MarketplaceTemplateGroupAlt>
        </ContentWrap>

        <cvt-dialog
          show={this.showArchiveDialog}
          height-auto={true}
          size={'sm'}
          onClose={() => this.abortTemplateArchive()}
        >
          <cvt-alert class="w-100" color="warning" icon="exclamation-triangle" >
            <p>
              You are archiving: { this.archivePopup && this.archivePopup.popupName }. <br/>
              Are you sure you want to continue? You can recover this under the Archived Templates if you need it back.
            </p>
          </cvt-alert>

          <template slot="title">
            <h5>Archive Popup Template</h5>
          </template>

          <template slot="modalFooter">
            <div class="d-flex">
              <cvt-button
                class={"mr-1"}
                loading={this.archiving}
                color={"light"}
                text={this.$t('marketplace.dialog.archive.cta.cancel')}
                onClick={(event) => this.abortTemplateArchive()}
              />
              <cvt-button
                color={"warning"}
                loading={this.archiving}
                text={this.$t('marketplace.dialog.archive.cta.submit')}
                onClick={(event) => this.onArchiveTemplate()}
              />
            </div>
          </template>
        </cvt-dialog>

      </div>
    )
  },
}
