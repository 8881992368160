import { V1ECommerce } from '@/js/store/services/api'
import * as bb from 'bluebird'
import * as getters from './getters'

const eCommerce = new V1ECommerce()

export default {
  namespaced: true,
  state: {
    products: [],
    plans: [],
    skus: [],
    productsDialogVisible: false
  },
  mutations: {
    openProductsDialog (state) {
      state.productsDialogVisible = true
    },
    closeProductsDialog (state) {
      state.productsDialogVisible = false
    }
  },
  actions: {
    async listProducts ({ state, rootGetters }, params) {
      return state.products = await eCommerce.listProducts(params)
    },

    async listProductsWithSkus ({ state, rootGetters }, params) {
      return state.products = await bb.map(await eCommerce.listProducts(params), async (product) => {
        product.skus = await eCommerce.listSkusByProductId(product.id, params)
        return product
      })
    },

    async searchProducts ({ state, rootGetters }, params) {
      return state.products = await eCommerce.searchProducts(params)
    },

    async searchProductsWithSkus ({ state, rootGetters }, params) {
      return state.products = await eCommerce.searchProductsWithSkus(params)
    },

    async listSkusByProductId ({ state, rootGetters }, { productId, params }) {
      return state.skus = await eCommerce.listSkusByProductId(productId, params)
    },

    async listPlansByProductId ({ state, rootGetters }, { productId, params }) {
      return state.plans = await eCommerce.listPlansByProductId(productId, params)
    },

    async createProductPlan ({ state, rootGetters }, { productId, options }) {
      return eCommerce.createProductPlan(productId, options)
    },

    async deleteProductById ({ state, rootGetters }, productId) {
      return eCommerce.deleteProductById(productId)
    },

    async createProduct ({ state, rootGetters }, options) {
      return eCommerce.createProduct(options)
    }

  }
}
