import {VDomCommands} from "./VDomCommand";
import {VueGridStackComponent, FBVNodeComponent} from "../../../types";


export class AddCommand extends VDomCommands {
    private _widget: object;
    private _addedGridStackNodeElement?: FBVNodeComponent;

    constructor(gridContainerVNodeId: string, widget: object) {
        super(gridContainerVNodeId);
        this._widget = widget
        // _addedGridStackNodeElement: for the purpose of redo and undo,
        // we want to track already created vueElement, just in case they are part of another undo or redo action
        this._addedGridStackNodeElement = undefined
    }

    getVueComponent(): VueGridStackComponent {
        super.getVueComponent();
        const vNodeIdGridComponent = (this._vNodeIdVueComponent_ as VueGridStackComponent)
        if (!vNodeIdGridComponent?.getGrid) {
            // Element must be a gridstack element [GridContainer or SubGridContainer]
            throw 'Invalid Element Found'
        }
        return vNodeIdGridComponent
    }

    get$emitData() {
        return {name: 'AddCommand', forceBuild: false}
    }

    get hasChanges (): boolean {
        return true
    }

    get firebaseRef() {
        return this.getVueComponent().firebaseRef()
    }

    get $children() {
        return this.getVueComponent().$children
    }

    get $nextTick () {
        return this.getVueComponent().$nextTick
    }

    async execute() {
        // save widget to database
        this.firebaseRef.push(this._widget).then((value: any) => {
            this.$nextTick(() => {
                this._addedGridStackNodeElement = this.getVueComponent().makeWidget(
                    this.$children[this.$children.length - 1]
                );
                this._addedGridStackNodeElement.$el.scrollIntoView({
                    behavior: 'smooth',
                })
                if (this._addedGridStackNodeElement?.resize) {
                    this._addedGridStackNodeElement.resize()
                }
            })
        })
    }

    async unExecute() {
        if (this._addedGridStackNodeElement) {
            // save to database
            await this.firebaseRef.removeNode(this._addedGridStackNodeElement.idx())
            // Remove Element from Grid
            this.getVueComponent().getGrid().removeWidget(this._addedGridStackNodeElement.$el)
        }
    }
}
