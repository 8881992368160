import * as _ from 'lodash'
import * as getters from './getters'
import * as mutations from './mutations'

const ELEMENT = 'element'
const LAYOUT = 'layout'

const MODES = [ELEMENT, LAYOUT]

export default {
  namespaced: true,
  state: {
    mode: ELEMENT,
  },
  getters: {
    message({ mode }) {
      return mode === ELEMENT ? 'ELEMENT MODE' : 'LAYOUT MODE'
    },
    layoutMode({ mode }) {
      return mode === LAYOUT
    },
  },
  mutations: {
    toggleLayoutMode(state, on) {
      state.mode = on ? LAYOUT : ELEMENT
    },
    setMode(state, mode) {
      if (!_.includes(MODES, mode)) {
        throw new Error(`cannot set mode: ${mode} must be one of ${MODES}`)
      }

      state.mode = mode
    },
  },
}
