import { relativePath } from '../../services/firebase.service'
import { database } from '@/js/store/services/firebase.service'


const db = database()
const FIREBASE_ROOT_PATH = 'checkoutPageConfigurations'

export class ConfigPageKey {
    constructor(sitePath, configName) {
        this.dbRef = db.ref(sitePath).child(FIREBASE_ROOT_PATH).child(configName)
        this._data = {data: {props: {['.path']: relativePath(this.dbRef), ['.key']: null}}}
    }

    subscribe() {
        this.dbRef.on('value', snap => {
            const data = snap.val()
            if (data) {
                this._data = snap.val()
                // this._data.data.props['.path'] = relativePath(this.dbRef)
            }
        })
    }

    load(defaults) {
        return new Promise(res => {
            this.dbRef.on('value', snap => {
                let data = snap.val()
                if (!data) {
                    this.dbRef.set({...defaults})
                    data = defaults
                }
                this._data = { ...data}
                this._data.data.props['.path'] = relativePath(this.dbRef)
                this.dbRef.off('value')
                res()
            })
            console.debug('Config Page Key', relativePath(this.dbRef))
        })
    }

    get data() {
        return this._data.data
    }

    destroy () {
        this.dbRef.off('value')
    }
}