
<div>
  <org-header></org-header>

  <div
    class="row m-0 p-0 signup-content-wrap flex-xs-wrap-reverse flex-sm-wrap-reverse"
  >
    <div
      class="col-sm-12 col-md-12 col-lg-6 m-0 p-0 signup-content-one"
    ></div>
    <div class="col-sm-12 col-md-12 col-lg-6 m-0 p-0 signup-content-two">
      <h1 class="signup-main-heading">
        You're moments away from experiencing Convrrt.
      </h1>

      <p class="supporting-txt">
        Every SaaS company is unique and has different needs. Complete the
        form and a product expert will be in touch.
      </p>

      <h3 class="sub-heading">
        Get started with your customized sandbox account
      </h3>

      <div class="signup-btns-wrap">
        <div class="btn-inner-wrap">
          <a href="/api/auth/sso/oauth?provider=google&on_success=/onboarding/information">
            <button class="signup-btns">
              <img
                class="social-logo-google"
                src="/img/organization/google-icon.svg"
                alt="Google Logo"
              />
              <span>Google</span>
            </button>
          </a>
        </div>

        <div class="btn-inner-wrap">
          <a href="/api/auth/sso/oauth?provider=microsoft&on_success=/onboarding/information">
            <button class="signup-btns">
              <img
                class="social-logo-microsoft"
                src="/img/organization/microsoft-icon.svg"
                alt="Google Logo"
              />
              <span>Microsoft</span>
            </button>
          </a>
        </div>
      </div>
      <div class="signin-option-label">
        <p>OR</p>
      </div>
      <div class="signin-form-wrap">
        <form
        class="signin-form-inner-wrap"
        @submit.prevent="onSignin">
            <input
              :value="email"
              class="signin-email-wrap"
              type="text"
              placeholder="name@work-email.com"
              @input="(e) => updateEmail(e)"
            />
            <cvt-button
              class="signin-btn"
              :color="''"
              :mode="'LIGHT'"
              text="Sign In With Email"
              :full-width="true"
              :loading="loading"
              submit
            >
            </cvt-button>
        </form>
      </div>
      <p class="another-sup-txt-wrap">
        <span class="another-sup-span">
          Sign In With Email We’ll email you a magic code for a password-free sign in. Or you can </span>
          <a class="another-sup-anchor" href="#">
          sign in manually instead.
        </a>
      </p>

      <p class="acc-create-sup-txt-wrap">
        <span class="acc-create-sup-span"
          >By creating an account, you agree to our &nbsp;</span
        >
        <a class="acc-create-sup-anchor" href="#">Terms of Service &nbsp;</a>
        <span class="acc-create-sup-span">and &nbsp;</span>
        <a class="acc-create-sup-anchor" href="#">Privacy Policy.</a>
      </p>
    </div>
  </div>

  <div class="row m-0 p-0 saas-content-wrap">
    <div class="col-xs-12 saas-heading-wrap">
      <h3 class="saas-heading">
        SaaS leaders trust Convrrt to scale faster.
      </h3>
    </div>

    <div class="col-xs-12 saas-leader-imgs-wrap">
      <img
        src="/img/organization/client-logos/sendinblue-logo.png"
        alt="Sendinblue Logo"
      />
      <img
        src="/img/organization/client-logos/keap-logo.png"
        alt="Keap Logo"
      />
      <img
        src="/img/organization/client-logos/sharpspring-logo.png"
        alt="Sharpspring Logo"
      />
      <img
        src="/img/organization/client-logos/integrate-logo.png"
        alt="Integrate Logo"
      />
      <img
        src="/img/organization/client-logos/instamojo-logo.png"
        alt="Instamojo Logo"
      />
    </div>
  </div>

  <signup-footer></signup-footer>
</div>
