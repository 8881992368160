import { styled } from '@egoist/vue-emotion'
import { mapState } from '../../../../builder/node_modules/vuex'
import { CvtIcon } from '../icon/icon'

let Spacing;

const getBtnWrapper = () => styled('div')`
  position: absolute;
  display: flex;
  width: 100%;
  z-index: 99;
  top: 0px;
  height: 0px;

  ${(props) => {
    let styles = ``
    if (props.closeBtnPosition === 'start') {
      styles += `justify-content: start;`;
    } else if (props.closeBtnPosition === 'end') {
      styles += `justify-content: end;`;
    }
    return styles
  }}
`

const getBtn = () => styled('div')`
  background-color: #fff;
  padding: ${Spacing.SPACING_2 + 1}px ${Spacing.SPACING_8 + 1.5}px ${Spacing.SPACING_2}px ${Spacing.SPACING_8 + 1}px;
  margin: ${Spacing.SPACING_8}px ${Spacing.SPACING_18 + Spacing.SPACING_2}px ${Spacing.SPACING_8}px ${Spacing.SPACING_8 + 1}px;
  border: 1px solid black;
  border-radius: 500%;
  height: ${Spacing.SPACING_32}px;
`

const getIcon = () => styled(CvtIcon)``


export const SapCloseBtn = {
  name: 'SapCloseBtn',
  props: {
    closeBtnPosition: {
      type: String,
      default: 'end'
    },
  },
  data() {
    return {}
  },
  created() {
    Spacing = this.Spacing
  },
  computed: {
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
    }),
  },
  render: function (h) {
    const BtnWrapper = getBtnWrapper()
    const Button = getBtn()
    const Icon = getIcon()

    return (
      <BtnWrapper closeBtnPosition={this.closeBtnPosition}>
        <Button>
          <Icon icon={'times'}></Icon>
        </Button>
      </BtnWrapper>
    )
  },
}
