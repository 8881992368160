import { clientSpecificCustomParam } from '../../utils/helpers'

import { V1CRM } from '../services/api'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

let crm = new V1CRM()

export default {
  state: {
    visible: false,
    fieldGroups: [],
    showNameDialog: false,
    showSiteSettings: false,
    showIntegrations: false,
  },
  getters: {
    [getters.FORM_EDITOR_VISIBLE]({ visible }) {
      return visible
    },
    [getters.FORM_EDITOR_FIELD_GROUPS]({ fieldGroups }) {
      return fieldGroups
    },
    [getters.EDITOR_NAME_DIALOG]({ showNameDialog }) {
      return showNameDialog
    },
    [getters.SITE_SETTINGS]({ showSiteSettings }) {
      return showSiteSettings
    },
    [getters.INTEGRATIONS_DIALOG_ENABLED]({ showIntegrations }) {
      return showIntegrations
    },
  },
  mutations: {
    [mutations.FORM_EDITOR_SHOW](state) {
      state.visible = true
    },
    [mutations.FORM_EDITOR_HIDE](state) {
      state.visible = false
    },
    [mutations.FORM_EDITOR_UPDATE_CUSTOM_FIELDS](state, fields) {
      state.fieldGroups = fields
    },
    [mutations.SHOW_EDITOR_NAME_DIALOG](state) {
      state.showNameDialog = true
    },
    [mutations.HIDE_EDITOR_NAME_DIALOG](state) {
      state.showNameDialog = false
    },
    [mutations.SHOW_SITE_SETTINGS](state) {
      state.showSiteSettings = true
    },
    [mutations.HIDE_SITE_SETTINGS](state) {
      state.showSiteSettings = false
    },
    [mutations.TRIGGER_INTEGRATIONS_DIALOG](state, visibility) {
      state.showIntegrations = visibility
    },
  },
  actions: {
    [actions.FORM_EDITOR_LOAD_FIELDS]({ state, commit, rootGetters }) {
      if (state.fieldGroups.length !== 0) return

      const config = rootGetters[getters.AUTH_GET_USER_CONFIG]
      const params = clientSpecificCustomParam(config)
      return crm.formFields(params).then(({ data }) => {
        commit(mutations.FORM_EDITOR_UPDATE_CUSTOM_FIELDS, data)
      })
    },
    [actions.FORM_EDITOR_UPDATE_FIELD]({ state, commit, dispatch }, data) {},
  },
}
