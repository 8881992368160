
<div
  :id="vnodeId"
  :data-vnode-id="vnodeId"
  class="cvt-embedded-form"
  :class="classes"
  :draggable="!isGridBuilder ? 'true' : 'false'"
  @click="select"
  @mouseover="target"
  @contextmenu.prevent.stop="showContextMenu"
  @dblclick.stop.prevent
  @dragstart="dragstart"
  @dragend="dragend"
>
  <img
    v-if="!embedURL"
    class="img-placeholder"
    src="/img/form-placeholder.svg"
  />

    <iframe
      v-if="embedURL"
      :key="embedURL"
      class="w-100 h-100"
      style="pointer-events: none"
      tabindex="-1"
      :src="embedURL"
      frameborder={0}
    />
  <drag-and-drop-zone :z-index="dropzoneZIndex" @dropped="addElementToSide" />
  <template v-if="showActionButtonsOnClick && !isGridBuilder">
    <!--THis is only here because of freshwork in v1 gridbuilder,
    once they migrate to v2 or v3, it will become irrelevant-->
    <action-buttons
      v-if="selected"
      @duplicate="duplicateAndValidate"
      @remove="removeAndValidate"
    />
  </template>
</div>
