import * as _ from 'lodash'

const color = function (props, weight) {
  switch (props.themeing) {
    case 'dark':
      return `color: ${this.Colors.BASIC[weight]};`
    case 'light':
      return `color: ${this.Colors.BASIC[100]};`
    case 'primary':
      return `color: ${this.Colors.PRIMARY[weight]};`
    case 'success':
      return `color: ${this.Colors.SUCCESS[weight]};`
    case 'info':
      return `color: ${this.Colors.INFO[weight]};`
    case 'warning':
      return `color: ${this.Colors.WARNING[weight]};`
    case 'danger':
      return `color: ${this.Colors.DANGER[weight]};`
    case 'smoke':
      return `color: ${this.Colors.SMOKE[weight]};`
    default:
      return `color: ${this.Colors.PRIMARY[weight]};`
  }
}

const ShadowDepth = function (props) {
  return {
    INSET: {
      SHADOW_1: 'inset 0px 0.5px 4px',
      SHADOW_1_ALPHA: this.ShadowAlpha.INSET.ALPHA1,
    },
    100: {
      SHADOW_1: '0px 1px 2px',
      SHADOW_1_ALPHA: this.ShadowAlpha.REGULAR.ALPHA1[props.mode],
      SHADOW_2: '0px 0px 1px',
      SHADOW_2_ALPHA: this.ShadowAlpha.DEEP.ALPHA2,
    },
    200: {
      SHADOW_1: '0px 2px 4px',
      SHADOW_1_ALPHA: this.ShadowAlpha.REGULAR.ALPHA1[props.mode],
      SHADOW_2: '0px 0px 1px',
      SHADOW_2_ALPHA: this.ShadowAlpha.REGULAR.ALPHA2,
    },
    300: {
      SHADOW_1: '0px 4px 8px',
      SHADOW_1_ALPHA: this.ShadowAlpha.REGULAR.ALPHA1[props.mode],
      SHADOW_2: '0px 0px 2px',
      SHADOW_2_ALPHA: this.ShadowAlpha.REGULAR.ALPHA2,
    },
    400: {
      SHADOW_1: '0px 8px 16px',
      SHADOW_1_ALPHA: this.ShadowAlpha.REGULAR.ALPHA1[props.mode],
      SHADOW_2: '0px 2px 4px',
      SHADOW_2_ALPHA: this.ShadowAlpha.REGULAR.ALPHA2,
    },
    500: {
      SHADOW_1: '0px 16px 24px',
      SHADOW_1_ALPHA: this.ShadowAlpha.REGULAR.ALPHA1[props.mode],
      SHADOW_2: '0px 2px 8px',
      SHADOW_2_ALPHA: this.ShadowAlpha.REGULAR.ALPHA2,
    },
    600: {
      SHADOW_1: '0px 20px 32px',
      SHADOW_1_ALPHA: this.ShadowAlpha.DEEP.ALPHA1[props.mode],
      SHADOW_2: '0px 2px 8px',
      SHADOW_2_ALPHA: this.ShadowAlpha.DEEP.ALPHA2,
    },
  }
}

const backgroundColor = function (props, weight) {
  if (props.showGradient) {
    if (props.removeGradient === true) {
      switch (props.themeing) {
        case 'dark':
          return `background: linear-gradient(180deg, ${this.Colors.BASIC[weight]} 0%, ${this.Colors.BASIC[weight]} 100%);`
        case 'light':
          return `background: linear-gradient(180deg, ${this.Colors.BASIC[100]} 0%, ${this.Colors.BASIC[100]} 100%);`
        case 'primary':
          return `background: linear-gradient(180deg, ${this.Colors.PRIMARY[weight]} 0%, ${this.Colors.PRIMARY[weight]} 100%);`
        case 'success':
          return `background: linear-gradient(180deg, ${this.Colors.SUCCESS[weight]} 0%, ${this.Colors.SUCCESS[weight]} 100%);`
        case 'info':
          return `background: linear-gradient(180deg, ${this.Colors.INFO[weight]} 0%, ${this.Colors.INFO[weight]} 100%);`
        case 'warning':
          return `background: linear-gradient(180deg, ${this.Colors.WARNING[weight]} 0%, ${this.Colors.WARNING[weight]} 100%);`
        case 'danger':
          return `background: linear-gradient(180deg, ${this.Colors.DANGER[weight]} 0%, ${this.Colors.DANGER[weight]} 100%);`
        case 'smoke':
          return `background: linear-gradient(180deg, ${this.Colors.SMOKE[weight]} 0%, ${this.Colors.SMOKE[weight]} 100%);`
        default:
          return `background: linear-gradient(180deg, ${this.Colors.PRIMARY[weight]} 0%, ${this.Colors.PRIMARY[weight]} 100%);`
      }
    } else {
      switch (props.themeing) {
        case 'dark':
          return `background: linear-gradient(${this.GradientColors.BASIC[weight]});`
        case 'light':
          return `background: linear-gradient(${this.GradientColors.BASIC[100]});`
        case 'primary':
          return `background: linear-gradient(${this.GradientColors.PRIMARY[weight]});`
        case 'success':
          return `background: linear-gradient(${this.GradientColors.SUCCESS[weight]});`
        case 'info':
          return `background: linear-gradient(${this.GradientColors.INFO[weight]});`
        case 'warning':
          return `background: linear-gradient(${this.GradientColors.WARNING[weight]});`
        case 'danger':
          return `background: linear-gradient(${this.GradientColors.DANGER[weight]});`
        default:
          return `background: linear-gradient(${this.GradientColors.PRIMARY[weight]});`
      }
    }
  } else {
    switch (props.themeing) {
      case 'dark':
        return `background-color: ${this.Colors.BASIC[weight]};`
      case 'light':
        return `background-color: ${this.Colors.BASIC[100]};`
      case 'primary':
        return `background-color: ${this.Colors.PRIMARY[weight]};`
      case 'success':
        return `background-color: ${this.Colors.SUCCESS[weight]};`
      case 'info':
        return `background-color: ${this.Colors.INFO[weight]};`
      case 'warning':
        return `background-color: ${this.Colors.WARNING[weight]};`
      case 'danger':
        return `background-color: ${this.Colors.DANGER[weight]};`
      case 'smoke':
        return `background-color: ${this.Colors.SMOKE[weight]};`
      default:
        return `background-color: ${this.Colors.PRIMARY[weight]};`
    }
  }
}

const borderColor = function (props, weight) {
  switch (props.themeing) {
    case 'dark':
      return `border-color: ${this.Colors.BASIC[weight]};`
    case 'light':
      return `border-color: ${this.Colors.BASIC[100]};`
    case 'primary':
      return `border-color: ${this.Colors.PRIMARY[weight]};`
    case 'success':
      return `border-color: ${this.Colors.SUCCESS[weight]};`
    case 'info':
      return `border-color: ${this.Colors.INFO[weight]};`
    case 'warning':
      return `border-color: ${this.Colors.WARNING[weight]};`
    case 'danger':
      return `border-color: ${this.Colors.DANGER[weight]};`
    case 'smoke':
      return `border-color: ${this.Colors.SMOKE[weight]};`
    default:
      return `border-color: ${this.Colors.PRIMARY[weight]};`
  }
}

const RgbaValues = function (props, position, weight) {
  let rgbaVal = 'rgba('
  const sd = ShadowDepth.call(this, props)[weight]

  if (props.mode === 'LIGHT') {
    if (position === 1) rgbaVal += '96, 97, 112, '
    if (position === 2) rgbaVal += '40, 41, 61, '
  } else {
    rgbaVal += '0, 0, 0, '
  }

  let alpha = position === 1 ? sd.SHADOW_1_ALPHA : sd.SHADOW_2_ALPHA

  return (rgbaVal += alpha + ')')
}

const elevation = function (props, weight) {
  const sd = ShadowDepth.call(this, props)[weight]

  if (weight === 'INSET') {
    return `box-shadow: ${sd.SHADOW_1} ${RgbaValues.call(
      this,
      props,
      1,
      weight,
    )};`
  }

  return `box-shadow: ${sd.SHADOW_1} ${RgbaValues.call(
    this,
    props,
    1,
    weight,
  )}, ${sd.SHADOW_2} ${RgbaValues.call(this, props, 2, weight)};`
}

const textFontSize = function (sz) {
  let size
  switch (sz) {
    case 'xs':
      size = this.FontSize.FONT_SIZE_10
      break
    case 'sm':
      size = this.FontSize.FONT_SIZE_12
      break
    case 'md':
      size = this.FontSize.FONT_SIZE_14
      break
    case 'lg':
      size = this.FontSize.FONT_SIZE_16
      break
    case 'xl':
      size = this.FontSize.FONT_SIZE_18
      break
    default:
      size = this.FontSize.FONT_SIZE_14
  }
  return `font-size: ${size}px;`
}

const textFontWeight = function (weight) {
  let wg = ''
  switch (weight) {
    case 'light':
    case 'LIGHT':
      wg = this.FontWeight.LIGHT
      break
    case 'REGULAR':
    case 'regular':
      wg = this.FontWeight.REGULAR
      break
    case 'SEMI_BOLD':
    case 'semi_bold':
      wg = this.FontWeight.SEMI_BOLD
      break
    case 'BOLD':
    case 'bold':
      wg = this.FontWeight.BOLD
      break
    default:
      wg = this.FontWeight.BOLD
      break
  }
  return `font-weight: ${wg};`
}

const textLineHeight = function (sz) {
  let l_height
  switch (sz) {
    case 'xs':
      l_height = this.LineHeight.LINE_HEIGHT_14
      break
    case 'sm':
      l_height = this.LineHeight.LINE_HEIGHT_14
      break
    case 'md':
      l_height = this.LineHeight.LINE_HEIGHT_12
      break
    case 'lg':
      l_height = this.LineHeight.LINE_HEIGHT_18
      break
    case 'xl':
      l_height = this.LineHeight.LINE_HEIGHT_16
      break
    default:
      l_height = this.LineHeight.LINE_HEIGHT_12
  }
  return `line-height: ${l_height}px;`
}

const stateConfigMerge = function (c1, c2) {
  return _.merge(c1, c2)
}

export default {
  color,
  backgroundColor,
  borderColor,
  elevation,
  textFontSize,
  textFontWeight,
  textLineHeight,
  stateConfigMerge,
}
