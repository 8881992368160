<template>
    <div v-show="componentToShow" class="advanced-popover-menu px-3 py-2">
      <component :is="activeMenuOption" v-if="componentToShow"  />
    </div>
</template>

<script>
import * as _ from 'lodash'
import * as getters from '../../../store/modules/getters'
import CForm from '../nodes/CForm.vue'
import Block from '../nodes/Block.vue'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
const WHITELISTED_COMPONENTS = ['Column']
const RegisteredComponent = ''

export default {
  // This file produces a warning: Unknown custom element: <CForm> - did you register the component correctly? For recursive components, make sure to provide the "name" option.
  // This file produces a warning: Unknown custom element: <Block> - did you register the component correctly? For recursive components, make sure to provide the "name" option.
  // The only fix I could find was through imports, don't know enough about component registration for our system to solve otherwise.
  components: {
    CForm,
    Block
  },
  computed: {
    ...mapState('advancedPopoverMenu', {
      enabled: 'visible'
    }),
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      open: getters.ADVANCED_MENU_OPEN,
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN,
      config: getters.AUTH_GET_USER_CONFIG
    }),
    visible () {
      return this.config.ADVANCED_POPOVER_ENABLED && this.enabled
    },
    componentToShow () {
      return this.visible && this.activeMenuOption && WHITELISTED_COMPONENTS.includes(this.activeMenuOption) 
    },
    activeMenuOption () {
      return _.get(this, 'selected.name', '')
    },
  },
  mounted () {
    this.initPopover(this.$el)
  },
  methods: {
    ...mapMutations('advancedPopoverMenu', {
      initPopover: 'init',
      attachToElement: 'attach'
    })
  }

}
</script>

<style lang="scss">
    @import "@/scss/utils";
    .advanced-popover-menu {
        height: 50px;
        width: 300px;
        border-radius: 5px;
        background: #0BB89C;
        z-index: 1005;
    }
</style>
