
<cvt-collapse
  :accordion="true"
  :theme="mode === 'LIGHT' ? 'light' : 'dark'"
  :prepend-chevron="prependChevron"
>
  <cvt-collapse-item
    name="Properties"
    :title="$t('elements.properties.properties.label')"
    :initial-active="true"
  >
    <div class="row no-gutters">
      <div class="col-12">
        <cvt-select
          mode="dropdown"
          :label="$t('element.property.countdown_timer.type')"
          :background-mode="mode"
          :value="countdownTimerType"
          @input="
            (value) => updateCountdownOptions('countdownTimerType', value)
          "
        >
          <option value="dateAndTimeBased">Date & Time Based</option>
          <option value="urgencyBased">Urgency Based</option>
        </cvt-select>
      </div>

      <template v-if="dateAndTimeBasedTimer">
        <div class="divider" />

        <div class="col-12">
          <cvt-input
            type="date"
            :label="$t('element.property.countdown_timer.end_date.label')"
            :min="minEndDate"
            :validation-status="endDateValidationStatus"
            :valid-message="
              $t('element.property.countdown_timer.end_date.valid_message')
            "
            :invalid-message="
              $t('element.property.countdown_timer.end_date.invalid_message')
            "
            :value="endDate"
            @input="(e) => updateCountdownOptions('endDate', e.target.value)"
          />
        </div>

        <div class="divider" />

        <div class="col-12">
          <cvt-input
            type="time"
            :label="$t('element.property.countdown_timer.end_time.label')"
            :min="minEndTime"
            :validation-status="endTimeValidationStatus"
            :valid-message="
              $t('element.property.countdown_timer.end_time.valid_message')
            "
            :invalid-message="
              $t('element.property.countdown_timer.end_time.invalid_message')
            "
            :value="endTime"
            @input="(e) => updateCountdownOptions('endTime', e.target.value)"
          />
        </div>
      </template>

      <template v-if="urgencyBasedTimer">
        <div class="divider" />
        <div class="col-12">
          <cvt-select
            mode="dropdown"
            :label="
              $t('element.property.countdown_timer.short_end_time.label')
            "
            :background-mode="mode"
            :value="shortEndTime"
            @input="(value) => updateUrgencyBasedTime('shortEndTime', value)"
          >
            <optgroup label="Mins">
              <option value="0" :selected="true" disabled>Select Time</option>
              <option value="5">5 mins</option>
              <option value="10">10 mins</option>
              <option value="15">15 mins</option>
              <option value="20">20 mins</option>
            </optgroup>
            <optgroup label="Days">
              <option value="1440">1 day</option>
              <option value="2880">2 days</option>
              <option value="7200">5 days</option>
              <option value="14400">10 days</option>
              <option value="21600">15 days</option>
              <option value="28800">20 days</option>
            </optgroup>
          </cvt-select>
        </div>
      </template>

      <div class="divider" />

      <div v-if="dateAndTimeBasedTimer" class="col-12">
        <cvt-select
          mode="dropdown"
          :label="$t('element.property.countdown_timer.timezone')"
          :background-mode="mode"
          :value="timeZoneOffset"
          @input="(value) => updateCountdownOptions('timeZoneOffset', value)"
        >
          <option
            v-for="datum in countriesTimezonesData"
            :key="datum.utcOffset"
            :value="datum.utcOffset"
          >
            {{ datum.cityNameWithUtcOffset }}
          </option>
        </cvt-select>
        <div class="divider" />
      </div>

      <div :key="countdownTimerType" class="col-12">
        <cvt-select
          mode="dropdown"
          :label="$t('element.property.countdown_timer.expire_action')"
          :background-mode="mode"
          :value="expireAction"
          @input="(value) => updateCountdownOptions('expireAction', value)"
        >
          <option v-if="urgencyBasedTimer" value="show_message">
            Show Message
          </option>
          <option value="redirect_to_a_page_in_funnel">
            Redirect to a page in funnel
          </option>
          <option value="redirect_to_url">Redirect to URL</option>
        </cvt-select>

        <div class="divider-no-border my-5"></div>

        <div v-if="expireAction === 'show_message' && urgencyBasedTimer">
          <cvt-input
            type="textarea"
            :label="
              $t('element.property.countdown_timer.expire_time_message')
            "
            :placeholder="'Type your message here'"
            :value="expireActionMessage"
            @input="
              (event) =>
                updateCountdownOptions(
                  'expireActionMessage',
                  event.target.value,
                )
            "
          />
        </div>
        <div v-else-if="expireAction === 'redirect_to_a_page_in_funnel'">
          <page-list
            :selected-page="expireActionSelectedPage"
            @change="
              (value) =>
                updateCountdownOptions('expireActionSelectedPage', value)
            "
          />
        </div>
        <div v-else-if="expireAction === 'redirect_to_url'">
          <url-input
            :label="$t('element.property.button.alt.url')"
            placeholder="https://www.example.com"
            :value="expireActionRedirectionUrl"
            @input="
              (value) =>
                updateCountdownOptions('expireActionRedirectionUrl', value)
            "
          />
        </div>
      </div>
    </div>
  </cvt-collapse-item>

  <cvt-collapse-item
    v-if="dateAndTimeBasedTimer"
    name="Design"
    :title="$t('elements.properties.design.label')"
  >
    <div class="row no-gutters">
      <div class="col-12">
        <h6 :class="textColorClass">
          {{ $t('element.property.countdown_timer.theme') }}
        </h6>
      </div>
      <div class="col-12 mt-2">
        <div
          v-for="datum in themeDesignsData"
          :key="datum.imgUrl"
          class="cdt-theme-img-wrap my-2"
          :class="datum.class"
          @click="updateCountdownOptions('themeStyle', datum.designTitle)"
        >
          <img class="cdt-theme-img" :src="datum.imgUrl" alt="" />
        </div>
      </div>
    </div>
  </cvt-collapse-item>
</cvt-collapse>
