
<div>
  <h1>Button</h1>
  <div
    v-for="(value, key) in shape"
    :key="key"
  >
    <h4>{{ key }}</h4>
    <div class="d-flex mb-2">
      <cvt-button
        v-for="(color, index) in colors"
        :key="index"
        :color="color"
        :text="index"
        :shape="value"
        class="ml-2 mr-2"
      />
    </div>
  </div>
  <div>
    <h4>Icon</h4>
    <div class="d-flex mb-2">
      <cvt-button
        v-for="(color, index) in colors"
        :key="color"
        :color="color"
        :text="index"
        icon="user"
        shape="pill"
        class="ml-2 mr-2"
      />
    </div>
  </div>
  <h1 class="mt-2">
    Button Icon
  </h1>
  <div
    v-for="(colorOfActionIcon, index) in colors"
    :key="index"
  >
    <h4>{{ index }}</h4>
    <div class="d-flex mb-2">
      <cvt-button
        v-for="iconMode in iconModes"
        :key="iconMode"
        :mode-of-action-icon="iconMode"
        :color-of-action-icon="colorOfActionIcon"
        :action-icon="true"
        icon="user"
        color="light"
        shape="pill"
        class="m-2"
      />
    </div>
  </div>
  <div>
    <h4>Size</h4>
    <div class="d-flex mb-2">
      <div
        v-for="size in sizes"
        :key="size"
      >
        <cvt-button
          :size="size"
          mode-of-action-icon="medium"
          color-of-action-icon="warning"
          :action-icon="true"
          icon="user"
          color="light"
          shape="pill"
          class="m-2"
        />
      </div>
    </div>
  </div>
</div>
