<template>
  <cvt-collapse :accordion="true" :theme="mode === 'LIGHT' ? 'light' : 'dark'" :prepend-chevron="prependChevron">
    <cvt-collapse-item title="Settings" name="settings" :initial-active="true">

      <div class="row mt-2">
        <div class="col">
          <h6>Show Product Image</h6>
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="showImage"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('showImage')"
          ></cvt-switch>
        </div>
      </div>

      <div class="divider"></div>

      <div class="row mt-2">
        <div class="col">
          <h6>Show Product Name</h6>
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="showName"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('showName')"
          ></cvt-switch>
        </div>
      </div>

      <div class="divider"></div>

      <div class="row mt-2">
        <div class="col">
          <h6>Show Product Price</h6>
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="showPrice"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('showPrice')"
          ></cvt-switch>
        </div>
      </div>

      <div class="divider"></div>

      <div class="row mt-2">
        <div class="col">
          <h6>Show Product Description</h6>
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="showDesc"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('showDesc')"
          ></cvt-switch>
        </div>
      </div>

      <div class="divider"></div>

      <product></product>
    </cvt-collapse-item>
    <cvt-collapse-item :title="$t('elements.properties.spacing.label')" name="spacing">
      <spacing-ctrl></spacing-ctrl>
    </cvt-collapse-item>
  </cvt-collapse>
</template>

<script>
  import SpacingCtrl from './SpacingCtrl.vue'
  import Product from './Product.vue'
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import * as getters from '../../../store/modules/getters'
  import * as actions from '../../../store/modules/actions'

  export default {
    components: {
      Product,
      SpacingCtrl
    },
    props: {
      mode: {
        type: String,
        default: 'LIGHT'
      },
      prependChevron: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        activeTab: 'settings'
      }
    },
    computed: {
      ...mapGetters({
        selected: getters.SELECTOR_SELECTED
      }),
      showImage: {
        get () {
          return this.selected.showImage
        },
        set (value) {
          return this.updateProp({key: 'showImage', value})
        }
      },
      showName: {
        get () {
          return this.selected.showName
        },
        set (value) {
          return this.updateProp({key: 'showName', value})
        }
      },
      showPrice: {
        get () {
          return this.selected.showPrice
        },
        set (value) {
          return this.updateProp({key: 'showPrice', value})
        }
      },
      showDesc: {
        get () {
          return this.selected.showDesc
        },
        set (value) {
          return this.updateProp({key: 'showDesc', value})
        }
      }
    },
    methods: {
      ...mapActions({
        updateProp: actions.SELECTOR_UPDATE_PROP
      }),
      onSwitchToggle (property) {
        property == 'showImage'? this.showImage = !this.showImage : property == 'showName' ? this.showName = !this.showName : property == 'showDesc' ? this.showDesc = !this.showDesc : property == 'showPrice' ? this.showPrice = !this.showPrice : ''
      }
    }
  }
</script>

<style lang="scss">
</style>
