import Vue from '../../../lib/utils/vue'
import { id } from '../../../lib/utils/common'
import { styled } from '@egoist/vue-emotion'
import { CvtIcon } from '../icon/icon'
import * as getters from "../../../../builder/src/js/store/modules/getters"
import { mapGetters,mapState } from '../../../../builder/node_modules/vuex'
import { VueEmotion } from '@egoist/vue-emotion'
import Util from './util'

Vue.use(VueEmotion)

let Spacing, FontFamily, Border
let textFontWeight, textLineHeight, textFontSize, color, borderColor

const getCollapseTinyItem = () => { return styled('div')`
  padding: ${Spacing.SPACING_8}px 0;
`
}
const getCollapseTinyItemHeader = () => { return styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: ${FontFamily.DEFAULT};
  cursor: pointer;
  outline: none;
  background-color: transparent;

  ${(props) => {
    let padding = `padding: 0;`
    let fontSize = textFontSize('sm')
    let fontWeight = textFontWeight()
    let textColor = Util.tinyCollapseHeaderColor(props)
    let lineHeight = textLineHeight('sm')

    return padding + fontSize + fontWeight + lineHeight + textColor
  }}

  &.is-active,
  &:hover {
    ${(props) => Util.textContentHoverColor(props)}
  }

  &::before {
    border-top: ${Border.SIZE_1}px solid;
    flex: 1;
    ${(props) => {
      let content = props.orientation == 'right' ? `content: ""` : ''

      return Util.borderTopColor(props) + content
    }}
  }

  &::after {
    border-top: ${Border.SIZE_1}px solid;
    flex: 1;
    ${(props) => {
      let content = props.orientation == 'left' ? `content: ""` : ''

      return Util.borderTopColor(props) + content
    }}
  }

  & > span {
    padding-left: ${Spacing.SPACING_8}px;
  }
`
}
const getCollapseItemWrap = () => { return styled('div')`
  &.smooth-enter-active,
  &.smooth-leave-active {
    transition: height 0.5s;
    overflow: hidden;
  }
  &.smooth-enter,
  &.smooth-leave-to {
    height: 0;
  }
`
}
const getCollapseItemContent = () => { return styled('div')`
  transition: 150ms ease-out;
  ${(props) => {
    let padding = `padding: ${Spacing.SPACING_16}px;`
    let fontSize = textFontSize('lg')
    let textColor = Util.textContentColor(props)
    let lineHeight = textLineHeight('lg')

    return padding + fontSize + lineHeight + textColor
  }}
`
}
const getCollapseItemArrow = () => { return styled(CvtIcon)`
  padding-right: ${Spacing.SPACING_8}px;
  padding-left: ${Spacing.SPACING_8}px;
`
}

export default {
  name: 'CvtCollapseTinyItem',
  data() {
    return {
      id: id()
    }
  },
  inject: ['collapse'],
  props: {
    title: {
      type: String,
      default: 'More Options'
    },
    name: {
      type: [String, Number]
    },
    orientation: {
      type: String,
      default: 'right'
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      color: getters.GLOBAL_STYLE_COLOR,
    }),
    ...mapState('globalTheme', {
      Spacing: ({globalTheme}) => globalTheme.Spacing,
      FontFamily: ({globalTheme}) => globalTheme.FontFamily,
      Border: ({globalTheme}) => globalTheme.Border,
      iconConfig: ({iconConfig}) => iconConfig,
    }),
    isActive() {
      return this.collapse.activeItems.indexOf(this.name) > -1
    },
    theme() {
      return this.collapse.$props.theme
    }
  },
  methods: {
    handleHeaderClick() {
      this.dispatch()
    },
    handleEnterClick() {
      this.dispatch()
    },
    dispatch() {
      const parent = this.$parent || this.$root
      parent.$emit.apply(parent, ['item-click', this])
    },
    beforeEnter: function (el) {
      el.style.height = '0'
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    afterEnter: function (el) {
      el.style.height = 'auto'
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leave: function (el) {
      /*
       * Add Height update operation to an event loop, so beforeLeave and leave does not conflict with each other.
       * Just to note that this will not delay the animation.
       * The animation will work as expected.
       */
      setTimeout(() => {
        el.style.height = '0'
      }, 0)
    }
  },
  created() {
    Spacing = this.Spacing
    FontFamily = this.FontFamily
    Border = this.Border

    textFontWeight = this.textFontWeight
    textLineHeight = this.textLineHeight
    textFontSize = this.textFontSize
    borderColor = this.borderColor
    color = this.color
  },
  render: function (h) {
    Util.self = this
    const CollapseTinyItem = getCollapseTinyItem()
    const CollapseTinyItemHeader = getCollapseTinyItemHeader()
    const CollapseItemWrap = getCollapseItemWrap()
    const CollapseItemContent = getCollapseItemContent()
    const CollapseItemArrow = getCollapseItemArrow()
    const collapseIcon = this.iconConfig?.propertiesSidebar?.collapse || 'chevron-down'
    const expandIcon = this.iconConfig?.propertiesSidebar?.expand || 'chevron-right'
    return (
      <CollapseTinyItem class={{ 'is-active': this.isActive }}>
        <div role='tab' aria-expanded={this.isActive}>
          <CollapseTinyItemHeader
            onClick={() => this.handleHeaderClick()}
            role='button'
            id={`cvt-collapse-head-${this.id}`}
            onKeyup={(e) => this.handleEnterClick(e)}
            class={{ 'is-active': this.isActive }}
            themeing={this.theme}
            orientation={this.orientation}
          >
            <span>{this.title}</span>
            <CollapseItemArrow
              icon={this.isActive ? collapseIcon : expandIcon}
            ></CollapseItemArrow>
          </CollapseTinyItemHeader>
        </div>

        <transition
          name='smooth'
          onBeforeEnter={(el) => this.beforeEnter(el)}
          onEnter={(el) => this.enter(el)}
          onBeforeLeave={(el) => this.beforeLeave(el)}
          onLeave={(el) => this.leave(el)}
          onAfterEnter={(el) => this.afterEnter(el)}
        >
          {this.isActive && (
            <CollapseItemWrap id={`cvt-collapse-content-${this.id}`}>
              <CollapseItemContent themeing={this.theme}>
                {this.$slots.default}
              </CollapseItemContent>
            </CollapseItemWrap>
          )}
        </transition>
      </CollapseTinyItem>
    )
  }
}
