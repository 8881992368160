import * as actions from '../../../store/modules/actions'
import OrgHeader from '../layouts/Header/Header.vue'
import { Organization } from '../../../store/services/api'
import SignupFooter from '../signupFooter/indexSignupFooter.vue'
import { mapActions } from 'vuex'
import { routePaths } from '../../../../../src/js/utils/routes'
import { mapMutations } from 'vuex'
import * as mutations from '../../../store/modules/mutations'

const organization = new Organization()

export default {
  components: {
    OrgHeader,
    SignupFooter,
  },
  props: {},
  data() {
    return {
      email: '',
      loading: false,
    }
  },
  created() {},
  methods: {
    ...mapActions({
      sendCode: actions.SEND_MFA_CODE,
    }),
    ...mapMutations({
      setSigninEmail: mutations.SET_SIGNIN_USER_EMAIL,
    }),
    updateEmail(e) {
      const { value } = e.target
      this.email = value
    },
    async onSignin() {
      try {
        const emailFormat =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        if (emailFormat.test(this.email)) {
          this.loading = true

          const payload = {
            to: this.email,
            channel: 'email',
            redirectURL: routePaths.onboardingInformation,
          }

          await this.sendCode(payload).then((res) => {
            this.setSigninEmail(this.email)

            this.$router.push({
              path: routePaths.verifySigninCode,
            })
          })
        } else {
          this.$message.error('Email is invalid')
        }
      } catch (err) {
        this.$message.error(err.response.data.errors[0].message)
      } finally {
        this.loading = false
      }
    },
  },
}
