import { FONT_WEIGHTS } from "@/js/lib/font";
import * as getters from "@/js/store/modules/getters";
import FroalaEditor from 'froala-editor/js/froala_editor.min';
import { Store } from "vuex";
import { Notification } from "element-ui";

const paragraphStyleOneFonts = ['font-style-title','font-style-subtitle', 'font-style-heading','font-style-alternativeHeading']
const paragraphStyleTwoFonts = ['font-style-lead','font-style-normalText','font-style-blockquote']

function setupFroalaWithOpenAI (store: Store<any>) {
    FroalaEditor.DefineIconTemplate('font_awesome_1', '<i role="presentation" class="icon fas fa-robot" aria-hidden="true"></i>');
    FroalaEditor.DefineIcon('chatGPTIcon', { NAME: 'robot', template: 'font_awesome_1'})

    FroalaEditor.RegisterCommand('chatGPT', {
        title: 'Ask chatGPT',
        icon: 'chatGPTIcon',
        undo: false,
        focus: false,
        refreshAfterCallback: false,
        callback: function () {
            const config = store.getters[getters.AUTH_GET_USER_CONFIG]
            if (!config.CHATGPT_API_KEY) {
                // @ts-ignore
                Notification.error({
                    title: 'Error',
                    message: 'CHATGPT is not Authorization for this account.',
                    position: 'bottom-right',
                })
                console.error('CHATGPT is not Authorization for this account.')
                return
            }
            const selection = this.selection.text()
            const data = {
                max_tokens: 256,
                temperature: 0,
                model: "gpt-3.5-turbo",
                messages: [
                    {
                        "role": "system",
                        "content": "You are an assistant."
                    },
                    {
                        "role": "user",
                        "content": selection
                    }
                ]
            }

            fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${config.CHATGPT_API_KEY}`
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((data) => {
                if (data.id) {
                    this.html.insert(data.choices[0].message.content)
                }else {
                    console.debug("CHATGPT: ", data.error)
                    // @ts-ignore
                    Notification.error({
                        title: 'Error',
                        message: 'Something went wrong',
                        position: 'bottom-right',
                    })
                }
            })
        },
    })
}

function paragraphCommandCallback(paragraphFontStyles: string[], cmdName: string) {
    function paragraphCommandCallbackDecorator(cmd, val) {
        if (cmd === cmdName) {

            let paragraphString = this.selection.element()

            if(val === 'font-style-title')
            {
                this.paragraphFormat.apply('h1');
            }
            else if(val === 'font-style-subtitle')
            {
                this.paragraphFormat.apply('h2');
            }
            else if(val === 'font-style-heading')
            {
                this.paragraphFormat.apply('h3');
            }
            else if(val === 'font-style-alternativeHeading')
            {
                this.paragraphFormat.apply('h4');
            }
            else if(val === 'font-style-lead')
            {
                this.paragraphFormat.apply('h5');
            }
            else if(val === 'font-style-normalText')
            {
                this.paragraphFormat.apply('p');
            }
            else if(val === 'font-style-blockquote')
            {
                this.paragraphFormat.apply('blockquote');
            }

            for(let style of paragraphFontStyles) {
                if(style === val) {
                    if(this?.node?.hasClass(paragraphString, style)) {
                        // do nothing
                    }
                    else {
                        this.paragraphStyle.apply(style)
                    }
                }
                else {
                    if(this?.node?.hasClass(paragraphString, style)) {
                        // check if it contains it, then remove
                        this.paragraphStyle.apply(style)
                    }
                }
            }
        }
    }
    return paragraphCommandCallbackDecorator
}

function RegisterDraftFroalaCommands () {
    let weights = {}
    FONT_WEIGHTS.forEach(font => {
        weights[font.value] = font.label
    })

    const paragraphFontStyles = ['font-style-title','font-style-subtitle', 'font-style-heading','font-style-alternativeHeading', 'font-style-lead','font-style-normalText','font-style-blockquote']

    FroalaEditor.DefineIcon('fontWeight', {NAME: 'text-width', template: 'font_awesome'});
    FroalaEditor.RegisterCommand('fontWeight', {
        title: 'Font Weight',
        type: 'dropdown',
        focus: true,
        undo: true,
        refreshAfterCallback: false,
        options: weights,
        callback: function (cmd, val) {
            this.format.applyStyle('font-weight', `${val};`)
        },
        // Callback on refresh.
        refresh: function ($btn) { },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) { }
    });

    FroalaEditor.RegisterCommand('customParagraphStyle', {
        title: 'Font Style',
        type: 'dropdown',
        icon: '&nbsp;&nbsp;Font Style&nbsp;&nbsp;&nbsp;&nbsp;', // this is a quick fix, because we want to show text in place of icon in toolbar
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        options: {
            'font-style-title': '<h1>Title</h1>',
            'font-style-subtitle': '<h2>SubTitle</h2>',
            'font-style-heading': '<h3>Heading</h3>',
            'font-style-alternativeHeading': '<h4>Alt. Heading</h4>',
            'font-style-lead': '<h5>Lead</h5>',
            'font-style-normalText': '<p>Normal Text</p>',
            'font-style-blockquote': '<blockquote>Blockquote</blockquote>',
        },
        callback: paragraphCommandCallback(paragraphFontStyles, 'customParagraphStyle'),
        // Callback on refresh.
        refresh: function ($btn) { },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) { }
    });

    FroalaEditor.RegisterCommand('customParagraphStyleOne', {
        title: 'Font Style',
        type: 'dropdown',
        icon: '&nbsp;&nbsp;Font Style&nbsp;&nbsp;&nbsp;&nbsp;', // this is a quick fix, because we want to show text in place of icon in toolbar
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        options: {
            'font-style-title': '<h1>Title</h1>',
            'font-style-subtitle': '<h2>SubTitle</h2>',
            'font-style-heading': '<h3>Heading</h3>',
            'font-style-alternativeHeading': '<h4>Alt. Heading</h4>',
        },
        callback: paragraphCommandCallback(paragraphStyleOneFonts, 'customParagraphStyleOne'),
        // Callback on refresh.
        refresh: function ($btn) { },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) { }
    });

    FroalaEditor.RegisterCommand('customParagraphStyleTwo', {
        title: 'Font Style',
        type: 'dropdown',
        icon: '&nbsp;&nbsp;Font Style&nbsp;&nbsp;&nbsp;&nbsp;', // this is a quick fix, because we want to show text in place of icon in toolbar
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        options: {
            'font-style-lead': '<h5>Lead</h5>',
            'font-style-normalText': '<p>Normal Text</p>',
            'font-style-blockquote': '<blockquote>Blockquote</blockquote>',
        },
        callback: paragraphCommandCallback(paragraphStyleTwoFonts, 'customParagraphStyleTwo'),
        // Callback on refresh.
        refresh: function ($btn) { },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) { }
    });

    FroalaEditor.DefineIcon('customList', {NAME: 'list', template: 'font_awesome'});
    FroalaEditor.RegisterCommand('customList', {
        title: 'Custom List',
        type: 'dropdown',
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        options: {
             default: '&#xf111;',
            'check':"&#xf00c;",
            'square-check':"&#xf14a;",
            'circle-check':"&#xf058;",
            'calendar-check':"&#xf274;",
            'phone':"&#xf095;",
            'mobile':"&#xf10b;",
            'phone-square':"&#xf098;",
            'whatsapp':"&#xf232;",
            'heart':"&#xf004;",
            'star':"&#xf005;",
            'arrow-right':"&#xf061;",
            'chevron-right':"&#xf054;",
            'angle-right':"&#xf105;",
            'share':"&#xf064;",
            'long-arrow-right':"&#xf178;",
            'dot-circle':"&#xf192;",
            'hand-point-right':"&#xf0a4;",
            'lightbulb':"&#xf0eb;",
            'bolt':"&#xf0e7;",
            'times':"&#xf00d;",
            'times-circle':"&#xf057;",
            'book':"&#xf02d;",
            'quote-left':"&#xf10d;",
        },
        callback: function (cmd, val) {
            this.format.apply('ul', {class: `cvt-clist ${val}`})
            this.cursor.enter(true);
            // this.cursor.backspace();
        },
        // Callback on refresh.
        refresh: function ($btn) {
        },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) {
        }
    });
}

export function setupFroala(store: Store<any>) {
    RegisterDraftFroalaCommands()
    setupFroalaWithOpenAI(store)
}
