export default {
  name: 'CvtCollapse',
  props: {
    accordion: Boolean,
    value: {
      type: [Array, String, Number],
      default() {
        return []
      }
    },
    theme: {
      type: String,
      default: ''
    },
    prependChevron: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeItems: [...this.value]
    }
  },
  provide() {
    return {
      collapse: this
    }
  },
  watch: {
    value(value) {
      this.activeItems = [...value]
    }
  },
  methods: {
    setActiveItems(items) {
      this.activeItems = [...items]
      let value = this.accordion ? items[0] : items
      this.$emit('input', value)
      this.$emit('change', value)
    },
    onToggle(item) {
      let activeItems = [...this.activeItems]
      if (this.accordion) {
        activeItems = activeItems.includes(item.name) ? [] : [item.name]
      } else {
        if (activeItems.includes(item.name)) {
          const index = this.activeItems.indexOf(item.name)
          activeItems.splice(index, 1)
        } else {
          activeItems.push(item.name)
        }
      }

      this.setActiveItems(activeItems)
    }
  },
  created() {
    this.$on('item-click', this.onToggle)
  },
  render: function (h) {
    return (
      <div class='cvt-collapse' role='tablist' aria-multiselectable='true'>
        {this.$slots.default}
      </div>
    )
  }
}