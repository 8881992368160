import jss, { SheetsRegistry } from 'jss'
import preset from 'jss-preset-default'

jss.setup(preset())

export const sheets = new SheetsRegistry()

export class StyleSheet {
  constructor (styles = {}) {
    this.create(styles)
  }

  create (styles) {
    this.sheet = jss.createStyleSheet({
      ...styles
    }).attach()

    this.addToSheets()
  }

  remove () {
    this.sheet.detach()
    this.removeFromSheets()
    jss.removeStyleSheet(this.sheet)
  }

  addToSheets () {
    sheets.add(this.sheet)
  }

  removeFromSheets () {
    sheets.remove(this.sheet)
  }

  update (styles) {
    this.remove()
    this.create(styles)
    return this.sheet
  }

  classes () {
    return this.sheet.classes
  }

  toString () {
    return this.sheet.toString()
  }
}

export function adaptFontColor (bgColor, fontColor) {
  if (bgColor.light()) {
    return fontColor.blacken(1)
  } else {
    return fontColor.whiten(1)
  }
}
