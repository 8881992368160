import { generateUuid } from '../util/generateUuid'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import Vue from '../../../lib/utils/vue'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import {
  FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../constants'

let borderColor,
color,
backgroundColor,
textFontSize,
textFontWeight,
textLineHeight,
showGradient,
gradientColor,
orgName,
topNavConfig,
radioSwitchConfig,
Spacing, 
FontFamily, 
Radii,
radioButtonConfig

Vue.use(VueEmotion)

const getRadioWrapper = () => styled('div')`
  ${(props) => UTIL.btnBorder(props)}
  ${(props) => props.supportTextActive && `display: flex; flex-direction: column;`}
`

const getSupportText = () => styled('span')`
  font-size: 12px;
  margin-top: 8px;
  text-align: center;
`

const getInActiveTextColor = (props) =>{
  if(!props.checked){
    if(props.specialBtnTextColor !== ""){
      return `color: ${props.specialBtnTextColor};`
    }else if(props.inActiveSwitchColor !== ""){
      return `color: ${props.inActiveSwitchColor};`
    }else{
      return radioButtonConfig ? UTIL.btnTextColor(props) : UTIL.btnDefaultTextColor(props);
    }
  }
}
const getInActiveBgColor = (props) =>{
  return radioButtonConfig.inActive ? UTIL.btnBgColor(props) : UTIL.btnDefaultBgColor(props)
}
const getActiveBgColor = (props) =>{
  return radioButtonConfig ? UTIL.btnActiveBgColor(props) : UTIL.btnActiveDefaultBgColor(props)
}
const getActiveTextColor = (props) =>{
  return radioButtonConfig ? UTIL.btnActiveTextColor(props) : UTIL.btnActiveDefaultTextColor(props)
}
const getBorderColor = (props) => {
  return radioButtonConfig ? UTIL.btnBorderColor(props) : UTIL.btnDefaultBorderColor(props)
}

const getRadioButton = () => styled('span')`
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-family: ${FontFamily.DEFAULT};
  text-align: center;
  cursor: pointer;
  user-select: none;
  ${(props) => props.width.trim() && `width: ${props.width};`};
  ${(props) =>
    getInActiveTextColor(props) +
    getInActiveBgColor(props) +
    UTIL.btnSize(props.size) +
    UTIL.btnShape(props.shape) +
    UTIL.btnFont(radioButtonConfig?.btnFont?.size ? radioButtonConfig.btnFont.size : props.size)
  }
  &:hover{
    ${(props) => {
      let hoverBgColorConfig = radioButtonConfig?.noTheme?.hover?.bgColor;
      let style = ``
      if(hoverBgColorConfig){
        style += backgroundColor({ themeing: hoverBgColorConfig.style }, hoverBgColorConfig.weight)
      }
      else if(props.specialBtnTextColor === ''){
        style += getActiveTextColor(props)
        style += getActiveBgColor(props)
      }
      return style
    }}
  }
  &.active {
    ${(props) =>
    getActiveTextColor(props) +
    getActiveBgColor(props) +
    UTIL.btnSize(props.size) 
  }
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const RadioInput = styled('input')`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

const RadioLabel = styled('label')`
  margin-bottom: 0;
  display: inline-flex;
`

const UTIL = {
  btnShape (shape) {
    switch (shape) {
      case 'pill':
        return `border-radius: ${Radii.RADIUS_PERCENT_50}px !important; margin-right:${Spacing.SPACING_8}px;`
      case 'circle':
        return `border-radius: ${Radii.RADIUS_PERCENT_50}% !important;margin-right:${Spacing.SPACING_8}px;`
      default:
        return `border-radius: 0px;`
    }
  },
  btnSize (size) {
    switch (size) {
      case 'xs':
        return `padding: ${Spacing.SPACING_4}px;`
      case 'sm':
        return `padding: ${Spacing.SPACING_8}px;`
      case 'lg':
        return `padding: ${Spacing.SPACING_16}px;`
      case 'xl':
        return `padding: ${Spacing.SPACING_18}px;`
      default:
        return `padding: ${Spacing.SPACING_12}px;`
    }
  },
  btnFont (size) {
    return textFontSize(size) + textFontWeight(size) + textLineHeight(size)
  },
  btnBgColor (props) {
    let { style, weight } = radioButtonConfig.noTheme.inActive.bgColor
    let { style: lightStyle, weight: lightWeight } = radioButtonConfig.lightTheme.inActive.bgColor
    
    switch (props.themeing) {
      case '':
        return (
          showGradient ? !props.checked &&
          backgroundColor({ showGradient: showGradient, themeing: style }, weight )
          : 'background-color: transparent;'
        )
      case 'LIGHT':
        return(
          backgroundColor({themeing: lightStyle}, lightWeight))
      default:
        return backgroundColor(props, 500)
    }
  },
  btnDefaultBgColor (props) {
    switch (props.themeing) {
      case '':
        return (
          showGradient ? !props.checked &&
          backgroundColor({ showGradient: showGradient,themeing: "primary" }, 500)
          : 'background-color: transparent;'
        )
      case 'LIGHT':
        return(backgroundColor({ themeing: "dark" }, 100 ))
      default:
        return backgroundColor(props, 100)
    }
  },
  btnTextColor (props) {
    let { style, weight } = radioButtonConfig.noTheme.inActive.textColor
    let { style: lightStyle, weight: lightWeight } = radioButtonConfig.lightTheme.inActive.textColor
    
    switch (props.themeing) {
      case '':
        return color({themeing: style}, weight)
      case 'LIGHT':
        return color({ themeing: lightStyle }, lightWeight)
      default:
        return color({ themeing: 'dark' }, 500)
    }
  },
  btnDefaultTextColor (props) {
    switch (props.themeing) {
      case '':
        return color({ themeing: "primary" }, 500)
      case 'LIGHT':
        return color({ themeing: "dark"}, 500)
      default:
        return color({ themeing: 'dark' }, 500)
    }
  },
  btnActiveBgColor (props) {
    let { style, weight } = radioButtonConfig.noTheme.active.bgColor
    let { style: lightStyle, weight: lightWeight } = radioButtonConfig.lightTheme.active.bgColor

    switch (props.themeing) {
      case '':
        return backgroundColor({ themeing: style }, weight)
      case 'LIGHT':
        return backgroundColor({ themeing: lightStyle }, lightWeight )
      default:
        return backgroundColor(props, 600)
    }
  },
  btnActiveDefaultBgColor (props) {
    switch (props.themeing) {
      case '':
        return backgroundColor({ themeing: "dark" }, 100)
      case 'LIGHT':
        return backgroundColor({ themeing: "primary" }, 500)
      default:
        return backgroundColor(props, 600)
    }
  },
  btnActiveTextColor (props) {
    let { style, weight } = radioButtonConfig.noTheme.active.textColor
    let { style: lightStyle, weight: lightWeight } = radioButtonConfig.lightTheme.active.textColor
    switch (props.themeing) {
      case '':
        return color({ themeing: style }, weight )
      case 'LIGHT':
        return color({ themeing: lightStyle }, lightWeight )
      default:
        return color("dark", 100)
    }
  },
  btnActiveDefaultTextColor (props) {
    switch (props.themeing) {
      case '':
        return color({ themeing: "primary" }, 500)
      case 'LIGHT':
        return color({ themeing: "dark" }, 100)
      default:
        return color("dark", 100)
    }
  },
  btnBorderColor (props) {
    let radioBtnNoThemeBorderConfig = radioButtonConfig.noTheme.borderColor
    let radioBtnLightThemeBorderConfig = radioButtonConfig.lightTheme.borderColor
    
    switch (props.themeing) {
      case '':
        return(
          radioBtnNoThemeBorderConfig.custom 
          ?  `border-color: ${radioBtnNoThemeBorderConfig.value};`
          : borderColor(radioBtnNoThemeBorderConfig.value, radioBtnNoThemeBorderConfig.weight)
        )
      case 'LIGHT':
        return(
          radioBtnLightThemeBorderConfig.custom
          ? `border-color: ${radioBtnLightThemeBorderConfig.value};`
          : borderColor(radioBtnLightThemeBorderConfig.value, radioBtnLightThemeBorderConfig.weight)
        )
      default:
        return borderColor(props, 600)
    }
  },
  btnDefaultBorderColor(props){
    switch (props.themeing) {
      case '':
        return borderColor({ themeing: "dark" }, 100 )
      case 'LIGHT':
        return borderColor({ themeing: "dark"}, 200 )
      default:
        return borderColor(props, 600)
    }
  },
  btnBorder (props) {
    if( props.type == 'multiselect' ){
      return '';
    }
    if (props.orientation == 'horizontal') {
      if (props.shape === 'pill') {
        return `.cvt-radio-btn {
            border-top-left-radius: ${Radii.RADIUS_PX_8}px;
            border-bottom-left-radius: ${Radii.RADIUS_PX_8}px;
            ${props.themeing == '' ? `border: 1px solid;` : ''}
            ${getBorderColor(props)}
          }`
      }
      return `&:first-child .cvt-radio-btn {
        border-top-left-radius: ${Radii.RADIUS_PX_8}px;
        border-bottom-left-radius: ${Radii.RADIUS_PX_8}px;
        ${props.themeing == ''
          ? `border-left: 1px solid; border-top: 1px solid; border-bottom: 1px solid;`
          : ''
        }
        ${getBorderColor(props)}
      }
  
      &:last-child .cvt-radio-btn {
        border-top-right-radius: ${Radii.RADIUS_PX_8}px;
        border-bottom-right-radius: ${Radii.RADIUS_PX_8}px;
        ${props.themeing == ''
          ? `border-top: 1px solid; border-right: 1px solid; border-bottom: 1px solid;`
          : ''
        }
        ${getBorderColor(props)}
      }
  
      &:not(:last-child) .cvt-radio-btn {
        border-right: 1px solid;
        ${props.themeing == ''
          ? `border-top: 1px solid; border-bottom: 1px solid;`
          : ''
        }
        ${getBorderColor(props)}
      }`
    } else {
      return `&:first-child .cvt-radio-btn {
        border-top-left-radius: ${Radii.RADIUS_PX_8}px;
        border-top-right-radius: ${Radii.RADIUS_PX_8}px;
        ${props.themeing == ''
          ? `border-left: 1px solid; border-right: 1px solid; border-top: 1px solid;`
          : ''
        }
      }
    
      &:last-child .cvt-radio-btn {
        border-bottom-left-radius: ${Radii.RADIUS_PX_8}px;
        border-bottom-right-radius: ${Radii.RADIUS_PX_8}px;
        ${props.themeing == ''
          ? `border-left: 1px solid; border-right: 1px solid; border-bottom: 1px solid;`
          : ''
        }
        ${getBorderColor(props)}
      }

      &:not(:last-child) .cvt-radio-btn {
        border-bottom: 1px solid;
        ${props.themeing == ''
          ? `border-left: 1px solid; border-right: 1px solid;`
          : ''
        }
        ${getBorderColor(props)}
      }`
    }
  }
}

export default {
  name: 'CvtRadioButton',
  components: {},
  inject: ['radioGroup'],
  props: {
    value: {
      type: String,
      default: '1'
    },
    border: {
      type: Boolean,
      default: false
    },
    orientation: {
      type: String,
      default: 'horizontal'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'light'
    },
    size: {
      type: String,
      default: 'md'
    },
    shape: {
      type: String,
      default: ''
    },
    inActiveSwitchColor: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: '',
    },
    btnWidth: {
      type: String,
      default: ''
    },
    supportText: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      uuid: generateUuid()
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      topNavConfig: ({ topNavConfig }) => topNavConfig,
      showGradient: ({ globalTheme }) =>
        globalTheme?.useButtonGredient !== undefined
          ? globalTheme.useButtonGredient
          : false,
      gradientColor: ({ globalTheme }) => globalTheme?.GradientColors,
      orgName: ({ globalTheme }) =>
        globalTheme?.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
      specialBtnTextColor: ({ globalTheme }) => globalTheme?.specialBtnTextColor !== undefined ? globalTheme.specialBtnTextColor : '',
      radioSwitchConfig: ({ globalTheme }) =>
        globalTheme.radioSwitchConfig !== undefined
          ? globalTheme.radioSwitchConfig
          : false,
      radioButtonConfig: ({ globalTheme }) => globalTheme.radioButtonConfig ? globalTheme.radioButtonConfig : "",
    }),
    isActive () {
      return this.value === this.radioGroup.activeValue
    },
    wrapperColor () {
      return !this.disabled ? `btn-${this.color}` : ''
    },
    theme () {
      return this.radioGroup.theme
    },
    name () {
      return this.radioGroup.uniqueName
    },
    isDisabled () {
      return this.disabled || this.radioGroup.isDisabled
    },
    radioOrientation () {
      return this.radioGroup.orientation
    }
  },
  methods: {
    onClick (value, from = null) {
      if (!this.isDisabled) {
        this.radioGroup.setActiveValue(value)
        if (from) {
          this.$emit('radioClick', value)
        }
      }
    }
  },
  created () {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    color = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    showGradient = this.showGradient
    gradientColor = this.gradientColor
    orgName = this.orgName
    topNavConfig = this.topNavConfig
    radioSwitchConfig = this.radioSwitchConfig
    radioButtonConfig = this.radioButtonConfig
  },
  render: function (h) {
    const RadioWrapper = getRadioWrapper()
    const RadioButton = getRadioButton()
    const SupportText = getSupportText()

    let btnSize = radioButtonConfig.btnSize ? radioButtonConfig.btnSize : this.size || this.radioGroup.size

    return (
      <RadioWrapper
        onClick={() => this.onClick(this.value, 'wrapper')}
        active={this.isActive}
        disabled={this.disabled}
        size={btnSize}
        themeing={this.theme}
        orientation={this.radioOrientation}
        shape={this.shape}
        type={this.type}
        supportTextActive={this.supportText.trim()}
      >
        <RadioLabel for={this.uuid}>
          <RadioInput
            id={this.uuid}
            themeing={this.theme}
            name={this.name}
            type='radio'
            value={this.value}
            checked={this.isActive}
            onClick={() => this.onClick(this.value)}
            disabled={this.isDisabled}
          />
          <RadioButton
            class={[
              'cvt-radio-btn',
              this.isActive ? 'active' : '',
              this.isDisabled ? 'disabled' : ''
            ]}
            themeing={this.theme}
            size={btnSize}
            width={this.btnWidth}
            checked={this.isActive}
            color={this.color}
            shape={this.shape}
            specialBtnTextColor={this.specialBtnTextColor}
            inActiveSwitchColor={this.inActiveSwitchColor}
          >
            {this.$slots.default}
          </RadioButton>
        </RadioLabel>
        {this.supportText.trim() && <SupportText>{this.supportText}</SupportText>}
      </RadioWrapper>
    )
  }
}
