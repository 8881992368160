export const CookieHelper = (cookieName: string) => {
  let cookie = document.cookie
  if (!cookie) {
    return {}
  }
  try {
    const getJWTPayload =
      cookie
        .split('; ')
        .find((row) => row.startsWith(cookieName))
        ?.split('=')[1] || ''

    return parseJwt(getJWTPayload)
  } catch (e) {
    console.error(e, 'Error parsing JOT')
    return {}
  }
}

function parseJwt(token: string) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}
