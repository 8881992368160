<template>
  <div>
    <h1>Pagination</h1>
    <cvt-pagination
      :total="50"
      :per-page="10"
      :current-page="currentPage"
      :pager-count="5"
      :background="false"
      @pagechanged="onPageChange"
    />

    <cvt-pagination
      :total="50"
      :per-page="10"
      :current-page="currentPage"
      :pager-count="5"
      :background="true"
      @pagechanged="onPageChange"
    />
  </div>
</template>
<script>
export default {
  name: 'PlayPagination',
  components: {
  },
  data () {
    return {
      currentPage: 1
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    onPageChange (page) {
      this.currentPage = page
    }
  },
}
</script>
<style lang="scss">
</style>
