
<div
  :id="vnodeId"
  class="cvt-editor-divider"
  :class="classes"
  :style="styles"
  draggable="true"
  @click="select"
  @mouseover="target"
  @contextmenu.prevent.stop="showContextMenu"
  @dblclick.stop.prevent
  @dragstart="dragstart"
  @dragend="dragend">

  <drag-and-drop-zone :z-index="dropzoneZIndex" @dropped="addElementToSide"></drag-and-drop-zone>

  <hr :class="hrClasses" :style="styleOverrides">
  <template v-if="showActionButtonsOnClick && !isGridBuilder">
    <!--THis is only here because of freshwork in v1 gridbuilder,
    once they migrate to v2 or v3, it will become irrelevant-->
    <action-buttons
        v-if="selected"
        @duplicate="duplicateAndValidate"
        @remove="removeAndValidate"
    />
  </template>
</div>
