import Vue from '../../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import { CvtIcon } from '../../molecules/icon/icon'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import CvtHeading from './heading'
import { STANDARD, FRESHWORKS } from '../../constants'

Vue.use(VueEmotion)

let Spacing, Radii, Border, ColoredTextLabel, Colors

let textFontWeight,
  borderColor,
  backgroundColor,
  textFontSize,
  textLineHeight,
  elevation,
  color

const getExpandedElements = () => styled('div')`
  display: grid;
  ${(props) =>
    props.type === 'freshworks'
      ? 'grid-template-columns: 80px 80px 80px;'
      : 'grid-template-columns: 92px 92px;'}
  grid-template-rows: auto repeat(12, 92px);
  ${(props) =>
    props.type === 'freshworks'
      ? `grid-gap: ${Spacing.SPACING_16 + 4}px;`
      : `grid-gap: ${Spacing.SPACING_16}px;`}
  ${(props) =>
    props.type === 'freshworks'
      ? `padding: ${Spacing.SPACING_16}px 28px;`
      : `padding: ${Spacing.SPACING_32}px ${Spacing.SPACING_16 + 4}px;`}
  border-left: ${Border.SIZE_1}px solid;

  ${(props) =>
    props.mode == 'DARK'
      ? borderColor({ themeing: 'dark' }, 500)
      : borderColor({ themeing: 'dark' }, 100)};
`

const getExpandedElementsSubtitle = () => styled('span')`
  text-align: center;
  ${textFontSize('sm')}
  ${textFontWeight('REGULAR')}
  ${textLineHeight('xl')}
  grid-column: span 2;
`

const getElement = () => styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.type === 'freshworks'
      ? 'width: 80px; height: 80px;'
      : 'width: 92px; height: 92px;'}
  cursor: move;
  transition: all 0.25s ease;
  border-radius: ${Radii.RADIUS_PX_8}px;
  ${(props) => {
    let bgColor =
      props.mode == 'DARK'
        ? backgroundColor({ themeing: 'dark' }, 800)
        : backgroundColor({ themeing: 'dark' }, 'WHITE')
    let boxShadow = props.orgName !== FRESHWORKS ? elevation(props, 200) : `box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);`

    return bgColor + boxShadow
  }}
  &:hover {
    transform: rotate(5deg);
  }
`

const getElementIcon = () => styled(CvtIcon)`
  width: 20px;
  height: 20px;
  font-size: 24px;
  text-align: center;
  ${(props) => {
    return props.orgName === FRESHWORKS ? Colors.BASIC[500] : ``
  }}
`

const getElementName = () => styled('span')`
  ${textFontSize('lg')}
  ${textFontWeight('REGULAR')}
  ${textLineHeight('lg')}
  margin-top: ${Spacing.SPACING_16}px;

  // TODO: color value should be refactored 
  ${() => (ColoredTextLabel === true ? `color: #044a75;` : ``)}
`

const getGridItemContainer = () => styled('div')``

export const CvtElements = {
  name: 'CvtElements',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      elevation: getters.GLOBAL_STYLE_ELEVATION,
      color: getters.GLOBAL_STYLE_COLOR
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      iconConfig: ({ iconConfig }) => iconConfig,
      ColoredTextLabel: ({ globalTheme }) =>
        globalTheme.LeftSideBarColoredElementLabel !== undefined
          ? globalTheme.LeftSideBarColoredElementLabel
          : false,
      orgName: ({ globalTheme }) => globalTheme.OrgName !== undefined
            ? globalTheme.OrgName
            : STANDARD,
    })
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
    elements: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'standard'
    }
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    elevation = this.elevation
    ColoredTextLabel = this.ColoredTextLabel
    color = this.color
    Colors = this.Colors
  },
  render: function (h) {
    const ExpandedElements = getExpandedElements()
    const ExpandedElementsSubtitle = getExpandedElementsSubtitle()
    const Element = getElement()
    const ElementIcon = getElementIcon()
    const ElementName = getElementName()
    const GridItemContainer = getGridItemContainer()
    const elements = []
    const elementIconConfig = this.iconConfig?.leftSidebar?.elements
    this.elements.forEach((element) => {
      elements.push(
        <GridItemContainer
          id={element.name + '_element'}
          draggable={true}
          class='grid-stack-draggable-item grid-stack-item ui-draggable'
          onDragstart={(e) => {
            console.log('drag started')
            this.$emit('element-drag-start', e, element)
          }}
          onDragend={(e) => this.$emit('element-drag-end', element)}
          onMousedown={(e) => this.$emit('element-mouse-down', element)}
        >
          <Element
            class='grid-stack-item-content ui-draggable-handle'
            mode={this.mode}
            type={this.type}
            orgName={this.orgName}
          >
            <ElementIcon
              orgName={this.orgName}
              icon={elementIconConfig[element.name] || element.icon}
            ></ElementIcon>
            <ElementName orgName={this.orgName}>{element.label}</ElementName>
          </Element>
        </GridItemContainer>
      )
    })

    return (
      <div>
        {this.type === 'freshworks' && <CvtHeading>ELEMENTS</CvtHeading>}

        <ExpandedElements mode={this.mode} type={this.type}>
          {this.type !== 'freshworks' && (
            <ExpandedElementsSubtitle>
              Drag and drop elements onto the canvas
            </ExpandedElementsSubtitle>
          )}
          {elements}
        </ExpandedElements>
      </div>
    )
  }
}
