import {
  BlockQuoteFroalaFormat,
  ButtonFormat,
  FroalaFormat,
  FroalaLinkFormat,
} from '../../../../lib/font'

export const DEFAULT_TYPOGRAPHY = {
  title: new FroalaFormat({
    font: 'arimo',
    size: '60',
    defaultTextColor: 'color1',
    defaultAltTextColor: 'color2',
  }),
  subtitle: new FroalaFormat({
    font: 'arimo',
    size: '48',
    defaultTextColor: 'color1',
    defaultAltTextColor: 'color2',
  }),
  heading: new FroalaFormat({
    font: 'arimo',
    size: '34',
    defaultTextColor: 'color1',
    defaultAltTextColor: 'color2',
  }),
  alternativeHeading: new FroalaFormat({
    font: 'arimo',
    size: '30',
    defaultTextColor: 'color1',
    defaultAltTextColor: 'color2',
  }),
  lead: new FroalaFormat({
    font: 'arimo',
    size: '22',
    defaultTextColor: 'color1',
    defaultAltTextColor: 'color2',
  }),
  normalText: new FroalaFormat({
    font: 'arimo',
    size: '18',
    defaultTextColor: 'color1',
    defaultAltTextColor: 'color2',
  }),
  blockquote: new BlockQuoteFroalaFormat({
    font: 'arimo',
    size: '18',
    defaultTextColor: 'color1',
    defaultAltTextColor: 'color2',
  }),
}

export const DEFAULT_BUTTON_TYPOGRAPHY = {
  button: new ButtonFormat({
    fontFamily: 'arimo',
    fontSize: '18',
    defaultTextColor: 'color1',
    defaultBackgroundColor: 'color2',
    fontWeight: '400',
    subtextStyle: {
      fontSize: '14',
      fontWeight: '200',
      fontFamily: 'arimo',
    },
  }),
}

export const DEFAULT_FROALA_LINK_TYPOGRAPHY = {
  link: new FroalaLinkFormat({
    defaultTextColor: 'color1',
  }),

  linkHover: new FroalaLinkFormat({
    defaultTextColor: 'color2',
  }),

  linkVisited: new FroalaLinkFormat({
    defaultTextColor: 'color3',
  }),
}
