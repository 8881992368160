
<div v-if="isAdmin">
  <h4 class="admin-label">{{$t('launch.tab.control.heading.add_categories')}}</h4>

  <el-select
    v-model="categories"
    multiple
    filterable
    allow-create
    placeholder="Select categories"
  >
    <el-option
      v-for="op in allCategories"
      :key="op.id"
      :label="op.label"
      :value="op.label"
    />
  </el-select>
</div>
