
<div>
    <org-header></org-header>
    <div class="code-verification-container">
        <div class="code-verification-inner-container">
            <div class="code-verification-header-content">
                <h1>Check your email for a code</h1>
                <p>We've sent a 6-character code to <span>{{ userEmail }}</span>. The code expires shortly, so please enter it soon.</p>
            </div>
            <form>
                <div class="code-input">
                    <div
                        v-for="(code, id) in codes"
                        :key="id"
                        class="code-inputs"
                    >
                        <input
                            :ref="`codeInputRef${id}`"
                            type="text"
                            :maxLength="1"
                            :value="code.value"
                            :autofocus="id === 0"
                            :readOnly="loading"
                            @input="e => processInput(e.target.value, id)"
                            @keydown="(e) => onKeyDown(e, id)"
                        />
                    </div>
                </div>
                <p>Can't find your code? Check your spam folder! Click here to <span class="code-verification-resend-btn" @click="resendCode">resend</span></p>
            </form>
        </div>
    </div>

    <div class="col-xs-12 saas-leader-imgs-wrap">
    <img
      src="/img/organization/client-logos/sendinblue-logo.png"
      alt="Sendinblue Logo"
    />
    <img
      src="/img/organization/client-logos/keap-logo.png"
      alt="Keap Logo"
    />
    <img
      src="/img/organization/client-logos/sharpspring-logo.png"
      alt="Sharpspring Logo"
    />
    <img
      src="/img/organization/client-logos/integrate-logo.png"
      alt="Integrate Logo"
    />
    <img
      src="/img/organization/client-logos/instamojo-logo.png"
      alt="Instamojo Logo"
    />
  </div>
</div>

