<template>
  <div> SMB onboarding page</div>
</template>

<script>
export default {
  props: {},
  data() {
    return {}
  },
}
</script>
