
<el-dialog :visible.sync="visible" size="small" @close="close">
  <span slot="title" class="save-block-title"> Site Settings </span>

  <div>
    <el-tabs v-model="activeTab" @tab-click="switchTabs">
      <el-tab-pane label="General" name="general">
        <el-form ref="siteSettings" :model="site" label-width="120px">
          <el-form-item label="Name" prop="name">
            <el-input
              v-model="site.name"
              placeholder="Name the block"
              autofocus
            >
            </el-input>
          </el-form-item>

          <el-form-item
            label="Javascript Embeds (Facebook Pixel / Google Analytics)"
            prop="embeds"
          >
            <el-input
              v-model="site.embeds"
              type="textarea"
              :min-rows="3"
              :max-rows="10"
              :autosize="true"
              placeholder="Paste your Facebook pixel or google tag manager tracking codes here. (one per line)"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="Global Typography" name="typography">
        <div class="row">
          <div class="col-md-6 typography" :class="themeTypographyClass">
            <h1
              class="typography__selection"
              :class="fontTypeIsSelected('h1')"
              @click.prevent.stop="selectFontType('h1')"
            >
              Header 1
            </h1>
            <h2
              class="typography__selection"
              :class="fontTypeIsSelected('h2')"
              @click.prevent.stop="selectFontType('h2')"
            >
              Header 2
            </h2>
            <h3
              class="typography__selection"
              :class="fontTypeIsSelected('h3')"
              @click.prevent.stop="selectFontType('h3')"
            >
              Header 3
            </h3>
            <h4
              class="typography__selection"
              :class="fontTypeIsSelected('h4')"
              @click.prevent.stop="selectFontType('h4')"
            >
              Header 4
            </h4>
            <h5
              class="typography__selection"
              :class="fontTypeIsSelected('h5')"
              @click.prevent.stop="selectFontType('h5')"
            >
              Header 5
            </h5>
            <h6
              class="typography__selection"
              :class="fontTypeIsSelected('h6')"
              @click.prevent.stop="selectFontType('h6')"
            >
              Header 6
            </h6>
            <p
              class="typography__selection"
              :class="fontTypeIsSelected('p')"
              @click.prevent.stop="selectFontType('p')"
            >
              Apparently we had reached a great height in the atmosphere, for
              the sky was a dead black, and the stars had ceased to twinkle.
              By the same illusion which lifts the horizon of the sea to the
              level of the spectator on a hillside, the sable cloud beneath
              was dished out, and the car seemed to float in the middle of an
              immense dark sphere, whose upper half was strewn with silver.
              Looking down into the dark gulf below, I could see a ruddy light
              streaming through a rift in the clouds.
            </p>
          </div>

          <div class="col-md-6">
            <font-family-selector
              @change="selectFontFamily"
            ></font-family-selector>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane label="Social Share Settings" name="social">
        <el-form ref="socialSettings" :model="opengraph" label-width="120px">
          <el-form-item label="Title" prop="title">
            <el-input
              v-model="opengraph.title"
              placeholder="Social Link Title"
              autofocus
            >
            </el-input>
          </el-form-item>

          <el-form-item label="Description" prop="description">
            <el-input
              v-model="opengraph.description"
              type="textarea"
              :min-rows="10"
              :max-rows="10"
              :autosize="true"
              placeholder="Add a short description that will show up in social websites like facebook when you paste this sites url."
            >
            </el-input>
          </el-form-item>

          <el-form-item label="Banner Image" prop="image">
            <uploader
              :url="opengraph.image"
              @change="updateOpenGraphImage"
            ></uploader>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>

  <span slot="footer" class="dialog-footer">
    <div class="pull-left">
      <el-tooltip content="Republish landing page" placement="top">
        <publish-btn></publish-btn>
      </el-tooltip>
    </div>

    <el-button @click="close">Cancel</el-button>
    <el-button type="primary" @click="updateAndConfirm">Update</el-button>
  </span>
</el-dialog>
