
<footer class="footer-wrap">
  <div class="footer-inner-wrap">
    <div class="footer-logo-wrap">
      <a href="https://www.convrrt.com">
        <img
          class="adm-logo-2"
          src="/img/organization/convrrt-logo-2.svg"
          alt="Convrrt Logo"
        />
      </a>
    </div>

    <div :class="altFooterStyle ? 'alt-footer-ul-wrap' : 'footer-ul-wrap'">
      <ul :class="altFooterStyle ? 'alt-footer-ul' : 'footer-ul'">
        <li>
          <a href="#">@2022 Convrrt, Inc.</a>
        </li>
        <li>
          <a href="#">Privacy Policy</a>
        </li>
        <li>
          <a href="#">Terms of Use</a>
        </li>
        <li>
          <a href="#">Cookie Policy</a>
        </li>
      </ul>
    </div>
  </div>
</footer>
