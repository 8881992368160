<template>
  <el-dialog :visible="visible" size="tiny" @close="onClose">
    <span slot="title" class="bookmark-block-title">
      Bookmark Page
    </span>
    <el-form
      ref="ruleForm"
      label-position="top"
      :model="pageData"
      :rules="rules"
      class="demo-ruleForm"
    >
      <el-form-item prop="pageName">
        <el-input
          v-model="pageData.pageName"
          class="col-12 mb-2"
          placeholder="Name the Page bookmark"
          autofocus
        />
      </el-form-item>
      <!-- <el-form-item prop="pageThumbnail">
        <el-input
          v-model="pageData.pageThumbnail"
          class="col-12 mb-2"
          placeholder="Url to thumbnail"
        />
      </el-form-item> -->
    </el-form>

    <div slot="footer" class="dialog-footer col-12">
      <div class="row justify-content-end">
        <el-button :disabled="isSaving" @click="onClose">{{
          $t('element.property.cta.cancel')
        }}</el-button>
        <el-button
          type="primary"
          :loading="isSaving"
          :disabled="false"
          @click="submitForm"
          >{{ $t('element.property.cta.confirm') }}</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import tags from '../../lib/tags'
import * as getters from '../../store/modules/getters'
import * as mutations from '../../store/modules/mutations'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import * as _ from 'lodash'
import * as actions from '@/js/store/modules/actions'
import { USER_OWNED } from "@/js/common/constants/constants";
import { database } from "@/js/store/services/firebase.service";
let db = database()

const PAGE_DEFAULT = {
  pageName: '',
  pageThumbnail: '',
  pageTag: USER_OWNED,
  isGridBuilder: false,
}

export default {
  data() {
    return {
      pageData: {
        pageName: '',
        pageThumbnail: '',
        pageTag: USER_OWNED,
        isGridBuilder: false,
      },
      tags,
      isSaving: false,
      rules: {
        pageName: [
          { required: true, message: 'Please input Name', trigger: 'blur' },
          {
            min: 5,
            max: 40,
            message: 'Length should be 3 to 40',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    ...mapState({
      theme: ({ theme }) => theme,
      site: ({ site }) => _.get(site, 'site'),
    }),
    ...mapGetters({
      visible: getters.PAGES_BOOKMARK_DIALOG_VISIBLE,
      pageRef: getters.PAGES_BOOKMARK_PAGE_REF,
      selected: getters.SELECTOR_SELECTED,
      config: getters.AUTH_GET_USER_CONFIG,
      isStandalonePopUpActive: getters.STANDALONE_POPUP_ACTIVE,
      cPopupBlock: getters.SDA_POPUP_FIREBASE_DATA,
      isGridBuilder: getters.IS_GRID_BUILDER,
    }),
    themeColors() {
      return {
        ...this.theme.colors,
        ...this.theme.blockColors,
      }
    },
  },
  methods: {
    ...mapMutations({
      close: mutations.PAGES_BOOKMARK_BLOCK_HIDE,
    }),
    onClose() {
      this.pageData = Object.assign({}, PAGE_DEFAULT)
      this.close()
    },
    ...mapActions({
      savePageToFirebase: actions.PAGES_BOOKMARK_BLOCK_CONFIRM
    }),
    async savePage() {
      this.loading = true
      const paletteColors = await this.getPaletteColorsFromCurrentSite()
      this.pageData.isGridBuilder = this.isGridBuilder

      const pageProps = {
        ...this.pageData,
        global: false,

        meta: {
          anchors: {},
          colors: paletteColors,
          clonedFromSiteRef: this.site.firebaseRef,
          clonedFromPageRef: this.pageRef,
        },
      }

      try {
        await this.savePageToFirebase({ path: this.pageRef, data: pageProps })
      } finally {
        this.loading = false
        this.onClose()
      }
    },
   async getPaletteColorsFromCurrentSite() {
      let paletteColors = {}

      await db.ref(this.pageRef).child('vdom').once('value', snap => {
        snap.val().forEach((block) => {
          const themeColorIds = this.getColorIdsFromChildElements(block, (n) => n.data?.props?.themeColorId)
          const overlayColorIds = this.getColorIdsFromChildElements(block, (n) =>  n.data?.props?.background?.coloroverlay)
          const backgroundShapeColorIds = this.getColorIdsFromChildElements(block, (n) => n.data?.props?.bgShape?.fillColor)

          const mergedColors = _.merge(
            themeColorIds,
            overlayColorIds,
            backgroundShapeColorIds,
          )
          let colors = _.chain(this.themeColors)
            .pick(mergedColors)
            .mapValues((c) => ({
              a: c.alpha(),
              hex: c.hex().toLowerCase(),
            }))
            .value()
          paletteColors = { ...paletteColors, ...colors }
      })

      this.vdom
      })

      return paletteColors
    },
    getColorIdsFromChildElements(block, selectCallback) {
      let colorIds = []
      const blockChildren = block?.children || []

      colorIds = _.chain(blockChildren)
        .findAllRecurs((n) => selectCallback(n))
        .map((n) => selectCallback(n))
        .value()

      if (selectCallback(block)) {
        colorIds.unshift(selectCallback(block))
      }
      return colorIds
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.savePage()
        } else {
          return false;
        }
      });
    },
  },
}
</script>

<style lang="scss"></style>
