import Vue from '../../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import { CvtIcon } from '../../molecules/icon/icon'
import { CvtTooltip } from '../../molecules/tooltip/CvtTooltip'
import { LEFT_SIDEBAR_FRESHWORKS, LEFT_SIDEBAR_STANDARD } from '../../constants'

Vue.use(VueEmotion)

let Spacing, Radii, Border, Colors

let textFontWeight, borderColor, backgroundColor, textFontSize, textLineHeight

const getLayoutSection = () => styled('div')`
  display: flex;
  position: relative;
  width: 300px;
  align-items: center;
  border-bottom: ${Border.SIZE_1}px solid;
  padding: ${Spacing.SPACING_12}px
    ${(props) => (props.type === LEFT_SIDEBAR_FRESHWORKS ? '12px' : '0px')};
  ${(props) => {
    return props.type === LEFT_SIDEBAR_FRESHWORKS
      ? `
    margin-bottom: 10px;
    background-color: #F5F7F9;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: ${Spacing.SPACING_4}px;
    height: 40px;
    `
      : ``
  }}

  ${(props) =>
    props.mode == 'DARK'
      ? borderColor({ themeing: 'dark' }, 600)
      : borderColor({ themeing: 'dark' }, 100)}

  &.ghost {
    border-top: 1px dashed;
    border-bottom: 1px dashed;
    ${(props) =>
      props.mode == 'DARK'
        ? borderColor({ themeing: 'dark' }, 300)
        : borderColor({ themeing: 'dark' }, 400)}
  }

  & .show-on-hover {
    display: none;
    cursor: pointer;
  }

  & .hide-on-hover {
    display: block;
  }

  &:hover {
    &::before {
      content: '';
      position: absolute;
      left: -24px;
      ${(props) =>
        props.type !== LEFT_SIDEBAR_FRESHWORKS ? 'width: calc(100% + 48px);' : ''};

      height: calc(100% + 2px);
      z-index: -1;
      ${(props) =>
        props.mode == 'DARK'
          ? backgroundColor({ themeing: 'dark' }, 600)
          : backgroundColor({ themeing: 'dark' }, 100)}
    }

    .show-on-hover {
      display: block;
    }

    .hide-on-hover {
      display: none;
    }
  }
`

const LayoutSectionMoveWrapper = styled('span')``

const LayoutSectionMove = styled(CvtIcon)`
  cursor: move;
`

const getLayoutSectionName = () => styled('span')`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${Spacing.SPACING_8}px 0;
  margin-left: ${Spacing.SPACING_16}px;
  ${textFontSize('xl')}
  ${textLineHeight('lg')}
  ${textFontWeight('REGULAR')}
  ${(props) => props.type === LEFT_SIDEBAR_FRESHWORKS ? `color: ${Colors.INFO[800]};`: ``}
`

const LayoutSectionActions = styled('span')`
  display: flex;
  margin-left: auto;
`

const getLayoutSectionMore = () => styled(CvtIcon)`
  margin-left: ${Spacing.SPACING_16}px;
`

const getLayoutSectionSetting = () => styled(CvtIcon)`
  margin-left: ${Spacing.SPACING_16}px;
`

const getLayoutSectionCopy = () => styled(CvtIcon)`
  margin-left: ${Spacing.SPACING_12}px;
`

const getLayoutSectionDelete = () => styled(CvtIcon)`
  margin-left: ${Spacing.SPACING_12}px;
`

export const CvtLayoutSection = {
  name: 'CvtLayoutSection',
  components: {
    CvtTooltip
  },
  data() {
    return {}
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
    layout: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      default: 'Untitled'
    },
    label: {
      type: String,
      default: 'Untitled'
    },
    id: {
      type: String,
      default: 'layout-manager'
    },
    type: {
      type: String,
      default: 'standard'
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      iconConfig: ({ iconConfig }) => iconConfig
    })
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    Colors = this.Colors
    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
  },
  render: function (h) {
    const LayoutSection = getLayoutSection()
    const LayoutSectionName = getLayoutSectionName()
    const LayoutSectionMore = getLayoutSectionMore()
    const LayoutSectionSetting = getLayoutSectionSetting()
    const LayoutSectionCopy = getLayoutSectionCopy()
    const LayoutSectionDelete = getLayoutSectionDelete()
    const iconConfig = this.iconConfig?.leftSidebar?.layout

    return (
      <LayoutSection
        class='layout-section'
        id={this.name}
        mode={this.mode}
        onMouseover={() => this.$emit('layout-mouse-over')}
        onMouseenter={() => this.$emit('layout-mouse-enter')}
        onClick={() => this.$emit('layout-click')}
        draggable={true}
        type={this.type}
      >
        <LayoutSectionMoveWrapper>
          <LayoutSectionMove
            icon={
              this.type === LEFT_SIDEBAR_FRESHWORKS
                ? iconConfig['drag_v1']
                : iconConfig['drag'] || 'bars'
            }
          ></LayoutSectionMove>
        </LayoutSectionMoveWrapper>
        <LayoutSectionName type={this.type} title={this.label}>{this.label}</LayoutSectionName>
        <LayoutSectionActions>
          {
            this.type === LEFT_SIDEBAR_STANDARD && 
            <LayoutSectionMore
              class='hide-on-hover'
              icon={
                this.type === LEFT_SIDEBAR_FRESHWORKS
                  ? iconConfig['edit']
                  : iconConfig['more'] || 'ellipsis-h'
              }
            ></LayoutSectionMore>
          }
          <LayoutSectionSetting
            id={this.id + this.name + '-settings'}
            class={this.type !== LEFT_SIDEBAR_FRESHWORKS ? 'show-on-hover': ''}
            icon={iconConfig['settings']}
            nativeOnClick={(e) => this.$emit('layout-setting', e)}
          ></LayoutSectionSetting>
          <CvtTooltip
            placement='top'
            offset='5'
            show={false}
            fallbackPlacement='right'
            delay='100'
            color='dark'
            mode={this.mode}
            noFade={true}
            innerPadding={8}
            ref='tooltip'
            target={this.id + this.name + '-settings'}
          >
            { this.$t('elements.properties.settings') }
          </CvtTooltip>
          <LayoutSectionCopy
            id={this.id + this.name + '-copy'}
            class={this.type !== LEFT_SIDEBAR_FRESHWORKS ? 'show-on-hover': ''}
            icon={iconConfig['duplicate']}
            nativeOnClick={(e) => this.$emit('layout-copy', e)}
          ></LayoutSectionCopy>
          <CvtTooltip
            placement='top'
            offset='5'
            show={false}
            fallbackPlacement='right'
            delay='100'
            color='dark'
            mode={this.mode}
            noFade={true}
            innerPadding={8}
            ref='tooltip'
            target={this.id + this.name + '-copy'}
          >
            { this.$t('marketplace.context.copy.message') }
          </CvtTooltip>
          <LayoutSectionDelete
            id={this.id + this.name + '-delete'}
            class={this.type !== LEFT_SIDEBAR_FRESHWORKS ? 'show-on-hover': ''}
            icon={iconConfig['delete']}
            nativeOnClick={(e) => this.$emit('layout-delete', e)}
          ></LayoutSectionDelete>
          <CvtTooltip
            placement='top'
            offset='5'
            show={false}
            fallbackPlacement='right'
            delay='100'
            color='dark'
            mode={this.mode}
            noFade={true}
            innerPadding={8}
            ref='tooltip'
            target={this.id + this.name + '-delete'}
          >
            { this.$t('layout.manage.delete') }
          </CvtTooltip>
        </LayoutSectionActions>
      </LayoutSection>
    )
  }
}
