<template>
  <div class="wizard-item">
    <component
      :is="obj.node.tag"
      v-if="!isReload"
      :key="obj.key"
      :fb-node="obj.node"
      :section-u-i-enabled="false"
      :disable-add-button-elements="true"
      v-bind="obj.node.data.props"
    ></component>

    <div v-if="genAIStatus !== 'completed'" class="wizard-item-overlay">
      <div class="wizard-item-overlay-message">
        <span class="">Please wait...</span>
        <br />
        <span class="font-size-14"
          >while we generate content for this section</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Block from '../editor/nodes/Block.vue'
import { database } from '@/js/store/services/firebase.service'
import { V1GenAI } from '@/js/store/services/api'

const db = database()
const genAIAPIClient = new V1GenAI()

export default {
  name: 'WizardItem',
  components: {
    block: Block,
  },
  props: {
    obj: {
      type: Object,
      required: true,
    },
    wizardIndex: {
      type: Number,
      required: true,
    },
    isReload: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      genAIStatus: 'pending',
    }
  },
  watch: {
    genAIStatus(newValue) {
      this.publishGenAIStatus()
    },
    wizardIndex(newValue, oldValue) {
      console.log('wizardIndex', newValue, oldValue)
      if (oldValue === undefined) {
        return
      }
      this.genAIStatus = 'pending'
      this.$nextTick(() => {
        this.getGenAIStatus()
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getGenAIStatus()
    })
  },
  methods: {
    publishGenAIStatus() {
      this.$emit('genAIStatusChanged', this.genAIStatus)
    },
    generateContentFromGenAIAPI() {
      genAIAPIClient
        .generateSectionContent({
          sectionPath: this.obj.node.data.props['.path'],
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getGenAIStatus() {
      const genAIStatusRef = db
        .ref(this.obj.node.data.props['.path'])
        .child('genAIStatus')
      genAIStatusRef.once('value').then((snap) => {
        if (snap.exists()) {
          this.genAIStatus = snap.val()
          if (this.genAIStatus !== 'completed') {
            this.waitForGenAIStatus()
            // initialize API call to generate content
            this.generateContentFromGenAIAPI()
          }
        } else {
          this.genAIStatus = 'completed'
        }
        this.publishGenAIStatus()
      })
    },
    waitForGenAIStatus() {
      const genAIStatusRef = db
        .ref(this.obj.node.data.props['.path'])
        .child('genAIStatus')

      genAIStatusRef.on('value', (snap) => {
        if (snap.exists()) {
          this.genAIStatus = snap.val()
          if (this.genAIStatus === 'completed') {
            genAIStatusRef.off('value')
            setTimeout(() => {
              this.$nextTick(() => {
                this.publishGenAIStatus()
              })
            }, 500)
          }
        }
      })
    },
  },
  // ... other component logic
}
</script>

<style scoped>
.wizard-item {
  position: relative;
}

.wizard-item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.wizard-item-overlay-message {
  text-align: center;
  padding: 20px;
  font-size: 16px;
}
.wizard-item-overlay-message span:first-child {
  font-weight: 600;
  font-size: 1.6rem;
}
.wizard-item-overlay-message span:last-child {
  color: #666;

  font-size: 1.4rem;
}
</style>
