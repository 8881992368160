import { Organization } from '../../services/api'
import * as getters from '../getters'
import * as mutations from '../mutations'
import * as actions from '../actions'

const organization = new Organization()

export default {
  state: {
    envJwtToken: '',
    oidcConfig: [],
  },
  getters: {
    [getters.ENV_JWT_TOKEN]({ envJwtToken }) {
      return envJwtToken
    },
    [getters.OIDC_CONFIG]({ oidcConfig }) {
      return oidcConfig
    },
  },
  mutations: {
    [mutations.SET_ENV_JWT_TOKEN](state, envJwtToken) {
      state.envJwtToken = envJwtToken
    },
    [mutations.SET_OIDC_CONFIG](state, oidcConfig) {
      state.oidcConfig = oidcConfig
    },
  },
  actions: {
    [actions.LOAD_ENV_JWT_TOKEN]({ commit }) {
      return organization.fetchEnvJwtToken().then((res) => {
        commit(mutations.SET_ENV_JWT_TOKEN, res.data.token)
      })
    },
    [actions.FETCH_OIDC_CONFIG]({ commit }) {
      return organization.fetctOIDCConfig().then((res) => {
        commit(mutations.SET_OIDC_CONFIG, res.data.data)
      })
    },
    [actions.ADD_OIDC_CONFIG]({ dispatch }, data) {
      return organization.addOIDCConfig(data).then(() => {
        dispatch(actions.FETCH_OIDC_CONFIG)
      })
    },
    [actions.CREATE_OIDC_CONFIG]({ dispatch }, data) {
      return organization.createIODCconfig(data).then(() => {
        dispatch(actions.FETCH_OIDC_CONFIG)
      })
    },
    [actions.VERIFY_MFA_CODE]({ dispatch }, data) {
      return organization.verifyMFACode(data)
    },
    [actions.SEND_MFA_CODE]({ dispatch }, data) {
      return organization.sendMFACode(data)
    },
  },
}
