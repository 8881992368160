import {HubSpotAPI} from "./hubspot";
import {GoogleIntegrationAPI} from "./google";
import { DefaultIntegrationAPI } from "./base";
import { ZapierIntegrationAPI } from "./zapier";
import { MailChimpIntegrationAPI } from "./mailchimp";


const IntegrationAPIs: {[x: string]: typeof DefaultIntegrationAPI} = {
  hubspot: HubSpotAPI,
  googleSheets: GoogleIntegrationAPI,
  zapier: ZapierIntegrationAPI,
  mailchimp: MailChimpIntegrationAPI
}

const _default = new DefaultIntegrationAPI()

export const getIntegrationAPI = (provider: string) => {
  const providerAPI = IntegrationAPIs[provider]
  if (providerAPI) {
    return new providerAPI()
  }
  return _default
}