import _ from "lodash";
import {VDomCommands} from "./VDomCommand";
import {FBVNode, FBVNodeComponent, FroalaVueComponent, VueGridStackComponent} from "../../../types";
import {ConvrrtFirebase} from "../convrrt-firebase";


export class PropertyChangeCommand extends VDomCommands {
    protected _firebaseKey: string;
    protected _newValue: FBVNode| any;
    protected readonly _lodashKey: string;
    protected _oldValue: FBVNode| any;

    constructor(gridItemVNodeId: string, key: string, newValue: object) {
        super(gridItemVNodeId);
        this._firebaseKey = key
        this._newValue =  newValue
        this._lodashKey = key.split("/").join(".")
        this._oldValue = _.clone(_.get(this.fbNode, this._lodashKey, ""))
    }

    getVueComponent() {
        if (!this._vNodeIdVueComponent_) {
            let _vNodeIdVueComponent_ = super.getVueComponentById(this._vNodeId, false)

            if (!_vNodeIdVueComponent_) {
                _vNodeIdVueComponent_ = super.getVueComponentByAttr(this._vNodeId)
            }
            this._vNodeIdVueComponent_ = _vNodeIdVueComponent_
        }
        return this._vNodeIdVueComponent_
    }

    get$emitData() {
        return  {name: 'PropertyChangeCommand', forceBuild: false}
    }

    get hasChanges (): boolean {
        return _.isEqual(this._newValue, this._oldValue) === false;
    }

    get fbNode() {
        return this.getVueComponent()?.fbNode
    }

    get firebaseRef() {
        return this.getVueComponent()?.firebaseRef()
    }

    async execute() {
        await this.firebaseRef?.setChild(this._firebaseKey, this._newValue)
    }

    async unExecute() {
        await this.firebaseRef?.setChild(this._firebaseKey, this._oldValue)
    }

    scrollToComponent () {
        return new Promise((resolve) => {
            this.getVueComponent()?.$el.scrollIntoView({
                behavior: 'smooth',
                block: "center",
                inline: "nearest"
            })
            setTimeout(resolve,100)
        })
    }
}

export class SizePropertyChangeCommand extends PropertyChangeCommand {
    private _compareOldValue: FBVNode;
    private _compareNewValue: FBVNode;

    constructor(gridItemVNodeId: string, key: string, newValue: FBVNode) {
        super(gridItemVNodeId, key, newValue);

        this._newValue = Object.assign({}, this._oldValue, newValue)
        this._compareOldValue = {
            dataWidth: this._oldValue.dataWidth,
            dataHeight: this._oldValue.dataHeight,
            dataY: this._oldValue.dataY,
            dataX: this._oldValue.dataX,
        }
        this._compareNewValue = {
            dataWidth: this._newValue.dataWidth,
            dataHeight: this._newValue.dataHeight,
            dataY: this._newValue.dataY,
            dataX: this._newValue.dataX,
        }
    }

    getVueComponent(): FBVNodeComponent {
        const _vNodeIdVueComponent_ = this.getVueComponentByAttr(this._vNodeId) as FBVNodeComponent
        if (!['GridItemWrapper', 'GridItemWrapperV3'].includes(_vNodeIdVueComponent_?.name)){
            throw 'Component name is not supported for this action'
        }
        return _vNodeIdVueComponent_
    }

    get _gridComponent(): VueGridStackComponent{
        const gridComponent =  this.getVueComponent()?.parent() as VueGridStackComponent
        if (!gridComponent?.getGrid) {
            // Element must be a gridstack element [GridContainer or SubGridContainer]
            throw 'Invalid Element Found'
        }
        return gridComponent
    }

    get$emitData() {
        return {name: 'SizePropertyChangeCommand', forceBuild: false}
    }
    updateElementSize(value: FBVNode){
        this._gridComponent.getGrid().update(
            this.getVueComponent()?.$el, {
                w: value.dataWidth,
                h: value.dataHeight,
                x: value.dataX,
                y: value.dataY,
            }
        )
    }

    get hasChanges () {
        return _.isEqual(this._compareNewValue, this._compareOldValue) === false;
    }

    async execute() {
        await this.firebaseRef?.setChild(this._firebaseKey, this._newValue)
        this.updateElementSize(this._newValue)
    }

    async unExecute() {
        await this.firebaseRef?.setChild(this._firebaseKey, this._oldValue)
        this.updateElementSize(this._oldValue)
    }
}


export class FroalaContentChangeCommand extends PropertyChangeCommand {
    private _executed_first: boolean;

    constructor(gridItemVNodeId: string, key: string, newValue: object) {
        super(gridItemVNodeId, key, newValue);
        this._executed_first = false
    }

    getVueComponent(): FroalaVueComponent {
        let _vNodeIdVueComponent_ = super.getVueComponent() as FroalaVueComponent
        if (_vNodeIdVueComponent_ && !_vNodeIdVueComponent_.froala){
            throw 'Component name is not supported for this action'
        }
        return _vNodeIdVueComponent_
    }

    async execute() {
        await super.execute()
        if (this._executed_first) {
            this.getVueComponent().froala?.html?.set?.(this._newValue)
        }
        this._executed_first = true
    }

    async unExecute() {
        await super.unExecute()
        this.getVueComponent().froala?.html?.set?.(this._oldValue)
    }
}


export class ManualPropertyChangeCommand extends PropertyChangeCommand {
    private readonly _vDomFirebaseRef: ConvrrtFirebase;
    constructor(rootPathKey: string, key: string, newValue: object, oldValue: object, vNodeId: string = "page-engine-wrapper", isProps: boolean = true) {
        const _key = isProps ? `/data/props/${key}` : key
        super(vNodeId, _key, newValue);
        this._oldValue = oldValue
        this._vDomFirebaseRef = new ConvrrtFirebase(rootPathKey)
    }

    get fbNode() {
        return {}
    }

    get firebaseRef() {
        return this._vDomFirebaseRef
    }

    getVueComponent(): FBVNodeComponent {
        return this.getVueComponentByAttr(this._vNodeId, false) as FBVNodeComponent
    }
}