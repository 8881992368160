import * as url from 'url'
import * as qs from 'qs'
const STRIPE_CONNECT_WINDOW_TITLE = 'Connect to Stripe'
const STRIPE_CONNECT_URL = 'https://dashboard.stripe.com/oauth/authorize'

function stripeConnectURL(clientID) {
  return `${STRIPE_CONNECT_URL}?${qs.stringify({
    response_type: 'code',
    client_id: clientID,
    redirect_uri: url.format({
      protocol: window.location.protocol,
      host: window.location.host,
      pathname: '/stripe/connect',
    }),
    scope: 'read_write',
  })}`
}

function windowOptions({ width, height }) {
  const top = window.screen.height / 2 - height / 2
  // window.screenX is to add multi monitor support
  // https://bugs.chromium.org/p/chromium/issues/detail?id=137681
  const left = window.screen.width / 2 - width / 2 + window.screenX
  return `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,width=${width},height=${height},top=${top},left=${left}`
}

export default function stripeConnect(clientID) {
  const connectWindow = window.open(
    stripeConnectURL(clientID),
    STRIPE_CONNECT_WINDOW_TITLE,
    windowOptions({
      width: 480,
      height: 600,
    }),
  )
  return new Promise((resolve, reject) => {
    const handler = ({ data, origin, source }) => {
      let resp

      try {
        resp = JSON.parse(data)
      } catch (err) {
        console.debug(`failed to parse stripe connect message`)
        console.debug({ origin, source, data })
        return
      }

      if (!resp.stripe) {
        console.debug(`skipping non stripe message`)
        console.debug({ origin, source, data })
        return // not our message
      }

      // remove the event listener from the window
      if (origin !== window.location.origin) {
        return reject(
          new Error('stripe connect message not received by same origin'),
        )
      }

      const { stripe } = resp

      connectWindow.close()
      window.removeEventListener('message', handler, false)
      return stripe.error ? reject(stripe) : resolve(stripe)
    }
    window.addEventListener('message', handler, false)
  })
}

window.stripeConnect = stripeConnect
