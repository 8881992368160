export default {
  name: 'OrgFooter',
  props: {
    altFooterStyle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {},
}