<template>
  <cvt-dialog
    :title="title"
    :show="visible"
    :bg-color="modalCustomConfig.dialogBgColor"
    :hide-borders="modalCustomConfig.hideBorders || false"
    @close="close"
  >
    <template
      v-if="showVersionHistoryText"
      #title
    >
      <h5 style="margin-top: 20px;padding: 0 22px;">{{ $t('element.properties.revision.button.label') }}</h5>
    </template>
    <div
      class="revision-manager-dialog"
      :style="modalCustomConfig.revisionManagerStyles"
    >
      <form
        v-if="VersionHistoryCombined"
        disabled="saving"
        @submit.prevent="saveSnapshotAndClearForm"
      >
        <div class="row mb-2" style="padding-top: 10px;" :style="modalCustomConfig.searchContainerStyles">
          <div class="col col-8" :class="modalCustomConfig.searchInputClass" :style="modalCustomConfig.searchInputStyles">
            <cvt-input
              :value="snapshotDesc"
              type="text"
              :style="modalCustomConfig.cvtInputStyles"
              :label="$t('element.revision.snapshot.description.label')"
              :no-label="true"
              :placeholder="$t('element.revision.snapshot.description.label')"
              required
              @input="setSnapshotDesc"
            />
          </div>
          <div :class="modalCustomConfig.searchBtnStyles ? '':'col'">
            <cvt-button
              :style="modalCustomConfig.searchBtnStyles"
              :disabled="saveButtonDisabled"
              :text="$t('element.snapshot.save.label')"
              :full-width="true"
            />
          </div>
        </div>
      </form>
      <div
        v-loading="saving"
        class="revision-table-container"
      >
        <table
          class="table table-striped revision-table"
        >
          <thead>
            <tr>
              <th scope="col" :style="modalCustomConfig.tableHeadStyles">
                {{ $t("element.revision.snapshot.user.label") }}
              </th>
              <th scope="col" :style="modalCustomConfig.tableHeadStyles">
                {{ $t("element.revision.description.label") }}
              </th>
              <th scope="col" :style="modalCustomConfig.tableHeadStyles">
                {{ $t("element.revision.snapshot.timestamp.label") }}
              </th>
              <th scope="col" :style="modalCustomConfig.tableHeadStyles" />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="rev in history"
              :key="rev.id"
              :class="highlightCurrentSnapshotClass(rev.id)"
              :style="modalCustomConfig.tableBodyClass"
            >
              <td v-text="rev.actor.email" />
              <td v-text="rev.event.description" />
              <td v-text="timeDistance(rev.timestampISO)" />
              <td :style="modalCustomConfig.historyBtnTableStyles">
                <cvt-button
                  :loading="loading && clickedRevId === rev.id"
                  :disabled="restored(rev.id)"
                  :text="restoreButtonText(rev.id)"
                  color="light"
                  :style="historyBtnStyles(rev.id)"
                  :outlined="true"
                  :full-width="modalCustomConfig.historyBtnFullWidth || true"
                  @click.prevent="restore(rev.id)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </cvt-dialog>
</template>

<script>
import * as _ from 'lodash'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import * as getters from '../store/modules/getters'
import { Notification } from 'element-ui'
import moment from 'moment'

export default {
  components: {},

  data () {
    return {
      snapshotDesc: '',
      clickedRevId: '',
      useConfig: false
    }
  },
  computed: {
    ...mapState('revisions', [
      'saving',
      'loading',
      'visible',
      'history',
      'prevSnapshotID',
      'currentSnapshotID',
      'pageChanged'
    ]),
    ...mapGetters('pageManager', {
          isSwitchingPages: 'isSwitchingPages'
    }),
    ...mapState('globalTheme', {
      VersionHistoryCombined: ({ globalTheme }) => globalTheme.VersionHistoryCombined !== undefined ? globalTheme.VersionHistoryCombined : true
    }),
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    title () {
      return this.$t('element.revision.title.label')
    },
    saveButtonDisabled () {
      return !this.pageChanged || this.loading || this.saving
    },
    showVersionHistoryText () {
      return !this.VersionHistoryCombined || this.modalCustomConfig.showVersionHistoryText;
    },
    modalCustomConfig () {
      return this.config.VERSION_HISTORY_MODAL_CONFIG?.custom || {}
    },
  },
  watch: {
    pageChanged (value) {
      if (value) {
        window.addEventListener('beforeunload', this.beforeUnloadEventHandler)
      } else {
        window.removeEventListener('beforeunload', this.beforeUnloadEventHandler)
      }
    }
  },
  methods: {
    ...mapMutations('revisions', [
      'close'
    ]),
    ...mapActions('revisions', [
      'saveSnapshot',
      'restoreSnapshot'
    ]),
    restoreButtonText (revId) {
      return this.restored(revId)
      ? this.$t('element.revision.snapshot.restored.label')
      : this.$t('element.revision.snapshot.restore.label')
    },
    highlightCurrentSnapshot (snapshotID) {
      return { 'table-primary': this.currentSnapshotID === snapshotID }
    },
    highlightCurrentSnapshotClass (snapshotID) {
      return Object.keys(this.modalCustomConfig).length && !this.modalCustomConfig.highlightCurrentSnapshot
      ? ''
      : this.highlightCurrentSnapshot(snapshotID)
    },
    restored (revId) {
      return this.currentSnapshotID === revId
    },
    restore (revId) {
      this.clickedRevId = revId
      this.restoreSnapshot(revId)
    },
    async saveSnapshotAndClearForm () {
      await this.saveSnapshot({
        key: 'manually.saved',
        description: this.snapshotDesc
      })
      this.snapshotDesc = ''
    },
    setSnapshotDesc: _.debounce(function ({target}) {
      this.snapshotDesc = target.value
    }, {
      trailing: true
    }, 600),
    async beforeUnloadEventHandler (event) {
      if (!this.isSwitchingPages) {
          event.preventDefault()
          // Chrome requires returnValue to be set.
          event.returnValue = ''
          // TODO: I18N
          await this.saveSnapshot({
              key: 'draft.auto.saved',
              description: this.$t('element.revision.browser.label')
          }).then((e) => {
              Notification.success({
                  duration: 6000,
                  message: `${this.$t('elements.property.revision.manger.success.saved.message')}`,
                  position: 'bottom-right'
              })
          }).catch((e) => {
              Notification.error({
                  duration: 6000,
                  message: 'Snapshot not save automatically, please try to save it manually!',
                  position: 'bottom-right'
              })
          })
      }
    },
    timeDistance (timestamp) {
      return moment(timestamp).fromNow()
    },
    historyBtnStyles (revId) {
      return this.modalCustomConfig.useCustomHistoryBtn ? this.restored(revId) ? this.modalCustomConfig.historyBtnActive: this.modalCustomConfig.historyBtnRestore : ''
    },
  }
}
</script>

<style lang="scss">
    @import "@/scss/variables";
    .revision-table-container {
      max-height: 500px;
      overflow-y: scroll;
    }
</style>
