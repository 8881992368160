<template>
  <div :class="stylesheet.classes.presentation">
   <div :class="stylesheet.classes.presentationBody">
     <div v-if="template.enableIcon" class="center-icon">
       <div v-if="useIconAsImage">
         <div
           class="img-fluid"
           :style="imgStylesGB"
         />
       </div>
       <div v-else :class="iconWrapperClasses">
         <icon-template
           :set="iconProperties.set"
           :selector="iconProperties.selector"
           :class="iconClasses"
         />
       </div>

     </div>
     <!-- eslint-disable vue/no-v-html -->
     <div v-if="template.enableHeadline" class="center-headline" v-html="titleHTML" >
     </div>
     <!-- eslint-disable vue/no-v-html -->
     <div v-if="template.enableText" class="center-text" v-html="descriptionHTML" >
     </div>

     <div  v-if="template.enableButton">
       <c-button v-bind="buttonProps" :fb-node="buttonData"/>
     </div>
   </div>
  </div>
</template>

<script>
import Vue from "vue";
import IconTemplate from "@/js/components/editor/nodes/icon/IconTemplate.vue";
import { StyleSheet } from "@/js/lib/styleGenerator";
import { mapGetters } from "vuex";
import * as getters from "@/js/store/modules/getters";
import CButton from "@/js/components/v3/editor/nodes/CButton.vue";

export default Vue.extend({
  name: "CardTemplate",
  components: { IconTemplate, CButton },
  props: {
    template: {
      type: Object,
      required: true,
    },
    presentation: {
      type: String,
      required: true
    },
    templateKey: {
      type: String,
      required: true
    },
    layoutView: {
      type: String,
      required: true
    },
  },
  data () {
    this._sheet = new StyleSheet()

    return {
    }
  },
  computed: {
    ...mapGetters({
      theme: getters.THEME,
    }),
    buttonData () {
      return this.template.button
    },
    buttonProps () {
      return { ...this.buttonData.data.props, ['.path']: `${this.templateKey}/button`.replaceAll('//', "/") }
    },
    iconProperties () {
      return this.template.icon
    },
    titleHTML () {
      return this.template.headline
    },
    descriptionHTML () {
      return this.template.text
    },
    stylesheet () {
      return this._sheet.update(this.styles)
    },
    useIconAsImage () {
      return this.template.useIconAsImage || false
    },
    iconImageWidth () {
      return this.iconProperties.imageWidth || 3
    },
    iconImageSRC () {
      return this.iconProperties.src
    },
    imgStylesGB() {
      return {
        backgroundImage: `url(${this.altIconSrc})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: this.iconProperties.hideImgOverflow ? 'cover' : 'contain',
        height: this.iconImageWidth ? `${this.iconImageWidth}rem` : null,
        width: this.iconImageWidth ? `${this.iconImageWidth}rem` : null,
        borderRadius: `${this.iconProperties.borderRadius || 0}%`
      }
    },
    altIconSrc() {
      return this.iconImageSRC || '/img/img-placeholder.svg'
    },
    styles() {
      return {
        icon: {
          fontSize: `${this.iconProperties.size}rem!important`,
          color: this.iconForegroundThemeColorHSL,
          zIndex: 0,
        },
        iconWrapper: this.iconWrapperStyle,
        ...this.otherStyles,
      }
    },
    otherStyles () {
      const alignment = {alignItems: 'self-start'}
      if (this.presentation === 'center') {
        alignment.alignItems = 'center'
        alignment.textAlign = 'center'
      }

      return {
        presentation : {
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          height: '100%',
          wordWrap: 'break-word',
          '@global': {
            'h1, h2, h3, h4, h5, h6': {
              margin: 'unset !important'
            },
            p: {
              marginBottom: 0
            }
          },
        },
        presentationBody: {
          display: 'flex',
          flex: '1 1 auto',
          padding: this.layoutView === 'list'? "0.5rem": '1rem',
          gap: `${this.template.gap}px`,
          justifyContent: 'center',
          flexDirection: 'column',
          ...alignment
        },
        presentationIcon : {
          // Style div directly wrapping the iconWrapperStyle div for content positioning
          display: 'flex',
          justifyContent: this.iconProperties.alignment,
        }
      }
    },
    iconWrapperStyle() {
      // Style div directly wrapping the icon
      return {
        width: '100%',
        backgroundColor: this.iconBgThemeColorHSL,
        display: 'block',
        padding: `0 ${0.2*this.iconProperties.size}rem`,
        borderRadius: `${this.iconProperties.borderRadius || 0}%`,
        borderWidth: `${this.iconProperties.borderWidth || 0}px`,
        borderStyle: `${this.iconProperties.borderStyle}`,
        borderColor: `${this.iconProperties.borderColor}`,
      }
    },
    iconForegroundThemeColor () {
      if (this.iconProperties.foreground?.themeColorId) {
        let color = this.themeColors[this.iconProperties.foreground?.themeColorId]
        let alpha = this.iconProperties.themeColorAlpha

        return color && color.alpha(alpha)
      }
      return null
    },
    iconForegroundThemeColorHSL () {
      return this.iconForegroundThemeColor ? this.iconForegroundThemeColor.hsl().toString() : null
    },
    iconBgThemeColor () {
      let color = this.themeColors[this.iconProperties.themeColorId]
      let alpha = this.iconProperties.themeColorAlpha

      return color && color.alpha(alpha)
    },
    iconBgThemeColorHSL () {
      return this.iconBgThemeColor ? this.iconBgThemeColor.hsl().toString() : null
    },
    iconWrapperClasses() {
      return {
        [this.stylesheet.classes.iconWrapper]: true,
      }
    },
    iconClasses() {
      return {
        [this.stylesheet.classes.icon]: true,
      }
    },
    themeColors () {
      return {
        ...this.theme.colors,
        ...this.theme.blockColors
      }
    },
    themeColorHSL () {
      return this.themeColor ? this.themeColor.hsl().toString() : null
    },
  }
});
</script>

