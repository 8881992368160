import CvtSlider from './Slider'
// import CvtVerticalSlider from './VerticalSlider'
import { CvtTooltipPlugin } from '../tooltip/index'
import { pluginFactory } from '../../../lib/utils/plugins'

const Plugin = pluginFactory({
  components: {
    CvtSlider,
    // CvtVerticalSlider,
  },
  plugins: [CvtTooltipPlugin],
})

export { Plugin, CvtSlider }
