import Vue from '../../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import CvtButton from '../../molecules/Button'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import CvtInput from '../../molecules/Input'
import CvtAlert from '../../molecules/Alert'
import CvtTable from '../../molecules/table/table'
import CvtThead from '../../molecules/table/thead'
import CvtTbody from '../../molecules/table/tbody'
import CvtTh from '../../molecules/table/th'
import CvtTr from '../../molecules/table/tr'
import CvtTd from '../../molecules/table/td'
import CvtAdvanceAlert from '../../molecules/AdvanceAlert'

import VueI18n from 'vue-i18n'
Vue.use(VueEmotion)
Vue.use(VueI18n)

let textFontSize,
  textFontWeight,
  backgroundColor,
  color,
  borderColor,
  BorderStyle,
  textLineHeight,
  LineHeight,
  Spacing,
  FontFamily,
  Border,
  textColor

const getCustomDomainAction = () => styled('div')`
  margin-bottom: ${Spacing.SPACING_32}px;
`

const getCustomDomainContainer = () => styled('div')`
  display: flex;
  align-items: flex-start;
  font-family: ${FontFamily.DEFAULT};
  margin-top: ${Spacing.SPACING_8}px;
`

const CustomDomainInput = styled(CvtInput)`
  flex: 1;
`

const getConnectBtn = () => styled(CvtButton)`
  align-self: flex-end;
  margin-bottom: 2px;
  margin-left: ${Spacing.SPACING_16}px;
`

const DomainInstruction = styled('div')`
  h5 {
    ${(props) =>
      props.mode == 'DARK'
        ? color({ themeing: 'dark' }, 'WHITE')
        : color({ themeing: 'dark' }, 500)}
  }
`

const getDomainInstructionTitle = () => styled('h5')`
  margin-top: ${Spacing.SPACING_32}px;
  margin-bottom: ${Spacing.SPACING_32}px;
  ${textFontSize('md')}
  ${textLineHeight('md')}
  ${textFontWeight('bold')}
`

const getStepTitle = () => styled('h5')`
  ${textFontSize('lg')}
  ${textLineHeight('lg')}
  ${textFontWeight('regular')}
  &.--mb-24 {
    margin-bottom: ${Spacing.SPACING_24}px;
  }
`

const getSteps = () => styled('div')`
  margin-bottom: ${Spacing.SPACING_32}px;
  & .step-progress {
    position: relative;
    padding-bottom: ${Spacing.SPACING_32}px;
    &:last-child {
      padding-bottom: 0;
      &::before {
        border-left: none;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -29px;
      width: 10px;
      height: 100%;
      border-left: ${Border.SIZE_1}px solid;
      ${borderColor({ themeing: 'dark' }, 200)}
    }
    &::after {
      left: -45px;
      top: -4px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
    }
  }
`

const getProgress = () => styled('div')`
  padding-left: ${Spacing.SPACING_48}px;
`

const getFirstStep = () => styled('div')`
  ul {
    ${textFontSize('lg')}
    ${textLineHeight('lg')}
    ${textFontWeight('regular')}
    list-style: "-";
    padding-left: ${Spacing.SPACING_24}px;
    li {
      padding-left: ${Spacing.SPACING_8}px;
    }
  }
  &::after {
    content: '1';
    border: 1px solid;
    border-radius: 50%;
    ${borderColor({ themeing: 'dark' }, 200)}
    ${(props) =>
      props.mode == 'LIGHT'
        ? backgroundColor({ themeing: 'dark' }, 'WHITE')
        : backgroundColor({ themeing: 'dark' }, 700)}
    ${(props) =>
      props.mode == 'LIGHT'
        ? color({ themeing: 'primary' }, 500)
        : color({ themeing: 'dark' }, 'WHITE')}
  }
`

const getSecondStep = () => styled('div')`
  &::after {
    content: '2';
    border: 1px solid;
    border-radius: 50%;
    ${borderColor({ themeing: 'dark' }, 200)}
    ${(props) =>
      props.mode == 'LIGHT'
        ? backgroundColor({ themeing: 'dark' }, 'WHITE')
        : backgroundColor({ themeing: 'dark' }, 700)}
    ${(props) =>
      props.mode == 'LIGHT'
        ? color({ themeing: 'primary' }, 500)
        : color({ themeing: 'dark' }, 'WHITE')}
  }
`

const getThirdStep = () => styled('div')`
  &::after {
    content: '3';
    border: 1px solid;
    border-radius: 50%;
    ${borderColor({ themeing: 'primary' }, 500)}
    ${backgroundColor({ themeing: 'primary' }, 500)}
    ${color({ themeing: 'dark' }, 'WHITE')}
  }
`

const getFourthStep = () => styled('div')`
  &::after {
    content: '✓';
    border: 1px solid;
    border-radius: 50%;
    ${borderColor({ themeing: 'success' }, 500)}
    ${backgroundColor({ themeing: 'success' }, 500)}
    ${color({ themeing: 'dark' }, 'WHITE')}
  }
`

const LinkContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
`

const LinkBtn = styled(CvtButton)``

const DNSCheckLink = styled('a')`
  &:hover {
    text-decoration: none;
  }
`

const getDNSCheckBtn = () => styled(CvtButton)`
  margin-bottom: ${Spacing.SPACING_16}px;
`

const getDNSCheckAlert = () => styled(CvtAlert)`
  p {
    margin-bottom: ${Spacing.SPACING_16}px;
  }
`

const getDNSStatusImg = () => styled('img')`
  margin-top: ${Spacing.SPACING_8}px;
  max-width: 100%;
`

const getDomainListContainer = () => styled('div')`
  display: flex;
  flex-direction: column;
  .h5-color {
    ${(props) =>
      props.mode == 'DARK'
        ? color({ themeing: 'dark' }, 'WHITE')
        : color({ themeing: 'dark' }, 500)}
  }
`

const getDomainListRow = () => styled('div')`
  display: flex;
  flex-direction: row;
  margin-bottom: ${Spacing.SPACING_8}px;
  padding: ${Spacing.SPACING_8}px 0;
  &:first-child {
    border-bottom: 1px solid;
    ${borderColor({ themeing: 'dark' }, 200)}
  }
`

const getDomainListHeading = () => styled('h5')`
  ${textFontSize('md')}
  ${textLineHeight('md')}
  ${textFontWeight('bold')}
`

const DomainRefresh = styled(CvtButton)`
  margin-left: auto;
`

const DomainNameContainer = styled('div')`
  flex: 2;
`

const DomainStatusContainer = styled('div')`
  flex: 1;
`

const DomainActionContainer = styled('div')`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const getDomainName = () => styled('h5')`
  cursor: text;
  user-select: text;
  ${textFontSize('lg')}
  ${textLineHeight('lg')}
  ${textFontWeight('regular')}
`

const getDomainStatusHeading = () => styled('h5')`
  ${textFontSize('md')}
  ${textLineHeight('md')}
  ${textFontWeight('bold')}
`

const getDomainStatus = () => styled('h5')`
  ${textFontSize('lg')}
  ${textLineHeight('lg')}
  ${textFontWeight('regular')}
  ${(props) =>
    props.status == 'active'
      ? color({ themeing: 'success' }, 500)
      : color({ themeing: 'danger' }, 500)}
`

const DNSActionBtns = styled('div')`
  display: flex;
  justify-content: flex-end;
`

const getDNSUnlinkBtn = () => styled(CvtButton)`
  margin-left: ${Spacing.SPACING_16}px;
  margin-bottom: auto;
`

const getTableBody = () => styled(CvtTbody)`
  td {
    cursor: text;
    user-select: text;
    ${textFontSize('lg')}
    ${textLineHeight('lg')}
    ${textFontWeight('regular')}
    ${color({ themeing: 'danger' }, 500)}
  }
`

const getTableHead = () => styled(CvtThead)`
  th {
    cursor: text;
    user-select: text;
    ${textFontSize('md')}
    ${textLineHeight('md')}
    ${textFontWeight('bold')}
    ${(props) =>
      props.mode == 'DARK'
        ? color({ themeing: 'dark' }, 'WHITE')
        : color({ themeing: 'dark' }, 500)}
  }
`

const getRsbDomainListContainer = () => styled('div')`
  width: ${3 * Spacing.SPACING_96 + Spacing.SPACING_12}px;
`

const getRsbCustomDomainContainer = () => styled('div')`
  width: ${3 * Spacing.SPACING_96 + Spacing.SPACING_12}px;
  padding: 0px ${Spacing.SPACING_24}px 0px ${Spacing.SPACING_24}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  font-family: ${FontFamily.DEFAULT};
  margin-top: ${Spacing.SPACING_48}px;
  height: ${Spacing.SPACING_96 + Spacing.SPACING_24 - Spacing.SPACING_4}px;
`

const getRsbContainer = () => styled('div')`
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  width: ${3 * Spacing.SPACING_96 + Spacing.SPACING_12}px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: ${Border.SIZE_1}px;
  border-style: ${BorderStyle.SOLID};
  ${borderColor({ themeing: 'dark' }, 500)};
`

const getRsbDomainLabel = () => styled('div')`
  font-family: ${FontFamily.DEFAULT};
  line-height: ${LineHeight.LINE_HEIGHT_24}px;
  font-style: normal;
  text-transform: capitalize;
  margin-top: ${Spacing.SPACING_12}px;
  margin-left: ${Spacing.SPACING_12 + Spacing.SPACING_2}px;
  margin-bottom: ${Spacing.SPACING_12}px;
  ${textFontWeight('BOLD')};
  ${textFontSize('md')};
  ${color({ themeing: 'dark' }, 500)};
`

const getRsbDomainUrl = () => styled('div')`
  font-family: ${FontFamily.DEFAULT};
  line-height: ${LineHeight.LINE_HEIGHT_24}px;
  font-style: normal;
  margin-left: ${Spacing.SPACING_12 + Spacing.SPACING_2}px;
  margin-bottom: ${Spacing.SPACING_32}px;
  ${textFontWeight('REGULAR')};
  ${textFontSize('lg')};
  ${color({ themeing: 'dark' }, 500)};
`

const getRsbDomainStatusLabel = () => styled('div')`
  font-family: ${FontFamily.DEFAULT};
  line-height: ${LineHeight.LINE_HEIGHT_24}px;
  font-style: normal;
  margin-left: ${Spacing.SPACING_12 + Spacing.SPACING_2}px;
  margin-bottom: ${Spacing.SPACING_4}px;
  ${textFontWeight('BOLD')};
  ${textFontSize('md')};
  ${color({ themeing: 'dark' }, 500)};
`

const getRsbDomainStatus = () => styled('div')`
  font-family: ${FontFamily.DEFAULT};
  line-height: ${LineHeight.LINE_HEIGHT_24}px;
  font-style: normal;
  margin-left: ${Spacing.SPACING_12 + Spacing.SPACING_2}px;
  margin-bottom: ${Spacing.SPACING_32}px;
  ${textFontWeight('REGULAR')};
  ${textFontSize('lg')};
  ${(props) => {
    if (props.status && props.status === 'active') {
      return `${color({ themeing: 'success' }, 500)}`
    } else {
      return `${color({ themeing: 'danger' }, 500)}`
    }
  }}
`

const getRsbDNSActionBtns = () => styled('div')`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: ${Spacing.SPACING_24}px;
`

const getRsbDNSCheckLink = () => styled('a')`
  &:hover {
    text-decoration: none;
  }
`

const getRsbDNSUnlinkBtn = () => styled(CvtButton)``

const getRsbDNSCheckBtn = () => styled(CvtButton)``

const getRsbDomainRefresh = () => styled(CvtButton)``

const getRsbCustomDomainInput = () => styled(CvtInput)``

const getRsbConnectBtn = () => styled(CvtButton)``

const getRsbDomainInstructionContainer = () => styled('div')`
  width: ${3 * Spacing.SPACING_96 + Spacing.SPACING_12}px;
  h5 {
    ${(props) =>
      props.mode == 'DARK'
        ? color({ themeing: 'dark' }, 'WHITE')
        : color({ themeing: 'dark' }, 500)}
  }
`

const getRsbDomainInstructionTitle = () => styled('h5')`
  margin-left: ${Spacing.SPACING_32 + Spacing.SPACING_12}px;
  margin-top: ${Spacing.SPACING_32}px;
  margin-bottom: ${Spacing.SPACING_32}px;
  ${textFontSize('md')}
  ${textLineHeight('md')}
  ${textFontWeight('bold')}
`

const getRsbSteps = () => styled('div')``

const getRsbStepTitle = () => styled('h5')`
  ${textFontSize('lg')}
  ${textLineHeight('lg')}
  ${textFontWeight('regular')}
  &.--mt-20 {
    margin-top: ${Spacing.SPACING_12 + Spacing.SPACING_8}px;
  }
  &.--mb-20 {
    margin-bottom: ${Spacing.SPACING_12 + Spacing.SPACING_8}px;
  }
  &.--mt-24 {
    margin-top: ${Spacing.SPACING_24}px;
  }
  &.--mb-24 {
    margin-bottom: ${Spacing.SPACING_24}px;
  }
  &.--ml-12 {
    margin-left: ${Spacing.SPACING_12}px;
  }
  &.--mb-12 {
    margin-bottom: ${Spacing.SPACING_12}px;
  }
  &.--mt-16 {
    margin-top: ${Spacing.SPACING_16}px;
  }
`

const getRsbStepIcon = () => styled('div')`
  width: ${Spacing.SPACING_32}px;
  height: ${Spacing.SPACING_32}px;
  margin-left: ${Spacing.SPACING_12}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 50%;
  ${(props) => {
    let styles = ``
    if (props.stepNumber && (props.stepNumber == 1 || props.stepNumber == 2)) {
      styles += `${borderColor({ themeing: 'dark' }, 200)};`
      styles += `${
        props.mode == 'LIGHT'
          ? backgroundColor({ themeing: 'dark' }, 'WHITE')
          : backgroundColor({ themeing: 'dark' }, 700)
      };`
      styles += `${
        props.mode == 'LIGHT'
          ? color({ themeing: 'primary' }, 500)
          : color({ themeing: 'dark' }, 'WHITE')
      };`
      return styles
    }
  }}
  ${(props) => {
    let styles = ``
    if (props.stepNumber && props.stepNumber == 3) {
      styles += `${borderColor({ themeing: 'primary' }, 500)};`
      styles += `${backgroundColor({ themeing: 'primary' }, 500)};`
      styles += `${color({ themeing: 'dark' }, 'WHITE')};`
    }
    return styles
  }}
  ${(props) => {
    let styles = ``
    if (props.stepNumber && props.stepNumber == 4) {
      styles += `${borderColor({ themeing: 'success' }, 500)};`
      styles += `${backgroundColor({ themeing: 'success' }, 500)};`
      styles += `${color({ themeing: 'dark' }, 'WHITE')};`
    }
    return styles
  }}
`

const getRsbFirstStep = () => styled('div')`
  margin-left: ${Spacing.SPACING_12}px;
  margin-top: ${Spacing.SPACING_32}px;
  margin-bottom: ${Spacing.SPACING_32}px;
  ul {
    ${textFontSize('lg')}
    ${textLineHeight('lg')}
    ${textFontWeight('regular')}
    list-style: "-";
    padding-left: ${Spacing.SPACING_24}px;
    li {
      padding-left: ${Spacing.SPACING_8}px;
    }
  }
`

const getRsbSecondStep = () => styled('div')``

const getRsbThirdStep = () => styled('div')``

const getRsbFourthStep = () => styled('div')``

const getRsbTable = () => styled('div')`
  width: ${3 * Spacing.SPACING_96 + Spacing.SPACING_12}px;
`

const getRsbTableHead = () => styled('div')`
  margin-left: ${Spacing.SPACING_12}px;
`

const getRsbTableBody = () => styled('div')`
  margin-left: ${Spacing.SPACING_12}px;
  margin-bottom: ${Spacing.SPACING_32}px;
`

const getRsbTableTr = () => styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  ${(props) => {
    let styles = ``
    if (props.displayBorder) {
      styles += `border-top-width: 0px;`
      styles += `border-left-width: 0px;`
      styles += `border-right-width: 0px;`
      styles += `border-bottom-width: ${Border.SIZE_1}px;`
      styles += `border-style: ${BorderStyle.SOLID};`
      styles += `${borderColor({ themeing: 'dark' }, 500)};`
    }
    return styles
  }}
`

const getRsbTableTh = () => styled('div')`
  ${textFontWeight('BOLD')};
  line-height: ${Spacing.SPACING_24}px;
  ${(props) => {
    let styles = ``
    styles += `width: ${props.width}px;`
    return styles
  }}
`

const getRsbTableTd = () => styled('div')`
  ${textFontWeight('REGULAR')};
  ${color({ themeing: 'danger' }, 500)};
  line-height: ${Spacing.SPACING_24}px;
  word-break: break-all;
  padding: 0px ${Spacing.SPACING_4}px 0px ${Spacing.SPACING_4}px;
  ${(props) => {
    let styles = ``
    styles += `width: ${props.width}px;`
    return styles
  }}
`

const getRsbDNSCheckLinkSecond = () => styled('a')`
  &:hover {
    text-decoration: none;
  }
`

const getRsbDNSCheckBtnSecond = () => styled(CvtButton)`
  margin-left: ${Spacing.SPACING_12}px;
  margin-right: ${Spacing.SPACING_12}px;
  margin-bottom: ${Spacing.SPACING_18 + Spacing.SPACING_2}px;
  & span {
    ${textFontSize('sm')}
  }
`

const getRsbDNSCheckAlert = () => styled(CvtAlert)`
  margin-left: ${Spacing.SPACING_12}px;
  margin-right: ${Spacing.SPACING_12}px;
  margin-bottom: ${Spacing.SPACING_32}px;
  p {
    margin-bottom: ${Spacing.SPACING_16}px;
  }
`

const getRsbLinkContainer = () => styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${Spacing.SPACING_12}px;
  margin-right: ${Spacing.SPACING_12}px;
`

const getRsbLinkBtn = () => styled(CvtButton)``

const getRsbAdvanceAlertWrapper = () => styled('div')`
  width: ${3 * Spacing.SPACING_96 + Spacing.SPACING_12}px;
  padding: 0px ${Spacing.SPACING_24}px 0px ${Spacing.SPACING_24}px;
  font-family: ${FontFamily.DEFAULT};
  margin-top: ${Spacing.SPACING_32}px;
`

const getRsbAdvanceAlert = () => styled(CvtAdvanceAlert)``

export const CvtCustomDomainAction = {
  name: 'CvtCustomDomainAction',
  data() {
    return {}
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    domain: {
      type: String,
      default: '',
    },
    showDomainInstruction: {
      type: Boolean,
      default: false,
    },
    linkBtnText: {
      type: String,
      default: 'Verify',
    },
    linkBtnLoading: {
      type: Boolean,
      default: false,
    },
    domainInfo: {
      type: Object,
      default: () => ({
        verificationKey: 'sendinblue-site-verification=convrrt',
        domain: 'abc.google.com',
        cname: 'cname.sibpages.com',
      }),
    },
    linkedDomains: {
      type: Array,
      default: () => [],
    },
    site: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    unlinkBtnTheme: {
      type: String,
      default: '',
    },
    componentUsage: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      color: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Border: ({ globalTheme }) => globalTheme.Border,
      topNavConfig: ({ topNavConfig }) => topNavConfig,
      LineHeight: ({ globalTheme }) => globalTheme.LineHeight,
      BorderStyle: ({ globalTheme }) => globalTheme.BorderStyle,
      customDomainConnectBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig.locations.launchPageComponent.locations
          .customDomainConnectBtn,
    }),
  },
  created() {
    textFontSize = this.textFontSize
    textFontWeight = this.textFontWeight
    backgroundColor = this.backgroundColor
    color = this.color
    borderColor = this.borderColor
    textLineHeight = this.textLineHeight
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Border = this.Border
    BorderStyle = this.BorderStyle
    LineHeight = this.LineHeight
    textColor = this.textColor
  },
  methods: {
    whatsMyDNSCNAME(domain) {
      return `https://www.whatsmydns.net/#CNAME/${domain}/${this.domainInfo.cname}`
    },
  },
  render: function (h) {
    const domainListRows = []
    const CustomDomainAction = getCustomDomainAction()
    const CustomDomainContainer = getCustomDomainContainer()
    const ConnectBtn = getConnectBtn()
    const DomainInstructionTitle = getDomainInstructionTitle()
    const StepTitle = getStepTitle()
    const Steps = getSteps()
    const Progress = getProgress()
    const FirstStep = getFirstStep()
    const SecondStep = getSecondStep()
    const ThirdStep = getThirdStep()
    const FourthStep = getFourthStep()
    const DNSCheckBtn = getDNSCheckBtn()
    const DNSCheckAlert = getDNSCheckAlert()
    const DNSStatusImg = getDNSStatusImg()
    const DomainListContainer = getDomainListContainer()
    const DomainListRow = getDomainListRow()
    const DomainListHeading = getDomainListHeading()
    const DomainName = getDomainName()
    const DomainStatusHeading = getDomainStatusHeading()
    const DomainStatus = getDomainStatus()
    const DNSUnlinkBtn = getDNSUnlinkBtn()
    const TableBody = getTableBody()
    const TableHead = getTableHead()

    const RsbDomainListContainer = getRsbDomainListContainer()
    const RsbCustomDomainContainer = getRsbCustomDomainContainer()
    const RsbContainer = getRsbContainer()
    const RsbDomainLabel = getRsbDomainLabel()
    const RsbDomainStatusLabel = getRsbDomainStatusLabel()
    const RsbDomainUrl = getRsbDomainUrl()
    const RsbDomainStatus = getRsbDomainStatus()

    const RsbDNSActionBtns = getRsbDNSActionBtns()
    const RsbDNSCheckLink = getRsbDNSCheckLink()
    const RsbDNSUnlinkBtn = getRsbDNSUnlinkBtn()
    const RsbDNSCheckBtn = getRsbDNSCheckBtn()
    const RsbDomainRefresh = getRsbDomainRefresh()

    const RsbCustomDomainInput = getRsbCustomDomainInput()
    const RsbConnectBtn = getRsbConnectBtn()

    const RsbDomainInstructionContainer = getRsbDomainInstructionContainer()
    const RsbDomainInstructionTitle = getRsbDomainInstructionTitle()
    const RsbSteps = getRsbSteps()
    const RsbStepTitle = getRsbStepTitle()
    const RsbFirstStep = getRsbFirstStep()
    const RsbSecondStep = getRsbSecondStep()
    const RsbThirdStep = getRsbThirdStep()
    const RsbFourthStep = getRsbFourthStep()

    const RsbTable = getRsbTable()
    const RsbTableHead = getRsbTableHead()
    const RsbTableBody = getRsbTableBody()
    const RsbTableTr = getRsbTableTr()
    const RsbTableTh = getRsbTableTh()
    const RsbTableTd = getRsbTableTd()

    const RsbDNSCheckLinkSecond = getRsbDNSCheckLinkSecond()
    const RsbDNSCheckBtnSecond = getRsbDNSCheckBtnSecond()
    const RsbDNSCheckAlert = getRsbDNSCheckAlert()
    const RsbLinkContainer = getRsbLinkContainer()
    const RsbLinkBtn = getRsbLinkBtn()

    const RsbStepNumberOne = getRsbStepIcon()
    const RsbStepNumberTwo = getRsbStepIcon()
    const RsbStepNumberThree = getRsbStepIcon()
    const RsbStepNumberFour = getRsbStepIcon()

    for (const record of this.linkedDomains) {
      if (this.componentUsage === '') {
        domainListRows.push(
          <DomainListRow key={record.domain}>
            <DomainNameContainer>
              <DomainName class="h5-color">{record.domain}</DomainName>
            </DomainNameContainer>
            <DomainStatusContainer>
              <DomainStatus status={record.status}>
                {record.status}
              </DomainStatus>
            </DomainStatusContainer>
            <DomainActionContainer>
              <DNSActionBtns>
                <DNSCheckLink
                  target="_blank"
                  href={this.whatsMyDNSCNAME(record.domain)}
                >
                  <DNSCheckBtn
                    color="light"
                    outlined={true}
                    prepend={false}
                    size="sm"
                    icon="share"
                    text={this.$t(
                      'custom_domain.ui.domain_list.check_dns_button.txt',
                    )}
                    mode={this.mode}
                  ></DNSCheckBtn>
                </DNSCheckLink>
                <DNSUnlinkBtn
                  color={this.unlinkBtnTheme}
                  outlined={true}
                  prepend={false}
                  size="sm"
                  icon="unlink"
                  text={this.$t(
                    'custom_domain.ui.domain_list.unlink_domain_button.txt',
                  )}
                  mode={this.mode}
                  onClick={() => {
                    this.$emit('dns-unlink', record.siteId, record.domain)
                  }}
                ></DNSUnlinkBtn>
              </DNSActionBtns>
            </DomainActionContainer>
          </DomainListRow>,
        )
      } else if (this.componentUsage === 'right-sidebar') {
        domainListRows.push(
          <RsbContainer key={record.domain + Math.random()}>
            <RsbDomainLabel>
              {this.$t('custom_domain.ui.domain_list.heading.domain')}
            </RsbDomainLabel>
            <RsbDomainUrl>{record.domain}</RsbDomainUrl>
            <RsbDomainStatusLabel>
              {this.$t('custom_domain.ui.domain_list.heading.status')}
            </RsbDomainStatusLabel>
            <RsbDomainStatus status={record.status}>
              {record.status}
            </RsbDomainStatus>
            <RsbDNSActionBtns>
              <RsbDNSCheckLink
                target="_blank"
                href={this.whatsMyDNSCNAME(record.domain)}
              >
                <RsbDNSCheckBtn
                  color="light"
                  outlined={true}
                  prepend={false}
                  size="sm"
                  icon="share"
                  text={this.$t(
                    'custom_domain.ui.domain_list.check_dns_button.txt',
                  )}
                  mode={this.mode}
                ></RsbDNSCheckBtn>
              </RsbDNSCheckLink>
              <RsbDomainRefresh
                color="light"
                shape="pill"
                size="sm"
                icon="recycle"
                shadow={true}
                mode={this.mode}
                onClick={() => this.$emit('refresh')}
              ></RsbDomainRefresh>
              <RsbDNSUnlinkBtn
                color={this.unlinkBtnTheme}
                outlined={true}
                prepend={false}
                size="sm"
                icon={this.$t(
                  'custom_domain.ui.domain_list.unlink_domain_button.txt',
                )}
                text="Unlink"
                mode={this.mode}
                onClick={() =>
                  this.$emit('dns-unlink', this.site.id, record.domain)
                }
              ></RsbDNSUnlinkBtn>
            </RsbDNSActionBtns>
          </RsbContainer>,
        )
      }
    }

    return (
      <CustomDomainAction>
        {this.linkedDomains.length > 0 && this.componentUsage === '' && (
          <DomainListContainer mode={this.mode}>
            <DomainListRow>
              <DomainNameContainer>
                <DomainListHeading class="h5-color">
                  {this.$t('custom_domain.ui.domain_list.heading.domain')}
                </DomainListHeading>
              </DomainNameContainer>
              <DomainStatusContainer>
                <DomainStatusHeading class="h5-color">
                  {this.$t('custom_domain.ui.domain_list.heading.status')}
                </DomainStatusHeading>
              </DomainStatusContainer>
              <DomainActionContainer>
                <DomainRefresh
                  color="light"
                  shape="pill"
                  size="sm"
                  icon="recycle"
                  shadow={true}
                  mode={this.mode}
                  onClick={() => this.$emit('refresh')}
                ></DomainRefresh>
              </DomainActionContainer>
            </DomainListRow>
            {domainListRows}
          </DomainListContainer>
        )}

        {this.linkedDomains.length > 0 &&
          this.componentUsage === 'right-sidebar' && (
            <RsbDomainListContainer mode={this.mode}>
              {domainListRows}
            </RsbDomainListContainer>
          )}

        {this.componentUsage === '' && (
          <CustomDomainContainer>
            <CustomDomainInput
              label={this.$t('custom_domain.ui.custom_domain_input.label')}
              floatingLabel={true}
              value={this.domain}
              onInput={(e) => this.$emit('domain-input', e)}
              onClear={(e) => this.$emit('clear', e)}
            ></CustomDomainInput>
            <ConnectBtn
              color={this.theme}
              outlined={this.outlined}
              text={this.$t('custom_domain.ui.custom_domain_button.text')}
              mode={this.mode}
              shadow={true}
              disabled={this.showDomainInstruction}
              onClick={() => this.$emit('validateDomainInput')}
              btnState={this.customDomainConnectBtnConfig.state}
            ></ConnectBtn>
          </CustomDomainContainer>
        )}

        {this.componentUsage === 'right-sidebar' && (
          <RsbCustomDomainContainer>
            <RsbCustomDomainInput
              label={this.$t('custom_domain.ui.custom_domain_input.label')}
              floatingLabel={false}
              id="custom-domain-input"
              value={this.domain}
              onInput={(e) => {
                this.$emit('domain-input', e.target.value)
              }}
              onClear={(e) => this.$emit('clear', e)}
            ></RsbCustomDomainInput>
            <RsbConnectBtn
              color={this.theme}
              outlined={this.outlined}
              text={this.$t('custom_domain.ui.custom_domain_button.text')}
              mode={this.mode}
              shadow={true}
              disabled={this.showDomainInstruction}
              fullWidth={true}
              onClick={() => this.$emit('validateDomainInput')}
            ></RsbConnectBtn>
          </RsbCustomDomainContainer>
        )}

        {this.showDomainInstruction && this.componentUsage === '' && (
          <DomainInstruction mode={this.mode}>
            <DomainInstructionTitle>
              {this.$t('custom_domain.ui.domain_instruction.title')}
            </DomainInstructionTitle>
            <Steps>
              <Progress>
                <FirstStep
                  mode={this.mode}
                  class="step-progress"
                  id="first-step"
                >
                  <StepTitle class="--mb-24">
                    {this.$t(
                      'custom_domain.ui.domain_instruction.first_step.title',
                    )}
                  </StepTitle>
                  <StepTitle>
                    {this.$t(
                      'custom_domain.ui.domain_instruction.first_step.example_providers',
                    )}
                  </StepTitle>
                  <ul>
                    <li>
                      <a target="_blank" href="//www.cloudflare.com">
                        {this.$t(
                          'custom_domain.ui.domain_instruction.first_step.provider_list.li1',
                        )}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="//www.godaddy.com">
                        {this.$t(
                          'custom_domain.ui.domain_instruction.first_step.provider_list.li2',
                        )}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="//www.namecheap.com">
                        {this.$t(
                          'custom_domain.ui.domain_instruction.first_step.provider_list.li3',
                        )}
                      </a>
                    </li>
                  </ul>
                </FirstStep>
                <SecondStep
                  mode={this.mode}
                  class="step-progress"
                  id="second-step"
                >
                  <StepTitle class="--mb-24">
                    {this.$t(
                      'custom_domain.ui.domain_instruction.second_step.title',
                    )}
                  </StepTitle>
                  <CvtTable mode={this.mode}>
                    <TableHead mode={this.mode}>
                      <CvtTr>
                        <CvtTh>
                          {this.$t(
                            'custom_domain.ui.domain_instruction.second_step.table_head.type',
                          )}
                        </CvtTh>
                        <CvtTh>
                          {this.$t(
                            'custom_domain.ui.domain_instruction.second_step.table_head.name',
                          )}
                        </CvtTh>
                        <CvtTh>
                          {this.$t(
                            'custom_domain.ui.domain_instruction.second_step.table_head.value',
                          )}
                        </CvtTh>
                      </CvtTr>
                    </TableHead>
                    <TableBody>
                      <CvtTr>
                        <CvtTd>TXT</CvtTd>
                        <CvtTd>@</CvtTd>
                        <CvtTd>{this.domainInfo.verificationKey}</CvtTd>
                      </CvtTr>
                      <CvtTr>
                        <CvtTd>CNAME</CvtTd>
                        <CvtTd>{this.domainInfo.domain}</CvtTd>
                        <CvtTd>{this.domainInfo.cname}</CvtTd>
                      </CvtTr>
                    </TableBody>
                  </CvtTable>
                </SecondStep>
                <ThirdStep class="step-progress" id="third-step">
                  <StepTitle class="--mb-24">
                    {this.$t(
                      'custom_domain.ui.domain_instruction.third_step.title',
                    )}
                  </StepTitle>
                  <DNSCheckLink
                    target="_blank"
                    href={this.whatsMyDNSCNAME(this.domainInfo.domain)}
                  >
                    <DNSCheckBtn
                      color="light"
                      mode={this.mode}
                      outlined={true}
                      prepend={false}
                      size="sm"
                      icon="share"
                      text={this.$t(
                        'custom_domain.ui.domain_instruction.third_step.dns_check_button.text',
                      )}
                    ></DNSCheckBtn>
                  </DNSCheckLink>
                  <DNSCheckAlert
                    alertTheme="light"
                    color="info"
                    icon="exclamation-circle"
                  >
                    <p>
                      {this.$t(
                        'custom_domain.ui.domain_instruction.third_step.dns_hints.p1',
                      )}
                    </p>
                    <p>
                      {this.$t(
                        'custom_domain.ui.domain_instruction.third_step.dns_hints.p2',
                      )}
                    </p>
                    <DNSStatusImg
                      src="https://cdn.convrrt.com/vendor/img/dns-check.png"
                      alt="dns check"
                    ></DNSStatusImg>
                  </DNSCheckAlert>
                </ThirdStep>
                <FourthStep class="step-progress" id="fourth-step">
                  <StepTitle>
                    {this.$t(
                      'custom_domain.ui.domain_instruction.fourth_step.title',
                    )}
                  </StepTitle>
                </FourthStep>
              </Progress>
            </Steps>
            <LinkContainer>
              <LinkBtn
                onClick={() => this.$emit('link')}
                text={this.linkBtnText}
                mode={this.mode}
                shadow={true}
                // TODO: These configs should not be in topNavConfig
                color={
                  this.topNavConfig.launch?.LinkCustomDomainBtnTheme !==
                  undefined
                    ? this.topNavConfig.launch?.LinkCustomDomainBtnTheme
                    : 'success'
                }
                outlined={
                  this.topNavConfig.launch?.LinkCustomDomainBtnOutlined !==
                  undefined
                    ? this.topNavConfig.launch?.LinkCustomDomainBtnOutlined
                    : false
                }
                fullWidth={true}
                loading={this.linkBtnLoading}
              ></LinkBtn>
            </LinkContainer>
          </DomainInstruction>
        )}

        {this.showDomainInstruction &&
          this.componentUsage === 'right-sidebar' && (
            <RsbDomainInstructionContainer mode={this.mode}>
              <RsbDomainInstructionTitle>
                {this.$t('custom_domain.ui.domain_instruction.title')}
              </RsbDomainInstructionTitle>
              <RsbSteps>
                <RsbStepNumberOne mode={this.mode} stepNumber={'1'}>
                  {' '}
                  1{' '}
                </RsbStepNumberOne>
                <RsbFirstStep
                  mode={this.mode}
                  class="step-progress"
                  id="first-step"
                >
                  <RsbStepTitle class="--mb-20">
                    {this.$t(
                      'custom_domain.ui.domain_instruction.first_step.title',
                    )}
                  </RsbStepTitle>
                  <RsbStepTitle>
                    {this.$t(
                      'custom_domain.ui.domain_instruction.first_step.example_providers',
                    )}
                  </RsbStepTitle>
                  <ul>
                    <li>
                      <a target="_blank" href="//www.cloudflare.com">
                        {this.$t(
                          'custom_domain.ui.domain_instruction.first_step.provider_list.li1',
                        )}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="//www.godaddy.com">
                        {this.$t(
                          'custom_domain.ui.domain_instruction.first_step.provider_list.li2',
                        )}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="//www.namecheap.com">
                        {this.$t(
                          'custom_domain.ui.domain_instruction.first_step.provider_list.li3',
                        )}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="//domains.google/">
                        {this.$t(
                          'custom_domain.ui.domain_instruction.first_step.provider_list.li4',
                        )}
                      </a>
                    </li>
                  </ul>
                </RsbFirstStep>

                <RsbStepNumberTwo mode={this.mode} stepNumber={'2'}>
                  {' '}
                  2{' '}
                </RsbStepNumberTwo>
                <RsbSecondStep
                  mode={this.mode}
                  class="step-progress"
                  id="second-step"
                >
                  <RsbStepTitle class="--mt-24 --mb-24 --ml-12">
                    {this.$t(
                      'custom_domain.ui.domain_instruction.second_step.title',
                    )}
                  </RsbStepTitle>

                  <RsbTable mode={this.mode}>
                    <RsbTableHead mode={this.mode}>
                      <RsbTableTr displayBorder={true}>
                        <RsbTableTh width={'70'}>
                          {this.$t(
                            'custom_domain.ui.domain_instruction.second_step.table_head.type',
                          )}
                        </RsbTableTh>
                        <RsbTableTh width={'70'}>
                          {this.$t(
                            'custom_domain.ui.domain_instruction.second_step.table_head.name',
                          )}
                        </RsbTableTh>
                        <RsbTableTh width={'160'}>
                          {this.$t(
                            'custom_domain.ui.domain_instruction.second_step.table_head.value',
                          )}
                        </RsbTableTh>
                      </RsbTableTr>
                    </RsbTableHead>
                    <RsbTableBody>
                      <RsbTableTr displayBorder={true}>
                        <RsbTableTd width={'70'}>TXT</RsbTableTd>
                        <RsbTableTd width={'70'}>@</RsbTableTd>
                        <RsbTableTd width={'160'}>
                          {this.domainInfo.verificationKey}
                        </RsbTableTd>
                      </RsbTableTr>
                      <RsbTableTr displayBorder={false}>
                        <RsbTableTd width={'70'}>CNAME</RsbTableTd>
                        <RsbTableTd width={'70'}>
                          {this.domainInfo.domain}
                        </RsbTableTd>
                        <RsbTableTd width={'160'}>
                          {this.domainInfo.cname}
                        </RsbTableTd>
                      </RsbTableTr>
                    </RsbTableBody>
                  </RsbTable>
                </RsbSecondStep>

                <RsbStepNumberThree mode={this.mode} stepNumber={'3'}>
                  {' '}
                  3{' '}
                </RsbStepNumberThree>
                <RsbThirdStep class="step-progress" id="third-step">
                  <RsbStepTitle class="--mb-24 --mt-24 --ml-12">
                    {this.$t(
                      'custom_domain.ui.domain_instruction.third_step.title',
                    )}
                  </RsbStepTitle>
                  <RsbDNSCheckLinkSecond
                    target="_blank"
                    href={this.whatsMyDNSCNAME(this.domainInfo.domain)}
                  >
                    <RsbDNSCheckBtnSecond
                      color="light"
                      mode={this.mode}
                      outlined={true}
                      prepend={false}
                      size="sm"
                      icon="share"
                      text={this.$t(
                        'custom_domain.ui.domain_instruction.third_step.dns_check_button.text',
                      )}
                    ></RsbDNSCheckBtnSecond>
                  </RsbDNSCheckLinkSecond>
                  <RsbDNSCheckAlert
                    alertTheme="light"
                    color="info"
                    icon="exclamation-circle"
                  >
                    <p>
                      {this.$t(
                        'custom_domain.ui.domain_instruction.third_step.dns_hints.p1',
                      )}
                    </p>
                    <p>
                      {this.$t(
                        'custom_domain.ui.domain_instruction.third_step.dns_hints.p2',
                      )}
                    </p>
                  </RsbDNSCheckAlert>
                </RsbThirdStep>

                <RsbStepNumberFour mode={this.mode} stepNumber={'4'}>
                  ✓
                </RsbStepNumberFour>

                <RsbFourthStep class="step-progress" id="fourth-step">
                  <RsbStepTitle class="--ml-12 --mb-12 --mt-16">
                    {this.$t(
                      'custom_domain.ui.domain_instruction.fourth_step.title',
                    )}
                  </RsbStepTitle>
                </RsbFourthStep>
              </RsbSteps>
              <RsbLinkContainer>
                <RsbLinkBtn
                  onClick={() => this.$emit('link')}
                  text={this.linkBtnText}
                  mode={this.mode}
                  shadow={true}
                  color={
                    this.topNavConfig.launch?.LinkCustomDomainBtnTheme !==
                    undefined
                      ? this.topNavConfig.launch?.LinkCustomDomainBtnTheme
                      : 'success'
                  }
                  outlined={
                    this.topNavConfig.launch?.LinkCustomDomainBtnOutlined !==
                    undefined
                      ? this.topNavConfig.launch?.LinkCustomDomainBtnOutlined
                      : false
                  }
                  fullWidth={true}
                  loading={this.linkBtnLoading}
                ></RsbLinkBtn>
              </RsbLinkContainer>
            </RsbDomainInstructionContainer>
          )}
      </CustomDomainAction>
    )
  },
}
