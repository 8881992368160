import * as _ from 'lodash'
import * as actions from '../../../../store/modules/actions'
import * as getters from '../../../../store/modules/getters'
import * as mutations from '../../../../store/modules/mutations'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import FontPicker from '../../../../components/common/FontPicker.vue'
import FontWeightSelector from '../../../../components/common/FontWeightSelector.vue';
import DefaultColorSelector from '../../../../components/common/DefaultColorSelector.vue';
import FontSizeSelector from '../../../../components/common/FontSizeSelector.vue'
import Colors from '@/js/components/editor/theme/Colors.vue'
import { DEFAULT_SDA_POPUP_SETTINGS } from '../../../../common/defaults/popup/settings'


export default {
  components: {
    Colors,
    FontPicker,
    FontSizeSelector,
    DefaultColorSelector,
    FontWeightSelector,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  data() {
    return {
      validatePopupName: {
        rules: {
          required: true,
          min: 3,
        },
      },
      removePreviousData: true // if this is true, we would remove the data when radio-btn is turned-off
    }
  },
  computed: {
    ...mapState('globalTheme', {
      sapAudienceSettingsConfig: ({ sdaPopupSettingsConfig }) => sdaPopupSettingsConfig.audience,
      sapRulesSettingsConfig: ({ sdaPopupSettingsConfig }) => sdaPopupSettingsConfig.rules,
    }),
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      fontStyles: getters.THEME_FONT_STYLES,
      buttonDefaultStyles: getters.THEME_BUTTON_DEFAULT_STYLES,
      linkDefaultStyles: getters.THEME_FROALA_LINK_DEFAULT_STYLES,
      settingsActiveTab: getters.SDA_POPUP_SETTINGS_ACTIVE_TAB,
      popup: getters.SDA_POPUP_SITE_DATA,
      availableFonts: getters.THEME_AVAILABLE_FONTS,
      typography: getters.THEME_TYPOGRAPHY,
      themeTypographyClass: getters.THEME_TYPOGRAPHY_CLASS,
      sdaPopupSettings: getters.SDA_POPUP_SETTINGS,
    }),
    nameInvalid() : string {
      return this.errors.has('name')
    },
    popupName: {
      get() {
        return this.popup.popupName
      },
      set: _.debounce(
        function ({ target }) {
          this.updatePopup({
            ...this.popup,
            popupName: target.value,
          })
          return target.value
        },
        700,
        { trailing: true },
      ),
    },
    // audience config
    showVisitorTypes(): boolean{
      return this.sapAudienceSettingsConfig.showVisitorTypes || false
    },
    showTrafficSources(): boolean{
      return this.sapAudienceSettingsConfig.showTrafficSources || false
    },
    showEventConditions(): boolean{
      return this.sapAudienceSettingsConfig.showEventConditions || false
    },
    showUrlConditions(): boolean{
      return this.sapAudienceSettingsConfig.showUrlConditions || false
    },
    // rules config
    showTriggerOptions(): boolean {
      return this.sapRulesSettingsConfig.showTriggerOptions || false
    },
    showFrequencyOption(): boolean {
      return this.sapRulesSettingsConfig.showFrequencyOption || false
    },
    showPlacementOptions(): boolean {
      return this.sapRulesSettingsConfig.showPlacementOptions || false
    },
    showOpeningTimeTrigger(): boolean {
      return this.sapRulesSettingsConfig.triggers.showOpeningTimeTrigger || false
    },
    showPageScrollTrigger(): boolean {
      return this.sapRulesSettingsConfig.triggers.showPageScrollTrigger || false
    },
    showExitIntentTrigger(): boolean {
      return this.sapRulesSettingsConfig.triggers.showExitIntentTrigger || false
    },
    showEventTrigger(): boolean {
      return this.sapRulesSettingsConfig.triggers.showEventTrigger || false
    },
    showCssSelectorTrigger(): boolean {
      return this.sapRulesSettingsConfig.triggers.showCssSelectorTrigger || false
    },
    // audience properties
    visitorTypes: {
      get() {
        return _.get(this.sdaPopupSettings, 'audience.visitorTypes.all', DEFAULT_SDA_POPUP_SETTINGS.audience.visitorTypes.all) ? 'all' : 'specific'
      },
      set(value) {
        this.sdaPopupSettingsUpdatePopup({ key: 'audience/visitorTypes/all', value: value })
      }
    },
    specificVisitorTypes: {
      get() {
        return _.get(this.sdaPopupSettings, 'audience.visitorTypes.specificVisitorTypes', DEFAULT_SDA_POPUP_SETTINGS.audience.visitorTypes.specificVisitorTypes)
      },
      set(value) {
        this.sdaPopupSettingsUpdatePopup({ key: 'audience/visitorTypes/specificVisitorTypes', value: value })
      }
    },
    trafficSources: {
      get() {
        return _.get(this.sdaPopupSettings, 'audience.trafficSources.all', DEFAULT_SDA_POPUP_SETTINGS.audience.trafficSources.all) ? 'all' : 'specific'
      },
      set(value) {
        this.sdaPopupSettingsUpdatePopup({ key: 'audience/trafficSources/all', value: value })
      }
    },
    specificTrafficSources: {
      get() {
        return _.get(this.sdaPopupSettings, 'audience.trafficSources.specificTrafficSources', DEFAULT_SDA_POPUP_SETTINGS.audience.trafficSources.specificTrafficSources)
      },
      set(value) {
        this.sdaPopupSettingsUpdatePopup({ key: 'audience/trafficSources/specificTrafficSources', value: value })
      }
    },
    eventConditions: {
      get() {
        return _.get(this.sdaPopupSettings, 'audience.eventConditions.enabled', DEFAULT_SDA_POPUP_SETTINGS.audience.eventConditions.enabled) ? 'on' : 'off'
      },
      set(value) {
        this.sdaPopupSettingsUpdatePopup({ key: 'audience/eventConditions/enabled', value: value })
      }
    },
    urlConditions: {
      get() {
        return _.get(this.sdaPopupSettings, 'audience.urlConditions.enabled', DEFAULT_SDA_POPUP_SETTINGS.audience.urlConditions.enabled) ? 'enabled' : 'disabled'
      },
      set(value) {
        this.sdaPopupSettingsUpdatePopup({ key: 'audience/urlConditions/enabled', value: value })
      }
    },
    // rules properties
    trigger: {
      get() {
        return _.get(this.sdaPopupSettings, 'rules.trigger', DEFAULT_SDA_POPUP_SETTINGS.rules.trigger)
      },
      set(value) {
        this.sdaPopupSettingsUpdatePopup({ key: 'rules/trigger', value: value })
      }
    },
    timeFrame: {
      get() {
        return _.get(this.sdaPopupSettings, 'rules.timeFrame', DEFAULT_SDA_POPUP_SETTINGS.rules.timeFrame)
      },
      set(value) {
        this.sdaPopupSettingsUpdatePopup({ key: 'rules/timeFrame', value: value })
      }
    },
    exitIntent: {
      get() {
        return _.get(this.sdaPopupSettings, 'rules.exitIntent', DEFAULT_SDA_POPUP_SETTINGS.rules.exitIntent)
      },
      set(value) {
        this.sdaPopupSettingsUpdatePopup({ key: 'rules/exitIntent', value: value })
      }
    },
    mouse: {
      get() {
        return _.get(this.sdaPopupSettings, 'rules.mouse', DEFAULT_SDA_POPUP_SETTINGS.rules.mouse)
      },
      set(value) {
        this.sdaPopupSettingsUpdatePopup({ key: 'rules/mouse', value: value })
      }
    },
    pageScroll: {
      get() {
        return _.get(this.sdaPopupSettings, 'rules.pageScroll', DEFAULT_SDA_POPUP_SETTINGS.rules.pageScroll)
      },
      set(value) {
        this.sdaPopupSettingsUpdatePopup({ key: 'rules/pageScroll', value: value })
      }
    },
  },
  methods: {
    ...mapMutations({
      setSettingsActiveTab: mutations.SDA_POPUP_TRIGGER_TAB,
      setSettingsMessage: mutations.SITE_SET_SETTINGS_MESSAGE,
    }),
    ...mapActions({
      openGraphSave: actions.OPEN_GRAPH_SAVE,
      setFont: actions.THEME_SET_FONT,
      setButtonDefaultStyles: actions.THEME_SET_BUTTON_DEFAULT_STYLES,
      setFroalaLinkDefaultStyles: actions.THEME_SET_FROALA_LINK_DEFAULT_STYLES,
      updateSite: actions.SITE_UPDATE,
      setBrandLogo: actions.THEME_SET_BRAND_LOGO,
      updatePopup: actions.POPUP_UPDATE,
      sdaPopupSettingsUpdatePopup: actions.SDA_POPUP_SETTINGS_UPDATE_PROP,
    }),
    ...mapActions('globalTheme', {
      setSdaPopupSettingsConfig: 'setSdaPopupSettingsConfig',
    }),
    updateFontFamily({ style, format }, font) {
      this.setFont({
        style,
        format: { ...format, font },
      })
    },
    updateFontWeight({ style, format }, weight) {
      this.setFont({
        style,
        format: { ...format, weight },
      })
    },
    updateFontSize({ style, format }, size) {
      this.setFont({
        style,
        format: { ...format, size },
      })
    },
    updateDefaultTextColor({ style, format }, defaultTextColor) {
      this.setFont({
        style,
        format: { ...format, defaultTextColor },
      })
    },
    updateButtonSubtextFontFamily({ item, format }, fontFamily) {
      this.setButtonDefaultStyles({
        item,
        format: { ...format, subtextStyle: {...format.subtextStyle, fontFamily } },
      })
    },
    updateButtonDefaultFontSize({ item, format }, fontSize) {
      this.setButtonDefaultStyles({
        item,
        format: { ...format, fontSize },
      });
    },
    updateButtonSubtextFontSize({ item, format }, fontSize) {
      this.setButtonDefaultStyles({
        item,
        format: { ...format, subtextStyle: {...format.subtextStyle, fontSize } },
      })
    },
    updateButtonDefaultFontWeight({ item, format }, fontWeight) {
      this.setButtonDefaultStyles({
        item,
        format: { ...format, fontWeight },
      })
    },
    updateButtonSubtextFontWeight({ item, format }, fontWeight) {
      this.setButtonDefaultStyles({
        item,
        format: { ...format, subtextStyle: {...format.subtextStyle, fontWeight} },
      })
    },
    updateButtonDefaultBackgroundColor({ item, format }, defaultBackgroundColor) {
      this.setButtonDefaultStyles({
        item,
        format: { ...format, defaultBackgroundColor },
      });
    },
    updateFroalaLinkDefaultTextColor({ item, format }, defaultTextColor) {
      this.setFroalaLinkDefaultStyles({
        item,
        format: { ...format, defaultTextColor },
      })
    },
    // audience properties
    visitorTypesChanged(value: string) {
      this.visitorTypes = (value === 'all')

      if(this.visitorTypes === 'all' && this.removePreviousData) {
        this.specificVisitorTypes = DEFAULT_SDA_POPUP_SETTINGS.audience.visitorTypes.specificVisitorTypes
      }
    },
    specificVisitorAdded(value) {
      this.specificVisitorTypes = value
    },
    trafficSourcesChanged(value) {
      this.trafficSources = (value === 'all')

      if(this.trafficSources === 'all' && this.removePreviousData) {
        this.specificTrafficSources = DEFAULT_SDA_POPUP_SETTINGS.audience.trafficSources.specificTrafficSources
      }
    },
    specificTrafficSourcesAdded(value) {
      this.specificTrafficSources = value
    },
    eventConditionsChanged(value) {
      this.eventConditions = (value === 'on')
    },
    urlConditionsChanged(value) {
      this.urlConditions = (value === 'enabled')
    },
    // rules properties
    triggerChanged(value) {
      this.trigger = value
    },
    timeFramePropsChanged(value) {
      this.timeFrame = value
    },
    exitIntentPropsChanged(value) {
      this.exitIntent = value
    },
    mousePropsChanged(value) {
      this.mouse = value
    },
    pageScrollPropsChanged(value) {
      this.pageScroll = value
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => setTimeout(vm.$store.dispatch('loader/stop', ''), 500))
  },
}
