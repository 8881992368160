
<div data-identity="EnableMarketplace">
  <h4 class="admin-label">
    {{ $t('launch.tab.control.heading.show_in_marketplace') }}
  </h4>
  <cvt-switch
    class="admin-switch"
    size="xs"
    color="active"
    type="circular"
    description-type="default"
    :initial-state="marketplaceEnabled"
    :mode="mode"
    @switchToggle="onSwitchToggle"
  />
</div>
