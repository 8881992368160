<script>
import GridContainerV3 from '../../../../components/v3/editor/nodes/GridContainer.vue'
import GridItemWrapper from "./GridItemWrapper.vue"

export default GridContainerV3.extend({
  name: "GridContainerV3Popup",
  components: {
    'grid-item-v3': GridItemWrapper
  },
  methods: {
    setPageId (vNode) {
      // const pageId = _.get(vNode, 'data.props.pageId', '')
      // TODO: Ask for Dhruv's opinion on popup submission action
      console.debug('Can\'t link automatically to the thank you page.')
      // _.set(vNode, 'data.props.pageId', this.thankYouPage.id)
    }
  }
})
</script>
