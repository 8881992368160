import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { styled } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import * as actions from '../../../../builder/src/js/store/modules/actions'
import { mapGetters, mapState, mapActions } from 'vuex'
import CvtButton from '../../molecules/Button'

Vue.use(VueI18n)

let textFontWeight, textFontSize, Spacing, FontSize, backgroundColor, Colors


const getIntegrationWrapper = () => styled('div')`
  display: flex;
  width: 100%;
`

const getBackArrowContainer = () => styled('div')`
  margin-right: 30px;
`

export const IntegrationContainer = {
  name: 'IntegrationContainer',
  data() {
    return {}
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      selectedIntegration: getters.SELECTED_INTEGRATION,
    }),
    ...mapGetters('globalTheme', {
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
    }),
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      globalTheme: ({ globalTheme }) => globalTheme,
    }),
  },
  created() {
    textFontSize = this.textFontSize
    textFontWeight = this.textFontWeight
    Spacing = this.Spacing
    FontSize = this.FontSize
    backgroundColor = this.backgroundColor
    Colors = this.Colors
  },
  mounted() {
    this.onScrollInToView()
  },
  methods: {
    ...mapActions({
      setSelectedIntegration: actions.SELECT_INTEGRATION,
    }),
    onCancel() {
      this.setSelectedIntegration(null).then()
    },
    onScrollInToView() {
      const container = document.getElementById('authWrap')
      container.scrollIntoView()
    },
  },
  render: function (h) {
    const IntegrationContainer = getIntegrationWrapper()
    const BackArrowContainer = getBackArrowContainer()

    return (
      <IntegrationContainer id="authWrap">
        <BackArrowContainer>
          <CvtButton
            outlined={true}
            fullWidth={true}
            shape="rounded"
            icon="arrow-left"
            color="light"
            mode="mode"
            text=""
            onClick={this.onCancel}
          />
        </BackArrowContainer>

        <div style="width: 100%;">
          {this.$slots.default}
        </div>
      </IntegrationContainer>
    )
  },
}
