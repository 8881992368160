export default function loadChameleon(user: {
  id: string
  email: string
  fullName: string
  orgID: string
}, key: string) {
  return new Promise((resolve, reject) => {
    /* chameleon.io script */

    !(function (d, w) {
      var t = key,
        c = 'chmln',
        i = d.createElement('script')
      if ((w[c] || (w[c] = {}), !w[c].root)) {
        ;(w[c].accountToken = t),
          (w[c].location = w.location.href.toString()),
          (w[c].now = new Date()),
          (w[c].fastUrl = 'https://fast.chameleon.io/')
        var m =
          'identify alias track clear set show on off custom help _data'.split(
            ' ',
          )
        for (var s = 0; s < m.length; s++) {
          !(function () {
            var t = (w[c][m[s] + '_a'] = [])
            w[c][m[s]] = function () {
              t.push(arguments)
            }
          })()
        }
        ;(i.src = w[c].fastUrl + 'messo/' + t + '/messo.min.js'),
          (i.async = !0),
          d.head.appendChild(i)
          i.onload = () => resolve(window['chmln'])
          i.onerror = (err) => reject(err)
      }
    })(document, window)

    /* chameleon.io user identification and data */

    // IMPORTANT: Replace USER.ID_IN_DB, USER.EMAIL, USER.FULL_NAME below with your app's actual variable names.

    chmln.identify(user.id, {
      email: user.email,
      fullName: user.fullName,
      orgID: user.orgID,
      project: user.id,
      company: {
        uid: user.orgID,
      }
    })
  })
}
