import Vue from '../../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import CvtButton from '../../molecules/Button'
import CvtInput from '../../molecules/Input'
import CvtSelect from '../../molecules/Select'
import { LazyImage } from '../../molecules/LazyImage'
import { ContentPlaceholder } from '../../molecules/ContentPlaceholder'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'

Vue.use(VueEmotion)

let Spacing, Radii, Border

let borderColor, backgroundColor, textFontSize, textLineHeight, elevation, color

const getExpandedGallery = () => styled('div')`
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-gap: ${Spacing.SPACING_24}px;
  padding: ${Spacing.SPACING_24}px ${Spacing.SPACING_8}px;
  border-left: ${Border.SIZE_1}px solid;

  ${(props) =>
    props.mode == 'DARK'
      ? borderColor({ themeing: 'dark' }, 500)
      : borderColor({ themeing: 'dark' }, 100)};
`

const getSearchImageWrapper = () => styled('div')`
  padding: 0 ${Spacing.SPACING_12}px;
`

const SearchImage = styled(CvtInput)``

const getGalleryFilterWrapper = () => styled('div')`
  padding: 0 ${Spacing.SPACING_12}px;
`

const getGalleryFilter = () => styled(CvtSelect)`
  ${color({ themeing: 'dark' }, 500)}
`

const getImagesWrapper = () => styled('div')`
  overflow: auto;
  padding: 0 ${Spacing.SPACING_12}px;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${Radii.RADIUS_PX_8}px;
    ${(props) =>
      props.mode == 'DARK'
        ? backgroundColor({ themeing: 'dark' }, 600)
        : backgroundColor({ themeing: 'dark' }, 100)}
  }
`

const getImage = () => styled('div')`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  width: 300px;
  margin-bottom: ${Spacing.SPACING_24}px;

  &:last-child {
    margin-bottom: 0;
  }
`

const getImageThumbnailWrapper = () => styled('div')`
  position: relative;
  overflow: hidden;
  border-radius: ${Radii.RADIUS_PX_8}px;
  ${(props) => elevation(props, 100)}

  &:hover {
    ${(props) => elevation(props, 400)}
    .overlay {
      display: flex;
    }
  }
`

const getImageOverlay = () => styled('div')`
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 1;
  ${backgroundColor({ themeing: 'dark' }, 100)}
  ${(props) =>
    props.mode == 'LIGHT'
      ? 'background: rgba(240, 242, 246, 0.5);'
      : 'background: rgb(73, 76, 83, 0.5);'}
  border-radius: ${Radii.RADIUS_PX_8}px;
`

const AddImageBtn = styled(CvtButton)``

const ImageThumbnail = styled(LazyImage)``

const getPlaceholderWrapper = () => styled('div')`
  position: relative;
  width: 300px;
  padding-top: calc((100% * 190 / 300) + 16px);
  margin-bottom: ${Spacing.SPACING_16}px;
`

const getPlaceholder = () => styled(ContentPlaceholder)`
  border-radius: ${Radii.RADIUS_PX_8}px;

  &.header-left {
    top: 0;
    left: 0;
    width: 70%;
    height: 16px;
    margin-bottom: ${Spacing.SPACING_8}px;
  }

  &.thumb-card {
    left: 0;
    width: 100%;
    height: calc(100% - 24px);
    top: 24px;
  }
`

export const CvtImageGallery = {
  name: 'CvtImageGallery',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textColor: getters.GLOBAL_STYLE_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      elevation: getters.GLOBAL_STYLE_ELEVATION
    }),
    ...mapState('globalTheme', {
      Spacing: ({globalTheme}) => globalTheme.Spacing,
      Radii: ({globalTheme}) => globalTheme.Radii,
      Border: ({globalTheme}) => globalTheme.Border
    }),
    currentImages() {
      return this.images
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
    loading: {
      type: Boolean,
      default: true
    },
    images: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    },
    activeCategory: {
      type: String,
      default: ''
    }
  },
  methods: {
    clearFilter(){
      if (this.$refs.galleryFilter) {
        this.$refs.galleryFilter.clear()
      }
    }
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    borderColor = this.borderColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    elevation = this.elevation
    color = this.textColor
  },
  render: function (h) {
    const ExpandedGallery = getExpandedGallery()
    const SearchImageWrapper = getSearchImageWrapper()
    const GalleryFilterWrapper = getGalleryFilterWrapper()
    const GalleryFilter = getGalleryFilter()
    const ImagesWrapper = getImagesWrapper()
    const Image = getImage()
    const ImageThumbnailWrapper = getImageThumbnailWrapper()
    const ImageOverlay = getImageOverlay()
    const PlaceholderWrapper = getPlaceholderWrapper()
    const Placeholder = getPlaceholder()
    const images = []
    const placeholders = Array(3).fill(
      <PlaceholderWrapper>
        <Placeholder class='header-left' mode={this.mode}></Placeholder>
        <Placeholder class='thumb-card' mode={this.mode}></Placeholder>
      </PlaceholderWrapper>
    )

    this.currentImages.forEach((image) => {
      images.push(
        <Image id={image.id}>
          <ImageThumbnailWrapper mode={this.mode}>
            <ImageOverlay mode={this.mode} class={'overlay'}>
              <AddImageBtn
                mode={this.mode}
                shadow={true}
                text='Use this image'
                onClick={() => this.$emit('insert-image', image)}
              ></AddImageBtn>
            </ImageOverlay>
            <ImageThumbnail
              mode={this.mode}
              dataSrc={image.gallery}
              alt={image.alt}
            ></ImageThumbnail>
          </ImageThumbnailWrapper>
        </Image>
      )
    })

    return (
      <ExpandedGallery mode={this.mode}>
        <SearchImageWrapper>
          <SearchImage
            placeholder="Search stock images"
            prefixIcon="search"
            onClear={() => this.$emit('search-change', "")}
            onInput={({target}) => this.$emit('search-input', target.value)}
            onChange={({target}) => this.$emit('search-change', target.value)}
          ></SearchImage>
        </SearchImageWrapper>
        <GalleryFilterWrapper>
          {this.categories.length > 0 && (
            <GalleryFilter
              label='Browse by category'
              floatingLabel={true}
              mode='dropdown'
              backgroundMode={this.mode}
              defaultValue={this.activeCategory}
              value={this.activeCategory}
              onInput={(value) => {
                this.$emit('filter-gallery', value)
              }}
              ref="galleryFilter"
            >
              {
                this.categories.map(option => {
                  return <option value={option}>{option}</option>
                })
              }
            </GalleryFilter>
          )}
        </GalleryFilterWrapper>
        <ImagesWrapper mode={this.mode}>
          {this.loading && placeholders} {!this.loading && images.length <= 0 ? <h6>No Results Found. <br/><strong>Please try a different search term.</strong></h6> : images}
        </ImagesWrapper>
      </ExpandedGallery>
    )
  }
}
