export default {
  data() {
    return {
      filestack: {
        upload: {
          progress: 0,
          status: null,
        },
      },
    }
  },
  mounted() {
    // this.$filestack = filestack.init('AQa7pkJxGRkmkl1MMIsPFz')
  },
  methods: {
    $filestackPick(options) {
      return this.$filestack.pick()
    },
    $filestackUpload(file) {
      this.$filestackInitProg()

      return this.$filestack.upload(file, {
        // onUploadStart: this.$filestackUploadStart,
        onProgress: this.$filestackUploadProgress,
      })
    },
    // $filestackUploadStart () {
    // },
    $filestackUploadProgress(e) {
      this.filestack.upload.progress = e.totalProgressPercent
    },
    $filestackUploadComplete() {
      this.filestack.upload.progress = 0
      this.filestack.upload.status = 'success'
    },
    $filestackInitProg() {
      this.filestack.upload = {
        progress: 1,
        status: null,
      }
    },
    $filestackReset() {
      this.filestack.upload = {
        status: null,
        progress: 0,
      }
    },
  },
}
