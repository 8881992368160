<template>
  <div class="element-image-manager">
    <div
      class="element-image-manager__preview checkered"
      @mouseover.stop.prevent="showOverlay"
      @mouseleave.stop.prevent="hideOverlay"
      @click.stop.prevent="showIconPicker"
    >
      <div
        v-show="overlayVisible"
        class="element-image-manager__preview__overlay"
      >
        <small
          ><i>{{ assetMgrBtnText }} Icon</i></small
        >
      </div>
      <icon-template
        :set="selected.set"
        :selector="selected.selector"
        class="icon-preview"
      />
    </div>

    <div v-if="OrgName !== orgNameFreshWork" class="divider mt-3" />
    <div v-else class="mt-3" />

    <div class="row">
      <div class="col">
        <cvt-button
          :full-width="fullWidth"
          shape="rounded"
          :size="btnSize"
          :color="btnColor"
          :text="replaceBtnText"
          :mode="mode"
          @click.stop.prevent="showIconPicker"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import {
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../../storybook/components/constants'
import * as getters from '../../../store/modules/getters'
import * as mutations from '../../../store/modules/mutations'
import IconTemplate from '../nodes/icon/IconTemplate.vue'

export default {
  components: {
    IconTemplate,
  },
  data() {
    return {
      overlayVisible: false,
      mode: 'LIGHT',
      orgNameFreshWork: LEFT_SIDEBAR_FRESHWORKS,
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
    }),

    ...mapState('globalTheme', {
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
    }),
    assetMgrBtnText() {
      return this.$t('element.property.image.replace')
    },
    btnSize() {
      return this.OrgName === LEFT_SIDEBAR_FRESHWORKS ? 'sm' : ''
    },
    btnColor() {
      return this.OrgName === LEFT_SIDEBAR_FRESHWORKS ? 'link' : 'success'
    },
    fullWidth() {
      return this.OrgName !== LEFT_SIDEBAR_FRESHWORKS
    },
    replaceBtnText() {
      return this.OrgName === LEFT_SIDEBAR_FRESHWORKS
        ? this.$t('element.property.image.replace') + ' icon'
        : this.$t('element.property.image.replace')
    },
  },
  methods: {
    ...mapMutations({
      showIconPicker: mutations.ICON_PICKER_SHOW,
    }),
    showOverlay() {
      this.overlayVisible = true
    },
    hideOverlay() {
      this.overlayVisible = false
    },
  },
}
</script>
<style lang="scss">
.icon-preview {
  font-size: 150px;
}
</style>
