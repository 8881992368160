import Vue from '../../lib/utils/vue'
import { VueEmotion, styled } from '@egoist/vue-emotion'
import { CvtIcon } from './icon/icon'
import * as getters from '../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'

Vue.use(VueEmotion)

let Spacing, Radii, Border, Colors, FontSize, FontFamily, LineHeight,
textFontWeight, borderColor, backgroundColor, textFontSize, textColor


const getContainer = () => styled('div')`
  width: ${(2*Spacing.SPACING_96) + Spacing.SPACING_64}px;
`

const getAlertBox = () => styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
  box-sizing: border-box;
  padding: 
    ${Spacing.SPACING_24}px
    ${Spacing.SPACING_18 + Spacing.SPACING_12}px
    ${Spacing.SPACING_18 + Spacing.SPACING_2}px
    ${Spacing.SPACING_18 + Spacing.SPACING_12}px;
  border: ${Border.SIZE_1}px solid transparent;
  border-radius: ${Radii.RADIUS_PX_8}px;
  ${(props) => {
    let styles = ``
    if (props.alertTheme === 'light') {
      styles += backgroundColor({ themeing: 'dark' }, 'WHITE')
    } else if (props.alertTheme === 'dark') {
      styles += backgroundColor({ themeing: 'dark' }, 700)
    } else {
      styles += backgroundColor(props, 100)
    }
    styles += borderColor(props, 300)
    return styles
  }}
`

const getIconWrapper = () => styled('div')`
  display: flex:
  justify-content: center;
  align-items: center;
  margin-bottom: ${Spacing.SPACING_24}px;
`

const getIcon = () => styled(CvtIcon)`
  font-size: ${FontSize.FONT_SIZE_34}px;
  ${(props) => textColor(props, 500)}
`

const getAlertMsgWrapper = () => styled('div')`
  `

const getAlertMsgHeading = () => styled('p')`
  text-align: center;
  ${textFontWeight('SEMI_BOLD')}
  font-family: ${FontFamily.DEFAULT};
  font-size: ${FontSize.FONT_SIZE_14}px;
  line-height: ${LineHeight.LINE_HEIGHT_24}px;
  margin: 0px 0px ${Spacing.SPACING_24}px 0px;
  ${(props) =>
    props.alertTheme === 'dark'
      ? textColor({ themeing: 'dark' }, 100)
      : textColor(props, 700)}
`

const getAlertMsgText = () => styled('p')`
  text-align: center;
  ${textFontWeight('REGULAR')}
  font-family: ${FontFamily.DEFAULT};
  font-size: ${FontSize.FONT_SIZE_10}px;
  line-height: ${LineHeight.LINE_HEIGHT_14}px;
  margin: 0;
  ${(props) =>
    props.alertTheme === 'dark'
      ? textColor({ themeing: 'dark' }, 100)
      : textColor(props, 700)}
`


export default {
  name: 'CvtAdvanceAlert',
  components: {
    CvtIcon
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      LineHeight: ({ globalTheme }) => globalTheme.LineHeight,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
    })
  },
  props: {
    alertTheme: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'check'
    }
  },
  data() {
    return { }
  },
  methods: { },
  created() {
    FontFamily = this.FontFamily
    LineHeight = this.LineHeight
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    Colors = this.Colors
    FontSize = this.FontSize

    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    textColor = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
  },
  render: function (h) {
    const Container = getContainer()
    const AlertBox = getAlertBox()
    const IconWrapper = getIconWrapper()
    const WarningIcon = getIcon()
    const AlertMsgWrapper = getAlertMsgWrapper()
    const AlertMsgHeading = getAlertMsgHeading()
    const AlertMsgText = getAlertMsgText()


    console.log(this);
    console.log(this.$slots);
    console.log(this.$scopedSlots);

    return (
      <Container>
        <AlertBox
          themeing={this.color}
          alertTheme={this.alertTheme}
        >
        <IconWrapper>
          <WarningIcon
            themeing={this.color}
            icon={this.icon}
            hover
          >
          </WarningIcon>
        </IconWrapper>

          <AlertMsgWrapper 
            themeing={this.color}
          >
            <AlertMsgHeading 
              themeing={this.color} 
              alertTheme={this.alertTheme}
            >
              {this.$scopedSlots && this.$scopedSlots.alertHeading && this.$scopedSlots.alertHeading()}
            </AlertMsgHeading>


            <AlertMsgText 
              themeing={this.color} 
              alertTheme={this.alertTheme}
            >
              {this.$scopedSlots && this.$scopedSlots.alertText && this.$scopedSlots.alertText()}
            </AlertMsgText>

          </AlertMsgWrapper>

        </AlertBox>
      </Container>
    )
  }
}
