import * as _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import * as actions from '../../../../store/modules/actions'
import * as getters from '../../../../store/modules/getters'
import FbVNode from '../../../base/FbVNode.vue'

const IMG_ALIGNMENT_OB = {
  'mx-auto': 'text-center',
  'ml-auto': 'text-right',
  'mr-auto': 'text-left',
}

const IMG_ALIGNMENT_GB = {
  'ml-auto': 'justify-content-end',
  'mx-auto': 'justify-content-center',
  'mr-auto': 'justify-content-start',
}

export default FbVNode.extend({
  props: {
    alt: String,
    width: Number,
    href: String,
    hideLogoOverflow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      name: 'BrandLogo',
    }
  },
  computed: {
    ...mapGetters({
      src: getters.THEME_BRAND_LOGO,
    }),
    mainWrapStyles() {
      if(this.isGridBuilder) {
        return {
          height: '100%',
          width: '100%',
        }
      }
      return {}
    },
    imgWrapperClassesOB() {
      return {
        [this.stylesheet.classes.spacing]: true,
        [IMG_ALIGNMENT_OB[this.bootstrapAlignment]]: true,
      }
    },
    imgWrapperClassesGB() {
      return {
        [this.stylesheet.classes.spacing]: true,
        [IMG_ALIGNMENT_GB[this.bootstrapAlignment]]: true,
      }
    },
    imgWrapperStyles() {
      return {
        height: '100%',
        width: '100%',

        minWidth: '50px',
        minHeight: '50px',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
    },
    // Styles for OldBuilder
    imgStylesOB() {
      return {
        width: this.width ? `${this.width}%` : null,
      }
    },
    // Styles for GridBuilder
    imgStylesGB() {
      return {
        backgroundImage: `url(${this.altSrc})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: (this.hideLogoOverflow ? 'cover': 'contain'),
        height: this.width ? `${this.width}%` : null,
        width: this.width ? `${this.width}%` : null,
      }
    },
    altSrc() {
      return this.src || '/img/logo-placeholder.svg'
    },
    styles() {
      return {
        spacing: {
          ...this.spacingStyle,
        },
      }
    },
  },
  methods: {
    ...mapActions({
      setBrandLogo: actions.THEME_SET_BRAND_LOGO,
    }),
    updateSrc(assets) {
      if (_.isArray(assets)) {
        this.setBrandLogo(assets[0])
        return
      }

      if (_.isString(assets)) {
        this.setBrandLogo(assets)
        return
      }

      if (assets && assets.image) {
        this.setBrandLogo(assets.image)
        return
      } else {
        this.setBrandLogo('')
        return
      }
    },
    triggerOpenAssetManager() {
      if (this.isGridBuilder) {
        return
      }
      this.openAssetManager(this.updateSrc)
    },
  },
})
