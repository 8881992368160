
<div class="dns-access-wrap">
  <div class="heading-n-desc-wrap">
    <div class="main-heading-wrap">
      <h3 class="main-heading">Set URLs for your environments</h3>
    </div>

    <div class="main-desc-wrap">
      <p class="main-desc">
        This is a one-time setup and should take no longer than 15 minutes of
        your time.
      </p>
    </div>
  </div>
  <form @submit.prevent="continueToConfigDns">
    <div v-for="(env, id) in envUrls" :key="id" class="label-n-input-wrap">
      <h3 class="label-heading">{{ env.environment }} Environment URL:</h3>
      <p class="small-desc">{{ env.label }}:</p>
      <input
        v-model="env.url"
        class="url-input"
        type="text"
        required
      />

      <div class="icon-n-text-wrap">
        <i class="fal fa-info-circle"></i>
        <span>{{ env.hint }}</span>
      </div>
    </div>

    <div class="dns-config-btn-wrap">
      <cvt-button
        class="dns-config-btn"
        :color="''"
        :mode="'primary'"
        :size="'md'"
        :full-width="true"
        :text="'Continue to DNS Configuration'"
        :special-padding="'20px 0px'"
        :loading="loading"
        submit
      >
      </cvt-button>

      <span class="dns-config-skip-btn" @click="onSkipHostNameUrlSetup">
        Skip this step
      </span>
    </div>
  </form>

</div>
