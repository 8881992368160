<template>
  <div v-loading="loading" class="img-async">
    <img class="img-fluid" :src="src" :alt="alt" @load="load" @error="error">
  </div>
</template>

<script>
  export default {
    props: {
      src: {
        type: String,
        default() {
          return ''
        }
      },
      alt: {
        type: String,
        default() {
          return ''
        }
      }
    },
    data () {
      return {
        loading: true
      }
    },
    methods: {
      load (e) {
        this.loading = false
        this.$emit('load', e)
      },

      error (e) {
        this.loading = false
        this.$emit('error', e)
      }
    }
  }
</script>

<style lang="scss">
  .img-async {
    width: 100%;
    height: auto;
  }
</style>
