import Vue from 'vue'
import { styled } from '@egoist/vue-emotion'
import { mapGetters, mapState } from 'vuex'
import { VueEmotion } from '@egoist/vue-emotion'

import * as getters from '../../../builder/src/js/store/modules/getters'

Vue.use(VueEmotion)

let Spacing,
  FontFamily,
  Colors,
  Radii,
  textFontWeight,
  backgroundColor,
  textFontSize,
  color,
  elevation,
  orgName,
  radioSwitchConfig,
  switchColorConfig

const getCustomCheckbox = () => styled('div')`
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 25px;
  height: 18px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    z-index: 1;
  }

  input:checked ~ span:after {
    display: block;
  }

  input:checked ~ span {
    background-color: ${Colors.PRIMARY[500]};
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 3px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid ${Colors.PRIMARY[500]};
    transition: background-color 0.3s ease-in-out;
  }

  span:after {
    content: '';
    position: absolute;
    display: none;
  }

  span:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`

const getLabel = () => styled('div')`
  font-size: ${(props) => (props.fontSize.trim() ? props.fontSize : '16px')};
`

export default {
  name: 'CvtCustomCheckbox',
  components: {},
  props: {
    label: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'LIGHT',
    },
    fontSize: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textColor: getters.GLOBAL_STYLE_COLOR,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Colors: ({ globalTheme }) => globalTheme.Colors,
    }),
  },
  methods: {},
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    FontFamily = this.FontFamily
    Colors = this.Colors
  },
  render: function (h) {
    const CustomCheckbox = getCustomCheckbox()
    const Label = getLabel()

    return (
      <CustomCheckbox>
        <input
          type={'checkbox'}
          checked={this.checked}
          onChange={(event) => this.$emit('change', event)}
        />
        <span className="checkmark"></span>
        <Label fontSize={this.fontSize}>{this.label}</Label>
      </CustomCheckbox>
    )
  },
}
