<template>
  <span
    v-show="visible"
    class="vnode__btn"
    :style="styles"
    @click.prevent.stop="click"
  >
    <el-tooltip :content="label" placement="top">
      <icon :size="size" :icon="icon" fw></icon>
    </el-tooltip>
  </span>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    visible: Boolean,
    icon: {
      type: String,
      default: ''
    },
    top: Boolean,
    left: Boolean,
    bottom: Boolean,
    right: Boolean,
    size: {
      type: String,
      default: ''
    },
  },
  computed: {
    noPos() {
      return !this.top && !this.left && !this.bottom && !this.right
    },
    styles() {
      return {
        position: this.noPos ? 'relative' : 'absolute',
        top: this.top ? 0 : null,
        left: this.left ? 0 : null,
        bottom: this.bottom ? 0 : null,
        right: this.right ? 0 : null,
      }
    },
  },
  methods: {
    click() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

.vnode__btn {
  position: absolute;
  color: $white;
  text-transform: uppercase;

  & i {
    // margin: .5em;
  }
}
</style>
