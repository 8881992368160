
<cvt-collapse :accordion="true" :theme="mode === 'LIGHT' ? 'light' : 'dark'" :prepend-chevron="prependChevron">
  <cvt-collapse-item title="Settings" name="settings" :initial-active="true">

    <div class="row mt-2">
      <div class="col">
        <h6>Show Product Image</h6>
      </div>
      <div class="col-3">
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="showImage"
          :mode="mode"
          @switchToggle="() => onSwitchToggle('showImage')"
        ></cvt-switch>
      </div>
    </div>

    <div class="divider"></div>

    <div class="row mt-2">
      <div class="col">
        <h6>Show Product Name</h6>
      </div>
      <div class="col-3">
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="showName"
          :mode="mode"
          @switchToggle="() => onSwitchToggle('showName')"
        ></cvt-switch>
      </div>
    </div>

    <div class="divider"></div>

    <div class="row mt-2">
      <div class="col">
        <h6>Show Product Price</h6>
      </div>
      <div class="col-3">
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="showPrice"
          :mode="mode"
          @switchToggle="() => onSwitchToggle('showPrice')"
        ></cvt-switch>
      </div>
    </div>

    <div class="divider"></div>

    <div class="row mt-2">
      <div class="col">
        <h6>Show Product Description</h6>
      </div>
      <div class="col-3">
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="showDesc"
          :mode="mode"
          @switchToggle="() => onSwitchToggle('showDesc')"
        ></cvt-switch>
      </div>
    </div>

    <div class="divider"></div>

    <product></product>
  </cvt-collapse-item>
  <cvt-collapse-item :title="$t('elements.properties.spacing.label')" name="spacing">
    <spacing-ctrl></spacing-ctrl>
  </cvt-collapse-item>
</cvt-collapse>
