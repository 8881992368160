import * as getters from './getters'
import defaultGlobalTheme from '../../global-theme/default.json'
import defaultTopNavConfig from '../../global-theme/topNavConfig.json'
import defaultSdaPopupSettingsConfig from '../../global-theme/sdaPopupSettingsConfig.json'
import defaultLeftNavConfig from '../../global-theme/leftNavConfig.json'
import defaultMarketplaceConfig from '../../global-theme/marketplaceConfig.json'
import defaultIconConfig from '../../global-theme/defaultIconConfig.json'
import defaultButtonConfig from '../../global-theme/buttonConfig.json'
import defaultCheckBoxConfig from '../../global-theme/checkBoxConfig.json'
import elementSize from '../../global-theme/defaultElementSize.json'
import builderFormIntegrations from '../../global-theme/builderFormIntegrations.json'
import Util from '../../global-theme/util'

export default {
  namespaced: true,
  state: {
    globalTheme: defaultGlobalTheme,
    topNavConfig: defaultTopNavConfig,
    sdaPopupSettingsConfig: defaultSdaPopupSettingsConfig,
    leftNavConfig: defaultLeftNavConfig,
    marketplaceConfig: defaultMarketplaceConfig,
    iconConfig: defaultIconConfig,
    globalButtonConfig: defaultButtonConfig,
    globalCheckBoxConfig: defaultCheckBoxConfig,
    elementSize: elementSize,
    activeCategory: null,
    builderIntegrationsConfig: builderFormIntegrations,
  },
  getters: {
    [getters.GLOBAL_STYLE_COLOR]: ({ globalTheme }) => (props, weight) => {
      return Util.color.call(globalTheme, props, weight)
    },
    [getters.GLOBAL_STYLE_BACKGROUND_COLOR]: ({ globalTheme }) => (props, weight) => {
      return Util.backgroundColor.call(globalTheme, props, weight)
    },
    [getters.GLOBAL_STYLE_BORDER_COLOR]: ({ globalTheme }) => (props, weight) => {
      return Util.borderColor.call(globalTheme, props, weight)
    },
    [getters.GLOBAL_STYLE_FONT_WEIGHT]: ({ globalTheme }) => (weight) => {
      return Util.textFontWeight.call(globalTheme, weight)
    },
    [getters.GLOBAL_STYLE_LINE_HEIGHT]: ({ globalTheme }) => (sz) => {
      return Util.textLineHeight.call(globalTheme, sz)
    },
    [getters.GLOBAL_STYLE_FONT_SIZE]: ({ globalTheme }) => (sz) => {
      return Util.textFontSize.call(globalTheme, sz)
    },
    [getters.GLOBAL_STYLE_ELEVATION]: ({ globalTheme }) => (props, weight) => {
      return Util.elevation.call(globalTheme, props, weight)
    }
  },
  mutations: {
    setTheme (state, globalThemeConfig) {
      state.globalTheme = Util.stateConfigMerge(defaultGlobalTheme, globalThemeConfig)
      sessionStorage.setItem('SVG_ICON', state.globalTheme.loaderSVGPath)
    },
    setTopNavConfig (state, topNavConfig) {
      state.topNavConfig = Util.stateConfigMerge(defaultTopNavConfig, topNavConfig)
    },
    setSdaPopupSettingsConfig (state, sdaPopupSettingsConfig) {
      state.sdaPopupSettingsConfig = Util.stateConfigMerge(defaultSdaPopupSettingsConfig, sdaPopupSettingsConfig)
    },
    setLeftNavConfig (state, leftNavConfig) {
      state.leftNavConfig = Util.stateConfigMerge(defaultLeftNavConfig, leftNavConfig)
    },
    setMarketplaceConfig (state, marketplaceConfig) {
      state.marketplaceConfig = Util.stateConfigMerge(defaultMarketplaceConfig, marketplaceConfig)
    },
    setIconConfig (state, iconConfig) {
      state.iconConfig = Util.stateConfigMerge(defaultIconConfig, iconConfig)
    },
    setGlobalButtonConfig (state, spannerGlobalButtonConfig) {
      state.globalButtonConfig = Util.stateConfigMerge(defaultButtonConfig, spannerGlobalButtonConfig)
    },
    setGlobalCheckBoxConfig (state, spannerGlobalCheckBoxConfig) {
      state.globalCheckBoxConfig = Util.stateConfigMerge(defaultCheckBoxConfig, spannerGlobalCheckBoxConfig)
    },
    setBuilderIntegrationsConfig (state, builderIntegrationsConfig) {
      state.builderIntegrationsConfig =  Util.stateConfigMerge(builderFormIntegrations, builderIntegrationsConfig)
    },
    setElementSize (state, elementSize) {
      state.elementSize = elementSize
    },
    setActiveCategory (state, activeCategory) {
      state.activeCategory = activeCategory
    }
  },
  actions: {
    setTheme ({ state, commit }, globalThemeConfig) {
      commit('setTheme', globalThemeConfig)
    },
    setTopNavConfig ({ state, commit }, topNavConfig) {
      commit('setTopNavConfig', topNavConfig)
    },
    setSdaPopupSettingsConfig ({ state, commit }, sdaPopupSettingsConfig) {
      commit('setSdaPopupSettingsConfig', sdaPopupSettingsConfig)
    },
    setLeftNavConfig ({ state, commit }, leftNavConfig) {
        commit('setLeftNavConfig', leftNavConfig)
    },
    setMarketplaceConfig ({ state, commit }, marketplaceConfig) {
        commit('setMarketplaceConfig', marketplaceConfig)
    },
    setIconConfig ({ state, commit }, iconConfig) {
      commit('setIconConfig', iconConfig)
    },
    setGlobalButtonConfig ({ state, commit }, spannerGlobalButtonConfig) {
      commit('setGlobalButtonConfig', spannerGlobalButtonConfig)
    },
    setGlobalCheckBoxConfig ({ state, commit }, spannerGlobalCheckBoxConfig) {
      commit('setGlobalCheckBoxConfig', spannerGlobalCheckBoxConfig)
    },
    setBuilderIntegrationsConfig ({ state, commit }, spannerBuilderIntegrationsConfig) {
      commit('setBuilderIntegrationsConfig', spannerBuilderIntegrationsConfig)
    },
    setElementSize ({ state, commit }, elementSize) {
      commit('setElementSize', elementSize)
    },
    setActiveCategory ({ commit }, activeCategory) {
      commit('setActiveCategory', activeCategory)
    }
  }
}
