import {CreateWidgetFromFirebaseNode} from "../components/v3/lib/vue-dom-obj";
import {PageEngineCommands} from "./PageEngineCommands";
import {ConvrrtFirebase} from "../convrrt-firebase";
import {CommandEventArgs, PopupProps, StandalonePageEngineComponent} from "../../../types";
import {Command} from "./Command";
import {PopupPropertyChangeCommand} from "./PopupPropertyChangeCommand";
import * as actions from "../store/modules/actions";
import {POPUP_PROPS_REFRESH} from "../store/modules/actions";


interface SavePopupBlock {
    vnode: any[]
    name: string
    tags: string[]
    popup?: PopupProps
    isGridBuilder: boolean
}


export class PopupInsertBlockCommand extends PageEngineCommands {
    private readonly _changes: Command[];
    private readonly _newNodeBlocks: object[];
    private readonly _oldNodeBlocks: object[];
    private readonly _vDomFirebaseRef: ConvrrtFirebase;

    constructor(blockData: SavePopupBlock, rootPathKey: string, vNodeId: string = "page-engine-wrapper") {
        super(vNodeId);
        this._newNodeBlocks = [blockData.vnode]
        this._oldNodeBlocks = []
        if (this.clonedVDom) {
            this._oldNodeBlocks = this.clonedVDom.map(x => CreateWidgetFromFirebaseNode(x.node))
        }
        this._changes = []
        const _previousPopupProps = {
            popupWidth: this.cPopupData?.data.props.popupWidth!,
            popupHeight: this.cPopupData?.data.props.popupHeight!,
            isFullScreen: this.cPopupData?.data.props.isFullScreen!,
            closeButtonPosition: this.cPopupData?.data.props.closeButtonPosition!,
        }
        const _newPopupProps = {
            popupWidth: blockData?.popup?.popupWidth || _previousPopupProps.popupWidth,
            popupHeight: blockData?.popup?.popupHeight || _previousPopupProps.popupHeight,
            isFullScreen: blockData?.popup?.isFullScreen === undefined ? _previousPopupProps.isFullScreen:  blockData?.popup?.isFullScreen,
            closeButtonPosition: blockData?.popup?.closeButtonPosition || _previousPopupProps.closeButtonPosition,
        }
        for (const [key, value] of Object.entries(_newPopupProps)) {
            const command = new PopupPropertyChangeCommand(key, value, vNodeId)
            this.addCommand(command)
        }
        this._vDomFirebaseRef = new ConvrrtFirebase(rootPathKey)
    }

    get cPopupData (): { data: { props: PopupProps } } | undefined {
        return (this.getVueComponent() as StandalonePageEngineComponent)?.cPopupData
    }

    get$emitData(): CommandEventArgs {
        return {name: 'PopupInsertBlock', forceBuild: true}
    }

    get hasChanges () {
        return true
    }

    async execute() {
        for (const command of this._changes) {
            await command.execute()
        }
        await this._vDomFirebaseRef.set(this._newNodeBlocks)
    }

    async unExecute() {
        for (const command of this._changes) {
            await command.unExecute()
        }
        await this._vDomFirebaseRef.set(this._oldNodeBlocks)
    }

    addCommand(command: Command): void {
        if (command.hasChanges) {
            this._changes.push(command)
        }
    }

    updateVDom(action: object) {
        this.getVueComponent()?.$store.dispatch(actions.POPUP_PROPS_REFRESH).then(() => {
            super.updateVDom(action);
        })
    }
}
