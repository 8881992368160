
<div class="add-element-popover">
  <cvt-button
    :id="btnId"
    color="light"
    icon="cvt-icon-plus"
    :class="btnClasses"
    size="xl"
    @click="() => rotatePlusClose = !rotatePlusClose"
  />
<cvt-popover placement="top" :target="btnId" triggers="click blur" color="light" mode="DARK" :border-radius="10" :inner-padding="10">
    <template #popper>
      <div class="d-flex">
        <cvt-button
          v-for="item of filteredElements"
          :id="btnId+'-'+item.name"
          :key="'btn-' + item.name"
          size="md"
          mode-of-action-icon="dark"
          color-of-action-icon="dark"
          :action-icon="true"
          :icon="elementIconConfig[item.name] || item.icon"
          color="light"
          @click="() => $emit('add-item', item.id)"
          @clickOut="clikedOutSide(item.name)"
        />
        <cvt-tooltip 
          v-for="item of filteredElements"
          :key="'tooltip-' + item.name"
          :target="btnId+'-'+item.name"
          size="xs"
        >
          <strong>{{ item.label }}</strong>
        </cvt-tooltip>
      </div>
    </template>
  </cvt-popover>

</div>
