<template>
  <div class="form-group">
    <cvt-select
      :value="fontSize"
      mode="dropdown"
      :label="$t('setup.font.size')"
      @input="(value) => updateFontSize(value)"
    >
      <option v-for="size in fontSizes" :key="size.value" :value="size.value" v-text="size.label" />
    </cvt-select>
  </div>
</template>

<script>
  import { FONT_SIZES,SUB_FONT_SIZES } from '../../lib/font'

  export default {
    props: {
      value: {
        type: String,
        default: ''
      },
     sub: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        fontSizes: this.sub ? SUB_FONT_SIZES : FONT_SIZES,
        fontSize: this.value
      }
    },
    methods: {
      updateFontSize (value) {
        this.fontSize = value
        this.$emit('input', value)
      }
    }
  }
</script>
