<template>
  <div
    class="bootstrap-alignment"
    :class="
      isGridBuilder && !disableAlignment && !disableConstrain
        ? 'add-padding'
        : ''
    "
  >
    <div class="row">
      <div v-if="!disableAlignment" class="col align-items-center"></div>
      <!-- Old Builder -->
      <div class="col-12 ml-auto">
        <cvt-radio-group
          v-if="!isGridBuilder"
          orientation="horizontal"
          :initial-value="alignment"
          :remove-active="removeActive"
        >
          <cvt-radio-button
            size="md"
            value="mr-auto"
            :shape="btnShape"
            @radioClick="updateAlignment"
          >
            <cvt-icon icon="cvt-icon-align-left" />
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="mx-auto"
            :shape="btnShape"
            @radioClick="updateAlignment"
          >
            <cvt-icon icon="cvt-icon-align-horizontal-center" />
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="ml-auto"
            :shape="btnShape"
            @radioClick="updateAlignment"
          >
            <cvt-icon icon="cvt-icon-align-right" />
          </cvt-radio-button>
        </cvt-radio-group>
      </div>
    </div>

    <!-- Grid Builder -->
    <div class="row d-flex justify-content-between">
      <div v-if="isGridBuilder && !disableAlignment" class="col">
        <cvt-radio-group
          orientation="horizontal"
          :initial-value="flexVerticalAlignment"
          :remove-active="removeActive"
        >
          <el-tooltip
            :content="$t('elements.properties.alignments.verticalTop')"
            placement="top"
          >
            <cvt-radio-button
              size="sm"
              value="constrainTop"
              @radioClick="updateFlexAlignment"
            >
              <cvt-icon icon="cvt-icon-align-top"></cvt-icon>
            </cvt-radio-button>
          </el-tooltip>
          <el-tooltip
            :content="$t('elements.properties.alignments.verticalCenter')"
            placement="top"
          >
            <cvt-radio-button
              size="sm"
              value="constrainCenter"
              @radioClick="updateFlexAlignment"
            >
              <cvt-icon icon="cvt-icon-align-vertical-center"></cvt-icon>
            </cvt-radio-button>
          </el-tooltip>
          <el-tooltip
            :content="$t('elements.properties.alignments.verticalBottom')"
            placement="top"
          >
            <cvt-radio-button
              size="sm"
              value="constrainBottom"
              @radioClick="updateFlexAlignment"
            >
              <cvt-icon icon="cvt-icon-align-bottom"></cvt-icon>
            </cvt-radio-button>
          </el-tooltip>
        </cvt-radio-group>
      </div>
      <div v-if="isGridBuilder && !disableConstrain" class="col">
        <cvt-radio-group
          orientation="horizontal"
          :initial-value="flexHorizonAlignment"
          :remove-active="removeActive"
        >
          <el-tooltip
            :content="$t('elements.properties.alignments.horizontalLeft')"
            placement="top"
          >
            <cvt-radio-button
              size="sm"
              value="alignLeft"
              @radioClick="updateFlexConstrain"
            >
              <cvt-icon icon="cvt-icon-align-left"></cvt-icon>
            </cvt-radio-button>
          </el-tooltip>
          <el-tooltip
            :content="$t('elements.properties.alignments.horizontalCenter')"
            placement="top"
          >
            <cvt-radio-button
              size="sm"
              value="alignCenter"
              @radioClick="updateFlexConstrain"
            >
              <cvt-icon icon="cvt-icon-align-horizontal-center"></cvt-icon>
            </cvt-radio-button>
          </el-tooltip>
          <el-tooltip
            :content="$t('elements.properties.alignments.horizontalRight')"
            placement="top"
          >
            <cvt-radio-button
              size="sm"
              value="alignRight"
              @radioClick="updateFlexConstrain"
            >
              <cvt-icon icon="cvt-icon-align-right"></cvt-icon>
            </cvt-radio-button>
          </el-tooltip>
        </cvt-radio-group>
      </div>
    </div>

    <div class="row">
      <div v-if="OrgName !== orgNameFreshWork" class="col">
        <alignment-button
          :outlined="true"
          :full-width="true"
          shape="rounded"
          color="light"
          :text="$t(`elements.properties.remove_alignment`)"
          :mode="mode"
          :custom-alignment-style="customAlignmentStyle"
          @click="clearAlignment"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  flexAlignmentClassMap,
  flexAlignmentClassMapReverse,
} from '../../../lib/flexAlignment'
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import { mapGetters, mapActions, mapState } from 'vuex'
import {
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../../storybook/components/constants'
import { styled } from '@egoist/vue-emotion'
import CvtButton from '../../../../../../storybook/components/molecules/Button'

const getAlignmentButton = () => styled(CvtButton)`
  ${(props) => {
    let styles = ``
    if (props['custom-alignment-style']) {
      styles += `
        border: 1px solid #CCCCD1 !important; 
        color: #111111 !important; 
        display:flex !important;
        align-items:center !important;

        &:hover {
          border: 1px solid #111111 !important; 
          background: #fff !important;
        } 
        :disabled {
          background: #E9E9EC !important;
          border: 1px solid #CCCCD1 !important;
          color: #8C909C;
        }`
    }
    return styles
  }}
`

const AlignmentButton = getAlignmentButton()

export default {
  components: {
    AlignmentButton,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    disableAlignment: {
      type: Boolean,
      default: false,
    },
    disableConstrain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      removeActive: false,
      orgNameFreshWork: LEFT_SIDEBAR_FRESHWORKS,
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      config: getters.AUTH_GET_USER_CONFIG,
      viewMode: getters.VIEW_MODE,
      isGridBuilder: getters.IS_GRID_BUILDER,
    }),
    ...mapState('globalTheme', {
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
      customAlignmentStyle: ({ globalTheme }) =>
        Boolean(globalTheme.customAlignmentStyle),
    }),
    textColorClass() {
      return { 'text-white': this.mode == 'DARK' }
    },
    btnShape() {
      return this.OrgName === LEFT_SIDEBAR_FRESHWORKS ? 'pill' : ''
    },
    alignment: {
      get() {
        return this.selected.bootstrapAlignment
      },
      set(value) {
        return value
      },
    },
    flexVerticalAlignment() {
      return flexAlignmentClassMapReverse[this.verticalAlignment]
    },
    flexHorizonAlignment() {
      return flexAlignmentClassMapReverse[this.horizontalAlignment]
    },
    verticalAlignment: {
      get() {
        return this.checkParent()
          ? this.selected.parent()[this.viewMode]['flexVerticalAlign']
          : undefined
      },
      set(value) {
        return value
      },
    },
    horizontalAlignment: {
      get() {
        return this.checkParent()
          ? this.selected.parent()[this.viewMode]['flexHorizonAlign']
          : undefined
      },
      set(value) {
        return value
      },
    },
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
      updateParentProp: actions.SELECTOR_UPDATE_PARENT_PROP,
    }),
    clear() {
      this.removeActive = true
      this.alignment = ''
      this.updateProp({ key: 'bootstrapAlignment', value: null })
    },
    clearAlignment() {
      this.removeActive = true
      if (this.isGridBuilder) {
        if (this.checkParent()) {
          this.verticalAlignment = ''
          this.updateParentProp({
            key: `${this.viewMode}/flexVerticalAlign`,
            value: null,
          })
          this.updateParentProp({
            key: `${this.viewMode}/flexHorizonAlign`,
            value: null,
          })
        }
      } else {
        this.clear()
      }
    },
    updateAlignment(value) {
      this.removeActive = false
      this.alignment = value
      this.updateProp({ key: 'bootstrapAlignment', value })
    },
    updateFlexAlignment(value) {
      this.removeActive = false
      if (this.checkParent()) {
        this.updateParentProp({
          key: `${this.viewMode}/flexVerticalAlign`,
          value: flexAlignmentClassMap[value],
        })
      }
    },
    updateFlexConstrain(value) {
      this.removeActive = false
      if (this.checkParent()) {
        this.updateParentProp({
          key: `${this.viewMode}/flexHorizonAlign`,
          value: flexAlignmentClassMap[value],
        })
      }
    },
    checkParent() {
      const parent = this.selected.parent()
      if (parent.name === 'GridItemWrapper') {
        return true
      }
      return false
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

.flex-alignment-grid {
  list-style: none;
  background: rgba(66, 66, 66, 0.2);
  overflow: hidden;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-bottom: 5px;
}

.flex-alignment-grid__ctrl {
  display: flex;
  justify-content: center;
  width: 33.333333333%;
  padding: 20px 0;
}

.flex-alignment-grid__ctrl__circle {
  display: inline-block;
  border: 2px solid #fff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.075);
  width: 17px;
  height: 17px;
  border-radius: 100%;
  background: rgba(227, 227, 227, 1);
  position: relative;
  text-indent: -1000px;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    border-radius: 1px;
    transform: scale(2.8);
  }
  &:after {
    content: '';
    background: $inf-green;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    border-radius: 100%;
    transition: opacity 0.35s;
  }

  &.flex-alignment-grid__ctrl__circle--active:after,
  &:hover:after {
    opacity: 1;
  }
}

.add-padding {
  padding: 16px;
}
</style>
