// triggerMockEvent
import { DefaultIntegrationAPI } from "./base";

export class ZapierIntegrationAPI extends DefaultIntegrationAPI{

  async triggerMockEvent (url: string, formId: string) {
    return await this.api.post(
      `/v1/integrations/zapier/trigger-mock-event`, { hookURL: url }
    )
  }

  async saveHookUrl (siteId: string, formId: string, url: string) {
    const res = await this.api.post(
      `/v1/sites/${siteId}/${formId}/hookURL?provider=zapier`, {hookURL: url}
    )
    return res.data
  }

  async getHookUrl (siteId: string, formId: string) {
    const res = await this.api.get(
      `/v1/sites/${siteId}/${formId}/hookURL?provider=zapier`,
    )
    return res.data
  }
}