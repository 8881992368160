<template>
  <div class="draggable-marker" :class="classes" @mousedown.stop></div>
</template>

<script>
  import * as _ from 'lodash'
  import Draggabilly from 'draggabilly'

  Draggabilly.prototype.setPosition = function({x, y}) {
    this.position.x = x
    this.position.y = y
    this.setLeftTop()
  }

  export default {
    props: {
      classes: {
        type: String,
        default() {
          return ''
        }
      },
      containment: Boolean,
      axis: {
        type: String,
        validate (val) {
          return ['x', 'y'].includes(val)
        },
        default() {
          return 'x'
        }
      }
    },
    beforeDestroy () {
      this.draggi.destroy()
    },
    mounted () {
      this.draggi = new Draggabilly(this.$el, {
        containment: true
      })

      this.draggi.on('dragStart', this.dragstart)
      this.draggi.on('dragMove', this.dragmove)
      this.draggi.on('dragEnd', this.dragend)
    },
    methods: {
      dragstart (event, pointer) {
        this.$emit('dragstart', {
          event,
          pointer,
          position: this.draggi.position
        })
      },
      dragmove (event, pointer, vector) {
        this.$emit('dragmove', {
          event,
          pointer,
          vector,
          position: this.draggi.position
        })
      },
      dragend (event, pointer) {
        this.$emit('dragend', {
          event,
          pointer,
          position: this.draggi.position
        })
      },
      updatePosition (position) {
        this.draggi.setPosition({
          ...this.draggi.position,
          ...position
        })
      },
      startDrag (e) {
        this.draggi._pointerDown(e, e)
      },
      parentSize () {
        return this.size(this.$el.parentElement)
      },
      size (el) {
        el = el || this.$el
        let { top, left, right, bottom } = el.getBoundingClientRect()
        return {
          width: right - left,
          height: bottom - right
        }
      }
    }
  }
</script>

<style lang="css">
</style>
