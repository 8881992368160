
<cvt-left-sidebar
  v-if="showSidebar"
  :type="OrgName"
  :panels="computedPanels"
  :active-panel="active"
  :side-bar-left="SideBarLeft"
  :is-template="isTemplate"
  :is-site-preview="isSitePreview"
  @settings="() => (siteSettings ? hideSettings() : showSettings())"
  @panel-toggle="(panel) => toggle(panel)"
>
  <template v-if="!isGridBuilder" #elements>
    <element-launcher :type="OrgName" />
  </template>
  <template #sections>
    <section-launcher :type="OrgName" />
  </template>
  <template #layouts>
    <layout :type="OrgName" />
  </template>
  <template v-if="(isTemplate || isSitePreview)" #pages>
    <preview-page-manager 
      :is-template="isTemplate" 
      :is-site-preview="isSitePreview"
    />
  </template>
  <template v-else #pages>
    <page-manager />
  </template>
  <template #ssd>
    <ssd-notes />
  </template>
  <template v-if="(isGridBuilder && isAdmin && genAiEnabled)" #genAi>
    <gen-AI-schema />
  </template>
  <template #imageGallery>
    <gallery />
  </template>
  <template #popups>
    <popup-launcher />
  </template>
</cvt-left-sidebar>
