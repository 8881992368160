import { mapGetters } from 'vuex'
import * as getters from '../../../../store/modules/getters'


export default {
  name: 'SectionControls',
  props: {
    vnodeId: {
      type: String,
      required: true,
      default: ''
    },
    headerText: {
      type: String,
      default: null
    },
    hideMoveButtons: {
      type: Boolean,
      default: false
    },
    allowedControls : {
      type: Array,
      default: () => ['clone', 'delete', 'swap']
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      viewMode: getters.VIEW_MODE,
      isGridBuilder: getters.IS_GRID_BUILDER,
      isStandalonePopupActive: getters.STANDALONE_POPUP_ACTIVE,
      config: getters.AUTH_GET_USER_CONFIG,
      popupBuilderOpen: getters.POPUP_BUILDER_OPEN,
    }),
    isDesktopView() {
      return this.viewMode === 'desktop'
    },
    isMobileView() {
      return this.viewMode === 'phone'
    },
    scSectionActionClasses() {
      return {
        'sc-section-action-btns-d': this.isDesktopView,
        'sc-section-action-btns-m': this.isMobileView,
        'sc-section-action-btns-pt-100': (!this.isGridBuilder && this.isMobileView),
        'sc-section-action-btns-pt-150': (this.isGridBuilder && this.isMobileView)
      }
    },
    scLeftContentClasses() {
      return {
        'sc-left-content-d': this.isDesktopView,
        'sc-left-content-m': this.isMobileView,
        'sc-left-content-pt-30': (this.isGridBuilder && this.isMobileView)
      }
    },
    isBuilderOnStaticMode () {
      return this.config.GRID_BUILDER_STATIC_MODE_ENABLED || false
    },
    disableUpAndDownButton () {
      return this.isStandalonePopupActive || this.popupBuilderOpen
    }
  },
  methods: {
    moveSectionUp(event) {
      this.$emit('moveSectionUp', event)
    },
    moveSectionDown(event) {
      this.$emit('moveSectionDown', event)
    },
    copySection(event) {
      this.$emit('copySection', event)
    },
    swapSection(event) {
      this.$emit('swapSection', event)
    },
    deleteSection(event) {
      this.$emit('deleteSection', event)
    },
    arrangeBlock(event: MouseEvent) {
      this.$emit('arrangeBlock', event)
    },
  },
}
