
<div>
  <cvt-top-nav-bar
    mode="LIGHT"
    variant="embedded"
    :class="'cvt-top-nav-bar'"
    :route="currentRouteName"
    :fixed="true"
    :template-name="siteName"
    :disable-exit="disableExit"
    :light-logo="logo"
    :dark-logo="logo"
    :revision-loading="saving || loading"
    :undo-disabled="disabled(editorCtrls[0].disabled)"
    :redo-disabled="disabled(editorCtrls[1].disabled)"
    :type="orgName"
    :disable-publish="disablePublish"
    @home="backStep"
    @backToCrm="(e) => goToCrmBuilder(e)"
    @exit-preview="goToCampaignBuilder"
    @back="backStep"
    @one-step-back="goOneStepBack"
    @switch-preview="switchPreview"
    @undo="execute(editorCtrls[0])"
    @redo="execute(editorCtrls[1])"
    @version-history="openRevisionManager"
    @version-history-save="openRevisionSaveManager"
    @help="help"
    @launch="(e) => launchStep(e)"
    @site-preview="goToSitePreview()"
    @use-template="
      {
      }
    "
    @done="
      {
      }
    "
    @top-settings="() => (siteSettings ? hideSettings() : showSettings())"
    @top-toggle="(panel) => open_menu(panel)"
    @exit="goToCampaignBuilder"
    @toggle-survey="toggleSurvey()"
    @toggle-popup="togglePopups()"
    @checkout-to-pages="checkoutToPages"
  />
</div>
