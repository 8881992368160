<script>
import LandingPageCategory from '../../../../../components/editor/steps/super/Categories.vue'
import {mapActions, mapGetters} from "vuex";
import * as getters from "@/js/store/modules/getters";
import * as actions from "@/js/store/modules/actions";

export default LandingPageCategory.extend({
  name: "StandalonePopUpCategories",
  computed: {
    ...mapGetters({
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN,
      sdaPopupSiteData: getters.SDA_POPUP_SITE_DATA
    })
  },
  mounted () {
    this.mapCategories()
  },
  methods: {
    ...mapActions({
      popupSiteUpdateCategories: actions.SDA_POPUP_SITE_UPDATE_CATEGORIES
    }),
    onCategoryChanged(newCategories, oldCategories) {
      this.popupSiteUpdateCategories({
        id: this.sdaPopupSiteData?.id,
        categories: newCategories.map(id => ({ id }))
      })
    },
    mapCategories () {
      const popupSiteCategories = this.sdaPopupSiteData?.categories || []
      this.categories = popupSiteCategories.map(c => c.id)
    }
  }
})
</script>

<style lang="scss" scoped></style>