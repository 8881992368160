<template>
  <div>
    <cvt-top-nav-bar
      style="position: fixed; width: 100%; z-index: 3"
      :mode="mode"
      variant="admin"
      :org-id="admin.orgID"
      :project-id="admin.projectID"
      route="marketplace"
      :fixed="true"
      :input-data="inputOrgData"
      :input-project-data="projects"
      @inputOrgName="onOrgChange"
      @createNewOrg="createNewOrg"
      @inputProjectID="onProjectChange"
      @change="searchProjects"
    >
    </cvt-top-nav-bar>

    <div>
      <div>
        <cvt-admin-left-nav-bar
          style="top: 64px"
          usage="adminDashboard"
          initial-active-id="templates"
          :mode="mode"
        >
        </cvt-admin-left-nav-bar>
      </div>
      <div style="position: absolute; left: 280px; top: 64px">
        <div class="search-filter-style">
          <cvt-search-filter-admin
            class="search-filter-bar"
            :mode="mode"
            @sortBymarketplace="onSortBymarketplace"
            @searchBySiteID="onSearchBySiteID"
            @goToMarketPlace="
              () => {
                $router.push('/')
              }
            "
          ></cvt-search-filter-admin>
        </div>
        <div
          class="cards-div"
          :style="filteredSites.length !== 0 ? 'padding: 20px;' : ''"
        >
          <cvt-marketplace-card
            v-for="(item, idx) in filteredSites"
            :id="item.id"
            :key="item.message"
            v-intersected-once="{
              onIntersected: loadMoreSites,
              shouldObserve: filteredSites.length - 1 === idx
            }"
            :mode="mode"
            :thumbnail="item.thumbnail"
            thumbnail-alt="Lead Gen Thumbnail"
            usage="adminDashboard"
            :item="item"
            @action="onAction"
          >
            <template #cardTitle>{{ item.name }}</template>
            <template #id>{{ item.id }}</template>
          </cvt-marketplace-card>
        </div>
      </div>
    </div>
    <cvt-dialog
      :show="newOrgDialogFlag"
      :height-auto="true"
      :show-close="true"
      :show-footer="false"
      :bg-color="'white'"
      @close="handleClose"
    >
      <template v-if="showDialogHeader" #title>
        <h1>Create New Organization</h1>
      </template>
      <template #default>
        <cvt-new-org-modal
          :show="newOrgDialogFlag"
          :org-created="orgCreated"
          :shared-url="sharedURL"
          @api-call="apiCall"
          @close="handleClose"
        />
      </template>
    </cvt-dialog>
  </div>
</template>

<script>
import * as _ from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import * as actions from '../../store/modules/actions'
import * as getters from '../../store/modules/getters'
import { Organization } from '../../store/services/api'

const organizationApi = new Organization()

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Templates',
  data() {
    return {
      filter: '',
      onlyMarketplace: false,
      apps: [],
      pagination: { limit: 5, offset: 0 },
      mode: 'LIGHT',
      inputOrgData: [],
      inputProjectData: [],
      newOrgDialogFlag: false,
      showDialogHeader: true,
      orgCreated: false,
      sharedURL: ''
    }
  },
  computed: {
    ...mapGetters({
      sites: getters.SITES_GET_LIST,
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN,
      admin: getters.AUTH_GET_USER
    }),

    ...mapState('super', [
      'searching',
      'projects',
      'organizations',
      'currentOrgID',
      'currentProjectID'
    ]),
    filteredSites() {
      return _.chain(this.sites)
        .filter((s) => {
          return this.filter
            ? _.includes(
                `${s.name.toLowerCase()} - ${s.id.toLowerCase()}`,
                this.filter.toLowerCase()
              )
            : true
        })
        .filter((s) => {
          return this.onlyMarketplace ? s.marketplace === true : true
        })
        .value()
    }
  },
    watch: {
    organizations(orgs) {
      this.inputOrgData = orgs.map((org) => {
        return {
          value: org,
          label: org
        }
      })
    }
  },
  created() {
    this.searchOrganizations()
    this.loadMoreSites()
  },

  methods: {
    ...mapActions({
      loadSites: actions.SITES_FETCH_DATA,
      publish: actions.SITE_PUBLISH
    }),
    ...mapActions('super', [
      'onProjectChange',
      'onOrgChange',
      'searchOrganizations',
      'searchProjects',
      'sudo'
    ]),
    editLink(site) {
      return {
        name: 'editor:setup',
        params: {
          siteId: site.id
        },
        query: {
          redirectUrl: '/admin/dashboard'
        }
      }
    },
    settingsLink(site) {
      return {
        name: 'editor:launch',
        params: {
          siteId: site.id
        },
        query: {
          redirectUrl: '/admin/dashboard'
        }
      }
    },
    btnDisabled(site) {
      return site.locked ? 'disabled' : ''
    },
    async loadMoreSites() {
      await this.loadSites(this.pagination)
      this.pagination.offset += this.pagination.limit
    },
    onAction(eventName, item) {
      if (eventName === 'edit') {
        this.$router.push(this.editLink(item))
      }
      if (eventName === 'settings') {
        this.$router.push(this.settingsLink(item))
      }
      if (eventName === 'publish') {
        this.publish(item, null)
      }
    },
    onSearchBySiteID(searchQuery) {
      this.filter = searchQuery
    },
    onSortBymarketplace(isMarketPlace) {
      this.onlyMarketplace = !this.onlyMarketplace
    },
    createNewOrg() {
      this.newOrgDialogFlag = true
    },
    handleClose() {
      this.newOrgDialogFlag = false
      this.showDialogHeader = true
      this.orgCreated = false
    },
    async apiCall(data) {
      let response = await organizationApi.addOrganization(data)
      console.log(response)
      if (response.status === 'OK') {
        this.sharedURL = `https://staging.builder.convrrt.com/api/auth/login?orgID=${data.orgID}&projectID=welcome`
        this.orgCreated = true
        this.showDialogHeader = false
      } else {
        //TODO add error notification here!
        console.log('Try Again!')
      }
     console.log(data)
    },
  },
}
</script>
<style lang="scss">
@import "@/scss/utils";

.admin-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  color: $white;
  background-color: $brand-6;
}

.cards-div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 96px;
  background: #fff;
  overflow-y: scroll;
}

.search-filter-style {
  position: fixed;
  width: calc(100vw - 280px);
  z-index: 2;
}
</style>
