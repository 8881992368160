
<div>
  <h1>Pagination</h1>
  <cvt-pagination
    :total="50"
    :per-page="10"
    :current-page="currentPage"
    :pager-count="5"
    :background="false"
    @pagechanged="onPageChange"
  />

  <cvt-pagination
    :total="50"
    :per-page="10"
    :current-page="currentPage"
    :pager-count="5"
    :background="true"
    @pagechanged="onPageChange"
  />
</div>
