<template>
  <div :class="subNavOuterClasses">
    <div :class="subNavInnerClasses">

      <div class="sub-nav-head">
        <cvt-floating-breadcrumb
          :mode="'LIGHT'"
          :active-menu="currentMenuId"
          :menus="breadcrumbMenus"
          :hide-back-btn="false"
          :hide-next-btn="false"
          :control-active-tab="true"
          @menuChanged="(eventData) => onBreadCrumbSelectionChange(eventData)"
        />
      </div>

      <div :class="subNavContentClasses">
        <slot></slot>
      </div>

    </div>
  </div>
</template>

<script>
import {  mapState } from 'vuex'
import { cvtRouteNodes } from "@/js/utils/routes"
export default {
  name: 'SubNavBar',
  props: {
    activeMenu: {
      type: String,
      default: 'design',
    },
  },
  data() {
    return {
      popupId: undefined,
      currentMenuId: this.activeMenu,
      breadcrumbMenus: [
        {
          id: 'design',
          routeNames: [cvtRouteNodes.design],
          title: 'Design',
          completed: false,
          disabled: false,
          action: this.designToggle,
        },
        {
          id: 'settings',
          routeNames: [cvtRouteNodes.settings],
          title: 'Settings',
          completed: false,
          disabled: false,
          action: this.settingToggle,
        },
        {
          id: 'publish',
          routeNames: [cvtRouteNodes.publish],
          title: 'Publish',
          completed: false,
          disabled: false,
          action: this.publishToggle,
        },
      ],
    }
  },

  computed: {
    ...mapState('globalTheme', {
      SideBarLeft: ({ globalTheme }) =>
        globalTheme.SideBarLeft !== undefined ? Boolean(globalTheme.SideBarLeft) : true,
    }),
    subNavOuterClasses() {
      return {
        'sub-nav-container': true,
        'design-page': this.currentMenuId === 'design',
        'settings-page': this.currentMenuId === 'settings',
        'publish-page': this.currentMenuId === 'publish',
      }
    },
    subNavInnerClasses() {
      return {
        'sub-nav': true,
        "left-sidebar":!!this.SideBarLeft,
        "right-sidebar":!this.SideBarLeft,
      }
    },
    subNavContentClasses() {
      return {
        'sub-nav-content': true,
        'design-page-2': this.currentMenuId === 'design',
        'settings-page-2': this.currentMenuId === 'settings',
      }
    },
  },
  mounted() {
    const { popupId } = this.$route.params
    this.popupId = popupId

    const {
      popupEditorDesign,
      popupEditorSettings,
      popupEditorPublish,
    } = cvtRouteNodes

    switch (this.$route.name) {
      case popupEditorDesign:
        this.currentMenuId = "design"
        break
      case popupEditorSettings:
        this.currentMenuId = "settings"
        break
      case popupEditorPublish:
        this.currentMenuId = "publish"
        break
      default:
        this.currentMenuId = "design"
    }
  },
  methods: {
    async pushRoute(route) {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      try {
        await this.$router.push({path: route, query: Object.fromEntries(params)})
      } catch (e) {
        console.debug(e)
      }
    },
    async designToggle() {
      if (this.$slots.default[0].componentInstance.$children[0].nodesIsValid) {
        const nodesIsValid = await this.$slots.default[0].componentInstance.$children[0].nodesIsValid()
        if (nodesIsValid === false) {
          return Promise.resolve(nodesIsValid)
        }
      }
      await this.pushRoute('design')
      return Promise.resolve(true)
    },
    async settingToggle() {
      if (this.$slots.default[0].componentInstance.$children[0].nodesIsValid) {
        const nodesIsValid = await this.$slots.default[0].componentInstance.$children[0].nodesIsValid()
        if (nodesIsValid === false) {
          return Promise.resolve(nodesIsValid)
        }
      }
      await this.pushRoute('settings')
      return Promise.resolve(true)
    },
    async publishToggle() {
      if (this.$slots.default[0].componentInstance.$children[0].nodesIsValid) {
        const nodesIsValid = await this.$slots.default[0].componentInstance.$children[0].nodesIsValid()
        if (nodesIsValid === false) {
          return Promise.resolve(nodesIsValid)
        }
      }
      await this.pushRoute('publish')
      return Promise.resolve(true)
    },
    onBreadCrumbSelectionChange(eventData) {
      const prevMenuId = this.currentMenuId
      if (eventData && eventData.action) {
        eventData.action().then((success) => {
          if (success === false) {
            this.currentMenuId = prevMenuId
          }
          else {
            this.currentMenuId = eventData.id
          }
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import './styles/subNavBar.module.scss'
</style>
