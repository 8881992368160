import ApiClient, { axios } from './ApiClient'

const client = axios.create({
  timeout: 60000,
  // eslint-disable-next-line no-undef
  baseURL: __CLOUD_FN_HOST__,
})

class CloudFunctionsApiClient extends ApiClient {
  _client = client

  imageSearch(search) {
    return this.get('/pexels/', search).then((resp) => {
      return resp.data
    })
  }
}

const functions = new CloudFunctionsApiClient()

export default functions
