<template>
  <span v-show="visible" class="vnode__drag-handle">
    <el-tooltip content="Click &amp; Drag">
      <i class="material-icons">drag_handle</i>
    </el-tooltip>
  </span>
</template>

<script>
  export default {
    props: {
      visible: Boolean
    }
  }
</script>

<style lang="scss">
  .vnode__drag-handle {
    top: 0;
    right: 0;
    position: absolute;
    z-index: 3;
    
    padding: .5em;
    color: white;
    line-height: 8pt;
    text-transform: uppercase;
    cursor: move;

    i {
      font-size: 12pt;
    }
  }
</style>
