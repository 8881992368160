import * as _ from 'lodash'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { styled } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import * as actions from '../../../../builder/src/js/store/modules/actions'
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
// import CvtInput from '../../molecules/Input'
import * as constants from '../../../../builder/src/js/common/constants/constants'
import GoogleSheetIntegration from './google'
import HubspotIntegration from './hubspot'
import MailchimpIntegration from './mailchimp'
import ZapierIntegration from './zapier'
import CvtButton from '../../molecules/Button'
import * as mutations from "../../../../builder/src/js/store/modules/mutations";

Vue.use(VueI18n)

let textFontWeight, textFontSize, Spacing, FontSize, backgroundColor, Colors, FontFamily

const getIntegrationsContainer = () => styled('div')`
  display: flex;
  gap: 15px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: ${FontFamily.DEFAULT};
`

const getIntegrationsItem = () => styled('div')`
  width: 210px;
  height: 230px;
  padding: 15px 25px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
    0px 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
`

const getIntegrationsAddItem = () => styled('div')`
  width: 210px;
  height: auto;
  padding: 15px 25px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
    0px 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 7px;
  cursor: pointer;
  border: 1px dashed ${Colors.PRIMARY[500]};
`

const getIntegrateCheck = () => styled('p')`
  display: flex;
  justify-content: space-between;
  margin: 0;
  height: 20px;
  margin-bottom: 8px;
`

const getIntegrationInnerContainer = () => styled('div')`
  text-align: center;
`

const getIntegrationImage = () => styled('div')`
  height: 60px;
  margin-bottom: 15px;
`

const getIntegrationAddImage = () => styled('div')`
  height: 70px;
  width: 70px;
  border: 4px solid ${Colors.PRIMARY[500]};
  border-radius: 50%;
  display: grid;
  place-content: center;
  margin-bottom: 20px;
`

const getIntegrationName = () => styled('div')`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 12px;
  color: ${Colors.PRIMARY[500]};
`

const getIntegrationDescription = () => styled('div')`
  font-weight: 400;
  font-size: 14px;
  color: ${Colors.BASIC[500]};
`

const getIntegrationAddInnerContainer = () => styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const getInputContainer = () => styled('div')`
  width: ${(props) => (props.inputFullWidth ? '100%' : '90%')};
  margin-bottom: 30px;
  font-family: ${FontFamily.DEFAULT};
`

const getConnectInfoIconWrap = () => styled('p')`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${Colors.BASIC[400]};
`

const getConnectInfoIcon = () => styled('p')`
  width: 16px;
  height: 16px;
  border: 1px solid ${Colors.BASIC[400]};
  border-radius: 50%;
  display: grid;
  place-content: center;
  margin: 4px;
`

const getInfoIcon = () => styled('i')`
  color: ${Colors.BASIC[400]};
  font-size: 8px;
`

const getIntegrationsHeading = () => styled('div')`
  color: ${Colors.BASIC[500]};
  margin-bottom: 15px;
  font-family: ${FontFamily.DEFAULT};
`

const getIntegrateCheckIcon = () => styled('i')`
  color: ${Colors.SUCCESS[500]};
  height: 15px;
`

const getIntegrationAddIcon = () => styled('i')`
  color: ${Colors.PRIMARY[500]};
  font-size: 30px;
`

const getIntegrationNewLabel = () => styled('span')`
  font-size: 12px;
  color: ${Colors.BASIC.WHITE};
  background: ${Colors.INFO[500]};
  padding: 2px 3px;
  border-radius: 4px;
`

const getInvalidFormCaptionHeader = () => styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 100;
  font-family: ${FontFamily.DEFAULT};
`

const getInvalidFormCaptionWrap = () => styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
`

const getInvalidFormCaption = () => styled('div')`
  background: white;
  padding: 1.5rem;
  border-radius: 5px;
  width: 450px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
`

export const IntegrationsTemplate = {
  name: 'IntegrationsTemplate',
  components: {},
  data() {
    return {
      componentMap: {
        googleSheets: GoogleSheetIntegration,
        hubspot: HubspotIntegration,
        mailchimp: MailchimpIntegration,
        zapier: ZapierIntegration,
        // ...other mappings for integrations
      },
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    inputFullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      integrations: getters.GET_BUILDER_INTEGRATIONS_DATA,
      // integrationPreference: getters.SITE_PREFERENCES_INTEGRATION,
      selectedIntegration: getters.SELECTED_INTEGRATION,
      selected: getters.SELECTOR_SELECTED,
    }),
    ...mapGetters('globalTheme', {
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
    }),
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      globalTheme: ({ globalTheme }) => globalTheme,
      integrationsConfig: ({ builderIntegrationsConfig }) =>
        builderIntegrationsConfig,
    }),
    ...mapState('sitePreferences', {
      integrationSetups: ({ preferences }) =>
        _.get(preferences, 'integrations', {}),
    }),
    integrationSetup() {
      return this.integrationSetups[this.selected.id] || {
        provider: "",
        step: constants.AUTH_INIT
      }
    },

    filteredIntegrations() {
      return this.integrations.filter((item) => {
        const integrationKey = item.key

        return (
          this.integrationsConfig.hasOwnProperty(integrationKey) &&
          this.integrationsConfig[integrationKey].integrationEnabled
        )
      })
    },
    isInvalidFormId() {
      // TODO: since multiple form integration is supposed. Drop this
      if (this.integrationSetup.formId === undefined) {
        return false
      }
      return (
        this.integrationSetup.formId !== '' &&
        this.integrationSetup.formId !== this.selected.id
      )
    },
  },
  async created() {
    textFontSize = this.textFontSize
    textFontWeight = this.textFontWeight
    Spacing = this.Spacing
    FontSize = this.FontSize
    backgroundColor = this.backgroundColor
    Colors = this.Colors
    FontFamily = this.FontFamily

    await this.setBuilderIntegrationsConfig(
      this.config.BUILDER_INTEGRATIONS_CONFIG,
    )
    // builder form integrations config
  },
  methods: {
    ...mapActions('globalTheme', {
      setBuilderIntegrationsConfig: 'setBuilderIntegrationsConfig',
    }),
    ...mapMutations({
      triggerIntegrationsDialog: mutations.TRIGGER_INTEGRATIONS_DIALOG,
    }),
    ...mapActions('sitePreferences', {
      saveIntegrationStep: 'saveIntegrationStep',
      clearIntegration: 'clearIntegration',
    }),
    ...mapActions({
      selectIntegration: actions.SELECT_INTEGRATION,
    }),

    async onClickIntegrateItem(item) {
      if (!this.integrationSetup?.provider) {
        await this.saveIntegrationStep({
          formId: this.selected.id,
          params: {
            provider: item.key,
            step: constants.AUTH_INIT,
          }
        })
      }
      await this.selectIntegration(item)
    },
    getStatus(key) {
      if (key === this.integrationSetup?.provider) {
        return [
          constants.INTEGRATION_COMPLETE,
          constants.INTEGRATION_FINISHED,
        ].includes(this.integrationSetup.step)
      }
      return false
    },
    disconnectFormIntegration() {
      this.clearIntegration(this.selected.id).then()
    },

    requestNewIntegration() {
      const integrationUrl = this.config.REQUEST_INTEGRATION_URL
      return integrationUrl && window.open(integrationUrl.url, '_blank');
    }
  },
  render: function (h) {
    const IntegrationsContainer = getIntegrationsContainer()
    const IntegrationsItem = getIntegrationsItem()
    const IntegrationsAddItem = getIntegrationsAddItem()
    const IntegrateCheck = getIntegrateCheck()
    const IntegrationInnerContainer = getIntegrationInnerContainer()
    const IntegrationAddInnerContainer = getIntegrationAddInnerContainer()
    const IntegrationImage = getIntegrationImage()
    const IntegrationAddImage = getIntegrationAddImage()
    const IntegrationName = getIntegrationName()
    const IntegrationDescription = getIntegrationDescription()
    const InputContainer = getInputContainer()
    const ConnectInfoIconWrap = getConnectInfoIconWrap()
    const ConnectInfoIcon = getConnectInfoIcon()
    const InfoIcon = getInfoIcon()
    const IntegrationsHeading = getIntegrationsHeading()
    const IntegrateCheckIcon = getIntegrateCheckIcon()
    const IntegrationAddIcon = getIntegrationAddIcon()
    const IntegrationNewLabel = getIntegrationNewLabel()
    const InvalidFormCaptionHeader = getInvalidFormCaptionHeader()
    const InvalidFormCaptionWrap = getInvalidFormCaptionWrap()
    const InvalidFormCaption = getInvalidFormCaption()

    const ProviderComponent = () => {
      if (this.selectedIntegration) {
        const Component = this.componentMap[this.selectedIntegration.key]
        if (Component) {
          return <Component />
        }
      }
      return <div></div>
    }

    return (
      <div class={'position-relative p-3'} style="width: 100%;">
        {this.isInvalidFormId && (
          <InvalidFormCaptionHeader>
            <InvalidFormCaptionWrap>
              <InvalidFormCaption>
                <p>
                  It seems you have an integration setup for another form. Clear
                  existing integration to be able to add new integration
                </p>

                <div class={'d-flex justify-content-between'}>
                  <CvtButton
                    text="Cancel"
                    outlined={true}
                    specialPadding="12px"
                    color="light"
                    onClick={() => this.triggerIntegrationsDialog(false)}
                  ></CvtButton>
                  <CvtButton
                    text={'Clear Form Integration'}
                    outlined={true}
                    specialPadding="12px"
                    color="danger"
                    onClick={() => this.disconnectFormIntegration()}
                  ></CvtButton>
                </div>
              </InvalidFormCaption>
            </InvalidFormCaptionWrap>
          </InvalidFormCaptionHeader>
        )}
        {!this.selectedIntegration && (
          <div>
            <IntegrationsHeading>
              {this.$t('builder.integrations.header.text')}
            </IntegrationsHeading>
            <InputContainer inputFullWidth={this.inputFullWidth}>
              {/*<CvtInput*/}
              {/*  label=""*/}
              {/*  size="small"*/}
              {/*  mode={this.mode}*/}
              {/*  color="black"*/}
              {/*  placeholder="Search"*/}
              {/*  value={''}*/}
              {/*  onClear={() => {}}*/}
              {/*  onInput={(e) => {}}*/}
              {/*  suffixIcon="search"*/}
              {/*></CvtInput>*/}
              <ConnectInfoIconWrap>
                <ConnectInfoIcon>
                  <InfoIcon class="fa fa-info" />
                </ConnectInfoIcon>
                Connect your form to other apps
              </ConnectInfoIconWrap>
            </InputContainer>

            <IntegrationsContainer>
              {this.filteredIntegrations.map((integration) => (
                <IntegrationsItem
                  onClick={() => this.onClickIntegrateItem(integration)}
                >
                  <IntegrateCheck>
                    <span>
                      {integration.new && (
                        <IntegrationNewLabel>New</IntegrationNewLabel>
                      )}
                    </span>
                    <span>
                      {this.getStatus(integration.key) && (
                        <IntegrateCheckIcon class="fa fa-check-circle" />
                      )}
                    </span>
                  </IntegrateCheck>
                  <IntegrationInnerContainer>
                    <IntegrationImage>
                      <img
                        style="height: 100%;"
                        src={integration.image}
                        alt={integration.title}
                      />
                    </IntegrationImage>
                    <IntegrationName> {integration.title} </IntegrationName>
                    <IntegrationDescription>
                      {integration.description}
                    </IntegrationDescription>
                  </IntegrationInnerContainer>
                </IntegrationsItem>
              ))}
              <IntegrationsAddItem onClick={this.requestNewIntegration}>
                <IntegrationAddInnerContainer>
                  <IntegrationAddImage>
                    <IntegrationAddIcon class="fa fa-plus" />
                  </IntegrationAddImage>
                  <IntegrationName> Request an integration </IntegrationName>
                  <IntegrationDescription>
                    Please tell us about the integration you’re requesting
                  </IntegrationDescription>
                </IntegrationAddInnerContainer>
              </IntegrationsAddItem>
            </IntegrationsContainer>
          </div>
        )}

        {this.selectedIntegration && <ProviderComponent />}
      </div>
    )
  },
}
