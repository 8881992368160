export default [
  // 'Benefits',
  'Call to Action',
  'Cover',
  'FAQs',
  'Features',
  'Forms',
  'Header',
  'How it Works',
  // 'Layout Placeholders',
  'Social Proof',
  'Testimonials',
  // 'Text Blocks',
  'Video',
  'Layout',
  // 'Skeleton',
  'Popup'
].map(t => t.toUpperCase())
