import { styled } from '@egoist/vue-emotion'
import Vue from '../../lib/utils/vue'
import { mapState } from '../../../builder/node_modules/vuex'


let froalaToolBarConfig

const getFroalaWrapper = () => styled('div')`
  &.froala-portal {
    & > .fr-toolbar {
      & > .fr-btn-grp {
        & > .fr-command.fr-btn {
          &:hover {
            background-color: ${froalaToolBarConfig.hover.color};
          }
        }
      }
    }
  }
`
export default {
  name: 'CvtFroalaWrapper',
  props: {
    wrapperId: {
      type: String,
      required: true,
      default: 'froala-portal',
    },
  },
  computed: {
    ...mapState('globalTheme', {
      froalaToolBarConfig: ({ globalTheme }) =>
        globalTheme.froalaToolBarConfig
          ? globalTheme.froalaToolBarConfig
          : false,
    }),
  },
  created() {
    froalaToolBarConfig = this.froalaToolBarConfig
  },
  render: function (h) {
    const FroalaWrapper = getFroalaWrapper()

    return (
      <FroalaWrapper id={this.wrapperId} class="froala-portal"></FroalaWrapper>
    )
  },
}
