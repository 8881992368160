<template>
  <cvt-dialog
    v-if="!AltPopupManager"
    :show="visible"
    :width="'50vw'"
    @close="close"
  >
    <template #title>
      <p class="upload__manager__text">File Manager</p>
    </template>

    <cvt-tabs
      v-if="config.COMPANY_IMAGES_TAB_ENABLED"
      :keep-inactive-border="false"
      :closeable="false"
      prefix-icon=""
    >
      <cvt-tab
        id="company-images"
        title="Company Images"
        name="company-images"
        :active="'company-images' == currentTab"
      >
        <crm-file-browser @use="confirmSelection" />
      </cvt-tab>

      <cvt-tab
        id="upload"
        title="Upload"
        name="upload"
        :active="'upload' == currentTab"
      >
        <alt-upload-dropzone
          ref="uploadDropzone"
          :image-only="true"
          :size-limit="5"
          @complete="confirmSelection"
          @gallery-btn-clicked="openGallery"
          @done="setShowDoneBtn"
        />
      </cvt-tab>
    </cvt-tabs>

    <alt-upload-dropzone
      v-if="!config.COMPANY_IMAGES_TAB_ENABLED"
      ref="uploadDropzone"
      :image-only="true"
      :size-limit="5"
      @complete="confirmSelection"
      @done="setShowDoneBtn"
      @gallery-btn-clicked="openGallery"
    />

<!--    <element-anchor v-if="isGridBuilder"></element-anchor>-->

    <template #modalFooter>
      <cvt-button :text="modalButtonText" @click="close" />
    </template>
  </cvt-dialog>

  <cvt-dialog
    v-else
    bg-color="#ffffff"
    :show="visible"
    size="sm"
    :height-auto="true"
    :show-close="false"
    @close="close"
  >
    <template #title>
      <h5>File upload</h5>
    </template>

    <alt-upload-dropzone
      ref="uploadDropzone"
      btn-label="Select from computer"
      :image-only="true"
      :size-limit="5"
      @complete="confirmSelection"
      @done="setShowDoneBtn"
      @gallery-btn-clicked="openGallery"
    />
    <template #modalFooter>
      <cvt-button :text="modalButtonText" color="light" @click="close" />
    </template>
  </cvt-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../storybook/components/constants'
import * as actions from '../../store/modules/actions'
import * as getters from '../../store/modules/getters'
import * as mutations from '../../store/modules/mutations'
// import ElementAnchor from '../editor/advanced-side-menu/ElementAnchor.vue'
import CvtGallery from '../editor/left-side-menu/ImageGallery.vue'
import AltUploadDropzone from './alt-upload-dropzone/AltUploadDropZoneIndex.vue'
import CrmFileBrowser from './CRMFileBrowser.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Manager',
  components: {
    AltUploadDropzone,
    CrmFileBrowser,
    // ElementAnchor,
  },
  data() {
    return {
      modalButtonText: 'Close',
    }
  },
  computed: {
    ...mapGetters({
      open: getters.ASSET_MANAGER_OPEN,
      activeTab: getters.ASSET_MANAGER_ACTIVE_TAB,
      user: getters.AUTH_GET_USER,
      config: getters.AUTH_GET_USER_CONFIG,
      isGridBuilder: getters.IS_GRID_BUILDER,
    }),
    ...mapState('globalTheme', {
      ImageDialog: ({ globalTheme }) =>
        globalTheme.ImageDialog !== undefined ? globalTheme.ImageDialog : true,
      AltPopupManager: ({ globalTheme }) =>
        globalTheme.AltPopupManager !== undefined
          ? globalTheme.AltPopupManager
          : false,
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
    }),
    visible: {
      get() {
        return this.open
      },
      set(value) {
        this.toggle(value)
      },
    },
    currentTab: {
      get() {
        return this.activeTab
      },
      set(tab) {
        return this.activateTab(tab)
      },
    },
    isSpecialStyle() {
      return this.OrgName === LEFT_SIDEBAR_FRESHWORKS ? 'dark' : ''
    },
  },
  methods: {
    ...mapMutations({
      toggle: mutations.ASSET_MANAGER_TOGGLE,
      reset: mutations.ASSET_MANAGER_RESET,
      activateTab: mutations.ASSET_MANAGER_ACTIVATE_TAB,
      imageSearchSetHandler: mutations.IMAGE_SEARCH_SET_INSERT_HANDLER,
    }),
    ...mapActions({
      toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
      confirmSelection: actions.ASSET_MANAGER_CONFIRM_SELECTION,
    }),
    setShowDoneBtn() {
      this.modalButtonText = 'Done'
    },
    close() {
      this.$refs.uploadDropzone.reset()
      this.toggle(false)
    },
    setGallery() {
      this.imageSearchSetHandler((image) => {
        this.confirmSelection(image)
        this.toggleLeftSideMenu({ activate: null, open: false })
      })
    },
    openGallery() {
      this.toggleLeftSideMenu({
        activate: 'image-gallery',
      })

      this.setGallery()

      this.close()
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

.upload__manager__text {
  font-size: 1.25rem;
  margin: 0;
  font-family: 'Nunito';
}
</style>
