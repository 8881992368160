import loadAppCues from '../../lib/loadAppCues'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

function AppCues(...args) {
  if (!window.appCues) {
    return console.debug('AppCues is not currently installed', ...args)
  }
  return window.appCues
}

export default {
  state: {
    loaded: false,
  },
  getters: {},
  mutations: {
    [mutations.APPCUES_LOADED](state, loaded) {
      state.loaded = loaded
    },
  },
  actions: {
    async [actions.APPCUES_BOOT]({ state, rootGetters, commit }, user) {
      if (state.loaded) {
        return
      }

      const config = rootGetters[getters.AUTH_GET_USER_CONFIG]
      if (!config.APPCUES_METRIC_URLS) {
        return
      }

      await loadAppCues(config.APPCUES_METRIC_URLS)
      commit(mutations.APPCUES_LOADED, true)
    },
  },
}
