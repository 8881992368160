import * as _ from 'lodash'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'
import { V1CRM } from '../services/api'

let crm = new V1CRM()

export default {
  state: {
    open: false,
    tab: 'upload',
    action: null,
    path: '/',
    assets: {},
    crmFiles: [],
  },
  getters: {
    [getters.ASSET_MANAGER_OPEN]({ open }) {
      return open
    },
    [getters.ASSET_MANAGER_ACTIVE_TAB]({ tab }) {
      return tab
    },
    [getters.ASSET_MANAGER_CRM_FILES]({ crmFiles }) {
      return crmFiles
    },
  },
  mutations: {
    [mutations.ASSET_MANAGER_TOGGLE](state, open) {
      state.open = open
    },
    [mutations.ASSET_MANAGER_ACTIVATE_TAB](state, tab) {
      state.tab = tab
    },
    [mutations.ASSET_MANAGER_SET_SELECT_ACTION](state, action) {
      state.action = action
    },
    [mutations.ASSET_MANAGER_RESET](state) {
      state.open = false
      state.action = null
    },
  },
  actions: {
    [actions.ASSET_MANAGER_INIT]({ commit }, action) {
      commit(mutations.ASSET_MANAGER_RESET)
      commit(mutations.ASSET_MANAGER_TOGGLE, true)
      commit(mutations.ASSET_MANAGER_SET_SELECT_ACTION, action)
    },
    [actions.ASSET_MANAGER_CONFIRM_SELECTION]({ commit, state }, assets) {
      if (_.isFunction(state.action)) {
        try {
          state.action(assets)
        } catch (e) {
          console.error('there was an error during the action hook', e)
        }
      }
      commit(mutations.ASSET_MANAGER_RESET)
    },
    [actions.ASSETS_MANAGER_SEARCH_CRM_FILES]({ state }, query) {
      return crm.searchFiles(query).then(({ data }) => {
        state.crmFiles = data
      })
    },
  },
}
