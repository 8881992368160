<template>
  <div>
    <div class="row">
      <div class="col">
        <strong>Button</strong>
      </div>
      <div class="col-3">
        <cvt-switch
          size="xs"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="enableButton"
          :mode="mode"
          @switchToggle="() => onSwitchToggle('enableButton')"
        />
      </div>
    </div>
    <div :class="DividerWithBorder ? 'divider' : 'divider-no-border'" />
    <div class="row">
      <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
        Inline Space
      </div>
      <div class="col-12">
        <cvt-slider
          id="slider-default"
          class="slider"
          :tooltips-enabled="true"
          :step-marks-enabled="true"
          :min="0"
          :max="50"
          :value="gap"
          :step="1"
          @change="(cval) => gap = cval"
        />
      </div>
    </div>

    <div v-if="isCardView" :class="DividerWithBorder ? 'divider' : 'divider-no-border'" />
    <div v-if="isCardView" class="row align-items-baseline">
      <div class="col-6">
        <strong>Card Width</strong>
      </div>
      <div class="col">
        <cvt-input
          v-model="cardWidth"
          type="number"
          :clearable="false"
        />
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import * as _ from "lodash";
import * as getters from "@/js/store/modules/getters";
import { LEFT_SIDEBAR_FRESHWORKS } from "../../../../../../../storybook/components/constants";
import * as actions from "@/js/store/modules/actions";

export default {
  name: "TemplateOptions",
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapState('globalTheme', {
      DividerWithBorder: ({ globalTheme }) =>
        globalTheme.DividerWithBorder !== undefined
          ? globalTheme.DividerWithBorder
          : true,
      OrgName: ({ globalTheme }) => globalTheme.OrgName !== undefined ? globalTheme.OrgName : 'standard'
    }),
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      viewMode: getters.VIEW_MODE
    }),
    isSpecialStyle() {
      return this.OrgName === LEFT_SIDEBAR_FRESHWORKS
    },
    isCardView () {
      return _.get(this, 'selected.layoutView', "") === "card"
    },
    enableButton: {
      get() {
        return _.get(this, 'selected.template.enableButton', true)
      },
      set(value) {
        return this.updateProp({ key: 'template/enableButton', value })
      },
    },
    gap: {
      get() {
        return _.get(this, 'selected.template.gap', "")
      },
      set: _.throttle(function (value) {
        return this.selected.updateProp('template/gap', value)
      }, 300),
    },
    cardWidth: {
      get() {
        return Number.parseInt(_.get(this, 'selected.cardWidth', 18))
      },
      set: _.throttle(function ({ target }) {
        this.selected.updateProp('cardWidth', Number.parseInt(target.value))
      }, 300),
    },
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
    }),
    onSwitchToggle (property) {
      this[property] = !this[property]
    },
  }
};
</script>

<style scoped>

</style>