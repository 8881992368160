import * as _ from 'lodash'

export const getFullUrlPath = () =>
  `${window.location.pathname}${window.location.search}`

/**
 * This is a function to send credentials from the client side to the backend
 * to get form fields.
 * This first function check the src which is a window object
 * The config has been set to get what fields need to be selected from the window object
 * and dynamically pass the keys as a query parameter to the backend
 * One client doing this logic is Doppler
 */
export const clientSpecificCustomParam = (config: any) => {
  if (config.FETCH_CLIENT_VALIDATION_FORM_FIELD) {
    let getClientValidationKeys: { [key: string]: string } = {}
    if (config.FETCH_CLIENT_VALIDATION_FORM_FIELD.src === 'window') {
      // Iterate over the window object
      for (var key in window[config.FETCH_CLIENT_VALIDATION_FORM_FIELD.key]) {
        // Check if the key exists in the window object
        if (
          Object.prototype.hasOwnProperty.call(
            window[config.FETCH_CLIENT_VALIDATION_FORM_FIELD.key],
            key,
          )
        ) {
          // Set the value in x with the same key
          getClientValidationKeys[key] =
            window[config.FETCH_CLIENT_VALIDATION_FORM_FIELD.key][key]
        }
      }
      getClientValidationKeys = _.pick(
        getClientValidationKeys,
        config.FETCH_CLIENT_VALIDATION_FORM_FIELD.select,
      )
    }
    return getClientValidationKeys
  }
}

/**
 * This is a function for certain client configurations
 * an example use case doppler client specific as their micro frontend requires unique
 * configuration to trigger the behavior of their microfrontend menu.
 */
export const customClientConfig = (orgId: string) => {
  const config: { [key: string]: () => any } = {
    doppler: () => {
      // orgID
      if (!window.dopplerSessionState) return {}
      if (window.location.pathname.includes('editor')) {
        return window.displayDopplerNavBar(false)
      }
      if (window.location.pathname.includes('choose-a-template')) {
        return window.displayDopplerNavBar(true)
      }
    },
  }
  return (config[orgId] && config[orgId]()) || {}
}
