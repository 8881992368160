
<cvt-dialog
    v-if="!AltPopupManager"
    size="sm"
    bg-color="#fff"
    :show="show"
    :show-close="false"
    :height-auto="true"
    :show-header="false"
    :show-footer="false"
>
  <div class="row mb-4 mt-4">
    <div class="col-md-10 text-center mx-auto">
      <h3 class="setup-title" :style="titleStyle">
        RESET EDITING CANVAS
      </h3>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-md-10 text-center mx-auto">
      <h6 :style="titleStyle">Would you like to start over from scratch?</h6>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col">
      <cvt-button
          size="md"
          text="Yes"
          :outlined="true"
          :full-width="true"
          v-bind="submitBtnProps"
          :loading="loading"
          @click="startOverHandler"
      />
    </div>
    <div class="col">
      <cvt-button
          size="md"
          text="No"
          :outlined="true"
          :full-width="true"
          v-bind="cancelBtnProps"
          :disable="loading"
          @click="closeDialog"
      />
    </div>
  </div>
</cvt-dialog>
<cvt-dialog
      v-else
      size="sm"
      bg-color="#fff"
      :show="show"
      :show-close="false"
      :height-auto="true"
      :show-header="false"
      :show-footer="false"
  >
    <div class="row mb-4 mt-4">
      <div class="col-md-10 text-center mx-auto">
        <h3 class="setup-title" :style="titleStyle">
          RESET EDITING CANVAS
        </h3>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-10 text-center mx-auto">
        <h6 :style="titleStyle">Would you like to start over from scratch?</h6>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col">
        <cvt-button
            size="md"
            color="primary"
            text="Yes"
            theme="light"
            special-style="dark"
            :outlined="true"
            :full-width="true"
            :loading="loading"
            @click="startOverHandler"
        />
      </div>
      <div class="col">
        <cvt-button
            size="md"
            color=""
            text="No"
            :outlined="true"
            :full-width="true"
            :disable="loading"
            @click="closeDialog"
        />
      </div>
    </div>
  </cvt-dialog>
