
<div
  :id="vnodeId"
  ref="subGridWrapper"
  data-identity="SubGridContainer"
  :data-vnode-id="vnodeId"
  :class="classes"
  @click="onClick"
  @mouseenter="mouseOverAction"
  @mouseleave="mouseLeaveAction"
  @contextmenu.prevent.stop="showContextMenu"
>
  <div :class="subContainerBgImage" />
  <div :class="subContainerBgColor" />

  <div class="section-drag-items-wrap">
    <cvt-section-drag-items
      v-if="vnodeId"
      class="drag-items-sub-container"
      :exclude-item="['container']"
      :usage="'sub-grid-container'"
      :show-plus-btn="true"
      :vnode-id="vnodeId"
      :widget-class="'grid-stack-item-sub'"
    />
  </div>

  <div class="w-100">
    <div
        :id="`${vnodeId}-sub-grid`"
        ref="gridRootWrapper" class="position-relative sub-grid-visual-box">
      <visual-box
          ref="vsBox"
          box-color="#c2bebe8f"
          :grid-column="column"
          :grid-column-height="cellHeight"
          :grid-v-node-id="`${vnodeId}-sub-grid`"/>
      <div
          ref="nestedGridStackElement"
          :style="fontColorBG"
          class="grid-stack"
      >
        <img
            v-if="!hasItemsInSlot && !backgroundImgExist"
            class="img-fluid shadow-none img-radius-none"
            src="/img/box-container-placeholder-.svg"
            alt=""
        />
        <slot />
      </div>
    </div>
  </div>
</div>
