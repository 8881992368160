
<div
  :id="vNodeId"
  ref="Container"
  data-identity="Container"
  :data-vnode-id="vNodeId"
  :class="classes"
  @click="onContainerClick"
  @mouseenter="mouseOverAction"
  @mouseleave="mouseLeaveAction"
  @contextmenu.prevent.stop="showContextMenu"
>
  <div :class="containerBgImage" />
  <div :class="containerBgColor" />

  <slot/>
</div>
