
	<cvt-collapse
		:accordion="true"
		:theme="mode === 'LIGHT' ? 'light' : 'dark'"
		:prepend-chevron="prependChevron"
	>
		<cvt-collapse-item
			name="Properties"
			:title="$t('elements.properties.checkout.mock.payment.page.display_options')"
			:initial-active="true"
		>
			<div class="row mt-2">
				<div class="col">
					<h6 :class="textColorClass">
						{{ $t('elements.properties.checkout.mock.thankyou.page.display_options.thankyou') }}
					</h6>
				</div>
				<div class="col-3">
					<cvt-switch
						size="sm"
						color="active"
						type="circular"
						description-type="default"
						:initial-state="showThankYouMessage"
						:mode="mode"
						@switchToggle="(status) => onSwitchToggle('showThankYouMessage', status)"
					></cvt-switch>
				</div>
			</div>

      <div class="divider" />

			<div class="row mt-2">
				<div class="col">
					<h6 :class="textColorClass">
						{{ $t('elements.properties.checkout.mock.thankyou.page.display_options.contact_info') }}
					</h6>
				</div>
				<div class="col-3">
					<cvt-switch
						size="sm"
						color="active"
						type="circular"
						description-type="default"
						:initial-state="showContactEmail"
						:mode="mode"
						@switchToggle="(status) => onSwitchToggle('showContactEmail', status)"
					></cvt-switch>
				</div>
			</div>

      <div class="divider" />

			<div class="row mt-2">
				<div class="col">
					<h6 :class="textColorClass">
						{{ $t('elements.properties.checkout.mock.thankyou.page.display_options.billing_info') }}
					</h6>
				</div>
				<div class="col-3">
					<cvt-switch
						size="sm"
						color="active"
						type="circular"
						description-type="default"
						:initial-state="showBillingInfo"
						:mode="mode"
						@switchToggle="(status) => onSwitchToggle('showBillingInfo', status)"
					></cvt-switch>
				</div>
			</div>

      <div class="divider" />

			<div class="row mt-2">
				<div class="col">
					<h6 :class="textColorClass">
						{{ $t('elements.properties.checkout.mock.thankyou.page.display_options.order_summary') }}
					</h6>
				</div>
				<div class="col-3">
					<cvt-switch
						size="sm"
						color="active"
						type="circular"
						description-type="default"
						:initial-state="showOrderSummary"
						:mode="mode"
						@switchToggle="(status) => onSwitchToggle('showOrderSummary', status)"
					></cvt-switch>
				</div>
			</div>

      <div class="divider" />

			<div class="row mt-2">
				<div class="col">
					<h6 :class="textColorClass">
						{{ $t('elements.properties.checkout.mock.thankyou.page.display_options.order_items') }}
					</h6>
				</div>
				<div class="col-3">
					<cvt-switch
						size="sm"
						color="active"
						type="circular"
						description-type="default"
						:initial-state="showItemsInOrder"
						:mode="mode"
						@switchToggle="(status) => onSwitchToggle('showItemsInOrder', status)"
					></cvt-switch>
				</div>
			</div>

			<template v-if="showThankYouMessage">
				<div class='row mt-3'>
					<div class='col-md-12 ml-auto'>
						<cvt-input
							type="text"
							:label="$t('elements.properties.checkout.mock.thankyou.page.thankyou_message_heading')"
							:validation-status="isThankYouMessage1Valid"
							:value="thankYouMessage1"
							@input="(e) => updateProperty('thankYouMessage1', e.target.value)"
						/>
					</div>
				</div>
				<div class='row'>
					<div class='col-md-12 ml-auto'>
						<cvt-input
							type="text"
							:label="$t('elements.properties.checkout.mock.thankyou.page.thankyou_message_subheading')"
							:validation-status="isThankYouMessage2Valid"
							:value="thankYouMessage2"
							@input="(e) => updateProperty('thankYouMessage2', e.target.value)"
						/>
					</div>
				</div>
				<div class='row'>
					<div class='col-md-12 ml-auto'>
						<cvt-input
							type="textarea"
							:label="$t('elements.properties.checkout.mock.thankyou.page.thankyou_message_long_text')"
							:validation-status="isThankYouMessageSubtitleValid"
							:value="thankYouMessageSubtitle"
							@input="(e) => updateProperty('thankYouMessageSubtitle', e.target.value)"
						/>
					</div>
				</div>
			</template>

		</cvt-collapse-item>
	</cvt-collapse>
