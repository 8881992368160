<template>
  <div class="ecomm-products">
    <el-select
      ref="searcher"
      v-model="query"
      remote
      filterable
      size="large"
      value-key="id"
      :remote-method="search"
      :placeholder="placeholder"
      this.query=""
      @change="productSelected"
      @visible-change="search('')"
    >
      <el-option
        v-for="product in productList"
        :key="product.id"
        :label="$options.filters.truncate(product.name, 50)"
        :value="product"
      >
        <span
          style="float: left"
          v-text="$options.filters.truncate(product.name, 50)"
        />
      </el-option>
    </el-select>
  </div>
</template>

<script>
import * as _ from 'lodash'
import { V1ECommerce } from '../../../store/services/api'

const eCommerce = new V1ECommerce()

export default {
  name: 'EcommProducts',
  props: {
    products: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Choose a Product'
    }
  },
  data () {
    return {
      query: '',
      remoteProducts: []
    }
  },
  computed: {
    productList () {
      return [
        ...this.remoteProducts.filter(p => !_.find(this.products, { id: p.id }))
      ]
    }
  },
  methods: {
    search: _.throttle(async function (query) {
      this.remoteProducts = await eCommerce.searchProductsWithSkus({ query })
    }, 500, {
      leading: true
    }),
    productSelected (product) {
      this.query = ''
      this.$emit('product-selected', product)
    }
  }
}
</script>

<style lang="scss">
  .ecomm-products {
    .el-select {
      width: 100%;
    }
  }
</style>
