<template>
  <div>
    <flex-align 
      v-if="isGridBuilder && selected.parent().name === 'GridItemWrapper' && !hideFlexAlignment"
      :mode="mode"
      :disable-alignment="hideFlexAlignment"
      :disable-constrain="hideFlexAlignment"
    ></flex-align>
    <cvt-collapse :accordion="true" :theme="mode === 'LIGHT' ? 'light' : 'dark'" :prepend-chevron="prependChevron">
      <cvt-collapse-item title="Image" name="image" :initial-active="true" :special-style="isSpecialStyle">
        <element-image-manager image-key="src" :hook="selected.updateSrc"></element-image-manager>
        <div v-if="!isGridBuilder" class="row">
          <h6 class="col text-capitalize">
            {{ $t("element.property.label.resize") }}
          </h6>
          
          <div class="col-12">
            <resize hide-label></resize>
          </div>
        </div>

        <div v-if="isGridBuilder" class="row mt-4 d-flex justify-content-between">
          <div class="col">
            <h6>{{ $t("element.property.label.crop_logo") }}</h6>
          </div>
          <div class="col-3">
            <cvt-switch
              size="sm"
              color="active"
              type="circular"
              description-type="default"
              :initial-state="hideLogoOverflow"
              :mode="mode"
              @switchToggle="(status) => updateLogoCrop(status)"
            ></cvt-switch>
          </div>
        </div>

        <div v-if="OrgName !== orgNameFreshWork" class="divider" />
        <div v-else class="mt-3" />

        <div class="row">
          <h6 class="col text-capitalize">
            {{ $t("element.property.image.alt.label") }}
          </h6>
          <div class="col-12">
            <cvt-input v-model="alt" type="text" :placeholder="$t('element.property.image.alt.alt_message')"></cvt-input>
          </div>
        </div>

        <div v-if="OrgName !== orgNameFreshWork" class="divider" />
        <div v-else class="mt-3" />

        <div class="row">
          <h6 class="col text-capitalize">
            {{ $t("element.property.link") }}
          </h6>
          <div class="col-12">
            <url-input v-model="href" type="text" :placeholder="$t('element.property.image.alt.link_message')"></url-input>
          </div>
        </div>

      </cvt-collapse-item>
      
      <cvt-collapse-item v-if="!isGridBuilder" :title="$t('elements.properties.alignment')" name="alignment" :special-style="isSpecialStyle">
        <flex-align :mode="mode"></flex-align>
      </cvt-collapse-item>

      <cvt-collapse-item :title="$t('elements.properties.spacing.label')" name="spacing" :special-style="isSpecialStyle">
        <spacing-ctrl></spacing-ctrl>
      </cvt-collapse-item>
      <cvt-collapse-item v-if="isGridBuilder" title="Style" name="style" :special-style="isSpecialStyle">

        <!-- <div class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t("elements.properties.background.color") }}
          </div>
          <div class="col-12">
            <colors alpha :mode="mode"/>
          </div>
        </div>

        <div class="divider mt-2" /> -->

        <div class="nestedInnerItem">
          <cvt-select
            :value="borderStyle"
            mode="dropdown"
            :background-mode="mode"
            :floating-label="false"
            :label='$t("elements.properties.border.style")'
            @input="(value) => updateBorderStyle(value)"
          >
            <option
              v-for="style in borderStyles"
              :key="style"
              :value="style"
              v-text="style"
            />
          </cvt-select>
          <div style="margin: 5px;" />
          <cvt-input
            v-model="borderColor"
            style="width: max-content;"
            type="text"
            :clearable="false"
            :label='$t("elements.properties.border.color")'
          />
        </div>

        <div class="divider mt-2" />

        <div class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t("elements.properties.border.width") }}
          </div>
          <div class="col-12">
            <cvt-slider
              id="slider-default"
              :tooltips-enabled="true"
              :step-marks-enabled="true"
              class="slider"
              :min="1"
              :max="10"
              :value="borderWidth"
              :step="1"
              @change="updateBorderWidth"
            />
          </div>
        </div>
        <div class="divider mt-2" />

        <div class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t("elements.properties.border.radius") }}
          </div>
          <div class="col-12">
            <cvt-slider
              id="slider-default"
              :tooltips-enabled="true"
              :step-marks-enabled="true"
              class="slider"
              :min="0"
              :max="100"
              :value="borderRadius"
              :step="5"
              @change="updateBorderRadius"
            />
          </div>
        </div>
      </cvt-collapse-item>
    </cvt-collapse>
  </div>
</template>

<script>
  import * as _ from 'lodash'
  import Resize from './Resize.vue'
  import SpacingCtrl from './SpacingCtrl.vue'
  import FlexAlign from './FlexAlign.vue'
  // import Colors from '../theme/Colors.vue'
  import ElementImageManager from './ElementImageManager.vue'
  import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
  import * as getters from '../../../store/modules/getters'
  import * as actions from '../../../store/modules/actions'
  import { LEFT_SIDEBAR_FRESHWORKS, LEFT_SIDEBAR_STANDARD } from '../../../../../../storybook/components/constants'
  export default {
    components: {
      Resize,
      SpacingCtrl,
      FlexAlign,
      ElementImageManager,
      // Colors
    },
    props: {
      mode: {
        type: String,
        default: 'LIGHT'
      },
      prependChevron: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        activeTabs: [
          'image'
        ],
        orgNameFreshWork: LEFT_SIDEBAR_FRESHWORKS,
        borderStyles: [
          'none',
          'solid',
          // 'ridge',
          // 'inset',
          // 'outset',
          'dashed',
          'dotted',
          'double',
          'groove'
        ],
        borderWidth: 0,
        borderRadius: 0
      }
    },
    computed: {
      ...mapGetters({
        selected: getters.SELECTOR_SELECTED,
        config: getters.AUTH_GET_USER_CONFIG,
        isGridBuilder: getters.IS_GRID_BUILDER
      }),
      ...mapState('globalTheme', {
        OrgName: ({ globalTheme }) => globalTheme.OrgName !== undefined ? globalTheme.OrgName : LEFT_SIDEBAR_STANDARD
      }),
      alt: {
        get () {
          return this.selected.alt
        },

        set: _.debounce(function ({target}) {
          return this.updateProp({
            key: 'alt',
            value: target.value
          })
        }, 800)
      },
      href: {
        get () {
          return this.selected.href || ''
        },
        set: _.debounce(function (value) {
          this.updateProp({
            key: 'href',
            value: value
          })
          return value
        }, 800)
      },
      isSpecialStyle() {
        return this.OrgName === LEFT_SIDEBAR_FRESHWORKS
      },
      borderStyle: {
        get () {
          return this.selected.parent().name === 'GridItemWrapper' ? this.selected.parent().borderStyle || 'none' : 'none'
        },
        set: _.throttle(function (value) {
          return this.selected.parent().updateProp('borderStyle', value)
        }, 300)
      },
      borderColor: {
        get () {
          return this.selected.parent().name === 'GridItemWrapper' ? this.selected.parent().borderColor : null
        },
        set: _.throttle(function ({ target }) {
          if (this.selected.parent().name === 'GridItemWrapper') {
            return this.selected.parent().updateProp('borderColor', target.value)
          }

          return
        }, 300)
      },
      hideLogoOverflow: {
        get () {
          return this.selected.hideLogoOverflow
        },
        set (value) {
          return this.updateProp({ key: 'hideLogoOverflow', value })
        }
      },
      hideFlexAlignment() {
        return this.config.HIDE_FLEX_ALIGNMENT_OPTIONS?.logo === true;
      },
    },
    created () {
      if (this.selected.parent().name === 'GridItemWrapper') {
        this.borderWidth = this.selected.parent().borderWidth || 0
        this.borderRadius = this.selected.parent().borderRadius || 0
      }
    },
    methods: {
      ...mapActions({
        updateProp: actions.SELECTOR_UPDATE_PROP
      }),
      updateBorderStyle (value) {
        if (this.selected.parent().name === 'GridItemWrapper') {
          this.borderStyle = value
        }
      },
      updateBorderWidth: _.throttle(function (value) {
        if (this.selected.parent().name !== 'GridItemWrapper') return

        this.borderWidth = value
        return this.selected.parent().updateProp('borderWidth', value)
      }, 300),
      updateBorderRadius: _.throttle(function (value) {
        if (this.selected.parent().name !== 'GridItemWrapper') return

        this.borderRadius = value
        return this.selected.parent().updateProp('borderRadius', value)
      }, 300),
      updateLogoCrop (status) {
        if(status === 'Active') {
          this.hideLogoOverflow = true
        } else if (status === 'Inactive') {
          this.hideLogoOverflow = false
        }
      },
    }
  }
</script>
