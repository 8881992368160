import Vue from '../../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import { CvtIcon } from '../../molecules/icon/icon'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import { LEFT_SIDEBAR_FRESHWORKS, LEFT_SIDEBAR_STANDARD } from '../../constants'

Vue.use(VueEmotion)
let Spacing, FontFamily, ColoredActive, LeftSidebarWidth

let textFontWeight, backgroundColor, textFontSize, textLineHeight, color

const getSideBar = () => styled('aside')`
  ${(props) => {
    return !props.sideBarLeft ? 'right: 0px;' : ''
  }}
  display: flex;
  margin: ${props => props.marginTop}px 0 0 0;
  height: calc(100vh - ${props => props.sideBarHeight}px);

  position: fixed;
  z-index: 2;
  flex-direction: row;
  font-family: ${FontFamily.DEFAULT};
  ${(props) => {
    let bgColor =
      props.mode == 'DARK'
        ? backgroundColor({ themeing: 'dark' }, 700)
        : backgroundColor({ themeing: 'dark' }, 'WHITE')
    let textColor =
      props.mode == 'DARK'
        ? color({ themeing: 'dark' }, 'WHITE')
        : color({ themeing: 'dark' }, 500)

    return bgColor + textColor
  }};
`

const getPanelContainer = () => styled('div')`
  display: flex;
  height: 100%;
  width: ${props => props.width}px;
  align-items: center;
  flex-direction: column;
`

const getPanel = () => styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${
  // Should be refactored to adding width in .json and pulling it by
  // a calling a method passing the props.type in argument.
  (props) =>
    props.type === LEFT_SIDEBAR_FRESHWORKS
      ? 'width: 40px; height: 40px;'
      : 'width: 82px;height: 82px;'
  }

  cursor: pointer;
  ${(props) =>
    props.type === LEFT_SIDEBAR_FRESHWORKS ? 'margin-bottom: 15px;' : ''}

  ${(props) =>
    props.type === LEFT_SIDEBAR_FRESHWORKS ? `&:first-child {
        margin-top: 8px;
      }`
      : (props.isTemplate === true || props.isSitePreview) ? `&:first-child {
        margin-top: 8px;
      }`
      : `&:last-child {
        margin-top: auto;
      }`
  }
  &:hover,
  &.active {
    ${(props) =>
    props.type === LEFT_SIDEBAR_FRESHWORKS ? 'border-radius: 22px;' : ''}
    ${(props) =>
    props.mode == 'DARK'
      ? backgroundColor({ themeing: 'dark' }, 800)
      : backgroundColor({ themeing: 'dark' }, 100)}

    // Overriding for colored active state
    ${(props) => {
    return ColoredActive === true
      ? props.mode == 'DARK'
        ? backgroundColor({ themeing: 'primary' }, 800)
        : backgroundColor({ themeing: 'primary' }, 100)
      : ``
  }}
  }

  // TODO: color value should be refactored
  &.active {
    ${() =>
    ColoredActive === true
      ? `border-right: 4px solid #044a75; color: #044a75;`
      : ``}
  }
`

const PanelIcon = styled(CvtIcon)`
  display: flex;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 24px;

  &.active {
  }
`

const getPanelName = () => styled('span')`
  ${textFontSize('sm')}
  ${textFontWeight('REGULAR')}
  ${textLineHeight('md')}
  text-transform: capitalize;
  margin-top: ${Spacing.SPACING_8}px;
`

export const CvtLeftSideBar = {
  name: 'CvtLeftSideBar',
  data () {
    return {
      sectionLoading: true
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT
    }),
    ...mapGetters({
      popupBuilderOpen: getters.POPUP_BUILDER_OPEN,
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      iconConfig: ({ iconConfig }) => iconConfig,
      topNavConfig: ({ topNavConfig }) => topNavConfig,
      ColoredActive: ({ globalTheme }) =>
        globalTheme.LeftSideBarColoredActive !== undefined
          ? globalTheme.LeftSideBarColoredActive
          : false,
      LeftSidebarWidth: ({ globalTheme }) =>
        globalTheme.LeftSidebarWidth !== undefined
          ? globalTheme.LeftSidebarWidth
          : "82"
    }),
    inFreeTrialMode() {
      return this.config?.TRIAL_MODE
    },
    sideBarHeight(){
      return this.inFreeTrialMode ? '130' : '64'
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
    route: {
      type: String,
      default: 'landing-page'
    },
    panels: {
      type: Array,
      default: []
    },
    activePanel: {
      type: String,
      default: ''
    },
    sideBarLeft: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: LEFT_SIDEBAR_STANDARD
    },
    isTemplate: {
      type: Boolean,
      default: false
    },
    isSitePreview: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click (eventName, e) {
      let panel = '';

      if (this.activePanel != e.currentTarget.id) {
        panel = e.currentTarget.id
      }

      this.$emit(eventName, e)
      this.$emit('panel-toggle', panel)
    }
  },
  mounted () {
    // This is some tricky logic which works well for all tenants, this works fine except freshworks
    // as freshworks left-side menu displays the pages actions in popover format and popover HTML
    // resides in root level but this logic searches the clicked element in <aside> (left sidebar) HTML
    // and it is then unable to find the clicked element in left-sidebar so it does not works well
    // with freshworks tenant but works fine with all other tenants.
    window.addEventListener('click', (e) => {
      if (this.$refs.sidebar && !this.$refs.sidebar.contains(e.target)) {
        this.$emit('panel-toggle', '');
      }
    })
  },
  created () {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    color = this.textColor
    textFontWeight = this.textFontWeight
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    backgroundColor = this.backgroundColor
    ColoredActive = this.ColoredActive
    LeftSidebarWidth = this.LeftSidebarWidth
  },

  render: function (h) {
    const SideBar = getSideBar()
    const PanelContainer = getPanelContainer()
    const Panel = getPanel()
    const PanelName = getPanelName()
    const panels = []
    const iconConfig = this.iconConfig?.leftSidebar?.root

    for (const panel of this.panels) {
      if (panel.page !== 'all' && panel.page !== this.route) {
        continue
      }
      panels.push(
        <Panel
          class={{ active: this.activePanel == panel.name }}
          mode={this.mode}
          id={panel.name}
          type={this.type}
          isTemplate={this.isTemplate}
          isSitePreview={this.isSitePreview}
          onClick={(e) => this.click(panel.name, e)}
        >
          <PanelIcon icon={iconConfig[panel.name] || panel.icon}></PanelIcon>

          {this.type !== LEFT_SIDEBAR_FRESHWORKS && (
            <PanelName>{panel.label}</PanelName>
          )}
        </Panel>
      )
    }

    const panelContainer = (
      <PanelContainer mode={this.mode} type={this.type} width={this.LeftSidebarWidth}>
        {panels}
      </PanelContainer>
    )
    return (
      <SideBar
        ref='sidebar'
        mode={this.mode}
        sideBarLeft={this.sideBarLeft}
        type={this.type}
        sideBarHeight={this.sideBarHeight}
        marginTop={
          // TODO: Why is a topNavConfig affecting a sidebar?
          this.topNavConfig.theme?.height
            ? this.topNavConfig.theme?.height
            : this.Spacing.SPACING_64
        }
      >
        {this.sideBarLeft && panelContainer}

        <div
          style={{
            display: this.activePanel === 'elements' ? 'block' : 'none'
          }}
        >
          {this.$slots.elements}
        </div>
        {this.activePanel == 'sections' && this.$slots.sections}
        {this.activePanel == 'layout' && this.$slots.layouts}
        {this.activePanel == 'pages' && this.$slots.pages}
        {this.activePanel == 'ssd' && this.$slots.ssd}
        {this.activePanel == 'genai' && this.$slots.genAi}
        {this.activePanel == 'image-gallery' && this.$slots.imageGallery}
        {this.activePanel == 'popups' && this.$slots.popups}
        {this.$slots.default}
        {!this.sideBarLeft && panelContainer}
      </SideBar>
    )
  }
}
