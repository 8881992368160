
<div class="users-container">
  <div class="users-heading-wrapper">
    <h1 class="users-heading-label">Team</h1>
    <div>
    <cvt-button
      text="Add Team Member"
      color="''"
      :mode="'primary'"
      icon="plus"
      class="ent-users-primary-btn"
      @click="() => toggleNewTeamModal(true)"
    />
  </div>
  </div>
  <div v-if="itMembers.length" class="users-body">
    <table  class="ent-dashboard-users-table">
      <thead>
        <tr>
          <th class="ent-dashboard-users-table-head">
            Name
          </th>
          <th class="ent-dashboard-users-table-head">
            Role
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(user, idx) in itMembers"
          :key='idx'
        >
          <td>
            <div>
              <p>{{ user.name }}</p>
              <p class="ent-dashboard-users-email">{{ user.email }}</p>
            </div>
          </td>
          <td>
            <div class="ent-dashboard-users-table-data"

            >
              <p>{{ user.role }}</p>
              <div
                :key='idx'
                @mouseover="onShowMenuOption(idx)"
                @mouseleave="onShowMenuOption(null)"
              >
                <cvt-icon
                  icon="ellipsis-h"
                />
                <div
                  v-if="currentIdx == idx"
                  class="ent-dashboard-user-menu-wrapper">
                  <div
                    class="user-menu-option"
                    @click="() => {onResendInvite(user.userID)}">
                    <cvt-icon
                      icon="envelope"
                    />
                    <p>Resend Invitation</p>
                  </div>
                  <div
                    class="user-menu-option"
                    @click="() => { onRemoveUser(user.email) }">
                    <cvt-icon
                      icon="trash"
                    />
                    <p>Remove User</p>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
  <div v-else class="empty-user-wrapper">
        <p> No User Available</p>
    </div>
  <div
    v-if="showNewTeamModal"
    class="ent-dashboard-new-users-container"
  >
    <div
      class="ent-dashboard-users-backdrop"
      @click="() => toggleNewTeamModal(false)"
    ></div>
    <cvt-invite-new-user-modal
      :loading="loading"
      @onTogggleModal="toggleNewTeamModal"
      @onCreateNewTeam="sendInvite"
    ></cvt-invite-new-user-modal>
  </div>
  </div>
