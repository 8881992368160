
<GenAILayout>
  <div class="landing-page-description-wrapper">
    <div class="landing-page-description-inner-wrapper">
      <h1 class="landingpage-description-header">
        {{ $t('gen_ai.business_description.header.text') }}
      </h1>
      <div class="landingpage-description-textbox-wrap">
        <p class="landingpage-description-textbox-header">
          {{ $t('gen_ai.business_description.header.subtext') }}
        </p>
        <p>[{{ $t('gen_ai.business_description.textbox.label') }}]</p>
        <cvt-input
          :placeholder="$t('gen_ai.business_description.textbox.placeholder')"
          type="textarea"
          :value="description"
          rows="6"
          :required="true"
          @input="(e) => onChangeDescription(e.target.value)"
        />
        <div
          :class="{
            'landingpage-description-help-text': true,
            error: !formError.isformValid,
          }"
        >
          <i class="fa fa-info-circle"></i>
          <span>{{ formError.descriptionMsg }}</span>
        </div>
      </div>

      <hr />
      <div class="landingpage-description-btn-wrap">
        <cvt-button
          class="landingpage-description-btn"
          :text="$t('custom_domain.ui.unlink_dialog.buttons.continue')"
          shape="round"
          color="primary"
          :disabled="disabled"
          icon="arrow-right"
          :prepend="false"
          @click="gotToIndustrySelection"
        ></cvt-button>
      </div>
    </div>
  </div>
</GenAILayout>
