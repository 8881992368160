/* eslint-disable vue/one-component-per-file */
// DEPRECATED
import Vue from 'vue'

const IconClass = Vue.extend({
  props: {
    selector: {
      type: String,
      required: true,
    },
  },
})

const FontAwesomeIcon = IconClass.extend({
  template: '<i class="fa" :class="selector"></i>',
})

const ThemifyIcon = IconClass.extend({
  template: '<i :class="selector"></i>',
})

const MaterialIcon = IconClass.extend({
  template: '<i class="material-icons" v-text="selector"></i>',
})

const components = {
  FontAwesomeIcon,
  ThemifyIcon,
  MaterialIcon,
}

export default Vue.extend({
  components,
  props: {
    set: {
      type: String,
      required: true,
    },
    selector: {
      type: String,
      required: true,
    },
  },
  render(h) {
    return h(
      this.set,
      {
        props: {
          selector: this.selector,
        },
      },
      this.$slots.default,
    )
  },
})
