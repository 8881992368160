
  <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" class="guides">
    <defs>
      <pattern
:id="`${gridVNodeId}-pattern`" :width="patternWidth" :height="patternHeight"
               patternUnits="userSpaceOnUse">
        <rect
:width="patternWidth - patterRectWidthDifference"
              :height="patternHeight - patterRectHeightDifference"
              :fill="boxColor" class="gridbox"></rect>
      </pattern>
    </defs>
    <rect width="100%" height="100%" :fill="`url(#${gridVNodeId}-pattern)`"></rect>
  </svg>
