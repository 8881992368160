<template>
  <div>
    <h1>Alert</h1>
    <cvt-alert
      v-for="(color, key) in colors"
      :key="key"
      :color="color"
      :close-icon="true"
    >
      {{ key }}
    </cvt-alert>
  </div>
</template>
<script>
export default {
  name: 'PlayAlert',
  components: {
  },
  data () {
    return {
      colors: {
        'Default': '',
        'Success': 'success',
        'Danger': 'danger',
        'Warning': 'warning',
        'Info': 'info',
        'Primary': 'primary',
      },
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
  },
}
</script>
<style lang="scss">
</style>
