import * as _ from 'lodash'

function findRecurs(list = [], predicate) {
  let match
  for (let idx in list) {
    let node = list[idx]

    if (predicate(node)) {
      return node
    }

    if (node && node.children && node.children.length > 0) {
      match = findRecurs(node.children, predicate)
    }

    if (match) {
      return match
    }
  }
}

function findAllRecurs(list = [], predicate) {
  let matches = []
  for (let idx in list) {
    let node = list[idx]

    if (predicate(node)) {
      matches.push(node)
    }

    if (node.children && node.children.length > 0) {
      matches.push(...findAllRecurs(node.children, predicate))
    }
  }
  return matches
}

_.mixin({
  findRecurs,
  findAllRecurs,
})
