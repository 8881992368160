
<div class="visibility-controls">
  <div class="row mt-2">
    <div class="col">
      <cvt-alert
        color="primary"
        icon="info-circle"
      >
        {{ $t('elements.properties.visibility.header') }}
        <span class="help">
          <br>
          {{ $t("elements.properties.visibility.message") }}
        </span>
      </cvt-alert>
    </div>
  </div>

  <cvt-radio-group
    v-if="!VisibilitySwitch"
    orientation="vertical"
    :initial-value="initialValue"
  >
    <cvt-alt-radio
      size="md"
      value="both"
      :shape="RadioButtonShape"
      :border="false"
      @radioClick="() => showVisibility('both')"
    >
      Show in desktop & mobile
    </cvt-alt-radio>
    <cvt-alt-radio
      size="md"
      value="desktop-only"
      :shape="RadioButtonShape"
      :border="false"
      @radioClick="() => {
        showVisibility('showDesktop')
      }"
    >
      Show in desktop only
    </cvt-alt-radio>
    <cvt-alt-radio
      size="md"
      value="mobile-only"
      :shape="RadioButtonShape"
      :border="false"
      @radioClick="() => {
        showVisibility('showMobile')
      }"
    >
      Show in mobile only
    </cvt-alt-radio>
  </cvt-radio-group>

  <div v-else>
    <div
      class="row mt-2"
    >
      <div class="col">
        <h6 :class="textColorClass">
          {{ $t("elements.properties.visibility.mobile") }}
        </h6>
      </div>
      <div class="col-3">
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="hideOnMobile"
          :disabled="hideOnDesktop"
          :mode="mode"
          @switchToggle="() => onSwitchToggle('hideOnMobile')"
        />
      </div>
    </div>

    <div class="divider" />

    <div class="row mt-2">
      <div class="col">
        <h6 :class="textColorClass">
          {{ $t("elements.properties.visibility.desktop") }}
        </h6>
      </div>
      <div class="col-3">
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="hideOnDesktop"
          :disabled="hideOnMobile"
          :mode="mode"
          @switchToggle="() => onSwitchToggle('hideOnDesktop')"
        />
      </div>
    </div>
  </div>
</div>
