//@ts-nocheck
import { ELEMENT_DROPPED_ON_SUB_GRID_CONTAINER } from '@/js/plugins/events.js'
import { GridStack } from 'gridstack'
import * as _ from 'lodash'
import { mapGetters, mapState } from 'vuex'
import {
  BrandLogo,
  CButton,
  CForm,
  CImage,
  CodeSnippet,
  CVideo,
  Divider,
  Draft,
  Icon,
  CountdownTimer,
  Checkout,
} from '../../../../lib/helper-classes'
import * as getters from '../../../../store/modules/getters'
import FbVNode from '../../../base/FbVNode.vue'
import AddElementPopover from '.././AddElementPopover.vue'
import DragItems from '.././DragItems.vue'
import BuilderVisualBox from "../BuilderBox/VisualBox.vue"
import { CEmbeddedForm } from "../../../../lib/custom-component-descriptors/cembedded-form";
import color from "color";

export default FbVNode.extend({
  name: 'SubGridContainer',
  components: {
    AddElementPopover,
    DragItems,
    'visual-box': BuilderVisualBox
  },
  props: {
    size: Number,
    sizePhone: Number,
    offset: Number,
    flexAlignment: Object,
    borderRadius: Number,
    borderWidth: Number,
    borderColor: String,
    borderStyle: String,
    borderPosition: Object,
    shadow: {
      type: String,
      default: 'shadow-none',
    },
  },

  data() {
    return {
      name: 'SubGridContainer',
      dzCenter: true,
      emptyStateClass: 'col--is-empty',
      hasItemsInSlot: false,
      observer: null,
      resizeObserver: null,
      rotatePlusClose: false,
      isDragIn: false,
      cellHeight: 12,
      column: 12,
      subGridOption: {
        itemClass: 'sub', // style sub items differently and use to prevent dragging in/out
        // acceptWidgets: '.grid-stack-item.sub', // only pink sub items can be inserted, otherwise grid-items causes all sort of issues
        // minWidth: 300, // min to go 1 column mode
        // column: 4, // make sure to include gridstack-extra.min.css
        margin: 0,
        float: true,
        dragIn: '.newWidget',
        dragInOptions: {
          revert: 'invalid',
          scroll: false,
          appendTo: 'body',
          helper: 'clone',
          handle: '.grid-stack-item-content',
        },
        styleInHead: true,
        placeholderClass: 'cvt-grid-stack-placeholder',
        resizable: {
          handles: 'e, se, s, sw, w, n, ne, nw',
        },
        staticGrid: false,
        disableOneColumnMode: true,
      },
    }
  },
  computed: {
    ...mapGetters({
      targetsRow: getters.SELECTOR_GET_TARGET_ROW,
      dropzonesActive: getters.DROPZONE_ACTIVE,
      viewMode: getters.VIEW_MODE,
    }),
    ...mapState('globalTheme', {
      elementDesktopSize: ({ elementSize }) =>
        elementSize.gridContainerStackDefaultSizeForDesktop,
      elementPhoneSize: ({ elementSize }) =>
        elementSize.gridStackDefaultSizeForPhone,
    }),
    styles() {
      let { image } = this.background
      let backgroundImagePositionCalculated = this.getBackgroundImagePosition()
      let bgColor = this.calcBgColor()

      return {
        containerBorderRadius: {
          borderRadius: `${this.borderRadius || 0}px`,
        },

        bgImage: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          backgroundImage: image && `url(${image})`,
          backgroundPosition: backgroundImagePositionCalculated,
          backgroundRepeat: this.background.repeat
            ? this.background.repeat
            : 'no-repeat',
          backgroundSize: this.background.size ? this.background.size : '',
          borderRadius: `${this.borderRadius || 0}px`,
        },

        bgColor: { ...bgColor },

        content: {
          width: '100%',
          color: this.fontColorHEX,
          ...this.spacing.padding.toObject(),
          ...this.spacing.margin.toObject(),
          ...this.formColorTransform,
        },
        bgEmpty: {
          backgroundImage: `url(/img/box-container-placeholder-.svg)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
        }
      }
    },
    contentClasses() {
      return {
        [this.stylesheet.classes.content]: true,
        ...this.flexAlignmentClasses,
      }
    },
    localClasses() {
      let bsCol = this.size ? `col-md-${this.size}` : 'col-md'
      let bsColSM = this.sizePhone ? `col-sm-${this.sizePhone}` : 'col-sm'
      return {
        [bsCol]: true,
        [bsColSM]: true,
        'col-12': true,
        'd-flex': true,
        [`subgrid-${this.viewMode}`]: true,
        [this.bootstrapAlignment]: true,
        [`${this.stylesheet.classes.root}`]: true,
        [this.shadow]: true,
        /* commented flex-properties because these were not being utilized as false value
        were present for these which makes these two properties useless
        [this.parent()[this.viewMode]['flexVerticalAlign']]: false,
        [this.parent()[this.viewMode]['flexHorizonAlign']]: false, */
        [`${this.stylesheet.classes.containerBorderRadius}`]: true,
      }
    },
    elements() {
      return [
        {
          icon: 'cvt-icon-text',
          name: 'text',
          label: 'Text',
          type: 'Text',
          id: 'text_element',
        },
        {
          icon: 'cvt-icon-image',
          name: 'image',
          label: 'Image',
          type: 'Image',
          id: 'image_element',
        },
        {
          icon: 'cvt-icon-button',
          name: 'button',
          label: 'Button',
          type: 'Button',
          id: 'button_element',
        },
        {
          icon: 'cvt-icon-divider',
          name: 'divider',
          label: 'Divider',
          type: 'Divider',
          id: 'divider_element',
        },
        {
          icon: 'cvt-icon-form',
          name: 'form',
          label: 'Form',
          type: 'Form',
          id: 'form_element',
        },
        {
          icon: 'cvt-icon-icons',
          name: 'icon',
          label: 'Icon',
          type: 'Icon',
          id: 'icon_element',
        },
        {
          icon: 'cvt-icon-video',
          name: 'video',
          label: 'Video',
          type: 'Video',
          id: 'video_element',
        },
        {
          icon: 'cvt-icon-logo',
          name: 'logo',
          label: 'Logo',
          type: 'Logo',
          id: 'logo_element',
        },
      ]
    },
    //font color based on the backgorund color selected
    fontColorBG() {
      let colr = ''
      if (this.fontColorHEX) {
        colr = 'color: ' + this.fontColorHEX + ';'
      }
      return colr
    },

    //Whether the background image or color to take the priority
    subContainerBgColor() {
      return this.stylesheet.classes.bgColor
    },

    subContainerBgImage() {
      return this.stylesheet.classes.bgImage
    },

    backgroundImgExist() {
      return this.background?.image != null && this.background?.image !== ''
    },
    isBackgroundColorDark() {
      const appliedBackgroudColor = this.appliedBackgroudColor()
      if (appliedBackgroudColor) {
        let appliedBgColor = color(appliedBackgroudColor)

        if (appliedBgColor.alpha() < 0.4 || appliedBgColor.lightness() >= 60) {
          return false
        }

        if (appliedBgColor.lightness() < 60) {
          return true
        }
      }

      return false
    }
  },
  methods: {
    getGrid() {
      return this.$refs.nestedGridStackElement?.gridstack
    },
    getDefaultSize(elementId, viewMode = 'desktop') {
      const elementSize =
        viewMode === 'desktop' ? this.elementDesktopSize : this.elementPhoneSize
      return elementSize[elementId]
    },
    getElementFromId(id) {
      let element = null
      switch (id) {
        case 'text_element':
          element = new Draft()
          break
        case 'button_element':
          element = new CButton()
          break
        case 'image_element':
          element = new CImage()
          break
        case 'divider_element':
          element = new Divider()
          break
        case 'form_element':
          element = new CForm()
          break
        case 'icon_element':
          element = new Icon()
          break
        case 'video_element':
          element = new CVideo()
          break
        case 'logo_element':
          element = new BrandLogo()
          break
        case 'code_element':
          element = new CodeSnippet()
          break
        case 'countdown_timer_element':
          element = new CountdownTimer()
          break
        case 'checkout_element':
          element = new Checkout()
          break
        case 'embed_form_element':
          element = new CEmbeddedForm()
          break
        default:
          break
      }
      return element
    },
    dragStart(event, element) {
      this.isDragIn = true
      this.$refs.nestedGridStackElement.classList.add('show-grid')
      this.getGrid().engine.setIsDragging(true)
    },
    dragStop(event, element) {
      this.isDragIn = false
      // `this` will only access your Vue instance if you used an arrow function, otherwise `this` binds to window scope. see https://hacks.mozilla.org/2015/06/es6-in-depth-arrow-functions/
      this.$refs.nestedGridStackElement.classList.remove('show-grid')
      this.getGrid().engine.setIsDragging(false)
    },
    resizeStart(e, el) {
      this.getGrid().engine.setIsDragging(false)
      this.itemResizing = true
      this.$refs.nestedGridStackElement.classList.add('show-grid')
    },
    resizeStop(e, el) {
      this.itemResizing = false
      this.$refs.nestedGridStackElement.classList.remove('show-grid')
    },
    onClick(e) {
      if (e.target && e.target.parentElement === this.$refs.subGridWrapper) {
        this.edit(e)
      }
    },
    change: _.debounce(function (e, items) {
      if (items.length > 0) {
        items.forEach((changedItem) => {
          const vNodeID = changedItem.el.dataset.vnodeId || 0
          this.$children.forEach((child) => {
            if (child.vnodeId === vNodeID) {
              const newProps = Object.assign(child.$props[this.viewMode], {
                dataX: changedItem.x,
                dataY: changedItem.y,
                dataWidth: changedItem.w,
                dataHeight: changedItem.h,
              })
              child.updateProp(this.viewMode, newProps)

              if (
                child.$children.length > 0 &&
                child.$children[0].name === 'Icon'
              ) {
                const remValue = Math.floor(
                  (child.$el.offsetWidth * 1) / 12.48,
                )
                child.$children[0].updateProp('size', remValue)
              }
            }
          })
        })
      }
    }, 500),
    getGridHeight() {
      return this.getGrid().el.getBoundingClientRect().height
    },
    getGridCellHeight() {
      return this.getGrid().getCellHeight()
    },
    fixOptions(options, viewMode){
      return options
    },
    dropped(e, prevWidget, newWidget) {
      // if drop is coming from dragItem, then ~ is a separator key for getting the element_id
      if (prevWidget && newWidget && this.handleDragFromAnotherSource) {
        this.handleDragFromAnotherSource(prevWidget, newWidget)
        return
      }
      const newElementId = newWidget.el.id.toString().split('~')[0]
      const defaultElementSize = this.getDefaultSize(`${newElementId}`)
      const defaultMobileElementSize = this.getDefaultSize(
        `${newElementId}`,
        'phone',
      )

      const desktopOptions = {
        dataX: newWidget.x,
        dataY: newWidget.y,
        dataWidth: defaultElementSize.w || 1,
        dataHeight: defaultElementSize.h || 1,
        flexHorizonAlign: 'justify-content-center',
        flexVerticalAlign: 'align-items-center',
      }
      let mobileOptions = Object.assign({}, desktopOptions)
      mobileOptions.dataHeight = defaultMobileElementSize.h || 1
      mobileOptions.dataWidth = defaultMobileElementSize.w || 1

      const opts = {
        phone: mobileOptions,
        desktop: desktopOptions,
      }
      // this is fix item positioning on view mode that is not activate
      const alternatedViewMode = this.getAlternatedViewMode()
      opts[alternatedViewMode] = this.fixOptions(opts[alternatedViewMode], alternatedViewMode)
      opts[alternatedViewMode].dataX = 0  // set X co-ordinate to 0 for view mode that is not activate

      const vNode = this.getElementFromId(newElementId)
      this.addGridItem(vNode, opts)

      // Remove cloned element from grid
      this.getGrid().removeWidget(newWidget.el)

      // $hub is event-bus
      this.$hub.$emit(ELEMENT_DROPPED_ON_SUB_GRID_CONTAINER, {
        data: newWidget,
      })
      this.getGrid().engine.setIsDragging(false)
    },
    addItem(id, btnId) {
      // ELEMENT_SIZE - from Spanner config
      // For Width and Height
      const defaultElementSize = this.getDefaultSize(`${id}`)
      const defaultMobileElementSize = this.getDefaultSize(`${id}`, 'phone')
      const addItemControl = document.getElementById(btnId)
      if (addItemControl) {
        addItemControl.click()
      }
      const desktopOptions = {
        dataX: 0,
        dataY: 0,
        dataWidth: defaultElementSize.w || 1,
        dataHeight: defaultElementSize.h || 1,
        flexHorizonAlign: 'justify-content-center',
        flexVerticalAlign: 'align-items-center',
      }
      let mobileOptions = Object.assign({}, desktopOptions)
      mobileOptions.dataHeight = defaultMobileElementSize.h || 1
      mobileOptions.dataWidth = defaultMobileElementSize.w || 1

      const opts = {
        phone: mobileOptions,
        desktop: desktopOptions,
      }
      const vNode = this.getElementFromId(id)
      this.addGridItem(vNode, opts)
    },
    addGridItem(vNode, options) {
      if (['grid-box-container', 'sub-grid-container'].includes(vNode.tag)) {
        return
      }

      // this function set some default values for flex-alignment using
      // config object that config object is set via configDefaults table
      // as well as organizationsConfig table, organizationConfig table entry
      // overrides the entry of configDefaults table
      this.setOrgSpecificDefaults(vNode, options)

      // initial option for widgets should be same on the mobile and desktop
      // Since it's a smaller grid and ideally should be treated as a single column grid
      let widget = this.wrapInGridItemWrapper(vNode, options)
      this.push(widget).then((a) => {
        // getGrid().makeWidget - converts an HTML to a widget
        this.getGrid().makeWidget(this.$children[this.$children.length - 1].$el)
        this.$children[this.$children.length - 1].$el.scrollIntoView({
          behavior: 'smooth',
        })
      })
    },
    onElChange() {
      this.hasItemsInSlot = !!this.$slots.default
    },
    updateBgSrc(assets) {
      if (!assets) return
      this.updateProp(
        'background/image',
        assets.block ? assets.block : assets.pop(),
      )
    },
    getBackgroundImagePosition() {
      if (
        this.background.imagePosition &&
        this.background.imagePosition.calcPos &&
        this.background.imagePosition.calcPos.x != null &&
        this.background.imagePosition.calcPos.y != null
      ) {
        return `${this.background.imagePosition.calcPos.x}% ${this.background.imagePosition.calcPos.y}%`
      } else {
        return 'center center'
      }
    },

    calcBgColor() {
      let defaultBorder = `${this.borderWidth || 0}px ${this.borderStyle} ${
        this.borderColor
      }`
      let bgColorApplied = this.appliedBackgroudColor()

      let defaultStyle = {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        backgroundColor: bgColorApplied,
        borderRadius: `${this.borderRadius || 0}px`,
      }

      if (this.borderPosition && Object.keys(this.borderPosition).length) {
        //Set the selections
        if (this.borderPosition.all) {
          defaultStyle['border'] = defaultBorder
        }
        if (this.borderPosition.top) {
          defaultStyle['borderTop'] = defaultBorder
        }
        if (this.borderPosition.btm) {
          defaultStyle['borderBottom'] = defaultBorder
        }
        if (this.borderPosition.lft) {
          defaultStyle['borderLeft'] = defaultBorder
        }
        if (this.borderPosition.ryt) {
          defaultStyle['borderRight'] = defaultBorder
        }
      } else {
        defaultStyle['border'] = defaultBorder
      }

      return defaultStyle
    },

    appliedBackgroudColor() {
      //Overlay background color
      if (this.background.overlay && this.overlayColorHSL) {
        return this.overlayColorHSL
      }

      //Background color
      return this.themeColorHSL
    },
    registerGridStackEvents() {
      if (this.getGrid()) {
        this.getGrid().on('dragstop', this.dragStop)
        this.getGrid().on('dragstart', this.dragStart)

        this.getGrid().on('change', this.change)
        this.getGrid().on('resizestart', this.resizeStart)

        this.getGrid().on('dropped', this.dropped)
        this.getGrid().on('resizestop', this.resizeStop)
      }
    },
    unRegisterGridStackEvents() {
      if (this.getGrid()) {
        this.getGrid().off('dragstop')
        this.getGrid().off('dragstart')

        this.getGrid().off('change')
        this.getGrid().off('resizestart')

        this.getGrid().off('resizestop')
        this.getGrid().off('dropped')
      }
    },
    setOrgSpecificDefaults(vNode, options) {
      try {
        const defaults = this.config.FLEX_ALIGNMENT_DEFAULTS

        if (defaults?.image && vNode.tag === 'c-image') {
          options.desktop.flexHorizonAlign =
            defaults?.image?.desktop?.flexHorizonAlign ||
            'justify-content-start'
          options.desktop.flexVerticalAlign =
            defaults?.image?.desktop?.flexVerticalAlign || 'align-items-start'

          options.phone.flexHorizonAlign =
            defaults?.image?.phone?.flexHorizonAlign || 'justify-content-start'
          options.phone.flexVerticalAlign =
            defaults?.image?.phone?.flexVerticalAlign || 'align-items-start'
        } else if (defaults?.logo && vNode.tag === 'brand-logo') {
          options.desktop.flexHorizonAlign =
            defaults?.logo?.desktop?.flexHorizonAlign ||
            'justify-content-center'
          options.desktop.flexVerticalAlign =
            defaults?.logo?.desktop?.flexVerticalAlign || 'align-items-center'

          options.phone.flexHorizonAlign =
            defaults?.logo?.phone?.flexHorizonAlign || 'justify-content-center'
          options.phone.flexVerticalAlign =
            defaults?.logo?.phone?.flexVerticalAlign || 'align-items-center'
        } else if (defaults?.icon && vNode.tag === 'icon') {
          options.desktop.flexHorizonAlign =
            defaults?.icon?.desktop?.flexHorizonAlign ||
            'justify-content-center'
          options.desktop.flexVerticalAlign =
            defaults?.icon?.desktop?.flexVerticalAlign || 'align-items-center'

          options.phone.flexHorizonAlign =
            defaults?.icon?.phone?.flexHorizonAlign || 'justify-content-center'
          options.phone.flexVerticalAlign =
            defaults?.icon?.phone?.flexVerticalAlign || 'align-items-center'
        } else if (defaults?.form && vNode.tag === 'c-form') {
          options.desktop.flexHorizonAlign =
            defaults?.form?.desktop?.flexHorizonAlign ||
            'justify-content-center'
          options.desktop.flexVerticalAlign =
            defaults?.form?.desktop?.flexVerticalAlign || 'align-items-center'

          options.phone.flexHorizonAlign =
            defaults?.form?.phone?.flexHorizonAlign || 'justify-content-center'
          options.phone.flexVerticalAlign =
            defaults?.form?.phone?.flexVerticalAlign || 'align-items-center'
        } else if (defaults?.text && vNode.tag === 'draft') {
          options.desktop.flexHorizonAlign =
            defaults?.text?.desktop?.flexHorizonAlign ||
            'justify-content-center'
          options.desktop.flexVerticalAlign =
            defaults?.text?.desktop?.flexVerticalAlign || 'align-items-center'

          options.phone.flexHorizonAlign =
            defaults?.text?.phone?.flexHorizonAlign || 'justify-content-center'
          options.phone.flexVerticalAlign =
            defaults?.text?.phone?.flexVerticalAlign || 'align-items-center'
        }
      } catch (e) {
        console.debug('Error In Setting Defaults To Elements', e)
      }
    },
    reloadContent() {
      if (this.$slots.default) {
        this.getGrid().removeAll(true)
        this.$nextTick(() => {
          this.$slots.default.forEach((x) => {
            this.getGrid().makeWidget(x.componentInstance.$el)
            x.componentInstance.$el.scrollIntoView({
              behavior: 'smooth',
            })
          })
        })
      }
    },
    registerObserver() {
      const observer = new MutationObserver(this.onElChange)
      observer.observe(this.$el, {
        childList: true,
        subtree: true,
      })
      this.observer = observer
    },
    mouseOverAction (e: MouseEvent) {
      e.stopPropagation()
      this.$parent.$parent.$el.classList.add('hide-grid-lines')
      this.$refs.vsBox.$el.classList.add('show-grid')
    },
    mouseLeaveAction (e: MouseEvent) {
      e.stopPropagation()
      this.$parent.$parent.$el.classList.remove('hide-grid-lines')
      this.$refs.vsBox.$el.classList.remove('show-grid')
    },
    initGridBuilderHandler({reload=false, skipEvent=false}) {
      if (!this.$refs.nestedGridStackElement) {
        return
      }
      if (this.viewMode === 'phone') {
        this.column = 8
        this.cellHeight = 8
      }
      const gridOptions = Object.assign({}, this.subGridOption, {
        acceptWidgets: `.grid-stack-item-sub-${this.vnodeId}`,
        dragInOptions: {
          revert: 'invalid',
          scroll: false,
          appendTo: `[data-vnode-id='${this.vnodeId}']`,
          helper: 'clone',
          handle: '.grid-stack-item-content',
          staticGrid: this.config.GRID_BUILDER_STATIC_MODE_ENABLED || false
        },
        column: this.column,
        cellHeight: this.cellHeight,
      })
      GridStack.addGrid(this.$refs.nestedGridStackElement, gridOptions)
      this.getGrid().rootComponent = this.$refs.subGridWrapper
      this.registerObserver() // only for v2

      this.$refs.nestedGridStackElement.style.setProperty(
          '--sub-grid-cell-height',
          gridOptions.cellHeight + 'px',
      )
      // this.gridElement.style.setProperty('--grid-cell-height', this.getGrid().cellWidth() + 'px')

      // necessary to re-render slots objects as gridstackitem widget if any exist
      // this only happens on page reload
      if (reload) this.reloadContent()
      if (!skipEvent) this.registerGridStackEvents()

      this.itemResizing = false
    }
  },
  beforeUpdate() {
    this.unRegisterGridStackEvents()
  },
  updated() {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      this.initGridBuilderHandler({reload: false, skipEvent: false})
    }, 400)
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.$nextTick(() => {
      let timeoutKey = setTimeout(() => {
        // eslint-disable-next-line no-undef
        this.initGridBuilderHandler({reload: true, skipEvent: false})
        clearTimeout(timeoutKey)
      }, 400)
    })
  },
  beforeUnmount() {
    this.observer.disconnect()
  },
  beforeDestroy() {
    this.getGrid()?.destroy()
  }
})
