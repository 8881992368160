
<div>
  <div>
    <div>
      <cvt-admin-left-nav-bar
        style="top: 64px"
        initial-active-id="documentation"
        :mode="mode"
      >
      </cvt-admin-left-nav-bar>
    </div>
    <div class="main-content">
      <iframe class="iframe-content" frameborder=0 src="https://developers.convrrt.com/"></iframe>
    </div>
  </div>
</div>
