
<el-tooltip
  :content="tip"
  placement="top"
>
  <anime
    :enter="enter"
    :leave="leave"
    :before-enter="beforeEnter"
  >
    <div v-if="OrgName === standard">
      <i
        v-if="!marked"
        class="material-icons"
        @click.prevent.stop="mark"
      >delete</i>
      <i
        v-else
        class="material-icons delete-forever"
        @click.prevent.stop="deleteForever"
      >delete_forever</i>
    </div>
    <div v-else>
      <div
        v-if="!marked"
        @click.prevent.stop="mark"
      >
        <cvt-icon
          :icon="iconConfig.propertiesSidebar.remove || 'trash'"
        />
      </div>
      <div
        v-else
        @click.prevent.stop="deleteForever"
      >
        <cvt-icon
          style="color: red;"
          :icon="iconConfig.propertiesSidebar.remove || 'trash'"
        />
      </div>
    </div>
  </anime>
</el-tooltip>
