
  <ContainerAbstractTemplate
    :classes="classes"
    :show-context-menu="showContextMenu"
    :mouse-leave-action="mouseLeaveAction"
    :mouse-over-action="mouseOverAction"
    :container-bg-color="ContainerBgColor"
    :container-bg-image="ContainerBgImage"
    :v-node-id="vnodeId"
    :style="dynamicWidth"
    :on-container-click="onClick"
  >
    <div :class="contentClasses">
      <CardTemplate
v-if="layout === 'left' || layout === 'center'"
                    :presentation="layout"
:layout-view="layoutView"
                    :template-key="fbTemplateKey"
                    :template="template"/>

      <NarrowCardTemplate
v-else-if="layout === 'narrow'"
                          :presentation="layout"
:layout-view="layoutView"
                          :template-key="fbTemplateKey"
                          :template="template"/>
      <div v-else>Unknown Presentation</div>
    </div>
  </ContainerAbstractTemplate>
