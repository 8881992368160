
<transition name="fade">
  <div v-show="visible" class="fullscreen-gallery">
    <div
      class="
        fullscreen-gallery__close
        d-flex
        justify-content-center
        align-items-center
      "
    >
      <i class="cvt-icon-delete" @click.prevent.stop="close"></i>
    </div>

    <div class="row text-center auto pt-5 bg-overlay">
      <div class="col">
        <h2>{{ $t('imagesearch.highres') }}</h2>
        <p>
          <i>{{ $t('imagesearch.cta.text') }}</i>
        </p>
      </div>
    </div>

    <div
      class="
        d-flex
        p-3
        justify-content-center
        align-items-center
        flex-wrap
        bg-overlay
      "
    >
      <div class="image-search-input">
        <el-input
          v-model="query"
          placeholder="Search the gallery"
          icon="search"
          size="large"
          autofocus
          :on-icon-click="runSearch"
          @keyup.enter.native="runSearch"
          @change="runSearch"
        >
        </el-input>
      </div>
    </div>

    <div
      class="
        d-flex
        p3
        justify-content-center
        align-items-center
        flex-wrap
        bg-overlay
      "
    >
      <h6
        v-for="(tag) in tags"
        :key="tag"
        class="badge-wrapper mr-2"
        @click.prevent.stop="activate(tag)"
      >
        <span
          class="badge badge-pill"
          :class="active(tag)"
          v-text="tag"
        ></span>
      </h6>
    </div>

    <transition-group
      name="fade"
      class="mx-5 mt-3 fullscreen-gallery__list"
      tag="div"
    >
      <image-search-item
        v-for="(image) in results"
        :key="image.id"
        :image="image"
        @selected="useImage"
      ></image-search-item>
    </transition-group>
  </div>
</transition>
