import { mapActions, mapGetters } from 'vuex'
import * as actions from '../../../../store/modules/actions'
import * as getters from '../../../../store/modules/getters'

export default {
  props: {},
  data() {
    return {
      loading: false,
      showNewTeamModal: false,
      showMenuOption: false,
      currentIdx: null,
    }
  },
  computed: {
    ...mapGetters({
      itMembers: getters.IT_TEAM,
    }),
  },
  methods: {
    ...mapActions({
      createNewMember: actions.CREATE_IT_MEMBER,
      fetchNewMembers: actions.FETCH_IT_TEAM,
      deleteITMember: actions.DELETE_IT_MEMBER,
      resendInvite: actions.RESEND_IT_INVITE,
    }),
    async sendInvite(data): Promise<void> {
      this.loading = true
      const payload = [data]
      await this.createNewMember(payload)
        .then((response) => {
          this.showNewTeamModal = false
          this.loading = false

          if (response.data[0].ok) {
            this.$message.info('Invite Sent successfuly')
            this.fetchNewMembers()
          } else {
            this.$message.info(response.data[0].error)
          }
        })
        .catch((err) => {
          this.showNewTeamModal = false
          this.loading = false
          this.$message.error(err.response.data.errors[0].message)
        })
    },
    toggleNewTeamModal(status: Boolean) {
      this.showNewTeamModal = status
    },
    onShowMenuOption(id) {
      this.currentIdx = id
    },
    async onResendInvite(id: String) {
      try {
        await this.resendInvite(id)
        this.$message.info('Invite Resent successfuly')
      } catch (err) {
        this.$message.error('An error occured.')
      }
    },
    async onRemoveUser(memberEmail: String) {
      try {
        const payload = {
          email: memberEmail,
        }
        await this.deleteITMember(payload)
        this.$message.info('User Removed successfuly')
      } catch (err) {
        this.$message.error('An error occured.')
      }
    },
  },
  created() {
    this.fetchNewMembers()
  },
}
