export const integrationsList = [
  {
    key: 'activeCampaign',
    image: 'https://cdn.convrrt.com/assets/integration-logos/active-campaign.png',
    complete: true,
    title: 'ActiveCampaign',
    description: 'Update contacts and deals in your sales CRM',
    new: false,
  },
  {
    key: 'airtable',
    image: 'https://cdn.convrrt.com/assets/integration-logos/airtable.png',
    complete: false,
    title: 'Airtable',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'asana',
    image: 'https://cdn.convrrt.com/assets/integration-logos/asana.png',
    complete: false,
    title: 'Asana',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'aweber',
    image: 'https://cdn.convrrt.com/assets/integration-logos/aweber.png',
    complete: false,
    title: 'Aweber',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'boxSign',
    image: 'https://cdn.convrrt.com/assets/integration-logos/box-sign.png',
    complete: false,
    title: 'Box Sign',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'box',
    image: 'https://cdn.convrrt.com/assets/integration-logos/box.png',
    complete: false,
    title: 'Box',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'brevo',
    image: 'https://cdn.convrrt.com/assets/integration-logos/brevo-logo.svg',
    complete: false,
    title: 'Brevo',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'campaignMonitor',
    image: 'https://cdn.convrrt.com/assets/integration-logos/campaign-monitor.png',
    complete: false,
    title: 'Campaign Monitor',
    description: 'Collect form information and add new contacts',
    new: true,
  },
  {
    key: 'cashApp',
    image: 'https://cdn.convrrt.com/assets/integration-logos/cash-app.png',
    complete: false,
    title: 'Cash App',
    description: 'Collect form information and add new contacts',
    new: true,
  },
  {
    key: 'clickBank',
    image: 'https://cdn.convrrt.com/assets/integration-logos/click-bank.png',
    complete: false,
    title: 'Click bank',
    description: 'Collect form information and add new contacts',
    new: true,
  },
  {
    key: 'clickUp',
    image: 'https://cdn.convrrt.com/assets/integration-logos/click-up.png',
    complete: false,
    title: 'Click up',
    description: 'Collect form information and add new contacts',
    new: true,
  },
  {
    key: 'constantContact',
    image: 'https://cdn.convrrt.com/assets/integration-logos/constant-contact.png',
    complete: false,
    title: 'Constant Contact',
    description: 'Collect form information and add new contacts',
    new: true,
  },
  {
    key: 'csv',
    image: 'https://cdn.convrrt.com/assets/integration-logos/csv.png',
    complete: false,
    title: 'CSV',
    description: 'Collect form information and add new contacts',
    new: true,
  },
  {
    key: 'discord',
    image: 'https://cdn.convrrt.com/assets/integration-logos/discord.png',
    complete: false,
    title: 'Discord',
    description: 'Collect form information and add new contacts',
    new: true,
  },
  {
    key: 'docuSign',
    image: 'https://cdn.convrrt.com/assets/integration-logos/docusign.png',
    complete: false,
    title: 'DocuSign',
    description: 'Collect form information and add new contacts',
    new: true,
  },
  {
    key: 'gmail',
    image: 'https://cdn.convrrt.com/assets/integration-logos/gmail.jpeg',
    complete: false,
    title: 'Gmail',
    description: 'Collect form information and add new contacts',
    new: true,
  },
  {
    key: 'googleCalendar',
    image: 'https://cdn.convrrt.com/assets/integration-logos/google-calendar.png',
    complete: false,
    title: 'Google Calendar',
    description: 'Collect form information and add new contacts',
    new: true,
  },
  {
    key: 'googleContacts',
    image: 'https://cdn.convrrt.com/assets/integration-logos/google-contacts.png',
    complete: false,
    title: 'Google Contacts',
    description: 'Collect form information and add new contacts',
    new: true,
  },
  {
    key: 'googleDrive',
    image: 'https://cdn.convrrt.com/assets/integration-logos/google-drive.png',
    complete: false,
    title: 'Google Drive',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'googleSheets',
    image: 'https://cdn.convrrt.com/assets/integration-logos/google-sheets.png',
    complete: true,
    title: 'Google Sheets',
    description: 'Instantly populate your spreadsheet with form data',
    new: false,
  },
  {
    key: 'hubspot',
    image: 'https://cdn.convrrt.com/assets/integration-logos/hubspot.png',
    complete: true,
    title: 'Hubspot',
    description: 'Send new contacts to your CRM and create new deals',
    new: false,
  },
  {
    key: 'keap',
    image: 'https://cdn.convrrt.com/assets/integration-logos/keap.png',
    complete: false,
    title: 'Keap',
    description: 'Add new contacts to your CRM and assign tags',
    new: false,
  },
  {
    key: 'klaviyo',
    image: 'https://cdn.convrrt.com/assets/integration-logos/klaviyo.jpeg',
    complete: false,
    title: 'Klaviyo',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'madMimi',
    image: 'https://cdn.convrrt.com/assets/integration-logos/mad-mimi.png',
    complete: false,
    title: 'Mad Mimi',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'mailchimp',
    image: 'https://cdn.convrrt.com/assets/integration-logos/mailchimp.png',
    complete: false,
    title: 'Mailchimp',
    description: 'Add and update contacts in your email lists',
    new: true,
  },
  {
    key: 'mailerLite',
    image: 'https://cdn.convrrt.com/assets/integration-logos/mailer-lite.png',
    complete: false,
    title: 'Mailer Lite',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'mollie',
    image: 'https://cdn.convrrt.com/assets/integration-logos/mollie.png',
    complete: false,
    title: 'Mollie',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'paypalPro',
    image: 'https://cdn.convrrt.com/assets/integration-logos/paypal-pro.png',
    complete: false,
    title: 'Paypal Pro',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'paypal',
    image: 'https://cdn.convrrt.com/assets/integration-logos/paypal.png',
    complete: false,
    title: 'Paypal',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'salesforce',
    image: 'https://cdn.convrrt.com/assets/integration-logos/salesforce.png',
    complete: false,
    title: 'Salesforce',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'sibBlack',
    image: 'https://cdn.convrrt.com/assets/integration-logos/sib-black.svg',
    complete: false,
    title: 'SIB Black',
    description: 'Collect subscriber info and send it to Sendinblue',
    new: false,
  },
  {
    key: 'sendinblue',
    image: 'https://cdn.convrrt.com/assets/integration-logos/sib-blue.png',
    complete: false,
    title: 'Sendinblue',
    description: 'Collect subscriber info and send it to Sendinblue',
    new: false,
  },
  {
    key: 'square',
    image: 'https://cdn.convrrt.com/assets/integration-logos/square.png',
    complete: true,
    title: 'Square',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'stripeAch',
    image: 'https://cdn.convrrt.com/assets/integration-logos/stripe-ach.png',
    complete: false,
    title: 'Stripe ACH',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'stripe',
    image: 'https://cdn.convrrt.com/assets/integration-logos/stripe.png',
    complete: false,
    title: 'Stripe',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'trello',
    image: 'https://cdn.convrrt.com/assets/integration-logos/trello.png',
    complete: false,
    title: 'Trello',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'twilio',
    image: 'https://cdn.convrrt.com/assets/integration-logos/twilio.png',
    complete: false,
    title: 'Twilio',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'zapier',
    image: 'https://cdn.convrrt.com/assets/integration-logos/zapier.png',
    complete: false,
    title: 'Zapier',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'zendesk',
    image: 'https://cdn.convrrt.com/assets/integration-logos/zendesk.png',
    complete: false,
    title: 'Zendesk',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'zohoCrm',
    image: 'https://cdn.convrrt.com/assets/integration-logos/zoho-crm.png',
    complete: false,
    title: 'Zoho Crm',
    description: 'Collect form information and add new contacts',
    new: false,
  },
  {
    key: 'zoom',
    image: 'https://cdn.convrrt.com/assets/integration-logos/zoom.png',
    complete: false,
    title: 'Zoom',
    description: 'Collect form information and add new contacts',
    new: false,
  },
]
