
import CustomerAuthentication from '../../CustomerAuthentication/CustomerAuthentication.vue';
import { cvtRouteNodes } from '@/js/utils/routes'

export default {
  components: {
    CustomerAuthentication
  },
  data() {
    return {}
  },
  props: {},
  computed: {},
  beforeDestroy() {},
  methods: {
    continueToFeatureConfig(): void {
      this.$router.push({
        name: cvtRouteNodes.enterpriseIntegrateBuilderFeatureConfig
      });
    },
  },
}
