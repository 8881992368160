
<div v-if="!AltPopupManager" class="upload-manager">
  <cvt-alert
    v-show="alertMessage.length"
    ref="uploadAlert"
    color="danger"
    icon="exclamation-triangle"
  >
    {{ alertMessage }}
  </cvt-alert>
  <div v-if="imageOnly && sizeLimitToTop" class="upload-file-note">
    {{ $t(`element.upload.image.max_size_${sizeLimit}_message`)}}
  </div>
  <div class="upload-queue">
    <div class="upload-queue__item">
      <div class="row">
        <div
          v-for="upload in queue.stack"
          :key="upload.file.name"
          class="col-auto mx-auto"
        >
          <div class="row">
            <div
              class="col-auto d-flex justify-content-center align-items-center"
            >
              <h6 v-text="upload.file.name"></h6>
            </div>
          </div>
          <div
            class="col-auto d-flex justify-content-center align-items-center"
          >
            <el-progress
              type="circle"
              :percentage="+upload.progress.toFixed(2)"
              :status="upload.status"
            ></el-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="!uploadInProgress"
    class="upload-dropzone"
    :class="dragEnabledClass"
    @dragenter.stop.prevent="dragenter"
    @dragover.stop.prevent="dragover"
    @dragleave.stop="dragleave"
    @drop.stop.prevent="dropped"
  >
    <div class="upload-dropzone__dialog">
      <i class="material-icons icon-xl">cloud_upload</i>
      <h4 v-text="dndMsg"></h4>
      <div class="upload-dropzone__dialog__divider my-3">
        - {{ $t('element.upload.image.label.or') }} -
      </div>
      <div
        class="upload-dropzone__dialog__actions d-flex justify-content-around w-75"
      >
        <cvt-button
          v-if="!hideGalleryButton"
          color="light"
          text="Pick from image gallery"
          @click.stop="openImageSearch"
        />
        <cvt-button
          :text="btnLabel"
          :special-style="isSpecialStyle"
          @click.stop="openFileDialog"
        />
        <input
          type="file"
          :accept="acceptUploadTypes"
          name="file"
          class="el-upload__input"
          :multiple="multi"
          @change="filesSelected"
        />
      </div>
    </div>
  </div>
  <div v-if="imageOnly && !sizeLimitToTop" class="upload-file-note">
    {{ $t(`element.upload.image.max_size_${sizeLimit}_message`)}}
  </div>
</div>
<div v-else class="alt-upload-manager">
  <cvt-alert
    v-show="alertMessage.length"
    ref="uploadAlert"
    color="danger"
    icon="exclamation-triangle"
  >
    {{ alertMessage }}
  </cvt-alert>
  <div v-if="imageOnly" class="upload-file-note">
    {{ $t(`element.upload.image.max_size_${sizeLimit}_message`)}}
  </div>
  <div class="upload-queue">
    <div class="upload-queue__item">
      <div class="row">
        <div
          v-for="upload in queue.stack"
          :key="upload"
          class="col-auto mx-auto"
        >
          <div class="row">
            <div
              class="col-auto d-flex justify-content-center align-items-center"
            >
              <h6 v-text="upload.file.name"></h6>
            </div>
          </div>
          <div
            class="col-auto d-flex justify-content-center align-items-center"
          >
            <el-progress
              type="circle"
              :percentage="+upload.progress.toFixed(2)"
              :status="upload.status"
            ></el-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="!uploadInProgress"
    class="upload-dropzone"
    :class="dragEnabledClass"
    @dragenter.stop.prevent="dragenter"
    @dragover.stop.prevent="dragover"
    @dragleave.stop="dragleave"
    @drop.stop.prevent="dropped"
  >
    <div class="upload-dropzone__dialog">
      <i class="material-icons icon-xl">cloud_upload</i>
      <h5 v-text="dndMsg"></h5>
      <div class="upload-dropzone__dialog__divider my-3">
        - {{ $t('element.upload.image.label.or') }} -
      </div>
      <div
        class="upload-dropzone__dialog__actions d-flex justify-content-between w-75"
      ></div>
      <cvt-button
        color="light"
        :text="btnLabel"
        @click.stop="openFileDialog"
      />
      <cvt-button
        v-if="!hideGalleryButton"
        class="my-3"
        color="link"
        text="Select from image library"
        @click.stop="openImageSearch"
      />
      <input
        type="file"
        :accept="acceptUploadTypes"
        name="file"
        class="el-upload__input"
        :multiple="multi"
        @change="filesSelected"
      />
    </div>
  </div>
</div>
