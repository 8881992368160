import loadCustomTentantScripts from '../../lib/loadCustomScripts'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

function CustomTenantScriptFn(...args: any[]) {
  if (!window.customTenantScripts) {
    return console.debug(
      'Custom tenant Script is not currently installed',
      ...args,
    )
  }
  return window.customTenantScripts
}

export default {
  state: {
    loaded: false,
  },
  getters: {},
  mutations: {
    [mutations.CUSTOM_TENANT_SCRIPTS_LOADED](
      state: { loaded: any },
      loaded: any,
    ) {
      state.loaded = loaded
    },
  },
  actions: {
    async [actions.CUSTOM_TENANT_SCRIPTS_BOOT]({ state, rootGetters, commit }) {
      if (state.loaded) {
        return
      }

      const config = rootGetters[getters.AUTH_GET_USER_CONFIG]
      if (!config.CUSTOM_TENANT_SCRIPTS) {
        return
      }

      await loadCustomTentantScripts(config.CUSTOM_TENANT_SCRIPTS)
      commit(mutations.CUSTOM_TENANT_SCRIPTS_LOADED, true)
    },
  },
}
