
<span
  v-show="visible"
  class="vnode__btn"
  :style="styles"
  @click.prevent.stop="click"
>
  <el-tooltip :content="label" placement="top">
    <icon :size="size" :icon="icon" fw></icon>
  </el-tooltip>
</span>
