
<div>
  <div>
    <div>
      <cvt-admin-left-nav-bar
        style="top: 64px"
        initial-active-id="configurations"
        :mode="mode"
      >
      </cvt-admin-left-nav-bar>
    </div>
    <div style="position: absolute; left: 280px; top: 64px">
      Configurations Data is displayed here
    </div>
  </div>
</div>
