
<div
  :id="vnodeId"
  data-identity="CountdownTimer"
  :data-vnode-id="vnodeId"
  class="cvt-editor-countdown-timer"
  :class="classes"
  draggable="true"
  @dragstart="dragstart"
  @dragend="dragend"
  @contextmenu.prevent.stop="showContextMenu"
  @click="select"
  @mouseover="target"
>
  <div :class="cdtWrapClasses">
    <template v-if="type === 'dateAndTimeBased'">
      <div class="cdtHmsOuterWrap day">
        <div class="cdtHmsInnerWrap">
          <span class="cdtTime">00</span>
        </div>
        <div class="cdtHmsLabel">DAYS</div>
      </div>
      <div class="cdtHmsSepratorWrap">
        <span class="cdtTime">:</span>
      </div>

      <div class="cdtHmsOuterWrap hour">
        <div class="cdtHmsInnerWrap">
          <span class="cdtTime">00</span>
        </div>
        <div class="cdtHmsLabel">HOURS</div>
      </div>
      <div class="cdtHmsSepratorWrap">
        <span class="cdtTime">:</span>
      </div>

      <div class="cdtHmsOuterWrap minute">
        <div class="cdtHmsInnerWrap">
          <span class="cdtTime">00</span>
        </div>
        <div class="cdtHmsLabel">MINUTES</div>
      </div>
      <div class="cdtHmsSepratorWrap d-none">
        <span class="cdtTime">:</span>
      </div>

      <div class="cdtHmsOuterWrap second d-none">
        <div class="cdtHmsInnerWrap">
          <span class="cdtTime">00</span>
        </div>
        <div class="cdtHmsLabel">SECONDS</div>
      </div>
    </template>
    <template v-else-if="type === 'urgencyBased'">
      <div class="cdtHmsOuterWrapLabel">
        <span class="cdtTime">Time left</span>
      </div>
      <div v-if="urgencyBasedTimeInDay" class="cdtHmsOuterWrap day">
        <span class="cdtTime">{{
          String(urgencyBasedTimeInDay).padStart(2, '0')
        }}</span>
      </div>
      <div v-if="urgencyBasedTimeInDay" class="cdtHmsSepratorWrap">
        <span class="cdtTime">:</span>
      </div>
      <div v-if="urgencyBasedTimeInHour" class="cdtHmsOuterWrap hour">
        <span class="cdtTime">{{
          urgencyBasedTimeInHour >= 24
            ? '00'
            : String(urgencyBasedTimeInHour).padStart(2, '0')
        }}</span>
      </div>
      <div v-if="urgencyBasedTimeInHour" class="cdtHmsSepratorWrap">
        <span class="cdtTime">:</span>
      </div>
      <div v-if="urgencyBasedTimeInMin" class="cdtHmsOuterWrap minute">
        <span class="cdtTime">{{
          urgencyBasedTimeInMin >= 1440
            ? '00'
            : String(urgencyBasedTimeInMin).padStart(2, '0')
        }}</span>
      </div>
      <div v-if="displayUrgencyBasedTimeInSec" class="cdtHmsSepratorWrap">
        <span class="cdtTime">:</span>
      </div>
      <div
        v-if="displayUrgencyBasedTimeInSec"
        class="cdtHmsOuterWrap seconds"
      >
        <span class="cdtTime">{{
          urgencyBasedTimeInSec >= 60
            ? '00'
            : String(urgencyBasedTimeInSec).padStart(2, '0')
        }}</span>
      </div>

      <div v-if="!urgencyBasedTimeInSec" class="cdtHmsOuterWrap">
        <span class="cdtTimeLabel">00 : 00 : 00</span>
      </div>
    </template>
  </div>
</div>
