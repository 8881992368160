<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="510px"
    :before-close="handleCloseDialog"
    custom-class="products-dialog-container"
  >
    <div class="products-dialog">
      <div
        v-if="showProducts"
        class="row"
      >
        <div class="col-7">
          <el-input
            v-model="query"
            placeholder="Search Products"
            @input="debounceProductSearch"
          />
        </div>
        <div class="col">
          <button
            class="btn btn-block btn btn-outline-primary"
            @click="createNewProduct"
          >
            {{ $t("elements.property.product.create") }}
          </button>
        </div>
      </div>
      <div
        v-if="showProducts"
        v-loading="loadingProducts"
        class="stipe-products"
      >
        <div
          v-for="productDisplay in products"
          :key="productDisplay.id"
          class="row stripe-product py-3"
        >
          <div class="col d-flex align-items-center">
            <div class="stripe-product-description">
              <h6 class="text-primary m-0">
                {{ productDisplay.name | truncate(100) }}
                <span class="text-success"><small>{{ productDisplay.skus[0].price | currency }}</small></span>
              </h6>
            </div>
          </div>
          <div class="col-3 ml-auto d-flex align-items-center justify-content-end product-actions">
            <el-tooltip
              :content="$t('element.property.product.cta.delete')"
              placement="top"
            >
              <i
                class="fa fa-trash fa-lg"
                @click="confirmAndRemoveProduct(productDisplay)"
              />
            </el-tooltip>
          </div>
        </div>
      </div>

      <div
        v-if="showCreate"
        class="strip-create-product"
      >
        <!-- <div class="row mb-3">
          <div class="col">
            <p class="text-muted">Add up to 5 product images</p>
            <upload-plus :before-upload="beforeProductImageUpload" @change="updateProductImages"></upload-plus>
          </div>
        </div> -->

        <div class="row mb-3">
          <div class="col">
            <label for="">Product Name</label>
            <input
              v-model="product.name"
              type="text"
              class="form-control"
              placeholder="Give this product a name"
            >
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <label for="">Description</label>
            <textarea
              v-model="product.description"
              cols="30"
              rows="5"
              class="form-control"
              placeholder="Write a fancy product description"
            />
          </div>
        </div>

        <div class="row no-gutters mb-3">
          <div class="col">
            <label for="">Price</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input
                v-model="product.price"
                type="text"
                class="form-control"
                aria-label="Amount (to the nearest dollar)"
              >
            </div>
          </div>
        </div>

        <div
          v-if="false"
          class="row mb-3"
        >
          <div class="col">
            <el-checkbox v-model="recurring">
              This is a recurring payment
            </el-checkbox>
          </div>
        </div>

        <div
          v-if="recurring"
          class="row mb-3"
        >
          <div class="col-12 mb-3">
            <label for="">Billing Interval</label>
            <div class="input-group">
              <el-select v-model="plan.interval">
                <el-option
                  v-for="interval in intervals"
                  :key="interval.value"
                  :label="interval.label"
                  :value="interval.value"
                />
              </el-select>
            </div>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col">
            <upload-drop :before-upload="beforeProductUpload" @change="updateProductDownloadUrls"></upload-drop>
          </div>
        </div> -->
        <div class="row">
          <div class="col">
            <button
              class="btn btn-primary"
              @click="saveProduct"
            >
              Save
            </button>
            <button
              class="btn btn-outline-dark"
              @click="cancelCreateProduct"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <span slot="footer" class="dialog-footer d-flex justify-content-center">

    </span> -->
  </el-dialog>
</template>

<script>
import * as _ from 'lodash'
// import UploadPlus from '@/js/components/common/UploadPlus.vue'
// import UploadDrop from '@/js/components/common/UploadDrop.vue'
import * as actions from '@/js/store/modules/actions'
import * as getters from '@/js/store/modules/getters'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

function product () {
  return {
    name: '',
    description: '',
    price: 0
  }
}

function plan () {
  return {
    nickname: '',
    interval: 'month',
    interval_count: 56
  }
}

export default {
  components: {
    // UploadDrop,
    // UploadPlus
  },

  data () {
    return {
      creating: false,
      product: product(),
      plan: plan(),
      intervals: [
        { value: 'month', label: 'Monthly' },
        { value: 'year', label: 'Annually' }
      ],
      recurring: false,
      query: '',
      loadingProducts: false
    }
  },
  computed: {
    ...mapState('eCommerce', {
      visible: 'productsDialogVisible',
      account: 'account',
      products: 'products'
    }),
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      config: getters.AUTH_GET_USER_CONFIG
    }),

    title () {
      return this.creating ? 'Create a new product' : 'Manage products'
    },
    showCreate () {
      return this.creating
    },
    showProducts () {
      return !this.creating
    }
  },

  async mounted () {
    if (this.config.ECOMMERCE) {
      this.searchProductsWithSkus()
    }
  },

  methods: {
    ...mapMutations('eCommerce', {
      handleClose: 'closeProductsDialog'
    }),
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP
    }),
    ...mapActions('eCommerce', {
      listProducts: 'listProducts',
      createProduct: 'createProduct',
      createProductPlan: 'createProductPlan',
      listSkusByProductId: 'listSkusByProductId',
      listProductsWithSkus: 'listProductsWithSkus',
      searchProductsWithSkus: 'searchProductsWithSkus',
      deleteProductById: 'deleteProductById'
    }),

    // setProduct (product) {
    //   this.updateProp({
    //     key: 'stripe',
    //     value: {
    //       publishableKey: this.account.stripe_publishable_key,
    //       product,
    //       sku: product.skus[0]
    //     }
    //   })
    // },

    resetProductCreation () {
      this.creating = false
      this.product = product()
      this.plan = plan()
    },
    createNewProduct () {
      this.resetProductCreation()
      this.creating = true
    },

    cancelCreateProduct () {
      this.resetProductCreation()
    },

    beforeProductImageUpload (file) {},

    beforeProductUpload (file) {},

    updateProductImages (uploads) {
      this.product.images = uploads.map(u => u.cdnURL)
    },

    updateProductDownloadUrls (uploads) {
      this.product.metadata = _.chain(uploads).keyBy((u) => {
        return _.chain(u.name).camelCase().truncate({
          length: 40
        }).value()
      }).mapValues('cdnURL').value()

      // eslint-disable-next-line no-self-assign
      this.product = this.product
    },

    async saveProduct () {
      const amountInCents = this.product.price * 100

      const product = await this.createProduct({
        ...this.product,
        price: amountInCents
      })

      if (this.recurring) {
        const plan = await this.createProductPlan({
          productId: product.id,
          options: {
            ...this.plan,
            amount: amountInCents,
            nickname: this.product.name
          }
        })
      }

      this.resetProductCreation()
    },

    handleCloseDialog () {
      this.handleClose()
      this.resetProductCreation()
    },

    async removeProduct (product) {
      await this.deleteProductById(product.id)
      await this.searchProductsWithSkus()
    },

    async confirmAndRemoveProduct (product) {
      await this.$confirm(`Are you sure you want to delete this product? Click Ok to delete.`, `Warning - ${product.name}`, {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })

      await this.$message({ type: 'success', message: `Product ${product.name} was deleted.` })
      await this.deleteProductById(product.id)
      await this.searchProductsWithSkus()
    },

    async handleProductSearch () {
      this.loadingProducts = true
      await this.searchProductsWithSkus({ query: this.query })
      this.loadingProducts = false
    },

    debounceProductSearch: _.debounce(function () {
      this.handleProductSearch()
    }, 500)
  }
}
</script>

<style lang="scss">
  @import "@/scss/variables";

  .stripe-product {
    // &:hover {
    //   background: $medium-gray;
    //   cursor: pointer;
    // }
  }

  .products-dialog-container {
    .el-dialog__body {
      padding-top: 0;
    }
  }

  .product-actions {
    i {
      cursor: pointer;
    }
  }
</style>
