<template>
  <span v-show="visible" class="vnode__label" v-text="mappedName"></span>
</template>

<script>
const NAME_MAP = {
  Divider: 'Divider',
  cvideo: 'Video',
  cimage: 'Image',
  cform: 'Form',
  cbutton: 'Button'
}

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    visible: Boolean
  },
  computed: {
    mappedName () {
      let mappedName = NAME_MAP[this.name.toLowerCase()]
      return mappedName || this.name
    }
  }
}
</script>

<style lang="scss">
.vnode__label {
  bottom: 0;
  left: -2px;
  position: absolute;
  padding: .5em;
  font-size: 8pt;
  color: white;
  line-height: 8pt;
  text-transform: uppercase;
  z-index: 2;
}
</style>
