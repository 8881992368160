import Vue from '../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import VueI18n from 'vue-i18n'
import { CvtIcon } from '../molecules/icon'
import CvtSwitch from '../molecules/Switch'
import { mapState } from '../../../builder/node_modules/vuex'
Vue.use(VueEmotion)
Vue.use(VueI18n)

let color

const getIndustryCardWrapper = () => styled('div')`
  font-family: Nunito;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid
    ${(props) => (props.isActive ? `${color.PRIMARY[500]}` : '#bcc2cb')};
  height: 90px;
  border-radius: 4px;
  cursor: pointer;
  min-width: 300px;
  padding: 10px 20px;
`
const getGoalContent = () => styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 80%;
`
const getLogo = () => styled(CvtIcon)`
  width: 50px;
  font-size: 40px;
  color: #494c53;
`
const getSwitchWrapper = () => styled('div')``
const getHeadertext = () => styled('p')`
  font-size: 20px;
  margin: 0px;
  font-weight: 400;
  padding-left: 10px;
`

const getSwitch = () => styled(CvtSwitch)`
  font-size: 16px;
  margin: 0px;
  font-weight: 400;
  color: ${color.PRIMARY[500]};
`

export const CvtLandingPageGoalCard = {
  name: 'CvtLandingPageGoalCard',
  data() {
    return {}
  },
  computed: {
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
    }),
  },
  created() {
    color = this.Colors
  },
  props: {
    headerText: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClickCard(e) {
      this.$emit('click', this.value)
    },
  },
  render: function (h) {
    const IndustryCardWrapper = getIndustryCardWrapper()
    const GoalContent = getGoalContent()
    const Logo = getLogo()
    const SwitchWrapper = getSwitchWrapper()
    const Headertext = getHeadertext()
    const Switch = getSwitch()

    return (
      <IndustryCardWrapper onClick={this.onClickCard} isActive={this.isActive}>
        <GoalContent>
          <Logo icon={this.icon}></Logo>
          <Headertext>{this.headerText}</Headertext>
        </GoalContent>
        <SwitchWrapper>
          <Switch
            size="sm"
            color="primary"
            type="circular"
            description-type="default"
            initial-state={this.isActive}
          ></Switch>
        </SwitchWrapper>
      </IndustryCardWrapper>
    )
  },
}
