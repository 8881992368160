import * as constants from '../../constants/constants'
import { SDA_POPUP_SETTINGS } from '../../types/vuex/index'


export const DEFAULT_SDA_POPUP_SETTINGS: SDA_POPUP_SETTINGS = {
  audience: {
    visitorTypes: {
      all: true,
      specificVisitorTypes: [{ visitorType: constants.NEW_VISITORS }],
    },
    trafficSources: {
      all: true,
      specificTrafficSources: [{ trafficSource: constants.DONT_SHOW }],
    },
    eventConditions: {
      enabled: false,
    },
    urlConditions: {
      enabled: false,
    },
  },
  rules: {
    trigger: constants.TIME_FRAME,
    timeFrame: {
      openAfterNoOfSeconds: 0,
      resetTimerOnPageLoad: false,
      delay: false, // does not exist in json
      delayBeforeOpening: 0,
    },
    exitIntent: {
      action: constants.ON_EXIT_SITE,
      onExitSite: {
        desktop: {
          delayEnabled: false,
          delayInSeconds: 0,
        },
        mobile: {
          onScrollToTop: true,
          onBackButtonClicked: false,
        },
      },
      campaignBetweenTimeframe: {
        desktop: {
          duration: constants.LESS_THAN,
          durationStartFrame: 0,
          durationEndFrame: 0,
          durationTimeUnit: constants.SECONDS,
        }
      },
    },
    mouse: {
      cssSelector: '',
      action: constants.CLICK,
    },
    pageScroll: {
      openAfterNoOfScrollPx: 0,
      measurement: constants.PIXEL,
    },
  },
}
