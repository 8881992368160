import Vue from '../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import CvtButton from '../molecules/Button'
import CvtInput from '../molecules/Input'
import CvtSelect from '../molecules/Select'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import VueI18n from 'vue-i18n'
import { FontSize } from '../../style/global'
import { CvtIcon } from '../molecules/icon/icon'

Vue.use(VueEmotion)
Vue.use(VueI18n)

let Spacing

const features = [
  {
    label: 'Popup Builder',
    value: 'POPUP_ENABLED'
  },
  {
    label: 'Funnel Pages',
    value: 'FUNNEL_ENABLED'
  }
]
const getWrapperDiv = () => styled('div')`
  height: 80vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: auto;
  width: 752px;
  background-color: transparent;
  margin: 0 auto;
  z-index: 200;
  border-radius: 7px;
`

const getCollapseWrapper = () => styled(`div`)`
  box-shadow: 0px 4px 12px rgba(18, 52, 77, 0.35);
  border-radius: 5px;
  background: white;
  margin: 20px 5px;
`

const getMainContent = () => styled('div')`
  height: 100%;
`

const getFormWrapper = () => styled('div')`
  width: 500px;
  margin: 0 auto;
`

const getInputWrapper = () => styled('div')`
  margin: 10px;
`

const getCreatedWrapper = () => styled('div')`
  text-align: center;
`

const getCreatedHeader = () => styled('h1')``

const getCreatedHelper = () => styled('p')``

const getSharedUrl = () => styled('a')`
  line-break: anywhere;
  font-size: 24px;
  margin: 5px;
`

const getButtonWrapper = () => styled('div')`
  margin-top: 100px;
  margin-bottom: 25px;
`

export default {
  name: 'CvtNewOrgModal',
  data() {
    return {
      nameOfOrgFlag: false,
      logoUrlFlag: false,
      altLogoUrlFlag: false,
      faviconUrlFlag: false,
      pageTitleFlag: false,
      topBarBackgroundColorFlag: false,
      trialPeriodFlag: false,
      nameOfOrg: '',
      logoUrl: '',
      altLogoUrl: '',
      faviconUrl: '',
      pageTitle: '',
      topBarBackgroundColor: '',
      trialPeriod: '',
      addedEmailAddresses: [''],
      nameOfOrgTouch: false,
      selectedFeatures: [],
      features: features
    }
  },
  computed: {
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing
    }),
    nextStep() {
      let isEnabled =
        this.nameOfOrgFlag &&
        this.logoUrlFlag &&
        this.altLogoUrlFlag &&
        this.faviconUrlFlag &&
        this.pageTitleFlag &&
        this.topBarBackgroundColorFlag
      return isEnabled
    },
    submit() {
      let isEnabled =
        this.nextStep && this.trialPeriodFlag && this.addedEmailAddresses
      return isEnabled
    }
  },
  created() {
    Spacing = this.Spacing
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    orgCreated: {
      type: Boolean,
      default: false
    },
    sharedUrl: {
      type: String,
      default: ''
    }
  },
  methods: {
    updateInput(e, inputField) {
      switch (inputField) {
        case 'orgName':
          this.nameOfOrgFlag = this.check(e)
          this.nameOfOrg = e.target.value
          break
        case 'logoUrl':
          this.logoUrlFlag = this.check(e)
          this.logoUrl = e.target.value
          break
        case 'altLogoUrl':
          this.altLogoUrlFlag = this.check(e)
          this.altLogoUrl = e.target.value
          break
        case 'faviconUrl':
          this.faviconUrlFlag = this.check(e)
          this.faviconUrl = e.target.value
          break
        case 'pageTitle':
          this.pageTitleFlag = this.check(e)
          this.pageTitle = e.target.value
          break
        case 'topBar':
          this.topBarBackgroundColorFlag = this.check(e)
          this.topBarBackgroundColor = e.target.value
          break
        case 'trialPeriod':
          this.trialPeriodFlag = this.check(e)
          this.trialPeriod = e.target.value
          break
        default:
          break
      }
    },
    updateEmail(e, index) {
      this.addedEmailAddresses[index] = e.target.value
    },
    check(e) {
      if (e.target.value === '') {
        return false
      } else {
        return true
      }
    },
    dispatch() {
      const child = this.$children[0]
      child.$emit.apply(child, ['item-click', child.$children[1]])
    },
    apiCall() {
      let orgConfigValue = {
        color: '#fff',
        logoHeight: '25px',
        logo: this.logoUrl,
        logoAlt: this.altLogoUrl,
        favicon: this.faviconUrl,
        pageTitle: this.pageTitle,
        mainNavBreadcrumbFontColor: this.topBarBackgroundColor
      }
      let data = {
        orgName: this.nameOfOrg,
        orgID: this.nameOfOrg.trim().toLowerCase().replace(/\s/g, '-'),
        orgConfigValue: JSON.stringify(orgConfigValue),
        trialPeriod: parseInt(this.trialPeriod),
        demoEmails: this.addedEmailAddresses,
        enabledFeatures: this.selectedFeatures
      }
      this.$emit('api-call', data)
    },
    addNewEmail() {
      this.addedEmailAddresses.push('')
      console.log(this.addedEmailAddresses)
    }
  },
  render: function (h) {
    const WrapperDiv = getWrapperDiv()
    const MainContent = getMainContent()
    const FormWrapper = getFormWrapper()
    const InputWrapper = getInputWrapper()
    const CollapseWrapper = getCollapseWrapper()
    const CreatedWrapper = getCreatedWrapper()
    const CreatedHeader = getCreatedHeader()
    const CreatedHelperText = getCreatedHelper()
    const SharedUrl = getSharedUrl()
    const ButtonWrapper = getButtonWrapper()

    return (
      this.show && (
        <WrapperDiv>
          <MainContent>
            {!this.orgCreated && (
              <cvt-collapse accordion={true}>
                <CollapseWrapper>
                  <cvt-collapse-form-item
                    title='Organization Details'
                    name='1'
                    initialActive={true}
                    subtitle='Provide details about the organization'
                    collapseIcon='pen'
                    expandIcon='pen'
                    icon='plus'
                  >
                    <FormWrapper>
                      <InputWrapper>
                        <CvtInput
                          type='text'
                          floatingLabel={false}
                          label='Name of the Organization'
                          required={true}
                          onInput={(e) => this.updateInput(e, 'orgName')}
                          value={this.nameOfOrg}
                          validation-status={
                            this.nameOfOrgTouch
                              ? this.nameOfOrgFlag
                                ? ''
                                : 'invalid'
                              : ''
                          }
                          onBlur={() => {
                            this.nameOfOrgTouch = true
                          }}
                          clearable={false}
                        ></CvtInput>
                      </InputWrapper>
                      <InputWrapper>
                        <CvtInput
                          type='text'
                          floatingLabel={false}
                          label='Logo URL'
                          onInput={(e) => this.updateInput(e, 'logoUrl')}
                          value={this.logoUrl}
                          clearable={false}
                        ></CvtInput>
                      </InputWrapper>
                      <InputWrapper>
                        <CvtInput
                          type='text'
                          floatingLabel={false}
                          label='Alt Logo URL'
                          onInput={(e) => this.updateInput(e, 'altLogoUrl')}
                          value={this.altLogoUrl}
                          clearable={false}
                        ></CvtInput>
                      </InputWrapper>
                      <InputWrapper>
                        <CvtInput
                          type='text'
                          floatingLabel={false}
                          label='Favicon URL'
                          onInput={(e) => this.updateInput(e, 'faviconUrl')}
                          value={this.faviconUrl}
                          clearable={false}
                        ></CvtInput>
                      </InputWrapper>
                      <InputWrapper>
                        <CvtInput
                          type='text'
                          floatingLabel={false}
                          label='Page Title'
                          onInput={(e) => this.updateInput(e, 'pageTitle')}
                          value={this.pageTitle}
                          clearable={false}
                        ></CvtInput>
                      </InputWrapper>
                      <InputWrapper>
                        <CvtInput
                          type='text'
                          floatingLabel={false}
                          label='Top bar background color'
                          onInput={(e) => this.updateInput(e, 'topBar')}
                          value={this.topBarBackgroundColor}
                          clearable={false}
                        ></CvtInput>
                      </InputWrapper>
                      <InputWrapper>
                        <CvtSelect
                          value={this.selectedFeatures}
                          label='Features'
                          multiple={true}
                          selectDisabled={false}
                          floatingLabel={false}
                          onInput={(e) => (this.selectedFeatures = e)}
                        >
                          {this.features.map((option) => {
                            return (
                              <option value={option.value}>
                                {option.label}
                              </option>
                            )
                          })}
                        </CvtSelect>
                      </InputWrapper>
                      <InputWrapper>
                        <CvtButton
                          text='Next: Send Demo Invites'
                          outlined={true}
                          shadow={true}
                          disabled={!this.nextStep}
                          onClick={() => this.dispatch()}
                        ></CvtButton>
                      </InputWrapper>
                    </FormWrapper>
                  </cvt-collapse-form-item>
                </CollapseWrapper>
                <CollapseWrapper>
                  <cvt-collapse-form-item
                    title='Send Demo Invites'
                    name='2'
                    subtitle='Send invites to stakeholders to try out the Convrrt app'
                    collapseIcon='pen'
                    expandIcon='pen'
                    icon='user'
                  >
                    <FormWrapper>
                      <InputWrapper>
                        <CvtInput
                          type='number'
                          floatingLabel={false}
                          label='Set Trial Period (in days)'
                          onInput={(e) => this.updateInput(e, 'trialPeriod')}
                          value={this.trialPeriod}
                          clearable={false}
                        ></CvtInput>
                      </InputWrapper>
                      <InputWrapper>
                        <CvtInput
                          type='text'
                          floatingLabel={false}
                          label='Invite Guest'
                          onInput={(e) => this.updateEmail(e, 0)}
                          value={this.addedEmailAddresses[0]}
                          clearable={false}
                        ></CvtInput>
                        {this.addedEmailAddresses.map((email, index) =>
                          index != 0 ? (
                            <CvtInput
                              type='text'
                              floatingLabel={false}
                              label=''
                              onInput={(e) => this.updateEmail(e, index)}
                              value={email}
                              clearable={false}
                            ></CvtInput>
                          ) : (
                            ''
                          )
                        )}
                      </InputWrapper>
                      <InputWrapper>
                        <CvtButton
                          text='Invite Another Guest'
                          icon='plus'
                          outlined={true}
                          shadow={true}
                          onClick={() => this.addNewEmail()}
                        ></CvtButton>
                      </InputWrapper>
                      <InputWrapper>
                        <CvtButton
                          text='Next: Send Demo Invites'
                          shape='rounded'
                          outlined={true}
                          disabled={!this.submit}
                          onClick={() => this.apiCall()}
                          shadow={true}
                        ></CvtButton>
                      </InputWrapper>
                    </FormWrapper>
                  </cvt-collapse-form-item>
                </CollapseWrapper>
              </cvt-collapse>
            )}
            {this.orgCreated && (
              <CreatedWrapper>
                <CreatedHeader>New Organization Created!</CreatedHeader>
                <CreatedHelperText>
                  Share the following URL to access the page builder
                </CreatedHelperText>
                <CvtIcon icon='share-alt'></CvtIcon>
                <SharedUrl target='_blank' href={this.sharedUrl}>
                  {this.sharedUrl}
                </SharedUrl>
                <ButtonWrapper>
                  <CvtButton
                    icon='check'
                    text='Done'
                    prepend={false}
                    outlined={true}
                    specialPadding='12px'
                    fullWidth={true}
                    color='success'
                    onClick={() => this.$emit('close')}
                  ></CvtButton>
                </ButtonWrapper>
              </CreatedWrapper>
            )}
          </MainContent>
        </WrapperDiv>
      )
    )
  }
}
