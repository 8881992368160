import { database } from '@/js/store/services/firebase.service'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'

let db = database()

class Block {
  name = ''
  thumbnail = ''
  tags = []
  colors = {}
}

export default {
  state: {
    visible: false,
    data: new Block(),
    vnode: null,
  },
  getters: {
    [getters.SAVE_BLOCK_VISIBLE]({ visible }) {
      return visible
    },
    [getters.SAVE_BLOCK_DATA]({ data }) {
      return data
    },
    [getters.SAVE_BLOCK_VNODE]({ vnode }) {
      return vnode
    },
  },
  mutations: {
    [mutations.SAVE_BLOCK_SHOW](state) {
      state.visible = true
    },
    [mutations.SAVE_BLOCK_HIDE](state) {
      state.visible = false
    },
    [mutations.SAVE_BLOCK_RESET](state) {
      state.data = new Block()
      state.vnode = null
    },
    [mutations.SAVE_BLOCK_SET_DATA](state, data) {
      state.data = data
    },
    [mutations.SAVE_BLOCK_SET_VNODE](state, vnode) {
      state.vnode = vnode
    },
    [mutations.SAVE_BLOCK_UPDATE_DATA](state, data) {
      state.data = {
        ...state.data,
        ...data,
      }
    },
  },
  actions: {
    [actions.SAVE_BLOCK_CONFIRM]({ state, commit, rootGetters }, isGlobal = false) {
      const { orgID } = rootGetters[getters.AUTH_GET_USER]
      const { MARKETPLACE_PATH } = rootGetters[getters.AUTH_GET_USER_CONFIG]

      let blockRef = null
      if (isGlobal) {
        blockRef = db.ref(MARKETPLACE_PATH).child('globalBlocks').push()
      }else {
        blockRef = db.ref(MARKETPLACE_PATH).child(orgID).child('blocks').push()
      }

      db.ref(state.vnode).once('value', (snap) => {
        return blockRef
          .set({
            ...state.data,
            vnode: snap.val(),
          })
          .then(() => {
            commit(mutations.SAVE_BLOCK_HIDE)
            commit(mutations.SAVE_BLOCK_RESET)
          })
      })
    },
    [actions.SAVE_POPUP_BLOCK_CONFIRM]({ state, commit, rootGetters }, popupData) {
      const { orgID } = rootGetters[getters.AUTH_GET_USER]
      const { MARKETPLACE_PATH } = rootGetters[getters.AUTH_GET_USER_CONFIG]

      db.ref(state.vnode).once('value', (snap) => {
        let blockRef = db.ref(MARKETPLACE_PATH).child(orgID).child('blocks').push()
        return blockRef
            .set({
              ...state.data,
              vnode: snap.val(),
              popup: {
                ...popupData
              }
            })
            .then(() => {
              commit(mutations.SAVE_BLOCK_HIDE)
              commit(mutations.SAVE_BLOCK_RESET)
            })
      })
    },
    [actions.SAVE_BLOCK_CANCEL]({ commit }) {
      commit(mutations.SAVE_BLOCK_HIDE)
      commit(mutations.SAVE_BLOCK_RESET)
    },
  },
}
