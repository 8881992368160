
<div
  :id="vnodeId"
  ref="gridRootWrapper"
  :data-vnode-id="vnodeId"
  class="position-relative visual-box grid-stack-wrapper"
  @mouseenter="mouseOverAction"
  @mouseleave="mouseLeaveAction"
>
  <!--    box-color="#e9e9e98c"-->
  <visual-box
    ref="vsBox"
    :grid-column="column"
    box-color="#333333"
    :grid-column-height="cellHeight"
    :grid-v-node-id="vnodeId"
  />
  <div
    :key="'grid-stack-' + vnodeId + viewMode"
    ref="gridElement"
    :class="gridClassName"
    @mousedown.stop="ContainerMouseDown"
    @mouseover.stop=""
  >
    <div
      v-if="
        fbNode.children.length === 0 &&
        parent().parent().name === 'CPopupBuilder'
      "
      class="d-flex justify-content-center"
    >
      <div class="popup-default-box">
        <!-- The enlarged Icon -->
        <span
          class="popup-default-icon cvt-icon-www-site-arrow-cursor"
        ></span>
        <!-- Select Popup Style text under the Icon -->
        <span class="popup-default-text">Select Popup Style</span>
        <!-- Button to open up Pre Made Popup Options on click -->
        <cvt-button
          class="popup-default-button"
          :color="selectPopupBtnConfig.color"
          :special-style="selectPopupBtnConfig.specialStyle"
          :icon="'arrow-right'"
          :prepend="false"
          :text="'Select Pre-Made Popup'"
          @click="click($event)"
        ></cvt-button>
      </div>
    </div>

    <div
      v-if="
        fbNode.children.length === 0 &&
        parent().parent().name !== 'CPopupBuilder'
      "
      class="empty-section-placeholder d-flex justify-content-center align-items-center"
    >
      <!-- Internal dashed box -->
    </div>

    <template v-for="node in fbNode.children">
      <grid-item-v3
        v-bind="node.data.props"
        :key="node.data.props.vnodeId"
        :fb-node="node"
        class="grid-stack-item-main"
        @removeElement="removeNode"
        @duplicateElement="duplicateNode"
        @forceRemoveElement="forceRemoveNode"
      ></grid-item-v3>
    </template>
  </div>
</div>
