<template>
  <cvt-top-nav-bar
    mode="LIGHT"
    variant="embedded"
    :route="currentRouteName"
    :fixed="true"
    template-name="Generative Wizard Editor"
    :disable-exit="!disableExitButton"
    :light-logo="logo"
    :dark-logo="logo"
    :revision-loading="false"
    :undo-disabled="disabled(editorCtrls[0].disabled)"
    :redo-disabled="disabled(editorCtrls[1].disabled)"
    :type="orgName"
    @back="backStep"
    @exit="exitAction"
    @help="help"
    @exit-preview="exitAction"
    @switch-preview="() => {}"
    @undo="execute(editorCtrls[0])"
    @redo="execute(editorCtrls[1])"
    @top-settings="() => {}"
    @version-history="() => {}"
    @version-history-save="() => {}"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as getters from '../../../store/modules/getters'
import color from 'color'
import * as actions from '../../../store/modules/actions'
import * as mutations from '../../../store/modules/mutations'
import { cvtRouteNodes, cvtRoutes } from '@/js/utils/routes'

const DESKTOP = {
  label: 'Desktop',
  icon: 'desktop_mac',
  action: 'enableDesktopMode',
  disabled: 'cantShowPreview',
}
const MOBILE = {
  label: 'Mobile',
  icon: 'phone_iphone',
  action: 'enablePhoneMode',
  disabled: 'cantShowPreview',
}

export default {
  name: 'WizardNav',
  data() {
    return {
      editorCtrls: [
        { label: 'undo', icon: 'undo', action: 'undo', disabled: 'cantUndo' },
        {
          label: 'redo',
          icon: 'redo',
          action: 'redo',
          iconRight: true,
          disabled: 'cantRedo',
        },
      ],
      selectedPreviewMode: DESKTOP,
      previewCtrls: [DESKTOP, MOBILE],
    }
  },
  computed: {
    ...mapGetters({
      step: getters.STEPS_CURRENT_STEP,
      user: getters.AUTH_GET_USER,
      config: getters.AUTH_GET_USER_CONFIG,
      theme: getters.AUTH_GET_USER_BUILDER_THEME,
      canUndo: getters.HISTORY_CAN_UNDO,
      canRedo: getters.HISTORY_CAN_REDO,
      viewmode: getters.VIEW_MODE,
      popup: getters.SDA_POPUP_SITE_DATA,
    }),
    ...mapState('loader', {
      loaderVisible: 'visible',
    }),
    ...mapState('route', {
      query: ({ query }) => query,
    }),
    ...mapState('globalTheme', {
      orgName: ({ globalTheme }) => globalTheme.OrgName,
    }),
    disableExitButton() {
      return false
    },
    currentRouteName() {
      const { wizardEditorDesignPage, wizardEditor, marketplace } =
        cvtRouteNodes

      switch (this.$route.name) {
        case wizardEditorDesignPage:
          return wizardEditorDesignPage
        case wizardEditor:
          return wizardEditor
        default:
          return marketplace
      }
    },
    headerTitle() {
      return (
        this.query.headerTitle ||
        this.theme.headerTitle ||
        this.theme.backToDashText
      )
    },
    navColor() {
      if (this.theme.color) {
        const colorPrefix = [3, 6].includes(this.theme.color.length) ? '#' : ''
        return color(`${colorPrefix}${this.theme.color}`)
      }
      return ''
    },
    logo() {
      return this.navColor && this.navColor.dark()
        ? this.theme.logoAlt
        : this.theme.logo
    },
    pageEngineVisible() {
      return (
        [cvtRouteNodes.editorDesignPage].includes(this.$route.name) &&
        !this.loaderVisible
      )
    },
    cantUndo() {
      return false
    },
    cantRedo() {
      return false
    },
    cantShowPreview() {
      return false
    },
  },
  watch: {
    viewmode: {
      immediate: true,
      handler(newVal) {
        this.selectedPreviewMode = newVal === 'desktop' ? DESKTOP : MOBILE
      },
    },
  },
  methods: {
    ...mapActions({
      toggleIntercom: actions.INTERCOM_TOGGLE,
    }),
    ...mapActions('loader', {
      startLoader: 'start',
      stopLoader: 'stop',
    }),
    help() {
      const { type: mode, value } = this.config.POPUP_HELP_BUTTON
      switch (mode) {
        case 'intercom':
          return this.toggleIntercom()
        case 'external_link':
          return window.open(value, '_blank')
        default:
          alert('Help button config has not been set.')
      }
    },
    async pushRoute(route) {
      const url = new URL(window.location.href)
      const params = new URLSearchParams(url.search)

      try {
        await this.$router.push({
          path: route,
          query: Object.fromEntries(params),
        })
      } catch (e) {
        console.debug(e)
      }
    },
    async backStep() {
      await this.exitAction()
    },
    async exitAction() {
      const urlParams = new URLSearchParams(window.location.search)
      const redirectURL = urlParams.get('next') || urlParams.get('redirectUrl')

      if (redirectURL) {
        window.location.href = decodeURIComponent(
          redirectURL.replace(/\+/g, ' '),
        )
        return
      }
      return await this.pushRoute(cvtRoutes.popupChooseATemplate.path)
    },
    execute(ctrl) {
      this[ctrl.action]()
    },
    disabled(prop) {
      return this[prop]
    },
  },
}
</script>

<style scoped></style>
