export function relativeMouseOffset (
  offset /* { clientX: number, clientY: number }*/,
  base /*HTMLElement*/
) {
  const bounds = base.getBoundingClientRect()
  return {
    left: offset.clientX - bounds.left,
    top: offset.clientY - bounds.top
  }
}

export const defaultValue = (value, defaultVal=0) => {
  const input = Number(value)
  let out = isNaN(input) ? defaultVal: input
  return (normalize) => normalize(out)
}

export const round = (value, min, max, step) => {
  if (value <= min) {
    return min
  }

  if (value >= max) {
    return max
  }

  const normalize = (value - min) / step
  const decimal = Math.floor(normalize)
  const fraction = normalize - decimal

  if (fraction === 0) return value

  if (fraction < 0.5) {
    return step * decimal + min
  } else {
    const nextStep = step * (decimal + 1) + min;
    return nextStep > max ? max : nextStep
  }
}

export const nextCustomValue = (value, arrSteps) => {
  for(let i=0, length = arrSteps.length; i < length; i++) {
    const v  = arrSteps[i];
    if(v === value) {
      return i + 1 < length ? arrSteps[i + 1] : arrSteps[i]
    }
  }
}

export const prevCustomValue = (value, arrSteps) => {
  for(let i=0, length = arrSteps.length; i < length; i++) {
    const v  = arrSteps[i];
    if(v === value) {
      return i - 1 > 0 ? arrSteps[i - 1] : arrSteps[i]
    }
  }
}

export const computePrevAndNextStep = (value, min, max, arrSteps) => {
  try {
    let prevStep = null;
    let nextStep = null;
    for(let i=0, length = arrSteps.length; i < length; i++) {
      const v  = arrSteps[i];
      if(v >= min && v <= value) {
        prevStep = v;
      } else if(v <= max && v>= min) {
        nextStep = v;
        break;
      }
    }
    return [prevStep || min, nextStep || max]
  } catch (e) {
    console.error('Error parsing marks object. Make sure you provided a valid object')
    throw e;
  }
}

export const roundToCustom  = (value, min, max, arrSteps) => {
  console.log('round-custom')
  if (value <= min) {
    return min
  }

  if (value >= max) {
    return max
  }
  const [prev, next] = computePrevAndNextStep(value, min, max, arrSteps)
  const nextPointOfChange = prev + (Math.floor(next - prev) / 2)

  if (value > nextPointOfChange) return next
  return prev
}

export const valuePercent = (value, min, max) => {
  return (value - min)  * 100 / (max - min)
}


