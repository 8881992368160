<script>
import CFormV3 from '../../../../components/v3/editor/nodes/CForm.vue'
import * as _ from 'lodash'
import { database } from '@/js/store/services/firebase.service'
import { mapGetters } from 'vuex'
import * as getters from '@/js/store/modules/getters'

export default CFormV3.extend({
  name: 'CFormV3Popup',
  computed: {
    ...mapGetters({
      popupSite: getters.SDA_POPUP_SITE_DATA,
    }),
  },
  methods: {
    async validationErrors() {
      const errors = []

      const redirectOptionsRef = database()
        .ref(this.popupSite.firebaseRef)
        .child('redirect')
      const oldRedirectOptions = await redirectOptionsRef
        .once('value')
        .then((snap) => snap.val())
      const oldChoice = _.get(oldRedirectOptions, 'choice')
      const oldURL = _.get(oldRedirectOptions, 'url')

      if (oldChoice === 'thankyou') {
        await this.updateProp('redirectChoice', 'page')
        // await this.updateProp('pageId', this.thankYouPage.id)
        await this.updateProp('pageId', '')
        await redirectOptionsRef.remove()
      }

      if (oldChoice === 'url' && oldURL) {
        await this.updateProp('redirectChoice', 'url')
        await this.updateProp('redirectUrl', oldURL)
        await redirectOptionsRef.remove()
      }

      if (this.user.orgID === 'sendinblue' && !this.folderId) {
        errors.push('You must select a contact folder.')
      }

      if (this.user.orgID === 'sendinblue' && !this.listId) {
        errors.push(`${this.$t('elements.property.form.field.list.error.message')}`)
      }
      const emailIsRequired = this.config.REQUIRED_FORM_FIELDS.includes('email')
      const emailField =
        _.find(this.fields, { id: 'email' }) ||
        _.find(this.fields, {
          type: 'email',
        })

      const smsField = _.find(
        this.fields,
        (f) => f.id === 'SMS' || f.id === 'mobile_phone',
      )
      const smsIsRequired = this.config.REQUIRED_FORM_FIELDS.includes('sms')

      if (!emailField && emailIsRequired && !smsField && smsIsRequired) {
        errors.push('Your form must contain at least an email or SMS field')
      }

      if (!this.redirectChoice) {
        errors.push(
          'Please select a redirect option for your form before you continue.',
        )
      }

      if (this.redirectChoice === 'page' && !this.pageId) {
        errors.push(
          'Your form redirect option is set to "page" but you have not selected a page.',
        )
      }

      if (this.redirectChoice === 'url' && !this.redirectUrl) {
        errors.push(
          'Your form redirect option is set to "url" but you have no set a "url"',
        )
      }

      if (errors.length) {
        this.selectSelfForHelp()
        this.advancedMenuSetActiveTabs(['redirectOptions'])
      }

      return errors
    },
    checkAndSetPageId() {
      if (this.pageId === '') {
        // TODO: Do nothing for now
      }
      if (this.redirectChoice === 'page') {
        // For popup, if the redirectChoice is `page`, we have it to `default`. But we don't want that action to be tracked
        // by undo-redo manager.
        const command = this.createCommand('redirectChoice', 'default')
        command.execute()
      }
    },
  },
})
</script>
