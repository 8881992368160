<script lang="jsx">
import FbVNode from '../../base/FbVNode.vue'
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import GridContainer from './GridContainer.vue'
import GridItemWrapper from './GridItemWrapper.vue'
import Block from './Block.vue'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import * as mutations from '../../../store/modules/mutations'
import vueDomo from '../../../lib/vue-dom-obj'
import { database } from '@/js/store/services/firebase.service'
import _ from 'lodash'
import { PopUpUndoRedoManager } from '../../../store/services/popUpUndoRedoManager'

const fbDB = database()
const defaultWidth = '625px'

export default FbVNode.extend({
  components: {
    GridContainer,
    GridItemWrapper,
    Block,
  },
  props: {},
  data() {
    return {
      name: 'CPopupBuilder',
      undoRedoManager: new PopUpUndoRedoManager(),
      ref: null,
      lastSnapshot: [],
      blockVnodeId: null,
      popupWidth: defaultWidth,
      popupHeight: 'auto',
      isFullScreen: false,
      closeButtonPosition: 'end',
    }
  },
  computed: {
    canUndo() {
      return this.undoRedoManager.canUndo
    },
    canRedo() {
      return this.undoRedoManager.canRedo
    },
    ...mapGetters({
      visible: getters.POPUP_BUILDER_OPEN,
      popupIdx: getters.POPUP_IDX,
      deltaVdom: getters.DELTA_VDOM,
      deltaKey: getters.DELTA_KEY,
      isempty: getters.POPUP_EMPTY,
      viewMode: getters.VIEW_MODE,
    }),
    currentIdx() {
      return this.popupIdx
    },
  },

  watch: {
    popupIdx(n, o) {
      this.reloadPopupContents()
    },

    popupWidth() {
      // TODO:
      // Doing this makes heights correct but elements loose position
      // Not doing this keeps the elements intact but height calculation is wrong
      if (this.$children[0]?.$children[1]) {
        this.$children[0]?.$children[1]?.recalculateHeight?.()
      }
    },
    viewMode(n, o) {
      this.undoRedoManager.viewModeChanged(n, o)
    },
    visible(n, o) {
      if (n === true) {
        this.reloadPopupContents()
      }
    },
  },
  methods: {
    ...mapActions({
      togglePopup: actions.POPUP_BUILDER_TOGGLE,
      getPopupProperty: actions.POPUP_GET_PROPERTY,
      updatePopupProperty: actions.POPUP_UPDATE_PROPERTY,
    }),
    ...mapMutations({
      initPopup: mutations.INIT_POPUP,
      deselectItem: mutations.SELECTOR_DESELECT,
    }),
    dismiss() {
      this.togglePopup()
      this.deselectItem()
    },
    stopProp(event) {
      document
        .getElementById(`${this.blockVnodeId}`)
        ?.dispatchEvent(new Event('click', { bubbles: true }))
      event.stopPropagation()
    },
    outerTarget(event) {
      document
        .getElementById(`${this.blockVnodeId}`)
        ?.dispatchEvent(new MouseEvent('mouseover', { bubbles: true }))
      event.stopPropagation()
    },
    async reloadPopupContents() {
      this.$store
        .dispatch(actions.POPUP_BUILDER_UNDO_REDO_MANAGER, this['.path'])
        .then((undoRedoManager) => {
          this.undoRedoManager = undoRedoManager
          this.blockVnodeId = undoRedoManager?.nodes[0]?.data?.props?.vnodeId
        })

      // console.log(this.getPopupProperty('popupWidth'));
      // const _ref = fbDB.ref(this['.path']+'/'+this.popupIdx)
      // _ref.on('value', (snapshot) => {
      //   this.popupWidth = snapshot.val()?.data.props?.popupWidth || '70%'
      // })

      this.popupWidth = await this.getPopupProperty('popupWidth')
    },
  },
  created: async function () {
    this.initPopup(this)
  },
  mounted: function () {},
  render: function (h) {
    if (!this.visible) {
      return
    }

    return (
      <div
        onMouseover={this.outerTarget}
        class={{ outer: true, 'outer-mobile': this.viewMode === 'phone' }}
        onClick={this.stopProp}
      >
        <div
          class={{ inner: true, 'inner-mobile': this.viewMode === 'phone' }}
          style={{
            width:
              this.isFullScreen === true || this.popupWidth === '100vw'
                ? '100%'
                : this.popupWidth,
            height:
              this.isFullScreen === true || this.popupHeight === '100vh'
                ? '100vh'
                : this.popupHeight,
          }}
          onClick={this.stopProp}
        >
          {this.undoRedoManager.currentSnapshot &&
            this.undoRedoManager.nodes.map((node) => {
              node['.path'] =
                this._self['.path'] + '/' + this.popupIdx + '/children/0'
              return vueDomo(h, this._v, node)
            })}
        </div>
      </div>
    )
  },
})
</script>

<style lang="scss">
// @import "bootstrap";

.outer {
  height: 100%;
  width: 100%;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}

.inner {
  padding: 5px 0px;
  max-height: 100%;
  outline: 0;
  -webkit-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out;
  display: flex;
  background: white;
  justify-content: center;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08), 0px 20px 32px rgba(0, 0, 0, 0.32);
  transition: all 0.3s ease-in-out;

  .block {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
  }
}

.inner-mobile {
  width: 575px;
}
</style>
