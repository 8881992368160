import * as _ from 'lodash'
import moveElementInArray from './lib/moveElementInArray'
import { isObject } from './components/v3/lib/vue-dom-obj'
import { database } from '@/js/store/services/firebase.service'

export class ConvrrtFirebase {
  constructor(path) {
    this.ref = database().ref(path)
    this.path = path
  }

  async load() {
    return this.ref.once('value').then((snap) => snap.val())
  }

  get key() {
    return +this.ref.key
  }

  async setChild(key, value) {
    return this.ref.child(key).set(value);
  }

  async set(value) {
    return this.ref.set(value);
  }

  transaction(cb) {
    return this.ref.transaction((val) => {
      return cb(val)
    })
  }

  modifyChildren(cb) {
    return this.transaction((val) => {
      if (val && val.tag) {
        val.children = val.children || []
        val.children = cb(val.children)
        return val
      }
      val = val || []
      return cb(val)
    })
  }

  append(vNode, method, idx = null) {
    if (_.isArray(vNode) === false) {
      vNode = [vNode]
    }

    return this.modifyChildren((children) => {
      if (idx) {
        children.splice(idx, 0, vNode)
        return children
      }
      children[method](...vNode)
      return children
    })
  }

  push(vNode, idx = null) {
    return this.append(vNode, 'push', idx)
  }

  unshift(vNode) {
    return this.append(vNode, 'unshift')
  }

  splice (index, deleteCount, vNode) {
    return this.transaction((children) => {
      // only if vNode is given in params, only then we want to insert it 
      // at specified index otherwise, undefined will be inserted at specified index 
      // if this if-check is not in place and vNode is not provided
      if(vNode) {
        children.splice(index, deleteCount, vNode)
      } else {
        children.splice(index, deleteCount)
      }
      return children
    })
  }

  removeNode(idx) {
    return this.modifyChildren((children) => {
      children.splice(idx, 1)
      return children
    })
  }
  moveChild(idx, direction) {
    return this.transaction((children) => {
      if (isObject(children)) return children
      return moveElementInArray(children, idx, direction)
    })
  }
}
