import {
  BlockQuoteFroalaFormat,
  ButtonFormat,
  FroalaFormat,
  FroalaLinkFormat,
} from '../../../../lib/font'
import { Typography, ButtonTypography, FroalaLinkTypography } from '../types'

export const DEFAULT_TYPOGRAPHY: Typography = {
  title: new FroalaFormat({
    font: 'arimo',
    size: '60',
    defaultTextColor: '#414141',
    defaultAltTextColor: '#fafafa',
  }),
  subtitle: new FroalaFormat({
    font: 'arimo',
    size: '48',
    defaultTextColor: '#414141',
    defaultAltTextColor: '#fafafa',
  }),
  heading: new FroalaFormat({
    font: 'arimo',
    size: '34',
    defaultTextColor: '#414141',
    defaultAltTextColor: '#fafafa',
  }),
  alternativeHeading: new FroalaFormat({
    font: 'arimo',
    size: '30',
    defaultTextColor: '#414141',
    defaultAltTextColor: '#fafafa',
  }),
  lead: new FroalaFormat({
    font: 'arimo',
    size: '22',
    defaultTextColor: '#414141',
    defaultAltTextColor: '#fafafa',
  }),
  normalText: new FroalaFormat({
    font: 'arimo',
    size: '18',
    defaultTextColor: '#414141',
    defaultAltTextColor: '#fafafa',
  }),
  blockquote: new BlockQuoteFroalaFormat({
    font: 'arimo',
    size: '18',
    defaultTextColor: '#414141',
    defaultAltTextColor: '#fafafa',
  }),
}

export const DEFAULT_BUTTON_TYPOGRAPHY: ButtonTypography = {
  button: new ButtonFormat({
    fontFamily: 'arimo',
    fontSize: '18',
    defaultTextColor: '#414141',
    defaultBackgroundColor: '#EF6C00',
    fontWeight: '400',
    subtextStyle: {
      fontSize: '14',
      fontWeight: '200',
      fontFamily: 'arimo',
    },
  }),
}

export const DEFAULT_FROALA_LINK_TYPOGRAPHY: FroalaLinkTypography = {
  link: new FroalaLinkFormat({
    defaultTextColor: '#3378FF',
  }),

  linkHover: new FroalaLinkFormat({
    defaultTextColor: '#384CAD',
  }),

  linkVisited: new FroalaLinkFormat({
    defaultTextColor: '#E64A19',
  }),
}
