import {BackgroundShapeSVGObject} from "../BackgroundShapeSVGObject";

const TriangleAltSVG =
    `
<svg data-name="Triangle Asymmetrical" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
    <path d="M1200 0L0 0 892.25 114.72 1200 0z" class="fill"></path>
</svg>`


export const TriangleAltShape = new BackgroundShapeSVGObject({
    name: "Triangle Asymmetrical",
    canInvert: true,
    canFlip: true,
    svg: TriangleAltSVG
})