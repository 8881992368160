export default {
  name: "CustomHostName",
  props: {
    customHostNames: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    verifyCustomHostnames(){
      this.$emit('onVerifyCustomHostName')
    },
    capitalizeFirstLetter(str: string) {
      if(str.length > 1){
        return str[0].toUpperCase() + str.slice(1).toLowerCase();
      }
      else if(str.length === 1){
        return str.toUpperCase()
      }
      else{
        return str
      }
    },
    whatsMyDNSCNAME (domain: String) {
      return `https://www.whatsmydns.net/#CNAME/${domain}`
    }
  }
}