<template>
  <el-dialog
    :visible.sync="visible"
    size="tiny"
    @open="open"
    @close="close"
  >
    <span
      slot="title"
      class="save-block-title"
    >
      {{ $t('editor.save_block') }}
    </span>

    <div class="row">
      <el-input
        v-model="block.name"
        class="col-12 mb-2"
        placeholder="Name the block"
        autofocus
      />
      <el-input
        v-model="block.thumbnail"
        class="col-12 mb-2"
        placeholder="Url to thumbnail"
      />

      <el-select
        v-show="block"
        v-model="block.tags"
        class="col-12"
        multiple
        filterable
        allow-create
        placeholder="Apply block tags"
      >
        <el-option
          v-for="tag in tags"
          :key="tag"
          :label="tag"
          :value="tag"
        />
      </el-select>
    </div>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <div class="row">
        <cvt-switch
                class="col-1"
                size="sm"
                color="active"
                type="circular"
                description-type="default"
                :initial-state="false"
                @switchToggle="(e) => saveBlockGlobally(e)"
        />
        <span class="col-2">{{
            $t(
                'editor.left_nav.pages.page_actions_istemplate.popover.save_page_globally',
            )
            }}</span>
        <div class="col-9 mr-0">
          <el-button @click="cancel">{{ $t('element.property.cta.cancel') }}</el-button>
          <el-button
              type="primary"
              :disabled="isSaving"
              @click="updateAndConfirm">{{ $t('element.property.cta.confirm') }}</el-button>
        </div>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import tags from '../../lib/tags'
import * as _ from 'lodash'
import * as getters from '../../store/modules/getters'
import * as actions from '../../store/modules/actions'
import * as mutations from '../../store/modules/mutations'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  data () {
    return {
      tags,
      isSaving: false,
      globalSaveBlockFlag: false
    }
  },
  computed: {
    ...mapState({
      theme: ({ theme }) => theme,
    }),
    ...mapGetters({
      visible: getters.SAVE_BLOCK_VISIBLE,
      block: getters.SAVE_BLOCK_DATA,
      vnode: getters.SAVE_BLOCK_VNODE,
      selected: getters.SELECTOR_SELECTED,
      config: getters.AUTH_GET_USER_CONFIG,
      isStandalonePopUpActive: getters.STANDALONE_POPUP_ACTIVE,
      cPopupBlock: getters.SDA_POPUP_FIREBASE_DATA,
      currentProductVersionNumber: getters.CURRENT_PRODUCT_VERSION_NUMBER,
      isGridBuilder: getters.IS_GRID_BUILDER,
    }),
    themeColors () {
      return {
        ...this.theme.colors,
        ...this.theme.blockColors
      }
    },
  },
  methods: {
    ...mapMutations({
      close: mutations.SAVE_BLOCK_HIDE,
      setData: mutations.SAVE_BLOCK_SET_DATA,
      updateData: mutations.SAVE_BLOCK_UPDATE_DATA,
      setVnode: mutations.SAVE_BLOCK_SET_VNODE,
    }),
    ...mapActions({
      confirm: actions.SAVE_BLOCK_CONFIRM,
      confirmPopup: actions.SAVE_POPUP_BLOCK_CONFIRM,
      cancel: actions.SAVE_BLOCK_CANCEL
    }),
    async updateAndConfirm () {
      this.block.isGridBuilder = this.isGridBuilder
      this.updateData(this.block)

      try {
        this.isSaving = true
        if (this.isStandalonePopUpActive && this.cPopupBlock) {
          await this.confirmPopup({
            popupWidth: this.cPopupBlock.data?.props?.popupWidth,
            popupHeight: this.cPopupBlock.data?.props?.popupHeight,
            isFullScreen: this.cPopupBlock.data?.props?.isFullScreen,
            closeButtonPosition: this.cPopupBlock.data?.props?.closeButtonPosition,
          })
          return
        }
        await this.confirm(this.globalSaveBlockFlag)
      }finally {
        this.isSaving = false
        this.globalSaveBlockFlag = false
      }
    },
    saveBlockGlobally(e) {
      if (e === 'Active') this.globalSaveBlockFlag = true
      else if (e === 'Inactive') this.globalSaveBlockFlag = false
    },
    open () {
      this.setVnode(this.selected.path())
      if (this.currentProductVersionNumber < 4) {
        this.computeColorIdsFromChildElements()
      }
      this.updateData({
        siteVersion: this.currentProductVersionNumber
      })
    },
    computeColorIdsFromChildElements() {
      let colors = this.selected.internalThemeColors() || {}
      let overlayColors = this.getOverlayColorsFromChildElements(
        (block) => block.data.props.background?.coloroverlay
      ) || {}
      let backgroundShapeColors = this.getOverlayColorsFromChildElements(
        (block) => block.data.props.bgShape?.fillColor
      ) || {}
      this.updateData({
        colors: _.merge(colors, overlayColors, backgroundShapeColors)
      })
    },
    getOverlayColorsFromChildElements(selectCallback) {
      let colors = {}
      let colorIds = []

      const block = this.selected?.fbNode
      const blockChildren = block?.children || []

      colorIds = _.chain(blockChildren)
        .findAllRecurs(n => selectCallback(n))
        .map(n => selectCallback(n))
        .value()

      if(selectCallback(block)) {
        colorIds.unshift(selectCallback(block))
      }

      colors = _.chain(this.themeColors)
        .pick(colorIds)
        .mapValues(c => ({
          a: c.alpha(),
          hex: c.hex().toLowerCase()
        }))
        .value()

      return colors
    }
  }
}
</script>

<style lang="scss">
  .save-block-title {
    font-size: 1.5em;
    font-weight: bold;
  }
</style>
