import loadEventTracking from '../../lib/loadEventTrackingScript'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

function AppEventTracking(...args) {
  if (!window.eventTracking) {
    return console.debug('EventTracking is not currently installed', ...args)
  }
  return window.eventTracking
}

export default {
  state: {
    loaded: false,
  },
  getters: {},
  mutations: {
    [mutations.EVENT_TRACKING_LOADED](state, loaded) {
      state.loaded = loaded
    },
  },
  actions: {
    async [actions.EVENT_TRACKING_BOOT](
      { state, rootGetters, commit },
      currentRoute,
    ) {
      if (state.loaded) {
        return
      }
      const config = rootGetters[getters.AUTH_GET_USER_CONFIG]
      if (!config.EVENT_TRACKING_URLS.length) {
        return
      }

      const currentRouteName = currentRoute?.name
      if (currentRouteName && currentRouteName.includes('enterprise')) {
        await loadEventTracking(config.EVENT_TRACKING_URLS)
        commit(mutations.EVENT_TRACKING_LOADED, true)
        AppEventTracking()
      }
    },
  },
}
