
<cvt-collapse :accordion="true" :theme="mode === 'LIGHT' ? 'light' : 'dark'" :prepend-chevron="prependChevron">
  <cvt-collapse-item :title="$t('elements.properties.background.color')" name="color" :initial-active="true">
    <colors alpha :mode="mode"></colors>
  </cvt-collapse-item>

  <cvt-collapse-item :title="$t('elements.properties.spacing.label')" name="spacing">
    <spacing-ctrl></spacing-ctrl>
  </cvt-collapse-item>

  <cvt-collapse-item :title="$t('elements.properties.visibility.label')" name="visibility">
    <visibility-ctrl :mode="mode"></visibility-ctrl>
  </cvt-collapse-item>

  <cvt-collapse-item :title="$t('elements.properties.advanced')" name="advanced">
    <div class="row">
      <div class="col align-items-center text-capitalize">
        <h6 :class="textColorClass">{{ $t("element.property.column.gutters") }}</h6>
      </div>
      <div class="col-12">
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="noGutters"
          :mode="mode"
          @switchToggle="() => onSwitchToggle('noGutters')"
        ></cvt-switch>
      </div>
    </div>

    <div class="divider"></div>

    <div class="row">
      <div class="col align-items-center text-capitalize">
        {{ $t("elements.properties.border.radius") }}
      </div>
      <div class="col-12">
        <resize size-key="borderRadius" :min="0" :step="5" :max="100" :label="$t('elements.properties.border.radius')" hide-label></resize>
      </div>
    </div>
  </cvt-collapse-item>
</cvt-collapse>
