import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'

export default {
  state: {
    visible: false,
    launcher: null,
    target: null
  },
  getters: {
    [getters.ELEMENT_LAUNCHER_VISISBLE] ({visible}) {
      return visible
    },
    [getters.ELEMENT_LAUNCHER_TARGET] ({target}) {
      return target
    }
  },
  mutations: {
    [mutations.ELEMENT_LAUNCHER_TOGGLE] (state, visible) {
      state.visible = visible

      if (!visible) {
        state.target = null
      }
    },
    [mutations.ELEMENT_LAUCHER_INIT] (state, launcher) {
      state.launcher = launcher
    },
    [mutations.ELEMENT_LAUCHER_TETHER] (state, target) {
      state.visible = true
      state.target = target
      state.launcher.tether(target)
    }
  },
  actions: {
    [actions.ELEMENT_LAUNCHER_INSERT] ({state}, payload) {
      state.visible = false
      state.target.insert(new payload.node())
    }
  }
}
