export const flexAlignmentClassMapCol = {
  topLeft: 'align-items-start justify-content-start',
  topCenter: 'align-items-center justify-content-start',
  topRight: 'align-items-end justify-content-start',

  middleLeft: 'align-items-start justify-content-center',
  middleCenter: 'align-items-center justify-content-center',
  middleRight: 'align-items-end justify-content-center',

  bottomLeft: 'align-items-start justify-content-end',
  bottomCenter: 'align-items-center justify-content-end',
  bottomRight: 'align-items-end justify-content-end'
}

export const flexAlignmentClassMapRow = {
  topLeft: 'align-items-start justify-content-start',
  topCenter: 'align-items-start justify-content-center',
  topRight: 'align-items-start justify-content-end',

  middleLeft: 'align-items-center justify-content-start',
  middleCenter: 'align-items-center justify-content-center',
  middleRight: 'align-items-center justify-content-end',

  bottomLeft: 'align-items-end justify-content-start',
  bottomCenter: 'align-items-end justify-content-center',
  bottomRight: 'align-items-end justify-content-end'
}

export const flexAlignmentClassMap = {
  alignLeft: 'justify-content-start',
  alignCenter: 'justify-content-center',
  alignRight: 'justify-content-end',
  constrainTop: 'align-items-start',
  constrainCenter: 'align-items-center',
  constrainBottom: 'align-items-end'
}

export const flexAlignmentClassMapReverse = {
  'justify-content-start': 'alignLeft',
  'justify-content-center': 'alignCenter',
  'justify-content-end': 'alignRight',
  'align-items-start': 'constrainTop',
  'align-items-center': 'constrainCenter',
  'align-items-end': 'constrainBottom'
}

export function defaultFlexAlignment () {
  return {
    topLeft: false,
    topCenter: false,
    topRight: false,
    middleLeft: false,
    middleCenter: false,
    middleRight: false,
    bottomLeft: false,
    bottomCenter: false,
    bottomRight: false,
    column: false
  }
}

export default function flexAlingment (alignment) {
  let flex = {
    'd-flex': true,
    'flex-column': alignment.column === true,
    'flex-wrap': !alignment.column
  }

  let alignmentMap = alignment.column === true ? flexAlignmentClassMapCol
                                               : flexAlignmentClassMapRow

  // console.log(flex, alignment)

  for (let key in alignment) {
    if (alignment[key] === true && key !== 'column') {
      return {
        ...flex,
        [alignmentMap[key]]: true
      }
    }
  }

  return {}
}
