<template>
  <div v-if="isAdmin">
    <h4 class="admin-label">{{$t('launch.tab.control.heading.add_categories')}}</h4>

    <el-select
      v-model="categories"
      multiple
      filterable
      allow-create
      placeholder="Select categories"
    >
      <el-option
        v-for="op in allCategories"
        :key="op.id"
        :label="op.label"
        :value="op.label"
      />
    </el-select>
  </div>
</template>

<script>
import Vue from 'vue'
import * as getters from '../../../../store/modules/getters'
import * as actions from '../../../../store/modules/actions'
import { V1Sites } from '../../../../store/services/api'
import { mapGetters, mapActions } from 'vuex'
const sitesAPI = new V1Sites();

export default Vue.extend({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Categories',
  data () {
    return {
      categories: []
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN,
      landingPageSiteData: getters.SITE_GET_DATA,
      allCategories: getters.GET_ALL_CATEGORIES,
    })
  },
  watch: {
    categories (newCategories, oldCategories) {
      this.onCategoryChanged(newCategories, oldCategories)
    }
  },
  mounted () {
    this.mapSiteCategories()
  },
  created(){
    this.fetchAllCategories()
  }, 
  methods: {
    ...mapActions({
      landingPageSiteUpdateCategories: actions.SITE_UPDATE_CATEGORIES,
      fetchAllCategories: actions.FETCH_ALL_CATEGORIES
    }),
    onCategoryChanged(newCategories, oldCategories) {
      this.landingPageSiteUpdateCategories({
        id: this.landingPageSiteData?.id,
        categories: newCategories.map(id => ({ id }))
      })
    },
    async mapSiteCategories () {
      const { data } = await sitesAPI.getCategories(this.landingPageSiteData?.id)
      this.categories = data
    }
  }
})
</script>

<style scoped lang="scss">
</style>
