import {VDomCommands} from "./VDomCommand";
import {CommandEventArgs, FBVNodeComponent, PageEngineComponent} from "../../../types";

export class PageEngineCommands extends VDomCommands {
    constructor(pageEngineVNodeId="page-engine") {
        super(pageEngineVNodeId);
    }

    get$emitData(): CommandEventArgs {
        return {name: 'PageEngineCommand', forceBuild: false}
    }

    get firebaseRef () {
        return this.getVueComponent()?.firebaseRef?.()
    }

    get clonedVDom (): any[] {
        return (this.getVueComponent() as PageEngineComponent)?.clonedVDom
    }

    get $nextTick () {
        return this.getVueComponent()?.$nextTick
    }

    get $children(): FBVNodeComponent[] {
        return this.getVueComponent()?.$children!
    }
}