import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import { CvtIcon } from './icon/icon'
import * as getters from "../../../builder/src/js/store/modules/getters"
import { mapGetters,mapState } from '../../../builder/node_modules/vuex'
import Vue from '../../lib/utils/vue'
Vue.use(VueEmotion)

let Spacing, Radii
let textFontWeight, textLineHeight, backgroundColor, textFontSize, color

const PItemList = styled('ul')`
  display: flex;
  justify-content: center;
  list-style-type: none;
`

const getPItem = () => { return styled('li')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.SPACING_4}px ${Spacing.SPACING_8}px;
  cursor: pointer;  
  text-align: center;
  margin: 0 ${Spacing.SPACING_8}px;
  border-radius: ${Radii.RADIUS_PX_4}px;

  ${(props) => {
    let fontSize = textFontSize('sm')
    let fontWeight = textFontWeight()
    let lineHeight = textLineHeight('sm')
    let bgColor = props.background
      ? backgroundColor({ themeing: 'dark' }, 100)
      : ''

    return fontSize + fontWeight + lineHeight + bgColor
  }}

  &.disabled {
    pointer-events: none; //This makes it not clickable
    opacity: 0.6; //This grays it out to look disabled
  }

  &.active {
    cursor: default;
    ${(props) => {
      let bgColor = ''
      let textColor = ''

      if (props.background) {
        bgColor = backgroundColor({ themeing: 'primary' }, 500)
        textColor = color({ themeing: 'light' })
      } else {
        bgColor = 'background-color: transparent;'
        textColor = color({ themeing: 'primary' }, 500)
      }

      return bgColor + textColor
    }}
  }
`
}
export default {
  name: 'CvtPagination',
  components: {},
  props: {
    pagerCount: {
      type: Number,
      required: false,
      default: 3
    },
    total: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    background: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { totalPages: Math.ceil(this.total / this.perPage) }
  },
  watch: {
    perPage() {
      this.totalPages = Math.ceil(this.total / this.perPage)
    },
    total() {
      this.totalPages = Math.ceil(this.total / this.perPage)
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      color: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
    }),
    ...mapState('globalTheme', {
      Spacing: ({globalTheme}) => globalTheme.Spacing,
      Radii: ({globalTheme}) => globalTheme.Radii
    }),
    startPage() {
      if (this.currentPage === 1) {
        return 1
      }

      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.pagerCount + 1 <= 0
          ? 1
          : this.totalPages - this.pagerCount + 1
      }

      if (
        this.currentPage - Math.floor(this.pagerCount / 2) >= 1 &&
        this.currentPage + Math.floor(this.pagerCount / 2) <= this.totalPages
      ) {
        return this.currentPage - Math.floor(this.pagerCount / 2)
      } else {
        if (this.currentPage - Math.floor(this.pagerCount / 2) < 1) return 1
        if (
          this.currentPage + Math.floor(this.pagerCount / 2) >
          this.totalPages
        ) {
          return this.totalPages - this.pagerCount + 1 <= 0
            ? 1
            : this.totalPages - this.pagerCount + 1
        }
      }
    },
    endPage() {
      return Math.min(this.startPage + this.pagerCount - 1, this.totalPages)
    },
    pages() {
      const range = []
      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        })
      }
      return range
    },
    isInFirstPage() {
      return this.currentPage === 1
    },
    isInLastPage() {
      return this.currentPage === this.totalPages
    }
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1)
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1)
    },
    onClickPage(page) {
      this.$emit('pagechanged', page)
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1)
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages)
    },
    isPageActive(page) {
      return this.currentPage === page
    }
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii

    textFontWeight = this.textFontWeight
    textLineHeight = this.textLineHeight
    color = this.color
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
  },
  render: function (h) {
    const PItem = getPItem()
    return (
      <div class='vtc-pagination'>
        <nav aria-label='Page navigation example'>
          <PItemList>
            <PItem
              class={[this.isInFirstPage ? 'disabled' : '']}
              onClick={() => this.onClickPreviousPage()}
              aria-label='Go to previous page'
              background={this.background}
            >
              <CvtIcon icon='angle-left'></CvtIcon>
            </PItem>

            {this.pages.map((page) => {
              return (
                <PItem
                  class={[this.isPageActive(page.name) ? 'active' : '']}
                  onClick={() => this.onClickPage(page.name)}
                  aria-label={`Go to page number ${page.name}`}
                  background={this.background}
                >
                  {page.name}
                </PItem>
              )
            })}

            <PItem
              class={[this.isInLastPage ? 'disabled' : '']}
              onClick={() => this.onClickNextPage()}
              aria-label='Go to next page'
              background={this.background}
            >
              <CvtIcon icon='angle-right'></CvtIcon>
            </PItem>
          </PItemList>
        </nav>
      </div>
    )
  }
}
