import { mapActions, mapGetters } from 'vuex'
import * as actions from '../../../../store/modules/actions'
import * as getters from '../../../../store/modules/getters'
import { Notification } from 'element-ui'
import CustomHostNames from '../../CustomHostNames/CustomHostNames.vue'
import { cvtRouteNodes } from '@/js/utils/routes'

export default {
  name: 'ConfigDns',
  components: {
    CustomHostNames,
  },
  props: {},
  data() {
    return {
      loading: false,
      customHostNames: [],
      envUrls: [
        {
          environment: 'Staging',
          url: '',
          label: 'Define your staging environment URL',
          hint: 'staging.builder.yourapp.com',
        },
        {
          environment: 'Production',
          url: '',
          label: 'Define your production environment URL',
          hint: 'production.builder.yourapp.com',
        },
        {
          environment: 'CDN',
          url: '',
          label: 'Create custom DNS CNAME record for your customers to alias',
          hint: 'pages.yourapp.com',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      dnsConfig: getters.CUSTOM_HOST_NAMES,
      dnsRecords: getters.DNS_RECORDS,
    }),
  },
  methods: {
    ...mapActions({
      loadCustomHostNames: actions.LOAD_CUSTOM_HOST_NAMES,
      verifyHostNames: actions.VERIFY_CUSTOM_HOST_NAMES,
      fetchDnsRecord: actions.FETCH_DNS_RECORD,
    }),
    async verifyCustomHostnames(): void {
      this.loading = true

      await this.verifyHostNames()
        .then(() => {
          this.loading = false
          Notification.success({
            title: 'Success',
            message: 'Custom Host Name Verified',
            position: 'bottom-right',
          })

          this.$router.push({
            name: cvtRouteNodes.enterpriseIntegrateBuilderVerifyHostname,
          })
        })
        .catch((err) => {
          this.loading = false
          Notification.error({
            title: 'Woops',
            message: err.response.data.errors[0].message,
            position: 'bottom-right',
          })
        })
    },
    async loadDnsRecords() {
      let result = []
      await this.fetchDnsRecord()

      if (this.dnsRecords.length) {
        this.envUrls.forEach((env) => {
          this.dnsRecords.forEach((record) => {
            if (
              record.environment.toLowerCase() === env.environment.toLowerCase()
            ) {
              result.push({
                ...env,
                url: record.url,
              })
              return
            }
          })
        })

        this.envUrls = result
      }
    },
  },
  async created() {
    await this.loadDnsRecords()

    await this.loadCustomHostNames()
    this.customHostNames = this.dnsConfig.hostnames
  },
}
