import { VueEmotion, styled } from '@egoist/vue-emotion'
import truncate from 'lodash/truncate'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import Vue from '../../../lib/utils/vue'
import CvtButton from '../../molecules/Button'
import CvtPopover from '../../molecules/Popover'
import { CvtIcon } from '../../molecules/icon/icon'
import { CvtTooltip } from '../../molecules/tooltip/CvtTooltip'

Vue.use(VueEmotion)

let Spacing, Radii, Border, addPageBtnText

let textFontWeight,
  borderColor,
  backgroundColor,
  textFontSize,
  textLineHeight,
  color

const getExpandedPages = () => styled('div')`
  overflow: auto;
  padding: ${Spacing.SPACING_24}px ${Spacing.SPACING_12}px
    ${Spacing.SPACING_24}px ${Spacing.SPACING_12}px;
  border-left: ${Border.SIZE_1}px solid;

  ${(props) => {
    let style = ''
    if (!props.pagesSidePanelLeft) return style
    style += `z-index: 200;`
    style += `position: fixed;`
    style += `left: 0px;`
    style += `top: ${props.height}px;`
    style += `height:100%;`
    style += `width:265px;`
    style += `background-color:white;`
    style += `box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.25);`
    style += `padding-top: ${Spacing.SPACING_18}px;`
    return style
  }}

  ${(props) =>
    props.mode == 'DARK'
      ? borderColor({ themeing: 'dark' }, 500)
      : borderColor({ themeing: 'dark' }, 100)};

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${Radii.RADIUS_PX_8}px;
    ${(props) =>
      props.mode == 'DARK'
        ? backgroundColor({ themeing: 'dark' }, 600)
        : backgroundColor({ themeing: 'dark' }, 100)}
  }
`

const getPage = () => styled('div')`
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  border-bottom: ${Border.SIZE_1}px solid;
  padding: ${Spacing.SPACING_12}px 0;
  ${(props) => {
    return props.pagesSidePanelLeft
      ? `width: 240px;height:32px;`
      : `width: 300px;`
  }}
  ${(props) =>
    props.mode == 'DARK'
      ? borderColor({ themeing: 'dark' }, 600)
      : borderColor({ themeing: 'dark' }, 100)}

  &::before {
    content: '';
    position: absolute;
    left: -23px;
    width: calc(100% + 46px);
    height: calc(100% + 2px);
    z-index: -1;
    ${(props) =>
      props.active
        ? props.PagesSidePanelLeft
          ? 'background-color:#E5F2FD'
          : 'background-color:#F0F2F6'
        : ``}
  }
  &.ghost {
    border-top: 1px dashed;
    border-bottom: 1px dashed;
    ${(props) =>
      props.mode == 'DARK'
        ? borderColor({ themeing: 'dark' }, 300)
        : borderColor({ themeing: 'dark' }, 400)}
  }

  & .show-on-hover {
    display: none;
    cursor: pointer;
  }

  & .hide-on-hover {
    display: block;
  }

  &:hover {
    &::before {
      content: '';
      position: absolute;
      left: -24px;
      width: calc(100% + 48px);
      height: calc(100% + 2px);
      z-index: -1;
      ${(props) =>
        props.mode == 'DARK'
          ? backgroundColor({ themeing: 'dark' }, 600)
          : backgroundColor({ themeing: 'dark' }, 100)}
    }

    .show-on-hover {
      display: block;
    }

    .hide-on-hover {
      display: none;
    }
  }
`

const PageIconWrapper = styled('span')``

const PageIcon = styled(CvtIcon)``

const getPageName = () => styled('span')`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${Spacing.SPACING_8}px 0;

  ${(props) => {
    return props.pagesSidePanelLeft
      ? `margin-left: 0px;`
      : `margin-left: ${Spacing.SPACING_16}px;`
  }}
  ${textFontSize('xl')}
  ${textLineHeight('lg')}
  ${textFontWeight('REGULAR')}
`

const getPageTitle = () => styled('span')`
  padding: ${Spacing.SPACING_8}px 0;
  ${textFontSize('lg')}
  ${textLineHeight('lg')}
  ${textFontWeight('LIGHT')}
  width: ${Spacing.SPACING_48 + Spacing.SPACING_2}px;
`

const PageActions = styled('span')`
  display: flex;
  margin-left: auto;
`

const getPageEdit = () => styled(CvtIcon)`
  margin-left: ${Spacing.SPACING_16}px;
`

const getPageSetting = () => styled(CvtIcon)`
  margin-left: ${Spacing.SPACING_12}px;
`

const getPageCopy = () => styled(CvtIcon)`
  margin-left: ${Spacing.SPACING_12}px;
`

const getPageDelete = () => styled(CvtIcon)`
  margin-left: ${Spacing.SPACING_12}px;
`
const getPageSave = () => styled(CvtIcon)`
  margin-left: ${Spacing.SPACING_12}px;
`
const getPageLayoutSwitch = () => styled(CvtIcon)`
  margin-left: ${Spacing.SPACING_12}px;
`

const getCreateBlankPageWrapper = () => styled('div')`
  display: flex;
  justify-content: center;
  padding: ${Spacing.SPACING_18}px;
`

const CreateBlankPageBtn = styled(CvtButton)``

const getBlankPageBtnAtTop = () => styled(CvtButton)`
  margin-left: ${Spacing.SPACING_8}px;
`

const getMoreActionsWrap = () => styled('div')`
  align-self: flex-end;
  padding: ${Spacing.SPACING_8}px 0;
  ${(props) => {
    return props.pagesSidePanelLeft
      ? `padding: ${Spacing.SPACING_8}px ${Spacing.SPACING_12}px;`
      : `padding: ${Spacing.SPACING_8}px 0;`
  }}
`

const getPopperContent = () => styled('div')`
  display: flex;
  align-items: center;
  ${textFontWeight('REGULAR')}
  ${textFontSize('md')}
  height: ${Spacing.SPACING_32}px;
  cursor: pointer;
  ${(props) => {
    let styles = `
      &:hover {
        ${
          props.mode === 'DARK'
            ? `${backgroundColor({ themeing: 'dark' }, 600)}`
            : `${backgroundColor({ themeing: 'primary' }, 100)} 
                ${color({ themeing: 'primary' }, 500)}`
        }
      }
    `
    return styles
  }}
`

const getPopperText = () => styled('span')`
  margin: 0 ${Spacing.SPACING_12}px;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  text-transform: capitalize;
`
const getPagesTitleDiv = () => styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${Spacing.SPACING_12}px;
`

const getStyledSlot = () => styled('div')`
  width: 160px;
`

const getBookmarkIcon = () => styled(CvtIcon)`
  margin-left: ${Spacing.SPACING_12}px;
`

export const CvtPages = {
  name: 'CvtPages',
  components: {
    CvtTooltip,
  },
  data() {
    return {}
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    pages: {
      type: Array,
      default: [],
    },
    activeId: {
      type: String,
      default: '',
    },
    allowDelete: {
      type: Boolean,
      default: false,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    allowDuplicate: {
      type: Boolean,
      default: false,
    },
    allowSwap: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: true,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
    isSitePreview: {
      type: Boolean,
      default: false,
    },
    allowBookmark: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    if (this.$refs.popover) {
      this.$refs.popover.$el.addEventListener('click', this.stopPropagation)
      document.body.addEventListener('click', this.onClickOutsidePopover)
    }
  },
  beforeDestroy() {
    if (this.$refs.popover) {
      this.$refs.popover.$el.removeEventListener('click', this.stopPropagation)
      document.body.removeEventListener('click', this.onClickOutsidePopover)
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      iconConfig: ({ iconConfig }) => iconConfig,
      topNavConfig: ({ topNavConfig }) => topNavConfig,
      addPageBtnText: ({ globalTheme }) =>
        globalTheme.AddPageBtnText ? globalTheme.AddPageBtnText : '',
      PagesSidePanelLeft: ({ globalTheme }) =>
        globalTheme.PagesSidePanelLeft !== undefined
          ? globalTheme.PagesSidePanelLeft
          : false,
    }),
  },
  methods: {
    isActive(id) {
      return this.activeId === id
    },
    truncate(name) {
      return truncate(name, {
        length: 30,
        omission: '...',
      })
    },
    getAddPageBtnText() {
      return addPageBtnText ? addPageBtnText : this.$t('add_page.cta')
    },
    moreActionClicked(key, page, event) {
      if (key === 'edit') {
        this.$emit('edit', page)
      } else if (key === 'settings') {
         event.stopPropagation()
        this.$emit('settings', page)
      } else if (key === 'copy') {
        this.$emit('copy', page)
      } else if (key === 'delete') {
        this.$emit('delete', page)
      } else if (key === 'layout') {
        // If we dont stopPropagation, if closes the leftSideMenu which renders the confirm dialog.
        // so leftSideMenu gets destroyed before it opens the confirm dialog.
        event.stopPropagation()
        this.$emit('layout', page)
      } else if (key === 'save') {
        this.$emit('save', page)
      }

      this.onClickOutsidePopover()
    },
    onClickOutsidePopover() {
      if (this.$refs.popover && this.$refs.popover.$refs.tooltip)
        this.$refs.popover.$refs.tooltip.doClose()
    },
  },
  beforeMount() {},
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    color = this.textColor
    addPageBtnText = this.addPageBtnText
  },
  render: function (h) {
    const ExpandedPages = getExpandedPages()
    const Page = getPage()
    const PageName = getPageName()
    const PageTitle = getPageTitle()
    const PageEdit = getPageEdit()
    const PageSetting = getPageSetting()
    const PageCopy = getPageCopy()
    const PageDelete = getPageDelete()
    const PageSave = getPageSave()
    const PageLayoutSwitch = getPageLayoutSwitch()
    const CreateBlankPageWrapper = getCreateBlankPageWrapper()
    const pages = []
    const iconConfig = this.iconConfig?.leftSidebar?.pages
    const MoreActionsWrap = getMoreActionsWrap()
    const BlankPageBtnAtTop = getBlankPageBtnAtTop()
    const PopperText = getPopperText()
    const PopperContent = getPopperContent()
    const PagesTitleDiv = getPagesTitleDiv()
    const StyledSlot = getStyledSlot()
    const BookmarkIcon = getBookmarkIcon()

    this.pages.forEach((page) => {
      pages.push(
        <Page
          class="layout-section"
          id={page.pageName}
          mode={this.mode}
          active={this.isActive(page.id)}
          pagesSidePanelLeft={this.PagesSidePanelLeft}
        >
          <PageIconWrapper>
            {!this.PagesSidePanelLeft && <PageIcon icon="file"></PageIcon>}
          </PageIconWrapper>
          <PageName
            title={page.pageName}
            pagesSidePanelLeft={this.PagesSidePanelLeft}
            onClick={() => this.moreActionClicked('edit', page)}
          >
            {this.truncate(page.pageName)}
          </PageName>
          {!this.PagesSidePanelLeft &&
            !this.isTemplate &&
            !this.isSitePreview && (
              <PageActions>
                <span>
                  <PageSetting
                    id={page.pageName + '-page-settings'}
                    icon={iconConfig['cog'] || 'cog'}
                    nativeOnClick={(e) => this.$emit('settings', page)}
                  ></PageSetting>
                  <CvtTooltip
                    placement="top"
                    offset="5"
                    fallbackPlacement="right"
                    delay="100"
                    color="dark"
                    mode={this.mode}
                    noFade={true}
                    innerPadding={8}
                    ref="tooltip"
                    target={page.pageName + '-page-settings'}
                  >
                    {this.$t('elements.properties.settings')}
                  </CvtTooltip>
                </span>
                {this.allowBookmark && (
                  <span>
                    <BookmarkIcon
                      id={page.pageName + '-page-bookmark'}
                      icon={iconConfig['bookmark'] || 'heart'}
                      nativeOnClick={(e) => this.$emit('bookmark', page)}
                    ></BookmarkIcon>
                    <CvtTooltip
                      placement="top"
                      offset="5"
                      fallbackPlacement="right"
                      delay="100"
                      color="dark"
                      mode={this.mode}
                      noFade={true}
                      innerPadding={8}
                      ref="tooltip"
                      target={page.pageName + '-page-bookmark'}
                    >
                      {this.$t('elements.properties.sidebar.bookmark.text')}
                    </CvtTooltip>
                  </span>
                )}
                {this.allowDuplicate && (
                  <span>
                    <PageCopy
                      id={page.pageName + '-page-copy'}
                      icon={iconConfig['duplicate'] || 'copy'}
                      nativeOnClick={(e) => this.$emit('copy', page)}
                    ></PageCopy>
                    <CvtTooltip
                      placement="top"
                      offset="5"
                      fallbackPlacement="right"
                      delay="100"
                      color="dark"
                      mode={this.mode}
                      noFade={true}
                      innerPadding={8}
                      ref="tooltip"
                      target={page.pageName + '-page-copy'}
                    >
                      {this.$t('marketplace.context.copy.message')}
                    </CvtTooltip>
                  </span>
                )}
                {this.allowSwap && (
                  <span>
                    <PageLayoutSwitch
                      id={page.pageName + '-page-change-layout'}
                      icon={iconConfig['layout'] || 'far fa-sync-alt'}
                      nativeOnClick={(e) => this.$emit('layout', page, e)}
                    ></PageLayoutSwitch>
                    <CvtTooltip
                      placement="top"
                      offset="5"
                      fallbackPlacement="right"
                      delay="100"
                      color="dark"
                      mode={this.mode}
                      noFade={true}
                      innerPadding={8}
                      ref="tooltip"
                      target={page.pageName + '-page-change-layout'}
                    >
                      {this.$t('elements.properties.change_layout')}
                    </CvtTooltip>
                  </span>
                )}
                {this.allowDelete && page.name !== 'index' && (
                  <span>
                    <PageDelete
                      id={page.pageName + '-page-delete'}
                      icon={iconConfig['delete'] || 'trash'}
                      nativeOnClick={(e) => this.$emit('delete', page)}
                    ></PageDelete>
                    <CvtTooltip
                      placement="top"
                      offset="5"
                      fallbackPlacement="right"
                      delay="100"
                      color="dark"
                      mode={this.mode}
                      noFade={true}
                      innerPadding={8}
                      ref="tooltip"
                      target={page.pageName + '-page-delete'}
                    >
                      {this.$t(
                        'editor.right_nav.theme.color_picker.delete.btn',
                      )}
                    </CvtTooltip>
                  </span>
                )}
                {this.isAdmin && (
                  <span>
                    <PageSave
                      id={page.pageName + '-page-save'}
                      icon={iconConfig['save'] || 'save'}
                      nativeOnClick={(e) => this.$emit('save', page)}
                    ></PageSave>
                    <CvtTooltip
                      placement="top"
                      offset="5"
                      fallbackPlacement="right"
                      delay="100"
                      color="dark"
                      mode={this.mode}
                      noFade={true}
                      innerPadding={8}
                      ref="tooltip"
                      target={page.pageName + '-page-save'}
                    >
                      {this.$t('editor.right_nav.theme.color_picker.save.btn')}
                    </CvtTooltip>
                  </span>
                )}
              </PageActions>
            )}

          {/* Freshworks LeftSideBar Actions */}
          {this.PagesSidePanelLeft && (
            <PageActions>
              <MoreActionsWrap pagesSidePanelLeft={this.PagesSidePanelLeft}>
                <PageEdit
                  id={page.id + '-more-actions'}
                  icon={
                    this.iconConfig['leftSidebar']['pages']['moreAction'] ||
                    'ellipsis-v'
                  }
                  onClick={(e) => {
                    e.stopPropagation()
                    return
                  }}
                ></PageEdit>

                <CvtPopover
                  ref="popover"
                  placement="bottom"
                  target={page.id + '-more-actions'}
                  triggers="click"
                  color="light"
                  mode="light"
                  innerPadding={8}
                  borderRadius={Radii.RADIUS_PX_8}
                >
                  <StyledSlot slot="popper">
                    <PopperContent
                      mode={this.mode}
                      onClick={(e) =>
                        this.moreActionClicked('settings', page, e)
                      }
                    >
                      <PopperText>
                        {this.$t(
                          'editor.left_nav.pages.page_actions_istemplate.popover.settings',
                        )}
                      </PopperText>
                    </PopperContent>

                    {this.allowDuplicate && (
                      <PopperContent
                        mode={this.mode}
                        onClick={(e) => this.moreActionClicked('copy', page, e)}
                      >
                        <PopperText>
                          {this.$t(
                            'editor.left_nav.pages.page_actions_istemplate.popover.copy',
                          )}
                        </PopperText>
                      </PopperContent>
                    )}
                    {this.allowSwap && (
                      <PopperContent
                        mode={this.mode}
                        onClick={(e) =>
                          this.moreActionClicked('layout', page, e)
                        }
                      >
                        <PopperText>
                          {this.$t(
                            'editor.left_nav.pages.page_actions_istemplate.popover.swap_page_layout',
                          )}
                        </PopperText>
                      </PopperContent>
                    )}
                    {this.allowDelete && page.name !== 'index' && (
                      <PopperContent
                        mode={this.mode}
                        onClick={(e) =>
                          this.moreActionClicked('delete', page, e)
                        }
                      >
                        <PopperText>
                          {this.$t(
                            'editor.left_nav.pages.page_actions_istemplate.popover.delete',
                          )}
                        </PopperText>
                      </PopperContent>
                    )}
                    {this.isAdmin && (
                      <PopperContent
                        mode={this.mode}
                        onClick={(e) => this.moreActionClicked('save', page, e)}
                      >
                        <PopperText>
                          {this.$t(
                            'editor.left_nav.pages.page_actions_istemplate.popover.save_page',
                          )}
                        </PopperText>
                      </PopperContent>
                    )}
                  </StyledSlot>
                </CvtPopover>
              </MoreActionsWrap>
            </PageActions>
          )}
        </Page>,
      )
    })

    return (
      <ExpandedPages
        mode={this.mode}
        pagesSidePanelLeft={this.PagesSidePanelLeft}
        height={
          this.topNavConfig.theme?.height
            ? this.topNavConfig.theme?.height
            : this.Spacing.SPACING_64
        }
      >
        {
          //TODO is the following block used and or needed?
        }
        {this.PagesSidePanelLeft && (
          <PagesTitleDiv>
            <PageTitle pagesSidePanelLeft={this.PagesSidePanelLeft}>
              {this.$t('editor.left_nav.manage_pages')}
            </PageTitle>
            <BlankPageBtnAtTop
              mode={this.mode}
              text={this.getAddPageBtnText()}
              icon={
                this.iconConfig['leftSidebar']['pages']['add-page'] || 'plus'
              }
              outlined={true}
              onClick={() => this.$emit('createNew')}
              size="sm"
              color={this.mode.toLowerCase()}
            ></BlankPageBtnAtTop>
          </PagesTitleDiv>
        )}
        {pages}
        {this.allowCreate && !this.PagesSidePanelLeft && (
          <CreateBlankPageWrapper>
            <CreateBlankPageBtn
              mode={this.mode}
              icon={this.iconConfig['leftSidebar']['pages']['plus'] || 'plus'}
              text={this.getAddPageBtnText()}
              onClick={() => this.$emit('createNew')}
            ></CreateBlankPageBtn>
          </CreateBlankPageWrapper>
        )}
      </ExpandedPages>
    )
  },
}
