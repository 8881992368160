
<transition name="fade">
  <div v-show="visible" class="fullscreen-gallery">

      <div class="fullscreen-gallery__close d-flex justify-content-center align-items-center">
        <i class="cvt-icon-delete" @click.prevent.stop="close"></i>
      </div>

      <div class="row text-center auto pt-5 bg-overlay">
        <div class="col">
          <h2>Select a block type</h2>
          <p><i>Click a block to add it to the page</i></p>
        </div>
      </div>
      <div class="d-flex p-3 justify-content-center align-items-center flex-wrap bg-overlay">
        <h6 v-for="(tag) in tags" :key="tag" class="badge-wrapper mr-2" @click.prevent.stop="activate(tag)">
          <span class="badge badge-pill" :class="active(tag)" v-text="tag"></span>
        </h6>
      </div>

      <transition-group name="fade" class="mx-5 mt-3 fullscreen-gallery__list" tag="div">
        <block-item v-for="(block) in filteredBlocks" :key="block['.key']" :block="block" @selected="useBlock" @remove="removeBlock"></block-item>
      </transition-group>

  </div>
</transition>
