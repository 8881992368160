import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'
import { Organization } from '../services/api'

const organizationAPI = new Organization()

export default {
    state: {
        tenantChatWidget: {}
    },
    getters: {
        [getters.CHAT_WIDGET_CONFIG]({ tenantChatWidget }) {
            return tenantChatWidget
        }
    },
    mutations: {
        [mutations.SET_CHAT_WIDGET_CONFIG](state, tenantChatWidget) {
          state.tenantChatWidget = tenantChatWidget
        }
    },
    actions: {
        async [actions.UPDATE_CHAT_WIDGET_CONFIG]({ commit }, data) {
            await organizationAPI.updateChatWidgetConfiguration(data).then((res) => {
                commit(mutations.SET_CHAT_WIDGET_CONFIG, data)
            })
        }
    }
}