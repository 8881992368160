<template>
  <div>
    <div class="d-flex">
      <strong class="mr-2">{{$t('elements.property.edit.background.color.label')}}</strong>
      <cvt-switch
        size="xs"
        color="active"
        type="circular"
        description-type="default"
        :initial-state="enableBackground"
        :mode="mode"
        @switchToggle="onToggleClicked"
      />
    </div>
    <div class="row" :class="{ 'pointer-events-none': !enableBackground }">
      <div class="col-12">
        <colors alpha :mode="mode"/>
      </div>
    </div>
  </div>
</template>

<script>
import Colors from "@/js/components/editor/theme/Colors.vue";
import { mapGetters, mapActions } from "vuex";
import * as _ from "lodash";
import * as getters from "@/js/store/modules/getters";
import * as actions from "@/js/store/modules/actions";

export default {
  name: "Background",
  components: { Colors },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED
    }),

    enableBackground: {
      get() {
        return _.get(this, 'selected.template.enableBackground', true)
      },
      set(value) {
        if (value === false) {
          this.updateProp({ key: 'themeColorId', value: null })
        }
        return this.updateProp({ key: 'template/enableBackground', value })
      },
    },
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
    }),

    onToggleClicked() {
      this.enableBackground = !this.enableBackground
    }
  }
};
</script>

<style scoped>

</style>