function rgbToHsl(r: number, g: number, b: number) {
  r /= 255
  g /= 255
  b /= 255

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h: number = 0,
    s: number,
    l: number = (max + min) / 2

  if (max === min) {
    h = s = 0
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }

  return { h: h * 360, s: s * 100, l: l * 100 }
}

export function getColorName(hexColor: string) {
  const hex = hexColor.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  const hsl = rgbToHsl(r, g, b)

  const prefixes = [
    'Cosmic',
    'Crystal',
    'Digital',
    'Electric',
    'Quantum',
    'Solar',
    'Cyber',
    'Neo',
    'Arctic',
    'Mystic',
  ]

  const colors = [
    'Ruby',
    'Coral',
    'Amber',
    'Gold',
    'Jade',
    'Emerald',
    'Aqua',
    'Sapphire',
    'Indigo',
    'Amethyst',
  ]

  const prefixIndex = Math.floor((hsl.s + hsl.l) % prefixes.length)
  const colorIndex = Math.floor((hsl.h / 360) * colors.length)

  return hsl.s > 50
    ? `${prefixes[prefixIndex]} ${colors[colorIndex]}`
    : colors[colorIndex]
}
