
<div identity="BackgroundColorOverlay">
  <div class="row">
    <div class="col">
      <strong>{{ $t("elements.properties.color.overlay") }}</strong>
    </div>
    <div class="col-3">
      <cvt-switch
        size="sm"
        color="active"
        type="circular"
        description-type="default"
        :initial-state="isOverlayOn"
        @switchToggle="() => repeatToggle()"
      />
    </div>
  </div>

  <colors
    v-if="bgOverlay" 
    alpha 
    :mode="mode" 
    :custom-options="customOptions"        
    @apply-color="applyBackgroundColor" 
    @apply-transparency="applyTransparency"
  />
</div>
