import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { styled } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from 'vuex'

import CvtButton from '../../molecules/Button'

Vue.use(VueI18n)

let textFontWeight, textFontSize, Spacing, FontSize, backgroundColor, Colors

const getIntegrationLogoWrap = () => styled('div')`
  background: #ffffff;
  display: flex;
  height: 150px;
  width: 150px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 30px;
`

const getIntegrationReadyWrap = () => styled('div')`
  width: 100%;
`

const getIntegrationReadyInnerWrap = () => styled('div')`
  background: ${Colors.BASIC[100]};
  padding: 45px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
`

const getIntegrationReadyText = () => styled('div')`
  font-size: 25px;
  margin-bottom: 35px;
  color: ${Colors.PRIMARY[500]};
`

const getIntegrationSuccessText = () => styled('div')`
  font-size: 14px;
  color: ${Colors.BASIC[500]};
`

const getButtonWrap = () => styled('div')`
  display: flex;
  justify-content: end;
`

const getButton = () => styled(CvtButton)`
  background-color: ${Colors.PRIMARY[500]};
`

// TODO: Refactor Component Names
export const IntegrationReady = {
  name: 'IntegrationReady',
  components: {},
  data() {
    return {}
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      selectedIntegration: getters.SELECTED_INTEGRATION,
    }),
    ...mapGetters('globalTheme', {
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
    }),
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      globalTheme: ({ globalTheme }) => globalTheme,
    }),
  },
  created() {
    textFontSize = this.textFontSize
    textFontWeight = this.textFontWeight
    Spacing = this.Spacing
    FontSize = this.FontSize
    backgroundColor = this.backgroundColor
    Colors = this.Colors
  },
  mounted() {
    this.onScrollInToView()
  },
  methods: {
    onScrollInToView() {
      const container = document.getElementById('authWrap')
      container?.scrollIntoView()
    },
  },
  render: function (h) {
    const IntegrationReadyWrap = getIntegrationReadyWrap()
    const IntegrationReadyInnerWrap = getIntegrationReadyInnerWrap()
    const IntegrationLogoWrap = getIntegrationLogoWrap()
    const IntegrationReadyText = getIntegrationReadyText()
    const IntegrationSuccessText = getIntegrationSuccessText()
    const ButtonWrap = getButtonWrap()
    const Button = getButton()

    return (
      <IntegrationReadyWrap>
        <IntegrationReadyInnerWrap>
          <IntegrationLogoWrap>
            <img
              style="height: 85%;"
              src={this.selectedIntegration.image}
              alt="logo"
            />
          </IntegrationLogoWrap>
          <IntegrationReadyText>Integration ready!</IntegrationReadyText>
          <IntegrationSuccessText>
            You have successfully created your integration.
          </IntegrationSuccessText>
        </IntegrationReadyInnerWrap>

        <ButtonWrap>
          <Button
            text="Finish"
            outlined={true}
            specialPadding="12px"
            color="primary"
            onClick={() => this.$emit("finished")}
          ></Button>
        </ButtonWrap>
      </IntegrationReadyWrap>
    )
  },
}
