<template>
  <div>
    <h5 class="mt-2 mb-4">{{ $t('launch.chat.header') }}</h5>

    <div v-if="getChatWidgetConfiguration" class="row mb-5 ml-2">
      <h6 :class="textColorClass">{{ $t('launch.chat.toggle') }}</h6>
      <cvt-switch
        size="sm"
        color="active"
        type="circular"
        description-type="default"
        :initial-state="chatToggleState"
        mode="LIGHT"
        @switchToggle="onSwitchToggle"
      />
      <div class="d-block w-100 text-black-50">
        <small class="css-2tg5nq">
          <a href="https://conversations-app.brevo.com" target="_blank"><cvt-icon icon="exclamation-circle" prefix-class="exclamation-circle-icon"/></a>
          {{ $t('launch.chat.hint') }}
        </small>
      </div>
    </div>
    <p v-if="!getChatWidgetConfiguration">
      {{ $t('launch.chat.disabled_message') }}
    </p>
  </div>
</template>

<script>
import { isArray } from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import * as actions from '../../../../store/modules/actions'
import * as getters from '../../../../store/modules/getters'
import { CookieHelper } from '../../../../store/services/cookieHelper'

export default {
  name: 'TenantChatWidget',
  data() {
    return {
      chatToggleState: false,
    }
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      chatWidgetConfig: getters.CHAT_WIDGET_CONFIG,
      site: getters.SITE_GET_DATA,
    }),
    ...mapState('globalTheme', {
      RadioButtonShape: ({ globalTheme }) =>
        globalTheme.RadioButtonShape !== undefined
          ? globalTheme.RadioButtonShape
          : '',
    }),
    textColorClass() {
      return { 'text-white': this.mode == 'DARK', 'mr-3': true, 'pt-1': true }
    },
    chatEnabled() {
      if (isArray(this.chatWidgetConfig)) {
        const getSiteEnabled = this.chatWidgetConfig.find(
          (c) => c.siteID === this.site.id,
        )
        return Boolean(getSiteEnabled?.siteID)
      } else {
        // backwards compatibility condition
        const isSameSiteChatEnabled =
          this.chatWidgetConfig?.siteID === this.site.id
        return Boolean(
          this.chatWidgetConfig &&
            Object.keys(this.chatWidgetConfig) &&
            this.chatWidgetConfig?.ConversationKey &&
            isSameSiteChatEnabled,
        )
      }
    },
    getChatWidgetConfiguration() {
      const session = CookieHelper('_pages_session=')
      const { chatWidgetConfiguration } = session
      return { ...chatWidgetConfiguration, siteID: this.site.id }
    },
  },
  created: async function () {
    this.chatToggleState = this.chatEnabled
  },
  methods: {
    ...mapActions({
      updateChatWidgetConfiguration: actions.UPDATE_CHAT_WIDGET_CONFIG,
    }),
    async onSwitchToggle() {
      await this.updateChatWidgetConfiguration(
        this.chatToggleState
          ? { siteID: this.site.id, remove: true }
          : this.getChatWidgetConfiguration,
      )
      this.chatToggleState = !this.chatToggleState
    },
  },
}
</script>
