import { styled } from '@egoist/vue-emotion'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import * as getters from '../../../builder/src/js/store/modules/getters'

let backgroundColor, FontFamily, Spacing, textColor, textFontSize, FontSize, FontWeight, Radii;

const getInfoContainer = () => styled('div')`
  font-family: ${FontFamily.DEFAULT};
  position: fixed;
  height: calc(100vh - 64px);
  width: 500px;
  right: 0;
  top: ${Spacing.SPACING_64}px;
  padding: ${Spacing.SPACING_24}px ${Spacing.SPACING_24}px;
  ${backgroundColor({ themeing: "dark"}, "WHITE")}
`

const getVideoIframe = () => styled('iframe')`
  width: 100%;
  height: 100%;
`

const getTemplateDetailsWrapper = () => styled('div')``

const getDetailsContainer = () => styled('div')`
  padding-top: ${Spacing.SPACING_48}px;
`

const getHeaderText = () => styled('h1')`
  margin: 0px;
  font-weight: ${FontWeight.BOLD};
  font-size: ${FontSize.FONT_SIZE_20}px;
`

const getVideoWrapper = () => styled('div')`
  height: 300px;
  padding-bottom: ${Spacing.SPACING_24}px;
`

export const CvtPreviewDetails = {
  name: 'CvtSitePreviewInfo',
  props: {
    description: {
      type: String,
      default: '',
    },
    videoPath: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('globalTheme', {
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing, 
      Radii: ({ globalTheme }) => globalTheme.Radii, 
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
    })
  },
  created(){
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    backgroundColor = this.backgroundColor
    textColor = this.textColor
    textFontSize = this.textFontSize
    FontSize = this.FontSize
    FontWeight = this.FontWeight
    Radii = this.Radii
  },
  render(h) {
    const InfoContainer = getInfoContainer()
    const VideoWrapper = getVideoWrapper()
    const VideoIframe = getVideoIframe()
    const TemplateDetailsWrapper = getTemplateDetailsWrapper()
    const HeaderText = getHeaderText()
    const DetailsContainer = getDetailsContainer()

    return (
      <InfoContainer>
        {this.videoPath && 
          <VideoWrapper>
            <VideoIframe src={this.videoPath} />
          </VideoWrapper>
        }
        <TemplateDetailsWrapper>
          <HeaderText>Here are some of the most important insights from this Template:</HeaderText>
          <DetailsContainer 
            domPropsInnerHTML={this.description}>
          </DetailsContainer>
        </TemplateDetailsWrapper>
      </InfoContainer>
    )
  },
}
