
<div
  :id="vnodeId"
  :data-vnode-id="vnodeId"
  class="cvt-editor-image"
  :class="classes"
  draggable="true"
  @click="select"
  @mouseover="target"
  @contextmenu.prevent.stop="showContextMenu"
>
  <div :class="imgWrapperClasses">
    <a v-if="href" :href="href" target="_blank">
      <img
        class="img-fluid"
        :class="imgClasses"
        :style="imgStyles"
        :src="altSrc"
        :alt="alt"
      />
    </a>
    <img
      v-else
      class="img-fluid"
      :class="imgClasses"
      :style="imgStyles"
      :src="altSrc"
      :alt="alt"
    />
  </div>
  <template v-if="showActionButtonsOnClick && !isGridBuilder">
    <!--THis is only here because of freshwork in v1 gridbuilder,
    once they migrate to v2 or v3, it will become irrelevant-->
    <action-buttons
      v-if="selected"
      @duplicate="duplicateAndValidate"
      @remove="removeAndValidate"
    />
  </template>
</div>
