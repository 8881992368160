import { styled, VueEmotion } from '@egoist/vue-emotion'
import * as axios from 'axios'
import { mapActions, mapGetters, mapState } from '../../../builder/node_modules/vuex'
import * as actions from '../../../builder/src/js/store/modules/actions'
import * as getters from '../../../builder/src/js/store/modules/getters'
import Vue from '../../lib/utils/vue'
import { CRMGROW } from '../constants'
import { CvtIcon } from './icon/icon'

Vue.use(VueEmotion)

let Spacing, 
  FontFamily, 
  Colors, 
  Border, 
  FontSize, 
  LineHeight, 
  FontWeight,
  textFontSize,
  textFontWeight,
  textLineHeight,
  backgroundColor,
  color,
  textColor

const getAvatarWrapper = () => styled('div')`
  position: relative;
  ${(props) => {
    let style = `height: ${props.height-(props.height/2)}px;`
    style += `width: ${props.height-(props.height/2)}px;`
    return style
  }}
  background-color: transparent;
`

const getCircleBackgroundContainer = () => styled('div')`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  ${(props) => { // Functional code snippet for modes
    // let weight = 'WHITE'
    // weight = props.mode === 'DARK' ? 700 : 'WHITE'
    // let bg = props.backgroundColor === 'dark' ? 'light' : 'dark'
    // let style = backgroundColor({ themeing: bg }, weight)
    // return style
    if( props.imgURL == '' ){
      return 'background-color: #5e35b1 !important;';
    }
  }}
`

const getUserNameInitialsContainer = () => styled('div')`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  font-family: ${FontFamily?.DEFAULT};
  text-align: center;
  color: #fff !important;
  ${(props) => { // Functional code snippet for modes
    // let weight = 'WHITE'
    // weight = props.mode === 'LIGHT' ? 'WHITE' : 500
    // return color({ themeing: props.color }, weight)
  }}
`

const getAvatarIcon = () => {
  return styled('CvtIcon')`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    color: #fff;
    `
}

const getAvatarImageContainer = () => styled('img')`
  max-width:100%;
  max-height:100%;
  clip-path: circle();
`

export default {
  name: 'UserAvatar',
  components: {
    CvtIcon
  },
  props: {
    mode: String,
    icon: {
      type: String,
      default: ''
    },
    userNameInitials: {
      type: String,
      default: ''
    },
    height: Number,
  },
  data () {
    return {
      imgURL: ''
    }
  },
  computed: {
    ...mapGetters({
      avatarImg: getters.AVATAR_IMG_URL
    }),
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      color: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      config: getters.AUTH_GET_USER_CONFIG
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      topNavConfig: ({ topNavConfig }) => topNavConfig,
      iconConfig: ({ iconConfig }) => iconConfig,
      activeCategory: ({ activeCategory }) => activeCategory,
      LineHeight: ({ globalTheme }) => globalTheme.LineHeight,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      orgName: ({ globalTheme }) => globalTheme.OrgName
    }),
  },
  watch: {},
  created () {
    FontFamily = this.FontFamily
    FontSize = this.FontSize
    Spacing = this.Spacing
    Colors = this.Colors
    Border = this.Border
    textFontWeight = this.textFontWeight
    color = this.color
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    textColor = this.textColor
    LineHeight = this.LineHeight
    FontWeight = this.FontWeight

    //get avatar image as required
    this.getAvatarImage();
  },

  methods: {
    ...mapActions({
      setAvatarUrl: actions.AVATAR_ORG_IMG
    }),

    async getAvatarImage(){

      //For crm grow get the avatar image from API and use the same
      if( this.orgName ==  CRMGROW ){
        if( !this.avatarImg ){          
          //Create axios
          const client = axios.create({
            baseURL: '/api',
            withCredentials: true
          });

          const endPoint = '/v1/crm/avatar/url';
          const getData = await client.get( endPoint );          
          this.setAvatarUrl( getData.data?.path );
        }
        this.imgURL = this.avatarImg;
      }
    }
  },

  render: function (h) {
    const AvatarWrapper = getAvatarWrapper()
    const CircleBackgroundContainer = getCircleBackgroundContainer()
    const UserNameInitialsContainer = getUserNameInitialsContainer()
    const AvatarIcon = getAvatarIcon()
    const AvatarImage = getAvatarImageContainer()

    return (
      <AvatarWrapper height={this.height} mode={this.mode}>
        <CircleBackgroundContainer 
          mode={this.mode} 
          backgroundColor={this.topNavConfig.theme.backgroundColor}
          imgURL={this.imgURL}
        >
          
          { this.imgURL == '' && this.userNameInitials!==null && this.userNameInitials!==undefined && (
            <UserNameInitialsContainer mode={this.mode} color={this.color}>
              {this.userNameInitials}
            </UserNameInitialsContainer>
          )}
          {this.imgURL == '' && this.userNameInitials===null && (
            <AvatarIcon class="fa fa-user" mode={this.mode} color={this.color}></AvatarIcon>
          )}

          { this.imgURL != '' && (
            <AvatarImage
              src = { this.imgURL }
              alt = { this.userNameInitials }
            >
              
            </AvatarImage>
          )}

        </CircleBackgroundContainer>
      </AvatarWrapper>
    )
  }
}
