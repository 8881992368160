// @ts-nocheck
import ImgAsync from '../../../components/utils/ImgAsync.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as getters from "../../../store/modules/getters";

export default {
  components: {
    ImgAsync,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    ...mapState('route', {}),
    ...mapState('globalTheme', {}),
    marketplaceLayout () {
      return {[this.config.POPUP_MARKETPLACE_LAYOUT || 'marketplace-layout-popup-default']: true}
    },
  },
  methods: {
    ...mapActions({}),
    ...mapMutations({}),
    ...mapActions('globalTheme', {}),
  },
}
