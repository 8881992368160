

<span>
  
    <div class="nestedInnerItem">
      <cvt-select
        :value="borderStyle"
        mode="dropdown"
        :background-mode="mode"
        :floating-label="false"
        :label="$t(&quot;elements.properties.outline.style&quot;)"
        @input="(value) => selectAction(value, 'borderStyle')"
      >
        <option
          v-for="style in borderStyles"
          :key="style"
          :value="style"
          v-text="style"
        />
      </cvt-select>
      <div style="margin: 5px;" />
      <cvt-input
        v-model="borderColor"
        style="width: max-content;"
        type="text"
        :clearable="false"
        :label="$t(&quot;elements.properties.outline.color&quot;)"
      />
    </div>

    <div class="divider mt-2" />

    <div class="row">
      <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
        {{ $t("elements.properties.outline.width") }}
      </div>
      <div class="col-12">
        <cvt-slider
          id="slider-default"
          :tooltips-enabled="true"
          :step-marks-enabled="true"
          class="slider"
          :min="1"
          :max="10"
          :value="borderWidth"
          :step="1"
          @change="borderWidthChange"
        />
      </div>
    </div>

</span>

