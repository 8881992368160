<template>
  <div>
    <h1>Dropdown</h1>
    <div
      v-for="(color, index) in colors"
      :key="color"
    >
      <span>{{ index }}</span>
      <cvt-dropdown
        :select-disabled="false"
        :on-hover-trigger-mode="false"
        :bg-color="color"
      >
        <option
          v-for="item in data"
          :key="item.value"
          :icon="item.icon"
          :value="item.value"
          v-text="item.label"
        />
      </cvt-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PlayDropdown',
  components: {
  },
  data () {
    return {
      colors: {
        Primary: 'primary',
        Secondary: 'secondary',
        Success: 'success',
        Danger: 'danger',
        Warning: 'warning',
        Info: 'info',
        Dark: 'dark'
      },
      data: [
        {
          value: 'react-js',
          label: 'React.js',
          icon: 'clock'
        },
        {
          value: 'vue',
          label: 'Vue.js',
          icon: 'user'
        },
        {
          value: 'svelte',
          label: 'Svelte',
          icon: 'check'
        },
        {
          value: 'angular',
          label: 'Angular',
          icon: 'clock'
        },
        {
          value: 'jquery',
          label: 'jQuery',
          icon: 'clock'
        },
        {
          value: 'prototype',
          label: 'Prototype'
        },
        {
          value: 'ember-js',
          label: 'Ember.js',
          icon: 'clock'
        },
        {
          value: 'angular-js',
          label: 'Angular.js',
          icon: 'clock'
        },
        {
          value: 'preact',
          label: 'Preact',
          icon: 'clock'
        },
        {
          value: 'polymer',
          label: 'Polymer',
          icon: 'clock'
        },
        {
          value: 'qooxdoo',
          label: 'qooxdoo',
          icon: 'clock'
        },
        {
          value: 'webix',
          label: 'Webix',
          icon: 'clock'
        },
        {
          value: 'knockout',
          label: 'Knockout',
          icon: 'clock'
        },
        {
          value: 'wakanda',
          label: 'Wakanda',
          icon: 'clock'
        },
        {
          value: 'backbone-js',
          label: 'Backbone.js',
          icon: 'clock'
        }
      ]
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
  },
}
</script>
<style lang="scss">
</style>
