import { styled, VueEmotion } from '@egoist/vue-emotion'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import idMixin from '../../../lib/mixins/id'
import normalizeSlotMixin from '../../../lib/mixins/normalize-slot'
import { arrayIncludes, concat } from '../../../lib/utils/array'
import BvEvent from '../../../lib/utils/bv-event.class'
import { selectAll } from '../../../lib/utils/dom'
import identity from '../../../lib/utils/identity'
import { isEvent } from '../../../lib/utils/inspect'
import looseEqual from '../../../lib/utils/loose-equal'
import { omit } from '../../../lib/utils/object'
import Vue from '../../../lib/utils/vue'
import { CvtNav, props as CvtNavProps } from '../nav/nav'
import CvtTabButtonWrapper from './TabButtonWrapper'
import CvtButton from '../Button'

Vue.use(VueEmotion)

let Spacing, Radii, backgroundColor, elevation, borderColor

// -- Constants --

const navProps = omit(CvtNavProps, ['tabs', 'isNavBar', 'cardHeader'])

// Filter function to filter out disabled tabs
const notDisabled = (tab) => !tab.disabled

const UTIL = {
  getTabsBgColor(props) {
    if (props.mode == 'DARK') {
      return backgroundColor({ themeing: 'dark' }, 700)
    } else {
      if (props.tabsConfig) {
        const tabsBgColorConfig = props.tabsConfig?.tabs?.default?.bgColor
        if (!tabsBgColorConfig?.custom) {
          return backgroundColor(
            tabsBgColorConfig.value,
            tabsBgColorConfig.weight,
          )
        } else {
          return `background-color: ${tabsBgColorConfig.value} !important;`
        }
      } else {
        return `background-color: transparent;`
      }
    }
  },
  getTabsBgColorOnHover(props) {
    if (props.mode == 'DARK') {
      return backgroundColor({ themeing: 'dark' }, 700)
    } else {
      if (props.tabsConfig) {
        const tabsBgColorHoverConfig = props.tabsConfig?.tabs?.hover?.bgColor
        if (!tabsBgColorHoverConfig?.custom) {
          return backgroundColor(
            tabsBgColorHoverConfig.value,
            tabsBgColorHoverConfig.weight,
          )
        } else {
          return `background-color: ${tabsBgColorHoverConfig.value} !important;`
        }
      } else {
        return `background-color: transparent;`
      }
    }
  },
}

const getCustomCvtNav = () => styled(CvtNav)`
  display: inline-flex;
  ${(props) => {
    if (props.alignTabs) {
      return ` display: flex !important; justify-content: ${props.alignTabs};`
    }
  }}
  ${(props) => UTIL.getTabsBgColor(props)}
  &:hover {
    ${(props) => UTIL.getTabsBgColorOnHover(props)}
  }
  ${(props) => {
    let styles = ``
    if (props.left || props.right) {
      styles += `flex-direction: column;`
    } else {
      styles += `flex-direction: row;`
    }

    if (props.headerRaised) {
      styles += `
        border-radius: ${Radii.RADIUS_PX_8}px;
        ${elevation(props, 300)}
      `
    } else if (props.iosPills) {
      styles += `
        border-radius: ${Radii.RADIUS_PX_8}px;
        ${
          props.mode == 'LIGHT'
            ? backgroundColor({ themeing: 'dark' }, 100)
            : backgroundColor({ themeing: 'dark' }, 800)
        }
        padding: ${Spacing.SPACING_4}px;
        &:hover {
          ${
            props.mode == 'LIGHT'
              ? backgroundColor({ themeing: 'dark' }, 100)
              : backgroundColor({ themeing: 'dark' }, 800)
          }
        } 
      `
    }

    if (props.fullHorizontalBorder) {
      if (props.smallerTabBorder) {
        styles += `margin-bottom: -1px;`
      } else {
        styles += `margin-bottom: -${Spacing.SPACING_4}px;`
      }
    } else {
      styles += `margin-bottom: 0px;`
    }

    if (props.tabsAsButton) {
      styles += `width: unset !important; align-items: flex-start;`
    }

    return styles
  }}
`

const getTabsContainerDiv = () => styled('div')`
  display: flex;
  ${(props) => {
    let styles = ``
    if (props.left || props.right) {
      styles += `flex-direction: row;`
    } else {
      styles += `flex-direction: column;`
    }
    return styles
  }}
`

const getTabContentDiv = () => styled('div')`
  flex: auto;
  ${(props) => {
    let styles = ``
    if (props.left || props.right) {
      styles += `margin-left: ${Spacing.SPACING_96}px;`
    }
    if (props.bottom) {
      styles += `margin-bottom: ${Spacing.SPACING_48}px;`
    }

    if (props.isBreadCrumbs) {
      styles += 'display: none;'
    }
    return styles
  }}
`

const getUlWrapper = () => styled('div')`
  ${(props) => {
    let styles = ``

    if (props.smallerTabBorder) {
      styles += `border-bottom-width: 1px;`
    }

    if (props.fullHorizontalBorder) {
      styles += `border-bottom-style: solid;`
      styles += `border-color: #CCCCD1;`
    }

    if (props.usage === 'PopupSiteSettings') {
      styles += `border-radius: ${Spacing.SPACING_8}px;`
    }

    return styles
  }}
`

const getCustomButton = () => styled(CvtButton)`
  text-align: unset !important;
`

const CustomButton = getCustomButton()

// @vue/component
export default {
  name: 'CvtTabs',
  mixins: [idMixin, normalizeSlotMixin],
  provide() {
    return {
      bvTabs: this,
    }
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    ...navProps,
    card: {
      type: Boolean,
      default: false,
    },
    end: {
      // Synonym for 'bottom'
      type: Boolean,
      default: false,
    },
    noNavStyle: {
      type: Boolean,
      default: false,
    },
    noKeyNav: {
      type: Boolean,
      default: false,
    },
    lazy: {
      // This prop is sniffed by the <cvt-tab> child
      type: Boolean,
      default: false,
    },
    navClass: {
      type: [String, Array, Object],
      default: null,
    },
    navWrapperClass: {
      type: [String, Array, Object],
      default: null,
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: null,
    },
    prefixIcon: {
      type: String,
      default: 'star',
    },
    headerRaised: {
      type: Boolean,
      default: false,
    },
    iosPills: {
      type: Boolean,
      default: false,
    },
    buttonGroup: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'LIGHT',
    },
    keepInactiveBorder: {
      type: Boolean,
      default: true,
    },
    //Used for breadcrumbs
    isBreadCrumbs: {
      type: Boolean,
      default: false,
    },
    postfixIcon: {
      type: String,
      default: '',
    },
    dividedBy: {
      type: String,
      default: 'slash',
    },
    fullHorizontalBorder: {
      type: Boolean,
      default: false,
    },
    smallerTabBorder: {
      type: Boolean,
      default: false,
    },
    specialMargin: {
      type: String,
      default: '',
    },
    specialPadding: {
      type: String,
      default: '',
    },
    preIconSpecialMargin: {
      type: String,
      default: '',
    },
    inActiveBorderColor: {
      type: String,
      default: '',
    },
    usage: {
      type: String,
      default: '',
    },
    alignTabs: {
      type: String,
      default: '',
    },
    tabsConfig: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let tabIdx = parseInt(this.value, 10)
    tabIdx = isNaN(tabIdx) ? -1 : tabIdx
    return {
      // Index of current tab
      currentTab: tabIdx,
      // Array of direct child <cvt-tab> instances, in DOM order
      tabs: [],
      // Array of child instances registered (for triggering reactive updates)
      registeredTabs: [],
      // Flag to know if we are mounted or not
      isMounted: false,
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      elevation: getters.GLOBAL_STYLE_ELEVATION,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      marketplaceConfig: ({ marketplaceConfig }) => marketplaceConfig,
    }),
    fade() {
      return !this.noFade
    },
    localNavClass() {
      const classes = []
      return [...classes, this.navClass]
    },
  },
  watch: {
    closeable() {
      const tabs = this.getTabs()
      tabs.forEach((tab) => {
        tab.isCloseable = this.closeable
      })
    },
    currentTab(newVal) {
      let index = -1
      // Ensure only one tab is active at most
      this.tabs.forEach((tab, idx) => {
        if (newVal === idx && !tab.disabled) {
          tab.localActive = true
          index = idx
        } else {
          tab.localActive = false
        }
      })
      // Update the v-model
      this.$emit('input', index)
    },
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        newVal = parseInt(newVal, 10)
        newVal = isNaN(newVal) ? -1 : newVal
        oldVal = parseInt(oldVal, 10) || 0
        const tabs = this.tabs
        if (tabs[newVal] && !tabs[newVal].disabled) {
          this.activateTab(tabs[newVal])
        } else {
          // Try next or prev tabs
          if (newVal < oldVal) {
            this.previousTab()
          } else {
            this.nextTab()
          }
        }
      }
    },
    registeredTabs() {
      // Each cvt-tab will register/unregister itself.
      // We use this to detect when tabs are added/removed
      // to trigger the update of the tabs.
      this.$nextTick(() => {
        this.updateTabs()
      })
    },
    tabs(newVal, oldVal) {
      // If tabs added, removed, or re-ordered, we emit a `changed` event.
      // We use `tab._uid` instead of `tab.safeId()`, as the later is changed
      // in a nextTick if no explicit ID is provided, causing duplicate emits.
      if (
        !looseEqual(
          newVal.map((t) => t._uid),
          oldVal.map((t) => t._uid),
        )
      ) {
        // In a nextTick to ensure currentTab has been set first.
        this.$nextTick(() => {
          // Emit shallow copies of the new and old arrays of tabs, to
          // prevent users from potentially mutating the internal arrays.
          this.$emit('changed', newVal.slice(), oldVal.slice())
        })
      }
    },
    isMounted(newVal) {
      // Trigger an update after mounted.  Needed for tabs inside lazy modals.
      if (newVal) {
        this.updateTabs()
      }
      // Enable or disable the observer
      // this.setObserver(newVal)
    },
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    backgroundColor = this.backgroundColor
    elevation = this.elevation
    borderColor = this.borderColor
    const tabIdx = parseInt(this.value, 10)
    this.currentTab = isNaN(tabIdx) ? -1 : tabIdx
    this._bvObserver = null
    // For SSR and to make sure only a single tab is shown on mount
    // We wrap this in a `$nextTick()` to ensure the child tabs have been created
    this.$nextTick(() => {
      this.updateTabs()
    })
  },
  mounted() {
    // Call `updateTabs()` just in case...
    this.updateTabs()
  },
  deactivated() /* istanbul ignore next */ {
    this.isMounted = false
  },
  activated() /* istanbul ignore next */ {
    const tabIdx = parseInt(this.value, 10)
    this.currentTab = isNaN(tabIdx) ? -1 : tabIdx
    this.$nextTick(() => {
      this.updateTabs()
      this.isMounted = true
    })
  },
  beforeDestroy() {
    this.isMounted = false
  },
  destroyed() {
    // Ensure no references to child instances exist
    this.tabs = []
  },
  methods: {
    registerTab(tab) {
      if (!arrayIncludes(this.registeredTabs, tab)) {
        this.registeredTabs.push(tab)
        tab.$once('hook:destroyed', () => {
          this.unregisterTab(tab)
        })
      }
    },
    closedTab(tab, index) {
      // allow consumer to prevent closing tab
      // by call bvEvt.preventDefault()
      const tabEvt = new BvEvent('close-tab', {
        cancelable: true,
        vueTarget: this,
        componentId: this.safeId(),
      })

      this.$emit(tabEvt.type, index, tabEvt)

      if (!tabEvt.defaultPrevented) {
        this.updateTabs(true)
        this.unregisterTab(tab)
      }
    },
    unregisterTab(tab) {
      this.registeredTabs = this.registeredTabs.slice().filter((t) => t !== tab)
    },
    getTabs() {
      // We use registeredTabs as the source of truth for child tab components. And we
      // filter out any CvtTab components that are extended CvtTab with a root child CvtTab.
      // https://github.com/bootstrap-vue/bootstrap-vue/issues/3260
      const tabs = this.registeredTabs.filter(
        (tab) => tab.$children.filter((t) => t._isTab).length === 0,
      )
      // DOM Order of Tabs
      let order = []
      if (this.isMounted && tabs.length > 0) {
        // We rely on the DOM when mounted to get the 'true' order of the cvt-tab children.
        // querySelectorAll(...) always returns elements in document order, regardless of
        // order specified in the selector.
        const selector = tabs.map((tab) => `#${tab.safeId()}`).join(', ')
        order = selectAll(selector, this.$el)
          .map((el) => el.id)
          .filter(identity)
      }
      // Stable sort keeps the original order if not found in the
      // `order` array, which will be an empty array before mount.
      return tabs
    },
    // Update list of <cvt-tab> children
    updateTabs(silent = false) {
      // Probe tabs
      const tabs = this.getTabs()

      if (silent) return

      // Find *last* active non-disabled tab in current tabs
      // We trust tab state over currentTab, in case tabs were added/removed/re-ordered
      let tabIndex = tabs.indexOf(
        tabs
          .slice()
          .reverse()
          .find((tab) => tab.localActive && !tab.disabled),
      )

      // Else try setting to currentTab
      if (tabIndex < 0) {
        const currentTab = this.currentTab
        if (currentTab >= tabs.length) {
          // Handle last tab being removed, so find the last non-disabled tab
          tabIndex = tabs.indexOf(tabs.slice().reverse().find(notDisabled))
        } else if (tabs[currentTab] && !tabs[currentTab].disabled) {
          // Current tab is not disabled
          tabIndex = currentTab
        }
      }

      // Else find *first* non-disabled tab in current tabs
      if (tabIndex < 0) {
        tabIndex = tabs.indexOf(tabs.find(notDisabled))
      }

      if (tabs[tabIndex]) {
        tabs[tabIndex].localActive = true
      }

      if (tabs.length === 1) {
        tabs[0].isCloseable = false
      }

      // Update the array of tab children
      this.tabs = tabs
      // Set the currentTab index (can be -1 if no non-disabled tabs)
      this.currentTab = tabIndex
    },
    // Find a button that controls a tab, given the tab reference
    // Returns the button vm instance
    getButtonForTab(tab) {
      return (this.$refs.buttons || []).find((btn) => btn.tab === tab)
    },
    // Force a button to re-render its content, given a <cvt-tab> instance
    // Called by <cvt-tab> on `update()`
    updateButton(tab) {
      const button = this.getButtonForTab(tab)
      if (button && button.$forceUpdate) {
        button.$forceUpdate()
      }
    },
    // Activate a tab given a <cvt-tab> instance
    // Also accessed by <cvt-tab>
    activateTab(tab) {
      let result = false
      if (tab) {
        const index = this.tabs.indexOf(tab)
        if (!tab.disabled && index > -1 && index !== this.currentTab) {
          const tabEvt = new BvEvent('activate-tab', {
            cancelable: true,
            vueTarget: this,
            componentId: this.safeId(),
          })
          this.$emit(tabEvt.type, index, this.currentTab, tabEvt)
          this.$emit('activateTabModified', tab)
          if (!tabEvt.defaultPrevented) {
            result = true
            this.currentTab = index
          }
        }
      }
      // Couldn't set tab, so ensure v-model is set to `this.currentTab`
      /* istanbul ignore next: should rarely happen */
      if (!result && this.currentTab !== this.value) {
        this.$emit('input', this.currentTab)
      }
      return result
    },
    // Deactivate a tab given a <cvt-tab> instance
    // Accessed by <cvt-tab>
    deactivateTab(tab) {
      if (tab) {
        // Find first non-disabled tab that isn't the one being deactivated
        // If no tabs are available, then don't deactivate current tab
        return this.activateTab(
          this.tabs.filter((t) => t !== tab).find(notDisabled),
        )
      }
      /* istanbul ignore next: should never/rarely happen */
      return false
    },
    // Focus a tab button given its <cvt-tab> instance
    focusButton(tab) {
      // Wrap in `$nextTick()` to ensure DOM has completed rendering/updating before focusing
      this.$nextTick(() => {
        const button = this.getButtonForTab(tab)
        if (button && button.focus) {
          button.focus()
        }
      })
    },
    // Emit a click event on a specified <cvt-tab> component instance
    emitTabClick(tab, evt) {
      if (isEvent(evt) && tab && tab.$emit && !tab.disabled) {
        tab.$emit('click', evt)
      }
    },
    // Click handler
    clickTab(tab, evt) {
      this.$emit('toggle', tab.id)
      this.activateTab(tab)
      this.emitTabClick(tab, evt)
    },
    // Move to first non-disabled tab
    firstTab(focus) {
      const tab = this.tabs.find(notDisabled)
      if (this.activateTab(tab) && focus) {
        this.focusButton(tab)
        this.emitTabClick(tab, focus)
      }
    },
    // Move to previous non-disabled tab
    previousTab(focus) {
      const currentIndex = Math.max(this.currentTab, 0)
      const tab = this.tabs.slice(0, currentIndex).reverse().find(notDisabled)
      if (this.activateTab(tab) && focus) {
        this.focusButton(tab)
        this.emitTabClick(tab, focus)
      }
    },
    // Move to next non-disabled tab
    nextTab(focus) {
      const currentIndex = Math.max(this.currentTab, -1)
      const tab = this.tabs.slice(currentIndex + 1).find(notDisabled)
      if (this.activateTab(tab) && focus) {
        this.focusButton(tab)
        this.emitTabClick(tab, focus)
      }
    },
    // Move to last non-disabled tab
    lastTab(focus) {
      const tab = this.tabs.slice().reverse().find(notDisabled)
      if (this.activateTab(tab) && focus) {
        this.focusButton(tab)
        this.emitTabClick(tab, focus)
      }
    },
  },
  render(h) {
    const CustomCvtNav = getCustomCvtNav()
    const TabsContainerDiv = getTabsContainerDiv()
    const TabContentDiv = getTabContentDiv()
    const UlWrapper = getUlWrapper()
    const tabs = this.tabs

    // Currently active tab
    const activeTab = tabs.find((tab) => tab.localActive && !tab.disabled)

    // Tab button to allow focusing when no active tab found (keynav only)
    const fallbackTab = tabs.find((tab) => !tab.disabled)

    // For each <cvt-tab> found create the tab buttons
    const buttons = tabs.map((tab, index) => {
      let tabIndex = null
      let nextTabIsActive = false
      // Ensure at least one tab button is focusable when keynav enabled (if possible)
      if (!this.noKeyNav) {
        // Buttons are not in tab index unless active, or a fallback tab
        tabIndex = -1
        if (activeTab === tab || (!activeTab && fallbackTab === tab)) {
          // Place tab button in tab sequence
          tabIndex = null
        }

        if (index < tabs.length - 1) {
          if (activeTab === tabs[index + 1]) {
            nextTabIsActive = true
          }
        } else {
          nextTabIsActive = true
        }
      }

      return (
        <CvtTabButtonWrapper
          key={tab._uid || index}
          ref="buttons"
          refInFor={true}
          props={{
            tab: tab,
            tabs: tabs,
            id:
              tab.controlledBy ||
              (tab.safeId ? tab.safeId(`_BV_tab_button_`) : null),
            controls: tab.safeId ? tab.safeId() : null,
            tabIndex,
            setSize: tabs.length,
            posInSet: index + 1,
            noKeyNav: this.noKeyNav,
            pills: !this.noNavStyle && this.pills,
            top: !this.vertical && !this.end,
            bottom: !this.vertical && this.end,
            left: this.vertical && !this.end,
            right: this.vertical && this.end,
            prefixIcon: this.prefixIcon,
            headerRaised: this.headerRaised,
            iosPills: this.iosPills,
            buttonGroup: this.buttonGroup,
            mode: this.mode,
            nextTabIsActive: nextTabIsActive,
            keepInactiveBorder: this.keepInactiveBorder,
            postfixIcon: this.postfixIcon,
            isBreadCrumbs: this.isBreadCrumbs,
            dividedBy: this.dividedBy,
            isLastTab: index == tabs.length - 1,
            specialMargin: this.specialMargin,
            specialPadding: this.specialPadding,
            preIconSpecialMargin: this.preIconSpecialMargin,
            smallerTabBorder: this.smallerTabBorder,
            inActiveBorderColor: this.inActiveBorderColor,
          }}
          onClick={(evt) => {
            this.clickTab(tab, evt)
          }}
          onFirst={this.firstTab}
          onPrev={this.previousTab}
          onNext={this.nextTab}
          onLast={this.lastTab}
          tabsConfig={this.tabsConfig}
        ></CvtTabButtonWrapper>
      )
    })

    let nav = this.marketplaceConfig?.tabsAsButton ? (
      <UlWrapper
        fullHorizontalBorder={this.fullHorizontalBorder}
        smallerTabBorder={this.smallerTabBorder}
        key="bv-tabs-nav"
        usage={this.usage}
        class={[this.navWrapperClass]}
      >
        <CustomCvtNav
          ref="nav"
          role="tablist"
          id={this.safeId('_BV_tab_controls_')}
          top={!this.vertical && !this.end}
          bottom={!this.vertical && this.end}
          left={this.vertical && !this.end}
          right={this.vertical && this.end}
          headerRaised={this.headerRaised}
          iosPills={this.iosPills}
          buttonGroup={this.buttonGroup}
          mode={this.mode}
          tabsAsButton={this.marketplaceConfig?.tabsAsButton}
          props={{
            fill: this.fill,
            justified: this.justified,
            align: this.align,
            tabs: !this.noNavStyle && !this.pills,
            pills: !this.noNavStyle && this.pills && !this.isBreadCrumbs,
            vertical: this.vertical,
            small: this.small,
            cardHeader: this.card && !this.vertical,
          }}
          fullHorizontalBorder={this.fullHorizontalBorder}
          smallerTabBorder={this.smallerTabBorder}
          tabsConfig={this.tabsConfig}
          alignTabs={this.alignTabs}
        >
          {this.tabs.map((tab, index) => (
            <CustomButton
              onClick={(evt) => {
                this.clickTab(tab, evt)
              }}
              style={{ marginBottom: '10px', marginLeft: '10px' }}
              text={tab?.title || ''}
              shape="pill"
              disabled={tab.disabled}
              mode={this.mode}
              color={this.currentTab == index ? undefined : 'transparent'}
              btnTextColor={this.currentTab == index ? undefined : 'black'}
              removeBackground={this.currentTab == index ? false : true}
            />
          ))}
        </CustomCvtNav>
      </UlWrapper>
    ) : (
      <UlWrapper
        fullHorizontalBorder={this.fullHorizontalBorder}
        smallerTabBorder={this.smallerTabBorder}
        key="bv-tabs-nav"
        usage={this.usage}
        class={[this.navWrapperClass]}
      >
        <CustomCvtNav
          ref="nav"
          role="tablist"
          id={this.safeId('_BV_tab_controls_')}
          top={!this.vertical && !this.end}
          bottom={!this.vertical && this.end}
          left={this.vertical && !this.end}
          right={this.vertical && this.end}
          headerRaised={this.headerRaised}
          iosPills={this.iosPills}
          buttonGroup={this.buttonGroup}
          mode={this.mode}
          props={{
            fill: this.fill,
            justified: this.justified,
            align: this.align,
            tabs: !this.noNavStyle && !this.pills,
            pills: !this.noNavStyle && this.pills && !this.isBreadCrumbs,
            vertical: this.vertical,
            small: this.small,
            cardHeader: this.card && !this.vertical,
          }}
          fullHorizontalBorder={this.fullHorizontalBorder}
          smallerTabBorder={this.smallerTabBorder}
          tabsConfig={this.tabsConfig}
          alignTabs={this.alignTabs}
        >
          {[
            this.normalizeSlot('tabs-start') || '',
            buttons,
            <div>{this.normalizeSlot('tabs-end')}</div> || '',
          ]}
        </CustomCvtNav>
      </UlWrapper>
    )

    let empty =
      !tabs || tabs.length === 0 ? (
        <div key="bv-empty-tab" class={['active']}>
          {this.normalizeSlot('empty')}
        </div>
      ) : (
        ''
      )

    const content = (
      <TabContentDiv
        ref="tabsContainer"
        key="bv-tabs-container"
        id={this.safeId('_BV_tab_container_')}
        top={!this.vertical && !this.end}
        bottom={!this.vertical && this.end}
        left={this.vertical && !this.end}
        right={this.vertical && this.end}
        isBreadCrumbs={this.isBreadCrumbs}
      >
        {concat(this.normalizeSlot('default'), empty)}
      </TabContentDiv>
    )

    return (
      <TabsContainerDiv
        class={{
          comingFromTabs: true,
        }}
        top={!this.vertical && !this.end}
        bottom={!this.vertical && this.end}
        left={this.vertical && !this.end}
        right={this.vertical && this.end}
        id={this.safeId()}
      >
        {[this.end ? content : '', [nav], this.end ? '' : content]}
      </TabsContainerDiv>
    )
  },
}
