
<cvt-dialog
  v-if="siteSettings"
  id="site-settings"
  :show="siteSettings"
  size=""
  bg-color="#fff"
  @close.prevent="validateBeforeDone"
>
  <template v-if="siteSettingsTopPosition" #title>
    <h5 id="site-settings-header" class="ml-4">
      {{ $t('elements.properties.settings') }}
    </h5>
  </template>
  <cvt-settings
    data-test-id="activeTab"
    :active-tab="settingsActiveTab"
    @toggle="(id) => setSettingsActiveTab(id)"
  >
    <template #general>
      <div class="general-settings-wrap">
        <!-- Landing Page Name Input -->
        <div class="row pb-3 mb-4">
          <div class="col-md-12">
            <cvt-input
              id="templateNameId"
              :value="siteName"
              type="text"
              :maxlength="150"
              name="name"
              :label="$t('settings.page.label')"
              :clearable="false"
              :validation-status="nameInvalid ? 'invalid' : 'valid'"
              :valid-message="$t('settings.page.validation')"
              :invalid-message="$t(invalidFieldMsg)"
              @input="(e) => onChangeSiteName(e)"
            />
          </div>
        </div>

        <!-- Brand Colors -->
        <div class="row mb-4">
          <p class="col-md-10">
            {{ $t('settings.select_brand_colors.label') }}
          </p>
          <div class="col-md-10">
            <colors :alpha="false" :mode="mode" usage="SiteSettings"></colors>
          </div>
        </div>

        <!-- Font Family, Font Size & Font Default Text Color -->
        <cvt-collapse class="row" :accordion="true" :prepend-chevron="true">
          <cvt-collapse-item
            :title="$t('element.property.typography')"
            name="properties"
            :initial-active="true"
          >
            <div v-for="style in fontStyles" :key="style.label" class="mb-3">
              <div
                class="bg-light border rounded py-3 px-3 row align-items-center"
              >
                <div class="row mr-2">
                  <div class="col-12">
                    <default-color-selector
                      :value="style.format?.defaultTextColor"
                      @input="updateDefaultTextColor(style, $event)"
                    />
                  </div>
                </div>
                <div>
                  <p class="text-secondary text-capitalize">
                    <small>{{ style.tag }}</small>
                  </p>
                  <p
                    class="mt-4"
                    :style="style.format?.toStyle()"
                    v-text="$t(`setup.font.${snakeCase(style.label)}`)"
                  />
                </div>
              </div>

              <div class="row flex-grow-1 mt-2">
                <div
                  class="d-flex justify-content-start align-items-center mb-3"
                >
                  <div class="col-4">
                    <font-picker
                      :value="style.format?.font"
                      @input="updateFontFamily(style, $event)"
                    />
                  </div>
                  <div class="col-2">
                    <font-size-selector
                      :value="style.format?.size"
                      @input="updateFontSize(style, $event)"
                    />
                  </div>
                  <div class="col-6">
                    <font-weight-selector
                      :value="style.format?.weight"
                      @input="updateFontWeight(style, $event)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </cvt-collapse-item>
        </cvt-collapse>

        <!-- Button Default Styles -->
        <cvt-collapse class="row" :accordion="true" :prepend-chevron="true">
          <cvt-collapse-item
            :title="$t('settings.buttons.label')"
            name="properties"
            :initial-active="false"
          >
            <div
              v-for="style in buttonDefaultStyles"
              :key="style.label"
              class=""
            >
              <div
                class="bg-light border rounded py-3 px-3 row align-items-center justify-content-between"
              >
                <div class="c-btn-wrapper col-4">
                  <c-button
                    class="my-auto"
                    :cta="$t(labelI18n(style.label))"
                    :subtext="$t('editor.element.button_subtext')"
                    :helper_text="$t('editor.element.button_helper_text')"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <default-color-selector
                      :value="style.format.defaultBackgroundColor"
                      @input="
                        updateButtonDefaultBackgroundColor(style, $event)
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="row flex-grow-1 align-items-center mt-2">
                <div class="col-6">
                  <font-picker
                    :value="style.format.fontFamily"
                    @input="updateButtonDefaultFontFamily(style, $event)"
                  />
                </div>
                <div class="col-3">
                  <font-size-selector
                    :value="style.format.fontSize"
                    @input="updateButtonDefaultFontSize(style, $event)"
                  />
                </div>
                <div class="col-3">
                  <font-weight-selector
                    :value="style.format.fontWeight"
                    @input="updateButtonDefaultFontWeight(style, $event)"
                  />
                </div>
              </div>
            </div>

            <!-- Subtext -->
            <div
              v-for="style in buttonDefaultStyles"
              :key="style.label + $t('editor.element.button_subtext')"
            >
              <div class="c-btn-wrapper mt-4 h5">
                {{ $t('editor.element.button_subtext') }}
              </div>
              <div class="row flex-grow-1">
                <div class="col-6">
                  <font-picker
                    :value="style.format.subtextStyle.fontFamily"
                    @input="updateButtonSubtextFontFamily(style, $event)"
                  />
                </div>
                <div class="col-3">
                  <font-size-selector
                    :value="style.format.subtextStyle.fontSize"
                    sub
                    @input="updateButtonSubtextFontSize(style, $event)"
                  />
                </div>
                <div class="col-3">
                  <font-weight-selector
                    :value="style.format.subtextStyle.fontWeight"
                    @input="updateButtonSubtextFontWeight(style, $event)"
                  />
                </div>
              </div>
            </div>
          </cvt-collapse-item>
        </cvt-collapse>

        <!-- Link Default Styles -->
        <cvt-collapse class="row" :accordion="true" :prepend-chevron="true">
          <cvt-collapse-item
            :title="$t('settings.links.label')"
            name="properties"
            :initial-active="false"
          >
            <div
              v-for="style in linkDefaultStyles"
              :key="style.label"
              class="mb-3"
            >
              <div
                class="bg-light border rounded py-3 px-3 row align-items-center"
              >
                <div class="row mr-2">
                  <div class="col-12">
                    <default-color-selector
                      :value="style.format.defaultTextColor"
                      @input="updateFroalaLinkDefaultTextColor(style, $event)"
                    />
                  </div>
                </div>

                <div>
                  <p class="text-secondary text-capitalize link-text-label">
                    {{ style.label }}
                  </p>
                  <a class="mt-4">
                    <span class="link-text">The quick brown fox </span>
                    <span
                      class="underline-link-text"
                      :style="style.format?.getLinkFontStyle()"
                      >jumps</span
                    >
                    <span class="link-text"> over the lazy dog</span>
                  </a>
                </div>
              </div>
            </div>
          </cvt-collapse-item>
        </cvt-collapse>
      </div>
    </template>
    <template #companyLogo>
      <div class="row">
        <div class="col-md-12">
          <div v-if="brandLogo" class="row">
            <div class="col-md-6 mx-auto text-center">
              <img
                v-if="!showUploadDropzone"
                class="img-fluid"
                :src="brandLogo"
              />
              <cvt-button
                class="my-3"
                :text="$t('setup.logo.replace')"
                :full-width="true"
                @click="toggleUpload"
              />
            </div>
          </div>
          <div v-if="showUploadDropzone" class="row">
            <div class="col">
              <upload-dropzone
                ref="uploadDropzone"
                :label="$t('element.upload.image.label.drag')"
                :btn-label="$t('element.upload.image.label.computer')"
                :hide-gallery-button="true"
                :image-only="true"
                :size-limit="5"
                @complete="uploadComplete"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="siteFaviconEnabled" #favicon>
      <div class="col-12">
        <url-input
          id="site_favicon"
          v-model="faviconUrl"
          :label="$t('settings.favicon_link.label')"
          :allowed-protocols="['http', 'https']"
        />
      </div>
    </template>
    <template v-if="siteTrackingEnabled" #tracking>
      <tracking />
    </template>
    <template v-if="tenantChatEnabled" #tenantChatWidget>
      <tenant-chat-widget />
    </template>
    <template v-if="siteSeoEnabled" #seo>
      <h5 class="mt-2 mb-4">{{ $t('launch.sharing.message') }}</h5>
      <div class="row mb-3">
        <div class="col">
          <cvt-input
            :value="opengraph.title"
            type="text"
            :label="$t('launch.sharing.title.label')"
            :validation-status="titleValidationStatus"
            :valid-message="'Input is valid'"
            :invalid-message="'Field is Required'"
            @input="updateOgTitle"
            @blur="saveOpenGraphOnBlur"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <url-input
            :value="opengraph.url"
            type="text"
            :label="$t('launch.sharing.url.label')"
            @input="updateOgUrl"
            @blur="saveOpenGraphOnBlur"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <cvt-input
            :value="opengraph.description"
            type="textarea"
            :validation-status="descriptionValidationStatus"
            :valid-message="'Input is valid'"
            :invalid-message="'Field is Required'"
            :placeholder="$t('launch.sharing.description.input')"
            @input="updateOgDescription"
            @blur="saveOpenGraphOnBlur"
          />
        </div>
      </div>
      <div class="row mb-5">
        <div class="col">
          <div v-if="opengraph.image" class="row mb-3">
            <div class="col">
              <img alt="" class="img-fluid" :src="ogImage" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <cvt-button
                class="btn btn-lg btn-block btn-success text-capitalize"
                :text="$t(uploadOpenGraphBtnTxt)"
                @click="openAssetManager"
              />
            </div>
          </div>
        </div>
      </div>

      <cvt-collapse
        v-if="opengraph.image"
        :accordion="true"
        :prepend-chevron="false"
      >
        <cvt-collapse-item
          title="Facebook"
          name="debugFacebookSharing"
          :initial-active="true"
        >
          <div class="row">
            <div class="col">
              <div v-if="opengraph.image" class="row mb-3">
                <div class="col">
                  <h4>{{ $t('launch.sharing.update.facebook.message') }}</h4>
                  <p>{{ $t('launch.sharing.debug.message') }}</p>
                  <ul>
                    <li>{{ $t('launch.sharing.debug.fb.bullet_one') }}</li>
                    <li>{{ $t('launch.sharing.debug.fb.bullet_two') }}</li>
                    <li>{{ $t('launch.sharing.debug.fb.bullet_three') }}</li>
                  </ul>
                </div>
              </div>
              <div v-if="opengraph.image" class="row">
                <div class="col">
                  <a
                    :href="facebookDebuggerUrl"
                    target="_blank"
                    class="btn btn-lg btn-block social-share-button--facebook text-capitalize"
                    style="color: white"
                  >
                    <i class="fa fa-facebook-official"></i>
                    {{ $t('launch.sharing.opengraph.cta') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </cvt-collapse-item>
        <cvt-collapse-item title="LinkedIn" name="debugLinkedInSharing">
          <div class="row">
            <div class="col">
              <div v-if="opengraph.image" class="row mb-3">
                <div class="col">
                  <h4>{{ $t('launch.sharing.update.linkedin.message') }}</h4>
                  <p>{{ $t('launch.sharing.debug.message') }}</p>
                  <ul>
                    <li>{{ $t('launch.sharing.debug.li.bullet_one') }}</li>
                    <li>{{ $t('launch.sharing.debug.li.bullet_two') }}</li>
                    <li>{{ $t('launch.sharing.debug.li.bullet_three') }}</li>
                  </ul>
                </div>
              </div>
              <div v-if="opengraph.image" class="row">
                <div class="col">
                  <a
                    :href="linkedInDebuggerUrl"
                    target="_blank"
                    class="btn btn-lg btn-block social-share-button--linkedIn text-capitalize"
                    style="color: white"
                  >
                    <i class="fa fa-linkedin-square"></i>
                    {{ $t('launch.sharing.opengraph.cta') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </cvt-collapse-item>
      </cvt-collapse>
    </template>
    <template v-if="config.ECOMMERCE" #checkoutPage>
      <h5 class="mt-2 mb-4">
        {{ $t('launch.ecommerce.checkout_settings') }}
      </h5>

      <div class="row mb-3">
        <div class="col">
          <cvt-select
            v-model="checkout.successAction"
            mode="dropdown"
            :label="$t('launch.ecommerce.redirect_message')"
            @change="updateCheckout(checkout)"
          >
            <option
              v-for="action in actions"
              :key="action.value"
              :value="action.value"
              v-text="action.label"
            />
          </cvt-select>
        </div>
      </div>

      <div v-if="checkout.successAction === 'page'" class="row mb-3">
        <div class="col">
          <h5 class="text-muted mb-1">Choose a page in your funnel</h5>
          <page-list
            :selected-page="checkout.successPage"
            @change="updateCheckoutSuccessPage"
          />
        </div>
      </div>

      <div v-if="checkout.successAction === 'link'" class="row mb-3">
        <div class="col">
          <url-input
            v-model="checkout.successURL"
            :label="$t('launch.ecommerce.redirect_url')"
            :allowed-protocols="['http', 'https']"
            @change="updateCheckout(checkout)"
          />
        </div>
      </div>

      <div v-if="false" class="row mb-3">
        <div class="col">
          <el-switch
            v-model="checkout.billingAddress"
            @change="updateCheckout(checkout)"
          ></el-switch>
          <span>{{ $t('launch.ecommerce.billing_address') }}</span>
        </div>
      </div>

      <div v-if="false" class="row mb-3">
        <div class="col">
          <el-switch
            v-model="checkout.shippingAddress"
            @change="updateCheckout(checkout)"
          ></el-switch>
          <span>{{ $t('launch.ecommerce.shipping_address') }}</span>
        </div>
      </div>

      <div v-if="false" class="row mb-3">
        <div class="col">
          <el-switch
            v-model="checkout.promoCode"
            @change="updateCheckout(checkout)"
          ></el-switch>
          <span>{{ $t('launch.ecommerce.promo_code') }}</span>
        </div>
      </div>
    </template>

    <template v-if="config.LEGAL_SYSTEM_ENABLED" #legal>
      <div class="col-12">
        <p v-if="config.LEGAL_TERMLY_RECOMMENDATION">
          We recommend
          <a href="https://termly.io/" target="_blank">termly.io</a> as a
          comprehensive way of managing compliance URLs.
        </p>

        <div class="card-body text-info mt-2">
          <p class="card-text">
            A cookie attestation banner is automatically displayed to your
            visitors when a Cookie Policy URL is set.
          </p>
        </div>

        <h5>Terms of Service URL</h5>
        <url-input
          id="legalTermsOfServiceURL"
          v-model="termsOfService"
          :allowed-protocols="['http', 'https']"
          :disabled="!config.LEGAL_TOS_EDITABLE"
        ></url-input>

        <h5>Cookie Policy URL</h5>
        <url-input
          id="legalCookiePolicyURL"
          v-model="cookiePolicy"
          :allowed-protocols="['http', 'https']"
          :disabled="!config.LEGAL_COOKIE_POLICY_EDITABLE"
        ></url-input>

        <h5>Privacy Policy URL</h5>
        <url-input
          id="legalPrivacyPolicyURL"
          v-model="privacyPolicy"
          :allowed-protocols="['http', 'https']"
          :disabled="!config.LEGAL_PRIVACY_POLICY_EDITABLE"
        ></url-input>
      </div>
    </template>
    <template v-if="alertMessage.length > 0" #alert>
      <cvt-alert
        class="w-100"
        color="danger"
        icon="exclamation-triangle"
        :close-icon="true"
      >
        {{ alertMessage }}
      </cvt-alert>
    </template>
  </cvt-settings>
  <template #modalFooter>
    <cvt-popover
      v-if="brandPreferenceEnabled"
      :ref="popoverRef"
      :mode="mode"
      placement="top"
      target="save_preferences_popover"
      triggers="click"
    >
      <template #popper>
        <div class="d-flex flex-column mt-2" style="width: 330px">
          <cvt-alert class="text-left" color="info" icon="info">
            <p class="">{{ $t('settings.page.saving_your_preference') }}</p>
            <ul class="">
              <li>{{ $t('settings.page.colors') }}</li>
              <li>{{ $t('settings.page.fonts') }}</li>
              <li>{{ $t('setup.logo.text') }}</li>
              <li>{{ $t('settings.favicon.label') }}</li>
              <li>{{ $t('launch.tracking.tab_title') }}</li>
            </ul>
          </cvt-alert>
          <div class="text-left">
            <cvt-input
              id="preferenceName"
              :value="preferenceName"
              type="text"
              name="preferenceName"
              :label="$t('settings.page.preferences_name')"
              @input="setPreferenceName"
            />
          </div>
        </div>
        <div class="divider mt-2" />
        <div class="d-flex align-items-center justify-content-center">
          <cvt-button
            :text="
              $t(
                'editor.left_nav.page_manager.layout_dialog.template.footer.cancel.btn',
              )
            "
            :outlined="true"
            color="light"
            btn-text-color="#494C53"
            class="border-secondary mr-4"
            @click.prevent="closePopover"
          ></cvt-button>
          <cvt-button
            :text="$t('settings.page.save_preferences')"
            :special-style="isSpecialStyle"
            @click.prevent="saveThePreference"
          ></cvt-button>
        </div>
      </template>
    </cvt-popover>
    <cvt-button
      v-if="brandPreferenceEnabled"
      id="save_preferences_popover"
      :text="$t('settings.page.save_my_preferences')"
      :outlined="true"
      icon="save"
      color="light"
      btn-text-color="#494C53"
      class="border-secondary"
      @click.stop.prevent
    ></cvt-button>
    <cvt-button
      :text="$t(labelI18n('Done'))"
      :special-style="isSpecialStyle"
      @click.prevent="validateBeforeDone"
    ></cvt-button>
  </template>
</cvt-dialog>
