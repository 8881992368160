<template>
  <div>
    <h1>Upload</h1>
    <h4 class="mt-3">
      FileList
    </h4>
    <div
      v-for="(listThumnailOrIcon, index) in listThumbnailIcon"
      :key="index"
    >
      <span>{{ index }}</span>
      <cvt-file-list
        :files="files"
        :remove-enabled="true"
        :thumbnails-enabled="listThumnailOrIcon === 'thumbnail'"
        :icons-enabled="listThumnailOrIcon === 'icon'"
        thumbnail-prop="thumbnail"
        description-prop="name"
        fallback-icon="file"
        :fallback-thumbnail="'http://longwoodinteriors.com/wp-content/uploads/2014/04/placeholder3.png'"
        :is-card="false"
        :show-separator="false"
        @remove="onRemove"
      >
        <template #noItemAvailable>
          <span>{{ customMessage }}</span>
        </template>
      </cvt-file-list>
    </div>

    <h4 class="mt-3">
      Upload
    </h4>
    <span>Default</span>
    <cvt-upload
      class="mt-3"
      :remove-enabled="true"
      :icons-enabled="true"
      :thumbnails-enabled="false"
      fallback-icon="file"
      :manual-upload="false"
      :plain="false"
      :disabled="false"
      :limit="10000000"
      :accept="'*'"
      :multiple="false"
      :action="'http://localhost:3000/file_upload/'"
      :webkitdirectory="false"
    />
    <span>Default</span>
    <cvt-upload
      class="mt-3"
      :remove-enabled="true"
      :icons-enabled="true"
      :thumbnails-enabled="false"
      fallback-icon="file"
      :manual-upload="true"
      :plain="true"
      :disabled="false"
      :limit="10000000"
      :accept="'*'"
      :multiple="false"
      :action="'http://localhost:3000/file_upload/'"
      :webkitdirectory="false"
    />
  </div>
</template>
<script>
export default {
  name: 'PlayItemList',
  components: {
  },
  data () {
    return {
      listThumbnailIcon: {
        Thumbnail: 'thumbnail',
        Icon: 'icon'
      },
      files: [
        {
          thumbnail:
            'https://ae01.alicdn.com/kf/HTB1gXaPXffsK1RjSszgq6yXzpXau/Mochila-Original-Xiaomi-mi-colorida-10L-tama-o-peque-o-hombro-8-colores-165g-peso-deporte.jpg_50x50.jpg',
          name: 'this is a very long wrapping list item text'
        },
        {
          thumbnail:
            'https://ae01.alicdn.com/kf/HTB1gXaPXffsK1RjSszgq6yXzpXau/Mochila-Original-Xiaomi-mi-colorida-10L-tama-o-peque-o-hombro-8-colores-165g-peso-deporte.jpg_50x50.jpg',
          name: 'Second listed item'
        },
        {
          thumbnail:
            'https://ae01.alicdn.com/kf/HTB1gXaPXffsK1RjSszgq6yXzpXau/Mochila-Original-Xiaomi-mi-colorida-10L-tama-o-peque-o-hombro-8-colores-165g-peso-deporte.jpg_50x50.jpg',
          name: 'Third listed item'
        },
        {
          thumbnail:
            'https://ae01.alicdn.com/kf/HTB1gXaPXffsK1RjSszgq6yXzpXau/Mochila-Original-Xiaomi-mi-colorida-10L-tama-o-peque-o-hombro-8-colores-165g-peso-deporte.jpg_50x50.jpg',
          name: 'Fourth listed item'
        }
      ]
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    onRemove (item) {
      this.files.splice(
        this.files.findIndex((sItem) => sItem.name === item.name),
        1
      )
    }
  },
}
</script>
<style lang="scss">
</style>
