import {BackgroundShapeSVGObject} from "../BackgroundShapeSVGObject";

const TriangleSVG =
    `
<svg data-name="Triangle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
    <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="fill"></path>
</svg>`


export const TriangleShape = new BackgroundShapeSVGObject({
    name: "Triangle",
    canInvert: true,
    canFlip: false,
    svg: TriangleSVG
})