import api_obj from '../../../services/api'
import APIClient from '../../../services/ApiClient'

export class DefaultIntegrationAPI {
  // @ts-ignore
  protected api: APIClient

  constructor() {
    this.api = api_obj
  }

  async getMappingFields(): Promise<any> {
    throw Error('Not implemented')
  }

  async getMappedFields(siteId: string, formId: string): Promise<any> {
    throw Error('Not implemented')
  }

  async updateMappedFields(
    data: { name: string; field: string }[],
    siteId: string, formId: string
  ): Promise<any> {
    throw Error('Not implemented')
  }
}
