<template>
  <div class="field-editor">
    <div v-if="canShowListFolder" class="row my-2">
      <div class="col">
        <remote-data-selector
          v-model="folderId"
          endpoint="/v1/crm/folders"
          :placeholder="$t('element.property.form.select.folder_list')"
          :mode="mode"
        />
      </div>
    </div>
    <!-- Moved to CFormMenuOptions under a different tab -->

    <!--    <div v-if="canShowContactList" class="row my-2">-->
    <!--      <div class="col">-->
    <!--        <remote-data-selector-->
    <!--          v-model="listId"-->
    <!--          :placeholder="$t('element.property.form.select.contact_list')"-->
    <!--          :endpoint="contactListEndpoint"-->
    <!--          :mode="mode"-->
    <!--          :last-updated="contactListLastUpdated"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- Moved to CFormMenuOptions under a different tab -->
    <!--    <div v-if="canAddCustomList" class="row my-2">-->
    <!--      <div class="col">-->
    <!--        <add-custom-list @created="refreshContactLists" />-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="row my-2">
      <div class="col">
        <remote-form-fields
          :exclude="formFields"
          :is-busy="refreshForms"
          :placeholder="$t('element.property.form.add.field')"
          @selected="addQuestion"
        />
      </div>
    </div>

    <div v-if="config.CRM_FORM_FIELD_UI_ENABLED" class="row my-2">
      <div class="col">
        <add-custom-field @created="addQuestion" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="form-fields">
          <div class="row">
            <div class="col-md-12">
              <draggable-list
                :list="formFields"
                @change="updateFormFields"
                @item-clicked="selectField"
              >
                <template slot="list-item-template" slot-scope="props">
                  <i
                    v-if="FormMenuHoverEffect"
                    id="drag-handle"
                    class="material-icons"
                    >drag_handle</i
                  >
                  <cvt-icon
                    v-else
                    id="drag-handle"
                    :icon="iconConfig.leftSidebar.layout.drag || 'copy'"
                  />
                  <span :class="{ 'blue-text': !FormMenuHoverEffect }">
                    {{ fmtFieldName(props.item) }}
                  </span>

                  <div
                    :class="addHoverEffect"
                    :style="!FormMenuHoverEffect ? 'margin-left: auto;' : ''"
                  >
                    <delete-button
                      v-if="
                        !FormRequiredFieldsUnDeletable || !props.item.required
                      "
                      :marked="markedForDeletion(props.index)"
                      @mark="markFieldForDeletion(props.index)"
                      @deleteForever="confirmFieldDeletion"
                    />
                  </div>
                </template>

                <template slot="list-item-settings" slot-scope="props">
                  <field-editor
                    :visible="props.selected"
                    :field="selectedField"
                    :mode="mode"
                    @change="updateFieldById"
                  />
                </template>
              </draggable-list>

              <cvt-button
                v-if="config.MANAGE_INTEGRATIONS_ENABLED"
                :outlined="false"
                :full-width="true"
                :text="$t('element.property.manage.integrations.text')"
                :color="'LIGHT'"
                @click="() => onManageIntegrations()"
              ></cvt-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="config.CRM_FORM_FIELD_VIRTUAL_UI_ENABLED" class="row my-2">
      <div class="col">
        <virtual-fields @selected="addQuestion" />
      </div>
    </div>
  </div>
</template>

<script>
/*
This component displays as a draggable for each field of the form
within the right sidebar under: Manage Fields.
When click it expands and displays the options presented from the FieldEditor.vue component.
*/
import * as _ from 'lodash'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import * as mutations from '../../../store/modules/mutations'
// DEFINED BUT NEVER USED:
import { V1CRM } from '@/js/store/services/api'
import { nanoid } from 'nanoid'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import DeleteButton from '../../common/DeleteButton.vue'
import DraggableList from '../dragndrop/DraggableList.vue'
import AddCustomField from './AddCustomField.vue'
import FieldEditor from './FieldEditor.vue'
import RemoteDataSelector from './RemoteDataSelector.vue'
import RemoteFormFields from './RemoteFormFields.vue'
import VirtualFields from './VirtualFields.vue'
const v1crm = new V1CRM()

const contactListEndpoints = {
  standard: () => '/v1/crm/lists',
  custom: (folderId) => `/v1/crm/folders/${folderId}/lists`,
}

export default {
  components: {
    DraggableList,
    FieldEditor,
    DeleteButton,
    RemoteFormFields,
    RemoteDataSelector,
    VirtualFields,
    AddCustomField,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  data() {
    return {
      deleteFieldIdx: null,
      deleteFieldDialogOpen: false,
      selectedFieldId: null,
      contactListLastUpdated: 0,
      refreshForms: false,
    }
  },
  computed: {
    ...mapGetters({
      visible: getters.FORM_EDITOR_VISIBLE,
      selected: getters.SELECTOR_SELECTED,
      config: getters.AUTH_GET_USER_CONFIG,
      user: getters.AUTH_GET_USER,
    }),
    ...mapState('globalTheme', {
      FormMenuHoverEffect: ({ globalTheme }) =>
        globalTheme.FormMenuHoverEffect !== undefined
          ? globalTheme.FormMenuHoverEffect
          : true,
      FormRequiredFieldsUnDeletable: ({ globalTheme }) =>
        globalTheme.FormRequiredFieldsUnDeletable !== undefined
          ? globalTheme.FormRequiredFieldsUnDeletable
          : false,
      iconConfig: ({ iconConfig }) => iconConfig,
    }),
    addHoverEffect() {
      return {
        'draggable-actions': this.FormMenuHoverEffect,
      }
    },
    form() {
      return this.selected || { fields: [], tags: [] }
    },
    formFields() {
      return (this.selected && this.selected.computedFields) || []
    },
    deleteFieldName() {
      return _.get(
        this.formFields,
        `${this.deleteFieldIdx}.label`,
        'NO_NAME_FOUND',
      )
    },
    selectedField() {
      return _.find(this.formFields, {
        id: this.selectedFieldId,
      })
    },
    folderId: {
      get() {
        return _.get(this, 'selected.folderId')
      },
      set(value) {
        this.updateProp({ key: 'folderId', value })
        this.updateProp({ key: 'listId', value: null })
      },
    },
    listId: {
      get() {
        return _.get(this, 'selected.listId')
      },
      set(value) {
        this.updateProp({ key: 'listId', value })
      },
    },
    canShowListFolder() {
      return this.config.CRM_FORM_LIST_FOLDER_ENABLED
    },
    canShowContactList() {
      return this.config.CRM_FORM_CONTACT_LIST_ENABLED
    },
    canAddCustomList() {
      return this.config.CRM_FORM_CAN_ADD_CUSTOM_LIST
    },
    contactListEndpoint() {
      return this.config.CRM_FORM_STANDARD_CONTACT_LIST
        ? contactListEndpoints.standard()
        : contactListEndpoints.custom(this.folderId)
    },
  },
  methods: {
    markedForDeletion(idx) {
      return this.deleteFieldIdx === idx
    },
    ...mapMutations({
      close: mutations.FORM_EDITOR_HIDE,
      triggerIntegrationsDialog: mutations.TRIGGER_INTEGRATIONS_DIALOG,
    }),
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
    }),
    fmtFieldName(field) {
      return field.label || field.placeholder
    },
    reset() {
      this.deleteFieldIdx = null
      this.deleteFieldDialogOpen = false
      this.selectedField = null
    },
    markFieldForDeletion(idx) {
      this.deleteFieldIdx = idx
      this.deleteFieldDialogOpen = true
    },
    async updateName(e) {
      const { value } = e.target

      if (!this.form.listId) {
        const { data } = await v1crm.createList({ name: value })
        this.updateProp({ key: 'listId', value: data.id })
      }

      this.updateProp({ key: 'formName', value: e.target.value })
    },
    confirmFieldDeletion() {
      const fields = this.formFields.slice()
      fields.splice(this.deleteFieldIdx, 1)
      this.refreshForms = true
      this.updateFormFields(fields).then(() => {
        this.deleteFieldDialogOpen = this.refreshForms = false
      })
    },
    updateFormFields(fields) {
      return this.updateProp(
        {
          key: 'fields',
          value: fields,
        },
        fields,
      )
    },
    updateFieldById(field) {
      const fields = this.formFields.slice()
      const idx = _.findIndex(fields, { id: field.id })
      if (idx >= 0) {
        fields.splice(idx, 1, field)
        return this.updateFormFields(fields)
      }
    },
    selectField({ payload, index }) {
      if (this.selectedFieldId === payload.id) {
        this.selectedFieldId = null
        return
      }
      this.selectedFieldId = payload.id
    },
    addQuestion(field) {
      const defaults = ['default_input', 'default_select']

      if (defaults.includes(field.field_type)) {
        field.id = `${field.id}_${this.randomID()}`
      }

      const fields = this.formFields.slice()

      let deduper = {}
      for (let i = 0; i < fields.length; i++) {
        deduper[fields[i].id] = null
      }

      if (!(field.id in deduper)) {
        fields.push(field)
      }
      this.refreshForms = true
      this.updateFormFields(fields).then(() => {
        this.refreshForms = false
      })
    },
    onManageIntegrations() {
      this.triggerIntegrationsDialog(true)
    },
    randomID() {
      return nanoid(5)
    },
    refreshContactLists(data) {
      this.contactListLastUpdated = Date.now()
      this.listId = data.listId || ''
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

.blue-text {
  color: #2c5cc5;

  &:active {
    color: white;
  }
}
</style>
