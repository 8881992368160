<template>
  <div class="visibility-controls">
    <div class="row mt-2">
      <div class="col">
        <cvt-alert
          color="primary"
          icon="info-circle"
        >
          {{ $t('elements.properties.visibility.header') }}
          <span class="help">
            <br>
            {{ $t("elements.properties.visibility.message") }}
          </span>
        </cvt-alert>
      </div>
    </div>

    <cvt-radio-group
      v-if="!VisibilitySwitch"
      orientation="vertical"
      :initial-value="initialValue"
    >
      <cvt-alt-radio
        size="md"
        value="both"
        :shape="RadioButtonShape"
        :border="false"
        @radioClick="() => showVisibility('both')"
      >
        Show in desktop & mobile
      </cvt-alt-radio>
      <cvt-alt-radio
        size="md"
        value="desktop-only"
        :shape="RadioButtonShape"
        :border="false"
        @radioClick="() => {
          showVisibility('showDesktop')
        }"
      >
        Show in desktop only
      </cvt-alt-radio>
      <cvt-alt-radio
        size="md"
        value="mobile-only"
        :shape="RadioButtonShape"
        :border="false"
        @radioClick="() => {
          showVisibility('showMobile')
        }"
      >
        Show in mobile only
      </cvt-alt-radio>
    </cvt-radio-group>

    <div v-else>
      <div
        class="row mt-2"
      >
        <div class="col">
          <h6 :class="textColorClass">
            {{ $t("elements.properties.visibility.mobile") }}
          </h6>
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="hideOnMobile"
            :disabled="hideOnDesktop"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('hideOnMobile')"
          />
        </div>
      </div>

      <div class="divider" />

      <div class="row mt-2">
        <div class="col">
          <h6 :class="textColorClass">
            {{ $t("elements.properties.visibility.desktop") }}
          </h6>
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="hideOnDesktop"
            :disabled="hideOnMobile"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('hideOnDesktop')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import * as _ from 'lodash'
export default {
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    }
  },
  data () {
    return {
      direction: 'vertical'
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED
    }),
    ...mapState('globalTheme', {
      VisibilitySwitch: ({ globalTheme }) => globalTheme.VisibilitySwitch !== undefined ? globalTheme.VisibilitySwitch : true,
    }),
    maxSelectedVisibilityOptions () {
      return _.filter([ this.hideOnMobile, this.hideOnDesktop], v => v === true).length >= 1
    },
    hideOnMobile: {
      get () {
        return this.selected.hideOnMobile
      },
      set (value) {
        if (!this.VisibilitySwitch) return this.updateProp({ key: 'hideOnMobile', value })
        return this.updatePropWithValidation({ key: 'hideOnMobile', value })
      }
    },
    hideOnDesktop: {
      get () {
        return this.selected.hideOnDesktop
      },
      set (value) {
        if (!this.VisibilitySwitch) return this.updateProp({ key: 'hideOnDesktop', value })
        return this.updatePropWithValidation({ key: 'hideOnDesktop', value })
      }
    },
    textColorClass () {
      return {'text-white': this.mode == 'DARK'}
    },
    initialValue () {
      if (!this.hideOnMobile && !this.hideOnDesktop) {
        return 'both'
      } else if (!this.hideOnMobile) {
        return 'mobile-only'
      } else {
        return 'desktop-only'
      }
    }
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP
    }),
    updatePropWithValidation (...args) {
      const { hideOnMobile, hideOnDesktop } = this.selected
      const value = _.get(args, '0.value')

      if (this.maxSelectedVisibilityOptions && value) {
        this.$notify({
          title: 'Validation Error',
          message:
            this.$t('editor.error.visibility_ctlr.error_message'),
          type: 'error'
        })
        return
      }
      return this.updateProp(...args)
    },
    onSwitchToggle (property) {
      property == 'hideOnMobile' ? this.hideOnMobile = !this.hideOnMobile : property == 'hideOnDesktop' ? this.hideOnDesktop = !this.hideOnDesktop : ''
    },
    showVisibility (value) {
      if (value === 'both') {
        this.hideOnMobile = false
        this.hideOnDesktop = false
      } else if (value === 'showDesktop') {
        this.hideOnDesktop = false
        this.hideOnMobile = true
      } else if (value === 'showMobile') {
        this.hideOnMobile = false
        this.hideOnDesktop = true
      }
    },
  }
}
</script>
