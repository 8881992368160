
import { Organization } from '../../../../store/services/api'
const organizationApi = new Organization()

import InviteUser from '../../InviteUser/InviteUser.vue'

export default {
  components: {
    InviteUser
  },
  props: {},
  data() {
    return {
      emailAddresses: [{ email: '' }],
      loading: false
    }
  },
  created() {},
  methods: {
    async sendInvite(): Promise<void> {
      const isEmpty = this.emailAddresses.some((input: {email: string}) => Object.values(input).some((val) => !val.trim()))
      if (isEmpty) return

      try {
        this.loading = true
        const emailsArr = this.emailAddresses.map(input => ({
          email: input.email,
          role: 'admin',
        }));
        const payload = emailsArr
        await organizationApi.inviteITMember(payload);
        this.loading = false
        this.$message.info('invite sent successfuly');
      } catch (err) {
        this.loading = false
        this.$message.error('An error occured.');
      }

    }
  },
}
