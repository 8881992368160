import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { styled } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import * as constants from '../../../../builder/src/js/common/constants/constants'
import { mapGetters, mapState, mapActions } from 'vuex'

import CvtButton from '../../molecules/Button'
import * as _ from 'lodash'

Vue.use(VueI18n)

let textFontWeight, textFontSize, Spacing, FontSize, FontFamily, backgroundColor, Colors

const getAuthenticateWrapper = () => styled('div')`
font-family: ${FontFamily.DEFAULT};
`
 
const getAuthenticateContainer = () => styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${FontFamily.DEFAULT};
`

const getAuthenticateHelpText = () => styled('p')`
  font-size: 12px;
  margin: 0;
`

const getAuthenticationDescription = () => styled('div')`
  background: ${Colors.BASIC[100]};
  font-family: ${FontFamily.DEFAULT};
`

const getHorizontalLine = () => styled('div')`
  border-top: 1px solid ${Colors.BASIC[300]};
  margin-bottom: 15px;
`

export const getIntegrationTitle = () => styled('p')`
  font-size: 16px;
`

export const getIntegrationUseWrap = () => styled('div')`
  font-size: 16px;
`

export const getIntegrationUseSubtext = () => styled('p')`
  font-size: 16px;
`

export const getButton = () => styled(CvtButton)`
  background-color: ${Colors.PRIMARY[500]};
`

export const IntegrationDescription = {
  name: 'IntegrationDescription',
  components: {},
  data() {
    return {}
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    provider: {
      type: String,
      default: 'googleSheet',
    },
    authenticationText: {
      type: String,
      default: 'authenticate',
    },
    skipAuth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      selectedIntegration: getters.SELECTED_INTEGRATION,
      providerAPI: getters.GET_PROVIDER_API_SERVICE,
      site: getters.SITE_GET_DATA,
      selected: getters.SELECTOR_SELECTED,
    }),
    ...mapGetters('globalTheme', {
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
    }),
    ...mapState('sitePreferences', {
      integrationConfigs: ({ preferences }) =>
        _.get(preferences, 'integrations', {}),
    }),

    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      globalTheme: ({ globalTheme }) => globalTheme,
    }),
    providerMisMatch() {
      return (
        this.selectedIntegration.key !== this.integrationConfig.provider &&
        [
          constants.INTEGRATION_COMPLETE,
          constants.INTEGRATION_FINISHED,
        ].includes(this.integrationConfig.step)
      )
    },
    integrationConfig () {
      return this.integrationConfigs[this.selected.id] || {
        provider: "",
        step: constants.AUTH_INIT
      }
    }
  },
  created() {
    textFontSize = this.textFontSize
    textFontWeight = this.textFontWeight
    Spacing = this.Spacing
    FontSize = this.FontSize
    backgroundColor = this.backgroundColor
    Colors = this.Colors
    FontFamily = this.FontFamily
  },
  methods: {
    ...mapActions('sitePreferences', {
      saveIntegrationStep: 'saveIntegrationStep',
    }),
    onChangeAuthStep(step) {
      const win = window.open(
        `/api/oauth/${this.provider}/authorize`,
        '_blank',
        'height=600,width=600,popup=true',
      )
      if (win) {
        let self = this
        const eventListener = (event) => {
          if (event.data === `success` && event.source === win) {
            try {
              win.close()
            } catch (e) {
              console.error(e)
            }
            self.$emit('stepChange', step)

            window.removeEventListener('message', eventListener)
            // this.providerAPI
            //   .saveActiveIntegration(this.provider, this.site.id, this.selected.id)
            //   .then()
          }
        }
        window.addEventListener('message', eventListener)
      }
    },
    authSkipped(step) {
      this.$emit('stepChange', step)
    },
    async onDeactivate(step) {
      await this.saveIntegrationStep(
        this.selected.id,
        {
          provider: this.selectedIntegration.key,
          step: step,
        }
      )
    },
  },
  render: function (h) {
    const AuthenticateContainer = getAuthenticateContainer()
    const AuthenticateHelpText = getAuthenticateHelpText()
    const AuthenticationDescription = getAuthenticationDescription()
    const HorizontalLine = getHorizontalLine()
    const AuthenticateWrapper = getAuthenticateWrapper()

    const Button = getButton()

    const integrationTitle = this.selectedIntegration.title

    const AuthenticateAction = () => {
      return (
        <AuthenticateWrapper>
          <HorizontalLine></HorizontalLine>
          <p style="font-weight: 700;padding-top:10px;font-size: 14px;">
            Authentication
          </p>
          <AuthenticateContainer>
            <AuthenticateHelpText>
              Authenticate your {integrationTitle} account in order to create an
              integration.
            </AuthenticateHelpText>
            {this.skipAuth ? (
              <Button
                text={this.authenticationText.toUpperCase()}
                outlined={true}
                specialPadding="12px"
                onClick={() => this.authSkipped(constants.AUTH_SUCCESS)}
              ></Button>
            ) : (
              <Button
                text={this.authenticationText.toUpperCase()}
                outlined={true}
                specialPadding="12px"
                onClick={() => this.onChangeAuthStep(constants.AUTH_SUCCESS)}
              ></Button>
            )}
          </AuthenticateContainer>
        </AuthenticateWrapper>
      )
    }

    const MisMatchProvider = () => {
      return (
        <AuthenticateWrapper>
          <HorizontalLine></HorizontalLine>
          <p style="font-weight: 700;padding-top:10px;font-size: 14px;">
            DEACTIVATE {(this.integrationConfig.provider || '').toUpperCase()}
          </p>
          <AuthenticateContainer>
            <AuthenticateHelpText>
              To proceed with <strong>{integrationTitle}</strong> integration,
              you have to deactivate{' '}
              <strong>{this.integrationConfig.provider}</strong>
            </AuthenticateHelpText>
            <CvtButton
              text="DEACTIVATE"
              outlined={true}
              specialPadding="12px"
              color="danger"
              onClick={() => this.onDeactivate(constants.AUTH_INIT)}
            ></CvtButton>
          </AuthenticateContainer>
        </AuthenticateWrapper>
      )
    }

    return (
      <AuthenticationDescription>
        <div>
          {this.$slots.default}
          <div style="padding: 15px 0 40px 45px;">
            {this.providerMisMatch ? (
              <MisMatchProvider />
            ) : (
              <AuthenticateAction />
            )}
          </div>
        </div>
      </AuthenticationDescription>
    )
  },
}
