import { VueEmotion, styled } from '@egoist/vue-emotion'
import DOMPurify from 'dompurify'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import * as getters from '../../../builder/src/js/store/modules/getters'
import Vue from '../../lib/utils/vue'
import { LEFT_SIDEBAR_FRESHWORKS, LEFT_SIDEBAR_STANDARD } from '../constants'
import CvtButton from './Button'
import CvtFieldLabel from './FieldLabel'
import DropdownMenu from './dropdown/DropdownMenu'
import { CvtIcon } from './icon/icon'

let orgName,
  Spacing,
  Radii,
  elevation,
  Colors,
  backgroundColor,
  Border,
  borderColor,
  color,
  textColor,
  FontFamily,
  textFontSize,
  textLineHeight,
  textFontWeight

Vue.use(VueEmotion)

/* 
Bottom margin commented out to add consistency in spacing
for when displaying control elements in the right sidebar
button editor.
*/
const getFormGroupDiv = () => styled('div')`
//   margin-bottom: ${Spacing.SPACING_16}px;
`

const getDropdown = () => styled('div')`
  position: relative;
  user-select: none;
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  border-radius: ${Radii.RADIUS_PX_8}px;
`

const getInputGroupDiv = () => styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  border: ${Border.SIZE_1}px solid ${Colors.BASIC[300]};
  ${(props) => `border-radius: ${props.selectRadius ? props.selectRadius : Radii.RADIUS_PX_8}px;`}

  &:hover {
    cursor: pointer;
  }

  ${(props) => {
    if (props.disabled)
      return `
        cursor: not-allowed; 
        ${backgroundColor({ themeing: 'dark' }, 200)} 
        opacity: 1;
        :hover{
          cursor: not-allowed; 
          ${backgroundColor({ themeing: 'dark' }, 200)} 
          opacity: 1;
        }`

    let styles = ''

    if (props.inputFocused) {
      styles +=
        orgName === LEFT_SIDEBAR_FRESHWORKS
          ? `${borderColor({ themeing: 'info' }, 800)} border-width:${Border.SIZE_2
          }px ;` : `${borderColor({ themeing: 'primary' }, 500)} ${elevation(props, 'INSET')}`
    } else {
      styles += `&: hover {
        ${borderColor({ themeing: 'dark' }, 400)}
      } `
    }

    return styles
  }}
`

const getFormControlInput = () => styled('input')`
  outline: 0;
  display: block;
  width: 100%;     
  height: ${Spacing.SPACING_32}px;
  padding: ${Spacing.SPACING_12}px ${Spacing.SPACING_4}px ${Spacing.SPACING_12}px ${Spacing.SPACING_4}px;
  margin: ${Spacing.SPACING_4}px ${Spacing.SPACING_8}px;
  ${textFontSize('md')}
  ${textLineHeight('md')}
  ${textFontWeight('LIGHT')}  
  font-family: ${FontFamily.DEFAULT};
  z-index: 0;
  ${color({ themeing: 'dark' }, 500)}
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  background-clip: padding-box;
  border: 0px solid transparent;
  border-radius: ${Radii.RADIUS_PERCENT_50 / 4}%;
  box-shadow: none;
  &:hover {
    cursor: pointer;
  }
  
  ${(props) => {
    if (props.disabled)
      return `
        cursor: not-allowed; 
        ${backgroundColor({ themeing: 'dark' }, 200)} 
        opacity: 1;
        :hover{
          cursor: not-allowed; 
          ${backgroundColor({ themeing: 'dark' }, 200)} 
          opacity: 1;
        }`
  }}

  transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  will-change: border-color, box-shadow;

  ~ .floating-label {
    position: absolute;
    pointer-events: none;
    left: 16px;
    top: 12px;
    transition: 0.2s ease all;
    max-width: 75%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    ${textFontSize('lg')}
    ${textLineHeight('lg')}
    ${textFontWeight('LIGHT')}  
  }

  &:focus ~ .floating-label,
  &.valid ~ .floating-label,
  &.valid:focus ~ .floating-label {
    top: -8px;
    left: 16px;
    opacity: 1;
    padding: 0 10px;
    border-radius: ${Radii.RADIUS_PX_8}px;
    ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
    ${textFontSize('sm')}
    ${textLineHeight('sm')}
    ${textFontWeight('LIGHT')}  
  }

  &.valid ~ .floating-label {
    ${borderColor({ themeing: 'dark' }, 300)}
  }
`

const getInputGroupTextSpan = () => styled('span')`
  display: flex;
  align-items: center;
  position: relative;
  padding: ${Spacing.SPACING_8 + Spacing.SPACING_2}px ${Spacing.SPACING_8}px ${Spacing.SPACING_8}px ${Spacing.SPACING_8}px;
  margin-bottom: 0;
  ${textFontSize('md')}
  ${textFontWeight('LIGHT')}  
  font-family: ${FontFamily.DEFAULT};
  ${textLineHeight('md')}
  ${color({ themeing: 'dark' }, 300)}
  text-align: center;
  white-space: nowrap;
  height: ${Spacing.SPACING_12}px;
`

const getVisibleIcon = () => styled(CvtIcon)`
  ${color({ themeing: 'dark' }, 300)}
  :hover {
    ${color({ themeing: 'dark' }, 400)}
    cursor: pointer;
  }

  transition: all 0.3s ease;
  &.up {
    transform: rotate(180deg);
  }

  ${(props) => {
    if (props.disabled) {
      return `
      :hover{
        cursor: not-allowed;
        ${color({ themeing: 'dark' }, 300)}
        ${backgroundColor({ themeing: 'dark' }, 200)}
        opacity: 1;
      }`
    }
  }}
`

const getClearBtnWrapSpan = () => styled('span')`
  display: inline-flex;
`

const getClearButton = () => styled(CvtButton)`
  margin: 0;
`

const getTagsInput = () => styled('div')`
  outline: 0;
  width: 100%;
  padding: ${Spacing.SPACING_8}px 0px ${Spacing.SPACING_8}px ${Spacing.SPACING_16
  }px;
  ${textFontSize('md')}
  ${textLineHeight('md')}
  ${textFontWeight('LIGHT')}    
  font-family: ${FontFamily.DEFAULT};   
  ${color({ themeing: 'dark' }, 500)}
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  background-clip: padding-box;
  border: ${Border.SIZE_1}px solid ${Colors.BASIC[300]};
  border-radius: ${Radii.RADIUS_PX_8}px; 
  box-shadow: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: auto;

  ${(props) => {
    if (props.disabled)
      return `
        cursor: not-allowed; 
        ${backgroundColor({ themeing: 'dark' }, 200)} 
        opacity: 1;
        :hover{
          cursor: not-allowed; 
          ${backgroundColor({ themeing: 'dark' }, 200)} 
          opacity: 1;
        }`

    if (props.isActive) {
      let styles = ''
      styles +=
        orgName === LEFT_SIDEBAR_FRESHWORKS
          ? `${borderColor({ themeing: 'info' }, 800)} border-width:${Border.SIZE_2
          }px ;`
          : `${borderColor({ themeing: 'primary' }, 500)} ${elevation(
            props,
            'INSET'
          )} `
      return styles
    } else {
      return `&:hover {
              cursor: pointer;
              ${borderColor({ themeing: 'dark' }, 400)}
            }`
    }
  }}

  transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
  border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
`

const getTagsWrapDiv = () => styled('div')`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  overflow: auto;
`

const getTagsBadgeSpan = () => styled('span')`
  display: inline-block;
  padding: ${Spacing.SPACING_8 - 2}px ${Spacing.SPACING_8}px;
  ${textFontSize('md')}
  ${textFontWeight('REGULAR')}
  ${textLineHeight('md')}    
  font-family: ${FontFamily.DEFAULT};
  text-align: center;
  white-space: break-spaces;
  vertical-align: baseline;
  border: ${Border.SIZE_1}px solid ${Colors.BASIC[200]};
  border-radius: ${Radii.RADIUS_PX_8}px;   
  ${color({ themeing: 'dark' }, 400)}
  ${backgroundColor({ themeing: 'dark' }, 100)}

  margin-right: ${Spacing.SPACING_8}px;

  ${(props) => {
    if (props.disabled)
      return `
      cursor: not-allowed; 
      ${backgroundColor({ themeing: 'dark' }, 200)} 
      opacity: 1;
      :hover{
        cursor: not-allowed; 
        ${backgroundColor({ themeing: 'dark' }, 200)} 
        opacity: 1;
      }`
  }}
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
`

const getTagsBadgeAnchor = () => styled('a')`
  margin-left: ${Spacing.SPACING_8}px;
  :hover {
    color: unset;
    text-decoration: none;
  }
`

const getTagsInputTerm = () => styled('input')`
  height: 100%;
  width: 100%;
  margin: 0;
  border: 0;
  outline: 0;
  ${textFontSize('lg')}
  ${textLineHeight('lg')}
  ${textFontWeight('LIGHT')}    
  font-family: ${FontFamily.DEFAULT};
  ${color({ themeing: 'dark' }, 500)}  
  ${(props) => {
    if (props.disabled)
      return `
      cursor: not-allowed; 
      ${backgroundColor({ themeing: 'dark' }, 200)} 
      opacity: 1;
      :hover{
        cursor: not-allowed; 
        ${backgroundColor({ themeing: 'dark' }, 200)} 
        opacity: 1;
      }`
  }}

  ~ .floating-label {
    position: absolute;
    pointer-events: none;
    left: 0px;
    top: 0px;
    transition: 0.2s ease all;
    ${textFontSize('lg')}
    ${textLineHeight('lg')}
    ${textFontWeight('LIGHT')}  
  }

  &:focus ~ .floating-label,
  &.valid ~ .floating-label,
  &.valid:focus ~ .floating-label {
    top: -16px;
    left: 0px;
    opacity: 1;
    padding: 0 10px;
    border-radius: ${Radii.RADIUS_PX_8}px;
    ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
    ${textFontSize('sm')}
    ${textLineHeight('sm')}
    ${textFontWeight('LIGHT')}  
  }
`

const getSmallSpinner = () => styled('i')`
  position: absolute;
  top: 0;
  left: 0;
  width: ${Spacing.SPACING_16}px;
  height: ${Spacing.SPACING_16}px;
  display: inline-block;
  vertical-align: text-bottom;
  border: 0.2em solid;
  border-color: ${Colors.BASIC[500]};
  border-right-color: transparent;
  border-radius: ${Radii.RADIUS_PERCENT_50}%;
  animation: 1s spin linear infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

let uuid = 0
export default {
  name: 'CvtSelect',
  components: {
    CvtIcon,
    CvtFieldLabel
  },
  props: {
    defaultValue: {
      type: String,
      default:''
    },
    placeholder: {
      type: String,
      default:''
    },
    value: {
      type: [String, Number, Array]
    },
    showCancelIcon: {
      type: Boolean,
      default: true
    },
    theme: {
        type: String,
        default: 'LIGHT'
    },
    backgroundMode: {
      type: String,
      default: 'LIGHT'
    },
    label: {
        type: String,
        default: ''
    },
    helpText: String,
    multiple: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'dropdown'
    },
    selectDisabled: {
      type: Boolean,
      default: false
    },
    singleLine: {
      type: Boolean,
      default: false
    },
    fieldlabelStyles: {
      type: Object,
      default: () => {}
    },
    selectRadius: {
      type: String,
      default: ''
    },
    fetchSuggestions: Function,
    dropdownMenuStyles: {
      type: Object,
      default: () => ({})
    },
  },
  data () {
    return {
      selected: [],
      options: [],
      filtered: [],
      open: false,
      term: '',
      loading: false,
      inputFocused: false,
      inputMouseenter: false,
      inputMouseleave: false
    }
  },
  beforeCreate () {
    this.uuid = `CvtSelect:${uuid}`
    uuid = uuid + 1
  },
  created () {
    orgName = this.orgName
    Spacing = this.Spacing
    Radii = this.Radii
    elevation = this.elevation
    Colors = this.Colors
    backgroundColor = this.backgroundColor
    Border = this.Border
    borderColor = this.borderColor
    color = this.color
    textColor = this.textColor
    FontFamily = this.FontFamily
    textFontWeight = this.textFontWeight
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
  },
  beforeMount () {
    this.options = this.getOptions()
    this.filtered = this.options.slice()
  },
  mounted () {
    const filterValue = this.value ? this.value : this.defaultValue;
    this.selectedFromValue(filterValue)
    this.options = this.getOptions()
    this.filtered = this.options.slice()
    if (this.$refs.dropdown) {
      document.body.addEventListener('click', this.handleTargetClick)
    }
  },
  beforeDestroy () {
    if (this.$refs.dropdown) {
      document.body.removeEventListener('click', this.handleTargetClick)
    }
  },
  watch: {
    value (value) {
      this.selectedFromValue(value)
    },
    term (value) {
      this.search(value)
    },
    open (value) {
      this.$emit('visible-change', value)
    }
  },
  computed: {
    ...mapGetters({
        config: getters.AUTH_GET_USER_CONFIG,
      }),
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      color: getters.GLOBAL_STYLE_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      elevation: getters.GLOBAL_STYLE_ELEVATION
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      Border: ({ globalTheme }) => globalTheme.Border,
      orgName: ({ globalTheme }) =>
        globalTheme?.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
      labelType: ({ globalTheme }) => globalTheme.labelType,
    }),
    selectedLabel () {
      if (this.selected.length > 0) {
        return this.selected.map((i) => i.label).join(', ')
      }
    },
    selectedValues () {
      if (this.selected.length > 0) {
        return this.selected.map((i) => i.value)
      }
      return []
    },
    showSuggestions () {
      return this.loading || (this.open && this.filtered.length > 0)
    },
    showClearIcon () {
      if (!this.selectDisabled && this.selected.length > 0 && this.showCancelIcon) {
        if (this.inputMouseenter) {
          return true
        }
        if (this.inputMouseleave) {
          return false
        }
      }
      return false
    },
    iconName () {
      return this.showSuggestions ? 'chevron-up' : 'chevron-down'
    }
  },
  methods: {
    getOptions () {
      const formatOptions = (item, group) => {
        if (item.tag === 'option') {
          let out = {
            group,
            value: item?.data?.domProps?.value || item.data?.attrs?.value,
            content: item?.children,
            label:
              item?.data?.domProps?.textContent ||
              item?.data?.attrs?.label ||
              item?.children?.[0]?.text
          }
          if (!out.label) {
            out.label = out.value
          }
          return out
        }
      }
      if (this.$slots.default && this.$slots.default.flatMap) {
        let group = undefined
        let v = this.$slots.default
          .flatMap((item) => {
            if (item.tag === 'option') {
              return formatOptions(item, group)
            } else if (item.tag === 'optgroup') {
              group = item.data.attrs.label
              return (item.children || []).map((childItem) => {
                return formatOptions(childItem, group)
              })
            }
          })
          .filter(Boolean)
        return v
      } else {
        return []
      }
    },
    toggleDropdown () {
      if (!this.selectDisabled) this.open = !this.open
    },
    reset (e) {
      this.open = false
      this.term = ''
      if (this.$refs.term) {
        this.$refs.term.blur()
      }
      this.filtered = this.options
    },
    onClickTagsInput () {
      if (!this.selectDisabled) {
        this.open = true
        if (this.$refs.term) {
          this.$refs?.term.focus()
        }
      }
    },
    onInputTags (e, h) {
      if (e.key === 'Enter' && this.term.length) {
        if (
          this.options.find(
            (item) => item.value === this.term || item.label === this.term
          )
        ) {
          alert('This value already exist!')
          return
        }

        let newOption = {}
        newOption.label = this.term
        newOption.value = this.term
        newOption.content = h('span', this.term)

        this.filtered.push(newOption)
        this.options.push(newOption)
      }
      this.term = e.target.value
    },
    selectedFromValue (value) {
      if (Array.isArray(value)) {
        this.selected = value.map((v) => this.itemForValue(v))
      } else {
        this.selected = [this.itemForValue(value)]
      }
      this.selected = this.selected.filter(Boolean)
    },
    itemForValue (value) {
      return this.options.find((i) => i.value === value)
    },
    labelForValue (value) {
      let item = this.itemForValue(value)
      if (item) {
        return item.label
      } else {
        return value
      }
    },
    onChange (option) {
      if (this.mode === 'tags') {
        this.term = ''
        this.$refs.term.focus()
      }
      if (this.multiple) {
        if (!this.selectedValues.includes(option.value)) {
          this.selected.push(option)
        } else {
          this.selected.splice(this.selectedValues.indexOf(option.value), 1)
        }
        this.$emit(
          'input',
          this.selected.slice(0).map((i) => i.value)
        )
      } else {
        this.open = false
        this.selected = [option]
        this.$emit('input', option.value)
      }
    },
    search (term) {
      if (this.fetchSuggestions && term.length > 0) {
        this.loading = true
        this.fetchSuggestions(term, (suggestions) => {
          this.loading = false
          if (!Array.isArray(suggestions)) {
            console.error(
              '[Convrrt Element][Input] autocomplete suggestions must be an array'
            )
            return
          }
          this.filtered = suggestions
        })
      } else {
        this.filtered = this.options.filter((item) => {
          return ((item?.value?.toString().indexOf(term) !== -1) || (item?.label?.toString().indexOf(term) !== -1));
        })
      }
    },
    removeSelection (option) {
      if (!this.selectDisabled)
        this.selected.splice(this.selected.indexOf(option), 1)

      this.$emit(
        'input',
        this.selected.slice(0).map((i) => i.value)
      )
    },
    hasItem (item) {
      return this.selected.find(
        (selectedItem) => selectedItem.value === item.value
      )
    },
    stopPropagation (event) {
      event.stopPropagation()
    },
    onClickOutside () {
      this.open = false
    },
    isGroupSameAsPrevious (array, index) {
      if (array[index]?.group && array[index - 1]?.group) {
        return array[index]?.group === array[index - 1]?.group
      } else {
        return false
      }
    },
    onInputEvent (eventName, eventData) {
      if (eventName === 'focus') {
        this.inputFocused = true
      }

      if (eventName === 'blur') {
        this.inputFocused = false
      }

      if (eventName === 'mouseenter') {
        this.inputMouseenter = true
        this.inputMouseleave = false
      }

      if (eventName === 'mouseleave') {
        this.inputMouseleave = true
        this.inputMouseenter = false
      }

      if (eventName === 'input') {
        let sanitizedValue = DOMPurify.sanitize(eventData.target.value)

        // replaceAll function is not supported in Chrome < 85, Firefox < 77
        // caused issues for multiple clients so changed it to more compatible function
        this.term = sanitizedValue?.replace(/&gt;/g, '>')?.replace(/&lt;/g, '<')
        eventData.target.value = this.term
      }
      this.$emit(eventName, eventData)
    },
    onSearchInput(e) {
      let sanitizedValue = DOMPurify.sanitize(e.target.value)

      // replaceAll function is not supported in Chrome < 85, Firefox < 77
      // caused issues for multiple clients so changed it to more compatible function
      this.term = sanitizedValue?.replace(/&gt;/g, '>')?.replace(/&lt;/g, '<')
    },
    clearInput (e) {
      e.stopPropagation()
      this.clear()
    },
    clear() {
      this.selected.splice(0)
      if (this.open) this.open = !this.open
      this.$emit('cleared')
    },
    getMenuTopPos () {
      let select = this.$refs.select
      const height = select.getBoundingClientRect().height
      if (this.searchEnabled) {
        return height + 36
      }
      return height
    },
    getMenuBottomPos () {
      let select = this.$refs.select
      return select.getBoundingClientRect().height + 16
    },
    handleTargetClick (e) {
      if (this.$refs.dropdown && this.$refs.dropdown.contains(e.target)) {
        e.stopPropagation()
      } else {
        this.onClickOutside()
      }
    }
  },
  render: function (h) {
    const FormGroupDiv = getFormGroupDiv()
    const Dropdown = getDropdown()
    const TagsInput = getTagsInput()
    const TagsWrapDiv = getTagsWrapDiv()
    const TagsBadgeSpan = getTagsBadgeSpan()
    const TagsBadgeAnchor = getTagsBadgeAnchor()
    const ClearBtnWrapSpan = getClearBtnWrapSpan()
    const ClearButton = getClearButton()
    const TagsInputTerm = getTagsInputTerm()
    const InputGroupTextSpan = getInputGroupTextSpan()
    const VisibleIcon = getVisibleIcon()
    const SmallSpinner = getSmallSpinner()
    const InputGroupDiv = getInputGroupDiv()
    const FormControlInput = getFormControlInput()

    const TagInput = (
      <TagsInputTerm
        ref='term'
        type='text'
        value={this.term}
        class={this.selected.length > 0 ? 'valid' : ''}
        onKeyup={(e) => this.onInputTags(e, h)}
        disabled={this.selectDisabled}
        onInput={(e) => this.onInputEvent('input', e)}
        onFocus={(e) => this.onInputEvent('focus', e)}
        onBlur={(e) => this.onInputEvent('blur', e)}
        placeholder={this.inputFocused ? 'Enter a new tag here' : ''}
      />
    )

    return (
      <FormGroupDiv
        identity='FormGroup'
        onKeydown={(e) => {
          if (e.key === 'Escape') this.reset(e)
        }}
      >
        {this.labelType === 'standard' && (
          <CvtFieldLabel
            mode={this.mode}
            for={this.id}
            label={this.label}
            fieldlabelStyles={this.fieldlabelStyles}
          />
        )}
        <Dropdown ref='dropdown'>
          {this.mode === 'tags' && (
            <TagsInput
              ref='select'
              isActive={this.open}
              disabled={this.selectDisabled}
              mode={this.backgroundMode}
            >
              <TagsWrapDiv onClick={() => this.onClickTagsInput()}>
                {this.selected.map((selection, index) => {
                  return (
                    <TagsBadgeSpan
                      disabled={this.selectDisabled}
                      key={this.index}
                    >
                      {
                        selection
                          ?.label
                          ?.replace(/&gt;/g, '>')
                          ?.replace(/&lt;/g, '<')
                      }
                      
                      <TagsBadgeAnchor
                        onClick={() => this.removeSelection(selection)}
                      >
                        <ClearBtnWrapSpan>
                          <ClearButton
                            color='light'
                            shape='pill'
                            size='xs'
                            icon='times'
                            text=''
                            actionIcon={true}
                            colorOfActionIcon={''}
                            modeOfActionIcon={'dark'}
                          ></ClearButton>
                        </ClearBtnWrapSpan>
                      </TagsBadgeAnchor>
                    </TagsBadgeSpan>
                  )
                })}
                {this.singleLine ? <span>{TagInput}</span> : TagInput}
                {this.floatingLabel && (
                  <span class='floating-label'>{this.label}</span>
                )}
              </TagsWrapDiv>

              <InputGroupTextSpan onClick={this.toggleDropdown}>
                {this.showClearIcon && !this.loading && (
                  <ClearButton
                    shape='pill'
                    size='xs'
                    icon='times'
                    text=''
                    actionIcon={true}
                    colorOfActionIcon={''}
                    modeOfActionIcon={'dark'}
                    color='light'
                    onClick={this.clearInput}
                    hover
                  ></ClearButton>
                )}
                {!this.showClearIcon && !this.loading && (
                  <VisibleIcon
                    class={this.showSuggestions ? 'up' : ''}
                    icon='chevron-down'
                    prefixClass='chevron-down'
                    disabled={this.selectDisabled}
                    hover
                  ></VisibleIcon>
                )}
                {this.loading && <SmallSpinner role='status'></SmallSpinner>}
              </InputGroupTextSpan>
            </TagsInput>
          )}

          {this.mode === 'dropdown' && (
            <InputGroupDiv
              ref='select'
              identity='InputGroup'
              inputFocused={this.inputFocused}
              disabled={this.selectDisabled}
              onmouseenter={(e) => this.onInputEvent('mouseenter', e)}
              onmouseleave={(e) => this.onInputEvent('mouseleave', e)}
              mode={this.backgroundMode}
              selectRadius={this.selectRadius}
            >
              <FormControlInput
                onClick={this.toggleDropdown}
                onFocus={(e) => this.onInputEvent('focus', e)}
                onBlur={(e) => this.onInputEvent('blur', e)}
                value={this.selectedLabel}
                class={this.selected.length > 0 ? 'valid' : ''}
                type='text'
                placeholder={this.placeholder ? this.placeholder : ''}
                readonly='readonly'
                autocomplete='off'
                disabled={this.selectDisabled}
              ></FormControlInput>
              {this.labelType === 'floating' && (
                <CvtFieldLabel
                    mode={this.mode}
                    label={this.label}
                />
              )}
              <InputGroupTextSpan onClick={this.toggleDropdown}>
                {this.showClearIcon && !this.loading && (
                  <ClearButton
                    shape='pill'
                    size='xs'
                    icon='times'
                    text=''
                    color='light'
                    actionIcon={true}
                    colorOfActionIcon={''}
                    modeOfActionIcon={'dark'}
                    onClick={this.clearInput}
                    hover
                  ></ClearButton>
                )}

                {!this.showClearIcon && !this.loading && (
                  <VisibleIcon
                    class={this.showSuggestions ? 'up' : ''}
                    icon='chevron-down'
                    prefixClass='chevron-down'
                    disabled={this.selectDisabled}
                    hover
                  ></VisibleIcon>
                )}
                {this.loading && <SmallSpinner role='status'></SmallSpinner>}
              </InputGroupTextSpan>
            </InputGroupDiv>
          )}
          {this.showSuggestions && this.open &&(
              <DropdownMenu
                dropUp={false}
                loading={this.loading}
                filtered={this.filtered}
                selected={this.selected}
                onoptionchange={(option) => this.onChange(option)}
                top={this.getMenuTopPos()}
                bottom={this.getMenuBottomPos()}
                dropdownMenuStyles={this.dropdownMenuStyles}
              ></DropdownMenu>
          )}
        </Dropdown>
        {this.helpText && <small>{this.helpText}</small>}
      </FormGroupDiv>
    )
  }
}
