<template>
  <div>
    <slot name="nav">
      <main-nav />
    </slot>

    <slot></slot>
  </div>
</template>

<script>
import * as getters from "@/js/store/modules/getters";
import { mapGetters } from "vuex";

export default {
  name: "GenAILayout",
  props: {},
  computed: {
    ...mapGetters({
      currentProductVersionNumber: getters.CURRENT_PRODUCT_VERSION_NUMBER,
    })
  },
  created() {
    if (this.currentProductVersionNumber < 4.0) {
      this.$router.push({ name: "choose-gen-ai-type" });
    }
  }
};
</script>

<style scoped>

</style>