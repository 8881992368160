// @ts-nocheck

export default {
    name: 'BuilderVisualBox',
    components: {
    },
    props: {
        // usually passed from router
        gridVNodeId: {
            type: String,
            required: true,
        },
        boxColor:{
            type: String,
            required: true,
            default: () => "#e9e9e98c"
        },
        gridColumn: {
            type: Number,
            required: true,
        },
        gridColumnHeight: {
            type: Number,
            required: true,
        }
    },

    data() {
        return {
            currentWidth: 1087,
            patterRectWidthDifference: 2.0039,
            patterRectHeightDifference: 1,
            resizeObserver: null
        }
    },
    mounted() {
        this.registerEvents()
    },
    computed: {
        patternWidth() {
            return this.currentWidth / this.gridColumn
        },
        patternHeight() {
            return this.gridColumnHeight
        }
    },
    methods: {
        registerEvents(){
            let _gridStackContainer = document.getElementById(this.gridVNodeId)
            if(_gridStackContainer) {
                this.resizeObserver = new ResizeObserver(entries =>
                    this.reCalculateVisualBox(entries[0].target)
                )
                this.resizeObserver.observe(_gridStackContainer)
            }
        },
        reCalculateVisualBox (target) {
            this.currentWidth =  target.clientWidth && target.clientWidth > 0 ? target.clientWidth : 1057
        }
    },
}
