import firebase from 'firebase/app'
import 'firebase/firebase-auth'
import 'firebase/firebase-database'
import 'firebase/firebase-firestore'
import 'firebase/firebase-storage'
import Vue from 'vue'
import VueFire from 'vuefire'

Vue.use(VueFire)

// @ts-ignore
// eslint-disable-next-line no-undef
const app = firebase.initializeApp(__FIREBASE__)

try {
  app.auth().signOut()
} catch (err) {
  console.debug(
    'Attempted to sign the user out so they could be re-initialized',
    err,
  )
}

export const database = firebase.database
export const storage = firebase.storage
export const relativePath = (snapshot) =>
  snapshot.ref.toString().substring(snapshot.ref.root.toString().length - 1)

export default app
