<template>
  <div
    :id="vnodeId"
    :data-vnode-id="vnodeId"
    class="cvt-editor-form"
    :class="classes"
    :style="styles"
    draggable="true"
    @click="select"
    @mouseover="target"
    @contextmenu.prevent.stop="showContextMenu"
    @dblclick.stop
    @dragstart="dragstart"
    @dragend="dragend"
  >
    <img
      v-show="isEmpty"
      class="img-placeholder"
      src="/img/form-placeholder.svg"
    />

    <a
      v-show="!isEmpty && facebook"
      href="/api/auth/opt-in/facebook"
      class="btn btn-rounded btn-lg btn-block sso-facebook"
    >
      <i class="fa fa-lg fa-facebook-square mr-2" />Autofill with Facebook
    </a>
    <a
      v-show="!isEmpty && linkedin"
      href="/api/auth/opt-in/linkedin"
      class="btn btn-rounded btn-lg btn-block sso-linkedin"
    >
      <i class="fa fa-lg fa-linkedin-square mr-2" />Autofill with LinkedIn
    </a>

    <form
      v-show="!isEmpty"
      :id="id"
      class="form"
      :class="formClasses"
      @submit.prevent.stop
    >
      <p v-show="!fields.length">Click here to add Form Field</p>
      <form-control
        v-for="(f, idx) in fields"
        :key="f.id"
        :index="idx"
        :field="f"
        :data-global-tag-ids="tagData"
        :org-field-defaults-enabled="orgFieldDefaultsEnabled"
      />

      <div v-if="config.LEGAL_SYSTEM_ENABLED" class="row">
        <div class="col">
          <div class="form-check mb-2 mr-sm-2">
            <label class="form-check-label">
              <input
                id="policyAttestation"
                class="form-check-input mr-3"
                type="checkbox"
                name="meta[policyAttestation]"
                required="required"
              />
              <span
                >I attest that I have read and understand the policies listed
                and that my personal information will be collected and stored by
                this website.</span
              >
            </label>
          </div>
        </div>
      </div>

      <div v-if="config.LEGAL_SYSTEM_ENABLED" class="row mb-2 mr-sm-2">
        <div class="col text-center">
          <a href="#" target="_blank">Terms of Service</a>
        </div>
        <div class="col text-center">
          <a href="#" target="_blank">Privacy Policy</a>
        </div>
        <div class="col text-center">
          <a href="#" target="_blank">Cookie Policy</a>
        </div>
      </div>

      <slot />
    </form>

    <drag-and-drop-zone :z-index="dropzoneZIndex" @dropped="addElementToSide" />
    <template v-if="showActionButtonsOnClick && !isGridBuilder">
      <!--THis is only here because of freshwork in v1 gridbuilder,
      once they migrate to v2 or v3, it will become irrelevant-->
      <action-buttons
        v-if="selected"
        @duplicate="duplicateAndValidate"
        @remove="removeAndValidate"
      />
    </template>
  </div>
</template>

<script>
import { database } from '@/js/store/services/firebase.service'
import * as _ from 'lodash'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import * as mutations from '../../../store/modules/mutations'
import FbVNode from '../../base/FbVNode.vue'
import FormControl from './CFormControl.vue'

export default FbVNode.extend({
  components: {
    FormControl,
  },
  props: {
    id: String,
    formName: String,
    folderId: String,
    listId: String,
    optInMethod: String,
    doubleOptInEmailTemplateID: String,
    squared: Boolean,
    callToAction: String,
    width: Number,
    design: String,
    inline: Boolean,
    redirectUrl: String,
    redirectChoice: String,
    doubleOptInRedirectUrl: String,
    doubleOptInRedirectChoice: String,
    doubleOptInPageId: String,
    facebook: Boolean,
    linkedin: Boolean,
    updateContact: Boolean,
    disableUrlParams: Boolean,
    pageId: String,
    contactSource: {
      type: Number,
      required: false,
      default: 0,
    },
    leadRoute: {
      type: Number,
      required: false,
      default: 0,
    },
    tags: {
      type: Array,
      default() {
        return []
      },
    },
    fields: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      name: 'CForm',
      emptyStateClass: 'cvt-editor-form--empty',
    }
  },
  computed: {
    ...mapGetters({
      site: getters.SITE_GET_DATA,
      config: getters.AUTH_GET_USER_CONFIG,
      user: getters.AUTH_GET_USER,
      thankYouPage: getters.SITE_GET_THANKYOU,
      formFieldGroups: getters.FORM_EDITOR_FIELD_GROUPS,
      blockIsAdded: getters.GET_BLOCK_ADD_STATUS,
    }),
    isEmpty() {
      return !this.fields ? true : this.fields.length === 0
    },
    localClasses() {
      return {
        [this.stylesheet.classes.customForm]: true,
      }
    },
    formClasses() {
      return {
        'form-input-squared': this.squared,
        'form-inline': this.inline,
        [this.design]: this.design,
      }
    },
    styles() {
      return {
        customForm: {
          width: `${this.width}%`,
          ...this.spacingStyle,
        },
      }
    },
    tagData() {
      return JSON.stringify(this.tags)
    },
    computedFields() {
      return this.fields.map((field) => _.clone(field))
    },
  },
  watch: {
    fields(newVal) {
      this.resizeContent()
    },
  },
  methods: {
    ...mapMutations({
      advancedMenuSetActiveTabs: mutations.ADVANCED_MENU_SET_ACTIVE_TABS,
      addBlock: mutations.SET_BLOCK_ADDED,
    }),
    ...mapActions({
      regenerateSiteForms: actions.SITE_REGENERATE_FORMS,
      fetchFormFieldGroups: actions.FORM_EDITOR_LOAD_FIELDS,
    }),
    async validationErrors() {
      const errors = []

      const redirectOptionsRef = database()
        .ref(this.site.firebaseRef)
        .child('redirect')
      const oldRedirectOptions = await redirectOptionsRef
        .once('value')
        .then((snap) => snap.val())
      const oldChoice = _.get(oldRedirectOptions, 'choice')
      const oldURL = _.get(oldRedirectOptions, 'url')

      if (oldChoice === 'thankyou') {
        await this.updateProp('redirectChoice', 'page')
        await this.updateProp('pageId', this.thankYouPage.id)
        await redirectOptionsRef.remove()
      }

      if (oldChoice === 'url' && oldURL) {
        await this.updateProp('redirectChoice', 'url')
        await this.updateProp('redirectUrl', oldURL)
        await redirectOptionsRef.remove()
      }

      if (this.user.orgID === 'sendinblue' && !this.folderId) {
        errors.push('You must select a contact folder.')
      }

      if (this.config.FORM_FIELD_LIST_REQUIRED && !this.listId) {
        errors.push(`${this.$t('elements.property.form.field.list.error.message')}`)
      }
      const emailIsRequired = this.config.REQUIRED_FORM_FIELDS.includes('email')
      const emailField =
        _.find(this.fields, { id: 'email' }) ||
        _.find(this.fields, {
          type: 'email',
        })

      const smsField = _.find(
        this.fields,
        (f) => f.id === 'SMS' || f.id === 'mobile_phone',
      )

      const smsIsRequired = this.config.REQUIRED_FORM_FIELDS.includes('sms')

      if (!emailField && emailIsRequired && !smsIsRequired) {
        errors.push('Your form must contain an email field')
      }

      if (!emailField && emailIsRequired && !smsField && smsIsRequired) {
        errors.push('Your form must contain at least an email or SMS field')
      }

      this.config.REQUIRED_FORM_FIELDS.filter(
        (x) => !['email', 'sms'].includes(x),
      ).map((rf) => {
        const field = _.find(this.fields, (f) => f.id === rf || f.id === rf)
        if (!field) {
          errors.push(`Your form must contain ${rf} field`)
        }
      })

      if (!this.redirectChoice) {
        errors.push(
          'Please select a redirect option for your form before you continue.',
        )
      }

      if (this.redirectChoice === 'page' && !this.pageId) {
        errors.push(
          'Your form redirect option is set to "page" but you have not selected a page.',
        )
      }

      if (this.redirectChoice === 'url' && !this.redirectUrl) {
        errors.push(
          'Your form redirect option is set to "url" but you have no set a "url"',
        )
      }

      if (errors.length) {
        this.selectSelfForHelp()
        this.advancedMenuSetActiveTabs(['redirectOptions'])
      }

      return errors
    },
    async pullOrgSpecificRequiredFields() {
      if (!this.isEmpty && !this.blockIsAdded) return
      await this.fetchFormFieldGroups()
      const requiredFields = this.formFieldGroups
        .map((g) => g.fields)
        .flat()
        .filter((f) => f.required)
      this.updateProp('fields', requiredFields)
    },
    orgFieldDefaultsEnabled() {
      /* This flag is passed as a boolean, however at the time of this writing no configuration option
      other than a boolean flag is needed so this flag simply allows for setting a generic default
      on a field, likely the best approach to added configuration ability would be to have the tenant
      send an object through the cookie. */
      return this.config.FIELD_DEFAULTS_ENABLED === true
        ? this.config.FIELD_DEFAULTS_ENABLED
        : false
    },
    checkAndSetPageId() {
      if (this.pageId === '') {
        if (this.site.pages.length > 2) {
          const thankYou = _.find(this.site.pages, (page) => {
            return page.name.includes('thank you')
          })

          if (thankYou) {
            this.updateProp('pageId', thankYou.id)
          } else {
            console.debug('Cannot link automatically to the thank you page.')
          }
        } else if (this.site.pages.length === 2) {
          console.debug('Able to connect thank you page.')
          this.updateProp('pageId', this.thankYouPage.id)
        }
      }
    },
    resizeContent() {
      if (this.isGridBuilder) {
        this.resize(true)
      }
    },
  },
  mounted() {
    this.$nextTick(async () => {
      await this.pullOrgSpecificRequiredFields()
      this.checkAndSetPageId()

      if (this.config.FORM_AUTO_SYNC) {
        this.regenerateSiteForms(this.site)
      }
      this.resizeContent()
      this.addBlock(false)
    })
  },
})
</script>

<style lang="scss">
@import '@/scss/variables';
.cvt-editor-form {
  position: relative;

  .img-placeholder {
    max-width: 100%;
    max-height: 100%;
  }
}

.cvt-editor-form * {
  cursor: pointer;
}

.cvt-editor-form--empty {
  display: flex;
  justify-content: center;
  align-items: middle;
}

// used to hide the honeypot email field
// from bots and spammers
.hp-field {
  position: absolute;
  left: -999em;
}

.form,
.form-inline {
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  input[type='url'],
  input[type='date'],
  select.form-control:not([size]):not([multiple]) {
    appearance: none;
    border: 1px solid #ececec;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    font-weight: 500;
    background: rgba(255, 255, 255, 1);
  }
  textarea {
    appearance: none;
    border: 1px solid #ececec;
    width: 100%;
    padding-left: 20px;
    font-weight: 500;
    background: rgba(255, 255, 255, 1);
  }
  select.form-control:not([size]):not([multiple]) {
    overflow: hidden;
    background: #fff url('https://cdn.convrrt.com/assets/img/dropdown.png')
      no-repeat 95% 50%;
  }
}

.form-inline {
  div {
    margin-left: 5px;
  }
}

.form-inline {
  display: flex;
  justify-content: center;

  .c-button.btn-block {
    flex-basis: 100%;
  }
}

.form-input-squared {
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  input[type='url'],
  input[type='date'],
  textarea,
  select.form-control {
    border-radius: 0;
  }
}

.form-input-rounded {
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  input[type='url'],
  input[type='date'],
  select.form-control {
    border-radius: 0.25rem;
  }
}
.form-input-circle {
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  input[type='url'],
  input[type='date'],
  textarea,
  select.form-control:not([size]):not([multiple]) {
    border-radius: 50rem;
  }
  select.form-control[type='multiple'] {
    border-radius: 12px;
  }
}

.form-input-outline {
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  input[type='url'],
  input[type='date'],
  textarea,
  select.form-control {
    border: 1px solid rgba(255, 255, 255, 1);
  }
  select.form-control:not([size]):not([multiple]) {
    overflow: hidden;
    background: #fff url('https://cdn.convrrt.com/assets/img/dropdown.png')
      no-repeat 95% 50%;
  }
}

.form-input-underline {
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  input[type='url'],
  input[type='date'],
  textarea,
  select.form-control:not([size]):not([multiple]) {
    border-top: 0px solid rgba(255, 255, 255, 0);
    border-right: 0px solid rgba(255, 255, 255, 0);
    border-bottom: 2px solid rgba(130, 130, 130, 1);
    border-left: 0px solid rgba(255, 255, 255, 0);
    background: rgba(0, 0, 0, 0);
  }
  select.form-control:not([size]):not([multiple]) {
    overflow: hidden;
    background: rgba(0, 0, 0, 0)
      url('https://cdn.convrrt.com/assets/img/dropdown.png') no-repeat 95% 50%;
  }
}

.form-input-hasbg {
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  input[type='url'],
  input[type='date'],
  textarea,
  select.form-control {
    background: rgba(0, 0, 0, 0.1);
  }
  select.form-control:not([size]):not([multiple]) {
    overflow: hidden;
    background: rgba(0, 0, 0, 0.1)
      url('https://cdn.convrrt.com/assets/img/dropdown.png') no-repeat 95% 50%;
  }
}

.form-input-nobg {
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  input[type='url'],
  input[type='date'],
  textarea,
  select.form-control {
    background: transparent;
  }

  select.form-control:not([size]):not([multiple]) {
    overflow: hidden;
    background: transparent
      url('https://cdn.convrrt.com/assets/img/dropdown.png') no-repeat 95% 50%;
  }
}

.form-inline {
  .form-group {
    margin-bottom: 0;
  }
}

.form-material {
  @extend .form-input-squared;
  @extend .form-input-underline;
}

.form-simple {
  @extend .form-input-squared;
  @extend .form-input-underline;
  @extend .form-input-hasbg;
}

.form-creative {
  @extend .form-input-circle;
  @extend .form-input-outline;
  @extend .form-input-hasbg;
}

.form-minimalist {
  @extend .form-input-outline;
  @extend .form-input-nobg;
}

.btn.sso-facebook {
  background-color: $facebook;
  font-size: 20px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  margin-bottom: 0.5rem;
}

.btn.sso-linkedin {
  background-color: $linkedin;
  font-size: 20px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  margin-bottom: 0.5rem;
}
</style>
