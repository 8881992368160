<template>
  <i :class="selector"></i>
</template>

<script lang="ts">
export default {
  name: 'ThemifyIcon',
  props: {
    selector: {
      required: true,
      type: String,
    },
  },
}
</script>
