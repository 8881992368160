
<div class="branding-container">
  <div class="branding-heading-wrapper">
    <h1>Branding</h1>
    <div>
      <!-- <cvt-button
        text="Save Changes"
        color="light"
        :disabled="disableBtn"
        hover
      /> -->
    </div>
  </div>
  <div class="branding-main-wrapper">
    <cvt-ent-dashboard-tabs
      :active-tab="activeTabIdx"
      :tabs-array="tabs"
      @tabClicked="(idx) => onTabClicked(idx)"
    >
      <div v-if="activeTabIdx === 0" class="branding-tab-container">
        <cvt-collapse
          :accordion="true"
          :prepend-chevron="false"
          theme="light"
        >
          <cvt-collapse-item
            v-for="(configKey, idx) in Object.keys(configs.THEME)"
            :key="idx"
            :special-style="true"
            :title="capitalizeFirstLetter(configKey)"
            :name="idx"
            special-font-family="Nunito"
          >
            <div
              v-if="configs.THEME[configKey].type === 'img'"
              class="admin-branding-collapse-wrapper"
            >
              <cvt-logo-selector
                :label="configs.THEME[configKey].label"
                :brand-logos="brandLogos.THEME"
                @setCurrentLogo="
                  (logo, logoIndex) =>
                    onSetThemeCurrentLogo('THEME', configKey, logo)
                "
                @selectNewLogo="(image) => onCompleteUpload('THEME', image)"
              />
            </div>
            <div
              v-else-if="configs.THEME[configKey].type === 'color'"
              class="py-3"
            >
              <cvt-color-selector
                :label="configs.THEME[configKey].label"
                :brand-colors="brandColors.THEME"
                @setCurrentColor="
                  (colorIndex) =>
                    onSetThemeCurrentColor('THEME', configKey, colorIndex)
                "
                @selectNewColor="(value) => onSelectNewColor('THEME', value)"
              />
            </div>
            <div
              v-else-if="configs.THEME[configKey].type === 'font'"
              class="admin-branding-collapse-wrapper"
            >
              <cvt-font-selector
                :label="configs.THEME[configKey].label"
                :brand-fonts="brandFonts.THEME"
                @setCurrentFont="
                  (fontIndex) =>
                    onSetThemeCurrentFont('THEME', configKey, fontIndex)
                "
                @selectNewFont="(value) => onSelectNewFont('THEME', value)"
              />
            </div>
          </cvt-collapse-item>
        </cvt-collapse>
      </div>

      <div v-else-if="activeTabIdx === 1" class="branding-tab-container">
        <cvt-collapse
          :accordion="true"
          :prepend-chevron="false"
          theme="light"
        >
          <cvt-collapse-item
            v-for="(configKey, idx) in Object.keys(configs.TOP_NAV_CONFIG)"
            :key="idx"
            :special-style="true"
            :title="capitalizeFirstLetter(configKey)"
            :name="idx"
            special-font-family="Nunito"
          >
            <div class="admin-branding-config-container">
              <div
                v-for="(config, id) in Object.keys(
                  configs.TOP_NAV_CONFIG[configKey],
                )"
                :key="id"
              >
                <div
                  v-if="
                    configs.TOP_NAV_CONFIG[configKey][config].type ===
                    'switch'
                  "
                  class="d-flex justify-content-between align-items-center admin-branding-config-wrapper"
                >
                  <p class="m-0">
                    {{ configs.TOP_NAV_CONFIG[configKey][config].label }}
                  </p>
                  <cvt-switch
                    size="sm"
                    color="active"
                    type="circular"
                    description-type="default"
                    :initial-state="
                      configs.TOP_NAV_CONFIG[configKey][config].value
                    "
                    mode="LIGHT"
                    @switchToggle="
                      () =>
                        onSwitchToggle('TOP_NAV_CONFIG', configKey, config)
                    "
                  />
                </div>

                <div
                  v-else-if="
                    configs.TOP_NAV_CONFIG[configKey][config].type === 'text'
                  "
                  class="admin-branding-config-wrapper"
                >
                  <cvt-input
                    :label="configs.TOP_NAV_CONFIG[configKey][config].label"
                    :clearable="false"
                    :value="
                      $t(configs.TOP_NAV_CONFIG[configKey][config].value)
                    "
                    @input="
                      (e) =>
                        updateInputValue(
                          'TOP_NAV_CONFIG',
                          configKey,
                          config,
                          e.target.value,
                        )
                    "
                  ></cvt-input>
                </div>

                <div
                  v-else-if="
                    configs.TOP_NAV_CONFIG[configKey][config].type === 'radio'
                  "
                >
                  <div class="py-3">
                    <p>
                      {{ configs.TOP_NAV_CONFIG[configKey][config].label }}
                    </p>
                    <cvt-radio-group
                      orientation="horizontal"
                      initial-value="dark"
                    >
                      <cvt-radio-button
                        v-for="(option, key) in configs.TOP_NAV_CONFIG[
                          configKey
                        ][config].options"
                        :key="key"
                        horizontal
                        size="md"
                        :value="option"
                        btn-width="150px"
                        :in-active-switch-color="'#494C53'"
                        @radioClick="
                          (val) =>
                            setRadioBtnValue(
                              'TOP_NAV_CONFIG',
                              configKey,
                              config,
                              val,
                            )
                        "
                      >
                        {{ capitalizeFirstLetter(option) }}
                      </cvt-radio-button>
                    </cvt-radio-group>
                  </div>
                </div>

                <div
                  v-else-if="
                    configs.TOP_NAV_CONFIG[configKey][config].type === 'color'
                  "
                  class="py-3"
                >
                  <cvt-color-selector
                    :label="configs.TOP_NAV_CONFIG[configKey][config].label"
                    :brand-colors="brandColors['TOP_NAV_CONFIG']"
                    @setCurrentColor="
                      (colorIndex) =>
                        onSetCurrentColor(
                          'TOP_NAV_CONFIG',
                          configKey,
                          config,
                          colorIndex,
                        )
                    "
                    @selectNewColor="
                      (value) => onSelectNewColor('TOP_NAV_CONFIG', value)
                    "
                  />
                </div>

                <div
                  v-else-if="
                    configs.TOP_NAV_CONFIG[configKey][config].type ===
                    'select'
                  "
                >
                  <cvt-select
                    :label="configs.TOP_NAV_CONFIG[configKey][config].label"
                    :value="configs.TOP_NAV_CONFIG[configKey][config].value"
                    @input="
                      (value) =>
                        updateInputValue(
                          'TOP_NAV_CONFIG',
                          configKey,
                          config,
                          value,
                        )
                    "
                  >
                    <option
                      v-for="(opt, index) in configs.TOP_NAV_CONFIG[
                        configKey
                      ][config].options"
                      :key="index"
                      :value="opt"
                    >
                      {{ opt }}
                    </option>
                  </cvt-select>
                </div>
              </div>
            </div>
          </cvt-collapse-item>
        </cvt-collapse>
      </div>
    </cvt-ent-dashboard-tabs>
    <div class="branding-editor-area-wrapper">
      <div class="branding-editor-area">
        <h1 class="branding-editor-area-text">Select component</h1>
        <div class="branding-editor-select-wrapper">
          <cvt-select
            value="Alert"
            :multiple="true"
            :select-disabled="false"
            :floating-label="false"
            @onInput="(e) => {}"
          >
            <option value="Alert">Alert</option>
          </cvt-select>
        </div>
      </div>
    </div>
  </div>
</div>
