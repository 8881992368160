
<cvt-sections
  class="make-scrollable"
  :sections="filteredBlocks"
  :section-tags="tags"
  :loading="loading"
  :active-tag="activeTag"
  :type="$attrs.type"
  :category="'POPUP'"
  @close-section="() => toggleLeftSideMenu({ open: false })"
  @insert-block="(block) => insertPopupBlock(block)"
  @insert-blank="insertBlank"
  @filter-section="(value) => activeTag = value"
/>
