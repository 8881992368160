<template>
  <div>
    <flex-align
      v-if="
        isGridBuilder &&
        selected.parent().name === 'GridItemWrapper' &&
        !hideFlexAlignment
      "
      :mode="mode"
      :disable-alignment="hideFlexAlignment"
      :disable-constrain="hideFlexAlignment"
    ></flex-align>

    <cvt-collapse
      :accordion="true"
      :theme="mode === 'LIGHT' ? 'light' : 'dark'"
      :prepend-chevron="prependChevron"
    >
      <cvt-collapse-item
        title="Image"
        name="image"
        :initial-active="true"
        :special-style="isSpecialStyle"
      >
        <element-image-manager
          image-key="src"
          :hook="selected.updateSrc"
        ></element-image-manager>

        <element-anchor></element-anchor>

        <div class="row mt-4 mb-4 d-flex justify-content-between">
          <div class="col text-capitalize">
            {{ $t('element.property.label.resize') }}
          </div>
          <div class="col-12">
            <resize hide-label></resize>
          </div>
        </div>

        <div v-if="isGridBuilder" class="divider"></div>

        <div
          v-if="isGridBuilder"
          class="row mt-4 d-flex justify-content-between"
        >
          <div class="col">
            <h6>{{ $t('element.property.label.crop_image') }}</h6>
          </div>
          <div class="col-3">
            <cvt-switch
              size="sm"
              color="active"
              type="circular"
              description-type="default"
              :initial-state="hideImgOverflow"
              :mode="mode"
              @switchToggle="(status) => updateImageCrop(status)"
            ></cvt-switch>
          </div>
        </div>

        <div v-if="!isGridBuilder" class="divider"></div>
      </cvt-collapse-item>

      <cvt-collapse-item
        title="Style"
        name="style"
        :special-style="isSpecialStyle"
      >
        <h6 :class="textColorClass">
          {{ $t('elements.properties.shadow.label') }}
        </h6>
        <cvt-radio-group orientation="horizontal" :initial-value="shadow">
          <cvt-radio-button
            size="md"
            value="shadow-none"
            :shape="RadioButtonShape"
            @radioClick="updateImgShadow"
          >
            {{ $t('elements.properties.option.none') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="shadow"
            :shape="RadioButtonShape"
            @radioClick="updateImgShadow"
          >
            {{ $t('elements.properties.shadow.normal') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="shadow-lg"
            :shape="RadioButtonShape"
            @radioClick="updateImgShadow"
          >
            {{ $t('elements.properties.shadow.deep') }}
          </cvt-radio-button>
        </cvt-radio-group>

        <div class="divider"></div>

        <h6 :class="textColorClass">
          {{ $t('elements.properties.border.radius') }}
        </h6>
        <cvt-radio-group orientation="horizontal" :initial-value="radius">
          <cvt-radio-button
            size="md"
            value="img-radius-none"
            :shape="RadioButtonShape"
            @radioClick="updateImgRadius"
          >
            {{ $t('elements.properties.option.none') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="rounded"
            :shape="RadioButtonShape"
            @radioClick="updateImgRadius"
          >
            {{ $t('elements.properties.shape.rounded') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="rounded-circle"
            :shape="RadioButtonShape"
            @radioClick="updateImgRadius"
          >
            {{ $t('elements.properties.shape.circular') }}
          </cvt-radio-button>
        </cvt-radio-group>
        <div class="divider"></div>

        <flex-align v-if="!isGridBuilder" :mode="mode"></flex-align>

        <div v-if="isGridBuilder" class="divider"></div>

        <!-- <div  v-if="isGridBuilder" class="row">
            <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
              {{ $t("elements.properties.background.color") }}
            </div>
            <div class="col-12">
              <colors alpha :mode="mode"/>
            </div>
          </div>

          <div v-if="isGridBuilder" class="divider mt-2" /> -->

        <div v-if="isGridBuilder" class="nestedInnerItem">
          <cvt-select
            :value="borderStyle"
            mode="dropdown"
            :background-mode="mode"
            :floating-label="false"
            :label="$t('elements.properties.border.style')"
            @input="(value) => updateBorderStyle(value)"
          >
            <option
              v-for="style in borderStyles"
              :key="style"
              :value="style"
              v-text="style"
            />
          </cvt-select>
          <div style="margin: 5px" />
          <cvt-input
            v-model="borderColor"
            style="width: max-content"
            type="text"
            :clearable="false"
            :label="$t('elements.properties.border.color')"
          />
        </div>

        <div v-if="isGridBuilder" class="divider mt-2" />

        <div v-if="isGridBuilder" class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t('elements.properties.border.width') }}
          </div>
          <div class="col-12">
            <cvt-slider
              id="slider-default"
              :tooltips-enabled="true"
              :step-marks-enabled="true"
              class="slider"
              :min="1"
              :max="10"
              :value="borderWidth"
              :step="1"
              @change="(value) => (borderWidth = value)"
            />
          </div>
        </div>
        <div class="divider mt-2" />

        <div v-if="isGridBuilder" class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t('elements.properties.border.radius') }}
          </div>
          <div class="col-12">
            <cvt-slider
              id="slider-default"
              :tooltips-enabled="true"
              :step-marks-enabled="true"
              class="slider"
              :min="0"
              :max="100"
              :value="borderRadius"
              :step="5"
              @change="updateBorderRadius"
            />
          </div>
        </div>
      </cvt-collapse-item>

      <cvt-collapse-item
        :title="$t('elements.properties.spacing.label')"
        name="spacing"
        :special-style="isSpecialStyle"
      >
        <spacing-ctrl></spacing-ctrl>
      </cvt-collapse-item>
    </cvt-collapse>
  </div>
</template>

<script>
import * as _ from 'lodash'
import Resize from './Resize.vue'
import SpacingCtrl from './SpacingCtrl.vue'
import FlexAlign from './FlexAlign.vue'
import ElementImageManager from './ElementImageManager.vue'
// import Colors from '../theme/Colors.vue'
import ElementAnchor from './ElementAnchor.vue'
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import {
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../../storybook/components/constants'
export default {
  components: {
    Resize,
    SpacingCtrl,
    FlexAlign,
    ElementImageManager,
    ElementAnchor,
    // Colors
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    prependChevron: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTabs: [
        'image',
        // 'style',
        // 'padding',
        // 'margin',
        // 'alignment'
      ],
      shadow: '',
      radius: '',
      borderStyles: [
        'none',
        'solid',
        // 'ridge',
        // 'inset',
        // 'outset',
        'dashed',
        'dotted',
        'double',
        'groove',
      ],
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      config: getters.AUTH_GET_USER_CONFIG,
      isGridBuilder: getters.IS_GRID_BUILDER,
    }),
    ...mapState('globalTheme', {
      RadioButtonShape: ({ globalTheme }) =>
        globalTheme.RadioButtonShape !== undefined
          ? globalTheme.RadioButtonShape
          : '',
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
    }),
    hideFlexAlignment() {
      return this.config.HIDE_FLEX_ALIGNMENT_OPTIONS?.image === true
    },
    href: {
      get() {
        return this.selected.href
      },

      set: _.debounce(function (value) {
        return this.updateProp({
          key: 'href',
          value,
        })
      }, 800),
    },
    alt: {
      get() {
        return this.selected.alt
      },

      set: _.debounce(function (value) {
        return this.updateProp({
          key: 'alt',
          value,
        })
      }, 800),
    },
    textColorClass() {
      return { 'text-white': this.mode === 'DARK' }
    },
    isSpecialStyle() {
      return this.OrgName === LEFT_SIDEBAR_FRESHWORKS
    },
    borderStyle: {
      get() {
        return _.get(this.selected.parent(), 'borderStyle', 'none')
      },
      set: _.throttle(function (value) {
        return this.selected.parent().updateProp('borderStyle', value)
      }, 300),
    },
    borderColor: {
      get() {
        return _.get(this.selected.parent(), 'borderColor', null)
      },
      set: _.throttle(function ({ target }) {
        if (this.selected.parent()?.updateProp) {
          return this.selected.parent().updateProp('borderColor', target.value)
        }
      }, 300),
    },
    borderRadius: {
      get() {
        return _.get(this.selected.parent(), 'borderRadius', 0)
      },
      set: _.throttle(function (value) {
        if (this.selected.parent()?.updateProp) {
          return this.selected.parent().updateProp('borderRadius', value)
        }
      }, 300),
    },
    borderWidth: {
      get() {
        return _.get(this.selected.parent(), 'borderWidth', 0)
      },
      set: _.throttle(function (value) {
        if (this.selected.parent()?.updateProp) {
          return this.selected.parent().updateProp('borderWidth', value)
        }
      }, 300),
    },
    hideImgOverflow: {
      get() {
        return this.selected.hideImgOverflow
      },
      set(value) {
        return this.updateProp({ key: 'hideImgOverflow', value })
      },
    },
  },
  created() {
    this.shadow = this.selected.shadow
    this.radius = this.selected.radius
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
    }),
    updateImgShadow(value) {
      this.shadow = value
      this.updateProp({ key: 'shadow', value })
    },
    updateImgRadius(value) {
      this.radius = value
      this.updateProp({ key: 'radius', value })
    },
    updateBorderStyle(value) {
      if (this.selected.parent().name === 'GridItemWrapper') {
        this.borderStyle = value
      }
    },
    updateImageCrop(status) {
      if (status === 'Active') {
        this.hideImgOverflow = true
      } else if (status === 'Inactive') {
        this.hideImgOverflow = false
      }
    },
    updateBorderWidth: _.throttle(function (value) {
      this.borderWidth = value
    }, 300),
    updateBorderRadius: _.throttle(function (value) {
      this.borderRadius = value
    }, 300),
  },
}
</script>

<style lang="scss"></style>
