
<div id="remote-fields" class="remote-fields">
  <div v-if="loading || isBusy" class="placeholder-container">
    <cvt-content-placeholder class="placeholder" />
  </div>
  <cvt-select
    v-else
    id="remote-fields-select"
    :value="selectedField"
    :label="placeholder"
    @input="updateSelected"
    @visible-change="loadFields"
  >
    <optgroup
      v-for="group in filteredFormFieldGroups"
      :key="group.label"
      :label="group.label"
      class="dropdown-options"
    >
      <option
        v-for="field in group.fields"
        :key="field.id"
        :label="field.label"
        :value="field"
        :icon="field.icon"
        class="dropdown-option"
      />
    </optgroup>
  </cvt-select>
</div>
