<template>
  <div>
    <div>
      <div>
        <cvt-admin-left-nav-bar
          style="top: 64px"
          initial-active-id="documentation"
          :mode="mode"
        >
        </cvt-admin-left-nav-bar>
      </div>
      <div class="main-content">
        <iframe class="iframe-content" frameborder=0 src="https://developers.convrrt.com/"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import * as getters from '../../store/modules/getters'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Documentation',
  data() {
    return {
      mode: 'LIGHT',
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN,
      admin: getters.AUTH_GET_USER
    }),

    ...mapState('super', [
      'searching',
      'projects',
      'organizations',
      'currentOrgID',
      'currentProjectID'
    ]),
  },

  methods: {
    ...mapActions('super', [
      'onProjectChange',
      'onOrgChange',
      'searchOrganizations',
      'searchProjects',
      'sudo'
    ]),
  },
}
</script>
<style lang="scss">
@import "@/scss/utils";

.main-content {
  position: absolute; 
  left: 280px; 
  top: 64px; 
  width:80%; 
  height:90%;
}

.iframe-content {
  width:100%; 
  height: 100%;
}

</style>
