import { styled } from '@egoist/vue-emotion'
import { cvtRouteNodes } from "@/js/utils/routes"
import { mapState, mapGetters } from '../../../../builder/node_modules/vuex'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import CvtButton from '../../molecules/Button'
import CvtDialog from '../../molecules/Dialog'


let FontFamily, Colors;

const getFreeTrialContainer = () => styled('div')`
  z-index: 5;
  font-family: ${FontFamily.DEFAULT};
`

const getPricingContainer = () => styled('div')`
  background: #F3F3FA;
  color: #000000;
  padding: 15px;
  margin: 0 15px;
`

const getFreeTrialWrapper = () => styled('div')`
background-color: #5d62ab;
font-family: ${FontFamily.DEFAULT};
display: flex;
gap: 13px;
font-size: 14px;
justify-content: center;
align-items: center;
padding: 15px;
color: white;
`

const getPricingWrap = () => styled('div')`
  display: flex;
  justify-content: center;
`

const getPriceContainer = () => styled('div')`
  background-color: #FFFFFF;
  border-radius: 15px;
  width: 30%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
`

const getFreeTrialCaption = () => styled('span')`
color: #fff;
margin: 0;
`

const getPricingFooterHead = () => styled('p')`
font-size: 28px;
margin-bottom: 30px;
font-weight: 350;
color: #0B233F;
`

const getPricingFooter = () => styled('div')`
  display: flex;
  justify-content:center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 15px;
`

const getPriceCaption = () => styled('div')`
text-align: center;
margin-top: 25px;
font-weight: 300;
`

const getButton = () => styled(CvtButton)`
  ${(props) => props.customPadding?.trim && `padding: ${props.customPadding}!important;`}
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

const getDialogBox = () => styled(CvtDialog)`
  > div > div {
    width: 1200px;

    > div {
      background: #FFFFFF;
    }
  }
`


export const FreeTrialFooter = {
  name: 'FreeTrialFooter',
  data() {
    return {
      showPricing: false,
      expiresIn: 30
    }
  },
  props: {},
  methods: {
    onGoToIntegrateBuilder() {
      this.$router.push({
        name: cvtRouteNodes.enterpriseIntegrateBuilder
      })
    },
    onGoToPricing() {
      this.showPricing = true;
    },
    onClosePricing() {
      this.showPricing = false;
    },

    getFreeTrialExpiryDate () {
      const createdAt = this.orgCreatedAt
      const now = new Date();
      const diff = now.getTime() - new Date(createdAt).getTime();
      const daysLeft = 30 - Math.floor(diff / (1000 * 60 * 60 * 24));
      this.expiresIn = daysLeft;
    },
  },
  computed: {
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Colors: ({ globalTheme }) => globalTheme.Colors,
    }),
    ...mapGetters({
      admin: getters.AUTH_GET_USER,
      config: getters.AUTH_GET_USER_CONFIG,
    }),

    showIntegrateButton(){
      console.log(this.$route.name === cvtRouteNodes.editorLaunch)
      console.log(this.$route.name === cvtRouteNodes.editorDesignPage)
      return (
        (this.$route.name === cvtRouteNodes.editorDesignPage ||
        this.$route.name === cvtRouteNodes.editorLaunch) &&
        !(this.config.CURRENT_STAGE === "enterprise_dashboard")
      )
    },

    orgCreatedAt() {
      return this.admin.organization.createdAt
    },

    trialHasExpired() {
      return this.expiresIn < 0
    }
  },

  created(){
    FontFamily = this.FontFamily
    Colors = this.Colors
  },

  mounted () {
    this.getFreeTrialExpiryDate()
  },

  render: function (h) {
    const FreeTrialContainer = getFreeTrialContainer()
    const FreeTrialWrapper = getFreeTrialWrapper()
    const FreeTrialCaption = getFreeTrialCaption()
    const Button = getButton()
    const DialogBox = getDialogBox()
    const PricingContainer = getPricingContainer()
    const PricingWrap = getPricingWrap()
    const PriceContainer = getPriceContainer()
    const PriceCaption = getPriceCaption()
    const PricingFooter = getPricingFooter()
    const PricingFooterHead = getPricingFooterHead()

    const prices = [
      {
        name: 'Free',
        users: '100',
        capOne: 'Easy landing page builder',
        capTwo: 'Fully customized',
        capThree: 'Feature updates',
        capFour: 'No hidden fees'
      },
      {
        name: 'Platform',
        users: '$0.33/user/mo therafter',
        capOne: 'Easy landing page builder',
        capTwo: 'Fully customized',
        capThree: 'Feature updates',
        capFour: 'No hidden fees'
      },
      {
        name: 'Onboarding',
        users: '$20,000',
        capOne: 'Deep integrations',
        capTwo: 'Template creation',
        capThree: 'Brand + UI customization',
        capFour: 'Documentation and training'
      }
    ]

    return (
    <FreeTrialContainer>
        <FreeTrialWrapper>
            <i style="font-size: 25px;" class="fas fa-exclamation-circle"></i>
            <FreeTrialCaption>Your free trial {this.trialHasExpired ?
             'has Expired' :
             `ends in ${this.expiresIn} days`}
            </FreeTrialCaption>
            <Button
            shape='round'
            color='light'
            text='See Pricing'
            onClick={this.onGoToPricing}
            />
            {this.showIntegrateButton && (<span>OR</span>)}
            {this.showIntegrateButton && (
            <Button
            shape='round'
            color='light'
            text='Integrate Builder on your app'
            onClick={this.onGoToIntegrateBuilder}
            />
            )}
        </FreeTrialWrapper>
        <DialogBox
          show={this.showPricing}
          height-auto={true}
          size={'xl'}
          onClose={this.onClosePricing}
        >
          <PricingContainer>
            <PricingWrap>
              {prices.map((price, idx) => (
                <PriceContainer style={`${idx !== prices.length - 1 && `margin-right: 3.3%;`}`}>
                  <p style="margin: 0;font-size: 18px;">{price.name}</p>
                  <p style="margin: 0;font-weight: 300;">{price.users}</p>
                  <PriceCaption>
                    <p style="margin: 0; margin-bottom: 5px;">{price.capOne}</p>
                    <p style="margin: 0;margin-bottom: 5px;">{price.capTwo}</p>
                    <p style="margin: 0;margin-bottom: 5px;">{price.capThree}</p>
                    <p style="margin: 0;margin-bottom: 5px;">{price.capFour}</p>
                  </PriceCaption>
                </PriceContainer>
              ))}
            </PricingWrap>

            <PricingFooter>
              <PricingFooterHead>See the magic of our custom landing page builder.</PricingFooterHead>
              <div>
                <Button
                  shape='round'
                  customPadding="15px 40px"
                  color='dark'
                  fullWidth={true}
                  text='Let’s Talk'
                  onClick={() => {}}
                />
              </div>
            </PricingFooter>
          </PricingContainer>
        </DialogBox>
    </FreeTrialContainer>
    )
  },
}