export const CvtIcon = {
  name: 'CvtIcon',
  props: {
    icon: {
      type: [String, Object],
      default: ''
    },
    hover: {
      type: Boolean,
      default: false
    },
    animation: {
      type: String,
      default: ''
    },
    prefixClass: {
      type: String,
      default: 'icon'
    }
  },
  computed: {
    stringIcon () {
      return typeof this.icon === 'string' // this determines, whether the icon is of string type i.e from [convrrt, keap, freshworks, fontawesome] or from material-icons if it is object
    },
    iconName () {
      return this.stringIcon ? this.icon.trim().toLowerCase() : (this.icon.name ?? '')
    },
    iconVendor () {
      return this.stringIcon ? '' : (this.icon.vendor ?? '')
    },
    iconAnimation () {
      return this.animation.trim().toLowerCase()
    },
    iconPrefix () {
      return (this.prefixClass || '').trim().toLowerCase()
    },
    iconVendorStyle() {
      return this.stringIcon ? '' : (this.icon.style ?? '') // e.g. outlined, rounded, sharp, filled, duo-tone
    },
    iconStyles() {
      let styles = {}

      if(this.iconVendor === 'material-symbols') {
        styles['fontSize'] = '16px';
      }

      if(this.icon.config && this.icon.config.fill && this.icon.config.weight && this.icon.config.grade && this.icon.config.opticalSize) {
        styles['fontVariationSettings'] = `'FILL' ${this.icon.config.fill}, 'wght' ${this.icon.config.weight}, 'GRAD' ${this.icon.config.grade}, 'opsz' ${this.icon.config.opticalSize}`;
      }

      return styles
    },
    iconClass () {
      if(this.stringIcon) {
        if (this.iconName.indexOf('cvt-') === 0 || this.iconName.indexOf('keap-') === 0 || this.iconName.indexOf('fw-') === 0) {
          return this.iconName
        }

        return `fas fa-${this.iconName}`
      } 
      else {
        if(this.iconVendor === 'material-symbols') {
          return `${this.iconVendor}-${this.iconVendorStyle.trim().toLowerCase()}`
        }
      }
    }
  },
  render: function (h) {
    return (
      <i
        class={[
          this.iconPrefix,
          this.iconClass,
          this.iconAnimation,
          this.hover ? 'hover' : ''
        ]}
        style={this.iconStyles}
        role='presentation'
      >
        {this.iconVendor === 'material-symbols' ? this.iconName : ''}
      </i>
    )
  }
}
