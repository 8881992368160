import 'intersection-observer'
import * as _ from 'lodash'
import anime from 'animejs'
import { database } from '@/js/store/services/firebase.service'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {
  assignIdsRecursive,
  Block,
  GridContainer,
  Row,
} from '@/js/lib/helper-classes'
import * as getters from '../../../../store/modules/getters'
import * as actions from '../../../../store/modules/actions'
import * as mutations from '../../../../store/modules/mutations'
import { MARKETPLACE_PATH } from '../../../../lib/helper-classes'
import { STANDARD, USER_OWNED } from "../../../../common/constants/constants";

let db = database()

export default {
  data() {
    return {
      activeTag: 'ALL',
      excludeTags: ['popup'],
      query: null,
      blocks: [],
      loading: true,
      tags: [],
      allTagsWithCount: { },
      userViewPreference: 'Standard',
      deletingBookmark: false,
      selectedBookmark: null,
      deleteBookmarkVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      user: getters.AUTH_GET_USER,
      isGridBuilder: getters.IS_GRID_BUILDER,
      selected: getters.SWAP_BLOCK_GET_CURRENT_BLOCK,
      sideBarContext: getters.LEFT_SIDE_BAR_CONTEXT,
      config: getters.AUTH_GET_USER_CONFIG
    }),
    showAddSectionAction () {
      if (this.selected?.isHeaderBlock || this.selected?.isFooterBlock) {
        return false
      }
      return true
    },
    showAddBlankSectionAction() {
      if (this.selected?.isHeaderBlock || this.selected?.isFooterBlock) {
        return false
      }
      if (this.sideBarContext?.isHeaderBlock || this.sideBarContext?.isFooterBlock) {
        return false
      }
      return true
    },
    filteredBlocks: function () {
      if (this.userViewPreference === STANDARD) {
        const standardBlocks = _.filter(this.blocks, (b) => {
          b.tags = b.tags || [];
          let blockTags = b.tags;
          let isTagFound = false;
          let isCurrentBuilder = _.get(b, 'isGridBuilder', false) === this.isGridBuilder;

          if(this.activeTag === 'ALL'){

            if(blockTags.length > 0) {
              isTagFound = blockTags.some(blockTag => {
                return !!blockTag.length && !this.excludeTags.includes(blockTag.toLowerCase()) && !blockTag.includes(USER_OWNED);
              })
            } else if (blockTags.length === 0) {
              // if tags array is empty, we want to include that block because activeTag === 'ALL'
              isTagFound = true
            }

            return isCurrentBuilder && isTagFound
          }
          else if(this.activeTag && this.activeTag !== 'ALL'){
            isTagFound = blockTags.some(blockTag => {
              return !!blockTag.length && blockTag.toUpperCase() === this.activeTag.toUpperCase();
            })

            return isCurrentBuilder && isTagFound
          }
          else{
            return false
          }
        })
        return standardBlocks
      } else {
        const bookmarkBlocks = _.filter(this.blocks, (b) => {
          let blockTags = b.tags || [];
          let isCurrentBuilder = _.get(b, 'isGridBuilder', false) === this.isGridBuilder;
          return isCurrentBuilder &&  blockTags.includes(USER_OWNED)
        })
        return bookmarkBlocks
      }
    },
  },

  created() {
    this.loadFirebaseData()
  },
  mounted() {
    if (this.selected?.isHeaderBlock || this.sideBarContext?.isHeaderBlock) {
      this.activeTag = 'header'
    }
    if (this.selected?.isFooterBlock || this.sideBarContext?.isFooterBlock) {
      this.activeTag = 'footer'
    }
    if (this.config.GRID_BUILDER_HEADER_FOOTER_ENABLED){
      this.excludeTags = [...this.excludeTags, 'header', 'footer']
      console.log({excludeTags: this.excludeTags})
    }
  },
  destroyed() {
    this.swapBlockSetCurrentBlockData(null)
  },

  methods: {
    ...mapActions({
      quickLaunchBlockInsert: actions.QUICK_LAUNCH_BLOCK_INSERT,
      toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
      addBlockColors: actions.THEME_ADD_COLORS_FROM_BLOCK,
      swapBlockSetCurrentBlockData: actions.SWAP_BLOCK_SET_CURRENT_BLOCK_DATA,
      swapWithCurrentBlock: actions.SWAP_BLOCK,
    }),
    ...mapMutations({
      addBlock: mutations.SET_BLOCK_ADDED
    }),
    async loadFirebaseData() {
      const { orgID, projectID } = this.user

      const paths = [
        { path: `${MARKETPLACE_PATH}/${orgID}`, child: 'blocks' },
        { path: `${MARKETPLACE_PATH}/${orgID}/${orgID}`, child: `blocks`},
        {path: MARKETPLACE_PATH, child: `globalBlocks`}, // add global blocks to list
        //USER BLOCKS
        { path: `${MARKETPLACE_PATH}/${orgID}/${projectID}`, child: `blocks`},
      ]

      for (const item of paths) {
        // const pathIndex = paths.indexOf(item);
        await db.ref(item.path).child(item.child).once('value', (snap) => {
          const path = `${item.path}/${item.child}`
          this.saveBlockLoadAction(snap, path)
        });
      }
    },
    saveBlockLoadAction(snap, path) {

      let localTags = { };
      let localTagsKeys = [];

      let blocks = snap.val()

      for (let key in blocks) {
        let block = blocks[key]

        this.blocks.push({
          ...block,
          '.key': key,
          fbPath: `${path}/${key}`
        })

        // this logic is for calculting category-names along with their tag-counts
        if (block.tags && !block.tags.includes(USER_OWNED)) {
          for(let tagKey in block.tags) {

            let tag = block.tags[tagKey]

            let lowerCaseTagName = String(tag).toLowerCase();
            let upperCaseTagName = String(tag).toUpperCase();

            if (
              !this.excludeTags.includes(lowerCaseTagName) &&
              this.isGridBuilder === _.get(block, 'isGridBuilder', false) // this makes sure that non-grid-builder sections count does not get included in grid-builder sections count
            ) {

              // Initially, if tag count is zero, make it one
              if(!this.allTagsWithCount[upperCaseTagName]) {
                this.allTagsWithCount[upperCaseTagName] = 1
              } else {
                this.allTagsWithCount[upperCaseTagName] += 1
              }
            }
          }
        }
      }

      for (let tagKey in this.allTagsWithCount) {
        let count = this.allTagsWithCount[tagKey]

        if(count > 0) {
          localTags[tagKey] = count
        }
      }

      localTagsKeys = Object.keys(localTags)

      this.tags = this.tags.concat(localTagsKeys)
      this.tags = _.uniq(this.tags)
      this.tags = this.tags.sort()

      this.loading = false
    },
    insertBlank() {
      let block = new Block()
      if (this.isGridBuilder) {
        block.children.push(new GridContainer())
      } else {
        block.children.push(new Row())
      }
      this.insertBlock({ vnode: block })
    },
    insertBlock({ colors, vnode }) {
      this.addBlock(true)
      this.addBlockColors(colors)
      this.quickLaunchBlockInsert(assignIdsRecursive(vnode))
      this.$nextTick((_) => {
        let node = document.getElementById(vnode.data.props.vnodeId)
        this.scrollTo(node)
        this.shakeNode(node)
        this.toggleLeftSideMenu({ open: false })
      })
    },
    async onDeleteBookmark() {
      this.deletingBookmark = true
      await db.ref(this.selectedBookmark.fbPath).remove()
      this.deletingBookmark = false
      this.$message({
        type: 'success',
        message: 'section deleted successfully'
      })
      this.deleteBookmarkVisible = false
      this.toggleLeftSideMenu({ open: false })
    },
    async editBookmarkName(block, replaceText) {
      await db.ref(block.fbPath).update({
        name: replaceText
      })
      block.name = replaceText
    },
    showDeleteBookmarkDialog(data) {
      this.selectedBookmark = data
      this.deleteBookmarkVisible = true
    },
    swapBlock({ colors, vnode }) {
      try {
        this.addBlock(true)
        this.addBlockColors(colors)
        this.swapWithCurrentBlock(assignIdsRecursive(vnode))

        this.$nextTick((_) => {
          let node = document.getElementById(vnode.data.props.vnodeId)
          this.scrollTo(node)
          this.shakeNode(node)
          this.toggleLeftSideMenu({ open: false })
          this.$message({
            type: 'success',
            message: this.$t('blockmanager.swap_block.success.message'),
          })
        })
      } catch (e) {
        this.$message({
          type: 'error',
          message: this.$t('blockmanager.swap_block.error.message'),
        })
        console.debug('SwapBlock Error', e)
      }
    },
    scrollTo(e) {
      if (e !== null) {
        if (_.isFunction(e.scrollIntoViewIfNeeded)) {
          e.scrollIntoViewIfNeeded(true)
        } else {
          e.scrollIntoView({ behavior: 'smooth' })
        }
      }
    },
    shakeNode(targets, delay = 0) {
      let animation = anime({
        targets,
        duration: 500,
        delay,
        translateY: [0, 50, -50, 0],
        easing: 'easeInOutQuart',
      })

      return animation.finished.then((_) => {
        if (targets && targets.style) {
          targets.style.transform = null
        }
      })
    },
  },
}
