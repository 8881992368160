<template>
  <section class="card">
    <slot></slot>
  </section>
</template>

<script>
  export default {
    name: 'CvtCard',
  }
</script>

<style scoped>
</style>
