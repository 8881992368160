<template>
  <cvt-dialog
      v-if="!AltPopupManager"
      size="sm"
      bg-color="#fff"
      :show="show"
      :show-close="false"
      :height-auto="true"
      :show-header="false"
      :show-footer="false"
  >
    <div class="row mb-4 mt-4">
      <div class="col-md-10 text-center mx-auto">
        <h3 class="setup-title" :style="titleStyle">
          RESET EDITING CANVAS
        </h3>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-10 text-center mx-auto">
        <h6 :style="titleStyle">Would you like to start over from scratch?</h6>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col">
        <cvt-button
            size="md"
            text="Yes"
            :outlined="true"
            :full-width="true"
            v-bind="submitBtnProps"
            :loading="loading"
            @click="startOverHandler"
        />
      </div>
      <div class="col">
        <cvt-button
            size="md"
            text="No"
            :outlined="true"
            :full-width="true"
            v-bind="cancelBtnProps"
            :disable="loading"
            @click="closeDialog"
        />
      </div>
    </div>
  </cvt-dialog>
  <cvt-dialog
        v-else
        size="sm"
        bg-color="#fff"
        :show="show"
        :show-close="false"
        :height-auto="true"
        :show-header="false"
        :show-footer="false"
    >
      <div class="row mb-4 mt-4">
        <div class="col-md-10 text-center mx-auto">
          <h3 class="setup-title" :style="titleStyle">
            RESET EDITING CANVAS
          </h3>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-10 text-center mx-auto">
          <h6 :style="titleStyle">Would you like to start over from scratch?</h6>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <cvt-button
              size="md"
              color="primary"
              text="Yes"
              theme="light"
              special-style="dark"
              :outlined="true"
              :full-width="true"
              :loading="loading"
              @click="startOverHandler"
          />
        </div>
        <div class="col">
          <cvt-button
              size="md"
              color=""
              text="No"
              :outlined="true"
              :full-width="true"
              :disable="loading"
              @click="closeDialog"
          />
        </div>
      </div>
    </cvt-dialog>
</template>

<script lang="js">
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import * as getters from '../../store/modules/getters'
import * as mutations from '../../store/modules/mutations'
import {START_OVER_KEY} from "../../lib/helper-classes";
import { FRESHWORKS } from '../../../../../storybook/components/constants'

export default {
  name: "StartOverDialog",
  data() {
    return {
      startOverVersion: null
    }
  },
  computed: {
    ...mapGetters({
      show: getters.START_OVER_DIALOG_SHOW
    }),
    ...mapState('revisions', [
      'history',
      'loading'
    ]),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      AltPopupManager: ({ globalTheme }) =>
          globalTheme.AltPopupManager !== undefined
              ? globalTheme.AltPopupManager
              : false,
      orgName: ({ globalTheme }) => globalTheme.OrgName,
      deleteColorCancelTheme: ({ globalTheme }) => globalTheme.deleteColorCancelTheme != null ? globalTheme.deleteColorCancelTheme : 'warning',
    }),
    titleStyle() {
      return { 'font-family': this.FontFamily.DEFAULT }
    },
    isFreshworksOrg() {
      return this.orgName === FRESHWORKS
    },

    cancelBtnProps() {
      if (this.isFreshworksOrg) {
        return { color: 'light' }
      } else {
        return { color: this.deleteColorCancelTheme }
      }
    },
    submitBtnProps() {
      if (this.isFreshworksOrg) {
        return {
          color: '',
          specialStyle: 'dark',
        }
      } else {
        return {
          color: 'light',
        }
      }
    }
  },
  watch: {
    history: {
      handler (newVal, oldVal) {
        if (newVal) {
          this.loadStartOverVersion()
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      showStartOverDialog: mutations.START_OVER_SHOW_DIALOG_SET
    }),
    ...mapActions('revisions', [
      'restoreSnapshot'
    ]),
    closeDialog () {
      this.showStartOverDialog(false)
    },
    loadStartOverVersion () {
      if (!this.startOverVersion) {
        const startOverHistory = this.history.filter(s => s.event.description === START_OVER_KEY)
        this.startOverVersion = startOverHistory.length > 0 ? startOverHistory[0] : null
        console.log("this.startOverVersion: ", this.startOverVersion)
      }
    },
    async startOverHandler () {
      if (this.startOverVersion && this.startOverVersion.id) {
        await this.restoreSnapshot(this.startOverVersion.id)
      }
      this.closeDialog()
    },
  },
}
</script>

<style scoped>

</style>