<template>
  <div id="remote-fields" class="remote-fields">
    <div v-if="loading || isBusy" class="placeholder-container">
      <cvt-content-placeholder class="placeholder" />
    </div>
    <cvt-select
      v-else
      id="remote-fields-select"
      :value="selectedField"
      :label="placeholder"
      @input="updateSelected"
      @visible-change="loadFields"
    >
      <optgroup
        v-for="group in filteredFormFieldGroups"
        :key="group.label"
        :label="group.label"
        class="dropdown-options"
      >
        <option
          v-for="field in group.fields"
          :key="field.id"
          :label="field.label"
          :value="field"
          :icon="field.icon"
          class="dropdown-option"
        />
      </optgroup>
    </cvt-select>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'

export default {
  props: {
    exclude: {
      type: Array,
      default() {
        return []
      },
    },
    placeholder: {
      type: String,
      default: 'Add a contact field',
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedField: '',
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      formFieldGroups: getters.FORM_EDITOR_FIELD_GROUPS,
    }),
    excludedIds() {
      return this.exclude.map((f) => f.id)
    },
    filteredFormFieldGroups() {
      const result = this.formFieldGroups.reduce((acc, group) => {
        const defaultInputFields = group.fields.filter(
          (field) => field.field_type === 'default_input',
        )
        const otherFields = group.fields.filter(
          (field) => field.field_type !== 'default_input',
        )
        if (otherFields.length > 0) {
          acc.push({
            label: group.label,
            fields: otherFields,
          })
        }
        if (defaultInputFields.length > 0) {
          acc.push({
            label: 'Additional Fields',
            fields: defaultInputFields,
          })
        }
        return acc
      }, [])
      return result.map((group) => {
        return {
          ...group,
          fields: group.fields.filter((f) => {
            if (f.field_type === 'default_input') return f
            return this.excludedIds.includes(f.id) === false
          }),
        }
      })
    },
  },
  mounted() {
    this.loading = true
    this.loadFields().finally(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions({
      loadFields: actions.FORM_EDITOR_LOAD_FIELDS,
    }),
    updateSelected(data) {
      this.selectedField = ''
      this.$emit('selected', data)
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

.remote-fields {
  .placeholder-container {
    position: relative;
    height: 48px;
  }

  .placeholder {
    height: 48px;
    width: 100%;
    left: 0;
    border-radius: 8px;
  }

  .el-select {
    width: 100%;
    .el-input__inner {
      border: 3px solid $inf-green;
      &::placeholder {
        text-transform: uppercase;
        color: $inf-green;
      }
    }
  }
  .el-select-group__title {
    font-size: 1rem;
    font-weight: bold;
    background: $dark;
    color: $white;
  }

  .el-select-dropdown__item {
    height: 36px;
    line-height: 0;

    .row {
      height: 100%;
    }

    .material-icons {
      line-height: 1;
    }

    small {
      line-height: 1;
      font-size: 1rem;
    }
  }
}
</style>
