
<div class="virtual-fields">
  <cvt-select
    mode="dropdown"
    :value="selectedField"
    :label="placeholder"
    @input="(value) => updateSelected(value)"
  >
    <option
      v-for="field in virtualFields"
      :key="field.type"
      :value="field"
      v-text="field.label"
    />
  </cvt-select>
  <cvt-alert class="my-2" color="info" icon="exclamation-triangle">
    <span class="help"
      >Virtual fields is an alternative to custom fields if you are looking to
      trigger automation from a tag on a field. These fields will not sync to
      a contact record.</span
    >
  </cvt-alert>
</div>
