
<div class="branding-page-wrapper">
  <org-header></org-header>

  <div class="branding-wrapper">
    <div class="branding-container">
      <div class="branding-backdrop-image">
        <div class="backdrop-image-group">
          <img
            src="/img/enterprise/branding-demo-image.jpg"
            class="branding-demo-image"
            alt="brandingImage"
          />
        </div>
        <div class="demo-example-container">
          <div class="demo-example-box">
            <div class="demo-example-head">
              <span v-if="demoImageSrc" class="demo-example-logo">
                <img :src="demoImageSrc" alt="logo" />
              </span>
              <span v-else class="demo-alt-example-logo"> your logo </span>
              <p
                class="demo-example-caption"
                :style="`font-family: ${brandFontStyle};`"
              >
                {{ brandName }}
              </p>
            </div>
            <div class="demo-side-panel-container">
              <div class="demo-side-panel"></div>
              <div class="demo-side-editor">
                <div class="demo-editor-area">
                  <div class="demo-editor-area-head"></div>
                  <div class="demo-editor-area-body">
                    <div
                      :style="`background-color: ${brandAccentColor}`"
                      class="editor-area-side"
                    >
                      <div class="editor-area-button">
                        <cvt-button
                          :style="`background-color: ${brandAccentColor}`"
                          color="success"
                          text="Button"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="setup-brand-container">
        <div class="setup-location-container">
          <cvt-floating-breadcrumb
            :mode="'LIGHT'"
            :menus="breadcrumbMenus"
            :hide-back-btn="true"
            :hide-next-btn="true"
            :active-menu="currentRoute"
            @menuChanged="
              (eventData) => onBreadCrumbSelectionChange(eventData)
            "
          />
        </div>
        <div>
          <h3>Set up your brand</h3>
          <p class="branding-caption">
            Based on what you told us, we found the following styles for
            {{ brand.name || '[Company Name]' }}. Want to make changes? Update
            your styles below. Click "Next" to proceed.
          </p>
        </div>

        <!-- Primary Logo -->
        <cvt-logo-selector
          label="Primary Logo"
          :brand-logos="brandLogos"
          @setCurrentLogo="onSelectLogo"
          @selectNewLogo="onCompleteUpload"
        />

        <!-- Color Section -->

        <cvt-color-selector
          label="Primar Color"
          :brand-colors="brandColors"
          @setCurrentColor="onSelectColor"
          @selectNewColor="onSelectNewColor"
        />

        <!-- Font Section -->

        <cvt-font-selector
          label="Primary Font"
          :brand-fonts="brandFonts"
          @setCurrentFont="onSelectFont"
          @selectNewFont="onSelectNewFont"
        />

        <!-- Button Section -->
        <div class="branding-next-btn-wrap">
          <button
            class="branding-next-btn"
            :disabled="loading"
            :loading="loading"
            @click="onCompleteBrandConfig"
          >
            NEXT : EDITOR TOUR
          </button>

          <span class="branding-skip-btn" @click="onSkipBranding">
            Skip this step
          </span>
        </div>
      </div>
    </div>

    <org-footer alt-footer-style></org-footer>
  </div>
</div>
