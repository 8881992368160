<template>
  <div class="h-100" style="overflow: auto">
    <div class="gen_ai_variable_wrap">
      <div class="mb-4">
        <strong>GenAI Variables</strong>
      </div>

      <cvt-schema-container :nodes="nodes" @selectNode="onSelectNode" />
    </div>
  </div>
</template>

<script>
import anime from 'animejs'
import { mapGetters, mapState } from "vuex";
import * as getters from "@/js/store/modules/getters";
import * as _ from "lodash";


const no_content = "section variable not set"


const generateDCObjects = (component, path) => {
  let res = []

  const node = component?.fbNode || {}

  if (node.data?.props?.dc?.label) {
    let type = node.tag === 'draft' || node.tag === 'c-button' ? 'string' : 'object';
    res.push({label: node.data.props.dc.label, content: {type, ...node.data.props.dc}, children: [], path})
  }

  if (component.$children) {
    component.$children.forEach((child, idx) => {
      const content = generateDCObjects(child, `${path}/$children/${idx}`)

      res = [...res, ...content]
    });
  }
  return res
}


export const getSchemaFromVDom = (pageEngineChildren) => {
  return pageEngineChildren.map((component, idx) => {
    let block_dc_data = {label: no_content, path: `${idx}`}

    const node = component?.fbNode || {}
    if (node.data?.props?.dc?.label) {
      let type = node.tag === 'draft' || node.tag === 'c-button' ? 'string' : 'object';
      block_dc_data = {label: node.data.props.dc.label, content: {type, ...node.data.props.dc}, }
    }

    const children = (component?.$children || []).reduce((preValue, currentValue, currentIndex) => {
      const child = generateDCObjects(currentValue, `${idx}/$children/${currentIndex}`)
      return [...preValue, ...child]
    }, [])

    if (block_dc_data.label === no_content && children.length === 0) {
      return null
    }

    block_dc_data = {...block_dc_data, children}
    return block_dc_data
  })
}

export default {
  name: "GenAISchema",
  data() {
    return {
      nodes: []
    }
  },
  computed: {
    ...mapGetters('globalTheme', {}),
    ...mapState('globalTheme', {}),
    ...mapGetters({
      vDom: getters.DELTA_VDOM,
      children: getters.LAYOUT_CHILDREN,
    })
  },
  mounted() {
    this.nodes = getSchemaFromVDom(this.children).filter(x => x !== null)
  },
  methods: {
    onSelectNode(nodeData) {
      console.log("onSelectNode:", nodeData)
      const node = _.get(this.children, nodeData.path.split("/"))
      console.log("node:", node)
      if (node) {
        node.target(this.synthMouseEvent(node))
        this.scrollTo(node?.$el)
        this.shakeNode(node?.$el)
      }
    },
    scrollTo (e) {
      e.scrollIntoView({ block: 'nearest' })
    },
    synthMouseEvent (node) {
      return {
        preventDefault() {
        },
        stopPropagation() {
        },
        shiftKey: node.layoutElm
      }
    },
    shakeNode (targets, delay = 0) {
      let animation = anime({
        targets,
        duration: 500,
        delay,
        translateY: [0, 50, -50, 0],
        easing: 'easeInOutQuart'
      })

      return animation.finished.then(_ => {
        if(targets && targets.style) {
          targets.style.transform = null;
        }
        return
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/utils';

.gen_ai_variable_wrap {
  width: 20rem; 
  padding: 35px 20px
}
</style>
