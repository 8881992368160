<template>
  <cvt-collapse
    :accordion="true"
    :theme="mode === 'LIGHT' ? 'light' : 'dark'"
    :prepend-chevron="prependChevron"
  >
    <cvt-collapse-item title="Style" name="style" :initial-active="true">
      <h6 :class="textColorClass">
        {{ $t('element.property.button.size.label') }}
      </h6>
      <cvt-radio-group orientation="horizontal" :initial-value="size">
        <cvt-radio-button
          size="md"
          shape="pill"
          value="hr-1"
          @radioClick="updateDividerSize"
        >
          {{ $t('element.property.divider.style.thin') }}
        </cvt-radio-button>
        <cvt-radio-button
          size="md"
          shape="pill"
          value="hr-2"
          @radioClick="updateDividerSize"
        >
          {{ $t('element.property.divider.style.thick') }}
        </cvt-radio-button>
        <cvt-radio-button
          size="md"
          shape="pill"
          value="hr-3"
          @radioClick="updateDividerSize"
        >
          {{ $t('element.property.divider.style.thickest') }}
        </cvt-radio-button>
      </cvt-radio-group>

      <!-- <div class="divider mt-3"></div>

      <h6>Spacing</h6>
      <el-radio-group v-model="hr">
        <el-radio-button label="py-1">XS</el-radio-button>
        <el-radio-button label="py-2">SM</el-radio-button>
        <el-radio-button label="py-3">MD</el-radio-button>
        <el-radio-button label="py-4">LG</el-radio-button>
        <el-radio-button label="py-5">XL</el-radio-button>
      </el-radio-group> -->
    </cvt-collapse-item>

    <cvt-collapse-item
      :title="$t('elements.properties.color.label')"
      name="color"
    >
      <colors :mode="mode"></colors>
    </cvt-collapse-item>

    <cvt-collapse-item
      :title="$t('elements.properties.spacing.label')"
      name="spacing"
    >
      <spacing-ctrl></spacing-ctrl>
    </cvt-collapse-item>
  </cvt-collapse>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import {
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../../storybook/components/constants'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import Colors from '../theme/Colors.vue'
import FlexAlign from './FlexAlign.vue'
import SpacingCtrl from './SpacingCtrl.vue'
export default {
  components: {
    SpacingCtrl,
    Colors,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    prependChevron: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 'style',
      size: '',
      orgNameFreshWork: LEFT_SIDEBAR_FRESHWORKS,
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
    }),
    ...mapState('globalTheme', {
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
    }),
    hr: {
      get() {
        return this.selected.hr
      },
      set(value) {
        return this.updateProp({ key: 'hr', value })
      },
    },
    textColorClass() {
      return { 'text-white': this.mode == 'DARK' }
    },
  },
  created() {
    this.size = this.selected.size
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
    }),
    updateDividerSize(value) {
      this.size = value
      this.updateProp({ key: 'size', value })
    },
  },
}
</script>

<style lang="scss"></style>
