import IBHeader from '../layouts/IBHeader/IBHeader.vue'
import MainNav from '../../../components/common/MainNav.vue'
import { cvtRouteNodes } from '@/js/utils/routes'

export default {
  components: {
    IBHeader,
    MainNav,
  },
  props: {},
  data() {
    return {
      breadcrumbMenus: [
        {
          id: 'set_up_custom_hostname',
          routeNames: [
            cvtRouteNodes.enterpriseIntegrateBuilderSetCustomHost,
            cvtRouteNodes.enterpriseIntegrateBuilderConfigDns,
            cvtRouteNodes.enterpriseIntegrateBuilderDnsAccess,
            cvtRouteNodes.enterpriseIntegrateBuilderVerifyHostname,
            cvtRouteNodes.enterpriseIntegrateBuilderInviteIt
          ],
          title: 'Set up custom hostname',
          completed: false,
          disabled: false,
          action: this.setupCustomHostname,
        },
        {
          id: 'authenticate_customers',
          routeNames: [cvtRouteNodes.enterpriseIntegrateBuilderAuthCustomer],
          title: 'Authenticate customers',
          completed: false,
          disabled: false,
          action: this.authenticateCustomers,
        },
        {
          id: 'feature_configuration',
          routeNames: [cvtRouteNodes.enterpriseIntegrateBuilderFeatureConfig],
          title: 'Feature configuration',
          completed: false,
          disabled: false,
          action: this.featureConfiguration,
        },
      ],
    }
  },
  computed: {
    currentRoute() {
      return this.$route.name
    },
  },
  methods: {
    setupCustomHostname() {
      this.$router.push({
        name: cvtRouteNodes.enterpriseIntegrateBuilderSetCustomHost,
      })
    },
    authenticateCustomers() {
      this.$router.push({
        name: cvtRouteNodes.enterpriseIntegrateBuilderAuthCustomer,
      })
    },
    featureConfiguration() {
      this.$router.push({
        name: cvtRouteNodes.enterpriseIntegrateBuilderFeatureConfig,
      })
    },
    onBreadCrumbSelectionChange(eventData) {
      if (eventData && eventData.action) {
        eventData.action()
      }
    },
  },
}
