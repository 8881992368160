import { mapActions, mapGetters } from 'vuex'
import * as getters from '../../../../store/modules/getters'
import * as actions from '../../../../store/modules/actions'
import { Notification } from 'element-ui'
import CustomHostNameUrlSetup from '../../CustomHostNameUrlSetup/CustomHostNameUrlSetup.vue'
import { cvtRouteNodes } from '@/js/utils/routes'

export default {
  components: {
    CustomHostNameUrlSetup,
  },
  props: {},
  data() {
    return {
      envUrls: [
        {
          environment: 'Staging',
          url: '',
          label: 'Define your staging environment URL',
          hint: 'staging.builder.yourapp.com',
        },
        {
          environment: 'Production',
          url: '',
          label: 'Define your production environment URL',
          hint: 'production.builder.yourapp.com',
        },
        {
          environment: 'CDN',
          url: '',
          label: 'Create custom DNS CNAME record for your customers to alias',
          hint: 'pages.yourapp.com',
        },
      ],
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      dnsRecords: getters.DNS_RECORDS,
    }),
  },
  methods: {
    ...mapActions({
      createDnsRecord: actions.UPDATE_DNS_RECORD,
      fetchDnsRecord: actions.FETCH_DNS_RECORD,
    }),

    async continueToConfigDns() {
      const payload = {}
      this.loading = true

      this.envUrls.map((env) => {
        let formattedKey = env.environment.toLowerCase()
        payload[formattedKey] = env.url
      })

      await this.createDnsRecord(payload)
        .then(() => {
          this.loading = false
          Notification.success({
            title: 'Success',
            message: 'DNS Record has been created',
            position: 'bottom-right',
          })

          this.$router.push({
            name: cvtRouteNodes.enterpriseIntegrateBuilderConfigDns,
          })
        })
        .catch((err) => {
          this.loading = false
          Notification.error({
            title: 'Woops',
            message: err.response.data.errors[0].message,
            position: 'bottom-right',
          })
        })
    },

    skipHostNameUrlSetup() {
      this.$router.push({
        name: cvtRouteNodes.enterpriseIntegrateBuilderInviteIt,
      })
    },
  },
  async created() {
    await this.fetchDnsRecord()
    let result = []

    if (this.dnsRecords.length) {
      this.envUrls.forEach((env) => {
        this.dnsRecords.forEach((record) => {
          if (
            record.environment.toLowerCase() === env.environment.toLowerCase()
          ) {
            result.push({
              ...env,
              url: record.url,
            })
            return
          }
        })
      })

      this.envUrls = result
    }
  },
}
