<template>
  <cvt-collapse
    :accordion="true"
    :theme="mode === 'LIGHT' ? 'light' : 'dark'"
    :prepend-chevron="prependChevron"
  >
    <cvt-collapse-item
      title="HTML"
      name="html"
      :initial-active="true"
      :special-style="isSpecialStyle"
    >
      <cvt-alert
        color="primary"
        icon="info-circle"
      >
        {{ $t("element.property.code.helper.label") }}
      </cvt-alert>
      <ace
        ref="ace"
        lang="html"
        :code-snippet-html="codeSnippetHtml"
        @change="updateCodeSnippet"
      />
      <strong><i /></strong>
    </cvt-collapse-item>
  </cvt-collapse>
</template>

<script>
  import * as _ from 'lodash'
  import { mapGetters, mapState, mapActions } from 'vuex'
  import * as getters from '../../../store/modules/getters'
  import * as actions from '../../../store/modules/actions'
  import Ace from '../../common/Ace.vue'
  import { LEFT_SIDEBAR_FRESHWORKS, LEFT_SIDEBAR_STANDARD } from '../../../../../../storybook/components/constants'
  export default {
    components: {
      Ace
    },
    props: {
      mode: {
        type: String,
        default: 'LIGHT'
      },
      prependChevron: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        activeTab: 'html'
      }
    },
    computed: {
      ...mapGetters({
        selected: getters.SELECTOR_SELECTED
      }),
      ...mapState('globalTheme', {
        OrgName: ({ globalTheme }) => globalTheme.OrgName !== undefined ? globalTheme.OrgName : LEFT_SIDEBAR_STANDARD
      }),
      isSpecialStyle () {
        return this.OrgName === LEFT_SIDEBAR_FRESHWORKS
      },
      codeSnippetHtml: {
        get () {
          return _.get(this, 'selected.html', '')
        },
        set (value) {
          return this.updateProp({ key: 'html', value })
        },
      },
    },
    methods: {
      ...mapActions({
        updateProp: actions.SELECTOR_UPDATE_PROP
      }),
      updateCodeSnippet: _.debounce(function (value) {
        this.codeSnippetHtml = value;
      }, 100)
    }
  }
</script>

<style lang="scss">
</style>
