<script>
import GridItemWrapperV3 from '../../../../components/v3/editor/nodes/GridItemWrapper.vue'
import CFormV3Popup from './CForm.vue'

export default GridItemWrapperV3.extend({
  name: "GridItemWrapperV3Popup",
  components: {
    'c-form': CFormV3Popup
  }
})
</script>
