import {VNodeData} from "@/js/lib/helper-classes";


export const checkoutPageOptionDefaults = {
    data: new VNodeData({
        props: {
            showLogo: true,
            showDiscountCode: true,
            showGooglePay: true,
            allowSubscription: true,
            showUpOrDownSells: true,
            redirectOption: '',
            pageId: '',
            redirectUrl: '',
            termsAndConditionsURL: '',
            privacyPolicyURL: '',
            refundURL: '',
        }
    })
}

export const checkoutThankYouPageOptionDefaults = {
    data: new VNodeData({
        props: {
            showThankYouMessage: true,
            showContactEmail: true,
            showBillingInfo: true,
            showOrderSummary: true,
            showItemsInOrder: true,
            thankYouMessage1: "Thank you!",
            thankYouMessage2: "Your order has been confirmed.",
            thankYouMessageSubtitle: "In the meantime, learn about our amazing product/service and how we can help you get desired outcome",
        }
    })
}