<template>
  <div>
    <h5 class="mt-2 mb-4">{{ $t("launch.tracking.message") }}</h5>
    <div class="row mb-4">
      <div class="col">
        <strong>{{ $t("launch.tracking.head") }} <code>&lt;head&gt;</code></strong>
        <ace 
          ref="trackHead" 
          lang="html" 
          :code-snippet-html="tracking.head" 
          @change="trackHead" 
        />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <strong>{{ $t("launch.tracking.opening_body") }}</strong>
        <ace 
          ref="trackAfterOpeningBody" 
          lang="html" 
          :code-snippet-html="tracking.afterOpeningBody" 
          @change="trackAfterOpeningBody"
        />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <strong>{{ $t("launch.tracking.closing_body") }}</strong>
        <ace 
          ref="trackBeforeClosingBody" 
          lang="html" 
          :code-snippet-html="tracking.beforeClosingBody" 
          @change="trackBeforeClosingBody"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash'
import Ace from '../../common/Ace.vue'
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import { mapActions, mapGetters } from 'vuex'

const SAVED_EMBEDS_MSG = 'We saved your tracking codes. Please republish to update your page to include them.'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Tracking',
  components: {
    Ace
  },

  computed: {
    ...mapGetters({
      siteTracking: getters.SITE_TRACKING,
    }),
    tracking () {
      return this.siteTracking
    }
  },

  methods: {
    ...mapActions({
      saveTracking: actions.SITE_JS_TRACKING_SAVE
    }),
    trackAndSaveProperty(prop, value){
      this.tracking[prop] = value
      return this.saveTracking(this.tracking).then(() => {
        this.$message({message: SAVED_EMBEDS_MSG})
      })
    },
    trackHead: _.debounce(function(ace) {
      return this.trackAndSaveProperty('head', ace);
    }, 3000),

    trackAfterOpeningBody: _.debounce(function(ace) {
      return this.trackAndSaveProperty('afterOpeningBody', ace)
    }, 3000),

    trackBeforeClosingBody: _.debounce(function(ace) {
      return this.trackAndSaveProperty('beforeClosingBody', ace)
    }, 3000)
  }
}
</script>
