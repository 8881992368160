
<el-dialog :visible.sync="visible" @close="close">
  <span slot="title" class="iconlib-title"> Icon Library </span>

  <el-alert
    :title="$t('icon.search.help.message')"
    type="info"
    show-icon
    :closable="false"
  >
  </el-alert>

  <div v-loading="loading" class="container">
    <div class="row mt-2">
      <div class="col-md-8">
        <input
          v-model="filter"
          type="text"
          class="form-control"
          placeholder="search icons"
          @input="debouncedSearch"
        />
      </div>
      <div class="col-md-4">
        <select
          v-model="selectedSet"
          class="form-control"
          @change="debouncedSearch"
        >
          <option
            v-for="set in sets"
            :key="set.value"
            :value="set.value"
            v-text="set.label"
          />
        </select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="icon-list">
          <div
            v-for="icon in icons"
            :key="icon.class"
            class="icon-list-item"
            @click="select(icon)"
          >
            <icon-template :set="icon.set" :selector="icon.class" />
            <small class="mt-2" v-text="icon.class" />
          </div>
        </div>
      </div>
    </div>
  </div>
</el-dialog>
