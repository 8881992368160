<template>
  <div data-identity="EnableMarketplace">
    <h4 class="admin-label">
      {{ $t('launch.tab.control.heading.show_in_marketplace') }}
    </h4>
    <cvt-switch
      class="admin-switch"
      size="xs"
      color="active"
      type="circular"
      description-type="default"
      :initial-state="marketplaceEnabled"
      :mode="mode"
      @switchToggle="onSwitchToggle"
    />
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'EnableMarketplace',
  props: {
    marketplaceEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mode: 'LIGHT',
    }
  },
  methods: {
    onSwitchToggle(status) {
      if (status === 'Active') {
        this.$emit('switchToggle', { value: true })
      } else if (status === 'Inactive') {
        this.$emit('switchToggle', { value: false })
      }
    },
  },
})
</script>

<style lang="scss" scoped></style>
