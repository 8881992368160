<template >
	<div 
		class="container-fluid thankyou-page-root-wrap"
		:class="{ 'selected-mark': selected, 'unselected-mark': !selected }" 
		style="cursor: pointer" 
		@click="edit"
	>
		<div class="row">
			<div v-if="showThankYouMessage" class="thank-you-top-container">
				<div class="thank-you-message-wrap col-6">
					<h2 class="thank-you-message mb-0" :class="stylesheet.classes.h2">
						<strong v-text="thankYouMessage1"></strong>
					</h2>
					<h4 class="mb-3" :class="stylesheet.classes.h4">
						<strong v-text="thankYouMessage2"></strong>
					</h4>
					<p class="mb-0" :class="stylesheet.classes.p" v-text="thankYouMessageSubtitle"></p>
				</div>

				<div class="need-help-wrap col-6">
					<h5 class="d-flex justify-content-center align-items-center need-help-title" :class="stylesheet.classes.h5">
						<strong>Need Help?</strong>
					</h5>
					<p class="d-flex justify-content-center align-items-center need-help-sub-title" :class="stylesheet.classes.p">
						You can contact our team using the buttons below:
					</p>

					<div class="email-btn-wrap mb-2">
						<button class="email-btn" :class="stylesheet.classes.btn">
							<i class="far fa-envelope mr-1"></i>
							SEND US AN EMAIL
						</button>
					</div>
					<div class="messenger-btn-wrap">
						<button class="messenger-btn" :class="stylesheet.classes.btn">
							<i class="fab fa-facebook-messenger"></i>
							Message us on FB
						</button>
					</div>

				</div>
			</div>

			<div class="contact-order-container">
				<div class="contact-outer-wrap col-6">

					<div v-if="showContactEmail" class="contact-inner-wrap mb-5">
						<h5 class="contact-header mb-3" :class="stylesheet.classes.h5">
							<strong>Contact Information</strong>
						</h5>
						<div class="ck-divider"></div>
						<p class="contact-email-label mt-2" :class="stylesheet.classes.p">
							<strong>Email</strong>
						</p>
						<p class="contact-email-text mt-1" :class="stylesheet.classes.p">You@email.com</p>
					</div>

					<div class="payment-inner-wrap mb-5">
						<h5 class="payment-header mb-3" :class="stylesheet.classes.h5">
							<strong>Payment Information</strong>
						</h5>
						<div class="ck-divider"></div>
						<div class="d-flex">
							<div class="payment-info w-50" :class="stylesheet.classes.p">
								<p class="payment-info-labell mt-2">
									<strong>Payment Info</strong>
								</p>
								<p class="payment-info-text">Visa 4534</p>
								<p class="payment-info-text">06/2023</p>
							</div>

							<div v-if="showBillingInfo" class="billing-info w-50" :class="stylesheet.classes.p">
								<p class="billing-info-labell mt-2">
									<strong>Billing Address</strong>
								</p>
								<p class="billing-info-text">John Doe</p>
								<p class="billing-info-text">123 Easy St.</p>
								<p class="billing-info-text">Disney, CA 91705</p>
								<p class="billing-info-text">USA</p>
							</div>
						</div>
					</div>

					<div v-if="showItemsInOrder" class="order-items-wrap">
						<h5 class="order-items-header mb-3" :class="stylesheet.classes.h5">
							<strong>Items in Order</strong>
						</h5>

						<div class="ck-divider"></div>

						<div class="d-flex">
							<div class="d-flex mt-3 w-75">
								<div class="pdt-thumb-wrap mr-3">
									<img class="pdt-thumb" src="/img/product--placeholder-v2.svg" alt="Product-Logo" />
								</div>
								<div class="pdt-title-price-qty-wrap mb-4" :class="stylesheet.classes.p">
									<p class="pdt-title">
										<strong>Your product title</strong>
									</p>
									<p class="pdt-qty">QTY: 1</p>
									<h5 class="pdt-price">
										<strong>$9.99 USD</strong>
									</h5>
								</div>
							</div>
							<div class="w-25 d-flex justify-content-end" :class="stylesheet.classes.p">
								<p class="pdt-price-right mt-3">
									<strong>$9.99 USD</strong>
								</p>
							</div>
						</div>
					</div>

				</div>

				<div class="order-sum-outer-wrap col-6">

					<div v-if="showOrderSummary" class="order-sum-inner-wrap bg-white mb-3">
						<h5 class="text-center" :class="stylesheet.classes.h5">
							<strong>Order Summary</strong>
						</h5>

						<div class="ck-divider my-2"></div>

						<div class="pdt-subtotal-wrap d-flex justify-content-between align-items-center mb-2">
							<h5 class="pdt-subtotal-text" :class="stylesheet.classes.h5">Subtotal</h5>
							<h5 class="pdt-subtotal-amount" :class="stylesheet.classes.h5">$9.99 USD</h5>
						</div>

						<div class="pdt-total-wrap d-flex justify-content-between align-items-center mb-3">
							<h5 class="pdt-total-text" :class="stylesheet.classes.h5">
								<strong>Total</strong>
							</h5>
							<h5 class="pdt-total-amount" :class="stylesheet.classes.h5">
								<strong>$9.99 USD</strong>
							</h5>
						</div>
					</div>

					<div class="download-inner-wrap bg-white">
						<h5 class="text-center" :class="stylesheet.classes.h5">
							<strong>Download</strong>
						</h5>

						<div class="ck-divider my-2"></div>

						<p class="download-pdt-name text-center" :class="stylesheet.classes.p">Product Name</p>

						<div class="download-file-btn-wrap d-flex justify-content-center mt-4">
							<button class="download-file-btn w-100" :class="stylesheet.classes.btn">DOWNLOAD FILE</button>
						</div>
					</div>

				</div>
			</div>

			<div class="footer-container" :class="stylesheet.classes.p">
				<div class="footer-text-normal">123 Easy St. 12345 New York</div>
				<div class="footer-text-normal">800-123-4567</div>
				<div class="footer-text-bold">
					<strong>TERMS & CONDITIONS | PRIVACY POLICY</strong>
				</div>
				<div class="footer-text-normal">2023 YOURCOMPANY.com All Rights Reserved.</div>
			</div>

		</div>
	</div>
</template>

<script>
import * as getters from '../../../../store/modules/getters'
import { mapGetters } from 'vuex'
import FbVNode from '@/js/components/base/FbVNode.vue'
import Mixins from '../PageMixins'


export default FbVNode.extend({
	name: 'CheckoutThankYou',
	mixins: [Mixins],
	props: {
		showThankYouMessage: {
			type: Boolean,
			default: true,
		},
		showContactEmail: {
			type: Boolean,
			default: true,
		},
		showBillingInfo: {
			type: Boolean,
			default: true,
		},
		showOrderSummary: {
			type: Boolean,
			default: true,
		},
		showItemsInOrder: {
			type: Boolean,
			default: true,
		},
		thankYouMessage1: {
			type: String,
			required: true,
		},
		thankYouMessage2: {
			type: String,
			required: true,
		},
		thankYouMessageSubtitle: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			name: 'CheckoutThankYou',
		}
	},
	computed: {
		...mapGetters({
			theme: getters.THEME,
		}),
	}
})
</script>

<style lang="scss" scoped src="./index.scss">