import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { styled } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from 'vuex'

Vue.use(VueI18n)

let textFontWeight, textFontSize, Spacing, FontSize, backgroundColor, Colors, FontFamily

const getLogoContainer = () => styled('p')`
  height: 60px;
  width: 60px;
  margin-right: 18px;
  margin-bottom: 0;
  font-family: ${FontFamily.DEFAULT};
`

const getAuthenticationHeader = () => styled('div')`
  display: flex;
  margin-bottom: 20px;
  font-family: ${FontFamily.DEFAULT};
`

const getSelectedIntegrationTitle = () => styled('p')`
  font-size: 18px;
  margin-bottom: 0;
  color: ${Colors.BASIC[500]};
`

const getSelectedIntegrationCaption = () => styled('p')`
  font-size: 14px;
  margin-bottom: 0;
  color: ${Colors.BASIC[500]};
`

export const IntegrationLogoWrapper = {
  name: 'IntegrationLogoWrapper',
  data() {
    return {}
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      selectedIntegration: getters.SELECTED_INTEGRATION,
    }),
    ...mapGetters('globalTheme', {
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
    }),
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      globalTheme: ({ globalTheme }) => globalTheme,
    }),
  },
  created() {
    textFontSize = this.textFontSize
    textFontWeight = this.textFontWeight
    Spacing = this.Spacing
    FontSize = this.FontSize
    backgroundColor = this.backgroundColor
    Colors = this.Colors
    FontFamily = this.FontFamily
  },
  mounted() {
    this.onScrollInToView()
  },
  methods: {
    onScrollInToView() {
      const container = document.getElementById('authWrap')
      container.scrollIntoView()
    },
  },
  render: function (h) {

    const SelectedIntegrationTitle = getSelectedIntegrationTitle()
    const SelectedIntegrationCaption = getSelectedIntegrationCaption()
    const LogoContainer = getLogoContainer()
    const AuthenticationHeader = getAuthenticationHeader()

    return (
      <div style="width: 100%;">
        <AuthenticationHeader>
          <LogoContainer>
            <img
              style="height: 100%;"
              src={this.selectedIntegration.image}
              alt="logo"
            />
          </LogoContainer>
          <div>
            <SelectedIntegrationTitle>
              {this.selectedIntegration.title}
            </SelectedIntegrationTitle>
            <SelectedIntegrationCaption>
              Send new contact to your CRM and create new deals.
            </SelectedIntegrationCaption>
          </div>
        </AuthenticationHeader>
        {this.$slots.default}
      </div>
    )
  },
}
