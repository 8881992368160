import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'

import Vue from '../../../lib/utils/vue'
Vue.use(VueEmotion)
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'

let Spacing, FontFamily
let backgroundColor, textFontSize

const getCvtTableWrapper = () => styled('div')`
  padding: ${Spacing.SPACING_16}px;
  ${(props) => {
    if (props.fixed)
      return `padding-top: 0px;
              margin-top: ${Spacing.SPACING_16}px;`
  }}
`

const getCvtTable = () => styled('table')`
  font-family: ${FontFamily.DEFAULT};
  ${textFontSize('md')}
  width: 100%;

  ${(props) => {
    let style = ''
    if (props.striped) {
      style += `
        tr:nth-child(2n+0) {
          ${
            props.mode == 'LIGHT'
              ? backgroundColor({ themeing: 'dark' }, 100)
              : backgroundColor({ themeing: 'dark' }, 800)
          }
        }
      `
    }
    if (props.fixed) {
      style += `th {
        ${
          props.mode == 'LIGHT'
            ? backgroundColor({ themeing: 'dark' }, 'WHITE')
            : backgroundColor({ themeing: 'dark' }, 700)
        }
        position: sticky;
        top: 0;
        z-index: 10;
      }`
    }
    if (props.singleSelect) {
      style += `
        tbody tr:active {
          ${backgroundColor({ themeing: 'primary' }, 300)}
        }
      `
    }
    return style
  }}
`

export default {
  name: 'CvtTable',
  components: {},
  props: {
    striped: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'LIGHT'
    },
    fixed: {
      type: Boolean,
      default: false
    },
    singleSelect: {
      type: Boolean,
      default: false
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, String, Number],
      default() {
        return []
      }
    }
  },
  data() {
    return {
      activeItems: [...this.value]
    }
  },
  provide() {
    return {
      table: this
    }
  },
  watch: {
    value(value) {
      this.activeItems = [...value]
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({globalTheme}) => globalTheme.FontFamily,
      Spacing: ({globalTheme}) => globalTheme.Spacing
    })
  },
  methods: {
    setActiveItems(items) {
      this.activeItems = [...items]
      let value = this.singleSelect ? items : this.multiSelect ? items : []
      this.$emit('input', value)
      this.$emit('change', value)
    },
    onToogle(item) {
      let activeItems = [...this.activeItems]

      if (this.singleSelect) {
        activeItems = activeItems.includes(item.name) ? [] : [item.name]
      } else if (this.multiSelect) {
        if (activeItems.includes(item.name)) {
          const index = this.activeItems.indexOf(item.name)
          activeItems.splice(index, 1)
        } else {
          activeItems.push(item.name)
        }
      }
      this.setActiveItems(activeItems)
    }
  },
  created() {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    this.$on('item-click', (e) => this.onToogle(e))
  },
  render: function (h) {
    const CvtTableWrapper = getCvtTableWrapper()
    const CvtTable = getCvtTable()

    return (
      <CvtTableWrapper fixed={this.fixed}>
        <CvtTable
          mode={this.mode}
          striped={this.striped}
          fixed={this.fixed}
          singleSelect={this.singleSelect}
        >
          {this.$slots.default}
        </CvtTable>
      </CvtTableWrapper>
    )
  }
}
