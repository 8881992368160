<template>
  <div class="country-subdivision-selector">
    <div class="form-group">
      <label>Default Country:</label><br>
      <el-select
        v-model="country"
        placeholder="Choose default country"
        @change="onCountryChange">

        <el-option
          v-for="c in countries"
          :key="c.alpha3"
          :label="c.name"
          :value="c.alpha3">
        </el-option>
      </el-select>
    </div>
    <div class="form-group">
      <label>Default State / Province:</label><br>
      <el-select
        v-model="subdivision"
        placeholder="Choose default state / province"
        @change="onSubdivisionChange">

        <el-option
          v-for="sub in subdivisions"
          :key="sub.alpha3"
          :label="sub.name"
          :value="sub.alpha3">
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
  import * as _ from 'lodash'
  import cdn from '@/js/store/services/cdn'

  export default {
    props: {
      defaultCountry: {
        type: Object,
        default () { return {} }
      },
      defaultSubdivision: {
        type: Object,
        default () { return {} }
      }
    },
    data () {
      return {
        countries: [],
        subdivisions: [],
        country: this.defaultCountry && this.defaultCountry.alpha3,
        subdivision: this.defaultSubdivision && this.defaultSubdivision.alpha3
      }
    },
    async mounted () {
      this.countries = await cdn.countries()
      if (this.country) {
        this.subdivisions = await cdn.subdivisionByCountryCode(this.country)
      }
    },
    methods: {
      async onCountryChange (alpha3) {
        this.emitChange({
          countryCode: alpha3,
          subdivisionCode: null
        })

        this.subdivision = null
        this.subdivisions = await cdn.subdivisionByCountryCode(alpha3)
      },
      onSubdivisionChange (alpha3) {
        if (!alpha3) return
        this.emitChange({
          countryCode: this.country,
          subdivisionCode: alpha3
        })
      },
      emitChange ({countryCode, subdivisionCode}) {
        this.$emit('country-subdivision-change', {
          country: _.find(this.countries, {alpha3: countryCode}) || null,
          subdivision: _.find(this.subdivisions, {alpha3: subdivisionCode}) || null
        })
      }
    }
  }
</script>

