import { mapGetters } from 'vuex'
import * as getters from '@/js/store/modules/getters'

export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      userSessionData: getters.PAGES_SESSION_DATA,
      subscriptionModalVisible: getters.SUBSCRIPTION_MODAL_VISIBLE,
    }),
    showSubscriptionModal(): Boolean {
      return (
        this.config.SUBSCRIPTION_ENABLED && 
        !this.userSessionData.user_subscribed &&
        this.subscriptionModalVisible
      )
    },
    modalHeading(): String {
      return this.config.SUBSCRIPTION_MODAL_HEADING || ''
    },
    modalDescription(): String {
      return this.config.SUBSCRIPTION_MODAL_DESCRIPTION || ''
    },
    modalActionBtnUrl(): String {
      return this.config.SUBSCRIPTION_MODAL_ACTION_BTN_URL || ''
    },
  },
  methods: { 
    continueToPayment(event: any) {
      this.$refs.actionBtnUrlAnchor.click()
    }
  },
}
