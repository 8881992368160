<script>
import { mapActions, mapGetters } from 'vuex'
import * as getters from '../../../store/modules/getters'
import * as actions from "@/js/store/modules/actions";
import DefaultColorSelectorVue from '../../common/DefaultColorSelector.vue'

export default DefaultColorSelectorVue.extend({
  computed: {
    ...mapGetters({
      colors: getters.MP_GET_THEME_COLORS,
    }),
  },
  methods: {
    ...mapActions({
      updateThemeColor: actions.MP_UPDATE_COLOR,
    }),
  },
})
</script>
