/*
* This is a patch for gridstack engine
* */
import {GridStack, GridStackEngine, Utils} from "gridstack";


export class ConvrrtGridStackEngine extends GridStackEngine {
    constructor(opts = {}) {
        super(opts)
        this._isDragging = false
    }
    setIsDragging(value) {
        // this._isDragging = value
        // THis is disabled for now
    }

    restoreInitial() {
        super.restoreInitial()
        console.debug('restoreInitial called...', this._isDragging)
        this.setIsDragging(false)
    }

    get isDragging() {
        return this._isDragging
    }
    lockNodes () {
        this.nodes.forEach(x => {
            x.locked = true;
            x._dirty = true; // will force attr update
        })
    }

    unLockNodes () {
        this.nodes.forEach(x => {
            x.locked = false;
            x._dirty = true; // will force attr update
        })
    }
    /** return true if the passed in node was actually moved (checks for no-op and locked) **/
    moveNode(node, o) {
        if (!node || /*node.locked ||*/ !o) return false;
        if (o.pack === undefined) o.pack = true;

        // constrain the passed in values and check if we're still changing our node
        if (typeof o.x !== 'number') { o.x = node.x; }
        if (typeof o.y !== 'number') { o.y = node.y; }
        if (typeof o.w !== 'number') { o.w = node.w; }
        if (typeof o.h !== 'number') { o.h = node.h; }
        let resizing = (node.w !== o.w || node.h !== o.h);
        let nn = Utils.copyPos({}, node, true); // get min/max out first, then opt positions next
        Utils.copyPos(nn, o);
        nn = this.nodeBoundFix(nn, resizing);
        Utils.copyPos(o, nn);

        if (Utils.samePos(node, o)) return false;
        let prevPos = Utils.copyPos({}, node);

        // check if we will need to fix collision at our new location
        let collides = this.collideAll(node, nn, o.skip);
        let needToMove = true;
        if (collides.length) {
            // now check to make sure we actually collided over 50% surface area while dragging
            let collide = node._moving && !o.nested ? this.directionCollideCoverage(node, o, collides) : collides[0];
            // if (collide && o.resizing) {
            //     // we want the component to remain where they are since its colliding with other nodes
            //     needToMove = false;
            // }
            if (collide) {
                needToMove = !this._fixCollisions(node, nn, collide, o); // check if already moved...
            } else {
                needToMove = false; // we didn't cover >50% for a move, skip...
            }
        }

        // now move (to the original ask vs the collision version which might differ) and repack things
        if (needToMove) {
            node._dirty = true;
            Utils.copyPos(node, nn);
        }
        if (o.pack) {
            this._packNodes()
                ._notify();
        }
        return !Utils.samePos(node, prevPos); // pack might have moved things back
    }

    /** return true if the passed in node was actually moved (checks for no-op and locked) **/
    getNodeCollision(node, o) {
        if (o.pack === undefined) o.pack = true;

        // constrain the passed in values and check if we're still changing our node
        if (typeof o.x !== 'number') { o.x = node.x; }
        if (typeof o.y !== 'number') { o.y = node.y; }
        if (typeof o.w !== 'number') { o.w = node.w; }
        if (typeof o.h !== 'number') { o.h = node.h; }
        let resizing = (node.w !== o.w || node.h !== o.h);
        let nn = Utils.copyPos({}, node, true); // get min/max out first, then opt positions next
        Utils.copyPos(nn, o);
        nn = this.nodeBoundFix(nn, resizing);
        Utils.copyPos(o, nn);

        // check if we will need to fix collision at our new location
        return this.collideAll(node, nn, o.skip);
    }

    addNode(node, triggerAddEvent = false) {
        let dup = this.nodes.find(n => n._id === node._id);
        if (dup) return dup; // prevent inserting twice! return it instead.

        if (node._isExternal) {
            // if node is External, it means we are adding new Element. So We lock all nodes
            // If eventually this node is not added `restoreInitial` will be called, and we will unlock all nodes
            // but if the node gets added, then on drop event we unlock the nodes.
            this.setIsDragging(true)
        }

        // skip prepareNode if we're in middle of column resize (not new) but do check for bounds!
        node = this._inColumnResize ? this.nodeBoundFix(node) : this.prepareNode(node);
        delete node._temporaryRemoved;
        delete node._removeDOM;

        if (node.autoPosition && this.findEmptyPosition(node)) {
            delete node.autoPosition; // found our slot
        }

        this.nodes.push(node);
        if (triggerAddEvent) { this.addedNodes.push(node); }

        this._fixCollisions(node);
        if (!this.batchMode) { this._packNodes()._notify(); }

        if (node.__convrrt__props === undefined && node.grid) {
            // We convert node.locked property to be computed from engine.isDragging property.
            // that way we can make all the node react from simply changing engine.isDragging
            node.__convrrt__props = true
            Object.defineProperty(node, "locked", {
                get: function() {
                    return this.grid.engine.isDragging;
                }
            });
        }

        return node;
    }
}

GridStack.registerEngine(ConvrrtGridStackEngine)

