import * as _ from 'lodash'
import { cvtRouteNodes } from '@/js/utils/routes'
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import AltUploadDropzone from '../../../components/asset-manager/alt-upload-dropzone/AltUploadDropZoneIndex.vue'
import ColorPicker from '../../../components/spectrum/ColorPicker.vue'
import * as getters from '../../../store/modules/getters'
import { Organization } from '../../../store/services/api'
import OrgFooter from '../layouts/Footer/Footer.vue'
import OrgHeader from '../layouts/Header/Header.vue'
import FontPicker from '../../../components/common/FontPicker.vue'
import { getFullUrlPath } from '@/js/utils/helpers'

const organizationAPI = new Organization()

interface IData {
  breadcrumbMenus: {
    id: string
    routeNames: string[]
    title: string
    completed: boolean
    disabled: boolean
    action: () => void
  }[]
  brandConfigErrors: {
    brandConfigValid: boolean
    msg: string
  }
  loading: boolean
  brand: {
    fonts: {
      name: string
    }[]
    colors: {
      hex: string
      type: string
    }[]
    logos: {
      formats: {
        src: string
      }[]
    }[]
    name: string
  }
  activeColor: number
  activeFont: number
  activeLogo: number
  demoImageSrc: string
  showUploadModal: boolean
  showModal: boolean
  selectedImage: string
  selectedColor: string
  selectedLogo: string
  selectedFont: string
  companyUrl: string
  currentMenuId: string
}

interface IProps {
  globalTheme: {}
}
interface IComputed {}
interface IMethods {
  organizationToggle: () => void
  onSelectColor: (idx: number) => void
  onSelectFont: (idx: number) => void
  onShowUploadModal: () => void
  onCloseUploadModal: () => void
  onCompleteUpload: (image: string[]) => void
  onSkipBranding: () => void
  onSelectNewFont: (selectedFont: string) => void
  onSelectNewColor: (selectedColor: string) => void
  profileToggle: () => void
  onCompleteBrandConfig: () => void
  editorToggle: () => void
  onOrgSetupComplete: () => void
  onBreadCrumbSelectionChange: (eventData: {
    action: () => void
    id: string
  }) => void
  onSelectLogo: (logo: string, idx: number) => void
  validateBrandConfig: () => boolean
}

export default Vue.extend<IData, IMethods, IComputed, IProps>({
  components: {
    OrgHeader,
    OrgFooter,
    AltUploadDropzone,
    FontPicker,
    ColorPicker,
  },
  props: {},
  data() {
    return {
      loading: false,
      brand: {
        fonts: [],
        colors: [],
        logos: [],
        name: '',
      },
      activeColor: null,
      activeFont: null,
      activeLogo: null,
      demoImageSrc: '',
      showUploadModal: false,
      showModal: false,
      selectedImage: '',
      selectedColor: '',
      selectedFont: '',
      selectedLogo: '',
      currentMenuId: '',
      companyUrl: this.$route.query.site as string,
      breadcrumbMenus: [
        {
          id: 'organization-details',
          routeNames: [cvtRouteNodes.enterpriseOnboardingCollectMoreInfo],
          title: 'Organization details',
          completed: false,
          disabled: true,
          action: (this as any).organizationToggle,
        },
        {
          id: 'brand-profile',
          routeNames: [cvtRouteNodes.enterpriseOnboardingBrandingPage],
          title: 'Set up your brand profile',
          completed: false,
          disabled: false,
          action: (this as any).profileToggle,
        },
        {
          id: 'editor-tour',
          routeNames: [''],
          title: 'Editor Tour',
          completed: false,
          disabled: false,
          action: (this as any).editorToggle,
        },
      ],
      brandConfigErrors: {
        brandConfigValid: false,
        msg: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      admin: getters.AUTH_GET_USER,
    }),
    ...mapState('globalTheme', {
      globalTheme: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined ? globalTheme : null,
    }),
    currentRoute() {
      return this.$route.name
    },
    brandName() {
      return this.brand.name || '[Company Name]'
    },

    brandLogoSrc() {
      return this.brand.logos ? this.brand.logos[0].formats[0].src : ''
    },

    brandColors() {
      return this.brand.colors?.length ? this.brand.colors : []
    },

    brandLogos() {
      return this.brand.logos?.length ? this.brand.logos : []
    },

    brandFonts() {
      return this.brand.fonts?.length ? this.brand.fonts : []
    },

    brandAccentColor() {
      return this.brand.colors
        ? this.brand.colors[this.activeColor]?.hex
        : '#73B5E5'
    },

    brandFontStyle() {
      const font = this.brand.fonts
        ? this.brand.fonts[this.activeFont]?.name
        : 'Nunito'
      return font
    },
  },
  async created() {
    window.scroll(0, 0)
    try {
      this.loading = true
      const data = await organizationAPI.fetchBrand(this.companyUrl)
      const brandData = data.data
      this.brand = { ...brandData }
      this.demoImageSrc = this.brand.logos[0].formats[0].src
      this.loading = false
    } catch (err) {
      this.loading = false
    }
  },
  methods: {
    onSelectColor(idx) {
      this.activeColor = idx
      this.selectedColor = this.brand.colors[idx]?.hex
    },

    onSelectLogo(logo: string, idx: number) {
      this.activeLogo = idx
      this.selectedLogo = logo
      this.demoImageSrc = logo
    },

    onSelectFont(idx) {
      this.activeFont = idx
      this.selectedFont = this.brand.fonts[idx]?.name
    },

    onShowUploadModal() {
      this.showUploadModal = !this.showUploadModal
    },

    onCloseUploadModal() {
      this.showModal = false
    },

    onSkipBranding() {
      this.onOrgSetupComplete()
    },

    async onCompleteBrandConfig() {
      var isBrandConfigValid = this.validateBrandConfig()

      if (isBrandConfigValid) {
        if (!this.globalTheme) return
        const theme = this.config.THEME
        const topNav_Config = this.config.TOP_NAV_CONFIG
        const activeColor = this.brand.colors?.length
          ? this.brand.colors[this.activeColor]
          : null
        const activeFont = this.brand.fonts?.length
          ? this.brand.fonts[this.activeFont]
          : null

        const activeLogo = this.brand.logos?.length
          ? this.brand.logos[this.activeLogo]
          : null

        const selectedConfigs = { activeColor, activeFont, activeLogo }
        if (_.some(selectedConfigs, (value) => value === null)) return

        try {
          this.loading = true
          const colorBasic = {
            ...this.globalTheme.Colors.BASIC,
            ['500']: activeColor.hex,
          }
          const updatedBasicColors = {
            ...this.globalTheme.Colors,
            BASIC: colorBasic,
          }

          const themeConfig = {
            key: 'THEME',
            table: 'organizationConfigs',
            value: {
              ...theme,
              logo: activeLogo.formats[0].src,
            },
          }
          const globalThemeConfig = {
            key: 'GLOBAL_THEME',
            table: 'organizationConfigs',
            value: {
              ...this.globalTheme,
              mode: 'LIGHT',
              Colors: updatedBasicColors,
              FontFamily: { DEFAULT: activeFont.name },
            },
          }

          const topNavConfig = {
            key: 'TOP_NAV_CONFIG',
            table: 'organizationConfigs',
            value: {
              ...topNav_Config,
              marketplace: {
                ...topNav_Config.marketplace,
                exit: false,
                showScheduleDemoBtn: true,
              },
              launch: {
                ...topNav_Config.launch,
                showScheduleDemoBtn: true,
              },
            },
          }

          const currentStageConfig = {
            key: 'CURRENT_STAGE',
            table: 'organizationConfigs',
            value: 'onboarded',
          }

          const updateThemeConfig = await organizationAPI.upsertFeatureConfig(
            themeConfig,
          )
          const updateGlobalConfig = await organizationAPI.upsertFeatureConfig(
            globalThemeConfig,
          )
          const updateTopNavConfig = await organizationAPI.upsertFeatureConfig(
            topNavConfig,
          )

          const updateCurrentStageConfig =
            await organizationAPI.upsertFeatureConfig(currentStageConfig)

          await Promise.all([
            updateThemeConfig,
            updateGlobalConfig,
            updateTopNavConfig,
            updateCurrentStageConfig,
          ])
          this.onOrgSetupComplete()
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$message.error('Could not update configurations')
        }
      } else {
        this.$message.error(this.brandConfigErrors.msg)
      }
    },

    onCompleteUpload(image) {
      const selectedLogo = image[0]
      if (!selectedLogo?.trim()) return
      const logos = this.brand.logos || []
      const newLogoValue = {
        type: 'icon',
        theme: null,
        formats: [
          {
            src: selectedLogo,
          },
        ],
      }
      const updatedLogoArr = [...logos, { ...newLogoValue }]
      const newBrand = { ...this.brand, logos: updatedLogoArr }
      this.brand = newBrand
      this.demoImageSrc = this.brand.logos[this.activeLogo].formats[0].src
      this.showUploadModal = false
    },

    onSelectNewFont(selectedFont: String) {
      if (!selectedFont) return
      const fonts = this.brand.fonts || []
      const updatedFontsArr = [...fonts, { name: selectedFont }]
      const newBrand = { ...this.brand, fonts: updatedFontsArr }
      this.brand = newBrand
    },

    onSelectNewColor(selectedColor: String) {
      const colors = this.brand.colors || []
      const newColorValue = {
        hex: selectedColor,
        type: 'accent',
      }
      const updatedColorArr = [...colors, { ...newColorValue }]
      const newBrand = { ...this.brand, colors: updatedColorArr }
      this.brand = newBrand
    },

    organizationToggle() {
      this.$router.push({
        path: '/onboarding/information',
      })
    },

    profileToggle() {
      this.$router.push({
        path: `/onboarding/brand-config?site=${this.companyUrl}`,
      })
    },

    editorToggle() {
      this.onOrgSetupComplete()
    },

    onBreadCrumbSelectionChange(eventData) {
      if (eventData && eventData.action) {
        eventData.action()
        this.currentMenuId = eventData.id
      }
    },

    async onOrgSetupComplete() {
      try {
        this.loading = true
        const trialMode = {
          key: 'TRIAL_MODE',
          table: 'organizationConfigs',
          value: true,
        }

        const enableGridBuilderConfig = {
          key: 'GRID_BUILDER_ENABLED',
          table: 'organizationConfigs',
          value: true,
        }

        const updateTrailModeConfig = await organizationAPI.upsertFeatureConfig(
          trialMode,
        )

        const updateEnableGridBuilderConfig =
          await organizationAPI.upsertFeatureConfig(enableGridBuilderConfig)

        await Promise.all([
          updateTrailModeConfig,
          updateEnableGridBuilderConfig,
        ])

        const origin = window.location.origin
        const fullPath = getFullUrlPath()

        const { email, orgID, projectID, userID, organization } = this.admin
        window.FM.associateVisitor(email)
        window.FM.trackCustomEvent('admin dashboard completed', {
          email: email,
          userID: userID,
          orgID: orgID,
          projectID: projectID,
          companyName: organization.name,
          companyWebsite: organization.onboardSurveyAnswers.website,
          industry: organization.onboardSurveyAnswers.industry,
          category: organization.onboardSurveyAnswers.category,
          checkoutEnabled: organization.onboardSurveyAnswers.checkoutEnabled,
          multipleAccEnabled:
            organization.onboardSurveyAnswers.multipleAccEnabled,
          popupsEnabled: organization.onboardSurveyAnswers.popupsEnabled,
          url: fullPath,
        })
        this.loading = false
        window.location.href = `${origin}/api/auth/login?orgID=${orgID}&projectID=${projectID}`
      } catch (err) {
        this.loading = false
        // no op
      }
    },

    validateBrandConfig(): boolean {
      var brandConfigValidationStatus: boolean = true

      if (!this.selectedLogo) {
        this.brandConfigErrors.msg = 'Select a logo'
        brandConfigValidationStatus = false
      } else if (!this.selectedColor) {
        this.brandConfigErrors.msg = 'Select a color'
        brandConfigValidationStatus = false
      } else if (!this.selectedFont) {
        this.brandConfigErrors.msg = 'Select a font'
        brandConfigValidationStatus = false
      }

      this.brandConfigErrors.brandConfigValid = brandConfigValidationStatus
      return brandConfigValidationStatus
    },
  },
})
