<!-- http://fontawesome.io/examples/ -->

<template>
  <span>
    <i :class="classes" />
    <span
      v-show="spin || pulse"
      class="sr-only"
      v-text="animationFallBack"
    />
  </span>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Icon',
  props: {
    animationFallBack: { type: String, default: 'Loading...' },
    border: { type: Boolean },
    flip: { type: Boolean },
    fw: { type: Boolean },
    icon: { type: String, required: true },
    li: { type: Boolean },
    pullLeft: { type: Boolean },
    pullRight: { type: Boolean },
    pulse: { type: Boolean },
    rotate: { type: Boolean },
    set: { type: String, default: 'fa' },
    size: { type: String, default: '' },
    spin: { type: Boolean }
  },
  computed: {
    classes () {
      return {
        'fa-li': this.li,
        'fa-fw': this.fw,
        ['fa-' + this.size]: this.size,
        'fa-border': this.border,
        'fa-pull-right': this.pullRight,
        'fa-pull-left': this.pullLeft,
        'fa-spin': this.spin,
        'fa-pulse': this.pulse,
        [this.set]: true,
        [`fa-${this.icon}`]: true,
        [`fa-flip-${this.flip}`]: this.flip,
        [`fa-rotate-${this.rotate}`]: this.rotate
      }
    }
  }
}
</script>

<style lang="scss">
  // $fa-font-path: '/fonts';
  // @import "font-awesome/scss/font-awesome";
</style>
