
<div class="set-up-custom-host-wrap">
  <div class="heading-n-desc-wrap">
    <div class="main-heading-wrap">
      <h3 class="main-heading">Setup custom host name</h3>
    </div>

    <div class="main-desc-wrap">
      <p class="main-desc">
        These steps require access to your DNS provider. If you do not have
        access to the DNS manager, we offer to invite other team members to
        help you with the next steps.
      </p>
    </div>
  </div>

  <div class="btns-wrap">
    <cvt-button
      class="ib-have-dns-access-btn"
      :color="'light'"
      :mode="'LIGHT'"
      :size="'xl'"
      :text="'I have access to DNS'"
      :special-padding="'47px 40px 47px 40px'"
      @click="navigateToNamedRoute(cvtRouteNodes.enterpriseIntegrateBuilderDnsAccess)"
    >
    </cvt-button>

    <cvt-button
      class="ib-invite-it-btn"
      :color="'light'"
      :mode="'LIGHT'"
      :size="'xl'"
      :text="'Invite IT'"
      :special-padding="'47px 40px 47px 40px'"
      @click="navigateToNamedRoute(cvtRouteNodes.enterpriseIntegrateBuilderInviteIt)"
    >
    </cvt-button>
  </div>
</div>
