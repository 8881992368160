
<div class="h-100" style="overflow: auto">
  <div class="gen_ai_variable_wrap">
    <div class="mb-4">
      <strong>GenAI Variables</strong>
    </div>

    <cvt-schema-container :nodes="nodes" @selectNode="onSelectNode" />
  </div>
</div>
