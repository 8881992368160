<template>
  <div>
    <h1>Select</h1>
    <div class="w-100">
      <cvt-select
        v-for="(variant, index) in variants"
        :key="'select-' + index"
        :value="value"
        :label="variant.label"
        :help-text="variant.helpText"
        :multiple="variant.multiple"
        :mode="variant.mode"
        :select-disabled="variant.selectDisabled"
        :floating-label="variant.floatingLabel"
      >
        <option
          v-for="(item, i) in records"
          :key="i"
          :value="item.value"
          v-text="item.label"
        />
      </cvt-select>

      <cvt-select
        :value="value"
        label="Select with Custom Template"
        help-text="Choose your framework of choice"
        :multiple="false"
        mode="dropdown"
      >
        <optgroup label="Libraries">
          <option
            value="fuze-js"
            label="Fuse.js"
          >
            <span style="float:left;">Fuze.js</span>
            <span style="float:right;">fuse-js</span>
          </option>
          <option
            value="lit-element"
            label="litElement"
          >
            <span style="float:left;">litElement</span>
            <span style="float:right;">lit-element</span>
          </option>
          <option
            value="socket-io"
            label="Socket.io"
          >
            <span style="float:left;">Socket.io</span>
            <span style="float:right;">socket-io</span>
          </option>
        </optgroup>
      </cvt-select>

      <cvt-select
        :value="value"
        label="Select with Remote Search"
        help-text="Choose your framework of choice"
        mode="tags"
        :multiple="true"
        :fetch-suggestions="fetchSuggestions"
        :floating-label="false"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'PlaySelect',
  components: {
  },
  data () {
    return {
      value: '',
      records: [
        {
          value: 'react-js',
          label: 'React.js'
        },
        {
          value: 'vue',
          label: 'Vue.js'
        },
        {
          value: 'svelte',
          label: 'Svelte'
        },
        {
          value: 'angular',
          label: 'Angular'
        },
        {
          value: 'jquery',
          label: 'jQuery'
        },
        {
          value: 'prototype',
          label: 'Prototype'
        },
        {
          value: 'ember-js',
          label: 'Ember.js'
        },
        {
          value: 'angular-js',
          label: 'Angular.js'
        },
        {
          value: 'preact',
          label: 'Preact'
        },
        {
          value: 'polymer',
          label: 'Polymer'
        },
        {
          value: 'qooxdoo',
          label: 'qooxdoo'
        },
        {
          value: 'webix',
          label: 'Webix'
        },
        {
          value: 'knockout',
          label: 'Knockout'
        },
        {
          value: 'wakanda',
          label: 'Wakanda'
        },
        {
          value: 'backbone-js',
          label: 'Backbone.js'
        }
      ],
      variants: [
        // Default Select.
        {
          label: 'Framework (Default Select)',
          helpText: 'Choose your framework of choice',
          multiple: false,
          mode: 'dropdown',
          selectDisabled: false,
          floatingLabel: false
        },
        // Default Select with Floating Label.
        {
          label: 'Framework (Select with Floating Label)',
          helpText: 'Choose your framework of choice',
          multiple: false,
          mode: 'dropdown',
          selectDisabled: false,
          floatingLabel: true
        },
        // Multiple Select.
        {
          label: 'Framework (Multiple Select)',
          helpText: 'Choose your framework of choice',
          multiple: true,
          mode: 'dropdown',
          selectDisabled: false,
          floatingLabel: false
        },
        // Select Disabled.
        {
          label: 'Framework (Select Disabled)',
          helpText: 'Choose your framework of choice',
          multiple: false,
          mode: 'dropdown',
          selectDisabled: true,
          floatingLabel: false
        },
        // Select with Tags.
        {
          label: 'Framework (Select with Tabs)',
          helpText: 'Choose your framework of choice',
          multiple: true,
          mode: 'tags',
          selectDisabled: false,
          floatingLabel: false
        }
      ]
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    fetchSuggestions (term, cb) {
      clearTimeout(this.timeout)
      let results = term
        ? this.records.filter((item) => {
          return item.value.toLowerCase().indexOf(term.toLowerCase()) === 0
        })
        : this.records
      this.timeout = setTimeout(() => {
        clearTimeout(this.timeout)
        cb(results)
      }, 1000 * Math.random())
    }
  }
}
</script>
<style lang="scss">
</style>
