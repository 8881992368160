import color from 'color'

export default {
  props: {
    pageConfig: {
      type: Object,
      required: true,
    },
  },
  computed: {
		buttonFontColorBasedOnBackground() {
			let buttonBackgroundColor = color(this.theme.buttonTypography.button.defaultBackgroundColor)

			if (buttonBackgroundColor.lightness() >= 60) {
				return color('black').hex()
			}

			if (buttonBackgroundColor.lightness() < 60) {
				return color('white').hex()
			}

      return null
    },
    styles() {
      return {
        h1: {
          fontFamily: `${this.theme.typography.title.font}`,
          fontSize: `${this.theme.typography.title.size}px`,
          fontWeight: `${this.theme.typography.title.weight}`,
          color: `${this.theme.typography.title.defaultTextColor}`,
        },
        h2: {
          fontFamily: `${this.theme.typography.subtitle.font}`,
          fontSize: `${this.theme.typography.subtitle.size}px`,
          fontWeight: `${this.theme.typography.subtitle.weight}`,
          color: `${this.theme.typography.subtitle.defaultTextColor}`,
        },
        h3: {
          fontFamily: `${this.theme.typography.heading.font}`,
          fontSize: `${this.theme.typography.heading.size}px`,
          fontWeight: `${this.theme.typography.heading.weight}`,
          color: `${this.theme.typography.heading.defaultTextColor}`,
        },
        h4: {
          fontFamily: `${this.theme.typography.alternativeHeading.font}`,
          fontSize: `${this.theme.typography.alternativeHeading.size}px`,
          fontWeight: `${this.theme.typography.alternativeHeading.weight}`,
          color:`${this.theme.typography.alternativeHeading.defaultTextColor}`,
        },
        h5: {
          fontFamily: `${this.theme.typography.lead.font}`,
          fontSize: `${this.theme.typography.lead.size}px`,
          fontWeight: `${this.theme.typography.lead.weight}`,
          color: `${this.theme.typography.lead.defaultTextColor}`,
        },
        p: {
          fontFamily: `${this.theme.typography.normalText.font}`,
          fontSize: `${this.theme.typography.normalText.size}px`,
          fontWeight: `${this.theme.typography.normalText.weight}`,
          color: `${this.theme.typography.normalText.defaultTextColor}`,
        },
        btn: {
					fontFamily: `${this.theme.buttonTypography.button.fontFamily}`,
					fontSize: `${this.theme.buttonTypography.button.fontSize}px`,
					fontWeight: `${this.theme.buttonTypography.button.fontWeight}`,
					color: `${this.buttonFontColorBasedOnBackground}`,
					backgroundColor: `${this.theme.buttonTypography.button.defaultBackgroundColor}`,
					border: `1px solid ${this.theme.buttonTypography.button.defaultBackgroundColor}`,
				},
      }
    },
  },
  methods: {
    self() {
      return this.pageConfig.dbRef
    },
  },
}
