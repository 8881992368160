<template>
  <div>
    <org-header></org-header>
    <cvt-enterprise-error-page
      @redirectClicked="onRedirectClicked"
    ></cvt-enterprise-error-page>
    <signup-footer></signup-footer>
  </div>
</template>

<script>
import SignupFooter from './signupFooter/indexSignupFooter.vue'
import OrgHeader from './layouts/Header/Header.vue'
import { routePaths } from '@/js/utils/routes'

export default {
  components: { OrgHeader, SignupFooter, },
  props: {},
  data() {
    return {}
  },
  methods: {
    onRedirectClicked: function () {
      this.$router.push({ path: routePaths.signUp })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
