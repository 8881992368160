
<div
  v-show="open"
  class="right-click-menu"
  :style="style"
  @mouseover.stop.prevent="highlightTargetedElement"
>
  <div
    v-for="(action, idx) in actions"
    :key="action.label + idx"
    class="right-click-menu__option"
    @click.stop.prevent="execute(action)"
  >
    <i class="material-icons" v-text="action.icon" />
    <span class="right-click-label-shortcut-wrap">
      <span class="right-click-label">
        {{ action.label }}
      </span>

      <span v-if="isWindows || isLinux" :id="'right-click-shortcut-key-win'+ idx" class="right-click-shortcut-key">
        <span>{{ action.shortcutKeyWin }}</span>
        <cvt-tooltip ref="right-click-shortcut-key-win" :target="'right-click-shortcut-key-win'+ idx"> {{ action.shortcutKeyWinTooltip }}</cvt-tooltip>
      </span>

      <span v-if="isMac" :id="'right-click-shortcut-key-mac'+ idx" class="right-click-shortcut-key">
        <span>{{ action.shortcutKeyMac }}</span>
        <cvt-tooltip ref="right-click-shortcut-key-mac" :target="'right-click-shortcut-key-mac'+ idx">{{ action.shortcutKeyMacTooltip }}</cvt-tooltip>
      </span>

    </span>
  </div>
</div>
