import { Organization } from "../../services/api";
import * as getters from '../getters'
import * as mutations from '../mutations'
import * as actions from '../actions'

const organization = new Organization()

export default {
    state: {
        dnsRecord: [],
        customHostnames: []
    },
    getters: {
        [getters.DNS_RECORDS]({ dnsRecord }) {
            return dnsRecord
        },
        [getters.CUSTOM_HOST_NAMES]({ customHostnames }) {
            return customHostnames
        }
    },
    mutations: {
        [mutations.SET_DNS_RECORD](state, dnsRecord) {
            state.dnsRecord = dnsRecord
        },
        [mutations.SET_CUSTOM_HOST_NAMES](state, customHostnames) {
            state.customHostnames = customHostnames
        }
    },
    actions: {
        [actions.FETCH_DNS_RECORD]({ commit }){
            return organization.fetchDnsRecord().then((res) =>{
                commit(mutations.SET_DNS_RECORD, res.data.data)
            })
        },
        [actions.UPDATE_DNS_RECORD]({ commit }, data){
            return organization.createDnsRecord(data).then((res) =>{
                commit(mutations.SET_DNS_RECORD, res.data)
            })
        },
        [actions.LOAD_CUSTOM_HOST_NAMES]({ commit }){
            return organization.loadCustomeHostNames().then((res) =>{
                commit(mutations.SET_CUSTOM_HOST_NAMES, res.data.data)
            })
        },
        [actions.VERIFY_CUSTOM_HOST_NAMES]({ commit }, data){
            return organization.verifyCustomHostnames(data)
        }
    }

}