<template>
  <el-dialog :visible="visible" size="tiny" @open="onOpen" @close="onClose">
    <span slot="title" class="bookmark-block-title">
      {{ $t('element.property.bookmark.block.title') }}
    </span>
    <el-form ref="ruleForm" label-position="top" :model="blockData" :rules="rules" class="demo-ruleForm">
        <el-form-item prop="name">
          <el-input
            v-model="blockData.name"
            class="col-12 mb-2"
            placeholder="Name the bookmark"
            autofocus
          />
        </el-form-item>
      <!-- <el-form-item prop="thumbnail">
        <el-input
          v-model="blockData.thumbnail"
          class="col-12 mb-2"
          placeholder="Url to thumbnail"
        />
      </el-form-item> -->
<!--      <el-form-item prop="tags">-->
<!--        <el-select-->
<!--          v-model="blockData.tags"-->
<!--          class="col-12"-->
<!--          multiple-->
<!--          filterable-->
<!--          allow-create-->
<!--          placeholder="Apply block tags"-->
<!--        >-->
<!--          <el-option v-for="tag in tags" :key="tag" :label="tag" :value="tag" />-->
<!--        </el-select>-->
<!--      </el-form-item>-->
    </el-form>

    <div slot="footer" class="dialog-footer col-12">
      <div class="row justify-content-end">
        <el-button :disabled="isSaving" @click="close">{{
          $t('element.property.cta.cancel')
        }}</el-button>
        <el-button type="primary" :loading="isSaving" :disabled="false" @click="submitForm">{{
          $t('element.property.cta.confirm')
        }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import tags from '../../lib/tags'
import * as getters from '../../store/modules/getters'
import * as mutations from '../../store/modules/mutations'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import * as _ from "lodash";
import * as actions from "@/js/store/modules/actions";
import { USER_OWNED } from "@/js/common/constants/constants";

const BLOCK_DEFAULT = {
  name: '',
    thumbnail: '',
    tags: [USER_OWNED],
  isGridBuilder: false
}

const validateTag = (rule, value, callback) => {
  if (_.isArray(value) && value.length === 0){
    callback(new Error("At least one tag is required"));
  } else {
    callback();
  }
}

export default {
  data() {
    return {
      blockData: {
        name: '',
        thumbnail: '',
        tags: [],
        isGridBuilder: false
      },
      tags,
      isSaving: false,
      rules: {
        name: [
          { required: true, message: 'Please input Name', trigger: 'blur' },
          { min: 5, max: 40, message: 'Length should be 3 to 40', trigger: 'blur' }
        ],
        tags: [
          { validator: validateTag, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      theme: ({ theme }) => theme,
    }),
    ...mapGetters({
      visible: getters.BOOKMARK_BLOCK_VISIBLE,
      selected: getters.SELECTOR_SELECTED,
      config: getters.AUTH_GET_USER_CONFIG,
      isStandalonePopUpActive: getters.STANDALONE_POPUP_ACTIVE,
      cPopupBlock: getters.SDA_POPUP_FIREBASE_DATA,
      isGridBuilder: getters.IS_GRID_BUILDER,
    }),
    themeColors () {
      return {
        ...this.theme.colors,
        ...this.theme.blockColors
      }
    },
  },
  methods: {
    ...mapMutations({
      close: mutations.BOOKMARK_BLOCK_HIDE,
    }),
    ...mapActions({
      saveBookmarkedBlock: actions.BOOKMARK_BLOCK_CONFIRM
    }),
    onClose() {
      this.blockData = Object.assign({},BLOCK_DEFAULT)
      this.close()
    },
    onOpen () {
      let colors = this.selected.internalThemeColors() || {}
      let overlayColors = this.getOverlayColorsFromChildElements(
        (block) => block.data.props.background?.coloroverlay
      ) || {}
      let backgroundShapeColors = this.getOverlayColorsFromChildElements(
        (block) => block.data.props.bgShape?.fillColor
      ) || {}

      this.blockData = Object.assign(
        {},
        BLOCK_DEFAULT, {
          colors: _.merge(colors, overlayColors, backgroundShapeColors)
        }
      )
    },
    async saveBookmark () {
      this.blockData.isGridBuilder = this.isGridBuilder
      try {
        this.isSaving = true
        if (this.isStandalonePopUpActive && this.cPopupBlock) {
          this.blockData.popup = {
            popupWidth: this.cPopupBlock.data?.props?.popupWidth,
            popupHeight: this.cPopupBlock.data?.props?.popupHeight,
            isFullScreen: this.cPopupBlock.data?.props?.isFullScreen,
            closeButtonPosition: this.cPopupBlock.data?.props?.closeButtonPosition,
          }
        }
        await this.saveBookmarkedBlock({
          data: this.blockData,
          path: this.selected.path()
        })
        this.onClose()
      }finally {
        this.isSaving = false
      }
    },
    getOverlayColorsFromChildElements(selectCallback) {
      let colors = {}
      let colorIds = []

      const block = this.selected?.fbNode
      const blockChildren = block?.children || []

      colorIds = _.chain(blockChildren)
        .findAllRecurs(n => selectCallback(n))
        .map(n => selectCallback(n))
        .value()

      if(selectCallback(block)) {
        colorIds.unshift(selectCallback(block))
      }

      colors = _.chain(this.themeColors)
        .pick(colorIds)
        .mapValues(c => ({
          a: c.alpha(),
          hex: c.hex().toLowerCase()
        }))
        .value()

      return colors
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.saveBookmark()
        } else {
          return false;
        }
      });
    },
  },
}
</script>

<style lang="scss">
.bookmark-block-title {
  font-size: 1.5em;
  font-weight: bold;
}
</style>
