
<div class="h-100" style="overflow: auto">
  <div class="ssd-wrapper"> 
    <p class="ssd-leading-wrap">
      <span class="ssd-leading-arrow"><i class="fa fa-arrow-left"></i></span>
      <span class="ssd-leading-text">SuperFunnel Strategy Database</span>
      <span v-if="notionConnected" class="ssd-leading-settings">
        <i class="fa fa-gear"></i>
      </span>
    </p>

    <cvt-button
      class="my-3"
      icon="plus"
      :prepend="true"
      :outlined="true"
      :text="notionConnected ? $t('left.sidebar.ssd.notes.add') : $t('left.sidebar.ssd.notes.connect.notion')"
      :full-width="true"
      @click="onConnectNotion" 
    />

    <div v-if="notionConnected" class="ssd-section-wrap">
      <div style="display: flex; justify-content: center;">
        <cvt-switch
          size="md"
          color="grey"
          type="rounded"
          description-type="text"
          :inactive-text="'Value Proposition'"
          :active-text="'Micro yes(s)'"
          :initial-state="direction == 'tab1'? false: true"
          @switchToggle="onSwitchToggle"
        />
      </div>

      <div
        v-for="(pages, idx) in notionNotes"
        :key="idx"
        class="ssd-section-inner"
        @click="() => {}"
      >
        <p class="ssd-section-head">{{pages.name}}</p>
        <div class="ssd-section-body">
          <div class="ssd-section-notes">
            <div v-if="pages.editMode" class="ssd-section-editable">
              <textarea v-model="pages.notes" class="ssd-section-textarea"></textarea>
            </div>
            <div v-else class="ssd-section-editable">
              <p>{{ pages.notes }}</p>
            </div>
            <p class='ssd-section-copy' @mouseleave="() => handleCopyBlur(idx)">
              <i 
                v-if="!pages.copied"
                style="cursor: pointer;font-size: 16px;"
                class="fa fa-copy"
                @click="() => onCopyNote(idx)"
              ></i>
              <span v-if="pages.copied" class='ssd-copied-text'>
                <i style="font-size: 14px;" class="fa fa-check"></i>
              </span>
            </p>
          </div>
          <hr />
          <cvt-button
            class="my-3"
            :icon="pages.editMode ? 'save' : 'cvt-icon-pencil'"
            :prepend="true"
            :outlined="true"
            :text="pages.editMode ? 'Update' : 'Edit'"
            :full-width="true"
            @click="() => onToggleEditMode(idx)" 
          />
        </div>
      </div>
    </div>
  </div>
</div>
