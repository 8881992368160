
<div class="create-new-custom-field">
  <button
    class="text-capitalize btn btn-outline-secondary btn-block"
    @click="open"
  >
    {{ $t('element.property.form.option.create_list') }}
  </button>

  <cvt-dialog
    :show="dialogVisible"
    :height-auto="true"
    size="sm"
    @close="handleClose"
  >
    <template #title>
      <h5>{{ $t('element.property.form.field.custom.list.attr') }}</h5>
    </template>
    <div class="form-group">
      <label for="new-contact-attr-name">{{
        $t('element.properties.section.title')
      }}</label>
      <cvt-input
        type="text"
        :value="attributes.listName || ''"
        :label="$t('element.property.form.field.custom.list.name')"
        :placeholder="$t('element.property.form.field.custom.list.name')"
        @input="(e) => updateAttributeName(e)"
      />
      <small>{{ $t('element.property.form.field.custom.list.help') }}</small>
    </div>

    <template #modalFooter>
      <span class="dialog-footer d-flex">
        <cvt-button
          class="mr-1"
          color="light"
          :text="$t('element.property.cta.cancel')"
          @click="reset"
        />
        <cvt-button
          :text="$t('element.property.cta.confirm')"
          @click="confirm"
        />
      </span>
    </template>
  </cvt-dialog>
</div>
