
<div class="marketplace-wrapper" :class="marketplaceLayout">
  <template v-if="!isTabsUiEnabled">
    <main-nav
      v-if="!marketplaceHeaderDisabled"
      :title="marketplaceHeaderTitleDisabled ? '' : marketplaceHeaderTitle"
    />
    <!-- <steps-nav :tabs="false" :controls="false" :breadcrumb="false"></steps-nav> -->
    <page-preview
      :title="previewTitle"
      :is-template="true"
      :is-first="isFirstTemplate"
      :is-last="isLastTemplate"
      :preview-cta="previewCta"
      @prev="prevTemplate"
      @next="nextTemplate"
      @use-template="useTemplate(previewTemplatId)"
    />
    <div class="content-nav">
      <cvt-left-nav-bar
        usage="landingPage"
        :type="leftNavType"
        :navigation-items="filteredCategories"
        :initial-active-id="null"
        :counts="templatesCount"
        :show-count="ShowCount"
        :align-tab-to-bottom="certifiedTemplatesMarketplaceUI"
        @navItemClicked="onSelectCategory"
      />
    </div>
    <div
      class="content"
      :class="{ 'content-with-header': activeCategory === yourTemplates.id }"
    >
      <div class="content-header">
        <cvt-search-filter
          v-if="activeCategory == yourTemplates.id"
          class="cvt-search-style mt-2"
          :mode="mode"
          :initial-templates-view-format-val="templatesDefaultViewFormat"
          :search-bar-bg-config="searchBarBgConfig"
          :display-template-view-format-btn="
            isTemplateActionsRightSidebarEnabled
          "
          :default-search="searchQuery"
          @searchDone="onSearchDone"
          @sortParameter="onSortParameter"
          @templatesViewToggled="onTemplatesViewToggled"
        />
      </div>

      <div class="content-wrap main-wrapper" style="flex-direction: column">
        <div v-if="displayAIBtn" class="landing-page-ai-btn-wrapper">
          <div class="landing-page-ai-btn-inner-wrapper">
            <cvt-button
              shape="round"
              color="dark"
              icon="magic"
              :text="$t('gen_ai.marketplace.btn.text')"
              :full-width="true"
              :prepend="false"
              @click="goToLandingPageDescription"
            ></cvt-button>
          </div>
        </div>
        <div v-if="brandingActive">
          <marketplace-branding />
        </div>
        <div v-if="genAiTemplatesActive" style="display: flex">
          <cvt-select
            class="col-4"
            mode="dropdown"
            :value="intentList"
            label="Select Intent"
            :theme="mode"
            :multiple="true"
            :background-mode="mode"
            @input="(value) => setGenAISelectedIntents(value)"
          >
            <option
              v-for="item in intentList"
              :key="item.value"
              :value="item.value"
              v-text="item.label"
            ></option>
          </cvt-select>

          <cvt-select
            class="col-4"
            mode="dropdown"
            :value="''"
            label="Select Intent Goal"
            :theme="mode"
            :multiple="true"
            :background-mode="mode"
            @input="(value) => setGenAISelectedIntentGoals(value)"
          >
            <optgroup
              v-for="intentObj of intentList"
              :key="intentObj.value"
              :label="intentObj.label"
            >
              <option
                v-for="intentGoal in intentObj.goals"
                :key="intentGoal.value"
                :value="intentGoal.value"
                v-text="intentGoal.label"
              ></option>
            </optgroup>
          </cvt-select>

          <div class="align-content-end">
            <cvt-button
              :mode="mode"
              style="height: 42px"
              :text="'Search'"
              :color="''"
              :loading="false"
              :shadow="true"
              :disabled="disableSortGenAiTemplates"
              @click="(event) => onSortGenAiTemplates(event)"
            />
          </div>
        </div>
        <main v-if="!brandingActive" class="marketplace-template-groups-alt">
          <cvt-modal
            v-if="showScheduleDemoModal"
            :modal-header="$t('marketplace.demo.modal.header.text')"
            :text-content="$t('marketplace.demo.modal.content.text')"
            :btn-text="$t('marketplace.demo.modal.button')"
            :modal-width="500"
            :btn-on-click="closeDemoModal"
          />

          <div v-if="templatesDefaultViewFormat === 'GRID'">
            <div v-if="certifiedTemplatesMarketplaceUI && subTabIsActive">
              <div class="marketplace-tag-container">
                <cvt-marketplace-tag :img-path="getAltLogo" />
              </div>
              <div class="sub-tab-wrapper">
                <cvt-tabs
                  :pills="true"
                  :keep-inactive-border="true"
                  :mode="''"
                  :prefix-icon="''"
                  :align-tabs="'center'"
                  :tabs-config="tabsConfig"
                  @activateTabModified="onTabCategoryClick"
                >
                  <cvt-tab
                    v-for="category in subTabCategories"
                    :key="category.id"
                    :title="category.id == null ? 'All' : category.label"
                    :tab-id="Number(category.index).toString()"
                  >
                    <div :class="'marketplace-templates'">
                      <div
                        v-if="isStartFromScratchCardEnabled"
                        key="startFromScratch"
                        :class="getStartFromScratchClass()"
                      >
                        <cvt-marketplace-card
                          id="mpCard"
                          usage="startFromScratch"
                          :mode="mode"
                          :hover="StartFromScratchHover"
                          :tabs-ui="true"
                          :get-started-button="
                            marketplaceConfig?.marketplaceCard
                              ?.getStartedButton
                          "
                          @startFromScratch="startFromScratch"
                        >
                          <template #cardTitle>{{
                            $t('marketplace.templates.label.scratch')
                          }}</template>
                        </cvt-marketplace-card>
                      </div>
                      <div
                        v-for="(template, $index) in filteredTemplates"
                        :key="`${$index}-${template.id}`"
                      >
                        <cvt-marketplace-card
                          :id="'mpCard' + $index"
                          v-intersected-once="{
                            onIntersected: loadMoreTemplates(),
                            shouldObserve:
                              filteredTemplates.length - 1 === $index,
                          }"
                          :mode="mode"
                          :thumbnail="template.thumbnail"
                          :get-started-button="
                            marketplaceConfig.marketplaceCard.getStartedButton
                          "
                          :preview-button="
                            marketplaceConfig.marketplaceCard.previewButton
                          "
                          @copyTemplate="showPreviewWindow(template.id)"
                          @getStarted="useTemplate(template.id)"
                          @preview="showPreviewWindow(template.id)"
                        >
                          <template #cardTitle>
                            {{
                              template.name || $t('site.input.default_value')
                            }}
                          </template>
                        </cvt-marketplace-card>
                      </div>

                      <template v-for="number in 6">
                        <div v-if="loading" :key="number">
                          <placeholder-card :mode="mode" />
                        </div>
                      </template>
                    </div>
                  </cvt-tab>
                </cvt-tabs>
              </div>
            </div>
            <div
              v-if="showVersionSwitchAlert"
              id="builderV2VersionSwitchAlert"
              class="version-switch-alert-container"
            >
              <cvt-alert-with-button
                :alert-btn-text="
                  $t('marketplace.builder_version_switch_alert.btn_text')
                "
                :alert-message="
                  $t('marketplace.builder_version_switch_alert.label')
                "
                :info-alert-config="versionSwitchAlertConfig"
                alert-width="1000"
                btn-width="350"
                btn-color="primary"
                @exitPreviewMode="switchToBuilderVersion"
              />
            </div>

            <div
              v-if="!certifiedTemplatesMarketplaceUI || !subTabIsActive"
              :class="[
                'marketplace-templates',
                activeCategory == yourTemplates.id ? 'your-page-style' : '',
              ]"
            >
              <div
                v-if="
                  !archivedTemplatesActive && isStartFromScratchCardEnabled
                "
                key="startFromScratch"
                :class="getStartFromScratchClass()"
              >
                <cvt-marketplace-card
                  id="mpCard"
                  :mode="mode"
                  :hover="StartFromScratchHover"
                  usage="startFromScratch"
                  :get-started-button="
                    marketplaceConfig.marketplaceCard.getStartedButton
                  "
                  @startFromScratch="startFromScratch"
                >
                  <template #cardTitle>{{
                    $t('marketplace.templates.label.scratch')
                  }}</template>
                </cvt-marketplace-card>
              </div>
              <div
                v-for="(template, $index) in filteredTemplates"
                :key="`${$index}-${template.id}`"
              >
                <cvt-marketplace-card
                  :id="'mpCard' + $index"
                  v-intersected-once="{
                    onIntersected: loadMoreTemplates(),
                    shouldObserve: filteredTemplates.length - 1 === $index,
                  }"
                  :mode="mode"
                  :thumbnail="template.thumbnail"
                  :usage="cardType"
                  :more-actions="moreActions"
                  :is-template-actions-right-sidebar-enabled="
                    isTemplateActionsRightSidebarEnabled
                  "
                  :archive-button="
                    marketplaceConfig.marketplaceCard.archiveButton
                  "
                  :copy-page-button="copyOrEditPageButton"
                  :get-started-button="
                    marketplaceConfig.marketplaceCard.getStartedButton
                  "
                  :preview-button="
                    marketplaceConfig.marketplaceCard.previewButton
                  "
                  :swap-template-button-action="
                    marketplaceConfig.marketplaceCard.swapTemplateButtonAction
                  "
                  @getStarted="useTemplate(template.id)"
                  @preview="showPreviewWindow(template.id)"
                  @editPage="editSite(template)"
                  @copyTemplate="showPreviewWindow(template.id)"
                  @moreActionClicked="
                    (data) => onMoreActionClicked(data, template)
                  "
                  @templateActionsBtnClicked="
                    (event) => showRightSidebar(event, template)
                  "
                  @restoreTemplate="restoreArchivedTemplate(template)"
                >
                  <template #cardTitle>
                    {{ template.name || $t('site.input.default_value') }}
                  </template>
                </cvt-marketplace-card>
              </div>
              <template v-for="number in 6">
                <div v-if="loading" :key="number">
                  <placeholder-card :mode="mode" />
                </div>
              </template>
            </div>
          </div>

          <div v-if="templatesDefaultViewFormat === 'LIST'">
            <div
              :class="[
                'marketplace-templates-item',
                activeCategory == yourTemplates.id
                  ? 'your-page-style-list-item'
                  : '',
              ]"
            >
              <div
                v-for="(template, $index) in filteredTemplates"
                :key="`${$index}-${template.id}`"
              >
                <cvt-marketplace-list-item
                  v-intersected-once="{
                    onIntersected: loadMoreListItems(),
                    shouldObserve: filteredTemplates.length - 1 === $index,
                  }"
                  :mode="mode"
                  :template-title="template.name"
                  :site-status="template.state"
                  @copyTemplateBtnClicked="
                    (event) => copyTemplateBtnClicked(event, template)
                  "
                  @editSiteBtnClicked="
                    (event) => editSiteBtnClicked(event, template)
                  "
                  @archiveTemplateBtnClicked="
                    (event) => archiveTemplateBtnClicked(event, template)
                  "
                  @templateActionsBtnClicked="
                    (event) => showRightSidebar(event, template)
                  "
                >
                </cvt-marketplace-list-item>
              </div>

              <cvt-loading-message message="Loading" :display="loading">
              </cvt-loading-message>
            </div>
          </div>
        </main>
        <cvt-free-trial-footer v-if="showFreeTrial" />
      </div>
    </div>
  </template>

  <!-- archive dialog -->
  <cvt-dialog
    :show="showArchiveDialog"
    :height-auto="true"
    size="sm"
    @close="abortTemplateArchive"
  >
    <template #title>
      <h5>{{ $t('elements.property.choose.template.archive.template') }}</h5>
    </template>
    <cvt-alert class="w-100" color="warning" icon="exclamation-triangle">
      <p>
        {{ $t('marketplace.archiving.alert.label.text') }}
        {{ archiveTemplate && archiveTemplate.name }}.
        {{ $t('marketplace.archiving.alert.body.text') }}
      </p>
    </cvt-alert>
    <template #modalFooter>
      <div class="d-flex">
        <cvt-button
          class="mr-1"
          color="light"
          :text="$t('marketplace.dialog.archive.cta.cancel')"
          @click="abortTemplateArchive"
        />
        <cvt-button
          color="warning"
          :loading="archiving"
          :text="$t('marketplace.dialog.archive.cta.submit')"
          @click="onArchiveTemplate"
        />
      </div>
    </template>
  </cvt-dialog>

  <!-- dialog for domain-unlinking -->
  <cvt-dialog
    :show="unlinkDialog"
    size="sm"
    :height-auto="true"
    @close="unlinkDialog = false"
  >
    <template #title>
      <h5>
        {{ `${$t('custom_domain.ui.unlink_dialog.h5')} ${unlinkDomainName}` }}
      </h5>
    </template>
    <cvt-alert color="warning" icon="exclamation-triangle">
      <p v-if="unlinkExistingDomain">
        {{
          `${$t(
            'custom_domain.ui.unlink_dialog.p.unlink_existing_domain',
          )} ${unlinkDomainName} ${$t(
            'custom_domain.ui.unlink_dialog.p.part_two',
          )}`
        }}
      </p>
      <p v-else>
        {{
          `${$t(
            'custom_domain.ui.unlink_dialog.p.part_one',
          )} ${unlinkDomainName} ${$t(
            'custom_domain.ui.unlink_dialog.p.part_two',
          )}`
        }}
      </p>
    </cvt-alert>

    <template #modalFooter>
      <cvt-button
        color="light"
        :text="$t('custom_domain.ui.unlink_dialog.buttons.cancel')"
        @click="unlinkDialog = false"
      />
      <cvt-button
        :color="popUpBtnColor"
        :text="$t('custom_domain.ui.unlink_dialog.buttons.continue')"
        @click="unlinkDomain"
      />
    </template>
  </cvt-dialog>

  <!-- dialog for apex warning -->
  <cvt-dialog
    :show="apexDialog"
    size="lg"
    :height-auto="true"
    @close="apexDialog = false"
  >
    <template #title>
      <h5>{{ $t('custom_domain.ui.apex_popup.title') }}</h5>
    </template>
    <cvt-alert color="warning" icon="exclamation-triangle">
      <h2>{{ $t('custom_domain.ui.apex_popup.h2.first') }}</h2>
    </cvt-alert>

    <p>
      {{ $t('custom_domain.ui.apex_popup.p1.part_one')
      }}<code>example.com</code>
      {{ $t('custom_domain.ui.apex_popup.p1.part_two')
      }}<code>www.example.com</code>
      {{ $t('custom_domain.ui.apex_popup.p1.part_three')
      }}<code>example.com</code>
      {{ $t('custom_domain.ui.apex_popup.p1.part_four')
      }}<code>www.example.com</code>)
    </p>

    <h2 class="mt-5">{{ $t('custom_domain.ui.apex_popup.h2.second') }}</h2>

    <p>
      {{ $t('custom_domain.ui.apex_popup.p2.part_one') }}
      <a href="https://www.ietf.org/rfc/rfc1034.txt">RFC1034</a>
      {{ $t('custom_domain.ui.apex_popup.p2.part_two') }}
    </p>

    <p>
      {{ $t('custom_domain.ui.apex_popup.p3.part_one') }}<code>www</code
      >{{ $t('custom_domain.ui.apex_popup.p3.part_two') }} (<code
        >example.com</code
      >{{ $t('custom_domain.ui.apex_popup.p3.part_three')
      }}<code>www.example.com</code>).
    </p>
  </cvt-dialog>

  <template v-if="isTabsUiEnabled">
    <page-preview
      :title="previewTitle"
      :is-template="true"
      :is-first="isFirstTemplate"
      :is-last="isLastTemplate"
      :preview-cta="previewCta"
      @prev="prevTemplate"
      @next="nextTemplate"
      @use-template="useTemplate(previewTemplatId)"
    />

    <div v-if="showTopLogo" class="top-logo-container">
      <img :src="topLogo" />
    </div>

    <div class="marketplace-wrapper-tabs-ui">
      <h4 class="choose-temp-heading mb-4">
        {{
          !marketplaceHeaderTitleDisabled
            ? ''
            : $t('editor.header.marketplace.title')
        }}
      </h4>
      <cvt-tabs
        :pills="false"
        :closeable="false"
        :end="false"
        :vertical="false"
        :prefix-icon="''"
        :keep-inactive-border="true"
        :mode="mode"
        :full-horizontal-border="false"
        :smaller-tab-border="false"
        @activateTabModified="onTabCategoryClick"
      >
        <cvt-tab
          v-for="category in filteredCategories"
          :key="category.id"
          :title="category.label"
          :tab-id="Number(category.index).toString()"
        >
          <div v-if="templatesDefaultViewFormat === 'GRID'">
            <div
              :class="[
                'marketplace-templates',
                activeCategory === yourTemplates?.id
                  ? 'your-page-style-tabs-ui'
                  : '',
              ]"
            >
              <cvt-marketplace-search-sort
                v-if="activeCategory === yourTemplates?.id"
                class="marketplace-search-sort"
                :default-search="''"
                @searchDone="onSearchDone"
                @sortParameter="onSortParameter"
              />

              <div
                v-if="
                  !archivedTemplatesActive && isStartFromScratchCardEnabled
                "
                key="startFromScratch"
                :class="getStartFromScratchClass()"
              >
                <cvt-marketplace-card
                  id="mpCard"
                  usage="startFromScratch"
                  :mode="mode"
                  :hover="StartFromScratchHover"
                  :tabs-ui="true"
                  :get-started-button="
                    marketplaceConfig?.marketplaceCard?.getStartedButton
                  "
                  @startFromScratch="startFromScratch"
                >
                  <template #cardTitle>{{
                    $t('marketplace.templates.label.scratch')
                  }}</template>
                </cvt-marketplace-card>
              </div>

              <div
                v-for="(template, $index) in filteredTemplates"
                :key="`${$index}-${template.id}`"
              >
                <cvt-marketplace-card
                  :id="'mpCard' + $index"
                  v-intersected-once="{
                    onIntersected: loadMoreTemplates(),
                    shouldObserve: filteredTemplates.length - 1 === $index,
                  }"
                  :mode="mode"
                  :thumbnail="template.thumbnail"
                  :usage="
                    activeCategory == yourTemplates?.id ? 'myTemplate' : ''
                  "
                  :tabs-ui="true"
                  :more-actions="moreActions"
                  :archive-button="
                    marketplaceConfig.marketplaceCard.archiveButton
                  "
                  :copy-page-button="copyOrEditPageButton"
                  :get-started-button="
                    marketplaceConfig.marketplaceCard.getStartedButton
                  "
                  :preview-button="
                    marketplaceConfig.marketplaceCard.previewButton
                  "
                  :swap-template-button-action="
                    marketplaceConfig.marketplaceCard.swapTemplateButtonAction
                  "
                  @getStarted="useTemplate(template.id)"
                  @preview="showPreviewWindow(template.id)"
                  @editPage="editSite(template)"
                  @copyTemplate="showPreviewWindow(template.id)"
                  @moreActionClicked="
                    (data) => onMoreActionClicked(data, template)
                  "
                  @templateActionsBtnClicked="
                    (event) => showRightSidebar(event, template)
                  "
                  @restoreTemplate="restoreArchivedTemplate(template)"
                >
                  <template #cardTitle>
                    {{ template.name || $t('site.input.default_value') }}
                  </template>
                </cvt-marketplace-card>
              </div>

              <template v-for="number in 6">
                <div v-if="loading" :key="number">
                  <placeholder-card :mode="mode" />
                </div>
              </template>
            </div>
          </div>
          <div v-if="templatesDefaultViewFormat === 'LIST'">
            <div
              :class="[
                'marketplace-templates-item',
                activeCategory == yourTemplates.id
                  ? 'your-page-style-list-item-tabs-ui'
                  : '',
              ]"
            >
              <div
                v-for="(template, $index) in filteredTemplates"
                :key="`${$index}-${template.id}`"
              >
                <cvt-marketplace-list-item
                  v-intersected-once="{
                    onIntersected: loadMoreListItems(),
                    shouldObserve: filteredTemplates.length - 1 === $index,
                  }"
                  :mode="mode"
                  :template-title="template.name"
                  :site-status="template.state"
                  @copyTemplateBtnClicked="
                    (event) => copyTemplateBtnClicked(event, template)
                  "
                  @editSiteBtnClicked="
                    (event) => editSiteBtnClicked(event, template)
                  "
                  @archiveTemplateBtnClicked="
                    (event) => archiveTemplateBtnClicked(event, template)
                  "
                  @templateActionsBtnClicked="
                    (event) => showRightSidebar(event, template)
                  "
                >
                </cvt-marketplace-list-item>
              </div>

              <cvt-loading-message message="Loading" :display="loading">
              </cvt-loading-message>
            </div>
          </div>
        </cvt-tab>
      </cvt-tabs>
    </div>
  </template>

  <cvt-right-sidebar
    v-if="
      isTemplateActionsRightSidebarEnabled &&
      selectedTemplate !== null &&
      activeCategory == yourTemplates.id
    "
    :key="rightSidebarComponentKey"
    :class="rightSidebarClasses"
    :right-sidebar-radio-group-loading="rightSidebarRadioGroupLoading"
    :step-title="selectedTemplate.name"
    :img-src="selectedTemplate.thumbnail"
    :img-alt-text="$t('marketplace.template.image.not_found')"
    :img-width="140"
    :img-height="116"
    :site-label-status="selectedTemplate.state"
    :site-label-width="87"
    :site-label-height="26"
    :radio-group-theme="''"
    :radio-group-orientation="'vertical'"
    :radio-group-size="'md'"
    :radio-group-initial-value="radioGroupInitialValue"
    :site="selectedTemplate"
    :site-url="siteUrl"
    :is-published-url-set="isPublishedUrlSet"
    :is-custom-url-set="isCustomUrlSet"
    :disable-publish="disablePublish"
    @rightSidebarClosed="hideRightSidebar"
    @copyTemplateBtnClicked="
      (event) => copyTemplateBtnClicked(event, selectedTemplate)
    "
    @editSiteBtnClicked="
      (event) => editSiteBtnClicked(event, selectedTemplate)
    "
    @archiveTemplateBtnClicked="
      (event) => archiveTemplateBtnClicked(event, selectedTemplate)
    "
    @dns-unlink="(siteId, domain) => openUnlinkDialog(siteId, domain)"
    @apexDialog="(show) => openApexDialog(show)"
  />
</div>
