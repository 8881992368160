<div v-if="!AltPopupManager">
  <cvt-alert
    ref="uploadAlert"
    v-show="alertMessage.length"
    color="danger"
    icon="exclamation-triangle"
  >
    {{ alertMessage }}
  </cvt-alert>
  <div v-if="imageOnly" class="upload-file-note">
    Only Image files of type JPEG, JPG, PNG, GIF and SVG are allowed to upload
    with maximum size limit of {{ sizeLimit }}mb.
  </div>

  <div
    class="upload-dropzone"
    :class="dragEnabledClass"
    @dragenter.stop.prevent="dragenter"
    @dragover.stop.prevent="dragover"
    @dragleave.stop="dragleave"
    @drop.stop.prevent="dropped"
  >
    <!-- displays when upload is in progress -->
    <div class="upload-queue__container" v-if="showUploadProgressFlow">
      <div
        class="upload__viewonly__image"
        v-if="uploadSuccess && viewOnlyImage"
      >
        <p class="viewonly__image__close">
          <span @click="closePreview()">x</span>
        </p>
        <div class="viewonly__image__container">
          <img :src="viewOnlyImage" class="viewonly__image" alt="" />
        </div>
      </div>

      <div class="upload-queue__group" v-else>
        <div class="row">
          <div class="upload-queue__innergroup" v-for="upload in queue.stack">
            <div class="upload-queue__spinner" :class="stopSpinnerClass">
              <img :src="uploadSpinnerSvg" class="viewonly__image" alt="" />
            </div>

            <div class="upload-queue__progress">
              <cvt-progress
                size="sm"
                :bg-color="uploadProgressColor"
                mode="LIGHT"
                label-pos="top"
                :max-value="100"
                :value-now="+upload.progress.toFixed(2)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end... -->

    <!-- displays when upload is not in progress -->
    <div class="upload-dropzone__dialog" v-else>
      <img :src="uploadIconSvg" class="viewonly__image" alt="" />
      <p class="upload-dropzone__text" v-text="dndMsg"></p>
      <p style="margin: 0">- {{ $t('element.upload.image.label.or') }} -</p>
      <div class="upload-dropzone__button__controls">
        <div class="upload-dropzone__gallery__button">
          <cvt-button
            color="light"
            @click.stop="openImageSearch"
            v-if="!hideGalleryButton"
            text="Pick an image from the gallery"
            fullWidth
            outlined
            customFontWeight="500"
          />
        </div>
        <div>
          <cvt-button
            @click.stop="openFileDialog"
            :text="btnLabel"
            :specialStyle="isSpecialStyle"
            fullWidth
            customFontWeight="500"
          />
        </div>
        <input
          type="file"
          :accept="acceptUploadTypes"
          name="file"
          class="el-upload__input"
          :multiple="multi"
          @change="filesSelected"
        />
      </div>
    </div>
  </div>
</div>

<!-- Alternate uploader -->
<div v-else class="alt-upload-manager">
  <cvt-alert
    ref="uploadAlert"
    v-show="alertMessage.length"
    color="danger"
    icon="exclamation-triangle"
  >
    {{ alertMessage }}
  </cvt-alert>
  <div v-if="imageOnly" class="upload-file-note">
    Only Image files of type JPEG, JPG, PNG, GIF and SVG are allowed to upload
    with maximum size limit of {{ sizeLimit }}mb.
  </div>

  <div
    class="upload-dropzone"
    :class="dragEnabledClass"
    @dragenter.stop.prevent="dragenter"
    @dragover.stop.prevent="dragover"
    @dragleave.stop="dragleave"
    @drop.stop.prevent="dropped"
  >
    <!-- displays when upload is in progress -->
    <div class="upload-queue__container" v-if="showUploadProgressFlow">
      <div
        class="upload__viewonly__image"
        v-if="uploadSuccess && viewOnlyImage"
      >
        <p class="viewonly__image__close">
          <span @click="closePreview()">x</span>
        </p>
        <div class="viewonly__image__container">
          <img :src="viewOnlyImage" class="viewonly__image" alt="" />
        </div>
      </div>

      <div class="upload-queue__group" v-else>
        <div class="row">
          <div class="upload-queue__innergroup" v-for="upload in queue.stack">
            <div class="upload-queue__spinner" :class="stopSpinnerClass">
              <img :src="uploadSpinnerSvg" class="viewonly__image" alt="" />
            </div>

            <div class="upload-queue__progress">
              <cvt-progress
                size="sm"
                :bg-color="uploadProgressColor"
                mode="LIGHT"
                label-pos="top"
                :max-value="100"
                :value-now="+upload.progress.toFixed(2)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end... -->

    <div class="upload-dropzone__dialog" v-else>
      <img :src="uploadIconSvg" class="viewonly__image" alt="" />
      <p class="upload-dropzone__text" v-text="dndMsg"></p>
      <p>- {{ $t('element.upload.image.label.or') }} -</p>
      <div
        class="upload-dropzone__dialog__actions d-flex justify-content-between"
      ></div>
      <cvt-button
        color="light"
        @click.stop="openFileDialog"
        :text="btnLabel"
        outlined
        customFontWeight="500"
      />
      <cvt-button
        class="my-3"
        color="link"
        @click.stop="openImageSearch"
        v-if="!hideGalleryButton"
        text="Pick an image from the gallery"
        customFontWeight="500"
      />
      <input
        type="file"
        :accept="acceptUploadTypes"
        name="file"
        class="el-upload__input"
        :multiple="multi"
        @change="filesSelected"
      />
    </div>
  </div>
</div>
