import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import Vue from '../../../lib/utils/vue'
import { CvtIcon } from '../icon/icon'
import CvtButton from '../Button'
import DropdownMenu from './DropdownMenu'
import { mapState } from '../../../../builder/node_modules/vuex'
Vue.use(VueEmotion)

let Radii

const getInputGroupDiv = () => {
  return styled('div')`
    position: relative;
    width: auto;
    height: auto;
    border-radius: ${Radii.RADIUS_PX_8}px;
    &:hover {
      cursor: pointer;
    }
  `
}

let uuid = 0
export default {
  name: 'CvtDropdown',
  components: {
    CvtIcon
  },
  props: {
    value: {
      type: [String, Array]
    },
    label: String,
    multiple: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'dropdown'
    },
    selectDisabled: {
      type: Boolean,
      default: false
    },
    onHoverTriggerMode: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: ''
    },
    fetchSuggestions: Function,
    clickAction: {
      type: Boolean,
      default: false
    },
    //This props is for title for clickAction mode only.
    title: {
      type: String,
      default: ''
    },
    specialStyle: {
      type: String,
      default: ''
    },
    showButtonLabel: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    actionButton: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    btnState:{
      type: Object,
      default: () => {}
    },
    btnTextChange: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      options: [],
      filtered: [],
      open: false,
      term: '',
      loading: false,
      inputFocused: false,
      dropUp: false,
      closeDropdown: true
    }
  },
  beforeCreate() {
    this.uuid = `CvtSelect:${uuid}`
    uuid = uuid + 1
  },
  beforeMount() {
    this.options = this.getOptions()
    this.filtered = this.options.slice()
  },
  mounted() {
    let dropdownButton = this.$refs.dropdown.$el
    let selectDomRectBottom = dropdownButton.getBoundingClientRect().bottom
    if (selectDomRectBottom > window.innerHeight / 2) {
      this.dropUp = true
    } else {
      this.dropUp = false
    }

    window.addEventListener('scroll', () => {
      selectDomRectBottom = dropdownButton.getBoundingClientRect().bottom
      if (selectDomRectBottom > window.innerHeight / 2) {
        this.dropUp = true
      } else {
        this.dropUp = false
      }
    })

    this.selectedFromValue(this.value)
    this.options = this.getOptions()
    this.filtered = this.options.slice()
    if (dropdownButton) {
      dropdownButton.addEventListener('click', this.stopPropagation)
      window.addEventListener('click', this.onClickOutside)
    }
  },

  beforeDestroy() {
    let dropdownButton = this.$refs.dropdown.$el
    if (dropdownButton) {
      dropdownButton.removeEventListener('click', this.stopPropagation)
      window.addEventListener('click', this.onClickOutside)
    }
  },
  watch: {
    value(value) {
      this.selectedFromValue(value)
    },
    term(value) {
      this.search(value)
    }
  },
  computed: {
    ...mapState('globalTheme', {
      Radii: ({ globalTheme }) => globalTheme.Radii,
    }),
    selectedLabel() {
      if (!this.clickAction) {
        if (this.selected.length > 0) {
          return this.selected.map((i) => i.label).join(', ')
        } else {
          return 'Please select'
        }
      } else {
        return this.title
      }
      // }
    },
    showSuggestions() {
      return this.loading || (this.open && this.filtered.length > 0)
    }
  },
  methods: {
    getOptions() {
      const formatOptions = (item, group) => {
        if (item.tag === 'option') {
          let out = {
            group,
            value: item?.data?.domProps?.value || item.data?.attrs?.value,
            content: item?.children,
            label:
              item?.data?.domProps?.textContent ||
              item?.data?.attrs?.label ||
              item?.children?.text,
            icon: item?.data?.attrs?.icon
          }
          if (!out.label) {
            out.label = out.value
          }
          return out
        }
      }
      if (this.$slots.default) {
        let group = undefined
        let v = this.$slots.default
          .flatMap((item) => {
            if (item.tag === 'option') {
              return formatOptions(item, group)
            } else if (item.tag === 'optgroup') {
              group = item.data.attrs.label
              return (item.children || []).map((childItem) => {
                return formatOptions(childItem, group)
              })
            }
          })
          .filter(Boolean)
        return v
      } else {
        return []
      }
    },

    handleTriggerDropdown() {
      this.timeOut = setTimeout(() => {
        if (this.closeDropdown) {
          this.toggleDropdown()
        }
      }, 150)
    },

    toggleDropdown() {
      if (!this.selectDisabled) this.open = !this.open
    },
    reset(e) {
      this.open = false
      this.term = ''
      this.filtered = this.options
    },

    selectedFromValue(value) {
      if (Array.isArray(value)) {
        this.selected = value.map((v) => this.itemForValue(v))
      } else {
        this.selected = [this.itemForValue(value)]
      }
      this.selected = this.selected.filter(Boolean)
    },
    itemForValue(value) {
      return this.options.find((i) => i.value === value)
    },
    labelForValue(value) {
      let item = this.itemForValue(value)
      if (item) {
        return item.label
      } else {
        return value
      }
    },
    onChange(option) {
      if (this.multiple) {
        if (!this.selected.includes(option)) {
          this.selected.push(option)
        } else {
          this.selected.splice(this.selected.indexOf(option), 1)
        }
        this.$emit(
          'input',
          this.selected.slice(0).map((i) => i.value)
        )
      } else {
        if (!this.clickAction) {
          this.open = false
          this.selected = [option]
          this.$emit('input', option.value)
        } else {
          this.open = false
          this.$emit('dropDownClickAction', option.value)
        }
      }
    },
    search(term) {
      if (this.fetchSuggestions && term.length > 0) {
        this.loading = true
        this.fetchSuggestions(term, (suggestions) => {
          this.loading = false
          if (!Array.isArray(suggestions)) {
            console.error(
              '[Convrrt Element][Input] autocomplete suggestions must be an array'
            )
            return
          }
          this.filtered = suggestions
        })
      } else {
        this.filtered = this.options.filter((item) => {
          return (
            item.value.indexOf(term) !== -1 || item.label.indexOf(term) !== -1
          )
        })
      }
    },
    removeSelection(option) {
      if (!this.selectDisabled)
        this.selected.splice(this.selected.indexOf(option), 1)
    },
    stopPropagation(event) {
      event.stopPropagation()
    },
    onClickOutside() {
      this.open = false
    },
    onInputEvent(eventName, eventData) {
      if (eventName === 'focus') {
        this.inputFocused = true
      }

      if (eventName === 'blur') {
        this.inputFocused = false
      }

      this.$emit(eventName, eventData)
    },
    getMenuTopPos() {
      let dropdownButton = this.$refs.dropdown.$el
      return dropdownButton.getBoundingClientRect().height
    },
    getMenuBottomPos() {
      let dropdownButton = this.$refs.dropdown.$el
      return dropdownButton.getBoundingClientRect().height + 16
    }
  },
  created() {
    Radii = this.Radii
  },
  render: function (h) {
    const InputGroupDiv = getInputGroupDiv()

    return (
      <InputGroupDiv
        inputFocused={this.inputFocused}
        disabled={this.selectDisabled}
      >
        <CvtButton
          ref='dropdown'
          text={this.showButtonLabel ? this.selectedLabel : ''}
          disabled={this.selectDisabled}
          icon='chevron-down'
          prepend={false}
          nativeOnmouseenter={() => {
            this.onHoverTriggerMode && !this.open ? this.toggleDropdown() : ''
          }}
          nativeOnmouseleave={() => {
            this.onHoverTriggerMode && this.open
              ? this.handleTriggerDropdown()
              : ''
          }}
          onClick={() =>
            !this.onHoverTriggerMode ? this.toggleDropdown() : ''
          }
          color={this.bgColor}
          specialStyle={this.specialStyle}
          outlined={this.outlined}
          size={this.size}
          fullWidth={ this.fullWidth }
          btnState={this.btnState}
          btnTextChange={this.btnTextChange}
        ></CvtButton>
        {this.showSuggestions && this.open && (
          <DropdownMenu
            dropUp={this.dropUp}
            loading={this.loading}
            filtered={this.filtered}
            selected={this.selected}
            top={this.getMenuTopPos()}
            bottom={this.getMenuBottomPos()}
            actionButton={this.actionButton}
            onmenumouseenter={() => {
              this.closeDropdown = false
              clearTimeout(this.timeOut)
            }}
            onmenumouseleave={() => {
              this.closeDropdown = true
              this.handleTriggerDropdown()
            }}
            onoptionchange={(option) => {
              this.onChange(option)
            }}
          ></DropdownMenu>
        )}
      </InputGroupDiv>
    )
  }
}
