
export default {
    namespaced: true,
    state: {
        genAIDialogVisible: false
    },
    mutations: {
        openGenAIDialog (state) {
            state.genAIDialogVisible = true
        },
        closeGenAIDialog (state) {
            state.genAIDialogVisible = false
        }
    },
    actions: {


    }
}
