<template>
  <div class="crm-file-browser">
    <div class="row mb-3">
      <div class="col-12 ml-auto">
        <el-input
          v-model="fileName"
          type="text"
          placeholder="Search your Company Files"
          :on-icon-click="search"
          icon="search"
          autofocus
          @keyup.enter.native="search"
        ></el-input>
      </div>
    </div>
    <div class="row">
      <div v-for="file in crmFiles" :key="file" class="col-3">
        <div class="crm-file-browser__image">
          <img
            :src="file.download_url"
            :alt="file.file_name"
            class="img-fluid"
            @click="use(file.download_url)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import * as getters from '../../store/modules/getters'
import * as mutations from '../../store/modules/mutations'
import * as actions from '../../store/modules/actions'

export default {
  props: {},
  data() {
    return {
      fileName: '',
    }
  },
  computed: {
    ...mapGetters({
      crmFiles: getters.ASSET_MANAGER_CRM_FILES,
    }),
  },
  mounted() {
    if (this.crmFiles.length === 0) {
      this.search()
    }
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      searchCRMFiles: actions.ASSETS_MANAGER_SEARCH_CRM_FILES,
    }),
    search() {
      return this.searchCRMFiles({
        name: this.fileName,
      })
    },
    use(url) {
      this.$emit('use', [url])
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

.crm-file-browser {
  position: relative;
  z-index: 0;
  &__image {
    cursor: pointer;
    transform: scale(1);
    transition: all 250ms ease;
    height: 150px;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      transform: scale(1.3);
      overflow-y: visible;
      z-index: 2;
    }
  }
}
</style>
