import {VDomCommands} from "./VDomCommand";
import {ConvrrtFirebase} from "../../js/convrrt-firebase";
import {PageEngineComponent} from "../../../types";


export class RevisionCommand extends VDomCommands {
    private _siteRef: ConvrrtFirebase;
    private _snapshotsRef: ConvrrtFirebase;
    private _snapshotID: any;
    private _oldSnapShot: null;
    private _newSnapShot: null;

    constructor(siteReferencePath: string, snapshotsReferencePath: string, snapshotID: string, pageEngineVNodeId="page-engine") {
        super(pageEngineVNodeId);
        this._siteRef = new ConvrrtFirebase(siteReferencePath)
        this._snapshotsRef = new ConvrrtFirebase(`${snapshotsReferencePath}/${snapshotID}`)
        this._snapshotID = snapshotID

        this._oldSnapShot = null
        this._newSnapShot = null
    }

    getVueComponent(): PageEngineComponent {
        return super.getVueComponent() as PageEngineComponent
    }

    get$emitData() {
        return  {name: 'RevisionCommand', forceBuild: true}
    }

    async pullChanges() {
        this._oldSnapShot = await this._siteRef.load()
        this._newSnapShot = await this._snapshotsRef.load()
    }

    async execute() {
        await this._siteRef.set(this._newSnapShot);
    }

    async unExecute() {
        await this._siteRef.set(this._oldSnapShot);
    }
    updateVDom(action:any) {
        this.getVueComponent()?.forceBuildDOM()
    }

    get hasChanges() {
        return true
    }
}


export class PopupRevisionCommand extends RevisionCommand {
    constructor(siteReferencePath: string, snapshotsReferencePath: string, snapshotID: string) {
        super(siteReferencePath, snapshotsReferencePath, snapshotID, "page-engine-wrapper");
    }
}