import { rest } from 'msw'

export const handlers = [
  // this endpoint allows the user to send 3 domains to be verified one per environment
  // {
  //   "production": "production.domain.com",
  //   "staging": "staging.domain.com",
  //   "cdn": "cdn.domain.com"
  // }
  rest.post('/api/v1/organization/admin/customer-domains', async (req, res, ctx) => {
    await wait(1000)
    const payload  = await req.json()

    if (!payload) {
      return res(
        ctx.status(400),
        ctx.json({
          ok: false,
          error: 'Missing request body',
        }),
      )
    }

    if (!(payload.production && payload.staging && payload.cdn)) {
      return res(
        ctx.status(400),
        ctx.json({
          ok: false,
          error: 'Missing required fields',
        }),
      )
    }

    if (!payload.production.endsWith('domain.com') ||
        !payload.staging.endsWith('domain.com') ||
        !payload.cdn.endsWith('domain.com')) {

      return res(
        ctx.status(400),
        ctx.json({
          ok: false,
          error: 'Invalid domain',
        })
      )
    }

    return res(ctx.json({
      ok: true
    })
    )}
  ),

  //this lists the environments url
  rest.get('/api/v1/organization/admin/customer-domains', async (req, res, ctx) => {
    return res(ctx.json({
      ok: true,
      data: [
        {
          environment: "production",
          url: "production.domain.com"
        },
        {
          environment: "staging",
          url: "staging.domain.com"
        },
        {
          environment: "cdn",
          url: "cdn.domain.com"
        }
      ]
    })
    )
  }),

  //this endpoint lists the custom host names
  rest.get('/api/v1/organization/admin/custom-hostnames', async (req, res, ctx) => {
    return res(ctx.json({
      data: {
        hostnames: [
          {
            environment: 'Production',
            url: 'production.domain.com',
            fqdn: 'domain.keap.com',
            ownershipVerification: {
              name:'_cf-custom-hostname.testing.keap.com',
              value: '_cf-custom-hostname.testing.keap.com'
            }
          },
          {
            environment: 'Stagging',
            url: 'stagging.domain.com',
            fqdn: 'domain.keap.com',
            ownershipVerification: {
              name:'_cf-custom-hostname.testing.keap.com',
              value: '_cf-custom-hostname.testing.keap.com'
            }
          },
          {
            environment: 'CDN',
            url: 'cdn.domain.com',
            cName: 'domain.keap.com',
            ownershipVerification: {
              name:'_cf-custom-hostname.testing.keap.com',
              value: '_cf-custom-hostname.testing.keap.com'
            }
          }
        ]
      }
    }))
  }),

  // this endpoint will verify the domains saved in the previous endpoint
  rest.get('/api/v1/organization/admin/customer-domains/verify', async (req, res, ctx) => {
    await wait(3000)
    return res(ctx.json({
      ok: true,
    }))
  }),

  // this endpoint lists the customers JWT keys
  rest.get('/api/v1/organization/admin/get-env-jwt', async (req, res, ctx) => {
    return res(ctx.json(
      {
        tokens: [
          {
            environment: 'production',
            domain: 'production.domain.com',
            value: 'd63a4688029dff8624a56e90046b1dd4bb1c252bdffd2a2d5bd43ce7e31e1ec9'
          },
          {
            environment: 'staging',
            domain: 'staging.domain.com',
            value: '991483a12dc8b51c16a5576222e4c1c214c9a45a327d00561a896012603c2a5b'
          }
        ]
      }
    ))
  }),


  // used when a user enters a URL to import OIDC settings from
  // this will be controlled by the end user not by us
  rest.get('https://example.com/oidc', async (req, res, ctx) => {
    return res(ctx.json(
      {
        issuer: "https://example.com",
        authorization_endpoint: "https://example.com/oauth2/authorize",
        token_endpoint: "https://example.com/oauth2/token",
        userinfo_endpoint: "https://example.com/oauth2/userinfo",
        jwks_uri: "https://example.com/oauth2/jwks",
        scopes_supported: ["openid", "profile", "email"],
        response_types_supported: ["code", "id_token", "token"],
        grant_types_supported: ["authorization_code", "implicit", "refresh_token"],
        subject_types_supported: ["public", "pairwise"],
        id_token_signing_alg_values_supported: ["RS256", "HS256"],
        token_endpoint_auth_methods_supported: ["client_secret_basic", "client_secret_post"],
      }
    ))
  }),

  //this endpoint adds oidc configuration
  rest.post('/api/v1/organization/admin/add-oidc-config', async (req, res, ctx) => {
    await wait(3000)
    const payload  = await req.json()

    if (!payload) {
      return res(
        ctx.status(400),
        ctx.json({
          ok: false,
          error: 'Missing request body',
        }),
      )
    }
    if (!(payload.iodcUrl && payload.environment)) {
      return res(
        ctx.status(400),
        ctx.json({
          ok: false,
          error: 'Missing required fields',
        }),
      )
    }

    return res(ctx.json({
      ok: true,
    }))
  }),

  //this endpint creates new oidc configuration
  rest.post('/api/v1/organization/admin/create-oidc-config', async (req, res, ctx) => {
    await wait(3000)
    const payload  = await req.json()

    if (!payload) {
      return res(
        ctx.status(400),
        ctx.json({
          ok: false,
          error: 'Missing request body',
        }),
      )
    }

    return res(ctx.json({
      ok: true,
    }))
  }),

  //this endpount list oidc configuration
  rest.get('/api/v1/organization/admin/oidc-config', async (req, res, ctx) => {
    return res(ctx.json({
      data: [
        {
          id: "1",
          clientId: "xxx343xx",
          environment: "Staging",
          testPassed: false,
          testIsLoading: false,
        },
        {
          id: "2",
          clientId: "xxx343xx",
          environment: "Production",
          testPassed: false,
          testIsLoading: false,
        }
      ]
    }))
  }),

  rest.post('/api/auth/mfa/send', async (req, res, ctx) => {
    await wait(3000)
    const payload  = await req.json()

    if (!payload) {
      return res(
        ctx.status(400),
        ctx.json({
          ok: false,
          error: 'Missing request body',
        }),
      )
    }

    if (!(payload.to && payload.channel && payload.redirectURL)) {
      return res(
        ctx.status(400),
        ctx.json({
          ok: false,
          error: 'Missing required fields',
        }),
      )
    }

    return res(ctx.json({
      redirectURL: "/onboarding/information"
    })
    )
  }),

  rest.get('/api/v1/organization/admin/members', async (req, res, ctx) => {
    return res(ctx.json([
      {
        createdAt: "2023-02-25T03:25:06.019000000Z",
        deletedAt: null,
        orgID: "convrrt-com",
        role: "Developer",
        updatedAt: null,
        userID: "bP7fhUHYm3mUf6GI93t53PbtBS0ufx",
        email: "adeniyi@convrrt.com",
        name: "Adejumo Adeniyi"
      },
      {
        createdAt: "2023-02-25T03:25:06.019000000Z",
        deletedAt: null,
        orgID: "convrrt-com",
        role: "Admin",
        updatedAt: null,
        userID: "bP7fhUHYm3mUf6GI93t53PbtBS0ufe",
        email: "dhruv@convrrt.com",
        name: "Patel Dhruv"
      }
  ]))
  }),

  rest.delete(`/api/v1/organization/admin/members`, async (req, res, ctx) => {
    await wait(2000)
    const payload  = await req.json()

    if (!payload) {
      return res(
        ctx.status(400),
        ctx.json({
          ok: false,
          error: 'Missing request body',
        }),
      )
    }

    return res(ctx.json([
      {
        createdAt: "2023-02-25T03:25:06.019000000Z",
        deletedAt: null,
        orgID: "convrrt-com",
        role: "Developer",
        updatedAt: null,
        userID: "bP7fhUHYm3mUf6GI93t53PbtBS0ufx",
        email: "adeniyi@convrrt.com",
        name: "Adejumo Adeniyi"
      }
  ]))
  }),

  rest.post('/api/v1/organization/admin/re-invite-member', async (req, res, ctx) => {
    await wait(3000)
    const payload  = await req.json()

    if (!payload) {
      return res(
        ctx.status(400),
        ctx.json({
          ok: false,
          error: 'Missing request body',
        }),
      )
    }

    return res(ctx.json({
      ok: true,
    }))
  }),

  rest.post('/api/auth/mfa/verify', async (req, res, ctx) => {
    await wait(3000)
    const payload  = await req.json()

    if (!payload) {
      return res(
        ctx.status(400),
        ctx.json({
          ok: false,
          error: 'Missing request body',
        }),
      )
    }

    if (!(payload.to && payload.code && payload.code)) {
      return res(
        ctx.status(400),
        ctx.json({
          ok: false,
          error: 'Missing required fields',
        }),
      )
    }

    return res(ctx.json({
        redirectURL: "/onboarding/information"
      }
     ))
  })

]

function wait(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}