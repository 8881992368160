import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'

export default {
  state: {
    active: false,
    dataTransfer: null,
    dropped: null
  },
  getters: {
    [getters.DROPZONE_ACTIVE] ({active}) {
      return active
    },
    [getters.DROPZONE_DATA_TRANSFER] ({dataTransfer}) {
      return dataTransfer
    }
  },
  mutations: {
    [mutations.DROPZONE_DEACTIVATE] (state) {
      state.active = false
      state.dataTransfer = null
    },
    [mutations.DROPZONE_DISABLE] (state) {
      state.active = false
    },
    [mutations.DROPZONE_DROPPED] (state) {
      if (state.dropped) {
        state.dropped()
        state.dropped = null
      }
    }
  },
  actions: {
    [actions.DROPZONE_ACTIVATE] ({state}, data) {
      state.active = true
      state.dataTransfer = data
      return new Promise((resolve) => {
        state.dropped = resolve
      })
    }
  }
}
