import Vue from 'vue'
import { styled } from '@egoist/vue-emotion'
import { mapGetters, mapState } from 'vuex'
import { VueEmotion } from '@egoist/vue-emotion'

import * as getters from '../../../builder/src/js/store/modules/getters'

Vue.use(VueEmotion)

let Spacing,
  FontFamily,
  Colors,
  Radii,
  textFontWeight,
  backgroundColor,
  textFontSize,
  color,
  elevation,
  orgName,
  radioSwitchConfig,
  switchColorConfig

const getCustomRadio = () => styled('div')`
  input {
    position: absolute;
    left: -9999px;

    &:checked + label,
    &:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
    }

    &:checked + label:before,
    &:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
    }

    &:checked + label:after,
    &:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: ${(props) => props.color || Colors.PRIMARY[500]};
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      transition: all 0.2s ease;
    }

    &:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0);
    }

    &:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }
  }
`

const getLabel = () => styled('label')`
  font-size: ${(props) => (props.fontSize.trim() ? props.fontSize : '16px')};
`

export default {
  name: 'CvtCustomRadio',
  components: {},
  props: {
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'LIGHT',
    },
    fontSize: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textColor: getters.GLOBAL_STYLE_COLOR,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Colors: ({ globalTheme }) => globalTheme.Colors,
    }),
  },
  methods: {},
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    FontFamily = this.FontFamily
    Colors = this.Colors
  },
  render: function (h) {
    const CustomRadio = getCustomRadio()
    const Label = getLabel()

    return (
      <CustomRadio color={this.color}>
        <input
          disabled={this.disabled}
          name={this.name}
          type={'radio'}
          checked={this.checked}
          onChange={(event) => this.$emit('change', event)}
          id={this.label}
        />
        <Label for={this.label} fontSize={this.fontSize}>
          {this.label}
        </Label>
      </CustomRadio>
    )
  },
}
