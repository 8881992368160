
<cvt-left-side-bar
  :mode="mode"
  :panels="panels"
  route="popup"
  :is-template="true"
  :type="OrgName"
  :active-panel="active"
  :side-bar-left="SideBarLeft"
  @panel-toggle="(panel) => toggle(panel)"
>
 <template #imageGallery>
  <gallery />
</template>

  <template #popups>
    <popup-launcher />
  </template>
</cvt-left-side-bar>
