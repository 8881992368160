import * as actions from '../actions'
import * as mutations from '../mutations'
import * as getters from "@/js/store/modules/getters";
import {checkoutPageOptionDefaults, checkoutThankYouPageOptionDefaults} from "./defaultOptions";
import {ConfigPageKey} from "./configModel";



export default {
    state: {
        checkoutPageOptions: null,
        checkoutThankYouPageOptions: null
    },
    getters: {
        [getters.CHECKOUT_PAGE_OPTIONS]({ checkoutPageOptions }) {
            return checkoutPageOptions
        },
        [getters.CHECKOUT_THANK_YOU_PAGE_OPTIONS]({ checkoutThankYouPageOptions }) {
            return checkoutThankYouPageOptions
        },
    },
    mutations: {
        [mutations.CHECKOUT_PAGE_OPTIONS](state, configPageKey) {
            state.checkoutPageOptions = configPageKey
        },
        [mutations.CHECKOUT_THANK_YOU_PAGE_OPTIONS](state, configPageKey) {
            state.checkoutThankYouPageOptions = configPageKey
        },
    },
    actions: {
        async [actions.CHECKOUT_CONFIG_INIT]({ state, commit, dispatch, rootGetters }, pageId) {
            const { firebaseRef } = rootGetters[getters.SITE_GET_DATA]
            await dispatch(actions.CHECKOUT_PAGE_OPTION_INIT, firebaseRef)
            await dispatch(actions.CHECKOUT_THANK_YOU_PAGE_INIT, firebaseRef)
            await dispatch('loader/stop')
        },
        async [actions.CHECKOUT_PAGE_OPTION_INIT]({ state, commit}, firebaseRef) {
            state.checkoutPageOptions?.destroy()

            const checkoutPageOptions = new ConfigPageKey(
                firebaseRef, 'checkoutPageOptions'
            )
            await commit(mutations.CHECKOUT_PAGE_OPTIONS, checkoutPageOptions)

            await checkoutPageOptions.load(checkoutPageOptionDefaults)
            checkoutPageOptions.subscribe()
        },
        async [actions.CHECKOUT_THANK_YOU_PAGE_INIT]({ state, commit }, firebaseRef) {
            state.checkoutThankYouPageOptions?.destroy()

            const checkoutThankYouPageOptions = new ConfigPageKey(
                firebaseRef, 'checkoutThankYouPageOptions'
            )
            await commit(mutations.CHECKOUT_THANK_YOU_PAGE_OPTIONS, checkoutThankYouPageOptions)

            await checkoutThankYouPageOptions.load(checkoutThankYouPageOptionDefaults)
            checkoutThankYouPageOptions.subscribe()
        },
    },
}
