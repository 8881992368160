
<div class="form-group">
  <cvt-select
    :value="fontSize"
    mode="dropdown"
    :label="$t('setup.font.size')"
    @input="(value) => updateFontSize(value)"
  >
    <option v-for="size in fontSizes" :key="size.value" :value="size.value" v-text="size.label" />
  </cvt-select>
</div>
