
<div
  v-if="visible"
  id="popup-wrapper"
  :data-vnode-id="vnodeId"
  :class="{ outer: true, 'outer-mobile': isMobile }"
  @mouseover="outerTarget"
  @click="stopProp"
>
  <div
    v-if="isListening"
    :class="{ inner: true, 'inner-mobile': isMobile }"
    :style="{
      width: computedWidth,
      height: computedHeight,
      position: 'relative',
    }"
    @click="stopProp"
  >
    <template v-for="obj in clonedVDom">
      <block :is="obj.node.tag" :key="obj.key" :fb-node="obj.node" v-bind="obj.node.data.props" :section-u-i-enabled="true"></block>
    </template>
    <cvt-sap-close-btn :close-btn-position="closeButtonPosition" />
  </div>
</div>
