import * as actions from './actions';
import * as getters from './getters';

export default {
  state: {
    imgUrl: ''
  },

  getters: {
    [getters.AVATAR_IMG_URL] ({ imgUrl }) {
      return imgUrl;
    },
  },

  mutations: {
    setImageURL (state, url) {
      state.imgUrl = url;
    },
  },

  actions: {
    async [actions.AVATAR_ORG_IMG] ({ state, commit }, url) {
      commit('setImageURL', url);
    },
  }
}
