
<div
  class="
    cvt-editor-grid-item-wrapper
    grid-stack-item-content
    grid-stack-draggable-item grid-stack-item
    ui-draggable
    item-hover-border
  "
  data-identity="GridItemWrapper"
  :data-gs-x="dataX"
  :data-gs-y="dataY"
  :data-gs-width="dataWidth"
  :data-gs-height="dataHeight"
  :data-vnode-id="vnodeId"
  :gs-x="dataX"
  :gs-y="dataY"
  :gs-w="dataWidth"
  :gs-h="dataHeight"
  :gs-locked="false"
  :gs-auto-position="false"
  :gs-no-resize="noResize"
  :data-gs-locked="false"
  :data-is-child-trageted="isChildTrageted"
  :data-is-child-selected="isChildSelected"
  :data-child-name="childComponent.name"
  @dblclick.stop=""
  @mouseenter="gridItemWrapperMouseEnter"
  @mouseover="targetChildElement"
>
  <div
    class="grid-stack-item-content grid-item-wrapper-content-wrapper"
    @dblclick="toggleOverlay(false)"
  >
      <div
        v-if="isNonDraggableContent"
        class="editable-element-overlay"
        :class="contentWrapClasses"
      >
        <cvt-button
          class="editable-element-edit-button"
          color="light"
          size="md"
          :mode="'LIGHT'"
          :text="editBtnText"
          :icon="'pencil'"
          :outlined="true"
          @click="toggleOverlay(false)"
        />
      </div>

      <div class="grid-item-actions">
        <div class="ui-draggable-handle move-icon">
          <cvt-icon icon="arrows-alt"/>
        </div>
      </div>

      <div
        ref="content"
        class="d-flex w-100 grid-stack-item-element-content"
        :class="contentClasses"
        data-identity="GridItemWrapperContent"
      >
        <template v-for="node in fbNode.children">
          <template v-if="node.tag === 'sub-grid-container'">
            <sub-grid-container-v3 v-bind="node.data.props" :key="node.vnodeId" :fb-node="node"/>
          </template>
          <template v-else-if="node.children !== undefined">
            <component :is="node.tag" v-bind="node.data.props" :key="node.vnodeId" :fb-node="node">
              <template v-for="item in node.children">
                <component :is="item.tag" v-bind="item.data.props" :key="item.vnodeId" :fb-node="node"></component>
              </template>
            </component>
          </template>
          <template v-else>
            <component :is="node.tag" v-bind="node.data.props" :key="node.vnodeId" :fb-node="node" :is-loading="isRegenerating"></component>
          </template>
        </template>
      </div>
    </div>
    <action-buttons
      v-if="selectedChild && !isBuilderOnStaticMode"
      :show-regenerate="showRegenerateBtn"
      @duplicate="duplicateElement"
      @remove="removeElement"
      @regenerate="regenerateElement"
    />

    <template v-if="fbNode.children.length === 0 && !isBuilderOnStaticMode">
      <action-buttons :show-duplicate="false" @remove="forceRemoveElement" />
    </template>
</div>
