import Vue from '../../../lib/utils/vue'
import idMixin from '../../../lib/mixins/id'
import normalizeSlotMixin from '../../../lib/mixins/normalize-slot'
import BVTransition from '../../../lib/utils/bv-transition'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import { mapState } from '../../../../builder/node_modules/vuex'

Vue.use(VueEmotion)

let Spacing

const getTabPanelDiv = () => styled('div')`
  ${(props) => {
    return props.localActive
      ? `margin-top: ${Spacing.SPACING_16}px;`
      : `display:none`
  }}
`

// @vue/component
export default {
  name: 'CvtTab',
  mixins: [idMixin, normalizeSlotMixin],
  inject: {
    bvTabs: {
      default() /* istanbul ignore next */ {
        return {}
      }
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'div'
    },
    buttonId: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    titleItemClass: {
      // Sniffed by tabs.js and added to nav 'li.nav-item'
      type: [String, Array, Object],
      default: null
    },
    titleLinkClass: {
      // Sniffed by tabs.js and added to nav 'a.nav-link'
      type: [String, Array, Object],
      default: null
    },
    titleLinkAttributes: {
      type: Object,
      default: null
    },
    closeable: {
      type: Boolean,
      default: false
    },
    closeIconClass: {
      type: [String, Array, Object],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noBody: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: false
    },
    stepCompleted: {
      type: Boolean,
      default: false
    },
    tabId: {  
      type: String,
      default: ''
    },
  },
  data() {
    return {
      localActive: this.active && !this.disabled,
      isCloseable: this.closeable
    }
  },
  computed: {
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing
    }),
    controlledBy() {
      return this.buttonId || this.safeId('__BV_tab_button__')
    },
    computedNoFade() {
      return !(this.bvTabs.fade || false)
    },
    computedLazy() {
      return this.bvTabs.lazy || this.lazy
    },
    _isTab() {
      // For parent sniffing of child
      return true
    }
  },
  watch: {
    localActive(newVal) {
      // Make 'active' prop work with `.sync` modifier
      this.$emit('update:active', newVal)
    },
    active(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          // If activated post mount
          this.activate()
        } else {
          /* istanbul ignore next */
          if (!this.deactivate()) {
            // Tab couldn't be deactivated, so we reset the synced active prop
            // Deactivation will fail if no other tabs to activate
            this.$emit('update:active', this.localActive)
          }
        }
      }
    },
    disabled(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal && this.localActive && this.bvTabs.firstTab) {
          this.localActive = false
          this.bvTabs.firstTab()
        }
      }
    }
  },
  mounted() {
    // Inform cvt-tabs of our presence
    this.registerTab()
  },
  updated() {
    // Force the tab button content to update (since slots are not reactive)
    // Only done if we have a title slot, as the title prop is reactive
    if (this.hasNormalizedSlot('title') && this.bvTabs.updateButton) {
      this.bvTabs.updateButton(this)
    }
  },
  destroyed() {
    // inform cvt-tabs of our departure
    this.unregisterTab()
  },
  methods: {
    // Private methods
    registerTab() {
      // Inform `cvt-tabs` of our presence
      this.bvTabs.registerTab && this.bvTabs.registerTab(this)
    },
    unregisterTab() {
      // Inform `cvt-tabs` of our departure
      this.bvTabs.unregisterTab && this.bvTabs.unregisterTab(this)
    },
    // Public methods
    activate() {
      if (this.bvTabs.activateTab && !this.disabled) {
        return this.bvTabs.activateTab(this)
      } else {
        // Not inside a <cvt-tabs> component or tab is disabled
        return false
      }
    },
    deactivate() {
      if (this.bvTabs.deactivateTab && this.localActive) {
        return this.bvTabs.deactivateTab(this)
      } else {
        // Not inside a <cvt-tabs> component or not active to begin with
        return false
      }
    }
  },
  created () {
    Spacing = this.Spacing
  },
  render(h) {
    const TabPanelDiv = getTabPanelDiv()
    return (
      <BVTransition mode='out-in' noFade={this.computedNoFade}>
        {
          <TabPanelDiv
            ref='panel'
            role='tabpanel'
            id={this.safeId()}
            aria-hidden={this.localActive ? 'false' : 'true'}
            aria-labelledby={this.controlledBy || null}
            localActive={this.localActive}
          >
            {this.localActive || !this.computedLazy
              ? this.normalizeSlot('default')
              : ''}
          </TabPanelDiv>
        }
      </BVTransition>
    )
  }
}
