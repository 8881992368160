import Vue from 'vue'
import Vuex from 'vuex'
import advancedMenu from './modules/advanced-menu'
import advancedPopoverMenu from './modules/advanced-popover-menu'
import anchors from './modules/anchors'
import appCues from './modules/appcues'
import assetManager from './modules/asset-manager'
import auth from './modules/auth'
import avatar from './modules/avatar'
import blockManager from './modules/block-manager'
import builderIntegrations from './modules/builderIntegration'
import builders from './modules/builders'
import chameleon from './modules/chameleon'
import config from './modules/config'
import customTenantScripts from './modules/custom-tenant-scripts'
import delta from './modules/delta'
import draft from './modules/draft'
import dropzone from './modules/dropzone'
import eCommerce from './modules/e-commerce'
import elementLauncher from './modules/element-launcher'
import eventTracking from './modules/eventTracking'
import formEditor from './modules/formEditor'
import froged from './modules/froged'
import doppler from './modules/doppler'
import globalTheme from './modules/global-theme'
import history from './modules/history'
import i18nPlugin from './modules/i18n'
import iconPicker from './modules/iconPicker'
import imageSearch from './modules/image-search'
import intercom from './modules/intercom'
import layout from './modules/layout'
import leftSideMenu from './modules/left-side-menu'
import loader from './modules/loader'
import marketplace from './modules/marketplace'
import mixPanel from './modules/mixpanel'
import opengraph from './modules/opengraph'
import pageManager from './modules/page-manager'
import pagePreview from './modules/page-preview'
import pendo from './modules/pendo'
import popupBuilder from './modules/popup/builder'
import popupMarketplace from './modules/popup/marketplace'
import popup from './modules/popup/popup'
import popupSettings from './modules/popup/settings'
import quickLaunchBlock from './modules/quick-launch-block'
import revisions from './modules/revisions'
import rightClickMenu from './modules/right-click-menu'
import saveBlock from './modules/save-block'
import selector from './modules/selector'
import site from './modules/site'
import sitePreferences from './modules/site-preferences'
import startover from './modules/startover'
import steps from './modules/steps'
import subscription from './modules/subscription'
import superMod from './modules/super'
import targetmode from './modules/targetmode'
import theme from './modules/theme'
import viewmode from './modules/viewmode'
import apiInterceptor from './plugins/api-interceptor'

import checkoutPageConfig from './modules/checkout-config'
import modals from './modules/modals'
import footer from './modules/page-extra-nodes/footer'
import header from './modules/page-extra-nodes/header'

import authenticateCustomer from './modules/enterprise-dashboard/authenticate-customer'
import dnsRecord from './modules/enterprise-dashboard/dns-record'

import inviteUsers from './modules/enterprise-dashboard/invite-users'
import signin from './modules/enterprise-dashboard/signin'
import chatIntegration from './modules/chat-integration'
import genAI from './modules/gen-ai'
import entConfig from './modules/enterprise-dashboard/ent-config'
import bookmarkBlock from './modules/bookmark-block'
import { Modules as MarketPlaceBrandingModules } from './modules/branding'
import version from './modules/version'

Vue.use(Vuex)

let plugins = [apiInterceptor]

// eslint-disable-next-line no-undef
if (__VUE_DEBUG_ENABLED__) {
  plugins.push((store) => {
    store.subscribe((mutation, state) => {
      console.debug('mutation', mutation.type, mutation.payload)
    })

    store.subscribeAction((action, state) => {
      console.debug('action', action.type, action.payload)
    })
  })
}

export default function Store({ i18n }) {
  return new Vuex.Store({
    // eslint-disable-next-line no-undef
    debug: __VUE_DEBUG_ENABLED__,
    modules: {
      loader,
      delta,
      config,
      history,
      intercom,
      anchors,
      eCommerce,
      theme,
      layout,
      steps,
      draft,
      viewmode,
      targetmode,
      advancedPopoverMenu,
      selector,
      dropzone,
      formEditor,
      assetManager,
      marketplace,
      imageSearch,
      pagePreview,
      elementLauncher,
      rightClickMenu,
      quickLaunchBlock,
      advancedMenu,
      leftSideMenu,
      iconPicker,
      saveBlock,
      opengraph,
      revisions,
      site,
      super: superMod,
      auth,
      pageManager,
      blockManager,
      sitePreferences,
      i18n: i18nPlugin(i18n),
      mixPanel,
      pendo,
      froged,
      doppler,
      appCues,
      eventTracking,
      chameleon,
      customTenantScripts,
      globalTheme,
      builders,
      avatar,
      popupMarketplace,
      popup,
      popupBuilder,
      popupSettings,
      subscription,
      startover,
      modals,
      header,
      footer,
      dnsRecord,
      authenticateCustomer,
      builderIntegrations,
      checkoutPageConfig,
      inviteUsers,
      signin,
      chatIntegration,
      genAI,
      entConfig,
      bookmarkBlock,
      ...MarketPlaceBrandingModules,
      version,
    },
    plugins,
  })
}
