
<div>
  <div
    v-for="(popup, idx) in popupList"
    :key="idx"
    class="e-popup-list-item d-flex justify-content-between align-items-center"
    :class="activePopupIdx === idx ? 'popup-item-active' : ''"
    @click="() => onClickPopup(popup, idx)"
  >
    <input
      v-if="activePopupIdx === idx"
      :value="fmtName(popup)"
      type="text"
      maxlength="12"
      class="embedded-popup-input"
      @change="(e) => debounceUpdatePopup(e, popup)"
    />
    <span v-else>{{ fmtName(popup) }}</span>
    <div v-if="activePopupIdx === idx">
      <span class="e-popup-icon" @click.stop="onConfigureClicked">
        <i class="cvt-icon-pencil"></i>
      </span>
      <span class="e-popup-icon" @click.stop="() => onDeleteClicked(popup)">
        <i class="fa fa-trash ml-3"></i>
      </span>
    </div>
  </div>

  <div
    v-if="!popupList[0]?.popupVNode"
    class="no-popup-text my-3 text-center"
  >
    {{ $t('e.popup.unavailable.message') }}
  </div>
  <div v-if="popupList[0]?.popupVNode" :class="'divider-with-legend my-2'">
    or
  </div>
</div>
