<template>
  <div
    v-if="visible"
    id="popup-wrapper"
    :data-vnode-id="vnodeId"
    :class="{ outer: true, 'outer-mobile': isMobile }"
    @mouseover="outerTarget"
    @click="stopProp"
  >
    <div
      v-if="isListening"
      :class="{ inner: true, 'inner-mobile': isMobile }"
      :style="{
        width: computedWidth,
        height: computedHeight,
        position: 'relative',
      }"
      @click="stopProp"
    >
      <template v-for="obj in clonedVDom">
        <block :is="obj.node.tag" :key="obj.key" :fb-node="obj.node" v-bind="obj.node.data.props" :section-u-i-enabled="true"></block>
      </template>
      <cvt-sap-close-btn :close-btn-position="closeButtonPosition" />
    </div>
  </div>
</template>

<script>
import FirebaseNodeV3 from '../../base/FirebaseNode'
import CPopupBuilder from "../../../editor/nodes/CPopupBuilder.vue";
import Block from "./Block.vue";
import {PopUpUndoRedoManagerV3} from "../../../../store/services/popUpUndoRedoManager";
import * as actions from "@/js/store/modules/actions";
import {mapGetters} from "vuex";
import * as getters from "@/js/store/modules/getters";
import {ConvrrtFirebase} from "@/js/convrrt-firebase";

export default CPopupBuilder.extend({
  name: 'CPopupBuilderV3',
  components: {
    block: Block,
  },
  mixins: [FirebaseNodeV3],
  computed: {
    ...mapGetters({
      viewMode: getters.VIEW_MODE,
    }),
    isMobile() {
      return this.viewMode === 'phone'
    },
    cPopupWidth() {
      return this.cPopupData.data?.props?.popupWidth
    },
    cPopupHeight() {
      return this.cPopupData.data?.props?.popupHeight
    },
    cPopupFullscreen() {
      return this.cPopupData.data?.props?.isFullScreen
    },
    computedWidth () {
      return this.cPopupFullscreen === true || this.isMobile || this.cPopupWidth === '100vw' ? '100%'  :  this.cPopupWidth
    },
    cPopupData() {
      return this.undoRedoManager.cPopupData
    },
    computedHeight() {
      return this.cPopupFullscreen === true || this.cPopupHeight === '100vh'
        ? '100vh'
        : this.cPopupHeight
    },
    clonedVDom: {
      get() {
        return this.undoRedoManager.VDom
      },
      set() {},
    },
    isListening() {
      return this.undoRedoManager.listen
    },
  },
  data() {
    return {
      undoRedoManager: new PopUpUndoRedoManagerV3(),
    }
  },
  updated() {
    if (this.undoRedoManager?.VDom?.length > 0) {
       this.blockVnodeId = this.undoRedoManager?.VDom[0].node.data?.props?.vnodeId
    }
  },
  methods: {
    async reloadPopupContents() {
      if (this.currentIdx === -1) {
          this.undoRedoManager = new PopUpUndoRedoManagerV3()
          this.blockVnodeId = null
          return
      }
      this.$store
        .dispatch(actions.POPUP_BUILDER_UNDO_REDO_MANAGER, this['.path'])
        .then((undoRedoManager) => {
          this.undoRedoManager = undoRedoManager
          if (undoRedoManager?.VDom?.length > 0) {
              this.blockVnodeId = undoRedoManager?.VDom[0].node.data?.props?.vnodeId
          }
        })
    },
    firebaseRef() {
      let popupRef = this.path()
      return new ConvrrtFirebase(popupRef)
    }
  },
})
</script>
