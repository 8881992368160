import * as _ from 'lodash'
import * as getters from '../../../../../store/modules/getters'
import FroalaEditor from 'froala-editor/js/froala_editor.min.js'
import { rgbToHex } from '../../../../../lib/color-utils'
import { FONT_SIZES, FONT_WEIGHTS } from '../../../../../lib/font.js'
import { mapGetters } from 'vuex'

export default {
  name: 'AddDescription',
  props: {
    description: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      froala: null,
      froalaConfig: {
        key: '',
        charCounterCount: true,
        toolbarVisibleWithoutSelection: true,
        attribution: false,
        keepFormatOnDelete: true,
        pastePlain: true,
        pasteDeniedTags: ['div'],
        pasteDeniedAttrs: ['class', 'style'],
        paragraphMultipleStyles: false,
        toolbarButtons: {
          moreMisc: {
            buttons: ['customParagraphStyle','textColor', 'bold', 'italic', 'underline', 'clearFormatting',
              'alignLeft', 'alignCenter', 'alignRight', 'insertLink', 'undo', 'redo', 'fontSize', 'fontWeight',
              'lineHeight', 'backgroundColor', 'strikeThrough', 'formatOLSimple', 'customList'],
            buttonsVisible: 9
          }
        },
        colorsText: [],
        colorsBackground: [],
        fontFamilySelection: true,
        fontFamily: {},
        fontSize: [],
        lineHeights: {
          Default: '',
          Single: '1',
          '1.15': '1.15',
          '1.5': '1.5',
          Double: '2'
        },
        linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove', 'textColor', 'backgroundColor'],
        linkAutoPrefix: 'https://',
        placeholderText: "Place your text",
        events: {
          'initialized': function () {
            console.log('initialized')
          },
          'commands.after': function (e, param1, param2) { },
          'contentChanged': () => {
            this.contentsChanged()
          }
        }
      },
    }
  },
  computed: {
    ...mapGetters({
      colors: getters.THEME_GET_COLORS,
      availableFonts: getters.THEME_AVAILABLE_FONTS,
      config: getters.AUTH_GET_USER_CONFIG
    }),
    getFroalaEditorId(){
      return this.config?.FROALA_EDITOR_ID
    }
  },
  created(){
    this.froalaConfig.colorsText = this.getHexColors()
    this.froalaConfig.colorsBackground = this.getHexColors()
    this.froalaConfig.fontFamily = this.getFontFamily()
    this.froalaConfig.fontSize = this.getFontSizes()
    this.froalaConfig.key = this.getFroalaEditorId
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      let weights:{[key:string]:string} = {}
      FONT_WEIGHTS.forEach(font => {
        weights[font.value] = font.label
      })

      FroalaEditor.DefineIcon('fontWeight', {NAME: 'text-width', template: 'font_awesome'});
      FroalaEditor.RegisterCommand('fontWeight', {
        title: 'Font Weight',
        type: 'dropdown',
        focus: true,
        undo: true,
        refreshAfterCallback: false,
        options: weights,
        callback: function (cmd:any, val:any) {
        this.format.applyStyle('font-weight', `${val};`)
        },
        // Callback on refresh.
        refresh: function ($btn:any) {
        },
        // Callback on dropdown show.
        refreshOnShow: function ($btn:any, $dropdown:any) {
        }
      });

      FroalaEditor.RegisterCommand('customParagraphStyle', {
        title: 'Font Style',
        type: 'dropdown',
        icon: '&nbsp;&nbsp;Font Style&nbsp;&nbsp;&nbsp;&nbsp;', // this is a quick fix, because we want to show text in place of icon in toolbar
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        options: {
          'font-style-title': '<h1>Title</h1>',
          'font-style-subtitle': '<h2>SubTitle</h2>',
          'font-style-heading': '<h3>Heading</h3>',
          'font-style-alternativeHeading': '<h4>Alt. Heading</h4>',
          'font-style-lead': '<h5>Lead</h5>',
          'font-style-normalText': '<p>Normal Text</p>',
          'font-style-blockquote': '<blockquote>Blockquote</blockquote>',
        },
        callback: function (cmd: string, val: string) {
          if (cmd === 'customParagraphStyle') {

            if(val === 'font-style-title')
            {
              this.paragraphFormat.apply('h1');
            }
            else if(val === 'font-style-subtitle')
            {
              this.paragraphFormat.apply('h2');
            }
            else if(val === 'font-style-heading')
            {
              this.paragraphFormat.apply('h3');
            }
            else if(val === 'font-style-alternativeHeading')
            {
              this.paragraphFormat.apply('h4');
            }
            else if(val === 'font-style-lead')
            {
              this.paragraphFormat.apply('h5');
            }
            else if(val === 'font-style-normalText')
            {
              this.paragraphFormat.apply('p');
            }
            else if(val === 'font-style-blockquote')
            {
              this.paragraphFormat.apply('blockquote');
            }
          }
        },
        // Callback on refresh.
        refresh: function ($btn) { },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) { }
      });

      FroalaEditor.DefineIcon('customList', {NAME: 'list', template: 'font_awesome'});
    FroalaEditor.RegisterCommand('customList', {
      title: 'Custom List',
      type: 'dropdown',
      focus: true,
      undo: true,
      refreshAfterCallback: false,
      options: {
        'check':"&#xf00c;",
        'square-check':"&#xf14a;",
        'circle-check':"&#xf058;",
        'calendar-check':"&#xf274;",
        'phone':"&#xf095;",
        'mobile':"&#xf10b;",
        'phone-square':"&#xf098;",
        'whatsapp':"&#xf232;",
        'heart':"&#xf004;",
        'star':"&#xf005;",
        'arrow-right':"&#xf061;",
        'chevron-right':"&#xf054;",
        'angle-right':"&#xf105;",
        'share':"&#xf064;",
        'long-arrow-right':"&#xf178;",
        'dot-circle':"&#xf192;",
        'hand-point-right':"&#xf0a4;",
        'lightbulb':"&#xf0eb;",
        'bolt':"&#xf0e7;",
        'times':"&#xf00d;",
        'times-circle':"&#xf057;",
        'book':"&#xf02d;",
        'quote-left':"&#xf10d;",
      },
      callback: function (cmd, val) {
      this.format.apply('ul', {class: `cvt-clist ${val}`})
      },
      // Callback on refresh.
      refresh: function ($btn) {
      },
      // Callback on dropdown show.
      refreshOnShow: function ($btn, $dropdown) {
      }
    });



      this.froala = new FroalaEditor("#description-froala-editor", this.froalaConfig, function () { })
    },
    getHexColors () {
      // Convert THEME_RGBA_COLOR to HEX format
      let hexColors = Object.keys(this.colors).map((key) => {
        let r = this.colors[key]['color'][0]
        let g = this.colors[key]['color'][1]
        let b = this.colors[key]['color'][2]
        return rgbToHex(r, g, b)
      })
      
      return hexColors
    },
    getFontSizes () {
      return FONT_SIZES.map(fontSize => {
        return fontSize.value
      })
    },
    getFontFamily () {
      let fontFamily = {}
      this.availableFonts.forEach(font => {
        fontFamily[`${font.familyName}, ${font.genericFamily}`] = font.familyName
      })
      return fontFamily
    },
    contentsChanged () {
      let htmlStr = this.froala.html.get(true)
      this.$emit('siteDescriptionChange', { value: htmlStr })
    },
  }
}