import {BackgroundShapeSVGObject} from "../BackgroundShapeSVGObject";

const TiltSVG =
    `
<svg data-name="Tilt" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="fill"></path>
</svg>
`


export const TiltShape = new BackgroundShapeSVGObject({
    name: "Tilt",
    canInvert: true,
    canFlip: true,
    svg: TiltSVG
})