import Vue from '../../../lib/utils/vue'
import { styled, VueEmotion } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'

import CvtImageThumbnail from '../../molecules/ImageThumbnail'
import CvtSiteStatusLabel from '../../molecules/SiteStatusLabel'
import CvtButton from '../../molecules/Button'
import VueI18n from 'vue-i18n'

Vue.use(VueEmotion)
Vue.use(VueI18n)

let Spacing,
  Radii,
  Border,
  Colors,
  textLineHeight,
  BorderStyle,
  textFontWeight,
  borderColor,
  backgroundColor,
  textFontSize,
  textColor

const getContainer = () => styled('div')`
  ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
  width: ${3 * Spacing.SPACING_96 + Spacing.SPACING_12}px;
  padding: ${Spacing.SPACING_18 + Spacing.SPACING_4}px
    ${Spacing.SPACING_32 + Spacing.SPACING_4}px 0px
    ${Spacing.SPACING_32 + Spacing.SPACING_4}px;
`

const getInnerContainer = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: ${Spacing.SPACING_32 + Spacing.SPACING_8}px;
  border-bottom: 2px solid ${Colors.BASIC[200]};
`

const getImgThumbAndLabelContainer = () => styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${Spacing.SPACING_128 + Spacing.SPACING_12}px;
`

const getImageThumbnail = () => styled(CvtImageThumbnail)`
  padding-top: ${Spacing.SPACING_2}px;
  margin-bottom: ${Spacing.SPACING_12 + Spacing.SPACING_8}px;
`

const getSiteStatusLabel = () => styled(CvtSiteStatusLabel)``

const getMainButtonsContainer = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${2 * Spacing.SPACING_96 - Spacing.SPACING_2}px;
  margin-top: ${Spacing.SPACING_16 + Spacing.SPACING_4}px;
`

const getInnerButtonsContainer = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${Spacing.SPACING_16}px;
`

const getCopyTemplateButton = () => styled(CvtButton)``

const getEditSiteButton = () => styled(CvtButton)`
  margin: 0px ${Spacing.SPACING_4}px 0px ${Spacing.SPACING_4}px;
`

const getArchiveButton = () => styled(CvtButton)`
  margin: 0px ${Spacing.SPACING_4}px 0px ${Spacing.SPACING_4}px;
`

const getAnalyticsButton = () => styled(CvtButton)`
  margin: ${Spacing.SPACING_18}px 0px 0px 0px;
`

export const CvtSiteThumbnailAndActions = {
  name: 'CvtSiteThumbnailAndActions',
  data() {
    return {}
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
      required: false,
    },
    imgSrc: {
      type: String,
      required: true,
    },
    imgAltText: {
      type: String,
      required: true,
    },
    imgWidth: {
      type: Number,
      required: false,
      default: 140,
    },
    imgHeight: {
      type: Number,
      required: false,
      default: 116,
    },
    siteStatus: {
      type: String,
      required: true,
    },
    siteWidth: {
      type: Number,
      required: false,
      default: 87,
    },
    siteHeight: {
      type: Number,
      required: false,
      default: 26,
    },
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textColor: getters.GLOBAL_STYLE_COLOR,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
    }),

    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),

    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      FontWeight: ({ globalTheme }) => globalTheme.FontWeight,
      BorderStyle: ({ globalTheme }) => globalTheme.BorderStyle,
    }),

    analyticsEnabled() {
      if (!this.config) return
      return (
        this.config.SIDEBAR_ANALYTICS_ENABLED === true ||
        this.config.SIDEBAR_ANALYTICS_ENABLED == 'true'
      )
    },
    cloneEnabled() {
      if (!this.config) return
      // This is a rough implementation to custom user permission for a client.
      // This should be removed after we have setup a clear permission system in the builder.
      return this.config.ADD_ON_NAME !== 'CONVRRT_LITE'
    },
    archiveEnabled() {
      if (!this.config) return
      // This is a rough implementation to custom user permission for a client.
      // This should be removed after we have setup a clear permission system in the builder.
      return this.config.ADD_ON_NAME !== 'CONVRRT_LITE'
    },
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    Colors = this.Colors
    BorderStyle = this.BorderStyle

    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    textColor = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
  },
  methods: {
    copyTemplateBtnClicked(event) {
      this.$emit('copyTemplateBtnClicked', event)
    },

    editSiteBtnClicked(event) {
      this.$emit('editSiteBtnClicked', event)
    },

    archiveTemplateBtnClicked(event) {
      this.$emit('archiveTemplateBtnClicked', event)
    },

    analyticsBtnClicked(event) {
      this.$emit('analyticsBtnClicked', event)
    },
  },

  render: function (h) {
    const Container = getContainer()
    const InnerContainer = getInnerContainer()
    const ImgThumbAndLabelContainer = getImgThumbAndLabelContainer()
    const ImageThumbnail = getImageThumbnail()
    const SiteStatusLabel = getSiteStatusLabel()

    const MainButtonsContainer = getMainButtonsContainer()
    const InnerButtonsContainer = getInnerButtonsContainer()

    const CopyTemplateButton = getCopyTemplateButton()
    const EditSiteButton = getEditSiteButton()
    const ArchiveButton = getArchiveButton()
    const AnalyticsButton = getAnalyticsButton()

    return (
      <Container>
        <InnerContainer>
          <ImgThumbAndLabelContainer>
            <ImageThumbnail
              src={this.imgSrc}
              alt={this.imgAltText}
              width={this.imgWidth}
              height={this.imgHeight}
            ></ImageThumbnail>

            <SiteStatusLabel
              status={this.siteStatus}
              width={this.siteWidth}
              height={this.siteHeight}
            ></SiteStatusLabel>
          </ImgThumbAndLabelContainer>

          <MainButtonsContainer>
            {this.cloneEnabled ? (
              <CopyTemplateButton
                text={this.$t('marketplace.templates.label.copyTemplate')}
                icon={'clone'}
                size={'md'}
                mode={this.mode}
                color={''}
                specialPadding={`${Spacing.SPACING_8 + Spacing.SPACING_4}px ${
                  Spacing.SPACING_8 + Spacing.SPACING_4 + Spacing.SPACING_2
                }px`}
                prepend={true}
                onClick={(event) => this.copyTemplateBtnClicked(event)}
              ></CopyTemplateButton>
            ) : (
              <CopyTemplateButton
                text={this.$t('marketplace.templates.label.copyTemplate')}
                icon={'clone'}
                size={'md'}
                disabled={true}
                mode={this.mode}
                color={''}
                specialPadding={`${Spacing.SPACING_8 + Spacing.SPACING_4}px ${
                  Spacing.SPACING_8 + Spacing.SPACING_4 + Spacing.SPACING_2
                }px`}
                prepend={true}
              ></CopyTemplateButton>
            )}

            <InnerButtonsContainer>
              <EditSiteButton
                text={this.$t('marketplace.templates.label.editTemplate')}
                icon={'pencil'}
                size={'xs'}
                mode={this.mode.toLowerCase()}
                color={this.mode.toLowerCase()}
                specialPadding={`${Spacing.SPACING_4 + Spacing.SPACING_2}px ${
                  Spacing.SPACING_8 + Spacing.SPACING_2
                }px`}
                actionIcon={false}
                icononly={false}
                prepend={true}
                outlined={true}
                onClick={(event) => this.editSiteBtnClicked(event)}
              ></EditSiteButton>

              {this.archiveEnabled ? (
                <ArchiveButton
                  text={this.$t('marketplace.templates.archive.label')}
                  icon={'archive'}
                  size={'xs'}
                  mode={this.mode.toLowerCase()}
                  color={this.mode.toLowerCase()}
                  specialPadding={`${Spacing.SPACING_4 + Spacing.SPACING_2}px ${
                    Spacing.SPACING_8 + Spacing.SPACING_2
                  }px`}
                  actionIcon={false}
                  icononly={false}
                  prepend={true}
                  outlined={true}
                  onClick={(event) => this.archiveTemplateBtnClicked(event)}
                ></ArchiveButton>
              ) : (
                <ArchiveButton
                  text={this.$t('marketplace.templates.archive.label')}
                  icon={'archive'}
                  size={'xs'}
                  mode={this.mode.toLowerCase()}
                  color={this.mode.toLowerCase()}
                  specialPadding={`${Spacing.SPACING_4 + Spacing.SPACING_2}px ${
                    Spacing.SPACING_8 + Spacing.SPACING_2
                  }px`}
                  disabled={true}
                  actionIcon={false}
                  icononly={false}
                  prepend={true}
                  outlined={true}
                ></ArchiveButton>
              )}
            </InnerButtonsContainer>

            {this.analyticsEnabled && (
              <AnalyticsButton
                text={this.$t('marketplace.templates.label.analytics')}
                size={'xs'}
                icon={'analytics'}
                mode={this.mode.toLowerCase()}
                color={this.mode.toLowerCase()}
                fullWidth={true}
                actionIcon={false}
                icononly={false}
                prepend={true}
                outlined={true}
                onClick={(event) => this.analyticsBtnClicked(event)}
              ></AnalyticsButton>
            )}
          </MainButtonsContainer>
        </InnerContainer>
      </Container>
    )
  },
}
