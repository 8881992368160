<template>
  <div>
    <h1>ItemList</h1>
    <div
      v-for="(listThumnailOrIcon, index) in listThumbnailIcon"
      :key="index"
    >
      <span>{{ index }}</span>
      <cvt-list
        :items="items"
        :remove-enabled="listThumnailOrIcon == 'thumbnail'"
        :thumbnails-enabled="listThumnailOrIcon == 'thumbnail'"
        :icons-enabled="listThumnailOrIcon == 'icon'"
        thumbnail-prop="thumbnail"
        title-prop="title"
        description-prop="description"
        fallback-icon="check"
        :fallback-thumbnail="'http://longwoodinteriors.com/wp-content/uploads/2014/04/placeholder3.png'"
        end-icon="check"
        :is-card="listThumnailOrIcon == 'thumbnail'"
        :show-separator="listThumnailOrIcon == 'thumbnail'"
        @remove="onRemove"
      >
        <template #noItemAvailable>
          <span>{{ customMessage }}</span>
        </template>
      </cvt-list>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PlayItemList',
  components: {
  },
  data () {
    return {
      listThumbnailIcon: {
        Default: '',
        Thumbnail: 'thumbnail',
        Icon: 'icon'
      },
      items: [
        {
          thumbnail:
            'https://ae01.alicdn.com/kf/HTB1gXaPXffsK1RjSszgq6yXzpXau/Mochila-Original-Xiaomi-mi-colorida-10L-tama-o-peque-o-hombro-8-colores-165g-peso-deporte.jpg_50x50.jpg',
          title: 'this is a very long wrapping list item text',
          description: 'this is short description 1'
        },
        {
          thumbnail:
            'https://ae01.alicdn.com/kf/HTB1gXaPXffsK1RjSszgq6yXzpXau/Mochila-Original-Xiaomi-mi-colorida-10L-tama-o-peque-o-hombro-8-colores-165g-peso-deporte.jpg_50x50.jpg',
          title: 'Second listed item',
          description: 'this is short description 2'
        },
        {
          thumbnail:
            'https://ae01.alicdn.com/kf/HTB1gXaPXffsK1RjSszgq6yXzpXau/Mochila-Original-Xiaomi-mi-colorida-10L-tama-o-peque-o-hombro-8-colores-165g-peso-deporte.jpg_50x50.jpg',
          title: 'Third listed item',
          description: 'this is short description 3'
        },
        {
          thumbnail:
            'https://ae01.alicdn.com/kf/HTB1gXaPXffsK1RjSszgq6yXzpXau/Mochila-Original-Xiaomi-mi-colorida-10L-tama-o-peque-o-hombro-8-colores-165g-peso-deporte.jpg_50x50.jpg',
          title: 'Fourth listed item',
          description: 'this is short description 4'
        }
      ]
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    onRemove (item) {
      this.items.splice(
        this.items.findIndex((sItem) => sItem.name === item.name),
        1
      )
    }
  },
}
</script>
<style lang="scss">
</style>
