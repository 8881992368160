<template>
  <div
    :id="vnodeId"
    data-identity="CImage"
    :data-vnode-id="vnodeId"
    class="cvt-editor-image"
    :class="classes"
    :style="mainWrapStyles"
    draggable="true"
    @click="select"
    @mouseover="target"
    @contextmenu.prevent.stop="showContextMenu"
    @dblclick.stop.prevent="triggerOpenAssetManager"
    @dragstart="dragstart"
    @dragend="dragend"
  >
    <drag-and-drop-zone :z-index="dropzoneZIndex" @dropped="addElementToSide" />

    <div v-if="!isGridBuilder" :class="imgWrapperClassesOB">
      <a 
        v-if="href" 
        :href="href" 
        target="_blank"
      >
        <img
          class="img-fluid"
          :class="imgClasses"
          :style="imgStylesOB"
          :src="altSrc"
          :alt="alt"
        />
      </a>
      <img
        v-else
        class="img-fluid"
        :class="imgClasses"
        :style="imgStylesOB"
        :src="altSrc"
        :alt="alt"
      />
    </div>
    
    <div v-else-if="isGridBuilder" :class="imgWrapperClassesGB" :style="imgWrapperStyles">
      <a 
        v-if="href" 
        :href="href" 
        target="_blank"
        class="img-fluid"
        :class="imgClasses"
        :style="imgStylesGB"
      >
      </a>

      <div
        v-else
        class="img-fluid"
        :class="imgClasses"
        :style="imgStylesGB"
      />
    </div>

    <template v-if="showActionButtonsOnClick && !isGridBuilder">
      <!--THis is only here because of freshwork in v1 gridbuilder,
      once they migrate to v2 or v3, it will become irrelevant-->
      <action-buttons
        v-if="selected"
        @duplicate="duplicateAndValidate"
        @remove="removeAndValidate"
      />
    </template>
    <span :style="captionStyle">{{ caption }}</span>
  </div>
</template>

<script>
import FbVNode from '../../base/FbVNode.vue'

const IMG_ALIGNMENT_OB = {
  'mx-auto': 'text-center',
  'ml-auto': 'text-right',
  'mr-auto': 'text-left',
}

const IMG_ALIGNMENT_GB = {
  'ml-auto': 'justify-content-end',
  'mx-auto': 'justify-content-center',
  'mr-auto': 'justify-content-start',
}

export default FbVNode.extend({
  props: {
    src: String,
    width: Number,
    shadow: String, // shadow or shadown--deep
    radius: String, // rounded or circle
    alt: String,
    href: String,
    caption: String,
    hideImgOverflow: {
      type: Boolean,
      default: false
    },
    anchor: String,
    action: String,
    popup: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      name: 'CImage'
    }
  },
  computed: {
    mainWrapStyles() {
      const flexStyle = {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '7px',
      }

      if(this.isGridBuilder) {
        return {
          height: '100%',
          width: '100%',
          ...flexStyle,
        }
      }

      return { ...flexStyle,}
    },
    imgWrapperClassesOB() {
      return {
        [this.stylesheet.classes.spacing]: true,
        [IMG_ALIGNMENT_OB[this.bootstrapAlignment]]: true,
      }
    },
    imgWrapperClassesGB() {
      return {
        [this.stylesheet.classes.spacing]: true,
        ['d-flex']: true,
        [IMG_ALIGNMENT_GB[this.bootstrapAlignment]]: true,
      }
    },
    imgWrapperStyles() {
      return {
        height: '100%',
        width: '100%',
        minWidth: '50px',
        minHeight: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
    },
    imgClasses() {
      return {
        [this.shadow]: this.shadow?.length,
        [this.radius]: this.radius?.length,
      }
    },
     captionStyle() {
      return {
        fontSize: '13px',
        textAlign: 'center'
      }
    },
    // Styles for OldBuilder
    imgStylesOB() {
      return {
        width: this.width ? `${this.width}%` : null,
      }
    },
    // Styles for GridBuilder
    imgStylesGB() {
      return {
        backgroundImage: `url(${this.altSrc})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: (this.hideImgOverflow ? 'cover': 'contain'),
        height: this.width ? `${this.width}%` : null,
        width: this.width ? `${this.width}%` : null,
      }
    },
    altSrc() {
      return this.src || '/img/img-placeholder.svg'
    },
    styles() {
      return {
        spacing: {
          ...this.spacingStyle,
        },
      }
    },
  },
  methods: {
    updateSrc(assets) {
      if (!assets) return
      this.updateProp('src', assets.image ? assets.image : assets.pop())
    },
    triggerOpenAssetManager() {
      if (this.isGridBuilder) {
        return
      }
      this.openAssetManager(this.updateSrc)
    },
  },
})
</script>

<style lang="scss">
@import '@/scss/utils';
.cvt-editor-image {
  position: relative;

  // if div#page-engine has grid-builder class only then apply this drag-and-drop-handle style
  .grid-builder & {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
}

.img-fluid {
  position: relative;

  @media (max-width: 768px) {
    width: inherit;
  }
}
</style>
