
<div class="config-dns-wrap">
  <div class="heading-n-desc-wrap">
    <div class="main-heading-wrap">
      <h3 class="main-heading">Setup CNAME and TXT records</h3>
    </div>

    <div class="main-desc-wrap">
      <p class="main-desc">
        This is a one-time setup and should take no longer than 15 minutes of
        your time.
      </p>
    </div>
  </div>
  <div class="custom-hostname-collapse-wrapper">
    <cvt-collapse :accordion="true" :prepend-chevron="false">
      <div
        v-for="(hostName, id) in customHostNames"
        :key="id"
        class="s-environment-content-inner-wrap"
      >
        <cvt-collapse-item
          :special-style="true"
          :initial-active="id === 0 && true"
          :title="
            capitalizeFirstLetter(hostName.environment) + ' Environment'
          "
          :name="id"
          special-font-family="Nunito"
        >
          <div class="py-3">
            <div class="s-environment-content-wrap">
              <h3 class="url-heading">
                {{ capitalizeFirstLetter(hostName.environment) }} Environment
                URL: <span>{{ hostName.url }}</span>
              </h3>

              <div class="cname-n-txt-wrap">
                <div class="cname-wrap">
                  <h5 class="cname-heading">CNAME</h5>

                  <p class="cname-desc-wrap">
                    <span class="cname-desc-one"
                      >CNAME for {{ hostName.fqdn }} should point to:
                    </span>
                    <span class="cname-desc-two">{{ hostName.url }}</span>
                  </p>
                </div>

                <div class="txt-wrap">
                  <h5 class="txt-heading">TXT RECORD</h5>
                  <div>
                    <p class="txt-desc-wrap">
                      <span class="txt-desc-one"
                        >Hostname pre-validation TXT name:
                      </span>
                      <span class="txt-desc-two">{{
                        hostName.ownershipVerification?.name
                      }}</span>
                    </p>

                    <p class="txt-desc-wrap">
                      <span class="txt-desc-one"
                        >Hostname pre-validation TXT value:
                      </span>
                      <span class="txt-desc-two">{{
                        hostName.ownershipVerification?.value
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="verify-c-domains-wrap">
                <h3 class="sub-heading">
                  Before clicking the VERIFY CUSTOM HOSTNAMES
                </h3>
                <a
                  class="check-dns-link"
                  :href="whatsMyDNSCNAME(hostName.fqdn)"
                  target="_blank"
                  >CHECK DNS STATUS</a
                >
              </div>
            </div>
          </div>
        </cvt-collapse-item>
        <div class="custom-hostname-collapse-horizontal-border"></div>
      </div>
    </cvt-collapse>
  </div>
  <div class="verify-btn-wrap">
    <cvt-button
      :class="'verify-btn'"
      :color="''"
      :mode="'LIGHT'"
      :text="'Verify Custom Hostnames'"
      :special-padding="'20px 171px'"
      :loading="loading"
      @click.prevent="verifyCustomHostnames"
    >
    </cvt-button>
  </div>
</div>
