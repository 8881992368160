<template>
  <div class="virtual-fields">
    <cvt-select
      mode="dropdown"
      :value="selectedField"
      :label="placeholder"
      @input="(value) => updateSelected(value)"
    >
      <option
        v-for="field in virtualFields"
        :key="field.type"
        :value="field"
        v-text="field.label"
      />
    </cvt-select>
    <cvt-alert class="my-2" color="info" icon="exclamation-triangle">
      <span class="help"
        >Virtual fields is an alternative to custom fields if you are looking to
        trigger automation from a tag on a field. These fields will not sync to
        a contact record.</span
      >
    </cvt-alert>
  </div>
</template>

<script>
import { FIELD_ICON_MAP, VirtualField } from '../../../lib/helper-classes'

export default {
  props: {
    exclude: {
      type: Array,
      default() {
        return []
      },
    },
    placeholder: {
      type: String,
      default: 'Add a virtual field',
    },
  },
  data() {
    return {
      selectedField: null,
      virtualFields: [
        {
          label: 'Drop Down',
          type: 'select',
          icon: FIELD_ICON_MAP['select'],
        },
        {
          label: 'Radio',
          type: 'radio',
          icon: FIELD_ICON_MAP['radio'],
        },
        {
          label: 'Checkbox',
          type: 'checkbox',
          icon: FIELD_ICON_MAP['checkbox'],
        },
        // {
        //   label: 'Hidden',
        //   type: 'hidden',
        //   icon: FIELD_ICON_MAP['text']
        // }
      ],
    }
  },
  computed: {},
  methods: {
    updateSelected(data) {
      // the event fires twice because of element-ui
      // when we unselected the data
      if (data === null) return
      this.selectedField = null
      this.$emit('selected', new VirtualField(data.type))
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

.virtual-fields {
  .el-select {
    width: 100%;
    .el-input__inner {
      border: 3px solid $inf-light-blue;
      &::placeholder {
        text-transform: uppercase;
        color: $inf-light-blue;
      }
    }
  }
  .el-select-group__title {
    font-size: 1rem;
    font-weight: bold;
    background: $dark;
    color: $white;
  }

  .el-select-dropdown__item {
    height: 36px;
    line-height: 0;

    .row {
      height: 100%;
    }

    .material-icons {
      line-height: 1;
    }

    small {
      line-height: 1;
      font-size: 1rem;
    }
  }
}
</style>
