<template>
  <anime
    :enter="enterFieldSettings"
    :leave="leaveFieldSettings"
    :before-enter="beforeEnterFieldSettings"
  >
    <div v-if="visible" class="form-field-settings">
      <!-- LABEL TOGGLE CONTROLS -->
      <div v-if="!changeToggleToCheckBox" class="row mt-3">
        <div class="col">
          <h6 :class="textColorClass">
            {{ $t('editor.right_nav.field_editor.label.options.label') }}
          </h6>
        </div>
        <div class="col d-flex justify-content-end">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="field.labelOn"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('labelOn')"
          />
        </div>
      </div>
      <div v-else-if="labelCheckbox" class="row mt-3">
        <div class="col-1">
          <input
            type="checkbox"
            :checked="field.labelOn"
            @change="() => onSwitchToggle('labelOn')"
          />
        </div>
        <div class="col-10">
          <small :class="textColorClass">{{
            $t(
              'editor.right_nav.field_editor.label.display_option_two.small_text',
            )
          }}</small>
        </div>
      </div>
      <!-- END TOGGLE LABEL CONTROLS -->
      <!-- HIDDEN TOGGLE CONTROLS -->
      <div
        v-if="(isText || isNonText) && !changeToggleToCheckBox"
        class="row mt-1"
      >
        <div class="col">
          <h6 :class="textColorClass">
            {{ $t('editor.right_nav.field_editor.hidden') }}
          </h6>
        </div>
        <div class="col d-flex justify-content-end">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="field.hidden"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('hidden')"
          />
        </div>
      </div>
      <div
        v-else-if="hiddenCheckbox && !field.optionalRequiredAttribute"
        class="row mt-3"
      >
        <div class="col-1">
          <input
            type="checkbox"
            :checked="field.hidden"
            @change="() => onSwitchToggle('hidden')"
          />
        </div>
        <div class="col-10">
          <small :class="textColorClass">{{
            $t('editor.right_nav.field_editor.hidden')
          }}</small>
        </div>
      </div>
      <!-- END TOGGLE HIDDEN CONTROLS -->
      <!-- REQUIRED TOGGLE CONTROLS -->
      <div v-if="!changeToggleToCheckBox" class="row mt-1">
        <div class="col">
          <h6 :class="textColorClass">
            {{ $t('editor.right_nav.field_editor.required') }}
          </h6>
        </div>
        <div class="col d-flex justify-content-end">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="field.required"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('required')"
          />
        </div>
      </div>
      <div
        v-else-if="requiredCheckbox && !field.optionalRequiredAttribute"
        class="row mt-3"
      >
        <div class="col-1">
          <input
            type="checkbox"
            :checked="field.required"
            @change="() => onSwitchToggle('required')"
          />
        </div>
        <div class="col-10">
          <small :class="textColorClass">{{
            $t('editor.right_nav.field_editor.required')
          }}</small>
        </div>
      </div>
      <!-- END REQUIRED TOGGLE CONTROLS -->
      <div v-if="OrgName !== orgNameFreshWork" class="divider" />
      <div v-else class="mt-3" />
      <!-- LABEL INPUT CONTROLS -->
      <div class="row">
        <div class="col-12">
          <cvt-input
            type="text"
            :value="field.label"
            :label="$t(`editor.right_nav.field_editor.label.options.label`)"
            :placeholder="
              $t('editor.right_nav.field_editor.label.options.placeholder')
            "
            @change="({ target }) => change('label', target.value)"
          />
        </div>
      </div>
      <!-- END INPUT LABEL CONTROLS -->
      <div
        v-if="placeholderEnabled && OrgName !== orgNameFreshWork"
        class="divider"
      />
      <div
        v-if="placeholderEnabled && OrgName === orgNameFreshWork"
        class="mt-3"
      />
      <!-- PLACEHOLDER CONTROLS -->
      <div v-if="placeholderEnabled" class="row">
        <div class="col-12">
          <cvt-input
            type="text"
            :value="field.placeholder"
            :label="$t(`editor.right_nav.field_editor.placeholder.label`)"
            :placeholder="$t('editor.right_nav.field_editor.placeholder.text')"
            @change="({ target }) => change('placeholder', target.value)"
          />
        </div>
      </div>
      <!-- END PLACEHOLDER CONTROLS -->
      <div v-if="helpEnabled && OrgName !== orgNameFreshWork" class="divider" />
      <div v-if="helpEnabled && OrgName === orgNameFreshWork" class="mt-3" />
      <!-- HELP TEXT CONTROLS -->
      <div v-if="helpEnabled" class="row mb-3">
        <div class="col-12">
          <div class="help-descriptor">
            <cvt-input
              type="text"
              :value="field.help"
              :label="$t(`element.property.form.option.help.label`)"
              :placeholder="$t('element.property.form.option.help.message')"
              @change="({ target }) => change('help', target.value)"
            />
          </div>
        </div>
      </div>
      <!-- END HELP TEXT CONTROLS -->
      <div v-if="isText && OrgName !== orgNameFreshWork" class="divider" />
      <div v-if="isText && OrgName === orgNameFreshWork" class="mt-3" />
      <!-- DEFAULT CONTROLS -->
      <!-- DATE & TEXT CONTROLS -->
      <div v-if="isText" class="row mb-3">
        <div class="col-12">
          <div class="help-descriptor">
            <el-date-picker
              v-if="isDate"
              type="datetime"
              :placeholder="$t('element.property.form.field.date.placeholder')"
              :editable="false"
              :value="toDate(field.defaultValue)"
              @input="update('defaultValue', dateToString($event))"
            />
            <cvt-input
              v-else-if="isText"
              type="text"
              :value="field.defaultValue"
              :label="$t(`element.property.form.option.default.label`)"
              :placeholder="$t('element.property.form.option.default.message')"
              @change="({ target }) => change('defaultValue', target.value)"
            />
          </div>
        </div>
      </div>
      <div
        v-if="
          (isSelect || isCheckbox) &&
          OrgName !== orgNameFreshWork &&
          hiddenFieldsDefaultsEnabled
        "
        class="divider"
      />
      <div
        v-if="
          (isSelect || isCheckbox) &&
          OrgName === orgNameFreshWork &&
          hiddenFieldsDefaultsEnabled
        "
        class="mt-3"
      />
      <!-- NON TEXT CONTROLS -->
      <div v-if="isWithOptions && hiddenFieldsDefaultsEnabled" class="row">
        <div class="col-12">
          <cvt-select
            type="select"
            :value="field.defaultValue"
            :label="$t(`element.property.form.option.default.label`)"
            :placeholder="$t('element.property.form.option.default.message')"
            :multiple="isSelectMulti"
            @input="(value) => update('defaultValue', value)"
            @cleared="() => clearDefaultValue()"
          >
            <option
              v-for="option in field.options"
              :key="option.label"
              :value="option.label"
              v-text="option.label"
            ></option>
          </cvt-select>
        </div>
      </div>
      <div v-if="isCheckbox && hiddenFieldsDefaultsEnabled" class="row mb-2">
        <div class="col-12">
          <cvt-select
            type="select"
            :value="formatCheckboxDefaultValue"
            :label="$t(`element.property.form.option.default.label`)"
            :placeholder="$t('element.property.form.option.default.message')"
            @input="(value) => update('defaultValue', value === 'option-true')"
            @cleared="() => clearDefaultValue()"
          >
            <option
              key="option-true"
              value="option-true"
              v-text="'true'"
            ></option>
            <option
              key="option-false"
              value="option-false"
              v-text="'false'"
            ></option>
          </cvt-select>
        </div>
      </div>
      <!-- END DEFAULT CONTROLS -->
      <div v-if="isText && OrgName !== orgNameFreshWork" class="divider" />
      <div v-if="isText && OrgName === orgNameFreshWork" class="mt-3" />
      <!-- URL PARAM CONTROLS -->
      <div v-if="isText" class="row mb-3">
        <div class="col">
          <h6 :class="textColorClass">
            {{ $t('element.property.form.option.url_param.label') }}
            <cvt-popover
              ref="urlParamHelp"
              placement="top"
              target="urlParamHelp"
              triggers="hover"
              :mode="mode"
            >
              <template #popper>
                <h4>
                  {{ $t('element.property.form.field.url.message.header') }}
                </h4>
                <p>{{ $t('element.property.form.field.url.message.one') }}</p>
                <p>{{ $t('element.property.form.field.url.message.two') }}</p>
                <p>{{ $t('element.property.form.field.url.message.three') }}</p>
                <img
                  class="img-fluid mb-3"
                  :src="urlExample"
                  alt="Example url params"
                />
                <p>
                  {{ $t('element.property.form.field.url.message.four') }}
                  {{ $t('element.property.form.field.url.message.five') }}
                  <br />{{ $t('element.property.form.field.url.message.six') }}
                </p>
              </template>
            </cvt-popover>
            <span
              v-if="OrgName === orgNameFreshWork"
              id="urlParamHelp"
              v-popover:urlParamHelp
            >
              <cvt-icon :icon="'fw-icon-circle-info'" />
            </span>
            <i
              v-else
              id="urlParamHelp"
              v-popover:urlParamHelp
              class="fa fa-info-circle"
            />
          </h6>
        </div>
        <div class="col-12">
          <div class="help-descriptor">
            <cvt-input
              type="text"
              :value="field.urlKey"
              :label="$t('element.property.form.option.url_param.label')"
              :no-label="true"
              :placeholder="
                $t('element.property.form.option.url_param.message')
              "
              @change="({ target }) => change('urlKey', target.value)"
            />
          </div>
        </div>
      </div>
      <!-- END URL PARAM CONTROLS -->
      <!-- ADDRESS CONTROLS -->
      <div v-if="isAddress" class="divider" />
      <div v-if="isAddress" class="row mb-3">
        <div class="col-12">
          <div class="help-descriptor">
            <select
              v-model="selectedAddressFormat"
              class="form-control"
              :label="$t('element.property.form.field.address.label.header')"
            >
              <option
                v-for="format in addressFormats"
                :key="format.id"
                :value="format.id"
                v-text="format.label"
              />
            </select>
          </div>
        </div>
      </div>
      <div v-if="isAddress" class="divider" />
      <div v-if="isAddress" class="row mb-3">
        <div class="col">
          <h6 :class="textColorClass">
            {{ $t('element.property.form.field.address.default.message') }}
          </h6>
        </div>
        <div class="col-12">
          <div class="help-descriptor">
            <country-subdivision-selector
              :default-country="field.country"
              :default-subdivision="field.subdivision"
              @country-subdivision-change="addressDefaultsChange"
            />
          </div>
        </div>
      </div>
      <!-- END ADDRESS CONTROLS -->
      <!-- TAG CONTROLS -->
      <div v-if="appliesTag(field)" class="divider" />
      <div v-if="appliesTag(field)" class="row mb-3">
        <div class="col">
          <div class="row">
            <div class="col">
              <h6 :class="textColorClass">
                {{ $t('editor.right_nav.tags.h6_text') }}
              </h6>
              <small>{{ $t('editor.right_nav.tags.small_text') }}</small>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <crm-tags
                :tags="field.tags || []"
                :placeholder="$t('editor.right_nav.tags.placeholder')"
                @update-tags="updateFieldTags"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- END TAG CONTROLS -->
      <!-- CUSTOM FIELD CONTROLS -->
      <div
        v-if="ShowMultiSelectWarningMessage && canUseOptions(field)"
        class="divider"
      />
      <div
        v-if="ShowMultiSelectWarningMessage && canUseOptions(field)"
        class="field-choice mb-4"
      >
        <h6 :class="textColorClass">
          {{ $t('element.property.form.field.custom.label') }}
        </h6>
        <p v-if="field.custom" style="color: red">
          {{ $t('element.property.form.field.custom.help.message') }}

          <a
            v-if="config.EXTERNAL_CUSTOM_FIELDS_LINK"
            :href="config.EXTERNAL_CUSTOM_FIELDS_LINK"
            target="_blank"
            >{{ $t('editor.right_nav.custom.p.a') }}</a
          >
        </p>

        <div
          v-if="isAddOptionEnabled && (field.virtual || canUseOptions(field))"
          class="row no-gutters my-2 mb-5"
        >
          <div class="col">
            <button
              class="btn btn-sm btn-outline-success text-capitalize btn-block"
              @click="addOption(field)"
            >
              {{ $t('element.property.form.field.custom.cta.label') }}
            </button>
          </div>
        </div>

        <div v-for="opt in field.options" :key="opt.id" class="no-gutters mb-2">
          <div class="col mb-1">
            <el-input
              :value="opt.label"
              type="text"
              :disabled="field.custom"
              @input="(data) => updateOptionLabel(opt.id, data)"
            />
          </div>

          <div class="col-8 justify-content-start field-choice-actions">
            <cvt-popover
              v-if="
                config.CRM_FORM_FIELD_TAGS_UI_ENABLED || canUseOptions(field)
              "
              :ref="`${opt.id}-crm-tag-popover`"
              :mode="mode"
              placement="right"
              triggers="click"
              :target="`${opt.id}-crm-tag-popover`"
            >
              <template #popper>
                <div>
                  <h6 :class="textColorClass"><strong v-text="opt.label" /></h6>
                  <p>{{ $t('editor.right_nav.crm_tags.popover.p') }}</p>
                  <crm-tags
                    :tags="opt.tags || []"
                    :placeholder="$t('editor.right_nav.tags.placeholder')"
                    @update-tags="updateOptionTags(opt.id, $event)"
                  />
                  <div class="actions">
                    <cvt-button
                      color=""
                      size="sm"
                      shape="rounded"
                      :text="$t('element.property.cta.cancel')"
                      @click="closePopover(opt.id)"
                    ></cvt-button>
                  </div>
                </div>
              </template>
            </cvt-popover>

            <div class="mt-1 field-choice-actions">
              <div
                v-if="config.CRM_FORM_FIELD_TAGS_UI_ENABLED"
                :id="`${opt.id}-crm-tag-popover`"
                class="d-flex align-items-center pointer mr-5"
              >
                <i class="fa fa-tags mr-1"></i>
                <small class="text-capitalize">{{
                  $t('editor.right_nav.crm_tags.field_choice_actions.tags')
                }}</small>
              </div>
              <div
                v-if="field.virtual || canUseOptions(field)"
                class="d-flex align-items-center pointer mr-5"
                @click="showDeleteDialog(opt)"
              >
                <i class="fa fa-remove mr-1"></i>
                <small class="text-capitalize">{{
                  $t('editor.right_nav.crm_tags.field_choice_actions.delete')
                }}</small>
              </div>
            </div>
          </div>
        </div>
        <cvt-dialog
          :show="deleteDialog"
          size="sm"
          :height-auto="true"
          @close="deleteDialog = false"
        >
          <template #title>
            <h5>{{ `Delete ${currentOption.label}` }}</h5>
          </template>
          <cvt-alert color="warning" icon="exclamation-triangle">
            <p>
              {{
                $t(
                  'editor.right_nav.crm_tags.virtual_field.delete_dialog.alert.p',
                )
              }}
            </p>
          </cvt-alert>

          <template #modalFooter>
            <cvt-button
              :text="$t('marketplace.dialog.archive.cta.cancel')"
              v-bind="cancelBtnProps"
              @click="deleteDialog = false"
            />
            <cvt-button
              :text="$t('marketplace.dialog.archive.cta.submit')"
              v-bind="submitBtnProps"
              @click="removeOption"
            />
          </template>
        </cvt-dialog>
      </div>
      <!-- END CUSTOM FIELD CONTROLS -->
    </div>
  </anime>
</template>

<script>
import * as _ from 'lodash'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import {
  FRESHWORKS,
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../../storybook/components/constants'
import urlExample from '../../../../../static/img/url-param-example.png'
import {
  DATE_TYPES,
  FIELD_TYPES,
  VirtualFieldOption,
} from '../../../lib/helper-classes'
import * as getters from '../../../store/modules/getters'
import CrmTags from './CRMTags.vue'
import CountrySubdivisionSelector from './CountrySubdivisionSelector.vue'

const addressFormats = [
  {
    id: 'full_address',
    label: 'Full Address',
  },
  {
    id: 'city_state',
    label: 'City, State',
  },
  {
    id: 'city_state_zip',
    label: 'City, State, Zip',
  },
  {
    id: 'state_country',
    label: 'State, Country',
  },
  {
    id: 'state',
    label: 'State (Only)',
  },
  {
    id: 'zip',
    label: 'Zip Code (Only)',
  },
  {
    id: 'country',
    label: 'Country (Only)',
  },
]

const TEXT_TYPES = ['email', 'text', 'tel', 'url', 'date', 'datetime-local']

const NON_TEXT_TYPES = ['number', 'radio', 'checkbox', 'select', 'selectMulti']

const WITH_OPTION_TYPES = ['radio', 'select', 'selectMulti']

export default {
  components: {
    CrmTags,
    CountrySubdivisionSelector,
  },
  props: {
    field: {
      type: Object,
      default() {},
    },
    visible: {
      type: Boolean,
    },
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  data() {
    return {
      urlExample,
      addressFormats,
      fieldTypes: FIELD_TYPES,
      enterFieldSettings: {
        opacity: [0, 1],
        duration: 500,
        height: '100%',
        easing: 'easeInOutQuart',
      },
      leaveFieldSettings: {
        opacity: [1, 0],
        duration: 500,
        height: '0px',
        easing: 'easeInOutQuart',
      },
      beforeEnterFieldSettings(e) {
        e.style.opacity = 0
        e.style.height = 0
      },
      deleteDialog: false,
      currentOption: '',
      orgNameFreshWork: LEFT_SIDEBAR_FRESHWORKS,
    }
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    ...mapState('globalTheme', {
      orgName: ({ globalTheme }) =>
        globalTheme.OrgName != null ? globalTheme.OrgName : 'standard',
      deleteColorCancelTheme: ({ globalTheme }) =>
        globalTheme.deleteColorCancelTheme != null
          ? globalTheme.deleteColorCancelTheme
          : 'warning',
      iconConfig: ({ iconConfig }) => iconConfig,
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
      changeToggleToCheckBox: ({ globalTheme }) =>
        globalTheme.changeToggleToCheckBox,
      labelCheckbox: ({ globalTheme }) => globalTheme.labelCheckbox,
      hiddenCheckbox: ({ globalTheme }) => globalTheme.hiddenCheckbox,
      requiredCheckbox: ({ globalTheme }) => globalTheme.requiredCheckbox,
      showCheckboxToHideNonMandatoryField: ({ globalTheme }) =>
        globalTheme.showCheckboxToHideNonMandatoryField,
      ShowMultiSelectWarningMessage: ({ globalTheme }) =>
        globalTheme.ShowMultiSelectWarningMessage !== undefined
          ? globalTheme.ShowMultiSelectWarningMessage
          : true,
    }),
    selectedAddressFormat: {
      get() {
        return this.field.format
      },
      set(value) {
        this.change('format', value)
      },
    },
    isAddress() {
      return this.field.type === 'address'
    },
    isText() {
      return _.includes(TEXT_TYPES, this.field.type)
    },
    isNonText() {
      return _.includes(NON_TEXT_TYPES, this.field.type)
    },
    isCheckbox() {
      return this.field.type === 'checkbox'
    },
    isSelect() {
      return this.field.type === 'select'
    },
    isWithOptions() {
      return _.includes(WITH_OPTION_TYPES, this.field.type)
    },
    isDate() {
      return _.includes(DATE_TYPES, this.field.type)
    },
    placeholderEnabled() {
      return !this.isDate && !this.isCheckbox
    },
    helpEnabled() {
      return !this.isDate && !this.isCheckbox
    },
    textColorClass() {
      return { 'text-white': this.mode == 'DARK' }
    },
    hiddenFieldsDefaultsEnabled() {
      return this.config.HIDDEN_FIELD_DEFAULTS_ENABLED
    },
    formatCheckboxDefaultValue() {
      if (!this.field.defaultValue) {
        return this.field.defaultValue
      }
      return `option-${this.field.defaultValue.toString()}`
    },
    isSelectMulti() {
      return this.field.type === 'selectMulti'
    },
    isFreshworksOrg() {
      return this.orgName === FRESHWORKS
    },
    cancelBtnProps() {
      if (this.isFreshworksOrg) {
        return { color: 'light' }
      } else {
        return { color: this.deleteColorCancelTheme }
      }
    },
    submitBtnProps() {
      if (this.isFreshworksOrg) {
        return {
          color: '',
          specialStyle: 'dark',
        }
      } else {
        return {
          color: 'light',
        }
      }
    },
    isAddOptionEnabled() {
      return this.config.ADD_VIRTUAL_FIELD_OPTIONS_ENABLED
    },
  },
  methods: {
    addressDefaultsChange({ country, subdivision }) {
      console.log('change country', JSON.stringify(country))
      console.log('change subdivision', JSON.stringify(subdivision))
      this.$emit('change', {
        ...this.field,
        country,
        subdivision,
      })
    },
    subdivisionChange(subdivision) {
      this.update('subdivision', subdivision)
    },
    appliesTag(field) {
      return (
        this.config.CRM_FORM_FIELD_TAGS_UI_ENABLED &&
        field.virtual &&
        field.type === 'checkbox'
      )
    },
    toDate(v) {
      return v ? moment(v).toDate() : v
    },
    dateToString(d) {
      if (!d) return d
      return moment(d).format('YYYY-MM-DDThh:mm:ss')
    },
    change: _.throttle(function (key, value) {
      console.debug('changed field value', key, value)
      this.update(key, value)
    }, 600),
    update(key, value) {
      this.$emit('change', {
        ...this.field,
        // clear default value
        defaultValue: '',
        [key]: value,
      })
    },
    updateDebounced: _.debounce(function (key, value) {
      this.update(key, value)
    }, 800),
    updateFieldTags(tags) {
      this.update('tags', tags)
    },
    updateOptionTags(optionId, tags) {
      let option = _.find(this.field.options, {
        id: optionId,
      })

      option.tags = tags
      this.update('options', this.field.options)
    },
    canUseOptions(field) {
      return (
        (field.virtual || field.options || this.isWithOptions) &&
        field.type !== 'checkbox'
      )
    },
    addOption(field) {
      if (field.options === undefined) {
        field.options = []
      }
      const newList = [...field.options, new VirtualFieldOption()]
      this.update('options', newList)
    },
    updateOptionLabel(optionId, data) {
      const updatedOptions = this.field.options.map((option) => {
        if (option.id === optionId) {
          return { ...option, label: data, value: data }
        }
        return option
      })
      this.updateDebounced('options', updatedOptions)
    },
    showDeleteDialog(option) {
      this.deleteDialog = true
      this.currentOption = option
    },
    removeOption() {
      const updatedOptions = this.field.options.filter(
        (option) => option.id !== this.currentOption.id,
      )
      // update options
      this.update('options', updatedOptions)

      this.currentOption = ''
      this.deleteDialog = false
    },
    onSwitchToggle(property) {
      if (property === 'labelOn') {
        // eslint-disable-next-line vue/no-mutating-props
        this.field.labelOn = !this.field.labelOn
        this.update(property, this.field.labelOn)
      } else if (property === 'hidden') {
        // eslint-disable-next-line vue/no-mutating-props
        this.field.hidden = !this.field.hidden
        this.update(property, this.field.hidden)
      } else if (property === 'required') {
        // eslint-disable-next-line vue/no-mutating-props
        this.field.required = !this.field.required
        this.update(property, this.field.required)
      }
    },
    closePopover(optId) {
      this.$refs[optId + '-crm-tag-popover'][0].$refs.tooltip.doClose()
    },
    updateSelectDefaultValue(value) {
      this.update('defaultValue', value)
    },
    clearDefaultValue() {
      this.update('defaultValue', '')
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

// .form-field-settings {
//   padding: 2em;
// }

.field-choice-actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  i {
    cursor: pointer;
  }
}
</style>
