import { CvtLeftNavBar } from './left-nav'

export const CvtAdminLeftNavBar = {
  name: 'CvtAdminLeftNavBar',
  data() {
    return {
      activeId: this.initialActiveId,
      adminNavItems: [
        {
          id: 'templates',
          icon: 'border-all',
          label: 'Templates',
          routeName: 'Templates'
        },
        {
          id: 'branding',
          icon: 'heart',
          label: 'Branding',
          routeName: 'Branding'
        },
        {
          id: 'configurations',
          icon: 'hexagon',
          label: 'Configurations',
          routeName: 'Configurations'
        },
        {
          id: 'documentation',
          icon: 'align-left',
          label: 'Documentation',
          routeName: 'Documentation'
        },
        {
          id: 'release-notes',
          icon: 'pen',
          label: 'Release Notes',
          routeName: 'ReleaseNotes'
        }
      ]
    }
  },
  computed: {},
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
    initialActiveId: {
      type: String,
      default: ''
    }
  },
  methods: {
    onNavItemClicked(item) {
      this.$router.push({ name: item.routeName })
    }
  },
  created() {},
  render: function (h) {
    return (
      <CvtLeftNavBar
        style='top: 64px'
        usage='adminDashboard'
        navigationItems={this.adminNavItems}
        initialActiveId={this.initialActiveId}
        mode={this.mode}
        onNavItemClicked={this.onNavItemClicked}
      />
    )
  }
}
