import {PageEngineCommands} from "./PageEngineCommands";
import {CreateWidgetFromFirebaseNode} from "../components/v3/lib/vue-dom-obj";
import {FBVNodeComponent} from "../../../types";
import {VDomCommands} from "./VDomCommand";
import {ConvrrtFirebase} from "../convrrt-firebase";

/**
 * Handles Section Add Actions
 */
export class AddSectionCommand extends PageEngineCommands {
    private _widget: object;
    private _addedNodeSection?: FBVNodeComponent

    constructor(sectionNode: any) {
        super();
        this._widget = CreateWidgetFromFirebaseNode(sectionNode, true)
        // _addedNodeSection: for the purpose of redo and undo,
        // we want to track already created vueElement, just in case they are part of another undo or redo action
        this._addedNodeSection = undefined
    }

    get$emitData() {
        return  {name: 'AddSectionCommand', forceBuild: false}
    }

    get hasChanges () {
        return true
    }

    async execute() {
        this.firebaseRef?.push(this._widget).then(() => {
            this.$nextTick!(() => {
                this._addedNodeSection = this.$children[this.$children.length - 1]
                this._addedNodeSection.$el.scrollIntoView({
                    behavior: 'smooth',
                })
            })
        })
    }

    async unExecute() {
        if (this._addedNodeSection) {
            await this.firebaseRef?.removeNode(this._addedNodeSection.idx())
            this._addedNodeSection = undefined
        }
    }
}

/**
 * Handles SubNode Add Actions
 */
export class AddSubNodeCommand extends VDomCommands {
    private readonly _widget: object;
    private _addedNodeSection?: FBVNodeComponent

    private readonly _firebaseRef: ConvrrtFirebase;
    private readonly validationFunc?: (node: any) => boolean

    constructor(rootKey: string, sectionNode: object, validationFunc?: (node: any) => boolean) {
        super("");
        this._firebaseRef = new ConvrrtFirebase(rootKey)
        this._widget = CreateWidgetFromFirebaseNode(sectionNode, true)
        // _addedNodeSection: for the purpose of redo and undo,
        // we want to track already created vueElement, just in case they are part of another undo or redo action
        this._addedNodeSection = undefined
        this.validationFunc = validationFunc
    }

    get$emitData() {
        return  {name: 'AddSubNodeCommand', forceBuild: false}
    }

    get firebaseRef(): ConvrrtFirebase {
        return this._firebaseRef
    }

    get hasChanges () {
        return true
    }

    async execute() {
        if (this.validationFunc) {
            const snap = await this.firebaseRef.load()
            if(!this.validationFunc(snap)) return
        }
        await this.firebaseRef?.push(this._widget)
    }

    async unExecute() {
        if (this._addedNodeSection) {
            await this.firebaseRef?.removeNode(this._addedNodeSection.idx())
            this._addedNodeSection = undefined
        }
    }
}