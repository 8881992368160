
<div>
  <form @submit.prevent="save">
    <div v-show="currentStep === 'general'">
      <div class="form-group">
        <label for="page-name">{{ $t('pagemanager.label.page.name') }}</label>
        <cvt-input
          type="text"
          :value="page.pageName"
          @input="(e) => updatePageSetting('pageName', e)"
        />
      </div>
       <div class="form-group">
        <label for="page-name">{{ $t('pagemanager.label.slug.text') }}</label>
        <cvt-input
         class="mb-3"
          type="text"
          :placeholder="$t('pagemanager.input.text.pagename')"
          :disabled="page.isIndex"
          :value="page.name"
          @input="(e) => updatePageSetting('name', e)"
        />
        {{ $t('pagemanager.label.slug.text') }}
        <strong class="text-muted">
          <span class="help">/</span>
          <span class="help" v-text="pageSlug" />
        </strong>
        <cvt-alert
          v-if="OrgName !== orgNameFreshWork"
          class="w-100"
          color="info"
          icon="exclamation-triangle"
        >
          {{ $t('pagemanager.slug.help.message') }}
        </cvt-alert>
      </div>
      <div class="form-group">
        <label for="page-name">{{
          $t('pagemanager.input.placeholder.pagename')
        }}</label>
        <cvt-input
          type="textarea"
          :placeholder="$t('pagemanager.label.seo')"
          :value="page.description"
          @input="(e) => updatePageSetting('description', e)"
        />
      </div>
      <div class="form-group">
        <input
          v-if="changeToggleToCheckBox"
          id="allowRobotsOnThisPageId"
          v-model="allowRobotsOnThisPage"
          type="checkbox"
        />
        <label class="ml-1" for="page-meta">{{
          $t('pagemanager.textarea.placeholder.description')
        }}</label>
        <cvt-switch
          v-if="!changeToggleToCheckBox"
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="allowRobotsOnThisPage"
          :mode="mode"
          @switchToggle="() => onSwitchToggle('allowRobotsOnThisPage')"
        />
        <cvt-button class="mt-2" color="light" text="Add Script" @click="AddScriptAction" />
      </div>
    </div>
    <div v-show="currentStep === 'script'">
      <div class="">
        <cvt-button color="light" text="back" @click="backAction" />
        <hr />
        <PageTracking />
      </div>
    </div>
  </form>

  <div style="width:370px;bottom:40px;" class="row justify-content-between position-absolute px-2">
    <cvt-button
    class="px-5"
      color="light"
      :loading="saving"
      :outlined="true"
      :text="$t('element.property.cta.cancel')"
      @click="handleClose"
    />
    <cvt-button
    class="px-5"
      :loading="saving"
      :text="$t('element.property.cta.save')"
      :special-style="saveBtnStyle"
      @click="saveAndClose"
    />
  </div>
</div>
