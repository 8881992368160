import Vue from '../../../lib/utils/vue'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import { mergeData } from 'vue-functional-data-merge'
import { CvtLink, propsFactory as linkPropsFactory } from '../link/link'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'

let FontFamily, Spacing, Radii, Border
let color, textFontSize

Vue.use(VueEmotion)

export const props = linkPropsFactory()

const getCustomListItem = () => styled('li')`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  ${textFontSize('md')}
  font-family: ${FontFamily.DEFAULT}
`

const getCustomCvtLink = () => styled(CvtLink)`
  display: block;
  padding: ${Spacing.SPACING_12}px ${Spacing.SPACING_18}px;
  border: ${Border.SIZE_1}px solid transparent;
  border-top-left-radius: ${Radii.RADIUS_PX_8}px;
  border-top-right-radius: ${Radii.RADIUS_PX_8}px;
  &:hover,
  :focus {
    text-decoration: none;
  }
  ${(attrs) => {
    let disabledStyles = ``
    if (attrs.props.disabled) {
      disabledStyles += `${color({ themeing: 'dark' }, 400)}  
         pointer-events: none;
         cursor: default;`
    }
    return disabledStyles
  }}
`
// @vue/component
export const CvtNavItem = {
  name: 'CvtNavItem',
  props: {
    ...props,
    linkAttrs: {
      type: Object,
      default: () => {}
    },
    linkClasses: {
      type: [String, Object, Array],
      default: null
    },
    styleProp: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textColor: getters.GLOBAL_STYLE_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border
    })
  },
  created () {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    color = this.textColor
    textFontSize = this.textFontSize
  },
  render: function(h) {
    // We transfer the listeners to the link
    const customListItem = getCustomListItem()
    const customCvtLink = getCustomCvtLink()

    let mergedData = mergeData({}, {
      style: {
        ...this.styleProp,
        marginRight: `${Spacing.SPACING_4}px`
      }
    })
    let CvtLinkData = {
      class: this.linkClasses,
      attrs: this.linkAttrs,
      props: this.$props
    }

    return (
      <customListItem {...mergedData}>
        <customCvtLink {...CvtLinkData} attrs={CvtLinkData}>
          {this.$slots.default}
        </customCvtLink>
      </customListItem>
    )
  }
}
