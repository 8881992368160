<template>
  <div class="product-wrapper">
    <cvt-button
        :outlined="true"
        :full-width="true"
        shape="rounded"
        color = "light"
        :mode="mode"
        text="Attach a product"
        @click="openProductsDialog"
      />
  </div>
</template>

<script>
  import * as getters from '../../../store/modules/getters'
  import * as actions from '../../../store/modules/actions'
  import { mapGetters, mapActions, mapMutations } from 'vuex'

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Product',
    computed: {
      ...mapGetters({
        selected: getters.SELECTOR_SELECTED
      })
    },
    methods: {
      ...mapActions({
        updateProp: actions.SELECTOR_UPDATE_PROP
      }),
      ...mapMutations('eCommerce', ['openProductsDialog'])
    }
  }
</script>
