import * as mutations from '../modules/mutations'
import _ from 'lodash';
import * as actions from '../modules/actions'
import { database } from '@/js/store/services/firebase.service'
import {cloneVDom, fixBlocksObjectData} from "../../components/v3/lib/vue-dom-obj";

const fbDB = database()

class UndoRedoItem {
  async undo() {}

  async redo() {}
}

class DeltaRefChangeUndoRedoItem extends UndoRedoItem {
  constructor(currentSnapShot, previousSnapShot) {
    super()
    this._currentSnapShot = currentSnapShot
    this._previousSnapShot = previousSnapShot
  }

  async undo() {
    return this._previousSnapShot
  }

  async redo() {
    return this._currentSnapShot
  }
}

class ViewModeUndoRedoItem extends UndoRedoItem {
  constructor(currentViewMode, previousViewMode, currentSnapShot) {
    super()
    this._currentSnapShot = currentSnapShot
    this._currentViewMode = currentViewMode
    this._previousViewMode = previousViewMode
  }

  async undo({ dispatch, commit }) {
    await this.handleAction(dispatch, this._previousViewMode)
    return this._currentSnapShot
  }

  async redo({ dispatch, commit }) {
    await this.handleAction(dispatch, this._currentViewMode)
    return this._currentSnapShot
  }

  async handleAction(dispatch, viewMode) {
    await dispatch('loader/start', `loader.viewmode.undo.redo.item`)
    await dispatch(actions.CHANGE_VIEW_MODE, viewMode)
    setTimeout(async () => {
      dispatch('loader/stop')
    }, 4000)
  }
}

export class PopUpUndoRedoManager {
  constructor(dispatch, commit, path, popUpIndex) {
    this.currentSnapshot = null
    this._undoStack = []
    this._redoStack = []
    this.path = path
    this.deltaRef = undefined
    this.dispatch = dispatch
    this.commit = commit
    this.listen = false
    this._initialized = false
    if (![undefined, null].includes(popUpIndex)) {
      this.initDBRef(popUpIndex)
    }
  }

  get initialized () {
    return this._initialized
  }

  get canUndo() {
    return this._undoStack.length - 1 > 0
  }

  get canRedo() {
    return this._redoStack.length > 0
  }

  get nodes() {
    if (!this.currentSnapshot) return []

    return [this.currentSnapshot.val()]
  }

  getHandlerContext() {
    return { dispatch: this.dispatch, commit: this.commit }
  }

  async undoHandler(levels = 1) {
    if (!this.canUndo) return

    const undoRedoItem = this._undoStack.pop()
    const ctx = this.getHandlerContext()
    this.listen = false

    const snapshot = await undoRedoItem.undo(ctx)

    this.deltaRef.set(_.omit(snapshot.val(), ['.key', '.path'])).then(() => {
      this.currentSnapshot = snapshot
      this._redoStack.push(undoRedoItem)
      this.listen = true
    })
  }

  async apply(action)  {
    if (this.listen === false) return

    this.commit(mutations.HISTORY_IGNORE)

    if (action === 'undo' && this.canUndo) {
      await this.undoHandler()
    }

    if (action === 'redo' && this.canRedo) {
      await this.redoHandler()
    }
  }

  async redoHandler(levels = 1) {
    if (!this.canRedo) return

    const undoRedoItem = this._redoStack.pop()
    const ctx = this.getHandlerContext()
    this.listen = false

    const snapshot = await undoRedoItem.redo(ctx)
    this.deltaRef.set(_.omit(snapshot.val(), ['.key', '.path'])).then(() => {
      this.currentSnapshot = snapshot
      this._undoStack.push(undoRedoItem)
      this.listen = true
    })
  }

  stateChanged(snapshot) {
    if (!this.listen) return

    const undoRedoItem = new DeltaRefChangeUndoRedoItem(
      snapshot,
      this.currentSnapshot,
    )
    this.currentSnapshot = snapshot

    this._undoStack.push(undoRedoItem)
    this._redoStack = []
  }

  viewModeChanged(currentViewMode, previousViewMode) {
    if (!this.listen) return

    const undoRedoItem = new ViewModeUndoRedoItem(
      currentViewMode,
      previousViewMode,
      this.currentSnapshot,
    )
    this._undoStack.push(undoRedoItem)
    this._redoStack = []
  }

  initDBRef(popUpIndex) {
    this.deltaRef = fbDB.ref(this.path + '/' + popUpIndex + '/children/0')
    this.deltaRef.once('value', (snapshot) => {
      this._redoStack = []
      this._undoStack = []
      this.stateChanged(snapshot)
      this._initialized = true
    })
  }

  unsubscribe() {
    this.deltaRef.off('value')
    this.listen = false
  }

  subscribe() {
    this.deltaRef.on(
      'value',
      (snapshot) => {
        this.stateChanged(snapshot)
      },
      function (errorObject) {
        console.debug('The read failed: ' + errorObject.code)
      },
    )
    this.listen = true
  }
}


export class PopUpUndoRedoManagerV3 {
  constructor(dispatch, commit, path, popUpIndex) {
    this._undoCommands = []
    this._redoCommands = []
    this.path = path
    this.deltaRef = undefined
    this.dispatch = dispatch
    this.commit = commit
    this.listen = false
    this._initialized = false
    this._clonedVDom = []
    this._popUpIndex = popUpIndex
    this._cPopupData = {}
    if (![undefined, null].includes(popUpIndex)) {
      this.initDBRef()
    }
  }
  get initialized () {
    return this._initialized
  }
  clear () {
    this._undoCommands = []
    this._redoCommands = []
  }

  get VDom() {
    return this._clonedVDom
  }

  get cPopupData() {
    return this._cPopupData
  }

  getHandlerContext() {
    return { dispatch: this.dispatch, commit: this.commit }
  }

  get canUndo () {
    return this._undoCommands.length > 0;
  }

  get canRedo () {
    return this._redoCommands.length > 0;
  }

  async redoHandler(levels = 1){
    for (let i = 1; i <= levels; i++) {
      if (this._redoCommands.length !== 0) {
        const command = this._redoCommands.pop();
        await command.execute();
        this._undoCommands.push(command);
      }
    }
  }

  async undoHandler(levels = 1) {
    for (let i = 1; i <= levels; i++) {
      if (this._undoCommands.length !== 0) {
        const command = this._undoCommands.pop();
        await command.unExecute();
        this._redoCommands.push(command);
      }
    }
  }

  async apply(action)  {
    if (action === 'undo' && this.canUndo) {
      await this.undoHandler()
    }

    else if (action === 'redo' && this.canRedo) {
      await this.redoHandler()
    }
  }

  async pushCommand (command) {
    this._undoCommands.push(command);
    await command.execute()
    this._redoCommands = []
    command.updateVDom('execute')
  }

  viewModeChanged(currentViewMode, previousViewMode) {
  }

  initDBRef() {
    const rootPath = this.path + '/' + this._popUpIndex
    this.deltaRef = fbDB.ref(rootPath)
    this.deltaRef.once('value', (snapshot) => {
      this.clear()
      const val = snapshot.val()
      if (val) {
        this._clonedVDom = []
        if (val.children) {
          this._clonedVDom = cloneVDom(val.children, `${rootPath}/children`)
          this._cPopupData = {data: val.data, tag: val.tag}
        }
        this._initialized = true
      }
    })
  }

  unsubscribe() {
    this.deltaRef.off('value')
    this.listen = false
  }

  subscribe() {
    const rootPath = this.path + '/' + this._popUpIndex
    this.deltaRef.on(
        'value',
        (snapshot) => {
          const val = snapshot.val()
          let _clonedVDOM = []
          if (val) {
            if (val.children) {
              _clonedVDOM = cloneVDom(val.children, `${rootPath}/children`)
            }
            this._cPopupData = {data: val.data, tag: val.tag}
          }
          this._clonedVDom = _clonedVDOM
          this.listen = true
        },
        function (errorObject) {
          console.debug('The read failed: ' + errorObject.code)
        },
    )
  }
}