interface ConstuctorOptions {
    name: string, 
    canInvert: boolean, 
    canFlip: boolean, 
    svg?: string, 
    isFullHeight?: boolean,
    adjustHeight?: boolean,
    adjustWidth?: boolean,
    showPositionToggle?: boolean
    adjustHeightInPercentage?: boolean
    adjustWidthInPercentage?: boolean
}
interface BackgroundShapeInterface extends ConstuctorOptions{
    constructor: Function
}


export class BackgroundShapeSVGObject implements BackgroundShapeInterface{
    public name: string;
    public canInvert: boolean;
    public canFlip: boolean;
    public svg?: string;
    public isFullHeight?: boolean;
    public adjustHeight?: boolean;
    public adjustWidth?: boolean;
    public showPositionToggle?: boolean;
    public adjustHeightInPercentage?: boolean;
    public adjustWidthInPercentage?: boolean;

    constructor(override: ConstuctorOptions, defaults: ConstuctorOptions = {name: 'no-name', canInvert: false, canFlip: false, svg: '', isFullHeight: false, adjustHeight: true, adjustWidth: true, showPositionToggle: true, adjustHeightInPercentage: false, adjustWidthInPercentage: false}) {
        const options = { ...defaults, ...override }
        this.name = options.name
        this.canInvert = options.canInvert
        this.canFlip = options.canFlip
        this.svg = options.svg
        this.isFullHeight = options.isFullHeight
        this.adjustHeight = options.adjustHeight
        this.adjustWidth = options.adjustWidth
        this.showPositionToggle = options.showPositionToggle
        this.adjustHeightInPercentage = options.adjustHeightInPercentage
        this.adjustWidthInPercentage = options.adjustWidthInPercentage
    }

}