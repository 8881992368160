<script lang="jsx">
//@ts-nocheck
import { customClientConfig } from '@/js/utils/helpers'
import { cvtRouteNodes } from '@/js/utils/routes'
import * as _ from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as actions from '../store/modules/actions'
import * as getters from '../store/modules/getters'
import * as mutations from '../store/modules/mutations'
import ConvrrtLayout from './Layout/ConvrrtEditorLayout/ConvrrtEditorLayout.vue'
import MouseFollower from './MouseFollower.vue'
import PageManager from './PageManager.vue'
import PagePreview from './PagePreview.vue'
import RenderPopupBuilder from './PopUpRenderer.vue'
import ProductsDialog from './ProductsDialog.vue'
import RevisionManager from './RevisionManager.vue'
import RevisionSaveManager from './RevisionSaveManager.vue'
import StartOverDialog from './StartOver/StartOverDialog.vue'
import IntegrationsDialog from './IntegrationsDialog/IntegrationsDialog.vue'
import SubscriptionModal from './SubscriptionModal/SubscriptionModal.vue'
import RightClickMenu from './editor/RightClickMenu.vue'
import AdvancedPopoverMenu from './editor/advanced-popover-menu/AdvancedPopoverMenu.vue'
import LeftSideBar from './editor/left-side-menu/LeftSideBar.vue'
import NameLandingPage from './editor/left-side-menu/NameLandingPage.vue'
import SavePages from './editor/left-side-menu/SavePages.vue'
import SiteSettings from './editor/left-side-menu/SiteSettings.vue'
import GenAIContentDialog from './genAi/GenAIContentDialog.vue'
import BookmarkBlock from './editor/BookmarkBlock.vue'
import PagesBookmarkBlock from './editor/PagesBookmarkBlock.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Editor',
  components: {
    RevisionManager,
    RevisionSaveManager,
    PagePreview,
    RightClickMenu,
    LeftSideBar,
    AdvancedPopoverMenu,
    MouseFollower,
    ProductsDialog,
    GenAIContentDialog,
    PageManager,
    NameLandingPage,
    SiteSettings,
    SavePages,
    RenderPopupBuilder,
    ConvrrtLayout,
    SubscriptionModal,
    StartOverDialog,
    IntegrationsDialog,
    BookmarkBlock,
    PagesBookmarkBlock
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  data() {
    return {
      routeEnter: {
        opacity: [0, 1],
      },
      routeLeave: {
        opacity: [1, 0],
      },
      isOverflown: true,
      prevRoute: null,
      grid: undefined,
      counter: 0,
    }
  },
  computed: {
    isEmpty() {
      return this.vdom.length === 0
    },
    ...mapGetters({
      vdom: getters.DELTA_VDOM,
      deltaRef: getters.DELTA_REF,
      selected: getters.SELECTOR_SELECTED,
      site: getters.SITE_GET_DATA,
      indexPage: getters.SITE_GET_INDEX,
      thankyouPage: getters.SITE_GET_THANKYOU,
      siteGetActivePage: getters.SITE_GET_ACTIVE_PAGE,
      advancedMenuOpen: getters.ADVANCED_MENU_OPEN,
      leftSideMenuOpen: getters.LEFT_SIDE_MENU_OPEN,
      viewmodeClass: getters.VIEW_MODE_CLASS,
      showNameDialog: getters.EDITOR_NAME_DIALOG,
      pagesOpen: getters.LEFT_SIDE_PAGE_OPEN,
      rightSectionsOpen: getters.LEFT_SIDE_SECTION_OPEN,
      config: getters.AUTH_GET_USER_CONFIG,
      surveyOpen: getters.SURVEY_OPEN,
      popupBuilderOpen: getters.POPUP_BUILDER_OPEN,
      currentProductVersionNumber: getters.CURRENT_PRODUCT_VERSION_NUMBER,
      isGridBuilder: getters.IS_GRID_BUILDER,
      showScheduleDemoModal: getters.GET_MODAL,
      viewMode: getters.VIEW_MODE,
    }),
    ...mapState('globalTheme', {
      SideBarLeft: ({ globalTheme }) =>
        globalTheme.SideBarLeft !== undefined ? globalTheme.SideBarLeft : true,
      PagesSidePanelLeft: ({ globalTheme }) =>
        globalTheme.PagesSidePanelLeft !== undefined
          ? globalTheme.PagesSidePanelLeft
          : false,
      AddSectionsBackDrop: ({ globalTheme }) =>
        globalTheme.AddSectionsBackDrop !== undefined
          ? globalTheme.AddSectionsBackDrop
          : false,
    }),
    activePage() {
      return this.siteGetActivePage(this.$route.params.pageId)
    },
    isLaunchPageRoute() {
      return this.$route.name === cvtRouteNodes.editorLaunch
    },
    pageEngineWrapperClasses() {
      const sideBarOpenPosition = this.SideBarLeft
        ? 'page-engine-wrapper--left-side-menu-open'
        : 'page-engine-wrapper--right-side-menu-open'
      const advancedMenuSize = this.SideBarLeft
        ? 'page-engine-wrapper--advanced-menu-open'
        : 'page-engine-wrapper--advanced-menu-open-small-shift'
      let doNotapplyExtraEffect = true
      if (this.PagesSidePanelLeft && this.pagesOpen) {
        doNotapplyExtraEffect = false
      }
      return {
        [this.viewmodeClass]: true,
        [sideBarOpenPosition]: this.leftSideMenuOpen && doNotapplyExtraEffect,
        [advancedMenuSize]:
          this.$route.name !== cvtRouteNodes.editorLaunch
            ? !this.isGridBuilder
              ? this.selected && this.advancedMenuOpen
              : true
            : false,
        'page-engine-wrapper--editor-active':
          this.SideBarLeft && this.showEditorControls,
        'page-engine-wrapper--editor-active-small-scale':
          !this.SideBarLeft && this.showEditorControls,
        'page-engine-wrapper--editor-active-grid-small-scale':
          this.isGridBuilder && this.showEditorControls,
        'page-engine-wrapper--advanced-menu-open-grid':
          this.isGridBuilder && this.showEditorControls,
        // Scale down the page engine for Gridbuilder/Freshwork
        'page-engine-wrapper--editor-active-grid-tiny-scale':
          !this.SideBarLeft && this.isGridBuilder && this.showEditorControls,
        'page-engine-wrapper--right-side-menu-open':
          !this.SideBarLeft && this.isGridBuilder,
      }
    },
    pageEngineWrapperNew() {
      return {
        [this.viewmodeClass]: !this.isLaunchPageRoute,
        'layout-wrapper page-engine-wrapper': true,
      }
    },
    layoutTopMargin() {
      return {
        'container-pd-64': this.SideBarLeft,
        'container-pd-50': !this.SideBarLeft,
      }
    },
    showSectionsBackdrop() {
      return this.AddSectionsBackDrop
        ? {
            backdrop: this.rightSectionsOpen,
          }
        : {}
    },
    changeZIndex() {
      return {
        'change-z-index': this.rightSectionsOpen,
      }
    },
    showEditorControls() {
      const { editorDesignPage, editorThankyouPage } = cvtRouteNodes
      return [editorDesignPage, editorThankyouPage].includes(this.$route.name)
    },
    editingLandingPage() {
      return this.$route.name === cvtRouteNodes.editorDesignPage
    },
    previewTitle() {
      return this.editingLandingPage
        ? this.$t('editor.landing_page_preview')
        : this.$t('editor.landing_page_thankyou')
    },
    previewUrl() {
      let page = this.editingLandingPage ? this.indexPage : this.thankyouPage

      if (this.site.id && page.id) {
        return `/api/v1/sites/${this.site.id}/preview/${page.id}`
      }
      return ''
    },
    showFreeTrial() {
      return this.config.TRIAL_MODE
    },
  },
  mounted() {
    this.bindShortcutKeys()
    customClientConfig(this.site.orgID)
  },
  async created() {
    // Set configurations for tenants if they exist
    this.setTheme(this.config.GLOBAL_THEME)
    this.setTopNavConfig(this.config.TOP_NAV_CONFIG)
    this.setLeftNavConfig(this.config.LEFT_NAV_CONFIG)
    this.setMarketplaceConfig(this.config.MARKETPLACE_CONFIG)
    this.setIconConfig(this.config.ICON_CONFIG)
    this.setGlobalButtonConfig(this.config.GLOBAL_BUTTON_CONFIG)
    this.setGlobalCheckBoxConfig(this.config.GLOBAL_CHECKBOX_CONFIG)
    this.setChatWidgetConfig(this.config?.TENANT_CHAT_WIDGET)
    // Set configurations for tenants End
  },
  methods: {
    ...mapActions('globalTheme', {
      setTheme: 'setTheme',
      setTopNavConfig: 'setTopNavConfig',
      setLeftNavConfig: 'setLeftNavConfig',
      setIconConfig: 'setIconConfig',
      setMarketplaceConfig: 'setMarketplaceConfig',
      setGlobalButtonConfig: 'setGlobalButtonConfig',
      setGlobalCheckBoxConfig: 'setGlobalCheckBoxConfig',
      applyHistory: actions.HISTORY_APPLY,
    }),
    ...mapActions({
      deselect: actions.SELECTOR_DESELECT,
      removeSelected: actions.SELECTOR_REMOVE_SELECTED,
      toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
    }),
    ...mapMutations({
      ignore: mutations.SELECTOR_IGNORE,
      openQuickLaunchBlock: mutations.QUICK_LAUNCH_BLOCK_SET_INSERT_HANDLER,
      hideEditorNameDialog: mutations.HIDE_EDITOR_NAME_DIALOG,
      toggleScheduleDemoModal: mutations.SET_MODAL,
      setChatWidgetConfig: mutations.SET_CHAT_WIDGET_CONFIG,
    }),
    closeDemoModal() {
      this.toggleScheduleDemoModal(false)
    },
    reRenderSiteSettings() {
      this.counter += 1
    },
    bindShortcutKeys() {
      window.addEventListener(
        'keyup',
        (e) => {
          // Escape Key (When Shift, Alt, Control and Commands keys are not pressed and then Escaped key is pressed)
          if (
            !e.ctrlKey &&
            !e.shiftKey &&
            !e.altKey &&
            !e.metaKey &&
            e.key === 'Escape'
          ) {
            this.ignoreDeselectAndToggleLeftSideMenu()
          }
          // Delete|Backspace Key (When Shift, Alt, Control and Commands keys are not pressed and then Delete|Backspace key is pressed)
          else if (
            !e.ctrlKey &&
            !e.shiftKey &&
            !e.altKey &&
            !e.metaKey &&
            (e.key === 'Delete' || e.key === 'Backspace')
          ) {
            if (e.target.tagName === 'BODY') {
              if (this.currentProductVersionNumber >= 3.0) {
                if (this.selected?.name === 'Block') {
                  this.selected?.deleteSection()
                } else {
                  this.selected?.parent()?.removeElement()
                }
              } else {
                // code for old-builder and v2
                this.selected?.removeAndValidate()
              }
              this.ignoreDeselectAndToggleLeftSideMenu()
            }
          }
        },
        { passive: true },
      )
    },
    addBlock(vnode) {
      this.deltaRef.transaction((children) => {
        children = children || []
        children.push(vnode)
        return children
      })
    },
    beforeEnter(e) {
      e.style.opacity = 0
    },
    blur() {
      console.log('blur editor')
    },
    focus() {
      console.log('focus editor')
    },
    ignoreDeselectAndToggleLeftSideMenu() {
      this.deselect()
      this.ignore()
      this.toggleLeftSideMenu({ open: false })
    },
  },
  render(h) {
    const { pageId } = this.$route.params
    let page = null
    if (pageId) {
      page = _.chain(this.$store)
        .get('state.site.site.pages')
        .find({ id: pageId })
        .value()
    }
    const pageEngineWrapperData = {
      class: [this.pageEngineWrapperClasses, 'page-engine-wrapper'],
    }
    const pageEngineWrapperNewClass = {
      class: [this.pageEngineWrapperNew],
    }

    const topMarginLayoutClass = {
      class: [this.layoutTopMargin],
    }
    return (
      <div onMouseOver={this.ignore}>
        <main-nav />
        {!this.surveyOpen && <left-side-bar class={this.changeZIndex} />}
        {!this.surveyOpen &&
          this.$route.name !== cvtRouteNodes.editorLaunch && (
            <advanced-side-menu />
          )}
        {/* Advance Popover Menu*/}
        <advanced-popover-menu />
        <mouse-follower />
        <convrrt-layout
          showLeftSideBar={this.SideBarLeft}
          showEditorControls={this.showEditorControls}
          isGridBuilder={this.isGridBuilder}
          viewMode={this.viewMode}
          {...topMarginLayoutClass}
        >
          {/* Page Engine Wrapper*/}
          <anime
            enter={this.routeEnter}
            leave={this.routeLeave}
            before-enter={this.beforeEnter}
          >
            <div {...pageEngineWrapperNewClass}>
              {!this.surveyOpen && !this.popupBuilderOpen && <router-view />}
              {this.surveyOpen && !this.popupBuilderOpen && (
                <div>
                  <iframe
                    src="https://app.staging.contactsmarter.com/campaigns/aszKPmtLyNnLCAkeyvf5u4/funnel/embed"
                    frameborder="0"
                    style="height:100vh;width:100vw"
                  />
                </div>
              )}
              {/* CPopUp Builder Renderer*/}
              {pageId && page && (
                <RenderPopupBuilder
                  popupPath={`${page.firebaseRef}/popup`}
                  isPopupOpen={this.popupBuilderOpen}
                  pageEngineWrapperClass={[]}
                />
              )}
            </div>
          </anime>
        </convrrt-layout>

        <div class={this.showSectionsBackdrop} />
        <save-block />
        <swap-block />
        <icon-picker />
        <right-click-menu />
        <asset-manager />
        <products-dialog />
        <GenAIContentDialog />
        <revision-manager />
        <revision-save-manager />
        <page-manager />
        <save-pages />
        <name-landing-page
          show={this.showNameDialog}
          onContinue={this.hideEditorNameDialog}
        />
        {this.showScheduleDemoModal && (
          <cvt-modal
            modalHeader={this.$t('marketplace.demo.modal.header.text')}
            textContent={this.$t('marketplace.demo.modal.content.text')}
            btnText={this.$t('marketplace.demo.modal.button')}
            modalWidth={500}
            btnOnClick={this.closeDemoModal}
          />
        )}
        <site-settings
          key={this.counter}
          onRerenderSettings={this.reRenderSiteSettings}
        />
        <subscription-modal />
        <start-over-dialog />
        {this.showFreeTrial && (
          <div class="free-trial-footer-wrapper">
            <cvt-free-trial-footer />
          </div>
        )}

        <integrations-dialog />
        <bookmark-block />
        <pages-bookmark-block />
      </div>
    )
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

.extra-scroll-area {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $medium-gray;
  width: 100%;
}

.free-trial-footer-wrapper {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 5;
}
</style>
