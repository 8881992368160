
<div class="ecomm-products">
  <el-select
    ref="searcher"
    v-model="query"
    remote
    filterable
    size="large"
    value-key="id"
    :remote-method="search"
    :placeholder="placeholder"
    this.query=""
    @change="productSelected"
    @visible-change="search('')"
  >
    <el-option
      v-for="product in productList"
      :key="product.id"
      :label="$options.filters.truncate(product.name, 50)"
      :value="product"
    >
      <span
        style="float: left"
        v-text="$options.filters.truncate(product.name, 50)"
      />
    </el-option>
  </el-select>
</div>
