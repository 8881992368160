<template>
  <div class="form-group">
    <cvt-input
      type="text"
      :value="value"
      :label="label"
      :placeholder="placeholder"
      :clearable="false"
      :validation-status="validationStatus"
      :valid-message="$t('editor.right_nav.url_input.valid_message')"
      :invalid-message="$t('editor.right_nav.url_input.invalid_message')"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import * as _ from 'lodash'
import v from 'validator'

const VALIDATE_URL = {
  rules: {
    required: false,
    url: {
      require_protocol: true
    }
  }
}

// as per the documentation of `validator-plugin`, it does not validate
// the mailto:, tel:, callto:, and sms: uri so custom functions are implemented
// to check the validity of these type of URIs
const DEFAULT_PROTOCOLS = ['http', 'https']

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    allowedProtocols: {
      type: Array,
      default () {
        return DEFAULT_PROTOCOLS
      }
    },
    autofocus: Boolean
  },
  data () {
    return {
      validateUrl: VALIDATE_URL,
    }
  },
  computed: {
    validationStatus: {
      get () {
        return this.isValid(this.value) ? 'valid' : 'invalid'
      },
      set (value) {
        return value
      }
    }
  },
  mounted () {
    this.$validator.validate()
  },
  methods: {
    isValid (uri) {
      let isValidURI = v.isURL(uri, {
        protocols: this.allowedProtocols,
      })

      if (!isValidURI) {
        isValidURI = this.isMailToLink(uri)
      }

      if (!isValidURI) {
        isValidURI = this.isTelephoneLink(uri)
      }

      if (!isValidURI) {
        isValidURI = this.isSmsLink(uri)
      }

      if (!isValidURI) {
        isValidURI = this.isCalltoLink(uri)
      }
      this.$emit('urlIsValid', isValidURI)

      return isValidURI;
    },
    onInput ({ target }) {
      this.validationStatus = this.isValid(target.value) ? 'valid' : 'invalid'
      this.$emit('input', this.fmt(target.value))
    },
    onChange ({ target }) {
      this.$emit('change', this.fmt(target.value))
    },
    onBlur ({ target }) {
      this.$emit('blur', this.fmt(target.value), this.validationStatus)
    },

    /**
     * isHTTPLink
     * covers protocols
     * http://localhost
     * https://localhost
     * //localhost
     */
    isHTTPLink (url = '') {
      return url.startsWith('http://') || url.startsWith('https://') || url.startsWith('//')
    },

    isMailToLink (url = '') {
      return url.startsWith('mailto:')
    },

    isTelephoneLink (url = '') {
      return url.startsWith('tel:')
    },

    isSmsLink (url = '') {
      return url.startsWith('sms:')
    },

    isCalltoLink (url = '') {
      return url.startsWith('callto:')
    },

    fmt (url = '') {
      if (_.isString(url) === false) {
        throw new Error(`URL is not a string ${url}`)
      }

      // if the url contains any protocol then don't format it
      // or isn't a valid url
      url = url.trim()

      // don't validate
      if (!url) {
        return url
      }

      // don't modify is mailto link or tel url
      if (
        this.isMailToLink(url) ||
        this.isTelephoneLink(url) ||
        this.isSmsLink(url) ||
        this.isCalltoLink(url)
      ) {
        return url
      }

      // already included protocol or it's still not valid.
      // don't modify it.
      if (this.isHTTPLink(url) || !v.isURL(url)) {
        return url
      }

      // add basic protocol which inherits browsers proto
      return `http://${url}`
    }
  }
}
</script>
