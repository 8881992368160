<template>
  <cvt-collapse
    :accordion="true"
    :theme="mode === 'LIGHT' ? 'light' : 'dark'"
    :prepend-chevron="prependChevron"
  >
    <cvt-collapse-item
      v-if="config.IS_GEN_AI_ENABLED"
      title="Generative AI"
      :special-style="isSpecialStyle"
    >
      <GenAIOptions :mode="mode" />
    </cvt-collapse-item>
    <cvt-collapse-item
      :title="$t('elements.properties.label')"
      name="properties"
      :initial-active="true"
      :special-style="isSpecialStyle"
    >
      <div class="row no-gutters">
        <div class="col-md-12">
          <cvt-input
            v-model="cta"
            type="text"
            :label="$t('sections.filter.cta')"
            :clearable="false"
          />
        </div>

        <div v-if="isNotFormButton" class="col-md-12 mt-3">
          <cvt-input
            v-model="subtext"
            type="text"
            :label="$t('editor.element.button_subtext')"
            :clearable="false"
          />
        </div>
        <div class="col-md-12 mt-3">
          <cvt-input
            v-model="helper_text"
            type="text"
            :label="$t('editor.element.button_helper_text')"
            :clearable="false"
          />
        </div>
      </div>

      <div
        v-if="isNotFormButton"
        :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
      />

      <div
        v-if="isNotFormButton"
        class="row no-gutters"
        data-identity="normalBtnActionsDropdownWrap"
      >
        <div class="col-md-12">
          <cvt-select
            mode="dropdown"
            :value="action"
            :label="$t(`element.property.button.alt.action`)"
            :theme="mode"
            :background-mode="mode"
            @input="(value) => selectAction(value, 'action')"
          >
            <option
              v-for="optionAction in actions"
              :key="optionAction.value"
              :value="optionAction.value"
              v-text="optionAction.label"
            ></option>
          </cvt-select>
        </div>
      </div>

      <div
        v-if="isNotFormButton && linkActive"
        :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
      />

      <div :class="{ 'subdiv-border': SubDivMargin }">
        <div :class="{ 'subdiv-spacing-left': SubDivMargin }">
          <div
            v-if="linkActive && isNotFormButton"
            class="row no-gutters"
            data-identity="normalBtnAnchorTargetsDropdownWrap"
          >
            <div class="col-md-12">
              <cvt-select
                mode="dropdown"
                :value="anchorTarget"
                :label="$t('element.property.button.alt.target')"
                :background-mode="mode"
                @input="(value) => selectAction(value, 'anchorTarget')"
              >
                <option
                  v-for="target in anchorTargets"
                  :key="target.value"
                  :value="target.value"
                  v-text="target.label"
                ></option>
              </cvt-select>
            </div>
          </div>

          <div
            v-if="isNotFormButton && linkActive"
            :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
          />

          <div v-if="linkActive && isNotFormButton" class="row no-gutters">
            <div class="col-md-12">
              <url-input
                v-model="href"
                placeholder="https://example.com"
                :label="$t('element.property.button.alt.url')"
              />
            </div>
          </div>

          <div
            v-if="isNotFormButton && pageActive"
            :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
          />

          <div
            v-if="pageActive && isNotFormButton"
            class="row no-gutters"
            data-identity="normalBtnPagesDropdownWrap"
          >
            <div class="col-md-12">
              <page-list
                :selected-page="selectedPage"
                @change="updateLinkedPage"
              />
            </div>
          </div>

          <div
            v-if="isNotFormButton && anchorActive"
            :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
          />

          <div v-if="anchorActive && isNotFormButton" class="row no-gutters">
            <div class="col">
              <h6 :class="textColorClass">Anchor To</h6>
            </div>
            <div class="col-md-12">
              <section-list v-model="anchor" :value="anchor" />
            </div>
          </div>

          <div
            v-if="isNotFormButton && popupActive"
            :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
          />

          <div
            v-if="popupActive && isNotFormButton"
            class="row no-gutters"
            data-identity="normalBtnPopupsDropdownWrap"
          >
            <div class="col-md-12">
              <popup-list-alt
                :key="selected.vnodeId"
                :value="popup"
                @configure-popup="configurePopup"
                @delete-popup="removePopup"
                @change="updatePopup"
              />
            </div>

            <div class="col-md-12 justify-content-center">
              <cvt-button
                class="m-1"
                :outlined="true"
                :text="$t('e.popup.create.a.popup')"
                :full-width="true"
                @click="createNewPopup"
              />
            </div>
          </div>
        </div>
      </div>
    </cvt-collapse-item>
    <cvt-collapse-item
      v-if="isNotFormButton && config.ECOMMERCE"
      :title="$t('elements.property.product.message')"
      name="products"
      :special-style="isSpecialStyle"
      data-identity="normalBtnProductsListParentWrap"
    >
      <products-list />
    </cvt-collapse-item>

    <cvt-collapse-item
      title="Style"
      name="style"
      :special-style="isSpecialStyle"
    >
      <div class="row">
        <div class="col-auto">
          <h6 :class="textColorClass">
            {{ $t('elements.properties.color.label') }}
          </h6>
        </div>
        <div>
          <colors :mode="mode" />
        </div>
      </div>
      <div :class="DividerWithBorder ? 'divider' : 'divider-no-border'" />

      <button-outline-options
        v-if="isGridBuilder"
        :mode="mode"
        data-identity="buttonOutlineOptionsParentWrap"
      ></button-outline-options>

      <div
        v-if="isGridBuilder"
        :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
      />

      <div v-if="!changeToggleToCheckBox && !isGridBuilder">
        <div class="row d-flex justify-content-between">
          <div class="col-auto">
            <h6 :class="textColorClass">
              {{ $t('element.property.button.full_width') }}
            </h6>
          </div>
          <div class="col-auto">
            <cvt-switch
              size="sm"
              color="active"
              type="circular"
              description-type="default"
              :initial-state="isBlock"
              :mode="mode"
              @switchToggle="onSwitchToggle"
            ></cvt-switch>
          </div>
        </div>
        <div :class="DividerWithBorder ? 'divider' : 'divider-no-border'" />
      </div>

      <div class="row">
        <div class="col-auto">
          <h6 :class="textColorClass">
            {{ $t('element.property.button.fill.label') }}
          </h6>
        </div>
        <div class="col-12">
          <cvt-radio-group orientation="horizontal" :initial-value="fill">
            <cvt-radio-button
              size="md"
              value="btn-primary"
              :shape="RadioButtonShape"
              @radioClick="updateBtnFill"
            >
              {{ $t('element.property.button.fill.full') }}
            </cvt-radio-button>
            <cvt-radio-button
              size="md"
              value="btn-outline-primary"
              :shape="RadioButtonShape"
              @radioClick="updateBtnFill"
            >
              {{ $t('element.property.button.fill.hollow') }}
            </cvt-radio-button>
          </cvt-radio-group>
        </div>
      </div>

      <div
        v-if="!isGridBuilder"
        :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
      />

      <div v-if="!isGridBuilder" class="row">
        <div class="col-auto">
          <h6 :class="textColorClass">
            {{ $t('element.property.button.size.label') }}
          </h6>
        </div>
        <div class="col-12">
          <cvt-radio-group orientation="horizontal" :initial-value="size">
            <cvt-radio-button
              size="md"
              value="btn-sm"
              :shape="RadioButtonShape"
              @radioClick="updateBtnSize"
            >
              SM
            </cvt-radio-button>
            <cvt-radio-button
              size="md"
              value="btn"
              :shape="RadioButtonShape"
              @radioClick="updateBtnSize"
            >
              MD
            </cvt-radio-button>
            <cvt-radio-button
              size="md"
              value="btn-lg"
              :shape="RadioButtonShape"
              @radioClick="updateBtnSize"
            >
              LG
            </cvt-radio-button>
            <cvt-radio-button
              size="md"
              value="btn-xl"
              :shape="RadioButtonShape"
              @radioClick="updateBtnSize"
            >
              XL
            </cvt-radio-button>
          </cvt-radio-group>
        </div>
        <div v-if="changeToggleToCheckBox" class="col-12">
          <div class="row">
            <div class="col-1">
              <input
                id="fullWidthBox"
                type="checkbox"
                :checked="isBlock"
                @change="onSwitchToggle"
              />
              &nbsp;&nbsp;
            </div>
            <small class="col-10" for="fullWidthBox">{{
              $t('element.property.button.full_width')
            }}</small>
          </div>
        </div>
      </div>

      <div :class="DividerWithBorder ? 'divider' : 'divider-no-border'" />

      <div class="row">
        <div class="col-auto">
          <h6 :class="textColorClass">
            {{ $t('element.property.button.shape.label') }}
          </h6>
        </div>
        <div class="col-12">
          <cvt-radio-group orientation="horizontal" :initial-value="shape">
            <cvt-radio-button
              size="md"
              value="btn-squared"
              :shape="RadioButtonShape"
              @radioClick="updateBtnShape"
            >
              {{ $t('element.property.button.shape.square') }}
            </cvt-radio-button>
            <cvt-radio-button
              size="md"
              value="btn-rounded"
              :shape="RadioButtonShape"
              @radioClick="updateBtnShape"
            >
              {{ $t('element.property.button.shape.round') }}
            </cvt-radio-button>
            <cvt-radio-button
              size="md"
              value="btn-pill"
              :shape="RadioButtonShape"
              @radioClick="updateBtnShape"
            >
              {{ $t('element.property.button.shape.pill') }}
            </cvt-radio-button>
          </cvt-radio-group>
        </div>
      </div>

      <div :class="DividerWithBorder ? 'divider' : 'divider-no-border'" />

      <div class="row">
        <div class="col-auto">
          <h6 :class="textColorClass">
            {{ $t('elements.properties.shadow.normal') }}
          </h6>
        </div>
        <div class="col-12">
          <cvt-radio-group orientation="horizontal" :initial-value="shadow">
            <cvt-radio-button
              size="md"
              value="btn-no-shadow"
              :shape="RadioButtonShape"
              @radioClick="updateBtnShadow"
            >
              {{ $t('elements.properties.option.none') }}
            </cvt-radio-button>
            <cvt-radio-button
              size="md"
              value="btn-shadow"
              :shape="RadioButtonShape"
              @radioClick="updateBtnShadow"
            >
              {{ $t('element.property.button.shadow.light') }}
            </cvt-radio-button>
            <cvt-radio-button
              size="md"
              value="btn-shadow-deep"
              :shape="RadioButtonShape"
              @radioClick="updateBtnShadow"
            >
              {{ $t('element.property.button.shadow.dark') }}
            </cvt-radio-button>
          </cvt-radio-group>
        </div>
      </div>
      <div :class="DividerWithBorder ? 'divider' : 'divider-no-border'" />
      <div class="row mt-2">
        <div class="col">
          <h6 :class="textColorClass">
            {{ $t('element.property.button.icon.toggle') }}
          </h6>
        </div>
        <div class="col-3">
          <cvt-switch
            key="button-icon-toggle"
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="iconEnabled"
            :mode="mode"
            @switchToggle="onButtonIconToggle"
          />
        </div>
      </div>

      <div
        v-if="iconEnabled"
        :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
      />
      <div v-if="iconEnabled" class="row">
        <div class="col-auto">
          <h6 :class="textColorClass">
            {{ $t('element.property.button.icon.position') }}
          </h6>
        </div>
        <div class="col-12">
          <cvt-radio-group
            orientation="horizontal"
            :initial-value="iconPosition"
          >
            <cvt-radio-button
              size="md"
              value="left"
              :shape="RadioButtonShape"
              @radioClick="updateIconPosition"
            >
              {{ $t('element.property.button.icon.position_left') }}
            </cvt-radio-button>
            <cvt-radio-button
              size="md"
              value="right"
              :shape="RadioButtonShape"
              @radioClick="updateIconPosition"
            >
              {{ $t('element.property.button.icon.position_right') }}
            </cvt-radio-button>
          </cvt-radio-group>
        </div>
      </div>
      <div
        v-if="iconEnabled"
        :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
      />
      <div v-if="iconEnabled" class="element-image-manager">
        <div
          class="element-image-manager__preview checkered"
          @mouseover.stop.prevent="showOverlay"
          @mouseleave.stop.prevent="hideOverlay"
          @click.stop.prevent="showIconPicker"
        >
          <div
            v-show="overlayVisible"
            class="element-image-manager__preview__overlay"
          >
            <small
              ><i>{{ assetMgrBtnText }} Icon</i></small
            >
          </div>
          <icon-template
            :set="selected.set"
            :selector="selected.selector"
            class="icon-preview"
          />
        </div>

        <div v-if="isSpecialStyle" class="divider mt-3" />
        <div v-else class="mt-3" />

        <div class="row">
          <div class="col">
            <cvt-button
              :full-width="fullWidth"
              shape="rounded"
              :size="size"
              :color="btnColor"
              :text="replaceBtnText"
              :mode="mode"
              @click.stop.prevent="showIconPicker"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isNotFormButton && !isGridBuilder"
        :class="DividerWithBorder ? 'divider' : 'divider-no-border'"
      />
      <flex-align v-if="isNotFormButton && !isGridBuilder" :mode="mode" />
    </cvt-collapse-item>

    <cvt-collapse-item
      :title="$t('elements.properties.spacing.label')"
      name="spacing"
      :special-style="isSpecialStyle"
    >
      <spacing-ctrl />
    </cvt-collapse-item>

    <cvt-collapse-item
      v-if="isCardButton"
      :title="$t('elements.properties.visibility.label')"
      name="visibility"
      :special-style="isSpecialStyle"
    >
      <visibility-ctrl :mode="mode" />
    </cvt-collapse-item>
  </cvt-collapse>
</template>

<script>
import * as _ from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../../storybook/components/constants'
import {
  Block,
  CPopupBuilder,
  GridContainer,
} from '../../../lib/helper-classes'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'
import * as mutations from '../../../store/modules/mutations'
import PageList from '../../common/PageList.vue'
import PopupListAlt from '../../common/PopupListAlt.vue'
import SectionList from '../../common/SectionList.vue'
import GenAIOptions from '../../genAi/GenAIOptions.vue'
import IconTemplate from '../nodes/icon/IconTemplate.vue'
import Colors from '../theme/Colors.vue'
import ButtonOutlineOptions from './ButtonOutlineOptions.vue'
import FlexAlign from './FlexAlign.vue'
import ProductsList from './ProductsList.vue'
import SpacingCtrl from './SpacingCtrl.vue'
import VisibilityCtrl from './VisibilityCtrl.vue'

import { database } from '@/js/store/services/firebase.service'

const fbDB = database()

export default {
  components: {
    SpacingCtrl,
    FlexAlign,
    SectionList,
    PopupListAlt,
    Colors,
    VisibilityCtrl,
    PageList,
    ProductsList,
    ButtonOutlineOptions,
    IconTemplate,
    GenAIOptions,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    prependChevron: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 'properties',
      anchorTargets: [
        {
          label: this.$t('element.property.button.currrent.window.label'),
          value: '_self',
        },
        {
          label: this.$t('element.property.button.new.tab.label'),
          value: '_blank',
        },
      ],
      overlayVisible: false,
      orgNameFreshWork: LEFT_SIDEBAR_FRESHWORKS,
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      config: getters.AUTH_GET_USER_CONFIG,
      isGridBuilder: getters.IS_GRID_BUILDER,
      popupBuilderOpen: getters.POPUP_BUILDER_OPEN,
      deltaRef: getters.DELTA_SOURCE,
      isStandalonePopupActive: getters.STANDALONE_POPUP_ACTIVE,
      user: getters.AUTH_GET_USER,
    }),
    ...mapState('globalTheme', {
      SubDivMargin: ({ globalTheme }) =>
        globalTheme.SubDivMargin !== undefined
          ? globalTheme.SubDivMargin
          : false,
      changeToggleToCheckBox: ({ globalTheme }) =>
        globalTheme.changeToggleToCheckBox !== undefined
          ? globalTheme.changeToggleToCheckBox
          : false,
      RadioButtonShape: ({ globalTheme }) =>
        globalTheme.RadioButtonShape !== undefined
          ? globalTheme.RadioButtonShape
          : '',
      DividerWithBorder: ({ globalTheme }) =>
        globalTheme.DividerWithBorder !== undefined
          ? globalTheme.DividerWithBorder
          : true,
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
    }),
    actions() {
      return _.omitBy(
        [
          {
            label: this.$t('element.property.button.action.option.url'),
            value: 'link',
          },
          {
            label: this.$t('element.property.button.action.option.anchor'),
            value: 'anchor',
          },
          !this.isStandalonePopupActive
            ? {
                label: this.$t('element.property.button.action.option.funnel'),
                value: 'page',
              }
            : null,
          this.config.ECOMMERCE
            ? {
                label: this.$t('elements.property.button.action.checkout'),
                value: 'checkout',
              }
            : null,
          this.config.INSTAMOJO_PAY_BUTTON
            ? { label: 'Default Payment Link', value: 'instamojo' }
            : null,
          this.config.GUMROAD_BUTTON
            ? { label: 'Gumroad Button', value: 'gumroad' }
            : null,
          this.PopupEnabled && !this.popupBuilderOpen
            ? { label: 'Open in a popup', value: 'popup' }
            : null,
        ],
        _.isNull,
      )
    },
    PopupEnabled() {
      return this.config.POPUP_ENABLED || false
    },
    anchorActive() {
      return this.action === 'anchor'
    },
    linkActive() {
      return this.action === 'link'
    },
    pageActive() {
      return this.action === 'page'
    },
    popupActive() {
      return this.action === 'popup'
    },
    isNotFormButton() {
      return !this.selected.form
    },
    selectedPage() {
      return _.get(this, 'selected.pageId')
    },
    isCardButton() {
      return _.get(this, 'selected.isCardButton', false)
    },
    action: {
      get() {
        return this.selected.action
      },
      set(value) {
        return this.updateProp({ key: 'action', value })
      },
    },
    anchor: {
      get() {
        return this.selected.anchor
      },
      set(value) {
        return this.updateProp({ key: 'anchor', value })
      },
    },
    anchorTarget: {
      get() {
        return this.selected.anchorTarget
      },
      set(value) {
        return this.updateProp({ key: 'anchorTarget', value })
      },
    },
    isBlock: {
      get() {
        return this.selected.isBlock || false
      },
      set(value) {
        return this.updateProp({ key: 'isBlock', value })
      },
    },
    cta: {
      get() {
        return this.selected.cta || ''
      },
      set: _.debounce(function ({ target }) {
        return this.updateProp({ key: 'cta', value: target.value })
      }, 1000),
    },
    subtext: {
      get() {
        return this.selected.subtext || ''
      },
      set: _.debounce(function ({ target }) {
        return this.updateProp({ key: 'subtext', value: target.value })
      }, 1000),
    },
    helper_text: {
      get() {
        return this.selected.helper_text || ''
      },
      set: _.debounce(function ({ target }) {
        return this.updateProp({ key: 'helper_text', value: target.value })
      }, 1000),
    },
    textColorClass() {
      return { 'text-white': this.mode == 'DARK' }
    },
    href: {
      get() {
        return this.selected.href || ''
      },
      set: _.debounce(function (value) {
        this.updateProp({ key: 'href', value })
        return value
      }, 1000),
    },
    fill: {
      get() {
        return this.selected.fill
      },
      set(value) {
        return value
      },
    },
    size: {
      get() {
        return this.selected.size || false
      },
      set(value) {
        return value
      },
    },
    shape: {
      get() {
        return this.selected.shape
      },
      set(value) {
        return value
      },
    },
    shadow: {
      get() {
        return this.selected.shadow || false
      },
      set(value) {
        return value
      },
    },
    isSpecialStyle() {
      return this.config.SIDEBAR_CONFIGURATION
    },
    popup: {
      get() {
        return this.selected.popup
      },
      set(value) {
        if (value !== null) {
          // this.selected.popup = value
          let vnode = value.popupVNode
          let index = value.index
          let name = value.name
          this.updateProp({ key: 'popupId', value: vnode })
          this.updateProp({ key: 'popup', value })
          this.updateProp({ key: 'popupVNode', value: index })
          this.updateProp({ key: 'popupName', value: name })
          this.updateProp({
            key: 'popupToggleScript',
            value: `togglePopup('${vnode}')`,
          })
          this.setPopupIdx(this.selected.$props['popupVNode'])
          this.setPopupPath(this.selected.$props['popupVNode'])
        }
      },
    },
    iconEnabled: {
      get() {
        return this.selected.iconEnabled || false
      },
      set(value) {
        return this.updateProp({ key: 'iconEnabled', value })
      },
    },
    iconPosition: {
      get() {
        return this.selected.iconPosition || 'left'
      },
      set(value) {
        return this.updateProp({ key: 'iconPosition', value })
      },
    },
    assetMgrBtnText() {
      return this.$t('element.property.image.replace')
    },
    fullWidth() {
      return !this.config.SIDEBAR_CONFIGURATION
    },
    btnColor() {
      return this.config.SIDEBAR_CONFIGURATION ? 'link' : 'success'
    },
    replaceBtnText() {
      return this.config.SIDEBAR_CONFIGURATION
        ? this.$t('element.property.image.replace') + ' icon'
        : this.$t('element.property.image.replace')
    },
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
      togglePopup: actions.POPUP_BUILDER_TOGGLE,
      pushNewPopup: actions.POPUP_BUILDER_PUSH_NEW_BLOCK,
      setPopupPath: actions.POPUP_SET_PATH,
      deletePopupData: actions.POPUP_BUILDER_DELETE_POPUP_DATA,
    }),
    ...mapMutations({
      setPopupIdx: mutations.SET_POPUP_IDX,
      setPopupEmpty: mutations.SET_POPUP_EMPTY,
      deselectItem: mutations.SELECTOR_DESELECT,
      ignore: mutations.SELECTOR_IGNORE,
      showIconPicker: mutations.ICON_PICKER_SHOW,
    }),
    async configurePopup() {
      if (this.selected.$props['popupVNode'] !== -1) {
        this.setPopupIdx(this.selected.$props['popupVNode'])
        this.setPopupPath(this.selected.$props['popupVNode'])

        this.fetchPopupChildren().then((resp) => {
          this.setPopupEmpty(resp)
          this.togglePopup()
          this.deselectItem()
        })
      } else {
        console.log('CButtonMenuOptions: Popup is NOT set ')
      }
    },
    fetchPopupChildren() {
      return new Promise((resolve, reject) => {
        if (this.popupIdx !== -1) {
          let buttonPath = this.path()
          let sitePath = buttonPath.split('/vdom')[0]
          // let path = sitePath + "/popup/" + this.selected.$props['popupVNode'] + "/children/0/children/0"
          let path = sitePath + '/popup/' + this.selected.$props['popupVNode']
          let value = fbDB.ref(path)
          value.once('value').then((value) => {
            let data = value.val()
            if (
              data['children']?.[0]?.['children']?.[0]?.['children'] ===
              undefined
            ) {
              resolve(true)
            } else {
              resolve(false)
            }
          })
        } else {
          resolve(false)
        }
      })
    },
    path() {
      return this.selected['.path']
    },
    removePopup(popup) {
      if (this.popupVNode != -1) {
        let buttonPath = this.path()
        let sitePath = buttonPath.split('/vdom')[0]

        let rf = this.deltaRef.ref(sitePath + '/popup/' + popup.index)
        rf.remove()
        let buttonDataPath = buttonPath + '/data/props/'
        this.deltaRef.ref(buttonDataPath + 'popup').remove()
        this.deltaRef.ref(buttonDataPath + 'popupId').remove()
        this.deltaRef.ref(buttonDataPath + 'popupName').remove()
        this.deltaRef.ref(buttonDataPath + 'popupToggleScript').remove()
        this.deltaRef.ref(buttonDataPath + 'popupVNode').remove()
        this.setPopupIdx(-1)
        this.setPopupPath(-1)
        this.ignore()
        this.deselectItem()
        this.deletePopupData(popup.index)
      }
    },
    createNewPopup() {
      const gridContainer = new Block(true)
      gridContainer.children.push(new GridContainer())

      const popUpWrapper = new CPopupBuilder()
      const popUpWrapperId = popUpWrapper.data.props.vnodeId
      popUpWrapper.children.push(gridContainer)
      this.pushNewPopup(popUpWrapper)
        .then(async (d) => {
          const lastIdx = d.snapshot.val().length - 1
          this.setPopupIdx(lastIdx)
          this.setPopupPath(lastIdx)

          this.updateProp({ key: 'popupVNode', value: lastIdx })
          this.updateProp({
            key: 'popupId',
            value: popUpWrapperId,
          })
          this.updateProp({
            key: 'popupToggleScript',
            value: `togglePopup('${popUpWrapperId}')`,
          })
          this.updateProp({
            key: 'popup',
            value: {
              index: lastIdx,
              name: `popup ${lastIdx}`,
              popupVNode: popUpWrapperId,
            },
          })
        })
        .then(() => {
          this.togglePopup()
          this.deselectItem()
        })
    },
    updateLinkedPage(value) {
      this.updateProp({ key: 'pageId', value })
    },
    updateBtnFill(value) {
      this.fill = value
      this.updateProp({ key: 'fill', value })
    },
    updateBtnSize(value) {
      this.size = value
      this.updateProp({ key: 'size', value })
    },
    updateBtnShape(value) {
      this.shape = value
      this.updateProp({ key: 'shape', value })
    },
    updateBtnShadow(value) {
      this.shadow = value
      this.updateProp({ key: 'shadow', value })
    },
    updatePopup(value) {
      this.popup = value
      this.updateProp({ key: 'popup', value })
    },
    onSwitchToggle(data) {
      this.isBlock = !this.isBlock
    },
    selectAction(value, computedProperty) {
      if (computedProperty === 'action') this.action = value
      if (computedProperty === 'anchorTarget') this.anchorTarget = value
    },
    onButtonIconToggle(data) {
      this.iconEnabled = !this.iconEnabled
    },
    updateIconPosition(value) {
      this.iconPosition = value
    },
    showOverlay() {
      this.overlayVisible = true
    },
    hideOverlay() {
      this.overlayVisible = false
    },
  },
}
</script>

<style lang="scss">
.no-gutters {
  /*this will cause the labels and form elements to stack properly in the right sidebar*/
  flex-direction: column;
}
.icon-preview {
  font-size: 150px;
}
</style>
