import {BackgroundShapeSVGObject} from "../BackgroundShapeSVGObject";

const CurveAltSVG =
    `
<svg data-name="Curve Asymmetrical" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
    <path d="M0,0V6c0,21.6,291,111.46,741,110.26,445.39,3.6,459-88.3,459-110.26V0Z" class="fill"></path>
</svg>`


export const CurveAltShape = new BackgroundShapeSVGObject({
    name: "Curve Asymmetrical",
    canInvert: true,
    canFlip: true,
    svg: CurveAltSVG
})