
  <el-dialog
    :visible="visible"
    size="tiny"
    custom-class="gen-ai-dialog-container"
    @close="handleClose"
    @open="handleOpen"
  >
  <span
      slot="title"
  >
<!--      {{ $t('editor.save_block') }}-->
    Generative AI Content
  </span>
  <form
class="d-flex mb-2"
        @submit.prevent="addNewLabel"
  >

    <div class="w-100 mr-2 mb-1">
      <NewLabelInput
          v-model="newLabel"
      />
    </div>
    <div class="m-2 align-self-end w-25">
      <cvt-button
        :outlined="true"
        :full-width="true"
        shape="rounded"
        color = "light"
        mode="light"
        :text="`Add Label`"
      />
    </div>
  </form>
  <div class="fieldItemContainer">
    <div class="d-flex fieldItem" style="cursor: default">
      <div class="col-2 p-0 m-0 fieldItem-title">Title</div>
      <div class="fieldItem-description font-weight-bold">Description</div>
    </div>
    <hr class="m-1">
    <div ref="tagSection" class="tag-section">
      <template v-for="(item, idx) in DynamicFields">
        <div
:key="idx" :class="{'d-flex fieldItem': true, 'fieldItem-selected': selectedValue.label === item.label}"
             @click="() => handleSelection(item)" >
          <div class="col-2 p-0 m-0 fieldItem-title">{{item.label}}</div>
          <div class="fieldItem-description">{{item.description || ''}}</div>
        </div>
      </template>
    </div>
  </div>
  </el-dialog>
