<template>
  <section class="card-header">
    <slot></slot>
  </section>
</template>

<script>
  export default {
    name: 'CvtCardHeader',
  }
</script>

<style scoped>
.card-header {
  width: 100%;
  height: 100%;
  padding: 16px 16px 12px;
  display: flex;
  background-color: white;
  border-bottom: 0;
}
</style>
