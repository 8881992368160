import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import * as getters from "../../../../store/modules/getters";
import color from "color";
import * as actions from "../../../../store/modules/actions";
import * as mutations from "../../../../store/modules/mutations";
import { cvtRouteNodes, cvtRoutes } from "@/js/utils/routes";

const DESKTOP = { label: 'Desktop', icon: 'desktop_mac', action: 'enableDesktopMode', disabled: 'cantShowPreview' }
const MOBILE = { label: 'Mobile', icon: 'phone_iphone', action: 'enablePhoneMode', disabled: 'cantShowPreview' }


export default {
    name: "PopupMainNav",
    data () {
        return {
            editorCtrls: [
                { label: 'undo', icon: 'undo', action: 'undo', disabled: 'cantUndo' },
                { label: 'redo', icon: 'redo', action: 'redo', iconRight: true, disabled: 'cantRedo' }
            ],
            selectedPreviewMode: DESKTOP,
            previewCtrls: [DESKTOP, MOBILE]
        }
    },
    computed : {
        ...mapGetters({
            step: getters.STEPS_CURRENT_STEP,
            user: getters.AUTH_GET_USER,
            config: getters.AUTH_GET_USER_CONFIG,
            theme: getters.AUTH_GET_USER_BUILDER_THEME,
            canUndo: getters.HISTORY_CAN_UNDO,
            canRedo: getters.HISTORY_CAN_REDO,
            viewmode: getters.VIEW_MODE,
            popup: getters.SDA_POPUP_SITE_DATA
        }),
        ...mapState('loader', {
            loaderVisible: 'visible'
        }),
        ...mapState('route', {
            query: ({ query }) => query
        }),
        ...mapState('globalTheme', {
            orgName: ({ globalTheme }) => globalTheme.OrgName
        }),
        disableExitButton() {
            const urlParams = new URLSearchParams(window.location.search);
            const redirectURL = urlParams.get('next') || urlParams.get('redirectURL');

            if (redirectURL) {
                return false
            }

            return true;
        },
        currentRouteName () {
            const {
                popupEditorDesign,
                popupEditorSettings,
                popupEditorPublish,
            } = cvtRouteNodes

            switch (this.$route.name) {
                case popupEditorDesign:
                    return "popup:editor"
                case popupEditorSettings:
                    return "popup:settings"
                case popupEditorPublish:
                    return "popup:publish"
                default:
                    return "popup:editor"
            }
        },
        headerTitle () {
            return this.query.headerTitle || this.theme.headerTitle || this.theme.backToDashText
        },
        navColor () {
            if (this.theme.color) {
                const colorPrefix = [3, 6].includes(this.theme.color.length) ? '#' : ''
                return color(`${colorPrefix}${this.theme.color}`)
            }
            return ''
        },
        logo () {
            return this.navColor && this.navColor.dark() ? this.theme.logoAlt : this.theme.logo
        },
        pageEngineVisible () {
            return [cvtRouteNodes.editorDesignPage].includes(this.$route.name) && !this.loaderVisible
        },
        cantUndo () {
            return this.canUndo === false
        },
        cantRedo () {
            return this.canRedo === false
        },
        cantShowPreview () {
            return false
        },
    },
    watch: {
        viewmode: {
            immediate: true,
            handler(newVal) {
                this.selectedPreviewMode = newVal === 'desktop' ? DESKTOP : MOBILE
            },
        },
    },
    methods: {
        ...mapActions({
            toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
            applyHistory: actions.HISTORY_APPLY,
            setViewMode: actions.CHANGE_VIEW_MODE,
            toggleIntercom: actions.INTERCOM_TOGGLE,
            leftSideMenuToggle: actions.LEFT_SIDE_MENU_TOGGLE,
        }),
        ...mapMutations({
            setSettingsActiveTab: mutations.SITE_TRIGGER_TAB,
            showSiteSettings: mutations.SHOW_SITE_SETTINGS,
            setSettingsMessage: mutations.SITE_SET_SETTINGS_MESSAGE,
            showSettings: mutations.SHOW_SITE_SETTINGS,
            hideSettings: mutations.HIDE_SITE_SETTINGS,
            siteSettings: getters.SITE_SETTINGS,
        }),
        ...mapActions('loader', {
            startLoader: 'start',
            stopLoader: 'stop'
        }),
        ...mapMutations('revisions', {
            openRevisionManager: 'open',
            openRevisionSaveManager: 'openSave'
        }),
        help() {
            const { type: mode, value } = this.config.POPUP_HELP_BUTTON
            switch (mode) {
                case 'intercom':
                    return this.toggleIntercom()
                case 'external_link':
                    return window.open(value, '_blank')
                default:
                    alert('Help button config has not been set.')
            }
        },
        async pushRoute(route) {
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);

            try {
                await this.$router.push({path: route, query: Object.fromEntries(params)})
            } catch (e) {
                console.debug(e)
            }
        },
        async backStep() {
            await this.exitAction()
        },
        async exitAction () {
            const urlParams = new URLSearchParams(window.location.search);
            const redirectURL = urlParams.get('next') || urlParams.get('redirectUrl');

            if (redirectURL) {
                window.location.href = decodeURIComponent(redirectURL.replace(/\+/g, " "))
                return;
            }
            return await this.pushRoute(cvtRoutes.popupChooseATemplate.path)
        },
        enableDesktopMode () {
            // this.selectedPreviewMode = DESKTOP
            this.setViewMode('desktop')
        },
        enablePhoneMode () {
            // this.selectedPreviewMode = MOBILE
            this.setViewMode('phone')
        },
        execute (ctrl) {
            this[ctrl.action]()
        },
        undo () {
            this.applyHistory('undo')
        },
        redo () {
            this.applyHistory('redo')
        },
        switchPreview (currentSelected) {
            this.startLoader(`Switching to ${currentSelected} view`).then(() => {
                setTimeout(() => {
                    if (currentSelected === 'desktop') {
                        this.enableDesktopMode()
                    } else {
                        this.enablePhoneMode()
                    }
                }, 500)
                this.$nextTick(async () => {
                    // disabled Loading Page
                    setTimeout(() => {
                        this.stopLoader()
                    }, 4000)
                })
            })
        },

        disabled (prop) {
            return this[prop]
        }
    },
}