import * as _ from 'lodash'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'
import { Notification } from 'element-ui'

import { database } from '@/js/store/services/firebase.service'

const db = database()

export const setupStep = {
  id: 'setup',
  label: 'Setup',
  icon: 'settings',
  route: 'editor:setup',
}

export const campaignStep = {
  id: 'campaign',
  label: 'Campaign',
  icon: 'campaign',
  route: 'campaign',
}

export const editSitePage = {
  id: 'edit_landing_page',
  label: 'Design',
  icon: 'mode_edit',
  route: 'editor:design',
}

export const launchStep = {
  id: 'launch',
  label: 'Launch',
  icon: 'check',
  route: 'editor:launch',
}

export default {
  state: {
    steps: [campaignStep, editSitePage, launchStep],
    stepId: null,
    blocked: false,
  },
  getters: {
    [getters.STEPS]({ steps }) {
      return steps
    },
    [getters.STEPS_CURRENT_STEP]({ stepId, steps }) {
      return _.find(steps, { id: stepId })
    },
    [getters.STEPS_CURRENT_STEP_IDX]({ steps }, rootGetters) {
      return _.indexOf(steps, rootGetters[getters.STEPS_CURRENT_STEP])
    },
    [getters.STEPS_PREV_STEP]({ stepId, steps }, rootGetters) {
      return steps[rootGetters[getters.STEPS_CURRENT_STEP_IDX] - 1]
    },
    [getters.STEPS_NEXT_STEP]({ steps }, rootGetters) {
      return steps[rootGetters[getters.STEPS_CURRENT_STEP_IDX] + 1]
    },
  },
  mutations: {
    [mutations.STEPS_SET_STEP](state, stepId) {
      state.stepId = stepId
    },
    [mutations.STEPS_RESET](state) {
      state.steps = state.steps.map((step) => {
        step.state = null
        return step
      })

      state.stepStates = null

      if (state.stepsRef) {
        state.stepsRef.off('value')
      }
    },
    [mutations.STEPS_BLOCK](state, blocked) {
      state.blocked = blocked
    },
  },
  actions: {
    [actions.STEPS_INIT]({ state, commit }, fbKey) {
      if (state.stepsRef) {
        return Promise.resolve()
      }

      commit(mutations.STEPS_RESET)

      state.stepsRef = db.ref(fbKey).child('steps')

      return new Promise((resolve) => {
        state.stepsRef.on('value', (snap) => {
          state.stepStates = snap.val() || {}
          // map step states
          state.steps = state.steps.map((step) => {
            step.state = state.stepStates[step.id]
            return step
          })

          resolve()
        })
      })
    },
    [actions.STEPS_MARK]({ state }, status) {
      const step = _.find(state.steps, { id: state.stepId })

      if (!step) {
        console.error(`There was no state with id ${state.stepId}`)
        return
      }

      return state.stepsRef.child(state.stepId).set(status)
    },
    [actions.STEPS_BACK](context, { router, route }) {
      const prevStep = context.getters[getters.STEPS_PREV_STEP]

      if (!prevStep) {
        console.error('there is no previous step')
        return
      }

      router.push({
        name: prevStep.route,
      })
    },
    [actions.STEPS_NEXT](context, { router, route }) {
      const nextStep = context.getters[getters.STEPS_NEXT_STEP]

      if (!nextStep) {
        Notification.warning({
          message: 'There is no next step',
          position: 'bottom-right',
        })

        return
      }

      if (context.state.blocked) {
        Notification.warning({
          message: 'Please fix any errors on this step before you proceed.',
          position: 'bottom-right',
        })
        return
      }

      // mark current step as complete
      context.dispatch(actions.STEPS_MARK, 'complete')

      // move to next step
      router.push({
        name: nextStep.route,
      })
    },
  },
}
