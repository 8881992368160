
<cvt-dialog
  :title="title"
  :show="visible"
  :bg-color="modalCustomConfig.dialogBgColor"
  :hide-borders="modalCustomConfig.hideBorders || false"
  @close="close"
>
  <template
    v-if="showVersionHistoryText"
    #title
  >
    <h5 style="margin-top: 20px;padding: 0 22px;">{{ $t('element.properties.revision.button.label') }}</h5>
  </template>
  <div
    class="revision-manager-dialog"
    :style="modalCustomConfig.revisionManagerStyles"
  >
    <form
      v-if="VersionHistoryCombined"
      disabled="saving"
      @submit.prevent="saveSnapshotAndClearForm"
    >
      <div class="row mb-2" style="padding-top: 10px;" :style="modalCustomConfig.searchContainerStyles">
        <div class="col col-8" :class="modalCustomConfig.searchInputClass" :style="modalCustomConfig.searchInputStyles">
          <cvt-input
            :value="snapshotDesc"
            type="text"
            :style="modalCustomConfig.cvtInputStyles"
            :label="$t('element.revision.snapshot.description.label')"
            :no-label="true"
            :placeholder="$t('element.revision.snapshot.description.label')"
            required
            @input="setSnapshotDesc"
          />
        </div>
        <div :class="modalCustomConfig.searchBtnStyles ? '':'col'">
          <cvt-button
            :style="modalCustomConfig.searchBtnStyles"
            :disabled="saveButtonDisabled"
            :text="$t('element.snapshot.save.label')"
            :full-width="true"
          />
        </div>
      </div>
    </form>
    <div
      v-loading="saving"
      class="revision-table-container"
    >
      <table
        class="table table-striped revision-table"
      >
        <thead>
          <tr>
            <th scope="col" :style="modalCustomConfig.tableHeadStyles">
              {{ $t("element.revision.snapshot.user.label") }}
            </th>
            <th scope="col" :style="modalCustomConfig.tableHeadStyles">
              {{ $t("element.revision.description.label") }}
            </th>
            <th scope="col" :style="modalCustomConfig.tableHeadStyles">
              {{ $t("element.revision.snapshot.timestamp.label") }}
            </th>
            <th scope="col" :style="modalCustomConfig.tableHeadStyles" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="rev in history"
            :key="rev.id"
            :class="highlightCurrentSnapshotClass(rev.id)"
            :style="modalCustomConfig.tableBodyClass"
          >
            <td v-text="rev.actor.email" />
            <td v-text="rev.event.description" />
            <td v-text="timeDistance(rev.timestampISO)" />
            <td :style="modalCustomConfig.historyBtnTableStyles">
              <cvt-button
                :loading="loading && clickedRevId === rev.id"
                :disabled="restored(rev.id)"
                :text="restoreButtonText(rev.id)"
                color="light"
                :style="historyBtnStyles(rev.id)"
                :outlined="true"
                :full-width="modalCustomConfig.historyBtnFullWidth || true"
                @click.prevent="restore(rev.id)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</cvt-dialog>
