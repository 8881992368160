import { Notification } from 'element-ui'
import { V1Sites } from '../../../store/services/api'
const sitesAPI = new V1Sites()

export default {
  async loadMoreSites() {
    const { data } = await sitesAPI.all({
      ...this.personalTemplatesSitesPagination,
      market: false,
    })
    this.yourTemplatesSitesListAll = this.yourTemplatesSitesListAll.concat(data)
    this.personalTemplatesSitesPagination.offset +=
      this.personalTemplatesSitesPagination.limit
  },
  loadMoreTemplates() {
    if (this.yourTemplatesActive) {
      return this.loadMorePersonalTemplates
    }
    if (this.archivedTemplatesActive) {
      return this.loadMoreArchivedTemplates
    }
    return this.loadMoreMarketplaceTemplates
  },
  loadMoreListItems() {
    return this.loadMorePersonalTemplates
  },
  async restoreArchivedTemplate(template) {
    try {
      const response = await this.restoreSite(template.id)
      if (response) {
        const { id, name } = template

        this.archivedTemplatesList = this.archivedTemplatesList.filter(
          (t) => t.id !== id,
        )
        this.yourTemplatesList.unshift(template)
        this.$message({ type: 'info', message: `${this.$t('elements.property.archive.template.restored')}: ${name}` })
        await this.fetchTemplateCount()
      }
    } catch {
      // no op
    }
  },
  onMoreActionClicked(actionName, template) {
    if (actionName === 'copy-template') this.showPreviewWindow(template.id)
    else if (actionName === 'edit-template') this.editSite(template)
    else if (actionName === 'archive') this.openArchivePrompt(template)
  },
  getStartFromScratchClass() {
    // 'marketplace-templates--wrapper my-3 mx-1': true,
    // marketplace-templates-alt
    return {
      'marketplace-templates--noMoreActions':
        this.yourTemplatesActive || this.archivedTemplatesActive,
    }
  },
  async unlinkDomain() {
    if (!this.unlinkDomainSiteId) {
      console.debug('Issue validating prior to request - Missing siteId')
      return
    }

    if (!this.unlinkDomainName) {
      Notification.error({
        message: this.$t(
          'custom_domain.error.domain_validation.reserved.empty_field',
        ),
        position: 'bottom-right',
      })
      return
    }

    // Then attempt to disconnect the domain
    const disconnetResponse = await this.disconnectDomain({
      siteId: this.unlinkDomainSiteId,
      domain: this.unlinkDomainName,
    })

    if (disconnetResponse) {
      this.loadDomains(this.unlinkDomainSiteId)

      this.$message({
        type: 'info',
        message: `${this.$t('custom_domain.success.domain_unlinked')} ${this.unlinkDomainName
          }`,
      })

      this.unlinkDomainSiteId = ''
      this.unlinkDomainName = ''
      this.unlinkDialog = false
      this.unlinkExistingDomain = false
      return
    }

    console.error(`${this.$t('custom_domain.error.unlink_dialog.part_one')}`)
    this.$message({
      type: 'warning',
      message: `${this.$t('custom_domain.error.unlink_dialog.part_one')}, ${this.unlinkDomainName
        }. ${this.$t('custom_domain.error.unlink_dialog.part_two')}`,
    })
  },
  onSearchDone(searchQuery) {
    this.searchQuery = searchQuery

    this.resetAndLoadYourPages()
  },
  onSortParameter(sortMethod, isSorted) {
    if (sortMethod === 'created') {
      this.isCreatedSelected = isSorted
      if (this.isCreatedSelected) {
        this.isLastUpdatedSelected = false
      }
    } else if (sortMethod === 'lastUpdated') {
      this.isLastUpdatedSelected = isSorted
      if (this.isLastUpdatedSelected) {
        this.isCreatedSelected = false
      }
    } else if (sortMethod === 'published') {
      this.isPublished = isSorted
    }

    this.resetAndLoadYourPages()
  },
  //Reset pages and Apply search, sort, filter params to load more templates
  resetAndLoadYourPages() {
    this.personalTemplatesPagination.offset = 0
    this.yourTemplatesListAll = []
    this.yourTemplatesList = []

    this.loadMorePersonalTemplates()
  },
  async showRightSidebar(_, template) {
    if (this.config.TEMPLATE_ACTIONS_RIGHT_SIDEBAR === true) {
      // it increments the component key, so everytime a user clicks to open the sidebar
      // it will re-render the component so that all previous data is reset
      ++this.rightSidebarComponentKey
      this.rightSidebarRadioGroupLoading = false
      // show right sidebar
      this.isRightSidebarVisible = true
      this.selectedTemplate = template
      this.selectedTemplateSite = null

      if (template.id) {
        this.selectedTemplateSite = this.searchSiteDataById(template.id)
        if (this.selectedTemplateSite) {
          // reset domains-data  first before loading it from server
          // so that even if API call fails, we are not showing old data to user
          this.updateDomains([])
          this.loadDomains(template.id)
        }
      }
    }
  },
  hideRightSidebar() {
    this.isRightSidebarVisible = false
    this.selectedTemplate = null
  },
  copyTemplateBtnClicked(event, template) {
    this.showPreviewWindow(template.id)
  },
  editSiteBtnClicked(event, template) {
    this.editSite(template)
  },
  archiveTemplateBtnClicked(event, template) {
    this.openArchivePrompt(template)
  },
  searchSiteDataById(siteId) {
    let searchedSite = this.yourTemplatesSitesListAll.find(
      (siteObject, index) => {
        return siteObject.id === siteId
      },
    )
    return searchedSite ? searchedSite : null
  },
  onTemplatesViewToggled(selectedViewType) {
    if (this.templatesDefaultViewFormat != selectedViewType) {
      this.templatesDefaultViewFormat = selectedViewType

      this.resetAndLoadYourPages()
    }
  },
  resetRightSideBarVariables() {
    this.isRightSidebarVisible = null
    this.rightSidebarRadioGroupLoading = false
    this.selectedTemplate = null
    this.selectedTemplateSite = null
    this.updateDomains([])
    this.unlinkDomainSiteId = ''
    this.unlinkDomainName = ''
    this.unlinkDialog = false
  },
  resetTemplateViewFormat() {
    this.templatesViewFormat = null
  },
  openApexDialog() {
    this.apexDialog = true
  },
  openUnlinkDialog(siteId, domain) {
    this.unlinkDomainSiteId = siteId
    this.unlinkDomainName = domain
    this.unlinkDialog = true
  },
}
