<template>
  <div
    ref="draggable"
    class="draggable mb-1"
    draggable="true"
    :class="draggableClasses"
    :style="draggableStyles"
    @dragstart="dragstart"
    @dragend="dragend"
    @dragenter.prevent="validate"
    @dragleave="reset"
    @dragover.prevent="dragover"
    @drop.prevent="dropped"
    @mousedown="mousedown"
    @click="click">

    <magic-line :active="highlightTop" side="top"></magic-line>
    <magic-line :active="highlightRight" side="right"></magic-line>
    <magic-line :active="highlightBottom" side="bottom"></magic-line>
    <magic-line :active="highlightLeft" side="left"></magic-line>

    <slot></slot>

  </div>
</template>

<script>
  import MagicLine from './MagicLine.vue'
  import { mapState } from 'vuex'
  import { FRESHWORKS } from '../../../../../../storybook/components/constants'

  const OFFSET_MAP = {
    left: 0,
    right: 1,
    top: 0,
    bottom: 1
  }

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Draggable',
    components: {
      MagicLine
    },

    props: {
      index: {
        type: Number,
        default: 0
      },
      payload: {
        type: Object,
        default() {}
      },
      active: Boolean,
      dragHandleId: {
        type: String,
        default: 'drag-handle'
      }
    },

    data () {
      return {
        invisible: false,
        clickTarget: null,
        hasDragHandle: false,
        axis: 'y',
        quadrant: {
          x: null,
          y: null
        }
      }
    },

    computed: {
      ...mapState('globalTheme', {
        FormMenuHoverEffect: ({ globalTheme }) => globalTheme.FormMenuHoverEffect !== undefined ? globalTheme.FormMenuHoverEffect : true,
        OrgName: ({ globalTheme }) => globalTheme.OrgName !== undefined ? globalTheme.OrgName : 'standard'
      }),

      highlightTop () {
        return this.axis === 'y' && this.quadrant.y === 'top'
      },

      highlightBottom () {
        return this.axis === 'y' && this.quadrant.y === 'bottom'
      },

      highlightLeft () {
        return this.axis === 'x' && this.quadrant.x === 'left'
      },

      highlightRight () {
        return this.axis === 'x' && this.quadrant.x === 'right'
      },

      draggableClasses () {
        return {
          'draggable--active': this.active,
          'hover-info': this.FormMenuHoverEffect,
          'box-styles': this.OrgName === FRESHWORKS,
          'draggable-alt': this.OrgName === FRESHWORKS
        }
      },
      draggableStyles () {
        return {
          cursor: this.hasDragHandle ? 'pointer' : 'move'
        }
      }
    },

    mounted () {
      this.hasDragHandle = this.dragHandle() !== undefined
    },

    methods: {
      validate (e) {
        return false
      },

      click () {
        this.$emit('click', {
          index: this.index,
          payload: this.payload
        })
      },

      reset () {
        this.clickTarget = null
        this.invisible = false
        this.quadrant = { x: null, y: null }
        this.$emit('reset')
      },

      dragover (e) {
        this.quadrant = this.calculateMouseQuadrant(e)
        return false
      },

      dropped (e) {
        this.$emit('dropped', {
          offset: OFFSET_MAP[this.quadrant[this.axis]],
          side: this.quadrant[this.axis],
          index: this.index
        })
        this.reset()
      },

      mousedown (e) {
        this.clickTarget = e.target
      },

      dragHandle () {
        return this.$el && this.$el.querySelector(`#${this.dragHandleId}`)
      },

      dragstart (e) {
        let handle = this.dragHandle()
        if (handle && handle.contains(this.clickTarget)) {
          e.dataTransfer.setData('text/plain', this.dragHandleId)
        } else if (handle) {
          e.preventDefault()
          return true
        }

        this.$emit('dragstart', {
          index: this.index,
          payload: this.payload
        })
      },

      dragend () {
        this.$emit('dragend')
        this.reset()
      },

      width () {
        return this.$refs.draggable?.clientWidth
      },

      height () {
        return this.$refs.draggable?.clientHeight
      },

      midPoints () {
        return {
          x: this.offset().left + (this.width() / 2),
          y: this.offset().top + (this.height() / 2)
        }
      },

      offset () {
        let {
          left, top, right, bottom
        } = this.$el.getBoundingClientRect()
        return { top, right, left, bottom }
      },

      calculateMouseQuadrant ({clientX, clientY}) {
        let {x, y} = this.midPoints()
        return {
          x: clientX < x ? 'left' : 'rigt',
          y: clientY > y ? 'bottom' : 'top'
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "@/scss/utils";

  .draggable {
    height: 50px;
    background-color: $dark-gray;
    display: flex;
    align-items: center;
    padding: 2em;
    box-sizing: border-box;
    position: relative;
    transition: all 0.1s linear;
    color: $darker;

    .draggable-actions {
      transition: all 0.1s linear;
      visibility: hidden;
      display: flex;
      margin-left: auto;
      color: $white;
      i.material-icons {
        font-size: 20px;
      }
    }

    #drag-handle {
      transition: all 0.1s linear;
      // visibility: hidden;
      cursor: move;
      margin-right: 1em;
      color: $darker;
    }

    &:hover .draggable-actions {
      visibility: visible;
      color: $white;
    }

    &--active, &:active {
      color: $white;
      background-color: darken($info, 10%);
    }

    &--active #drag-handle, &--active .draggable-actions {
      color: $white;
      visibility: visible;
    }

    &--active span {
      color: $white;
    }
  }

  .draggable-alt {
    &{
      height: 40px;
      padding: 1em;
    }
  }

  .draggable--active:hover {
    background-color: $info;

    &:active {
      background-color: darken($info, 10%)
    }
  }

  .hover-info {
    &:hover {
      color: $white;
      background-color: darken($info, 5%);
    }

    &:hover #drag-handle {
      visibility: visible;
      color: $white;
    }
  }

  .box-styles {
    border: 1px solid #CFD7DF;
    border-radius: 4px;
  }
  .draggable > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
