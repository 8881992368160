
<div class="feature-config-container">
  <feature-configuration />
  <div class="feature-config-continue-btn">
    <cvt-button
      text='Continue to Dashboard for more options'
      @click='() => onClickFeatureConfig()'
    >
    </cvt-button>
  </div>
</div>
