
<div class="layout-container">
  <div :class="layoutStyles">
    <div :class="contentStyle">
      <cvt-froala-wrapper v-if="showEditorControls" wrapper-id="froala-portal" />
      <layout-breadcrumb v-if="showEditorControls && !isGridBuilder" />
      <slot></slot>
    </div>
  </div>
</div>
