<template>
  <i class="fa" :class="selector"></i>
</template>
<script lang="ts">
export default {
  name: 'FontAwesomeIcon',
  props: {
    selector: {
      required: true,
      type: String,
    },
  },
}
</script>
