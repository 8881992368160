<template>
  <div v-show="active" :class="magicClasses" :style="magicStyle"></div>
</template>

<script>
const ML_VERT = 'magicline--vertical'
const ML_HORZ = 'magicline--horizontal'

export default {
  props: {
    side: {
      type: String,
      default: ''
    },
    active: Boolean,
    color: {
      type: String,
      default: '#8F00FF'
    }
  },
  computed: {
    magicClasses () {
      return {
        magicline: true,
        [ML_VERT]: ['left', 'right'].includes(this.side),
        [ML_HORZ]: ['top', 'bottom'].includes(this.side),
        [`magicline--${this.side}`]: true
      }
    },
    magicStyle () {
      return {
        background: this.color
      }
    }
  }
}
</script>

<style lang="scss">
  .magicline {
    position: absolute;
    transition: all ease 0.3s;
  }

  .magicline--vertical {
    top: 0;
    height: 100%;
    width: 3px;
  }

  .magicline--horizontal {
    left: 0;
    width: 100%;
    height: 3px;
  }

  .magicline--top {
    top: -3px;
  }

  .magicline--right {
    right: -3px;
  }

  .magicline--bottom {
    bottom: -3px;
  }

  .magicline--left {
    left: -3px;
  }
</style>