import CvtAdvanceAlert from '../molecules/AdvanceAlert'
import CvtAlert from '../molecules/Alert'
import { CvtAlertWithButton } from '../molecules/AlertWithButton'
import CvtButton from '../molecules/Button'
import { ContentPlaceholder } from '../molecules/ContentPlaceholder'
import CvtDialog from '../molecules/Dialog'
import CvtFieldLabel from '../molecules/FieldLabel'
import CvtFroalaWrapper from '../molecules/FroalaWrapper'
import CvtInput from '../molecules/Input.jsx'
import { LazyImage } from '../molecules/LazyImage'
import LoadingMessage from '../molecules/LoadingMessage'
import CvtPagination from '../molecules/Pagination'
import CvtPopover from '../molecules/Popover'
import CvtProgress from '../molecules/Progress'
import CvtSelect from '../molecules/Select'
import CvtSwitch from '../molecules/Switch'
import CvtCardBody from '../molecules/card/card-body.vue'
import CvtCardHeader from '../molecules/card/card-header.vue'
import CvtCard from '../molecules/card/card.vue'
import CvtCollapse from '../molecules/collapse/Collapse'
import CvtCollapseFormItem from '../molecules/collapse/CollapseFormItem'
import CvtCollapseItem from '../molecules/collapse/CollapseItem'
import CvtCollapseTinyItem from '../molecules/collapse/CollapseTinyItem'
import CvtDropdown from '../molecules/dropdown/Dropdown'
import CvtDropdownMenu from '../molecules/dropdown/DropdownMenu'
import { CvtIcon } from '../molecules/icon/icon'
import { CvtLink } from '../molecules/link/link'
import CvtItem from '../molecules/list/CvtItem'
import CvtList from '../molecules/list/CvtList'
import { MarketplaceTag } from '../molecules/marketplace-tag'
import { CvtModal } from '../molecules/modal'
import { CvtNav } from '../molecules/nav/nav'
import { CvtNavItem } from '../molecules/nav/nav-item'
import { CvtNavText } from '../molecules/nav/nav-text'
import CvtAltRadio from '../molecules/radio-group/AltRadio'
import CvtRadio from '../molecules/radio-group/Radio'
import CvtRadioButton from '../molecules/radio-group/RadioButton'
import CvtRadioGroup from '../molecules/radio-group/RadioGroup'
import CvtSlider from '../molecules/slider/Slider'
import CvtVerticalSlider from '../molecules/slider/VerticalSlider'
import { SapCloseBtn } from '../molecules/standalone-popup/SapCloseBtn'
import CvtTable from '../molecules/table/table'
import CvtTbody from '../molecules/table/tbody'
import CvtTd from '../molecules/table/td'
import CvtTfoot from '../molecules/table/tfoot'
import CvtTh from '../molecules/table/th'
import CvtThead from '../molecules/table/thead'
import CvtTr from '../molecules/table/tr'
import CvtTab from '../molecules/tabs/Tab'
import CvtTabs from '../molecules/tabs/Tabs'
import { CvtTooltip } from '../molecules/tooltip/CvtTooltip'
import CvtFileList from '../molecules/upload/FileList'
import CvtUpload from '../molecules/upload/Upload'
import CvtCustomCheckbox from '../molecules/CustomCheckbox'
import CvtCustomRadio from '../molecules/CustomRadio'
import { CvtAdminLeftNavBar } from '../organisms/admin-left-nav'
import { CvtColorSelector } from '../organisms/enterprise/color-selector'
import { CvtEntDashboardSideNav } from '../organisms/enterprise/dasboard-side-nav'
import { CvtEntDashboardTabs } from '../organisms/enterprise/dashboard-tabs'
import { CvtEntDashboardTopNav } from '../organisms/enterprise/dashboard-top-nav'
import { EnterpriseFooter } from '../organisms/enterprise/enterprise-footer'
import { CvtFontSelector } from '../organisms/enterprise/font-selector'
import { FreeTrialFooter } from '../organisms/enterprise/free-trial-footer'
import { EnterpriseLeftNavBar } from '../organisms/enterprise/left-nav-bar'
import { CvtLogoSelector } from '../organisms/enterprise/logo-selector'
import { CvtOidcEnvironmentCard } from '../organisms/enterprise/oidc-environment-card'
import { EnterpriseTopNavBar } from '../organisms/enterprise/top-nav-bar'
import { CvtFloatingBreadcrumb } from '../organisms/floating-breadcrumb'
import { CvtLeftNavBar } from '../organisms/left-nav'
import CvtPageSettings from '../organisms/left-sidebar/PageSettings'
import { CvtSavePages } from '../organisms/left-sidebar/SavePages'
import { CvtElements } from '../organisms/left-sidebar/elements'
import { CvtImageGallery } from '../organisms/left-sidebar/image-gallery'
import { CvtLayoutSection } from '../organisms/left-sidebar/layout-section'
import { CvtLayouts } from '../organisms/left-sidebar/layouts'
import { CvtLeftSideBar } from '../organisms/left-sidebar/left-sidebar'
import { CvtPages } from '../organisms/left-sidebar/pages'
import { CvtSections } from '../organisms/left-sidebar/sections'
import {
  CvtMarketplaceCard,
  PlaceholderCard,
} from '../organisms/marketplace-card'
import { CvtMarketplaceListItem } from '../organisms/marketplace-list-item'
import { CvtMarketplaceSearchSort } from '../organisms/marketplace-search-sort'
import CvtNewOrgModal from '../organisms/new-org-modal'
import { CvtPopupLeftNavBar } from '../organisms/popup-left-nav'
import { CvtPopupListItem } from '../organisms/popup-list-item'
import { CvtPopupSearchFilter } from '../organisms/popup-search'
import { CvtPreviewDetails } from '../organisms/preview-details'
import { CvtPropertiesSidebar } from '../organisms/properties-sidebar/properties-sidebar'
import { CvtCustomDomainAction } from '../organisms/publish/custom-domain-action'
import { CvtPublishCard } from '../organisms/publish/publish-card'
import { RightSideImgModal } from '../organisms/right-side-img-modal'
import { CvtAnalyticsSidebar } from '../organisms/right-sidebar/analytics-sidebar'
import { CvtSiteThumbnailAndActions } from '../organisms/right-sidebar/site-thumbnail-and-actions'
import { CvtUrlRadioButtonActions } from '../organisms/right-sidebar/url-radio-button-actions'
import { CvtSearchFilterAdmin } from '../organisms/seach-admin'
import { CvtSearchFilter } from '../organisms/search'
import { CvtSectionDragItems } from '../organisms/section-ui/section-drag-items'
import { CvtSectionSettingActions } from '../organisms/section-ui/section-setting-actions'
import { CvtSectionUpDownActions } from '../organisms/section-ui/section-up-down-actions'
import { CvtSectionVisibility } from '../organisms/section-ui/section-visibility'
import { CvtSettings } from '../organisms/settings/settings'
import { CvtTopNavBar } from '../organisms/top-nav'
import { CvtPopupChooseTemplate } from '../templates/cvt-popup-choose-template'
import { CvtInviteNewUserModal } from '../templates/invite-new-user-modal'
import { CvtRightSidebar } from '../templates/right-sidebar'
import { CvtSapAudience } from '../templates/sap-audience'
import { CvtSapRules } from '../templates/sap-rules'
import { CvtIndustryCard } from '../organisms/industry-card'
import { CvtLandingPageGoalCard } from '../organisms/landing-page-goal-card'
import { CvtLeftSidebar } from '../organisms/left-sidebar'
import { CvtSchemaContainer } from '../molecules/schema-collapse/SchemaContainer.jsx'

export default {
  install(Vue, options) {
    Vue.component('cvt-alert', CvtAlert)
    Vue.component('cvt-advance-alert', CvtAdvanceAlert)
    Vue.component('cvt-button', CvtButton)
    Vue.component('cvt-icon', CvtIcon)
    Vue.component('cvt-dialog', CvtDialog)
    Vue.component('cvt-pagination', CvtPagination)
    Vue.component('cvt-progress', CvtProgress)
    Vue.component('cvt-select', CvtSelect)
    Vue.component('cvt-switch', CvtSwitch)
    Vue.component('cvt-froala-wrapper', CvtFroalaWrapper)
    Vue.component('cvt-dropdown', CvtDropdown)
    Vue.component('cvt-dropdown-menu', CvtDropdownMenu)
    Vue.component('cvt-lazy-image', LazyImage)
    Vue.component('cvt-input', CvtInput)
    Vue.component('cvt-card', CvtCard)
    Vue.component('cvt-card-body', CvtCardBody)
    Vue.component('cvt-card-header', CvtCardHeader)
    Vue.component('cvt-collapse', CvtCollapse)
    Vue.component('cvt-collapse-item', CvtCollapseItem)
    Vue.component('cvt-collapse-tiny-item', CvtCollapseTinyItem)
    Vue.component('cvt-collapse-form-item', CvtCollapseFormItem)
    Vue.component('cvt-link', CvtLink)
    Vue.component('cvt-item', CvtItem) // CvtListItem
    Vue.component('cvt-list', CvtList)
    Vue.component('cvt-nav', CvtNav)
    Vue.component('cvt-nav-item', CvtNavItem)
    Vue.component('cvt-nav-text', CvtNavText)
    Vue.component('cvt-radio', CvtRadio)
    Vue.component('cvt-radio-button', CvtRadioButton)
    Vue.component('cvt-radio-group', CvtRadioGroup)
    Vue.component('cvt-alt-radio', CvtAltRadio)
    Vue.component('cvt-slider', CvtSlider)
    Vue.component('cvt-vertical-slider', CvtVerticalSlider)
    Vue.component('cvt-table', CvtTable)
    Vue.component('cvt-thead', CvtThead)
    Vue.component('cvt-tbody', CvtTbody)
    Vue.component('cvt-tfoot', CvtTfoot)
    Vue.component('cvt-th', CvtTh)
    Vue.component('cvt-tr', CvtTr)
    Vue.component('cvt-td', CvtTd)
    Vue.component('cvt-tabs', CvtTabs)
    Vue.component('cvt-tab', CvtTab)
    Vue.component('cvt-tooltip', CvtTooltip)
    Vue.component('cvt-popover', CvtPopover)
    Vue.component('cvt-upload', CvtUpload)
    Vue.component('cvt-file-list', CvtFileList)
    Vue.component('cvt-left-nav-bar', CvtLeftNavBar)
    Vue.component('cvt-popup-left-nav-bar', CvtPopupLeftNavBar)
    Vue.component('cvt-new-org-modal', CvtNewOrgModal)
    Vue.component('cvt-marketplace-card', CvtMarketplaceCard)
    Vue.component('cvt-marketplace-list-item', CvtMarketplaceListItem)
    Vue.component('cvt-popup-list-item', CvtPopupListItem)
    Vue.component('cvt-marketplace-search-sort', CvtMarketplaceSearchSort)
    Vue.component('placeholder-card', PlaceholderCard)
    Vue.component('cvt-top-nav-bar', CvtTopNavBar)
    Vue.component('cvt-publish-card', CvtPublishCard)
    Vue.component('cvt-custom-domain-action', CvtCustomDomainAction)
    Vue.component('cvt-properties-sidebar', CvtPropertiesSidebar)
    Vue.component('cvt-left-sidebar', CvtLeftSideBar)
    Vue.component('cvt-settings', CvtSettings)
    Vue.component('cvt-elements', CvtElements)
    Vue.component('cvt-pages', CvtPages)
    Vue.component('cvt-sections', CvtSections)
    Vue.component('cvt-save-pages', CvtSavePages)
    Vue.component('cvt-page-settings', CvtPageSettings)
    Vue.component('cvt-layouts', CvtLayouts)
    Vue.component('cvt-layout-section', CvtLayoutSection)
    Vue.component('cvt-image-gallery', CvtImageGallery)
    Vue.component('cvt-search-filter', CvtSearchFilter)
    Vue.component('cvt-popup-search-filter', CvtPopupSearchFilter)
    Vue.component('cvt-search-filter-admin', CvtSearchFilterAdmin)
    Vue.component('cvt-content-placeholder', ContentPlaceholder)
    Vue.component('cvt-right-side-img-modal', RightSideImgModal)
    Vue.component('cvt-site-thumbnail-and-actions', CvtSiteThumbnailAndActions)
    Vue.component('cvt-url-radio-button-actions', CvtUrlRadioButtonActions)
    Vue.component('cvt-right-sidebar', CvtRightSidebar)
    Vue.component('cvt-admin-left-nav-bar', CvtAdminLeftNavBar)
    Vue.component('cvt-loading-message', LoadingMessage)
    Vue.component('cvt-analytics-sidebar', CvtAnalyticsSidebar)
    Vue.component('cvt-field-label', CvtFieldLabel)
    Vue.component('cvt-section-setting-actions', CvtSectionSettingActions)
    Vue.component('cvt-section-visibility', CvtSectionVisibility)
    Vue.component('cvt-section-drag-items', CvtSectionDragItems)
    Vue.component('cvt-section-up-down-actions', CvtSectionUpDownActions)
    Vue.component('cvt-floating-breadcrumb', CvtFloatingBreadcrumb)
    Vue.component('cvt-popup-choose-template', CvtPopupChooseTemplate)
    Vue.component('cvt-sap-audience', CvtSapAudience)
    Vue.component('cvt-sap-rules', CvtSapRules)
    Vue.component('cvt-sap-close-btn', SapCloseBtn)
    Vue.component('cvt-enterprise-top-nav', EnterpriseTopNavBar)
    Vue.component('cvt-enterprise-left-nav-bar', EnterpriseLeftNavBar)
    Vue.component('cvt-enterprise-footer', EnterpriseFooter)
    Vue.component('cvt-free-trial-footer', FreeTrialFooter)
    Vue.component('cvt-ent-dashboard-topnav', CvtEntDashboardTopNav)
    Vue.component('cvt-ent-dashboard-sidenav', CvtEntDashboardSideNav)
    Vue.component('cvt-ent-dashboard-tabs', CvtEntDashboardTabs)
    Vue.component('cvt-alert-with-button', CvtAlertWithButton)
    Vue.component('cvt-preview-details', CvtPreviewDetails)
    Vue.component('cvt-marketplace-tag', MarketplaceTag)
    Vue.component('cvt-modal', CvtModal)
    Vue.component('cvt-oidc-environment-card', CvtOidcEnvironmentCard)
    Vue.component('cvt-color-selector', CvtColorSelector)
    Vue.component('cvt-font-selector', CvtFontSelector)
    Vue.component('cvt-logo-selector', CvtLogoSelector)
    Vue.component('cvt-invite-new-user-modal', CvtInviteNewUserModal)
    Vue.component('cvt-industry-card', CvtIndustryCard)
    Vue.component('cvt-goal-card', CvtLandingPageGoalCard)
    Vue.component('cvt-left-side-bar', CvtLeftSidebar)
    Vue.component('cvt-checkbox', CvtCustomCheckbox)
    Vue.component('cvt-custom-radio', CvtCustomRadio)
    Vue.component('cvt-schema-container', CvtSchemaContainer)
  },
}
