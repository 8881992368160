import Vue from '../../../lib/utils/vue'
import { styled, VueEmotion } from '@egoist/vue-emotion'

import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import { CvtSchemaContainer } from './SchemaContainer'
import { truncateText } from '../../data.js'
import VueI18n from 'vue-i18n'

Vue.use(VueEmotion)
Vue.use(VueI18n)

let Spacing, Radii, Border, AddPagesToRight

let borderColor, backgroundColor, textFontSize, textLineHeight, elevation, color

const getNodeWrapper = () => styled('div')`
  display: flex;
  justify-content: space-between;
`

const getNode = () => styled('div')`
  padding-left: ${(props) => props.level * 20}px;
  cursor: pointer;
  display: inline-block;
`

const getLabel = () => styled('span')`
  margin-left: 5px;
`

const getContentWrapper = () => styled('div')`
  padding-left: ${(props) => (props.level + 1) * 20}px;
  color: gray;
`

const getContent = () => styled('div')`
  display: flex;
  align-items: center;
`

const getContentName = () => styled('span')`
  font-size: 14px;
  margin-right: 5px;
`

const getContentLabel = () => styled('span')`
  font-size: 13px;
  font-weight: 700;
`

export const CvtSchemaCollapseItem = {
  name: 'CvtSchemaCollapseItem',
  data() {
    return {
      collapsed: true,
    }
  },
  computed: {
    ...mapGetters('globalTheme', {}),
    ...mapState('globalTheme', {}),
  },
  methods: {
    handleToggle() {
      this.collapsed = !this.collapsed
    },
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    level: {
      type: Number,
      default: 0,
    },
    node: {
      type: Object,
      default: {},
    },
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    AddPagesToRight = this.AddPagesToRight
    borderColor = this.borderColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    elevation = this.elevation
    color = this.textColor
  },
  render: function (h) {
    const Node = getNode()
    const Label = getLabel()
    const Content = getContent()
    const ContentLabel = getContentLabel()
    const ContentName = getContentName()
    const NodeWrapper = getNodeWrapper()
    const ContentWrapper = getContentWrapper()

    return (
      <div>
        <NodeWrapper>
          <Node level={this.level} onClick={this.handleToggle}>
            {this.node.children ? (this.collapsed ? '▶' : '▼') : '•'}
            <Label>{this.node.label}</Label>
          </Node>
          <span onClick={() => {
            this.$emit("selectNode", this.node)
          }}>
            <cvt-icon style="cursor: pointer; font-size: 12px;" icon="location-arrow"></cvt-icon>
          </span>
        </NodeWrapper>
        {!this.collapsed && this.node.children && (
          <CvtSchemaContainer
            nodes={this.node.children}
            level={this.level + 1}
            onSelectNode={(node) => this.$emit("selectNode", node)}
          />
        )}
        {!this.collapsed && this.node.content && (
          <ContentWrapper level={this.level}>
            <Content>
              <ContentName>Type: </ContentName>
              <ContentLabel>{this.node.content.type}</ContentLabel>
            </Content>
            <Content>
              <ContentName>Description: </ContentName> 
              <ContentLabel title={this.node.content.description}>{truncateText(this.node.content.description, 15)}</ContentLabel>
            </Content>
            <Content>
              <ContentName>MaxLength: </ContentName> 
              <ContentLabel>{this.node.content.maxLength}</ContentLabel>
            </Content>
          </ContentWrapper>
        )}
      </div>
    )
  },
}
