import * as _ from 'lodash'
import uuid from 'uuid/v4'
import { relativePath } from '../../../store/services/firebase.service'

function fixDefaultNodeValue(node) {

  if (
    [
      'block',
      'grid-container',
      'GridItemWrapper',
      'sub-grid-container',
      'grid-box-container',
    ].includes(node.tag)
  ) {

    if (node.children === undefined) {
      node.children = []
    }
  }
}

export function vueDomV3(node, clone = true) {
  if (_.isString(node)) {
    return node
  }

  let vNode = node

  if (clone) {
    vNode = _.cloneDeep(node)
    fixDefaultNodeValue(vNode)
  }

  _.set(vNode, 'data.props[".key"]', node['.key'])
  _.set(vNode, 'data.props[".path"]', node['.path'])

  if (vNode.children) {
    const _children = []

    vNode.children.forEach((child, idx) => {
      if (!child || !child.tag) return

      // TODO: add 'grid-box-container' support for v3 grid-builder
      if (['grid-box-container'].includes(child.tag)) return

      child['.key'] = `${idx}`
      child['.path'] = `${node['.path']}/children/${idx}`
      child = vueDomV3(child)
      _children.push(child)
    })
    vNode.children = _children
  }
  return vNode
}

export function CreateChildFirebaseNodeV3(widget, key, root_path) {
  let path = `${root_path}/children/${key}`
  _.set(widget, 'data.props[".key"]', key)
  _.set(widget, 'data.props[".path"]', path)
  fixDefaultNodeValue(widget)

  if (widget.children) {
    widget.children = widget.children.map((child, idx) => {
      return CreateChildFirebaseNodeV3(child, `${idx}`, path)
    })
  }
  return widget
}

/**
 * Modifies a FirebaseNode of tag='block'
 * and adds .key and .path to the object return
 *
 * NOTE: Please `edit` with caution as a wrong 'key' or 'path' can change expected data structure or hierarchy
 */
export function CreateBlockVueDomV3(node, key, root_path) {
  let path = `${root_path}/${key}`
  node['.key'] = `${key}`
  node['.path'] = path

  return vueDomV3(node)
}

export function refreshBlockKeyPath(node, key, root_path) {
  let path = `${root_path}/${key}`
  node['.key'] = `${key}`
  node['.path'] = path

  return vueDomV3(node, false)
}

export function CreateWidgetFromFirebaseNode(widget, changeVNodeId = false) {
  let _widget = _.omit(widget, [
    '.key',
    '.path',
    'data.props[".key"]',
    'data.props[".path"]',
  ])
  if (changeVNodeId) {
    _.set(_widget, 'data.props.vnodeId', uuid())
  }

  if (_widget.children) {
    _widget.children = _widget.children.map((child) => {
      return CreateWidgetFromFirebaseNode(child, changeVNodeId)
    })
  }
  return _widget
}

function _getKey(snapshot) {
  return typeof snapshot.key === 'function' ? snapshot.key() : snapshot.key
}

export function isObject(val) {
  return Object.prototype.toString.call(val) === '[object Object]'
}

export function createRecord(snapshot) {
  const value = snapshot.val()
  const res = isObject(value) ? value : { '.value': value }
  res['.key'] = _getKey(snapshot)
  res['.path'] = relativePath(snapshot.ref)
  return res
}

export function constructV3PageEngineDom (node, key) {
  return { key: `block-node-${key}`, node: node}
}
/**
 * Returns a cloned version of all blocks in a page
 */
export function cloneVDom(nodeBlocks, root_path) {
  return nodeBlocks.map((node, key) => {
    let _node = CreateBlockVueDomV3(node, key, root_path)
    return constructV3PageEngineDom(_node, key)
  })
}

/**
 * Returns a cloned version of all blocks in a page
 */
export function cloneVDomFromDelta(nodeBlocks) {
  return nodeBlocks.map((node, key) => {
    let _node = vueDomV3(node)
    return constructV3PageEngineDom(_node, key)
  })
}

/**
 * Fixes Page Blocks if it's not a list
 */
export function fixBlocksObjectData(convrrtFirebase, snapshot) {
  let value = snapshot.val()
  if (isObject(value)) {
    const objectValues = Object.values(value)
    convrrtFirebase.set([])
    value = objectValues.filter((node) => {
      if (isObject(node) && node.tag === 'block') {
        convrrtFirebase.push(node)
      }
      return isObject(node)
    })
  }
  return value
}
