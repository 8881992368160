
<div class="form-group">
  <cvt-input
    type="text"
    :value="value"
    :label="label"
    :placeholder="placeholder"
    :clearable="false"
    :validation-status="validationStatus"
    :valid-message="$t('editor.right_nav.url_input.valid_message')"
    :invalid-message="$t('editor.right_nav.url_input.invalid_message')"
    @input="onInput"
    @change="onChange"
    @blur="onBlur"
  />
</div>
