<template>
  <el-select v-model="vnodeId" placeholder="choose a section">
    <el-option
      v-for="(section, idx) in sections"
      :key="section.vnodeId"
      :label="fmtName(section, idx + 1)"
      :value="section.vnodeId"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters } from 'vuex'
import * as getters from '../../store/modules/getters'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      sections: getters.LAYOUT_SECTIONS,
      anchors: getters.ANCHORS,
    }),
    vnodeId: {
      get() {
        return this.value
      },
      set(id) {
        this.$emit('input', id)
      },
    },
  },

  methods: {
    fmtName(section, idx) {
      return section.anchorName || `Section ${idx}`
    },
  },
}
</script>
