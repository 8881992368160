export default {
    name: 'AddImageUrl',
    props: {
      imageUrl: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      }
    },
    data () {
      return { }
    },
  }