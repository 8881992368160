export default {
  name: 'AddVideoUrl',
  props: {
    videoUrl: {
      type: String,
      default: ''
    }
  },
  data () {
    return { }
  },
}