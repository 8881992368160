import * as getters from '../getters'
import * as mutations from '../mutations'


export default {
    state: {
        userEmail: "",
    },
    getters: {
        [getters.SIGNIN_USER_EMAIL]({ userEmail }) {
            return userEmail
        }
    },
    mutations: {
        [mutations.SET_SIGNIN_USER_EMAIL](state, userEmail) {
            state.userEmail = userEmail
        }
    }
}