import axios from 'axios'

export { axios }

export const apiClient = axios.create({
  timeout: 60000,
  withCredentials: true,
  baseURL: '/api',
})

export default class APIClient {
  _client = apiClient

  call(...args) {
    return this._client(...args)
  }

  justData(resp) {
    return resp.data
  }

  get(url, params = {}) {
    return this._client.get(url, {
      params,
    })
  }

  put(url, data?: Object, config = {}) {
    return this._client.put(url, data, config)
  }

  post(url, data?: Object, config = {}) {
    return this._client.post(url, data, config)
  }

  delete(url, data?: Object, config = {}) {
    return this._client.delete(url, {
      data,
      ...config,
    })
  }

  head(url, config = {}) {
    return this._client.head(url, config)
  }
}
