<script>
import BlockV3 from '../../../../components/v3/editor/nodes/Block.vue'
import GridContainerV3Popup from "./GridContainer.vue";

export default BlockV3.extend({
  name: "BlockV3Popup",
  components: {
    'grid-container': GridContainerV3Popup,
  }
})
</script>