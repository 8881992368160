import { styled, VueEmotion } from '@egoist/vue-emotion'

import { generateUuid } from '../util/generateUuid'
import Vue from '../../../lib/utils/vue'
import { mapState } from '../../../../builder/node_modules/vuex'

let Spacing

Vue.use(VueEmotion)

const getRadioGroup = () => styled('div')`
  display: flex;
  flex-direction: column;
  margin: ${Spacing.SPACING_8}px 0;

  ${(props) =>
    props.orientation === 'horizontal' ? 'flex-direction:row;' : ''}
`

export default {
  name: 'CvtRadioGroup',
  components: {},
  props: {
    value: {
      type: String,
      default: '1'
    },
    orientation: {
      type: String,
      default: 'horizontal'
    },
    initialValue: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'md'
    },
    theme: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    removeActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      uniqueName: this.name || generateUuid(),
      activeValue: ''
    }
  },
  watch: {
    removeActive (value) {
      if (value) {
        this.setActiveValue('')
      }
    },
    initialValue (value) {
      if (value) {
        this.setActiveValue(value);
      }
    }
  },
  provide () {
    return {
      radioGroup: this
    }
  },
  computed: {
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing
    }),
    isDisabled() {
      return this.disabled;
    }
  },
  created () {
    Spacing = this.Spacing
    this.activeValue = this.initialValue
  },
  methods: {
    setActiveValue (value) {
      this.activeValue = value
    }
  },
  render: function (h) {
    const RadioGroup = getRadioGroup()

    return (
      <RadioGroup orientation={this.orientation}>
        {this.$slots.default}
      </RadioGroup>
    )
  }
}
