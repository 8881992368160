import * as _ from 'lodash'
import uuid from 'uuid/v4'

const DEFAULT_SPACING = {
  margin: {},
  padding: {},
}

export const MARKETPLACE_PATH = '/marketplace-2022-08-25'
export const START_OVER_KEY = 'Initial Page Design'

export const FIELD_ICON_MAP = {
  email: 'email',
  text: 'short_text',
  textarea: 'subject',
  tel: 'call',
  url: 'link',
  yesno: 'radio_button_checked',
  select: 'arrow_drop_down_circle',
  selectMulti: 'list',
  radio: 'radio_button_checked',
  checkbox: 'check_box',
  hidden: 'short_text',
  date: 'date_range',
  currency: 'attach_money',
  'datetime-local': 'access_time',
}

export const TEXT_TYPES = ['email', 'password', 'text', 'url', 'tel']

export const DATE_TYPES = ['date', 'datetime-local', 'time', 'month', 'week']

export const SELECT_TYPES = ['select', 'selectMulti']

export const ADDRESS_FORMATS = {
  full_address: [
    'line1',
    'line2',
    'locality',
    'region',
    'postal_code',
    'country_code',
  ],
  city_state_zip: ['locality', 'region', 'postal_code'],
  city_state: ['locality', 'region'],
  state_country: ['region', 'country_code'],
  state: ['region'],
  zip: ['postal_code'],
  country: ['country_code'],
}

export class VirtualField {
  id = uuid()
  label = 'Virtual Field'
  help = ''
  options = []
  required = false
  labelOn = true
  custom = false
  virtual = true
  defaultValue = ''
  urlKey = ''
  socialField = ''

  constructor(type) {
    this.type = type
    this.icon = FIELD_ICON_MAP[this.type]
  }
}

export function spacing({ margin = {}, padding = {} } = DEFAULT_SPACING) {
  return {
    margin: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      unit: 'px',
      vertical: 'my-0',
      horizontal: 'mx-0',
      ...margin,
    },
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      unit: 'px',
      vertical: 'py-0',
      horizontal: 'px-0',
      ...padding,
    },
  }
}

export function assignIdsRecursive(node) {
  _.set(node, 'data.props.vnodeId', uuid())

  // set form-id to unique id when it is cloned
  if (_.get(node, 'tag') === 'c-form') {
    _.set(node, 'data.props.id', uuid())
  }

  if (_.isArray(node.children) === false) return node

  node.children = node.children.map((child) => {
    return assignIdsRecursive(child)
  })

  return node
}

export class VNode {
  tag = ''
  data = new VNodeData()
  context = {}
  children = []
  constructor(opts = {}) {
    _.merge(this, opts)
  }
}

export class VNodeData {
  props = {
    class: {},
    style: {},
    vnodeId: uuid(),
    domProps: {},
    attrs: {},
  }

  constructor(opts = {}) {
    _.merge(this, opts)
  }
}

export class CodeSnippet extends VNode {
  tag = 'code-snippet'
}

export class BuyNowButton extends VNode {
  tag = 'buy-now-button'
  data = new VNodeData({
    props: {
      ...spacing(),
      showImage: true,
      showPrice: true,
      showName: true,
      showDesc: true,
      stripe: {
        publishableKey: '',
        product: null,
        sku: null,
      },
    },
  })
}

export class Block extends VNode {
  // tag = 'block'
  // data = new VNodeData({
  //   props: {
  //     ...spacing({
  //       margin: {
  //         vertical: 'my-0',
  //         horizontal: 'mx-0'
  //       },
  //       padding: {
  //         vertical: 'py-1',
  //         horizontal: 'px-0'
  //       }
  //     }),
  //     background: {
  //       image: '',
  //       size: 'cover',
  //       repeat: 'no-repeat',
  //       y: 0,
  //       active: 'image',
  //       parallax: false
  //     },
  //     isCover: false,
  //     isFluid: false,
  //     hideOnTablet: false,
  //     hideOnMobile: false,
  //     marginPadding: {
  //       desktop: {
  //         padding: {
  //           vertical: 'py-3',
  //           horizontal: ''
  //         },
  //         margin: {
  //           vertical: '',
  //           horizontal: ''
  //         }
  //       },
  //       phone: {
  //         padding: {
  //           vertical: 'py-3',
  //           horizontal: ''
  //         },
  //         margin: {
  //           vertical: '',
  //           horizontal: ''
  //         }
  //       }
  //     }
  //   }
  // })

  constructor(isPopupBlock = false) {
    super()
    super.tag = 'block'
    super.data = new VNodeData({
      props: {
        isPopupBlock,
        ...spacing({
          margin: {
            vertical: 'my-0',
            horizontal: 'mx-0',
          },
          padding: {
            vertical: 'py-1',
            horizontal: 'px-0',
          },
        }),
        bgShape: {
          isFlipped: false,
          isInverted: false,
          fillColor: "",
          fillColorAlpha: 1.0,
          isFullHeight: false,
          adjustHeightInPercentage: false,
          adjustwidthInPercentage: false,
          desktop: {
            height: 150,
            width: 100,
          },
          phone: {
            height: 150,
            width: 100,
          },
          position: 'top',
          shape: {
            name: "NONE",
            svg: null
          },
        },
        background: {
          image: '',
          size: 'cover',
          repeat: 'no-repeat',
          y: 0,
          active: 'image',
          parallax: false,

          overlay: false,
          coloroverlay: "",
          coloralpha: 0.9,

          imagePosition: {
            default: 'center center',
            rawPos: {
              x: null,
              y: null,
            },
            calcPos: {
              x: null,
              y: null,
            },
          },
          box: '',
        },
        isCover: false,
        isFluid: false,
        hideOnTablet: false,
        hideOnMobile: false,
        hideOnDesktop: false,
        marginPadding: {
          desktop: {
            padding: {
              vertical: 'py-4',
              horizontal: '',
            },
            margin: {
              vertical: '',
              horizontal: '',
            },
          },
          phone: {
            padding: {
              vertical: 'py-4',
              horizontal: '',
            },
            margin: {
              vertical: '',
              horizontal: '',
            },
          },
        },
      },
    })
  }
  children = []
}

export class HeaderBlock extends Block {
  constructor() {
    super()
    this.tag = 'header-block'
    this.data.props.isHeaderBlock = true
  }
  children = [new GridContainer()]
}

export class FooterBlock extends Block {
  constructor() {
    super()
    this.tag = 'footer-block'
    this.data.props.isFooterBlock = true
  }
  children = [new GridContainer()]
}

export class Row extends VNode {
  tag = 'row'
  data = new VNodeData({
    props: {
      ...spacing({
        margin: {
          vertical: 'my-0',
          horizontal: 'mx-0',
        },
        padding: {
          vertical: 'py-1',
          horizontal: 'px-0',
        },
      }),
      noGutters: false,
    },
  })
}

export class GridContainer extends VNode {
  // tag = 'grid-container'
  // data = new VNodeData({
  //   props: {}
  // })

  /**
   * Refactoring to add default parameters
   * while initializing.
   * Ex. To make Fixed Row Grid
   * maxRow = 0 -> infinite rows
   */
  constructor(maxRow = 0, column = 12) {
    super()
    super.tag = 'grid-container'
    super.data = new VNodeData({
      props: {
        options: {
          alwaysShowResizeHandle:
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent,
            ),
          resizable: {
            handles: 'e, se, s, sw, w, n, ne, nw',
          },
          acceptWidgets: '.grid-stack-item',
          removeTimeout: 100,
          removable: '#trash',
          float: true,
          maxRow: maxRow,
          column: column,
          // cellHeight: 78,
          styleInHead: true,
          placeholderClass: 'cvt-grid-stack-placeholder',
          disableOneColumnMode: true,
        },
      },
    })
  }
  children = []
}

export class CPopupBuilder extends VNode {
  tag = 'c-popup-builder'
  data = new VNodeData({
    props: {
      popupWidth: '60%',
      popupHeight: 'auto',
      isFullScreen: false,
      closeButtonPosition: 'end',
    },
  })
}

export class GridBoxContainer extends VNode {
  tag = 'grid-box-container'
  data = new VNodeData({
    props: {
      ...spacing({
        margin: {
          vertical: 'my-0',
          horizontal: 'mx-0',
        },
        padding: {
          vertical: 'py-1',
          horizontal: 'px-0',
        },
      }),
      size: 0,
      sizePhone: 12,
      offset: 0,
      background: {
        image: '',
        size: 'cover',
        repeat: 'no-repeat',
        y: 0,
        active: 'image',
      },
      border: '',
      borderRadius: 0,
      shadow: '',
    },
  })
}

export class SubGridContainer extends VNode {
  tag = 'sub-grid-container'
  data = new VNodeData({
    props: {
      ...spacing({
        margin: {
          vertical: 'my-0',
          horizontal: 'mx-0',
        },
        padding: {
          vertical: 'py-1',
          horizontal: 'px-1',
        },
      }),
      size: 0,
      sizePhone: 12,
      offset: 0,
      background: {
        image: '',
        size: 'cover',
        repeat: 'no-repeat',
        y: 0,
        active: 'image',
      },
      border: '',
      borderRadius: 0,
      shadow: '',
    },
  })
  children = []
}

export class Column extends VNode {
  tag = 'column'
  data = new VNodeData({
    props: {
      ...spacing({
        margin: {
          vertical: 'my-0',
          horizontal: 'mx-0',
        },
        padding: {
          vertical: 'py-1',
          horizontal: 'px-0',
        },
      }),
      size: 0,
      sizePhone: 12,
      offset: 0,
      background: {
        image: '',
        size: 'cover',
        repeat: 'no-repeat',
        y: 0,
        active: 'image',
      },
      border: '',
      borderRadius: 0,
      shadow: '',
    },
  })
}

export class GridItemWrapper extends VNode {
  tag = 'GridItemWrapper'
  children = []
  data = new VNodeData({
    props: {
      phone: {
        dataX: 0,
        dataY: 0,
        dataWidth: 1,
        dataHeight: 1,
        flexHorizonAlign: 'justify-content-center',
        flexVerticalAlign: 'align-items-center',
        noResize: false,
      },
      desktop: {
        dataX: 0,
        dataY: 0,
        dataWidth: 1,
        dataHeight: 1,
        flexHorizonAlign: 'justify-content-center',
        flexVerticalAlign: 'align-items-center',
        noResize: false,
      },
      borderWidth: 0,
      borderStyle: "",
      borderColor: "",
      borderRadius: 0,
    },
  })
  constructor(opts) {
    super(opts)
    this.data.props.phone = _.clone(opts.phone || {})
    this.data.props.desktop = _.clone(opts.desktop || {})
  }
}

export class Icon extends VNode {
  tag = 'icon'
  data = new VNodeData({
    props: {
      set: 'material-icon',
      selector: 'star_half',
      size: 3,
      ...spacing(),
      bootstrapAlignment: 'mx-auto',
    },
  })
}

export class Draft extends VNode {
  tag = 'draft'
  data = new VNodeData({
    props: {
      ...spacing(),
      contentsHTML: '<br>',
      contents: {
        ops: [
          {
            attributes: {
              size: '20',
            },
          },
          {
            insert: '\n',
          },
        ],
      },
      shadow: '',
    },
  })
}

export class CImage extends VNode {
  tag = 'c-image'
  data = new VNodeData({
    props: {
      ...spacing(),
      width: 100,
      src: '',
      shadow: 'shadow-none',
      radius: 'img-radius-none',
      bootstrapAlignment: 'mx-auto',
      bootstrapAlignment: 'mx-auto',
      hideImgOverflow: false,
      popup: {disabled: true},
      action: "",
      anchor: ""
    },
  })
}

export class CSurvey extends VNode {
  tag = 'c-survey'
  data = new VNodeData({
    props: {
      ...spacing(),
      width: 100,
      src: '',
      shadow: 'shadow-none',
      radius: 'img-radius-none',
      bootstrapAlignment: 'mx-auto',
    },
  })
}

export class BrandLogo extends VNode {
  tag = 'brand-logo'
  data = new VNodeData({
    props: {
      ...spacing(),
      width: 100,
      bootstrapAlignment: 'mx-auto',
      hideLogoOverflow: false,
    },
  })
}

export class CountdownTimer extends VNode {
  tag = 'countdown-timer'
  data = new VNodeData({
    props: {
      type: 'dateAndTimeBased',
      themeStyle: 'themeOne',
      endDate: '',
      endTime: '',
      shortEndTime: 0,
      timeZoneOffset: '+00:00',
      expireAction: 'redirect_to_url',
      expireActionMessage: '',
      expireActionPageId: '',
      expireActionRedirectionUrl: '',
    },
  })
}
 
export class Checkout extends VNode {
  tag = 'checkout'
  data = new VNodeData({
    props: {
      showThumbnail: true,
      showTitle: true,
      showBenefits: true,
      showCost: true,
      showSavings: true,
      product: { // stripe product
        thumbnailUrl: '',
        title: '',
        benefits: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
        currency: '',
        price: 0,
        discountedPrice: 0,
      },
      marginPadding: {
        desktop: {
          padding: {
            vertical: '',
            horizontal: '',
          },
          margin: {
            vertical: '',
            horizontal: '',
          },
        },
        phone: {
          padding: {
            vertical: '',
            horizontal: '',
          },
          margin: {
            vertical: '',
            horizontal: '',
          },
        },
      },
    }
  })
  children = [new CButton()]
}

export class VNodeBtnProps {
  cta = 'Call to action'
  fill = 'btn-primary'
  shape = 'btn-rounded'
  align = ''
  href = ''
  color = ''
  size = 'btn-lg'
  shadow = 'btn-no-shadow'
  anchorTarget = '_self'
  action = 'link'
  pageId = ''
  isBlock = false
  form = false
}

export class VNodeFormBtnProps extends VNodeBtnProps {
  form = true
  isBlock = true
}

export class CButton extends VNode {
  tag = 'c-button'
  data = new VNodeData({
    props: {
      ...spacing(),
      ...new VNodeBtnProps(),
      bootstrapAlignment: 'mx-auto',
    },
  })
}

export class CFormButton extends CButton {
  data = new VNodeData({
    props: new VNodeFormBtnProps(),
  })
}

export class Divider extends VNode {
  tag = 'divider'
  data = new VNodeData({
    props: {
      ...spacing(),
      size: 'hr-2',
    },
  })
}

export class CVideo extends VNode {
  tag = 'c-video'
  data = new VNodeData({
    props: {
      ...spacing(),
    },
  })
}

export class CForm extends VNode {
  tag = 'c-form'
  data = new VNodeData({
    props: {
      id: uuid(),
      page: '',
      url: '',
      width: 100,
      design: '',
      redirectChoice: 'page',
      pageId: '',
      redirectUrl: '',
      inline: false,
      // recaptcha: true,
      ...spacing(),
      fields: [
        // new Field({
        //   id: 'email',
        //   label: 'Email',
        //   type: 'email',
        //   icon: 'email',
        //   socialField: 'email',
        //   required: true
        // })
      ],
    },
  })
  children = [new CFormButton()]
}

export class Field {
  id = uuid()
  name = ''
  label = ''
  help = ''
  type = 'text' // inf standard,
  options = []
  required = false
  crm = false
  icon = ''
  socialField = ''
  labelOn = true
  constructor(opts = {}) {
    _.merge(this, opts)
  }
}

export class VirtualFieldOption {
  id = uuid()
  label = ''
  value = ''
}

export class FieldOption {
  id = uuid()
  name = ''
  label = ''
  crm = false
  crmMapping = null
  constructor(opts = {}) {
    _.merge(this, opts)
  }
}

export class TextField extends Field {
  type = 'text'
}

export class NumberField extends Field {
  type = 'number'
  min = 0
  max = 9999
}

export class PhoneField extends Field {
  type = 'tel'
}

export class EmailField extends Field {
  type = 'email'
  socialField = 'email'
}

export class TextAreaField extends Field {
  type = 'textarea'
}

export class SelectField extends Field {
  type = 'select'
  multi = false
}

export class RadioField extends Field {
  type = 'radio'
}

export class CheckboxField extends Field {
  type = 'checkbox'
}

export const FIELD_TYPES = [
  { label: 'Email', type: 'email', class: EmailField, icon: 'email' },
  { label: 'Short Answer', type: 'text', class: TextField, icon: 'short_text' },
  {
    label: 'Paragraph',
    type: 'textarea',
    class: TextAreaField,
    icon: 'subject',
  },
  { label: 'Phone Number', type: 'tel', class: PhoneField, icon: 'call' },
  {
    label: 'Dropdown',
    type: 'select',
    class: SelectField,
    icon: 'arrow_drop_down_circle',
  },
  {
    label: 'Radio',
    type: 'radio',
    class: RadioField,
    icon: 'radio_button_checked',
  },
  {
    label: 'Checkbox',
    type: 'checkbox',
    class: CheckboxField,
    icon: 'check_box',
  },
]

export const ELEMENT_NAME_MAP = {
  Block: 'Section',
  Row: 'Row',
  GridContainer: 'Grid Container',
  GridBoxContainer: 'Container',
  SubGridContainer: 'Container Box',
  Column: 'Column',
  CButton: 'Button',
  Divider: 'Divider',
  CVideo: 'Video',
  CForm: 'Form',
  Icon: 'Icon',
  Draft: 'Text',
  CImage: 'Image',
  CSurvey: 'Survey',
  BrandLogo: 'Brand Logo',
  CountdownTimer: 'Countdown Timer',
  CodeSnippet: 'Code Snippet',
  Checkout: 'Checkout',
  BuyNowButton: 'Buy Now Button',
}
