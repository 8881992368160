
<section
  :id="anchorId"
  class="block"
  :class="classes"
  :data-vnode-id="vnodeId"
  :data-key-id="blockKey"
  @click="select"
  @mouseover="target"
  @contextmenu.stop.prevent="showContextMenu"
  @dblclick.stop.prevent="triggerOpenAssetManager"
>
  <div
    v-show="showTextureBackground && isSectionUIEnabled"
    class="dashed-texture"
  />

  <div v-if="rearrangingCurrentBlocks" class="working-on-block">
    <div class="working-on-block__container">
      <h3 class="loader-message">Please wait...</h3>
    </div>
  </div>

  <div
    v-show="showControlls && isSectionUIEnabled"
    class="block__target__indicator block__target__indicator--top"
  ></div>
  <div
    v-show="showControlls"
    class="block__target__indicator block__target__indicator--right"
  ></div>
  <div
    v-show="showControlls && isSectionUIEnabled"
    class="block__target__indicator block__target__indicator--bottom"
  ></div>
  <div
    v-show="showControlls"
    class="block__target__indicator block__target__indicator--left"
  ></div>

  <div :class="stylesheet.classes.bgColor"></div>
  <div :class="stylesheet.classes.bgImage"></div>
  <div :class="stylesheet.classes.overlayColor"></div>
  <!-- eslint-disable vue/no-v-html -->
  <div :class="stylesheet.classes.bgShape" v-html="bgSvg"></div>
  <!--eslint-enable-->
  <cvt-section-drag-items
    v-if="vnodeId && isGridBuilder && !isSectionUIEnabled && !isBuilderOnStaticMode && showElementsBtn"
    class="drag-items-block"
    usage="block"
    :show-plus-btn="true"
    :vnode-id="vnodeId"
    :widget-class="'grid-stack-item'"
  />

  <div v-show="isSectionUIEnabled" class="section-controls-wrap">
    <section-controls
      v-show="showControlls"
      :class="sectionControlsClasses"
      :vnode-id="vnodeId"
      @moveSectionUp="moveSectionUp"
      @moveSectionDown="moveSectionDown"
      @copySection="copySection"
      @swapSection="swapSection"
      @deleteSection="deleteSection"
      @arrangeBlock="arrangeBlock"
    />
  </div>

  <div v-show="!isEmpty" :class="containerClasses">
    <template v-for="node in fbNode.children">
      <component
        :is="node.tag"
        :key="node.data.props.vnodeId"
        :fb-node="node"
        v-bind="node.data.props"
      ></component>
    </template>
  </div>

  <cvt-popover
    :ref="popoverRef"
    :mode="mode"
    placement="top"
    :target="`${anchorId}-add-core-element`"
    triggers="click"
  >
    <template #popper>
      <div class="d-flex">
        <cvt-button
          color="success"
          class="text-capitalize ml-1"
          :text="$t('add_section.cta')"
          @click.stop.prevent="openQuickLaunch"
        />
      </div>
    </template>
  </cvt-popover>
</section>
