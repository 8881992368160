
<div
  :id="vnodeId"
  :data-vnode-id="vnodeId"
  class="cvt-editor-video w-100"
  :class="classes"
  :style="styles"
  draggable="true"
  @click="select"
  @mouseover="target"
  @contextmenu.prevent.stop="showContextMenu"
  @dblclick.stop.prevent
  @dragstart="dragstart"
  @dragend="dragend"
>
  <drag-and-drop-zone
    :z-index="dropzoneZIndex"
    @dropped="addElementToSide"
  />

  <div
    class="embed-responsive embed-responsive-16by9"
    :class="videoWrapperClasses"
  >
    <div class="cvt-editor-video__overlay" />
    <iframe
      class="embed-responsive-item"
      :src="src"
      allowfullscreen
    />
  </div>
  <template v-if="showActionButtonsOnClick && !isGridBuilder">
    <!--THis is only here because of freshwork in v1 gridbuilder,
    once they migrate to v2 or v3, it will become irrelevant-->
    <action-buttons
        v-if="selected"
        @duplicate="duplicateAndValidate"
        @remove="removeAndValidate"
    />
  </template>
</div>
