<template>
  <cvt-dialog
    :title="title"
    :show="visibleSave"
    :height-auto="true"
    :width="'512px'"
    @close="closeSave"
  >
    <template #title>
      <div>Save Snapshot</div>
    </template>
    <div
      class="revision-manager-dialog"
    >
      <cvt-input
        ref="snapInput"
        type="textarea"
        required
        :value="snapshotDesc"
        :label="$t('element.revision.snapshot.description.label')"
        :placeholder="$t('element.revision.snapshot.description.label')"
        @input="setSnapshotDesc"
      />
      

      <div
        v-loading="saving"
        class="revision-table-container"
      >

      </div>
    </div>

    <template #modalFooter>
      <div style="display: flex;">
      <cvt-button
        :outlined="true"
        text='Cancel'
        color='light'
        @click="closeSave"
      ></cvt-button>
      <cvt-button
        style="margin-left: 12px;"
        text='Save'
        :disabled="saveButtonDisabled"
        :outlined="true"
        special-style='dark'
        @click="saveSnapshotAndClearForm"
      ></cvt-button>
      </div>
    </template>
  </cvt-dialog>
</template>

<script>
import * as _ from 'lodash'
import * as getters from '../store/modules/getters'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { Notification } from 'element-ui'
import moment from 'moment'

export default {
  components: {},
  data () {
    return {
      snapshotDesc: '',
      clickedRevId: '',
    }
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    ...mapGetters('pageManager', {
        isSwitchingPages: 'isSwitchingPages'
    }),
    ...mapState('revisions', [
      'saving',
      'loading',
      'visibleSave',
      'history',
      'prevSnapshotID',
      'currentSnapshotID',
      'pageChanged'
    ]),
    ...mapState('globalTheme', {
      VersionHistoryCombined: ({ globalTheme }) => globalTheme.VersionHistoryCombined !== undefined ? globalTheme.VersionHistoryCombined : true
    }),
    title () {
      return this.$t('element.revision.title.label')
    },
    saveButtonDisabled () {
      return !this.pageChanged || this.loading || this.saving
    }
  },
  watch: {
    pageChanged (value) {
      if (value) {
        window.addEventListener('beforeunload', this.beforeUnloadEventHandler)
      } else {
        window.removeEventListener('beforeunload', this.beforeUnloadEventHandler)
      }
    }
  },
  methods: {
    ...mapMutations('revisions', [
      'closeSave'
    ]),
    ...mapActions('revisions', [
      'saveSnapshot',
      'restoreSnapshot'
    ]),
    async saveSnapshotAndClearForm () {
      if (this.snapshotDesc)
        await this.saveSnapshot({
          key: 'manually.saved',
          description: this.snapshotDesc
        })
        this.snapshotDesc = ''
        this.config.GLOBAL_THEME.snapshotSaveButtonCloseDialog === true ? this.closeSave() : ''
    },
    setSnapshotDesc: _.debounce(function ({target}) {
      this.snapshotDesc = target.value
    }, {
      trailing: true
    }, 600),
    async beforeUnloadEventHandler (event) {
      if (!this.isSwitchingPages) {
          event.preventDefault()
          // Chrome requires returnValue to be set.
          event.returnValue = ''

          await this.saveSnapshot({
              key: 'draft.auto.saved',
              description: this.$t('element.revision.browser.label')
          }).then((e) => {
              Notification.success({
                  duration: 6000,
                  message: `${this.$t('elements.property.revision.manger.success.saved.message')}`,
                  position: 'bottom-right'
              })
          }).catch((e) => {
              Notification.error({
                  duration: 6000,
                  message: 'Snapshot not save automatically, please try to save it manually!',
                  position: 'bottom-right'
              })
          })
      }
    },
    timeDistance (timestamp) {
      return moment(timestamp).fromNow()
    }
  }
}
</script>

<style lang="scss">
    @import "@/scss/variables";
    .revision-table-container {
      max-height: 500px;
      overflow-y: scroll;
    }
</style>
