<script>
import FirebaseNodeV3 from '../../base/FirebaseNode'
import Icon from "../../../editor/nodes/icon/Icon.vue";
import IconComponent from '../../../editor/nodes/icon/Icon.js'

export default Icon.extend({
  name: "IconV3",
  mixins: [FirebaseNodeV3],
  components: {
    'icon': IconComponent
  }
})
</script>