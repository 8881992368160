
<div
  class="bootstrap-alignment"
  :class="
    isGridBuilder && !disableAlignment && !disableConstrain
      ? 'add-padding'
      : ''
  "
>
  <div class="row">
    <div v-if="!disableAlignment" class="col align-items-center"></div>
    <!-- Old Builder -->
    <div class="col-12 ml-auto">
      <cvt-radio-group
        v-if="!isGridBuilder"
        orientation="horizontal"
        :initial-value="alignment"
        :remove-active="removeActive"
      >
        <cvt-radio-button
          size="md"
          value="mr-auto"
          :shape="btnShape"
          @radioClick="updateAlignment"
        >
          <cvt-icon icon="cvt-icon-align-left" />
        </cvt-radio-button>
        <cvt-radio-button
          size="md"
          value="mx-auto"
          :shape="btnShape"
          @radioClick="updateAlignment"
        >
          <cvt-icon icon="cvt-icon-align-horizontal-center" />
        </cvt-radio-button>
        <cvt-radio-button
          size="md"
          value="ml-auto"
          :shape="btnShape"
          @radioClick="updateAlignment"
        >
          <cvt-icon icon="cvt-icon-align-right" />
        </cvt-radio-button>
      </cvt-radio-group>
    </div>
  </div>

  <!-- Grid Builder -->
  <div class="row d-flex justify-content-between">
    <div v-if="isGridBuilder && !disableAlignment" class="col">
      <cvt-radio-group
        orientation="horizontal"
        :initial-value="flexVerticalAlignment"
        :remove-active="removeActive"
      >
        <el-tooltip
          :content="$t('elements.properties.alignments.verticalTop')"
          placement="top"
        >
          <cvt-radio-button
            size="sm"
            value="constrainTop"
            @radioClick="updateFlexAlignment"
          >
            <cvt-icon icon="cvt-icon-align-top"></cvt-icon>
          </cvt-radio-button>
        </el-tooltip>
        <el-tooltip
          :content="$t('elements.properties.alignments.verticalCenter')"
          placement="top"
        >
          <cvt-radio-button
            size="sm"
            value="constrainCenter"
            @radioClick="updateFlexAlignment"
          >
            <cvt-icon icon="cvt-icon-align-vertical-center"></cvt-icon>
          </cvt-radio-button>
        </el-tooltip>
        <el-tooltip
          :content="$t('elements.properties.alignments.verticalBottom')"
          placement="top"
        >
          <cvt-radio-button
            size="sm"
            value="constrainBottom"
            @radioClick="updateFlexAlignment"
          >
            <cvt-icon icon="cvt-icon-align-bottom"></cvt-icon>
          </cvt-radio-button>
        </el-tooltip>
      </cvt-radio-group>
    </div>
    <div v-if="isGridBuilder && !disableConstrain" class="col">
      <cvt-radio-group
        orientation="horizontal"
        :initial-value="flexHorizonAlignment"
        :remove-active="removeActive"
      >
        <el-tooltip
          :content="$t('elements.properties.alignments.horizontalLeft')"
          placement="top"
        >
          <cvt-radio-button
            size="sm"
            value="alignLeft"
            @radioClick="updateFlexConstrain"
          >
            <cvt-icon icon="cvt-icon-align-left"></cvt-icon>
          </cvt-radio-button>
        </el-tooltip>
        <el-tooltip
          :content="$t('elements.properties.alignments.horizontalCenter')"
          placement="top"
        >
          <cvt-radio-button
            size="sm"
            value="alignCenter"
            @radioClick="updateFlexConstrain"
          >
            <cvt-icon icon="cvt-icon-align-horizontal-center"></cvt-icon>
          </cvt-radio-button>
        </el-tooltip>
        <el-tooltip
          :content="$t('elements.properties.alignments.horizontalRight')"
          placement="top"
        >
          <cvt-radio-button
            size="sm"
            value="alignRight"
            @radioClick="updateFlexConstrain"
          >
            <cvt-icon icon="cvt-icon-align-right"></cvt-icon>
          </cvt-radio-button>
        </el-tooltip>
      </cvt-radio-group>
    </div>
  </div>

  <div class="row">
    <div v-if="OrgName !== orgNameFreshWork" class="col">
      <alignment-button
        :outlined="true"
        :full-width="true"
        shape="rounded"
        color="light"
        :text="$t(`elements.properties.remove_alignment`)"
        :mode="mode"
        :custom-alignment-style="customAlignmentStyle"
        @click="clearAlignment"
      />
    </div>
  </div>
</div>
