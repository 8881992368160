<template>
  <cvt-select
    mode="dropdown"
    :value="selectedPage"
    :label="$t('element.property.form.option.funnel.label')"
    :placeholder="$t('element.property.form.option.funnel.label')"
    :background-mode="mode"
    @input="(value) => change(value)"
  >
    <option
      v-for="page in pages"
      :key="page.id"
      :value="page.id"
      v-text="page.pageName"
    />
  </cvt-select>
</template>

<script>
import * as _ from 'lodash'
import { mapState } from 'vuex'
export default {
  props: {
    selectedPage: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'LIGHT'
    }
  },
  computed: {
    ...mapState({
      pages: ({ site }) => site && site.site && _.sortBy(site.site.pages, ['isInbdex', 'createdAt'])
    }),
  },
  methods: {
    change (e) {
      this.$emit('change', e)
    }
  }
}
</script>

<style lang="scss">
</style>
