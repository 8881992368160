import { mapGetters, mapState } from 'vuex'
import * as getters from '../../../../store/modules/getters';

export default {
  data () {
    return {
      colors: '',
      fontSize: '',
      textFontWeight: '',
      border: '',
      radii: '',
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
  },
  computed: {
    ...mapState('globalTheme', {
      Colors: ({ globalTheme }) => globalTheme.Colors,
      FontSize: ({ globalTheme }) => globalTheme.FontSize,
      Border: ({ globalTheme }) => globalTheme.Border,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
    }),

    ...mapGetters ('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
    }),
  },
  methods: {
  },
  created () {
    this.colors = this.Colors;
    this.fontSize = this.FontSize;
    this.textFontWeight = this.TextFontWeight;
    this.border = this.Border;
    this.radii = this.Radii;
  },

}