<template>
  <div id="page-engine" data-vnode-id="page-engine" :class="pageEngineClasses">
    <header-engine  :page="page"/>
    <template v-for="obj in clonedVDom">
      <component :is="obj.node.tag" v-if="!isReload" :key="obj.key" :fb-node="obj.node" v-bind="obj.node.data.props"></component>
    </template>
    <footer-engine :page="page"/>
  </div>
</template>

<script>
import FirebaseNodeV3 from './base/FirebaseNode'
import {cloneVDomFromDelta} from './lib/vue-dom-obj'
import {mapActions, mapGetters, mapMutations} from "vuex";
import _ from "lodash";
import {Notification} from "element-ui";
import * as getters from '@/js/store/modules/getters'
import * as actions from '@/js/store/modules/actions'
import * as mutations from '@/js/store/modules/mutations'

import Block from "./editor/nodes/Block.vue";
import HeaderEngine from "@/js/components/v3/header/HeaderEngine.vue";
import FooterEngine from "@/js/components/v3/footer/FooterEngine.vue";
import CountdownTimer from "./editor/nodes/CountdownTimer/CountdownTimer.vue";
import Checkout from "./editor/nodes/Checkout/Checkout.vue";
import FbVNode from "../base/FbVNode.vue";
import {PAGE_ENGINE_VDOM_UPDATE} from "../../plugins/events";
import {DESKTOP, MOBILE} from "../../common/constants/constants";

export default FbVNode.extend({
  name: "PageEngineV3",
  mixins: [FirebaseNodeV3],
  components: {
    'block': Block,
    'header-engine': HeaderEngine,
    'footer-engine': FooterEngine,
    'countdown-timer': CountdownTimer,
    'checkout': Checkout,
  },
  async beforeRouteLeave (to, from, next) {
    try {
      const pageIsValid = await this.pageIsValid()

      if (pageIsValid === false) {
        return next(false)
      }

      this.toggleAdvancedMenu(false)
      this.toggleLeftSideMenu({ open: false })
      this.updateSiteThumbnail()
      this.setViewMode('desktop')
      this.deselect()
      this.ignore()
      next()
    } catch (err) {
      console.error('beforeRouteLeave error', err)
      next(false)
    }
  },

  props: {
    // usually passed from router
    page: {
      type: Object,
      required: true
    },
    popupPath: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      name: 'PageEngine',
      clonedVDom: [],
      isReload: false
    }
  },
  computed: {
    ...mapGetters({
      vdom: getters.DELTA_VDOM,
      site: getters.SITE_GET_DATA,
      step: getters.STEPS_CURRENT_STEP,
      siteGetActivePage: getters.SITE_GET_ACTIVE_PAGE,
      viewMode: getters.VIEW_MODE,
      historyReady: getters.HISTORY_INITIALIZED,
      config: getters.AUTH_GET_USER_CONFIG,
      builderVersion: getters.CURRENT_PRODUCT_VERSION_NUMBER
    }),
    pageEngineClasses() {
      return {
        'page-engine': true,
        'typography': true,
        'grid-builder': true,
        'view-mode-desktop': this.viewMode === DESKTOP,
        'view-mode-phone': this.viewMode === MOBILE
      }
    }
  },
  watch: {
    viewMode: {
      handler (newVal) {
        setTimeout(()=> {
          this.$nextTick(() => {
            this.$mount()
            this.clonedVDom = [...this.clonedVDom]
          })
        }, 200)
      },
    },
    historyReady: {
      handler (newVal, oldVal) {
        if (newVal) {
          this.buildDOM()
        }
      },
    },
  },

  beforeRouteEnter (to, from, next) {
    next((vm) => setTimeout(vm.initVdom, 500))
  },

  async beforeRouteUpdate (to, from, next) {
    try {
      const pageIsValid = await this.pageIsValid()

      if (pageIsValid === false) {
        return next(false)
      }
      this.startLoader('Switching Pages')
      setTimeout(this.initVdom, 500)
      next()
    } catch (err) {
      console.debug('beforeRouteUpdate error', err)
      next(false)
    }
  },

  methods: {
    ...mapMutations({
      ignore: mutations.SELECTOR_IGNORE,
      setQuickLaunchEngine: mutations.QUICK_LAUNCH_BLOCK_SET_PAGE_ENGINE,
      setViewMode: mutations.VIEW_MODE_SET
    }),
    ...mapActions({
      deltaInit: actions.DELTA_INIT,
      markStep: actions.STEPS_MARK,
      updateSiteThumbnail: actions.SITE_UPDATE_THUMBNAIL,
      toggleAdvancedMenu: actions.ADVANCED_MENU_TOGGLE,
      toggleLeftSideMenu: actions.LEFT_SIDE_MENU_TOGGLE,
      initLayout: actions.LAYOUT_INIT,
      undo: actions.HISTORY_COMMAND_UNDO,
      redo: actions.HISTORY_COMMAND_REDO,
    }),

    ...mapMutations('loader', {
      setLoaderMsg: 'setMessage'
    }),

    ...mapActions('loader', {
      startLoader: 'start'
    }),
    ...mapActions('pageManager', {
      initPageTracking: 'pageTrackingInit'
    }),
    buildDOM () {
      this.clonedVDom = cloneVDomFromDelta(this.vdom)
    },
    forceBuildDOM () {
      this.isReload = true
      this.disableCommandPush()
      this.buildDOM()
      this.$nextTick(() => {
        this.isReload = false
        setTimeout(() => this.enableCommandPush(), 500)
      })
    },
    async pageIsValid () {
      try {
        const nodes = this.findAllDescendants().filter(n => n.isEditorNode).filter(n => _.isFunction(n.validationErrors))
        const nodeErrors = await Promise.all(nodes.map(n => n.validationErrors()))
        const errors = _.flatten(nodeErrors)

        // Requires upgrades to 2.4.5
        // http://element.eleme.io/#/en-US/component/notification#notification
        // Notification.closeAll()
        errors.forEach(message => setTimeout(() => { Notification.error({ message, duration: 10000, position: 'bottom-right' }) }, 300))

         // find a better way to notify them of the errors as this currently is not effective and won't allow them to switch to other page

        return _.isEmpty(errors)
      } catch (err) {
        console.error('failed to validate page', err)
      }
      return true
    },
    initVdom () {
      this.deltaInit({
        key: this.path()
      })

      if (this.step.state === undefined) {
        this.markStep('inprogress')
      }

      this.initLayout({
        pageEngine: this
      })

      this.setQuickLaunchEngine({
        pageEngine: this
      })
      this.initPageTracking(this.page.id)
      console.debug(`Builder Version: ${this.builderVersion}`)
    },
    $hubEventHandler(data ) {
      if (data.forceBuild) {
        this.forceBuildDOM()
        return
      }
      this.buildDOM()
    }
  },
  mounted() {
    this.$hub.$on(PAGE_ENGINE_VDOM_UPDATE, this.$hubEventHandler)
  },
  beforeDestroy() {
    this.$hub.$off(PAGE_ENGINE_VDOM_UPDATE, this.$hubEventHandler)
  }
})
</script>

<style scoped lang="scss">
.undo_redo_container {
  position: sticky;
  z-index: 88888888;
  top: 0;
  left: 16px;

  .undo_test_button {
    display: flex;
    margin: 1rem;
    background-color: #2a3046;
    width: 5rem;
    padding: 0.5rem;

    button {
      margin-right: 5px;
    }
  }
}

</style>
