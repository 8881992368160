
<div class="remote-fields">
  <div v-if="loading" class="placeholder-container">
    <cvt-content-placeholder class="placeholder" />
  </div>
  <cvt-select
    v-else
    mode="dropdown"
    :value="value"
    :label="placeholder"
    :background-mode="mode"
    @input="(data) => updateSelected(data)"
  >
    <option
      v-for="datum in remoteData"
      :key="datum.key"
      :value="datum.value"
      v-text="datum.label"
    />
  </cvt-select>
</div>
