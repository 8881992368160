import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as getters from '../../../store/modules/getters'
import * as mutations from '../../../store/modules/mutations'
import * as actions from '../../../store/modules/actions'
import { Notification } from 'element-ui'
import { cvtRouteNodes } from '../../../utils/routes'

export default {
  data() {
    return {
      activeTab: null,
      envOptions: ['Staging', 'Production'],
      isJwtBtnDisabled: false,
      isOauthBtnDisabled: false,
      oidcCurrentScreen: 'discovery',
      newOidcConfigFields: [
        {
          key: 'userInfo',
          label: 'User information endpoint',
          type: 'text',
          value: '',
        },
        {
          key: 'clientId',
          label: 'ClientID',
          type: 'text',
          value: '',
        },
        {
          key: 'apiToken',
          label: 'Token endpoint',
          type: 'text',
          value: '',
        },
        {
          key: 'clientSecret',
          label: 'Client secret',
          type: 'text',
          value: '',
        },
        {
          key: 'authApi',
          label: 'Authorization endpoint',
          type: 'text',
          value: '',
        },
        {
          key: 'environment',
          label: 'Environment',
          type: 'select',
          value: '',
          options: ['Staging', 'Production'],
        },
      ],
      addOIDC: {
        environment: '',
        iodcUrl: '',
      },
      loading: false,
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      showModal: getters.GET_MODAL,
      envJwtToken: getters.ENV_JWT_TOKEN,
      oidcConfigs: getters.OIDC_CONFIG,
    }),
    jwtTabActive() {
      return this.activeTab === 'jwt'
    },
    oauthActive() {
      return this.activeTab === 'oauth'
    },
    isOidcEnvironmentScreen() {
      return this.oidcCurrentScreen === 'environment'
    },
    isOidcConfigurationScreen() {
      return this.oidcCurrentScreen === 'configurations'
    },
    isOidcDiscoveryScreen() {
      return this.oidcCurrentScreen === 'discovery'
    },
  },
  beforeDestroy() {},
  methods: {
    ...mapMutations({
      toggleModal: mutations.SET_MODAL,
    }),
    ...mapActions({
      fetchEnvJwtToken: actions.LOAD_ENV_JWT_TOKEN,
      fetchOidcConfig: actions.FETCH_OIDC_CONFIG,
      addOIDCconfig: actions.ADD_OIDC_CONFIG,
      createOidcConfig: actions.CREATE_OIDC_CONFIG,
    }),
    setActivetab(tab) {
      if (tab === 'jwt') {
        this.isJwtBtnDisabled = false
        this.isOauthBtnDisabled = true
      } else {
        this.isOauthBtnDisabled = false
        this.isJwtBtnDisabled = true
      }
      this.activeTab = tab
    },
    onAddNewEnvironment() {
      this.oidcCurrentScreen = 'configurations'
    },
    async onAddNewConfiguration() {
      this.loading = true

      await this.addOIDCconfig(this.addOIDC)
        .then(() => {
          this.loading = false
          Notification.success({
            title: 'Success',
            message: 'OIDC Configuration has been added',
            position: 'bottom-right',
          })
          this.oidcCurrentScreen = 'environment'
        })
        .catch((err) => {
          this.loading = false
          Notification.error({
            title: 'Woops',
            message: err.response.data.errors[0].message,
            position: 'bottom-right',
          })
        })
    },
    async onCreateNewConfiguration() {
      this.loading = true
      const payload = {}

      this.newOidcConfigFields.map((data) => {
        payload[data.key] = data.value
      })
      await this.createOidcConfig(payload)
        .then(() => {
          Notification.success({
            title: 'Success',
            message: 'New OIDC Configuration Created',
            position: 'bottom-right',
          })

          this.oidcCurrentScreen = 'environment'
          this.newOidcConfigFields = this.newOidcConfigFields.map((data) => {
            return {
              ...data,
              value: '',
            }
          })
        })
        .catch((err) => {
          this.loading = false
          Notification.error({
            title: 'Woops',
            message: err.response.data.errors[0].message,
            position: 'bottom-right',
          })
        })
    },
    continueToFeatureConfig() {
      this.$emit('onContinueToFeatureConfig')
    },
    startConnection(item, idx) {
      if (item.testPassed) return
      this.oidcConfigs[idx].testIsLoading = true
      setTimeout(() => {
        this.oidcConfigs[idx].testPassed = true
        this.toggleModal(true)
        this.oidcConfigs[idx].testIsLoading = false
      }, 5000)
    },
    removeOidcItem(idx, fields) {
      fields.splice(idx, 1)
    },
    addNewItem(event) {
      this.$emit('addNewItem', event)
    },
    closeModal() {
      this.toggleModal(false)
    },
    formatFirstLetter(str: String) {
      if (str) {
        const firstLetterCap = str.charAt(0).toUpperCase()
        const remainingLetters = str.slice(1)
        return firstLetterCap + remainingLetters
      }
      return ''
    },
    onOidcUrlChange({ target }) {
      this.addOIDC.iodcUrl = target.value
    },
    onEnvironmentChange(value) {
      this.addOIDC.environment = value
    },
    onChangeNewOidcInput({ target }, index) {
      this.newOidcConfigFields[index].value = target.value
    },
    selectOnChange(value, index) {
      this.newOidcConfigFields[index].value = value
    },
    onSkipItInvite() {
      this.$router.push({
        name: cvtRouteNodes.enterpriseIntegrateBuilderFeatureConfig,
      })
    },
    copyToClipboard() {
      const element = this.$refs.jwtToken
      element.select()
      document.execCommand('copy')
      Notification.success({
        message: 'Copied to your clipboard',
        position: 'bottom-right',
      })
    },
  },
  created() {
    this.fetchEnvJwtToken()
  },
}
