<template>
  <div>
    <h1>Input</h1>
    <div class="w-100">
      <cvt-input
        v-for="(variant, index) in variants"
        :key="index"
        :mode="variant.mode"
        :type="variant.type"
        :input-type="variant.type"
        :label="variant.label"
        :disabled="variant.disabled"
        :required="variant.required"
        :input-size="variant.inputSize"
        :validation-status="variant.validationStatus"
        :suffix-icon="variant.suffixIcon"
        :prefix-icon="variant.prefixIcon"
        :minlength="variant.minLength"
        :maxlength="variant.maxLength"
        :clearable="variant.clearable"
        :floating-label="variant.floatingLabel"
      />

      <span>Grouped Input</span>
      <cvt-input mode="LIGHT" type="url">
        <template #prefix>http://</template>
      </cvt-input>

      <cvt-input mode="LIGHT" type="url">
        <template #suffix>.com</template>
      </cvt-input>

      <cvt-input
v-model="value"
        type="search"
        mode="LIGHT"
        :fetch-suggestions="fetchSuggestions"
        label="Autocomplete"
      ></cvt-input>

      <cvt-input
v-model="customValue"
        type="search"
        mode="LIGHT"
        :fetch-suggestions="fetchCustomSuggestions"
        label="Custom Template"
      >
        <template slot="item" slot-scope="prop">
          <div class="value">{{prop.value}}</div>
          <span class="link">{{prop.link}}</span>
        </template>
      </cvt-input>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayInput',
  components: {
  },
  data () {
    return {
      value: '',
      customValue: '',
      variants: [
        // Default
        {
          mode: 'LIGHT',
          type: 'text',
          label: 'Default Input',
          disabled: false,
          required: true,
          inputSize: '',
          validationStatus: '',
          suffixIcon: '',
          prefixIcon: '',
          minlength: 15,
          maxlength: 30,
          clearable: false,
          floatingLabel: false
        },
        // Default Valid
        {
          mode: 'LIGHT',
          type: 'text',
          label: 'Default Valid',
          disabled: false,
          required: true,
          inputSize: '',
          validationStatus: 'valid',
          suffixIcon: '',
          prefixIcon: '',
          minlength: 15,
          maxlength: 30,
          clearable: false,
          floatingLabel: false
        },
        // Default InValid
        {
          mode: 'LIGHT',
          type: 'text',
          label: 'Default In Valid',
          disabled: false,
          required: true,
          inputSize: '',
          validationStatus: 'invalid',
          suffixIcon: '',
          prefixIcon: '',
          minlength: 15,
          maxlength: 30,
          clearable: false,
          floatingLabel: false
        },
        // Default Input with floating label
        {
          mode: 'LIGHT',
          type: 'text',
          label: 'Default Input with floating label',
          disabled: false,
          required: true,
          inputSize: '',
          validationStatus: '',
          suffixIcon: '',
          prefixIcon: '',
          minlength: 15,
          maxlength: 30,
          clearable: false,
          floatingLabel: true
        },
        // Default Textarea.
        {
          mode: 'LIGHT',
          type: 'textarea',
          label: 'Textarea',
          disabled: false,
          required: true,
          inputSize: '',
          validationStatus: '',
          suffixIcon: '',
          prefixIcon: '',
          minlength: 15,
          maxlength: 30,
          clearable: false,
          floatingLabel: false
        },
        // Default Disabled.
        {
          mode: 'LIGHT',
          type: 'text',
          label: 'Default Disabled',
          disabled: true,
          required: true,
          inputSize: '',
          validationStatus: '',
          suffixIcon: '',
          prefixIcon: '',
          minlength: 15,
          maxlength: 30,
          clearable: false,
          floatingLabel: false
        },
        // Default Password Input.
        {
          mode: 'LIGHT',
          type: 'password',
          label: 'Password',
          disabled: false,
          required: true,
          inputSize: '',
          validationStatus: '',
          suffixIcon: '',
          prefixIcon: '',
          minlength: 15,
          maxlength: 30,
          clearable: false,
          floatingLabel: false
        },
        // Default Number Input.
        {
          mode: 'LIGHT',
          type: 'number',
          label: 'Number',
          disabled: false,
          required: true,
          inputSize: '',
          validationStatus: '',
          suffixIcon: '',
          prefixIcon: '',
          minlength: 15,
          maxlength: 30,
          clearable: false,
          floatingLabel: false
        },
        // Default Prefix Icon.
        {
          mode: 'LIGHT',
          type: 'text',
          label: 'Prefix Icon',
          disabled: false,
          required: true,
          inputSize: '',
          validationStatus: '',
          suffixIcon: '',
          prefixIcon: 'calendar',
          minlength: 15,
          maxlength: 30,
          clearable: false,
          floatingLabel: false
        },
        // Default Suffix Icon.
        {
          mode: 'LIGHT',
          type: 'text',
          label: 'Suffix Icon',
          disabled: false,
          required: true,
          inputSize: '',
          validationStatus: '',
          suffixIcon: 'user',
          prefixIcon: '',
          minlength: 15,
          maxlength: 30,
          clearable: false,
          floatingLabel: false
        }
      ],
      inputData: [
        {
          value: 'react-js',
          label: 'React.js'
        },
        {
          value: 'vue',
          label: 'Vue.js'
        },
        {
          value: 'svelte',
          label: 'Svelte'
        },
        {
          value: 'angular',
          label: 'Angular'
        },
        {
          value: 'jquery',
          label: 'jQuery'
        },
        {
          value: 'prototype',
          label: 'Prototype'
        },
        {
          value: 'ember-js',
          label: 'Ember.js'
        },
        {
          value: 'angular-js',
          label: 'Angular.js'
        },
        {
          value: 'preact',
          label: 'Preact'
        },
        {
          value: 'polymer',
          label: 'Polymer'
        },
        {
          value: 'qooxdoo',
          label: 'qooxdoo'
        },
        {
          value: 'webix',
          label: 'Webix'
        },
        {
          value: 'knockout',
          label: 'Knockout'
        },
        {
          value: 'wakanda',
          label: 'Wakanda'
        },
        {
          value: 'backbone-js',
          label: 'Backbone.js'
        }
      ],
      inputCustomData: [
        {
          value: 'vue',
          label: 'Vue.js',
          link: 'https://github.com/vuejs/vue'
        },
        {
          value: 'element',
          label: 'Element',
          link: 'https://github.com/ElemeFE/element'
        },
        {
          value: 'cooking',
          label: 'Cooking',
          link: 'https://github.com/ElemeFE/cooking'
        },
        {
          value: 'mint-ui',
          label: 'Mint-Ui',
          link: 'https://github.com/ElemeFE/mint-ui'
        },
        {
          value: 'vuex',
          label: 'Vuex',
          link: 'https://github.com/vuejs/vuex'
        },
        {
          value: 'vue-router',
          label: 'Vue-router',
          link: 'https://github.com/vuejs/vue-router'
        },
        {
          value: 'babel',
          label: 'Babel',
          link: 'https://github.com/babel/babel'
        }
      ]
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    fetchSuggestions(term, cb) {
      clearTimeout(this.timeout)
      let results = term
        ? this.inputData.filter((item) => {
            return item.value.toLowerCase().indexOf(term.toLowerCase()) === 0
          })
        : this.inputData

      this.timeout = setTimeout(() => {
        clearTimeout(this.timeout)
        cb(results)
      }, 1000 * Math.random())
    },
    fetchCustomSuggestions(term, cb) {
      clearTimeout(this.timeout)
      let results = term
        ? this.inputCustomData.filter((item) => {
            return item.value.toLowerCase().indexOf(term.toLowerCase()) === 0
          })
        : this.inputCustomData

      this.timeout = setTimeout(() => {
        clearTimeout(this.timeout)
        cb(results)
      }, 1000 * Math.random())
    },
  },
}
</script>
<style lang="scss">
</style>
