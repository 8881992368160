
<GenAILayout>
  <div class="select-industry-container">
    <div class="select-industry-inner-container">
      <h1 class="select-industry-header">
        {{ $t('gen_ai.industry.header.text') }}
      </h1>
      <div class="select-industry-wrapper">
        <div class="select-industry-inner-wrapper">
          <div
            v-for="(industry, index) in industries"
            :key="index"
            class="select-industry-card-wrapper"
          >
            <cvt-industry-card
              :header-text="industry.industryName"
              :sub-text="industry.text"
              :icon="industry.icon"
              :is-active="cardIsSelected(industry.industryType)"
              :value="industry.industryType"
              @click="(industryType) => onClickIndustryCard(industryType)"
            >
            </cvt-industry-card>
          </div>
        </div>
      </div>
      <div
        v-if="!otherIndustryIsActive"
        class="industry-description-checkbox-wrap"
      >
        <input type="checkbox" @change="toggleCheckBox" />
        <label>{{
          $t('gen_ai.industry.other_industry.checkbox.label')
        }}</label>
      </div>
      <div v-else class="industry-description-input-wrap">
        <label>{{ $t('gen_ai.industry.other_industry.input.label') }}</label>
        <cvt-input
          :value="otherIndustry"
          type="text"
          :placeholder="
            $t('gen_ai.industry.other_industry.input.placeholder')
          "
          @input="(e) => onChangeOtherIndustry(e.target.value)"
        />
      </div>

      <hr />
      <div class="select-industry-btn-wrap">
        <cvt-button
          class="select-industry-btn"
          :text="$t('custom_domain.ui.unlink_dialog.buttons.continue')"
          shape="round"
          color="primary"
          icon="arrow-right"
          :disabled="disabled"
          :prepend="false"
          @click="gotTolandingPageGoal"
        ></cvt-button>
      </div>
    </div>
  </div>
</GenAILayout>
