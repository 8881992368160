<template>
  <el-button-group class="mr-2">
    <el-button size="mini" class="viewport_btn" label="Desktop" @click="enableDesktopMode">
      <i class="material-icons">desktop_mac</i>
    </el-button>
    <el-button size="mini" class="viewport_btn" label="Mobile" @click="enablePhoneMode">
      <i class="material-icons">phone_iphone</i>
    </el-button>
  </el-button-group>
</template>

<script>
import * as mutations from '../../../store/modules/mutations'
import { mapMutations } from 'vuex'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Viewport',
  computed: {
  },
  methods: {
    ...mapMutations({
      setViewMode: mutations.VIEW_MODE_SET
    }),
    enableDesktopMode () {
      this.setViewMode('desktop')
    },
    enablePhoneMode () {
      this.setViewMode('phone')
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/utils";

  .viewport_btn {
    i {
      font-size: 12px;
    }
  }
</style>

