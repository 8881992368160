import { VueEmotion, styled } from '@egoist/vue-emotion'
import * as _ from 'lodash'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import * as getters from '../../../builder/src/js/store/modules/getters'
import Vue from '../../lib/utils/vue'
import {
  FRESHWORKS,
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../constants'
import CvtButton from './Button'
import { CvtIcon } from './icon/icon'
import { generateUuid } from './util/generateUuid'
Vue.use(VueEmotion)

let Spacing, Radii, Border
let borderColor, color, backgroundColor, textFontSize, textLineHeight, elevation

const position = (props) => {
  if (props.position === 'top') {
    return '60px auto 1.875rem auto'
  } else if (props.position === 'left') {
    return '1.875rem auto 1.875rem 1.875rem'
  } else if (props.position === 'right') {
    return '1.875rem 1.875rem 1.875rem auto'
  } else if (props.position === 'bottom') {
    return '3.75rem auto 0px auto'
  } else if (props.position === 'center') {
    return '5rem auto 5rem auto'
  }
}

const getModalTransition = () => styled('div')`
  &.fade-enter-active,
  &.fade-leave-active {
    transition: opacity 0.5s;
  }
  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }
`

const getModal = () => styled('div')`
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2020;
  width: 100%;
  height: 100%;
  ${(props) => (props.scrollOnOverflow ? 'overflow-y: auto; overflow-x: hidden;' : 'overflow: hidden;')}
  outline: 0;
  transition: opacity 200ms ease-in-out;
  ${(props) => (props.display ? 'display: block;' : 'display: none;')}
  ${(props) =>
    !props.closeFromOutside && props.shake
      ? `& {
        animation: shake 100ms ease-in;
        animation-iteration-count: infinite;
    ` : ''}
`
const getModalDialog = () => styled('div')`
  margin: ${(props) => position(props)};
  ${(props) =>
    props.maxWidth ? `max-width: ${props.maxWidth};` : `max-width: 80vw;`}
  ${(props) => UTIL.getWidth(props)}
  height: ${(props) => (props.heightAuto ? 'auto' : '80vh')};
  position: relative;
  pointer-events: none;
  transition: transform 0.3s ease-out;
  transform: none;
`

const getModalContent = () => styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  ${(props) => {
    let contentBorderColor = borderColor(props, 200)
    let modalBoxShadow = elevation(props, 600)
    let bgColor = props.bgColor
      ? `background-color: ${props.bgColor};`
      : backgroundColor(props, 500)
    let borderRadius = `border-radius: ${props.borderRadius};`
    let textColor =
      props.themeing == 'dark'
        ? color(props, 100)
        : color({ themeing: 'dark' }, 500)

    return (
      bgColor + textColor + contentBorderColor + modalBoxShadow + borderRadius
    )
  }}
`

const getModalHeader = () => styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) =>
    props.orgName === FRESHWORKS ? Spacing.SPACING_32 : Spacing.SPACING_16}px;
  border-bottom: ${(props) =>
      props.orgName === FRESHWORKS ? 0 : Border.SIZE_1}px
    solid;
  ${borderColor({ themeing: 'dark' }, 200)}
  ${(props) => (props.orgName === FRESHWORKS ? `padding-bottom: 12px;` : ``)}
  ${(props) => (props.hideBorders ? 'border: 0;' : '')}
`

const getModalTitle = () => styled('h5')`
  margin-bottom: 0;
  ${textFontSize('xl')}
  ${textLineHeight('xl')}
  ${(props) =>
    props.themeing == 'dark'
      ? color(props, 100)
      : color({ themeing: 'dark' }, 500)}
`

const getModalBody = () => styled('div')`
  position: relative;
  padding: ${(props) =>
    props.orgName === FRESHWORKS ? Spacing.SPACING_32 : Spacing.SPACING_16}px;
  ${(props) => (props.orgName === FRESHWORKS ? `padding-top: 0px;` : ``)}
  overflow-y: auto;
  height: 100%;
  ${(props) =>
    props.maxHeight ? `max-height: ${props.maxHeight}` : `max-height: 100%;`}
`

const getModalFooter = () => styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: ${Spacing.SPACING_16}px;
  margin-top: auto;
  border-top: ${Border.SIZE_1}px solid;
  ${borderColor({ themeing: 'dark' }, 200)}
  ${(props) =>
    props.orgName === FRESHWORKS
      ? backgroundColor({ themeing: 'smoke' }, 50)
      : ``}
  ${(props) => `border-radius: ${props.borderRadius};`}
  ${(props) => (props.hideBorders ? 'border: 0;' : '')}

  > * {
    margin: 0 ${Spacing.SPACING_8}px;
  }
`

const getModalBackDrop = () => styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2010;
  width: 100vw;
  height: 100vh;
  ${backgroundColor({ themeing: 'dark' }, 800)}

  &.show {
    opacity: 0.7;
  }
  &.fade {
    opactiy: 0;
  }
`

const getClearButton = () => styled(CvtButton)`
  margin: 0;
`

const UTIL = {
  getWidth(props) {
    switch (props.size) {
      case 'sm':
        return `width: 30em;`
      case 'lg':
        return `width: 50em;`
      case 'xl':
        return `width: 60em;`
      default:
        return `width: 100%;`
    }
  },
}

export default {
  name: 'CvtDialog',
  components: {
    CvtIcon,
    CvtButton,
  },
  computed: {
    ...mapGetters('globalTheme', {
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      elevation: getters.GLOBAL_STYLE_ELEVATION,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      orgName: ({ globalTheme }) =>
        globalTheme?.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
    }),
  },
  props: {
    size: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'center',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    heightAuto: {
      type: Boolean,
      default: false,
    },
    scrollOnOverflow: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'LIGHT',
    },
    color: {
      type: String,
      default: 'light',
    },
    show: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    bgColor: {
      type: String,
      default: '',
    },
    borderRadius: {
      type: String,
      default: '8px',
    },
    hideBorders: {
      type: Boolean,
      default: false,
    },
    closeFromOutside: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialogId: `dialog_${generateUuid()}`,
      shake: false,
    }
  },
  methods: {
    loadDialog() {},
    onAction(action = 'close', e) {
      this.$emit(action, e)
    },
    onEscKey(e) {
      if (e.keyCode === 27) {
        this.onAction('close', e)
      }
    },
    handleContainerClick(e) {
      !this.closeFromOutside && this.shakeContainer()
      if (e.target == this.$refs.dialog && this.closeFromOutside) {
        this.onAction('close', e)
      }
    },
    shakeContainer() {
      this.shake = true
      this.stopShake()
    },
    stopShake: _.debounce(
      function () {
        this.shake = false
      },
      500,
      {
        trailing: true,
      },
    ),
    handleStopPropagation(e) {
      e.stopImmediatePropagation()
      e.stopPropagation()
    },
  },

  mounted() {
    this.loadDialog()
  },
  updated() {
    if (this.show) {
      window.addEventListener('keydown', this.onEscKey)
    } else {
      window.removeEventListener('keydown', this.onEscKey)
    }
  },
  watch: {
    $props: {
      handler() {
        this.loadDialog()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border

    borderColor = this.borderColor
    color = this.textColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    elevation = this.elevation
  },
  render: function (h) {
    const ModalTransition = getModalTransition()
    const Modal = getModal()
    const ModalDialog = getModalDialog()
    const ModalContent = getModalContent()
    const ModalHeader = getModalHeader()
    const ModalTitle = getModalTitle()
    const ModalBody = getModalBody()
    const ModalFooter = getModalFooter()
    const ModalBackDrop = getModalBackDrop()
    const ClearButton = getClearButton()

    return (
      this.show && (
        <ModalTransition>
          <Modal
            id={this.dialogId}
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            display={this.show}
            ref="dialog"
            onClick={this.handleContainerClick}
            shake={this.shake}
            scrollOnOverflow={this.scrollOnOverflow}
          >
            <ModalDialog
              role="document"
              size={this.size}
              position={
                this.orgName === LEFT_SIDEBAR_FRESHWORKS ? 'top' : this.position
              }
              maxWidth={this.width}
              heightAuto={this.heightAuto}
              onClick={this.handleStopPropagation}
            >
              <ModalContent
                maxHeight={this.height}
                themeing={this.color}
                mode={this.mode}
                bgColor={this.bgColor}
                borderRadius={this.borderRadius}
              >
                {this.showHeader && (
                  <ModalHeader
                    id="modal-header"
                    orgName={this.orgName}
                    hideBorders={this.hideBorders}
                  >
                    <ModalTitle themeing={this.color}>
                      {this.$slots.title}
                    </ModalTitle>
                    {this.showClose && (
                      <ClearButton
                        shape="pill"
                        size="sm"
                        icon="times"
                        text=""
                        actionIcon={true}
                        colorOfActionIcon={''}
                        modeOfActionIcon={
                          this.color == 'light' ? 'dark' : 'light'
                        }
                        onClick={(e) => this.onAction('close', e)}
                        hover
                      ></ClearButton>
                    )}
                  </ModalHeader>
                )}
                <ModalBody
                  id="modal-body"
                  maxHeight={this.height}
                  orgName={this.orgName}
                >
                  {this.$slots.default}
                </ModalBody>
                {this.showFooter && (
                  <ModalFooter
                    id="modal-footer"
                    orgName={this.orgName}
                    borderRadius={this.borderRadius}
                    hideBorders={this.hideBorders}
                  >
                    {this.$slots.modalFooter}
                  </ModalFooter>
                )}
              </ModalContent>
            </ModalDialog>
          </Modal>
          <ModalBackDrop class="fade show"></ModalBackDrop>
        </ModalTransition>
      )
    )
  },
}
