import idMixin from '../../../lib/mixins/id'
import ItemBaseMixin from './mixins/item-base'
import CvtItem from './CvtItem'

import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'

import Vue from '../../../lib/utils/vue'
Vue.use(VueEmotion)

const List = styled('ul')`
  text-align: left;
  margin-block-start: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  margin-block-end: 0;
  padding-block-end: 0;
  list-style-type: none;
`

export default {
  name: 'CvtList',
  components: {
    CvtItem
  },
  mixins: [ItemBaseMixin],
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    _removeHidden: false,
    valueProgress: 75
  }),
  methods: {
    onRemove(item) {
      this.$emit('remove', item)
    }
  },
  render: function (h) {
    return (
      <List>
        {this.items.length === 0 && this.$slots.noItemAvailable}
        {this.items.map((item) => {
          return (
            <cvt-item
              item={item}
              removeEnabled={this.removeEnabled}
              thumbnailsEnabled={this.thumbnailsEnabled}
              fallbackThumbnail={this.fallbackThumbnail}
              iconsEnabled={this.iconsEnabled}
              fallbackIcon={this.fallbackIcon}
              iconProp={this.iconProp}
              thumbnailProp={this.thumbnailProp}
              descriptionProp={this.descriptionProp}
              titleProp={this.titleProp}
              endIcon={this.endIcon}
              showSeparator={this.showSeparator}
              isCard={this.isCard}
              onRemove={(e) => this.onRemove(e)}
            ></cvt-item>
          )
        })}
      </List>
    )
  }
}
