import Vue from '../../lib/utils/vue';
import { styled } from '@egoist/vue-emotion';
import { VueEmotion } from '@egoist/vue-emotion';
import CvtButton from '../molecules/Button';
import CvtInput from '../molecules/Input';
import CvtSwitch from '../molecules/Switch';
import CvtRadioGroup from '../molecules/radio-group/RadioGroup';
import CvtRadioButton from '../molecules/radio-group/RadioButton';
import { CvtIcon } from '../molecules/icon/icon';
import * as getters from '../../../builder/src/js/store/modules/getters';
import { mapGetters, mapState } from '../../../builder/node_modules/vuex';
import { LIONDESK, LEFT_SIDEBAR_STANDARD, LEFT_SIDEBAR_FRESHWORKS } from '../constants'
import VueI18n from 'vue-i18n';
import { Colors } from '../../style/global';
Vue.use(VueEmotion);
Vue.use(VueI18n);


let textFontSize, textFontWeight, backgroundColor, color, textColor;
let FontFamily, Spacing, orgName;

const getSearchBar = () => styled('div')`
  padding: 0 ${Spacing.SPACING_32}px;
  display: flex;
  height: 72px;
  justify-content: space-between;
  font-family: ${FontFamily.DEFAULT};
  background-color: #fff;
  border-bottom: 1px solid #F0F2F6;
`;


const getButton = () => styled(CvtButton)`
  border-radius: 4px !important;
  > span {
    font-size: 12px;
    font-weight: 400 !important;
  }
`

const getPublishedSwitchWrap = () => styled('div')`
  display: flex;
  align-items: center;
`

const getPublishedText = () => styled('span')`
  font-size: 12px;
  padding-left: 12px;
  color: ${Colors.BASIC[500]};
  font-weight: 400;
`

const getSortByName = () => styled('span')`
  ${textFontSize('sm')};
  margin: ${Spacing.SPACING_4}px;
  ${textFontWeight('LIGHT')}
  font-family: ${FontFamily.DEFAULT};
  ${(props) => {
    return props.mode === 'DARK'
      ? textColor({ themeing: 'light' }, 100)
      : textColor({ themeing: 'dark' }, 700);
  }}
`;

const getInput = () => styled('div')`
  width: 70%;
`;

const getSortByDiv = () => styled('div')`
  display: flex;
  & > * {
    margin: ${Spacing.SPACING_4}px;
  }
`;
const getWideFlexDiv = () => styled('div')`
  display: flex;
  flex: 2;
  align-items: center;
  & > * {
    margin: ${Spacing.SPACING_4}px;
  }
`;
const getCvtSwitch = () => styled(CvtSwitch)`
  margin: ${Spacing.SPACING_4}px;
`;
const getRightDiv = () => styled('div')`
  margin:auto;
`

export const CvtPopupSearchFilter = {
  name: 'CvtPopupSearchFilter',
  data () {
    return {
      inputQuery: '',
      isCreatedSelected: false,
      isLastUpdatedSelected: false,
      isPublished: false,
    };
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      color: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textColor: getters.GLOBAL_STYLE_COLOR,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      orgName: ({ globalTheme }) => globalTheme?.OrgName !== undefined ? globalTheme.OrgName : LEFT_SIDEBAR_STANDARD,
      ypSearchDateUpdatedBtnConfig: ({ globalButtonConfig }) => globalButtonConfig?.locations.sdaPopupYourPopups.locations.searchDateUpdated.props,
      ypSearchDateCreatedBtnConfig: ({ globalButtonConfig }) => globalButtonConfig?.locations.sdaPopupYourPopups.locations.searchDateCreated.props
    }),
  },
  created () {
    (Spacing = this.Spacing),
      (textFontSize = this.textFontSize),
      (FontFamily = this.FontFamily),
      (backgroundColor = this.backgroundColor),
      (textFontWeight = this.textFontWeight),
      (color = this.color),
      (textColor = this.textColor),
      (orgName = this.orgName)
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    initialTemplatesViewFormatVal: {
      type: String,
      required: false,
      default: 'GRID',
    },
    displayTemplateViewFormatBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultSearch: {
      type: String,
      required: false,
      default: ''
    },
    searchDone: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    radioBtnClicked(value) {
      this.$emit('templatesViewToggled', value); // GRID|LIST
    }
  },
  render: function (h) {
    const SearchBar = getSearchBar();
    const SortByWrapDiv = getSortByDiv();
    const WideFlexDiv = getWideFlexDiv();
    const InputBar = getInput();
    const PublishedSwitch = getCvtSwitch();
    const RightDiv = getRightDiv();

    const Button = getButton();
    const PublishedText = getPublishedText();
    const PublishedSwitchWrap = getPublishedSwitchWrap();

    return (
      <SearchBar mode={this.mode}>
        <WideFlexDiv>
          <InputBar>
            <CvtInput
              value={ this.defaultSearch }
              onClear={() => {
                this.inputQuery = '';
                this.searchDone('');
              }}
              onInput={(e) => {
                this.inputQuery = (e.target.value.trim() || '');
                if (!this.inputQuery) {
                  this.searchDone('');
                }
              }}
              onEnter={() => this.searchDone(this.inputQuery)}
              size='small'
              placeholder={this.$t('marketplace.template.popup.search.label')}
              noLabel
              floatingLabel={true}
              suffixIcon="search"
              mode={this.mode}
              color='black'
            ></CvtInput>
          </InputBar>
        </WideFlexDiv>

        <RightDiv>
          <SortByWrapDiv>
            <Button
              mode={this.mode}
              size='sm'
              text={this.$t('marketplace.template.search.date.updated')}
              color={this.ypSearchDateUpdatedBtnConfig.color}
              specialStyle={this.ypSearchDateUpdatedBtnConfig.specialStyle}
              outlined={this.ypSearchDateUpdatedBtnConfig.outlined}
              onClick={() => {
                this.isLastUpdatedSelected = !this.isLastUpdatedSelected;
                if (this.isCreatedSelected) this.isCreatedSelected = false;
                this.$emit(
                  'sortParameter',
                  'lastUpdated',
                  this.isLastUpdatedSelected
                );
              }}
            ></Button>
            <Button
              mode={this.mode}
              size='sm'
              text={this.$t('marketplace.template.search.date.created')}
              color={this.ypSearchDateCreatedBtnConfig.color}
              specialStyle={this.ypSearchDateCreatedBtnConfig.specialStyle}
              outlined={this.ypSearchDateCreatedBtnConfig.outlined}
              onClick={() => {
                this.isCreatedSelected = !this.isCreatedSelected;
                if (this.isLastUpdatedSelected)
                  this.isLastUpdatedSelected = false;
                this.$emit('sortParameter', 'created', this.isCreatedSelected);
              }}
            ></Button>
          </SortByWrapDiv>
        </RightDiv>

        <PublishedSwitchWrap>
          <PublishedText>Published</PublishedText>
          <PublishedSwitch
            size='md'
            color='default'
            type='circular'
            descriptionType='text'
            activeText=''
            inactiveText=''
            disabled={false}
            initialState={false}
            leftSideLabel={true}
            showBigSwitch={false}
            mode={this.mode}
            showInside={true}
            onSwitchToggle={() => {
              this.isPublished = !this.isPublished;
              this.$emit('sortParameter', 'published', this.isPublished);
            }}
          ></PublishedSwitch>
        </PublishedSwitchWrap>
      </SearchBar>
    );
  },
};