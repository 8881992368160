import { mapGetters } from 'vuex'
import * as getters from '../../../store/modules/getters'
import _ from 'lodash'
import { cvtRouteNodes } from '@/js/utils/routes'
import { Organization } from '../../../store/services/api'

const organizationAPI = new Organization()

export default {
  components: {},
  props: {},
  data() {
    return {
      featureConfig: {
        HELP_LINK: '',
        BUILDER_DEFAULT_VIDEO: '',
        MAINTENANCE_ENABLED: false,
        FUNNEL_ENABLED: false,
        POPUP_ENABLED: false,
        MAINTENANCE_MESSAGE: '',
      },
      loading: false,
      disableBtn: false,
      isVideoUrlValid: false,
      isHelpUrlValid: false,
    }
  },
  async created() {
    const featureConfigKeys = Object.keys(this.featureConfig)

    this.featureConfig = this.filterConfigsByKeys(
      featureConfigKeys,
      this.config,
    )
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    builderDefaultVideo: {
      get(): void {
        return this.featureConfig.BUILDER_DEFAULT_VIDEO
      },
      set: _.debounce(function (value) {
        this.featureConfig.BUILDER_DEFAULT_VIDEO = value

        if (this.isVideoUrlValid) {
          const payload = { key: 'BUILDER_DEFAULT_VIDEO', value: value }
          this.updateConfig(payload)
        }
      }, 500),
    },
    helpLink: {
      get(): void {
        return this.featureConfig.HELP_LINK
      },
      set: _.debounce(function (value) {
        this.featureConfig.HELP_LINK = value

        if (this.isHelpUrlValid) {
          const payload = { key: 'HELP_LINK', value: value }
          this.updateConfig(payload)
        }
      }, 500),
    },
    maintenanceMessage: {
      get(): void {
        return this.featureConfig.MAINTENANCE_MESSAGE
      },
      set: _.debounce(function ({ target }) {
        this.featureConfig.MAINTENANCE_MESSAGE = target.value

        const payload = { key: 'MAINTENANCE_MESSAGE', value: target.value }
        this.updateConfig(payload)
      }, 500),
    },
  },
  methods: {
    async updateConfig(config: { key: String; value: String }) {
      try {
        const payload = {
          key: config.key,
          table: 'organizationConfigs',
          value: config.value,
        }
        await organizationAPI.upsertFeatureConfig(payload)
      } catch (err) {
        this.disableBtn = true
        console.log(err)
      }
    },
    helpUrlIsValid(status: boolean) {
      this.isHelpUrlValid = status
    },
    videoUrlIsValid(status: boolean) {
      this.isVideoUrlValid = status
    },
    onSwitchToggle(property) {
      this.featureConfig[property] = !this.featureConfig[property]

      const payload = { key: property, value: this.featureConfig[property] }

      this.updateConfig(payload)
    },
    onClickFeatureConfig() {
      this.$router.push({
        name: cvtRouteNodes.enterpriseDashboardBranding,
      })
    },
    filterConfigsByKeys(keys: String[], configs: object) {
      let filteredConfig = {}

      if (keys.length) {
        filteredConfig = Object.keys(configs)
          .filter((key) => keys.includes(key))
          .reduce((obj, key) => {
            obj[key] = configs[key]
            return obj
          }, {})
      }
      return filteredConfig
    },
  },
}
