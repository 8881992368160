
<div id="page-engine" data-vnode-id="page-engine" :class="pageEngineClasses">
  <div class="wizard-container">
    <div class="wizard-steps">
      <div
        :class="[
          'wizard-step',
          {
            active: true,
            completed: false,
          },
        ]"
      >
        {{
          currentSection?.title
            ? currentSection.title
            : `Step ${currentSectionIndex + 1}`
        }}
      </div>
    </div>
    <div class="wizard-content-wrapper">
      <div class="wizard-content">
        <wizard-item
          v-if="currentSection"
          :obj="currentSection"
          :wizard-index="currentSectionIndex"
          :is-reload="isReload"
          @genAIStatusChanged="
            (status) =>
              currentSectionStatusUpdate(
                currentSection.title,
                'genAIStatus',
                status,
              )
          "
        ></wizard-item>
      </div>
    </div>
    <div class="wizard-navigation">
      <button :disabled="!canPrevious" @click="previousSection">
        Previous
      </button>
      <div class="wizard-navigation__next-buttons">
        <button
          v-if="!isLastSection"
          :disabled="!(canNext && isCurrentSectionValid)"
          @click="nextSection"
        >
          Next
        </button>
        <button v-else :disabled="!isCurrentSectionValid" @click="onFinish">
          Finish, Let's go to builder!
        </button>
      </div>
    </div>
  </div>
</div>
