import * as actions from '../../../store/modules/actions'
import OrgHeader from '../layouts/Header/Header.vue'
import { Organization } from '../../../store/services/api'
import SignupFooter from '../signupFooter/indexSignupFooter.vue'
import * as getters from '../../../store/modules/getters'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import { routePaths } from '../../../../../src/js/utils/routes'

const organization = new Organization()

export default {
  components: {
    OrgHeader,
    SignupFooter,
  },
  props: {},
  data() {
    return {
      codes: [
        {
          key: '1',
          value: '',
        },
        {
          key: '2',
          value: '',
        },
        {
          key: '3',
          value: '',
        },
        {
          key: '4',
          value: '',
        },
        {
          key: '5',
          value: '',
        },
        {
          key: '6',
          value: '',
        },
      ],
      loading: false,
      userEmail: '',
      verificationCode: '',
      codeLimit: 6,
    }
  },

  created() {
    if (this.signinUserEmail) {
      this.userEmail = this.signinUserEmail
    } else {
      this.$router.push({
        path: routePaths.signUp,
      })
    }
  },
  computed: {
    ...mapGetters({
      signinUserEmail: getters.SIGNIN_USER_EMAIL,
    }),
  },
  methods: {
    ...mapActions({
      verifCode: actions.VERIFY_MFA_CODE,
      sendCode: actions.SEND_MFA_CODE,
    }),
    processInput(codeValue, slot) {
      const newCode = [...this.codes]
      newCode[slot].value = codeValue
      this.codes = newCode

      if (codeValue && slot !== this.codes.length - 1) {
        this.$refs[`codeInputRef${slot + 1}`][0].focus()
      }
      const code = this.codes.map(({ value }) => value).join('')
      this.verificationCode = code

      if (code.length === this.codeLimit) {
        this.verifyCode(code)
      }
    },
    onKeyDown(e, slot) {
      if (e.keyCode === 8 && slot !== 0 && !this.codes[slot].value) {
        this.$refs[`codeInputRef${slot - 1}`][0].focus()
      }

      if ((e.metaKey || e.ctrlKey) && e.keyCode === 86) {
        this.pasteClipBoardText(slot)
      }
    },
    pasteClipBoardText(slot) {
      let formattedClipboardText = ''

      navigator.clipboard
        .readText()
        .then((clipboardText) => {
          formattedClipboardText = clipboardText

          if (clipboardText.length + slot >= this.codeLimit) {
            formattedClipboardText = clipboardText.substring(
              0,
              this.codeLimit - slot,
            )
          }

          if (formattedClipboardText) {
            let splittedCode = formattedClipboardText.split('')
            splittedCode.map((code, id) => {
              this.processInput(code, slot + id)
            })
          }
        })
        .catch((error) => {
          console.log('Failed to read clipboard text:', error)
        })
    },
    async verifyCode(code) {
      try {
        this.loading = true
        const payload = {
          to: this.userEmail,
          code: code,
          xhr: true,
        }

        await this.verifCode(payload).then((res) => {
          this.$router.push({
            path: res.data.redirectURL,
          })
        })
      } catch (err) {
        this.$message.error(err.response.data.errors[0].message)
      } finally {
        this.loading = false
      }
    },
    async resendCode() {
      const payload = {
        to: this.userEmail,
        channel: 'email',
        redirectURL: routePaths.onboardingInformation,
      }

      await this.sendCode(payload)
        .then((response) => {
          this.$message.success('Code Resent Successfully!!')
        })
        .catch((err) => {
          this.$message.error(err.response.data.errors[0].message)
        })
    },
  },
}
