<template>
  <div v-if="!AltPopupManager" class="upload-manager">
    <cvt-alert
      v-show="alertMessage.length"
      ref="uploadAlert"
      color="danger"
      icon="exclamation-triangle"
    >
      {{ alertMessage }}
    </cvt-alert>
    <div v-if="imageOnly && sizeLimitToTop" class="upload-file-note">
      {{ $t(`element.upload.image.max_size_${sizeLimit}_message`)}}
    </div>
    <div class="upload-queue">
      <div class="upload-queue__item">
        <div class="row">
          <div
            v-for="upload in queue.stack"
            :key="upload.file.name"
            class="col-auto mx-auto"
          >
            <div class="row">
              <div
                class="col-auto d-flex justify-content-center align-items-center"
              >
                <h6 v-text="upload.file.name"></h6>
              </div>
            </div>
            <div
              class="col-auto d-flex justify-content-center align-items-center"
            >
              <el-progress
                type="circle"
                :percentage="+upload.progress.toFixed(2)"
                :status="upload.status"
              ></el-progress>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!uploadInProgress"
      class="upload-dropzone"
      :class="dragEnabledClass"
      @dragenter.stop.prevent="dragenter"
      @dragover.stop.prevent="dragover"
      @dragleave.stop="dragleave"
      @drop.stop.prevent="dropped"
    >
      <div class="upload-dropzone__dialog">
        <i class="material-icons icon-xl">cloud_upload</i>
        <h4 v-text="dndMsg"></h4>
        <div class="upload-dropzone__dialog__divider my-3">
          - {{ $t('element.upload.image.label.or') }} -
        </div>
        <div
          class="upload-dropzone__dialog__actions d-flex justify-content-around w-75"
        >
          <cvt-button
            v-if="!hideGalleryButton"
            color="light"
            text="Pick from image gallery"
            @click.stop="openImageSearch"
          />
          <cvt-button
            :text="btnLabel"
            :special-style="isSpecialStyle"
            @click.stop="openFileDialog"
          />
          <input
            type="file"
            :accept="acceptUploadTypes"
            name="file"
            class="el-upload__input"
            :multiple="multi"
            @change="filesSelected"
          />
        </div>
      </div>
    </div>
    <div v-if="imageOnly && !sizeLimitToTop" class="upload-file-note">
      {{ $t(`element.upload.image.max_size_${sizeLimit}_message`)}}
    </div>
  </div>
  <div v-else class="alt-upload-manager">
    <cvt-alert
      v-show="alertMessage.length"
      ref="uploadAlert"
      color="danger"
      icon="exclamation-triangle"
    >
      {{ alertMessage }}
    </cvt-alert>
    <div v-if="imageOnly" class="upload-file-note">
      {{ $t(`element.upload.image.max_size_${sizeLimit}_message`)}}
    </div>
    <div class="upload-queue">
      <div class="upload-queue__item">
        <div class="row">
          <div
            v-for="upload in queue.stack"
            :key="upload"
            class="col-auto mx-auto"
          >
            <div class="row">
              <div
                class="col-auto d-flex justify-content-center align-items-center"
              >
                <h6 v-text="upload.file.name"></h6>
              </div>
            </div>
            <div
              class="col-auto d-flex justify-content-center align-items-center"
            >
              <el-progress
                type="circle"
                :percentage="+upload.progress.toFixed(2)"
                :status="upload.status"
              ></el-progress>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!uploadInProgress"
      class="upload-dropzone"
      :class="dragEnabledClass"
      @dragenter.stop.prevent="dragenter"
      @dragover.stop.prevent="dragover"
      @dragleave.stop="dragleave"
      @drop.stop.prevent="dropped"
    >
      <div class="upload-dropzone__dialog">
        <i class="material-icons icon-xl">cloud_upload</i>
        <h5 v-text="dndMsg"></h5>
        <div class="upload-dropzone__dialog__divider my-3">
          - {{ $t('element.upload.image.label.or') }} -
        </div>
        <div
          class="upload-dropzone__dialog__actions d-flex justify-content-between w-75"
        ></div>
        <cvt-button
          color="light"
          :text="btnLabel"
          @click.stop="openFileDialog"
        />
        <cvt-button
          v-if="!hideGalleryButton"
          class="my-3"
          color="link"
          text="Select from image library"
          @click.stop="openImageSearch"
        />
        <input
          type="file"
          :accept="acceptUploadTypes"
          name="file"
          class="el-upload__input"
          :multiple="multi"
          @change="filesSelected"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { storage } from '@/js/store/services/firebase.service'
import clientPath from 'path-browserify'
import uuid from 'uuid/v4'
import { mapGetters, mapMutations, mapState } from 'vuex'
import {
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../storybook/components/constants'
import * as getters from '../../store/modules/getters'
class Upload {
  constructor({ file, ref }) {
    this.file = file
    this.ref = ref
    this.progress = 0
    this.status = ''
    this.task = null
  }

  put() {
    return this.ref.put(this.file)
  }

  start() {
    this.task = this.put()

    let unsubscribe = this.task.on(storage.TaskEvent.STATE_CHANGED, (snap) => {
      let percent = (snap.bytesTransferred / snap.totalBytes) * 100
      if (percent > 0) {
        this.progress = parseInt(percent)
      }
    })

    this.task.then((snap) => {
      this.progress = 100
      this.status = 'success'
      unsubscribe()

      // let meta = snap.metadata
      // return database().ref(`assets/${snap.ref.parent.fullPath}`).push({
      //   name: meta.name,
      //   bucket: meta.bucket,
      //   fullPath: meta.fullPath,
      //   generation: meta.generation,
      //   metageneration: meta.metageneration,
      //   size: meta.size,
      //   contentType: meta.contentType,
      //   type: meta.type,
      //   md5Hash: meta.md5Hash,
      //   timeCreated: meta.timeCreated,
      //   updated: meta.updated
      // })
    })

    this.task.catch(() => {
      this.progress = 100
      this.status = 'exception'
    })

    return this
  }

  pause() {
    this.task.pause()
    return this
  }

  resume() {
    this.task.resume()
    return this
  }

  cancel() {
    this.task.cancel()
    return this
  }
}

class UploadQueue {
  constructor(uploads = []) {
    this.stack = uploads
  }

  push(upload) {
    this.stack.push(upload)
  }

  start() {
    this.stack = this.stack.map((f) => f.start())
    return Promise.all(this.stack.map((f) => f.task))
  }

  pause() {
    this.stack = this.stack.map((f) => f.pause())
  }

  cancel() {
    this.stack = this.stack.map((f) => f.cancel())
  }

  drain() {
    this.cancel()
  }
}

export default {
  props: {
    label: {
      type: String,
      default: 'Drag files here',
    },
    btnLabel: {
      type: String,
      default: 'Select a file from your computer',
    },
    multi: {
      type: Boolean,
      default: false,
    },
    bucket: {
      type: String,
      default: 'cdn.convrrt.com',
    },
    private: {
      type: Boolean,
      default: false,
    },
    hideGalleryButton: {
      type: Boolean,
    },
    imageOnly: {
      type: Boolean,
      default: false,
    },
    // If the value is more than zero, limit will be applied in mb.
    sizeLimit: {
      type: Number,
      default: 0,
    },
    sizeLimitToTop: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      queue: new UploadQueue(),
      dragging: false,
      alertMessage: '',
    }
  },
  computed: {
    ...mapGetters({
      user: getters.AUTH_GET_USER,
    }),
    ...mapState('globalTheme', {
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
      Colors: ({ globalTheme }) =>
        globalTheme.Colors !== undefined
          ? globalTheme.Colors
          : LEFT_SIDEBAR_STANDARD,
      AltPopupManager: ({ globalTheme }) =>
        globalTheme.AltPopupManager !== undefined
          ? globalTheme.AltPopupManager
          : false,
    }),
    prefix() {
      return this.private ? 'users' : 'apps'
    },
    userKey() {
      return this.private
        ? this.user.id
        : [this.user.orgID, this.user.projectID].join('/')
    },
    storageRef() {
      return storage()
        .refFromURL(`gs://${this.bucket}`)
        .child(`/${this.prefix}/${this.userKey}`)
    },
    dndMsg() {
      return this.dragging ? 'Drop file(s) here' : this.label
    },
    dragEnabledClass() {
      return {
        'upload-dropzone--active': this.dragging,
      }
    },
    uploadInProgress() {
      return this.queue.stack.some((u) => u.status === '')
    },
    isSpecialStyle() {
      return this.OrgName === LEFT_SIDEBAR_FRESHWORKS ? 'dark' : ''
    },
    acceptUploadTypes() {
      let allowedType = ''

      if (this.imageOnly) {
        allowedType = 'image/x-png,image/gif,image/jpeg,image/svg+xml'
      }

      return allowedType
    },
  },
  methods: {
    ...mapMutations({}),
    openImageSearch() {
      this.$emit('gallery-btn-clicked')
    },
    openFileDialog() {
      this.$el.querySelector('.el-upload__input').click()
    },
    reset() {
      if (this.queue) {
        this.queue.drain()
      }

      this.queue = new UploadQueue()
    },
    dragenter(e) {
      this.dragging = true
      console.debug('dragenter', e)
      return true
    },
    dragover(e) {
      console.debug('dragover', e)
      return false
    },
    dragleave(e) {
      this.dragging = false
      console.debug('dragleave', e)
    },
    dropped(e) {
      this.dragging = false
      this.filesSelected({ target: { files: e.dataTransfer.files } })
    },
    genUUIDNAME(f) {
      return `${uuid()}${clientPath.extname(f.name)}`
    },

    //Validate file type and size
    validateFiles(files) {
      const allowedImages = ['jpeg', 'jpg', 'png', 'gif', 'svg+xml']
      let result = { success: true, message: '' }

      for (let i = 0; i < files.length; i++) {
        let file = files.item(i)

        //size validation
        if (this.sizeLimit > 0) {
          let sizeInMB = (file.size / (1024 * 1024)).toFixed(1)

          if (sizeInMB > this.sizeLimit) {
            let validationMsg = `You are trying to upload a file of size ${sizeInMB}mb. `
            validationMsg += `File size more than ${this.sizeLimit}mb is not allowed.`

            result = { success: false, message: validationMsg }
            break
          }
        }
        //Type validation
        if (this.imageOnly) {
          let fType = file.type.split('/')
          console.debug(fType)
          if (!allowedImages.includes(fType[1])) {
            let validationMsg = `File type ${file.type} is not allowed. Please try with a different image file.`

            result = { success: false, message: validationMsg }
            break
          }
        }
      }

      return result
    },

    filesSelected(e) {
      this.reset()
      this.alertMessage = ''

      let files = e.target.files
      let valid = this.validateFiles(files)

      if (!valid.success) {
        this.alertMessage = valid.message

        let modalBody = document.getElementById('modal-body')
        modalBody.scrollTop = 0
        return false
      }

      for (let i = 0; i < files.length; i++) {
        let file = files.item(i)

        this.queue.push(
          new Upload({
            file,
            ref: this.storageRef.child(this.genUUIDNAME(file)),
          }),
        )
      }

      this.queue.start().then((results) => {
        this.reset()
        this.$emit(
          'complete',
          results.map((t) => `https://${t.ref.bucket}/${t.ref.fullPath}`),
        )
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

$upload-dropzone-bg-color: $light-med-gray;
$upload-dropzone-border-color: darken($upload-dropzone-bg-color, 10%);

$upload-dropzone-bg-color-active: $inf-light-blue;
$upload-dropzone-border-color-active: darken(
  $upload-dropzone-bg-color-active,
  10%
);

.upload-dropzone {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  background: $upload-dropzone-bg-color;
  border: 4px $upload-dropzone-border-color dashed;
  transition: all 250ms ease;

  &--active {
    background: $upload-dropzone-bg-color-active;
    border: 4px $upload-dropzone-border-color-active dashed;
    color: $white;
  }
}

.upload-dropzone__dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__divider {
    font-size: 14px;
    padding: 0 0 16px;
    font-weight: bold;
  }

  i.material-icons.icon-xl {
    font-size: 90px;
  }
}

.alt-upload-manager {
  .upload-dropzone {
    width: 100%;
    height: 275px;
    border-radius: 4px;
    background: #e5f2fd;
    border: 1px dashed #bbdcfe;
    transition: all 250ms ease;

    &--active {
      background: $upload-dropzone-bg-color-active;
      border: 4px $upload-dropzone-border-color-active dashed;
      color: $white;
    }
  }
}

.upload-file-note {
  font-size: 14px;
  margin: 5px;
}
</style>
