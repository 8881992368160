import {BookShape} from './shapes/book'
import {ArrowShape} from './shapes/arrow'
import {CurveAltShape} from './shapes/curve-alt'
import {CurveShape} from './shapes/curves'
import {WaveShape} from './shapes/waves'
import {WavesOpacity} from './shapes/waves-alt'
import {SplitShape} from './shapes/split'
import {HorizontalRectangleShape, VerticalRectangleShape} from './shapes/rectangle'
import {TriangleShape} from './shapes/triangle'
import {TriangleAltShape} from './shapes/triangle-alt'
import {TiltShape} from './shapes/tilt'


export const ConvrrtBackgroundShapes = [
    BookShape,
    ArrowShape,
    CurveShape,
    CurveAltShape,
    WavesOpacity,
    WaveShape,
    SplitShape,
    TriangleAltShape,
    TriangleShape,
    TiltShape,
    HorizontalRectangleShape,
    VerticalRectangleShape
]