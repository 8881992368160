<template>
  <div class="">
    <div class="row mb-1">
      <div class="col">
        <strong
          >{{ $t('launch.tracking.head') }} <code>&lt;head&gt;</code></strong
        >
        <ace
          ref="trackHead"
          lang="html"
          :code-snippet-html="tracking.head"
          @change="trackHead"
        />
      </div>
    </div>
    <div class="row mb-1">
      <div class="col">
        <strong>{{ $t('launch.tracking.opening_body') }}</strong>
        <ace
          ref="trackAfterOpeningBody"
          lang="html"
          :code-snippet-html="tracking.afterOpeningBody"
          @change="trackAfterOpeningBody"
        />
      </div>
    </div>
    <div class="row mb-1">
      <div class="col">
        <strong>{{ $t('launch.tracking.closing_body') }}</strong>
        <ace
          ref="trackBeforeClosingBody"
          lang="html"
          :code-snippet-html="tracking.beforeClosingBody"
          @change="trackBeforeClosingBody"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Ace from '@/js/components/common/Ace.vue'
import * as _ from 'lodash'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PageTracking',
  components: {
    Ace,
  },
  computed: {
    ...mapState('pageManager', {
      tracking: 'tracking',
    }),
  },
  methods: {
    ...mapActions('pageManager', {
      saveTracking: 'saveTracking',
    }),
    trackAndSaveProperty(prop, value) {
      const tracking = { ...this.tracking }
      tracking[prop] = value
      return this.saveTracking(tracking)
    },
    trackHead: _.debounce(function (ace) {
      return this.trackAndSaveProperty('head', ace)
    }, 3000),

    trackAfterOpeningBody: _.debounce(function (ace) {
      return this.trackAndSaveProperty('afterOpeningBody', ace)
    }, 3000),

    trackBeforeClosingBody: _.debounce(function (ace) {
      return this.trackAndSaveProperty('beforeClosingBody', ace)
    }, 3000),
  },
}
</script>

<style lang="scss">
.ace__container {
  width: 100%;
  height: 150px;
  position: relative;
  .editor {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 150px;
  }
}
</style>
