import * as actions from './actions'
import * as mutations from './mutations'
import {MixPanel} from '../../lib/mixpanel'

let MP = null

function loadMixPanel () {
  if (!MP) {
    MP = new MixPanel()
  }
}

export default {
  state: {
    loaded: false
  },
  getters: {
  },
  mutations: {
    [mutations.MIXPANEL_LOADED] (state, loaded) {
      state.loaded = loaded
    }
  },
  actions: {
    async [actions.MIXPANEL_BOOT] ({state, commit, dispatch}, user) {
      if (state.loaded) {
        return
      }

      loadMixPanel()

      commit(mutations.MIXPANEL_LOADED, true)

      await dispatch(actions.MIXPANEL_PEOPLE_SET_ONCE, user)

      await dispatch(actions.MIXPANEL_INIT_USER, user.userID)
    },
    async [actions.MIXPANEL_PEOPLE_SET_ONCE] ({state}, user) {
      await MP.setPeopleOnce(user)
    },
    async [actions.MIXPANEL_INIT_USER] ({state}, userId) {
      await MP.initUser(userId)
    },
    async [actions.MIXPANEL_TRACK] ({state}, { event, category, data }) {
      await MP.trackEvent(event, category, data)
    }
  }
}
