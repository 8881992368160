<template>
  <div class="form-group">
    <cvt-select
      :value="fontFamily"
      mode="dropdown"
      :label="$t('setup.font.family')"
      @input="(value) => updateFontFamily(value)"
    >
      <option v-if="placeholder.trim()" value="" disabled selected>
        {{placeholder}}
      </option>
      <option
        v-for="font in availableFonts"
        :key="font.kababName()"
        :value="font.kababName()"
      >
        <span :style="font.toStyle('18px')" v-text="font.familyName" />
      </option>
    </cvt-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as getters from '../../store/modules/getters'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fontFamily: this.value,
    }
  },
  computed: {
    ...mapGetters({
      availableFonts: getters.THEME_AVAILABLE_FONTS,
    }),
  },
  methods: {
    updateFontFamily(value) {
      this.fontFamily = value
      this.$emit('input', value)
    },
  },
}
</script>
