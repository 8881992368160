<template>
  <div identity="BackgroundShape">
    <div v-if="showOption('desktop')" class="row mb-3">
      <div class="col-12">
        <cvt-select
            mode="dropdown"
            :value="objectShape"
            label="Shapes"
            :theme="mode"
            :background-mode="mode"
            @input="onShapeChanged"
        >
          <option
              v-for="optionAction in shapes"
              :key="optionAction.name"
              :value="optionAction.name"
              v-text="optionAction.name"
          ></option>
        </cvt-select>
      </div>
    </div>

    <div v-if="showOption('desktop')" class="row">
      <div class="col">
        <h6>{{$t('elements.properties.bgshape.color')}}</h6>
      </div>
      <div class="col-12">
        <colors
            alpha
            :mode="mode"
            :custom-options="customOptions"
            @apply-color="applyBackgroundColor"
            @apply-transparency="applyTransparency"
        />
      </div>
    </div>

    <div v-if="showShapePositionToggle" class="row  mt-3">
      <div
          v-if="OrgName === 'freshworks'"
          class="col-auto mx-auto"
      >
        <cvt-radio-group
            class="radio-group"
            orientation="horizontal"
            :initial-value="bgPosition"
        >
          <cvt-radio-button
              horizontal
              size="md"
              value='top'
              color="primary"
              @radioClick="toggleShapePosition"
          >
            {{$t('elements.properties.bgshape.top')}}
          </cvt-radio-button>
          <cvt-radio-button
              size="md"
              value='bottom'
              color="primary"
              @radioClick="toggleShapePosition"
          >
            {{$t('elements.properties.bgshape.bottom')}}
          </cvt-radio-button>
        </cvt-radio-group>
      </div>
      <div
          v-else
          class="col-auto mx-auto"
      >
        <cvt-switch
            size="md"
            color="grey"
            type="rounded"
            description-type="text"
            :inactive-text="$t('elements.properties.bgshape.top')"
            :active-text="$t('elements.properties.bgshape.bottom')"
            :initial-state="bgPosition === 'top'? false: true"
            :mode="mode"
            @switchToggle="toggleShapePosition"
        />
      </div>
    </div>

    <div v-if="showWidth" class="row  mt-3">
      <div class="col-auto d-flex align-items-center">
        <h6 :class="textColorClass" class="m-0">
          {{$t('elements.properties.bgshape.width')}}
        </h6>
      </div>
      <div class="col d-flex align-items-center">
        <cvt-slider
            id="slider-default"
            :tooltips-enabled="true"
            :step-marks-enabled="false"
            class="slider"
            :min="getMinWidth"
            :max="getMaxWidth"
            :value="width"
            :step="1"
            @change="onWidthChange"
        />
      </div>
    </div>

    <div v-if="showHeight" class="row  mt-3">
      <div class="col-auto d-flex align-items-center">
        <h6 :class="textColorClass" class="m-0">
          {{$t('elements.properties.bgshape.height')}}
        </h6>
      </div>
      <div class="col d-flex align-items-center">
        <cvt-slider
            id="slider-default"
            :tooltips-enabled="true"
            :step-marks-enabled="false"
            class="slider"
            :min="0"
            :max="getMaxHeight"
            :value="height"
            :step="1"
            @change="onHeightChange"
        />
      </div>
    </div>
    <div v-if="canFlip && showOption('desktop')">
      <div class="divider mt-2"/>
      <div class="row">
        <div class="col">
          <strong>{{$t('elements.properties.bgshape.flip')}}</strong>
        </div>
        <div class="col-3">
          <cvt-switch
              size="sm"
              color="active"
              type="circular"
              description-type="default"
              :initial-state="isFlipped"
              @switchToggle="() => toggleFlip()"
          />
        </div>
      </div>
    </div>
    <div v-if="canInvert && showOption('desktop')">
      <div class="divider mt-2"/>
      <div class="row">
        <div class="col">
          <strong>{{$t('elements.properties.bgshape.invert')}}</strong>
        </div>
        <div class="col-3">
          <cvt-switch
              size="sm"
              color="active"
              type="circular"
              description-type="default"
              :initial-state="isInverted"
              @switchToggle="() => toggleInvert()"
          />
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Colors from '../theme/Colors.vue'
import * as _ from "lodash";
import {ConvrrtBackgroundShapes} from "../../../lib/background-shapes";
import * as actions from "../../../store/modules/actions";
import * as getters from "../../../store/modules/getters";

export default {
  name: "BackgroundShape",
  components: {
    Colors
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  data () {
    const noneShape = {
      svg: null,
      canFlip: false,
      canInvert: false,
      name: 'NONE',
      isFullHeight: false,
      adjustHeightInPercentage: false,
      adjustWidthInPercentage: false
    }
    const shapes = [noneShape, ...ConvrrtBackgroundShapes]
    return {
      defaultTransparency: 1.0,
      shapes,
      currentShape: {}
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      viewMode: getters.VIEW_MODE
    }),
    ...mapState('globalTheme', {
      OrgName: ({ globalTheme }) => globalTheme.OrgName !== undefined ? globalTheme.OrgName : 'standard'
    }),
    bgPosition: {
      get() {
        return _.get(this, 'selected.bgShape.position', 'top')
      },
      set(value) {
        return this.updateProp({ key: 'bgShape/position', value: value })
      }
    },
    bgShapeObject: {
      get() {
        return _.get(this, 'selected.bgShape.isEnabled', null)
      },
      set(value) {
        return this.updateProp({ key: 'bgShape', value: value })
      }
    },
    textColorClass() {
      return { 'text-white': this.mode == 'DARK' }
    },
    spacingMode () {
      return this.spacingModeData
    },
    width: {
      get() {
        return _.get(this, `selected.bgShape.${this.viewMode}.width`, this.getDefaultWidth)
      },
      set(value) {
        return this.updateProp({ key: `bgShape/${this.viewMode}`, value:  {width: value, height: this.height}})
      }
    },
    height: {
      get() {
        return _.get(this, `selected.bgShape.${this.viewMode}.height`, this.getDefaultHeight)
      },
      set(value) {
        return this.updateProp({ key: `bgShape/${this.viewMode}`, value: {width: this.width, height: value} })
      }
    },
    fillColor: {
      get() {
        return _.get(this, 'selected.bgShape.fillColor', "")
      },
      set(value) {
        return this.updateProp({ key: 'bgShape/fillColor', value: value })
      }
    },
    fillColorAlpha: {
      get() {
        return _.get(this, 'selected.bgShape.fillColorAlpha', this.defaultTransparency)
      },
      set(value) {
        return this.updateProp({ key: 'bgShape/fillColorAlpha', value: value })
      }
    },
    isFlipped: {
      get() {
        return _.get(this, 'selected.bgShape.isFlipped', false)
      },
      set(value) {
        return this.updateProp({ key: 'bgShape/isFlipped', value: value })
      }
    },
    isInverted: {
      get() {
        return _.get(this, 'selected.bgShape.isInverted', false)
      },
      set(value) {
        return this.updateProp({ key: 'bgShape/isInverted', value: value })
      }
    },
    canFlip() {
      return this.currentShape.canFlip === true
    },
    canInvert() {
      return this.currentShape.canInvert === true
    },
    objectShape: {
      get() {
        return _.get(this, 'selected.bgShape.shape.name', '')
      },
      set(value) {
        const v = {
          name:value.name,
          svg: value.svg
        }
        return this.updateProp({ key: 'bgShape/shape', value: v })
      }
    },
    customOptions() {
      return {
        bindSelected: false,
        transparency: this.fillColorAlpha,
        activeColor: this.fillColor
      };
    },
    showWidth(){
      return this.showOption('desktop', 'phone') && this.currentShape.adjustWidth
    },
    showHeight(){
      return this.showOption('desktop', 'phone') && this.currentShape.adjustHeight
    },
    showShapePositionToggle(){
      return this.showOption('desktop') && this.currentShape.showPositionToggle
    },
    getMaxHeight(){
      return this.currentShape?.adjustHeightInPercentage ? 100 : 500
    },
    getMinWidth(){
      return this.currentShape?.adjustWidthInPercentage ? 0 : 100
    },
    getMaxWidth(){
      return this.currentShape?.adjustWidthInPercentage ? 100 : 300
    },
    getDefaultHeight(){
      return this.currentShape?.adjustHeightInPercentage ? 20 : 150
    },
    getDefaultWidth(){
      return this.currentShape?.adjustWidthInPercentage ? 20 : 100
    }
  },
  mounted () {
    const selectedShapes = this.shapes.filter(x => x.name === this.objectShape)
    if (selectedShapes.length > 0) {
      this.currentShape = selectedShapes[0]
    }
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP
    }),
    toggleShapePosition (data) {
      this.bgPosition = data.toLowerCase()
        this.isFlipped = !this.isFlipped
    },
    applyBackgroundColor( options ) {
      this.fillColor = options.colorId;
    },
    applyTransparency( val ) {
      this.fillColorAlpha = val;
    },
    onWidthChange(val) {
      this.width = val
    },
    onHeightChange(val) {
      this.height = val
    },
    toggleFlip () {
      this.isFlipped = !this.isFlipped
    },
    toggleInvert () {
      this.isInverted = !this.isInverted
    },
    onShapeChanged (value) {
      const selectedShapes = this.shapes.filter(x => x.name === value)
      if (selectedShapes.length > 0) {
        this.currentShape = selectedShapes[0]
        if (this.bgShapeObject === null) {
          this.BgShapeEnable(this.currentShape)
        }
        else {
          if (selectedShapes[0].name !== 'NONE') {
            this.objectShape = this.currentShape
          }
          else {
            this.BgShapeDisable()
          }
        }
      }
    },
    showOption(...views) {
      return views.includes(this.viewMode)
    },
    defaultShapeObject() {
      return {
        isFlipped: false,
        isInverted: false,
        fillColor: "#867474",
        fillColorAlpha: 1.0,
        isFullHeight: false,
        adjustHeightInPercentage: false,
        adjustWidthInPercentage: false,
        desktop: {
          height: this.getDefaultHeight,
          width: this.getDefaultWidth,
        },
        phone: {
          height: this.getDefaultHeight,
          width: this.getDefaultWidth,
        },
        position: 'top',
        shape: {
          name: "NONE",
          svg: null
        },
      }
    },
    BgShapeEnable(shape) {
      let bgShapeObject = this.defaultShapeObject()
      bgShapeObject = {
        ...bgShapeObject,
        shape:{
          name: shape.name,
          svg: shape.svg
        },
        isFullHeight: shape.isFullHeight,
        adjustHeightInPercentage: shape.adjustHeightInPercentage,
        adjustWidthInPercentage: shape.adjustWidthInPercentage
      }
      this.bgShapeObject = bgShapeObject
    },
    BgShapeDisable () {
      this.bgShapeObject = this.defaultShapeObject()
    }
  },
}
</script>

<style scoped>

</style>