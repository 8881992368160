import * as _ from 'lodash'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

export default {
  state: {
    path: ['$children'],
    pageEngine: null,
  },
  getters: {
    [getters.LAYOUT_PAGE_ENGINE]({ pageEngine }) {
      return pageEngine
    },
    [getters.LAYOUT_PATH]({ path }) {
      return path
    },
    [getters.LAYOUT_PARENT](state, rootGetters) {
      return _.chain(rootGetters[getters.LAYOUT_CHILDREN])
        .head()
        .get('$parent')
        .value()
    },
    [getters.LAYOUT_CHILDREN]({ path, pageEngine }) {
      return _.chain(pageEngine).get(path, []).value()
    },
    [getters.LAYOUT_SECTIONS]({ pageEngine }) {
      return _.chain(pageEngine).get('$children').value().filter((layout) => layout.name === 'Block')
    },
  },
  mutations: {
    [mutations.LAYOUT_UPDATE_PATH](state, { path }) {
      state.path = path
    },
  },
  actions: {
    [actions.LAYOUT_INIT]({ state }, { pageEngine }) {
      state.pageEngine = pageEngine
    },
    [actions.LAYOUT_REFRESH]({ state }, path = null) {
      path = !path ? state.path : path
      state.path = [...path]
    },
    [actions.SET_LAYOUT_CHILDREN]({ state }, { path, reorderedElements }) {
      return _.chain(state.pageEngine).set(path, reorderedElements).value()
    },
  },
}
