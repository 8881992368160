import Vue from '../../../lib/utils/vue'
import { styled, VueEmotion } from '@egoist/vue-emotion'
Vue.use(VueEmotion)

const getHeader = () => styled('div')`
  background-color: #f5f7f9;
  height: 50px;
  padding: 15px;
`

export default {
  name: 'CvtHeading',
  render: function (h) {
    const Header = getHeader()
    return <Header>{this.$slots.default}</Header>
  }
}
