
<div class="element-image-manager">
  <div
    class="element-image-manager__preview checkered"
    @mouseover.stop.prevent="showOverlay"
    @mouseleave.stop.prevent="hideOverlay"
    @click.stop.prevent="showIconPicker"
  >
    <div
      v-show="overlayVisible"
      class="element-image-manager__preview__overlay"
    >
      <small
        ><i>{{ assetMgrBtnText }} Icon</i></small
      >
    </div>
    <icon-template
      :set="selected.set"
      :selector="selected.selector"
      class="icon-preview"
    />
  </div>

  <div v-if="OrgName !== orgNameFreshWork" class="divider mt-3" />
  <div v-else class="mt-3" />

  <div class="row">
    <div class="col">
      <cvt-button
        :full-width="fullWidth"
        shape="rounded"
        :size="btnSize"
        :color="btnColor"
        :text="replaceBtnText"
        :mode="mode"
        @click.stop.prevent="showIconPicker"
      />
    </div>
  </div>
</div>
