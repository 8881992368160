import languagePacks from '../../i18n'
import { safeLocalStorage } from '../../lib/localStorage'
import { CookieHelper } from '../services/cookieHelper'

export type State = {
  default: string
  browser: string
  locale: string
  cookie?: string
  override?: string
}

export const selectLanguageFromState = (state: State) =>
  state.override || state.cookie || state.browser || state.default

const languageSupported = (lang: string) => lang in languagePacks

const logLanguageSupportWarning = (lang: string, state: State): string => {
  if (!languageSupported(lang)) {
    console.warn(`Language (${lang}) isn't supported`, JSON.stringify(state))
  }
  return lang
}

const selectWithWarning = (state: State): string =>
  logLanguageSupportWarning(selectLanguageFromState(state), state)

const defaultState = (i18n: any): State => ({
  default: 'en-US',
  browser: window.navigator.language,
  override: safeLocalStorage.getItem('languageOverride'),
  cookie: CookieHelper('_pages_session=')?.language,
  locale: i18n.locale,
})

export default (i18n: any) => {
  return {
    namespaced: true,
    state: defaultState(i18n),
    mutations: {
      initLocale(state: State) {
        return (i18n.locale = state.locale = selectWithWarning(state))
      },
    },
  }
}
