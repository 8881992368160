import { generateUuid } from '../util/generateUuid'
import { styled } from '@egoist/vue-emotion'
import { VueEmotion } from '@egoist/vue-emotion'
import Vue from '../../../lib/utils/vue'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'

let borderColor, color, backgroundColor, textFontSize, textFontWeight, textLineHeight
let Spacing, FontFamily, Radii, Colors, Border

Vue.use(VueEmotion)

const getRadioWrapper = () => styled('div')`
  display: flex;
  align-items: center;
  margin: ${Spacing.SPACING_4}px;
  font-family: ${FontFamily.DEFAULT};
  ${(props) => {
    let fontSize = textFontSize(props.size)
    let lineHeight = textLineHeight(props.size)
    let fontWeight = textFontWeight()

    return fontSize + lineHeight + fontWeight
  }};
`

const getRadioLabel = () => styled('label')`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0;
  padding: ${Spacing.SPACING_16}px ${Spacing.SPACING_16}px
    ${Spacing.SPACING_16}px ${Spacing.SPACING_48}px;
  user-select: none;

  ${(props) => {
    let textColor = props.disabled
      ? color({ themeing: 'dark' }, 300)
      : color({ themeing: 'dark' }, 500)
    let cursorPointer = props.disabled
      ? 'cursor: not-allowed;'
      : 'cursor: pointer;'
    let border = props.border
      ? `border: ${Border.SIZE_1}px solid; border-radius: ${
          Radii.RADIUS_PX_4
        }px; ${borderColor({ themeing: 'dark' }, 200)}`
      : ''

    return textColor + cursorPointer + border
  }}
`

const getRadioInput = () => styled('input')`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  :checked ~ span {
    ${(props) =>
      props.themeing == 'light'
        ? backgroundColor({ themeing: 'dark' }, 500)
        : backgroundColor({ themeing: 'light' })}
    &:after {
      ${(props) => backgroundColor(props, 500)}
      display: block;
    }
  }

  :disabled ~ span {
    ${backgroundColor({ themeing: 'dark' }, 100)}
    ${borderColor({ themeing: 'dark' }, 400)}
    cursor: not-allowed;
    &:after {
      ${backgroundColor({ themeing: 'dark' }, 400)}
    }
    &:hover {
      ${backgroundColor({ themeing: 'dark' }, 100)}
      box-shadow: none;
    }
  }

  ~ span {
    position: absolute;
    top: 8px;
    left: 16px;
    padding: ${Spacing.SPACING_4}px;
    width: 18px;
    height: 18px;
    border-radius: ${Radii.RADIUS_PERCENT_50}%;
    margin: ${Spacing.SPACING_4}px 0;
    border: ${Border.SIZE_1}px solid;
    ${(props) => {
      let radioBorderColor = borderColor(props, 500)
      return radioBorderColor
    }}

    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 2px;
      left: 2px;
      width: 12px;
      height: 12px;
      border-radius: ${Radii.RADIUS_PERCENT_50}%;
    }

    &:hover {
      ${backgroundColor({ themeing: 'dark' }, 200)}
      box-shadow: 0 0 0 6px ${Colors.BASIC[200]};
    }
  }
`

const Radio = styled('span')``

export default {
  name: 'CvtRadio',
  components: {},
  inject: ['radioGroup'],
  props: {
    value: {
      type: String,
      default: '1'
    },
    // the value-prop is behaving abnormally, so for backward-campatibility
    // a new-prop is declared here as 'otherValue', which will be used as 'value'
    // the value-prop will continue to work as it was working before.
    // backward-campatibility is in place
    otherValue: {
      type: String,
      default: null,
      required: false
    },
    orientation: {
      type: String,
      default: 'horizontal'
    },
    border: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      uuid: generateUuid()
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      color: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT
    }),
    ...mapState('globalTheme', {
      FontFamily: ({globalTheme}) => globalTheme.FontFamily,
      Spacing: ({globalTheme}) => globalTheme.Spacing,
      Radii: ({globalTheme}) => globalTheme.Radii,
      Colors: ({globalTheme}) => globalTheme.Colors,
      Border: ({globalTheme}) => globalTheme.Border
    }),
    isActive() {
      if(this.otherValue === null){
        return this.value === this.radioGroup.activeValue;
      }else{
        return this.otherValue === this.radioGroup.activeValue;
      }
    },
    theme() {
      return this.radioGroup.theme
    },
    name() {
      return this.radioGroup.uniqueName
    },
    isDisabled() {
      return this.disabled || this.radioGroup.isDisabled
    }
  },
  methods: {
    onClick(value) {
      this.radioGroup.activeValue = value
      this.$emit('radioClick', value)
    }
  },
  created() {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Radii = this.Radii
    Colors = this.Colors
    Border = this.Border
    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    color = this.color
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
  },
  render: function (h) {
    const RadioWrapper = getRadioWrapper()
    const RadioLabel = getRadioLabel()
    const RadioInput = getRadioInput()

    return (
      <RadioWrapper
        border={this.border}
        size={this.size || this.radioGroup.size}
      >
        <RadioLabel
          for={this.uuid}
          disabled={this.isDisabled}
          themeing={this.theme}
          border={this.border}
        >
          {this.$slots.default}
          <RadioInput
            id={this.uuid}
            themeing={this.theme}
            name={this.name}
            type='radio'
            value={
                this.otherValue === null
                ? this.value :
                  this.otherValue
              }
            checked={this.isActive}
            onClick={() =>
              this.otherValue === null
                ? this.onClick(this.value) :
                  this.onClick(this.otherValue)
              }
            disabled={this.isDisabled}
          />
          <Radio themeing={this.theme} />
        </RadioLabel>
      </RadioWrapper>
    )
  }
}
