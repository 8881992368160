import { styled, VueEmotion } from '@egoist/vue-emotion'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import Vue from '../../../lib/utils/vue'
import {
  FRESHWORKS,
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../constants'
import CvtButton from '../../molecules/Button'
import { CvtIcon } from '../../molecules/icon/icon'

Vue.use(VueEmotion)
let Spacing, FontFamily, Border
let borderColor,
  backgroundColor,
  textFontSize,
  textLineHeight,
  textFontWeight,
  color

const getPropertiesSidebar = () => styled('aside')`
  margin: ${(props) => props.marginTop}px 0 0 0;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.type === LEFT_SIDEBAR_FRESHWORKS ? `width: 335px;` : `width: 272px;`}
  font-family: ${FontFamily.DEFAULT};
  border-left: ${Border.SIZE_1}px solid transparent;
  ${(props) => {
    let borderColorShade = props.mode === 'LIGHT' ? 100 : 600
    let colorShade = props.mode === 'LIGHT' ? 500 : 100
    let backgroundColorShade = props.mode === 'LIGHT' ? 'WHITE' : 700
    let styles =
      borderColor({ themeing: 'dark' }, borderColorShade) +
      color({ themeing: 'dark' }, colorShade)
    styles += props.specialStyleBgColor
      ? `background-color: ${props.collapseItemContentColor};`
      : backgroundColor({ themeing: 'dark' }, backgroundColorShade)
    return styles
  }}
  &.__fixed {
    position: fixed;
    right: 0;

    ${(props) => (props.type === LEFT_SIDEBAR_FRESHWORKS ? `right: 64px;` : ``)}
    z-index: 1;
    height: calc(100vh - ${props => props.sideBarHeight}px);
  }
`

const getHeaderArea = () => styled('div')`
  flex: 0;
  padding: ${Spacing.SPACING_12}px;
  display: flex;
  border-bottom: ${Border.SIZE_1}px solid transparent;
  ${(props) => {
    let borderColorShade = props.mode === 'LIGHT' ? 100 : 600
    return borderColor({ themeing: 'dark' }, borderColorShade)
  }}
  ${textFontSize('xl')}
  ${textLineHeight('xl')}
  ${textFontWeight('regular')}
  ${(props) => {
    if (!props.showHeader) {
      return 'display: none;'
    }
    return ''
  }}
`

const getHeaderTextWrap = () => styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
`

const getHeaderText = () => styled('span')`
  margin-left: ${Spacing.SPACING_8}px;
  ${(props) => (props.type === FRESHWORKS ? 'font-size: 16px;' : '')}
`

const getHeaderActionWrap = () => styled('div')`
  flex: 0;
`

const HeaderAction = styled(CvtButton)``

const getFooterArea = () => styled('div')`
  flex: 0;
  display: flex;
  border-top: ${Border.SIZE_1}px solid transparent;
  ${textFontSize('sm')}
  ${textLineHeight('sm')}
  ${textFontWeight('light')}
  ${(props) => {
    let borderColorShade = props.mode === 'LIGHT' ? 100 : 600
    return borderColor({ themeing: 'dark' }, borderColorShade)
  }}
  & .footer-item {
    padding: ${Spacing.SPACING_16}px;
    flex: 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &:hover,
    :focus,
    :active {
      ${(props) => {
        let backgroundColorShade = props.mode === 'LIGHT' ? 100 : 600
        return backgroundColor({ themeing: 'dark' }, backgroundColorShade)
      }}
    }
  }

  & .justify-center {
    display: flex;
    justify-content: center;
  }
`
const getFooterIcon = () => styled(CvtIcon)`
  margin-bottom: ${Spacing.SPACING_8}px;
`
const DuplicateWrap = styled('div')``

const getDuplicateIcon = (FooterIcon) => styled(FooterIcon)``

const DuplicateText = styled('span')``

const DeleteWrap = styled('div')``

const getDeleteIcon = (FooterIcon) => styled(FooterIcon)``

const DeleteText = styled('span')``

const SaveWrap = styled('div')``

const getSaveIcon = (FooterIcon) => styled(FooterIcon)``

const SaveText = styled('span')``

const getMainArea = () => styled('div')`
  overflow-y: auto;
  flex: 1;
`

const getBookmarkWrap = () => styled('div')`
  display: flex;
  border-top: ${Border.SIZE_1}px solid transparent;
  ${textFontSize('sm')}
  ${textLineHeight('sm')}
  ${textFontWeight('light')}
  ${(props) => {
    let borderColorShade = props.mode === 'LIGHT' ? 100 : 600
    return borderColor({ themeing: 'dark' }, borderColorShade)
  }}
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding: ${Spacing.SPACING_16}px;
  align-items: center;
  &:hover,
    :focus,
    :active {
      ${(props) => {
        let backgroundColorShade = props.mode === 'LIGHT' ? 100 : 600
        return backgroundColor({ themeing: 'dark' }, backgroundColorShade)
      }}
    }
`

const getBookmarkIcon = (FooterIcon) => styled(FooterIcon)``

const getBookmarkText = () => styled('div')`
`

const getBackArrowBtn = () => styled('span')`
  margin-right: 8px;
  cursor: pointer;
`

export const CvtPropertiesSidebar = {
  name: 'CvtPropertiesSidebar',
  components: {},
  provide() {
    return {
      propertiesSidebar: this,
    }
  },
  created() {
    FontFamily = this.FontFamily
    Spacing = this.Spacing
    Border = this.Border
  },
  computed: {
    ...mapGetters('globalTheme', {
      textColor: getters.GLOBAL_STYLE_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
    }),
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
      isGridBuilder: getters.IS_GRID_BUILDER,
      selected: getters.SELECTOR_SELECTED,
    }),
    ...mapState('globalTheme', {
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Border: ({ globalTheme }) => globalTheme.Border,
      iconConfig: ({ iconConfig }) => iconConfig,
      collapseItemContentColor: ({ globalTheme }) =>
        globalTheme.collapseItemContentColor !== undefined
          ? globalTheme.collapseItemContentColor
          : '',
      // TODO: STATE_REFACTOR: Investigate
      topNavConfig: ({ topNavConfig }) => topNavConfig,
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
    }),
    inFreeTrialMode() {
      return this.config.TRIAL_MODE
    },
    sideBarHeight(){
      return this.inFreeTrialMode ? '130' : '64'
    },
    enableBookmark() {
      // @TODO: feature flag bookmarking feature here
      return true
    },
    showBackArrowBtn() {
      return this.allowBackAction.includes(this.selected?.name)
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    prependChevron: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: '',
    },
    imageUrlValidation: {
      type: String,
      default: '',
    },
    imageLinkValidation: {
      type: String,
      default: '',
    },
    showSidebar: {
      type: Boolean,
      default: false,
    },
    showSaveButton: {
      type: Boolean,
      default: false,
    },
    gridBuilderEnabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: LEFT_SIDEBAR_STANDARD,
    },
    specialStyleBgColor: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showBookmarkButton: {
      type: Boolean,
      default: false,
    },
    allowBackAction: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    closeSidebar() {
      this.$emit('closeSidebar', false)
    },
    clickFooterButton(eventType) {
      this.$emit(eventType)
    },
    convertStrToSnakeCase(str){
      return str.toLowerCase().replace(/\s/g, "_")
    },
    editBtnText() {
      return this.$t(`element.property.title.edit_${this.convertStrToSnakeCase(this.variant)}`)
    },
    helperMessage() {
      return this.$t('help.header.message')
    }
  },
  created() {
    Spacing = this.Spacing
    FontFamily = this.FontFamily
    Border = this.Border
    borderColor = this.borderColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    textFontWeight = this.textFontWeight
    color = this.textColor
  },
  render: function (h) {
    const HeaderActionWrap = getHeaderActionWrap()
    const HeaderText = getHeaderText()
    const HeaderTextWrap = getHeaderTextWrap()
    const HeaderArea = getHeaderArea()
    const PropertiesSidebar = getPropertiesSidebar()
    const FooterArea = getFooterArea()
    const FooterIcon = getFooterIcon()
    const MainArea = getMainArea()
    const DuplicateIcon = getDuplicateIcon(FooterIcon)
    const DeleteIcon = getDeleteIcon(FooterIcon)
    const SaveIcon = getSaveIcon(FooterIcon)
    const BookmarkWrap = getBookmarkWrap()
    const BookmarkIcon = getBookmarkIcon(FooterIcon)
    const BookmarkText = getBookmarkText()
    const BackArrowBtn = getBackArrowBtn()

    return (
      <div>
        {this.showSidebar && (
          <PropertiesSidebar
            class={{ __fixed: true }}
            mode={this.mode}
            type={this.type}
            specialStyleBgColor={this.specialStyleBgColor}
            collapseItemContentColor={this.collapseItemContentColor}
            marginTop={
              this.topNavConfig.theme?.height
                ? this.topNavConfig.theme?.height
                : this.Spacing.SPACING_64
            }
            sideBarHeight={this.sideBarHeight}
          >
            <HeaderArea mode={this.mode} showHeader={this.showHeader}>
              {!this.isGridBuilder && (
                <HeaderActionWrap mode={this.mode}>
                  <HeaderAction
                    shape="pill"
                    size={this.type === LEFT_SIDEBAR_FRESHWORKS ? 'lg' : 'sm'}
                    icon={this.iconConfig?.propertiesSidebar?.close || 'times'}
                    actionIcon={true}
                    colorOfActionIcon=""
                    modeOfActionIcon={this.mode === 'LIGHT' ? 'dark' : 'light'}
                    onClick={this.closeSidebar}
                    mode={this.mode}
                    color={
                      this.type === LEFT_SIDEBAR_FRESHWORKS ? 'link' : 'light'
                    }
                    removeBackground={this.type === FRESHWORKS}
                  ></HeaderAction>
                </HeaderActionWrap>
              )}
              <HeaderTextWrap mode={this.mode}>
                {/* {
                  this.isGridBuilder && (
                  <HeaderText>{this.variant.toUpperCase()} QUICK TIPS</HeaderText>
                )} */}
                {this.type === LEFT_SIDEBAR_FRESHWORKS && (
                  <HeaderText type={this.type} mode={this.mode}>
                    {this.variant ? `EDIT ${this.variant.toUpperCase()}`: `QUICK TIPS`}
                  </HeaderText>
                )}
                {this.type !== LEFT_SIDEBAR_FRESHWORKS && this.variant && (
                  <HeaderText type={this.type} mode={this.mode}>
                    {this.showBackArrowBtn && (
                      <BackArrowBtn onClick={() => this.$emit('back')}>
                        <CvtIcon icon='angle-left'></CvtIcon>
                      </BackArrowBtn>
                    )}
                    { this.editBtnText() }
                  </HeaderText>
                )}
                {this.type !== LEFT_SIDEBAR_FRESHWORKS &&
                  this.isGridBuilder &&
                  !this.variant && (
                    <HeaderText>
                      {this.variant.toUpperCase()}{' '}
                      { this.helperMessage() }
                    </HeaderText>
                  )}
              </HeaderTextWrap>
            </HeaderArea>
            <MainArea mode={this.mode}>
              {!this.variant && this.isGridBuilder && (
                <div class="px-4 pt-3">
                  <div class="row pt-3">
                    <div class="col  d-flex flex-column align-items-center">
                      <h5 class="text-center">{this.$t('help.dnd.message')}</h5>
                      <img src="/img/dnd-v2.svg" alt="" width="200px" />
                    </div>
                  </div>
                  <div class="row pt-3">
                    <div class="col d-flex flex-column align-items-center">
                      <h5 class="text-center">
                        {this.$t('help.resize.message')}
                      </h5>
                      <img src="/img/resize-v2.svg" alt="" width="200px" />
                    </div>
                  </div>
                  <div class="row pt-3">
                    <div class="col d-flex flex-column align-items-center">
                      <h5 class="text-center">
                        {this.$t('help.container.message')}
                      </h5>
                      <img src="/img/container-v2.svg" alt="" width="200px" />
                    </div>
                  </div>
                </div>
              )}
              {this.$slots.default}
            </MainArea>
            {this.showBookmarkButton && this.enableBookmark && (
              <BookmarkWrap mode={this.mode} onClick={() => this.clickFooterButton('bookmark')}>
                <BookmarkIcon
                  icon="heart"
                  mode={this.mode}
                ></BookmarkIcon>
                <BookmarkText mode={this.mode}>
                  {this.$t('elements.properties.sidebar.bookmark.text')}
                </BookmarkText>
              </BookmarkWrap>
            )}
            {(this.showSaveButton || this.OrgName !== FRESHWORKS) && (
              <FooterArea mode={this.mode}>
                {this.variant && !this.isGridBuilder && (
                  <DeleteWrap
                    class={{ 'footer-item': true }}
                    mode={this.mode}
                    onClick={() => this.clickFooterButton('delete')}
                  >
                    <DeleteIcon
                      icon={
                        this.iconConfig?.propertiesSidebar?.remove || 'trash'
                      }
                      class={{ 'justify-center': true }}
                      mode={this.mode}
                    ></DeleteIcon>
                    <DeleteText
                      class={{ 'justify-center': true }}
                      mode={this.mode}
                    >
                      Delete
                    </DeleteText>
                  </DeleteWrap>
                )}
                {this.variant && !this.isGridBuilder && (
                  <DuplicateWrap
                    class={{ 'footer-item': true }}
                    mode={this.mode}
                    onClick={() => this.clickFooterButton('duplicate')}
                  >
                    <DuplicateIcon
                      icon={this.iconConfig?.propertiesSidebar?.copy || 'copy'}
                      class={{ 'justify-center': true }}
                      mode={this.mode}
                    ></DuplicateIcon>
                    <DuplicateText
                      class={{ 'justify-center': true }}
                      mode={this.mode}
                    >
                      Duplicate
                    </DuplicateText>
                  </DuplicateWrap>
                )}
                {this.showSaveButton && (
                  <SaveWrap
                    class={{ 'footer-item': true }}
                    mode={this.mode}
                    onClick={() => this.clickFooterButton('save')}
                  >
                    <SaveIcon
                      icon="save"
                      class={{ 'justify-center': true }}
                      mode={this.mode}
                    ></SaveIcon>
                    <SaveText
                      class={{ 'justify-center': true }}
                      mode={this.mode}
                    >
                      Save
                    </SaveText>
                  </SaveWrap>
                )}
              </FooterArea>
            )}
          </PropertiesSidebar>
        )}
      </div>
    )
  },
}
