import * as actions from "../store/modules/actions";
import {VDomCommands} from "./VDomCommand";


export class ViewModeCommand extends VDomCommands {
    private _currentViewMode: string;
    private _PreviewViewMode: string;
    private _dispatch: (action: string, payload?:any) => void;
    private _message: string | undefined;

    constructor(dispatchInstance:(action: string)=> void, currentViewMode: string, PreviewViewMode: string, message?: string) {
        super('');
        this._currentViewMode = currentViewMode
        this._PreviewViewMode = PreviewViewMode
        this._dispatch = dispatchInstance
        this._message = message
    }

    get$emitData() {
        return  {name: 'ViewModeCommand', forceBuild: false}
    }

    get hasChanges () {
        return !this._currentViewMode && !this._PreviewViewMode
    }

    async execute() {
        await this.handleAction(this._currentViewMode, this._message)
    }

    async unExecute() {
        await this.handleAction(this._PreviewViewMode, this._message)
    }

    async handleAction (viewMode: string, message?: string) {
        const _message = message || `Switching to ${viewMode} view`
        await this._dispatch('loader/start', _message)
        setTimeout(() => {
            // We need to delay the dispatch for a while to avoid freeze screen
            this._dispatch(
                actions.COMMAND_CHANGE_VIEW_MODE,
                viewMode
            )
        }, 500)
        setTimeout(async () => {
            // We can only assume in 3-4 seconds, the page has rendered
            this._dispatch('loader/stop')
        }, 4000)

    }
}
