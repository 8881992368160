import { database } from '@/js/store/services/firebase.service'
import { BRAND_MODE_LIST } from '@/js/common/constants/constants'
import * as getters from '@/js/store/modules/getters'
import * as actions from '@/js/store/modules/actions'
import * as mutations from '@/js/store/modules/mutations'

let db = database()

export default {
  state: {
    brands: [],
    managerMode: BRAND_MODE_LIST,
    brandingRef: null,
  },
  getters: {
    [getters.MP_GET_BRANDS]({ brands }) {
      return brands
    },
    [getters.MP_GET_MANAGER_MODE]({ managerMode }) {
      return managerMode
    },
  },
  mutations: {
    [mutations.MP_SET_BRANDS](state, brands) {
      state.brands = brands
    },
    [mutations.MP_SET_MANAGER_MODE](state, mode) {
      state.managerMode = mode
    },
  },
  actions: {
    async [actions.MP_INIT_BRAND_MANAGER]({ state, commit, rootGetters }) {
      // Pull the brands from firebase and commit
      const { orgID, projectID } = rootGetters[getters.AUTH_GET_USER]
      const { MARKETPLACE_PATH } = rootGetters[getters.AUTH_GET_USER_CONFIG]

      // Pull the branding List for firebase
      const brandingRef = db.ref(
        `${MARKETPLACE_PATH}/${orgID}/${projectID}`
      ).child('branding')

      state.brandingRef = brandingRef

      try {
        const snap = await brandingRef.once('value');
        const branding = snap.val() || [];

        // Convert to an array of values
        const brandingArray = Object.entries(branding).map(([key, value]) => ({
          idx: key,
          ...value,
        }));
  
        commit(mutations.MP_SET_BRANDS, brandingArray);
        commit(mutations.MP_SET_MANAGER_MODE, 'brand-list');
      } catch (error) {
        console.error('Error fetching branding data:', error);
      }
    },
    async [actions.MP_DELETE_BRAND]({ state, commit }, brand) {
      try {
        if (!state.brandingRef) {
          throw new Error('Branding reference not initialized');
        }

        // The idx is the key in Firebase, so we can directly remove it
        await state.brandingRef.child(brand.idx).remove();

        // Update the local state
        const updatedBrands = state.brands.filter(b => b.idx !== brand.idx);
        commit(mutations.MP_SET_BRANDS, updatedBrands);

      } catch (error) {
        console.error('Error deleting brand:', error);
      }
    },
    [actions.MP_SET_MANAGER_MODE]({ state, commit, dispatch }, mode) {
      if (!['brand-list', 'brand-create'].includes(mode)) {
        throw `Mode: ${mode} is invalid`
      }
      commit(mutations.MP_SET_MANAGER_MODE, mode)
    },
  },
}
