import Vue from '../../../lib/utils/vue'
import { styled, VueEmotion } from '@egoist/vue-emotion'
import truncate from 'lodash/truncate'

import CvtButton from '../../molecules/Button'
import CvtSelect from '../../molecules/Select'
import CvtSwitch from '../../molecules/Switch'
import { LazyImage } from '../../molecules/LazyImage'
import { CvtIcon } from '../../molecules/icon/icon'
import { CvtTooltip } from '../../molecules/tooltip/CvtTooltip'
import { ContentPlaceholder } from '../../molecules/ContentPlaceholder'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import { mapGetters, mapState } from '../../../../builder/node_modules/vuex'
import { LEFT_SIDEBAR_STANDARD, LEFT_SIDEBAR_FRESHWORKS } from '../../constants'
import VueI18n from 'vue-i18n'
import { BOOKMARKS, STANDARD } from "../../../../builder/src/js/common/constants/constants";

Vue.use(VueEmotion)
Vue.use(VueI18n)

let Spacing, Radii, Border, AddPagesToRight

let borderColor, backgroundColor, textFontSize, textLineHeight, textFontWeight, elevation, color

// TODO: VUE3_UPDATE: Vue 2 work around for fragmenting in jsx, can be removed and it native to Vue 3
const Fragment = {
  functional: true,
  render: (h, ctx) => ctx.children,
}

const getExpandedPages = () => styled('div')`
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-gap: ${Spacing.SPACING_24}px;
  padding: ${Spacing.SPACING_24}px ${Spacing.SPACING_8}px;
  border-left: ${Border.SIZE_1}px solid;

  ${(props) =>
    props.type === LEFT_SIDEBAR_FRESHWORKS
      ? `
      position: fixed;
      top: 100px;
      right: 64px;
      background-color: #FFF;
      height: 100vh;
  `
      : ``}

  ${(props) =>
    props.mode == 'DARK'
      ? borderColor({ themeing: 'dark' }, 500)
      : borderColor({ themeing: 'dark' }, 100)};
`

const getPage = () => styled('div')`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  width: 300px;
  margin-bottom: ${Spacing.SPACING_24}px;

  &:last-child {
    margin-bottom: 0;
  }
`

const getPageName = () => styled('span')`
  margin-bottom: ${Spacing.SPACING_4}px;
  ${textFontSize('sm')}
  ${textLineHeight('xl')}
`

const getPageThumbnailWrapper = () => styled('div')`
  position: relative;
  overflow: hidden;
  border-radius: ${Radii.RADIUS_PX_8}px;
  ${(props) => elevation(props, 100)}

  &:hover {
    ${(props) => elevation(props, 400)}
    .overlay {
      display: flex;
    }
  }
`
const getPageOverlay = () => styled('div')`
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 1;
  ${backgroundColor({ themeing: 'dark' }, 100)}
  ${(props) =>
    props.mode == 'LIGHT'
      ? 'background: rgba(240, 242, 246, 0.5);'
      : 'background: rgb(73, 76, 83, 0.5);'}
  border-radius: ${Radii.RADIUS_PX_8}px;
`
const getPagesWrapper = () => styled('div')`
  overflow: auto;
  ${(props) => props.noPadding ? '' : `padding: 0 ${Spacing.SPACING_12}px;`}
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: ${Radii.RADIUS_PX_8}px;
    ${(props) =>
    props.mode == 'DARK'
      ? backgroundColor({ themeing: 'dark' }, 600)
      : backgroundColor({ themeing: 'dark' }, 100)}
  }

  ${(props) =>
    props.type === LEFT_SIDEBAR_FRESHWORKS
      ? `height: calc(100vh - 230px);`
      : ``}
`
const getAddBlankPageWrapper = () => styled('div')`
  padding: 0 ${Spacing.SPACING_12}px;
`
const getPlaceholderWrapper = () => styled('div')`
  position: relative;
  width: 300px;
  padding-top: calc((100% * 190 / 300) + 16px);
  margin-bottom: ${Spacing.SPACING_16}px;
`
const getPlaceholder = () => styled(ContentPlaceholder)`
  border-radius: ${Radii.RADIUS_PX_8}px;

  &.header-left {
    top: 0;
    left: 0;
    width: 70%;
    height: 16px;
    margin-bottom: ${Spacing.SPACING_8}px;
  }

  &.thumb-card {
    left: 0;
    width: 100%;
    height: calc(100% - 24px);
    top: 24px;
  }
`
const getBlankText = () => styled('span')`
  display: flex;
  width: 300px;
  justify-content: center;
`
const getSectionFilterWrapper = () => styled('div')`
  padding: 0 ${Spacing.SPACING_12}px;
  ${props => props.side === true ? `
    border-top: 1px solid #CFD7DF;
    box-shadow: 0px 8px 4px rgb(7 126 214 / 6%);
    padding-top: 24px;
  ` : ``}
`
const getSectionFilter = () => styled(CvtSelect)`
  ${color({ themeing: 'dark' }, 500)}
`

const getTitleWrapper = () => styled('div')`
 display:flex;
`
const getManagePageTitle = () => styled('div')`
  margin-top:auto; 
  margin-bottom:auto;
`
const getSectionSwitchWrap = () => styled('div')`
  display: flex;
  justify-content: center;
`

const getCvtSwitch = () => styled(CvtSwitch)`
  margin: ${Spacing.SPACING_4}px;
`;

const getBookmark = () => styled('div')`
  display: flex;
  align-items: center;
  width: 300px;
  border-bottom: ${Border.SIZE_1}px solid;
  margin-bottom: ${Spacing.SPACING_24}px;
  ${(props) =>
    props.mode == 'DARK'
      ? borderColor({ themeing: 'dark' }, 600)
      : borderColor({ themeing: 'dark' }, 100)}

  &:last-child {
    margin-bottom: 0;
  }
`

const getBookmarkName = () => styled('span')`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  padding: ${Spacing.SPACING_8}px 0;
  margin-left: 10px;
  ${textFontSize('xl')}
  ${textLineHeight('lg')}
  ${textFontWeight('REGULAR')}

  &:focus {
    outline: none;
  }
`

const DeleteBookmarkIcon = styled(CvtIcon)`
  cursor: pointer;
  margin-left: 7px;
`

const AddBookmarkIcon = styled(CvtIcon)`
  ${props => props.insertingPage ? 'cursor: not-allowed;' : 'cursor: pointer;'}
  margin-left: 7px;
`

const EditBookmarkIcon = styled(CvtIcon)`
cursor: pointer;
`


export const CvtSavePages = {
  name: 'CvtSavePages',
  components: {
    CvtTooltip,
  },
  data () {
    return {
      switchDirection: STANDARD,
      selectedPage: null
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textColor: getters.GLOBAL_STYLE_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textLineHeight: getters.GLOBAL_STYLE_LINE_HEIGHT,
      elevation: getters.GLOBAL_STYLE_ELEVATION,
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Border: ({ globalTheme }) => globalTheme.Border,
      AddPagesToRight: ({ globalTheme }) => globalTheme.AddPagesToRight !== undefined ? globalTheme.AddPagesToRight : false,
      PageGalleryLabel: ({ globalTheme }) => globalTheme.PageGalleryLabel !== undefined ? globalTheme.PageGalleryLabel : 'editor.section.filter.dropdown.placeholder',
      iconConfig: ({ iconConfig }) => iconConfig
    }),
    enableBookmark() {
      // @TODO: feature flag bookmarking feature here
      return true
    }
  },
  methods: {
    onSwitchToggle(data) {
      this.switchDirection = data
      this.$emit("user-view-preference", data)
    },
    truncate(name) {
      return truncate(name, {
        length: 20,
        omission: '...',
      })
    },
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
    loading: {
      type: Boolean,
      default: true
    },
    pages: {
      type: Array,
      default: () => []
    },
    pageTags: {
      type: Array,
      default: () => ['ALL']
    },
    activeTag: {
      type: String,
      default: 'ALL'
    },
    type: {
      type: String,
      default: LEFT_SIDEBAR_STANDARD
    },
    insertingPage: {
      type: Boolean,
      default: false
    }
  },
  created () {
    Spacing = this.Spacing
    Radii = this.Radii
    Border = this.Border
    AddPagesToRight = this.AddPagesToRight
    borderColor = this.borderColor
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    textLineHeight = this.textLineHeight
    elevation = this.elevation
    color = this.textColor
    textFontWeight = this.textFontWeight
  },
  render: function (h) {
    const ExpandedPages = getExpandedPages()
    const pages = []
    const uniqueKeys = new Set();
    const Page = getPage()
    const PageName = getPageName()
    const PageThumbnailWrapper = getPageThumbnailWrapper()
    const PageOverlay = getPageOverlay()
    const PagesWrapper = getPagesWrapper()
    const AddBlankPageWrapper = getAddBlankPageWrapper()
    const PlaceholderWrapper = getPlaceholderWrapper()
    const Placeholder = getPlaceholder()
    const BlankText = getBlankText()
    const SectionFilterWrapper = getSectionFilterWrapper()
    const SectionFilter = getSectionFilter()
    const TitleWrapper = getTitleWrapper()
    const ManagePageTitle = getManagePageTitle()
    const SectionSwitch = getCvtSwitch();
    const SectionSwitchWrap = getSectionSwitchWrap()
    const Bookmark = getBookmark()
    const BookmarkName = getBookmarkName()


    const blankText = <BlankText>No Saved Pages found!</BlankText>
    const placeholders = Array(3).fill(
      <PlaceholderWrapper>
        <Placeholder class='header-left' mode={this.mode}></Placeholder>
        <Placeholder class='thumb-card' mode={this.mode}></Placeholder>
      </PlaceholderWrapper>
    )
    this.pages.forEach(page => {
      if (!uniqueKeys.has(page.key)) {
        uniqueKeys.add(page.key);
        if (this.switchDirection === BOOKMARKS) {
          const isEditable = this.selectedPage && this.selectedPage.key === page.key
          return pages.push(
            <Bookmark id={page.pageName} key={page.key}>
              <CvtIcon icon="file"></CvtIcon>
              <BookmarkName 
                title={page.pageName}
                contentEditable={isEditable}
                suppressContentEditableWarning={true}
                onBlur={({ target }) => {
                  this.$emit('edit-page-name', this.selectedPage, target.innerText)
                  this.selectedPage = null
                }}
                style={{ border: isEditable ? '0.5px solid gray' : 'none' }}
              >
                {isEditable ? page.pageName : this.truncate(page.pageName)}
              </BookmarkName>

              <span>
                <EditBookmarkIcon 
                  id={page.pageName + '-bookmark-edit'}
                  icon="pen"
                  nativeOnClick={(e) => this.selectedPage = page}
                ></EditBookmarkIcon>
                <CvtTooltip
                  placement="top"
                  offset="5"
                  fallbackPlacement="right"
                  delay="100"
                  color="dark"
                  mode={this.mode}
                  noFade={true}
                  innerPadding={8}
                  ref="tooltip"
                  target={page.pageName + '-bookmark-edit'}
                >
                  Edit
                </CvtTooltip>
              </span>

              <span>
                <AddBookmarkIcon 
                  id={page.pageName + '-bookmark-add'}
                  icon="cvt-icon-plus"
                  insertingPage={this.insertingPage}
                  nativeOnClick={() => this.$emit('insert-page', page)}
                ></AddBookmarkIcon>
                <CvtTooltip
                  placement="top"
                  offset="5"
                  fallbackPlacement="right"
                  delay="100"
                  color="dark"
                  mode={this.mode}
                  noFade={true}
                  innerPadding={8}
                  ref="tooltip"
                  target={page.pageName + '-bookmark-add'}
                >
                  Add this page
                </CvtTooltip>
              </span>

              <span>
                <DeleteBookmarkIcon 
                  id={page.pageName + '-bookmark-delete'}
                  icon="cvt-icon-trashcan"
                  nativeOnClick={(e) => this.$emit('delete-bookmark-dialog', page)}
                ></DeleteBookmarkIcon>
                <CvtTooltip
                  placement="top"
                  offset="5"
                  fallbackPlacement="right"
                  delay="100"
                  color="dark"
                  mode={this.mode}
                  noFade={true}
                  innerPadding={8}
                  ref="tooltip"
                  target={page.pageName + '-bookmark-delete'}
                >
                  Delete
                </CvtTooltip>
              </span>
            </Bookmark>
          )
        }
        pages.push(
          <Page id={page.pageName} key={page.key}>
            <PageName>{page.pageName}</PageName>
            <PageThumbnailWrapper mode={this.mode}>
              <PageOverlay mode={this.mode} class={'overlay'}>
                <CvtButton
                  color={this.type === LEFT_SIDEBAR_FRESHWORKS ? 'light' : ''}
                  mode={this.mode}
                  shadow={true}
                  text='Add this Page'
                  onClick={() => this.$emit('insert-page', page)}
                ></CvtButton>
              </PageOverlay>
              <LazyImage
                mode={this.mode}
                dataSrc={page.pageThumbnail}
              ></LazyImage>
            </PageThumbnailWrapper>
          </Page>
        );
      }
    });
    return (
      <div>
        <ExpandedPages mode={this.mode} type={this.type}>
          <TitleWrapper>
            <CvtButton
              mode={this.mode}
              size={`md`}
              onClick={() => this.$emit('back')}
              icon={'fas fa-arrow-left'}
              actionIcon={true}
              removeBackground={true}
            ></CvtButton>
            <ManagePageTitle>
              {this.$t('editor.left_nav.manage_pages_title')}
            </ManagePageTitle>
          </TitleWrapper>
          <AddBlankPageWrapper>
            <CvtButton
              mode={this.mode}
              color={this.mode.toLowerCase()}
              size={this.type === LEFT_SIDEBAR_FRESHWORKS ? `sm` : ``}
              fullWidth={this.type === LEFT_SIDEBAR_STANDARD ? true : false}
              shadow={true}
              text={this.$t('editor.left_nav.add_a_blank_page_button_text')}
              onClick={() => this.$emit('insert-blank-page')}
              outlined={true}
              icon={ this.iconConfig.leftSidebar.pages.plus || '' }
            ></CvtButton>
          </AddBlankPageWrapper>
          {this.enableBookmark && (
            <SectionSwitchWrap>
              <SectionSwitch
                size="md"
                color="grey"
                type="rounded"
                descriptionType="text"
                inactiveText={STANDARD}
                activeText={BOOKMARKS}
                initialState={this.switchDirection === BOOKMARKS}
                onSwitchToggle={data => this.onSwitchToggle(data)}
              >
              </SectionSwitch>
            </SectionSwitchWrap>
          )}
          
          {this.switchDirection === STANDARD && (
            <Fragment>
              <SectionFilterWrapper side={this.AddSectionsToRight}>
                {this.type === LEFT_SIDEBAR_FRESHWORKS && (
                  <h5>{this.$t('editor.header.marketplace.title')}</h5>
                )}
                {this.pages.length > 0 && (
                  <SectionFilter
                    label={this.$t(this.SectionGalleryLabel)}
                    floatingLabel={
                      this.type === LEFT_SIDEBAR_STANDARD ? true : false
                    }
                    mode="dropdown"
                    backgroundMode={this.mode}
                    showCancelIcon={false}
                    value={this.activeTag}
                    defaultValue={this.activeTag}
                    onInput={(value) => {
                      this.$emit('filter-section', value)
                    }}
                  >
                    {this.pageTags.map((option) => {
                      return <option value={option}>{option}</option>
                    })}
                  </SectionFilter>
                )}
              </SectionFilterWrapper>
              <PagesWrapper mode={this.mode} type={this.type}>
                {this.loading && placeholders} {!this.loading && (pages.length > 0 ? pages : blankText)}
              </PagesWrapper>
            </Fragment>
          )}

          {this.switchDirection === BOOKMARKS && (
            <Fragment>
              <PagesWrapper mode={this.mode} type={this.type}>
                {this.loading && placeholders} {!this.loading && (pages.length > 0 ? pages : blankText)}
              </PagesWrapper>
            </Fragment>
          )}
        </ExpandedPages>
      </div>
    )
  }
}
