import { DefaultIntegrationAPI } from "./base";
// import { listSample, mappingFields, savedSetup, tagsSample } from "./mocks";

interface MappingFields{
  integrationFields: {name: string, label: string}[]
  standardFields: {id: string, label: string}[]
}

export class MailChimpIntegrationAPI extends DefaultIntegrationAPI {
  async getUserList() {
    // [...listSample]
    const { data } = await this.api.get('/v1/integrations/mailchimp/list')
    return data
  }

  async getUserListTag(listID: string) {
    // [...tagsSample]
    const { data } = await this.api.get(`/v1/integrations/mailchimp/list/tags?listId=${listID}`)
    return data
  }

  async saveMappedSetup(siteId: string, formId: string, data: object) {
    //saveIntegrationMapping
    // console.log("saveMappedSetup: ", data)
    const res = await this.api.post(
      `/v1/sites/${siteId}/${formId}/saveIntegrationMapping?provider=mailchimp`, {...data}
    )
    return res.data
  }

  async getMappedSetup(siteId: string, formId: string) {
    // return {...savedSetup}
    const { data } = await this.api.get(`/v1/sites/${siteId}/${formId}/getIntegrationMapping`)
    return data
  }

  async getFieldMappings (listID: string, formId: string): Promise<MappingFields> {
    // {...mappingFields}
    const { data } = await this.api.get(`/v1/integrations/mailchimp/form/fields?listId=${listID}`)
    return data
  }
}