
<div
  :id="vnodeId"
  data-identity="CImage"
  :data-vnode-id="vnodeId"
  class="cvt-editor-image"
  :class="classes"
  :style="mainWrapStyles"
  draggable="true"
  @click="select"
  @mouseover="target"
  @contextmenu.prevent.stop="showContextMenu"
  @dblclick.stop.prevent="triggerOpenAssetManager"
  @dragstart="dragstart"
  @dragend="dragend"
>
  <drag-and-drop-zone :z-index="dropzoneZIndex" @dropped="addElementToSide" />

  <div v-if="!isGridBuilder" :class="imgWrapperClassesOB">
    <a 
      v-if="href" 
      :href="href" 
      target="_blank"
    >
      <img
        class="img-fluid"
        :class="imgClasses"
        :style="imgStylesOB"
        :src="altSrc"
        :alt="alt"
      />
    </a>
    <img
      v-else
      class="img-fluid"
      :class="imgClasses"
      :style="imgStylesOB"
      :src="altSrc"
      :alt="alt"
    />
  </div>
  
  <div v-else-if="isGridBuilder" :class="imgWrapperClassesGB" :style="imgWrapperStyles">
    <a 
      v-if="href" 
      :href="href" 
      target="_blank"
      class="img-fluid"
      :class="imgClasses"
      :style="imgStylesGB"
    >
    </a>

    <div
      v-else
      class="img-fluid"
      :class="imgClasses"
      :style="imgStylesGB"
    />
  </div>

  <template v-if="showActionButtonsOnClick && !isGridBuilder">
    <!--THis is only here because of freshwork in v1 gridbuilder,
    once they migrate to v2 or v3, it will become irrelevant-->
    <action-buttons
      v-if="selected"
      @duplicate="duplicateAndValidate"
      @remove="removeAndValidate"
    />
  </template>
  <span :style="captionStyle">{{ caption }}</span>
</div>
