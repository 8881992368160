
<div
  :id="vnodeId"
  ref="subGridWrapper"
  data-identity="SubGridContainer"
  :data-vnode-id="vnodeId"
  :class="classes"
  @click="onClick"
  @mouseenter="mouseOverAction"
  @mouseleave="mouseLeaveAction"
>
  <div :class="subContainerBgImage" />
  <div :class="subContainerBgColor" />

  <div class="section-drag-items-wrap">
    <cvt-section-drag-items
      v-if="vnodeId"
      class="drag-items-sub-container"
      :exclude-item="['container', 'card_element']"
      usage="sub-grid-container"
      :show-plus-btn="true"
      :vnode-id="vnodeId"
      :widget-class="'grid-stack-item grid-stack-item-sub'"
    />
  </div>
  <div  v-show="fbNode.children.length === 0 && !backgroundImgExist" :class="stylesheet.classes.bgEmpty" />
  <div class="w-100" :class="containerClasses">
    <div
        :id="`${vnodeId}-sub-grid`"
        ref="gridRootWrapper" class="position-relative sub-grid-visual-box">
      <visual-box
          ref="vsBox"
          box-color="#333333"
          :grid-column="column"
          :grid-column-height="cellHeight"
          :grid-v-node-id="`${vnodeId}-sub-grid`"/>
      <div
          ref="nestedGridStackElement"
          :style="fontColorBG"
          class="grid-stack"
          @mousedown="subContainerMouseDown"
      >
        <template v-for="node in fbNode.children">
          <grid-item-v3
              :key="node.data.props.vnodeId"
              :fb-node="node"
              v-bind="node.data.props"
              class="grid-stack-item-sub"
              @removeElement="removeNode"
              @duplicateElement="duplicateNode"
              @forceRemoveElement="forceRemoveNode"
          >
          </grid-item-v3>
        </template>
      </div>
    </div>
  </div>
</div>
