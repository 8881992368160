import * as getters from './getters'
import * as actions from './actions'
import { V1Sites } from '../services/api'

const api = new V1Sites()

type State = {
  productVersionNumber: number | null
  modelProductVersionNumber: number | null
  userProductVersionNumber: number
}

export default {
  state: {
    productVersionNumber: null,
    modelProductVersionNumber: null,
    userProductVersionNumber: 1.0,
  },
  getters: {
    [getters.PRODUCT_VERSION_NUMBER](state: State) {
      return state.productVersionNumber
    },
    [getters.CURRENT_PRODUCT_VERSION_NUMBER](state: State) {
      return (
        state.modelProductVersionNumber || state.userProductVersionNumber
      )
    },
    [getters.USER_PRODUCT_VERSION_NUMBER](state: State) {
      return state.userProductVersionNumber
    },
    [getters.IS_GRID_BUILDER](state: State) {
      if (!state.modelProductVersionNumber) {
        return state.userProductVersionNumber >= 2.0
      }
      return state.modelProductVersionNumber >= 2.0
    },
  },
  actions: {
    // get current product version number
    async [actions.GET_PRODUCT_VERSION_NUMBER]({
      state,
      rootGetters,
    }: {
      state: State
      rootGetters: any
    }) {
      if (state.productVersionNumber !== null) {
        return
      }
      const config = rootGetters[getters.AUTH_GET_USER_CONFIG]
      state.userProductVersionNumber = state.modelProductVersionNumber =
        parseFloat(config.PRODUCT_VERSION_NUMBER)

      return api
        .getProductVersionNumber()
        .then(({ data }) => {
          state.productVersionNumber = parseFloat(data.PRODUCT_VERSION_NUMBER?.toString())
        })
        .catch((error) => {
          console.error(error)

          state.productVersionNumber = null
          throw error
        })
    },
    // set current product version number from site or popup
    async [actions.SET_CURRENT_PRODUCT_VERSION_NUMBER](
      { state }: { state: State },
      version: number | string,
    ) {
      if (!version) {
        throw new Error("Model Version is not available")
      }
      try {
        state.modelProductVersionNumber = parseFloat(version.toString())
      } catch (error) {
        console.error(error)
        throw error
      }
    },
  },
}
