import loadFroged from '../../lib/loadFroged'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

function FrogedInstallation({ key }: { key: string }) {
  window.frogedSettings = {
    appId: key,
  }
  return window.frogedSettings
}

export default {
  state: {
    loaded: false,
  },
  getters: {},
  mutations: {
    [mutations.FROGED_LOADED](state, loaded) {
      state.loaded = loaded
    },
  },
  actions: {
    async [actions.FROGED_BOOT]({ state, rootGetters, commit }, user) {
      if (state.loaded) {
        return
      }

      const config = rootGetters[getters.AUTH_GET_USER_CONFIG]

      if (!config.FROGED_SCRIPT_KEY) {
        return
      }
      FrogedInstallation({ key: config.FROGED_SCRIPT_KEY })
      await loadFroged(config.FROGED_SCRIPT_KEY)
      commit(mutations.FROGED_LOADED, true)

      window.Froged('set', {
        userId: user.userID,
        email: user.email,

        orgID: user.orgID,
        projectID: user.projectID,
        firstName: user.firstName,
        name: user.fullName,
      })
    },
  },
}
