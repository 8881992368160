import { styled, VueEmotion } from '@egoist/vue-emotion'
import Vue from '../../../lib/utils/vue'
import { CvtIcon } from '../../molecules/icon';
import AltUploadDropzone from "../../../../builder/src/js/components/asset-manager/alt-upload-dropzone/AltUploadDropZoneIndex.vue"


Vue.use(VueEmotion)

const getLogoContainer = () => styled('div')`
   margin-bottom: 40px !important;

   p {
    font-size: 20px;
    margin-bottom: 10px;
   }
`

const getLogoHeader = () => styled('p')``

const getLogoGroupContainer = () => styled('div')`
   width: 100%;
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(250px, max-content));
   grid-gap: 15px;
   place-items: center;

   &::-webkit-scrollbar{
    display: none;
   }
`

const getLogoBoxContainer = () => styled('div')`
   display: flex;
`

const getLogoBox = () => styled('div')`
    width: 270px;
    height: 196px;
    border-radius: 4px;
    background-color: #F0F2F6;
    display: grid;
    place-content: center;
    cursor: pointer;
   ${(props) => props.isActive && `border: 3px solid #5D62AB;`}

   & > img{
    height: 40px;
    margin-bottom: 10px;
   }
`

const getLogoActiveBox = () => styled('div')`
   display: flex;
   justify-content: center;
   align-items: center;
`


const getLogoActiveLogo = () => styled('img')`
   height: 20px;
`


const getAddNewLogo = () => styled('div')`
    width: 270px;
    height: 196px;
    border: 2px dashed #BCC2CB;
    border-radius: 4px;
    display: grid;
    place-content: center;
    cursor: pointer;
    text-align: center;

    p {
        color: #8A8E97;
        width: 80%;
        margin: 0 auto;
        padding-top: 15px;
    }
`

const getLogo = () => styled('img')``

const getAddNewLogoIcon = () => styled(CvtIcon)`
   font-size: 25px;
   color: #5D62AB;
`

const getAddNewLogoLabel = () => styled('p')``

export const CvtLogoSelector = {
   name: 'CvtLogoSelector',
   components: {
    AltUploadDropzone
   },
   data () {
   return {
       activeLogo: null,
       selectedLogo: '',
       showUploadModal: false
   }
   },
   props: {
       label: {
           type: String,
           default: ''
       },
       brandLogos: {
           type: Array,
           default: () => []
       }
   },
   computed: {},
   methods: {
       onSelectLogo(logo, idx) {
           this.activeLogo = idx
           this.$emit('setCurrentLogo', logo.formats[0].src, idx)
       },
       onShowUploadModal() {
           this.showUploadModal = !this.showUploadModal
       },
       onCompleteUpload(image){
            this.$emit('selectNewLogo', image)
            this.showUploadModal = false
       }
   },
   render: function (h) {


       const LogoContainer = getLogoContainer()
       const LogoHeader = getLogoHeader()
       const Logo = getLogo()
       const LogoGroupContainer = getLogoGroupContainer()
       const LogoBoxContainer = getLogoBoxContainer()
       const LogoActiveBox = getLogoActiveBox()
       const LogoActiveLogo = getLogoActiveLogo()
       const LogoBox = getLogoBox()
       const AddNewLogo = getAddNewLogo()
       const AddNewLogoIcon = getAddNewLogoIcon()
       const AddNewLogoLabel = getAddNewLogoLabel()




       return (
           <LogoContainer>
               <LogoHeader>{ this.label }</LogoHeader>
               <LogoGroupContainer>
               { this.brandLogos.map((logo, idx) => (
                   <LogoBoxContainer
                       key={idx}
                       onClick={() => this.onSelectLogo(logo, idx)}
                   >
                       <LogoBox
                           isActive={this.activeLogo === idx}
                       >
                        <Logo
                            src={logo.formats[0].src}
                            alt="brandingImage"
                        />
                           <LogoActiveBox>
                           {
                               this.activeLogo === idx && (
                                   <LogoActiveLogo
                                       src="/img/enterprise/branding-check-light.svg"
                                       alt="check"
                                   />
                               )
                           }
                           </LogoActiveBox>
                       </LogoBox>
                   </LogoBoxContainer>
               ))}
                   <AddNewLogo
                       onclick={() => this.onShowUploadModal()}
                   >
                       <AddNewLogoIcon
                           icon="fa fa-plus-circle"
                        />
                        <AddNewLogoLabel>Add a logo for your brand</AddNewLogoLabel>
                   </AddNewLogo>
                   <cvt-dialog
                       bg-color="#ffffff"
                       size="sm"
                       height-auto={true}
                       show-close={true}
                       show={this.showUploadModal}
                       onclose={() => this.onShowUploadModal()}
                   >
                        <alt-upload-dropzone
                            ref="uploadDropzone"
                            image-only={true}
                            size-limit={5}
                            hide-gallery-button={true}
                            oncomplete={(value) => this.onCompleteUpload(value)}
                        />
                   </cvt-dialog>
               </LogoGroupContainer>
           </LogoContainer>
       )
   }
}
