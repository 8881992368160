
<div class="layers-menu">
  <div v-if="title" class="layer-menu__title">
    <h2>Layers</h2>
  </div>

  <div
    v-for="node in nodes"
      :key="node"
       class="layer"
       :class="isActiveNode(node)"
       @mouseover.stop.prevent="target(node)"
       @click.stop.prevent="select(node)">

    <h3 v-text="fmtNodeName(node.name)"></h3>
  </div>

</div>
