/**
 * safeLocalStorage is a wrapper for localStorage that provides a safe interface
 * when 3rd party cookies are disabled accessing local storage can result in runtime errors
 */
export const safeLocalStorage = {
  exec(method: string, key: string, value?: any): any {
    try {
      return localStorage[method](key, value)
    } catch (e) {
      console.warn(
        `localStorage access failed ${method} ${key} ${value} \n ${e}`,
      )
      return
    }
  },
  getItem(key: string): string {
    return this.exec('getItem', key)
  },
  setItem(key: string, value: any): void {
    return this.exec('setItem', key, value)
  },
}
