
<div data-identity="PopupThumbnailUrl">
  <h4 class="admin-label">{{ label }}</h4>
  <cvt-input
    type="text"
    name="thumbnailUrl"
    placeholder="https://"
    :clearable="false"
    :value="thumbnailUrl"
    @input="(event) => popupSiteThumbnailUrlChange(event)"
  />
</div>
