import Vue from 'vue'
import VueI18n from 'vue-i18n'
import * as constants from '../../../../../builder/src/js/common/constants/constants'
import CvtRadioGroup from '../../../molecules/radio-group/RadioGroup'
import { IntegrationReady } from '../ready'
import {
  getIntegrationTitle,
  getIntegrationUseSubtext,
  getIntegrationUseWrap,
  IntegrationDescription
} from "../description";
import { IntegrationLogoWrapper } from '../logo-wrapper'
import { IntegrationContainer } from '../container'
import { HubspotSetup } from "./setup";
import IntegrationItemBase from "../IntegrationItemBase.vue";

Vue.use(VueI18n)

export default IntegrationItemBase.extend({
  name: 'HubspotIntegration',
  components: {
    CvtRadioGroup
  },
  data() {
    return {
      seeMapping: false
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  mounted() {
    this.onScrollInToView()
  },
  methods: {
    onScrollInToView() {
      const container = document.getElementById('authWrap')
      container?.scrollIntoView()
    },
  },
  render(h) {
    const integrationTitle = this.selectedIntegration.title

    const IntegrationTitle = getIntegrationTitle()
    const IntegrationUseWrap = getIntegrationUseWrap()
    const IntegrationUseSubtext = getIntegrationUseSubtext()

    return (
      <IntegrationContainer>
        {this.showAuthScreen && (
          <IntegrationLogoWrapper>
            <IntegrationDescription provider="hubspot" onStepChange={(step) => this.onChangeAuthStep(step, this.selected.id)}>
              <div style="padding: 45px 0px 45px 45px;">
                <IntegrationTitle>
                  <a href="https://www.hubspot.com/" target="_blank">HubSpot</a> offers free CRM software and tools for more
                  efficient marketing, sales, and customer service.
                  Integrate your contact form or lead gen form with HubSpot
                  CRM to update your HubSpot account automatically.
                </IntegrationTitle>
                <IntegrationUseWrap>

                  <p>Use this integration to</p>
                  <ul>
                    <li>
                      Capture new leads
                    </li>
                    <li>
                      Add contacts to your HubSpot lists
                    </li>
                    <li>
                      Keep existing contacts up-to-date
                    </li>
                    <li>
                      Create deals for contacts
                    </li>
                  </ul>
                </IntegrationUseWrap>
                <IntegrationUseSubtext>
                  Streamline your contact management process with Meclabs AI’s HubSpot integration.
                  By instantly populating your HubSpot CRM with the information you collect through your online forms,
                  you’ll never have to manually transfer contacts again — saving you time better spent getting to know your customers.
                </IntegrationUseSubtext>
                <IntegrationUseSubtext>
                  {/* Learn how to */}
                  Learn how to{' '}
                  <a href="https://integrations.meclabsai.com/hubspot.html" target="_blank" style="text-decoration: underline;">
                    {' '}
                    integrate your form with {integrationTitle}.
                  </a>
                </IntegrationUseSubtext>
              </div>
            </IntegrationDescription>
          </IntegrationLogoWrapper>
        )}

        {this.integrationConfig.step === constants.AUTH_SUCCESS && this.isValidProvider && (
          <HubspotSetup onCompleted={step => this.onCompleted()} onCancel={this.onCancel}/>
        )}

        {this.integrationConfig.step === constants.INTEGRATION_FINISHED && this.isValidProvider && (
          <HubspotSetup onCancel={this.onCancel} onCompleted={step => this.onUpdate(constants.INTEGRATION_FINISHED)}/>
        )}

        {this.integrationConfig.step === constants.INTEGRATION_COMPLETE && this.isValidProvider && (
          <IntegrationReady onFinished={
            () => this.onFinished()
          }/>
        )}
      </IntegrationContainer>
    )
  },
})
