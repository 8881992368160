const DEFAULT_MESSAGE = 'loader.builder.message'
const DEFAULT_ROUTE = ''

export default {
  namespaced: true,
  state: {
    visible: true,
    msg: DEFAULT_MESSAGE,
    svg: 'InitializeAnimation',
    route: '',
  },
  mutations: {
    show(state) {
      state.visible = true
    },
    hide(state) {
      state.visible = false
    },
    setMessage(state, msg = '') {
      state.msg = msg || DEFAULT_MESSAGE
    },
    setRoute(state, route = '') {
      state.route = route || DEFAULT_ROUTE
    },
  },
  actions: {
    async start({ state, commit }, payload) {
      const isAnObject = typeof payload === 'object'
      const msg = isAnObject ? payload?.msg : payload || DEFAULT_MESSAGE
      const route = isAnObject ? payload.route : ''

      commit('setMessage', msg)
      commit('show')
      if (route) {
        commit('setRoute', route)
      }
    },
    async stop({ state, commit }, msg = '') {
      commit('setMessage', msg)
      commit('hide')
    },
  },
}
