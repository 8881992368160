<template>
  <cvt-collapse :accordion="true" :theme="mode === 'LIGHT' ? 'light' : 'dark'" :prepend-chevron="prependChevron">
    <cvt-collapse-item :title="$t('elements.properties.background.color')" name="color" :initial-active="true">
      <colors alpha :mode="mode"></colors>
    </cvt-collapse-item>

    <cvt-collapse-item :title="$t('elements.properties.spacing.label')" name="spacing">
      <spacing-ctrl></spacing-ctrl>
    </cvt-collapse-item>

    <cvt-collapse-item :title="$t('elements.properties.visibility.label')" name="visibility">
      <visibility-ctrl :mode="mode"></visibility-ctrl>
    </cvt-collapse-item>

    <cvt-collapse-item :title="$t('elements.properties.advanced')" name="advanced">
      <div class="row">
        <div class="col align-items-center text-capitalize">
          <h6 :class="textColorClass">{{ $t("element.property.column.gutters") }}</h6>
        </div>
        <div class="col-12">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="noGutters"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('noGutters')"
          ></cvt-switch>
        </div>
      </div>

      <div class="divider"></div>

      <div class="row">
        <div class="col align-items-center text-capitalize">
          {{ $t("elements.properties.border.radius") }}
        </div>
        <div class="col-12">
          <resize size-key="borderRadius" :min="0" :step="5" :max="100" :label="$t('elements.properties.border.radius')" hide-label></resize>
        </div>
      </div>
    </cvt-collapse-item>
  </cvt-collapse>
</template>

<script>
  import * as _ from 'lodash'
  import Resize from './Resize.vue'
  import SpacingCtrl from './SpacingCtrl.vue'
  import FlexAlign from './FlexAlign.vue'
  import VisibilityCtrl from './VisibilityCtrl.vue'
  import Colors from '../theme/Colors.vue'
  import { mapGetters, mapMutations, mapActions } from 'vuex'
  import * as getters from '../../../store/modules/getters'
  import * as actions from '../../../store/modules/actions'
  import * as mutations from '../../../store/modules/mutations'

  export default {
    components: {
      Resize,
      SpacingCtrl,
      Colors,
      VisibilityCtrl
    },
    props: {
      mode: {
        type: String,
        default: 'LIGHT'
      },
      prependChevron: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        activeTab: 'color'
      }
    },
    computed: {
      ...mapGetters({
        selected: getters.SELECTOR_SELECTED
      }),
      noGutters: {
        get () {
          return this.selected.noGutters
        },
        set (value) {
          return this.updateProp({key: 'noGutters', value})
        }
      },
      textColorClass () {
        return {'text-white': this.mode=='DARK'}
      }
    },
    methods: {
      ...mapActions({
        updateProp: actions.SELECTOR_UPDATE_PROP
      }),
      onSwitchToggle (property) {
        property == 'noGutters'? this.noGutters = !this.noGutters :  ''
      }
    }
  }
</script>

<style lang="scss">
</style>
