import { Notification } from 'element-ui';

export default {
  async linkDomain() {
    this.validateSiteIdOrDomain();
    this.linkBtnLoading = true;
    this.linkBtnText = this.$t(
      'custom_domain.ui.domain_instruction.verify_button.text.verifying'
    );

    try {
      const response = await this.connectDomain({
        siteId: this.site.id,
        domain: this.domain,
      });
      const check = response.data.check;
      let message = response.data.message;

      if (check) {
        if (message === 'owner_match') {
          this.unlinkExistingDomain = true;
          this.openUnlinkDialog(this.site.id, this.domain);
          return;
        }
        this.performCheckAction(message);
      } else if (!check) {
        if (message === 'already_linked') {
          return this.loadDomains();
        }
        this.performErrorNotification(message);
      }
    } catch (error) {
      console.error(error.message);
      this.linkBtnLoading = false;
      this.linkBtnText = this.$t(
        'custom_domain.ui.domain_instruction.verify_button.text.try_again'
      );

      Notification.error({
        message: 'An error occured',
        position: 'bottom-right',
      });
    }
  },
  async unlinkDomain(domain) {
    // update the domain data properties
    this.domain = domain;
    this.validateSiteIdOrDomain();

    const response = await this.disconnectDomain({
      siteId: this.site.id,
      domain: this.domain,
    });

    if (response) {
      this.$message({
        type: 'info',
        message: `${this.$t('custom_domain.success.domain_unlinked')} ${
          this.unlinkDomainName
        }`,
      });
      // reset the UI and data properties
      this.unlinkDomainSiteId = '';
      this.unlinkDomainName = '';
      this.unlinkDialog = false;
      this.unlinkExistingDomain = false;
      this.performUiReset();
    } else {
      console.error(`${this.$t('custom_domain.error.unlink_dialog.part_one')}`);
      this.$message({
        type: 'warning',
        message: `${this.$t('custom_domain.error.unlink_dialog.part_one')}, ${
          this.unlinkDomainName
        }. ${this.$t('custom_domain.error.unlink_dialog.part_two')}`,
      });
    }
  },
  openUnlinkDialog(siteId, domain) {
    this.unlinkDomainSiteId = siteId;
    this.unlinkDomainName = domain;
    this.unlinkDialog = true;
  },
  openApexDialog() {
    this.apexDialog = true;
  },
  clearDomain() {
    this.showDomainInstruction = false;
    this.domain = '';
  },
  updateDomain({ target }) {
    this.domain = target.value;
  },
  performCheckAction(message) {
    this.performUiReset();
    this.linkSuccessMessage = this.$t(message);
    setTimeout(() => {
      this.linkSuccessMessage = '';
    }, 5000);
  },
  performErrorNotification(message) {
    // TODO:
    // The connect button in custom-domain-actions.jsx is not reactive and
    // its disable attribute is reliant on showDomainInstructions value of false.
    // This button needs its own disabling property to fix the issue.

    this.linkBtnLoading = false;
    this.linkBtnText = this.$t(
      'custom_domain.ui.domain_instruction.verify_button.text.try_again'
    );
    this.clearDomain();
    Notification.error({ message: this.$t(message), position: 'bottom-right' });
  },
  performUiReset() {
    this.loadDomains();
    this.linkBtnLoading = false;
    this.linkBtnText = this.$t(
      'custom_domain.ui.domain_instruction.verify_button.text.verify'
    );
    this.clearDomain();
  },
};
