<template>
  <GenAILayout>
    <div class="select-industry-container">
      <div class="select-industry-inner-container">
        <h1 class="select-industry-header">
          {{ $t('gen_ai.industry.header.text') }}
        </h1>
        <div class="select-industry-wrapper">
          <div class="select-industry-inner-wrapper">
            <div
              v-for="(industry, index) in industries"
              :key="index"
              class="select-industry-card-wrapper"
            >
              <cvt-industry-card
                :header-text="industry.industryName"
                :sub-text="industry.text"
                :icon="industry.icon"
                :is-active="cardIsSelected(industry.industryType)"
                :value="industry.industryType"
                @click="(industryType) => onClickIndustryCard(industryType)"
              >
              </cvt-industry-card>
            </div>
          </div>
        </div>
        <div
          v-if="!otherIndustryIsActive"
          class="industry-description-checkbox-wrap"
        >
          <input type="checkbox" @change="toggleCheckBox" />
          <label>{{
            $t('gen_ai.industry.other_industry.checkbox.label')
          }}</label>
        </div>
        <div v-else class="industry-description-input-wrap">
          <label>{{ $t('gen_ai.industry.other_industry.input.label') }}</label>
          <cvt-input
            :value="otherIndustry"
            type="text"
            :placeholder="
              $t('gen_ai.industry.other_industry.input.placeholder')
            "
            @input="(e) => onChangeOtherIndustry(e.target.value)"
          />
        </div>

        <hr />
        <div class="select-industry-btn-wrap">
          <cvt-button
            class="select-industry-btn"
            :text="$t('custom_domain.ui.unlink_dialog.buttons.continue')"
            shape="round"
            color="primary"
            icon="arrow-right"
            :disabled="disabled"
            :prepend="false"
            @click="gotTolandingPageGoal"
          ></cvt-button>
        </div>
      </div>
    </div>
  </GenAILayout>
</template>
<script>
import { cvtRouteNodes } from '@/js/utils/routes'
import { getSessionItemGenAIOnboarding, setSessionGenAiOnboarding } from "./utils";
import GenAILayout from "./baseLayout.vue"

export default {
  components: {
    GenAILayout
  },
  props: {},
  data() {
    return {
      disabled: true,
      otherIndustryIsActive: false,
      otherIndustry: '',
      selectedIndustries: [],
      industries: [
        {
          id: 0,
          industryName: 'Online Store',
          text: 'Physical or digital products',
          icon: 'shopping-cart',
          industryType: 'online-store',
          isActive: false,
        },
        {
          id: 1,
          industryName: 'Local Service Provider',
          text: 'Physical or digital products',
          icon: 'gear',
          industryType: 'local-service-provider',
          isActive: false,
        },
        {
          id: 2,
          industryName: 'Coach & Consultant',
          text: 'Physical or digital products',
          icon: 'chalkboard-teacher',
          industryType: 'coach-and-consultant',
          isActive: false,
        },
        {
          id: 3,
          industryName: 'Online Learning',
          text: 'Physical or digital products',
          icon: 'laptop',
          industryType: 'online-learning',
          isActive: false,
        },
        {
          id: 4,
          industryName: 'Events & Entertainment',
          text: 'Physical or digital products',
          icon: 'calendar',
          industryType: 'events-and-entertainment',
          isActive: false,
        },
        {
          id: 5,
          industryName: 'Health & Fitness',
          text: 'Physical or digital products',
          icon: 'heart',
          industryType: 'health-fitness',
          isActive: false,
        },
        {
          id: 6,
          industryName: 'Medical & Dental',
          text: 'Physical or digital products',
          icon: 'hospital',
          industryType: 'medical-dental',
          isActive: false,
        },
        {
          id: 7,
          industryName: 'Real Estate',
          text: 'Physical or digital products',
          icon: 'home',
          industryType: 'real-estate',
          isActive: false,
        },
        {
          id: 8,
          industryName: 'Professional Services',
          text: 'Physical or digital products',
          icon: 'briefcase',
          industryType: 'professional-services',
          isActive: false,
        },
      ],
      sectionError: {
        isSectionValid: false,
        msg: '',
      },
    }
  },
  computed: {},
  mounted() {
    this.selectedIndustries = getSessionItemGenAIOnboarding('industry') || []
    this.validateSection(this.selectedIndustries)
  },
  methods: {
    toggleCheckBox() {
      this.selectedIndustries = []
      this.otherIndustryIsActive = true
    },
    gotTolandingPageGoal() {
      if (this.sectionError.isSectionValid) {
        setSessionGenAiOnboarding('industry', this.selectedIndustries)

        this.$router.push({
          name: cvtRouteNodes.landingPageGoal,
        })
      }
    },
    cardIsSelected(industryType) {
      let industryIsSelelcted = this.selectedIndustries.includes(industryType)
      if (industryIsSelelcted) {
        return true
      }
      return false
    },
    onClickIndustryCard(industryType) {
      this.otherIndustryIsActive = false

      let industryIsNotSelelcted =
        !this.selectedIndustries.includes(industryType)
      if (industryIsNotSelelcted) {
        this.selectedIndustries.push(industryType)
      } else {
        this.selectedIndustries = this.selectedIndustries.filter(
          (item) => item !== industryType,
        )
      }
      this.validateSection(this.selectedIndustries)
    },
    onChangeOtherIndustry(value) {
      this.otherIndustry = value

      if (value) {
        this.selectedIndustries = [value]
      } else {
        this.selectedIndustries = []
      }
      this.validateSection(this.selectedIndustries)
    },
    validateSection(selectedIndustries) {
      if (selectedIndustries.length === 0) {
        this.disabled = true
        this.sectionError.msg = 'Select atleast one industry'
        this.sectionError.isSectionValid = false
      } else {
        this.disabled = false
        this.sectionError.msg = ''
        this.sectionError.isSectionValid = true
      }
    },
  },
}
</script>
<style lang="scss">
@import './styles/SelectIndustry.style.scss';
</style>
