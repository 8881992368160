
<div class="form-group" :data-controller="stimulusController">
  <template v-if="isTextInput">
    <label v-show="label" :class="srOnly" :for="id" v-text="label" />
    <input
      :id="id"
      class="form-control"
      :type="type"
      :aria-describedby="helpId"
      :placeholder="placeholder"
      :name="name"
      :data-custom="isCustom"
      :data-virtual="isVirtual"
      :required="required"
      data-target="phone-validator.input"
      data-action="input->phone-validator#validate"
      @focus="blur"
    />
    <small
      v-show="helpText"
      :id="helpId"
      class="form-text"
      :class="srOnlyHelp"
      v-text="helpText"
    />
  </template>

  <template v-if="isTextArea">
    <label v-show="label" :class="srOnly" :for="id" v-text="label" />
    <textarea
      :id="id"
      class="form-control"
      rows="6"
      :type="type"
      :aria-describedby="helpId"
      :name="name"
      :data-custom="isCustom"
      :data-virtual="isVirtual"
      :required="required"
      :placeholder="placeholder"
      @focus="blur"
    />
    <small
      v-show="helpText"
      :id="helpId"
      class="form-text"
      :class="srOnlyHelp"
      v-text="helpText"
    />
  </template>

  <template v-if="isSelect">
    <label v-show="label" :class="srOnly" :for="id" v-text="label" />
    <select
      :id="id"
      class="form-control"
      :name="name"
      :data-custom="isCustom"
      :data-virtual="isVirtual"
      :required="required"
      :multiple="isSelectMulti"
      disabled
      @focus="blur"
    >
      <template v-if="value !== ''">
        <option value="value" disabled selected v-text="value"></option>
      </template>
      <template v-else>
        <option value="" disabled selected>{{ placeholder }}</option>
      </template>
    </select>
    <small
      v-show="helpText"
      :id="helpId"
      class="form-text"
      :class="srOnlyHelp"
      v-text="helpText"
    />
  </template>

  <template v-if="isRadio">
    <label class="form-control-label mr-1" v-text="placeholder" />
    <div v-for="o in field.options" :key="o.id" class="form-check">
      <label class="form-check-label">
        <input
          :id="id"
          class="form-check-input mr-1"
          type="radio"
          :name="name"
          :data-custom="isCustom"
          :data-virtual="isVirtual"
          :value="o.value"
          :data-tag-ids="tagIds(o.tags)"
          :checked="field.defaultValue === o.value"
          disabled
        />
        {{ o.label }}
      </label>
    </div>
  </template>

  <template v-if="isYesNo">
    <label class="form-control-label mr-1" v-text="placeholder" />
    <div class="form-check form-check-inline">
      <label class="form-check-label">
        <input
          :id="id"
          class="form-check-input mr-1"
          type="radio"
          :name="name"
          :data-custom="isCustom"
          :data-virtual="isVirtual"
          value="1"
          disabled
        />
        Yes
      </label>
    </div>

    <div class="form-check form-check-inline">
      <label class="form-check-label">
        <input
          :id="id"
          class="form-check-input"
          type="radio"
          :name="name"
          :data-custom="isCustom"
          :data-virtual="isVirtual"
          value="0"
        />
        No
      </label>
    </div>
  </template>

  <template v-if="isDateInput">
    <label v-show="label" :class="srOnly" :for="id" v-text="label" />
    <input
      :id="id"
      class="form-control"
      :type="type"
      :placeholder="placeholder"
      :name="name"
      :data-custom="isCustom"
      :data-virtual="isVirtual"
      :value="value"
      :required="required"
      @focus="blur"
    />
    <small
      v-show="helpText"
      :id="helpId"
      class="form-text"
      :class="srOnlyHelp"
      v-text="helpText"
    />
  </template>

  <template v-if="isCheckbox">
    <div class="form-check mb-2 mr-sm-2 mb-sm-0">
      <label class="form-check-label">
        <input
          class="form-check-input"
          type="checkbox"
          :name="name"
          :data-custom="isCustom"
          :data-virtual="isVirtual"
          :required="required"
          :data-tag-ids="tagIds(tags)"
          :checked="value"
        />
        {{ placeholder }}
      </label>
    </div>
  </template>

  <template v-if="isHidden">
    <input
      :id="id"
      type="hidden"
      :value="value"
      :name="name"
      :data-custom="isCustom"
      :data-virtual="isVirtual"
      :data-url-key="urlKey"
    />
  </template>

  <template v-if="isCurrency">
    <label v-show="label" :class="srOnly" :for="id" v-text="label" />
    <input
      :id="id"
      class="form-control"
      type="number"
      min="0"
      max="200000000000.9999"
      step="0.0001"
      :aria-describedby="helpId"
      :placeholder="placeholder"
      :name="name"
      :data-custom="isCustom"
      :data-virtual="isVirtual"
      :required="required"
      @focus="blur"
    />
    <small
      v-show="helpText"
      :id="helpId"
      class="form-text"
      :class="srOnlyHelp"
      v-text="helpText"
    />
  </template>

  <template v-if="isNumber">
    <label v-show="label" :class="srOnly" :for="id" v-text="label" />
    <input
      :id="id"
      class="form-control"
      type="number"
      min="0"
      max="200000000000.9999"
      step="0.0001"
      :aria-describedby="helpId"
      :placeholder="placeholder"
      :name="name"
      :data-custom="isCustom"
      :data-virtual="isVirtual"
      :required="required"
      @focus="blur"
    />
    <small
      v-show="helpText"
      :id="helpId"
      class="form-text"
      :class="srOnlyHelp"
      v-text="helpText"
    />
  </template>

  <template v-if="isAddress">
    <div :data-remote-field="remoteField" data-address="true">
      <label v-show="label" :class="srOnly" :for="id" v-text="label" />

      <div v-if="addressFieldEnabled('line1')" class="form-group">
        <input
          class="form-control"
          type="text"
          placeholder="Address Line 1"
          :name="addressFieldName('line1')"
          :required="required"
          @focus="blur"
        />
      </div>

      <div v-if="addressFieldEnabled('line2')" class="form-group">
        <input
          class="form-control"
          type="text"
          placeholder="Address Line 2"
          :name="addressFieldName('line2')"
          :required="required"
          @focus="blur"
        />
      </div>

      <div v-if="addressFieldEnabled('country_code')" class="form-group">
        <select
          class="form-control"
          :name="addressFieldName('country_code')"
          :required="required"
        >
          <option
            v-if="country"
            :value="country.alpha3"
            selected
            v-text="country.name"
          />
          <option v-else value="">-- select country --</option>
        </select>
      </div>

      <div v-if="addressFieldEnabled('locality')" class="form-group">
        <input
          class="form-control"
          type="text"
          placeholder="City"
          :name="addressFieldName('locality')"
          :required="required"
          @focus="blur"
        />
      </div>

      <div v-if="addressFieldEnabled('region')" class="form-group">
        <select
          class="form-control"
          :name="addressFieldName('region')"
          :required="required"
        >
          <option
            v-if="subdivision"
            :value="subdivision.alpha3"
            v-text="subdivision.name"
          />
          <option v-else value="">-- select a state / region --</option>
        </select>
      </div>

      <div v-if="addressFieldEnabled('postal_code')" class="form-group">
        <input
          class="form-control"
          type="text"
          placeholder="Postal Code"
          :name="addressFieldName('postal_code')"
          :required="required"
          @focus="blur"
        />
      </div>

      <small
        v-show="helpText"
        :id="helpId"
        class="form-text"
        :class="srOnlyHelp"
        v-text="helpText"
      />
    </div>
  </template>
</div>
