
<div>
  <cvt-top-nav-bar
    style="position: fixed; width: 100%; z-index: 3"
    :mode="mode"
    variant="admin"
    :org-id="admin.orgID"
    :project-id="admin.projectID"
    route="marketplace"
    :fixed="true"
    :input-data="inputOrgData"
    :input-project-data="projects"
    @inputOrgName="onOrgChange"
    @createNewOrg="createNewOrg"
    @inputProjectID="onProjectChange"
    @change="searchProjects"
  >
  </cvt-top-nav-bar>

  <div>
    <div>
      <cvt-admin-left-nav-bar
        style="top: 64px"
        usage="adminDashboard"
        initial-active-id="templates"
        :mode="mode"
      >
      </cvt-admin-left-nav-bar>
    </div>
    <div style="position: absolute; left: 280px; top: 64px">
      <div class="search-filter-style">
        <cvt-search-filter-admin
          class="search-filter-bar"
          :mode="mode"
          @sortBymarketplace="onSortBymarketplace"
          @searchBySiteID="onsearchBySiteID"
          @goToMarketPlace="
            () => {
              $router.push('/')
            }
          "
        ></cvt-search-filter-admin>
      </div>
      <div
        class="cards-div"
        :style="filteredSites.length !== 0 ? 'padding: 20px;' : ''"
      >
        <cvt-marketplace-card
          v-for="(item, idx) in filteredSites"
          :id="item.id"
          :key="item.message"
          v-intersected-once="{
            onIntersected: !onlyMarketplace
              ? loadMoreSites
              : loadMoreTemplates,
            shouldObserve: filteredSites.length - 1 === idx,
          }"
          :item="item"
          :mode="mode"
          :thumbnail="item.thumbnail"
          :preview-button="previewButton"
          :copy-page-button="copyPageButton"
          thumbnail-alt="Lead Gen Thumbnail"
          usage="adminDashboard"
          @action="onAction"
        >
          <template #cardTitle>{{ item.name }}</template>
          <template #id>{{ item.id }}</template>
        </cvt-marketplace-card>
      </div>

      <div class="admin-dashboard-loading">
        <cvt-loading-message message="Loading" :display="isLoading">
        </cvt-loading-message>
      </div>
    </div>
  </div>
  <cvt-dialog
    :show="newOrgDialogFlag"
    :height-auto="true"
    :show-close="true"
    :show-footer="false"
    :bg-color="'white'"
    @close="handleClose"
  >
    <template v-if="showDialogHeader" #title>
      <h1>Create New Organization</h1>
    </template>
    <template #default>
      <cvt-new-org-modal
        :show="newOrgDialogFlag"
        :org-created="orgCreated"
        :shared-url="sharedURL"
        @api-call="apiCall"
        @close="handleClose"
      />
    </template>
  </cvt-dialog>
</div>
