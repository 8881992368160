<template>
  <div class="h-100" style="overflow: auto">
    <div class="ssd-wrapper"> 
      <p class="ssd-leading-wrap">
        <span class="ssd-leading-arrow"><i class="fa fa-arrow-left"></i></span>
        <span class="ssd-leading-text">SuperFunnel Strategy Database</span>
        <span v-if="notionConnected" class="ssd-leading-settings">
          <i class="fa fa-gear"></i>
        </span>
      </p>

      <cvt-button
        class="my-3"
        icon="plus"
        :prepend="true"
        :outlined="true"
        :text="notionConnected ? $t('left.sidebar.ssd.notes.add') : $t('left.sidebar.ssd.notes.connect.notion')"
        :full-width="true"
        @click="onConnectNotion" 
      />

      <div v-if="notionConnected" class="ssd-section-wrap">
        <div style="display: flex; justify-content: center;">
          <cvt-switch
            size="md"
            color="grey"
            type="rounded"
            description-type="text"
            :inactive-text="'Value Proposition'"
            :active-text="'Micro yes(s)'"
            :initial-state="direction == 'tab1'? false: true"
            @switchToggle="onSwitchToggle"
          />
        </div>

        <div
          v-for="(pages, idx) in notionNotes"
          :key="idx"
          class="ssd-section-inner"
          @click="() => {}"
        >
          <p class="ssd-section-head">{{pages.name}}</p>
          <div class="ssd-section-body">
            <div class="ssd-section-notes">
              <div v-if="pages.editMode" class="ssd-section-editable">
                <textarea v-model="pages.notes" class="ssd-section-textarea"></textarea>
              </div>
              <div v-else class="ssd-section-editable">
                <p>{{ pages.notes }}</p>
              </div>
              <p class='ssd-section-copy' @mouseleave="() => handleCopyBlur(idx)">
                <i 
                  v-if="!pages.copied"
                  style="cursor: pointer;font-size: 16px;"
                  class="fa fa-copy"
                  @click="() => onCopyNote(idx)"
                ></i>
                <span v-if="pages.copied" class='ssd-copied-text'>
                  <i style="font-size: 14px;" class="fa fa-check"></i>
                </span>
              </p>
            </div>
            <hr />
            <cvt-button
              class="my-3"
              :icon="pages.editMode ? 'save' : 'cvt-icon-pencil'"
              :prepend="true"
              :outlined="true"
              :text="pages.editMode ? 'Update' : 'Edit'"
              :full-width="true"
              @click="() => onToggleEditMode(idx)" 
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as actions from '../../../store/modules/actions'
import * as mutations from '../../../store/modules/mutations'
import * as getters from '../../../store/modules/getters'


export default {
  data() {
    return {
      notionConnected: false,
      notionNotes: [
        {
          name: 'ICP (Initial customer profile)',
          notes: '1. Small to medium businesses in the home services and construction niche doing $1 million a year in revenue 2. Move Towards: Freedom & Autonomy, Positive Impact, Financial Success, Flexibility, Creativity, Growth & Expansion, Innovation, Customer & Employee Satisfaction, Social Responsibility, Compressing Time. Move Away: Financial loss & damage to rep, competition from bigger companies, cash flow rollercoaster, losing key employees, shifts in the market, excessive risk, wasted resources, inefficiencies, complacency, legal issues 3. Micro Yes 1 4. Proven 15 - 40% increase in sales revenue in 90 days. Same training that’s responsible for 9 figures in revenue at GC Enterprises 5. With sales growth charts & testimonials, what’s inside the training (lessons, coaching, tracking, hiring assessments, live coaching calls)',
          copied: false,
          editMode: false
        },
        {
          name: 'CFO (Customer first objective)',
          notes: 'TO help small and medium business owners experience our world class salestraining (1 Billion+ lessons and 396,543 active users) BY giving them a free 14 day trial of our training (8000+ interactive video segments) IN EXCHANGE for scheduling a demo with our sales team',
          copied: false,
          editMode: false
        }
      ],
      direction: 'tab1',
    }
  },
  computed: {
    ...mapState({
    }),
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),
  },

  methods: {
    ...mapMutations({
    }),

    onConnectNotion () {
      this.notionConnected = true
    },
    onSwitchToggle (data) {
      this.direction = data
    },
    async onCopyNote(idx) {
      try {
        const value = this.notionNotes[idx].notes;
        await navigator.clipboard.writeText(value);

        this.notionNotes[idx].copied = true
      } catch(err) {
        // no op
      }
    },
    onToggleEditMode(idx) { 
      this.notionNotes[idx].editMode = !this.notionNotes[idx].editMode; 
    },
    handleCopyBlur(idx) {
      this.notionNotes[idx].copied = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/utils';

.ssd-wrapper {
  width: 20rem;
  padding: 35px 20px;
}

.ssd-leading-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.ssd-leading-arrow {
  cursor: pointer;
}

.ssd-leading-settings {
  margin-left: auto;
  display: flex;
  align-item: center;
}

.ssd-section-wrap {
  padding-top: 20px;
}

.ssd-section-inner {
  margin: 25px 0;
}

.ssd-section-head {
  font-size: 12px;
}

.ssd-section-body {
  background: #F0F2F6;
  border-radius: 5px;
  padding: 23px;
}

.ssd-section-copy {
  outline: 0;
}

.ssd-copied-text {
  font-size: 11px;
}

.ssd-section-notes {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.ssd-leading-text {
  font-weight: 700;
  font-size: 14px;
  margin-left: 15px;
}

.ssd-section-editable {
  width: 88%;
}

.ssd-section-textarea {
  width: 100%;
  height: 220px;
  outline: none;
}
</style>
