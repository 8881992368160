<template>
  <div
    class="
      cvt-editor-grid-item-wrapper
      grid-stack-draggable-item grid-stack-item
      ui-draggable
      item-hover-border
    "
    data-identity="GridItemWrapper"
    :data-gs-x="dataX"
    :data-gs-y="dataY"
    :data-gs-width="dataWidth"
    :data-gs-height="dataHeight"
    :data-vnode-id="vnodeId"
    :gs-x="dataX"
    :gs-y="dataY"
    :gs-w="dataWidth"
    :gs-h="dataHeight"
    :gs-locked="false"
    :data-gs-locked="false"
    :data-is-child-trageted="isChildTrageted"
    :data-is-child-selected="isChildSelected"
    :data-child-name="childComponent.name"
    @dblclick.stop=""
    @mouseenter="gridItemWrapperMouseEnter"
    @mouseover="targetChildElement"
  >
    <div
      :class="contentWrapClasses"
      data-identity="GridItemWrapperContentWrapper"
      @dblclick="toggleOverlay(false)"
    >
      <div class="grid-item-actions">
        <div class="ui-draggable-handle move-icon">
          <cvt-icon icon="arrows-alt"/>
        </div>
      </div>

      <div
        v-if="isNonDraggableContent"
        class="editable-element-overlay"
      >
        <cvt-button
          class="editable-element-edit-button"
          color="light"
          size="md"
          :mode="'LIGHT'"
          :text="editBtnText"
          :icon="'pencil'"
          :outlined="true"
          @click="toggleOverlay(false)"
        />
      </div>

      <div
        ref="content"
        class="d-flex w-100 grid-stack-item-element-content"
        :class="contentClasses"
        data-identity="GridItemWrapperContent"
      >
        <slot />
      </div>

    </div>
    <action-buttons
      v-if="selectedChild"
      @duplicate="duplicateElement"
      @remove="removeElement"
    />
  </div>
</template>

<script>
import uuid from 'uuid/v4'
import * as _ from 'lodash'
import FbVNode from '../../base/FbVNode.vue'
import { mapGetters } from 'vuex'
import * as getters from '../../../store/modules/getters'

export default FbVNode.extend({
  name: 'GridItemWrapper',
  props: {
    phone: {
      type: Object,
      default: () => {},
    },
    desktop: {
      type: Object,
      default: () => {},
    },
    borderWidth: {
      type: Number,
      default: 0,
    },
    borderStyle: {
      type: String,
      default: '',
    },
    borderColor: {
      type: String,
      default: '',
    },
    borderRadius: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      name: 'GridItemWrapper',
      isMounted: false,
      showEditingOverlay: false,
      childComponentTag: null,
    }
  },
  beforeUpdate () {
    // everytime this component re-render/re-evalute we want to get child-component again from $slots object
    // need to improve this logic
    this.childComponentTag = this.getChildComponentTag()
  },
  updated () {
    this.childComponentTag = this.getChildComponentTag()
  },
  mounted () {
    if (!this.vnodeId) {
      this.updateProp('vnodeId', uuid())
    }
    this.isMounted = true
    this.childComponentTag = this.getChildComponentTag()
  },
  computed: {
    ...mapGetters({
      viewMode: getters.VIEW_MODE,
      selectedComponent: getters.SELECTOR_SELECTED,
      targetedComponent: getters.SELECTOR_TARGETED,
    }),
    contentWrapClasses () {
      if (!this.isGridBuilder) {
        return {
          'grid-item-wrapper-content-wrapper': true,
          'grid-stack-item-content': !this.isNonDraggableContent || this.showEditingOverlay,
          'show-overlay': this.showEditingOverlay,
          'hide-overlay': !this.showEditingOverlay || this.selectedChild,
        }
      }
      return {
        'show-overlay': this.showEditingOverlay,
        'hide-overlay': !this.showEditingOverlay || this.selectedChild,
      }
    },
    contentClasses () {
      return {
        'grid-item-wrapper-content': true,
        // 'overlayable-element': this.isOverlayableElement,
        [this[this.viewMode].flexHorizonAlign]: true,
        [this[this.viewMode].flexVerticalAlign]: true,
        [this.stylesheet.classes.bgColor]: true,
      }
    },
    isOverlayableElement () {
      return ['draft', 'c-form', 'sub-grid-container'].includes(this.childComponentTag)
    },
    isNonDraggableContent () {
      return (
        ['draft', 'c-form', 'sub-grid-container'].includes(this.childComponentTag)
      )
    },
    editBtnText() {
      if (this.childComponentTag === 'draft') {
        return this.$t('editor.section.grid_item.edit.btn_text')
      } else if (this.childComponentTag === 'c-form') {
        return this.$t('editor.section.grid_item.edit.form_btn_text')
      } else {
        return this.$t('editor.section.grid_item.edit.element_btn_text')
      }
    },
    dataX () {
      return this[this.viewMode].dataX
    },
    dataY () {
      return this[this.viewMode].dataY
    },
    dataWidth () {
      return this[this.viewMode].dataWidth
    },
    dataHeight () {
      return this[this.viewMode].dataHeight
    },
    selectedChild () {
      if (this.$slots.default) {
        return this.selectedComponent === this.$slots.default[0].componentInstance
      }
      return false
    },
    isChildTrageted () {
      return (
        this.targetedComponent &&
        this.getWrappedElement() &&
        this.targetedComponent.vnodeId === this.getWrappedElement().vnodeId
      )
    },
    isChildSelected () {
      return (
        this.selectedComponent &&
        this.getWrappedElement() &&
        this.selectedComponent.vnodeId === this.getWrappedElement().vnodeId
      )
    },
    childComponent () {
      return this.isMounted ? (this.$refs.content?.children?.[0]?.__vue__ ?? {}) : {}
    },
    styles () {
      return {
        bgColor: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          backgroundColor: this.themeColorHSL,
          borderRadius: `${this.borderRadius || 0}px`,
          borderWidth: `${this.borderWidth || 0}px`,
          borderColor: this.borderColor,
          borderStyle: this.borderStyle,
        },
      }
    },
  },
  watch: {
    viewMode: {
      immediate: true,
      handler (newVal) {
        setTimeout(() => {
          this.triggerResizeChild()
        }, 400)
      },
    },
  },
  methods: {
    duplicateElement () {
      if (this.$slots.default) {
        this.$slots.default[0].componentInstance.duplicateAndValidate()
      }
    },
    removeElement () {
      if (this.$slots.default) {
        this.$slots.default[0].componentInstance.removeAndValidate()
      }
    },
    triggerResizeChild () {
      this.$children.forEach((child) => {
        if (_.get(child, '.path')) {
          child.resize()
        }
      })
    },
    getWrappedElement() {
      return this.$refs.content?.children?.[0]?.__vue__ ?? {}
    },
    toggleOverlay (val) {
      this.showEditingOverlay = val

      if(this.showEditingOverlay === false) {
        const wrappedElement = this.getWrappedElement()

        if(wrappedElement?.name === 'Draft' ) {
          wrappedElement.focusAndEditFroalaText?.()
        }
      }
    },
    targetChildElement (e) {
      e.stopPropagation()
      e.preventDefault()
      const wrapperElement = this.getWrappedElement()
      if(wrapperElement) {
        this.mutateTargeted(wrapperElement)
      }
    },
    getChildComponentTag () {
      return this.$slots?.default?.[0]?.componentOptions?.tag;
    },
    gridItemWrapperMouseEnter(e) {
      // If the child component is selected and if child component is SubGridContainer,
      // we want to hide overlay else we want to show edit overlay
      if(!this.selectedChild && this.childComponentTag !== "sub-grid-container") {
        this.toggleOverlay(true);
      } else {
        this.toggleOverlay(false);
      }
    },
  },
})
</script>

<style lang="scss">
@import "@/scss/utils";
.cvt-editor-text-overflow {
  text-overflow: ellipsis;
  overflow: auto;
}
.cvt-editor-grid-item-wrapper {
  transition: all 0.5s;

  .grid-builder & {
    .cvt-editor-text,
    .cvt-editor-divider {
      border: 2px dotted transparent;
    }
  }

  &:hover {
    .show-overlay {
      .editable-element-overlay {
        display: flex;
      }
      display: flex !important;
    }

    & > .grid-item-wrapper-content-wrapper {
      > .grid-item-actions {
          display: flex;
          transition: all 0.2s ease-in-out;

          &:hover {
            background-color: $dark-blue-3;
            width: 26px;
            height: 26px;

            .ui-draggable-handle {
              transition: font-size 0.2s ease-in-out;

              &.move-icon {
                font-size: 16px;
              }
            }
          }
      }
    }

    .grid-builder & {
      .cvt-editor-text,
      .cvt-editor-divider {
        border: 2px dotted #8A8E97;
      }
    }
  }

  .hide-overlay {
    .editable-element-overlay {
      display: none;
    }
    display: none !important;
  }

  .editable-element-overlay {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    z-index: 2;
    & > button {
      border: 1px solid #5d62ab;
      padding: 12px 12px;
    }
  }

  .overlayable-element {
    padding: 1px;
  }

  & > .grid-item-wrapper-content-wrapper {
    position: unset !important;
    > .grid-item-actions {
      position: absolute;
      display: none;
      align-items: center;
      justify-content: center;
      background-color: $dark-blue-2;
      color: white;
      z-index: 3;
      width: 20px;
      height: 20px;
      cursor: grab;

      top: 3px;
      left: 3px;
      border-radius: 4px;
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
      border: 1px solid $dark-blue-3;

      &:active {
        cursor: grabbing;
      }

      .ui-draggable-handle {
        width: 100%;
        height: 100%;
        text-align: center;

        display: flex;
        justify-content: center;
        align-items: center;
        transition: font-size 0.2s ease-in-out;

        &.move-icon {
          font-size: 12px;
        }
      }
    }
  }

  &[data-is-child-trageted="true"] {
    box-shadow: 0 0 0 2px $light-blue, 0 2px 5px rgba(0, 0, 0, 0.4);
  }

  &[data-is-child-selected="true"] {
    box-shadow: 0 0 0 2px $dark-blue-2, 0 2px 5px rgba(0, 0, 0, 0.4);
  }
}


.cvt-editor-grid-item-wrapper[gs-w="1"],
.cvt-editor-grid-item-wrapper[gs-h="1"],
.cvt-editor-grid-item-wrapper[gs-w="2"],
.cvt-editor-grid-item-wrapper[gs-h="2"] {
  .editable-element-edit-button {
    & > span {
      display: none;
    }
  }
}

.grid-stack-item > .grid-stack-item-content {
  inset: 1px !important;
}

.ui-draggable-dragging,
.ui-resizable-resizing {
  box-shadow: 0px 10px 10px rgba(93, 98, 171, 0.26), 0px 14px 28px rgba(93, 98, 171, 0.25);

  & .overlayable-element {
    opacity: 0;
  }
}

.grid-stack > .grid-stack-item {
  > .ui-resizable-handle {
    width: 10px;
    height: 10px;
    background: white;
    border: 1.5px solid $dark-blue-2;
    z-index: 3 !important;
  }

  > .ui-resizable-handle:hover {
    width: 14px;
    height: 14px;
    background: $light-blue;
    transition: all 0.1s linear;

    &.ui-resizable-n {
      top: -7.5px;
      left: calc(50% - 7.5px);
      right: calc(50% - 7.5px);
    }

    &.ui-resizable-s {
      bottom: -7.5px;
      left: calc(50% - 7.5px);
      right: calc(50% - 7.5px);
    }

    &.ui-resizable-e {
      right: -7.5px !important;
      top: calc(50% - 7.5px);
      bottom: calc(50% - 7.5px);
    }

    &.ui-resizable-w {
      left: -7.5px !important;
      top: calc(50% - 7.5px);
      bottom: calc(50% - 7.5px);
    }

    &.ui-resizable-ne {
      top: -7.5px;
      right: -7.5px !important;
    }

    &.ui-resizable-nw {
      top: -7.5px;
      left: -7.5px !important;
    }

    &.ui-resizable-se {
      bottom: -7.5px !important;
      right: -7.5px !important;
    }

    &.ui-resizable-sw {
      bottom: -7.5px !important;
      left: -7.5px !important;
    }
  }

  > .ui-resizable-n {
    top: -6px;
    left: calc(50% - 6px);
    right: calc(50% - 6px);
  }

  > .ui-resizable-s {
    bottom: -6px;
    left: calc(50% - 6px);
    right: calc(50% - 6px);
  }

  > .ui-resizable-w {
    left: -6px !important;
    top: calc(50% - 6px);
    bottom: calc(50% - 6px);
  }

  > .ui-resizable-e {
    right: -6px !important;
    top: calc(50% - 6px);
    bottom: calc(50% - 6px);
  }

  > .ui-resizable-ne {
    top: -6px;
    right: -6px !important;
  }

  > .ui-resizable-nw {
    top: -6px;
    left: -6px !important;
  }

  > .ui-resizable-se {
    bottom: -6px !important;
    right: -6px !important;
    transform: unset;
  }

  > .ui-resizable-sw {
    bottom: -6px !important;
    left: -6px !important;
    transform: unset;
  }
}

</style>
