import {VDomCommands} from "./VDomCommand";
import {Command} from "./Command";
import {CommandEventArgs} from "../../../types";


export class BulkPropertyChangeCommand extends VDomCommands {
    private readonly _changes: {[id: string] : Command; };

    constructor(gridContainerVNodeId: string) {
        super(gridContainerVNodeId);
        this._changes = {}
    }

    get$emitData() : CommandEventArgs {
        return  {name: 'BulkPropertyChangeCommand', forceBuild: false}
    }

    get hasChanges (): boolean {
        return Object.keys(this._changes).length > 0;
    }

    async execute(): Promise<any> {
        for (const [key, command] of Object.entries(this._changes)) {
            await command.execute()
        }
    }

    async unExecute(): Promise<any> {
        for (const [key, command] of Object.entries(this._changes)) {
            await command.unExecute()
        }
    }

    addCommand(vNodeID:string, command: Command): void {
        if (command.hasChanges) {
            this._changes[vNodeID] = command
        }
    }
}