import * as _ from 'lodash'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'

function rangeFormatFactory() {
  return {
    bold: false,
    italic: false,
    underline: false,
    strike: false,
    size: null,
    link: null,
  }
}

export default {
  state: {
    editing: false,
    range: { index: 0, length: 0 },
    rangeFormat: rangeFormatFactory(),
  },
  getters: {
    [getters.DRAFT_IS_EDITING]({ editing }) {
      return editing
    },
    [getters.DRAFT_RANGE]({ range }) {
      return range
    },
    [getters.DRAFT_RANGE_FORMAT]({ rangeFormat }) {
      return rangeFormat
    },
  },
  mutations: {
    [mutations.DRAFT_DECLARE_EDITING](state, editing) {
      state.editing = editing
    },
    [mutations.DRAFT_UPDATE_RANGE](state, range) {
      state.range = range
    },
    [mutations.DRAFT_UPDATE_RANGE_FORMAT](state, rangeFormat) {
      state.rangeFormat = {
        ...rangeFormatFactory(),
        ...rangeFormat,
      }
    },
  },
  actions: {},
}
