<template>
  <div class="froala-root">
    <!-- Any tag modification will change how froala data is saved. See saveWithHtml method -->
    <!-- eslint-disable vue/no-v-html -->
    <div class="froala-editor" v-html="content" />
  </div>
</template>

<script>
import FroalaEditor from 'froala-editor/js/froala_editor.min'

const paragraphFontStyles = [
  'font-style-title',
  'font-style-subtitle',
  'font-style-heading',
  'font-style-alternativeHeading',
  'font-style-lead',
  'font-style-normalText',
  'font-style-blockquote',
]

export default {
  name: "InlineEditor",
  props: {
    content: {
      type: String,
      required: true
    },
    config: { type: Object, required: true }
  },
  data() {
    return {
      defaultEventConfig: {
        'save.before': (html) => {
          this.saveWithHtml(html)
          return false
        },
        'commands.after': function(cmd, param1, param2) {
          const isClearFormatting = cmd === 'clearFormatting'

          if (isClearFormatting) {
            let paragraphString = this.selection.element()
            for (let style of paragraphFontStyles) {
              if (style === 'font-style-normalText') {
                if (!this?.node?.hasClass(paragraphString, style)) {
                  this.paragraphStyle.apply(style)
                }
              } else {
                if (this?.node?.hasClass(paragraphString, style)) {
                  // check if it contains it, then remove
                  this.paragraphStyle.apply(style)
                }
              }
            }
          }

          if (cmd === 'customParagraphStyle') {
            this.save.save()
          }
        },
      },
      defaultSaveConfig: {
        saveInterval: 1000,
        // Set the save param.
        saveParam: 'content',
        // Set the save URL.
        saveURL: '#',
        // HTTP request type.
        saveMethod: 'POST',
        // Additional save params.
        saveParams: {id: 'my_editor'},
      },
      editor: null,
      currentConfig: null,
      editorCreated: false
    }
  },
  mounted() {
    this.createEditor()
  },
  destroyed() {
  },
  methods: {
    shutdown () {
      if (this.$el) {
        this.editor.destroy()
      }
    },
    createEditor () {
      if (this.editorCreated) return

      this.currentConfig = Object.assign(this.defaultSaveConfig, this.config)
      this.currentConfig.events = Object.assign(this.defaultEventConfig, this.currentConfig.events || {})

      this.editor = new FroalaEditor(this.$el, this.currentConfig)
      this.editorCreated = true
    },
    saveWithHtml (html) {
      let htmlStr = html
      if (htmlStr.search('<div class="froala-editor">') >= 0) {
        htmlStr = htmlStr.replace('<div class="froala-editor">', '') // replaces the first occurance
        htmlStr = htmlStr.replace(/<\/div>$/, '') // replaces the last </div> tag in the HTML string
      }

      // this makes sure no empty string is saved in firebase which makes froala element non-editable
      if (htmlStr === '') {
        if (this.contentsHTML !== '') {
          htmlStr = this.contentsHTML
        } else {
          htmlStr = '<br>'
        }
      }
      this.$emit('change', htmlStr)
    }
  }
};
</script>

<style scoped>

</style>