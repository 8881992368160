<template>
  <button
    :class="btnClasses"
    :disabled="site.locked"
    @click.prevent.stop="publishSite(site, $event)"
  >
    {{ msg }} <i
      v-show="site.locked"
      class="fa fa-circle-o-notch fa-spin"
    />
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as actions from '../store/modules/actions'
import * as getters from '../store/modules/getters'

export default {
  props: {
    filled: Boolean,
    className: {
      type: String,
      default() {
        return ''
      }
    },
    text: {
      type: String,
      default: 'Publish'
    }
  },
  computed: {
    ...mapGetters({
      site: getters.SITE_GET_DATA,
      isAdmin: getters.AUTH_GET_USER_IS_ADMIN
    }),
    msg () {
      return this.site.locked ? 'Publishing' : this.text
    },
    btnClasses () {
      return {
        [this.className]: true,
        disabled: this.site.locked
      }
    }
  },
  methods: {
    ...mapActions({
      publish: actions.SITE_PUBLISH
    }),
    async publishSite (site, event) {
      try {
        const { errors } = await this.publish(site, event)
        this.$emit('published')
        if (errors && errors.length) {
          this.$emit('error', errors)
        }
      } catch (err) {
        console.error('failed to publish', err)
        this.$emit('published')
      }
    }
  }
}
</script>
