
<div
  v-if="isAdmin"
  class="container mt-5 pt-5"
>
  <div class="playground-banner">
    <cvt-alert
      color="danger"
      :close-icon="true"
    >
      <h4>You're currently logged in as orgID: <strong>{{ admin.orgID }}</strong> projectID: <strong>{{ admin.projectID }}</strong></h4>
    </cvt-alert>
  </div>
  <div class="item-list">
    <play-alert />
    <play-button />
    <play-dropdown />
    <play-pagination />
    <play-progress />
    <play-collapse />
    <play-dialog />
    <play-input />
    <play-item-list />
    <play-nav />
    <play-popover />
    <play-radio-group />
    <play-select />
    <play-slider />
    <play-switch />
    <play-table />
    <play-tab />
    <play-tooltip />
    <play-upload />
  </div>
</div>
