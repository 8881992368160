
<div data-identity="AddDescription" class="admin-description-wrapper">
  <h4 class="admin-label">
    {{ $t('launch.tab.control.heading.add_description') }}
  </h4>
  <cvt-input
    id="description-froala-editor"
    type="textarea"
    name="description"
    :clearable="false"
    :show-input-border="false"
    :value="description"
    @input="(event) => { $emit('siteDescriptionChange', event) }"
  />
</div>
