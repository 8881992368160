import {Command} from "./Command";
import {PAGE_ENGINE_VDOM_UPDATE} from "../plugins/events";
import {hub} from "../plugins/EventHub";
import {
    CommandEventArgs,
    VueGridStackComponent,
    VueHTMLElement,
    FBVNodeComponent, PageEngineComponent
} from "../../../types";

type VDomComponent = FBVNodeComponent | VueGridStackComponent| PageEngineComponent


export class VDomCommands extends Command {
    protected _vNodeIdVueComponent_?: VDomComponent;
    protected readonly _vNodeId: string;

    constructor(vNodeId: string) {
        super();
        this._vNodeId = vNodeId
        this._vNodeIdVueComponent_ = undefined
        this.getVueComponent(false)
    }

    getVueComponent (raiseException:boolean = true) :VDomComponent | undefined{
        if (!this._vNodeIdVueComponent_) {
            this._vNodeIdVueComponent_ = this.getVueComponentById(this._vNodeId, raiseException)
        }
        return this._vNodeIdVueComponent_
    }

    getVueComponentById(vNodeId: string, raiseException:boolean = true): VDomComponent | undefined {
        const _vNodeComponent = (document.getElementById(vNodeId) as VueHTMLElement)?.__vue__ || null
        if (_vNodeComponent === null && raiseException) {
            throw 'Element Not Found'
        }
        return _vNodeComponent
    }

    getVueComponentByAttr(vNodeId: string, raiseException: boolean=true): VDomComponent | undefined {
        let _vNodeComponent = undefined
        // eslint-disable-next-line no-undef
        const elements: NodeListOf<VueHTMLElement> = document.querySelectorAll(
            `[data-vnode-id="${vNodeId}"]`
        )
        if (elements.length > 0) {
            _vNodeComponent = elements[0].__vue__ || undefined
        }
        if (!_vNodeComponent && raiseException) {
            throw 'Element Not Found'
        }
        return _vNodeComponent
    }

    get$emitData(): CommandEventArgs {
        return  {name: 'VDomCommands', forceBuild: false}
    }

    updateVDom(action: object) {
        hub.$emit(PAGE_ENGINE_VDOM_UPDATE, {...this.get$emitData(), action: action})
        this._vNodeIdVueComponent_ = undefined
    }

    scrollToView() {
        this.getVueComponent()?.$el.scrollIntoView({
            behavior: 'smooth',
        })
    }
}