
<cvt-select
  mode="dropdown"
  :value="selectedPage"
  :label="$t('element.property.form.option.funnel.label')"
  :placeholder="$t('element.property.form.option.funnel.label')"
  :background-mode="mode"
  @input="(value) => change(value)"
>
  <option
    v-for="page in pages"
    :key="page.id"
    :value="page.id"
    v-text="page.pageName"
  />
</cvt-select>
