
<div>
  <div>
    <div>
      <cvt-admin-left-nav-bar
        style="top: 64px"
        usage="adminBranding"
        initial-active-id="branding"
        :mode="mode"
      >
      </cvt-admin-left-nav-bar>
    </div>
    <div style="position: absolute; left: 280px; top: 64px">
      Branding Data is displayed here
    </div>
  </div>
</div>
