export default {
  name: 'AddVerifiedSeal',
  props: {
    verifiedSealEnabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mode: 'LIGHT',
    }
  },
  methods: {
    changeVerifiedSeal (status) {
      if(status === 'Active') {
        this.$emit('switchToggle', { value: true })
      } 
      else if(status === 'Inactive'){
        this.$emit('switchToggle', { value: false })
      }
    }
  }
}