import anime from 'animejs'

export default {
  props: {
    enter: {
      type: Object,
      required: true
    },
    leave: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: 'out-in',
      validate (value) {
        return ['out-in', 'in-out'].includes(value)
      }
    },
    beforeEnter: {
      type: Function,
      default () {
        return () => {}
      }
    }
  },
  methods: {
    onEnter (targets, done) {
      anime({
        targets,
        ...this.enter
      }).finished.then(done)
    },
    onLeave (targets, done) {
      anime({
        targets,
        ...this.leave
      }).finished.then(done)
    }
  }
}
