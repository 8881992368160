<template>

    <span>
      
        <div class="nestedInnerItem">
          <cvt-select
            :value="borderStyle"
            mode="dropdown"
            :background-mode="mode"
            :floating-label="false"
            :label="$t(&quot;elements.properties.outline.style&quot;)"
            @input="(value) => selectAction(value, 'borderStyle')"
          >
            <option
              v-for="style in borderStyles"
              :key="style"
              :value="style"
              v-text="style"
            />
          </cvt-select>
          <div style="margin: 5px;" />
          <cvt-input
            v-model="borderColor"
            style="width: max-content;"
            type="text"
            :clearable="false"
            :label="$t(&quot;elements.properties.outline.color&quot;)"
          />
        </div>

        <div class="divider mt-2" />

        <div class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t("elements.properties.outline.width") }}
          </div>
          <div class="col-12">
            <cvt-slider
              id="slider-default"
              :tooltips-enabled="true"
              :step-marks-enabled="true"
              class="slider"
              :min="1"
              :max="10"
              :value="borderWidth"
              :step="1"
              @change="borderWidthChange"
            />
          </div>
        </div>

    </span>

</template>

<script>
import * as _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import * as actions from '../../../store/modules/actions'
import * as getters from '../../../store/modules/getters'

  
export default {
  props: {
    mode: {
      type: String,
      default: 'LIGHT'
    },
  },

  data () {
    return {
      borderStyles: [
        'none',
        'solid',
        'dashed',
        'dotted',
        'double',
        'groove'
      ],
      borderWidth: 1,

    }
  },

 computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED
    }),
    borderStyle: {
      get () {
        return this.selected.borderStyle || 'none'
      },
      set: _.throttle(function (value) {
        return this.updateProp({ key: 'borderStyle', value })
      }, 300)
    },
    borderColor: {
      get () {
        return this.selected.borderColor
      },
      set: _.throttle(function ({ target }) {
        return this.updateProp({ key: 'borderColor', value: target.value })
      }, 300)
    },
  },
  
  created () {
    this.borderWidth = this.selected.borderWidth || 1;
  },

  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP
    }),
    selectAction (value, computedProperty) {
      if (computedProperty === 'borderStyle') { this.borderStyle = value }
    },
    borderWidthChange: _.throttle(function (value) {
      this.borderWidth = value
      return this.updateProp({ key: 'borderWidth', value })
    }, 300),
  }
}
</script>
