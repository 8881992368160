<template>
  <div
    :id="vnodeId"
    :data-vnode-id="`${vnodeId}`" :class="containerClasses"
    @click="select"
    @mouseover="target">
    <card-item-control
      v-for="(item, idx) in cardItems"
      :key="item.id"
      :class="stylesheet.classes.cardItem"
      :layout="layout"
      :layout-view="layoutView"
      :index="idx"
      v-bind="item"
      :border-radius="itemBorderRadius(item.borderRadius)"
      :border-style="itemBorderStyle(item.borderStyle)"
      :border-color="itemBorderColor(item.borderColor)"
      :border-width="itemBorderWidth(item.borderWidth)"
      :shadow="itemShadow(item.shadow)"
      :item-styles="itemStyles"
    />
  </div>
</template>

<script>
import ContainerAbstractTemplate from '@/js/components/v3/editor/nodes/abstracts/ContainerAbstractTemplate.vue'
import CardItemControl from "@/js/components/v3/editor/nodes/card/CardItemControl.vue";
import FbVNode from "@/js/components/base/FbVNode.vue";
import FirebaseNodeV3 from "@/js/components/v3/base/FirebaseNode";
import { mapGetters } from "vuex";
import * as getters from "@/js/store/modules/getters";


export default FbVNode.extend({
  name: "CCard",
  mixins: [FirebaseNodeV3],
  components: {
    CardItemControl,
    ContainerAbstractTemplate
  },
  props: {
    offset: Number,
    flexAlignment: Object,
    borderRadius: Number,
    borderWidth: Number,
    borderColor: String,
    borderStyle: String,
    borderPosition: Object,
    shadow: {
      type: String,
      default: 'shadow-none',
    },
    cardItems: {
      required: true,
      default: () => []
    },
    layout: {
      type: String,
      required: true
    },
    layoutGap: {
      type: Number,
      required: true
    },
    layoutWrap: {
      type: String,
      required: true
    },
    layoutSpacing: {
      type: String,
      required: true
    },
    layoutView: {
      type: String,
      required: true
    },
    itemStyles: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      name: 'CCard'
    }
  },
  computed: {
    ...mapGetters({
      viewMode: getters.VIEW_MODE
    }),
    containerClasses() {
      return {
        [this.classes]: true,
        [this.stylesheet.classes.cardContainer]: true,
        ...this.flexAlignmentClasses,
      }
    },
    styles() {
      let cardItemStyle = {}
      let extraStyle = {flexWrap: this.layoutWrap, justifyContent: this.layoutSpacing, flexDirection: 'unset'}
      if (this.viewMode === 'phone' && this.layoutView !== 'list') {
        extraStyle.flexWrap = 'wrap'
      }

      if (this.layoutView === 'list') {
        extraStyle.flexDirection = 'column'
      }

      if (this.layoutView === 'list' && this.layoutWrap !== 'wrap') {
        cardItemStyle = {
          flex: "0 0 100%",
          maxWidth: "100%",
          width: "100% !important"
        }
      }

      return {
        cardContainer: {
          display: 'flex',
          gap: `${this.layoutGap}px`,
          width: "100%",
          margin: '0.1rem',
          ...extraStyle,

          '@global': {
            '.vnode--selected': {
              cursor: 'pointer',
              outline: '#3c79e0 solid 3px'
            }
          }
        },
        cardItem: {
          ...cardItemStyle,
        }
      }
    }
  },
  methods: {
    itemShadow(shadow) {
      return shadow || this.itemStyles.shadow
    },
    itemBorderWidth(borderWidth) {
      return borderWidth || this.itemStyles.borderWidth
    },
    itemBorderRadius(borderRadius) {
      return borderRadius || borderRadius === 0 ? borderRadius : this.itemStyles.borderRadius
    },
    itemBorderColor(borderColor) {
      return borderColor || borderColor === '' ? borderColor : this.itemStyles.borderColor
    },
    itemBorderStyle(borderStyle) {
      return borderStyle && borderStyle !== '' ? borderStyle : this.itemStyles.borderStyle
    },
  }
});
</script>

<style scoped>

</style>