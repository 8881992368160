<template>
  <transition :mode="mode" :css="false" @enter="onEnter" @leave="onLeave" @before-enter="beforeEnter">
    <slot></slot>
  </transition>
</template>

<script>
  import anime from './anime'
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Anime',
    mixins: [anime]
  }
</script>
