<template>
  <div>
    <form @submit.prevent="save">
      <div v-show="currentStep === 'general'">
        <div class="form-group">
          <label for="page-name">{{ $t('pagemanager.label.page.name') }}</label>
          <cvt-input
            type="text"
            :value="page.pageName"
            @input="(e) => updatePageSetting('pageName', e)"
          />
        </div>
         <div class="form-group">
          <label for="page-name">{{ $t('pagemanager.label.slug.text') }}</label>
          <cvt-input
           class="mb-3"
            type="text"
            :placeholder="$t('pagemanager.input.text.pagename')"
            :disabled="page.isIndex"
            :value="page.name"
            @input="(e) => updatePageSetting('name', e)"
          />
          {{ $t('pagemanager.label.slug.text') }}
          <strong class="text-muted">
            <span class="help">/</span>
            <span class="help" v-text="pageSlug" />
          </strong>
          <cvt-alert
            v-if="OrgName !== orgNameFreshWork"
            class="w-100"
            color="info"
            icon="exclamation-triangle"
          >
            {{ $t('pagemanager.slug.help.message') }}
          </cvt-alert>
        </div>
        <div class="form-group">
          <label for="page-name">{{
            $t('pagemanager.input.placeholder.pagename')
          }}</label>
          <cvt-input
            type="textarea"
            :placeholder="$t('pagemanager.label.seo')"
            :value="page.description"
            @input="(e) => updatePageSetting('description', e)"
          />
        </div>
        <div class="form-group">
          <input
            v-if="changeToggleToCheckBox"
            id="allowRobotsOnThisPageId"
            v-model="allowRobotsOnThisPage"
            type="checkbox"
          />
          <label class="ml-1" for="page-meta">{{
            $t('pagemanager.textarea.placeholder.description')
          }}</label>
          <cvt-switch
            v-if="!changeToggleToCheckBox"
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="allowRobotsOnThisPage"
            :mode="mode"
            @switchToggle="() => onSwitchToggle('allowRobotsOnThisPage')"
          />
          <cvt-button class="mt-2" color="light" text="Add Script" @click="AddScriptAction" />
        </div>
      </div>
      <div v-show="currentStep === 'script'">
        <div class="">
          <cvt-button color="light" text="back" @click="backAction" />
          <hr />
          <PageTracking />
        </div>
      </div>
    </form>

    <div style="width:370px;bottom:40px;" class="row justify-content-between position-absolute px-2">
      <cvt-button
      class="px-5"
        color="light"
        :loading="saving"
        :outlined="true"
        :text="$t('element.property.cta.cancel')"
        @click="handleClose"
      />
      <cvt-button
      class="px-5"
        :loading="saving"
        :text="$t('element.property.cta.save')"
        :special-style="saveBtnStyle"
        @click="saveAndClose"
      />
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash'
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  LEFT_SIDEBAR_FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../../storybook/components/constants'
import PageTracking from '@/js/components/editor/left-side-menu/PageTracking.vue'
import { cvtRouteNodes } from '@/js/utils/routes'

export default {
  components: { PageTracking },
   props: {
    handleClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      mode: 'LIGHT',
      orgNameFreshWork: LEFT_SIDEBAR_FRESHWORKS,
      pageArgs: null,
      removing: false,
      currentStep: 'general',
    }
  },
  computed: {
    ...mapState('pageManager', {
      page: 'page',
      visible: 'settingsVisible',
      deleteVisible: 'deleteVisible',
      saving: 'saving',
    }),
    ...mapState('sitePreferences', {
      allowRobots: ({ preferences }) =>
        _.get(preferences, 'seoSettings.allowRobots'),
    }),
    ...mapState('globalTheme', {
      changeToggleToCheckBox: ({ globalTheme }) =>
        globalTheme.changeToggleToCheckBox !== undefined
          ? globalTheme.changeToggleToCheckBox
          : false,
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
    }),
    ...mapState({
      site: ({ site }) => _.get(site, 'site'),
      pages: ({ site }) =>
        _.chain(site)
          .get('site.pages')
          .orderBy(['isIndex', 'createdAt'], ['desc', 'asc'])
          .value(),
      indexPage: ({ site }) =>
        _.chain(site).get('site.pages').find({ isIndex: true }).value(),
    }),
    pageSlug() {
      return (
        this.page &&
        this.page.name &&
        this.page.name
          .replace(/\W{1,}/g, '-')
          .toLowerCase()
          .trim()
      )
    },
    closeBtnBoolean() {
      return this.OrgName !== LEFT_SIDEBAR_FRESHWORKS
    },
    saveBtnStyle() {
      return this.OrgName !== LEFT_SIDEBAR_FRESHWORKS ? '' : 'dark'
    },
    allowRobotsOnThisPage: {
      get() {
        return _.get(this.allowRobots, ['pages', this.page.id], false)
      },
      set(value = false) {
        return this.saveSitePreferences({
          key: `seoSettings/allowRobots/pages/${this.page.id}`,
          value,
        })
      },
    },
  },
  methods: {
    ...mapMutations('pageManager', {
      update: 'update',
      closeDeleteDialog: 'closeDeleteDialog',
    }),
    ...mapActions('pageManager', {
      save: 'save',
      remove: 'remove',
    }),
    ...mapActions('sitePreferences', {
      saveSitePreferences: 'save',
    }),
    backAction(e) {
      e.preventDefault()
      e.stopPropagation()
      this.currentStep = 'general'
    },
    AddScriptAction(e) {
      e.preventDefault()
      e.stopPropagation()
      this.currentStep = 'script'
    },
    async saveAndClose() {
      await this.save()
      this.handleClose()
    },

    updatePageSetting(key, { target }) {
      this.update({
        [key]: target.value,
      })
    },

    onSwitchToggle(property) {
      property == 'allowRobotsOnThisPage'
        ? (this.allowRobotsOnThisPage = !this.allowRobotsOnThisPage)
        : ''
    },

    async proceedPageRemoval() {
      this.edit(this.indexPage)
      this.removing = true
      await this.remove({ ...this.page })

      this.$message({
        type: 'success',
        message: this.$t('pagemanager.cvt_dialog.delete.success.message'),
      })
      this.removing = false
      this.abortPageRemoval()
    },

    abortPageRemoval() {
      this.removing = false
      this.closeDeleteDialog()
    },

    edit({ id }) {
      this.$router.push({
        page: cvtRouteNodes.editorDesignPage,
        params: {
          ...this.$route.params,
          pageId: id,
        },
      })
      window.location.reload()
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/variables';
</style>
