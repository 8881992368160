
<cvt-collapse
  :accordion="true"
  :theme="themeMode"
  :prepend-chevron="prependChevron"
>
  <!-- <cvt-collapse-item
    name="Products"
    :title="$t('elements.properties.checkout.products')"
    :initial-active="true"
  >
  </cvt-collapse-item> -->

  <cvt-collapse-item
    name="Product Display Options"
    :title="$t('elements.properties.checkout.product_display_options')"
    :initial-active="true"
  >
    <div class="row no-gutters">

      <div class="row">
        <div class="col">
          <strong>{{ $t("elements.properties.checkout.product.thumbnail") }}</strong>
          <!-- <strong>Thumbnail</strong> -->
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="showThumbnail"
            @switchToggle="(state) => updateCheckoutProp('showThumbnail', state)"
          />
        </div>
      </div>

      <div class="divider" />

      <div class="row">
        <div class="col">
          <strong>{{ $t("elements.properties.checkout.product.title") }}</strong>
          <!-- <strong>Title</strong> -->
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="showTitle"
            @switchToggle="(state) => updateCheckoutProp('showTitle', state)"
          />
        </div>
      </div>

      <div class="divider" />

      <div class="row">
        <div class="col">
          <strong>{{ $t("elements.properties.checkout.product.benefits") }}</strong>
          <!-- <strong>Benefits</strong> -->
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="showBenefits"
            @switchToggle="(state) => updateCheckoutProp('showBenefits', state)"
          />
        </div>
      </div>
      
      <div class="divider" />

      <div class="row">
        <div class="col">
          <strong>{{ $t("elements.properties.checkout.product.savings") }}</strong>
          <!-- <strong>Savings</strong> -->
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="showCost"
            @switchToggle="(state) => updateCheckoutProp('showCost', state)"
          />
        </div>
      </div>

      <div class="divider" />
      
      <div class="row">
        <div class="col">
          <strong>{{ $t("elements.properties.checkout.product.cost") }}</strong>
          <!-- <strong>Cost</strong> -->
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="showSavings"
            @switchToggle="(state) => updateCheckoutProp('showSavings', state)"
          />
        </div>
      </div>

    </div>
  </cvt-collapse-item>

  <cvt-collapse-item
    :title="$t('elements.properties.spacing.label')"
    name="spacing"
  >
    <spacing-ctrl></spacing-ctrl>
  </cvt-collapse-item>

</cvt-collapse>
