import { styled, VueEmotion } from '@egoist/vue-emotion'
import VueI18n from 'vue-i18n'
import { mapGetters, mapState } from '../../../builder/node_modules/vuex'
import * as getters from '../../../builder/src/js/store/modules/getters'
import Vue from '../../lib/utils/vue'
import CvtDropdown from '../molecules/dropdown/Dropdown'
import CvtInput from '../molecules/Input'

Vue.use(VueEmotion)
Vue.use(VueI18n)

let Spacing, FontFamily, backgroundColor, inputConfig

const getSearchBar = () => styled('div')`
  padding: ${Spacing.SPACING_8}px;
  display: flex;
  justify-content: space-between;
  font-family: ${FontFamily.DEFAULT};
  ${(props) => {
    if (props.isTabsUiEnabled) return
    let weight = 'WHITE'
    weight = props.mode === 'DARK' ? 700 : 'WHITE'
    let background = backgroundColor(
      { themeing: props.mode === 'DARK' ? 'dark' : 'light' },
      weight,
    )
    return background
  }}
  ${(props) => {
    return props.chooseTemplateTenantStyles?.searchBarStyles || ''
  }}
`

const getWideFlexDiv = () => styled('div')`
  ${(props) => {
    return props.isTabsUiEnabled
      ? `
  width: 500px;
  `
      : `
  display: flex;
  flex: 2;
  align-items: center;
  `
  }}
`

const getRightDiv = () => styled('div')`
  ${(props) => {
    return props.isTabsUiEnabled
      ? ``
      : `
  margin: auto;
  `
  }}
  display: flex;
`

const getPublishCheckContainer = () => styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${Spacing.SPACING_16}px;
`

const getPublishCheck = () => styled('input')``

const getDropdownContainer = () => styled('div')`
  width: ${Spacing.SPACING_128 + Spacing.SPACING_32}px;
`

const getDropdown = () => styled(CvtDropdown)``

const getCvtInput = () => styled(CvtInput)``

const getPublishTextWrap = () => styled('span')`
  padding: 0px ${Spacing.SPACING_8}px 0px ${Spacing.SPACING_8}px;
`

export const CvtMarketplaceSearchSort = {
  name: 'CvtMarketplaceSearchSort',
  components: {
    CvtDropdown,
  },
  data() {
    return {
      selectedLabel: 'Sort By',
      btnTextChange: false,
    }
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
      required: false,
    },
    defaultSearch: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    ...mapGetters('globalTheme', {
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
    }),
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
      sortByBtnConfig: ({ globalButtonConfig }) =>
        globalButtonConfig?.locations?.marketPlaceSearchSortComponent?.locations
          ?.sortByBtn,
      inputConfig: ({ globalTheme }) =>
        globalTheme.inputConfig ? globalTheme.inputConfig : '',
    }),
    chooseTemplateTenantStyles() {
      return this.config?.MARKETPLACE_CHOOSE_A_TEMPLATE_CONFIG?.customStyles
    },
    isTabsUiEnabled() {
      if (!this.config) return
      return Boolean(this.config.CHOOSE_A_TEMPLATE_TABS_UI)
    },
  },
  created() {
    Spacing = this.Spacing
    FontFamily = this.FontFamily
    backgroundColor = this.backgroundColor
    inputConfig = this.inputConfig
  },
  methods: {
    triggerSearch(val) {
      this.$emit('searchDone', val)
    },
    triggerSort(val) {
      if (val != this.selectedLabel) {
        this.$emit('sortParameter', val, true)
        this.btnTextChange = true

        if (val === 'created') {
          this.selectedLabel = this.$t(
            'marketplace.template.search.date.created',
          )
        } else {
          this.selectedLabel = this.$t(
            'marketplace.template.search.date.updated',
          )
        }
      }
    },
    triggerPublish(val) {
      this.$emit('sortParameter', 'published', val)
    },
  },
  render: function (h) {
    const SearchBar = getSearchBar()
    const WideFlexDiv = getWideFlexDiv()
    const RightDiv = getRightDiv()
    const PublishCheckContainer = getPublishCheckContainer()
    const PublishCheck = getPublishCheck()
    const PublishTextWrap = getPublishTextWrap()
    const DropdownContainer = getDropdownContainer()
    const Dropdown = getDropdown()
    const CvtInput = getCvtInput()

    return (
      <SearchBar
        isTabsUiEnabled={this.isTabsUiEnabled}
        chooseTemplateTenantStyles={this.chooseTemplateTenantStyles}
      >
        <WideFlexDiv isTabsUiEnabled={this.isTabsUiEnabled}>
          <CvtInput
            value={this.defaultSearch}
            onClear={() => {
              this.triggerSearch('')
            }}
            onInput={(e) => {
              this.inputQuery = e.target.value
              if (!this.inputQuery) {
                this.triggerSearch('')
              }
            }}
            onEnter={() => this.triggerSearch(this.inputQuery)}
            size="small"
            noLabel={true}
            floatingLabel={true}
            suffixIcon="search"
            mode={this.mode}
            color="black"
            placeholder={this.$t('marketplace.template.search.label')}
            inputConfig={this.inputConfig}
          ></CvtInput>
        </WideFlexDiv>

        <RightDiv isTabsUiEnabled={this.isTabsUiEnabled}>
          <PublishCheckContainer>
            <PublishCheck
              type="checkbox"
              onChange={(e) => {
                this.triggerPublish(e.target.checked)
              }}
            ></PublishCheck>
            <PublishTextWrap>Published</PublishTextWrap>
          </PublishCheckContainer>

          <DropdownContainer>
            <Dropdown
              bgColor="light"
              title={this.selectedLabel}
              clickAction={true}
              outlined={true}
              fullWidth={true}
              ondropDownClickAction={(e) => this.triggerSort(e)}
              btnState={this.sortByBtnConfig.state}
              btnTextChange={this.btnTextChange}
            >
              <option value="created">
                {this.$t('marketplace.template.search.date.created')}
              </option>
              <option value="lastUpdated">
                {this.$t('marketplace.template.search.date.updated')}
              </option>
            </Dropdown>
          </DropdownContainer>
        </RightDiv>
      </SearchBar>
    )
  },
}
