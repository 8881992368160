import Vue from 'vue'
import accounting from 'accounting'

Vue.filter('truncate', (str, maxLen) => {
  if (str.length > maxLen) {
    return str.slice(0, maxLen) + '...'
  }
  return str
})

Vue.filter('default', (str, fallback) => {
  return str || fallback
})

Vue.filter('currency', (amountInCents) => {
  return `$${accounting.format(amountInCents / 100)}`
})
