
<div
  ref="draggable"
  class="draggable mb-1"
  draggable="true"
  :class="draggableClasses"
  :style="draggableStyles"
  @dragstart="dragstart"
  @dragend="dragend"
  @dragenter.prevent="validate"
  @dragleave="reset"
  @dragover.prevent="dragover"
  @drop.prevent="dropped"
  @mousedown="mousedown"
  @click="click">

  <magic-line :active="highlightTop" side="top"></magic-line>
  <magic-line :active="highlightRight" side="right"></magic-line>
  <magic-line :active="highlightBottom" side="bottom"></magic-line>
  <magic-line :active="highlightLeft" side="left"></magic-line>

  <slot></slot>

</div>
