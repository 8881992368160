
<span>
  <div class="row mt-4">
    <div class="col">
      <strong> {{ $t("elements.properties.background.size") }} </strong>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <cvt-radio-group :key="backgroundSizeVal" :initial-value="backgroundSizeVal" orientation='horizontal' >
        <cvt-radio-button size="sm" value="auto" @radioClick="updateBackground">
          {{ $t("elements.properties.background.original") }}
        </cvt-radio-button>
        <cvt-radio-button size="sm" value="cover" @radioClick="updateBackground">
          {{ $t("elements.properties.background.cover") }}
        </cvt-radio-button>
        <cvt-radio-button size="sm" value="contain" @radioClick="updateBackground">
          {{ $t("elements.properties.background.box") }}
        </cvt-radio-button>
      </cvt-radio-group>
    </div>
  </div>

  <div v-if="OrgName !== orgNameFreshWork" class="divider"/>
  
  <div class="row mt-4">
    <div class="col">
      <strong> {{ $t("elements.properties.background.repeat") }} </strong>
    </div>
    <div class="col-3">
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="isRepeatOn"
          @switchToggle="() => repeatToggle()"
        ></cvt-switch>
    </div>
  </div>
</span>

