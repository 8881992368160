
<el-dialog :visible="visible" size="tiny" @close="onClose">
  <span slot="title" class="bookmark-block-title">
    Bookmark Page
  </span>
  <el-form
    ref="ruleForm"
    label-position="top"
    :model="pageData"
    :rules="rules"
    class="demo-ruleForm"
  >
    <el-form-item prop="pageName">
      <el-input
        v-model="pageData.pageName"
        class="col-12 mb-2"
        placeholder="Name the Page bookmark"
        autofocus
      />
    </el-form-item>
    <!-- <el-form-item prop="pageThumbnail">
      <el-input
        v-model="pageData.pageThumbnail"
        class="col-12 mb-2"
        placeholder="Url to thumbnail"
      />
    </el-form-item> -->
  </el-form>

  <div slot="footer" class="dialog-footer col-12">
    <div class="row justify-content-end">
      <el-button :disabled="isSaving" @click="onClose">{{
        $t('element.property.cta.cancel')
      }}</el-button>
      <el-button
        type="primary"
        :loading="isSaving"
        :disabled="false"
        @click="submitForm"
        >{{ $t('element.property.cta.confirm') }}</el-button
      >
    </div>
  </div>
</el-dialog>
