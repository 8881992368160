import { mapActions, mapGetters, mapState } from 'vuex'
import { CvtLeftSideBar } from '../../../../../../../storybook/components/organisms/left-sidebar/left-sidebar'
import PopupLauncher from '../../../../components/editor/left-side-menu/PopupLauncher.vue'
import * as actions from '../../../../store/modules/actions'
import * as getters from '../../../../store/modules/getters'
 import Gallery from '@/js/components/editor/left-side-menu/ImageGallery.vue'
//This pannel can come from the config, if we can have multiple options
const panels = [
  {
    page: 'popup',
    label: 'Popups',
    name: 'popups',
    icon: 'th-large',
    items: [],
  },
]

export default {
  components: {
    CvtLeftSideBar,
    PopupLauncher,
    Gallery
  },
  data() {
    return {
      panels: panels,
      mode: 'LIGHT',
    }
  },
  computed: {
    ...mapGetters({
      active: getters.LEFT_SIDE_MENU_ACTIVE_OPTION,
      config: getters.AUTH_GET_USER_CONFIG,
      popupBuilderOpen: getters.POPUP_BUILDER_OPEN,
    }),
    ...mapState('globalTheme', {
      SideBarLeft: ({ globalTheme }) =>
        globalTheme.SideBarLeft !== undefined ? globalTheme.SideBarLeft : true,
      OrgName: ({ globalTheme }) =>
        globalTheme.OrgName !== undefined ? globalTheme.OrgName : 'standard',
    }),
  },
  created() {},
  methods: {
    ...mapActions({
      leftSideMenuToggle: actions.LEFT_SIDE_MENU_TOGGLE,
    }),
    isActive(menu) {
      return {
        'left-side-bar__item--active': menu === this.active,
      }
    },
    toggle(menu) {
      if (menu) {
        this.leftSideMenuToggle({
          activate: menu,
        })
      } else {
        this.leftSideMenuToggle({
          open: false,
        })
      }
    },
  },
}
