
<cvt-collapse :accordion="true" :theme="mode === 'LIGHT' ? 'light' : 'dark'" :prepend-chevron="prependChevron">
  <cvt-collapse-item
    title="Style"
    name="style"
    :initial-active="true"
  >
    <div class="row">
      <div class="col mb-3 d-flex align-items-center text-capitalize">
        {{ $t("element.property.label.resize") }}
      </div>
      <viewport />
      <div class="col-12">
        <resize
          :size-key="sizeKey"
          :min="0"
          :step="1"
          :max="12"
          hide-label
        />
      </div>
    </div>

    <div class="divider mt-2">
      <div class="nestedInnerItem">
          <cvt-select 
              :value="borderStyle"
              mode="dropdown"
              :background-mode="mode"
              :floating-label="false"
              :label='$t("elements.properties.border.style")'
              @input = "(value) => selectAction(value, 'borderStyle')"
          >
              <option v-for="style in borderStyles" :key="style" :value="style" v-text="style">
              </option>
          </cvt-select>
          <div style="margin: 5px;"></div>
          <cvt-input
            v-model="borderColor"
            style="width: max-content;"
            type="text"
            :clearable="false"
            :label='$t("elements.properties.border.color")'
          />
      </div>
    </div>

    <div class="divider mt-2" />

    <div class="row">
      <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
        {{ $t("elements.properties.border.width") }}
      </div>
      <div class="col-12">
        <cvt-slider
          id="slider-default"
          :tooltips-enabled="true"
          :step-marks-enabled="true"
          class="slider"
          :min="1"
          :max="10"
          :value="borderWidth"
          :step="1"
          @change="onChange"
        />
      </div>
    </div>
    <div class="divider mt-2" />

    <div class="row">
      <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
        {{ $t("elements.properties.border.radius") }}
      </div>
      <div class="col-12">
        <resize
          size-key="borderRadius"
          :min="0"
          :step="5"
          :max="100"
          :label="$t('elements.properties.border.radius')"
          hide-label
        />
      </div>
    </div>
  </cvt-collapse-item>

  <cvt-collapse-item
    :title="$t('elements.properties.background.image')"
    name="bgImage"
  >
    <element-image-manager
      image-key="background/image"
      :hook="selected.updateBgSrc"
    />
  </cvt-collapse-item>

  <cvt-collapse-item
    :title="$t('elements.properties.background.color')"
    name="color"
  >
    <colors alpha :mode="mode"/>
  </cvt-collapse-item>

  <cvt-collapse-item
    :title="$t('elements.properties.spacing.label')"
    name="spacing"
  >
    <spacing-ctrl />
  </cvt-collapse-item>

  <cvt-collapse-item
    :title="$t('elements.properties.visibility.label')"
    name="visibility"
  >
    <visibility-ctrl :mode="mode"/>
  </cvt-collapse-item>

  <cvt-collapse-item
    :title="$t('elements.properties.alignment')"
    name="alignment"
  >
    <flex-align :mode="mode"/>
  </cvt-collapse-item>
</cvt-collapse>
