
<div
  :id="vnodeId"
  class="cvt-editor-buy-now-button"
  :class="classes"
  draggable="true"
  :data-vnode-id="vnodeId"
  @click="select"
  @mouseover="target"
  @contextmenu.prevent.stop="showContextMenu"
  @dblclick.stop.prevent
  @dragstart="dragstart"
  @dragend="dragend"
>
  <drag-and-drop-zone
    :z-index="dropzoneZIndex"
    @dropped="addElementToSide"
  />

  <div
    v-if="showImage"
    class="row mb-3"
  >
    <div class="col">
      <img
        class="img-fluid rounded"
        :src="productImage"
        :alt="productName"
      >
    </div>
  </div>

  <div
    v-if="showName || showPrice"
    class="row mb-3"
  >
    <div
      v-if="showName"
      class="col"
    >
      <h3 v-text="productName" />
    </div>
    <div
      v-if="showPrice"
      class="col d-flex justify-content-end"
    >
      <h3 v-text="productPrice" />
    </div>
  </div>

  <div
    v-if="showDesc"
    class="row mb-3"
  >
    <div class="col">
      <p v-text="productDescription" />
    </div>
  </div>

  <hr>

  <div class="row mb-3">
    <div class="col">
      <label for="">Name on card</label>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          placeholder="Card holders name"
        >
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col">
      <label for="">Credit card number</label>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          placeholder="0000 0000 0000 0000"
        >
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-4">
      <label for="">Expiration</label>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          placeholder="12/22"
        >
      </div>
    </div>

    <div class="col-4">
      <label for="">CVC</label>
      <div class="input-group">
        <input
          type="number"
          class="form-control"
          placeholder="123"
        >
      </div>
    </div>

    <div class="col-4">
      <label for="">Zip Code</label>
      <div class="input-group">
        <input
          type="number"
          class="form-control"
          placeholder="10002"
        >
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col">
      <button
        class="btn btn-primary btn-block text-capitalize"
        :class="btnClasses"
      >
        <span>Buy Now</span>
      </button>
    </div>
  </div>

  <div class="row p-4">
    <div class="col-3">
      <img
        src="//cdn.convrrt.com/light-payment-icons/1.png"
        alt=""
        class="img-fluid"
      >
    </div>
    <div class="col-3">
      <img
        src="//cdn.convrrt.com/light-payment-icons/2.png"
        alt=""
        class="img-fluid"
      >
    </div>
    <div class="col-3">
      <img
        src="//cdn.convrrt.com/light-payment-icons/22.png"
        alt=""
        class="img-fluid"
      >
    </div>
    <div class="col-3">
      <img
        src="//cdn.convrrt.com/light-payment-icons/14.png"
        alt=""
        class="img-fluid"
      >
    </div>
  </div>
</div>
