<template>
  <div>
    <!-- Delete Page Dialog -->
    <cvt-dialog
      :show="deleteVisible"
      :height-auto="true"
      size="sm"
      @close="abortPageRemoval"
    >
      <template #title>
        <h5>{{ $t("pagemanager.cvt_dialog.template.h5") }}</h5>
      </template>
      <cvt-alert
        class="w-100"
        color="warning"
        icon="exclamation-triangle"
      >
        <p>{{ $t("pagemanager.cvt_dialog.cvt_alert.p") }}</p>
      </cvt-alert>
      <template #modalFooter>
        <div class="d-flex">
          <cvt-button
            :loading="removing"
            class="mr-1"
            color="light"
            :text="$t(`pagemanager.cvt_dialog.submit.btn`)"
            @click="proceedPageRemoval"
          />
          <cvt-button
            :loading="removing"
            color="warning"
            :special-style="saveBtnStyle"
            :text="$t(`pagemanager.cvt_dialog.cancel.btn`)"
            @click="abortPageRemoval"
          />
        </div>
      </template>
    </cvt-dialog>

  </div>
</template>

<script>
  import * as _ from 'lodash'
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { LEFT_SIDEBAR_FRESHWORKS, LEFT_SIDEBAR_STANDARD } from '../../../../storybook/components/constants'
  import { cvtRouteNodes } from "@/js/utils/routes"

  export default {
    data () {
      return {
        mode: 'LIGHT',
        orgNameFreshWork: LEFT_SIDEBAR_FRESHWORKS,
        pageArgs: null,
        removing: false,
        currentStep: 'general'
      }
    },

    computed: {
      ...mapState('pageManager', {
        page: 'page',
        visible: 'settingsVisible',
        deleteVisible: 'deleteVisible',
        saving: 'saving',
      }),
      ...mapState('sitePreferences', {
        allowRobots: ({ preferences }) => _.get(preferences, 'seoSettings.allowRobots')
      }),
      ...mapState('globalTheme', {
        changeToggleToCheckBox: ({ globalTheme }) => globalTheme.changeToggleToCheckBox !== undefined ? globalTheme.changeToggleToCheckBox : false,
        OrgName: ({ globalTheme }) => globalTheme.OrgName !== undefined ? globalTheme.OrgName : LEFT_SIDEBAR_STANDARD
      }),
      ...mapState({
        site: ({ site }) => _.get(site, 'site'),
        pages: ({ site }) => _.chain(site).get('site.pages').orderBy(['isIndex', 'createdAt'], ['desc', 'asc']).value(),
        indexPage: ({ site }) => _.chain(site).get('site.pages').find({ isIndex: true }).value()
      }),
      pageSlug () {
        return this.page && this.page.name && this.page.name.replace(/\W{1,}/g, '-').toLowerCase().trim()
      },
      closeBtnBoolean () {
        return this.OrgName !== LEFT_SIDEBAR_FRESHWORKS
      },
      saveBtnStyle () {
        return this.OrgName !== LEFT_SIDEBAR_FRESHWORKS ? '' : 'dark'
      },
      allowRobotsOnThisPage: {
        get () {
          return _.get(this.allowRobots, ['pages', this.page.id], false)
        },
        set (value = false) {
          return this.saveSitePreferences({
            key: `seoSettings/allowRobots/pages/${this.page.id}`,
            value
          })
        }
      },
    },
    methods: {
      ...mapMutations('pageManager', {
        handleClose: 'close',
        update: 'update',
        closeDeleteDialog: 'closeDeleteDialog',
      }),
      ...mapActions('pageManager', {
        save: 'save',
        remove: 'remove',
      }),
      ...mapActions('sitePreferences', {
        saveSitePreferences: 'save'
      }),
      backAction (e) {
          e.preventDefault()
          e.stopPropagation()
          this.currentStep = 'general'
      },
      AddScriptAction (e) {
          e.preventDefault()
          e.stopPropagation()
          this.currentStep = 'script'
      },
      async saveAndClose () {
        await this.save()
        this.handleClose()
      },

      updatePageSetting (key, { target }) {
        this.update({
          [key]: target.value
        })
      },

      onSwitchToggle (property) {
        property == 'allowRobotsOnThisPage' ? this.allowRobotsOnThisPage = !this.allowRobotsOnThisPage : ''
      },


      abortPageRemoval () {
        this.removing = false
        this.closeDeleteDialog()
      },

      async proceedPageRemoval () {
        const { id } = this.indexPage
        this.removing = true
        await this.remove({...this.page})

        this.$router.push({
          page: cvtRouteNodes.editorDesignPage,
          params: {
            ...this.$route.params,
            pageId: id
          }
        })

        this.$message({
          type: 'success',
          message: this.$t("pagemanager.cvt_dialog.delete.success.message")
        })
        this.removing = false
        this.abortPageRemoval()
      },

      edit ({ id }) {
        this.$router.push({
          page: cvtRouteNodes.editorDesignPage,
          params: {
            ...this.$route.params,
            pageId: id
          }
        })
        window.location.reload()
      },
    }
  }
</script>

<style lang="scss">
  @import "@/scss/variables";
</style>
