
<div data-identity="AddVerifiedSeal">
  <h4 class="admin-label">{{$t('launch.tab.control.heading.add_verified_seal')}}</h4>
  <cvt-switch
    size="sm"
    color="active"
    type="circular"
    description-type="default"
    :initial-state="verifiedSealEnabled"
    :mode="mode"
    @switchToggle="(status) => changeVerifiedSeal(status)"
  />
</div>
