
<cvt-collapse
  :accordion="true"
  :theme="mode === 'LIGHT' ? 'light' : 'dark'"
  :prepend-chevron="prependChevron"
>
  <cvt-collapse-item v-if="config.IS_GEN_AI_ENABLED" title="Generative AI">
    <GenAIOptions :mode="mode" />
  </cvt-collapse-item>
  <cvt-collapse-item
    v-if="!selected.isPopupBlock"
    :title="$t('elements.properties.label')"
    name="properties"
    :initial-active="true"
  >
    <div class="row no-gutters my-3">
      <div class="col-12">
        <cvt-input
          v-model="anchorName"
          type="text"
          :placeholder="$t('editor.section.name.message')"
          :label="$t('editor.section.name.message')"
        ></cvt-input>
      </div>
    </div>
    <div v-if="isHeaderBlock" class="row mt-2">
      <div class="col">
        <h6 :class="textColorClass">Disable Header</h6>
      </div>
      <div class="col-3">
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="headerDisabled"
          :mode="mode"
          @switchToggle="() => disableHeaderAction(!headerDisabled)"
        ></cvt-switch>
      </div>
    </div>
    <div v-if="isFooterBlock" class="row mt-2" data-test-id="footer-block">
      <div class="col">
        <h6 :class="textColorClass">Disable Footer</h6>
      </div>
      <div class="col-3">
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="footerDisabled"
          :mode="mode"
          @switchToggle="() => disableFooterAction(!footerDisabled)"
        ></cvt-switch>
      </div>
    </div>
  </cvt-collapse-item>
  <cvt-collapse-item
    v-if="selected.isPopupBlock"
    :title="'Close Button Location'"
    name="Close Button Location"
  >
    <div class="flex row justify-content-between mx-5">
      <cvt-radio-group
        size="md"
        orientation="horizontal"
        :initial-value="cCloseButtonPosition"
      >
        <cvt-radio-button
          size="md"
          :value="'start'"
          @radioClick="setPopupClosePosition"
        >
          {{ 'Left' }}
        </cvt-radio-button>

        <cvt-radio-button
          size="md"
          :value="'end'"
          @radioClick="setPopupClosePosition"
        >
          {{ 'Right' }}
        </cvt-radio-button>
      </cvt-radio-group>
    </div>
  </cvt-collapse-item>
  <cvt-collapse-item
    v-if="selected.isPopupBlock && showPopupSizeConstraints"
    :title="'Size Constraints'"
    name="Size Constraints"
    :initial-active="true"
  >
    <div class="row no-gutters">
      <div class="col-12">
        <cvt-radio-group
          size="md"
          orientation="horizontal"
          :initial-value="cPopupWidth"
          :disabled="cPopupIsFullScreen"
        >
          <cvt-radio-button
            size="md"
            value="small"
            :disabled="cPopupIsFullScreen"
            @radioClick="setPopupWidth"
          >
            {{ $t('elements.popups.setting.size.small') }}
          </cvt-radio-button>

          <cvt-radio-button
            size="md"
            value="medium"
            :disabled="cPopupIsFullScreen"
            @radioClick="setPopupWidth"
          >
            {{ $t('elements.popups.setting.size.medium') }}
          </cvt-radio-button>

          <cvt-radio-button
            size="md"
            value="large"
            :disabled="cPopupIsFullScreen"
            @radioClick="setPopupWidth"
          >
            {{ $t('elements.popups.setting.size.large') }}
          </cvt-radio-button>
        </cvt-radio-group>
      </div>

      <div class="divider"></div>

      <div class="row mt-2">
        <div class="col">
          <h6 :class="textColorClass">
            {{ $t('elements.popups.setting.size.fs') }}
          </h6>
        </div>
        <div class="col-3">
          <cvt-switch
            size="sm"
            color="active"
            type="circular"
            description-type="default"
            :initial-state="cPopupIsFullScreen"
            :mode="mode"
            @switchToggle="
              (status) => setPopupWidthFullSreen(status, 100, 100)
            "
          ></cvt-switch>
        </div>
      </div>
    </div>
  </cvt-collapse-item>
  <cvt-collapse-item
    :title="$t('elements.properties.background.color')"
    name="color"
  >
    <colors alpha :mode="mode"></colors>
  </cvt-collapse-item>
  <cvt-collapse-item
    :title="$t('elements.properties.background.image')"
    name="bgImage"
  >
    <element-image-manager
      image-key="background/image"
      :hook="selected.updateBgSrc"
      :background-img-position="backgroundPosition"
      @backgroundImgPositionChanged="onBgImgPositionChanged"
    >
    </element-image-manager>
    <background-size-repeat> </background-size-repeat>

    <div class="divider"></div>
    <background-color-overlay :mode="mode"> </background-color-overlay>
  </cvt-collapse-item>
  <cvt-collapse-item
    v-if="isGridBuilder"
    :title="$t('elements.properties.background.shape')"
    name="bgShape"
  >
    <background-shape />
  </cvt-collapse-item>
  <cvt-collapse-item
    :title="$t('elements.properties.style.label')"
    name="style"
  >
    <div class="row mt-2">
      <div class="col">
        <h6 :class="textColorClass">
          {{ $t('element.property.section.edge') }}
        </h6>
      </div>
      <div class="col-3">
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="isFluid"
          :mode="mode"
          @switchToggle="() => onSwitchToggle('isFluid')"
        ></cvt-switch>
      </div>
    </div>

    <div v-if="!selected.isPopupBlock" class="divider"></div>

    <div v-if="!selected.isPopupBlock" class="row mt-2">
      <div class="col">
        <h6 :class="textColorClass">
          {{ $t('element.property.section.full_height') }}
        </h6>
      </div>
      <div class="col-3">
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="isCover"
          :mode="mode"
          @switchToggle="() => onSwitchToggle('isCover')"
        ></cvt-switch>
      </div>
    </div>

    <div v-if="!selected.isPopupBlock" class="divider"></div>

    <div v-if="!selected.isPopupBlock" class="row mt-2">
      <div class="col">
        <h6 :class="textColorClass">
          {{ $t('element.property.section.parallax') }}
        </h6>
      </div>
      <div class="col-3">
        <cvt-switch
          size="sm"
          color="active"
          type="circular"
          description-type="default"
          :initial-state="backgroundParallax"
          :mode="mode"
          @switchToggle="() => onSwitchToggle('backgroundParallax')"
        ></cvt-switch>
      </div>
    </div>
  </cvt-collapse-item>
  <cvt-collapse-item
    :title="$t('elements.properties.spacing.label')"
    name="spacing"
  >
    <spacing-ctrl></spacing-ctrl>
  </cvt-collapse-item>

  <!-- Hide it when New-Section-UI is enabled regardless of grid-builder or old-builder -->
  <cvt-collapse-item
    v-if="!isSectionUIEnabled"
    :title="$t('elements.properties.visibility.label')"
    name="visibility"
  >
    <visibility-ctrl :mode="mode"></visibility-ctrl>
  </cvt-collapse-item>
</cvt-collapse>
