<template>
  <div class="remote-fields">
    <div v-if="loading" class="placeholder-container">
      <cvt-content-placeholder class="placeholder" />
    </div>
    <cvt-select
      v-else
      mode="dropdown"
      :value="value"
      :label="placeholder"
      :background-mode="mode"
      @input="(data) => updateSelected(data)"
    >
      <option
        v-for="datum in remoteData"
        :key="datum.key"
        :value="datum.value"
        v-text="datum.label"
      />
    </cvt-select>
  </div>
</template>

<script>
import * as axios from 'axios'
import { mapGetters } from 'vuex'
import * as getters from '../../../store/modules/getters'
import uuid from 'uuid/v4'
import { clientSpecificCustomParam } from '../../../utils/helpers'

const client = axios.create({
  baseURL: '/api',
  withCredentials: true,
})

export default {
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      required: true,
    },
    endpoint: {
      type: String,
      required: true,
    },
    keyBy: {
      type: String,
      default: 'id',
    },
    labelField: {
      type: String,
      default: 'name',
    },
    valueField: {
      type: String,
      default: 'id',
    },
    dataFormattingType: {
      type: String,
      default: 'default',
      required: false,
    },
    mockingEnabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    mockingData: {
      type: Array,
      default: () => [],
      required: false,
    },
    lastUpdated: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      remoteData: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),
  },
  watch: {
    endpoint(val, prev) {
      if (val !== prev) {
        this.loadRemoteData()
      }
    },
    lastUpdated(val, prev) {
      if (val !== prev) {
        this.loadRemoteData()
      }
    },
  },
  async created() {
    if (!this.mockingEnabled) {
      await this.loadRemoteData()
    } else {
      this.remoteData = this.formatRemoteData(this.mockingData)
    }
  },
  methods: {
    async loadRemoteData() {
      try {
        this.loading = true
        const optionalParams = clientSpecificCustomParam(this.config)
        const getData = await client.get(this.endpoint, {
          params: optionalParams,
        })
        this.remoteData = this.formatRemoteData(getData.data)
        this.loading = false
      } catch (error) {
        console.error(error)
      }
    },
    updateSelected(data) {
      this.$emit('input', data)
    },
    formatRemoteData(remoteData = []) {
      let formattedRemoteData = []

      for (let item of remoteData) {
        let datum = {}

        if (this.dataFormattingType === 'default') {
          datum = {
            key: uuid(),
            value: item[this.valueField],
            label: item[this.labelField],
          }
        } else if (this.dataFormattingType === 'liondesk-lead-routes') {
          datum = {
            key: uuid(),
            value: item.id,
            label: `${item.lead_source?.name || ''} - ${
              item.contact_source?.name || ''
            }`,
          }
        }

        formattedRemoteData.push(datum)
      }

      return formattedRemoteData
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/utils';

.remote-fields {
  .placeholder-container {
    position: relative;
    height: 48px;
  }

  .placeholder {
    height: 48px;
    width: 100%;
    left: 0;
    border-radius: 8px;
  }

  .el-select {
    width: 100%;
    .el-input__inner {
      border: 3px solid $inf-green;
      &::placeholder {
        text-transform: uppercase;
        color: $inf-green;
      }
    }
  }
  .el-select-group__title {
    font-size: 1rem;
    font-weight: bold;
    background: $dark;
    color: $white;
  }

  .el-select-dropdown__item {
    .row {
      height: 100%;
    }

    .material-icons {
      line-height: 1;
    }

    small {
      line-height: 1;
      font-size: 1rem;
    }
  }
}
</style>
