
<div 
  v-if="!isGridBuilder" 
  class="l-breadcrumb" 
  @click.prevent.stop
>
  <a
    v-for="node in nodeList"
    :key="node.vnodeId"
    @mouseover="target(node, $event)"
    @click="select(node, $event)"
    v-text="fmtName(node)"
  >
  </a>
</div>
