
<div class="product-wrapper">
  <cvt-button
      :outlined="true"
      :full-width="true"
      shape="rounded"
      color = "light"
      :mode="mode"
      text="Attach a product"
      @click="openProductsDialog"
    />
</div>
