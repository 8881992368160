
  <el-dialog :visible="visible" size="tiny" @open="onOpen" @close="onClose">
    <span slot="title" class="bookmark-block-title">
      {{ $t('element.property.bookmark.block.title') }}
    </span>
    <el-form ref="ruleForm" label-position="top" :model="blockData" :rules="rules" class="demo-ruleForm">
        <el-form-item prop="name">
          <el-input
            v-model="blockData.name"
            class="col-12 mb-2"
            placeholder="Name the bookmark"
            autofocus
          />
        </el-form-item>
      <!-- <el-form-item prop="thumbnail">
        <el-input
          v-model="blockData.thumbnail"
          class="col-12 mb-2"
          placeholder="Url to thumbnail"
        />
      </el-form-item> -->
<!--      <el-form-item prop="tags">-->
<!--        <el-select-->
<!--          v-model="blockData.tags"-->
<!--          class="col-12"-->
<!--          multiple-->
<!--          filterable-->
<!--          allow-create-->
<!--          placeholder="Apply block tags"-->
<!--        >-->
<!--          <el-option v-for="tag in tags" :key="tag" :label="tag" :value="tag" />-->
<!--        </el-select>-->
<!--      </el-form-item>-->
    </el-form>

    <div slot="footer" class="dialog-footer col-12">
      <div class="row justify-content-end">
        <el-button :disabled="isSaving" @click="close">{{
          $t('element.property.cta.cancel')
        }}</el-button>
        <el-button type="primary" :loading="isSaving" :disabled="false" @click="submitForm">{{
          $t('element.property.cta.confirm')
        }}</el-button>
      </div>
    </div>
  </el-dialog>
