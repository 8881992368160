export default function loadCustomTentantScripts(URL: string[]) {
  var y, z
  var o = (window['customTenantScripts'] = window['customTenantScripts'] || {})
  o._q = o._q || []
  URL.map((API_URL: string) => {
    return new Promise((resolve, reject) => {
      y = document.createElement('script')
      y.async = true
      y.src = API_URL
      y.onload = () => resolve(window['customTenantScripts'])
      y.onerror = (err) => reject(err)
      z = document.getElementsByTagName('script')[0]
      z.parentNode.insertBefore(y, z)
    })
  })
}
