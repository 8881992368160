import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

export default {
  state: {
    open: false,
    tabs: []
  },
  getters: {
    [getters.ADVANCED_MENU_TABS] ({ tabs }) {
      return tabs
    },
    [getters.ADVANCED_MENU_OPEN] ({ open }) {
      return open
    }
  },
  mutations: {
    [mutations.ADVANCED_MENU_SET_ACTIVE_TABS] (state, tabs) {
      state.tabs = tabs
    }
  },
  actions: {
    [actions.ADVANCED_MENU_TOGGLE] ({ state, dispatch, commit }, open) {
      state.open = open

      if (state.open) {
        dispatch(actions.LEFT_SIDE_MENU_TOGGLE, { open: false })
      }
    }
  }
}
