
<div data-identity="SectionLauncher">
  <cvt-sections
    class="make-scrollable"
    :sections="filteredBlocks"
    :section-tags="tags"
    :loading="loading"
    :active-tag="activeTag"
    :type="$attrs.type"
    :show-add-blank-section="showAddBlankSectionAction"
    :show-add-section="showAddSectionAction"
    @close-section="() => toggleLeftSideMenu({ open: false })"
    @insert-block="(block) => insertBlock(block)"
    @swap-block="(block) => swapBlock(block)"
    @delete-bookmark-dialog="(block) => showDeleteBookmarkDialog(block)"
    @edit-bookmark-name="(block, replaceText) => editBookmarkName(block, replaceText)"
    @insert-blank="insertBlank"
    @user-view-preference="(data) => userViewPreference = data"
    @filter-section="(value) => activeTag = value"
  />

  <cvt-dialog
    :show="deleteBookmarkVisible"
    :height-auto="true"
    size="sm"
    @close="deleteBookmarkVisible = false"
  >
    <template slot="title">
      <h5>Delete Bookmark</h5>
    </template>

    <cvt-alert class="w-100" color="warning">
      <p>
        You are deleting: {{ selectedBookmark && selectedBookmark.name }}. <br/>
        Are you sure you want to continue?
      </p>
    </cvt-alert>

    <template slot="modalFooter">
      <div class="d-flex">
        <cvt-button
          class="mr-1"
          :loading="deletingBookmark"
          color="light"
          :text="$t('marketplace.dialog.archive.cta.cancel')"
          @click="deleteBookmarkVisible = false"
        />
        <cvt-button
          color="warning"
          :loading="deletingBookmark"
          :text="$t('marketplace.dialog.archive.cta.submit')"
          @click="onDeleteBookmark"
        />
      </div>
    </template>
  </cvt-dialog>
</div>
