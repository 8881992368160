<template>
  <div>
    <h1>Slider</h1>
    <h3>Horizontal</h3>
    <div v-for="(color, key) in colorOptions" :key="color" style="width: 70%; padding: 20px 0;">
      <div style="margin: 0 0 20px 0;">
        <span>{{ key }}</span>
      </div>
      <cvt-slider
        id="slider-default"
        :mark-labels-enabled="false"
        :tooltips-enabled="true"
        :step-marks-enabled="false"
        :range-enabled="false"
        :min="0"
        :max="100"
        :value="40"
        :step="10"
        mode="LIGHT"
        :color="color"
        :input-enabled="false" 
        :disabled="false"
        class="slider"
      >
      </cvt-slider>
    </div>
    <br>
    <br>
    <h3>Custom Marks Slider</h3>
    <div style="width: 70%; padding: 40px 0;">
      <cvt-slider
        id="slider-default10"
        :mark-labels-enabled="true"
        :tooltips-enabled="false"
        :step-marks-enabled="true"
        :custom-steps-enabled="true"
        :marks="customMarks"
        :min="0"
        :max="100"
        :value="40"
        :step="10"
        mode="LIGHT"
        color="success"
        :input-enabled="false" 
        :disabled="false"
        class="slider"
      >
      </cvt-slider>
    </div>
    <br>
    <br>
    <h3>Range Slider</h3>
    <div style="width: 70%; padding: 40px 0;">
      <cvt-slider
        id="slider-default10"
        :mark-labels-enabled="true"
        :tooltips-enabled="false"
        :step-marks-enabled="true"
        :range-enabled="true"
        :min="0"
        :max="100"
        :value="[30, 80]"
        :step="10"
        mode="LIGHT"
        color="danger"
        :input-enabled="false" 
        :disabled="false"
        class="slider"
        >
      </cvt-slider>
    </div>
    <br>
    <br>
    <br>
    <h3>Range Custom Marks Slider</h3>
    <div style="width: 70%; padding: 40px 0;">
      <cvt-slider
        id="slider-default10"
        :mark-labels-enabled="true"
        :tooltips-enabled="false"
        :step-marks-enabled="true"
        :range-enabled="true"
        :custom-steps-enabled="true"
        :marks="customMarks"
        :min="0"
        :max="100"
        :value="[30, 80]"
        :step="10"
        mode="LIGHT"
        color="success"
        :input-enabled="false" 
        :disabled="false"
        class="slider"
        >
      </cvt-slider>
    </div>
    <br>
    <br>
    <br>
    <h3>Disabled Custom Marks</h3>
    <div style="width: 70%; padding: 40px 0;">
      <cvt-slider
        id="slider-default10"
        :mark-labels-enabled="true"
        :tooltips-enabled="false"
        :step-marks-enabled="true"
        :range-enabled="true"
        :custom-steps-enabled="true"
        :marks="customMarks"
        :min="0"
        :max="100"
        :value="[30, 80]"
        :step="10"
        mode="LIGHT"
        color="danger"
        :input-enabled="false" 
        :disabled="true"
        class="slider"
        >
      </cvt-slider>
    </div>  
    <br>
    <br>
    <br>
    <h3>Vertical Slider</h3>
    <div style="display: flex; flex-wrap:wrap;">
      <div v-for="(color, key) in colorOptions" :key="color" style="width: 20%; padding: 20px 0;">
        <div style="margin: 0 0 20px 0;">
          <span>{{ key }}</span>
        </div>
        <cvt-vertical-slider
          id="slider-default"
          :mark-labels-enabled="false"
          :tooltips-enabled="true"
          :step-marks-enabled="false"
          :range-enabled="false"
          :min="0"
          :max="100"
          :value="40"
          :step="10"
          mode="LIGHT"
          :color="color"
          :input-enabled="false" 
          :disabled="false"
          class="slider"
        >
        </cvt-vertical-slider>
      </div>
    </div>
    <br>
    <br>
    <br>
    <h3>Vertical Range Custom Marks Slider</h3>
    <div style="display: flex; flex-wrap:wrap;">
      <div v-for="(color, key) in colorOptions" :key="color" style="width: 20%; padding: 20px 0;">
        <div style="margin: 0 0 20px 0;">
          <span>{{ key }}</span>
        </div>
        <cvt-vertical-slider
          id="slider-default10"
          :mark-labels-enabled="true"
          :tooltips-enabled="false"
          :step-marks-enabled="true"
          :range-enabled="true"
          :custom-steps-enabled="true"
          :marks="customMarks"
          :min="0"
          :max="100"
          :value="[30, 80]"
          :step="10"
          mode="LIGHT"
          :color="color"
          :input-enabled="false" 
          :disabled="false"
          class="slider"
          >
        </cvt-vertical-slider>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PlaySlider',
  components: {
  },
  data () {
    return {
      colorOptions: {
        Default: '',
        Dark: 'dark',
        Light: 'light',
        Primary: 'primary',
        Danger: 'danger',
        Success: 'success',
        Warning: 'warning'
      },
      customMarks: {
        0: {
          label: '0°C'
        },
        8: {
          label: '8°C'
        },
        37: {
          label: '37°C'
        },
        50: {
          style: {
            color: '#1989FA'
          },
          label: `<strong>50%</strong>`
        }
      },
      modeOptions: {
        Light: 'LIGHT',
        Dark: 'DARK'
      }
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
  },
}
</script>
<style lang="scss">
</style>
