
<cvt-collapse
  :accordion="true"
  :theme="mode === 'LIGHT' ? 'light' : 'dark'"
  :prepend-chevron="prependChevron"
>
  <cvt-collapse-item
    title="URL"
    name="url"
    :initial-active="true"
    :special-style="isSpecialStyle"
  >
    <cvt-alert color="primary" icon="info-circle">
      {{ $t('element.property.video.message') }}
    </cvt-alert>

    <cvt-input
      v-model="src"
      class="mt-2"
      :placeholder="$t('element.property.video.input.placeholder')"
      :label="
        !VideoMenuInputFloatingLabel
          ? $t('element.property.video.input.placeholder')
          : ''
      "
      :floating-label="VideoMenuInputFloatingLabel"
      autofocus
    >
    </cvt-input>

    <template v-if="sideBarVideoListOptions">
      <div class="divider mt-2" />

      <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
        {{ $t('element.property.video.crm.video_list') }}
      </div>

      <video-list
        :value="crmRemoteVideoUrl"
        :mode="mode"
        endpoint="/v1/crm/media/lists"
        placeholder="Select Video"
        @videoSelectionUpdated="onVideoSelectionUpdated"
      />
    </template>
  </cvt-collapse-item>

  <cvt-collapse-item
    :title="$t('elements.properties.spacing.label')"
    name="spacing"
    :special-style="isSpecialStyle"
  >
    <spacing-ctrl></spacing-ctrl>
  </cvt-collapse-item>
  <cvt-collapse-item
    v-if="isGridBuilder"
    title="Style"
    name="style"
    :special-style="isSpecialStyle"
  >

    <!-- <div class="row">
      <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
        {{ $t('elements.properties.background.color') }}
      </div>
      <div class="col-12">
        <colors alpha :mode="mode" />
      </div>
    </div>

    <div class="divider mt-2" /> -->

    <div class="nestedInnerItem">
      <cvt-select
        :value="borderStyle"
        mode="dropdown"
        :background-mode="mode"
        :floating-label="false"
        :label="$t('elements.properties.border.style')"
        @input="(value) => updateBorderStyle(value)"
      >
        <option
          v-for="style in borderStyles"
          :key="style"
          :value="style"
          v-text="style"
        />
      </cvt-select>
      <div style="margin: 5px" />
      <cvt-input
        v-model="borderColor"
        style="width: max-content"
        type="text"
        :clearable="false"
        :label="$t('elements.properties.border.color')"
      />
    </div>

    <div class="divider mt-2" />

    <div class="row">
      <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
        {{ $t('elements.properties.border.width') }}
      </div>
      <div class="col-12">
        <cvt-slider
          id="slider-default"
          :tooltips-enabled="true"
          :step-marks-enabled="true"
          class="slider"
          :min="1"
          :max="10"
          :value="borderWidth"
          :step="1"
          @change="updateBorderWidth"
        />
      </div>
    </div>
    <div class="divider mt-2" />

    <div class="row">
      <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
        {{ $t('elements.properties.border.radius') }}
      </div>
      <div class="col-12">
        <cvt-slider
          id="slider-default"
          :tooltips-enabled="true"
          :step-marks-enabled="true"
          class="slider"
          :min="0"
          :max="100"
          :value="borderRadius"
          :step="5"
          @change="updateBorderRadius"
        />
      </div>
    </div>
  </cvt-collapse-item>
</cvt-collapse>
