<div @mouseover="ignore">
    <popup-main-nav />
    <layout-breadcrumb/>
    <left-side-bar :class="changeZIndex"   v-if="showSideControls"/>
    <advanced-side-menu v-if="showSideControls"/>
    <advanced-popover-menu />
    <mouse-follower />
<!--    <div :class="pageEngineWrapperData">-->
        <sub-nav-bar>
            <anime :enter="routeEnter" :leave="routeLeave" :before-enter="beforeEnter">
                <router-view />
            </anime>
        </sub-nav-bar>
<!--    </div>-->
    <div :class="showSectionsBackdrop" />
    <save-block/>
    <name-landing-page
        :show="showNameDialog"
        @continue="hideEditorNameDialog"
        :useCallback="true"
        @callback="savePopupCampaignName"
    />
    <icon-picker />
    <right-click-menu />
     <asset-manager />
    <revision-manager />
    <revision-save-manager />
</div>
