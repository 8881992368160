<template>
  <div class="">
    <p>{{ $t("elements.property.product.select.header") }}</p>
    <div class="mb-2">
      <cvt-button
        :outlined="true"
        :full-width="true"
        shape="rounded"
        color = "light"
        :mode="mode"
        :text="$t(`elements.property.product.manage`)"
        @click="openProductsDialog"
      />
    </div>
    <div class="">
      <ecomm-products
        :products="products"
        :placeholder="$t('elements.property.product.select.message')"
        @product-selected="updateProducts"
      />
    </div>
    <div class="divider" />
    <div class="products">
      <div
        v-for="product in products"
        :key="product.id"
        class="row py-1 stripe-product"
      >
        <div class="col">
          <p
            class="font-weight-bold m-0"
            v-text="product.name"
          />
          <small
            class="text-success m-0"
          >{{ product.sku.price | currency }}</small>
        </div>
        <div class="col-3 product-actions align-self-center text-center">
          <el-tooltip
            :content="$t('element.property.product.cta.remove')"
            placement="top"
          >
            <i
              class="fa fa-trash fa-lg"
              @click="removeProduct(product)"
            />
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash'
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'
import ECommProducts from '@/js/components/editor/forms/ECommProducts.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  components: {
    'ecomm-products': ECommProducts
  },
  data () {
    return {
      mode: 'LIGHT'
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED
    }),
    products () {
      return this.selected.products || []
    }
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP
    }),
    ...mapMutations('eCommerce', ['openProductsDialog']),
    updateProducts (product) {
      return this.updateProp({ key: 'products', value: [...this.products, this.mapProductToCartItem(product)] })
    },
    removeProduct (product) {
      const products = [...this.products]
      _.remove(products, product)
      return this.updateProp({ key: 'products', value: products })
    },
    mapProductToCartItem ({ id, name, caption, description, skus }) {
      return {
        id,
        name,
        caption,
        description,
        sku: _.chain(skus).head().pick(['id', 'price']).value()
      }
    }
  }
}
</script>
