import Vue from '../../../lib/utils/vue'
import VueI18n from 'vue-i18n'
import { styled, VueEmotion } from '@egoist/vue-emotion'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import CvtButton from '../../molecules/Button'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'

Vue.use(VueEmotion)
Vue.use(VueI18n)

let FontFamily, Colors;

const getItemWrapper = () => styled('div')`
  background: ${Colors.PRIMARY[100]};
  padding: 15px 10px 15px 20px;
  border: 1px solid ${Colors.PRIMARY[400]};
  border-radius: 7px;
  display: flex;
  margin-right: 15px;
  margin-bottom: 15px;
`
const getInnerItemWrapper = () => styled('div')`
  ${(props) => props.width && `width:${props.width}px`}
`

const getConnectButton = () => styled('span')`
  width: 90px;
  padding: 7px;
  margin-right: 10px;
  border: 1px solid ${Colors.PRIMARY[400]};
  color: ${Colors.PRIMARY[400]};
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;

  ${(props) =>
    props.passed &&
    `border: 1px solid ${Colors.SUCCESS[500]}; color: ${Colors.SUCCESS[500]};`}
`

const getEnvironmentCaption = () => styled('p')`
  margin-bottom: 0;
`

const getEnvironmentCaptionSpan = () => styled('span')`
  font-size: 18px;
  font-weight: 700;
`

const getConnectButtonWrap = () => styled('p')`
  padding-top: 15px;
`

const getCheckIcon = () => styled('i')`
  color: ${Colors.SUCCESS[500]};
`

const getCheckSpan = () => styled('span')`
  cursor: pointer;
`

const getDeleteItemIcon = () => styled('span')`
  text-align: end;
  margin-bottom: 0;
  cursor: pointer;
  margin-top: -10px;
`

const getAddNewIcon = () => styled('div')`
  font-size: 30px;
  border: 1px solid ${Colors.PRIMARY[500]};
  padding: 2px 18px;
  border-radius: 18px;
  color: ${Colors.PRIMARY[500]};
  cursor: pointer;
`

export const CvtOidcEnvironmentCard = {
    name: 'CvtOidcEnvironmentCard',
    components: {},
    data() {
      return {}
    },
    props: {
      id: {
        type: String,
        default: ''
      },
      width: {
        type: String,
        default: '210'
      },
      clientId: {
        type: String,
        default: ''
      },
      testPassed: {
        type: Boolean,
        default: false
      },
      testIsLoading: {
        type: Boolean,
        default: false
      },
      environment: {
        type: String,
        default: ''
      }
    },
    computed: {
      ...mapGetters({
        config: getters.AUTH_GET_USER_CONFIG,
      }),
      ...mapState('globalTheme', {
        FontFamily: ({ globalTheme }) => globalTheme.FontFamily,
        Colors: ({ globalTheme }) => globalTheme.Colors,
      }),
    },
    beforeDestroy() {},
    methods: {
      ...mapActions({}),
      ...mapMutations({}),

    //   closeModal() {
    //     this.showModal = false
    //   },

      connectButtonText(testPassed, testIsLoading) {
        if (testIsLoading) return 'Testing...'
        if (testPassed) return 'Verified'
        return 'Test Connection'
      },
    },
    async mounted() {},
    created() {
      FontFamily = this.FontFamily
      Colors = this.Colors
    },
    render: function (h) {
      const ItemWrapper = getItemWrapper()
      const InnerItemWrapper = getInnerItemWrapper()
      const ConnectButton = getConnectButton()
      const EnvironmentCaption = getEnvironmentCaption()
      const EnvironmentCaptionSpan = getEnvironmentCaptionSpan()
      const ConnectButtonWrap = getConnectButtonWrap()
      const CheckSpan = getCheckSpan()
      const CheckIcon = getCheckIcon()
      const DeleteItemIcon = getDeleteItemIcon()

      return (
        <ItemWrapper>
          <InnerItemWrapper width={this.width}>
            <EnvironmentCaption>
              <EnvironmentCaptionSpan>ClientID: </EnvironmentCaptionSpan>
              { this.clientId }
            </EnvironmentCaption>
            <EnvironmentCaption>
              <EnvironmentCaptionSpan>Environment: </EnvironmentCaptionSpan>
              { this.environment }
            </EnvironmentCaption>
            <ConnectButtonWrap>
              <ConnectButton
                onclick={() => this.$emit('onStartConnection', this.id)}
                passed={this.testPassed}
              >
                {this.connectButtonText(this.testPassed, this.testIsLoading)}
              </ConnectButton>

              {this.testIsLoading && (
                <CheckSpan>
                  <i class="fas fa-refresh"></i>
                </CheckSpan>
              )}
              {this.testPassed && (
                <CheckSpan>
                  <CheckIcon class="fas fa-check"></CheckIcon>
                </CheckSpan>
              )}
            </ConnectButtonWrap>
          </InnerItemWrapper>
          <DeleteItemIcon
            onClick={() => this.$emit('onRemoveOidcItem')}
          >
            <i class="fas fa-close"></i>
          </DeleteItemIcon>
        </ItemWrapper>
      )
    }
  }