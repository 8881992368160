<template>
  <div class="marketplace-wrapper" :class="marketplaceLayout">
    <template v-if="!isTabsUiEnabled">
      <main-nav
        v-if="!marketplaceHeaderDisabled"
        :title="marketplaceHeaderTitleDisabled ? '' : marketplaceHeaderTitle"
      />
      <!-- <steps-nav :tabs="false" :controls="false" :breadcrumb="false"></steps-nav> -->
      <page-preview
        :title="previewTitle"
        :is-template="true"
        :is-first="isFirstTemplate"
        :is-last="isLastTemplate"
        :preview-cta="previewCta"
        @prev="prevTemplate"
        @next="nextTemplate"
        @use-template="useTemplate(previewTemplatId)"
      />
      <div class="content-nav">
        <cvt-left-nav-bar
          usage="landingPage"
          :type="leftNavType"
          :navigation-items="filteredCategories"
          :initial-active-id="null"
          :counts="templatesCount"
          :show-count="ShowCount"
          :align-tab-to-bottom="certifiedTemplatesMarketplaceUI"
          @navItemClicked="onSelectCategory"
        />
      </div>
      <div
        class="content"
        :class="{ 'content-with-header': activeCategory === yourTemplates.id }"
      >
        <div class="content-header">
          <cvt-search-filter
            v-if="activeCategory == yourTemplates.id"
            class="cvt-search-style mt-2"
            :mode="mode"
            :initial-templates-view-format-val="templatesDefaultViewFormat"
            :search-bar-bg-config="searchBarBgConfig"
            :display-template-view-format-btn="
              isTemplateActionsRightSidebarEnabled
            "
            :default-search="searchQuery"
            @searchDone="onSearchDone"
            @sortParameter="onSortParameter"
            @templatesViewToggled="onTemplatesViewToggled"
          />
        </div>

        <div class="content-wrap main-wrapper" style="flex-direction: column">
          <div v-if="displayAIBtn" class="landing-page-ai-btn-wrapper">
            <div class="landing-page-ai-btn-inner-wrapper">
              <cvt-button
                shape="round"
                color="dark"
                icon="magic"
                :text="$t('gen_ai.marketplace.btn.text')"
                :full-width="true"
                :prepend="false"
                @click="goToLandingPageDescription"
              ></cvt-button>
            </div>
          </div>
          <div v-if="brandingActive">
            <marketplace-branding />
          </div>
          <div v-if="genAiTemplatesActive" style="display: flex">
            <cvt-select
              class="col-4"
              mode="dropdown"
              :value="intentList"
              label="Select Intent"
              :theme="mode"
              :multiple="true"
              :background-mode="mode"
              @input="(value) => setGenAISelectedIntents(value)"
            >
              <option
                v-for="item in intentList"
                :key="item.value"
                :value="item.value"
                v-text="item.label"
              ></option>
            </cvt-select>

            <cvt-select
              class="col-4"
              mode="dropdown"
              :value="''"
              label="Select Intent Goal"
              :theme="mode"
              :multiple="true"
              :background-mode="mode"
              @input="(value) => setGenAISelectedIntentGoals(value)"
            >
              <optgroup
                v-for="intentObj of intentList"
                :key="intentObj.value"
                :label="intentObj.label"
              >
                <option
                  v-for="intentGoal in intentObj.goals"
                  :key="intentGoal.value"
                  :value="intentGoal.value"
                  v-text="intentGoal.label"
                ></option>
              </optgroup>
            </cvt-select>

            <div class="align-content-end">
              <cvt-button
                :mode="mode"
                style="height: 42px"
                :text="'Search'"
                :color="''"
                :loading="false"
                :shadow="true"
                :disabled="disableSortGenAiTemplates"
                @click="(event) => onSortGenAiTemplates(event)"
              />
            </div>
          </div>
          <main v-if="!brandingActive" class="marketplace-template-groups-alt">
            <cvt-modal
              v-if="showScheduleDemoModal"
              :modal-header="$t('marketplace.demo.modal.header.text')"
              :text-content="$t('marketplace.demo.modal.content.text')"
              :btn-text="$t('marketplace.demo.modal.button')"
              :modal-width="500"
              :btn-on-click="closeDemoModal"
            />

            <div v-if="templatesDefaultViewFormat === 'GRID'">
              <div v-if="certifiedTemplatesMarketplaceUI && subTabIsActive">
                <div class="marketplace-tag-container">
                  <cvt-marketplace-tag :img-path="getAltLogo" />
                </div>
                <div class="sub-tab-wrapper">
                  <cvt-tabs
                    :pills="true"
                    :keep-inactive-border="true"
                    :mode="''"
                    :prefix-icon="''"
                    :align-tabs="'center'"
                    :tabs-config="tabsConfig"
                    @activateTabModified="onTabCategoryClick"
                  >
                    <cvt-tab
                      v-for="category in subTabCategories"
                      :key="category.id"
                      :title="category.id == null ? 'All' : category.label"
                      :tab-id="Number(category.index).toString()"
                    >
                      <div :class="'marketplace-templates'">
                        <div
                          v-if="isStartFromScratchCardEnabled"
                          key="startFromScratch"
                          :class="getStartFromScratchClass()"
                        >
                          <cvt-marketplace-card
                            id="mpCard"
                            usage="startFromScratch"
                            :mode="mode"
                            :hover="StartFromScratchHover"
                            :tabs-ui="true"
                            :get-started-button="
                              marketplaceConfig?.marketplaceCard
                                ?.getStartedButton
                            "
                            @startFromScratch="startFromScratch"
                          >
                            <template #cardTitle>{{
                              $t('marketplace.templates.label.scratch')
                            }}</template>
                          </cvt-marketplace-card>
                        </div>
                        <div
                          v-for="(template, $index) in filteredTemplates"
                          :key="`${$index}-${template.id}`"
                        >
                          <cvt-marketplace-card
                            :id="'mpCard' + $index"
                            v-intersected-once="{
                              onIntersected: loadMoreTemplates(),
                              shouldObserve:
                                filteredTemplates.length - 1 === $index,
                            }"
                            :mode="mode"
                            :thumbnail="template.thumbnail"
                            :get-started-button="
                              marketplaceConfig.marketplaceCard.getStartedButton
                            "
                            :preview-button="
                              marketplaceConfig.marketplaceCard.previewButton
                            "
                            @copyTemplate="showPreviewWindow(template.id)"
                            @getStarted="useTemplate(template.id)"
                            @preview="showPreviewWindow(template.id)"
                          >
                            <template #cardTitle>
                              {{
                                template.name || $t('site.input.default_value')
                              }}
                            </template>
                          </cvt-marketplace-card>
                        </div>

                        <template v-for="number in 6">
                          <div v-if="loading" :key="number">
                            <placeholder-card :mode="mode" />
                          </div>
                        </template>
                      </div>
                    </cvt-tab>
                  </cvt-tabs>
                </div>
              </div>
              <div
                v-if="showVersionSwitchAlert"
                id="builderV2VersionSwitchAlert"
                class="version-switch-alert-container"
              >
                <cvt-alert-with-button
                  :alert-btn-text="
                    $t('marketplace.builder_version_switch_alert.btn_text')
                  "
                  :alert-message="
                    $t('marketplace.builder_version_switch_alert.label')
                  "
                  :info-alert-config="versionSwitchAlertConfig"
                  alert-width="1000"
                  btn-width="350"
                  btn-color="primary"
                  @exitPreviewMode="switchToBuilderVersion"
                />
              </div>

              <div
                v-if="!certifiedTemplatesMarketplaceUI || !subTabIsActive"
                :class="[
                  'marketplace-templates',
                  activeCategory == yourTemplates.id ? 'your-page-style' : '',
                ]"
              >
                <div
                  v-if="
                    !archivedTemplatesActive && isStartFromScratchCardEnabled
                  "
                  key="startFromScratch"
                  :class="getStartFromScratchClass()"
                >
                  <cvt-marketplace-card
                    id="mpCard"
                    :mode="mode"
                    :hover="StartFromScratchHover"
                    usage="startFromScratch"
                    :get-started-button="
                      marketplaceConfig.marketplaceCard.getStartedButton
                    "
                    @startFromScratch="startFromScratch"
                  >
                    <template #cardTitle>{{
                      $t('marketplace.templates.label.scratch')
                    }}</template>
                  </cvt-marketplace-card>
                </div>
                <div
                  v-for="(template, $index) in filteredTemplates"
                  :key="`${$index}-${template.id}`"
                >
                  <cvt-marketplace-card
                    :id="'mpCard' + $index"
                    v-intersected-once="{
                      onIntersected: loadMoreTemplates(),
                      shouldObserve: filteredTemplates.length - 1 === $index,
                    }"
                    :mode="mode"
                    :thumbnail="template.thumbnail"
                    :usage="cardType"
                    :more-actions="moreActions"
                    :is-template-actions-right-sidebar-enabled="
                      isTemplateActionsRightSidebarEnabled
                    "
                    :archive-button="
                      marketplaceConfig.marketplaceCard.archiveButton
                    "
                    :copy-page-button="copyOrEditPageButton"
                    :get-started-button="
                      marketplaceConfig.marketplaceCard.getStartedButton
                    "
                    :preview-button="
                      marketplaceConfig.marketplaceCard.previewButton
                    "
                    :swap-template-button-action="
                      marketplaceConfig.marketplaceCard.swapTemplateButtonAction
                    "
                    @getStarted="useTemplate(template.id)"
                    @preview="showPreviewWindow(template.id)"
                    @editPage="editSite(template)"
                    @copyTemplate="showPreviewWindow(template.id)"
                    @moreActionClicked="
                      (data) => onMoreActionClicked(data, template)
                    "
                    @templateActionsBtnClicked="
                      (event) => showRightSidebar(event, template)
                    "
                    @restoreTemplate="restoreArchivedTemplate(template)"
                  >
                    <template #cardTitle>
                      {{ template.name || $t('site.input.default_value') }}
                    </template>
                  </cvt-marketplace-card>
                </div>
                <template v-for="number in 6">
                  <div v-if="loading" :key="number">
                    <placeholder-card :mode="mode" />
                  </div>
                </template>
              </div>
            </div>

            <div v-if="templatesDefaultViewFormat === 'LIST'">
              <div
                :class="[
                  'marketplace-templates-item',
                  activeCategory == yourTemplates.id
                    ? 'your-page-style-list-item'
                    : '',
                ]"
              >
                <div
                  v-for="(template, $index) in filteredTemplates"
                  :key="`${$index}-${template.id}`"
                >
                  <cvt-marketplace-list-item
                    v-intersected-once="{
                      onIntersected: loadMoreListItems(),
                      shouldObserve: filteredTemplates.length - 1 === $index,
                    }"
                    :mode="mode"
                    :template-title="template.name"
                    :site-status="template.state"
                    @copyTemplateBtnClicked="
                      (event) => copyTemplateBtnClicked(event, template)
                    "
                    @editSiteBtnClicked="
                      (event) => editSiteBtnClicked(event, template)
                    "
                    @archiveTemplateBtnClicked="
                      (event) => archiveTemplateBtnClicked(event, template)
                    "
                    @templateActionsBtnClicked="
                      (event) => showRightSidebar(event, template)
                    "
                  >
                  </cvt-marketplace-list-item>
                </div>

                <cvt-loading-message message="Loading" :display="loading">
                </cvt-loading-message>
              </div>
            </div>
          </main>
          <cvt-free-trial-footer v-if="showFreeTrial" />
        </div>
      </div>
    </template>

    <!-- archive dialog -->
    <cvt-dialog
      :show="showArchiveDialog"
      :height-auto="true"
      size="sm"
      @close="abortTemplateArchive"
    >
      <template #title>
        <h5>{{ $t('elements.property.choose.template.archive.template') }}</h5>
      </template>
      <cvt-alert class="w-100" color="warning" icon="exclamation-triangle">
        <p>
          {{ $t('marketplace.archiving.alert.label.text') }}
          {{ archiveTemplate && archiveTemplate.name }}.
          {{ $t('marketplace.archiving.alert.body.text') }}
        </p>
      </cvt-alert>
      <template #modalFooter>
        <div class="d-flex">
          <cvt-button
            class="mr-1"
            color="light"
            :text="$t('marketplace.dialog.archive.cta.cancel')"
            @click="abortTemplateArchive"
          />
          <cvt-button
            color="warning"
            :loading="archiving"
            :text="$t('marketplace.dialog.archive.cta.submit')"
            @click="onArchiveTemplate"
          />
        </div>
      </template>
    </cvt-dialog>

    <!-- dialog for domain-unlinking -->
    <cvt-dialog
      :show="unlinkDialog"
      size="sm"
      :height-auto="true"
      @close="unlinkDialog = false"
    >
      <template #title>
        <h5>
          {{ `${$t('custom_domain.ui.unlink_dialog.h5')} ${unlinkDomainName}` }}
        </h5>
      </template>
      <cvt-alert color="warning" icon="exclamation-triangle">
        <p v-if="unlinkExistingDomain">
          {{
            `${$t(
              'custom_domain.ui.unlink_dialog.p.unlink_existing_domain',
            )} ${unlinkDomainName} ${$t(
              'custom_domain.ui.unlink_dialog.p.part_two',
            )}`
          }}
        </p>
        <p v-else>
          {{
            `${$t(
              'custom_domain.ui.unlink_dialog.p.part_one',
            )} ${unlinkDomainName} ${$t(
              'custom_domain.ui.unlink_dialog.p.part_two',
            )}`
          }}
        </p>
      </cvt-alert>

      <template #modalFooter>
        <cvt-button
          color="light"
          :text="$t('custom_domain.ui.unlink_dialog.buttons.cancel')"
          @click="unlinkDialog = false"
        />
        <cvt-button
          :color="popUpBtnColor"
          :text="$t('custom_domain.ui.unlink_dialog.buttons.continue')"
          @click="unlinkDomain"
        />
      </template>
    </cvt-dialog>

    <!-- dialog for apex warning -->
    <cvt-dialog
      :show="apexDialog"
      size="lg"
      :height-auto="true"
      @close="apexDialog = false"
    >
      <template #title>
        <h5>{{ $t('custom_domain.ui.apex_popup.title') }}</h5>
      </template>
      <cvt-alert color="warning" icon="exclamation-triangle">
        <h2>{{ $t('custom_domain.ui.apex_popup.h2.first') }}</h2>
      </cvt-alert>

      <p>
        {{ $t('custom_domain.ui.apex_popup.p1.part_one')
        }}<code>example.com</code>
        {{ $t('custom_domain.ui.apex_popup.p1.part_two')
        }}<code>www.example.com</code>
        {{ $t('custom_domain.ui.apex_popup.p1.part_three')
        }}<code>example.com</code>
        {{ $t('custom_domain.ui.apex_popup.p1.part_four')
        }}<code>www.example.com</code>)
      </p>

      <h2 class="mt-5">{{ $t('custom_domain.ui.apex_popup.h2.second') }}</h2>

      <p>
        {{ $t('custom_domain.ui.apex_popup.p2.part_one') }}
        <a href="https://www.ietf.org/rfc/rfc1034.txt">RFC1034</a>
        {{ $t('custom_domain.ui.apex_popup.p2.part_two') }}
      </p>

      <p>
        {{ $t('custom_domain.ui.apex_popup.p3.part_one') }}<code>www</code
        >{{ $t('custom_domain.ui.apex_popup.p3.part_two') }} (<code
          >example.com</code
        >{{ $t('custom_domain.ui.apex_popup.p3.part_three')
        }}<code>www.example.com</code>).
      </p>
    </cvt-dialog>

    <template v-if="isTabsUiEnabled">
      <page-preview
        :title="previewTitle"
        :is-template="true"
        :is-first="isFirstTemplate"
        :is-last="isLastTemplate"
        :preview-cta="previewCta"
        @prev="prevTemplate"
        @next="nextTemplate"
        @use-template="useTemplate(previewTemplatId)"
      />

      <div v-if="showTopLogo" class="top-logo-container">
        <img :src="topLogo" />
      </div>

      <div class="marketplace-wrapper-tabs-ui">
        <h4 class="choose-temp-heading mb-4">
          {{
            !marketplaceHeaderTitleDisabled
              ? ''
              : $t('editor.header.marketplace.title')
          }}
        </h4>
        <cvt-tabs
          :pills="false"
          :closeable="false"
          :end="false"
          :vertical="false"
          :prefix-icon="''"
          :keep-inactive-border="true"
          :mode="mode"
          :full-horizontal-border="false"
          :smaller-tab-border="false"
          @activateTabModified="onTabCategoryClick"
        >
          <cvt-tab
            v-for="category in filteredCategories"
            :key="category.id"
            :title="category.label"
            :tab-id="Number(category.index).toString()"
          >
            <div v-if="templatesDefaultViewFormat === 'GRID'">
              <div
                :class="[
                  'marketplace-templates',
                  activeCategory === yourTemplates?.id
                    ? 'your-page-style-tabs-ui'
                    : '',
                ]"
              >
                <cvt-marketplace-search-sort
                  v-if="activeCategory === yourTemplates?.id"
                  class="marketplace-search-sort"
                  :default-search="''"
                  @searchDone="onSearchDone"
                  @sortParameter="onSortParameter"
                />

                <div
                  v-if="
                    !archivedTemplatesActive && isStartFromScratchCardEnabled
                  "
                  key="startFromScratch"
                  :class="getStartFromScratchClass()"
                >
                  <cvt-marketplace-card
                    id="mpCard"
                    usage="startFromScratch"
                    :mode="mode"
                    :hover="StartFromScratchHover"
                    :tabs-ui="true"
                    :get-started-button="
                      marketplaceConfig?.marketplaceCard?.getStartedButton
                    "
                    @startFromScratch="startFromScratch"
                  >
                    <template #cardTitle>{{
                      $t('marketplace.templates.label.scratch')
                    }}</template>
                  </cvt-marketplace-card>
                </div>

                <div
                  v-for="(template, $index) in filteredTemplates"
                  :key="`${$index}-${template.id}`"
                >
                  <cvt-marketplace-card
                    :id="'mpCard' + $index"
                    v-intersected-once="{
                      onIntersected: loadMoreTemplates(),
                      shouldObserve: filteredTemplates.length - 1 === $index,
                    }"
                    :mode="mode"
                    :thumbnail="template.thumbnail"
                    :usage="
                      activeCategory == yourTemplates?.id ? 'myTemplate' : ''
                    "
                    :tabs-ui="true"
                    :more-actions="moreActions"
                    :archive-button="
                      marketplaceConfig.marketplaceCard.archiveButton
                    "
                    :copy-page-button="copyOrEditPageButton"
                    :get-started-button="
                      marketplaceConfig.marketplaceCard.getStartedButton
                    "
                    :preview-button="
                      marketplaceConfig.marketplaceCard.previewButton
                    "
                    :swap-template-button-action="
                      marketplaceConfig.marketplaceCard.swapTemplateButtonAction
                    "
                    @getStarted="useTemplate(template.id)"
                    @preview="showPreviewWindow(template.id)"
                    @editPage="editSite(template)"
                    @copyTemplate="showPreviewWindow(template.id)"
                    @moreActionClicked="
                      (data) => onMoreActionClicked(data, template)
                    "
                    @templateActionsBtnClicked="
                      (event) => showRightSidebar(event, template)
                    "
                    @restoreTemplate="restoreArchivedTemplate(template)"
                  >
                    <template #cardTitle>
                      {{ template.name || $t('site.input.default_value') }}
                    </template>
                  </cvt-marketplace-card>
                </div>

                <template v-for="number in 6">
                  <div v-if="loading" :key="number">
                    <placeholder-card :mode="mode" />
                  </div>
                </template>
              </div>
            </div>
            <div v-if="templatesDefaultViewFormat === 'LIST'">
              <div
                :class="[
                  'marketplace-templates-item',
                  activeCategory == yourTemplates.id
                    ? 'your-page-style-list-item-tabs-ui'
                    : '',
                ]"
              >
                <div
                  v-for="(template, $index) in filteredTemplates"
                  :key="`${$index}-${template.id}`"
                >
                  <cvt-marketplace-list-item
                    v-intersected-once="{
                      onIntersected: loadMoreListItems(),
                      shouldObserve: filteredTemplates.length - 1 === $index,
                    }"
                    :mode="mode"
                    :template-title="template.name"
                    :site-status="template.state"
                    @copyTemplateBtnClicked="
                      (event) => copyTemplateBtnClicked(event, template)
                    "
                    @editSiteBtnClicked="
                      (event) => editSiteBtnClicked(event, template)
                    "
                    @archiveTemplateBtnClicked="
                      (event) => archiveTemplateBtnClicked(event, template)
                    "
                    @templateActionsBtnClicked="
                      (event) => showRightSidebar(event, template)
                    "
                  >
                  </cvt-marketplace-list-item>
                </div>

                <cvt-loading-message message="Loading" :display="loading">
                </cvt-loading-message>
              </div>
            </div>
          </cvt-tab>
        </cvt-tabs>
      </div>
    </template>

    <cvt-right-sidebar
      v-if="
        isTemplateActionsRightSidebarEnabled &&
        selectedTemplate !== null &&
        activeCategory == yourTemplates.id
      "
      :key="rightSidebarComponentKey"
      :class="rightSidebarClasses"
      :right-sidebar-radio-group-loading="rightSidebarRadioGroupLoading"
      :step-title="selectedTemplate.name"
      :img-src="selectedTemplate.thumbnail"
      :img-alt-text="$t('marketplace.template.image.not_found')"
      :img-width="140"
      :img-height="116"
      :site-label-status="selectedTemplate.state"
      :site-label-width="87"
      :site-label-height="26"
      :radio-group-theme="''"
      :radio-group-orientation="'vertical'"
      :radio-group-size="'md'"
      :radio-group-initial-value="radioGroupInitialValue"
      :site="selectedTemplate"
      :site-url="siteUrl"
      :is-published-url-set="isPublishedUrlSet"
      :is-custom-url-set="isCustomUrlSet"
      :disable-publish="disablePublish"
      @rightSidebarClosed="hideRightSidebar"
      @copyTemplateBtnClicked="
        (event) => copyTemplateBtnClicked(event, selectedTemplate)
      "
      @editSiteBtnClicked="
        (event) => editSiteBtnClicked(event, selectedTemplate)
      "
      @archiveTemplateBtnClicked="
        (event) => archiveTemplateBtnClicked(event, selectedTemplate)
      "
      @dns-unlink="(siteId, domain) => openUnlinkDialog(siteId, domain)"
      @apexDialog="(show) => openApexDialog(show)"
    />
  </div>
</template>

<script>
import { customClientConfig } from '@/js/utils/helpers'
import {
  GenAIIntent,
  genAIIntentGoalList,
  genAIIntentList,
} from '@/js/components/genAi/utils'
import { cvtRouteNodes } from '@/js/utils/routes'
import * as _ from 'lodash'
import VueRouter from 'vue-router'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  FRESHWORKS,
  LEFT_SIDEBAR_STANDARD,
} from '../../../../../storybook/components/constants'
import * as actions from '../../store/modules/actions'
import * as getters from '../../store/modules/getters'
import * as mutations from '../../store/modules/mutations'
import { Marketplace, V1Sites, V1GenAI } from '../../store/services/api'
import PagePreview from '../PagePreview.vue'
import chooseTemplateHelper from './helpers/chooseTemplateHelper'
import MarketplaceBranding from './branding/index.vue'

let sitesAPI = new V1Sites()
const marketplaceAPI = new Marketplace()
const genAIApi = new V1GenAI()
const { isNavigationFailure, NavigationFailureType } = VueRouter

const removeDuplicateTemplates = (templateArr) => {
  const uniqueIds = []
  const unique = templateArr.filter((element) => {
    const isDuplicate = uniqueIds.includes(element.id)
    if (!isDuplicate) {
      uniqueIds.push(element.id)
      return true
    }
    return false
  })
  return unique
}

export default {
  components: {
    PagePreview,
    MarketplaceBranding,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
  },
  data() {
    return {
      locked: false,
      yourTemplates: {
        id: 'your-templates',
        label: this.$t('marketplace.templates.your_pages_label'),
        index: 23,
      },
      archivedTemplates: {
        id: 'archived-templates',
        label: this.$t('marketplace.templates.archive.label'),
        index: 24,
      },
      showOverlayOn: null,
      previewTemplatId: null,
      enter: {
        duration: 800,
        opacity: 1,
        easing: 'easeInOutQuart',
      },
      leave: {
        duration: 800,
        opacity: 0,
        easing: 'easeInOutQuart',
      },
      beforeEnter(e) {
        e.style.opacity = 0
      },
      yourTemplatesList: [],
      yourTemplatesListAll: [],
      archivedTemplatesList: [],
      yourTemplatesSitesListAll: [],
      marketplacePagination: { limit: 15, offset: 0 },
      archivedTemplatesPagination: { limit: 5, offset: 0 },
      personalTemplatesPagination: { limit: 10, offset: 0 },
      personalTemplatesSitesPagination: { limit: 1000, offset: 0 },
      loading: true,
      rightSidebarRadioGroupLoading: true,
      showArchiveDialog: false,
      archiveTemplate: null,
      archiving: false,
      templatesCount: {},
      searchQuery: '',
      isCreatedSelected: false,
      isLastUpdatedSelected: false,
      isPublished: false,
      filterToEcommerce: false,
      isRightSidebarVisible: null,
      selectedTemplate: null,
      selectedTemplateSite: null,
      templatesViewFormat: null,
      unlinkDomainName: '',
      unlinkExistingDomain: false,
      unlinkDomainSiteId: '',
      unlinkDialog: false,
      rightSidebarComponentKey: 0,
      apexDialog: false,
      subTabIsActive: false,
      genAIData: {
        selectedIntents: [],
        selectedIntentGoals: [],
      },
      genAiTemplateList: [],
    }
  },
  computed: {
    ...mapGetters({
      marketplaceTemplates: getters.MARKETPLACE_TEMPLATES,
      previewOpen: getters.PAGE_PREVIEW_OPEN,
      config: getters.AUTH_GET_USER_CONFIG,
      user: getters.AUTH_GET_USER,
      linkedDomains: getters.MARKETPLACE_SITE_DOMAINS,
      allCategories: getters.GET_ALL_CATEGORIES,
      showScheduleDemoModal: getters.GET_MODAL,
      currentProductVersionNumber: getters.CURRENT_PRODUCT_VERSION_NUMBER,
      isGridBuilder: getters.IS_GRID_BUILDER,
    }),
    ...mapState('route', {
      query: (state) => state.query,
    }),
    ...mapState('globalTheme', {
      globalTheme: ({ globalTheme }) => globalTheme,
      topNavConfig: ({ topNavConfig }) => topNavConfig,
      leftNavConfig: ({ leftNavConfig }) => leftNavConfig,
      marketplaceConfig: ({ marketplaceConfig }) => marketplaceConfig,
      iconConfig: ({ iconConfig }) => iconConfig,
      leftNavType: ({ iconConfig }) =>
        iconConfig?.marketplaceCategories?.theme || 'standard',
      leftNavItemsAllTemplatesText: ({ leftNavConfig }) =>
        leftNavConfig.marketplace.navItems.allTemplatesText.text,
      leftNavItemsYourPagesTemplatesText: ({ leftNavConfig }) =>
        leftNavConfig.marketplace.navItems.yourPagesTemplatesText.text,
      StartFromScratchHover: ({ globalTheme }) =>
        globalTheme.StartFromScratchHover !== undefined
          ? globalTheme.StartFromScratchHover
          : true,
      ShowCount: ({ globalTheme }) =>
        globalTheme.ShowCount !== undefined ? globalTheme.ShowCount : true,
      orgName: ({ globalTheme }) =>
        globalTheme?.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD,
      activeCategory: ({ activeCategory }) => activeCategory,
      certifiedTemplatesMarketplaceUI: ({ globalTheme }) =>
        globalTheme.certifiedTemplatesMarketplaceUI,
      tabsConfig: ({ globalTheme }) => globalTheme.tabsConfig,
      searchBarBgConfig: ({ globalTheme }) => globalTheme.searchBarBgConfig,
      versionSwitchAlertConfig: ({ globalTheme }) =>
        globalTheme.versionSwitchAlertConfig,
    }),
    cardType() {
      return this.yourTemplatesActive
        ? 'myTemplate'
        : this.archivedTemplatesActive
        ? 'archivedTemplate'
        : ''
    },
    displayAIBtn() {
      return (
        this.config.IS_GEN_AI_ENABLED &&
        !this.brandingActive &&
        this.allowedVersion(4.0)
      )
    },
    showBrandingCategory() {
      return (
        this.config.MARKETPLACE_BRANDING_ENABLED && this.allowedVersion(4.0)
      )
    },
    showGenAICategory() {
      return this.config.GEN_AI_MARKETPLACE_ENABLED && this.allowedVersion(4.0)
    },
    getDynamicCategories() {
      const processCategoriesLabel = this.allCategories.map((r) => {
        const formatLabel = r.label.toLowerCase().replaceAll(' ', '_')
        return {
          ...r,
          label: this.$t(`marketplace.sidebar.filter.${formatLabel}`),
        }
      })
      return processCategoriesLabel.length ? processCategoriesLabel : []
    },
    getAltLogo() {
      return this.config.THEME?.logoAlt ? this.config.THEME.logoAlt : ''
    },
    marketplaceLayout() {
      return {
        [this.config.MARKETPLACE_LAYOUT || 'marketplace-default-layout']: true,
      }
    },
    onlyYourPages() {
      // This is a rough implementation to custom user permission for a client.
      // This should be removed after we have setup a clear permission system in the builder.
      return this.config.ADD_ON_NAME === 'CONVRRT_LITE'
    },
    enableV2BuilderVersionSwitch() {
      return this.config.ENABLE_V2_BUILDER_VERSION_SWITCH
    },
    yourTemplatesActive() {
      return this.activeCategory === this.yourTemplates.id
    },
    archivedTemplatesActive() {
      return this.activeCategory === this.archivedTemplates.id
    },
    brandingActive() {
      return this.activeCategory === 'branding'
    },
    genAiTemplatesActive() {
      return this.activeCategory === 'gen-ai'
    },
    filteredTemplates() {
      if (this.yourTemplatesActive) {
        return removeDuplicateTemplates(this.yourTemplatesList)
      }
      if (this.archivedTemplatesActive) {
        return removeDuplicateTemplates(this.archivedTemplatesList)
      }

      if (this.genAiTemplatesActive) {
        const uniqueTemplates = this.genAiTemplateList
          .filter(
            (item) => typeof item === 'object' && item.name && item.thumbnail,
          )
          .reduce((acc, current) => {
            if (!acc.some((item) => item.siteId === current.siteId)) {
              acc.push(current)
            }
            return acc
          }, [])

        return uniqueTemplates.map((template) => ({
          ...template,
          id: template.siteId,
        }))
      }

      return _.chain(this.marketplaceTemplates)
        .filter((t) => {
          return this.activeCategory
            ? _.includes(
                Object.keys(t).length ? t.categories.map((c) => c.id) : {},
                this.activeCategory,
              )
            : true
        })
        .value()
    },
    isFirstTemplate() {
      return (
        _.chain(this.filteredTemplates).head().get('id').value() ===
        this.previewTemplatId
      )
    },
    isLastTemplate() {
      return (
        _.chain(this.filteredTemplates).last().get('id').value() ===
        this.previewTemplatId
      )
    },
    previewTemplate() {
      return _.find(this.filteredTemplates, { id: this.previewTemplatId })
    },
    previewTemplateIdx() {
      return _.indexOf(this.filteredTemplates, this.previewTemplate)
    },
    previewTitle() {
      return _.get(this.previewTemplate, 'name')
    },
    previewCta() {
      if (this.yourTemplatesActive) {
        return this.$t('marketplace.templates.label.preview')
      } else if (this.archivedTemplatesActive) {
        return this.$t('marketplace.templates.label.restore')
      } else {
        return this.$t('marketplace.preview.cta')
      }
    },
    filteredCategories() {
      return this.categories.filter((category) => {
        const isBrandingEnabled =
          this.showBrandingCategory && category.id === 'branding'
        const isGenAiEnabled =
          this.showGenAICategory && category.id === 'gen-ai'

        return (
          category.id == null ||
          isGenAiEnabled ||
          isBrandingEnabled ||
          (this.templatesCount[category.id] &&
            this.templatesCount[category.id] > 0)
        )
      })
    },
    tabUiCategories() {
      return {
        all: {
          id: null,
          label: this.$t(this.leftNavItemsAllTemplatesText),
          tabIndex: 0,
        },
        lead_gen: {
          id: 'lead_gen',
          label: this.$t('marketplace.sidebar.filter.leadgen'),
          tabIndex: 1,
        },
        'your-templates': {
          id: 'your-templates',
          label: this.$t('marketplace.templates.your_pages_label'),
          tabIndex: 2,
        },
      }
    },
    categories() {
      if (this.onlyYourPages) {
        // This is a rough implementation to custom user permission for a client.
        // This should be removed after we have setup a clear permission system in the builder.
        return [
          {
            id: null,
            label: this.$t(this.leftNavItemsAllTemplatesText),
            index: 0,
          },
          {
            id: 'your-templates',
            label: this.$t(this.leftNavItemsYourPagesTemplatesText),
            index: 23,
          },
        ]
      }
      if (this.config.ECOMMERCE && this.filterToEcommerce) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.activeCategory = 'e-commerce'
        return [
          { id: 'e-commerce', label: 'E-Commerce', index: 22 },
          {
            id: 'your-templates',
            label: this.$t(this.leftNavItemsYourPagesTemplatesText),
            index: 23,
          },
          this.archivedTemplates,
        ]
      } else {
        return [
          {
            id: null,
            label: this.$t(this.leftNavItemsAllTemplatesText),
            index: 0,
          },
          ...this.getDynamicCategories,
          ...(this.allowedVersion(4.0)
            ? [
                {
                  id: 'gen-ai',
                  label: 'Gen AI',
                  index: this.getDynamicCategories.length + 3,
                },
              ]
            : []),
          {
            id: 'your-templates',
            label: this.$t(this.leftNavItemsYourPagesTemplatesText),
            index: this.getDynamicCategories.length + 1,
          },
          {
            ...this.archivedTemplates,
            index: this.getDynamicCategories.length + 2,
          },
          ...(this.allowedVersion(4.0)
            ? [
                {
                  id: 'branding',
                  label: 'Branding',
                  index: this.getDynamicCategories.length + 4,
                },
              ]
            : []),
        ].filter((i) => i !== null)
      }
    },
    subTabCategories() {
      if (this.certifiedTemplatesMarketplaceUI) {
        const filteredSubCategories = this.categories.filter(
          (category) =>
            category.id == null ||
            (category.id !== 'your-templates' &&
              category.id !== 'archived-templates' &&
              this.templatesCount[category.id] &&
              this.templatesCount[category.id] > 0),
        )
        return [...filteredSubCategories]
      } else {
        return this.filteredCategories()
      }
    },
    moreActions() {
      const labelOrder = [
        {
          key: 'copy-template',
          text: this.$t('marketplace.templates.label.copyTemplate'),
        },
        {
          key: 'edit-template',
          text: this.$t('marketplace.templates.label.editTemplate'),
        },
        {
          key: 'archive',
          text: this.$t('marketplace.templates.archive.label'),
        },
      ]

      if (this.onlyYourPages) {
        return [labelOrder[1]]
      }

      if (this.marketplaceConfig.marketplaceCard.swapTemplateButtonAction) {
        return [labelOrder[1], labelOrder[0], labelOrder[2]]
      } else {
        return labelOrder
      }
    },
    popUpBtnColor() {
      return this.orgName === FRESHWORKS ? 'light' : 'warning'
    },
    rightSidebarVisibilityStatus: {
      // getter
      get() {
        if (this.isRightSidebarVisible !== null) {
          if (this.isRightSidebarVisible) {
            return {
              'slide-left': true,
              'slide-right': false,
            }
          } else if (!this.isRightSidebarVisible) {
            return {
              'slide-left': false,
              'slide-right': true,
            }
          }
        }
        return {}
      },
      // setter
      set: function (newValue) {
        if (newValue) {
          this.isRightSidebarVisible = {
            'slide-left': true,
            'slide-right': false,
          }
        } else {
          this.isRightSidebarVisible = {
            'slide-left': false,
            'slide-right': true,
          }
        }
      },
    },
    isTemplateActionsRightSidebarEnabled() {
      return this.config.TEMPLATE_ACTIONS_RIGHT_SIDEBAR
    },
    templatesDefaultViewFormat: {
      // TODO: LIST VALUES need to be changed back to GRID,
      // WHEN in organizationConfigs the key:orgID = convrrt || liondesk || keap-demo, then TEMPLATE_ACTIONS_RIGHT_SIDEBAR is true
      // Swapping the lines with GRID to the ones with LIST will active the right sidebar for development purposes
      // IMPORTANT, THE GRID LINES ARE THE DEFAULT, IF CHANGED MAKE SURE TO REVERT BEFORE PUSHING TO STAGE.
      get: function () {
        if (this.templatesViewFormat === null) {
          if (this.yourTemplatesActive) {
            return this.config.MY_PAGES_DEFAULT_VIEW
              ? this.config.MY_PAGES_DEFAULT_VIEW
              : 'GRID'
            // return this.config.MY_PAGES_DEFAULT_VIEW ? this.config.MY_PAGES_DEFAULT_VIEW : 'LIST'
          } else {
            return 'GRID'
            // return 'LIST'
          }
        }
        return this.templatesViewFormat
      },
      set: function (newVal) {
        this.templatesViewFormat = newVal
      },
    },
    siteUrl() {
      return this.selectedTemplateSite?.siteUrl || ''
    },
    radioGroupInitialValue() {
      if (this.isCustomUrlSet) {
        return 'custom_domain'
      } else if (this.isPublishedUrlSet) {
        return 'published_url'
      } else {
        return 'custom_domain'
      }
    },
    isPublishedUrlSet() {
      return (
        this.selectedTemplate &&
        this.selectedTemplateSite &&
        !!this.selectedTemplateSite.siteUrl
      )
    },
    isCustomUrlSet() {
      return (
        this.selectedTemplate &&
        this.selectedTemplateSite &&
        !!this.selectedTemplateSite.siteUrl
      )
    },
    copyOrEditPageButton() {
      if (this.marketplaceConfig.marketplaceCard.swapTemplateButtonAction) {
        return this.marketplaceConfig.marketplaceCard.editPageButton
      }
      return this.marketplaceConfig.marketplaceCard.copyPageButton
    },
    disablePublish() {
      /**
       * Function to grant/permit a user ability to publish a page
       * Config key: USER_PUBLISH_PERMISSION_CHECK: boolean
       * @returns boolean
       * If the configuration permission USER_PUBLISH_PERMISSION_CHECK is turned on for an organization.
       * Then only we should obey the value of user_activated set in the cookie.
       */
      if (this.config.HIDE_PUBLISH_ACCESS) {
        return true
      }
      //Config is not set or on
      if (!this.config.USER_PUBLISH_PERMISSION_CHECK) {
        return false
      }

      //Get the cookie value
      const get_user_active_status = this.getCookie?.['user_activated']

      if (!get_user_active_status) {
        return true
      }

      return false
    },
    isTabsUiEnabled() {
      return this.config.CHOOSE_A_TEMPLATE_TABS_UI
    },
    fixedRightSidebar() {
      return this.config.FIXED_RIGHT_SIDEBAR
    },
    rightSidebarClasses() {
      return {
        'right-sidebar-wrap': !this.isTabsUiEnabled,
        'right-sidebar-wrap-tabs-ui': this.isTabsUiEnabled,
        'fixed-right-sidebar-wrap-tabs-ui': this.fixedRightSidebar,
        'absolute-right-sidebar-wrap-tabs-ui': !this.fixedRightSidebar,
        ...this.rightSidebarVisibilityStatus,
      }
    },
    showTopLogo() {
      return Boolean(this.topNavConfig['marketplace']?.showTopLogo)
    },
    topLogo() {
      return this.config['THEME']?.logo
    },
    isStartFromScratchCardEnabled() {
      if (this.onlyYourPages) {
        // This is a rough implementation to custom user permission for a client.
        // This should be removed after we have setup a clear permission system in the builder.
        return false
      }
      return this.config.START_FROM_SCRATCH_ENABLED
    },
    showVersionSwitchAlert() {
      return (
        this.enableV2BuilderVersionSwitch &&
        !this.isGridBuilder &&
        !this.activeCategory
      )
    },
    showFreeTrial() {
      return this.config.TRIAL_MODE
    },
    marketplaceHeaderTitleDisabled() {
      return this.config.MARKETPLACE_HEADER_TITLE_DISABLED
    },
    marketplaceHeaderDisabled() {
      return this.config.MARKETPLACE_HEADER_DISABLED
    },
    customSDKMenuActive() {
      return this.config.CUSTOM_SDK_NAV_MENU_ACTIVE
    },
    marketplaceHeaderTitle() {
      return this.brandingActive
        ? this.$t('marketplace.header.branding.title')
        : this.$t('editor.header.marketplace.title')
    },
    intentList() {
      return [...GenAIIntent]
    },
    disableSortGenAiTemplates() {
      return (
        this.genAIData.selectedIntents.length < 1 ||
        this.genAIData.selectedIntentGoals.length < 1
      )
    },
  },
  mounted() {
    this.playedVideo = localStorage.getItem('playedVideo') || ''
    customClientConfig(this.user.orgID)
  },
  async created() {
    // Set configurations for tenants if they exist
    this.setTheme(this.config.GLOBAL_THEME)
    this.setTopNavConfig(this.config.TOP_NAV_CONFIG)
    this.setLeftNavConfig(this.config.LEFT_NAV_CONFIG)
    this.setMarketplaceConfig(this.config.MARKETPLACE_CONFIG)
    this.setIconConfig(this.config.ICON_CONFIG)
    this.setGlobalButtonConfig(this.config.GLOBAL_BUTTON_CONFIG)
    this.setGlobalCheckBoxConfig(this.config.GLOBAL_CHECKBOX_CONFIG)
    // Set configurations for tenants End

    const { categories = '' } = this.query
    this.filterToEcommerce = categories.includes('e-commerce')
    this.fetchAllCategories()

    if (!this.onlyYourPages) {
      this.loadMoreMarketplaceTemplates()
    }

    if (this.showGenAICategory) {
      await this.loadMoreGenAiTemplates(genAIIntentList, genAIIntentGoalList)
    }

    await this.loadMoreSites()
    await this.loadMorePersonalTemplates()
    await this.loadMoreArchivedTemplates()

    await this.fetchTemplateCount()

    //this flag toggles meclabs custom tab
    this.subTabIsActive = this.certifiedTemplatesMarketplaceUI
  },
  beforeDestroy() {
    this.resetRightSideBarVariables()
  },
  methods: {
    ...mapActions({
      createSiteFromTemplate: actions.MARKETPLACE_CREATE_SITE_FROM_TEMPLATE,
      openPreviewWindow: actions.PAGE_PREVIEW_TOGGLE,
      archiveSite: actions.SITE_ARCHIVE,
      restoreSite: actions.SITE_RESTORE,
      loadMarketplaceTemplates: actions.MARKETPLACE_LOAD_TEMPLATES,
      mixpanelTrack: actions.MIXPANEL_TRACK,
      templateFetchData: actions.TEMPLATE_FETCH_DATA,
      loadDomains: actions.MARKETPLACE_LOAD_DOMAINS,
      disconnectDomain: actions.CUSTOM_DOMAIN_DISCONNECT,
      showPagePreviewInfo: actions.PAGE_PREVIEW_INFO_TOGGLE,
      fetchAllCategories: actions.FETCH_ALL_CATEGORIES,
    }),
    ...mapMutations({
      hideEditorNameDialog: mutations.HIDE_EDITOR_NAME_DIALOG,
      showEditorNameDialog: mutations.SHOW_EDITOR_NAME_DIALOG,
      updateDomains: mutations.MARKETPLACE_UPDATE_DOMAINS,
      showPagePreviewInfo: mutations.PAGE_PREVIEW_INFO_TOGGLE,
      toggleScheduleDemoModal: mutations.SET_MODAL,
    }),
    ...mapActions('globalTheme', {
      setTheme: 'setTheme',
      setTopNavConfig: 'setTopNavConfig',
      setLeftNavConfig: 'setLeftNavConfig',
      setMarketplaceConfig: 'setMarketplaceConfig',
      setIconConfig: 'setIconConfig',
      setGlobalButtonConfig: 'setGlobalButtonConfig',
      setGlobalCheckBoxConfig: 'setGlobalCheckBoxConfig',
      setActiveCategory: 'setActiveCategory',
    }),
    ...mapActions('loader', {
      startLoader: 'start',
      stopLoader: 'stop',
    }),
    ...chooseTemplateHelper,
    async startFromScratch() {
      console.debug('clicked start from scratch')
      if (this.locked) {
        console.debug('start from scratch is locked')
        console.error(
          'You already clicked this once... something went wrong. If you are having issues refresh your browser and try again.',
        )
        return
      }
      this.locked = true
      try {
        const { data } = await this.createSiteFromTemplate()
        this.showEditorNameDialog()
        this.$router.push({
          name: cvtRouteNodes.editorDesign,
          params: {
            siteId: data.id,
          },
        })

        this.mixpanelTrack({
          event: 'Start From Scratch',
          category: 'templates',
          data: { template_id: data.id },
        })
      } catch (e) {
        if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
          Promise.reject(e)
        }
        this.hideEditorNameDialog()
        this.locked = false
        throw e
      }
    },
    selectCategory(cat) {
      this.setActiveCategory(cat.id)
      this.resetRightSideBarVariables()
      this.resetTemplateViewFormat()
    },
    onSelectCategory(cat) {
      this.subTabIsActive = true
      if (cat.id !== null) {
        this.subTabIsActive = false
      }
      this.selectCategory(cat)
    },
    onTabCategoryClick(tabComponent) {
      let selectedCategory = null
      let tabIndex = tabComponent.tabId

      for (let category of this.filteredCategories) {
        if (category.index == tabIndex) {
          selectedCategory = category
          this.selectCategory(selectedCategory)
        }
      }
    },
    fmtGroupName(id) {
      id = id || 1
      return _.chain(this.categories).find({ id }).get('label').value()
    },
    overlayIsActive(templateId) {
      return this.showOverlayOn === templateId
    },
    disableOverlay() {
      this.showOverlayOn = null
    },
    activateOverlay(templateId) {
      this.showOverlayOn = templateId
    },
    showPreviewWindow(templateId) {
      this.showPagePreviewInfo(false)
      this.previewTemplatId = templateId
      // let temp = _.find(this.filteredTemplates, { id: this.previewTemplatId })
      this.templateFetchData(templateId)
      // console.log("Temp: ", templateId)
      this.openPreviewWindow(true)
      if (this.yourTemplatesActive) {
        this.mixpanelTrack({
          event: 'Copy Template',
          category: 'templates',
          data: { template_id: templateId },
        })
      } else if (this.archivedTemplatesActive) {
        this.mixpanelTrack({
          event: 'Restore archived template',
          category: 'templates',
          data: { template_id: templateId },
        })
      } else {
        this.mixpanelTrack({
          event: 'Use This Template',
          category: 'templates',
          data: { template_id: templateId },
        })
      }
    },
    getTemplateIdByIndex(templateIndex) {
      return _.chain(this.filteredTemplates)
        .get(templateIndex)
        .get('id')
        .value()
    },
    prevTemplate() {
      let templateIndex = this.previewTemplateIdx - 1
      if (templateIndex < 0) {
        templateIndex = 0
      }
      this.previewTemplatId = this.getTemplateIdByIndex(templateIndex)
    },
    nextTemplate() {
      let templateIndex = this.previewTemplateIdx + 1
      if (templateIndex + 1 > this.filteredTemplates.length) {
        templateIndex = this.filteredTemplates.length - 1
      }
      this.previewTemplatId = this.getTemplateIdByIndex(templateIndex)
    },
    async useTemplate(templateId) {
      console.debug('using template', templateId)
      const { data } = await this.createSiteFromTemplate(templateId)
      console.debug('cloned template', data)
      this.showEditorNameDialog()
      try {
        await this.$router.push({
          name: cvtRouteNodes.editorDesign,
          params: {
            siteId: data.id,
          },
        })
      } catch (e) {
        if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
          await Promise.reject(e)
        }
      }
      if (this.yourTemplatesActive) {
        this.mixpanelTrack({
          event: 'Copy Template',
          category: 'templates',
          data: { template_id: templateId },
        })
      } else if (this.archivedTemplatesActive) {
        this.mixpanelTrack({
          event: 'Restore archived template',
          category: 'templates',
          data: { template_id: templateId },
        })
      } else {
        this.mixpanelTrack({
          event: 'Use This Template',
          category: 'templates',
          data: { template_id: templateId },
        })
      }
    },
    editSite(template) {
      this.mixpanelTrack({
        event: 'Edit Template',
        category: 'templates',
        data: { template_id: template.id },
      })
      this.$router
        .push({
          name: cvtRouteNodes.editorDesign,
          params: {
            siteId: template.id,
          },
        })
        .catch((e) => {
          if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
            Promise.reject(e)
          }
        })
    },
    async openArchivePrompt(template) {
      this.showArchiveDialog = true
      this.archiveTemplate = template
    },
    async onArchiveTemplate() {
      const { id, name } = this.archiveTemplate
      this.archiving = true
      await this.archiveSite(id)
      this.yourTemplatesList = this.yourTemplatesList.filter((t) => t.id !== id)
      this.yourTemplatesListAll = this.yourTemplatesListAll.filter(
        (t) => t.id !== id,
      )
      this.archivedTemplatesList.push(this.archiveTemplate)
      this.$message({ type: 'info', message: `Archived: ${name}` })
      this.archiveTemplate = null
      this.selectedTemplate = null
      this.archiving = false
      this.showArchiveDialog = false

      await this.fetchTemplateCount()
    },
    abortTemplateArchive() {
      this.archiveTemplate = null
      this.showArchiveDialog = false
    },
    loadMoreMarketplaceTemplates: _.debounce(async function () {
      this.loading = true
      await this.loadMarketplaceTemplates({
        ...this.marketplacePagination,
        productVersion: this.currentProductVersionNumber,
      })
      this.marketplacePagination.offset += this.marketplacePagination.limit
      this.loading = false
    }, 1000),
    async loadMoreArchivedTemplates() {
      this.loading = true
      const { data } = await sitesAPI.all({
        ...this.archivedTemplatesPagination,
        archived: true,
      })
      this.archivedTemplatesList = this.archivedTemplatesList.concat(data)
      this.archivedTemplatesPagination.offset +=
        this.archivedTemplatesPagination.limit
      this.loading = false
    },
    async loadMorePersonalTemplates() {
      let submitData = {
        ...this.personalTemplatesPagination,
        dateCreated: this.isCreatedSelected ? 1 : 0,
        dateUpdated: this.isLastUpdatedSelected ? 1 : 0,
        isPublished: this.isPublished ? 1 : 0,
        search: this.searchQuery ? this.searchQuery.trim() : '',
      }
      this.loading = true
      const { data } = await sitesAPI.all(submitData)
      this.yourTemplatesListAll = this.yourTemplatesListAll.concat(data)
      this.personalTemplatesPagination.offset +=
        this.personalTemplatesPagination.limit
      this.yourTemplatesList = this.yourTemplatesListAll.slice(
        0,
        this.personalTemplatesPagination.offset +
          this.personalTemplatesPagination.limit,
      )
      this.loading = false
    },
    onSortGenAiTemplates() {
      const intentList = this.genAIData.selectedIntents
      const intentGoalList = this.genAIData.selectedIntentGoals
      this.loadMoreGenAiTemplates(intentList, intentGoalList)
    },
    async loadMoreGenAiTemplates(intent, intentGoal) {
      try {
        this.loading = true
        const data = await genAIApi.genAiTemplates({ intent, intentGoal })
        this.genAiTemplateList = [...data]
        this.loading = false
      } catch (err) {
        console.error(err, 'err in loadMoreGenAiTemplates')
        this.loading = false
      }
    },
    setGenAISelectedIntents(valueArray) {
      this.genAIData.selectedIntents = valueArray
    },
    setGenAISelectedIntentGoals(valueArray) {
      this.genAIData.selectedIntentGoals = valueArray
    },
    async switchToBuilderVersion() {
      this.startLoader()
      await sitesAPI.switchBuilderVersion({
        enableV2: true,
      })
      this.$router.go()
    },
    closeDemoModal() {
      this.toggleScheduleDemoModal(false)
    },
    goToLandingPageDescription() {
      this.$router.push({
        name: cvtRouteNodes.aboutLandingPage,
      })
    },
    async fetchTemplateCount() {
      this.templatesCount = await marketplaceAPI
        .countTemplates({})
        .then(({ data }) => data)
    },
    allowedVersion(version) {
      return this.currentProductVersionNumber >= version
    },
  },
}
</script>

<style lang="scss">
@import './helpers/ChooseATemplate.style.scss';
</style>
