import { cvtRouteNodes } from "../../../utils/routes"

export default {
  components: {},
  props: {
    emailAddresses: {
        type: Array,
        default: [{ email: '' }]
    },
    loading: {
        type: Boolean,
        default: false
    },
    disableCancelBtn: false
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    cancelBtn(): void {
      this.$router.back()
    },
    sendInvite(){
        this.$emit('onSendInvite')
    },

    addNewInput(fields): void {
      fields.push({ email: '' });
    },

    removeInput(index, fields) {
      fields.splice(index, 1);
    },

    onSkipItInvite() {
      this.$router.push({
        name: cvtRouteNodes.enterpriseIntegrateBuilderAuthCustomer
      })
    }
  },
}
