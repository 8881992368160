
<div class="create-new-custom-field">
  <button
    class="text-capitalize btn btn-outline-secondary btn-block"
    @click="open"
  >
    {{ $t("element.property.form.option.create_field") }}
  </button>

  <cvt-dialog
    :show="dialogVisible"
    :height-auto="true"
    size="sm"
    @close="handleClose"
  >
    <template #title>
      <h5>{{ $t('element.property.form.field.custom.contact.attr') }}</h5>
    </template>
    <div class="form-group">
      <label for="new-contact-attr-name">{{ $t("element.properties.section.title") }}</label>
      <cvt-input
        type="text"
        :value="attributes.name || ''"
        :label="$t('element.property.form.field.custom.contact.name')"
        :placeholder="$t('element.property.form.field.custom.contact.name')"
        @input="(e) => updateAttributeName(e)"
      />
      <small>{{ $t("element.property.form.field.custom.contact.help") }}</small>
    </div>

    <div class="form-group">
      <cvt-select
        :value="attributes.type"
        :label="$t('element.property.form.field.custom.type')"
        @input="(value) => selectedType(value)"
      >
        <option
          v-for="attrType in types"
          :key="attrType.value"
          :value="attrType.value"
          v-text="attrType.label"
        />
      </cvt-select>
    </div>

    <div
      v-if="categories.length"
      class="form-group"
    >
      <cvt-select
        :value="attributes.category"
        :label="$t('element.property.form.field.custom.category')"
        @input="(value) => selectedCategory(value)"
      >
        <option
          v-for="cat in categories"
          :key="cat.value"
          :value="cat.value"
          v-text="cat.label"
        />
      </cvt-select>
    </div>
    <template #modalFooter>
      <span
        class="dialog-footer d-flex"
      >
        <cvt-button
          class="mr-1"
          color="light"
          :text="$t('element.property.cta.cancel')"
          @click="reset"
        />
        <cvt-button
          :text="$t('element.property.cta.confirm')"
          @click="confirm"
        />
      </span>
    </template>
  </cvt-dialog>
</div>
