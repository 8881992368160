
<div class="ib-header-wrap">
  <div class="ib-left-aligned-content">
    <div class="ib-logo-wrap">
      <a href="https://www.convrrt.com">
        <img
          class="ib-header-logo"
          src="/img/enterprise-convrrt-logo.svg"
          alt="Convrrt Logo"
        />
      </a>
    </div>
    <div class="ib-header-txt-wrap">
      <p class="ib-header-txt">Integrate builder in your app</p>
    </div>
  </div>

  <div class="ib-right-aligned-content">
    <div class="ib-help-btn-wrap">
      <cvt-button
        :class="'ib-help-btn'"
        :mode="'LIGHT'"
        :color="'light'"
        :icon="'question-circle'"
        :text="''"
      >
      </cvt-button>
    </div>

    <div class="ib-done-btn-wrap">
      <cvt-button
        class="ib-done-btn"
        :color="'light'"
        :mode="'LIGHT'"
        :text="'Done'"
      >
      </cvt-button>
    </div>
  </div>
</div>
