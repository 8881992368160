<template>
  <div>
    <cvt-collapse
      :accordion="true"
      :theme="mode === 'LIGHT' ? 'light' : 'dark'"
      :prepend-chevron="prependChevron"
    >
      <cvt-collapse-item title="Style" name="style" :initial-active="true">
        <div class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t('elements.properties.background.color') }}
          </div>
          <div class="col-12">
            <colors alpha :mode="mode" />
          </div>
        </div>
        <div class="divider mt-2" />
        <div class="nestedInnerItem">
          <cvt-select
            :value="borderStyle"
            mode="dropdown"
            :background-mode="mode"
            :floating-label="false"
            :label="$t('elements.properties.border.style')"
            @input="(value) => selectAction(value, 'borderStyle')"
          >
            <option
              v-for="style in borderStyles"
              :key="style"
              :value="style"
              v-text="style"
            />
          </cvt-select>
          <div style="margin: 5px" />
          <cvt-input
            v-model="borderColor"
            style="width: max-content"
            type="text"
            :clearable="false"
            :label="$t('elements.properties.border.color')"
          />
        </div>

        <div class="divider mt-2" />

        <div class="row">
          <div class="col-md-12 d-flex align-items-center text-capitalize">
            {{ $t('elements.properties.border.position') }}
          </div>
        </div>

        <div class="row">
          <div class="col">
            <cvt-radio-group
              :key="borderPositionKey"
              orientation="horizontal"
              :initial-value="positionDefVal"
            >
              <cvt-radio-button
                :value="pv.all"
                :disabled="disableAllBorder"
                type="multiselect"
                @radioClick="($event) => updatePosition($event, 'all')"
              >
                <cvt-icon icon="border-all"></cvt-icon>
              </cvt-radio-button>
              <cvt-radio-button
                :value="pv.top"
                type="multiselect"
                @radioClick="($event) => updatePosition($event, 'top')"
              >
                <cvt-icon icon="border-top"></cvt-icon>
              </cvt-radio-button>
              <cvt-radio-button
                :value="pv.btm"
                type="multiselect"
                @radioClick="($event) => updatePosition($event, 'btm')"
              >
                <cvt-icon icon="border-bottom"></cvt-icon>
              </cvt-radio-button>
              <cvt-radio-button
                :value="pv.lft"
                type="multiselect"
                @radioClick="($event) => updatePosition($event, 'lft')"
              >
                <cvt-icon icon="border-left"></cvt-icon>
              </cvt-radio-button>
              <cvt-radio-button
                :value="pv.ryt"
                type="multiselect"
                @radioClick="($event) => updatePosition($event, 'ryt')"
              >
                <cvt-icon icon="border-right"></cvt-icon>
              </cvt-radio-button>
            </cvt-radio-group>
          </div>
        </div>

        <div class="divider mt-2" />

        <div class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t('elements.properties.border.width') }}
          </div>
          <div class="col-12">
            <cvt-slider
              id="slider-default"
              :tooltips-enabled="true"
              :step-marks-enabled="true"
              class="slider"
              :min="1"
              :max="10"
              :value="borderWidth"
              :step="1"
              @change="onChange"
            />
          </div>
        </div>
        <div class="divider mt-2" />

        <div class="row">
          <div class="col-md-12 mb-3 d-flex align-items-center text-capitalize">
            {{ $t('elements.properties.border.radius') }}
          </div>
          <div class="col-12">
            <resize
              size-key="borderRadius"
              :min="0"
              :step="5"
              :max="100"
              :label="$t('elements.properties.border.radius')"
              hide-label
            />
          </div>
        </div>
        <div class="divider mt-2" />

        <div class="row">
          <div class="col">
            <h6>{{ $t('elements.properties.shadow.label') }}</h6>
          </div>
          <div class="col-12">
            <cvt-radio-group orientation="horizontal" :initial-value="shadow">
              <cvt-radio-button
                size="md"
                value="shadow-none"
                :shape="RadioButtonShape"
                @radioClick="updateImgShadow"
              >
                {{ $t('elements.properties.option.none') }}
              </cvt-radio-button>
              <cvt-radio-button
                size="md"
                value="shadow"
                :shape="RadioButtonShape"
                @radioClick="updateImgShadow"
              >
                {{ $t('elements.properties.shadow.normal') }}
              </cvt-radio-button>
              <cvt-radio-button
                size="md"
                value="shadow-lg"
                :shape="RadioButtonShape"
                @radioClick="updateImgShadow"
              >
                {{ $t('elements.properties.shadow.deep') }}
              </cvt-radio-button>
            </cvt-radio-group>
          </div>
        </div>
      </cvt-collapse-item>

      <!-- Implement background image option -->
      <cvt-collapse-item
        :title="$t('elements.properties.background.image')"
        name="bgImage"
      >
        <element-image-manager
          image-key="background/image"
          :hook="selected.updateBgSrc"
          :background-img-position="backgroundPosition"
          :can-change-postion="true"
          @backgroundImgPositionChanged="onBgImgPositionChanged"
        />

        <background-size-repeat> </background-size-repeat>

        <div class="divider mt-2" />
        <background-color-overlay :mode="mode"> </background-color-overlay>
      </cvt-collapse-item>

      <cvt-collapse-item
        :title="$t('elements.properties.spacing.label')"
        name="spacing"
      >
        <spacing-ctrl />
      </cvt-collapse-item>

<!--      <cvt-collapse-item-->
<!--        :title="$t('elements.properties.visibility.label')"-->
<!--        name="visibility"-->
<!--      >-->
<!--        <visibility-ctrl :mode="mode" />-->
<!--      </cvt-collapse-item>-->
    </cvt-collapse>
  </div>
</template>

<script>
import * as _ from 'lodash'
import Resize from './Resize.vue'
import SpacingCtrl from './SpacingCtrl.vue'
import Colors from '../theme/Colors.vue'
import ElementImageManager from './ElementImageManager.vue'
import BackgroundSizeRepeat from './BackgroundSizeRepeat.vue'
import BackgroundColorOverlay from './BackgroundColorOverlay.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import * as getters from '../../../store/modules/getters'
import * as actions from '../../../store/modules/actions'

export default {
  components: {
    Resize,
    SpacingCtrl,
    Colors,
    ElementImageManager,
    BackgroundSizeRepeat,
    BackgroundColorOverlay,
  },
  props: {
    mode: {
      type: String,
      default: 'LIGHT',
    },
    prependChevron: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTabs: [
        'style',
        // 'bgImage',
        // 'color',
        // 'padding'
      ],
      borderStyles: [
        'none',
        'solid',
        // 'ridge',
        // 'inset',
        // 'outset',
        'dashed',
        'dotted',
        'double',
        'groove',
      ],
      borderWidth: 1,
      positionDefVal: 'set',
      pv: {
        all: 'set',
        top: '',
        btm: '',
        lft: '',
        ryt: '',
      },
      borderPositionKey: 1,
    }
  },
  computed: {
    ...mapGetters({
      selected: getters.SELECTOR_SELECTED,
      viewmode: getters.VIEW_MODE,
    }),
    ...mapState('globalTheme', {
      RadioButtonShape: ({ globalTheme }) =>
        globalTheme.RadioButtonShape !== undefined
          ? globalTheme.RadioButtonShape
          : '',
    }),
    sizeKey() {
      return this.viewmode === 'desktop' ? 'size' : 'sizePhone'
    },
    borderStyle: {
      get() {
        return this.selected.borderStyle || 'none'
      },
      set: _.throttle(function (value) {
        return this.updateProp({ key: 'borderStyle', value })
      }, 300),
    },
    borderColor: {
      get() {
        return this.selected.borderColor
      },
      set: _.throttle(function ({ target }) {
        return this.updateProp({ key: 'borderColor', value: target.value })
      }, 300),
    },
    shadow: {
      get() {
        return this.selected.shadow
      },
      set(value) {
        return this.updateProp({
          key: 'shadow',
          value,
        })
      },
    },
    backgroundPosition: {
      get() {
        return _.get(this, 'selected.background.imagePosition', {})
      },
      set(value) {
        return this.updateProp({ key: 'background/imagePosition', value })
      },
    },
    borderPosition: {
      get() {
        return _.get(this, 'selected.borderPosition', {})
      },
      set(value) {
        return this.updateProp({ key: 'borderPosition', value })
      },
    },
    disableAllBorder() {
      if (
        this.pv.top != '' ||
        this.pv.btm != '' ||
        this.pv.lft != '' ||
        this.pv.ryt != ''
      ) {
        return true
      }

      return false
    },
  },
  created() {
    this.borderWidth = this.selected.borderWidth || 1

    if (this.borderPosition && Object.keys(this.borderPosition).length) {
      this.pv = this.borderPosition
    }
    this.setDefaultAllBorder()
  },
  methods: {
    ...mapActions({
      updateProp: actions.SELECTOR_UPDATE_PROP,
    }),
    onChange: _.throttle(function (value) {
      this.borderWidth = value
      return this.updateProp({ key: 'borderWidth', value })
    }, 300),
    selectAction(value, computedProperty) {
      if (computedProperty === 'borderStyle') {
        this.borderStyle = value
      }
    },
    updateImgShadow(value) {
      this.shadow = value
    },
    onBgImgPositionChanged(eventDetails) {
      this.backgroundPosition = this.formatPositionData(eventDetails)
    },

    formatPositionData(eventDetails) {
      let x = 0
      let y = 0
      let calculatedX = 0
      let calculatedY = 0

      if (eventDetails.eventType === 'mouseDrag') {
        ;({ x, y } = eventDetails.eventData.position)

        calculatedX = ((x - 10) / 2.06).toFixed()
        calculatedY = ((y - 10) / 1.84).toFixed()
      } else if (eventDetails.eventType === 'mouseClick') {
        // when eventType is mouseClick, it only gets position object in eventData
        ;({ x, y } = eventDetails.eventData.position)

        // in this case the marker was not taking width and height, so actual values are implemented
        calculatedX = (x / 2.21).toFixed()
        calculatedY = (y / 2.0).toFixed()
      }

      return {
        rawPos: {
          // raw position
          x: x,
          y: y,
        },
        calcPos: {
          // calculated position
          x: calculatedX,
          y: calculatedY,
        },
      }
    },

    updatePosition(key, from) {
      if (key == '') {
        this.pv[from] = this.positionDefVal
      } else {
        this.pv[from] = ''
      }

      this.setDefaultAllBorder()
      this.borderPositionKey++;
    },

    setDefaultAllBorder() {
      if (
        this.pv.top != '' ||
        this.pv.btm != '' ||
        this.pv.lft != '' ||
        this.pv.ryt != ''
      ) {
        this.pv.all = ''
      } else {
        this.pv.all = this.positionDefVal
      }

      this.borderPosition = this.pv
    },
  },
}
</script>

<style lang="scss">
.nestedInnerItem {
  display: flex;
}

.nestedInnerItem div[identity='FormGroup'] > label {
  line-height: inherit;
}
</style>
