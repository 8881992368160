
<div class="galley__item" @mouseenter="showOverlay" @mouseleave="hideOverlay">
  <div v-show="overlay" class="galley__item__overlay">
    <div class="galley__item__overlay__content d-flex flex-column justify-content-center align-items-center">
      <a v-show="button" href="#" class="btn btn-primary" @click.prevent.stop="use">{{ $t("imagesearch.item.use_this_image") }}</a>
      <i v-show="loader" class="fa fa-spinner fa-spin"></i>
      <p v-show="loader"><i>{{ $t("imagesearch.loading") }}</i></p>
      <p v-show="error" class="text-danger"><i v-text="error"></i></p>
    </div>
  </div>
  <img v-show="!overlay" class="img-fluid" :src="image.gallery" alt="">
</div>
