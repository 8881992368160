export default {
  self: this,
  textHeaderColor (props) {
    switch (props.themeing) {
      case '':
      case 'light':
        return this.self.color({ themeing: 'dark' }, 500)
      default:
        return this.self.color({ themeing: 'dark' }, 100)
    }
  },
  textContentColor (props) {
    switch (props.themeing) {
      case 'dark':
        return this.self.color(props, 100)
      default:
        return this.self.color({ themeing: 'dark' }, 500)
    }
  },
  borderTopColor (props) {
    switch (props.themeing) {
      case 'dark':
        return this.self.borderColor(props, 100)
      case '':
      case 'light':
        return this.self.borderColor({ themeing: 'dark' }, 500)
      default:
        return this.self.borderColor(props, 500)
    }
  },
  tinyCollapseHeaderColor (props) {
    switch (props.themeing) {
      case 'dark':
        return this.self.color(props, 100)
      case '':
      case 'light':
        return this.self.color({ themeing: 'dark' }, 500)
      default:
        return this.self.color(props, 500)
    }
  },
  textContentHoverColor (props) {
    switch (props.themeing) {
      case 'dark':
        return this.self.color(props, 200)
      case '':
      case 'light':
        return this.self.color({ themeing: 'dark' }, 600)
      default:
        return this.self.color(props, 600)
    }
  }
}
