export default function loadZendesk(
  key: string,
  language: string,
  settings: any = {},
) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.async = true
    script.id = 'ze-snippet'
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`
    document.head.appendChild(script)

    script.onload = () => {
      console.log('Zendesk script loaded')

      // Set Zendesk settings after script loads
      window.zESettings = {
        webWidget: {
          ...settings.webWidget, // Merge custom settings with defaults
        },
        ...settings, // Merge other top-level settings (if applicable)
      }
      resolve(window['zE']) // Assuming Zendesk exposes functions through 'zE'
      window['zE']('webWidget', 'setLocale', language)
    }

    script.onerror = (err) => reject(err)
    // window.zE('webWidget', 'setLocale', language)
  })
}
