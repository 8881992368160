
<div
  :id="vnodeId"
  class="cvt-editor-icon"
  :class="classes"
  draggable="true"
  @click="select"
  @mouseover="target"
  @contextmenu.prevent.stop="showContextMenu"
  @dblclick.stop.prevent="openIconPicker"
  @dragstart="dragstart"
  @dragend="dragend"
>
  <drag-and-drop-zone
    :z-index="dropzoneZIndex"
    @dropped="addElementToSide"
  ></drag-and-drop-zone>

  <div :class="iconWrapperClasses">
    <a
      v-if="href"
      :href="href"
      target="_blank"
      :class="iconLinkWrapperClasses"
    >
      <icon-template :set="set" :selector="selector" :class="iconClasses" />
    </a>
    <icon-template
      v-if="!href"
      :set="set"
      :selector="selector"
      :class="iconClasses"
    />
  </div>
  <template v-if="showActionButtonsOnClick && !isGridBuilder">
    <!--THis is only here because of freshwork in v1 gridbuilder,
    once they migrate to v2 or v3, it will become irrelevant-->
    <action-buttons
      v-if="selected"
      @duplicate="duplicateAndValidate"
      @remove="removeAndValidate"
    />
  </template>
</div>
