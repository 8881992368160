import { styled, VueEmotion } from '@egoist/vue-emotion'
import Vue from '../../lib/utils/vue'

import { ContentPlaceholder } from './ContentPlaceholder'

Vue.use(VueEmotion)

const ImageContainer = styled('div')`
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: calc(100% * ${(props) => props.aspectRatio});
  }
`

const Image = styled('img')`
  position: absolute;
  max-width: 100%;
  top: 0;
`

const Placeholder = styled(ContentPlaceholder)`
  width: 100%;
  height: 100%;
  top: 0;
`

export const LazyImage = {
  name: 'LazyImage',
  data () {
    return {
      src: '',
      options: {},
      observer: {}
    }
  },
  created () {
    this.options = {
      root: null,
      rootMargin: '0px',
      threshold: this.threshold
    }
    this.observer = new IntersectionObserver(this.handleIntersect, this.options)
  },
  mounted () {
    this.observer.observe(this.$el)
  },
  beforeUpdate () {
    this.observer = new IntersectionObserver(this.handleIntersect, this.options)
    this.observer.observe(this.$el)
  },
  methods: {
    handleIntersect (entries, observer) {
      const threshold = this.usage === 'popupMarketplace' ? '0.6' : '0.8';
      entries.forEach(function (entry) {
        // threshold < entry.intersectionRatio
        // temporarily remove the threshold check to load images faster if there's a src.
        if (this.src != this.dataSrc) {
          this.loadDataSrc()
        }
      }, this)
    },
    loadDataSrc () {
      if (this.src == this.dataSrc) {
        return
      }

      this.src = this.dataSrc
    },
    error () {
      this.$emit('imgError', 'imgError')
    }
  },
  props: {
    dataSrc: {
      type: String,
      default: ''
    },
    aspectRatio: {
      type: Number,
      default: 190 / 300
    },
    mode: {
      type: String,
      default: 'LIGHT'
    },
    threshold: {
      type: Number,
      default: 0.6
    },
    imageLoadError: {
      type: Boolean,
      default: false
    },
    usage: {
      type: String,
      default: ''
    }
  },
  render: function (h) {
    return (
      <ImageContainer aspectRatio={this.aspectRatio}>
        {(this.dataSrc !== this.src) && <Placeholder mode={this.mode}></Placeholder>}
        { (!this.imageLoadError) && <Image ref='lazyImage' data-src={this.dataSrc} src={this.src} ></Image>}
        { (this.imageLoadError) && <Image ref='lazyImage' src={this.dataSrc} onerror={(e) => { this.error() }}></Image>}
      </ImageContainer >
    )
  }
}
