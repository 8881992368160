// Spacing
const Spacing = {
  SPACING_2: 2,
  SPACING_4: 4,
  SPACING_8: 8,
  SPACING_12: 12,
  SPACING_16: 16,
  SPACING_18: 18,
  SPACING_24: 24,
  SPACING_32: 32,
  SPACING_48: 48,
  SPACING_64: 64,
  SPACING_96: 96,
  SPACING_128: 128,
  SPACING_192: 192,
  SPACING_256: 256
}

// Typography
const FontFamily = {
  DEFAULT: '"Nunito", sans-serif'
}

const FontWeight = {
  LIGHT: 300,
  REGULAR: 400,
  SEMI_BOLD: 600,
  BOLD: 700
}

const FontSize = {
  FONT_SIZE_40: 40,
  FONT_SIZE_34: 34,
  FONT_SIZE_28: 28,
  FONT_SIZE_24: 24,
  FONT_SIZE_20: 20,
  FONT_SIZE_18: 18,
  FONT_SIZE_16: 16,
  FONT_SIZE_14: 14,
  FONT_SIZE_12: 12,
  FONT_SIZE_11: 11,
  FONT_SIZE_10: 10
}

const LineHeight = {
  LINE_HEIGHT_12: 12,
  LINE_HEIGHT_14: 14,
  LINE_HEIGHT_16: 16,
  LINE_HEIGHT_18: 18,
  LINE_HEIGHT_20: 20,
  LINE_HEIGHT_24: 24
}

// Shadows
const ShadowAlpha = {
  INSET: {
    ALPHA1: 0.32
  },
  REGULAR: {
    ALPHA1: {
      LIGHT: 0.16,
      DARK: 0.32
    },
    ALPHA2: 0.04
  },
  DEEP: {
    ALPHA1: {
      LIGHT: 0.24,
      DARK: 0.32
    },
    ALPHA2: 0.08
  }
}

// Colors
const Colors = {
  PRIMARY: {
    '100': '#E3E5FB',
    '200': '#C9CCF5',
    '300': '#A6ABE5',
    '400': '#868BCD',
    '500': '#5D62AB',
    '600': '#424892',
    '700': '#2E327B',
    '800': '#1D2065',
    '900': '#101453'
  },
  BASIC: {
    WHITE: '#FFF',
    '100': '#F0F2F6',
    '200': '#E2E6ED',
    '300': '#BCC2CB',
    '400': '#8A8E97',
    '500': '#494C53',
    '600': '#353A47',
    '700': '#242A3B',
    '800': '#171C30',
    '900': '#0E1227',
    '1000': '#060616',
    '1100': '#2B2B34'
  },
  SUCCESS: {
    '100': '#DFFAF1',
    '200': '#C2F8EA',
    '300': '#9DEBDD',
    '400': '#7ED8D0',
    '500': '#55BFBD',
    '600': '#3E9CA4',
    '700': '#2A7988',
    '800': '#195A6E',
    '900': '#10425B'
  },
  INFO: {
    '100': '#D2FBFD',
    '200': '#A7F1FC',
    '300': '#79DDF4',
    '400': '#56C6EB',
    '500': '#23A3DD',
    '600': '#1A7FBF',
    '700': '#1161A0',
    '800': '#0C4481',
    '900': '#06306A'
  },
  WARNING: {
    '100': '#FFF6D7',
    '200': '#FDEBAD',
    '300': '#FBDB84',
    '400': '#F7CC64',
    '500': '#F3B433',
    '600': '#D09225',
    '700': '#AE7318',
    '800': '#8C5711',
    '900': '#744309'
  },
  DANGER: {
    '100': '#FDE8D5',
    '200': '#FDCCAC',
    '300': '#F9A782',
    '400': '#F18563',
    '500': '#E74F30',
    '600': '#C73222',
    '700': '#A71A18',
    '800': '#860F17',
    '900': '#6F0917'
  }
}

// Icons, currently font-awesome
const IconSize = {
  ICON_SIZE_XS: 14,
  ICON_SIZE_SM: 16,
  ICON_SIZE_MD: 16,
  ICON_SIZE_LG: 20,
  ICON_SIZE_XL: 24
}
// RADII
const Radii = {
  RADIUS_PX_4: 4,
  RADIUS_PX_8: 8,
  RADIUS_PERCENT_50: 50
}

// Borders
const Border = {
  SIZE_1: 1
}

const BorderStyle = {
  SOLID: 'solid',
  DOTTED: 'dotted',
  DASHED: 'dashed'
}

export {
  Spacing,
  Colors,
  Radii,
  Border,
  BorderStyle,
  FontSize,
  FontWeight,
  FontFamily,
  LineHeight,
  ShadowAlpha,
  IconSize
}
