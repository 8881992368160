import { VueEmotion, styled } from '@egoist/vue-emotion'
import { mapGetters, mapState } from 'vuex'
import * as getters from '../../../../builder/src/js/store/modules/getters'
import Vue from '../../../lib/utils/vue'
import {
  FRESHWORKS,
  LEFT_SIDEBAR_STANDARD
} from '../../constants'
import CvtButton from '../Button'
import { CvtIcon } from '../icon/icon'
Vue.use(VueEmotion)

let backgroundColor, color, textFontSize, textFontWeight, borderColor, elevation
let Spacing, Radii, Border, Colors, orgName

const getDropDownMenuContainer = () => {
  return styled('div')`
    position: absolute;
    z-index: 1000;
    width: 100%;
    ${(props) =>
      props.dropUp ? `bottom: ${props.bottom}px;` : `top: ${props.top}px;`}
    ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
    padding: 8px 0;
    background-clip: padding-box;
    border: ${Border.SIZE_1}px solid rgba(0, 0, 0, 0.05);
    border-radius: ${Radii.RADIUS_PX_8}px;
    ${elevation({ mode: 'LIGHT' }, 300)}
  `
}

const getDropdownMenu = () => {
  return styled('div')`
    position: relative;
    width: auto;
    max-height: 50vh;
    overflow: auto;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: ${Spacing.SPACING_8}px 0;
    ${orgName === FRESHWORKS
      ? `padding: ${Spacing.SPACING_8}px 6px;`
      : `padding: ${Spacing.SPACING_8}px 0;`}
    margin: 0 0 0;
    ${textFontSize('md')}
    ${color({ themeing: 'dark' }, 500)}
    text-align: left;
    list-style: none;
    ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
    border-radius: ${Radii.RADIUS_PX_8}px;
  `
}

const getDropDownItemContainer = () => {
  return styled('span')`
    &:not(:first-child) > h6 {
      border-top: ${Border.SIZE_1}px solid;
      ${borderColor({ themeing: 'dark' }, 300)}
    }
  `
}

const getDropdownHeader = () => {
  return styled('h6')`
    display: block;
    padding: ${Spacing.SPACING_8}px ${Spacing.SPACING_16}px;
    margin-bottom: 0;
    ${textFontSize('md')}
    ${color({ themeing: 'dark' }, 1000)}
    white-space: nowrap;
  `
}

const getDropDownItemAnchor = () => {
  return styled('a')`
    display: inline-flex;
    width: 100%;
    padding: ${Spacing.SPACING_8}px ${Spacing.SPACING_16}px;
    clear: both;
    ${textFontWeight('LIGHT')}
    ${color({ themeing: 'dark' }, 500)}
    text-align: inherit;
    text-decoration: none;
    &:hover {
      ${orgName === FRESHWORKS && Colors.SMOKE
        ? backgroundColor({ themeing: 'smoke' }, 50) + `border-radius: 4px;`
        : backgroundColor({ themeing: 'dark' }, 300)}
      text-decoration: none;
      ${color({ themeing: 'dark' }, 500)}
    }
    white-space: nowrap;
    ${(props) =>
      props.checked && orgName === FRESHWORKS
        ? backgroundColor({ themeing: 'primary' }, 50) + `border-radius: 4px;`
        : `background-color: transparent;`}
    border: 0;
    ${textFontSize('md')}
    transition: background-color 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06),
      color 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
    ${(props) => {
      return props.chooseTemplateTenantStyles?.dropDownItemContainerStyles || ''
    }}
  `
}

const getDropDownTextSpan = () => {
  return styled('span')`
    flex: 1;
    overflow: hidden;
    white-space: break-spaces;
  `
}

const getSelectedCheckIcon = () => {
  return styled(CvtIcon)`
    ${
      orgName === FRESHWORKS
        ? color({ themeing: 'primary' }, 800)
        : color({ themeing: 'dark' }, 400)
    }
    margin-left: ${Spacing.SPACING_16}px;
  `
}

const getPrependIcon = () => {
  return styled(CvtIcon)`
    margin-right: ${Spacing.SPACING_16}px;
  `
}

const getSmallSpinner = () => {
  return styled('i')`
    position: absolute;
    top: calc(50% - ${Spacing.SPACING_8}px);
    left: calc(50% - ${Spacing.SPACING_8}px);
    width: ${Spacing.SPACING_16}px;
    height: ${Spacing.SPACING_16}px;
    display: inline-block;
    vertical-align: text-bottom;
    border: 0.2em solid;
    border-color: ${Colors.BASIC[500]};
    border-right-color: transparent;
    border-radius: ${Radii.RADIUS_PERCENT_50}%;
    animation: 1s spin linear infinite;
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  `
}

const getSpinnerContainer = () => {
  return styled('div')`
    position: relative;
    width: 100%;
    margin: 10px;
  `
}

const getActionButton = () => {
  return styled('div')`
    padding: ${Spacing.SPACING_8}px;
    ${backgroundColor({ themeing: 'dark' }, 'WHITE')}
    border-top: ${Border.SIZE_1}px solid;
    ${borderColor({ themeing: 'dark' }, 300)}
    border-radius: ${Radii.RADIUS_PX_8}px;
  `
}

export default {
  name: 'CvtDropdownMenu',
  props: {
    dropUp: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    filtered: {
      type: Array
    },
    selected: {
      type: Array
    },
    top: {
      type: Number,
      default: 38
    },
    bottom: {
      type: Number,
      default: 56
    },
    actionButton: {
      type: Boolean,
      default: false
    },
     dropdownMenuStyles: {
      type: Object,
      default: () => ({})
    },
  },
  methods: {
    hasItem(item) {
      return this.selected.find(
        (selectedItem) => selectedItem.value === item.value && item.value?.field_type !== 'default_input'
      )
    },
    isGroupSameAsPrevious(array, index) {
      if (array[index]?.group && array[index - 1]?.group) {
        return array[index]?.group === array[index - 1]?.group
      } else {
        return false
      }
    }
  },
  computed: {
    ...mapGetters('globalTheme', {
      textFontWeight: getters.GLOBAL_STYLE_FONT_WEIGHT,
      borderColor: getters.GLOBAL_STYLE_BORDER_COLOR,
      color: getters.GLOBAL_STYLE_COLOR,
      backgroundColor: getters.GLOBAL_STYLE_BACKGROUND_COLOR,
      textFontSize: getters.GLOBAL_STYLE_FONT_SIZE,
      elevation: getters.GLOBAL_STYLE_ELEVATION
    }),
    ...mapGetters({
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    ...mapState('globalTheme', {
      Spacing: ({ globalTheme }) => globalTheme.Spacing,
      Radii: ({ globalTheme }) => globalTheme.Radii,
      Colors: ({ globalTheme }) => globalTheme.Colors,
      Border: ({ globalTheme }) => globalTheme.Border,
      orgName: ({ globalTheme }) =>
        globalTheme?.OrgName !== undefined
          ? globalTheme.OrgName
          : LEFT_SIDEBAR_STANDARD
    }),
    chooseTemplateTenantStyles () {
      return this.config?.MARKETPLACE_CHOOSE_A_TEMPLATE_CONFIG?.customStyles
    }
  },
  created() {
    Spacing = this.Spacing
    Radii = this.Radii
    Colors = this.Colors
    Border = this.Border
    textFontWeight = this.textFontWeight
    borderColor = this.borderColor
    color = this.color
    backgroundColor = this.backgroundColor
    textFontSize = this.textFontSize
    elevation = this.elevation
    orgName = this.orgName
  },
  render: function (h) {
    const DropDownMenuContainer = getDropDownMenuContainer()
    const DropdownMenu = getDropdownMenu()
    const SpinnerContainer = getSpinnerContainer()
    const SmallSpinner = getSmallSpinner()
    const DropDownItemContainer = getDropDownItemContainer()
    const DropdownHeader = getDropdownHeader()
    const DropDownItemAnchor = getDropDownItemAnchor()
    const PrependIcon = getPrependIcon()
    const DropDownTextSpan = getDropDownTextSpan()
    const SelectedCheckIcon = getSelectedCheckIcon()
    const ActionButton = getActionButton()

    return (
      <DropDownMenuContainer
        dropUp={this.dropUp}
        top={this.top}
        bottom={this.bottom}
        style={this.dropdownMenuStyles}
      >
        <DropdownMenu
          id="dropDownMenuElement"
          role="combobox"
          x-placement="bottom-start"
          ref="dropdownMenu"
          onmouseenter={() => {
            this.$emit('menumouseenter')
          }}
          onmouseleave={() => {
            this.$emit('menumouseleave')
          }}
        >
          {this.filtered <= 0 &&
            this.loading && ( // Show Spinner on filtered items = 0 and currently loading.
              <SpinnerContainer>
                <SmallSpinner role="status"></SmallSpinner>
              </SpinnerContainer>
            )}

          {this.filtered.map((option, index) => {
            return (
              <DropDownItemContainer key={index}>
                {option.group &&
                  !this.isGroupSameAsPrevious(this.filtered, index) && (
                    <DropdownHeader>{option.group}</DropdownHeader>
                  )}

                <DropDownItemAnchor
                  href={option.link || ''}
                  onClick={(e) => {
                    if (!option.link) {
                      e.preventDefault()
                    }
                    e.stopPropagation()
                    this.$emit('optionchange', option)
                  }}
                  checked={this.hasItem(option)}
                  chooseTemplateTenantStyles={this.chooseTemplateTenantStyles}
                >
                  {option.icon ? (
                    <PrependIcon icon={option.icon} hover></PrependIcon>
                  ) : (
                    ''
                  )}
                  <DropDownTextSpan>
                    {option.content || option.label}
                  </DropDownTextSpan>
                  {this.hasItem(option) && (
                    <SelectedCheckIcon
                      icon="check"
                      prefixClass="check-icon"
                      hover
                    ></SelectedCheckIcon>
                  )}
                </DropDownItemAnchor>
              </DropDownItemContainer>
            )
          })}
        </DropdownMenu>
        {this.actionButton && (
          <ActionButton>
            <CvtButton
              text="Create New Org."
              icon="plus"
              outlined={true}
              fullWidth={true}
              onClick={() => this.$emit('createNewOrg')}
            ></CvtButton>
          </ActionButton>
        )}
      </DropDownMenuContainer>
    )
  }
}
