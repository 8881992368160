import {CreateWidgetFromFirebaseNode} from "../components/v3/lib/vue-dom-obj";
import {VDomCommands} from "./VDomCommand";
import {FBVNodeComponent, VueGridStackComponent} from "../../../types";


export class RemoveCommand extends VDomCommands {
    private _gridItemVNodeId: string;
    private _widget: object;

    constructor(gridContainerVNodeId: string, GridItemVNodeId: string, currentFBNode: object) {
        super(gridContainerVNodeId);
        this._gridItemVNodeId = GridItemVNodeId
        this._widget = CreateWidgetFromFirebaseNode(currentFBNode)
    }

    getVueComponent(): VueGridStackComponent {
        const _vNodeIdVueComponent_ = super.getVueComponent() as VueGridStackComponent;
        if (!_vNodeIdVueComponent_.getGrid) {
            // Element must be a gridstack element [GridContainer or SubGridContainer]
            throw 'Invalid Element Found'
        }
        return _vNodeIdVueComponent_
    }

    get$emitData() {
        return  {name: 'RemoveCommand', forceBuild: false}
    }

    get firebaseRef () {
        return this.getVueComponent().firebaseRef()
    }

    get $children() {
        return this.getVueComponent().$children
    }

    get $nextTick () {
        return this.getVueComponent().$nextTick
    }

    get hasChanges () {
        return this._widget !== null
    }

    async execute() {
        const wrapComponent = this.getVueComponentByAttr(this._gridItemVNodeId, false)
        if (wrapComponent) {
            // save to database
            await this.firebaseRef.removeNode(+wrapComponent.fbNode.data.props['.key'])
            this.getVueComponent().getGrid().removeWidget(wrapComponent.$el)
        }
    }

    async unExecute() {
        this.firebaseRef.push(this._widget).then(() => {
            this.$nextTick(() => {
                const wrapComponent = this.getVueComponentByAttr(this._gridItemVNodeId) as FBVNodeComponent
                this.getVueComponent().makeWidget(wrapComponent.$el)
                wrapComponent.$el.scrollIntoView({
                    behavior: 'smooth',
                })
                if (wrapComponent.resize) {
                    wrapComponent.resize()
                }
            })
        })
    }
}
